import {
    ApiErrorStatusEnum,
    arrayIsEmpty,
    time2String,
    stringIsEmpty,
    downloadFile,
    exportExcelAsBlob,
    FileExtensionsEnum,
    objectIsEmpty,
} from 'edah_utils/dist'
import React, {useEffect, useState} from 'react'
import CustomTable from '../../components/ReferralSystem/CustomTable'
import {EMERGENCY_HOSPITAL_MAINTAIN_HEADER} from '../../constants/referralSystem/maintainAreaHeader'
import {Pagination} from '../../components/Pagination/Pagination'
import {
    queryTranFirstAid,
    editTranFirstAid,
    delTranFirstAi,
} from '../../api/v1/Rmis'
import useToast from '../../hooks/useToast'
import useDataGrid from '../../hooks/useDataGrid'
import {DATE_FORMAT, DATETIME_FORMAT} from '../../constants/common'
import {queryByHospNo} from '../../api/v1/Menu'
import {
    updatePrintContent,
    completePrintMode,
} from '../../redux/Slice/printSlice'
import {useSelector} from 'react-redux'
import store from '../../redux/store'
import {FORM_TYPE} from '../../constants/referralSystem/print'
import {
    AlertTypeEnum,
    TextField,
    IconEnum,
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    Dialog,
    DialogVariant, DialogSizeEnums
} from "edah-component/dist"
import {t} from 'i18next'

/**
 * layout component
 * @returns {JSX.Element}
 */
const Layout = ({children}) => {
    return (
        <div className="p-2 w-full h-[calc(100vh-98px)] overflow-auto">
            {children}
        </div>
    )
}

/**
 * 急救責任醫院維護
 * @returns {JSX.Element}
 */
const EmergencyHospitalMaintain = () => {
    // pagination
    const [filter, setFilter] = useState({
        totalPageSize: 1,
        totalItemSize: 0,
        pageNum: 1,
        pageSize: 10,
    })

    // data grid
    const {
        // 更新資料列表
        updateDataList,
        // 進入編輯模式
        handleEdit,
        // 儲存資料
        handleSave,
        // 取消編輯
        handleCancel,
        // 新增資料
        addNewData,
        // 資料變更
        dataOnChange,
        // 選取單筆資料
        handleToggleItem,
        // 全選
        handleToggleAll,
        // 刪除單筆資料
        deleteRow,
        // 資料列表
        dataList,
        // 是否編輯模式
        isEditMode,
    } = useDataGrid()

    // 當前選取的資料
    const [activeRow, setActiveRow] = useState({})

    // 院所代碼
    const [hospitalCode, setHospitalCode] = useState('')
    // 院所名稱
    const [hospitalName, setHospitalName] = useState('')
    // 分院名稱
    const [subHospitalName, setSubHospitalName] = useState('')

    // 刪除確認popup控制
    const [showDeletePopup, setShowDeletePopup] = useState(false)

    // toast message
    const showToast = useToast()

    // 是否列印模式
    const isPrintMode = useSelector((state) => state.print.isPrintMode)

    /**
     * 搜尋列- 更新院所代碼
     * @param e {Event}
     * @return {void}
     */
    const handleHospitalCodeChange = (e) => setHospitalCode(e.target.value)

    /**
     * 搜尋列- 更新院所名稱
     * @param e {Event}
     * @return {void}
     */
    const handleHospitalNameChange = (e) => setHospitalName(e.target.value)

    /**
     * 搜尋列- 更新分院名稱
     * @param e {Event}
     * @return {void}
     */
    const handleSubHospitalNameChange = (e) =>
        setSubHospitalName(e.target.value)

    /**
     * 查詢按鈕
     * @return {void}
     */
    const handleClickQuery = () => {
        getAidHospitalList(1, filter.pageSize)
    }

    /**
     * 取得急救責任醫院列表
     * @param pageNum {number} 目前頁碼
     * @param pageSize {number} 每頁筆數
     * @return {void}
     */
    const getAidHospitalList = (pageNum, pageSize) => {
        const params = {
            hospName: hospitalName,
            hospNo: hospitalCode,
            branchHospName: subHospitalName,
            pageNum,
            pageSize,
        }
        // 取得急救責任醫院列表
        queryTranFirstAid(params).then((res) => {
            // 成功
            if (res.err === ApiErrorStatusEnum.Success) {
                // 轉換等級名稱
                const list =
                    res.data?.map((item) => {
                        const aidGradeName =
                            EMERGENCY_HOSPITAL_MAINTAIN_HEADER[2].options.filter(
                                (option) => option.value === item.aidGrade
                            )[0]?.name ?? ''
                        return {
                            ...item,
                            aidGradeText: aidGradeName,
                        }
                    }) || []
                // 若無資料，則顯示無資料 toast
                if (arrayIsEmpty(list.length)) {
                    showToast({
                        message: '查無資料',
                        type: AlertTypeEnum.Warning,
                    })
                }
                setFilter({
                    ...filter,
                    pageSize,
                    pageNum,
                    totalItemSize: res.totalItemSize,
                    totalPageSize: res.totalPageSize,
                })
                updateDataList(list)
            } else {
                // 失敗 顯示錯誤訊息
                showToast({
                    message: res.msg,
                    type: AlertTypeEnum.Error,
                })
            }
        })
    }

    /**
     * 刪除單筆資料
     * @return {void}
     */
    const handleConfirmDelete = () => {
        // 刪除急救責任醫院
        delTranFirstAi({
            hospNo: activeRow.hospNo,
            recCount: activeRow.recCount,
        }).then((res) => {
            // 成功
            if (res.err === ApiErrorStatusEnum.Success) {
                // 顯示刪除成功 toast
                showToast({message: '刪除成功', type: AlertTypeEnum.Success})
                // 刪除該列資料
                deleteRow(activeRow)
                // 重新取得急救責任醫院列表
                getAidHospitalList(filter.pageNum, filter.pageSize)
            } else {
                // 失敗 顯示錯誤訊息
                showToast({message: res.msg, type: AlertTypeEnum.Error})
            }
            // 關閉刪除彈窗
            setShowDeletePopup(false)
        })
    }

    /**
     * 開啟刪除彈窗
     * @param tableRow {object} 當列資料
     * @return {void}
     */
    const handleDeletePopupOpen = (tableRow) => {
        setShowDeletePopup(true)
        setActiveRow(tableRow)
    }

    /**
     * 關閉刪除彈窗
     * @return {void}
     */
    const handleDeletePopupClose = () => {
        setShowDeletePopup(false)
    }

    /**
     * 匯出Excel api
     * @return {void}
     */
    const handleExport = () => {
        const selectedData = dataList.filter((item) => item.isSelected)
        // 未選取不能匯出 excel
        if (selectedData.length === 0) {
            return
        }
        // 取得表格表頭 key
        const keys = EMERGENCY_HOSPITAL_MAINTAIN_HEADER.map((item) => item.key)
        // 取得表格表頭名稱
        const titles = EMERGENCY_HOSPITAL_MAINTAIN_HEADER.map(
            (item) => item.name
        )
        // 建立資料陣列
        const dataArray = []
        selectedData.forEach((item) => {
            const data = []
            keys.forEach((key) => {
                // 日期格式化 => YYYY-MM-DD 00:00:00
                if (key === 'effDate' || key === 'endDate') {
                    data.push(time2String(item[key], DATE_FORMAT))
                } else {
                    data.push(item[key])
                }
            })
            dataArray.push(data)
        })
        // 建立 Excel 資料
        const sheets = [
            {
                titles,
                data: dataArray,
            },
        ]
        // 匯出 Excel
        downloadFile(
            exportExcelAsBlob(sheets),
            '急救責任醫院維護',
            FileExtensionsEnum.XLSX
        )
    }

    /**
     * 點選 pagination 頁碼
     * @param page {string} 目前頁碼
     * @return {void}
     */
    const handlePageOnChange = (page) => {
        const currentPage = Number(page)
        getAidHospitalList(currentPage, filter.pageSize)
    }

    /**
     * 變更每頁筆數
     * @param event {object}
     * @return {void}
     */
    const handlePageSizeChange = (event) => {
        const pageSize = Number(event.target.value)
        getAidHospitalList(1, pageSize)
    }
    /**
     * 點選上一頁
     * @return {void}
     */
    const handlePrevPageOnClick = () => {
        const prevPage = filter.currentPage - 1
        // 若上一頁小於1，則顯示最後一頁，否則顯示上一頁
        const page = prevPage < 1 ? filter.totalPage : prevPage
        getAidHospitalList(page, filter.pageSize)
    }

    /**
     * 點選下一頁
     * @return {void}
     */
    const handleNextPageOnClick = () => {
        const nextPage = filter.pageNum + 1
        const firstPage = 1
        // 若下一頁大於總頁數，則顯示第一頁，否則顯示下一頁
        const page = nextPage > filter.totalPageSize ? firstPage : nextPage
        getAidHospitalList(page, filter.pageSize)
    }

    /**
     * 點擊編輯進入編輯模式
     * @param row {object} 當列資料
     * @return {void}
     */
    const handleEditMode = (row) => {
        // 進入編輯模式
        handleEdit({
            ...row,
            hospNo: row.hospNo || '',
            aidGrade: row.aidGrade || '',
            branchHospName: row.branchHospName || '',
            sortBy: row.sortBy || '',
            uploadCodeNo: row.uploadCodeNo || '',
            effDate: time2String(row.effDate, DATE_FORMAT) || '',
            endDate: time2String(row.endDate, DATE_FORMAT) || '',
        })
    }

    /**
     * 儲存該列資料
     * @param tableRow {object} 當列資料
     * @return {void}
     */
    const saveAidHospital = (tableRow) => {
        // 若院所代號、院所名稱、急救責任醫院等級有空值，則不儲存
        if (
            stringIsEmpty(tableRow.hospNo) ||
            stringIsEmpty(tableRow.hospName) ||
            stringIsEmpty(tableRow.aidGrade)
        ) {
            return
        }

        const data = {
            // 鎖定版本
            lockVersion: tableRow.lockVersion,
            // 院所代號
            hospNo: tableRow.hospNo,
            // 院所名稱
            hospName: tableRow.hospName,
            // 等級
            aidGrade: tableRow.aidGrade,
            recCount: tableRow.recCount,
            // 分院名稱
            branchHospName: tableRow.branchHospName,
            // 開始日期
            effDate: time2String(tableRow.effDate, DATETIME_FORMAT),
            // 結束日期
            endDate: time2String(tableRow.endDate, DATETIME_FORMAT),
            // 排序號碼
            sortBy: tableRow.sortBy,
        }
        // 更新急救責任醫院
        editTranFirstAid(data).then((res) => {
            // 成功
            if (res.err === ApiErrorStatusEnum.Success) {
                // 儲存該列資料
                handleSave(tableRow)
                // 重新取得急救責任醫院列表
                getAidHospitalList(filter.pageNum, filter.pageSize)
                // 顯示儲存成功 toast
                showToast({message: '儲存成功', type: AlertTypeEnum.Success})
            } else {
                // 失敗 顯示錯誤訊息
                showToast({message: res.msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 新增一列資料
     * @return {void}
     */
    const addAidHospital = () => {
        const newAidHospital = {
            //急救責任院所代號
            hospNo: '',
            //急救責任醫院等級
            aidGrade: '',
            //急救責任醫院分區名稱
            branchHospName: '',
            //排序
            sortBy: '',
            //開始日期
            effDate: '',
            //結束日期
            endDate: '',
            recCount: 0,
        }
        addNewData(newAidHospital)
    }

    /**
     * table欄位失焦事件
     * @param e {Event} 失焦事件
     * @param row {object} 該列資料
     * @param key {string} 欄位
     * @return {void}
     */
    const handleInputOnBlur = (e, row, key) => {
        if (key === 'hospNo' && !stringIsEmpty(row.hospNo)) {
            const value = e.target.value
            getHospNameByHospNo(value, row)
        }
    }

    /**
     * 防抖動
     * @param func {function} 函數
     * @param delay {number} 延遲毫秒數
     * @return {function}
     */
    const debounce = (func, delay = 500) => {
        let timer

        return (...args) => {
            clearTimeout(timer)
            timer = setTimeout(() => {
                func(...args)
            }, delay)
        }
    }

    /**
     *  以院所代碼取得院所名稱
     * @param value {string} 院所代碼
     * @param row {object} 當列資料
     * @return {void}
     */
    const getHospNameByHospNo = debounce((value, row) => {
        queryByHospNo({hospNo: value}).then((res) => {
            // 成功
            if (res.err === ApiErrorStatusEnum.Success) {
                // 若查無相關院所代碼，則顯示查無相關院所代碼 toast
                if (objectIsEmpty(res.data)) {
                    showToast({
                        message: '查無相關院所代碼',
                        type: AlertTypeEnum.Error,
                    })
                    // 清空院所名稱、院所代號
                    dataOnChange(row, 'hospName', '')
                    dataOnChange(row, 'hospNo', '')
                } else {
                    // 更新院所名稱、院所代號
                    dataOnChange(row, 'hospName', res.data.hospName || '')
                    dataOnChange(row, 'hospNo', value)
                }
            } else {
                // 失敗 顯示錯誤訊息
                showToast({
                    message: res.msg,
                    type: AlertTypeEnum.Error,
                })
                // 清空院所名稱、院所代號
                dataOnChange(row, 'hospName', '')
                dataOnChange(row, 'hospNo', '')
            }
        })
    })

    /**
     * 匯出Excel
     * @return {JSX.Element}
     */
    const exportExcelBtn = () => {
        return (
            <Button
                icon={IconEnum.Export}
                text={t('general.export')}
                color={ButtonColorEnum.Success}
                size={ButtonSizeEnum.Large}
                variant={ButtonVariantEnum.Text}
                onClick={handleExport}
            />
        )
    }

    /**
     * 列印按鈕
     * @return {void}
     */
    const handlePrint = () => {
        const printData = dataList.filter((item) => item.isSelected)
        store.dispatch(
            updatePrintContent({
                reportType: FORM_TYPE.aidHospitalList,
                printData,
            })
        )
    }

    /**
     * 開啟列印模式則開啟瀏覽器列印視窗
     * 結束列印則關閉列印模式
     * @return {void}
     */
    useEffect(() => {
        // 開啟列印模式則開啟瀏覽器列印視窗
        if (isPrintMode) {
            window.print()
            // 結束列印則關閉列印模式
            store.dispatch(completePrintMode())
        }
    }, [isPrintMode])

    return (
        <Layout>
            {/*刪除確認popup*/}
            <Dialog
                open={showDeletePopup}
                title={'提醒'}
                variant={DialogVariant.DELETE}
                paperStyleProps={{width: DialogSizeEnums.SM}}
                content={'是否確定刪除？'}
                onClose={handleDeletePopupClose}
                onDelete={handleConfirmDelete}
                muiDialogContentProps={{sx: {height: '110px', display: 'flex', alignItems: 'center'}}}
            />
            <div className="flex justify-between p-2 items-center">
                {/*搜尋列*/}
                <div className="flex items-center space-x-2">
                    {/* 院所代碼 */}
                    <TextField
                        label="院所代碼"
                        value={hospitalCode}
                        onChange={handleHospitalCodeChange}
                    />
                    {/* 院所名稱 */}
                    <TextField
                        label="院所名稱"
                        value={hospitalName}
                        onChange={handleHospitalNameChange}
                    />
                    {/* 分院名稱 */}
                    <TextField
                        label="分院名稱"
                        value={subHospitalName}
                        onChange={handleSubHospitalNameChange}
                    />
                    {/* 查詢按鈕 */}
                    <Button
                        color={ButtonColorEnum.Primary}
                        size={ButtonSizeEnum.Medium}
                        variant={ButtonVariantEnum.Contained}
                        text={t('general.query')}
                        onClick={handleClickQuery}
                        sx={{fontWeight: 'bold', marginLeft: '1rem'}}
                    />
                </div>
                <div>
                    <Button
                        icon={IconEnum.Print}
                        text={t('general.print')}
                        color={ButtonColorEnum.Secondary}
                        size={ButtonSizeEnum.Medium}
                        variant={ButtonVariantEnum.Outlined}
                        onClick={handlePrint}
                        sx={{fontWeight: 'bold'}}
                    />
                </div>
            </div>
            {/* 表格 */}
            <CustomTable
                isSelectable={true}
                fields={EMERGENCY_HOSPITAL_MAINTAIN_HEADER}
                dataList={dataList}
                toggleAll={handleToggleAll}
                toggleItem={handleToggleItem}
                totalPage={filter.page}
                total={filter.total}
                slotButton={exportExcelBtn()}
                isCrud={true}
                isEditMode={isEditMode}
                handleEdit={handleEditMode}
                handleSave={saveAidHospital}
                handleCancel={handleCancel}
                addNewData={addAidHospital}
                handleInputOnBlur={handleInputOnBlur}
                deleteRow={handleDeletePopupOpen}
            />
            <div className="flex justify-end">
                <Pagination
                    pageSize={filter.pageSize}
                    totalSize={filter.total}
                    currentPage={filter.currentPage}
                    totalPageSize={filter.totalPage}
                    onPageOnChange={handlePageOnChange}
                    onPageSizeChange={handlePageSizeChange}
                    onPrevPageOnClick={handlePrevPageOnClick}
                    onNextPageOnClick={handleNextPageOnClick}
                />
            </div>
        </Layout>
    )
}

export default EmergencyHospitalMaintain
