import {arrayIsEmpty, time2String} from "edah_utils/dist"
import React, {useState} from "react";
import { t } from "i18next";

/**
 *  檢驗結果
 *  @param data {Array}  資料
 *  @return {JSX.Element}
 */
export const InspectionResults = ({data}) => {
    return (
        <div className="w-full">
            {/*列表清單區塊*/}
            {/*列表清單*/}
            <ul className="tableList icIRList">
                {/*表單項目標題*/}
                <li className="title">
                    <div>門住診別</div>
                    <div>報告類別</div>
                    <div>檢查檢驗類別</div>
                    <div>醫令代碼</div>
                    <div>檢驗項目</div>
                    <div>檢驗方法</div>
                    <div>檢查檢驗結果</div>
                    <div>單位</div>
                    <div>參考值</div>
                    <div>檢體</div>
                    <div>醫令日期</div>
                    <div>採檢日期</div>
                    <div>報告日期</div>
                    <div>醫事機構代碼</div>
                    <div>醫事機構簡稱</div>
                    <div>TAF認證</div>
                </li>

                {
                    !arrayIsEmpty(data) && data.map((item, index) =>
                        <li key={index}>
                            {/*門住診別*/}
                            <div>{t(`ICCard.InpOpdEnum.${item.inpOpd}`)}</div>
                            {/*報告類別*/}
                            <div>{item.cloudencReportType}</div>
                            {/*檢查檢驗類別*/}
                            <div>{item.heDivNo}</div>
                            {/*醫令代碼*/}
                            <div>{item.orderHeCode}</div>
                            {/*檢驗項目*/}
                            <div>{item.examName}</div>
                            {/*檢驗方法*/}
                            <div>{item.examMethod}</div>
                            {/*檢查檢驗結果*/}
                            <div>{item.examResult}</div>
                            {/*單位*/}
                            <div>{item.unit}</div>
                            {/*參考值*/}
                            <div>{item.reference}</div>
                            {/*檢體*/}
                            <div>{item.specimen}</div>
                            {/*醫令日期*/}
                            <div>{time2String(item.orderDate, 'YYYY-MM-DD')}</div>
                            {/*採檢日期*/}
                            <div>{time2String(item.examDate, 'YYYY-MM-DD')}</div>
                            {/*報告日期*/}
                            <div>{time2String(item.reportDate, 'YYYY-MM-DD')}</div>
                            {/*醫事機構代碼*/}
                            <div>{item.hospNo}</div>
                            {/*醫事機構簡稱*/}
                            <div>{item.hospName}</div>
                            {/*TAF認證*/}
                            <div>{item.tafFlag}</div>
                        </li>
                    )}
            </ul>
        </div>
    )
}
