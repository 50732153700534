// Icon
import AddIcon from "../../../assets/images/icons/add_circle.svg"

import {BasePopup} from "../../Popup/BasePopup"
import {BaseInput} from "../../Input/BaseInput"
import React, {useState} from "react"
import {t} from "i18next"

/**
 *  操作 / 編輯 細節 Popup
 *  @param {object} info 資訊
 *  @param {boolean} show 是否顯示
 *  @param onClosePopupButtonClick {function} 關閉彈窗時的 callback
 *  @return {JSX.Element}
 */
export const ModifyNotifyMaintainerDetailPopup = ({info, show, onClosePopupButtonClick}) => {
    // 是否顯示彈窗
    const [isShow, setIsShow] = useState(show)

    /**
     * 點擊確認按鈕
     * @return {void}
     */
    const handleAddButtonOnClick = () => {

    }

    /**
     * 取得Content
     * @return {JSX.Element}
     */
    const getContent = () => <div className="w-full">
        {/*服務代號/名稱*/}
        <div className="w-full flex flex-row items-center justify-start py-4 px-6 mb-4">
            <p className="text-left pr-4">服務代號/名稱</p>
            <select className="w-[235px] h-10 px-2 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4">
                <option key="" value="0">服務代號 - 服務名稱</option>
                <option value="PID0001A - IC卡資料上傳">PID0001A - IC卡資料上傳</option>
            </select>
        </div>
        {/*新增按鈕*/}
        <button className="flex flex-row justify-center items-center w-[112px] h-10 mb-4"
                onClick={handleAddButtonOnClick}>
            <div className="p-[3.33px] w-10 h-10">
                <img className="w-full h-full" src={AddIcon} alt="addIcon"/>
            </div>
            <p className="text-center text-[#38A169]">
                {t('general.add')}
            </p>
        </button>
        <div className="maintainContainer flex flex-row items-start justify-start">
            <div className="p-4 bg-white w-full border-[1px] border-[#D4D4D8] rounded-[12px] m-4">
                <div className="searchGroup flex flex-row items-center justify-between mb-4">
                    <BaseInput className="w-[320px] h-10 pl-4 border-[2px] border-[#D4D4D8] rounded-[6px]" type="text"
                               placeholder="進階搜尋" inputMode={'search'}/>
                </div>
                <ul className="tableList notificationList">
                    <li className="title">
                        <div>操作</div>
                        <div>人員代號</div>
                        <div>姓名</div>
                        <div>通知啟用</div>
                        <div>持續報警(分)</div>
                        <div>通知方式</div>
                    </li>
                    {/*{filteredNotificationsChild.map(([key, notification]) => (*/}
                    {/*    <li key={key}>*/}
                    {/*        <div>*/}
                    {/*            <button*/}
                    {/*                onClick={() => handleModNotification(key)}*/}
                    {/*                className="flex flex-row items-center justify-start text-[#2B6CB0] mr-3"*/}
                    {/*            >*/}
                    {/*                <EditBtn/>編輯*/}
                    {/*            </button>*/}
                    {/*            <button*/}
                    {/*                onClick={() => handleDeleteChildBtn(key)}*/}
                    {/*                className="flex flex-row items-center justify-start text-[#2B6CB0]"*/}
                    {/*            >*/}
                    {/*                <DeleteBtn/>刪除*/}
                    {/*            </button>*/}
                    {/*        </div>*/}
                    {/*        <div>{notification.department}</div>*/}
                    {/*        <div>{notification.name}</div>*/}
                    {/*        <div>{notification.status}</div>*/}
                    {/*        <div>{notification.interval}</div>*/}
                    {/*        <div>{notification.method}</div>*/}
                    {/*    </li>*/}
                    {/*))}*/}
                </ul>
            </div>
        </div>
    </div>

    return isShow ? <BasePopup title={"通知人員維護"} closePopupButtonOnClick={onClosePopupButtonClick} width={"1030px"}
                               content={getContent()}/> : null
}
