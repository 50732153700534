import {v1Api} from "../Http"
import {HttpMethodEnum} from "edah_utils/dist"

/**
 * 依標語類別、醫師代號及科別代碼來查詢標語紀錄
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const sloganQueryBySlognTypeAndDoctorNoAndDivNo = (params) => v1Api.request({
    url: '/regs/slogn/querySlognBySlognTypeAndDoctorNoAndDivNo',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 修改單筆標語紀錄
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const slognEditData = (data) => v1Api.request({
    url: '/regs/slogn/editSlognData',
    method: HttpMethodEnum.PUT,
    data
})

/**
 * 新增單筆標語紀錄
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const slognAddData = (data) => v1Api.request({
    url: '/regs/slogn/addSlognData',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 刪除單筆標語紀錄
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const slognDelData = (params) => v1Api.request({
    url: '/regs/slogn/delSlognData',
    method: HttpMethodEnum.DELETE,
    params
})

/**
 * 查詢標語內文for周班月班自動帶入
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const sloganQueryContentForWeekOrClinic = (params) => v1Api.request({
    url: '/regs/slogn/querySlognContentForWeekOrClinic',
    method: HttpMethodEnum.GET,
    params
})

export {
    sloganQueryBySlognTypeAndDoctorNoAndDivNo,
    slognEditData,
    slognAddData,
    slognDelData,
    sloganQueryContentForWeekOrClinic
}