import React, {useState} from 'react'
import {DelSlotBtn, EditSlotBtn} from '../SlotButton'
import {usePopup} from '../PopupProvider'
import {stringIsEmpty, time2String} from "edah_utils/dist";
import {Button, ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum, IconEnum} from "edah-component/dist";
import {t} from "i18next";

/**
 * 時段
 * @type {{"": string, "1": string, "2": string, "3": string}}
 */
const apnList = {
    "1": '早',
    "2": '午',
    "3": '晚',
}

/**
 * 特殊權限設定列表項目
 * @param {Object} item - 列表資料
 * @param {Number} index - 列表索引
 * @param {Boolean} isSelectAll - 是否全選
 * @returns {JSX.Element}
 */
const ListItem = ({item, index, isSelectAll = null}) => {
    // 使用上下文，獲取刪除列表popup顯示狀態和列表當前焦點索引
    const {setShowDeletePopup, setShowSpecialPermissionMaintain, setFocusItem, focusItem} = usePopup()

    // 人員工號搜尋字串
    const [queryString, setQueryString] = useState('')

    /**
     * 刪除列表項目，顯示刪除列表popup，返回列表當前焦點索引
     * @return {void}
     */
    const handleOnDelete = () => {
        setShowDeletePopup(true)
        setFocusItem(item)
    }

    /**
     * 編輯列表項目，顯示特殊權限維護popup，返回列表當前物件
     * @return {void}
     */
    const handleOnEdit = () => {
        if (item !== focusItem) setFocusItem(item)
        setShowSpecialPermissionMaintain(true)
    }

    /**
     * 根據editable狀態渲染列表按鈕
     * @returns {JSX.Element}
     */
    const renderSlotBtn = () => {
        return (
            <div className='flex flex-row gap-2'>
                <Button sx={{whiteSpace:'nowrap'}}
                        color={ButtonColorEnum.Primary}
                        size={ButtonSizeEnum.Large}
                        variant={ButtonVariantEnum.Text}
                        icon={IconEnum.Edit}
                        text={t('general.edit')}
                        onClick={handleOnEdit}
                />

                <Button sx={{whiteSpace: 'nowrap'}}
                        color={ButtonColorEnum.Primary}
                        size={ButtonSizeEnum.Large}
                        variant={ButtonVariantEnum.Text}
                        icon={IconEnum.Delete}
                        text={t('general.delete')}
                        onClick={handleOnDelete}
                />
            </div>
        )
    }

    return (
        <li key={index}>
            {/*操作*/}
            {renderSlotBtn()}
            {/*科別*/}
            <div>{item.divNo && item.divName ? `${item.divNo} ${item.divName}` : ''}</div>
            {/*醫師*/}
            <div>{item.userNo && item.userName ? `${item.userNo} ${item.userName}` : ''}</div>
            {/*時段*/}
            <div>{item.apn === '' || item.apn === null ? '全部' : apnList[item.apn]}</div>
            {/*看診日期*/}
            <div>{item.encountDate ? (item.encountDate.split(' ')[0]) : ''}</div>
            {/*診室名稱*/}
            <div>{item.clinicNo ? item.clinicNo : ''} {item.clinicName ? item.clinicName : ''}</div>
        </li>
    )
}

export default ListItem
