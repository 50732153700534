import React, {useEffect, useRef, useState} from 'react'
import {
    ApiErrorStatusEnum,
    arrayIsEmpty,
    convertGregorianToMinGuo,
    downloadFile,
    exportExcelAsBlob,
    fuzzySearchObjects,
    getLocalStorage,
    stringIsEmpty,
    time2String,
} from 'edah_utils/dist'
import '../../components/TableList/tableList.scss'
import {usePopup} from "./PopupProvider";
import {rsisAddSendLog} from "../../api/v1/RSIS";
import {t} from "i18next";
import {
    AlertTypeEnum,
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    Checkbox,
    IconEnum,
    Card
} from "edah-component/dist"
import {SearchTextField} from "../SearchTextField/SearchTextField";

/**
 * @param header {JSX.Element} 表頭
 * @param advancedSearch {Boolean} 是否啟用進階搜尋
 * @param fields {Array} 表格欄位
 * @param role {String} 角色
 * @param dataList {Array} 表格資料
 * @param extraDropDownListData {Array} 額外下拉選單資料
 * @param depList {Array} 承辦部門列表
 * @param ListItem {JSX.Element} 列表組件
 * @param hasSelectAll {Boolean} 是否有全選功能
 * @param type {String} 表格類型
 * @param saveData {Function} 當存擋按鈕被點擊時觸發
 * @param exportSlotBtn {Boolean} 是否有匯出按鈕
 * @param exportFileName {String} 匯出檔案名稱
 * @param handleGetSelectItem {Function} 取得已選擇的資料
 * @param handleOnUpdateCallback {Function} 更新資料後的回調
 * @param handleOnListItemSave {Function} 儲存列表項目的回調
 * @param handleOnListItemCancel {Function} 取消列表項目的回調
 * @param handleOnListItemChange {Function} 更新列表項目的回調
 * @return {JSX.Element}
 */
const CustomTable = ({
                         header = null,
                         advancedSearch = false,
                         fields,
                         role = null,
                         dataList,
                         extraDropDownListData = null,
                         ListItem,
                         hasSelectAll = false,
                         type,
                         saveData = null,
                         exportSlotBtn = false,
                         exportFileName = '',
                         handleGetSelectItem = null,
                         handleOnUpdateCallback = null,
                         handleOnListItemSave = null,
                         handleOnListItemCancel = null,
                         handleOnListItemChange = null
                     }) => {
    //分塊大小
    const CHUNK_SIZE = 300
    //export excel titles
    const EXPORT_EXCEL_TITLES = [
        "身分證/統一證號",
        "支援開始時間",
        "支援結束時間",
        "支援科別",
        "支援機構代碼",
        "支援非醫事機構名稱",
        "支援非醫事機構地址",
        "報備為一般支援",
        "報備為負責人",
        "報備為糖尿病共同照護網",
        "整合式健康檢查",
        "預防針接種",
        "藥癮治療或傳染病防治服務",
        "義診或巡迴醫療服務",
        "藥事照護相關業務",
        "矯正機關及偏遠地區",
        "公益",
        "緊急需要",
        "備註"
    ]

    // 是否全選
    const [isSelectAll, setIsSelectAll] = useState(false);
    // 被選擇的列表
    const [selectedItem, setSelectedItem] = useState([]);
    // 模糊搜尋的字串
    const [queryString, setQueryString] = useState('');
    // 使用上層傳入的方法
    const {batchSelected, isReadyForExport, setIsReadyForExport, showToast} = usePopup()
    // 分塊大小
    const chunkArraySize = useRef('')

    /**
     * 當列表被全選時觸發
     * @param e {Event}
     * @return {void}
     */
    const handleSelectAll = (e) => {
        setIsSelectAll(e.target.checked);
        if (e.target.checked) {
            const value = getFilterData()
            if (batchSelected === '1' || batchSelected === '2' || batchSelected === '3') {
                // 過濾出符合批次作業的資料
                const selectItem = value.filter((item) => {
                    // 批次作業-收案確認 -> 只收申請狀態之資料
                    if (batchSelected === '1') return item.stepStatus === '1'
                    // 批次作業-衛福部送審轉出 -> 只收確認狀態且未變更或註銷的資料
                    if (batchSelected === '2' && stringIsEmpty(item.supportModifyStatus)) return item.stepStatus === '2'
                    // 批次作業-退回申請 -> 只收確認狀態的資料
                    if (batchSelected === '3') return item.stepStatus === '2'
                })

                setSelectedItem(selectItem);
                handleGetSelectItem && handleGetSelectItem(selectItem)
            } else if (batchSelected === '4') { // 整批變更註銷或刪除頁面時不需要判斷狀態
                setSelectedItem(value);
                handleGetSelectItem && handleGetSelectItem(value)
            }
        } else {
            setSelectedItem([]);
            handleGetSelectItem && handleGetSelectItem([])
        }
    }

    /**
     * 當列表被勾選時觸發
     * @param newData {Object} 待新增的資料
     * @return {void}
     */
    const handleAddSelectedItem = (newData) => {
        const value = [...selectedItem, newData]
        setSelectedItem(value);
        handleGetSelectItem && handleGetSelectItem(value)
    }

    /**
     * 當列表被取消勾選時觸發
     * @param newData {Object} 待刪除的資料
     * @return {void}
     */
    const handleRemoveSelectedItem = (newData) => {
        const value = selectedItem.filter((item) => item !== newData)
        setSelectedItem(value);
        handleGetSelectItem && handleGetSelectItem(value)
    }

    /**
     * 匯出檔案日期格式化api需要
     * @param {string} name 匯出名稱
     * @return {string}
     */
    const formatExportDateName = (name) => {
        const dateTime = name.split('_')[0]
        const year = dateTime.slice(0, 4);
        const month = dateTime.slice(4, 6);
        const day = dateTime.slice(6, 8);
        const hour = dateTime.slice(8, 10);
        const minute = dateTime.slice(10, 12);
        const result = `${year}-${month}-${day} ${hour}:${minute}:00`
        console.log(result)
        return `${year}-${month}-${day} ${hour}:${minute}:00`;
    }

    /**
     * 搜尋字串改變時
     * @param event {Event}
     * @return {void}
     */
    const handleQueryOnChange = (event) => setQueryString(event.target.value);

    /**
     * 衛福部送審轉出
     * @param data {Object} 資料RSISMaster
     * @return {void}
     */
    const handleSendCase = (data) => {
        const updatedData = data.map(item => ({
            ...item,
            sendFileName: exportFileName,
            sendDatetime: formatExportDateName(exportFileName)
        }))
        rsisAddSendLog({
            // uuid
            sendUuid: "",
            // 匯出人員代號
            sendUserNo: getLocalStorage('userno'),
            // 匯出日期時間
            sendDatetime: formatExportDateName(exportFileName),
            // 匯出編號
            sendFileName: exportFileName,
            // 備註
            memo: "",
            // 衛福部案號
            sendCaseNo: '',
            // rsis master object
            masterList: updatedData
        }).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            // 取得成功
            if (err === ApiErrorStatusEnum.Success) {
                // 送審轉出成功，重新取得資料
                handleOnUpdateCallback()
                showToast({message: '衛福部送審轉出成功', type: AlertTypeEnum.Success})
            } else {
                handleOnUpdateCallback()
                // 顯示錯誤訊息
                showToast({message: `衛福部送審轉出失敗，${msg}`, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 匯出申請與查詢Excel
     * @return {void}
     */
    const handleExportRSISMasterQueryExcel = () => {
        // 資料不存在的話
        if (arrayIsEmpty(dataList)) {
            // 顯示錯誤訊息
            showToast({message: "資料不存在", type: AlertTypeEnum.Warning})
            return
        }

        //取得要輸出資料
        const data = dataList.map(item => {
            return [
                item.supportNo,                  // 報備支援單號
                item.itemVersion,                // 版號
                item.supportUserNo,              // 報備支援人員代號
                item.supportUserName,            // 報備支援人員姓名
                item.supportOrgNo,             // 報備支援機構代號
                item.supportOrgName,             // 報備支援機構名稱
                item.supportDivNo,               // 支援科別代號
                item.supportDivName,             // 支援科別名稱
                item.purposeCode,                // 支援目的代碼
                item.purposeCodeExt,             // 支援目的說明
                item.itemStartDate ? item.itemStartDate.split(' ')[0] : '',              // 起始支援日期
                item.itemEndDate ? item.itemEndDate.split(' ')[0] : '',                // 結束支援日期
                item.itemStartTime,              // 起始支援時間
                item.itemEndTime,                // 結束支援時間
                item.supportProperties,          // 支援性質
                item.executeContent,             // 執行內容與
                item.memo,                       // 備註
                item.agentUserNo,                // 代理人員代號
                item.agentUserName,              // 代理人員姓名
                t(`RSIS.StepStatusEnum.${item.stepStatus}`),                 // 狀態
                item.supportModifyStatus && item.supportModifyStatus !== 0 ? t(`RSIS.SupportModifyStatusEnum.${item.supportModifyStatus}`) : '', // 支援變更狀態
                item.sendFileName,               // 匯出編號
                item.sendCaseNo,                 // 送審編號
                item.sendDatetime,               // 匯出日期時間
                item.applyType,                  // 申請類別
                item.zone,                       // 院區
                item.hospName,                   // 醫院名稱
                item.effFlag,                    // 有效否
                item.ownerDeptNo,                // 承辦部門代號
                item.ownerDeptName,              // 承辦部門名稱
                item.changeFieldsDoc,            // 變更欄位說明
                item.confirmUserNo,              // 收件確認人員
                item.confirmDatetime,            // 收件確認時間
                item.createUser,                 // 申請人代號
                item.createUserName              // 申請人姓名
            ];
        })

        //取得標題
        const titles = [
            "報備支援單號",
            "版號",
            "報備支援人員代號",
            "報備支援人員姓名",
            "報備支援機構代號",
            "報備支援機構名稱",
            "支援科別代號",
            "支援科別名稱",
            "支援目的代碼",
            "支援目的說明",
            "起始支援日期",
            "結束支援日期",
            "起始支援時間",
            "結束支援時間",
            "支援性質",
            "執行內容與",
            "備註",
            "代理人員代號",
            "代理人員姓名",
            "狀態",
            "",
            "匯出編號",
            "送審編號",
            "匯出日期時間",
            "申請類別",
            "院區",
            "醫院名稱",
            "有效否",
            "承辦部門代號",
            "承辦部門名稱",
            "變更欄位說明",
            "收件確認人員",
            "收件確認時間",
            "申請人代號",
            "申請人姓名"
        ]

        //取得Sheets
        const sheets = [
            {
                titles: titles,
                data: data,
            }
        ]

        // 取得日期時間
        const current = time2String(new Date(), 'YYYY-MM-DD HH-mm-ss')
        // 檔案名稱
        const fileName = `${current}_自行備註名稱`
        //取得excel
        const excel = exportExcelAsBlob(sheets)
        //下載檔案
        downloadFile(excel, fileName)
    }

    /**
     * 個案管理
     * @param exportType {String} 匯出類型，SAVE: 儲存匯出selecteItem的資料，ALL: 匯出全部資料
     * @return {void}
     */
    const handleExportRSISCaseManagementTableExcel = (exportType) => {
        let exportItems;
        // 若為存擋事件觸發，則只匯出已選擇的資料
        if (exportType === 'SAVE') {
            exportItems = selectedItem
        } else if (exportType === 'ALL') { // 若為匯出事件直接觸發，則匯出所有資料
            exportItems = dataList
        }

        // 資料不存在的話
        if (arrayIsEmpty(exportItems)) {
            // 顯示錯誤訊息
            showToast({message: "資料不存在", type: AlertTypeEnum.Warning})
            return
        }

        /**
         * 取得分割陣列
         * @return {Array<Array>}
         */
        const chunkArray = () => {
            const result = []
            for (let i = 0; i < exportItems.length; i += CHUNK_SIZE)
                result.push(exportItems.slice(i, i + CHUNK_SIZE))

            return result
        }
        chunkArraySize.current = chunkArray().length

        /**
         * 取得民國日期和時間
         * @param dateTime {String}
         * @return {String}
         */
        const convert2MinGuoDateTime = (dateTime) => {
            //不存在的話
            if (stringIsEmpty(dateTime)) {
                return ""
            }

            //切割字串
            const splitArray = dateTime.split(" ")
            //取得日期
            const date = convertGregorianToMinGuo(splitArray[0])
            //取得時間
            const time = splitArray[1].replaceAll(":", "")

            return `${date}${time}`
        }

        /**
         * 取得格式化時間
         * @param timeString {String}
         * @return {String}
         */
        const formattedTime = (timeString) => `${timeString.substring(0, 2)}:${timeString.substring(2)}:00`

        /**
         * 解析報備支援目的
         * @param v {String} 當前值
         * @param target {Number} 目標
         * @return {String}
         */
        const parserPurposeCodeContent = (v, target) =>
            //資料不存在的話
            stringIsEmpty(v) ? "N" :
                //資料存在的話
                v.split(',').some(i => i.trim() === `${target}`) ?
                    //包含於
                    'Y' :
                    //不包含
                    'N'

        // 是否要分塊
        const shouldChunk = exportItems.length > CHUNK_SIZE
        // 衛福部送審轉出
        if (exportType === 'SAVE') {
            // 要分塊
            if (shouldChunk) {
                //走訪分塊
                chunkArray().forEach((item, index) => {
                    //檔案名稱
                    const fileName = `${exportFileName ? exportFileName : time2String(new Date(), 'YYYY-MM-DD HH-mm-ss')}-${index + 1}`

                    const sheets = {
                        // titles
                        titles: EXPORT_EXCEL_TITLES,
                        // data
                        data: []
                    }

                    //走訪分塊內資料
                    item.forEach(i => {
                        // 取得開始日期
                        const itemStartDate = !stringIsEmpty(i.itemStartDate) ? i.itemStartDate.split(' ')[0] : ''
                        // 取得開始時間
                        const itemStartTime = !stringIsEmpty(i.itemStartTime) ? formattedTime(i.itemStartTime) : ''
                        // 取得結束日期
                        const itemEndDate = !stringIsEmpty(i.itemEndDate) ? i.itemEndDate.split(' ')[0] : ''
                        // 取得結束時間
                        const itemEndTime = !stringIsEmpty(i.itemEndTime) ? formattedTime(i.itemEndTime) : ''

                        sheets.data.push([
                            // 欠缺身分證,由醫師代號為主
                            i.supportUserNo,
                            // 支援開始時間
                            convert2MinGuoDateTime(`${itemStartDate} ${itemStartTime}`),
                            // 支援結束時間
                            convert2MinGuoDateTime(`${itemEndDate} ${itemEndTime}`),
                            // 支援科別
                            i.supportDivNo,
                            // 支援機構代碼
                            i.supportOrgNo,
                            // 支援非醫事機構名稱
                            i.supportOrgName,
                            // 支援非醫事機構地址
                            "",
                            // 報備為一般支援
                            parserPurposeCodeContent(i.purposeCode, 10),
                            // 報備為負責人
                            parserPurposeCodeContent(i.purposeCode, 20),
                            // 報備為糖尿病共同照護網
                            parserPurposeCodeContent(i.purposeCode, 30),
                            // 整合式健康檢查
                            parserPurposeCodeContent(i.purposeCode, 40),
                            // 預防針接種
                            parserPurposeCodeContent(i.purposeCode, 50),
                            // 藥癮治療或傳染病防治服務
                            parserPurposeCodeContent(i.purposeCode, 60),
                            // 義診或巡迴醫療服務
                            parserPurposeCodeContent(i.purposeCode, 70),
                            // 藥事照護相關業務
                            parserPurposeCodeContent(i.purposeCode, 80),
                            // 矯正機關及偏遠地區
                            parserPurposeCodeContent(i.purposeCode, 90),
                            // 公益
                            parserPurposeCodeContent(i.purposeCode, 100),
                            // 緊急需要
                            parserPurposeCodeContent(i.purposeCode, 110),
                            // 備註
                            i.memo
                        ])
                    })
                    //取得excel
                    const excel = exportExcelAsBlob([sheets])
                    //下載檔案
                    downloadFile(excel, fileName)
                })
            } else { //不分塊
                //檔案名稱
                const fileName = exportFileName ? exportFileName : `${time2String(new Date(), 'YYYY-MM-DD HH-mm-ss')}`
                const sheets = {
                    // titles
                    titles: EXPORT_EXCEL_TITLES,
                    // data
                    data: []
                }

                exportItems.forEach(i => {
                    // 取得開始日期
                    const itemStartDate = !stringIsEmpty(i.itemStartDate) ? i.itemStartDate.split(' ')[0] : ''
                    // 取得開始時間
                    const itemStartTime = !stringIsEmpty(i.itemStartTime) ? formattedTime(i.itemStartTime) : ''
                    // 取得結束日期
                    const itemEndDate = !stringIsEmpty(i.itemEndDate) ? i.itemEndDate.split(' ')[0] : ''
                    // 取得結束時間
                    const itemEndTime = !stringIsEmpty(i.itemEndTime) ? formattedTime(i.itemEndTime) : ''

                    sheets.data.push([
                        // 欠缺身分證,由醫師代號為主
                        i.supportUserNo,
                        // 支援開始時間
                        convert2MinGuoDateTime(`${itemStartDate} ${itemStartTime}`),
                        // 支援結束時間
                        convert2MinGuoDateTime(`${itemEndDate} ${itemEndTime}`),
                        // 支援科別
                        i.supportDivNo,
                        // 支援機構代碼
                        i.supportOrgNo,
                        // 支援非醫事機構名稱
                        i.supportOrgName,
                        // 支援非醫事機構地址
                        "",
                        // 報備為一般支援
                        parserPurposeCodeContent(i.purposeCode, 10),
                        // 報備為負責人
                        parserPurposeCodeContent(i.purposeCode, 20),
                        // 報備為糖尿病共同照護網
                        parserPurposeCodeContent(i.purposeCode, 30),
                        // 整合式健康檢查
                        parserPurposeCodeContent(i.purposeCode, 40),
                        // 預防針接種
                        parserPurposeCodeContent(i.purposeCode, 50),
                        // 藥癮治療或傳染病防治服務
                        parserPurposeCodeContent(i.purposeCode, 60),
                        // 義診或巡迴醫療服務
                        parserPurposeCodeContent(i.purposeCode, 70),
                        // 藥事照護相關業務
                        parserPurposeCodeContent(i.purposeCode, 80),
                        // 矯正機關及偏遠地區
                        parserPurposeCodeContent(i.purposeCode, 90),
                        // 公益
                        parserPurposeCodeContent(i.purposeCode, 100),
                        // 緊急需要
                        parserPurposeCodeContent(i.purposeCode, 110),
                        // 備註
                        i.memo
                    ])
                })
                //取得excel
                const excel = exportExcelAsBlob([sheets])
                //下載檔案
                downloadFile(excel, fileName)
            }
        } else { // 個案管理-總匯出
            const titles = [
                "申請單號",
                "院區名稱",
                "申請人代號",
                "姓名",
                "支援醫師代號",
                "姓名",
                "支援機構代號",
                "名稱",
                "支援科別代號",
                "名稱",
                "開始日期 時間",
                "結束日期 時間",
                "狀態別",
                "",
                "衛福部案號",
                "匯出編號",
                "備註"
            ]

            /**
             * 取得日期和時間
             * @param date {String|null}
             * @param time {String|null}
             * @return {String}
             */
            const getDateAndTime = (date, time) => {
                // 日期和時間存在
                if (date && time) {
                    /**
                     * 取得新時間格式
                     * @param dateStr {String}
                     * @param timeStr {String}
                     * @return {String}
                     */
                    const getNewDateTime2String = (dateStr, timeStr) => {
                        const d = time2String(date, 'YYYY-MM-DD')
                        const hours = parseInt(timeStr.substring(0, 2), 10)
                        const minutes = parseInt(timeStr.substring(2, 4), 10)
                        return time2String(`${d} ${hours}:${minutes}:00`, 'YYYY-MM-DD HH:mm:ss')
                    }

                    return time ? getNewDateTime2String(date, time) : date
                }

                return ''
            }

            // 要分塊
            if (shouldChunk) {
                //走訪分塊
                chunkArray().forEach((item, index) => {
                    //檔案名稱
                    const fileName = `${exportFileName ? exportFileName : time2String(new Date(), 'YYYY-MM-DD HH-mm-ss')}-${index + 1}`
                    const sheets = {
                        // titles
                        titles: titles,
                        // data
                        data: []
                    }

                    //走訪分塊內資料
                    item.forEach(i => sheets.data.push([
                        // 申請單號
                        i.supportNo,
                        // 院區名稱
                        t(`campus.shortName.${i.zone}`),
                        // 申請人代號
                        i.createUser,
                        // 申請人姓名
                        i.createUserName,
                        // 支援醫師代號
                        i.supportUserNo,
                        // 支援醫師姓名
                        i.supportUserName,
                        // 支援機構代號
                        i.supportOrgNo,
                        // 支援機構名稱
                        i.supportOrgName,
                        // 支援科別代號
                        i.supportDivNo,
                        // 支援科別名稱
                        i.supportDivName,
                        // 開始日期 時間
                        getDateAndTime(i.itemStartDate, i.itemStartTime),
                        // 結束日期 時間
                        getDateAndTime(i.itemEndDate, i.itemEndTime),
                        // 狀態別
                        t(`RSIS.StepStatusEnum.${i.stepStatus}`),
                        // 支援變更狀態
                        i.supportModifyStatus && i.supportModifyStatus !== 0 ? t(`RSIS.SupportModifyStatusEnum.${i.supportModifyStatus}`) : '',
                        // 衛福部案號
                        i.sendCaseNo,
                        // 匯出編號
                        i.sendFileName,
                        // 備註
                        i.memo
                    ]))
                    //取得excel
                    const excel = exportExcelAsBlob([sheets])
                    //下載檔案
                    downloadFile(excel, fileName)
                })
            } else { //不分區塊
                //檔案名稱
                const fileName = exportFileName ? exportFileName : `${time2String(new Date(), 'YYYY-MM-DD HH-mm-ss')}`
                const sheets = {
                    // titles
                    titles: titles,
                    // data
                    data: []
                }

                exportItems.forEach(i => sheets.data.push([
                    // 申請單號
                    i.supportNo,
                    // 院區名稱
                    t(`campus.shortName.${i.zone}`),
                    // 申請人代號
                    i.createUser,
                    // 申請人姓名
                    i.createUserName,
                    // 支援醫師代號
                    i.supportUserNo,
                    // 支援醫師姓名
                    i.supportUserName,
                    // 支援機構代號
                    i.supportOrgNo,
                    // 支援機構名稱
                    i.supportOrgName,
                    // 支援科別代號
                    i.supportDivNo,
                    // 支援科別名稱
                    i.supportDivName,
                    // 開始日期 時間
                    getDateAndTime(i.itemStartDate, i.itemStartTime),
                    // 結束日期 時間
                    getDateAndTime(i.itemEndDate, i.itemEndTime),
                    // 狀態別
                    t(`RSIS.StepStatusEnum.${i.stepStatus}`),
                    // 支援變更狀態
                    i.supportModifyStatus && i.supportModifyStatus !== 0 ? t(`RSIS.SupportModifyStatusEnum.${i.supportModifyStatus}`) : '',
                    // 衛福部案號
                    i.sendCaseNo,
                    // 匯出編號
                    i.sendFileName,
                    // 備註
                    i.memo
                ]))
                //取得excel
                const excel = exportExcelAsBlob([sheets])
                //下載檔案
                downloadFile(excel, fileName)
            }
        }
    }

    /**
     * 匯出表格
     * @param exportType {String} 匯出類型，SAVE: 儲存匯出selecteItem的資料，ALL: 匯出全部資料
     * @return {void}
     */
    const handleExportForm = (exportType) => {

        switch (type) {
            //個案管理
            case 'RSISCaseManagementTable':
                // 彈出使用者輸入檔案名稱彈窗
                handleExportRSISCaseManagementTableExcel(exportType)
                break
            case 'RSISMasterQuery':
                handleExportRSISMasterQueryExcel()
                break
        }
    }

    /**
     * 儲存表單
     * @return {void}
     */
    const handleSaveForm = () => {
        // 儲存資料
        saveData(selectedItem, chunkArraySize)
    }

    /**
     * 取得模糊匹配後的資料陣列
     * @return {Array<Object>}
     */
    const getFilterData = () => fuzzySearchObjects(dataList, queryString)

    /**
     * 監聽是否準備好匯出
     * @return {void}
     */
    useEffect(() => {
        // 當準備好匯出時
        if (isReadyForExport) {
            // 處理匯出表格
            handleExportForm('SAVE')
            // 當handleExportForm執行完畢後
            handleSendCase(selectedItem)
            // // 重置準備好匯出狀態
            setIsReadyForExport(false)
        }
    }, [isReadyForExport]);

    /**
     * 當表格資料改變時，批次作業選項改變時，清空已選擇的資料
     * @return {void}
     */
    useEffect(() => {
        // 當表格資料改變時，清空已選擇的資料
        setSelectedItem([])
        setIsSelectAll(false)
    }, [dataList, batchSelected]);

    return (
        <div>
            <div className="flex justify-between items-center mb-2">
                {/* 進階搜尋或者條件選擇 */}
                {advancedSearch && (
                    <SearchTextField
                        value={queryString}
                        onChange={handleQueryOnChange}
                        placeholder={t('general.advancedSearch')}/>
                )}
                {header && header}
                {/* 儲存、匯出按鈕 */}
                <div className='flex flex-row gap-4'>
                    {/*儲存按鈕*/}
                    {
                        saveData && <Button
                            color={ButtonColorEnum.Primary} size={ButtonSizeEnum.Medium}
                            text={t('general.saveFile')} variant={ButtonVariantEnum.Contained}
                            onClick={handleSaveForm}/>
                    }
                    {/*匯出按鈕*/}
                    {
                        exportSlotBtn &&
                        <Button
                            color={ButtonColorEnum.Success} icon={IconEnum.Export} size={ButtonSizeEnum.Large}
                            text={t('general.export')} variant={ButtonVariantEnum.Text}
                            onClick={() => handleExportForm('ALL')}/>
                    }
                </div>
            </div>
            {/* 表格內容主體 */}
            <ul className={`tableList ${type}`}>
                <li key={0} className="title">
                    {/* 判斷是否有列表全選功能 */}
                    {
                        hasSelectAll ? (
                            <div>
                                <Checkbox
                                    checked={isSelectAll}
                                    onChange={handleSelectAll}
                                    disabled={batchSelected === null}
                                />
                            </div>
                        ) : null
                    }
                    {/* 表頭 */}
                    {
                        fields.map((item, index) => {
                            if (Array.isArray(item)) {
                                return (
                                    <div key={index}>
                                        <p className='w-full'>{item[0]}</p>
                                        <p className='w-full'>{item[1]}</p>
                                    </div>
                                )
                            } else {
                                return <div key={index}>{item}</div>;
                            }
                        })
                    }
                </li>
                {/* 列表資料 */}
                {
                    !arrayIsEmpty(getFilterData()) &&
                    Array.isArray(getFilterData()) &&
                    getFilterData().map((item, index) => (
                        <ListItem
                            key={index}
                            index={index}
                            item={item}
                            role={role}
                            isSelectAll={isSelectAll}
                            // 若有全選功能，則傳遞選擇及取消選擇回調函數給列表組件
                            {...(hasSelectAll && {
                                handleOnSelected: handleAddSelectedItem,
                                handleOnUnSelected: handleRemoveSelectedItem
                            })}
                            // 若有更新資料的回調函數，則傳遞給列表組件
                            {...(handleOnUpdateCallback && {
                                handleOnUpdate: handleOnUpdateCallback
                            })}
                            // 若有儲存列表項目的回調函數，則傳遞給列表組件
                            {...(handleOnListItemSave && {
                                onSave: handleOnListItemSave
                            })}
                            // 若有取消列表項目的回調函數，則傳遞給列表組件
                            {...(handleOnListItemCancel && {
                                onCancel: handleOnListItemCancel
                            })}
                            // 若有更新列表項目的回調函數，則傳遞給列表組件
                            {...(handleOnListItemChange && {
                                onChange: handleOnListItemChange
                            })
                            }
                            // 若有更新額外的下拉清單，則傳遞給列表組件
                            {...(extraDropDownListData && {
                                dropListData: extraDropDownListData
                            })
                            }
                        />
                    ))
                }
            </ul>
            <div className="flex justify-end mt-2">
                {/* <UsePagination
                  PageNum={paginationProps.pageNum}
                  PageSize={paginationProps.pageSize}
                  TotalItemSize={paginationProps.totalItemSize}
                  TotalPageSize={paginationProps.totalPageSize}
                  CurrentPageItemSize={paginationProps.currentPageItemSize}
                  CallbackFn={paginationProps.fn}
                /> */}
            </div>
        </div>
    );
}

export default CustomTable;
