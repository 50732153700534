import {arrayIsEmpty, convertMinGuoToGregorian, stringIsEmpty, time2String} from "edah_utils/dist"
import {t} from "i18next"

/**
 *  就醫資料門診處方箋
 *  @param {Array} data 資料
 *  @return {JSX.Element}
 */
export const PrescriptionNoteSection = ({data}) => {

    /**
     * 將就醫日期轉換成字串
     * @param encounterDate {string} 就醫日期
     * @return {string} 就醫日期字串
     */
    const convert2Date = (encounterDate) => {
        // 取得民國日期
        const date = encounterDate.slice(0, 7)
        // 取得時間
        const time = encounterDate.slice(7, encounterDate.length)
            //確保每兩個字元加一個冒號
            .match(/.{1,2}/g).join(':')
        return `${convertMinGuoToGregorian(date)} ${time}`
    }

    return !arrayIsEmpty(data) ? (
        <div className="prescriptionNote">
            <ul className="tableList prescriptionNote">
                <li className="title">
                    <div>就診日期時間</div>
                    <div>醫令類別</div>
                    <div>主要診斷名稱</div>
                    <div>診療部位</div>
                    <div>用法</div>
                    <div>天數</div>
                    <div>總量</div>
                    <div>交付處方註記</div>
                </li>
                {
                    data.map((item, index) =>
                        <li key={index}>
                            {/*就診日期時間*/}
                            <div>{time2String(item.encDatetime, "YYYY-MM-DD")}</div>
                            {/*醫令類別*/}
                            <div>{`${item.orderType}:${t(`ICCard.orderType.${item.orderType}`)}`}</div>
                            {/*主要診斷名稱*/}
                            <div>A006271100:Paran(永信理冒伯樂止痛)</div>
                            {/*診療部位*/}
                            <div>{stringIsEmpty(item.orderPos) ? "" : item.orderPos}</div>
                            {/*用法*/}
                            <div>{stringIsEmpty(item.useNo) ? "" : item.useNo}</div>
                            {/*天數*/}
                            <div>{stringIsEmpty(item.days) ? "" : item.days}</div>
                            {/*總量*/}
                            <div>{stringIsEmpty(item.tqty) ? "" : item.tqty}</div>
                            {/*交付處方註記*/}
                            <div>{`${item.giveOrder}:${t(`ICCard.giveOrder.${item.giveOrder}`)}`}</div>
                        </li>
                    )
                }
            </ul>
        </div>
    ) : null
}
