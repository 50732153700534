import {ApiErrorStatusEnum, arrayIsEmpty, downloadFile, exportExcelAsBlob, FileExtensionsEnum,} from 'edah_utils/dist'
import React, {useEffect, useMemo, useState} from 'react'
import CustomTable from '../../components/ReferralSystem/CustomTable'
import {STRATEGIC_ALLIANCE_MAINTAIN} from '../../constants/referralSystem/maintainAreaHeader'
import {Pagination} from '../../components/Pagination/Pagination'
import StrategicAllianceMaintainForm, {
    ModalStatus,
} from '../../components/ReferralSystem/StrategicAllianceMaintain/StrategicAllianceMaintainForm'
import {queryTranFederal} from '../../api/v1/Rmis'
import useToast from '../../hooks/useToast'
import {completePrintMode, updatePrintContent,} from '../../redux/Slice/printSlice'
import {useSelector} from 'react-redux'
import store from '../../redux/store'
import {FORM_TYPE} from '../../constants/referralSystem/print'
import {
    AlertTypeEnum,
    TextField,
    IconEnum,
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
} from "edah-component/dist";
import {t} from 'i18next'

const Layout = ({children}) => {
    return (
        <div className="p-2 w-full h-[calc(100vh-98px)] overflow-auto">
            {children}
        </div>
    )
}

/**
 * 策略聯盟維護
 * @return {JSX.Element}
 */
const StrategicAllianceMaintain = () => {
    // 表格相關選項
    const [dataList, setDataList] = useState([])
    const [filter, setFilter] = useState({
        totalPageSize: 1,
        totalItemSize: 0,
        pageNum: 1,
        pageSize: 10,
    })

    // 查詢條件
    const [keyword, setKeyword] = useState('')

    /**
     * 策略聯盟表單狀態
     */
    const [modalInfo, setModalInfo] = useState({
        show: false,
        modalStatus: ModalStatus.ADD,
    })
    // 編輯該列資料
    const [activeRow, setActiveRow] = useState({})

    // 列印模式
    const isPrintMode = useSelector((state) => state.print.isPrintMode)

    // 顯示 toast
    const showToast = useToast()

    // 查詢條件
    const handleKeywordChange = (e) => setKeyword(e.target.value)

    /**
     * 查詢按鈕
     * @return {void}
     */
    const handleClickQuery = () => {
        fetchTranFerderalList(1, filter.pageSize)
    }

    /**
     * 取得策略聯盟清單
     * @param pageNum {number} 頁碼
     * @param pageSize {number} 每頁筆數
     * @return {void}
     */
    const fetchTranFerderalList = (pageNum, pageSize) => {
        queryTranFederal({
            search: keyword,
            pageNum,
            pageSize,
        }).then((res) => {
            // 成功
            if (res.err === ApiErrorStatusEnum.Success) {
                // 清單資料預設未勾選
                const list =
                    res.data?.map((item) => {
                        return {
                            ...item,
                            isSelected: false,
                        }
                    }) || []
                // 查無資料
                if (arrayIsEmpty(list)) {
                    showToast({
                        message: '查無資料',
                        type: AlertTypeEnum.Warning,
                    })
                }
                setDataList(list)
                setFilter({
                    ...filter,
                    pageNum,
                    pageSize,
                    totalItemSize: res.totalItemSize,
                    totalPageSize: res.totalPageSize,
                })
            } else {
                // 失敗
                showToast({
                    message: res.msg,
                    type: AlertTypeEnum.Error,
                })
            }
        })
    }

    /**
     * 點選 pagination 頁碼
     * @param page {string} 目前頁碼
     * @return {void}
     */
    const handlePageOnChange = (page) => {
        const currentPage = Number(page)
        fetchTranFerderalList(currentPage, filter.pageSize)
    }

    /**
     * 變更每頁筆數
     * @param event {object}
     * @return {void}
     */
    const handlePageSizeChange = (event) => {
        const pageSize = Number(event.target.value)
        fetchTranFerderalList(1, pageSize)
    }

    /**
     * 點選上一頁
     * @return {void}
     */
    const handlePrevPageOnClick = () => {
        const prevPage = filter.pageNum - 1
        const page = prevPage < 1 ? filter.totalPageSize : prevPage
        fetchTranFerderalList(page, filter.pageSize)
    }

    /**
     * 點選下一頁
     * @return {void}
     */
    const handleNextPageOnClick = () => {
        const nextPage = filter.pageNum + 1
        const firstPage = 1
        const page = nextPage > filter.totalPageSize ? firstPage : nextPage
        fetchTranFerderalList(page, filter.pageSize)
    }

    /**
     * 表格中的編輯按鈕
     * @param tableRow {object} 要編輯的單筆資料
     * @return {JSX.Element}
     */
    const Edit = (tableRow) => (
        <div className="flex items-center justify-center">
            <Button
                icon={IconEnum.Edit}
                color={ButtonColorEnum.Primary}
                size={ButtonSizeEnum.Medium}
                variant={ButtonVariantEnum.Text}
                onClick={() => {
                    setActiveRow(tableRow)
                    setModalInfo({
                        show: true,
                        modalStatus: ModalStatus.EDIT,
                    })
                }}
                text={t('general.edit')}
                />
        </div>
    )

    /**
     * boolean 表格呈現
     * @param tableRow {object} 單筆資料
     * @param field {object}
     * @return {JSX.Element}
     */
    const BooleanDisplay = (tableRow, field) => (
        <div className="flex items-center justify-center">
            <div className="ml-1">{tableRow[field.key] ? '是' : '否'}</div>
        </div>
    )

    /**
     * 匯出Excel
     * @return {void}
     */
    const exportText = () => {
        // 檢查資料是否為空
        if (dataList.length === 0) {
            return
        }
        // 轉出清單
        const dataArray = []
        // 遍歷資料，將每筆資料轉成陣列
        dataList.forEach((item) => {
            const data = []
            renderFields.forEach((field) => {
                switch (field.key) {
                    case 'effectDate':
                    case 'endDate':
                        data.push(field.format(item[field.key])) // 時間格式
                        break
                    case 'federalFlag':
                    case 'communityFlag':
                    case 'supportFlag':
                        data.push(item[field.key] ? '是' : '否')
                        break
                    default:
                        data.push(item[field.key])
                }
            })
            dataArray.push(data)
        })
        // 將資料轉成 Excel sheet
        const sheets = [
            {
                titles: renderFields.map((item) => item.name),
                data: dataArray,
            },
        ]
        // 下載 Excel 檔案
        downloadFile(
            exportExcelAsBlob(sheets),
            '策略聯盟維護',
            FileExtensionsEnum.XLSX
        )
    }

    /**
     * 匯出Excel按鈕
     * @return {JSX.Element}
     */
    const exportExcelBtn = () => {
        return (
            <Button
                text={t('general.export')}
                icon={IconEnum.Export}
                color={ButtonColorEnum.Success}
                size={ButtonSizeEnum.Large}
                variant={ButtonVariantEnum.Text}
                onClick={exportText}
            />
        )
    }

    /**
     * 重設
     * @return {void}
     */
    const handleReset = () => {
        setModalInfo({
            modalStatus: ModalStatus.ADD,
            show: false,
        })
        setActiveRow({})
        handleClickQuery()
    }

    /**
     * 列印按鈕
     * @return {void}
     */
    const handlePrint = () => {
        // 列印單頁資料
        store.dispatch(
            updatePrintContent({
                reportType: FORM_TYPE.federalList,
                printData: dataList,
            })
        )
    }

    /**
     * 開啟列印模式則開啟瀏覽器列印視窗
     * 結束列印則關閉列印模式
     * @return {void}
     */
    useEffect(() => {
        if (isPrintMode) {
            window.print()
            store.dispatch(completePrintMode())
        }
    }, [isPrintMode])

    /**
     * 轉出清單 table header
     * @return {void}
     */
    const renderFields = useMemo(() => {
        STRATEGIC_ALLIANCE_MAINTAIN[0] = {
            ...STRATEGIC_ALLIANCE_MAINTAIN[0],
            render: Edit,
        }
        STRATEGIC_ALLIANCE_MAINTAIN[5] = {
            ...STRATEGIC_ALLIANCE_MAINTAIN[5],
            render: BooleanDisplay,
        }
        STRATEGIC_ALLIANCE_MAINTAIN[6] = {
            ...STRATEGIC_ALLIANCE_MAINTAIN[6],
            render: BooleanDisplay,
        }
        STRATEGIC_ALLIANCE_MAINTAIN[7] = {
            ...STRATEGIC_ALLIANCE_MAINTAIN[7],
            render: BooleanDisplay,
        }

            return STRATEGIC_ALLIANCE_MAINTAIN
    }, [])

    return (
        <Layout>
            <div className="flex justify-between p-2 items-center">
                <div className="flex items-center gap-2">
                    {/* 查詢條件 */}
                    <TextField
                        label="查詢條件"
                        value={keyword}
                        onChange={handleKeywordChange}
                    />
                    {/* 查詢按鈕 */}
                    <Button
                        sx={{fontWeight: 'bold', height: '2.5rem'}}
                        text={t('general.query')}
                        color={ButtonColorEnum.Primary}
                        size={ButtonSizeEnum.Medium}
                        variant={ButtonVariantEnum.Contained}
                        onClick={handleClickQuery}
                    />
                </div>
                <div>
                    {/* 列印按鈕 */}
                    <Button
                        sx={{fontWeight: 'bold', height: '2.5rem'}}
                        text={t('general.print')}
                        color={ButtonColorEnum.Primary}
                        size={ButtonSizeEnum.Medium}
                        variant={ButtonVariantEnum.Contained}
                        onClick={handlePrint}
                    />
                </div>
            </div>
            <div className="flex px-2 justify-between">
                {/* 新增按鈕 */}
                <Button
                    text={'新增內容'}
                    icon={IconEnum.Add}
                    color={ButtonColorEnum.Success}
                    size={ButtonSizeEnum.Medium}
                    variant={ButtonVariantEnum.Text}
                    onClick={() => {
                        setModalInfo({
                            modalStatus: ModalStatus.ADD,
                            show: true,
                        })
                    }}
                />
            </div>
            {/* 表格 */}
            <CustomTable
                isSelectable={false}
                fields={renderFields}
                dataList={dataList}
                slotButton={exportExcelBtn()}
            />
            {/* 分頁 */}
            <div className="flex justify-end">
                <Pagination
                    pageSize={filter.pageSize}
                    totalSize={filter.totalItemSize}
                    currentPage={filter.pageNum}
                    totalPageSize={filter.totalPageSize}
                    onPageOnChange={handlePageOnChange}
                    onPageSizeChange={handlePageSizeChange}
                    onPrevPageOnClick={handlePrevPageOnClick}
                    onNextPageOnClick={handleNextPageOnClick}
                />
            </div>
            {/* 策略聯盟表單 */}
            {modalInfo.show && (
                <StrategicAllianceMaintainForm
                    data={activeRow}
                    onClose={handleReset}
                    status={modalInfo.modalStatus}
                />
            )}
        </Layout>
    )
}

export default StrategicAllianceMaintain
