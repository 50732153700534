import { DATE_FORMAT } from '../common'
import { time2String } from 'edah_utils'
import i18n from '../../i18n'

/**
 * 不轉診原因報表清單table欄位
 */
export const NO_REFERRAL_REPORT_FIELDS = [
    {
        key: 'encounterDate',
        name: i18n.t('Rmis.pages.noReferralReport.tableHeader.encounterDate'), // 就醫日期
        format: (value) => time2String(value, DATE_FORMAT),
    },
    {
        key: 'encounterId',
        name: i18n.t('Rmis.pages.noReferralReport.tableHeader.encounterId'), // 批價序號
    },
    {
        key: 'patientId',
        name: i18n.t('Rmis.pages.noReferralReport.tableHeader.patientId'), // 病歷號
    },
    {
        key: 'patientName',
        name: i18n.t('Rmis.pages.noReferralReport.tableHeader.patientName'), // 姓名
    },
    {
        key: 'notTranBackReason',
        name: i18n.t('Rmis.pages.noReferralReport.tableHeader.notTranBackReason'), // 不轉診原因
    },
    {
        key: 'doctorName',
        name: i18n.t('Rmis.pages.noReferralReport.tableHeader.doctorName'), // 輸入醫師
    },
]
