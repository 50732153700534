import { IconEnum, SizeEnum, TextField } from 'edah-component/dist'

/**
 * 搜尋輸入框
 * @param onChange {Function} 搜尋文字改變時觸發
 * @param placeholder {String} 提示文字
 * @param onKeyDown {Function|Null} 鍵盤按鍵按下事件
 * @param size {SizeEnum} 大小
 * @param inputWidth {SizeEnum} 輸入框寬度
 * @param label {String} 標籤
 * @param value {String} 值
 */
export const SearchTextField = ({
    onChange,
    placeholder,
    onKeyDown,
    size,
    inputWidth,
    label,
    value,
    ...props
}) => (
    <TextField
        startAdornment={IconEnum.Search}
        placeholder={placeholder}
        size={size}
        inputWidth={inputWidth}
        label={label}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        {...props}
    />
)
