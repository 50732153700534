import { useSelector } from 'react-redux'
import A4Print from './A4Print'

/**
 * 長照中心維護 - 列印內容
 * @returns {JSX.Element}
 */
const PrintLongTermCareListContent = () => {
    const printData = useSelector((state) => state.print.printData)
    return (
        <>
            <h1 className="text-xl font-bold text-center">長照中心維護</h1>
            <table className="w-full border-collapse text-sm border-t border-[#111111]/15">
                <thead>
                    <tr className="h-[40px] text-[#18181b]">
                        <th className="w-[100px] text-left break-words p-1">
                            院所代碼
                        </th>
                        <th className="w-[100px] text-left break-words p-1">
                            長期照護單位名稱
                        </th>
                        <th className="w-[100px] text-left break-words p-1">
                            郵遞區號
                        </th>
                        <th className="w-[100px] text-left break-words p-1">
                            地址
                        </th>
                        <th className="w-[100px] text-left break-words p-1">
                            電話
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {printData.map((item, index) => {
                        return (
                            <tr
                                key={index}
                                className="h-[50px] text-[#18181b] "
                            >
                                <td className="text-left break-words p-1">
                                    {item.hospNo}
                                </td>
                                <td className="text-left break-words p-1">
                                    {item.hospName}
                                </td>
                                <td className="text-left break-words p-1">
                                    {item.hospZipCode}
                                </td>
                                <td className="text-left break-words p-1">
                                    {item.hospAddress}
                                </td>
                                <td className="text-left break-words p-1">
                                    {item.hospTel}
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </>
    )
}

/**
 * 長照中心維護 - 列印
 * @returns {JSX.Element}
 */
const PrintLongTermCareList = () => {
    return (
        <A4Print>
            <PrintLongTermCareListContent />
        </A4Print>
    )
}
export default PrintLongTermCareList
