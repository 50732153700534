import React, {useState} from 'react'
import {ApiErrorStatusEnum, RsisDocTypeEnum, stringIsEmpty} from 'edah_utils/dist'
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {usePopup} from "../PopupProvider";
//import api
import {rsisEditFunctionDoc} from '../../../api/v1/RSIS'
import {t} from "i18next"
import {AlertTypeEnum, Dialog, DialogVariant, Field, Select, SelectWidthEnum} from "edah-component/dist";

/**
 * 報備支援-功能操作說明維護彈出視窗
 * @param data {Object} 父層級帶入的顯示資料
 * @param handleOnClose {function} 彈出視窗關閉事件
 * @param handleOnUpdate {function} 彈出視窗更新事件
 * @return {JSX.Element}
 */
const FunctionOperateIntroMaintain = ({
                                          data,
                                          handleOnClose,
                                          handleOnUpdate
                                      }) => {
    //Show Toast
    const {showToast} = usePopup()
    // 功能ID
    const [funNo, setFunNo] = useState(data.funNo)
    // 功能名稱
    const [functionName, setFunctionName] = useState(data.funcName)
    // 類別
    const [docType, setDocType] = useState(stringIsEmpty(data.docType) ? RsisDocTypeEnum.StandardDescription : data.docType)
    // 有效否
    const [valid, setValid] = useState(data.effFlag !== null ? (data.effFlag ? 1 : 0) : 0)
    // CKEditor內容
    const [editorData, setEditorData] = useState(data.htmlContent)
    // Lock version
    const [lockVersion, setLockVersion] = useState(data.lockVersion)

    /**
     * 存檔按鈕點擊事件
     * @return {void}
     * */
    const handleOnSave = () => editFunctionDoc()

    /**
     * CKEditor改變時
     * @param event {Event}
     * @param editor {TEditor}
     * @return {void}
     */
    const handleCKEditorOnChange = (event, editor) => {
        const data = editor.getData()
        setEditorData(data)
    }

    /**
     * 彈窗關閉時
     * @return {void}
     */
    const handlePopupCloseButtonOnClick = () => handleOnClose(false)

    /**
     * 類別改變時
     * @param value {String} 類別
     * @return {void}
     */
    const handleDocTypeInputOnChange = (value) => setDocType(value)

    /**
     * 有效否改變時
     * @param value {Number} 有效否
     */
    const handleValidSelectOnChange = (value) => setValid(value)

    /**
     * 修改/儲存功能操作說明維護
     * @return {void}
     */
    const editFunctionDoc = () => {
        rsisEditFunctionDoc([{
            // Lock version
            lockVersion: lockVersion,
            // 功能代號
            funNo: funNo,
            // 說明類型
            docType: docType,
            // 內容
            htmlContent: editorData,
            // 有效否
            effFlag: valid === 1,
        }]).then(res => {
            // 狀態 / 訊息
            const {err, msg} = res
            // 取得成功
            if (err === ApiErrorStatusEnum.Success) {
                //顯示提示訊息
                showToast({message: msg})
                // 更新主頁面資料
                handleOnUpdate()
                // 關閉彈窗
                handlePopupCloseButtonOnClick()
            } else { // 取得失敗
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    return (
        <Dialog
            open={true}
            title={t('RSIS.Popup.FunctionOPMaintain')}
            content={
                <div className='py-4'>
                    <div className='flex flex-col space-y-2'>
                        {/*第一列*/}
                        <div className='grid grid-cols-2'>
                            {/*功能ID*/}
                            <Field label='功能ID'>
                                {funNo}
                            </Field>
                            {/*有效否*/}
                            <Field label="有效否">
                                <Select
                                    data={{
                                        label: '',
                                        options: [
                                            {label: t('general.yes'), value: 1},
                                            {label: t('general.no'), value: 0}
                                        ]
                                    }}
                                    value={valid}
                                    width={SelectWidthEnum.Medium}
                                    onChange={handleValidSelectOnChange}
                                />
                            </Field>
                        </div>
                        {/*第二列*/}
                        <div className='grid grid-cols-2'>
                            {/*功能名稱*/}
                            <Field label='功能名稱'>
                                {functionName}
                            </Field>
                            {/*類別*/}
                            <Field label="類別">
                                <Select
                                    data={{
                                        label: '',
                                        options: [
                                            {label: 'A.常態說明', value: RsisDocTypeEnum.StandardDescription},
                                            {label: 'B.特別說明', value: RsisDocTypeEnum.SpecialDescription}
                                        ]
                                    }}
                                    value={docType}
                                    width={SelectWidthEnum.Medium}
                                    onChange={handleDocTypeInputOnChange}
                                />
                            </Field>
                        </div>
                        {/*第三列*/}
                        {/*操作說明*/}
                        <Field label='操作說明' sx={{alignItems: 'start'}}>
                            <div className='h-[400px] overflow-y-scroll w-[450px]'>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={editorData}
                                    config={{
                                        // 移除圖片上傳功能
                                        removePlugins: [
                                            'ImageUpload',
                                            'EasyImage',
                                            'CKFinder',
                                            'CKFinderUploadAdapter',
                                            'ImageToolbar',
                                            'ImageCaption',
                                            'ImageStyle',
                                            'ImageResize'
                                        ],
                                    }}
                                    onChange={(event, editor) => handleCKEditorOnChange(event, editor)}/>
                                <style>
                                    {`
                                        .ck-editor__editable_inline {
                                              height: 300px;
                                         }
                                        .ck-toolbar {
                                            display: flex;
                                        }

                                        .ck-toolbar .ck-toolbar__group:nth-child(3) {
                                            margin-left: auto; /* 將 Show More 或其他按鈕靠右 */
                                        }
                                    `}
                                </style>
                            </div>
                        </Field>
                    </div>
                </div>
            }
            onClose={handlePopupCloseButtonOnClick}
            onSave={handleOnSave}
            variant={DialogVariant.EDIT}
            paperStyleProps={{width: '720px'}}
            muiDialogContentProps={{dividers: true}}
        />
    )
}

export default FunctionOperateIntroMaintain
