import React, {useEffect, useState} from "react";
import {t} from "i18next";
import {queryPatientByField} from "../../../api/v1/Mris";
import {ApiErrorStatusEnum, arrayIsEmpty, objectIsEmpty, stringIsEmpty, time2String,} from "edah_utils/dist";
import {
    Button,
    ButtonSizeEnum,
    ButtonVariantEnum,
    ColorEnum,
    Dialog, DialogSizeEnums,
    DialogVariant,
    Select,
    Pagination,
    AdvancedDataGrid,
    TextField,
    Card
} from "edah-component/dist";
import {SearchTextField} from "../../SearchTextField/SearchTextField";

/**
 *  病人病歷資料彈出視窗
 *  @param closePopupButtonOnClick {Function} 關閉按鈕點擊事件
 *  @param show {Boolean} 是否顯示彈窗
 *  @param choicePatientDate {Function} 選擇病人病歷事件
 *  @param ptSearchOptionList {Array} 查詢的欄位清單
 *  @param importFindedData {Object} 傳入找到的資料
 *  @return {JSX.Element} 病人病歷資料彈出視窗
 */
export const PatientMedicalRecordsPopup = ({
                                               closePopupButtonOnClick,
                                               show = false,
                                               choicePatientDate,
                                               ptSearchOptionList,
                                               importFindedData,
                                           }) => {
    /**
     * 是否顯示彈窗
     */
    const [showPopup, setShowPopup] = useState(show);

    /**
     * 搜尋文字狀態
     */
    const [advancedSearchText, setAdvancedSearchText] = useState("");

    /**
     * 病人們資料
     */
    const [arrayData, setArrayData] = useState([]);
    // 病人資訊清單進階搜尋結果array data
    const [advSearchArrayData, setAdvSearchArrayData] = useState([]);
    //查詢欄位資料(已查詢後)
    const [queryInputData, setQueryInputData] = useState({
        //搜尋文字
        text: "",
        //查詢欄位
        field: "",
    });
    //查詢欄位資料
    const [queryInputTmpData, setQueryInputTmpData] = useState({
        text: "",
        field: "",
    });

    // pagination控制變數
    const [paginationProps, setPaginationProps] = useState({
        //當前頁碼
        currentPage: 1,
        //每頁資料筆數
        pageSize: 10,
        //總資料筆數
        totalItemSize: 0,
        //總頁碼
        totalPageSize: 0,
    });

    // 表頭資料
    const patientDataHeader = [
        {field: 'patientId', headerName: '病歷號', flex: 1},
        {field: 'patientName', headerName: '姓名', flex: 1},
        {
            field: 'birthDate',
            headerName: '出生日期',
            flex: 1,
        },
        {field: 'idNo', headerName: '身分證號', flex: 1},
        {field: 'phoneNo', headerName: '手機號', flex: 1},
    ]

    /**
     * 搜尋文字輸入事件
     * @param e {Event} 事件
     * @return {void}
     */
    const onSearchTextOnInputChange = (e) => {
        setQueryInputTmpData({
            ...queryInputTmpData,
            text: e.target.value,
        });
    };

    /**
     * 搜尋文字鍵盤按鈕按下事件
     * @param e {Event} 事件
     * @return {void}
     */
    const onSearchTextOnInputKeyDown = (e) => {
        if (e.key === "Enter") {
            //搜尋Patient
            if (stringIsEmpty(queryInputTmpData.text)) return;

            queryPatient(queryInputTmpData, 1, paginationProps.pageSize);
        }
    };

    /**
     * 進階搜尋文字輸入事件
     * @param e {Event} 事件
     * @return {void}
     */
    const onAdvancedSearchTextOnInputChange = (e) =>
        setAdvancedSearchText(e.target.value);

    /**
     * 頁碼變更事件
     * @param page {Number} 頁碼
     */
    const onPaginationPageOnChange = (page, pageSize) => {
        queryPatient(queryInputData, page, pageSize);
    };

    /**
     * 每頁資料筆數變更事件
     * @param e {Event} 事件
     */
    const onPaginationPageSizeOnChange = (e) => {
        // 設定每頁資料筆數
        queryPatient(queryInputData, 1, e.target.value);
    };

    /**
     * 上一頁點擊事件
     */
    const onPaginationPreviousOnClick = () => {
        const page =
            paginationProps.currentPage - 1 > 1
                ? paginationProps.currentPage - 1
                : 1;
        queryPatient(queryInputData, page, paginationProps.pageSize);
    };

    /**
     * 下一頁點擊事件
     */
    const onPaginationNextOnClick = () => {
        const page =
            paginationProps.currentPage + 1 < paginationProps.totalPageSize
                ? paginationProps.currentPage + 1
                : paginationProps.totalPageSize;
        queryPatient(queryInputData, page, paginationProps.pageSize);
    };

    /**
     * 選擇病人病歷
     * @param patient {Object} 病人病歷資訊
     */
    const handlePatientItemOnClick = (patient) => {
        choicePatientDate(patient);
    };

    /**
     * 處理 DataGrid 的行點擊事件
     * @param params {Object} - 行參數
     */
    const handleRowClick = (params) => {
        const patientData = params.row;
        handlePatientItemOnClick(patientData);
    };

    /**
     * 欄位下拉選單改變時
     * @param value {String}
     */
    const handleFieldOnChange = (value) => {
        setQueryInputTmpData({
            ...queryInputTmpData,
            field: value,
        })
    }

    /**
     * 查詢Patient
     * @param queryInputObj {Object} 查詢的資料
     * @param page {Number} 第幾頁
     * @param pageSize {Number} 每頁筆數
     */
    const queryPatient = (queryInputObj, page, pageSize) => {
        //把暫存的查詢條件寫入到真實的查詢條件
        setQueryInputData(queryInputObj);

        queryPatientByField({
            search: queryInputObj.text,
            field: queryInputObj.field,
            pageNum: page,
            pageSize: pageSize,
        }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                //設定取得到病人病歷list，並變更生日格式
                setArrayData(res.data.dataList?.map(item => ({
                    ...item,
                    birthDate: time2String(item.birthDate, "YYYY-MM-DD")
                })) ?? []);
                setPaginationProps({
                    ...paginationProps,
                    totalItemSize: res.data.totalItemSize,
                    totalPageSize: res.data.totalPageSize,
                    currentPage: page,
                    pageSize: pageSize,
                });
            } else {
                setArrayData([]);
                setPaginationProps({
                    ...paginationProps,
                    totalItemSize: 0,
                    totalPageSize: 0,
                    pageSize: pageSize,
                });
            }
        });
    };

    /**
     * 查詢按鈕點擊事件
     */
    const handleSearchOnClick = () => {
        if (stringIsEmpty(queryInputTmpData.text)) return;
        queryPatient(queryInputTmpData, 1, paginationProps.pageSize);
    };

    /**
     * 取得病人進階搜尋後的結果
     * @returns
     */
    const getAdvSearchPatientList = () => {
        //如果搜尋文字為空
        if (stringIsEmpty(advancedSearchText)) {
            return arrayData;
        } else {
            //如果搜尋文字不為空
            const lowerCaseSearchText = advancedSearchText.toLowerCase();
            return arrayData.filter((data) => {
                return (
                    (data.patientId !== null &&
                        data.patientId
                            .toString()
                            .toLowerCase()
                            .includes(lowerCaseSearchText)) ||
                    (data.patientName !== null &&
                        data.patientName
                            .toString()
                            .toLowerCase()
                            .includes(lowerCaseSearchText)) ||
                    (data.idNo !== null &&
                        data.idNo
                            .toString()
                            .toLowerCase()
                            .includes(lowerCaseSearchText)) ||
                    (data.birthDate !== null &&
                        time2String(data.birthDate, "YYYYMMDD").includes(
                            lowerCaseSearchText
                        ))
                );
            });
        }
    };

    /**
     * 取得內容
     * @return {JSX.Element}
     */
    const getContent = () => (
        <>
            <div className="filter flex flex-row pt-2 mb-2.5 items-center justify-start space-x-2">
                {/* 搜尋條件 */}
                <Select
                    data={{
                        label: "搜尋條件",
                        options:
                            ptSearchOptionList?.map((item) => ({
                                label: item.justnameName,
                                value: item.justnameNo,
                            })) || [],
                    }}
                    value={queryInputTmpData.field}
                    onChange={handleFieldOnChange}
                    notched={true}
                    displayEmpty = {true}
                />
                {/*搜尋文字*/}
                <TextField
                    sx={{mr: '10px'}}
                    value={queryInputTmpData.text}
                    onChange={(e) => onSearchTextOnInputChange(e)}
                    onKeyDown={(e) => onSearchTextOnInputKeyDown(e)}
                />
                {/*查詢按鈕*/}
                <Button color={ColorEnum.Primary} variant={ButtonVariantEnum.Contained}
                        size={ButtonSizeEnum.Medium}
                        onClick={handleSearchOnClick}>
                    {t("general.query")}
                </Button>
            </div>
            <Card>
                {/*進階搜尋*/}
                <div className="mb-2">
                    <SearchTextField
                        value={advancedSearchText}
                        placeholder={t("general.advancedSearch")}
                        onChange={(e) => onAdvancedSearchTextOnInputChange(e)}
                    />
                </div>
                {/*表單*/}
                <AdvancedDataGrid
                    rows={advSearchArrayData}
                    columns={patientDataHeader}
                    checkboxSelection={false}
                    disableRowSelectionOnClick
                    disableColumnMenu={true}
                    height={'600px'}
                    onRowClick={handleRowClick}
                    getRowId={(row) => row.patientId}
                    getRowHeight={(params) => 56}
                />
                <div className="flex justify-end w-full">
                    <Pagination
                        totalPageSize={paginationProps.totalPageSize}
                        page={paginationProps.currentPage}
                        pageSize={paginationProps.pageSize}
                        totalSize={paginationProps.totalItemSize}
                        onPageOnChange={(page, pageSize) =>
                            onPaginationPageOnChange(page, pageSize)
                        }
                        showFirstButton
                        showLastButton
                    />
                </div>
            </Card>

        </>
    );

    /**
     * 監聽是否顯示彈窗Pros變化
     */
    useEffect(() => {
        // 顯示彈窗時重置頁碼
        if (!show) {
            // 重置查詢欄位
            if (!arrayIsEmpty(ptSearchOptionList)) {
                setQueryInputTmpData({
                    ...queryInputTmpData,
                    field: ptSearchOptionList[0].justnameNo,
                });
                setQueryInputData({
                    ...queryInputData,
                    field: ptSearchOptionList[0].justnameNo,
                });
            }
            // 重置查詢文字
            setQueryInputTmpData({
                ...queryInputTmpData,
                text: "",
            });
            setQueryInputData({
                ...queryInputData,
                text: "",
            });
        } else {
            //重置進階搜尋文字
            setAdvancedSearchText("");
            setPaginationProps({
                ...paginationProps,
                currentPage: 1,
                totalItemSize: 0,
                totalPageSize: 0,
            });
            setQueryInputData({});
        }
        setShowPopup(show);
    }, [show]);

    /**
     * 監聽ptSearchOptionList 變化時
     */
    useEffect(() => {
        if (!arrayIsEmpty(ptSearchOptionList)) {
            setQueryInputTmpData({
                ...queryInputTmpData,
                field: ptSearchOptionList[0].justnameNo,
            });
        }
    }, [ptSearchOptionList]);

    /**
     * 監聽 importFindedData Pros變化
     */
    useEffect(() => {
        if (objectIsEmpty(importFindedData)) {
            setArrayData([]);
        } else {
            if (arrayIsEmpty(importFindedData.dataList)) {
                setArrayData([]);
            } else {
                //將傳入的病人病歷list設定給arrayData，並變更生日格式
                setArrayData(importFindedData.dataList?.map(item => ({
                    ...item,
                    birthDate: time2String(item.birthDate, "YYYY-MM-DD")
                })) ?? []);

                //設定分頁狀態
                setPaginationProps({
                    //當前頁碼
                    currentPage: importFindedData.currentPage,
                    //每頁資料筆數
                    pageSize: importFindedData.pageSize,
                    //總資料筆數
                    totalItemSize: importFindedData.totalItemSize,
                    //總頁碼
                    totalPageSize: importFindedData.totalPageSize,
                });

                //用於顯示的欄位值
                setQueryInputTmpData({
                    //搜尋文字
                    text: importFindedData.searchText,
                    //查詢欄位
                    field: "ALL",
                });

                //用於紀錄真正查詢的欄位值
                setQueryInputData({
                    //搜尋文字
                    text: importFindedData.searchText,
                    //查詢欄位
                    field: "ALL",
                });
            }
        }
    }, [importFindedData]);

    /**
     * 監聽arrayData & advancedSearchText變化
     */
    useEffect(() => {
        if (arrayData !== null) {
            const list = getAdvSearchPatientList();
            setAdvSearchArrayData(list);
        } else {
            setAdvSearchArrayData(null);
        }
    }, [arrayData, advancedSearchText]);

    return (
        <Dialog
            open={showPopup}
            variant={DialogVariant.EDIT}
            paperStyleProps={{width: DialogSizeEnums.LG}}
            title={"病人病歷資料"}
            content={getContent()}
            onClose={closePopupButtonOnClick}
            muiDialogActionsProps={{sx: {display: 'none'}}}
        />
    )
};
