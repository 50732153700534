import React, {useEffect, useState} from "react"
import {t} from "i18next"
import {
    Button,
    ButtonSizeEnum,
    ButtonVariantEnum,
    ColorEnum,
    Dialog,
    DialogSizeEnums,
    DialogVariant
} from 'edah-component/dist'

/**
 * 刪除提示彈窗
 * @param {Boolean} show 是否顯示
 * @param {Function} closePopupButtonOnClick 關閉按鈕點擊事件
 * @param {Function} handleDeleteOnClick 刪除事件
 * @returns
 */
const DeletePromptPopup = ({
                               show = false,
                               closePopupButtonOnClick,
                               handleDeleteOnClick,
                               message
                           }) => {
    /**
     * 是否顯示彈窗
     */
    const [showPopup, setShowPopup] = useState(show);

    /**
     * 監聽是否顯示彈窗Pros變化
     */
    useEffect(() => {
        // 設定是否顯示彈窗狀態
        setShowPopup(show)
    }, [show])

    /**
     * 取得內容
     * @return {JSX.Element}
     */
    const getContent = () => (
        <>
            <div className="text-left px-6 pt-2 text-lg">
                {message}
            </div>
            <div className="text-left px-6 pb-8 pt-4 text-lg border-b-[1px] border-[rgba(0,0,0,0.15)]">
                是否確定刪除?
            </div>

            <div className="flex py-4 px-6 space-x-3 justify-end">
                <Button
                    color={ColorEnum.Primary}
                    variant={ButtonVariantEnum.Outlined}
                    size={ButtonSizeEnum.Medium}
                    onClick={closePopupButtonOnClick}>
                    {t('general.no')}
                </Button>
                <Button
                    color={ColorEnum.Primary}
                    variant={ButtonVariantEnum.Contained}
                    size={ButtonSizeEnum.Medium}
                    onClick={handleDeleteOnClick}>
                    {t('general.yes')}
                </Button>
            </div>
        </>
    )

    return (
        <Dialog
            open={showPopup}
            title={"提醒"}
            content={"是否確定刪除?"}
            variant={DialogVariant.DELETE}
            paperStyleProps={{width: DialogSizeEnums.SM}}
            onClose={closePopupButtonOnClick}
            onDelete={handleDeleteOnClick}
        />

    )
}

export default DeletePromptPopup
