import {createSlice} from '@reduxjs/toolkit'

export const tab = createSlice({
    name: 'tab',
    initialState: {
        /**
         * 分頁列表
         */
        tabList: [],
        /**
         * 當前分頁ID
         */
        activeTabId: null
    },
    reducers: {
        /**
         * 更新分頁列表
         * @param state {Object}
         * @param action {Object}
         * @param action.payload {Array}
         * @param action.payload.id {String}
         * @param action.payload.name {String}
         * @return {void}
         */
        updateTabList(state, action) {
            state.tabList = action.payload
        },
        /**
         * 新增分頁
         * @param state {Object}
         * @param action {Object}
         * @param action.payload {Object}
         * @param action.payload.id {String}
         * @param action.payload.name {String}
         * @return {void}
         */
        addTab(state, action) {
            const {name, id} = action.payload
            //Tab是否存在
            const isTabExists = state.tabList.some((tab) => tab.id === id)
            //Tab不存在
            if (!isTabExists) {
                //超過6，移除第一個
                if (state.tabList.length >= 6) {
                    // 找到第一個不是 activeTabId 的元素索引
                    const indexToRemove = state.tabList.findIndex(tab => tab.id !== state.activeTabId)
                    // 移除第一個不是 activeTabId 的元素
                    const newTabList = state.tabList.splice(indexToRemove, 1)
                    // 設置新的 tabList
                    updateTabList(newTabList)
                }

                //新增Tab
                state.tabList = [...state.tabList, {id, name}];
            }
            state.activeTabId = id;
        },
        /**
         * 設置當前分頁ID
         * @param state {Object}
         * @param action {Object}
         * @param action.payload {Object}
         * @param action.payload.id {String}
         * @return {void}
         */
        setActiveTabId(state, action) {
            state.activeTabId = action.payload.id
        }
    },
})
export const {updateTabList, addTab, setActiveTabId} = tab.actions
export default tab.reducer
