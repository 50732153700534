import React, { useEffect, useState } from 'react'
import { time2String, objectIsEmpty, ApiErrorStatusEnum } from 'edah_utils/dist'
import ReferralHospitalModal from '../ReferralHospitalModal'
import { DATE_FORMAT } from '../../../constants/common'
import { t } from 'i18next'
import { queryTranTypeByEncounterId } from '../../../api/v1/Rmis'
import { FORM_TYPE } from '../../../constants/referralSystem/print'
import {
    completePrintMode,
    updatePrintContent,
} from '../../../redux/Slice/printSlice'
import store from '../../../redux/store'
import { useSelector } from 'react-redux'
import { getBirthDayAndDetailedAge } from '../utils'
import {
    Divider,
    Field,
    SizeEnum,
    TextArea,
    TextField,
    AlertTypeEnum,
    Checkbox,
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    Dialog,
    DialogVariant,
    Select,
    DatePicker,
    Search,
    SelectWidthEnum
} from 'edah-component/dist'
import dayjs from "dayjs"
import useToast from '../../../hooks/useToast'

/**
 * 轉診原因: 6.其他
 */
const TRAN_REASON_OTHERS = '6'

/**
 * 轉診單個管作業_維護視窗
 * @param {object} props
 * @param {function} props.onClose 關閉視窗
 * @param {function} props.onConfirm 存檔
 * @param {object} props.editCase 開啟視窗時傳入個案的資料
 * @param {array} props.doctorList 醫師清單
 * @param {array} props.divisionList 科別清單
 * @param {array} props.outStatusList 轉診追蹤狀態
 * @param {array} props.zipCodeList 高雄地區下拉選單
 * @return {JSX.Element}
 */
const TranOutCaseModal = ({
    onClose,
    onConfirm,
    editCase,
    doctorList = [],
    divisionList = [],
    outStatusList = [],
    tranReasonList = [],
    zipCodeList = [],
}) => {
    // show toast
    const showToast = useToast()
    let initialValue = {
        encounterDate: '',
        // TODO:因為要門診資料但phase1沒有門診，待api確定
        payFeeNo: '', // FIXME:批價代碼
        patientId: '', // 病歷號碼
        patientName: '', // 病人姓名
        genderName: '', // 性別
        birthDate: '', // 生日
        idNo: '', // 身分證號
        divNo: '', // 就醫科別代碼
        divName: '', // 就醫科別
        doctorNo: '', // 醫師代碼
        doctorName: '', // 醫師
        treatmentCode: '', // 診斷代碼
        treatmentName: '', // 診斷名
        treatmentCode1: '', // 診斷代碼1
        treatmentName1: '', // 診斷名1
        treatmentCode2: '', // 診斷代碼2
        treatmentName2: '', // 診斷名2
        tranOutHospNo: '', // 轉出院所代碼
        tranOutHospName: '', // 轉出院所
        tranOutHospGradeName: '', // 轉出院所層名稱
        tranDate: '', // 轉診日期
        tranDoctorNo: '', // 轉診醫師代碼
        tranDoctorName: '', // 轉診醫師
        tranReason: '', // 轉診原因
        tranReasonRemark: '', // 轉診原因備註
        tranOutDivNo: '', // 轉診科別代碼
        tranOutDivName: '', // 轉診科別
        outRealOutHospNo: '', // 實際轉出院所代碼
        outRealOutHospName: '', // 實際轉出院所
        outRealOutDivNo: '', // 實際轉出科別代碼
        outRealOutDivName: '', // 實際轉出科別
        outReplyDate2: '', // 接受回覆日期(病歷摘要)
        outReplyDate: '', // 接受對方回覆日期(初步)
        outReturnDate: '', // 轉診單回收日期
        dischargeDate: '', // 出院日期
        admissionDate: '', // 住院日期
        outCancerFlag: '', // 癌症篩檢
        outStatus: '', // 轉診追蹤狀態
        outRemark: '', // 備註
    }

    // 補列印轉診單 - 列印數量
    const [printSetting, setPrintSetting] = useState({
        copies: 1,
        couplet: 2,
    })

    // 取得列印模式
    const isPrintMode = useSelector((state) => state.print.isPrintMode)

    // 轉診單資料
    const [caseDetail, setCaseDetail] = useState(initialValue)

    // 是否開啟轉診院所查詢視窗
    const [isShowReferralHospitalModal, setIsShowReferralHospitalModal] =
        useState(false)

    /**
     * 更新轉診單資料
     * @param {event} e
     * @param {string} field 欄位名稱
     * @return {void}
     */
    const updateCaseDetail = (e, field) => {
        switch (field) {
            // 癌症篩檢
            case 'outCancerFlag': {
                const value = e.target.checked
                setCaseDetail({
                    ...caseDetail,
                    outCancerFlag: value,
                })
                break
            }
            // 轉診追蹤狀態
            case 'outStatus': {
                setCaseDetail({
                    ...caseDetail,
                    outStatus: e
                })
                break
            }
            default:
                const value = e.target.value
                setCaseDetail({
                    ...caseDetail,
                    [field]: value,
                })
        }
    }

    /**
     * 日期改變時通用處理
     * @param {Dayjs | null} newValue - 選中的新日期
     * @param {string} field - caseDetail 中的字段名稱
     * @return {void}
     */
    const handleDateChange = (newValue, field) => {
        const formattedDate = newValue ? newValue.format('YYYY-MM-DD') : ''
        setCaseDetail({
            ...caseDetail,
            [field]: formattedDate // 使用動態字段名來更新相應的日期
        })
    }

    // TODO: 列印流程尚未確定
    /**
     * 列印
     * @param {event} e
     * @param {*} field
     * @return {void}
     */
    const updatePrintSetting = (e, field) => {
        const value = e.target.value
        setPrintSetting({ ...printSetting, [field]: value })
    }

    /**
     * 存檔
     * @return {void}
     */
    const handleOnSave = () => {
        onConfirm(caseDetail)
    }

    /**
     * 帶入院所及科別
     * 將tranOutHosp tranOutDiv帶入 outRealOutHosp, outRealOutDiv
     * @return {void}
     */
    const populateWithtranOutHospDiv = () => {
        setCaseDetail((prev) => {
            const newValue = {
                ...prev,
                outRealOutHospNo: prev.tranOutHospNo,
                outRealOutHospName: prev.tranOutHospName,
                outRealOutDivNo: prev.tranOutDivNo,
                outRealOutDivName: prev.tranOutDivName,
            }
            return newValue
        })
    }

    /**
     * 模糊搜尋選單-選擇的轉診科別
     * @param {object} doctor
     * @return {void}
     */
    const selectTranOutDivision = (division) => {
        setCaseDetail((prev) => ({
            ...prev,
            tranOutDivNo: division ? division.divNo : '',
            tranOutDivName: division ? division.divName : '',
        }))
    }

    /**
     * 模糊搜尋選單-選擇的醫師
     * @param {object} doctor
     * @return {void}
     */
    const selectTranOutDoctor = (doctor) => {
        setCaseDetail((prev) => ({
            ...prev,
            tranDoctorNo: doctor ? doctor.doctorNo : '',
            tranDoctorName: doctor ? doctor.doctorName : '',
        }))
    }

    /**
     * 開啟院所查詢視窗
     * @return {void}
     */
    const showReferralHospital = () => {
        setIsShowReferralHospitalModal(true)
    }

    /**
     * 選擇院所
     * @return {void}
     */
    const onSelectHospital = (hospital) => {
        if (objectIsEmpty(hospital)) {
            setIsShowReferralHospitalModal(false)
            return
        }

        setCaseDetail({
            ...caseDetail,
            outRealOutHospName: hospital.hospName,
            outRealOutHospNo: hospital.hospNo,
        })
        setIsShowReferralHospitalModal(false)
    }

    /**
     * 選擇實際轉出院所科別
     * @param {object} division 科別
     * @return {void}
     */
    const selectOutRealOutDiv = (division) => {
        setCaseDetail((prev) => ({
            ...prev,
            outRealOutDivNo: division ? division.divNo : '',
            outRealOutDivName: division ? division.divName : '',
        }))
    }

    /**
     * 轉診單列印
     * @return {void}
     */
    const handleTranOutForm = () => {
        // 取得轉診單資料
        queryTranTypeByEncounterId({
            encounterId: editCase.encounterId,
            encounterDate: editCase.encounterDate,
            inpOpd: editCase.inpOpd,
            patientId: editCase.patientId,
        }).then((res) => {
            // 成功
            if (res.err === ApiErrorStatusEnum.Success) {
                const printData = res.data
                store.dispatch(
                    updatePrintContent({
                        reportType: FORM_TYPE.tranOutForm,
                        printData,
                    })
                )
            } else {
                // 失敗
                showToast({ message: res.msg, type: AlertTypeEnum.Error })
            }
        })
    }

    /**
     * 開啟列印模式則開啟瀏覽器列印視窗
     * 結束列印則關閉列印模式
     */
    useEffect(() => {
        if (isPrintMode) {
            window.print()
            store.dispatch(completePrintMode())
        }
    }, [isPrintMode])

    /**
     * mounting初始化資料，取得醫師、科別清單
     * @return {void}
     */
    useEffect(() => {
        const newValue = { ...initialValue, ...editCase }
        setCaseDetail(newValue)
    }, [editCase])

    return (
        <Dialog
            open={true}
            title={'轉出單個管作業_維護'}
            onClose={onClose}
            onSave={handleOnSave}
            variant={DialogVariant.EDIT}
            paperStyleProps={{ width: '90%' }}
            content={
                <>
                    <div className="flex justify-end items-center">
                        <div className="bg-[#F4F4F5] flex items-center p-1 mr-2 rounded-[6px] gap-2">
                            {/* 補列印轉診單 */}
                            <Button
                                color={ButtonColorEnum.Secondary}
                                size={ButtonSizeEnum.Medium}
                                variant={ButtonVariantEnum.Outlined}
                                text={'補列印轉診單'}
                                onClick={handleTranOutForm}
                                sx={{fontWeight: 'bold'}}
                            />
                            <div className="flex items-center space-x-2">
                                <TextField
                                    size={SizeEnum.Small}
                                    inputWidth={SizeEnum.Tiny}
                                    value={printSetting.copies}
                                    type="number"
                                    onChange={(e) =>
                                        updatePrintSetting(e, 'copies')
                                    }
                                />
                                份
                                <TextField
                                    size={SizeEnum.Small}
                                    inputWidth={SizeEnum.Tiny}
                                    value={printSetting.couplet}
                                    type="number"
                                    onChange={(e) =>
                                        updatePrintSetting(e, 'couplet')
                                    }
                                />
                                聯
                            </div>
                        </div>
                    </div>
                    <div className="p-3 space-y-2">
                        <div className="w-full grid grid-cols-4">
                            {/* 就醫日期 */}
                            <Field label="就醫日期">
                                {caseDetail.encounterDate
                                    ? time2String(
                                        caseDetail.encounterDate,
                                        DATE_FORMAT
                                    )
                                    : ''}
                            </Field>
                            {/* 批價序號 */}
                            <Field label="批價序號">
                                {/* TODO: 需等門診開發 */}
                                {caseDetail.payFeeNo}
                            </Field>
                            {/* 病歷號 */}
                            <Field label={t('general.patientNo')}>
                                {caseDetail.patientId}
                            </Field>
                        </div>
                        <div className="w-full grid grid-cols-4">
                            {/* 姓名 */}
                            <Field label={t('general.username')}>
                                {caseDetail.patientName}
                            </Field>
                            {/* 性別 */}
                            <Field label={t('general.gender.name')}>
                                {caseDetail.genderName}
                            </Field>
                            {/* 出生日期 */}
                            <Field label={t('general.birthday')}>
                                {caseDetail.birthDate
                                    ? getBirthDayAndDetailedAge(
                                        caseDetail.birthDate
                                    )
                                    : ''}
                            </Field>
                            {/* 身分證號 */}
                            <Field label={t('general.idNumber')}>
                                {caseDetail.idNo}
                            </Field>
                        </div>
                        <div className="w-full grid grid-cols-4">
                            {/* 轉診科別 */}
                            <Field label="就醫科別">
                                {`${caseDetail.divNo}${caseDetail.divName}`}
                            </Field>
                            {/* 轉診醫師 */}
                            <Field label={t('general.doctor')}>
                                {`${caseDetail.doctorNo}${caseDetail.doctorName}`}
                            </Field>
                        </div>
                        {/* 診斷碼 */}
                        <Field label="診斷碼" sx={{alignItems: 'start'}}>
                            <div className="space-y-2">
                            {/* 診斷碼1 */}
                            <div className="grid grid-cols-5 gap-2">
                                <div className="col-span-1">
                                    <TextField
                                        size={SizeEnum.Small}
                                        inputWidth={SizeEnum.Fill}
                                        value={caseDetail.treatmentCode}
                                        onChange={(e) =>
                                            updateCaseDetail(e, 'treatmentCode')
                                        }
                                        disabled
                                    />
                                </div>
                                <div className="col-span-3">
                                    <TextField
                                        size={SizeEnum.Small}
                                        inputWidth={SizeEnum.Fill}
                                        value={caseDetail.treatmentName}
                                        disabled
                                        onChange={(e) =>
                                            updateCaseDetail(e, 'treatmentName')
                                        }
                                    />
                                </div>
                            </div>
                            {/* 診斷碼2 */}
                            <div className="grid grid-cols-5 gap-2 w-full">
                                <div className="col-span-1">
                                    <TextField
                                        size={SizeEnum.Small}
                                        inputWidth={SizeEnum.Fill}
                                        type="text"
                                        value={caseDetail.treatmentCode1}
                                        onChange={(e) =>
                                            updateCaseDetail(e, 'treatmentCode1')
                                        }
                                        disabled
                                    />
                                </div>
                                <div className="col-span-3">
                                    <TextField
                                        size={SizeEnum.Small}
                                        inputWidth={SizeEnum.Fill}
                                        value={caseDetail.treatmentName1}
                                        disabled
                                        onChange={(e) =>
                                            updateCaseDetail(e, 'treatmentName1')
                                        }
                                    />
                                </div>
                            </div>
                            {/* 診斷碼3 */}
                            <div className="grid grid-cols-5 gap-2 w-full">
                                <div className="col-span-1">
                                    <TextField
                                        size={SizeEnum.Small}
                                        inputWidth={SizeEnum.Fill}
                                        value={caseDetail.treatmentCode2}
                                        onChange={(e) =>
                                            updateCaseDetail(e, 'treatmentCode2')
                                        }
                                        disabled
                                    />
                                </div>
                                <div className="col-span-3">
                                    <TextField
                                        size={SizeEnum.Small}
                                        inputWidth={SizeEnum.Fill}
                                        value={caseDetail.treatmentName2}
                                        disabled
                                        onChange={(e) =>
                                            updateCaseDetail(e, 'treatmentName2')
                                        }
                                    />
                                </div>
                            </div>
                            </div>
                        </Field>
                        {/* 轉出醫院 */}
                        <Field label="轉出醫院">
                            <div className="flex space-x-2">
                                <TextField
                                    size={SizeEnum.Small}
                                    value={caseDetail.tranOutHospNo}
                                    disabled
                                />
                                <TextField
                                    size={SizeEnum.Small}
                                    inputWidth={SizeEnum.XLarge}
                                    value={caseDetail.tranOutHospName}
                                    disabled
                                />
                            </div>
                        </Field>
                        {/* 轉出醫院所層 */}
                        <Field label="轉出醫院所層">
                            <TextField
                                size={SizeEnum.Small}
                                value={caseDetail.tranOutHospGradeName}
                                disabled
                            />
                        </Field>
                        {/* 轉診日期 */}
                        <div className="grid grid-cols-4 gap-2 w-full">
                            <Field label="轉診日期">
                                <DatePicker
                                    label=""
                                    value={caseDetail.tranDate ? dayjs(caseDetail.tranDate) : null}
                                    onChange={(newValue)=> handleDateChange(newValue, 'tranDate')}
                                />
                            </Field>
                            {/* 轉診科別 */}
                            <Field label="轉診科別">
                                {divisionList &&　<Search 
                                    options = {divisionList}
                                    getOptionLabel={(option) => `${option.divNo} ${option.divName}`}
                                    value={{
                                        divNo: caseDetail.tranOutDivNo,
                                        divName: caseDetail.tranOutDivName,
                                    }}
                                    onChange={(_event, newValue)=>selectTranOutDivision(newValue)}
                                />}
                            </Field>
                            {/* 轉診醫師 */}
                            <Field label="轉診醫師">
                                {doctorList &&　<Search 
                                    options = {doctorList}
                                    getOptionLabel={(option) => `${option.doctorNo} ${option.doctorName}`}
                                    value={{
                                        doctorName: caseDetail.tranDoctorName,
                                        doctorNo: caseDetail.tranDoctorNo,
                                    }}
                                    onChange={(_event, newValue)=>selectTranOutDoctor(newValue)}
                                />}
                            </Field>
                        </div>
                        {/* 轉診原因 */}
                        <Field label="轉診原因">
                            <div className="flex items-center">
                                <Select 
                                    data = {{
                                        label: "轉診原因",
                                        options: tranReasonList.map((item)=>({
                                            value: item.tranReason,
                                            label: item.tranReasonName
                                        }))
                                    }}
                                    value = {caseDetail.tranReason}
                                    onChange = {(e)=>updateCaseDetail({target:{value:e}},'tranReason')}
                                    showLabel = {false}
                                    width={SelectWidthEnum.XLarge}
                                />
                                {caseDetail.tranReason === TRAN_REASON_OTHERS ? (
                                    <TextField
                                        sx={{ml: 2}}
                                        inputWidth={SizeEnum.XLarge}
                                        value={caseDetail.tranReasonRemark}
                                        onChange={(e) =>
                                            updateCaseDetail(e, 'tranReasonRemark')
                                        }
                                    />
                                ) : (
                                    <></>
                                )}
                            </div>
                        </Field>
                        <Divider />
                        <div className="w-full grid grid-cols-2">
                            {/* 實際轉出院所 */}
                            <Field label="實際轉出院所">
                                <div className="flex space-x-2">
                                    <Button
                                        color={ButtonColorEnum.Secondary}
                                        variant={ButtonVariantEnum.Outlined}
                                        size={ButtonSizeEnum.Medium}
                                        text={'院所查詢'}
                                        onClick={showReferralHospital}
                                    />
                                    <TextField
                                        value={caseDetail.outRealOutHospNo}
                                        disabled
                                    />
                                    <TextField
                                        value={caseDetail.outRealOutHospName}
                                        disabled
                                    />
                                </div>
                            </Field>
                            {/* 實際轉出院所科別 */}
                            <Field label="實際轉出院所科別" labelWidth={160}>
                                <div className="flex gap-2">
                                    <Search 
                                        options = {divisionList}
                                        getOptionLabel={(option) => `${option.divNo} ${option.divName}`}
                                        value={{
                                            divNo: caseDetail.outRealOutDivNo,
                                            divName: caseDetail.outRealOutDivName,
                                        }}
                                        onChange={(_event, newValue)=>selectOutRealOutDiv(newValue)}
                                    />
                                    {/* 帶入院所及科別 */}
                                    <Button
                                        color={ButtonColorEnum.Secondary}
                                        size={ButtonSizeEnum.Small}
                                        variant={ButtonVariantEnum.Outlined}
                                        text={'帶入院所及科別'}
                                        onClick={populateWithtranOutHospDiv}
                                        sx={{fontWeight: 'bold'}}
                                    />
                                </div>
                            </Field>
                        </div>
                        <div className="w-full grid grid-cols-4">
                            {/* 轉診單回收日期 */}
                            <Field label="轉診單回收日期">
                                <DatePicker
                                    label=""
                                    value={caseDetail.outReturnDate ? dayjs(caseDetail.outReturnDate) : null}
                                    onChange={(newValue)=> handleDateChange(newValue, 'outReturnDate')}
                                />
                            </Field>
                            {/* 接受對方回覆日期(初步) */}
                            <Field label="接受對方回覆日期(初步)" labelWidth={190}>
                                <DatePicker
                                    label=""
                                    value={caseDetail.outReplyDate ? dayjs(caseDetail.outReplyDate) : null}
                                    onChange={(newValue)=> handleDateChange(newValue, 'outReplyDate')}
                                />
                            </Field>
                            {/* 接受回覆日期(病歷摘要) */}
                            <Field label="接受回覆日期(病歷摘要)" labelWidth={190}>
                                <DatePicker
                                    label=""
                                    value={caseDetail.outReplyDate2 ? dayjs(caseDetail.outReplyDate2) : null}
                                    onChange={(newValue)=> handleDateChange(newValue, 'outReplyDate2')}
                                />
                            </Field>
                        </div>
                        <div className="w-full grid grid-cols-4">
                            {/* 住院日期 */}
                            <Field label="住院日期">
                                <DatePicker
                                    label=""
                                    value={caseDetail.admissionDate ? dayjs(caseDetail.admissionDate) : null}
                                    onChange={(newValue)=> handleDateChange(newValue, 'admissionDate')}
                                />
                            </Field>
                            {/* 出院日期 */}
                            <Field label="出院日期" labelWidth={190}>
                                <DatePicker
                                    label=""
                                    value={caseDetail.dischargeDate ? dayjs(caseDetail.dischargeDate) : null}
                                    onChange={(newValue)=> handleDateChange(newValue, 'dischargeDate')}
                                />
                            </Field>
                        </div>
                        {/* 轉診追蹤狀態 */}
                        <Field label="轉診追蹤狀態">
                            <Select
                                data={{
                                    label: '轉診追蹤狀態',
                                    options: [
                                        {value:'', label:''},
                                        ...outStatusList.map((item) => ({
                                            value: item.outStatus,
                                            label: item.outStatusName
                                        }))
                                    ]
                                }}
                                value = {caseDetail.outStatus}
                                onChange = {(value)=>updateCaseDetail(value,'outStatus')}
                                showLabel={false}
                            />
                        </Field>
                        {/* 癌症篩檢 */}
                        <Field label="癌症篩檢">
                            <Checkbox
                                checked={!!caseDetail.outCancerFlag}
                                onChange={(e) =>
                                    updateCaseDetail(e, 'outCancerFlag')
                                }
                            />
                        </Field>
                        {/* 備註 */}
                        <Field label={'備註'} sx={{alignItems: 'start'}}>
                            <TextArea
                                inputSize={SizeEnum.Fill}
                                value={caseDetail.outRemark}
                                onChange={(e) =>
                                    updateCaseDetail(e, 'outRemark')
                                }
                            />
                        </Field>
                    </div>
                    <div className="w-full border-b-2 mt-3"></div>
                    {/* 院所查詢視窗 */}
                    {isShowReferralHospitalModal && (
                        <ReferralHospitalModal
                            zipCodeList={zipCodeList}
                            onConfirm={onSelectHospital}
                            closePopupButtonOnClick={() =>
                                setIsShowReferralHospitalModal(false)
                            }
                        />
                    )}
                </>
            }
            muiDialogContentProps={{dividers: true}}
        />
    )
}

export default TranOutCaseModal
