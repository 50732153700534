import {ChartTypeEnum, ChartOptions, isNumber, arrayIsEmpty, stringIsEmpty} from "edah_utils/dist"
import React from "react"
import PieChart from "../../Chart/PieChart"
//import BarChart from "../../Chart/BarChart"
import {BarChart, BarChartLayoutEnum} from 'edah-component/dist'

/**
 * 查詢簽章率明細圖表
 * @param chartOptions {ChartOptions}
 * @return {JSX.Element}
 */
export const SignRateChart = ({chartOptions}) => {
    /**
     * 取得寬度
     * @return {string}
     */
    const getWidth = () => isNumber(chartOptions.width) ?
        // 若是數字則加上 px
        `${chartOptions.width}px` :
        // 若是字串則直接回傳
        chartOptions.width

    /**
     * 取得高度
     * @return {string}
     */
    const getHeight = () => isNumber(chartOptions.height) ?
        // 若是數字則加上 px
        `${chartOptions.height}px` :
        // 若是字串則直接回傳
        chartOptions.height

    /**
     * 圖表選項驗證器
     * @return {boolean}
     */
    const chartOptionsValidator = () => {
        // 若圖表選項、寬度、高度、圖表類型有一個為空值，則回傳 false
        if (!chartOptions || !chartOptions.width || !chartOptions.height || !chartOptions.chartType) {
            return false
        }

        switch (chartOptions.chartType) {
            case ChartTypeEnum.Pie:
                // 若資料為空，則回傳 false
                return !arrayIsEmpty(chartOptions.data)
            case ChartTypeEnum.BarVertical:
                // 若資料、次要資料、標籤、顏色、次要顏色有一個為空值，則回傳 false
                return !arrayIsEmpty(chartOptions.data) &&
                    !arrayIsEmpty(chartOptions.secData) &&
                    !arrayIsEmpty(chartOptions.labels) &&
                    !arrayIsEmpty(chartOptions.color) &&
                    !arrayIsEmpty(chartOptions.secColor)
        }
    }

    /**
     * 取得圖表元件
     * @return {JSX.Element}
     */
    const getChartSection = () => {

        switch (chartOptions.chartType) {
            case ChartTypeEnum.Pie:
                return <PieChart data={chartOptions.data} width={getWidth()} height={getHeight()}/>
            case ChartTypeEnum.BarVertical: 
            {
                const dataSeries = [
                    {
                      label: '已簽',
                      data: chartOptions.data
                    },
                    {
                      label: '未簽',
                      data: chartOptions.secData
                    }
                ]
                return <BarChart layout={BarChartLayoutEnum.Vertical} labels={chartOptions.labels}
                                dataSeries = {dataSeries} width={960} height={650}/>
            }
        }
    }

    return chartOptionsValidator() ?
        // 若圖表選項驗證成功，則回傳圖表元件
        <div className='pt-2'>{getChartSection()}</div> :
        // 若圖表選項驗證失敗，則回傳 null
        null
}
