import React from 'react'
import '../../TableList/tableList.scss'
import {usePopup} from '../PopupProvider'
import {t} from "i18next"
import {sanitizedData} from "../utils";
import {
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    IconEnum
} from "edah-component/dist/index"

/**
 * RSIS功能操作維護列表組件
 * @param item {Object} 清單資料
 * @param index {Number} 清單索引
 * @param isSelectedAll {Boolean} 是否包含全選
 * @return {JSX.Element}
 */
const ListItem = ({item, index, isSelectAll = null,}) => {
    // 取得html資料
    const data = item.htmlContent ? item.htmlContent : ''

    // 使用上下文
    const {setShowFunctionOperateIntroEditModal, setFocusItem} = usePopup();

    /**
     * 編輯事件，指定編輯index
     * @return {void}
     */
    const handleOnItemEdit = () => {
        // 顯示功能操作維護編輯彈窗
        setShowFunctionOperateIntroEditModal(true)
        // 設定編輯項目
        setFocusItem(item)
    }

    return (
        <li key={index}>
            {/*操作*/}
            <div className='flex justify-evenly'>
                <span className='flex items-center'>
                    {/*編輯按鈕*/}
                    <Button
                        color={ButtonColorEnum.Primary} size={ButtonSizeEnum.Large} icon={IconEnum.Edit}
                        text={t('general.edit')} variant={ButtonVariantEnum.Text}
                        onClick={handleOnItemEdit}/>
                </span>
            </div>
            {/* 功能ID */}
            <div>{item.funNo}</div>
            {/* 功能名稱 */}
            <div>{item.funcName}</div>
            {/* 類別 */}
            <div>{item.docType ? t(`RSIS.DocTypeEnum.${item.docType}`) : ''}</div>
            {/* 操作說明 */}
            <div
                className='flex flex-col items-start justify-start operation-description prose max-w-none'
                dangerouslySetInnerHTML={{__html: sanitizedData(data)}}
            />
            {/* 有效否 */}
            <div>{item.effFlag ? t(`RSIS.effFlag.${Number(item.effFlag)}`) : t('RSIS.effFlag.0')}</div>
        </li>
    )
}

export default ListItem
