/**
 * 電子轉診單_下載
 */
export const REFERRAL_FORM_DOWNLOAD_NO = 'RMIS-0001-1'
/**
 * 電子轉診單_上傳
 */
export const REFERRAL_FORM_UPLOAD_NO = 'RMIS-0001-2'
/**
 * 轉診回函
 */
export const REFERRAL_REPLY_LETTER = 'RMIS-0001-3'
/**
 * 出院病摘_申請/列印
 */
export const APPLY_DISCHARGE_SUMMARY_NO = 'RMIS-0001-4'
/**
 * 出院病摘核准
 */
export const APPROVE_DISCHARGE_SUMMARY_NO = 'RMIS-0001-5'
/**
 * 轉入單個管作業_清單
 */
export const TRAN_IN_CASE_LIST_NO = 'RMIS-0001-6'
/**
 * 轉出單個管作業_清單
 */
export const TRAN_OUT_CASE_LIST_NO = 'RMIS-0001-7'
/**
 * 病症暨失能診斷證明書
 */
export const TRAN_FOREIGN_LIST_NO = 'RMIS-0001-8'
/**
 * 轉出轉入開立作業
 */
export const ADD_REFERRAL_NO = 'RMIS-0001-9'
/**
 * 其他作業
 */
export const OTHERS_NO = 'RMIS-0003'
/**
 * 看診清單查詢
 */
export const QUERY_PATIENT_VISIT_LIST_NO = 'RMIS-0003-1'
/**
 * 不轉診原因報表
 */
export const NO_REFERRAL_REPORT_NO = 'RMIS-0003-2'
/**
 * 急診品質方案VPN上傳匯出作業
 */
export const ER_QUALITY_VPN_UPLOAD_EXPORT_NO = 'RMIS-0003-3'
/**
 * MARS(VPN)報表
 */
export const MARS_REPORT_NO = 'RMIS-0003-4'
/**
 * 轉診外掛單機上傳程式
 */
export const REFERRAL_PLUGIN_UPLOAD_NO = 'RMIS-0003-5'
/**
 * 維護作業
 */
export const MAINTAIN_NO = 'RMIS-0002'
/**
 * 權限設定
 */
export const PERMISSION_SETTING_NO = 'RMIS-0002-1'
/**
 * 轉診代碼維護
 */
export const REFERRAL_CODE_MAINTAIN_NO = 'RMIS-0002-2'
/**
 * 急診責任醫院維護
 */
export const EMERGENCY_HOSPITAL_MAINTAIN_NO = 'RMIS-0002-3'
/**
 * 策略聯盟維護
 */
export const STRATEGIC_ALLIANCE_MAINTAIN_NO = 'RMIS-0002-4'
/**
 * 長照中心維護
 */
export const LONG_TERM_CARE_CENTER_NO = 'RMIS-0002-5'
/**
 * 離島偏鄉醫院維護
 */
export const RURAL_HOSPITAL_NO = 'RMIS-0002-6'
