import React from 'react'
import {usePopup} from "../PopupProvider";
import {
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    Dialog,
    DialogVariant,
    DialogSizeEnums
} from "edah-component/dist";
import {t} from "i18next";
/**
 * 詢問使用者是否送交衛福部彈窗
 * @param {function} handleOnClose - 控制彈窗關閉
 * @return {JSX.Element}
 */
const SendMOHWConfirm = ({handleOnClose}) => {

    const {
        // 設定變更彈窗開關狀態
        setShowChangeModal,
        // 設定註銷彈窗開關狀態
        setShowLogOffModal,
        // 設定收案確認(個案管理的編輯)彈窗開關狀態
        setShowCaseConfirmation,
        // 設定刪除彈窗開關狀態
        setShowDeletePopup,
        // 當前觸發的按鈕類型
        listItemBtnType
    } = usePopup()

    /**
     * 是按鈕點擊事件
     * return {void}
     */
    const handleOnAgree = () => {
        // 按鈕觸發來源為編輯時，開啟變更彈窗
        listItemBtnType === 'EDIT' && setShowChangeModal(true)
        // 按鈕觸發來源為刪除時，開啟註銷彈窗
        listItemBtnType === 'DELETE' && setShowLogOffModal(true)
        // 關閉本彈窗
        handleOnClose()
    }

    /**
     * 否按鈕點擊事件
     * return {void}
     */
    const handleOnDisagree = () => {
        // 按鈕觸發來源為編輯時，開啟收案確認彈窗
        listItemBtnType === 'EDIT' && setShowCaseConfirmation(true)
        // 按鈕觸發來源為刪除時，開啟刪除彈窗
        listItemBtnType === 'DELETE' && setShowDeletePopup(true)
        // 關閉本彈窗
        handleOnClose()
    }

    return (
        <Dialog
            open={true}
            title={'提醒'}
            content={'是否已送交衛福部？'}
            variant={DialogVariant.CUSTOM}
            paperStyleProps={{width: DialogSizeEnums.SM}}
            onClose={handleOnClose}
            footerContent={
                <>
                    <Button
                        text={t('general.yes')}
                        onClick={handleOnAgree}
                        variant={ButtonVariantEnum.Outlined}
                        size={ButtonSizeEnum.Medium}
                        color={ButtonColorEnum.Primary}
                    />
                    <Button
                        text={t('general.no')}
                        onClick={handleOnDisagree}
                        variant={ButtonVariantEnum.Outlined}
                        size={ButtonSizeEnum.Medium}
                        color={ButtonColorEnum.Primary}
                    />
                </>
            }
        />
    )
}

export default SendMOHWConfirm
