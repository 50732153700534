import {arrayIsEmpty, time2String} from "edah_utils/dist"
import React, {useState} from "react";
import { t } from "i18next";

/**
 * 手術明細紀錄
 * @param data {Array} 資料
 * @return {JSX.Element}
 */
export const SurgeryDetailsRecord = ({data}) => {
    return (
        <div className="w-full">
            {/*列表清單區塊*/}
            {/*列表清單*/}
            <ul className="tableList icSDRList">
                {/*表單項目標題*/}
                <li className="title">
                    <div>門住診別</div>
                    <div>就醫科別代碼</div>
                    <div>就醫科別名稱</div>
                    <div>主診斷碼</div>
                    <div>主診斷名稱</div>
                    <div>手術明細代碼</div>
                    <div>手術明細名稱</div>
                    <div>診療部位</div>
                    <div>執行時間-起</div>
                    <div>執行時間-迄</div>
                    <div>醫令總量</div>
                    <div>醫事機構代碼</div>
                    <div>醫事機構簡稱</div>
                </li>

                {
                    !arrayIsEmpty(data) && data.map((item, index) =>
                        <li key={index}>
                            {/*門住診別*/}
                            <div>{t(`ICCard.InpOpdEnum.${item.inpOpd}`)}</div>
                            {/*健保就醫科別代碼*/}
                            <div>{data.heDivNo}</div>
                            {/*健保就醫科別名稱*/}
                            <div>{data.heDivName}</div>
                            {/*主診斷碼*/}
                            <div>{item.treatmentCode}</div>
                            {/*主診斷名稱*/}
                            <div>{item.treatmentName}</div>
                            {/*手術明細代碼*/}
                            <div>{item.treatmentCode}</div>
                            {/*手術明細名稱*/}
                            <div>{item.treatmentName}</div>
                            {/*診療部位*/}
                            <div>{t(`ICCard.orderPos.${item.orderPos}`)}</div>
                            {/*執行時間-起*/}
                            <div>{time2String(item.execStartDate, 'YYYY-MM-DD')}</div>
                            {/*執行時間-迄*/}
                            <div>{time2String(item.execEndDate, 'YYYY-MM-DD')}</div>
                            {/*醫令總量*/}
                            <div>{item.tqty}</div>
                            {/*醫事機構代碼*/}
                            <div>{item.hospNo}</div>
                            {/*醫事機構簡稱*/}
                            <div>{item.hospName}</div>
                        </li>
                    )}
            </ul>
        </div>
    )
}