import LongTermCareCenter from '../../pages/ReferralSystem/LongTermCareCenter'

const FORM_TYPE = {
    /**
     * 列印轉回原醫療院所名單
     */
    tranBackCaseList: 'tranBackCaseList',
    /**
     * 轉入單列印
     */
    tranInForm: 'tranInForm',
    /**
     * 未收到原醫療院所回覆名單
     */
    tranOutNoReplyLetterList: 'tranOutNoReplyLetterList',
    /**
     * 未收到原醫療院所回覆名單
     */
    tranOutNoReplyLetterLabel: 'tranOutNoReplyLetterLabel',
    /**
     * 轉出單列印
     */
    tranOutForm: 'tranOutForm',
    /**
     * 權限設定列印
     */
    permissionSettingList: 'permissionSettingList',
    /**
     * 權限設定標籤列印
     */
    permissionLabel: 'permissionLabel',
    /**
     * 急救責任醫院維護列印
     */
    aidHospitalList: 'aidHospitalList',
    /**
     * 轉診代碼列印
     */
    referralCodeList: 'referralCodeList',
    /**
     * 申請人信封
     */
    applyEnvelope: 'applyEnvelope',
    /**
     * 長照中心信封
     */
    longTermCareEnvelope: 'longTermCareEnvelope',
    /**
     * 長照中心列印
     */
    longTermCareList: 'longTermCareList',
    /**
     * 策略聯盟列印
     */
    federalList: 'federalList',
    /**
     * 轉診回函明細列印
     */
    replyLetterList: 'replyLetterList',
    /**
     * 轉診回函信封列印
     */
    replyLetterEnvelopeCover: 'replyLetterEnvelopeCover',
    /**
     * 轉診回函信封列印
     */
    replyLetterLabel: 'replyLetterLabel',
    /**
     * 轉診回函單筆/整批列印
     */
    replyLetterOrder: 'replyLetterOrder',
    /**
     * 出院病摘列印
     */
    dischargeSummary: 'dischargeSummary',
    /**
     * 出院病摘標籤列印
     */
    dischargeSummaryLabel: 'dischargeSummaryLabel',
    /**
     * 出院病摘信封列印
     */
    dischargeSummaryEnvelope: 'dischargeSummaryEnvelope',
    /**
     * 轉診回診證明單
     */
    returnTicket: 'returnTicket'
}

export { FORM_TYPE }
