import React from 'react'
import WardAgentMaintain from '../../components/RSIS/WardAgentMaintain/WardAgentMaintain'
import { PopupProvider } from '../../components/RSIS/PopupProvider'

/**
 * 病房代理人維護
 * @returns {JSX.Element}
 */
function WardAgentMaintainLayout() {
  return (
    <PopupProvider>
      <WardAgentMaintain />
    </PopupProvider>
  )
}

export default WardAgentMaintainLayout