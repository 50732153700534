import {t} from "i18next"
import React, {useEffect, useState} from "react"
import { PopupModeEnum } from "edah_utils/dist"
import { saveCdss } from "../../api/v1/CDSS";
import { ApiErrorStatusEnum, ToastTypeEnum, stringIsEmpty } from "edah_utils/dist"
import { Field, SizeEnum, TextArea, AlertTypeEnum} from "edah-component/dist/index";
import useToast from "../../hooks/useToast";
import {Dialog, DialogSizeEnums, DialogVariant, TextField, Select, RadioGroup, SelectWidthEnum} from "edah-component/dist";

/**
 * @param mode {Number} 新增或編輯
 * @param data {Object} 資料
 * @param closePopup {Function} 關閉彈出視窗
 * @param handleSuccessCreateRule {Function} 建立或編輯成功事件
 */
const RulePopup = ({
    mode,
    data,
    closePopup,
    handleSuccessCreateRule
}) => {
    //預設設定
    const baseSettings = {
        //規則代碼
        cdssId: data.cdssId ? data.cdssId : "",
        //分類
        category: data.category ? data.category : "",
        //目的說明
        purpose: data.purpose ? data.purpose : "",
        //規則說明
        description: data.description ? data.description : "",
        //規則出處
        ruleSource: data.ruleSource ? data.ruleSource : "",
        //服務伺服器類型
        serverType: data.serverType ? data.serverType : "",
        //服務中
        effectiveFlag: data.effectiveFlag ? data.effectiveFlag : true,
        //參考規範
        parameterConstraints: data.parameterConstraints ? data.parameterConstraints : "",
        //回傳結果
        returnResult: data.returnResult ? data.returnResult : ""
    }
    //當前設定
    const [record, setRecord] = useState(baseSettings)

    // Toast Message Hook
    const showToast = useToast()

    /**
     * Toast資料
     */
    const [toast, setToast] = useState(null)

    /**
     * 儲存或編輯按鈕被點擊時
     */
    const handleSaveOrEditOnClick = () =>{
        
        let fieldEmpty = false;
        let warningMsg = "";
        do {
            if(stringIsEmpty(record.cdssId)) {
                fieldEmpty = true;
                warningMsg = "規則代碼欄位為空"
                break
            }
            if(stringIsEmpty(record.category)) {
                fieldEmpty = true;
                warningMsg = "分類欄位為空"
                break
            }
            if(stringIsEmpty(record.purpose)) {
                fieldEmpty = true;
                warningMsg = "目的說明欄位為空"
                break
            }
            if(stringIsEmpty(record.description)) {
                fieldEmpty = true;
                warningMsg = "規則說明欄位為空"
                break
            }
            if(stringIsEmpty(record.ruleSource)) {
                fieldEmpty = true;
                warningMsg = "規則來源欄位為空"
                break
            }
            if(stringIsEmpty(record.serverType)) {
                fieldEmpty = true;
                warningMsg = "服務伺服器類型沒有選擇"
                break
            }
            if(stringIsEmpty(record.parameterConstraints)) {
                fieldEmpty = true;
                warningMsg = "參考規範為空"
                break
            }
            if(stringIsEmpty(record.returnResult)) {
                fieldEmpty = true;
                warningMsg = "回傳結果為空"
                break
            }
        } while(false)
        if(fieldEmpty === true) {
            setToast({
                show:true,
                type:'Error',
                message: warningMsg
            })

            return
        }
        
        //是否為新增模式
        const isAdd = mode === PopupModeEnum.Add;
        if(isAdd) {
            //新增規則
            saveCdss({
                // CDSS ID
                cdssId: record.cdssId,
                // 分類
                category: record.category,
                // 目的說明
                purpose: record.purpose,
                // 規則說明
                description: record.description,
                // 規則來源
                ruleSource: record.ruleSource,
                // 服務伺服器類型
                serverType: record.serverType,
                // effective Flag
                effectiveFlag: record.effectiveFlag,
                // 參考規範
                parameterConstraints: record.parameterConstraints,
                // 回傳結果
                returnResult: record.returnResult,
                lockVersion: 0
            }).then(res => {
                //新增成功
                if (res.err === ApiErrorStatusEnum.Success) {
                    handleSuccessCreateRule();
                }
                else
                {
                    setToast({
                        show:true,
                        type:'Error',
                        message: res.msg
                    })
                }
            })
        }
        else { //修改模式
            //新增規則
            saveCdss({
                // CDSS ID
                cdssId: record.cdssId,
                // 分類
                category: record.category,
                // 目的說明
                purpose: record.purpose,
                // 規則說明
                description: record.description,
                // 規則來源
                ruleSource: record.ruleSource,
                // 服務伺服器類型
                serverType: record.serverType,
                // effective Flag
                effectiveFlag: record.effectiveFlag,
                // 參考規範
                parameterConstraints: record.parameterConstraints,
                // 回傳結果
                returnResult: record.returnResult,
                lockVersion: 0
            }).then(res => {
                //新增成功
                if (res.err === ApiErrorStatusEnum.Success) {
                    handleSuccessCreateRule();
                }
                else {
                    setToast({
                        show:true,
                        type:'Error',
                        message: res.msg
                    })
                }
            })
        }
    }

    /**
     * 規則代碼文字欄位改變時
     * @param e {Event}
     * @return {void}
     */
    const handleRuleTextOnChange = (e) => {
        setRecord({...record, cdssId: e.target.value})
    }

    /**
     * 分類文字欄位改變時
     * @param e {Event}
     * @return {void}
     */
    const handleCategoryTextOnChange = (e) => {
        setRecord({...record, category: e.target.value})
    }

    /**
     * 目的說明欄位改變時
     * @param e {Event}
     * @return {void}
     */
    const handlePurposeOnChange = (e) => {
        setRecord({...record, purpose: e.target.value})
    }

    /**
     * 規則說明欄位改變時
     * @param e {Event}
     * @return {void}
     */
    const handleDescriptionOnChange = (e) => {
        setRecord({...record, description: e.target.value})
    }

    /**
     * 規則出處欄位改變時
     * @param e {Event}
     * @return {void}
     */
    const handleRuleSourceOnChange = (e) => {
        setRecord({...record, ruleSource: e.target.value})
    }

    /**
     * 服務伺服器類型欄位改變時
     * @param value {Event}
     * @return {void}
     */
    const handleServerTypeOnChange = (value) => {
        setRecord({...record, serverType: value})
    }

    /**
     * 服務中欄位改變時
     * @param e {Boolean}
     * @return {void}
     */
    const handleEffectiveFlagOnChange = (e) => {
        setRecord({...record, effectiveFlag: e.target.value})
    }

    /**
     * 參考規範欄位改變時
     * @param e {Boolean}
     * @return {void}
     */
    const handleParameterConstraintsOnChange = (e) => {
        setRecord({...record, parameterConstraints: e.target.value})
    }

    /**
     * 回傳結果欄位改變時
     * @param e {Boolean}
     * @return {void}
     */
    const handleReturnResultOnChange = (e) => {
        setRecord({...record, returnResult: e.target.value})
    }

    /**
     * 監聽是否需要顯示 Toast
     */
    useEffect(()=>{
        if(toast) {
        showToast({type:AlertTypeEnum[toast.type], message:toast.message})}
    },[toast])

    return (
        <Dialog
            title={mode === PopupModeEnum.Add ? "新增" : "編輯"}
            open={closePopup}
            onClose={closePopup}
            variant={DialogVariant.EDIT}
            paperStyleProps={{width:DialogSizeEnums.MD}}
            onSave={handleSaveOrEditOnClick}
            content={
            <div className="flex">
                <div className="space-y-2">
                    {/**規則代碼 */}
                    <Field label={'規則代碼'}>
                        <TextField
                            value={record.cdssId}
                            disabled={mode !== PopupModeEnum.Add}
                            onChange={handleRuleTextOnChange}/>
                    </Field>
                    {/**分類 */}
                    <Field label={'分類'}>
                        <TextField
                            value={record.category}
                            onChange={handleCategoryTextOnChange}/>
                    </Field>
                    {/**目的說明 */}
                    <Field label={"目的說明"}>
                        <TextArea
                            value={record.purpose}
                            onChange={handlePurposeOnChange}/>
                    </Field>
                    {/**規則說明 */}
                    <Field label={"目的說明"}>
                        <TextArea
                            value={record.description}
                            onChange={handleDescriptionOnChange}/>
                        </Field>
                    {/**規則出處 */}
                    <Field label={'規則出處'}>
                        <TextField
                            value={record.ruleSource}
                            onChange={handleRuleSourceOnChange}/>
                    </Field>
                    {/**服務伺服器類型 */}
                    <Field label={'服務伺服器類型'}>
                        <Select
                            width={SelectWidthEnum.Medium}
                            data={{
                                label: '服務伺服器類型',
                                options: [
                                    {value:'Rule-Server',label:'Rule-Server'},
                                    {value:'KIE-Server',label:'KIE-Server'}
                                ]
                            }}
                            value={record.serverType}
                            showLabel={false}
                            onChange={handleServerTypeOnChange}/>
                    </Field>
                    {/**服務中 */}
                    <Field label={'服務中'}>
                        <RadioGroup
                            size={SizeEnum.Medium}
                            defaultValue={record.effectiveFlag}
                            optionProps={{
                                options: [
                                    { label: '是', value: true,},
                                    { label: '否', value: false,},
                                ],
                            }}
                            onChange={handleEffectiveFlagOnChange}/>
                    </Field>
                </div>
                <div className="space-y-2">
                    {/**參考規範 */}
                    <Field label={"參考規範"}>
                        <TextArea
                            value={record.parameterConstraints}
                            onChange={handleParameterConstraintsOnChange}
                        />
                    </Field>
                    {/**回傳結果 */}
                    <Field label={"回傳結果"}>
                        <TextArea
                            value={record.returnResult}
                            onChange={handleReturnResultOnChange}
                        />
                    </Field>
                </div>
            </div>
            }
        />
    )
}

export default RulePopup;