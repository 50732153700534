// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media print {
  .a4-page {
    margin: auto;
  }
  @page {
    .a4-page {
      size: A4 portrait;
    }
    .a5-page {
      size: A5 landscape;
      margin: auto;
    }
  }
  .break-after {
    page-break-after: always;
    break-after: page;
  }
  .print-only {
    display: block !important;
  }
  .no-print {
    display: none;
  }
  .label-page {
    width: 75mm;
    height: 45mm;
    margin: auto;
  }
  .envelope-cover {
    transform: rotate(90deg);
    margin-top: 90mm;
    margin-left: -25mm;
    width: 200mm;
    height: 50mm;
    page-break-after: always;
    break-after: page;
  }
  .envelope-cover-basic {
    transform: rotate(90deg);
    margin-top: 75mm;
    margin-left: 25mm;
    width: 150mm;
    height: 50mm;
    page-break-after: always;
    break-after: page;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Print/print.scss"],"names":[],"mappings":"AAAA;EACI;IAMI,YAAA;EAJN;EADM;IADJ;MAEQ,iBAAA;IAIR;IAEA;MAEQ,kBAAA;MACA,YAAA;IACR;EALF;EAOE;IACI,wBAAA;IAAA,iBAAA;EACN;EACE;IACI,yBAAA;EACN;EACE;IACI,aAAA;EACN;EACE;IACI,WAAA;IACA,YAAA;IACA,YAAA;EACN;EACE;IACI,wBAAA;IACA,gBAAA;IACA,kBAAA;IACA,YAAA;IACA,YAAA;IACA,wBAAA;IAAA,iBAAA;EACN;EACE;IACI,wBAAA;IACA,gBAAA;IACA,iBAAA;IACA,YAAA;IACA,YAAA;IACA,wBAAA;IAAA,iBAAA;EACN;AACF","sourcesContent":["@media print {\n    .a4-page {\n        @page {\n            size: A4 portrait;\n        }\n        // width: 210mm;\n        // height: 297mm;\n        margin: auto;\n    }\n    .a5-page {\n        @page {\n            size: A5 landscape;\n            margin: auto;\n        }\n    }\n    .break-after {\n        break-after: page;\n    }\n    .print-only {\n        display: block !important;\n    }\n    .no-print {\n        display: none;\n    }\n    .label-page {\n        width: 75mm;\n        height: 45mm;\n        margin: auto;\n    }\n    .envelope-cover {\n        transform: rotate(90deg);\n        margin-top: 90mm;\n        margin-left: -25mm;\n        width: 200mm;\n        height: 50mm;\n        break-after: page;\n    }\n    .envelope-cover-basic {\n        transform: rotate(90deg);\n        margin-top: 75mm;\n        margin-left: 25mm;\n        width: 150mm;\n        height: 50mm;\n        break-after: page;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
