import {
    ApiErrorStatusEnum,
    downloadFile,
    exportExcelAsBlob,
    FileExtensionsEnum
} from "edah_utils/dist";
import React, {useEffect, useState} from "react";
import CustomTable from "../../components/ReferralSystem/CustomTable";
import {LONG_TERM_CARE_CENTER} from "../../constants/referralSystem/maintainAreaHeader";
import {Pagination} from "../../components/Pagination/Pagination";
import {
    addRmisTranCareCenter,
    deleteRmisTranCareCenter,
    editRmisTranCareCenter,
    queryRmisTranCareCenter
} from "../../api/v1/Rmis";
import useDataGrid from "../../hooks/useDataGrid";
import useToast from "../../hooks/useToast";
import store from "../../redux/store";
import {completePrintMode, updatePrintContent} from "../../redux/Slice/printSlice";
import {FORM_TYPE} from "../../constants/referralSystem/print";
import {useSelector} from "react-redux";
import {
    AlertTypeEnum,
    TextField,
    IconEnum,
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    Dialog,
    DialogVariant,
    DialogSizeEnums
} from "edah-component/dist"
import {t} from "i18next";

/**
 * layout component
 * @returns {JSX.Element}
 */
const Layout = ({children}) => {
    return (
        <div className="p-2 w-full h-[calc(100vh-98px)] overflow-auto">
            {children}
        </div>
    )
}

/**
 * 長照中心維護
 * @returns {JSX.Element}
 */
const LongTermCareCenter = () => {
    const [filter, setFilter] = useState({
        // 總頁數
        totalPage: 1,
        // 總筆數
        total: 0,
        // 目前頁碼
        currentPage: 1,
        // 每頁筆數
        pageSize: 10,
    })
    // 當前選取的列
    const [activeRow, setActiveRow] = useState({})
    // 刪除確認popup控制
    const [showDeletePopup, setShowDeletePopup] = useState(false)
    /**
     * Show Toast
     */
    const showToast = useToast()
    // 列印模式
    const isPrintMode = useSelector((state) => state.print.isPrintMode)

    /**
     * 搜尋列 input
     */
    const [hospitalCode, setHospitalCode] = useState("")


    // data grid
    const {
        updateDataList,
        handleEdit,
        handleSave,
        handleCancel,
        addNewData,
        dataOnChange,
        handleToggleItem,
        handleToggleAll,
        deleteRow,
        dataList,
        isEditMode,
    } = useDataGrid()

    /**
     * 搜尋列 更新
     */
    const handleHospitalCodeChange = (e) => setHospitalCode(e.target.value)

    /**
     * 查詢按鈕
     */
    const handleClickQuery = async (pageNum, pageSize) => {
        const result = await queryRmisTranCareCenter({
            hospNoOrName: hospitalCode,
            pageNum: pageNum,
            pageSize: pageSize
        })

        if (result.err === ApiErrorStatusEnum.Success) {
            updateDataList(result.data.dataList)
            setFilter({
                ...filter,
                currentPage: pageNum,
                pageSize: pageSize,
                totalPage: result.data.totalPageSize,
                total: result.data.totalItemSize
            })
        } else {
            showToast({message: result.msg, type: AlertTypeEnum.Warning})
        }
    }

    /**
     * 刪除單筆資料
     * @return {void}
     */
    const handleConfirmDelete = () => {
        // 長照機構代碼
        const hospNo = JSON.stringify([{hospNo: activeRow.hospNo, lockVersion: activeRow.lockVersion}])
        // 刪除api
        deleteRmisTranCareCenter({
            tableName: "tw.com.hismax.his.entity.rmis.RmisTranCareCenter",
            tableData: hospNo
        }).then((res) => {
            // 成功
            if (res.err === ApiErrorStatusEnum.Success) {
                showToast({message: '刪除成功', type: AlertTypeEnum.Success})
                // 刪除當列資料
                deleteRow(activeRow)
            } else {
                // 失敗
                showToast({message: res.msg, type: AlertTypeEnum.Error})
            }

            setShowDeletePopup(false)
        })
    }

    /**
     * 開啟刪除彈窗
     * @param tableRow {object} 當前選取的列
     * @return {void}
     */
    const handleDeletePopupOpen = (tableRow) => {
        setShowDeletePopup(true)
        setActiveRow(tableRow)
    }

    // 關閉刪除彈窗
    const handleDeletePopupClose = () => {
        setShowDeletePopup(false)
    }

    /**
     * 點選 pagination 頁碼
     * @param page {string} 目前頁碼
     * @return {void}
     */
    const handlePageOnChange = (page) => {
        const currentPage = Number(page)
        handleClickQuery(currentPage, filter.pageSize)
    }

    /**
     * 變更每頁筆數
     * @param event {Event}
     * @return {void}
     */
    const handlePageSizeChange = (event) => {
        const pageSize = Number(event.target.value)
        handleClickQuery(1, pageSize)
    }
    /**
     * 點選上一頁
     * @return {void}
     */
    const handlePrevPageOnClick = () => {
        const prevPage = filter.currentPage - 1
        const page = prevPage < 1 ? filter.totalPage : prevPage
        handleClickQuery(page, filter.pageSize)
    }

    /**
     * 點選下一頁
     * @return {void}
     */
    const handleNextPageOnClick = () => {
        const nextPage = filter.currentPage + 1
        const firstPage = 1
        const page = nextPage > filter.totalPage ? firstPage : nextPage
        handleClickQuery(page, filter.pageSize)
    }

    /**
     * 匯出Excel
     * @return {void}
     */
    const handleExportExcel = () => {
        const selectedData = dataList.filter((item) => item.isSelected)
        // 未選取不能匯出 excel
        if (selectedData.length === 0) {
            return
        }

        // 取得欄位 key
        const keys = LONG_TERM_CARE_CENTER.map((item) => item.key)
        // 取得欄位表頭名稱
        const titles = LONG_TERM_CARE_CENTER.map((item) => item.name)
        // 已選取的 row 陣列
        const dataArray = []
        selectedData.forEach((item) => {
            // 將已選取的 row 的欄位資料以陣列呈現
            const data = []
            keys.forEach((key) => {
                // 將當筆資料欄位所對應的值 push 到資料陣列
                data.push(item[key])
            })
            dataArray.push(data)
        })
        // 建立Excel資料
        const sheets = [
            {
                titles,
                data: dataArray,
            },
        ]
        // 匯出Excel
        downloadFile(
            exportExcelAsBlob(sheets),
            '長照中心維護)',
            FileExtensionsEnum.XLSX
        )
    }

    /**
     * 匯出Excel
     * @return {JSX.Element}
     */
    const exportExcelBtn = () => {
        return (
            <Button
                icon={IconEnum.Export}
                text={t('general.export')}
                color={ButtonColorEnum.Success}
                size={ButtonSizeEnum.Large}
                variant={ButtonVariantEnum.Text}
                onClick={handleExportExcel}
            />
        )
    }

    /**
     * 點擊編輯進入編輯模式
     * @param tableRow {object} 當列資料
     * @return {void}
     */
    const handleEditMode = (tableRow) => {
        handleEdit({
            ...tableRow,
            //長照機構代碼
            hospNo: tableRow.hospNo || '',
            //長照機構名稱
            hospName: tableRow.hospName || '',
            //長照機構郵遞區號
            hospZipCode: tableRow.hospZipCode || '',
            //長照機構地址
            hospAddress: tableRow.hospAddress || '',
            //電話區域號碼
            hospAreaCode: tableRow.hospAreaCode || '',
            //醫事機構電話
            hospTel: tableRow.hospTel || '',
        })
    }


    /**
     * 編輯模式下的 input save
     * @param tableRow {object} 當列資料
     * @return {void}
     */
    const handleSaveAction = async (tableRow) => {
        const data = {
            //長照機構代碼
            hospNo: tableRow.hospNo,
            //長照機構名稱
            hospName: tableRow.hospName,
            //長照機構郵遞區號
            hospZipCode: tableRow.hospZipCode,
            //長照機構地址
            hospAddress: tableRow.hospAddress,
            //電話區域號碼
            hospAreaCode: tableRow.hospAreaCode,
            //醫事機構電話
            hospTel: tableRow.hospTel,
            //版本鎖定
            lockVersion: tableRow.lockVersion
        }
        // 修改長照機構代碼維護
        const res = await editRmisTranCareCenter(data)
        // 成功
        if (res.err === ApiErrorStatusEnum.Success) {
            // 儲存當列資料
            handleSave(tableRow)
            // 重新查詢取得清單
            handleClickQuery(filter.currentPage, filter.pageSize)
            // 顯示成功訊息
            showToast({message: '儲存成功', type: AlertTypeEnum.Success})
        } else {
            // 失敗
            showToast({message: res.msg, type: AlertTypeEnum.Error})
        }

    }
    /**
     * 新增一列資料
     * @return {void}
     */
    const handleAddNewRow = async () => {
        console.log('handleAddNewRow')
        const newItem = {
            //長照機構代碼
            hospNo: "",
            //長照機構名稱
            hospName: "",
            //長照機構郵遞區號
            hospZipCode: "",
            //長照機構地址
            hospAddress: "",
            //電話區域號碼
            hospAreaCode: "",
            //醫事機構電話
            hospTel: "",
            //版本鎖定
            lockVersion: 0
        }
        // 新增長照機構代碼維護
        const res = await addRmisTranCareCenter(newItem)
        // 成功
        if (res.err === ApiErrorStatusEnum.Success) {
            // 重新查詢取得清單
            handleClickQuery(filter.currentPage, filter.pageSize)
            // 顯示成功訊息
            showToast({message: '新增成功', type: AlertTypeEnum.Success})
        } else {
            showToast({message: res.msg, type: AlertTypeEnum.Error})
        }
    }

    /**
     * 新增一列空白資料
     * @return {void}
     */
    const addEmptyRow = () => {
        const newItem = {
            //長照機構代碼
            hospNo: "",
            //長照機構名稱
            hospName: "",
            //長照機構郵遞區號
            hospZipCode: "",
            //長照機構地址
            hospAddress: "",
            //電話區域號碼
            hospAreaCode: "",
            //醫事機構電話
            hospTel: "",
            //版本鎖定
            lockVersion: 0
        }
        addNewData(newItem)
    }

    /**
     * 列印
     * @return {void}
     */
    const handlePrintList = () => {
        const printData = dataList.filter((item) => item.isSelected)
        store.dispatch(
            updatePrintContent({
                reportType: FORM_TYPE.longTermCareList,
                printData,
            })
        )
    }

    /**
     * 開啟列印模式則開啟瀏覽器列印視窗
     * 結束列印則關閉列印模式
     */
    useEffect(() => {
        if (isPrintMode) {
            window.print()
            store.dispatch(completePrintMode())
        }
    }, [isPrintMode])

    return (
        <Layout>
            {/*刪除確認彈窗*/}
            <Dialog
                open={showDeletePopup}
                title={"提醒"}
                variant={DialogVariant.DELETE}
                paperStyleProps={{width: DialogSizeEnums.SM}}
                onClose={handleDeletePopupClose}
                onDelete={handleConfirmDelete}
                content={"是否確定刪除？"}
                muiDialogContentProps={{sx: {height: '110px', display: 'flex', alignItems: 'center'}}}
            />
            <div className="flex justify-between p-2 items-center">
                <div className="flex items-center gap-2">
                    {/* 院所代碼/長期照護單位名稱 */}
                    <TextField
                        label="院所代碼/長期照護單位名稱"
                        value={hospitalCode}
                        onChange={handleHospitalCodeChange}
                    />
                    {/* 查詢 */}
                    <Button
                        sx={{fontWeight: 'bold'}}
                        color={ButtonColorEnum.Primary}
                        size={ButtonSizeEnum.Medium}
                        variant={ButtonVariantEnum.Contained}
                        text={t('general.query')}
                        onClick={() => handleClickQuery(1, filter.pageSize)}
                    />
                </div>
                <div>
                    {/* 列印 */}
                    <Button
                        sx={{fontWeight: 'bold'}}
                        color={ButtonColorEnum.Primary}
                        size={ButtonSizeEnum.Medium}
                        variant={ButtonVariantEnum.Contained}
                        text={t('general.print')}
                        onClick={handlePrintList}
                    />
                </div>
            </div>
            {/* 表格 */}
            <CustomTable
                isSelectable={true}
                fields={LONG_TERM_CARE_CENTER}
                dataList={dataList}
                slotButton={exportExcelBtn()}
                toggleAll={handleToggleAll}
                toggleItem={handleToggleItem}
                isCrud={true}
                handleAddRow={handleAddNewRow}
                isEditMode={isEditMode}
                handleEdit={handleEditMode}
                handleSave={handleSaveAction}
                handleCancel={handleCancel}
                addNewData={addEmptyRow}
                deleteRow={handleDeletePopupOpen}
            />
            {/* 分頁 */}
            <div className="flex justify-end">
                <Pagination
                    pageSize={filter.pageSize}
                    totalSize={filter.total}
                    currentPage={filter.currentPage}
                    totalPageSize={filter.totalPage}
                    onPageOnChange={handlePageOnChange}
                    onPageSizeChange={handlePageSizeChange}
                    onPrevPageOnClick={handlePrevPageOnClick}
                    onNextPageOnClick={handleNextPageOnClick}
                />
            </div>
        </Layout>
    )
}

export default LongTermCareCenter
