import React, {useEffect, useState} from 'react'
import {PopupModeEnum, RegsWeekModeEnum} from "edah_utils/dist"

/**
 * 列表項目
 * @param mode {number} 當前為科別或醫生模式
 * @param item {Object} 項目
 * @param options {Array<Object>} 科別或醫生選項
 * @param baseData {Object} 基礎資料
 */
const ListItem = ({mode, item, options, showPopup, baseData}) => {
    // 搜尋文字
    const [searchText, setSearchText] = useState('')
    // 診間名稱
    const [clinicName, setClinicName] = useState('')

    /**
     * 第一次進來時
     */
    useEffect(() => {
        if (item.divNo && item.doctorNo) {
            // 科別或醫生編號
            const no = +mode === RegsWeekModeEnum.Department ? item.doctorNo : item.divNo
            // 取得醫生或科別名字
            const name = +mode === RegsWeekModeEnum.Department ?
                options.find(option => option.userNo === no)?.userName :
                options.find(option => option.divNo === no)?.divName
            // 設定搜尋文字
            setSearchText(name)
            // 設定診間名稱
            setClinicName(item.clinicName)
        }
    }, [])

    /**
     * 點擊Record時
     */
    const handleOnDoubleClick = () => {
        // 顯示編輯視窗
        showPopup({
            //
            weekId: item.weekId,
            // 預約最大看診人次
            maxPatient: item.limit,
            // 現場最大看診人次
            onSiteNumber: item.onsiteLimit,
            // 看診時間(人/分)
            consultationTime: item.slotMin,
            // 預約方式
            reservationMethod: parseInt(item.giveNumType),
            // 調病歷
            canGetMedicalRecords: item.sentChartFlag,
            // 給號方式
            numberGivingMethod: item.noType,
            // 診間開始展班日期
            effectiveDate: item.effectiveDate ? item.effectiveDate.split(' ')[0] : null,
            // 收費別
            chargeType: item.cashType !== null ? `${item.cashType} ${baseData.cashTypeList.find(cashType => cashType.cashType === `${item.cashType}`).cashName}` : '',
            // 預約限制
            reservationLimit: item.reserveLimitType,
            // 診別
            medicalType: item.clinicType,
            // 展班月數
            exhibitionMonth: item.tranMonth,
            // 假日展班
            holidayExhibition: item.holidayexclFlag,
            // 開放網掛/App掛號
            webOrAppBooking: item.webapptFlag,
            // 開放網掛/App取消看診進度
            webOrAppCancel: item.showProcessFlag,
            //網路預約期限
            onlineBookingDeadline: item.webapptDay,
            //看診項目
            consultationItems: item.clinicMarkNo,
            //診間地點
            clinicLocation: item.clinicLocation,
            //注意事項
            notes: item.weekNotice,
            //診間標語
            clinicSlogan: item.clinicSlogan,
            //醫生或科別
            doctorOrDepartment: +mode === RegsWeekModeEnum.Department ? item.doctorNo : item.divNo,
            //診室名稱
            clinicName: item.clinicName,
            //時段
            timeslot: item.apn,
            //星期幾
            week: item.week,
            // 醫師
            doctor: `${item.doctorNo} ${baseData.doctorList.find(doctor => doctor.userNo === item.doctorNo).userName}`,
            // 科別
            department: `${item.divNo} ${baseData.divisionList.find(department => department.divNo === item.divNo).divName}`,

            lockVersion: item.lockVersion

        }, PopupModeEnum.Modify)
    }


    return (
        <div>
            <div className='flex flex-row gap-1 text-sm text-[#3F3F46]'
                 onDoubleClick={handleOnDoubleClick}>
                <div
                    className='w-full w-[80px] max-w-[80px] p-1 border border-[#D4D4D8] rounded-md items-center flex text-left'>
                    {searchText}
                </div>

                {/*輸入診室名稱*/}
                <div className='w-full min-w-[80px] p-1 border border-[#D4D4D8] rounded-md items-center flex text-left'>
                    {clinicName}
                </div>

            </div>
        </div>
    )
}

export default ListItem
