import React, {useState} from 'react'
import {ApiErrorStatusEnum, getLocalStorage, stringIsEmpty} from 'edah_utils/dist';
import CustomTable from '../RSISCustomTable';
import {RSISPersonnelListQueryTable} from '../Constant';
import {queryEmployeeInfo} from "../../../api/v1/Users";
import {usePopup} from "../PopupProvider";
import {filterStringIsEmpty} from "../utils";
import {t} from "i18next"
import {
    TextField,
    Button,
    ButtonVariantEnum,
    ButtonColorEnum,
    ButtonSizeEnum,
    Dialog,
    DialogVariant,
    DialogSizeEnums,
    AlertTypeEnum
} from "edah-component/dist";

/**
 * 列表組件
 * @param item {Object} 列表項目
 * @param index {number} 列表索引
 * @param handleOnClick {function} 點擊事件
 * @return {JSX.Element}
 */
const ListItem = ({item, index, handleOnClick,}) => {
    /**
     * 點擊列表項目事件，帶回醫師資料
     * @return {void}
     */
    const handleOnItemClick = () => {
        handleOnClick(item)
    }

    /**
     * 過濾空字串
     * @param value {string} 字串
     * @return {string} 過濾後的字串
     */
    const getValidString = (value) => {
        let str = ''
        stringIsEmpty(value) ? str = '' : str += value
        return str
    }

    return (
        <li className='hover:cursor-pointer' key={index} onClick={handleOnItemClick}>
            <div>{item.userNo}</div>
            <div>{item.depNo}</div>
            <div>{item.userName}</div>
            <div>{`${getValidString(item.hospNo)} ${getValidString(item.hospName)}`}</div>
        </li>
    )
}

/**
 * 人員清單查詢彈窗
 * @param filterEmp {boolean} 是否過濾專職人員及非專職人員，預設為false
 * @param handleOnClose {function} 關閉彈窗
 * @param handleOnUpdate {function} 更新事件
 * @return {JSX.Element}
 */
const QueryPersonList = ({filterEmp = false, handleOnClose, handleOnUpdate}) => {
    // Show Toast
    const {showToast} = usePopup();
    // 醫師代號/工號
    const [doctorNo, setDoctorNo] = useState('');
    // 姓名
    const [name, setName] = useState('');
    // 人員清單
    const [dataList, setDataList] = useState([]);
    // 分頁屬性
    const [paginationProps, setPaginationProps] = useState({
        pageNum: 1,
        pageSize: 10,
        totalItemSize: 0,
        totalPageSize: 0,
        currentPageItemSize: 0,
        fn: () => {
        }
    });

    /**
     * 查詢
     * @return {void}
     */
    const handleOnSearch = () => getCreateUserList()

    /**
     * 員工/醫師代碼改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleDoctorNoOnChange = (e) => setDoctorNo(e.target.value)

    /**
     * 姓名改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleNameOnChange = (e) => setName(e.target.value)

    /**
     * 過濾專職人員及非專職人員，僅顯示本院院區專職人員及員工，專職人員有執照，員工若為本院及是空字串
     * @param data {Array} 人員列表
     * @return {Array} 過濾後的人員列表
     */
    const getFilterEmployeeList = (data) => {
        // 取得登入醫院院區全名
        const campusId = getLocalStorage('campusId')
        // 返回執登院所符合campusFullName的人員及空字串的員工
        return data.filter((item) => {
            // 若hospNo、hospName為空字串，則表示為本院員工
            return item.hospNo === campusId || (filterStringIsEmpty(item.hospNo) && filterStringIsEmpty(item.hospName))
        })
    }

    /**
     * 取得醫師非醫師人員清單
     * @return {void}
     */
    const getCreateUserList = () => {
        // 醫師代號/工號及姓名皆為空時，顯示提示訊息
        if (stringIsEmpty(doctorNo) && stringIsEmpty(name)) {
            showToast({message: '請輸入查詢值!', type: AlertTypeEnum.Warning})
            return
        }

        queryEmployeeInfo({
            userNo: doctorNo,
            userName: name,
            medicalStaff: '',
            activeFlag: true
        }).then((res) => {
            const {err, data, msg} = res
            //執行成功
            if (err === ApiErrorStatusEnum.Success) {
                // 如果資料長度為0
                if (data.length === 0) {
                    // 清空人員列表
                    setDataList([])
                    showToast({message: '找不到該員工或醫師，請重新輸入!', type: AlertTypeEnum.Warning})
                    return
                }

                // 是否過濾專職人員及非專職人員
                if (filterEmp) {
                    // 取得過濾後的人員列表
                    const filteredEmpList = getFilterEmployeeList(data)
                    // 若過濾後的人員列表為空，則顯示提示訊息
                    if (filteredEmpList.length === 0) {
                        // 清空人員列表
                        setDataList([])
                        showToast({message: t('RSIS.toast.docIDCheck'), type: AlertTypeEnum.Warning})
                        return
                    }
                    // 人員列表按照代號由小到大排序
                    const sortedEmpList = filteredEmpList.sort((a, b) => a.userNo - b.userNo)
                    // 設定過濾後的人員列表
                    setDataList(sortedEmpList)
                } else {

                    // 人員列表按照代號由小到大排序
                    const sortedEmpList = data.sort((a, b) => a.userNo - b.userNo)
                    // 設定排序後的人員列表
                    setDataList(sortedEmpList)
                }
            } else { //錯誤時
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    return (
        <Dialog
            open={true}
            title={t('RSIS.Popup.QueryPersonList')}
            content={
                <div className='px-6 pb-6'>
                    <div className='flex justify-start items-center pb-4 space-x-2'>
                        {/*醫師代號/工號*/}
                        <TextField
                            label={'醫師代號/工號'}
                            value={doctorNo}
                            onChange={handleDoctorNoOnChange}
                        />
                        {/*姓名*/}
                        <TextField
                            label="姓名"
                            value={name}
                            onChange={handleNameOnChange}
                        />
                        <div className="source flex flex-row items-center justify-start mr-4">
                            {/*查詢按鈕*/}
                            <Button color={ButtonColorEnum.Primary} variant={ButtonVariantEnum.Contained}
                                    size={ButtonSizeEnum.Medium} text={t('general.query')}
                                    onClick={handleOnSearch}/>
                        </div>
                    </div>
                    <hr className="pb-6"/>
                    <CustomTable
                        fields={RSISPersonnelListQueryTable.key} dataList={dataList} advancedSearch={true}
                        ListItem={(props) => (<ListItem{...props} handleOnClick={handleOnUpdate}/>)}
                        type={'RSISQueryPersonListTable'}
                        paginationProps={paginationProps}/>
                </div>
            }
            variant={DialogVariant.CONFIRM}
            onClose={handleOnClose}
            paperStyleProps={{width: DialogSizeEnums.LG}}
            muiDialogActionsProps={{sx: {display: 'none'}}}
            muiDialogContentProps={{dividers: true}}
        />
    )
}

export default QueryPersonList
