import React from 'react'

/**
 * 支援科別維護
 * @returns {JSX.Element}
 */
function SupportDivMaintainLayout() {
  return (
    <div>SupportDivMaintainLayout</div>
  )
}

export default SupportDivMaintainLayout