import React, {useEffect, useState} from 'react'
import ListItem from './ListItem'
import {RegsWeekModeEnum} from "edah_utils/dist"
import {t} from "i18next"
import {Button, ColorEnum, ButtonSizeEnum, ButtonVariantEnum} from 'edah-component/dist'

/**
 * 列表卡片
 * @param data {Array<Object>}
 * @param mode {RegsWeekModeEnum}
 * @param options {Array<Object>}
 * @param week {number} 星期幾
 * @param timeslot {number}
 * @param isAddAppointment {Boolean} 是否顯示新增問診
 * @param onAddAppointment {Function} 新增問診函式
 * @param showPopup
 * @param baseData {Object} 基礎資料
 */
const ListCard = ({
                      data,
                      mode,
                      options,
                      week,
                      timeslot,
                      isAddAppointment,
                      onAddAppointment,
                      showPopup,
                      baseData
                  }) => {
    // 列表資料
    const [list, setList] = useState(data)

    /**
     * 顯示編輯視窗
     * @param e {Event} 事件
     * @return {void}
     */
    const handleBlockOnContextMenu = (e) => {
        //防止右鍵點擊時觸發右鍵選單
        e.preventDefault()
        //右鍵點擊時
        if (e.nativeEvent.button === 2) {
            //透過父層函式新增問診
            onAddAppointment(week, timeslot)
        }
    }

    /**
     * 點擊新增問診時
     */
    const handleNewAppointmentOnClick = () => {
        showPopup({
            //星期幾
            week: week,
            //時段
            timeslot: timeslot
        })
    }

    /**
     * 當滑鼠右鍵點擊新問診時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleNewAppointmentOnContextMenu = (e) => e.preventDefault()

    /**
     * 監聽當前模式改變時重新設定列表
     */
    useEffect(() => {
        //重新清空列表
        setList(data || [])
    }, [mode])

    /**
     * 當列表資料改變時更新列表
     */
    useEffect(() => {
        setList(data)
    }, [data])

    return (
        <div
            className='w-full min-h-[200px] p-1 border-2 bg-white rounded-lg border-gray-300 flex flex-col relative overflow-y-scroll gap-1'
            onContextMenu={(e) => handleBlockOnContextMenu(e)}>
            <div className='flex flex-col'>
                <div className='flex flex-row gap-1'>
                    <div className='px-2 w-full w-[80px] max-w-[80px] text-start h-6 text-base'>
                        {+mode === RegsWeekModeEnum.Department ? t('general.doctor') : t('general.department')}
                    </div>
                    <div className='w-full min-w-[80px] text-start h-6 text-base'>{t("Regs.general.clinicName")}</div>
                </div>
            </div>
            {
                isAddAppointment && (

                    <Button sx={{marginX: "1.5rem", paddingY: '0.25rem'}}
                            color={ColorEnum.Primary} variant={ButtonVariantEnum.Contained}
                            size={ButtonSizeEnum.Large}
                            onContextMenu={(e) => handleNewAppointmentOnContextMenu(e)}
                            onClick={handleNewAppointmentOnClick}>{t('page.MonthMaintenance.NewAppointment')}</Button>
                )
            }
            {
                list.map((item, index) =>
                    <ListItem
                        key={index}
                        options={options}
                        mode={mode}
                        item={item}
                        showPopup={showPopup}
                        baseData={baseData}/>)
            }
        </div>
    )
}
export default ListCard
