import React from "react"
import {t} from "i18next"
import {Button} from 'edah-component/dist'

/**
 * 自訂彈出視窗
 * @param title {String} 標題
 * @param content {JSX.Element} 內容
 * @param closePopupButtonOnClick {Function} 關閉按鈕點擊事件
 * @param width {String} 視窗寬度
 * @return {JSX.Element}
 */
export const BasePopup = ({title, content, closePopupButtonOnClick, width="1116px"}) =>
    <div
        className="fixed top-[-8px] left-0 pt-[60px] flex items-start justify-center w-[100vw] h-full bg-[rgba(0,0,0,0.3)] z-50 overflow-scroll">
        <div className={`w-[${width}] bg-white rounded-[6px]`}>
            <div
                className="title w-full flex flex-row items-center justify-between px-6 py-4 mb-4 border-b-[1px] border-[rgba(0,0,0,0.15)]">
                {/*標題*/}
                <h3 className="text-[20px]">{title}</h3>
                {/*關閉按鈕*/}
                <Button color={'primary'} variant={'text'} size={'large'} sx={{fontSize:'1rem'}} onClick={closePopupButtonOnClick}>{t('general.close')}</Button>
            </div>
            {content}
        </div>
    </div>


