import React from 'react'
import SupportOrgMaintain from '../../components/RSIS/SupportOrgMaintain/SupportOrgMaintain'
import { PopupProvider } from '../../components/RSIS/PopupProvider'


/**
 * 支援機構維護
 * @returns {JSX.Element}
 */
function SupportOrgMaintainLayout() {
  return (
    <PopupProvider>
      <SupportOrgMaintain />
    </PopupProvider>
  )
}

export default SupportOrgMaintainLayout