// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vhiCardContainer {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
}
.vhiCardContainer .vhiCard {
  width: 740px;
  background-color: #FFFFFF;
  border-radius: 4px;
  padding-bottom: 32px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
}
.vhiCardContainer .qrCode {
  width: 100%;
  max-width: 612px;
  padding: 28px 0;
  margin: 0 auto;
  margin-bottom: 16px;
  border: 2px solid #D4D4D8;
  border-radius: 4px;
}
.vhiCardContainer .remoteRequest {
  width: 100%;
  max-width: 612px;
  margin: 0 auto;
  margin-bottom: 16px;
  border: 2px solid #D4D4D8;
  border-radius: 4px;
}`, "",{"version":3,"sources":["webpack://./src/components/ICCard/VHICard.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,aAAA;EACA,oCAAA;AACF;AAAE;EACE,YAAA;EACA,yBAAA;EAGA,kBAAA;EACA,oBAAA;EACA,4CAAA;AAEJ;AAAE;EACE,WAAA;EACA,gBAAA;EACA,eAAA;EACA,cAAA;EACA,mBAAA;EACA,yBAAA;EAGA,kBAAA;AAEJ;AAAE;EACE,WAAA;EACA,gBAAA;EACA,cAAA;EACA,mBAAA;EACA,yBAAA;EAGA,kBAAA;AAEJ","sourcesContent":[".vhiCardContainer {\n  position: absolute;\n  top: 0;\n  left: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100vw;\n  height: 100vh;\n  background-color: rgba(#000, 0.3);\n  .vhiCard {\n    width: 740px;\n    background-color: #FFFFFF;\n    -webkit-border-radius: 4px;\n    -moz-border-radius: 4px;\n    border-radius: 4px;\n    padding-bottom: 32px;\n    box-shadow: 0px 10px 20px rgba(0,0,0, 0.3);\n  }\n  .qrCode {\n    width: 100%;\n    max-width: 612px;\n    padding: 28px 0;\n    margin: 0 auto;\n    margin-bottom: 16px;\n    border: 2px solid #D4D4D8;\n    -webkit-border-radius: 4px;\n    -moz-border-radius: 4px;\n    border-radius: 4px;\n  }\n  .remoteRequest {\n    width: 100%;\n    max-width: 612px;\n    margin: 0 auto;\n    margin-bottom: 16px;\n    border: 2px solid #D4D4D8;\n    -webkit-border-radius: 4px;\n    -moz-border-radius: 4px;\n    border-radius: 4px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
