import React, {useEffect, useState} from 'react'
import CustomTable from '../../components/ReferralSystem/CustomTable'
import {Pagination} from '../../components/Pagination/Pagination'
import {RETURN_APPOINTMENT} from '../../constants/referralSystem/maintainAreaHeader'
import {ApiErrorStatusEnum,} from 'edah_utils/dist'
import {queryTranInToOpd} from '../../api/v1/Rmis'
import useToast from '../../hooks/useToast'
import {AlertTypeEnum, Dialog, DialogVariant, Field, TextField} from "edah-component/dist";

/**
 * 轉診回診清單內容
 * @param patientId {string} 病歷號
 * @param patientName {string} 姓名
 * @param encounterDate {string} 就醫日期
 * @param onClose {function} 關閉
 * @param onConfirm {function} 確認
 * @returns {Element}
 * @constructor
 */
const ReturnAppointmentModalContent = ({
                                           patientId,
                                           patientName,
                                           encounterDate,
                                           onClose,
                                           onConfirm,
                                       }) => {
    // 表格相關選項
    const [dataList, setDataList] = useState([])
    // 分頁
    const [filter, setFilter] = useState({
        totalPage: 1,
        total: 0,
        currentPage: 1,
        pageSize: 10,
    })
    // 病歷號
    const [medicalRecord, setMedicalRecord] = useState('')
    // 姓名
    const [name, setName] = useState('')
    // 顯示 toast
    const showToast = useToast()

    /**
     * 病歷號 onChange
     * @param e {object} event
     * @return {void}
     */
    const handleMedicalRecordChange = (e) => setMedicalRecord(e.target.value)
    
    /**
     * 姓名 onChange
     * @param e {object} event
     * @return {void}
     */
    const handleNameChange = (e) => setName(e.target.value)

    /**
     * 取得轉診回診清單
     * @param {number} pageNum 頁碼
     * @param {number} pageSize 每頁筆數
     * @return {void}
     */
    const getTranInToOpdList = (pageNum, pageSize) => {
        // 轉診回診清單
        queryTranInToOpd({patientId, encounterDate, pageNum, pageSize}).then(
            (res) => {
                // 成功
                if (res.err === ApiErrorStatusEnum.Success) {
                    // 取得的轉診回診清單預設為未勾選
                    const list = res.data?.dataList?.map((item) => {
                        return {
                            ...item,
                            isSelected: false,
                        }
                    })
                    // 查無資料
                    if (list.length === 0) {
                        showToast({
                            message: '查無資料',
                            type: AlertTypeEnum.Warning,
                        })
                    }
                    // 設定轉診回診清單
                    setDataList(list)
                    // 設定分頁
                    setFilter({
                        ...filter,
                        total: res.data.totalItemSize,
                        currentPage: pageNum,
                        pageSize,
                        totalPage: res.data.totalPageSize,
                    })
                } else {
                    // 失敗
                    showToast({message: res.msg, type: AlertTypeEnum.Error})
                }
            }
        )
    }

    /**
     * 確定
     * @return {void}
     */
    const handleConfirm = () => {
        const data = dataList.filter((item) => item.isSelected)
        onConfirm(data)
    }

    /**
     * 選擇單筆資料
     * @param isChecked {boolean} 是否勾選
     * @param selectedItem {object} 勾選的資料
     * @param index {number} 勾選項目的index
     */
    const toggleItem = (isChecked, selectedItem, index) => {
        const modifiedList = dataList.map((item) => {
            return {
                ...item,
                isSelected:
                    selectedItem.encounterId === item.encounterId
                        ? isChecked
                        : false,
            }
        })
        setDataList(modifiedList)
    }

    /**
     * 點選 pagination 頁碼
     * @param page {string} 目前頁碼
     * @return {void}
     */
    const handlePageOnChange = (page) => {
        const currentPage = Number(page)
        getTranInToOpdList(currentPage, filter.pageSize)
    }

    /**
     * 變更每頁筆數
     * @param event {object} 事件
     * @return {void}
     */
    const handlePageSizeChange = (event) => {
        const pageSize = Number(event.target.value)
        getTranInToOpdList(1, pageSize)
    }
    /**
     * 點選上一頁
     * @return {void}
     */
    const handlePrevPageOnClick = () => {
        const prevPage = filter.currentPage - 1
        const page = prevPage < 1 ? filter.totalPage : prevPage
        getTranInToOpdList(page, filter.pageSize)
    }

    /**
     * 點選下一頁
     * @return {void}
     */
    const handleNextPageOnClick = () => {
        const nextPage = filter.currentPage + 1
        const firstPage = 1
        const page = nextPage > filter.totalPage ? firstPage : nextPage
        getTranInToOpdList(page, filter.pageSize)
    }

    useEffect(() => {
        getTranInToOpdList(1, filter.pageSize)
    }, [])

    return (
        <Dialog
            open={true}
            title={"轉診回診清單"}
            variant={DialogVariant.CONFIRM}
            paperStyleProps={{width: '80%'}}
            onClose={onClose}
            onConfirm={handleConfirm}
            content={
                <>
                    {/* 病歷號、姓名 */}
                    <div className="flex justify-between p-2 items-center">
                        <div className="flex items-center">
                            <Field label="病歷號" labelWidth={75}>
                                <TextField
                                    value={medicalRecord || patientId}
                                    onChange={handleMedicalRecordChange}
                                    disabled
                                />
                            </Field>
                            <div>
                                <Field label="姓名" labelWidth={75}>
                                    <TextField
                                        value={name || patientName}
                                        onChange={handleNameChange}
                                        disabled
                                    />
                                </Field>
                            </div>
                        </div>
                    </div>
                    {/* 表格 */}
                    <CustomTable
                        isSelectable={true}
                        fields={RETURN_APPOINTMENT}
                        dataList={dataList}
                        toggleItem={toggleItem}
                        isSingleSelect={true}
                    />
                    {/* 分頁 */}
                    <div className="flex justify-end">
                        <Pagination
                            pageSize={filter.pageSize}
                            totalSize={filter.total}
                            currentPage={filter.currentPage}
                            totalPageSize={filter.totalPage}
                            onPageOnChange={handlePageOnChange}
                            onPageSizeChange={handlePageSizeChange}
                            onPrevPageOnClick={handlePrevPageOnClick}
                            onNextPageOnClick={handleNextPageOnClick}
                        />
                    </div>
                    {/* 注意事項 */}
                    <div className="px-2 pt-4 pb-4 text-left space-y-2">
                        <div>
                            ※若病人為電子轉診病人，請勾選後帶回。
                        </div>
                        <div>※打勾處反灰，代表此單已被使用。</div>
                        <div>
                            ※取消打勾後，按確定，即可取消使用此單表。
                        </div>
                    </div>
                </>
            }
            muiDialogContentProps={{dividers: true}}
        />
    )
}
/**
 * 轉診回診清單
 * @param onClose {function} 關閉
 * @param patientInfo {object} 病歷號、姓名、就醫日期
 * @param onConfirm {function} 確認
 * @returns {Element}
 * @constructor
 */
const ReturnAppointmentModal = ({onClose, patientInfo, onConfirm}) => {
    return (
        <ReturnAppointmentModalContent
            patientId={patientInfo.patientId}
            patientName={patientInfo.patientName}
            encounterDate={patientInfo.encounterDate}
            onConfirm={onConfirm}
            onClose={onClose}
        />
    )
}

export default ReturnAppointmentModal
