import { useSelector } from 'react-redux'
import './print.scss'

/**
 * 長照中心信封
 * @returns {JSX.Element}
 */
const PrintLongTermCareEnvelope = () => {
    const printData = useSelector((state) => state.print.printData)
    return (
        <>
            {printData?.map((item) => {
                return (
                    <div className="envelope-cover-basic">
                        <div className="border-[#D4D4D8] border p-8 rounded-md">
                            <div className="border-b border-[#111111]">
                                <div>雙掛號</div>
                                <div className='ml-3'>{item?.careHospName} 收</div>
                            </div>
                            <div>
                                <div>{item?.careHospZipCode}</div>
                                <div>{item?.careHospAddress}</div>
                            </div>
                        </div>
                    </div>
                )
            })
            }
        </>

    )
}

export default PrintLongTermCareEnvelope
