import React, {useMemo, useState} from 'react'
import CustomTable from '../../components/ReferralSystem/CustomTable'
import ReferralFormSearchBar from '../../components/ReferralSystem/ReferralForm/ReferralFormSearchBar'
import {REFERRAL_FORM_UPLOAD_FIELDS} from '../../constants/referralSystem/referralForm'
import AttachmentModal from '../../components/ReferralSystem/ReferralForm/AttachmentModal'
import {
    ApiErrorStatusEnum,
    BlobTypeEnum,
    downloadFile,
    exportExcelAsBlob,
    FileExtensionsEnum,
    time2String,
    arrayIsEmpty
} from 'edah_utils/dist'
import {Pagination} from '../../components/Pagination/Pagination'
import useToast from '../../hooks/useToast'
import {DATETIME_FORMAT} from '../../constants/common'
import {queryTranCaseToTranApiBySearch, tranApiToXml} from '../../api/v1/Rmis'
import {t} from 'i18next'
import {
    AlertTypeEnum,
    RadioGroup,
    SizeEnum,
    Select,
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    IconEnum, SelectWidthEnum
} from "edah-component/dist"

/**
 * 電子轉診單_上傳 轉診類別
 */
export const TRAN_TYPE = [
    {
        name: '開立電子轉診單',
        value: '1',
    },
    {
        name: '回覆電子轉診單',
        value: '2',
    },
    {
        name: '轉回或轉至適當院所',
        value: '3',
    },
    {
        name: '刪除電子轉診單',
        value: '8',
    },
    {
        name: '刪除回復電子轉診單',
        value: '7',
    },
    {
        name: '無需轉回註記作業',
        value: '6',
    },
]
// 上傳選項
const SEND_FLAG_LIST = [
    {
        value: '',
        name: '全部',
    },
    {
        value: '0',
        name: '未上傳',
    },
    {
        value: '1',
        name: '已上傳',
    },
]
// 上傳結果選項
const UPLOAD_FLAG_LIST = [
    {
        value: '',
        name: '全部',
    },
    {
        value: '0',
        name: '錯誤',
    },
    {
        value: '1',
        name: '正確',
    },
]

/**
 * 電子轉診單_上傳
 * @return {JSX.Element}
 */
const ReferralFormUpload = () => {
    /**
     * pagination
     */
    const [filter, setFilter] = useState({
        // 總頁數
        totalPageSize: 1,
        // 總筆數
        totalItemSize: 0,
        // 當前頁碼
        pageNum: 1,
        // 每頁筆數
        pageSize: 10,
    })

    const showToast = useToast()

    /**
     * 選取的附件
     */
    const [activeAttachment, setActiveAttachment] = useState({})

    /**
     * 附件視窗
     */
    const [isShowAttachmentModal, setIsShowAttachmentModal] = useState(false)

    /**
     * 電子轉診單清單
     */
    const [dataList, setDataList] = useState([])

    /**
     * 查詢欄位
     */
    const [searchParams, setSearchParams] = useState({
        // 有效日期(起日)
        tranStartDate: '',
        // 有效日期(迄日)
        tranEndDate: '',
        // 身分證號
        idNo: '',
        // 轉診類別
        rmisTranType: TRAN_TYPE[0].value, // 預設為"開立電子轉診單"
        // 上傳選項
        sendFlag: SEND_FLAG_LIST[1].value, // 預設為"未上傳"
        // 上傳結果
        uploadFlag: '',
    })

    /**
     * 匯出
     * @return {void}
     */
    const exportText = () => {
        // 已勾選的資料
        const selectedDataList = dataList.filter((item) => item.isSelected)
        // 無選取資料則不匯出
        if (selectedDataList.length === 0) {
            return
        }
        // 匯出資料陣列
        const dataArray = []
        selectedDataList.forEach((item) => {
            const data = []
            // 遍歷將當列欄位資料，將資料從object轉換成陣列
            REFERRAL_FORM_UPLOAD_FIELDS.forEach((field) => {
                switch (field.key) {
                    case 'orderDate':
                    case 'rmisTranApiDate':
                        data.push(field.format(item[field.key])) // 時間格式
                        break
                    default:
                        data.push(item[field.key])
                }
            })
            dataArray.push(data)
        })
        // excel sheet資料
        const sheets = [
            {
                titles: REFERRAL_FORM_UPLOAD_FIELDS.map((item) => item.name),
                data: dataArray,
            },
        ]
        // 下載excel
        downloadFile(
            exportExcelAsBlob(sheets),
            '電子轉診單',
            FileExtensionsEnum.XLSX
        )
    }

    /**
     * 插入CustomTable的按鈕
     * @return {JSX.Element} 匯出Text按鈕
     */
    const exportTextButton = () => {
        return (
            <Button
                color={ButtonColorEnum.Success}
                icon={IconEnum.Export}
                size={ButtonSizeEnum.Large}
                text={t('general.export')}
                variant={ButtonVariantEnum.Text}
                onClick={exportText}
            />
        )
    }

    /**
     * 關閉上傳附件視窗
     * @return {void}
     */
    const closeAttachmentModal = () => {
        setActiveAttachment({})
        setIsShowAttachmentModal(false)
    }

    /**
     * 開啟上傳附件視窗
     * @param referralCase {object} 轉診資料
     * @return {void}
     */
    const showAttachmentModal = (referralCase) => {
        setActiveAttachment(referralCase)
        setIsShowAttachmentModal(true)
    }

    /**
     * 附件上傳成功後重新取得電子轉診清單
     * @return {void}
     */
    const handleUploadAttachmentSuccess = () => {
        closeAttachmentModal()
        getETranList(filter.pageNum, filter.pageSize)
    }

    /**
     * 附件按鈕
     * @param referralCase {object} 要上傳附件的轉診資料
     * @return {JSX.Element} 附件按鈕
     */
    const Attachment = (referralCase) =>
        // "轉診類別" 選為 "回覆電子轉入單"時才顯示附件按鈕
        referralCase.rmisTranType === TRAN_TYPE[1].value && (
            <Button
                sx={{fontWeight: 'bold'}}
                color={ButtonColorEnum.Secondary}
                size={ButtonSizeEnum.Medium}
                text={t('general.attachment')}
                variant={ButtonVariantEnum.Outlined}
                onClick={() => showAttachmentModal(referralCase)}
            />
        )

    /**
     * 傳入CustomTable的欄位加上附件按鈕
     */
    const generateFields = useMemo(() => {
        return [
            ...REFERRAL_FORM_UPLOAD_FIELDS,
            {
                key: 'action',
                name: '',
                render: Attachment,
                className: 'w-[100px]',
            },
        ]
    })

    /**
     * 更新轉診類別
     * @param e {Event}
     * @return {void}
     */
    const updateTranType = (e) => setSearchParams({...searchParams, rmisTranType: e.target.value})

    /**
     * 更新上傳選項
     * @param value {string}
     * @return {void}
     */
    const sendFlagOnChange = (value) => {
        setSearchParams((prev) => ({
            ...prev,
            sendFlag: value,
        }))
        if (value !== SEND_FLAG_LIST[2].value) {
            setSearchParams((prev) => ({
                ...prev,
                uploadFlag: '',
            }))
        }
    }

    /**
     * 更新上傳結果
     * @param string {string}
     * @return {void}
     */
    const uploadFlagOnChange = (value) => {
        setSearchParams({
            ...searchParams,
            uploadFlag: value,
        })
    }

    /**
     * 更新ReferralFormSearchBar裡面的查詢條件
     * @param params {Object}
     * @return {void}
     */
    const searchParamsOnChange = (params) => {
        setSearchParams({
            ...searchParams,
            tranStartDate: time2String(params.effStartDate, DATETIME_FORMAT),
            tranEndDate: time2String(params.effEndDate, DATETIME_FORMAT),
            idNo: params.idNo,
        })
    }

    /**
     * 點選查詢
     * @return {void}
     */
    const handleQueryETranList = () => {
        getETranList(1, filter.pageSize)
    }

    /**
     * 取得電子轉診單
     * @param {number} pageNum
     * @param {number} pageSize
     * @return {void}
     */
    const getETranList = (pageNum, pageSize) => {
        const params = {
            ...searchParams,
            tranStartDate: time2String(
                searchParams.tranStartDate,
                DATETIME_FORMAT
            ),
            tranEndDate: time2String(searchParams.tranEndDate, DATETIME_FORMAT),
            sendFlag: searchParams.sendFlag,
            uploadFlag: searchParams.uploadFlag,
            pageNum,
            pageSize,
        }
        // 查詢電子轉診單
        queryTranCaseToTranApiBySearch(params).then((res) => {
            // 成功
            if (res.err === ApiErrorStatusEnum.Success) {
                const list =
                    res.data?.map((item) => ({
                        ...item,
                        isSelected: false,
                        rmisTranType: searchParams.rmisTranType,
                    })) || []
                // 查無資料提醒
                if (list.length === 0) {
                    showToast({
                        message: '查無資料',
                        type: AlertTypeEnum.Warning,
                    })
                }
                // 設定電子轉診單清單
                setDataList(list)
                // 設定分頁資料
                setFilter({
                    ...filter,
                    totalItemSize: res.totalItemSize,
                    totalPageSize: res.totalPageSize,
                    pageSize,
                    pageNum,
                })
            } else {
                // 失敗
                showToast({message: res.msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 換頁
     * @param {number} page
     * @return {void}
     */
    const onPageOnChange = (page) => {
        const currentPage = Number(page)
        getETranList(currentPage, filter.pageSize)
    }

    /**
     * 更新每頁筆數
     * @param {event} event
     * @return {void}
     */
    const onPageSizeChange = (event) => {
        const pageSize = Number(event.target.value)
        getETranList(1, pageSize)
    }

    /**
     * 下一頁
     * @return {void}
     */
    const onPrevPageOnClick = () => {
        const prevPage = filter.currentPage - 1
        // 頁碼小於1則顯示第一頁
        const page = prevPage < 1 ? filter.totalPage : prevPage
        getETranList(page, filter.pageSize)
    }

    /**
     * 上一頁
     * @return {void}
     */
    const onNextPageOnClick = () => {
        const nextPage = filter.currentPage + 1
        const firstPage = 1
        // 頁碼大於總頁數則顯示第一頁
        const page = nextPage > filter.totalPage ? firstPage : nextPage
        getETranList(page, filter.pageSize)
    }

    /**
     * 全選資料
     * @param isAllSelected {boolean} 是否全選
     * @return {void}
     */
    const toggleAll = (isAllSelected) => {
        const modifiedList = dataList.map((item) => ({
            ...item,
            isSelected: isAllSelected,
        }))
        setDataList(modifiedList)
    }

    /**
     * 選擇單筆資料
     * @param isChecked {boolean} 是否勾選
     * @param selectedItem {object} 勾選的資料
     * @param index {number} 勾選項目的index
     * @return {void}
     */
    const toggleItem = (isChecked, selectedItem, index) => {
        const modifiedList = dataList.map((item) => {
            if (selectedItem.encounterId === item.encounterId) {
                return {
                    ...item,
                    isSelected: isChecked,
                }
            }
            return item
        })
        setDataList(modifiedList)
    }

    /**
     * 上傳附件
     * @return {Element}
     */
    const UploadDropdowns = () => {
        return (
            <div className="flex flex-row items-center justify-start mr-4 text-left">
                <div className="mr-4">上傳選項:</div>
                <Select
                    data={{
                        label: "上傳選項",
                        options: SEND_FLAG_LIST.map((item) => ({
                            value: item.value,
                            label: item.name
                        }))
                    }}
                    value={searchParams.sendFlag}
                    showLabel={false}
                    width={SelectWidthEnum.Small}
                    onChange={sendFlagOnChange}
                    displayEmpty={true}
                />
                {searchParams.sendFlag === SEND_FLAG_LIST[2].value && (
                    <div className="flex flex-row items-center justify-start">
                        <div className="ml-2 mr-4">上傳結果:</div>
                        <Select
                            data={{
                                label: '上傳結果',
                                options: UPLOAD_FLAG_LIST.map((item) => ({
                                    value: item.value,
                                    label: item.name
                                }))
                            }}
                            value={searchParams.uploadFlag}
                            onChange={uploadFlagOnChange}
                            width={SelectWidthEnum.Small}
                            showLabel={false}
                            displayEmpty={true}
                        />
                    </div>
                )}
            </div>
        )
    }

    /**
     * 上傳按鈕: 點選後下載XML檔案
     * @return {void}
     */
    const handleUploadOnClick = () => {
        // 已勾選的資料
        const encounterIdList = dataList
            .filter((item) => item.isSelected)
            .map((item) => ({encounterId: item.encounterId}))
        if(arrayIsEmpty(encounterIdList)) {
            return
        }
        // 上傳電子轉診XML
        tranApiToXml({
            rmisTranType: searchParams.rmisTranType,
            encounterIdData: encounterIdList,
        }).then((res) => {
            // 成功
            if (res.err === ApiErrorStatusEnum.Success) {
                // 下載XML
                const blob = new Blob([res.data.data], {
                    type: BlobTypeEnum.XML,
                })
                downloadFile(blob, res.data.fileName, FileExtensionsEnum.XML)
            } else {
                // 失敗
                showToast({message: res.msg, type: AlertTypeEnum.Error})
            }
        })
    }

    return (
        <div className="p-2 w-full h-[calc(100vh-98px)] overflow-auto">
            <div className="flex justify-between text-center p-2">
                <div>
                    {/* 查詢欄位 */}
                    <ReferralFormSearchBar
                        handleQuery={handleQueryETranList}
                        searchParamsOnChange={searchParamsOnChange}
                        slotElement={<UploadDropdowns/>}
                    />
                    {/* 轉診類別 */}
                    <div className="flex w-full">
                        <RadioGroup
                            sx={{display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', ml: 3}}
                            labelProps={{text: "轉診類別"}}
                            size={SizeEnum.Medium}
                            value={searchParams.rmisTranType}
                            optionProps={{options: TRAN_TYPE.map((item) => ({label: item.name, value: item.value}))}}
                            onChange={updateTranType}/>
                    </div>
                </div>
                {/* 上傳按鈕 */}
                <div>
                    <Button
                        sx={{fontWeight: 'bold', height: '2.5rem'}}
                        color={ButtonColorEnum.Primary}
                        size={ButtonSizeEnum.Large}
                        variant={ButtonVariantEnum.Contained}
                        text={t('general.upload')}
                        onClick={handleUploadOnClick}
                    />
                </div>
            </div>
            {/* 電子轉診單清單 */}
            <CustomTable
                fields={generateFields}
                slotButton={exportTextButton()}
                dataList={dataList}
                isSelectable={true}
                toggleAll={toggleAll}
                toggleItem={toggleItem}
            />
            {/* 分頁 */}
            <div className="flex justify-end">
                <Pagination
                    pageSize={filter.pageSize}
                    totalSize={filter.totalItemSize}
                    currentPage={filter.pageNum}
                    totalPageSize={filter.totalPageSize}
                    onPageOnChange={onPageOnChange}
                    onPageSizeChange={onPageSizeChange}
                    onPrevPageOnClick={onPrevPageOnClick}
                    onNextPageOnClick={onNextPageOnClick}
                />
            </div>
            {/* 上傳附件視窗 */}
            {isShowAttachmentModal && (
                <AttachmentModal
                    onClose={closeAttachmentModal}
                    onUploadSuccess={handleUploadAttachmentSuccess}
                    info={activeAttachment}
                />
            )}
        </div>
    )
}

export default ReferralFormUpload
