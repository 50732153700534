import React from "react";
import { ReactComponent as Check } from "../../assets/images/icons/checkbox.svg";
import {arrayIsEmpty, fuzzySearchObjects} from "edah_utils/dist";

const CloseCaseList = ({ data, searchText }) => {
    /**
     * 取得模糊匹配後的資料陣列
     * @return {Array<Object>}
     */
    const getFilterData = (data) => fuzzySearchObjects(data, searchText);

    return (
        <ul className="tableList CQRRTableList">
            {/*表單項目標題*/}
            <li className="title">
                <div><Check /></div>
                <div>編號</div>
                <div>家字號</div>
                <div>病歷號</div>
                <div>姓名</div>
                <div>出生日期</div>
                <div>身分證號</div>
                <div>收案日期</div>
                <div>銷案日期</div>
                <div>結案人員</div>
                <div>結案原因</div>
                <div>結案原因其他描述</div>
                <div>備註</div>
            </li>

            {!arrayIsEmpty(getFilterData(data)) ? getFilterData(data).map((item, index) =>
                <li key={index}>
                    {/*操作*/}
                    <div>
                        <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5"
                               type="checkbox"
                               id={`r${index}`} style={{ accentColor: "#38A169" }} />
                    </div>
                    {/*編號*/}
                    <div>{item.id}</div>
                    {/*家字號*/}
                    <div>{item.familySerialNumber}</div>
                    {/*病歷號*/}
                    <div>{item.medicalRecordNumber}</div>
                    {/*姓名*/}
                    <div>{item.name}</div>
                    {/* 出生日期*/}
                    <div>{item.birthDate}</div>
                    {/* 身分證號*/}
                    <div>{item.idNumber}</div>
                    {/* 收案日期*/}
                    <div>{item.admissionDate}</div>
                    {/*銷案日期*/}
                    <div>{item.dischargeDate}</div>
                    {/*結案人員*/}
                    <div>{item.caseCloser}</div>
                    {/*結案原因*/}
                    <div>{item.closeReason}</div>
                    {/*結案原因其他描述*/}
                    <div>{item.closeReasonOtherDescription}</div>
                    {/*備註*/}
                    <div>{item.notes}</div>
                </li>
            ) : null}
        </ul>
    );
};

export default CloseCaseList;
