import './InfinityTable.scss'
import CustomScroll from "react-custom-scroll";

const InfinityTable = ({columns, currentTableData, isLoading, next}) => {

    const handleScroll = (e) => {
        
        let element = e.target;
        const { scrollTop, clientHeight, scrollHeight } = element;

        if (scrollTop + clientHeight >= scrollHeight - 20) {
            next();
        }
    }

    return (
        <div className='bg-white rounded-bl-[5px] rounded-br-[5px] border-t-0 border-l-2 border-r-2 border-b-2 border-zinc-500 h-[70vh] max-h-[70vh]'>
            <div className='mx-[50px] my-[30px]'>
                <div className='table flex border-collapse w-full'>
                    <div className='flex'>
                        {columns.map((item, index) => {
                            const isFirst = index === 0;
                            const isLast = index === columns.length - 1;
                            return (
                                <th key={index} 
                                    className={`${item.classWidth?item.classWidth:"flex-[1]"} 
                                                p-[8px] border-solid border-[#828282] 
                                                ${isFirst?"rounded-tl-[3px]":""} 
                                                ${isLast?"rounded-tr-[3px]":""}
                                                ${isFirst?"border-[1px]":"border-t-[1px] border-r-[1px] border-b-[1px]"}`}>
                                    {item.Header}
                                </th>
                            );
                        })}
                    </div>

                    <div className='h-[55vh] max-h-[55vh]'>
                        <CustomScroll heightRelativeToParent="100%" onScroll={handleScroll}>
                            <div className='scroll_contain'>
                                {currentTableData.map((item, row_index) => {
                                return (
                                <div key={row_index} 
                                    className='flex'>
                                    {
                                        columns.map((column, index) => {
                                            const isFirst = index === 0 && row_index === currentTableData.length - 1;
                                            const isLast = index === columns.length - 1 && row_index === currentTableData.length - 1;
                                            
                                            return(
                                            <div 
                                                key={index} 
                                                className={`${column.classWidth?column.classWidth:"flex-[1]"} 
                                                            p-[8px] bg-white border-solid border-[#828282] text-center 
                                                            ${isFirst?"rounded-bl-[3px]":""} 
                                                            ${isLast?"rounded-br-[3px]":""}
                                                            ${index === 0?"border-l-[1px] border-r-[1px] border-b-[1px]":"border-r-[1px] border-b-[1px]"}`}>
                                                {
                                                    (column['has_checked'] !== undefined && column['has_checked'] === true) ?
                                                    <input className='text-black text-xl' type="checkbox"></input> : item.hasOwnProperty(column['accessor']) ?
                                                    item[column['accessor']] : ''
                                                }
                                            </div>
                                            )
                                        })
                                    }
                                </div>
                                );
                            })}
                            </div>
                            {isLoading && 
                            <div className='flex justify-center'>
                                loading......................
                            </div>
                            }
                        </CustomScroll>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InfinityTable;