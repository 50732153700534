import React, {useCallback, useMemo, useState} from 'react';
import {Calendar, momentLocalizer} from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import CalendarPopup from "./CalendarPopup";
import EditEventPopup from "./EditCalendarPopup";
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import {ApiErrorStatusEnum, arrayIsEmpty, objectIsEmpty, stringIsEmpty, time2String} from "edah_utils/dist"
import {queryRegsClinicStatusByTime} from '../../../api/v1/RegsMonth';
import {Dialog, DialogVariant} from 'edah-component/dist'

// 設置本地化
moment.locale('zh-tw');
const DragAndDropCalendar = withDragAndDrop(Calendar)
const localizer = momentLocalizer(moment);

/**
 * 預約日曆組件
 * @param {Object} props - 組件屬性
 * @param {Function} props.closePopup - 關閉彈窗的函數
 * @param {Date} choiceDate - 日期
 * @param {Object} giveNumberTimeRangeRecord - 給號時間區間的紀錄
 * @param {Function} props.onSave - 儲存function
 * @param {String} clinicId - 診室id
 * @returns {JSX.Element} 預約日曆組件
 */
const AppointmentCalendar = ({
                                 closePopup,
                                 choiceDate,
                                 apn,
                                 patientData,
                                 clinicId,
                                 giveNumberTimeRangeRecord,
                                 onSave
                             }) => {
    // 預約資料
    const [events, setEvents] = useState([])
    /*    const [events, setEvents] = useState([
            {
                id: "1",
                start: new Date(2024, 7, 1, 8, 30),
                end: new Date(2024, 7, 1, 10, 10),
                title: '0012233 李小姐 女',
                //key for edit、drag、resize
                editable: false,
            },
            {
                id: "2",
                start: new Date(2024, 6, 19, 8, 20),
                end: new Date(2024, 6, 19, 8, 30),
                title: '0012233 吳小姐 女',
                editable: false
            },
        ]);*/

    // 新增掛號狀態
    const [showEventPrompt, setShowEventPrompt] = useState(false);
    const [selectedSlot, setSelectedSlot] = useState(null);

    // 控制編輯事件彈窗的顯示
    const [showEditEventPrompt, setShowEditEventPrompt] = useState(false);

    // 儲存選中的事件
    const [selectedEvent, setSelectedEvent] = useState(null);

    // 診間開診時間
    const [minDateTime, setMinDateTime] = useState("")
    // 診間結束時間
    const [maxDateTime, setMaxDateTime] = useState("")
    // 每人看診時間(分)
    const [slotMin, setSlotMin] = useState(10)

    /**
     * 新增事件到日曆
     * @param {Object} newEvent - 新事件對象
     */
    const addEvent = (newEvent) => {
        //setEvents([...events, { ...newEvent, id: Date.now() }]);
        setEvents([...events, {...newEvent, id: "new"}])
    };

    /**
     * 處理新事件確認
     * @param eventInfo
     */
    const handleEventConfirm = (eventInfo) => {
        addEvent({
            start: eventInfo.startTime,
            end: eventInfo.endTime,
            title: eventInfo.title,
            editable: true
        });
        setShowEventPrompt(false);
        setSelectedSlot(null);
    };

    /**
     * 處理新事件取消
     */
    const handleEventCancel = () => {
        setShowEventPrompt(false);
        setSelectedSlot(null);
    };

    /**
     * 編輯處理事件刪除
     * @param {string} idToDelete
     */
    const handleEventDelete = (idToDelete) => {
        setEvents((prevEvents) =>
            prevEvents.filter((event) => event.id !== idToDelete)
        );
        setShowEditEventPrompt(false)
        setSelectedSlot(null)
    }

    /**
     * 處理編輯事件確認
     * @param {Object} updatedEvent - 更新後的事件對象
     */
    const handleEditEventConfirm = (updatedEvent) => {
        setEvents(events.map(e => e.id === updatedEvent.id ? updatedEvent : e));
        setShowEditEventPrompt(false);
        setSelectedEvent(null);
    };

    /**
     * 處理編輯事件取消
     */
    const handleEditEventCancel = () => {
        setShowEditEventPrompt(false);
        setSelectedEvent(null);
    };

    /**
     * 存檔按鈕點擊事件
     */
    const handleSaveOnClick = () => {
        const findedEvent = events.find(item => item.id === "new")
        onSave(objectIsEmpty(findedEvent) ? null : {start: findedEvent.start, end: findedEvent.end})
        closePopup()
    }

    /**
     * 計算事件的樣式
     * @param {Object} event - 事件對象
     * @param {Date} start - 事件開始時間
     * @param {Date} end - 事件結束時間
     * @param {boolean} isSelected - 事件是否被選中
     * @returns {Object} 事件的樣式對象
     */
    const eventStyleGetter = (event, start, end, isSelected) => {
        return {
            style: {
                backgroundColor: '#BEE3F8',
                borderRadius: '5px',
                opacity: event.id === "new" ? 1.0 : 0.7,
                color: '#111111',
                border: '0px',
                display: 'block',
            }
        };
    };

    // 使用 useCallback 函數，定義 moveEvent 函數，用來處理事件的移動
    const moveEvent = useCallback(({event, start, end, isAllDay: droppedOnAllDaySlot = false}) => {
        const {allDay} = event
        if (!allDay && droppedOnAllDaySlot) {
            event.allDay = true
        }
        if (allDay && !droppedOnAllDaySlot) {
            event.allDay = false;
        }

        setEvents((prev) => {
            const existing = prev.find((ev) => ev.id === event.id) ?? {}
            const filtered = prev.filter((ev) => ev.id !== event.id)
            return [...filtered, {...existing, start, end, allDay: event.allDay}]
        })
    }, [setEvents])

    // 使用 useCallback 函數，定義 resizeEvent 函數，用來處理事件的調整大小
    const resizeEvent = useCallback(({event, start, end}) => {
        setEvents((prev) => {
            const existing = prev.find((ev) => ev.id === event.id) ?? {}
            const filtered = prev.filter((ev) => ev.id !== event.id)
            return [...filtered, {...existing, start, end}]
        })
    }, [setEvents])

    // 日曆內容
    const CalendarContent = (
        <div>
            <style>
                {`
                  .rbc-today { background-color: rgba(250, 250, 250, 1); }
                  .rbc-time-header { display: none; }
                `}
            </style>
            <div className="p-4 pt-0">
                <DragAndDropCalendar
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    eventPropGetter={eventStyleGetter}
                    style={{height: 500, width: 640, backgroundColor: '#FFFFFF'}}
                    dayLayoutAlgorithm="no-overlap"
                    defaultView="day"
                    defaultDate={new Date(choiceDate)}
                    toolbar={false}
                    step={10}
                    timeslots={1}
                    min={minDateTime} // 設置日曆開始時間
                    max={maxDateTime} // 設置日曆結束時間
                    selectable={true}
                    onSelectSlot={(slotInfo) => {
                        const findedNew = events.find(item => item.id === "new")
                        if (objectIsEmpty(findedNew)) {
                            //僅能新增一個
                            if (slotInfo.action === 'click') {
                                //click方式
                                let end = new Date(slotInfo.start)
                                //指定看診時間
                                end.setMinutes(end.getMinutes() + slotMin)
                                //判斷結束時間是否超過診間結束時間
                                if (end > new Date(maxDateTime)) {
                                    end = new Date(maxDateTime)
                                }
                                setSelectedSlot({...slotInfo, end: end})
                                setShowEventPrompt(true);
                            } else {
                                setSelectedSlot(slotInfo);
                                setShowEventPrompt(true);
                            }
                        }
                    }}
                    onSelectEvent={(event) => {
                        if (event.editable) {
                            //有編輯權力
                            setSelectedEvent(event);
                            setShowEditEventPrompt(true);
                        }
                    }}
                    draggableAccessor={"editable"}
                    onEventDrop={moveEvent}
                    resizableAccessor={"editable"}
                    onEventResize={resizeEvent}
                />
            </div>
            {/*<div className="flex justify-end border-t border-[rgba(0,0,0,0.15)] py-3">*/}
            {/*    <Button*/}
            {/*        sx={{marginRight: '0.5rem'}}*/}
            {/*        color={ColorEnum.Primary} variant={ButtonVariantEnum.Contained}*/}
            {/*        size={ButtonSizeEnum.Medium}*/}
            {/*        onClick={handleSaveOnClick}>*/}
            {/*        {t('general.saveFile')}*/}
            {/*    </Button>*/}
            {/*</div>*/}
        </div>
    );

    const getEventTitle = (patient) => {
        let titleText = ""
        if (!objectIsEmpty(patient)) {
            if (!stringIsEmpty(patient.patientId)) {
                titleText = `${patient.patientId} `
            }
            if (!stringIsEmpty(patient.patientName)) {
                titleText = `${titleText}${patient.patientName} `
            }
            if (!stringIsEmpty(patient.genderName)) {
                titleText = `${titleText}${patient.genderName} `
            }
        }
        return titleText
    }

    useMemo(() => {
        const choiceDateTime = new Date(choiceDate)
        //設定時間區間
        const apnToNumber = parseInt(apn)
        if (apnToNumber === 1) {
            const min = new Date(choiceDateTime)
            const max = new Date(choiceDateTime)
            min.setHours(8)
            min.setMinutes(30)
            max.setHours(12)
            setMinDateTime(min)
            setMaxDateTime(max)
        } else if (apnToNumber === 2) {
            const min = new Date(choiceDateTime)
            const max = new Date(choiceDateTime)
            min.setHours(13)
            min.setMinutes(30)
            max.setHours(17)
            setMinDateTime(min)
            setMaxDateTime(max)
        } else if (apnToNumber === 3) {
            const min = new Date(choiceDateTime)
            const max = new Date(choiceDateTime)

            min.setHours(18)
            max.setHours(21)
            setMinDateTime(min)
            setMaxDateTime(max)
        }

        if (arrayIsEmpty(events)) {
            // 取得診間給號紀錄
            queryRegsClinicStatusByTime({
                clinicId: clinicId
            }).then(res => {
                if (res.err === ApiErrorStatusEnum.Success) {
                    if (!objectIsEmpty(res.data)) {
                        setSlotMin(res.data.slotMin)

                        if (!arrayIsEmpty(res.data.regList)) {
                            const data = res.data.regList.map((item, index) => {
                                const start = moment(item.startViewDatetime, 'YYYY-MM-DD HH:mm').toDate();
                                const end = moment(item.endViewDatetime, 'YYYY-MM-DD HH:mm').toDate();
                                return {
                                    start: start,
                                    end: end,
                                    title: getEventTitle(item),
                                    editable: false,
                                    id: index.toString()
                                }
                            })
                            setEvents((prevEvents) => [
                                ...prevEvents,
                                ...data
                            ])
                        }
                    }
                } else {
                    console.log(res.msg)
                }
            })
        }

        const rangStart = time2String(giveNumberTimeRangeRecord.start)
        const rangeEnd = time2String(giveNumberTimeRangeRecord.end)
        if (!objectIsEmpty(giveNumberTimeRangeRecord) &&
            !stringIsEmpty(rangStart) && !stringIsEmpty(rangeEnd)) {
            let titleText = getEventTitle(patientData)

            setEvents([
                ...events,
                {
                    start: giveNumberTimeRangeRecord.start,
                    end: giveNumberTimeRangeRecord.end,
                    title: titleText,
                    editable: true,
                    id: "new"
                }
            ])
        }
    }, [])

    return (
        <>
            <Dialog
                open={true}
                title={"診號狀況"}
                content={CalendarContent}
                onClose={closePopup}
                onSave={handleSaveOnClick}
                variant={DialogVariant.EDIT}
                paperStyleProps={{width: '1116px'}}/>

            {/*新增事件*/}
            {showEventPrompt && (
                <CalendarPopup
                    onConfirm={handleEventConfirm}
                    onCancel={handleEventCancel}
                    initialSelectTimeRange={{start: selectedSlot.start, end: selectedSlot.end}}
                    patientData={patientData}
                    apnTimeRange={{start: minDateTime, end: maxDateTime}}
                />
            )}

            {/*編輯事件*/}
            {showEditEventPrompt && selectedEvent && (
                <EditEventPopup
                    event={selectedEvent}
                    onConfirm={handleEditEventConfirm}
                    onCancel={handleEditEventCancel}
                    onDelete={handleEventDelete}
                    apnTimeRange={{start: minDateTime, end: maxDateTime}}
                />
            )}
        </>
    );
};
7
export default AppointmentCalendar;
