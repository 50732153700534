import { useSelector } from 'react-redux'
import './print.scss'

/**
 * 未收到原醫療院所回覆名單 - 標籤列印
 * @returns {JSX.Element}
 */
const TranOutNoReplyLetterLabel = () => {
    const printData = useSelector((state) => state.print.printData)
    return (
        <>
            {printData.map((item) => {
                return (
                    <div className="label-page p-1">
                        <div>平信</div>
                        <div>{item.tranOutHospName}</div>
                        <div className="text-right">轉診中心 收</div>
                        <span>{item.tranOutHospZipCode}</span>
                        <span>{item.tranOutHospAddress}</span>
                    </div>
                )
            })}
        </>
    )
}

export default TranOutNoReplyLetterLabel
