import { useSelector } from 'react-redux'
import './print.scss'
import A4Print from './A4Print'

/**
 * 轉診回函信封列印
 * @returns {JSX.Element}
 */
const PrintReplyLetterOrderCover = ({ info }) => {
    return (
        <A4Print
            key={info.encounterId}
            className="break-after"
        >
            <div className="w-full rounded-md grid grid-cols-[10%,90%] mb-5">
                <div>From：</div>
                <div>
                    <p className="text-left mb-2">
                        義大醫療財團法人義大醫院轉診中心 (機構代號1142120001)
                    </p>
                    <p className="text-left">高雄市燕巢區角宿里義大路1號</p>
                </div>
            </div>
            <div className="w-full grid grid-cols-[5%,5%,90%] mt-5">
                <div></div>
                <div>To: </div>
                <div className="border-[#D4D4D8] border p-2">
                    <div className="flex justify-between">
                        <div>{info.tranInHospName}</div>
                        <div>轉診中心收</div>
                    </div>
                    <div>
                        {`${info.tranInHospZipCode} ${info.tranInHospAddress}`}
                    </div>
                </div>
            </div>
        </A4Print>
    )
}

export default PrintReplyLetterOrderCover
