import {t} from "i18next"
import React, {useEffect, useState} from "react"
import {BaseInput} from "../../Input/BaseInput"
import {InputTypeEnum} from "edah_utils/dist"
import {BasePopup} from "../../Popup/BasePopup"

/**
 * 通知人員修改popup
 * @param {object} info 資訊
 * @param {boolean} show 是否顯示
 * @param onClosePopupButtonClick {function} 關閉彈窗時的 callback
 * @return {JSX.Element}
 */
export const ModifyNotifyMaintainerPopup = ({info, show, onClosePopupButtonClick}) => {
    // 是否顯示彈窗
    const [isShow, setIsShow] = useState(show)

    /**
     * 點擊確認按鈕
     * @return {void}
     */
    const handleConfirmButtonOnClick = () => {

    }

    /**
     * 取得Content
     * @return {JSX.Element}
     */
    const getContent = () => <div>
        <ul className="w-full p-2.5">
            {/*通知人員*/}
            <li className="flex flex-row items-center justify-start h-[42px] mb-1">
                <p className="w-[160px] text-right pr-4">通知人員</p>
                <BaseInput className="w-[235px] h-10 pl-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                           type={InputTypeEnum.Text} placeholder={'--'}/>
            </li>
            {/*持續報警*/}
            <li className="flex flex-row items-center justify-start h-[42px] mb-1">
                <p className="w-[160px] text-right pr-4">持續報警(分)</p>
                <BaseInput className="w-[235px] h-10 pl-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                           type={InputTypeEnum.Text} placeholder={'--'}/>
            </li>
            {/*通知方式*/}
            <li className="flex flex-row items-center justify-start h-[42px] mb-1">
                <p className="w-[160px] text-right pr-4">通知方式</p>
                <BaseInput className="w-[500px] h-10 pl-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                           type={InputTypeEnum.Text}/>
            </li>
        </ul>
        <div className="h-[60px] flex flex-row items-center justify-end border-t-[1px] border-[rgba(0,0,0,0.15)]">
            <button
                className="flex items-center justify-center h-10 px-4 mr-4 border-[2px] bg-[#2B6CB0] text-white border-[#2B6CB0] rounded-[6px]"
                onClick={handleConfirmButtonOnClick}>
                {t('general.confirm')}
            </button>
        </div>
    </div>

    /**
     * show 改變時，更新 isShow
     */
    useEffect(() => setIsShow(show), [show])

    return isShow ? <BasePopup title="通知人員修改" closePopupButtonOnClick={onClosePopupButtonClick} width="724px"
                               content={getContent()}/> : null
}
