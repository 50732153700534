import { time2String } from 'edah_utils/dist'
import { DATE_FORMAT } from '../common'
import i18n from "../../i18n/index"

export const REFERRAL_REPLY_FIELDS = [
    {
        key: 'encounterDate',
        name: i18n.t('Rmis.pages.referralReplyLetter.tableHeader.encounterDate'), // 就醫日期
        format: (value) => time2String(value, DATE_FORMAT),
    },
    {
        key: 'patientId',
        name: i18n.t('Rmis.pages.referralReplyLetter.tableHeader.patientId'), // 病歷號
    },
    {
        key: 'idNo',
        name: i18n.t('Rmis.pages.referralReplyLetter.tableHeader.idNo'), // 身分證號
    },
    {
        key: 'patientName',
        name: i18n.t('Rmis.pages.referralReplyLetter.tableHeader.patientName'), // 姓名
    },
    {
        key: 'divName',
        name: i18n.t('Rmis.pages.referralReplyLetter.tableHeader.divName'), // 就醫科別
    },
    {
        key: 'doctorName',
        name: i18n.t('Rmis.pages.referralReplyLetter.tableHeader.doctorName'), // 就醫醫師
    },
    {
        key: 'tranInPrintDate',
        name: i18n.t('Rmis.pages.referralReplyLetter.tableHeader.tranInPrintDate'), // 列印日期
        format: (value) => time2String(value, DATE_FORMAT),
    },
]
