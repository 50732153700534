import {v1Api} from "../Http"
import {HttpMethodEnum} from "edah_utils/dist"

/**
 * 查詢初診預約(POP視窗)
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const regsQueryFirstviewByField = (params) => v1Api.request({
    url: '/regs/regsfirstview/queryRegsFirstviewByField',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 新增初診預約存檔
 * @param data {Object} params
 * @return {AxiosPromise}
 */
const regsAddFirstview = (data) => v1Api.request({
    url: '/regs/regsfirstview/addRegsFirstview',
    method: HttpMethodEnum.POST,
    data
})

export {
    regsQueryFirstviewByField,
    regsAddFirstview
}