import React, {useEffect, useState} from 'react'
import ChangeRecordQuery from './QueryChangeRecord';
import {usePopup} from '../PopupProvider';
import {t} from 'i18next';
import {ApiErrorStatusEnum, arrayIsEmpty, objectIsEmpty, stringIsEmpty,} from "edah_utils/dist"
import {
    AlertTypeEnum,
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    Card,
    Dialog,
    DialogSizeEnums,
    DialogVariant,
    Divider,
    Field,
    Select,
    SelectWidthEnum
} from 'edah-component/dist'

// import API
import {rsisQueryCodeValue} from '../../../api/v1/RSIS';
import {checkDoctorLicence} from "../../../api/v1/Menu";

/**
 * 報備支援-內容檢視 彈窗
 * @param {Object} data 報備支援內容資料
 * @param {function} handleOnClose 關閉彈窗
 * @return {JSX.Element}
 */
const RSISContentView = ({data, handleOnClose}) => {
    // 值登資料
    const [doctorData, setDoctorData] = useState()
    // 查詢變更紀錄
    const [showChangeRecordQuery, setShowChangeRecordQuery] = useState(false)
    // 支援目的選項
    const [purposeOptions, setPurposeOptions] = useState([])
    // 子支援目的選項
    const [subPurposeOptions, setSubPurposeOptions] = useState([])
    // 性質清單
    const [kindList, setKindList] = useState([])
    // 支援目的選項
    const [purposeCode, setPurposeCode] = useState(data.purposeCode ? data.purposeCode.split(',').filter(Boolean) : '')
    // 子支援目的選項
    const [subPurposeCode, setSubPurposeCode] = useState(data.purposeCodeExt ? data.purposeCodeExt : '')
    // 變更原因
    const [changeReason, setChangeReason] = useState([])
    // 使用上下文
    const {showToast} = usePopup()

    /**
     * 取得醫師資料
     * @retunr {void}
     */
    const getDoctorData = () => {
        // 根據醫師代碼查詢醫師資料
        checkDoctorLicence({
            doctorNo: data.supportUserNo,
            divNo: ''
        }).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                // 若有執豋資料，則設定醫師資料
                if (data) {
                    setDoctorData(data)
                } else {
                    // 若無執登資料，則設定空值
                    setDoctorData({
                        divNo: '',
                        divName: '',
                        licenceId: '',
                        hospNo: '',
                        hospName: '',
                        licenceKind: '',
                        licenceKindName: ''
                    })
                }
            } else {
                setDoctorData({
                    divNo: '',
                    divName: '',
                    licenceId: '',
                    hospNo: '',
                    hospName: '',
                    licenceKind: '',
                    licenceKindName: ''
                })
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 渲染支援目的選項
     * @return {Array<JSX.Element>|JSX.Element}
     */
    const renderPurposeOptions = () => {

        const subPurpose = () => {
            if (subPurposeCode === '1') {
                return '門診'
            } else if (subPurposeCode === '2') {
                return '值班'
            } else if (subPurposeCode === '3') {
                return '會診'
            }
        }
        // 判斷purposeCode 是否有split成陣列
        if (Array.isArray(purposeCode)) {
            const purposeTexts = purposeCode.map(codeNo => {
                // 比對purposeCode跟purposeOptions的codeNo，找到對應的codeValue1
                const purpose = purposeOptions.find(item => item.codeNo === codeNo)
                // purpose有值
                if (purpose) {
                    // 如果codeNo=10，則返回支援目的(報備為一般支援的情況下)+子支援目的
                    if (codeNo === '10') {
                        return `${purpose.codeValue1} ${data.purposeCodeExt ? `${subPurpose()}` : ''}`;
                    }
                    return purpose.codeValue1;
                }
                return null;
            }).filter(Boolean)

            // 顯示支援目的
            return purposeTexts.map((text, index) => <p key={index}>{text}</p>)
        } else if (!stringIsEmpty(purposeCode)) { // 判斷purposeCode沒有split成讖列，額外是否有值，單純字串
            const purpose = purposeOptions.find(item => item.codeNo === purposeCode)
            if (purpose) {
                if (purposeCode === '10') {
                    return <p className='flex justify-start pl-4 py-[9px]'>
                        {`${purpose.codeValue1} ${data.purposeCodeExt ? `${subPurpose()}` : ''}`}
                    </p>
                }

                return <p className='flex justify-start pl-4 py-[9px]'>
                    {purpose.codeValue1}
                </p>
            }
        } else {// purposeCode 為空
            return <p className='flex justify-start pl-4 py-[9px]'>{''}</p>
        }
    }

    /**
     * 取得支援性質文字
     * @param supportProperties {String} 支援性質
     * @return {String}
     */
    const getSupportPropertiesText = (supportProperties) => {
        // 支援性質或性質清單為空
        if (stringIsEmpty(supportProperties) || arrayIsEmpty(kindList)) {
            return ''
        }

        // 取得支援性質
        const kind = kindList.find(item => item.codeNo === supportProperties)
        // 找不到支援性質
        if (objectIsEmpty(kind)) {
            return ''
        }

        return kind.codeValue1
    }

    /**
     * 取得支援目的選項
     * @return {void}
     */
    const getPurposeOptions = () => {
        // 查詢支援目的選項
        rsisQueryCodeValue({effFlag: 'ALL'}).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            // API取得成功
            if (err === ApiErrorStatusEnum.Success) {
                // 設定性質清單
                setKindList(data.filter(item => item.codeType === 'KIND'))
                // 設定支援目的選項
                setPurposeOptions(data.filter(item => item.codeType === 'PURPOSE'))
                // 設定子支援目的選項
                setSubPurposeOptions(data.filter(item => item.codeType === 'SUBPURPOSE'))
                // 設定變更原因
                setChangeReason(data.filter(item => item.codeType === 'CHANGE'))
            } else { // API取得失敗
                // 清空性質清單
                setKindList([])
                // 清空支援目的選項
                setPurposeOptions([])
                // 清空子支援目的選項
                setSubPurposeOptions([])
                // 清空變更原因
                setChangeReason([])
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 第一次執行時
     * @return {void}
     */
    useEffect(() => {
        // 取得醫師執登資料
        getDoctorData()
        // 取得支援目的選項
        getPurposeOptions()
    }, [])

    return (
        <Dialog
            open={true}
            title={t('RSIS.Popup.RSISContentView')}
            content={
                <div className='px-6'>
                    <div className='flex flex-row justify-end items-center gap-2'>
                        <Button color={ButtonColorEnum.Secondary}
                                variant={ButtonVariantEnum.Outlined}
                                size={ButtonSizeEnum.Medium}
                                text={t('查詢變更紀錄')}
                                sx={{height: '2.5rem'}}
                                onClick={() => setShowChangeRecordQuery(true)}/>

                        <Select
                            data={{
                                label: "",
                                options: [
                                    {value: "1", label: t(`campus.${data.zone}`)},
                                ],
                            }}
                            disabled
                            value='1'
                            width={SelectWidthEnum.Small}/>
                        <span className='flex items-center text-[#38A169]'>
                            {`${t(`RSIS.StepStatusEnum.${data.stepStatus}`)}狀態`}
                        </span>
                    </div>
                    <div className='flex flex-col space-y-2'>
                        <Card title={"支援人員"}>
                            <div className='flex flex-col gap-2'>
                                <Field label="員工/醫師代碼">
                                    {`${data.supportUserNo} ${data.supportUserName}`}
                                </Field>
                            </div>
                            <div className='grid grid-cols-4'>
                                <Field label="執登機構">
                                    {!objectIsEmpty(doctorData) && !stringIsEmpty(doctorData.hospNo) && !stringIsEmpty(doctorData.hospName) ? `${doctorData.hospNo} ${doctorData.hospName}` : ''}
                                </Field>
                                <Field label="職類別">
                                    {!objectIsEmpty(doctorData) && !stringIsEmpty(doctorData.licenceKind) && !stringIsEmpty(doctorData.licenceKindName) ? `${doctorData.licenceKind} ${doctorData.licenceKindName}` : ''}
                                </Field>
                                <Field label="執登科別">
                                    {!objectIsEmpty(doctorData) && !stringIsEmpty(doctorData.divNo) && !stringIsEmpty(doctorData.divName) ? `${doctorData.divNo} ${doctorData.divName}` : ''}
                                </Field>
                                <Field label="證書字號">
                                    {!objectIsEmpty(doctorData) && !stringIsEmpty(doctorData.licenceId) ? doctorData.licenceId : ''}
                                </Field>
                            </div>
                            <div>
                                <Field label="支援期間明細">
                                    {`${data.itemStartDate.replace(/ 00:00:00/g, '')}~${data.itemEndDate.replace(/ 00:00:00/g, '')} ${data.itemStartTime}~${data.itemEndTime}`}
                                </Field>
                            </div>
                        </Card>
                    </div>
                    {/* 分隔線 */}
                    <Divider sx={{my: 2}}/>
                    {/* 報備支援內容 */}
                    <div className='flex flex-col mt-6 mb-6'>
                        <Card title={"報備支援內容"}>
                            <div className="space-y-2">
                                <Field label="申請單號">
                                    {data.supportNo}
                                </Field>
                                <Field label="機構代碼">
                                    {`${!stringIsEmpty(data.supportOrgNo) ? data.supportOrgNo : ''} ${!stringIsEmpty(data.supportOrgName) ? data.supportOrgName : ''}`}
                                </Field>
                                <Field label="支援科別">
                                    {`${data.supportDivNo} ${data.supportDivName}`}
                                </Field>
                                <Field label="支援目的">
                                    {renderPurposeOptions()}
                                </Field>
                                <div className='flex'>
                                    <Field label="承辦部門">
                                        {`${data.ownerDeptNo} ${data.ownerDeptName}`}
                                    </Field>
                                    <Field label="代理人員">
                                        {`${data.agentUserNo} ${data.agentUserName ? data.agentUserName : ''}`}
                                    </Field>
                                </div>
                                <Field label="性質">
                                    {getSupportPropertiesText(data.supportProperties)}
                                </Field>
                                <Field label="執行內容">
                                    {data.executeContent}
                                </Field>
                                <Field label="備註">
                                    {data.memo}
                                </Field>
                            </div>
                        </Card>
                    </div>
                    {
                        showChangeRecordQuery && (
                            <ChangeRecordQuery supportNo={data.supportNo}
                                               handleOnClose={() => setShowChangeRecordQuery(false)}/>
                        )
                    }
                </div>
            }
            variant={DialogVariant.CONFIRM}
            paperStyleProps={{width: DialogSizeEnums.XL}}
            muiDialogActionsProps={{sx: {display: 'none'}}}
            muiDialogContentProps={{dividers: true}}
            onClose={handleOnClose}/>
    )
}

export default RSISContentView
