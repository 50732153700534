import React, {useEffect, useState} from "react"
import {DialogSizeEnums, Dialog, DialogVariant} from 'edah-component/dist'

/**
 * 提示彈出窗
 * @param promptText {String} 提示字串
 * @param closePopupButtonOnClick {Function} 關閉事件
 * @param show {Boolean} 是否顯示彈窗
 * @returns
 */
const PromptPopup = ({
                         promptText,
                         closePopupButtonOnClick,
                         show = false
                     }) => {
    /**
     * 是否顯示彈窗
     */
    const [showPopup, setShowPopup] = useState(show)

    const getContent = () => {
        return (
            <div className="mb-4 px-6 space-y-2">
                <div className="text-left">
                    {promptText}
                </div>
            </div>
        )
    }

    /**
     * 監聽是否顯示彈窗Pros變化
     */
    useEffect(() => {
        setShowPopup(show)
    }, [show])

    return (
        <Dialog
            open={showPopup}
            title={'注意'}
            content={getContent()}
            variant={DialogVariant.REMIND}
            paperStyleProps={{width: DialogSizeEnums.SM}}
            onClose={closePopupButtonOnClick}
            muiDialogContentProps={{sx: {padding: '0px', height: '110px', display: 'flex', alignItems: 'center'}}}
        />
    )
}

export default PromptPopup;
