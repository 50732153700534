import React from 'react'
import {PopupProvider} from '../../components/RSIS/PopupProvider'
import UrgentApplication from '../../components/RSIS/UrgentApplication/UrgentApplication'

/**
 * 急件申請
 * @returns {JSX.Element}
 */
const UrgentApplicationLayout = () =>
    <PopupProvider>
        <UrgentApplication/>
    </PopupProvider>


export default UrgentApplicationLayout
