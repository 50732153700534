import { useSelector } from 'react-redux'
import A4Print from './A4Print'
import { STRATEGIC_ALLIANCE_MAINTAIN } from '../../constants/referralSystem/maintainAreaHeader'
import { time2String } from 'edah_utils'
import { DATE_FORMAT } from '../../constants/common'
import { booleanForDisplay } from '../ReferralSystem/utils'
/**
 * 出院摘要 - 列印內容
 * @returns {JSX.Element}
 */
const DischargeSummaryList = () => {
    const printData = useSelector((state) => state.print.printData)

    return (
        <>
            <h1 className="text-xl font-bold text-center">出院病摘</h1>
            <table className="w-full border-collapse text-sm border-t border-[#111111]/15">
                <thead>
                    <tr className="h-[40px] text-[#18181b]">
                        <th className="w-[calc(100%/15)] text-left break-words p-1">
                            就醫日期
                        </th>
                        <th className="w-[calc(100%/15)] text-left break-words p-1">
                            入院日期
                        </th>
                        <th className="w-[calc(100%/15)] text-left break-words p-1">
                            出院日期
                        </th>
                        <th className="w-[calc(100%/15)] text-left break-words p-1">
                            病歷號
                        </th>
                        <th className="w-[calc(100%/15)] text-left break-words p-1">
                            姓名
                        </th>
                        <th className="w-[calc(100%/15)] text-left break-words p-1">
                            科別
                        </th>
                        <th className="w-[calc(100%/15)] text-left break-words p-1 ">
                            主治醫師
                        </th>
                        <th className="w-[calc(100%/15)] text-left break-words p-1">
                            轉入院所
                        </th>
                        <th className="w-[calc(100%/15)] text-left break-words p-1">
                            就醫號
                        </th>
                        <th className="w-[calc(100%/15)] text-left break-words p-1">
                            批價序號
                        </th>
                        <th className="w-[calc(100%/15)] text-left break-words p-1">
                            病摘完成否
                        </th>
                        <th className="w-[calc(100%/15)] text-left break-words p-1">
                            申請日期
                        </th>
                        <th className="w-[calc(100%/15)] text-left break-words p-1">
                            核准日期
                        </th>
                        <th className="w-[calc(100%/15)] text-left break-words p-1">
                            列印時間
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {printData.map((item, index) => {
                        return (
                            <tr
                                key={index}
                                className="h-[50px] text-[#18181b] "
                            >
                                <td className="text-left break-words p-1">
                                    {time2String(
                                        item.encounterDate,
                                        DATE_FORMAT
                                    )}
                                </td>
                                <td className="text-left break-words p-1">
                                    {item.admissionDate}
                                </td>
                                <td className="text-left break-words p-1">
                                    {item.dischargeDate}
                                </td>
                                <td className="text-left break-words p-1">
                                    {item.patientId}
                                </td>
                                <td className="text-left break-words p-1">
                                    {item.patientName}
                                </td>
                                <td className="text-left break-words p-1">
                                    {item.divName}
                                </td>
                                <td className="text-left break-words p-1">
                                    {item.doctorName}
                                </td>
                                <td className="text-left break-words p-1">
                                    {item.tranToHospName}
                                </td>
                                <td className="text-left break-words p-1">
                                    {item.inpchtNo}
                                </td>
                                <td className="text-left break-words p-1">
                                    {item.wholeNo}
                                </td>
                                <td className="text-left break-words p-1">
                                    {booleanForDisplay(item.compliteFlag)}
                                </td>
                                <td className="text-left break-words p-1">
                                    {time2String(item.applyDate, DATE_FORMAT)}
                                </td>
                                <td className="text-left break-words p-1">
                                    {time2String(item.aprvDate, DATE_FORMAT)}
                                </td>
                                <td className="text-left break-words p-1">
                                    {time2String(item.applyDate, DATE_FORMAT)}
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </>
    )
}
/**
 * 出院摘要 - 列印
 * @returns {JSX.Element}
 */
const PrintDischargeSummaryList = () => {
    return (
        <A4Print>
            <DischargeSummaryList />
        </A4Print>
    )
}
export default PrintDischargeSummaryList
