import A4Print from "./A4Print";
import {time2String} from "edah_utils/dist";
import {useSelector} from "react-redux";
import React from 'react';
import { DATE_FORMAT } from "../../constants/common";
import moment from "moment/moment";
import {getBirthDayAndDetailedAge} from "../ReferralSystem/utils";

/**
 * 轉出單(轉診單) - 列印內容
 * @returns {JSX.Element}
 */
const TranOutFormContent = () => {
    const today = new Date();
    const printData = useSelector((state) => state.print.printData)
    const replyYear = moment(printData.replyDate).year()
    const replyMonth = moment(printData.replyDate).month()
    const replyDate = moment(printData.replyDate).date()
    return (
        <div className="max-w-4xl mx-auto font-sans text-sm break-after">
            <h1 className="text-2xl font-bold text-center">全民健康保險回覆單</h1>
            <div className="flex justify-between font-bold mb-1 text-sm">
                <p>保險醫事服務機構代號:{printData?.hospNo}</p>
                <p className="mr-6">轉出單號:{printData?.tranNo}</p>
            </div>
            <div className="flex">

                <table className="w-full border-collapse border border-gray-400 mb-2">
                    <tbody>
                    <th rowSpan="13"
                        className="border border-gray-400 w-8 [writing-mode:vertical-lr] tracking-[3em]">原診治醫院診所
                    </th>

                    <tr className="">
                        <th rowSpan="4"
                            className="border border-gray-400 w-10  [writing-mode:vertical-lr] tracking-[.35em]">保險對象基本資料
                        </th>
                        <th colSpan="2" className="border border-gray-400">姓名</th>
                        <th colSpan="2" className="border border-gray-400">身分證號</th>
                        <th colSpan="1" className="border border-gray-400">性別</th>
                        <th className="border border-gray-400">出生日期</th>
                        <th colSpan="4" className="border border-gray-400">病歷號</th>
                    </tr>
                    <tr>
                        <td colSpan="2" className="border border-gray-400">{printData?.patientName}</td>
                        <td colSpan="2" className="border border-gray-400">{printData?.idNo}</td>
                        <td colSpan="1" className="border border-gray-400">{printData?.genderName}</td>
                        <td className="border border-gray-400">
                            {printData.birthDate?getBirthDayAndDetailedAge(printData.birthDate):''}
                        </td>
                        <td colSpan="2" className="border border-gray-400">{printData?.patientId}</td>
                    </tr>
                    <tr>
                        <th colSpan="2" className="border border-gray-400">聯絡電話</th>
                        <th colSpan="2" className="border border-gray-400">聯絡人</th>
                        <th colSpan="5" className="border border-gray-400">聯絡地址</th>
                    </tr>
                    <tr>
                        <td colSpan="2" className="border border-gray-400">{printData?.tranOut.contactTel}</td>
                        <td colSpan="2" className="border border-gray-400">{printData?.tranOut.contact}</td>
                        <td colSpan="5" className="border border-gray-400">{printData?.tranOut.contactAddress}</td>
                    </tr>
                    <tr>
                        <th colSpan="1" className="border border-gray-400">病歷摘要</th>
                        <td colSpan="8" className="border border-gray-400">{printData?.subject}</td>
                    </tr>
                    <tr>
                        <th colSpan="1" className="border border-gray-400">診斷</th>
                        <td colSpan="8" className="border border-gray-400">
                            <p>{printData?.tranOut.treatmentCode}</p>
                            <p>{printData?.tranOut.treatmentName}</p>
                        </td>
                    </tr>
                    <tr>
                    <th colSpan="1" className="border border-gray-400">轉診目的</th>
                        <td colSpan="8" className="border border-gray-400">{printData?.tranPurposeName}</td>
                    </tr>
                    <tr>
                        <th colSpan="1" className=" border border-gray-400">院所地址</th>
                        <td colSpan="3" className="border border-gray-400">{printData?.hospAddress}</td>
                        <th colSpan="1" className=" border border-gray-400">傳真號碼<br/>電子信箱</th>
                        <td colSpan="4" className="border border-gray-400">
                            <p> {printData?.hospTel} </p>
                            <p> {printData?.hospMail} </p>
                        </td>
                    </tr>
                    <tr>
                    <th colSpan="1" className="border border-gray-400">診治醫師</th>
                        <td colSpan="1" className="border border-gray-400">姓名</td>
                        <td className="border border-gray-400 w-[100px]">{printData?.doctorName}</td>
                        <td colSpan="1" className="border border-gray-400">科別</td>
                        <td className="border border-gray-400">{printData?.divName}</td>
                        <th className="border border-gray-400">聯絡電話</th>
                        <td className="border border-gray-400">{printData?.hospTel}</td>
                        <th className="border border-gray-400">醫師簽章</th>
                        <td colSpan="2" className="border border-gray-400 w-[100px]"></td>
                    </tr>
                    <tr>
                        <th colSpan="1" className="border border-gray-400  ">轉出日期</th>
                        <td colSpan="4" className="border border-gray-400  ">{time2String(printData?.encounterDate, DATE_FORMAT)}</td>
                        <th className="border border-gray-400  ">有效期限</th>
                        <td colSpan="3" className="border border-gray-400 ">{time2String(printData?.tranOut.effDate, DATE_FORMAT)}</td>
                    </tr>
                    <tr>
                        <th colSpan="1" className="border border-gray-400">建議轉診院所</th>
                        <td colSpan="4" className="border border-gray-400">
                            <p>{printData?.tranOut.tranOutHospNo}</p>
                            <p>{printData?.tranOut.tranOutHospName}</p>

                        </td>
                        <th colSpan="4" className="border border-gray-400 text-left">
                            <p>建議科別：{printData?.tranOut.tranOutDivName}</p>
                            <p>建議醫師：{printData?.tranOut.tranOutDoctorName}</p>
                        </th>
                    </tr>
                    <tr>
                        <td colSpan="9" className="border border-gray-400">
                            <p>轉診院所地址:{printData?.tranOut.tranOutHospAddress}</p>
                            <p>診所聯絡電話:{printData?.tranOut.tranOutHospTel}</p>
                        </td>
                    </tr>

                    <tr>
                        <th colSpan="1" rowSpan="5"
                            className="border border-gray-400 [writing-mode:vertical-lr] tracking-[.35em]">接受轉診醫院診所
                        </th>
                        <th colSpan="1" className="border border-gray-400">處理情形</th>
                        <td colSpan="9" className="border border-gray-400">
                            {printData?.tranIn.inHandleStatus}
                            □1. 已予適當處置並離院<br/>
                            □2. 已予處理，建議事項如下欄<br/>
                            □3. 已安排門診治療中<br/>
                            □4. 已住院治療中<br/>
                            □5. 其他
                        </td>
                    </tr>
                    <tr>
                        <th colSpan="1" className="border border-gray-400">治療摘要</th>

                        <td colSpan="8" className="border border-gray-400">請參閱附件</td>
                    </tr>
                    <tr>
                        <th colSpan="1" className="border border-gray-400">院所名稱</th>
                        <td colSpan="4" className="border border-gray-400">{printData?.tranIn.hospNameIn}</td>
                        <th className="border border-gray-400">聯絡電話</th>
                        <td colSpan="4" className="border border-gray-400">{printData?.tranIn.tranInHospTel}</td>
                    </tr>
                    <tr>
                        <th colSpan="1" className="border border-gray-400">負責醫師</th>
                        <td className="border border-gray-400">姓名</td>
                        <td className="border border-gray-400">{printData?.tranIn.doctorName}</td>
                        <td className="border border-gray-400">科別</td>
                        <td className="border border-gray-400">{printData?.tranIn.divName}</td>
                        <td colSpan="1" className="border border-gray-400">醫師簽章</td>
                        <td className="border border-gray-400"></td>
                        <td colSpan="1" className="border border-gray-400">回覆日期</td>
                        <td className="border border-gray-400">
                            <p>西元{replyYear}年{replyMonth}月{replyDate}日</p>
                        </td>
                    </tr>
                    <tr>
                        <th colSpan="1" className="border border-gray-400">備註</th>
                        <td colSpan="9" className="border border-gray-400">
                            <p>本人________，法定代理人________ □同意 □不同意</p>
                            <p>此次轉診後續生之各項衍生醫療資料，可供原轉診診所所查詢。</p>
                        </td>
                    </tr>

                    </tbody>
                </table>
                <div className="[writing-mode:vertical-lr] tracking-[0.5em]"> ★第一聯 接受轉診醫院、診所回覆義大醫院
                </div>

            </div>
            <p className="text-xs mb-2 font-bold">*本轉出單限使用乙次，病人請將此聯交予轉診之醫療院所</p>
            <div className="text-xs text-gray-600 flex flex-row justify-end mr-6">
                <div>
                    <p>列印日期: {time2String(today, 'yyyy-MM-DD HH:mm')}</p>
                    <p>1021.04修訂 MR-03-07(5)</p>
                </div>
            </div>
        </div>
    );
};

/**
 * 轉出單(轉診單) - 附件
 * @returns {Element}
 * @constructor
 */
const TranOutFormSubContent = () => {
    const printData = useSelector((state) => state.print.printData);
    const today = new Date();

    return (
    <div className="mx-auto p-4">
        <h1 className="text-xl font-bold text-center mb-4">義大醫院轉診單附件</h1>
        <table className="w-full border-collapse mb-2 border-b border-b-black">
            <tr className="">
                <th colSpan="2" className="text-right">
                    病歷號：
                </th>
                <td colSpan="2">{printData?.patientId}</td>
                <th colSpan="2" className="text-right">
                    姓名：
                </th>
                <td colSpan="2">{printData?.patientName}</td>
                <th colSpan="2" className="text-right">
                    身分證號：
                </th>
                <td colSpan="2">{printData?.idNo}</td>
            </tr>
            <tr>
                <th colSpan="2" className="text-right">
                    生日：
                </th>
                <td colSpan="2">1968年03月17日</td>
                <th colSpan="2" className="text-right">
                    診治醫師：
                </th>
                <td colSpan="2">{printData?.doctorName}</td>
                <th colSpan="2" className="text-right">
                    單號：
                </th>
                <td colSpan="2">{printData?.tranNo}</td>
            </tr>
        </table>
        <div className="p-4 border border-black h-[calc(100vh-200px)]">
            <div className="mb-4">
                <h2 className="font-semibold mb-2">A.病情摘要(主訴及病歷摘要)：</h2>
                <p>test</p>
                <p>test</p>
            </div>
            <div>===========================================================</div>
            <div>
                <h2 className="font-semibold mb-2">C.檢查及治療結果：</h2>
                <p>Upper GI Panendoscopy:</p>
            </div>
            <div className="mt-8">
                <span>共1頁</span>
            </div>
        </div>
        <div className="text-xs flex justify-between">
        <div className="mt-4">
            <span>頁次：1</span>
        </div>
        <div className="flex mt-4 justify-end">
            <p className="mr-4">
                列印日期: {time2String(today, "yyyy-MM-DD HH:mm")}
            </p>
            <p>MR-03-03(4)</p>
        </div>
        </div>
    </div>
  );
};

/**
 * 轉出單(轉診單) - 列印
 * @returns {JSX.Element}
 */
export const PrintTranOutForm = () => {
  return (
    <>
      <A4Print><TranOutFormContent/></A4Print>
      <A4Print>
        <TranOutFormSubContent />
      </A4Print>
    </>
  );
};

export default PrintTranOutForm;