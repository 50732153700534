import {arrayIsEmpty, time2String, stringIsEmpty} from "edah_utils/dist"

/**
 * 就醫資料長期處方箋
 * @param data {Array} 資料
 * @return {JSX.Element}
 */
export const LongTermPrescriptionSection = ({data}) => {
    return !arrayIsEmpty(data) ? (
        <div className="longTermPrescription">
            <ul className="tableList longTermPrescription">
                <li className="title">
                    <div>開立日期</div>
                    <div>藥品代碼</div>
                    <div>用法</div>
                    <div>天數</div>
                    <div>數量</div>
                </li>
                {
                    data.map((item, index) =>
                        <li key={index}>
                            {/*開立日期*/}
                            <div>{time2String(item.orderDate, "YYYY-MM-DD")}</div>
                            {/*藥品代碼*/}
                            <div>{stringIsEmpty(item.heCode) ? "" : item.heCode}</div>
                            {/*用法*/}
                            <div>{stringIsEmpty(item.useNo) ? "" : item.useNo}</div>
                            {/*天數*/}
                            <div>{stringIsEmpty(item.days) ? "" : item.days}</div>
                            {/*數量*/}
                            <div>{stringIsEmpty(item.tqty) ? "" : item.tqty}</div>
                        </li>
                    )
                }
            </ul>
        </div>
    ) : null
}
