import {
    ApiErrorStatusEnum,
    time2String,
    stringIsEmpty,
    downloadFile,
    exportExcelAsBlob,
    FileExtensionsEnum,
    objectIsEmpty
} from 'edah_utils/dist'
import React, {useEffect, useState} from 'react'
import {PERMISSION_SETTING_HEADER} from '../../constants/referralSystem/maintainAreaHeader'
import {Pagination} from '../../components/Pagination/Pagination'
import {queryTranUser, saveTranUser, deleteTranUser} from '../../api/v1/Rmis'
import useToast from '../../hooks/useToast'
import CustomTable from '../../components/ReferralSystem/CustomTable'
import useDataGrid from '../../hooks/useDataGrid'
import {DATETIME_FORMAT, DATE_FORMAT} from '../../constants/common'
import {
    updatePrintContent,
    completePrintMode,
} from '../../redux/Slice/printSlice'
import {useSelector} from 'react-redux'
import {FORM_TYPE} from '../../constants/referralSystem/print'
import store from '../../redux/store'
import {debounce} from '../../components/ReferralSystem/utils'
import {queryByHospNo} from '../../api/v1/Menu'
import {
    AlertTypeEnum,
    TextField,
    IconEnum,
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    Dialog,
    DialogVariant,
    DialogSizeEnums
} from "edah-component/dist";
import {t} from 'i18next'

const Layout = ({children}) => {
    return (
        <div className="p-2 w-full h-[calc(100vh-98px)] overflow-auto">
            {children}
        </div>
    )
}

/**
 * 權限設定(轉診外網)
 * @returns {JSX.Element}
 */
const PermissionSetting = () => {
    const {
        updateDataList,
        handleEdit,
        handleSave,
        handleCancel,
        addNewData,
        handleToggleItem,
        handleToggleAll,
        deleteRow,
        dataList,
        isEditMode,
        dataOnChange,
    } = useDataGrid()

    // pagination
    const [filter, setFilter] = useState({
        totalPageSize: 1,
        totalItemSize: 0,
        pageNum: 1,
        pageSize: 10,
    })
    // 搜尋欄位
    // 院所代碼
    const [hospitalCode, setHospitalCode] = useState('')
    // 院所名稱
    const [hospitalName, setHospitalName] = useState('')
    // 欲刪除的資料
    const [activeRow, setActiveRow] = useState({})
    // 刪除確認popup控制
    const [showDeletePopup, setShowDeletePopup] = useState(false)
    // toast message
    const showToast = useToast()
    // 列印模式
    const isPrintMode = useSelector((state) => state.print.isPrintMode)

    /**
     * 院所代碼改變事件
     * @param e {object} 事件
     * @return {void}
     */
    const handleHospitalCodeChange = (e) => setHospitalCode(e.target.value)
    /**
     * 院所名稱改變事件
     * @param e {object} 事件
     * @return {void}
     */
    const handleHospitalNameChange = (e) => setHospitalName(e.target.value)

    /**
     * 刪除單筆資料
     * @return {void}
     */
    const handleConfirmDelete = () => {
        deleteTranUser({hospNo: activeRow.hospNo}).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                showToast({message: '刪除成功', type: AlertTypeEnum.Success})
                deleteRow(activeRow)
            } else {
                showToast({message: res.msg, type: AlertTypeEnum.Error})
            }

            setShowDeletePopup(false)
        })
    }
    /**
     * 開啟刪除彈窗
     * @param tableRow {object} 資料列
     * @return {void}
     */
    const handleDeletePopupOpen = (tableRow) => {
        setShowDeletePopup(true)
        setActiveRow(tableRow)
    }
    /**
     * 關閉刪除彈窗
     * @return {void}
     */
    const handleDeletePopupClose = () => {
        setShowDeletePopup(false)
    }

    /**
     * 點選 pagination 頁碼
     * @param page {string} 目前頁碼
     * @return {void}
     */
    const handlePageOnChange = (page) => {
        const currentPage = Number(page)
        fetchTranUserList(currentPage, filter.pageSize)
    }

    /**
     * 變更每頁筆數
     * @param event {object} 事件
     * @return {void}
     */
    const handlePageSizeChange = (event) => {
        const pageSize = Number(event.target.value)
        fetchTranUserList(1, pageSize)
    }
    /**
     * 點選上一頁
     * @return {void}
     */
    const handlePrevPageOnClick = () => {
        const prevPage = filter.pageNum - 1
        const page = prevPage < 1 ? filter.totalPageSize : prevPage
        fetchTranUserList(page, filter.pageSize)
    }

    /**
     * 點選下一頁
     * @return {void}
     */
    const handleNextPageOnClick = () => {
        const nextPage = filter.pageNum + 1
        const firstPage = 1
        const page = nextPage > filter.totalPageSize ? firstPage : nextPage
        fetchTranUserList(page, filter.pageSize)
    }

    /**
     * 查詢按鈕
     * @return {void}
     */
    const handleClickQuery = () => {
        fetchTranUserList(1, filter.pageSize)
    }

    /**
     * 輸入框失焦事件
     * @param e {object} 事件
     * @param row {object} 資料列
     * @param key {string} input欄位名稱
     * @return {void}
     */
    const handleInputOnBlur = (e, row, key) => {
        // 檢查院所代碼是否有值
        if (key === 'hospNo' && !stringIsEmpty(row.hospNo)) {
            const value = e.target.value
            // 以院所代碼取得院所名稱
            getHospNameByHospNo(value, row)
        }
    }

    /**
     * 以院所代碼取得院所名稱
     * @param value {string} 院所代碼
     * @param row {object} 資料列
     * @return {void}
     */
    const getHospNameByHospNo = debounce((value, row) => {
        queryByHospNo({hospNo: value}).then((res) => {
            // 成功 
            if (res.err === ApiErrorStatusEnum.Success) {
                // 查無相關院所代碼
                if (objectIsEmpty(res.data)) {
                    showToast({
                        message: '查無相關院所代碼',
                        type: AlertTypeEnum.Error,
                    })
                    // 清空資料
                    dataOnChange(row, 'hospName', '')
                    dataOnChange(row, 'hospNo', '')
                } else {
                    dataOnChange(row, 'hospName', res.data.hospName || '')
                    dataOnChange(row, 'hospNo', value)
                }
            } else {
                // 失敗
                showToast({
                    message: res.msg,
                    type: AlertTypeEnum.Error,
                })
                // 清空資料
                dataOnChange(row, 'hospName', '')
                dataOnChange(row, 'hospNo', '')
            }
        })
    })

    /**
     * 取得權限設定清單
     * @param pageNum {number} 頁碼
     * @param pageSize {number} 每頁筆數
     * @return {void}
     */
    const fetchTranUserList = (pageNum, pageSize) => {
        // 取得權限設定清單
        queryTranUser({
            hospName: hospitalName,
            hospNo: hospitalCode,
            pageNum,
            pageSize,
        }).then((res) => {
            // 成功
            if (res.err === ApiErrorStatusEnum.Success) {
                // 預設所有資料為未選取
                const list =
                    res.data?.dataList.map((item) => {
                        return {
                            ...item,
                            isSelected: false,
                        }
                    }) || []
                // 無資料
                if (list.length === 0) {
                    showToast({
                        message: '查無資料',
                        type: AlertTypeEnum.Warning,
                    })
                }
                // 更新資料列表
                updateDataList(list)
                // 更新分頁
                setFilter({
                    ...filter,
                    totalItemSize: res.data.totalItemSize,
                    totalPageSize: res.data.totalPageSize,
                    pageSize,
                    pageNum,
                })
            } else {
                // 失敗
                showToast({
                    message: res.msg,
                    type: AlertTypeEnum.Error,
                })
            }
        })
    }

    /**
     * 匯出Excel
     * @return {void}
     */
    const handleExportExcel = () => {
        const selectedData = dataList.filter((item) => item.isSelected)
        // 未選取不能匯出 excel
        if (selectedData.length === 0) {
            return
        }
        // 取得欄位key
        const keys = PERMISSION_SETTING_HEADER.map((item) => item.key)
        // 取得欄位名稱
        const titles = PERMISSION_SETTING_HEADER.map((item) => item.name)
        // 資料陣列
        const dataArray = []
        // 遍歷選取資料將每列資料轉為陣列
        selectedData.forEach((item) => {
            const data = []
            keys.forEach((key) => {
                // 日期欄位格式轉為yyyy-MM-dd
                if (key === 'applicantDate') {
                    data.push(time2String(item[key], DATE_FORMAT))
                } else {
                    data.push(item[key])
                }
            })
            dataArray.push(data)
        })
        // 建立Excel sheet
        const sheets = [
            {
                titles,
                data: dataArray,
            },
        ]
        // 匯出Excel
        downloadFile(
            exportExcelAsBlob(sheets),
            '權限設定(轉診外網)',
            FileExtensionsEnum.XLSX
        )
    }

    /**
     * 匯出Excel按鈕
     * @return {JSX.Element}
     */
    const exportExcelBtn = () => {
        return (
            <Button
                icon={IconEnum.Export}
                text={t('general.export')}
                color={ButtonColorEnum.Success}
                size={ButtonSizeEnum.Large}
                variant={ButtonVariantEnum.Text}
                onClick={handleExportExcel}
            />
        )
    }

    /**
     * 新增列
     * @return {void}
     */
    const addNewPermission = () => {
        const newData = {
            lockVersion: 0,
            hospNo: '',
            hospAccount: '',
            hospPasswd: '',
            applicantName: '',
            applicantTel: '',
            applicantFax: '',
            applicantEmail: '',
            applicantDate: '',
            seqNo: '',
            sortBy: '',
            hospName: '',
        }
        addNewData(newData)
    }

    /**
     * 編輯該列資料
     * @param row {object} 資料列
     * @return {void}
     */
    const editPermission = (row) => {
        handleEdit({
            ...row,
            applicantDate: time2String(row.applicantDate, DATE_FORMAT) || '',
            applicantEmail: row.applicantEmail || '',
            applicantName: row.applicantName || '',
            hospAccount: row.hospAccount || '',
            hospName: row.hospName || '',
            hospNo: row.hospNo || '',
            hospPasswd: row.hospPasswd || '',
        })
    }

    /**
     * 儲存該列資料
     * @param tableRow {object} 資料列
     * @return {void}
     */
    const savePermissionData = (tableRow) => {
        // 檢查欄位是否有值
        if (
            stringIsEmpty(tableRow.hospNo) ||
            stringIsEmpty(tableRow.hospName) ||
            stringIsEmpty(tableRow.hospAccount) ||
            stringIsEmpty(tableRow.hospPasswd) ||
            stringIsEmpty(tableRow.applicantName) ||
            stringIsEmpty(tableRow.applicantDate)
        ) {
            return
        }

        const data = {
            // 鎖定版本
            lockVersion: tableRow.lockVersion,
            // 院所代碼
            hospNo: tableRow.hospNo,
            // 醫院帳號
            hospAccount: tableRow.hospAccount,
            // 醫院帳號
            hospPasswd: tableRow.hospPasswd,
            // 申請人姓名
            applicantName: tableRow.applicantName,
            // 申請人電話
            applicantTel: tableRow.applicantTel,
            // 申請人傳真
            applicantFax: tableRow.applicantFax,
            // 申請人Email
            applicantEmail: tableRow.applicantEmail,
            // 申請日期
            applicantDate: time2String(tableRow.applicantDate, DATETIME_FORMAT),
            // 序號
            seqNo: tableRow.seqNo,
            // 排序
            sortBy: tableRow.sortBy,
            // 院所名稱
            hospName: tableRow.hospName,
        }
        // 儲存權限設定
        saveTranUser(data).then((res) => {
            // 成功
            if (res.err === ApiErrorStatusEnum.Success) {
                handleSave(tableRow)
                fetchTranUserList(filter.pageNum, filter.pageSize)
                showToast({message: '儲存成功', type: AlertTypeEnum.Success})
            } else {
                // 失敗
                showToast({message: res.msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 列印
     * @return {void}
     */
    const handlePrintList = () => {
        const printData = dataList.filter((item) => item.isSelected)
        store.dispatch(
            updatePrintContent({
                reportType: FORM_TYPE.permissionSettingList,
                printData,
            })
        )
    }

    /**
     * 標籤列印
     * @return {void}
     */
    const handlePrintLabelList = () => {
        const printData = dataList.filter((item) => item.isSelected)
        store.dispatch(
            updatePrintContent({
                reportType: FORM_TYPE.permissionLabel,
                printData,
            })
        )
    }

    /**
     * 開啟列印模式則開啟瀏覽器列印視窗
     * 結束列印則關閉列印模式
     */
    useEffect(() => {
        if (isPrintMode) {
            window.print()
            store.dispatch(completePrintMode())
        }
    }, [isPrintMode])

    return (
        <Layout>
            {/*刪除確認popup*/}
            <Dialog
                muiDialogContentProps={{sx: {height: '110px', display: 'flex', alignItems: 'center'}}}
                open={showDeletePopup}
                title={'提醒'}
                variant={DialogVariant.DELETE}
                paperStyleProps={{width: DialogSizeEnums.SM}}
                onClose={handleDeletePopupClose}
                onDelete={handleConfirmDelete}
                content={'是否確定刪除？'}
            />
            {/*院所代碼*/}
            <div className="flex justify-between p-2 items-center">
                <div className="flex items-center space-x-2">
                    {/* 院所代碼 */}
                    <TextField
                        label="院所代碼"
                        value={hospitalCode}
                        onChange={handleHospitalCodeChange}
                    />
                    {/* 院所名稱 */}
                    <TextField
                        label="院所名稱"
                        value={hospitalName}
                        onChange={handleHospitalNameChange}
                    />
                    {/* 查詢 */}
                    <Button
                        sx={{fontWeight: 'bold'}}
                        color={ButtonColorEnum.Primary}
                        size={ButtonSizeEnum.Medium}
                        variant={ButtonVariantEnum.Contained}
                        text={t('general.query')}
                        onClick={handleClickQuery}
                    />
                </div>
                <div className="flex gap-2">
                    {/* 標籤列印 */}
                    <Button
                        sx={{fontWeight: 'bold'}}
                        color={ButtonColorEnum.Secondary}
                        size={ButtonSizeEnum.Medium}
                        variant={ButtonVariantEnum.Outlined}
                        text='標籤列印'
                        onClick={handlePrintLabelList}
                    />
                    {/* 列印 */}
                    <Button
                        sx={{fontWeight: 'bold'}}
                        color={ButtonColorEnum.Secondary}
                        size={ButtonSizeEnum.Medium}
                        variant={ButtonVariantEnum.Outlined}
                        text={t('general.print')}
                        onClick={handlePrintList}
                    />
                </div>
            </div>
            {/* 表格 */}
            <CustomTable
                isSelectable={true}
                fields={PERMISSION_SETTING_HEADER}
                dataList={dataList}
                toggleAll={handleToggleAll}
                toggleItem={handleToggleItem}
                isCrud={true}
                slotButton={exportExcelBtn()}
                handleAddRow={addNewPermission}
                isEditMode={isEditMode}
                handleEdit={editPermission}
                handleSave={savePermissionData}
                handleCancel={handleCancel}
                addNewData={addNewData}
                deleteRow={handleDeletePopupOpen}
                handleInputOnBlur={handleInputOnBlur}
            />
            {/* 分頁 */}
            <div className="flex justify-end">
                <Pagination
                    pageSize={filter.pageSize}
                    totalSize={filter.totalItemSize}
                    currentPage={filter.pageNum}
                    totalPageSize={filter.totalPageSize}
                    onPageOnChange={handlePageOnChange}
                    onPageSizeChange={handlePageSizeChange}
                    onPrevPageOnClick={handlePrevPageOnClick}
                    onNextPageOnClick={handleNextPageOnClick}
                />
            </div>
        </Layout>
    )
}

export default PermissionSetting
