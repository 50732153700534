import React, {useEffect, useState} from "react"
import {DefaultButton} from "../Button/DefaultButton";

/**
 * 退掛確認彈窗
 * @param show 是否顯示
 * @param closePopupButtonOnClick 關閉按鈕點擊事件
 * @param savePopupButtonOnClick 確認按鈕點擊事件
 * @returns
 */
const PinCodePopup = ({
   show = false,
   closePopupButtonOnClick,
   savePopupButtonOnClick
}) => {

    /**
     * 是否顯示彈窗
     */
    const [showPopup, setShowPopup] = useState(show)

    /**
     * 確定刪除點擊事件
     */
    const handleConfirmOnClick = () => {
        closePopupButtonOnClick()
    }

    /**
     * 確認PINCODE btn
     * */ 
    const handleConfirmSaveOnClick =()=>{
        savePopupButtonOnClick()
    }

    /**
     * 取消刪除點擊事件
     */
    const handleCancelOnClick = () => {
        closePopupButtonOnClick()
    }

    /**
     * 監聽是否顯示彈窗Pros變化
     */
    useEffect(() => {
        setShowPopup(show)
    }, [show])

    return showPopup ? <>
        <div
            className="absolute top-0 left-0 bg-[rgba(0,0,0,0.5)] w-screen h-screen z-80 min-w-screen min-h-screen items-center justify-center flex py-12 px-[100px] text-globalFont"
        >
            <div
                className="flex w-[800px] bg-[#ffffff] rounded-md text-lg relative flex flex-col"
                onClick={(e) => e.stopPropagation()}>
                {/**Title */}
                <div
                    className="h-[60px] w-full px-6 py-4 bg-white rounded-tl-lg rounded-tr-lg border-b border-neutral-900 border-opacity-20 flex justify-between items-center gap-2.5">
                    <div className="text-black text-xl font-normal">
                        請輸入PinCode
                    </div>
                    
                    <div className="text-sky-700 text-lg font-normal cursor-pointer"
                        onClick={handleConfirmOnClick}>關閉
                    </div>
                </div>
                {/**Container */}
                <div className="flex flex-row">
                    <p className="mt-5 w-[150px] ">Pin Code</p>
                    <input className="w-full h-10 m-4 border-[1px] border-[#D4D4D8] rounded-[6px] text-center"
                         type="password"
                    />
                </div>
                <div
                    className="h-[60px] p-4 bg-white rounded-bl-lg rounded-br-lg border-t border-neutral-900 border-opacity-20 flex-col justify-center items-end inline-flex">
                    <button
                        className="bg-[#2B6CB0] p-2 text-white rounded-[6px] text-[16px] min-w-[64px]"
                        onClick={handleConfirmSaveOnClick}
                    >送出
                    </button>
                </div>
            </div>
        </div>

    </> : <></>
}

export default PinCodePopup;