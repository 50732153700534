import React from "react";
import { ReactComponent as MenuOpen } from "../../assets/images/icons/ham.svg";

function SideTab({ isOpen, toggleMenu }) {
  return (
    <button
      className="absolute top-[-43px] w-[32px] h-[32px] left-4 z-10 shadow-md"
      onClick={toggleMenu}
    >
       <MenuOpen/>
    </button>
  );
}

export default SideTab;
