import React from 'react';

// const progressPercentage = 25;

const ProgressBarChart = ({ percentage, color }) => {
    return (
        <div className="flex items-center w-full">
            <div className="flex-1 h-5 bg-[#5B93FF0D]/10 rounded-full overflow-hidden">
                <div className={`h-full bg-[${color}] rounded-full`} style={{ width: `${percentage}%` }}></div>
            </div>
            <div className="ml-2">{percentage}%</div>
        </div>
    );
};

export default ProgressBarChart;
