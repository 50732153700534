import React from "react";
import { ReactComponent as Check } from "../../assets/images/icons/checkbox.svg";
import {arrayIsEmpty, fuzzySearchObjects} from "edah_utils/dist";

const NewCaseList = ({ data, searchText }) => {
    /**
     * 取得模糊匹配後的資料陣列
     * @return {Array<Object>}
     */
    const getFilterData = (data) => fuzzySearchObjects(data, searchText);

    return (
        <ul className="tableList CQRRTableList">
            {/*表單項目標題*/}
            <li className="title">
                <div><Check /></div>
                <div>家字號</div>
                <div>病歷號</div>
                <div>姓名</div>
                <div>收案日期</div>
                <div>主診斷碼</div>
                <div>主診斷碼敘述</div>
                <div>轉介來源</div>
                <div>一般護照</div>
                <div>留置鼻胃管</div>
                <div>留置尿管</div>
                <div>氣切更換</div>
                <div>呼吸器</div>
                <div>傷口照護</div>
                <div>造口護理</div>
                <div>檢體收集</div>
                <div>其他</div>
                <div>其他敘述</div>
                <div>收案人員</div>
            </li>

            {!arrayIsEmpty(getFilterData(data)) ? getFilterData(data).map((item, index) =>
                <li key={index}>
                    {/*操作*/}
                    <div>
                        <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5"
                               type="checkbox"
                               id={`r${index}`} style={{ accentColor: "#38A169" }} />
                    </div>
                    {/*家字號*/}
                    <div>{item.familySerialNumber}</div>
                    {/*病歷號*/}
                    <div>{item.medicalRecordNumber}</div>
                    {/*姓名*/}
                    <div>{item.name}</div>
                    {/*收案日期*/}
                    <div>{item.admissionDate}</div>
                    {/*主診斷碼*/}
                    <div>{item.primaryDiagnosisCode}</div>
                    {/*主診斷碼敘述*/}
                    <div>{item.primaryDiagnosisDescription}</div>
                    {/*轉介來源*/}
                    <div>{item.referralSource}</div>
                    {/*一般護照*/}
                    <div>{item.generalPassport}</div>
                    {/*留置鼻胃管*/}
                    <div>{item.nasogastricTube}</div>
                    {/*留置尿管*/}
                    <div>{item.urinaryCatheter}</div>
                    {/*氣切更換*/}
                    <div>{item.tracheostomyReplacement}</div>
                    {/*呼吸器*/}
                    <div>{item.ventilator}</div>
                    {/*傷口照護*/}
                    <div>{item.woundCare}</div>
                    {/*造口護理*/}
                    <div>{item.ostomyCare}</div>
                    {/*檢體收集*/}
                    <div>{item.specimenCollection}</div>
                    {/*其他*/}
                    <div>{item.others}</div>
                    {/*其他敘述*/}
                    <div>{item.otherDescription}</div>
                    {/*收案人員*/}
                    <div>{item.caseManager}</div>
                </li>
            ) : null}
        </ul>
    );
};

export default NewCaseList;
