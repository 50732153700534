import { useSelector } from 'react-redux'
import './print.scss'

/**
 * 轉診回函信封列印
 * @returns {JSX.Element}
 */
const PrintReplyLetterEnvelopeCover = () => {
    const printData = useSelector((state) => state.print.printData)
    return (
        <>
            {printData.map((item) => (
                <div
                    key={item.encounterId}
                    className="envelope-cover rotate-90"
                >
                    <div className="w-full p-8 rounded-md grid grid-cols-[10%,90%]">
                        <div>From：</div>
                        <div>
                            <p className="text-left mb-2">
                                義大醫療財團法人義大醫院轉診中心
                                (機構代號1142120001)
                            </p>
                            <p className="text-left">
                                高雄市燕巢區角宿里義大路1號
                            </p>
                        </div>
                    </div>
                    <div className="w-full grid grid-cols-[10%,90%]">
                        <div></div>
                        <div className="border-[#D4D4D8] border p-2">
                            <p>
                                {item.recipient
                                    .split('\n')
                                    .map((item, index) => {
                                        return <p key={`recipient-cover-${index}`}>{item}</p>
                                    })}
                            </p>
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}

export default PrintReplyLetterEnvelopeCover
