import React, {useEffect, useState} from "react";
import {ApiErrorStatusEnum, InputTypeEnum} from "edah_utils/dist";
import {
    rsisAddCodeValue,
    rsisDelCodeValue,
    rsisEditCodeValue,
    rsisQueryCodeValueCodeType,
    rsisQueryCodeValueCodeTypeOption
} from "../../../api/v1/RSIS";
import useToast from "../../../hooks/useToast";
import DeletePopup from "../../../components/RSIS/Popup/DeletePopup";
import {t} from "i18next"
import {
    AlertTypeEnum,
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    IconEnum,
    Select,
    Table
} from "edah-component/dist"

const GridRowModes = {
    View: "view",
    Edit: "edit",
};

/**
 * 布局
 * @param children
 * @return {JSX.Element}
 * @constructor
 */
const Layout = ({children}) => {
    return (
        <div className="p-2 w-full h-[calc(100vh-98px)] overflow-auto">
            {children}
        </div>
    );
};

/**
 * 標題
 * @param children
 * @param extendClass
 * @returns {Element}
 * @constructor
 */
const Title = ({children, extendClass}) => {
    return (
        <div
            className={`date flex flex-row items-center justify-start ${extendClass}`}
        >
            <div>{children}</div>
        </div>
    );
};
// 類別
const InputStyle =
    "w-[160px] h-10  px-2 bg-white text-black rounded-[6px] border border-gray-300";
// 按鈕樣式
const ButtonStyle =
    "flex items-center justify-center font-bold px-4 h-10 rounded-[6px] bg-[#2B6CB0] text-white";
// 副按鈕樣式
const SecondButtonStyle =
    "bg-gray-50 flex items-center justify-center font-bold px-4 h-10 rounded-[6px] border-2";
// 下拉選單樣式
const SelectStyle =
    "w-[250px] h-10  px-2 bg-white text-black rounded-[6px] border border-gray-300";

/**
 * 基本資料設定
 * @returns {Element}
 * @constructor
 */
const BasicSetting = () => {
    // 類別
    const [codeType, setCodeType] = useState("");
    // 類別選項
    const [typeOptions, setTypeOptions] = useState([]);
    // 有效否
    const [effective, setEffective] = useState('ALL');
    // 有效否選項
    const [effectiveOptions, setEffectiveOptions] = useState([
        {
            label: "Y",
            value: 1,
        },
        {
            label: "N",
            value: 0,
        },
        {
            label: "ALL",
            value: 'ALL',
        },
    ]);
    // 表格內容
    const [rows, setRows] = useState([]);
    // 刪除彈窗
    const [showDeletePopup, setShowDeletePopup] = useState(false)
    // 刪除ID
    const [deleteId, setDeleteId] = useState()
    // 編輯狀態
    const [rowModesModel, setRowModesModel] = useState({});
    /**
     * Show Toast
     */
    const showToast = useToast()
    /**
     * 有效否改變
     * @param value {String}
     */
    const handleEffectiveChange = (value) => {
        setEffective(value);
    };

    /**
     * 類別改變
     * @param value {String}
     */
    const handleTypeChange = (value) => {
        setCodeType(value);
    };

    const updateRow = (row) => {
        // 使用 validateEffFlag 方法檢核有效否
        if (!validateEffFlag(row)) {
            return;
        }

        const newRow = {
            // CODEVALUE_UUID
            codeValueUUID: row?.codevalueUUID || '',
            // 類別
            codeType: codeType || '',
            // 代號
            codeNo: row.codeNo,
            // 值1
            codeValue1: row.codeValue1,
            // 值2
            codeValue2: row.codeValue2,
            // 值3
            codeValue3: row.codeValue3,
            // 有效否(1/0)
            effFlag: row?.effFlag === "Y" || row?.effFlag === "y" ? 1 : 0,
            // 排列順序
            sortNo: row?.sortNo || ''
        }


        const result = (res) => {
            const {err, msg} = res
            //取得成功
            if (err === ApiErrorStatusEnum.Success) {
                showToast({message: '儲存成功', type: AlertTypeEnum.Success})
            } else {
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        }
        newRow?.codeValueUUID ? rsisEditCodeValue([newRow]).then(result) : rsisAddCodeValue(newRow).then(result)
    }

    /**
     * 新增內容
     */
    const handleAddNewRow = () => {
        const id = Math.floor(Math.random() * 100);
        setRows((oldRows) => [...oldRows, {id, name: "", age: "", isNew: true}]);
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: {mode: GridRowModes.Edit, fieldToFocus: "name"},
        }));
    };


    /**
     * 檢核EffFlag是否存在
     * @param row {Object} row
     * @return {boolean}
     */
    const validateEffFlag = (row) => {
        if (!row.effFlag) {
            setRowModesModel({...rowModesModel, [row.id]: {mode: GridRowModes.Edit}});
            showToast({message: '有效否沒有填寫，不允許存檔', type: AlertTypeEnum.Error});
            return false;
        }
        return true;
    };

    /**
     * 編輯
     * @param id
     * @returns {(function(): void)|*}
     */
    const handleEditClick = (id) => () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.Edit}});
    };

    /**
     * 儲存
     * @param id
     * @returns {(function(): void)|*}
     */
    const handleSaveClick = (id) => () => {
        const row = rows.find((row) => row.id === id)
        console.log(row)
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.View}});
    };

    /**
     * 刪除點擊
     * @param id
     * @returns {(function(): void)|*}
     */
    const handleDeleteClick = (id) => () => {
        const deleteUUid = rows.find((row) => row.id === id)?.codevalueUUID
        setDeleteId(deleteUUid)
        setShowDeletePopup(true)
    };

    /**
     * 刪除
     * @return {void}
     */
    const handleDeletePopup = () => {
        rsisDelCodeValue({codevaluePk: deleteId}).then(async res => {
            const {msg, err} = res
            //執行成功
            if (err === ApiErrorStatusEnum.Success) {
                // 顯示訊息
                showToast({message: msg})
                await handleClickQuery()
                setShowDeletePopup(false)
            } else { //錯誤
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 取消
     * @param id
     * @returns {(function(): void)|*}
     */
    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: {mode: GridRowModes.View, ignoreModifications: true},
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }
    };

    /**
     * 新增內容
     * @returns {Element}
     * @constructor
     */
    const AddNewRow = () => {
        return (
            <div className="flex px-2 mt-2 mb-2 justify-between">
                <Button
                    color={ButtonColorEnum.Success}
                    icon={IconEnum.Add}
                    size={ButtonSizeEnum.Large}
                    text={t('Regs.DoctorInformSearch.addContent')}
                    variant={ButtonVariantEnum.Text}
                    onClick={handleAddNewRow}
                />
            </div>
        );
    };

    /**
     * 表格標頭
     * @type {[{headerClassName: string, field: string, renderHeader: (function(): *), width: number, getActions: ((function({id: *}): *)|*), sortable: boolean, type: string},{headerClassName: string, headerName: string, field: string, editable: boolean, flex: number, width: number, type: InputTypeEnum.Text},{headerClassName: string, headerName: string, field: string, flex: number, className: string, type: InputTypeEnum.Text},{headerClassName: string, headerName: string, field: string, flex: number, type: InputTypeEnum.Text},{headerClassName: string, headerName: string, field: string, flex: number, type: InputTypeEnum.Text},null]}
     */
    const header = [
        {
            field: "actions",
            type: "actions",
            renderHeader: () => <AddNewRow/>,
            sortable: false,
            width: 200,
            headerClassName: "super-app-theme--header",
            // 客製化 actions
            getActions: ({id}) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
                // 編輯模式
                if (isInEditMode) {
                    return [
                        // 儲存
                        <Button color={ButtonColorEnum.Primary}
                                variant={ButtonVariantEnum.Text}
                                size={ButtonSizeEnum.Large}
                                icon={IconEnum.Save}
                                text={t('general.save')}
                                onClick={handleSaveClick(id)}/>,
                        // 取消
                        <Button color={ButtonColorEnum.Primary}
                                variant={ButtonVariantEnum.Text}
                                size={ButtonSizeEnum.Large}
                                icon={IconEnum.Cancel}
                                text={t('general.cancel')}
                                onClick={handleCancelClick(id)}/>
                        ,
                    ];
                } else {
                    return [
                        // 編輯
                        <Button
                            color={ButtonColorEnum.Primary}
                            variant={ButtonVariantEnum.Text}
                            size={ButtonSizeEnum.Large}
                            icon={IconEnum.Edit}
                            text={t('general.edit')}
                            onClick={handleEditClick(id)}/>
                        ,
                        // 刪除
                        <Button
                            color={ButtonColorEnum.Primary}
                            variant={ButtonVariantEnum.Text}
                            size={ButtonSizeEnum.Large}
                            icon={IconEnum.Delete}
                            text={t('general.delete')}
                            onClick={handleDeleteClick(id)}
                        />,
                    ];
                }
            },
        },
        {
            headerName: "代號",
            field: "codeNo",
            headerClassName: "super-app-theme--header",
            width: 220,
            editable: true,
            flex: 1,
            type: InputTypeEnum.Text,
        },
        {
            headerName: "值1",
            className: "",
            field: "codeValue1",
            type: InputTypeEnum.Text,
            editable: true,
            flex: 1,
            headerClassName: "super-app-theme--header",
        },
        {
            headerName: "值2",
            field: "codeValue2",
            type: InputTypeEnum.Text,
            editable: true,
            flex: 1,
            headerClassName: "super-app-theme--header",
        },
        {
            headerName: "值3",
            field: "codeValue3",
            type: InputTypeEnum.Text,
            editable: true,
            flex: 1,
            headerClassName: "super-app-theme--header",
        },
        {
            headerName: "有效否",
            field: "effFlag",
            type: InputTypeEnum.Text,
            editable: true,
            flex: 1,
            headerClassName: "super-app-theme--header",
        },
    ];

    /**
     * 查詢類別內容
     * @param type
     * @returns {Promise<void>}
     */
    const fetchQueryCodeValueCodeType = async (type) => {
        const fetchCodeType = codeType || type
        const res = await rsisQueryCodeValueCodeType({
            codeType: fetchCodeType,
            effFlag: effective === 'ALL' ? 'ALL' : effective === 1 ? 'Y' : 'N'
        });
        const data = res.data.map((item, index) => {
            return {
                ...item,
                effFlag: item.effFlag === 1 ? 'Y' : 'N',
                id: index + item.codeNo + item.codeType + item.codeValueUUID
            }
        })
        setRows(data);
    }

    /**
     * 查詢按鈕
     */
    const handleClickQuery = async () => {
        fetchQueryCodeValueCodeType()
    };

    /**
     * 初始化
     * @returns {Promise<void>}
     */
    const init = async () => {
        // 取得類別選項
        const result = await rsisQueryCodeValueCodeTypeOption();
        setTypeOptions(
            //過濾掉相同的value
            result.data
                .map((item) => ({
                    value: item.codeType,
                    label: item.codeType,
                    id: item.codeType
                }))
                .filter((item, index, self) =>
                    index === self.findIndex((t) => t.value === item.value)
                )
        );
        setCodeType(result.data[0].codeType)
        fetchQueryCodeValueCodeType(result.data[0].codeType)
    };

    const processRowUpdate = (newRow) => {
        const updatedRow = {...newRow, isNew: false};
        setRows(rows.map(row => (row.id === newRow.id ? updatedRow : row)));
        updateRow(newRow)
        return updatedRow;
    };

    /**
     * 處理Row更新時的錯誤
     * @param error {Object}
     * @return {void}
     */
    const handleProcessRowUpdateError = (error) => {
        return
    }

    useEffect(() => {
        init();
    }, []);

    return (
        <Layout>
            {/* 類別 */}
            <div className="flex justify-between p-2 items-center">
                <div className="flex items-center text-left">
                    <Select
                        data={{
                            label: '類別',
                            options: typeOptions.map((item) => ({
                                value: item.value,
                                label: item.label
                            }))
                        }}
                        value={codeType}
                        onChange={handleTypeChange}
                        notched={true}
                        displayEmpty = {true}
                    />
                    {/* 有效否 */}
                    <Select
                        data={{
                            label: '有效否',
                            options: effectiveOptions.map((item) => ({
                                value: item.value,
                                label: item.label
                            }))
                        }}
                        value={effective}
                        onChange={handleEffectiveChange}
                        notched={true}
                        displayEmpty = {true}
                    />

                    {/* 查詢 */}
                    <Button sx={{marginLeft: '1rem'}} color={ButtonColorEnum.Primary}
                            variant={ButtonVariantEnum.Contained} size={ButtonSizeEnum.Medium}
                            onClick={handleClickQuery}
                            text={t('general.query')}/>
                </div>
            </div>
            {showDeletePopup && (
                <DeletePopup
                    handleDeletePopupClose={() => {
                        setShowDeletePopup(false);
                    }}
                    handleNotificationDelete={handleDeletePopup}
                    currentKey={deleteId}
                />
            )}

            {/* 表格 */}
            <Table
                autoHeight={true}
                columns={header}
                rows={rows}
                rowModesModel={rowModesModel}
                hideFooter={true}
                processRowUpdate={processRowUpdate}
                onProcessRowUpdateError={handleProcessRowUpdateError}
                sx={{
                    '& .MuiDataGrid-cell': {
                        borderRight: '1px solid #e0e0e0',
                    },
                    '& .MuiDataGrid-columnHeader': {
                        borderRight: '1px solid #e0e0e0',
                    },
                    '& .MuiDataGrid-columnSeparator': {
                        display: 'none',
                    },
                    '& .MuiDataGrid-row:hover': {
                        backgroundColor: '#BEE3F8', // 替換為你想要的顏色
                    },
                }}
            />
        </Layout>
    );
};

export default BasicSetting;
