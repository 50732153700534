import { useSelector } from 'react-redux'
import A5Print from './A5Print'
import { time2String } from 'edah_utils/dist'
import { DATE_FORMAT, DATETIME_FORMAT } from '../../constants/common'
import './print.scss'
import moment from 'moment'

/**
 * 轉診回診證明單 - 列印內容
 * @returns {JSX.Element}
 */
const PrintReturnTicketContent = () => {
    const printDatetime = new Date()
    const printData = useSelector((state) => state.print.printData)
    return (
        <>
            <h1 className="text-2xl">轉診回診證明單列印</h1>
            <hr className="mb-2" />
            <div className="text-sm text-right">
                列印時間: {time2String(printDatetime, 'yyyy-MM-DD HH:mm:ss')}
            </div>
            <div className="grid grid-cols-[25%,25%,25%,25%] gap-2 text-sm mt-2">
                <div className="grid grid-cols-[40%,60%]">
                    <div className="text-justify">就醫日期:</div>
                    <div className="border-b border-[#111] text-left">
                        {time2String(printData?.encounterDate, DATE_FORMAT)}
                    </div>
                </div>
                <div className="grid grid-cols-[40%,60%]">
                    <div className="text-justify">就醫號:</div>
                    <div className="border-b border-[#111]">
                        {printData?.encounterId}
                    </div>
                </div>
                <div className="grid grid-cols-[40%,60%]">
                    <div className="text-justify">主治醫師:</div>
                    <div className="border-b border-[#111]">
                        {printData?.doctorName}
                    </div>
                </div>
                <div className="grid grid-cols-[40%,60%]">
                    <div className="text-justify">有效期限:</div>
                    <div className="border-b border-[#111]">
                        {moment(printData.encounterDate)
                            .add(1, 'M')
                            .format('YYYY-MM-DD')}
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-[25%,25%,25%,25%] gap-2 text-sm mt-2">
                <div className="grid grid-cols-[40%,60%]">
                    <div className="text-justify">病歷號:</div>
                    <div className="border-b border-[#111] text-left">
                        {printData?.patientId}
                    </div>
                </div>
                <div className="grid grid-cols-[40%,60%]">
                    <div className="text-justify">病人姓名:</div>
                    <div className="border-b border-[#111]">
                        {printData?.patientName}
                    </div>
                </div>
                <div></div>
                <div></div>
            </div>
            <div className="grid grid-cols-[25%,1fr] gap-2 text-sm mt-2">
                <div className="grid grid-cols-[40%,60%]">
                    <div className="text-justify">轉入院所:</div>
                    <div className="border-b border-[#111] text-left">
                        {printData?.tranIn.hospNoIn}
                    </div>
                </div>
                <div className="border-b border-[#111]">
                    {printData?.tranIn.hospNameIn}
                </div>
            </div>
            <div className="w-full grid grid-cols-[10%,1fr] gap-2 text-sm mt-2">
                <div className="text-justify">診斷:</div>
                <div className="grid grid-cols-[20%,20%,20%,20%,20%] gap-2">
                    <div className="border-b border-[#111] text-left">
                        {printData?.tranIn.treatmentCode}
                    </div>
                    <div className="border-b border-[#111] text-left">
                        {printData?.tranIn.treatmentCode1}
                    </div>
                    <div className="border-b border-[#111] text-left">
                        {printData?.tranIn.treatmentCode2}
                    </div>
                    <div className="border-b border-[#111] text-left">
                        {printData?.tranIn.treatmentCode3}
                    </div>
                    <div className="border-b border-[#111] text-left">
                        {printData?.tranIn.treatmentCode4}
                    </div>
                </div>
            </div>
            <div className="mt-4">
                注意事項:
                <ul>
                    <li>
                        1.
                        持轉診單就醫後，因「轉診之傷病」經接受轉診之「醫師認定」須繼續於該院所「門診治療」，轉診就醫日起1個月內至多4次之回診，不含轉診就醫當次，可視同轉診。
                    </li>
                    <li>2. 本證明單僅限病人本人且不得跨院區使用。</li>
                    <li>
                        3.
                        本單若未加蓋承辦人員章、塗改、影印及超過有效期限，均屬無效，若有遺失恕不補發。
                    </li>
                    <li>
                        4.
                        請於回診就醫當天務必攜帶本證明單，於就診時將本單交給診間人員，若未出示本單，則依照健保局
                    </li>
                    <li>
                        5.
                        若於回診當天忘記攜帶本單，請於回診後10日內，持本證明單、收據正本、健保IC卡至門診批價櫃檯辦理部分負擔差額退費。
                    </li>
                </ul>
                <div className="flex justify-end font-bold">
                    ※若有不適，請儘速至本院急診就醫※
                </div>
                <div className="grid grid-cols-[25%,25%,25%,25%] border border-[#111] h-[80px]">
                    <div className="grid grid-cols-[20%,1fr] h-full border-[#111]">
                        <div className="h-full flex items-center justify-center border-r border-[#111]">
                            1
                        </div>
                        <div></div>
                    </div>
                    <div className="grid grid-cols-[20%,1fr] h-full border-[#111]">
                        <div className="h-full flex items-center justify-center border-r border-l border-[#111]">
                            2
                        </div>
                        <div></div>
                    </div>
                    <div className="grid grid-cols-[20%,1fr] h-full border-[#111]">
                        <div className="h-full flex items-center justify-center border-r border-l border-[#111]">
                            3
                        </div>
                        <div></div>
                    </div>
                    <div className="grid grid-cols-[20%,1fr]">
                        <div className="h-full flex items-center justify-center border-l border-r border-[#111]">
                            4
                        </div>
                        <div></div>
                    </div>
                </div>
            </div>
        </>
    )
}
/**
 * 轉診回診證明單 - 列印
 * @returns {JSX.Element}
 */
const PrintReturnTicket = () => {
    return (
        <A5Print>
            <PrintReturnTicketContent />
        </A5Print>
    )
}
export default PrintReturnTicket
