import React, {useEffect, useState} from "react"
import {ApiErrorStatusEnum, arrayIsEmpty, PopupModeEnum, ToastTypeEnum} from "edah_utils/dist"
import {t} from "i18next"
import {essiAddEmrType, essiEditEmrType} from "../../api/v1/ESSI"
import useToast from "../../hooks/useToast"
import { Field, TextArea } from "edah-component/dist/index";

/**
 * 電子表單類別維護-新增 / 修改 彈窗
 * @param show 是否顯示
 * @param data {Object} 資料
 * @param mode {Number} 新增或編輯
 * @param parentNameData {Array<String>} 父層代號資料
 * @param closePopupOnClick {function} 關閉按鈕點擊事件
 * @return {JSX.Element}
 */
const AddEditEmrTypePopup = ({show = false, data, mode, parentNameData, closePopupOnClick}) => {
    /**
     * ShowToast
     */
    const showToast = useToast()

    /**
     * 預設資料
     */
    const defaultData = {
        // 表單類別代號
        "emrRptType": '',
        // 表單名稱
        "rptName": '',
        // 顯示順序
        "sortBy": '',
        // 有效否(0/1)
        "effFlag": true,
        // 備註
        "memo": '',
        // 父項類別代號
        "parentEmrRptType": ''
    }

    //是否為新增模式
    const [isAddMode, setIsAddMode] = useState(false)

    //是否顯示彈窗
    const [showPopup, setShowPopup] = useState(show)

    //新增或修改的資料
    const [newData, setNewData] = useState(mode === PopupModeEnum.Add ? defaultData : data)

    /**
     * 確定刪除點擊事件
     * @return {void}
     */
    const handleCloseButtonOnClick = () => {
        // 關閉彈窗
        setShowPopup(false)
        // 關閉彈窗後執行父層的關閉事件
        closePopupOnClick()
    }

    /**
     * 儲存或編輯按鈕被點擊時
     * @return {void}
     */
    const handleSaveOrEditOnClick = () => {
        if (isAddMode) {
            addEmrType()
        } else {
            editEmrType()
        }
    }

    /**
     * 排序欄位改變時
     * @param e {Event}
     * @return {void}
     */
    const handleSortByOnChange = (e) => setNewData({...newData, sortBy: e.target.value})

    /**
     * 父層代號欄位改變時
     * @param e {Event}
     * @return {void}
     */
    const handleParentEmrRptTypeOnChange = (e) => setNewData({...newData, parentEmrRptType: e.target.value})

    /**
     * 單據代號欄位改變時
     * @param e {Event}
     * @return {void}
     */
    const handleEmrRptTypeOnChange = (e) => {
        // 如果不是新增模式
        if (!isAddMode) {
            // 不允許修改
            return
        }
        setNewData({...newData, emrRptType: e.target.value})
    }

    /**
     * 單據名稱欄位改變時
     * @param e {Event}
     * @return {void}
     */
    const handleRptNameOnChange = (e) => setNewData({...newData, rptName: e.target.value})

    /**
     * 說明欄位改變時
     * @param e {Event}
     * @return {void}
     */
    const handleMemoOnChange = (e) => setNewData({...newData, memo: e.target.value})

    /**
     * 有效否欄位改變時
     * @param e {Event}
     * @return {void}
     */
    const handleEffFlagOnChange = (e) => setNewData({...newData, effFlag: !newData.effFlag})

    /**
     * 新增電子表單類別
     * @return {void}
     */
    const addEmrType = () => {
        essiAddEmrType([newData]).then(handleApiResponse)
    }

    /**
     * 編輯電子表單類別
     * @return {void}
     */
    const editEmrType = () => {
        essiEditEmrType([newData]).then(handleApiResponse)
    }

    /**
     * 處理API回應
     * @param res {Object} API回應
     * @return {void}
     */
    const handleApiResponse = (res) => {
        // 取得API回應
        if (res.err === ApiErrorStatusEnum.Success) {
            // 顯示成功訊息
            showToast({message: res.msg})
            closePopupOnClick()
        } else { // 顯示錯誤訊息
            showToast({type: ToastTypeEnum.Error, message: res.msg})
        }
    }

    /**
     * 監聽是否顯示彈窗Pros變化
     * @return {void}
     */
    useEffect(() => {
        // 設定是否顯示彈窗
        setShowPopup(show)
        const isAddMode = mode === PopupModeEnum.Add
        // 設定是否為新增模式
        setIsAddMode(isAddMode)
        if (show) {
            // 如果是新增模式
            if (isAddMode) {
                setNewData(defaultData)
            } else { // 如果是編輯模式
                setNewData(data)
            }
        }
    }, [show])

    return showPopup ?
        <div
            className="absolute top-0 left-0 bg-[rgba(0,0,0,0.5)] w-screen h-screen z-80 min-w-screen min-h-screen items-center justify-center flex py-12 px-[100px] text-globalFont">
            <div className="flex w-[930px] bg-[#ffffff] rounded-md text-lg relative flex-col"
                 onClick={(e) => e.stopPropagation()}>
                {/**Title */}
                <div
                    className="h-[60px] w-full px-6 py-4 bg-white rounded-tl-lg rounded-tr-lg border-b border-neutral-900 border-opacity-20 flex justify-between items-center gap-2.5">
                    <div className="text-black text-xl font-normal">
                        電子表單類別維護
                    </div>
                    <div className="text-sky-700 text-lg font-normal cursor-pointer"
                         onClick={handleCloseButtonOnClick}>{t('general.close')}
                    </div>
                </div>
                {/**Container */}
                <div className="flex flex-row p-[9px] space-x-[20px] text-[16px]">
                    <div className="w-1/2 space-y-[4px]">
                        {/*顯示順序*/}
                        <div className=" space-x-[32px] flex flex-row items-center">
                            <p className="w-[40%] text-right">顯示順序</p>
                            <input
                                className={`px-[14px] w-[60%] border border-[#D4D4D8] h-[40px] rounded-[6px] mr-[30px]`}
                                type="text" value={data.sortBy} onChange={handleSortByOnChange}/>
                        </div>
                        {/*父層代號*/}
                        <div className=" space-x-[32px] flex flex-row items-center">
                            <p className="w-[40%] text-right">父層代號</p>
                            <select
                                className="px-[14px] w-[60%] border border-[#D4D4D8] h-[40px] rounded-[6px] mr-[30px]"
                                onChange={handleParentEmrRptTypeOnChange}>
                                {/*空白*/}
                                <option value=""></option>
                                {
                                    !arrayIsEmpty(parentNameData) && parentNameData.map((data, index) =>
                                        <option key={index}
                                                value={data}>{data}
                                        </option>
                                    )
                                }
                            </select>
                        </div>
                        {/*單據代號*/}
                        <div className="space-x-[32px] flex flex-row items-center">
                            <p className="w-[40%] text-right">單據代號</p>
                            <input
                                className="px-[14px] w-[60%] border border-[#D4D4D8] h-[40px] rounded-[6px] mr-[30px]"
                                disabled={!isAddMode} type="text" value={data.emrRptType}
                                onChange={handleEmrRptTypeOnChange}/>
                        </div>
                    </div>
                    <div className="w-1/2 space-y-[4px]">
                        <div className="min-h-[59px] space-x-[32px] flex flex-row items-center">
                            <p className="w-[40%] text-right">單據有效否</p>
                            <label className='flex cursor-pointer select-none items-center'>
                                <div className='relative'>
                                    <input className='sr-only' type='checkbox' checked={newData.effFlag}
                                           onChange={handleEffFlagOnChange}/>
                                    <div
                                        className={`block h-8 w-14 rounded-full ${newData.effFlag ? 'bg-[#2B6CB0]' : 'bg-[#F4F4F5]'}`}>
                                        <div
                                            className={`left-[4px] top-[4px] absolute w-6 h-6 bg-white rounded-full shadow transition-transform transform ${newData.effFlag ? 'translate-x-full' : ''}`}></div>
                                    </div>
                                </div>
                            </label>
                        </div>
                        {/*單據名稱*/}
                        <div className="min-h-[59px] space-x-[32px] flex flex-row items-center">
                            <p className="w-[40%] text-right">單據名稱</p>
                            <input
                                className={`px-[14px] w-[60%] border border-[#D4D4D8] h-[40px] rounded-[6px] mr-[30px]`}
                                type="text" value={data.rptName}
                                onChange={handleRptNameOnChange}/>
                        </div>
                    </div>
                </div>
                <div className="w-full pl-[9px] mb-2 text-[16px]">
                        <Field label={"說明"} labelWidth={190}>
                            <TextArea
                                value={data.memo}
                                onChange={handleMemoOnChange}
                                placeholder={"請輸入說明"}
                            />
                        </Field>
                </div>
                {/**下方區塊(存檔) */}
                <div
                    className="h-[60px] p-4 bg-white rounded-bl-lg rounded-br-lg border-t border-neutral-900 border-opacity-20 flex-col justify-center items-end inline-flex">
                    {/*送出按鈕*/}
                    <button className="bg-[#2B6CB0] p-2 text-white rounded-[6px] text-[16px] min-w-[64px]"
                            onClick={handleSaveOrEditOnClick}>
                        送出
                    </button>
                </div>
            </div>
        </div> : null
}
export default AddEditEmrTypePopup
