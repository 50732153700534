import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {
    ApiErrorStatusEnum,
    CampusEnum,
    enum2Array,
    generateTimeIntervals,
    getLocalStorage,
    multiTermFuzzySearchObjects,
    stringIsEmpty,
    time2String,
} from 'edah_utils/dist'
import {
    rsisAddMaster,
    rsisCheckTimeValid,
    rsisQueryBaseData,
    rsisQueryFunctionDocHtml,
    rsisQueryOrganization
} from "../../../api/v1/RSIS"
import {t} from "i18next"
import {usePopup} from "../PopupProvider"
import QueryPersonList from "../Modal/QueryPersonList";
import QuerySupportOrg from "../Modal/QuerySupportOrg";
import {getCurrentZoneNo, Layout, sanitizedData} from "../utils";
import {useSelector} from 'react-redux';
import {checkDoctorLicence} from "../../../api/v1/Menu";
import dayjs from 'dayjs';
import {
    AlertTypeEnum,
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    Card,
    DatePicker,
    Dialog,
    DialogSizeEnums,
    DialogVariant,
    Divider,
    Field,
    Search,
    Select,
    SelectWidthEnum,
    SizeEnum,
    TextField
} from "edah-component/dist"

/**
 * 報備支援-急件申請頁面
 * @return {JSX.Element}
 */
const UrgentApplication = () => {
    // 取得當前TabId
    const activeTabId = useSelector(state => state.tab.activeTabId)
    // 取得Context功能
    const {showToast} = usePopup()
    // 當前院區
    const [zone, setZone] = useState(getLocalStorage('campus'))
    // 支援人員代碼
    const [supportUserNo, setSupportUserNo] = useState('')
    // 支援機構代碼
    const [supportOrgNo, setSupportOrgNo] = useState('')
    // 支援科別代碼
    const [supportDivNo, setSupportDivNo] = useState('')
    // 支援科別文字方框
    const [supportDivNoSearch, setSupportDivNoSearch] = useState('')
    // 支援開始日期
    const [itemStartDate, setItemStartDate] = useState('')
    // 支援開始時間
    const [itemStartTime, setItemStartTime] = useState('00:00')
    // 支援結束日期
    const [itemEndDate, setItemEndDate] = useState('')
    // 支援結束時間
    const [itemEndTime, setItemEndTime] = useState('00:00')
    // 執行內容
    const [executeContent, setExecuteContent] = useState('')
    // 醫師清單
    const [doctorList, setDoctorList] = useState([])
    // 科別清單
    const [divisionList, setDivisionList] = useState([])
    // 支援機構清單
    const [organizationList, setOrganizationList] = useState([])
    // 顯示支援醫師查詢彈窗
    const [showQueryPersonList, setShowQueryPersonList] = useState(false)
    // 顯示支援機構查詢彈窗
    const [showQuerySupportOrgModal, setShowQuerySupportOrgModal] = useState(false)
    // 佈告欄
    const [htmlContent, setHtmlContent] = useState('')
    // 申請結果提示窗
    const [applyResult, setApplyResult] = useState({
        //是否顯示
        show: false,
        //執行狀態
        status: false,
        // 訊息
        msg: ''
    })

    /**
     * 醫師/工號方框點擊時
     * @return {void}
     */
    const handleQueryDoctorOnClick = () => setShowQueryPersonList(true)

    /**
     * 支援科別變更時
     * @param _ {Event} 事件
     * @param option {Object} 支援科別
     * @return {void}
     */
    const handleSupportDivOnChange = (_, option) => {
        const newValue = option?.supportDivNo ? `${option?.supportDivNo} ${option?.supportDivName}` : ''
        setSupportDivNo(newValue)
    }

    /**
     * 支援科別方框改變時
     * @param options {Array<Object>}
     * @param state {Object}
     * @return {Array<Object>}
     */
    const handleSupportDivOnFilterOptions = (options, state) => {
        // 取得輸入值
        const {inputValue} = state
        // 當沒有輸入值時，顯示所有選項
        if (!inputValue) {
            return supportDivNo ? multiTermFuzzySearchObjects(options, supportDivNo.split(' ')) : options
        }

        // 當有輸入值時，顯示符合條件的選項
        return multiTermFuzzySearchObjects(options, inputValue.split(' '))
    }

    /**
     * 支援科別方框改變時
     * @param _ {Event} 事件
     * @param v {String} 值
     * @return {void}
     */
    const handleSupportDivSearchOnInputChange = (_, v) => setSupportDivNoSearch(v)

    /**
     * 支援開始日期改變時
     * @param newValue {dayjs} 選擇的新日期
     * @return {void}
     */
    const handleItemStartDateOnChange = (newValue) => setItemStartDate(dayjs(newValue).format('YYYY-MM-DD'))

    /**
     * 支援開始時間改變時
     * @param value {string}
     * @return {void}
     */
    const handleItemStartTime = (value) => setItemStartTime(value)

    /**
     * 支援結束日期改變時
     * @param newValue {dayjs} 選擇的新日期
     * @return {void}
     */
    const handleItemEndDateOnChange = (newValue) => setItemEndDate(dayjs(newValue).format('YYYY-MM-DD'))

    /**
     * 支援結束時間改變時
     * @param value {String}
     * @return {void}
     */
    const handleItemEndTime = (value) => setItemEndTime(value)

    /**
     * 執行內容改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleExecuteContentOnChange = (e) => setExecuteContent(e.target.value)

    /**
     * 取得時間區間選項
     * @return {string[]}
     */
    const getTimeIntervals = () => {
        const intervals = generateTimeIntervals()
        intervals.push('24:00')
        // 產生時間區間
        return useMemo(() => intervals, [])
    }

    /**
     * 取得日期和時間
     * @param date {String}
     * @param time {String}
     * @return {{date: string, time: string}}
     */
    const getDateAndTimeObject = (date, time) => ({
        date: time2String(date, 'YYYY-MM-DD'),
        time: `${time.substring(0, 2)}:${time.substring(2, 4)}`
    })

    /**
     * 取得重疊的時間段
     * @param date {string} 日期
     * @param time {string} 時間
     * @return {Array<Object>}
     */
    const getOverlapDateAndTimeList = (date, time) => {
        // 結果
        let result = []

        // 檢查date和time長度是否一致
        date.length === time.length ? date.map((item, index) => {
            // 將結果加入result
            result.push(getDateAndTimeObject(item, time[index]))
        }) : []

        return result
    }

    /**
     * 存檔按鈕點擊時
     * @return {void}
     */
    const handleSaveButtonOnClick = async () => {
        // 檢查支援醫師是否有值
        if (!supportUserNo) {
            showToast({message: '請選擇支援醫師', type: AlertTypeEnum.Warning})
            return
        }

        // 檢查支援機構是否有值
        if (!supportOrgNo) {
            showToast({
                message: `支援者：${supportUserNo.split(' ')[1]}，${t('RSIS.toast.supportOrgFieldIsMissing')}`,
                type: AlertTypeEnum.Warning
            })
            return
        }

        // 檢查支援科別是否有值
        if (!supportDivNo) {
            showToast({
                message: `支援者：${supportUserNo.split(' ')[1]}，${t('RSIS.toast.supportDivFieldIsMissing')}`,
                type: AlertTypeEnum.Warning
            })
            return
        }

        // 檢查支援日期是否有值
        if (!itemStartDate || !itemEndDate) {
            showToast({
                message: `支援者：${supportUserNo.split(' ')[1]}，${t('RSIS.toast.supportDateFieldIsMissing')}`,
                type: AlertTypeEnum.Warning
            })
            return
        }

        // 檢查是否有空值
        if (stringIsEmpty(supportUserNo)
            || stringIsEmpty(supportOrgNo)
            || stringIsEmpty(supportDivNo)
            || stringIsEmpty(itemStartDate)
            || stringIsEmpty(itemStartTime)
            || stringIsEmpty(itemEndDate)
            || stringIsEmpty(itemEndTime)) {
            showToast({message: '請輸入完整資料', type: AlertTypeEnum.Warning});
            return
        }

        // 取得內容資料
        const sendData = getContentData()

        /**
         * 取得時間數據
         * @param time {String}
         * @return {String}
         */
        const convertTime = (time) => {
            //時間
            const hours = time.slice(0, 2)
            //分鐘
            const minutes = time.slice(2, 4)

            return time ? `${hours}:${minutes}:00` : '00:00:00'
        }

        //起始時間
        const startDate = new Date(time2String(`${sendData.itemStartDate.split(' ')[0]} ${convertTime(sendData.itemStartTime)}`, 'YYYY-MM-DD HH:mm:ss'))
        //結束時間
        const endDate = new Date(time2String(`${sendData.itemEndDate.split(' ')[0]} ${convertTime(sendData.itemEndTime)}`, 'YYYY-MM-DD HH:mm:ss'))
        // 當結束日期早於起始日期
        if (endDate <= startDate) {
            showToast({message: '結束日期不得早於開始日期', type: AlertTypeEnum.Warning})
            return
        }

        rsisCheckTimeValid({
            // 支援人員代號
            "supportUserNo": sendData.supportUserNo,
            "timeList": [
                {
                    // 項目開始日期 yyyy-MM-dd HH:mm:ss
                    "itemStartDate": sendData.itemStartDate,
                    //頁目結束日期"yyyy-MM-dd HH:mm:ss
                    "itemEndDate": sendData.itemEndDate,
                    // 開始時間(24HHMM)
                    "itemStartTime": sendData.itemStartTime,
                    //結束時間(24HHMM)
                    "itemEndTime": sendData.itemEndTime
                }
            ]
        }).then(res => {
            //資料 / 錯誤 / 訊息
            const {data, err, msg} = res
            //API執行錯誤
            if (err === ApiErrorStatusEnum.Success) {
                data.forEach(item => {
                    const {itemStartDate, itemStartTime, itemEndDate, itemEndTime} = item
                    if (item.overlapFlag === false) { //當前未有時間段重疊問題
                        addMaster(sendData)
                    } else { //當前有時間段重疊問題
                        const overlapMessage = `${data?.map(((item) => {
                                const {
                                    itemStartDate,
                                    itemEndDate,
                                    itemStartTime,
                                    itemEndTime,
                                    dbStartDate,
                                    dbEndDate,
                                    dbStartTime,
                                    dbEndTime
                                } = item
                                // 取得當前輸入的起始日期和時間
                                const {
                                    date: inputStartDate,
                                    time: inputStartTime
                                } = getDateAndTimeObject(itemStartDate, itemStartTime)
                                // 取得當前輸入的結束日期和時間
                                const {
                                    date: inputEndDate,
                                    time: inputEndTime
                                } = getDateAndTimeObject(itemEndDate, itemEndTime)
                                // 取得資料庫重疊的起始日期時間段
                                const overlapStartDate = getOverlapDateAndTimeList(dbStartDate, dbStartTime)
                                // 取得資料庫重疊的結束日期時間段
                                const overlapEndDate = getOverlapDateAndTimeList(dbEndDate, dbEndTime)
                                // 重疊時間段字串
                                let overlapTime = ''
                                // 組合重疊時間字串
                                overlapStartDate.map((item, index) => {
                                    overlapTime += `${item.date} ${item.time}~${overlapEndDate[index].date} ${overlapEndDate[index].time}\n`
                                })

                                return overlapTime
                            }
                        ))}`
                        showToast({
                            message: `${itemStartDate.split(' ')[0]} ${convertTime(itemStartTime)}~${itemEndDate.split(' ')[0]} ${convertTime(itemEndTime)}支援時間段重疊，支援人員：${sendData.supportUserName} 已於${overlapMessage}申請報備支援。`,
                            type: AlertTypeEnum.Warning
                        })
                    }
                })
            } else { //API發生錯誤時
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 醫師欄位更新時
     * @param doctor {Object} 醫師資料
     * @return {void}
     */
    const handleOnUpdateDoctor = (doctor) => {
        // 設定支援醫師編號
        setSupportUserNo(`${doctor.userNo} ${doctor.userName}`)
        // 根據醫師編號取得執登資料
        getDoctorData(doctor.userNo)
        // 關閉醫師查詢彈窗
        setShowQueryPersonList(false)
    }

    /**
     * 支援機構/工號方框點擊時
     * @return {void}
     */
    const handleQuerySupportOrg = () => setShowQuerySupportOrgModal(true)

    /**
     * 更新支援機構/工號
     * @return {void}
     */
    const handleOnUpdateSupportOrg = (item) => {
        // 設定支援機構編號
        setSupportOrgNo(`${item.orgNo} ${item.orgName}`)
        // 關閉支援機構查詢彈窗
        setShowQuerySupportOrgModal(false)
    }

    /**
     * 清除資料
     * @return {void}
     */
    const cleanData = () => {
        // 清除支援醫師
        setSupportUserNo('')
        // 清除支援機構
        setSupportOrgNo('')
        // 清除支援科別
        setSupportDivNo('')
        // 清除支援科別文字方框
        setSupportDivNoSearch('')
        // 清除支援開始日期
        setItemStartDate('')
        // 清除支援開始時間
        setItemEndDate('')
        // 清除支援結束日期
        setItemStartTime('00:00')
        // 清除支援結束時間
        setItemEndTime('00:00')
        // 清除執行內容
        setExecuteContent('')
    }

    /**
     * 開新分頁至衛福部網站
     * @return {void}
     */
    const handleApplyResultOnConfirm = () => {
        // 關閉申請結果提示窗
        handleApplyResultOnClose()
        // 申請結果為失敗時，不開新分頁
        if (!applyResult.status) {
            return
        }
        // 申請成功則，開新分頁至衛福部網站
        openNewTab2MOHW()
    }

    /**
     * 關閉申請結果提示窗
     * @return {void}
     */
    const handleApplyResultOnClose = () => setApplyResult({show: false, status: false, msg: ''})

    /**
     * 開新分頁至衛福部網站
     * @return {WindowProxy|null}
     */
    const openNewTab2MOHW = () => window.open("https://ma.mohw.gov.tw/Default.aspx", '_blank')

    /**
     * 取得內容資料
     * @return {Object} 內容資料
     */
    const getContentData = () => {
        // 取得支援機構
        const org = organizationList.find(o => o.orgNo === supportOrgNo.split(' ')[0])

        return {
            // 單號
            supportNo: "",
            // 項目修改版號, 從1開始
            itemVersion: "1",
            // 支援醫師姓名
            supportUserName: supportUserNo.split(' ')[1],
            // 支援人員代號
            supportUserNo: supportUserNo.split(' ')[0],
            // 支援機構Pkey
            supportOrgPkey: org ? org.orgPkey : '',
            // 支援機構代號
            supportOrgNo: supportOrgNo ? supportOrgNo.split(' ')[0] : '',
            // 支援機構名稱
            supportOrgName: supportOrgNo ? supportOrgNo.split(' ')[1] : '',
            // 支援科別代號
            supportDivNo: supportDivNo ? supportDivNo.split(' ')[0] : '',
            // 支援科別名稱
            supportDivName: supportDivNo ? supportDivNo.split(' ')[1] : '',
            // 支援目的代碼
            purposeCode: "10", // 預設為「報備為一般支援」
            // 支援目的說明
            purposeCodeExt: "1",
            // 項目開始日期
            itemStartDate: itemStartDate ? `${itemStartDate} 00:00:00` : null,
            // 項目結束日期
            itemEndDate: itemEndDate ? `${itemEndDate} 00:00:00` : null,
            // 開始時間(24HHMM)
            itemStartTime: itemStartTime.split(':').join(''),
            // 結束時間(24HHMM)
            itemEndTime: itemEndTime.split(':').join(''),
            // 支援性質
            supportProperties: "",
            // 執行內容
            executeContent: executeContent,
            // 備註
            memo: "",
            // 代理人代號
            agentUserNo: "",
            // 狀態(1申請/2確認/3送審/9失效)
            stepStatus: "2",
            // 變更/註銷狀態(null空:無/M:變更/D:刪除)
            supportModifyStatus: "",
            // 變更/註銷日期
            supportModifyDatetime: "",
            // 變更/註銷原因代號
            supportModifyReasonCode: "",
            // 變更/註銷原因說明
            supportModifyReasonDoc: "",
            // 送審檔案名稱
            sendFileName: "",
            // 送審案號
            sendCaseNo: "",
            // 送審日期
            sendDatetime: "",
            // 申請方式(N:一般 / E:急件申請)
            applyType: "E",
            // 院區
            zone: getLocalStorage('campus'),
            // 有效否(0/1)
            effFlag: true,
            // 承辦部門代號
            ownerDeptNo: "",
            // 欄位變更說明,以中文標記欄位名稱
            changeFieldsDoc: "",
            // 確認人員
            confirmUserNo: "",
            // 確認時間
            confirmDatetime: ""
        }
    }

    /**
     * 新增報備支援
     * @return {void}
     */
    const addMaster = (data) => {
        rsisAddMaster([data]).then(res => {
            // 狀態
            const {err} = res
            // 新增成功
            if (err === ApiErrorStatusEnum.Success) {
                cleanData()
                setApplyResult({
                    show: true,
                    status: true,
                    msg: '急件申請已成功送出，請至衛福部網站查看。'
                })
            } else { // 新增失敗
                setApplyResult({
                    show: true,
                    status: false,
                    msg: '急件申請失敗，請重新操作。'
                })
            }
        })
    }

    /**
     * 取得所有科別
     * @return {void}
     */
    const getAllDivision = () => {
        rsisQueryBaseData({}).then(res => {
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                // 設定科別清單
                setDivisionList(data.supportDivList)
            } else {
                setDivisionList([])
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 取得支援機構所有資料
     * @return {void}
     */
    const getOrganization = () => {
        rsisQueryOrganization({}).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            // 刪除成功
            if (err === ApiErrorStatusEnum.Success) {
                setOrganizationList(data)
            } else { // 刪除失敗
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 取得佈告欄
     * @return {void}
     */
    const getHTMLContent = () => {
        rsisQueryFunctionDocHtml({funNo: activeTabId}).then(res => {
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                setHtmlContent(data.htmlContent)
            } else {
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 檢核支援醫師執豋資料，並自動帶入取得支援科別
     * @param doctorNo {String} 醫師代碼
     * @return {void}
     */
    const getDoctorData = (doctorNo) => {
        checkDoctorLicence({
            doctorNo: doctorNo,
            divNo: ''
        }).then(res => {
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                //檢核醫師是否有值登資料
                if (data) {
                    // 若有資料則自動帶入支援科別，若無資料則為空值
                    data.supportDivNo && data.divName ? setSupportDivNo(`${data.supportDivNo} ${data.divName}`) : setSupportDivNo('')
                } else {
                    // 若沒有資料則帶入空值
                    setSupportDivNo('')
                }
            } else {
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 取得預設支援科別
     * @return {Object|null}
     */
    const getDefaultDivNoValue = useMemo(() => {
            const data = divisionList?.find(option => `${supportDivNo}`?.includes(option?.supportDivNo || ''))
            return data && supportDivNo ? data : null
        }, [divisionList, supportDivNo]
    )

    /**
     * 第一次執行時
     * @return {void}
     */
    useMemo(() => {
        // 取得所有科別
        getAllDivision()
        // 取得佈告欄內容
        getHTMLContent()
        // 取得支援機構
        getOrganization()
        // 若尚未取當前院區代號，則取得代號並儲存在localStorage
        if (getLocalStorage('campusId') === null) {
            // 取得院區代號
            getCurrentZoneNo()
        }
    }, [])

    /**
     * 監聽支援開始時間
     * @return {void}
     */
    useEffect(() => {
        // 當支援開始日期和時間都有值時
        if (!stringIsEmpty(itemStartDate) && !stringIsEmpty(itemStartTime)) {
            //只有當支援開始時間為24:00時，支援開始日期才會+1
            if (itemStartTime === '24:00') {
                //取得新的支援開始日期
                const newItemStartDate = dayjs(itemStartDate).add(1, 'day').format('YYYY-MM-DD')
                // 設定新的支援開始日期
                setItemStartDate(newItemStartDate)
                // 設定支援開始時間為0000
                setItemStartTime('00:00')
            }
        }
    }, [itemStartDate, itemStartTime])

    /**
     * 監聽支援結束時間
     * @return {void}
     */
    useEffect(() => {
        // 當支援結束日期和時間都有值時
        if (!stringIsEmpty(itemEndDate) && !stringIsEmpty(itemEndTime)) {
            //只有當支援結束時間為24:00時，支援結束日期才會+1
            if (itemEndTime === '24:00') {
                //取得新的支援結束日期
                const newItemEndDate = dayjs(itemEndDate).add(1, 'day').format('YYYY-MM-DD')
                // 設定新的支援結束日期
                setItemEndDate(newItemEndDate)
                // 設定支援結束時間為0000
                setItemEndTime('00:00')
            }
        }
    }, [itemEndDate, itemEndTime])

    /**
     * 動態設定支援機構/工號方框寬度
     * @return {String}
     */
    const getSupportOrgNoTextFieldsWidth = useCallback(() => supportOrgNo ? `${(supportOrgNo.length + 1) * 0.875}rem` : SizeEnum.Small, [supportOrgNo])

    /**
     * 動態設定執行內容寬度
     * @return {String}
     */
    const getExecuteContentTextFieldsWidth = useCallback(() => {
        // 中文正規表示法
        const chineseRegex = /[\u4e00-\u9fa5]/g
        // 取得符合中文正規表示法的字串
        const matchesChinese = executeContent?.match(chineseRegex)
        // 取得中文字串長度
        const chineseLength = matchesChinese ? matchesChinese.length : 0
        // 中文字串長度+1 * 1.035
        const chineseWidth = (chineseLength + 1) * 1.035
        // 其他字符（英文字母和數字）的長度
        const otherLength = executeContent ? executeContent.length - chineseLength : 0
        // 其他字符（英文和數字）長度 * 0.575
        const otherWidth = otherLength * 0.575
        // 計算總寬度
        const totalWidth = chineseWidth + otherWidth
        // 當總寬度*14 >= 224時，寬度為總寬度+0.875rem，否則為Small
        return executeContent && totalWidth * 14 >= 350 ? `${totalWidth + 0.875}rem` : SizeEnum.Small
    }, [executeContent])

    return (
        <Layout>
            <div className='w-full flex flex-col gap-4 px-4'>
                {/* 第一列 */}
                <div className='flex flex-row justify-end items-center mt-2 gap-2 text-left'>
                    <Select
                        data={{
                            label: "院區",
                            options: enum2Array(CampusEnum).map((item) => ({
                                value: item.value,
                                label: t(`campus.fullName.${item.value}`)
                            }))
                        }}
                        value={zone} disabled={true} width={SelectWidthEnum.Small} displayEmpty={true} notched={true}/>
                    <div className='text-[#38A169]'>申請狀態</div>
                </div>
                {/* 第二列 */}
                <div className='flex flex-row justify-end items-center gap-2'>
                    {/*存檔按鈕*/}
                    <Button text={t('general.saveFile')}
                            variant={ButtonVariantEnum.Contained}
                            size={ButtonSizeEnum.Medium}
                            color={ButtonColorEnum.Primary}
                            onClick={handleSaveButtonOnClick}/>
                </div>
                {/* 報備支援內容 */}
                <Card title={"報備支援內容"}>
                    {/* 內容 */}
                    <div className='flex flex-col gap-4'>
                        {/*支援醫師代碼*/}
                        <Field label={`${t('RSIS.general.urgentSupportDoc')}/${t('RSIS.general.jobNum')}`}>
                            <TextField
                                size={SizeEnum.Small}
                                value={supportUserNo}
                                onClick={handleQueryDoctorOnClick}/>
                        </Field>
                        {/*機構代碼/名稱*/}
                        <Field label={`機構代碼/${t('RSIS.general.name')}`}>
                            <TextField sx={{
                                width: getSupportOrgNoTextFieldsWidth,
                                transition: 'width 0.2s ease-out',
                            }} size={SizeEnum.Small} value={supportOrgNo}
                                       onClick={handleQuerySupportOrg}/>
                        </Field>
                        {/*支援科別*/}
                        <div className='flex flex-row w-full'>
                            <Field label={"支援科別"}>
                                <Search inputValue={supportDivNoSearch} inputSize={SizeEnum.Small} freeSolo
                                        value={getDefaultDivNoValue} options={divisionList}
                                        getOptionLabel={option => `${option.supportDivNo} ${option.supportDivName}`}
                                        filterOptions={handleSupportDivOnFilterOptions}
                                        onInputChange={handleSupportDivSearchOnInputChange}
                                        onChange={handleSupportDivOnChange}/>
                            </Field>
                        </div>
                        {/*支援日期*/}
                        <Field label={"支援日期"}>
                            <div className=' flex flex-row gap-2 items-center'>
                                <DatePicker label="開始日期" disablePast
                                            value={itemStartDate ? dayjs(itemStartDate) : null}
                                            onChange={handleItemStartDateOnChange}/>
                                <Select
                                    data={{
                                        label: '開始時間',
                                        options: getTimeIntervals().map((item) => ({value: item, label: item}))
                                    }} value={itemStartTime} width={SelectWidthEnum.Small} notched={true}
                                    displayEmpty={true}
                                    onChange={handleItemStartTime}/>
                                <p>～</p>
                                <DatePicker
                                    label="結束日期"
                                    disablePast
                                    value={itemEndDate ? dayjs(itemEndDate) : null}
                                    onChange={handleItemEndDateOnChange}/>
                                <Select
                                    data={{
                                        label: '結束時間',
                                        options: getTimeIntervals().map((item) => ({value: item, label: item}))
                                    }} value={itemEndTime} width={SelectWidthEnum.Small} notched={true}
                                    displayEmpty={true}
                                    onChange={handleItemEndTime}/>
                            </div>
                        </Field>

                        <Field label='執行內容'>
                            <TextField sx={{
                                width: getExecuteContentTextFieldsWidth,
                                transition: 'width 0.2s ease-out',
                            }} inputWidth={SizeEnum.XLarge} size={SizeEnum.Small} value={executeContent}
                                       onChange={handleExecuteContentOnChange}/>
                        </Field>
                    </div>
                    {/* 分隔線 */}
                    <Divider sx={{my: 2}}/>
                    <p className='flex justify-start text-sm text-[#71717A] h-9 pl-2'>說明與注意事項</p>
                    <div className='flex flex-col'>
                        {/* 佈告欄 */}
                        <div
                            className='flex w-[350px] h-[150px] m-4 bg-[#D9D9D9] text-black justify-center items-center overflow-y-auto'
                            dangerouslySetInnerHTML={{__html: sanitizedData(htmlContent)}}
                        ></div>
                    </div>
                </Card>
            </div>

            {/* 支援醫師查詢彈窗 */}
            {
                showQueryPersonList && (
                    <QueryPersonList
                        filterEmp={true} doctorList={doctorList}
                        handleOnClose={() => setShowQueryPersonList(false)}
                        handleOnUpdate={handleOnUpdateDoctor}/>
                )
            }

            {/* 支援機構查詢 */}
            {
                showQuerySupportOrgModal && (
                    <QuerySupportOrg
                        filterOrg={true}
                        handleOnClose={() => setShowQuerySupportOrgModal(false)}
                        handleOnUpdate={handleOnUpdateSupportOrg}/>
                )
            }

            {/* 申請提示視窗 */}
            <Dialog open={applyResult.show} title={'申請結果'} variant={DialogVariant.CONFIRM}
                    paperStyleProps={{width: DialogSizeEnums.SM}}
                    content={
                        <div className='flex flex-col gap-4 justify-center items-center mb-4'>
                            <p>{applyResult.msg}</p>
                        </div>
                    }
                    muiDialogContentProps={{
                        dividers: true,
                        sx: {height: '110px', display: 'flex', justifyContent: "flex-start", alignItems: "center"}
                    }}
                    onClose={handleApplyResultOnClose}
                    onConfirm={handleApplyResultOnConfirm}/>
        </Layout>
    )
}

export default UrgentApplication
