import React, {useEffect, useState} from "react";
import {t} from "i18next";
import {ApiErrorStatusEnum, arrayIsEmpty, objectIsEmpty, stringIsEmpty, time2String,} from "edah_utils/dist";
import {
    Button,
    ButtonSizeEnum,
    ButtonVariantEnum,
    ColorEnum,
    Dialog, DialogSizeEnums,
    DialogVariant,
    Select,
    AdvancedDataGrid,
    TextField,
    Card, Divider
} from "edah-component/dist";
import {AlertTypeEnum, Checkbox, Tabs, TabsEnum} from "edah-component/dist/index";
import {cpoeQueryDittoOrders} from "../../../api/v1/Cpoe";
import useToast from "../../../hooks/useToast";
import medicalInformation from "../../Appointment/MedicalInformation";
import {DATE_FORMAT} from "../../../constants/common";

/**
 *  CPOE開單彈出視窗
 *  @param closePopupButtonOnClick {Function} 關閉按鈕點擊事件
 *  @param onConfirm {Function} 確認按鈕點擊事件
 *  @param show {Boolean} 是否顯示彈窗
 *  @param patientInfo {Object} 患者資訊
 *  @return {JSX.Element} CPOE開單彈出視窗
 */
export const CpoePopup = ({
                               closePopupButtonOnClick,
                               show = false,
                               patientInfo,
                               onConfirm,
                           }) => {
    // 切換用藥記錄
    const [activeTab, setActiveTab] = useState('medRecord');
    // 切換 門/急/住
    const [activeEncounterType, setActiveEncounterType] = useState('OP');
    // 搜尋條件-科別
    const [divNo, setDivNo] = useState('62');
    // 科別清單
    const [divList, setDivList] = useState([]);
    // 搜尋條件-醫師
    const [doctorNo, setDoctorNo] = useState('2345');
    // 醫師清單
    const [doctorList, setDoctorList] = useState([]);
    // toast
    const showToast = useToast()
    // 就醫資料
    const [medicalInformation, setMedicalInformation] = useState([])
    // 診斷記錄
    const [diagnosticRecords, setDiagnosticRecords] = useState([])
    // 醫囑清單
    const [dittoOrders, setDittoOrders] = useState([])
    // 是否勾選處方全選
    const [isAllOrder, setIsAllOrder] = useState(false)
    // 是否勾選"S"
    const [isSOrder, setIsSOrder] = useState(false)
    // 是否勾選"O"
    const [isOOrder, setIsOOrder] = useState(false)
    // 是否勾選ICD10
    const [isIcd10, setIsIcd10] = useState(false)
    // 是否勾選藥品
    const [isMed, setIsMed] = useState(false)
    // 是否勾選處置
    const [isDisPosal, setIsDisPosal] = useState(false)
    // 選中某一筆就醫資訊
    const [selectedEncounterData, setSelectedEncounterData] = useState(null)
    // 選中的ditto
    const [selectedDittoOrders, setSelectedDittoOrders] = useState([])
    // columns設置
    const orderColumns = [
        { field: 'orderName', headerName:'醫囑明細', width:300},
        { field: 'qty', headerName:'次量', width:100},
        { field: 'qtyUnit', headerName: '單位', width:100},
        { field: 'freqNo', headerName: '頻率', width:100},
        { field: 'bhpDivNo', headerName: '服用方法', width:100},
        { field: 'methodNo', headerName: '途徑', width:100},
        { field: 'day', headerName: '天數', width:100},
        { field: 'tqty', headerName: '總量', width:100 },
        { field: 'tqtyUnit', headerName: '單位', width:100 },
        { field: 'paidType', headerName: '自費', width:100 }
    ]



    /**
     * 取得門診急診住院 tab
     */
    const getEncounterTypeTab = ()=>{
        return [
            {id: 'OP',title: '門診'},{id: 'ED',title: '急診'},{id: 'IP',title: '住院'}
        ]
    }

    /**
     * 取得Tab內容
     * @return {Object[]}
     */
    const getTabContent = () => {
        return [
            {
                id: 'medRecord',
                title: '用藥記錄',
                content: (
                    <div className="min-h-[400px] flex flex-row justify-start items-start gap-4">
                        <div className="w-[250px]">
                            {/*搜尋條件-門急住切換*/}
                            <Tabs
                                variant={TabsEnum.WORKSPACE_TAB}
                                activeTabId={activeEncounterType}
                                items={getEncounterTypeTab()}
                                onTabChange={(tab)=>setActiveEncounterType(tab)}
                            />
                            {/*患者信息*/}
                            <div className="gap-2">
                                <span className="mr-2">{patientInfo.patientName}</span>
                                <span className="mr-2">{patientInfo.genderName}</span>
                                <span>{time2String(patientInfo.birthDate, "YYYY-MM-DD")}</span>
                            </div>
                            {/*搜尋條件-科別，醫師下拉*/}
                            <div className="flex flex-row gap-2">
                                <Select
                                    data={{
                                        label: "科別",
                                        options:
                                            !arrayIsEmpty(divList) ? divList?.map((item) => ({
                                                label: item.justnameName,
                                                value: item.justnameNo,
                                            })) : [],
                                    }}
                                    value={divNo}
                                    onChange={(value)=>setDivNo(value)}
                                    notched={true}
                                    displayEmpty = {true}
                                />
                                <Select
                                    data={{
                                        label: "醫師",
                                        options:
                                            !arrayIsEmpty(doctorList) ? doctorList?.map((item) => ({
                                                label: item.justnameName,
                                                value: item.justnameNo,
                                            })) : [],
                                    }}
                                    value={doctorNo}
                                    onChange={(value)=>setDoctorNo(value)}
                                    notched={true}
                                    displayEmpty = {true}
                                />
                            </div>
                            {/*就醫資訊*/}
                            <ul className="w-[100%]">
                                <li className="font-sans text-left text-[18px] text-[#000000]">就醫資訊</li>
                                {
                                    !arrayIsEmpty(medicalInformation) && medicalInformation.map((item)=> (
                                        <>
                                            <li className="cursor-pointer"
                                                style={{background: (selectedEncounterData?.encounterId === item.encounterId)?
                                                        'rgba(49, 130, 206, 0.1)':'#FFFFFF'}}
                                                onClick={()=>handleSelectEncounterData(item)}>
                                                <div className="gap-2">
                                                    <span className="inline-block mr-2">{time2String(item?.encounterDate, DATE_FORMAT)}</span>
                                                    <span className="inline-block mr-2">{item?.divName || ''}</span>
                                                    <span className="inline-block mr-2">{item?.doctorName || ''}</span>
                                                </div>
                                                {/*該次就醫咨詢詳情===内容待定，再調整*/}
                                                <div className="text-[#71717A] text-[14px]">
                                                    {item?.encIcdCmParamList[0]?.icdCmCode}S56.011A Strain of flexor muscle,
                                                    fascial and tendo...
                                                </div>
                                            </li>
                                            <Divider/>
                                        </>
                                    ))
                                }
                            </ul>
                        </div>
                        <div className="flex-1">
                            {/*checkbox選擇類別勾選醫囑*/}
                            <div className="flex flex-row gap-2">
                                <Checkbox label="處方全選" checked={isAllOrder} />
                                <Checkbox label="S" checked={isSOrder} />
                                <Checkbox label="O" checked={isOOrder} />
                                <Checkbox label="ICD10" checked={isIcd10} />
                                <Checkbox label="藥品" checked={isMed} />
                                <Checkbox label="處置" checked={isDisPosal} />
                            </div>
                            {/*SOAP部分*/}
                            <div className="flex flex-row gap-2 mb-4 h-[168px] text-[#3F3F46]">
                                {
                                    objectIsEmpty(selectedEncounterData) &&
                                    (<span className="text-[#71717A]">
                                        未選擇一筆紀錄
                                        </span>
                                    )
                                }
                                {
                                    !objectIsEmpty(selectedEncounterData) && (
                                        // 待改
                                        <span>
                                        *S*
                                        hyperlipidemia was noted.
                                        </span>
                                    )
                                }
                            </div>
                            {/*可勾選醫囑清單*/}
                            <div className="border-collapse w-full overflow-x-auto overflow-y-auto min-h-[100px] " >
                                <AdvancedDataGrid
                                    rows={dittoOrders}
                                    columns={orderColumns}
                                    checkboxSelection={true}
                                    disableRowSelectionOnClick={false}
                                    disableColumnMenu={true}
                                    disableColumnSorting={true}
                                    height={'400px'}
                                    getRowHeight={(params) => 40}
                                    getRowId={(row) => row.orderId}
                                    onRowSelectionModelChange={handleSelectionModelChange}
                                    rowSelectionModel={selectedDittoOrders}
                                />
                            </div>
                        </div>
                    </div>
                )
            },
            {
                id: 'commonUse',
                title: '個人常用',
                content: (
                    <div className="h-[400px] flex flex-row justify-start items-start gap-2">

                    </div>
                )
            },
            {
                id: 'division',
                title: '分科',
                content: (
                    <div className="h-[400px] flex flex-row justify-start items-start gap-2">
                    </div>
                )
            },
            {
                id: 'hospital',
                title: '全院',
                content: (
                    <div className="h-[400px] flex flex-row justify-start items-start gap-2">

                    </div>
                )
            }
        ]
    }

    /**
     * 取得dialog內容
     * @return {JSX.Element}
     */
    const getContent = () => (
        <>
            <div className="filter flex flex-row items-center justify-start space-x-2">
                <Tabs
                    variant={TabsEnum.INDICATOR_TAB}
                    activeTabId={activeTab}
                    items={getTabContent()}
                    onTabChange={(tab)=>setActiveTab(tab)}
                />
            </div>

        </>
    );

    /**
     * 選擇該筆就醫資訊
     * @param item {Object}
     * @return {void}
     */
    const handleSelectEncounterData = (item)=>{
        setSelectedEncounterData(item)
        setDittoOrders(item?.encounterOrderDittoParamList)
    }

    const handleSelectionModelChange = (newSelectionModel)=>{
        setSelectedDittoOrders(newSelectionModel)
    }

    /**
     * 點擊確認按鈕，新增order到cpoe主畫面
     */
    const handleConfirmPopup = ()=>{
        // 待調整
        let tempAddData = dittoOrders.filter(item => item.orderId === selectedDittoOrders)
        onConfirm([...tempAddData])
        // 保存后清空畫面資料
        setSelectedEncounterData(null)
        // setSelectedDittoOrders(null)
        setDittoOrders(null)
    }

    /**
     * 查詢Ditto的就診歷史清單
     * 測試數據：encounterType=OP&divNo=ER&doctorNo=D005&patientId=128506&startDate=2023-01-01 00:00:00&endDate=2024-09-20 23:59:59
     * startDate/endDate 可不傳
     * @return {void}
     */
    const queryDittoOrders = ()=>{
        cpoeQueryDittoOrders({
            encounterType: activeEncounterType,
            divNo: divNo,
            doctorNo: doctorNo,
            patientId: patientInfo.patientId,
            // startDate: '2023-01-01 00:00:00',
            // endDate: '2024-09-20 23:59:59',
        }).then(res =>{
            if (res.err === ApiErrorStatusEnum.Success) {
                setMedicalInformation(res.data)
            } else {
                showToast({message: res.msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 監聽arrayData & advancedSearchText變化
     */
    useEffect(() => {
        queryDittoOrders()
    }, []);

    return (
        <Dialog
            open={show}
            variant={DialogVariant.CONFIRM}
            content={getContent()}
            paperStyleProps={{width: DialogSizeEnums.XL}}
            title={"開單"}
            onClose={closePopupButtonOnClick}
            onConfirm={handleConfirmPopup}
            muiDialogContentProps={{sx: {overflowY: 'hidden'}}}
        />
    )
};
