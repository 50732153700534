import {useRef, useState} from "react"
import {login, queryUserNameByUserNo} from "../../api/v1/Users";
import {useNavigate} from "react-router-dom";
import {t} from "i18next"

/**
 * Images&css
 */
import edahLogo from "../../assets/images/login_edah-logo.svg";
import loginImageBlue from "../../assets/images/login_image-blue.svg";
import loginImagePurple from "../../assets/images/login_image-purple.svg";
import loginImageGreen from "../../assets/images/login_image-green.svg";
import loginImageRed from "../../assets/images/login_image-red.svg";
import '../../assets/css/login.scss'
import {CampusEnum, setLocalStorage} from "edah_utils/dist"

const LoginPage = () => {
    const navigate = useNavigate()
    // 院區列舉值陣列
    const campusEnumArray = Object.values(CampusEnum)
    // 院區視覺設定
    const campusOptionStyle = [
        {
            inputClassName: 'appearance-none ring-2 w-2 h-2 ring-gray-300 rounded-full ring-offset-[4px]  checked:bg-loginBtn-blue checked:ring-loginBtn-blue checked cursor-pointer',
            textClassName: 'text-loginBtn-blue',
            backgroundColor: 'bg-loginBg-blue',
            leftImage: loginImageBlue,
            buttonColor: 'bg-loginBtn-blue',
            borderColor: 'focus:outline-loginBtn-blue'
        },
        {
            inputClassName: 'appearance-none ring-2 w-2 h-2 ring-gray-300 checked:ring-loginBtn-purple checked:bg-loginBtn-purple  rounded-full ring-offset-[4px]  cursor-pointer',
            textClassName: 'text-loginBtn-purple',
            backgroundColor: 'bg-loginBg-purple',
            leftImage: loginImagePurple,
            buttonColor: 'bg-loginBtn-purple',
            borderColor: 'focus:outline-loginBtn-purple'
        },
        {
            inputClassName: 'appearance-none ring-2 w-2 h-2 ring-gray-300 rounded-full ring-offset-[4px] checked:bg-loginBtn-green checked:ring-loginBtn-green checked cursor-pointer',
            textClassName: 'text-loginBtn-green',
            backgroundColor: 'bg-loginBg-green',
            leftImage: loginImageGreen,
            buttonColor: 'bg-loginBtn-green',
            borderColor: 'focus:outline-loginBtn-green'
        },
        {
            inputClassName: 'appearance-none ring-2 w-2 h-2 ring-gray-300 rounded-full ring-offset-[4px]  checked:bg-loginBtn-red checked:ring-loginBtn-red  cursor-pointer',
            textClassName: 'text-loginBtn-red',
            backgroundColor: 'bg-loginBg-red',
            leftImage: loginImageRed,
            buttonColor: 'bg-loginBtn-red',
            borderColor: 'focus:outline-loginBtn-red'
        }
    ]
    //帳號輸入框
    const inputAccount = useRef(null)
    //密碼輸入框
    const inputPassword = useRef(null)
    //當前院區的索引
    const [currentCampusIndex, setCurrentCampusIndex] = useState(0)
    //姓名
    const [username, setUsername] = useState("")
    //密碼
    const [password, setPassword] = useState("")
    //密碼明碼切換
    const [showPassword, setShowPassword] = useState(false)
    //帳號
    const [account, setAccount] = useState("")
    //錯誤訊息
    const [errMessage, setErrMessage] = useState(null)

    /**
     * 密碼輸入方框按下Tab時
     * @param event
     * @return void
     */
    const handlePasswordOnKeyDown = (event) => {
        //按下Tab鍵
        if (event.key === 'Tab') {
            //停止預設行為
            event.preventDefault()
            //將焦點轉移到密碼輸入框
            inputAccount.current.focus()
        }
    }

    /**
     * 帳號輸入方框按下Tab時
     * @param event
     * @return void
     */
    const handleAccountOnKeyDown = (event) => {
        //按下Tab鍵
        if (event.key === 'Tab') {
            //停止預設行為
            event.preventDefault()
            //將焦點轉移到密碼輸入框
            inputPassword.current.focus()
        }
    }

    /**
     * 帳號輸入方框離開焦點時
     */
    const handleAccountOnBlur = async (event) => {
        //帳號有輸入文字
        if (account) {
            //取得使用者名稱
            queryUserNameByUserNo({
                userNo: account
            }).then(result => {
                //沒有錯誤
                if (result.err === 0) {
                    setUsername(result.data.userName)
                }
            })
        }
    }

    /**
     * 密碼輸入時
     * @param event
     */
    const handlePasswordOnChange = (event) => {
        if (event.target.value) {
            setPassword(event.target.value)
        } else {
            setPassword("")
        }
    }

    /**
     * 帳號輸入時
     * @param event
     */
    const handleAccountOnChange = (event) => {
        if (event.target.value) {
            setAccount(event.target.value)
        } else {
            setAccount("")
        }
    }

    /**
     * 分院點擊時
     * @param index
     * @return void
     */
    const handleCampusOnClick = (index) => setCurrentCampusIndex(index)

    /**
     * 點擊忘記密碼時
     */
    const handleForgotPassword = () => {
    }

    /**
     * 表單送出時
     * @param event
     * @return void
     */
    const handleSubmit = async (event) => {
        event.preventDefault();

        login({
            userNo: account,
            password: password
        }).then(result => {
            //登入成功
            if (result.err === 0) {
                //寫入當前的院區
                setLocalStorage('campus', campusEnumArray[currentCampusIndex])
                //轉跳至/URL
                navigate('/')
            } else if (result.err === 1) { //登入失敗
                // 將原因記錄下來並顯示
                setErrMessage(result.msg)
            }
        })
    }

    return (
        <div className=" bg-red-600/30 backdrop-brightness-75 w-full h-full">
            <div
                className={`login w-full h-screen bg-loginPage bg-no-repeat bg-cover ${campusOptionStyle[currentCampusIndex].backgroundColor} bg-blend-overlay`}>
                <div className="bg-white w-full h-full flex flex-col sm:flex-row justify-between rounded-xl ">
                    <div className={`w-full h-full`}>
                        <img src={campusOptionStyle[currentCampusIndex].leftImage}
                             className="w-full h-full bg-cover object-cover rounded-l-xl" alt="login"/>
                    </div>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <div className="flex flex-row space-x-8 mb-[30px]">
                            <img src={edahLogo} className="w-72 h-18" alt="edah-logo"/>
                            <div className="w-1 h-full bg-gray-100"></div>
                            <h1 className="text-2xl flex items-center tracking-wide font-semibold text-[#3F3F46]">
                                {t('page.Login.HIS_SystemLogin')}
                            </h1>
                        </div>
                        <div className="flex flex-col w-full text-left">
                            <div className="flex flex-col space-y-8">
                                {/*選擇院別*/}
                                <div className="flex flex-col space-y-4">
                                    <p className="text-lg text-[#3F3F46]">{t('general.campus')}</p>
                                    <div className="flex flex-row justify-between text-lg">
                                        {
                                            // 輸出不同院區
                                            campusEnumArray.map((campus, index) => (
                                                <div className="flex flex-row space-x-4 items-center cursor-pointer"
                                                     onClick={() => handleCampusOnClick(index)} key={index}>
                                                    {/*院區選項*/}
                                                    <input className={campusOptionStyle[index].inputClassName}
                                                           type="checkbox" checked={currentCampusIndex === index}
                                                           onChange={() => {
                                                           }}/>
                                                    {/*院區文字*/}
                                                    <label
                                                        className={`${currentCampusIndex === index ? campusOptionStyle[index].textClassName : ''} cursor-pointer`}>
                                                        {t(`campus.${campus}`)}
                                                    </label>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>

                                <div className="flex flex-row justify-between space-x-12 w-full">
                                    {/*帳號*/}
                                    <div className="flex flex-col space-y-2 text-left w-full">
                                        <label className="text-xl px-1 text-[#3F3F46]">{t('general.account')}</label>
                                        <input
                                            ref={inputAccount}
                                            className={`border-2 py-2 w-full text-xl placeholder:text-black px-2 rounded-md focus:bg-[#F4F4F5] ${campusOptionStyle[currentCampusIndex].borderColor} text-[#3F3F46]`}
                                            type="text" value={account}
                                            onChange={(event) => handleAccountOnChange(event)}
                                            onBlur={(event) => handleAccountOnBlur(event)}
                                            onKeyDown={(event) => handleAccountOnKeyDown(event)}
                                            required/>
                                    </div>
                                    {/*姓名*/}
                                    <div className="flex flex-col space-y-2 text-left w-full">
                                        <label className="text-xl px-1 text-[#3F3F46]">{t('general.username')}</label>
                                        <input
                                            className={`border-2 py-2 w-full text-xl placeholder:text-black px-2 rounded-md focus:bg-[#F4F4F5] ${campusOptionStyle[currentCampusIndex].borderColor} text-[#3F3F46]`}
                                            value={username}
                                            type="text" disabled required/>
                                    </div>
                                </div>

                                {/*科別*/}
                                <div className="flex flex-row justify-between space-x-12 w-full">
                                    <div className="flex flex-col space-y-2 text-left w-full">
                                        <label className="text-xl px-1">科別</label>
                                        <input
                                            type="text"
                                            className="border-2 py-2 w-full text-xl placeholder:text-black px-2"
                                            placeholder="門診內科"
                                        />
                                    </div>

                                    <div className="flex flex-col space-y-2 text-left w-full">
                                        <label className="text-xl px-1">庫別</label>
                                        <input
                                            type="text"
                                            className="border-2 py-2 w-full text-xl placeholder:text-black px-2"
                                            placeholder="資訊部測試庫"
                                        />
                                    </div>
                                </div>

                                {/*密碼區塊*/}
                                <div className="flex flex-col space-y-2 text-left w-full ">
                                    {/*忘記密碼*/}
                                    <div className="flex flex-row justify-between">
                                        <label className="text-xl px-1 text-[#3F3F46]">{t('general.password')}</label>
                                        <button
                                            className="flex justify-end text-xl px-1 text-blue-800 hover:text-blue-700 rounded-md"
                                            type="button" onClick={handleForgotPassword}>
                                            {t('general.forgetPassword')}
                                        </button>
                                    </div>
                                    {/*密碼*/}
                                    <div className="relative w-full">
                                        <input
                                            ref={inputPassword}
                                            type={showPassword ? "text" : "password"} // 切換明碼或密碼
                                            className={`py-2 w-full text-xl placeholder:text-black px-2 pr-10 rounded-md border-2 focus:bg-[#F4F4F5] ${campusOptionStyle[currentCampusIndex].borderColor} text-[#3F3F46]`}
                                            value={password}
                                            onChange={(event) => handlePasswordOnChange(event)}
                                            onKeyDown={(event) => handlePasswordOnKeyDown(event)}
                                            required
                                        />
                                        <button
                                            type="button"
                                            className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-600 "
                                            onClick={() => setShowPassword(!showPassword)} // 切換狀態
                                        >
                                            {showPassword ? '隱藏' : '顯示'}
                                        </button>
                                    </div>
                                </div>

                                {/*登入按鈕*/}
                                <div className="flex flex-col space-y-2">
                                    <button
                                        className={`text-lg text-center ${campusOptionStyle[currentCampusIndex].buttonColor} text-white py-4 font-semibold rounded-md`}
                                        type="submit">
                                        {t('general.login')}
                                    </button>
                                    {errMessage !== null && (
                                        <p className="text-red text-xl text-center text-red-500 mt-0 ">
                                            {errMessage}
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;

