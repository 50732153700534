//Import css
import '../../../components/TableList/tableList.scss'
import '../../../components/ICCard/maintain.scss'

//Import icons
import {ReactComponent as Delete} from '../../../assets/images/ICCard/delete.svg'

//Import function
import React, {useState} from "react"
import {t} from "i18next"
import {BaseInput} from "../../../components/Input/BaseInput"
import {
    ApiErrorStatusEnum,
    arrayIsEmpty,
    fuzzySearchObjects,
    InputTypeEnum, stringIsEmpty,
    time2String,
} from "edah_utils/dist";
import {iccmCriticalillnessQueryData} from "../../../api/v1/ICCM"
import useToast from "../../../hooks/useToast"
import {
    DateRangePicker,
    TextField,
    Button,
    ButtonSizeEnum,
    ButtonVariantEnum,
    ButtonColorEnum,
    IconEnum,
    AlertTypeEnum
} from "edah-component/dist"
import dayjs from "dayjs"

/**
 * 重大傷病資料維護
 * @return {JSX.Element}
 */
const MainInjury = () => {
    /**
     * Show Toast
     */
    const showToast = useToast()

    //開始日期
    const [startDate, setStartDate] = useState("")
    //結束日期
    const [endDate, setEndDate] = useState("")
    //病歷號
    const [patientId, setPatientId] = useState("")
    //資料
    const [data, setData] = useState([])
    //進階搜尋
    const [advancedSearch, setAdvancedSearch] = useState("")

    /**
     * 日期範圍改變時
     * @param dateRange {Array} [startDate, endDate]
     * @return {void}
     */
    const handleDateRangeChange = (dateRange) => {
        const [start, end] = dateRange
        setStartDate(start ? start.format('YYYY-MM-DD') : null)
        setEndDate(end ? end.format('YYYY-MM-DD') : null)
    }

    /**
     * 病歷號
     * @param e {Event} 事件
     * @return {void}
     */
    const handlePatientIdOnChange = (e) => setPatientId(e.target.value)

    /**
     * 進階搜尋
     * @param e {Event} 事件
     * @return {void}
     */
    const handleAdvancedSearch = (e) => setAdvancedSearch(e.target.value)

    /**
     * 查詢按鈕點擊
     * @return {void}
     */
    const handleQueryOnClick = () => getCriticalillnessQueryData()

    /**
     * 取得過濾資料
     * @return {Array<Object>}
     */
    const getFilterData = () => fuzzySearchObjects(data, advancedSearch)

    /**
     * 取得進階搜尋是否禁用
     * @return {boolean}
     */
    const getAdvancedSearchIsDisable = () => arrayIsEmpty(data)

    /**
     * 取得重大傷病讀取
     * @return {void}
     */
    const getCriticalillnessQueryData = () => {
        // 檢查日期是否有效
        if (!dayjs(startDate).isValid() || !dayjs(endDate).isValid()) {
            showToast({message: '就醫日期為必填', type: AlertTypeEnum.Error})
            return
        }
        // 檢查病歷號是否有填寫
        if (stringIsEmpty(patientId)) {
            showToast({message: '病歷號為必填', type: AlertTypeEnum.Error})
            return
        }
        iccmCriticalillnessQueryData({
            //病歷號
            patientId: patientId,
            //開始日期
            startDate: `${startDate} 00:00:00`,
            //結束日期
            endDate: `${endDate} 23:59:59`
        }).then(res => {
            const {err, msg, data} = res
            //成功
            if (err === ApiErrorStatusEnum.Success) {
                //設定資料
                setData(data)
            } else { //錯誤訊息
                setData([])
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    return (
        <div className="w-full p-4 bg-[#FAFAFA]">
            <div className="filterGroup flex flex-row flex-wrap items-center justify-start space-x-2 my-2">
                {/* 日期起訖 */}
                <DateRangePicker
                    value={[startDate ? dayjs(startDate) : null, endDate ? dayjs(endDate) : null]}
                    required
                    onChange={handleDateRangeChange}
                />
                {/*病歷號*/}
                <TextField
                    label={t('general.patientNo')}
                    value={patientId}
                    onChange={handlePatientIdOnChange} required/>
                {/*查詢按鈕*/}
                <Button color={ButtonColorEnum.Primary} variant={ButtonVariantEnum.Contained}
                        size={ButtonSizeEnum.Medium}
                        text={t('general.query')}
                        onClick={handleQueryOnClick}/>
            </div>
            <div className="add&save w-full flex flex-row items-center justify-between mb-4">
                {/*新增內容按鈕*/}
                <Button color={ButtonColorEnum.Success} variant={ButtonVariantEnum.Text} size={ButtonSizeEnum.Large}
                        text={t('general.add')} icon={IconEnum.Add}/>
                <Button color={ButtonColorEnum.Primary} variant={ButtonVariantEnum.Text} size={ButtonSizeEnum.Large}
                        text={t('general.saveFile')} icon={IconEnum.Save}/>
            </div>
            <div className="py-6 px-4 bg-white border-[1px] border-[#D4D4D8] rounded-[12px]">
                <div className="flex flex-row items-center justify-start mb-4">
                    {/*進階搜尋*/}
                    <BaseInput className="w-[320px] h-10 pl-4 ml-2 border-[2px] border-[#D4D4D8] rounded-[6px]"
                               type={InputTypeEnum.Text} inputMode={InputTypeEnum.Search}
                               disable={getAdvancedSearchIsDisable()} placeholder={t('general.advancedSearch')}
                               value={advancedSearch}
                               onChange={handleAdvancedSearch}/>
                </div>
                <ul className="tableList icMainInjuryList">
                    <li className="title">
                        <div>{t('general.patientNo')}</div>
                        <div>{t('general.username')}</div>
                        <div>類別</div>
                        <div>重大傷病碼</div>
                        <div>名稱</div>
                        <div>開始日期</div>
                        <div>結束日期</div>
                        <div>重大傷病卡</div>
                        <div>是否拷貝</div>
                        <div>輸入日期</div>
                        <div>輸入人員</div>
                        <div>輸入人員名稱</div>
                        <div>操作</div>
                    </li>
                    {
                        !arrayIsEmpty(data) && getFilterData((item, index) =>
                            <li key={index}>
                                {/*病歷號*/}
                                <div>{item.patientId}</div>
                                {/*姓名*/}
                                <div></div>
                                {/*類別*/}
                                <div>{item.icdType}</div>
                                {/*重大傷病碼*/}
                                <div>{item.illCode}</div>
                                {/*名稱*/}
                                <div></div>
                                {/*開始日期*/}
                                <div>{time2String(item.startDate, 'YYYY-MM-DD')}</div>
                                {/*結束日期*/}
                                <div>{time2String(item.endDate, 'YYYY-MM-DD')}</div>
                                {/*重大傷病卡*/}
                                <div>{item.illCardNo}</div>
                                {/*是否拷貝*/}
                                <div>
                                    <input type="checkbox" checked/>
                                </div>
                                {/*輸入日期*/}
                                <div></div>
                                {/*輸入人員*/}
                                <div></div>
                                {/*輸入人員名稱*/}
                                <div></div>
                                {/*操作*/}
                                <div>
                                    <button className="flex flex-row items-center justify-start text-[#2B6CB0]">
                                        <Delete/>
                                        {t('general.delete')}
                                    </button>
                                </div>
                            </li>
                        )
                    }
                </ul>
            </div>
        </div>
    );
};

export default MainInjury;
