import React from 'react'
import {Dialog, DialogVariant, DialogSizeEnums} from "edah-component/dist/index";

/**
 * 刪除提醒彈窗
 * @param {function} handleDeletePopupClose - 控制彈窗關閉
 * @param {function} handleNotificationDelete - 刪除提醒
 * @param {string} currentKey - 當前提醒的key
 * @return {JSX.Element}
 */
function DeletePopup({handleDeletePopupClose, handleNotificationDelete, currentKey}) {
    return (
        <Dialog
            open={true}
            title={"提醒"}
            content={"是否確定刪除？"}
            variant={DialogVariant.DELETE}
            onClose={handleDeletePopupClose}
            onDelete={() => handleNotificationDelete(currentKey)}
            paperStyleProps={{width: DialogSizeEnums.SM, height: '250px'}}
            muiDialogContentProps={{style: {display: 'flex', alignItems: 'center'}}}
        />
    )
}

export default DeletePopup
