const WoundCategoryListMap = {
    pressure: "壓力性損傷",
    diabeticFoot: "糖尿病足",
    incontinenceAssociated: "失禁皮膚",
    tumorWound: "腫瘤傷口",
    other: "其他",
}
const WOUND_RECORD_HEADER = [
    {
        key: 'action',
        name: '操作',
        className: 'text-sm w-[85px]',
    },
    {
        key: 'date',
        name: '記錄日期',
        className: 'text-sm',
    },
    {
        key: 'change',
        name: '傷口變化',
        className: 'text-sm',
    },
    {
        key: 'long',
        name: '長/寬/深',
        className: 'text-sm w-[120px]',
    },
    {
        key: 'wound',
        name: '潛行傷口',
        className: 'text-sm',
    },
    {
        key: 'level',
        name: '等級',
        className: 'text-sm',
    },
    {
        key: 'color',
        name: '傷口顏色',
        className: 'text-sm',
    },
    {
        key: 'exudates',
        name: '滲出液',
        className: 'text-sm',
    },
    {
        key: 'type',
        name: '換藥方式',
        className: 'text-sm',
    },
    {
        key: 'count',
        name: '次數',
        className: 'text-sm',
    },
    {
        key: 'nurse',
        name: '護理師',
        className: 'text-sm',
    },
]
const OptionChange = [
    {
        label: '新增',
        value: '新增'
    },
    {
        label: '無變化',
        value: '無變化'
    },
    {
        label: '癒合',
        value: '癒合'
    },
    {
        label: '惡化',
        value: '惡化'
    },
    {
        label: '惡化',
        value: '惡化'
    }
]
const OptionLevel = [
    {
        label: '1級',
        value: '1級'
    },
    {
        label: '2級',
        value: '2級'
    },
    {
        label: '3級',
        value: '3級'
    },
    {
        label: '4級',
        value: '4級'
    },
    {
        label: '無法分級',
        value: '無法分級'
    },
    {
        label: '深層組織',
        value: '深層組織'
    }
]
const OptionColor = [
    {
        label: '黑色',
        value: '黑色'
    },
    {
        label: '黃綠色',
        value: '黃綠色'
    },
    {
        label: '紅色',
        value: '紅色'
    },
    {
        label: '粉色',
        value: '粉色'
    }
]
const OptionExudates = [

    {
        label: '無',
        value: '無'
    },
    {
        label: '漿液',
        value: '漿液'
    },
    {
        label: '漿液血液混合性',
        value: '漿液血液混合性'
    },
    {
        label: '血液性',
        value: '血液性'
    },
    {
        label: '膿狀滲出液',
        value: '膿狀滲出液'
    }
]


export {
    WoundCategoryListMap,
    WOUND_RECORD_HEADER,
    OptionLevel,
    OptionExudates,
    OptionChange,
    OptionColor
}