import React from 'react'
import {PopupProvider} from '../../components/RSIS/PopupProvider'
import MailListMaintain from "../../components/RSIS/MailListMaintain/MailListMaintain";

/**
 * 警示信箱發送
 * @return {JSX.Element}
 */
const MailListMaintainLayout = () => (
    <PopupProvider>
        <MailListMaintain/>
    </PopupProvider>
)

export default MailListMaintainLayout
