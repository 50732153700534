import {BasePopup} from "../../Popup/BasePopup"
import {BaseInput} from "../../Input/BaseInput"
import {InputTypeEnum} from "edah_utils/dist"
import React, {useEffect, useState} from "react"
import {t} from "i18next";

/**
 * Alert Popup
 * @param show {boolean} show 是否顯示
 * @param onClosePopupButtonClick {function} 關閉彈窗時的 callback
 * @return {JSX.Element}
 */
export const AlertPopup = ({show, onClosePopupButtonClick}) => {
    // 是否顯示彈窗
    const [isShow, setIsShow] = useState(show)

    /**
     * 點擊確認按鈕
     * @return {void}
     */
    const handleConfirmButtonOnClick = () => {

    }

    /**
     * 取得Content
     * @return {JSX.Element}
     */
    const getContent = () => <div>
        <BaseInput className={`w-[235px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mt-4 mb-8 mr-4`}
                   type={InputTypeEnum.Text} placeholder={'--'}/>
        {/*確定按鈕*/}
        <div className="h-[60px] flex flex-row items-center justify-end border-t-[1px] border-[rgba(0,0,0,0.15)]">
            <button
                className="flex items-center justify-center h-10 px-4 mr-4 border-[2px] bg-[#2B6CB0] text-white border-[#2B6CB0] rounded-[6px]"
                onClick={handleConfirmButtonOnClick}>
                {t('general.confirm')}
            </button>
        </div>
    </div>

    /**
     * show 改變時，更新 isShow
     */
    useEffect(() => setIsShow(show), [show])

    return isShow ?
        <BasePopup title={'請輸入處理說明'} width={'375px'} closePopupButtonOnClick={onClosePopupButtonClick}
                   content={getContent()}/> : null
}
