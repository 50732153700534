import {arrayIsEmpty, time2String} from "edah_utils/dist"

/**
 * 孕婦產前檢查
 * @param data {Array} 資料
 * @return {JSX.Element}
 */
export const CheckPregnantSection = ({data}) => {
    return !arrayIsEmpty(data) ? (
        <div className="checkPregnant">
            <ul className="tableList checkPregnant">
                <li className="title">
                    <div>檢查日期</div>
                    <div>醫療院所代碼</div>
                    <div>醫療院所</div>
                    <div>檢查項目代碼</div>
                    <div>檢查項目</div>
                </li>
                {
                    data.map((item, index) =>
                        <li key={index}>
                            <div>{time2String(item.examDate)}</div>
                            <div>{item.hospNo}</div>
                            <div>義大醫院</div>
                            <div>{item.examNo}</div>
                            <div>{item.examName}</div>
                        </li>
                    )
                }
            </ul>
        </div>
    ) : null
}
