import {BrowserRouter, Route, Routes} from 'react-router-dom'
import routes from './router/index'
import {RoutesAuth} from './router/RoutesAuth'
import Loading from './components/Loading/Loading'
import {useSelector} from 'react-redux'
import {ThemeProvider} from '@mui/material/styles'
import {Alert, EDATheme, ScrollBar, ScrollBarSize} from 'edah-component/dist'

/**
 * Create Routes
 */
const createRoutes = () =>
    routes.map((route, index) => (
        <Route
            key={index}
            path={route.path}
            element={
                //不為登入URL
                route.path !== '/login' ? (
                    //透過RoutesAuth判斷是否已經登入
                    <RoutesAuth>{route}</RoutesAuth>
                ) : (
                    //已經登入就不需要驗證直接回傳
                    route.element
                )
            }
        />
    ))

/**
 * App Routes
 */
const App = () => {
    //取得Loading狀態
    const isShow = useSelector((state) => state.loadingPage)
    //取得ToastMessage狀態
    const {message, type, duration, origin, open, showCloseButton} = useSelector((state) => state.toastMessage)

    return (
        <BrowserRouter>
            <ThemeProvider theme={EDATheme}>
                <ScrollBar size={ScrollBarSize.SMALL}>
                    {/*Alert*/}
                    <Alert duration={duration} message={message} type={type} open={open}
                           origin={origin} showCloseButton={showCloseButton}/>
                    {/*Loading Page*/}
                    <Loading isShow={isShow}/>
                    <Routes>{createRoutes()}</Routes>
                </ScrollBar>
            </ThemeProvider>
        </BrowserRouter>
    )
}
export default App
