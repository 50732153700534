// Icon
import {ReactComponent as StopAlertIcon} from "../../../assets/images/SystemMonitoring/blueBell.svg"
import {ReactComponent as AlertIcon} from "../../../assets/images/SystemMonitoring/redBell.svg"

import React from "react"

/**
 * 停止報警圖示
 * @param alertStatus {string} - 報警狀態
 * @param openAlertPopup {function} - 開啟報警視窗
 * @return {JSX.Element}
 */
export const AlertButton = ({alertStatus, openAlertPopup}) => {
    switch (alertStatus) {
        // 停止報警
        case 'stop':
            return (
                <button className="flex flex-row items-center justify-start whitespace-nowrap text-[#2B6CB0]"
                        onClick={openAlertPopup}>
                    <StopAlertIcon/>
                    停止報警
                </button>
            )
        // 報警中
        case 'alert':
            return (
                <button className="flex flex-row items-center justify-start whitespace-nowrap text-[#E53E3E]">
                    <AlertIcon/>
                    報警中
                </button>
            )
        default:
            return <></>
    }
}
