import {arrayIsEmpty, convertMinGuoToGregorian, time2String} from "edah_utils/dist"

/**
 * 就醫資料重要醫令
 * @param {Array} data 資料
 * @return {JSX.Element}
 */
export const MedicalOrderSection = ({data}) => {
    return !arrayIsEmpty(data) ? (
        <div className="medicalOrder">
            <ul className="tableList medicalOrder">
                <li className="title">
                    <div>實施日期</div>
                    <div>醫療院所</div>
                    <div>重要醫令項目</div>
                    <div>實施部位</div>
                    <div>總量</div>
                </li>
                {
                    data.map((item, index) =>
                        <li key={index}>
                            {/*實施日期*/}
                            <div>{time2String(item.startDate, "YYYY-MM-DD")}</div>
                            {/*醫療院所*/}
                            <div>義大醫院</div>
                            {/*重要醫令項目*/}
                            <div>{item.heCode}</div>
                            {/*實施部位*/}
                            <div>{item.orderPos}</div>
                            {/*總量*/}
                            <div>{item.tqty}</div>
                        </li>
                    )
                }
            </ul>
        </div>
    ) : null
}
