import React from 'react';

/**
 * 基本資料組件
 * @return {JSX.Element}
 */
const BasicInfo = () => {
    return (
        <div>
            <h2>基本資料</h2>
            {/* 这里是基本资料的内容 */}
        </div>
    );
};

export default BasicInfo;
