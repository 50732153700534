import React, {useEffect, useState} from "react"
import {PopupModeEnum} from "edah_utils/dist"

/**
 * 退掛確認彈窗
 * @param show 是否顯示
 * @param closePopupButtonOnClick 關閉按鈕點擊事件
 * @param detailData 該筆詳細資料
 * @returns
 */
const SignInfoPopup = ({
   show = false,
   detailData,
   closePopupButtonOnClick
}) => {
    //grid element 的預設type
    const GridDefaultType = "grid grid-cols-2 gap-4"

    //顯示已簽radio(PopupModeEnum.Modify)或未簽radio(PopupModeEnum.Add)
    const [signRadio, setSignRadio] = useState([])

    //初始資料
    const [data, setData]=useState({})
    console.log(data)

    /**
    * 進階搜尋文字輸入事件
    * @param e {Event} 事件
    * @return {void}
    */
    const onAdvancedSearchTextOnInputChange = (e) => setAdvancedSearchText(e.target.value)

    /**
     * 進階搜尋文字鍵盤按鈕按下事件
     * @param e {Event} 事件
     * @return {void}
     */
    const onAdvancedSearchTextOnInputKeyDown = (e) => {
        if (e.key === 'Enter') {
            //搜尋Patient
            advancedQueryPatient()
        }
    }

    /**
    * 頁碼變更事件
    * @param page {Number} 頁碼
    */
    const onPaginationPageOnChange = (page) => {
        setTimeout(() => setCurrentPage(page), 100)
    }

    /**
     * 每頁資料筆數變更事件
     * @param e {Event} 事件
     */
    const onPaginationPageSizeOnChange = (e) => {
        // 設定每頁資料筆數
        setPageSize(e.target.value)
    }

    /**
     * 上一頁點擊事件
     */
    const onPaginationPreviousOnClick = () => (currentPage - 1) > 1 ? setCurrentPage(currentPage - 1) : setCurrentPage(1)

    /**
     * 下一頁點擊事件
     */
    const onPaginationNextOnClick = () => (currentPage + 1) < totalPage ? setCurrentPage(currentPage + 1) : setCurrentPage(totalPage)


    /**
     * 是否顯示彈窗
     */
    const [showPopup, setShowPopup] = useState(show)

    /**
     * 確定刪除點擊事件
     */
    const handleConfirmOnClick = () => {
        closePopupButtonOnClick()
    }

    /**
     * 取消刪除點擊事件
     */
    const handleCancelOnClick = () => {
        closePopupButtonOnClick()
    }

    /**
     * signRadio改變時
     * @param signRadio {Number}
     */
    const handleSignRadioOnChange = (signRadio) => {
        setSignRadio(signRadio)
    }

    /**
     * 監聽是否顯示彈窗Pros變化
     * 開啟畫面下，radio為已簽章
     */
    useEffect(() => {
        setShowPopup(show)
        setData(detailData)
    }, [show])

    return (showPopup ?
        <>
            <div
                className="absolute top-0 left-0 bg-[rgba(0,0,0,0.5)] w-screen h-screen z-80 min-w-screen min-h-screen items-center justify-center flex py-12 px-[100px] text-globalFont"
            >
                <div
                    className="flex w-[930px] bg-[#ffffff] rounded-md text-lg relative flex flex-col"
                    onClick={(e) => e.stopPropagation()}>
                    {/**Title */}
                    <div
                        className="h-[60px] w-full px-6 py-4 bg-white rounded-tl-lg rounded-tr-lg border-b border-neutral-900 border-opacity-20 flex justify-between items-center gap-2.5">
                        <div className="text-black text-xl font-normal">
                            詳細內容
                        </div>
                        <div className="text-sky-700 text-lg font-normal cursor-pointer"
                            onClick={handleConfirmOnClick}>關閉
                        </div>
                    </div>
                    {/**Container */}
                    <div className="m-2">
                        <div className="m-1">
                            <div >
                                <div className="flex-row flex w-full justify-end row-span-3">
                                    <button
                                        className="flex flex-row items-center justify-start h-10 bg-[#FAFAFA] border-2 border-[#D4D4D8] border-solid rounded-[6px] px-4">
                                        時戳驗證
                                    </button>
                                    <button
                                        className="ml-4 flex flex-row items-center justify-start h-10 bg-[#FAFAFA] border-2 border-[#D4D4D8] border-solid rounded-[6px] px-4">
                                        驗證文件內容
                                    </button>
                                </div>
                                <div>

                                {
                                data && data.map((data) => (

                                    <div className={`mt-3 ${GridDefaultType}`}>
                                        <div className={` ${GridDefaultType}`}>
                                            <p className=" text-right">來源系統代號</p>
                                            <p className=" text-left">{data.transFromSys}</p>
                                        </div>
                                        <div className={` ${GridDefaultType}`}>
                                            <p className=" text-right">院區</p>
                                            <p className=" text-left">{data.zone}</p>
                                        </div>
                                        <div className={` ${GridDefaultType}`}>
                                            <p className=" text-right">患者姓名</p>
                                            <p className=" text-left">{data.patientId}</p>
                                        </div>
                                        <div className={` ${GridDefaultType}`}>
                                            <p className=" text-right">患者身分證號</p>
                                            <p className=" text-left">{data.patientId}</p>
                                        </div>
                                        <div className={` ${GridDefaultType}`}>
                                            <p className=" text-right">患者病歷號</p>
                                            <p className=" text-left">{data.chartNo}</p>
                                        </div>
                                        <div className={` ${GridDefaultType}`}>
                                            <p className=" text-right">患者來源</p>
                                            <p className=" text-left">{data.inpOpd}</p>
                                        </div>
                                        <div className={` ${GridDefaultType}`}>
                                            <p className=" text-right">門/急/住院日期</p>
                                            <p className=" text-left">{data.encounterDate}</p>
                                        </div>
                                        <div className={` ${GridDefaultType}`}>
                                            <p className=" text-right">序號</p>
                                            <p className=" text-left">{data.encounterDate}</p>
                                        </div>
                                        <div className={` ${GridDefaultType}`}>
                                            <p className=" text-right">建立人員</p>
                                            <p className=" text-left">{data.createUserNo}</p>
                                        </div>
                                        <div className={` ${GridDefaultType}`}>
                                            <p className=" text-right">傳輸狀態</p>
                                            <p className=" text-left">{data.actionCode}</p>
                                        </div>
                                        <div className={` ${GridDefaultType}`}>
                                            <p className=" text-right">HCA憑證類別</p>
                                            <p className=" text-left">{data.actionCode}</p>
                                        </div>
                                        <div className={` ${GridDefaultType}`}>
                                            <p className=" text-right">簽章演算法</p>
                                            <p className=" text-left">{data.actionCode}</p>
                                        </div>
                                        <div className={` ${GridDefaultType}`}>
                                            <p className=" text-right">簽章日期時間</p>
                                            <p className=" text-left">{data.signDatetime1}</p>
                                        </div>
                                        <div className={` ${GridDefaultType}`}>
                                            <p className=" text-right">簽章人員</p>
                                            <p className=" text-left">{data.signUserNo1}</p>
                                        </div>
                                        <div className={` ${GridDefaultType}`}>
                                            <p className=" text-right">簽章HCA卡號</p>
                                            <p className=" text-left">{data.signUserNo1}</p>
                                        </div>
                                        <div className={` ${GridDefaultType}`}>
                                            <p className=" text-right">簽章電腦位置</p>
                                            <p className=" text-left">{data.signPcName1}</p>
                                        </div>
                                        <div className={` ${GridDefaultType}`}>
                                            <p className=" text-right">異動次數</p>
                                            <p className=" text-left">{data.signPcName1}</p>
                                        </div>
                                        <div className={` ${GridDefaultType}`}>
                                            <p className=" text-right">Trace ID</p>
                                            <p className=" text-left">{data.traceId}</p>
                                        </div>
                                        <div className={` ${GridDefaultType}`}>
                                            <p className=" text-right">敏感性類別</p>
                                            <p className=" text-left">{data.sensitiveType}</p>
                                        </div>
                                        <div className={` ${GridDefaultType}`}>
                                            <p className=" text-right">敏感性代號</p>
                                            <p className=" text-left">{data.sensitiveCode}</p>
                                        </div>
                                    </div>

                                    // <tr
                                    //     className={`h-[40px] ${index % 2 === 0 ? '':'bg-[#f4f4f5]'} text-[#18181b]`}
                                    //     key={index}>
                                    //     <td className={`${tableElementDefaultType}`} onClick={()=>handleSelectedOnClick(index)}>
                                    //         <input className="bg-green-400 checked:bg-green-600 text-green-600 w-[20px] h-[20px]" type="checkbox" style={{accentColor: "#38A169"}} checked={data.selected}
                                    //         onChange={()=>{}}/>
                                    //     </td>
                                    //     <td className={`${tableElementDefaultType}`}>
                                    //          <button onClick={handleCancelPdfJsonOnClick} className="flex flex-row items-center justify-start text-[#2B6CB0] ml-2"><PdfJson className="mr-1" />閱讀</button>
                                    //     </td>
                                    //     <td className={`${tableElementDefaultType}`}>{data.emrRpttype?data.emrRpttype:""}<br/>{data.emrTitle?data.emrTitle:""}</td>
                                    //     <td className={`${tableElementDefaultType}`}>{data.inpOpd?data.inpOpd:""}<br/>{data.patientName?data.patientName:""}</td>
                                    //     <td className={`${tableElementDefaultType}`}>{data.transStatus?data.transStatus:""}</td>
                                    //     <td className={`${tableElementDefaultType}`}>{data.formCode?data.formCode:""}<br/>{data.createUser?data.createUser:""}{data.createDatetime?data.createDatetime:""}</td>
                                    //     <td className={`${tableElementDefaultType}`}>{data.fixStatue?data.fixStatue:""}<br/>{data.memo?data.memo:""}</td>
                                    //     <td className={`${tableElementDefaultType}`}>{data.contentType?data.contentType:""}<br/>{data.transMemo?data.transMemo:""}</td>
                                    //     <td className={`${tableElementDefaultType}`}>{data.transDocId?data.transDocId:""}</td>
                                    // </tr>
                                ))
                            }

                                    {/* <div className={` ${GridDefaultType}`}>
                                        <p className=" text-right">來源系統代號</p>
                                        <p className=" text-left">{data.transFromSys}</p>
                                    </div>
                                    <div className={` ${GridDefaultType}`}>
                                        <p className=" text-right">院區</p>
                                        <p className=" text-left">{data.zone}</p>
                                    </div>
                                    <div className={` ${GridDefaultType}`}>
                                        <p className=" text-right">患者姓名</p>
                                        <p className=" text-left">{data.patientId}</p>
                                    </div>
                                    <div className={` ${GridDefaultType}`}>
                                        <p className=" text-right">患者身分證號</p>
                                        <p className=" text-left">{data.patientId}</p>
                                    </div>
                                    <div className={` ${GridDefaultType}`}>
                                        <p className=" text-right">患者病歷號</p>
                                        <p className=" text-left">{data.chartNo}</p>
                                    </div>
                                    <div className={` ${GridDefaultType}`}>
                                        <p className=" text-right">患者來源</p>
                                        <p className=" text-left">{data.inpOpd}</p>
                                    </div>
                                    <div className={` ${GridDefaultType}`}>
                                        <p className=" text-right">門/急/住院日期</p>
                                        <p className=" text-left">{data.encounterDate}</p>
                                    </div>
                                    <div className={` ${GridDefaultType}`}>
                                        <p className=" text-right">序號</p>
                                        <p className=" text-left">{data.encounterDate}</p>
                                    </div>
                                    <div className={` ${GridDefaultType}`}>
                                        <p className=" text-right">建立人員</p>
                                        <p className=" text-left">{data.createUserNo}</p>
                                    </div>
                                    <div className={` ${GridDefaultType}`}>
                                        <p className=" text-right">傳輸狀態</p>
                                        <p className=" text-left">{data.actionCode}</p>
                                    </div>
                                    <div className={` ${GridDefaultType}`}>
                                        <p className=" text-right">HCA憑證類別</p>
                                        <p className=" text-left">{data.actionCode}</p>
                                    </div>
                                    <div className={` ${GridDefaultType}`}>
                                        <p className=" text-right">簽章演算法</p>
                                        <p className=" text-left">{data.actionCode}</p>
                                    </div>
                                    <div className={` ${GridDefaultType}`}>
                                        <p className=" text-right">簽章日期時間</p>
                                        <p className=" text-left">{data.signDatetime1}</p>
                                    </div>
                                    <div className={` ${GridDefaultType}`}>
                                        <p className=" text-right">簽章人員</p>
                                        <p className=" text-left">{data.signUserNo1}</p>
                                    </div>
                                    <div className={` ${GridDefaultType}`}>
                                        <p className=" text-right">簽章HCA卡號</p>
                                        <p className=" text-left">{data.signUserNo1}</p>
                                    </div>
                                    <div className={` ${GridDefaultType}`}>
                                        <p className=" text-right">簽章電腦位置</p>
                                        <p className=" text-left">{data.signPcName1}</p>
                                    </div>
                                    <div className={` ${GridDefaultType}`}>
                                        <p className=" text-right">異動次數</p>
                                        <p className=" text-left">{data.signPcName1}</p>
                                    </div>
                                    <div className={` ${GridDefaultType}`}>
                                        <p className=" text-right">Trace ID</p>
                                        <p className=" text-left">{data.traceId}</p>
                                    </div>
                                    <div className={` ${GridDefaultType}`}>
                                        <p className=" text-right">敏感性類別</p>
                                        <p className=" text-left">{data.sensitiveType}</p>
                                    </div>
                                    <div className={` ${GridDefaultType}`}>
                                        <p className=" text-right">敏感性代號</p>
                                        <p className=" text-left">{data.sensitiveCode}</p>
                                    </div> */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </> : <></>
    )
}

export default SignInfoPopup;
