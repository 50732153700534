import React, {useCallback, useEffect, useState} from "react";
import {usePopup} from "../PopupProvider";
import {ApiErrorStatusEnum, objectIsEmpty, stringIsEmpty} from "edah_utils/dist";
import {
    rsisAddOrganization,
    rsisEditOrganization,
    rsisQueryCodeValue,
} from "../../../api/v1/RSIS";
import {t} from "i18next";
import {string} from "bfj/src/events";
import {
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    Field,
    SizeEnum,
    TextField,
    Dialog,
    DialogVariant,
    DialogSizeEnums,
    AlertTypeEnum,
} from "edah-component/dist";
import {Select} from "edah-component/dist/index";

/**
 * 支援機構新增編輯Modal
 * @param data {object} 待編輯的資料
 * @param type {string} 彈窗類型為新增或編輯
 * @param handleOnUpdate {function} 更新清單
 * @param handleOnClose {function} 關閉Modal
 * @return {JSX.Element}
 * */
const EditSupportOrg = ({
                            data,
                            type,
                            handleOnUpdate,
                            handleOnClose
                        }) => {
    //使用上下文
    const {
        // 顯示查詢全國醫療院所彈窗
        setShowQueryHospitalModal,
        // 當前選擇的項目
        setFocusItem,
        focusItem,
        showToast
    } = usePopup();

    /**
     * 列舉名稱
     */
    const Category = {
        /**
         * 醫療
         */
        MEDICAL: 0,
        /**
         * 非醫療
         */
        NON_MEDICAL: 1,
    };
    // Modal類型
    const [modalType, setModalType] = useState(type);
    // 編號
    const [orgPkey, setOrgPkey] = useState(data ? data.orgPkey : "");
    // 機構型態
    const [orgType, setOrgType] = useState(data ? data.orgType : Category.MEDICAL);
    // 院所(機構)代碼
    const [orgId, setOrgId] = useState(data ? data.orgNo : "");
    // 院所名稱
    const [orgName, setOrgName] = useState(data ? data.orgName : "");
    // 縣市選項
    const [cityOptions, setCityOptions] = useState([]);
    // 所有區域
    const [allDistricts, setAllDistricts] = useState([]);
    // 可使用的縣市
    const [availableDistricts, setAvailableDistricts] = useState([]);
    // 非醫療機構住址
    const [fullAddress, setFullAddress] = useState("");
    // 醫療住址
    const [orgAddress, setOrgAddress] = useState({
        //城市
        city: "",
        //區域
        district: "",
        //道路
        road: "",
    });

    /**
     * 解析地址
     * @param addressString {String} 地址
     * @return {Object|null}
     */
    const parseAddress = (addressString) => {
        const parts = addressString.match(
            /^(?<city>\D+[縣市])(?<district>\D+?(市區|鎮區|鎮市|[鄉鎮市區]))(?<others>.+)$/
        );
        //正規表示法找到時
        if (parts) {
            const groups = parts.groups;
            return parts
                ? //有找到
                {city: groups.city, district: groups.district, road: groups.others}
                : //沒找到
                {city: "", district: "", road: ""};
        }

        return {city: "", district: "", road: ""};
    };

    /**
     * 更新地址狀態
     * @param newFullAddress
     * @param newOrgType
     * @return {void}
     */
    const updateAddressStates = (newFullAddress, newOrgType) => {
        setFullAddress(newFullAddress);
        if (newOrgType === Category.MEDICAL) {
            // 醫療機構：保持完整地址
            setOrgAddress({city: "", district: "", road: newFullAddress});
        } else {
            // 非醫療機構：嘗試解析地址
            const parsedAddress = parseAddress(newFullAddress);
            console.log(parsedAddress);
            if (parsedAddress) {
                setOrgAddress(parsedAddress);
            } else {
                setOrgAddress({city: "", district: "", road: newFullAddress});
            }
        }
    };

    /**
     * 當orgType更新時，更新地址狀態
     * @param value {number} 機構型態
     * @return {void}
     */
    const handleOrgTypeChange = (value) => {
        const newOrgType = parseInt(value);
        // 設定機構型態
        setOrgType(newOrgType);
        // 更新地址狀態
        updateAddressStates(fullAddress, newOrgType);
    };

    /**
     * 當地址變更時，更新地址狀態
     * @param e {Event}
     * @return {void}
     */
    const handleAddressChange = (e) => updateAddressStates(e.target.value, orgType);

    /**
     * 當縣市變更時，更新區域
     * @param value {string} 縣市
     * @return {void}
     */
    const handleCityChange = (value) => {
        const newCity = value;
        // 設定地址
        setOrgAddress((prev) => ({...prev, city: newCity, district: ""}));
        // 更新合法地區區域
        updateAvailableDistricts(newCity);
        // 更新完整地址
        updateFullAddress(newCity, "", orgAddress.road);
    };

    /**
     * 當區域變更時，更新完整地址
     * @param value {string} 區域
     * @return {void}
     */
    const handleDistrictChange = (value) => {
        //取得新的值
        const newDistrict = value;
        //設定地址
        setOrgAddress((prev) => ({...prev, district: newDistrict}));
        // 非醫療選項改變時
        if (orgType === Category.NON_MEDICAL) {
            // 取得區域
            const district = availableDistricts.find((district) => district.name === newDistrict);
            //區域存在的話
            if (district) {
                //根據區域設定機構代碼
                setOrgId(district.codeNo);
            }
        }
        // 更新完整地址
        updateFullAddress(orgAddress.city, newDistrict, orgAddress.road);
    };

    /**
     * 當路名變更時，更新完整地址
     * @param e {Event}
     * @return {void}
     */
    const handleRoadChange = (e) => {
        const newRoad = e.target.value;
        // 設定地址
        setOrgAddress((prev) => ({...prev, road: newRoad}));
        // 更新完整地址
        updateFullAddress(orgAddress.city, orgAddress.district, newRoad);
    };

    /**
     * 更新完整地址
     * @param city {String} 城市
     * @param district {String} 區域
     * @param road {String} 道路
     * @return {void}
     */
    const updateFullAddress = (city, district, road) => setFullAddress(`${city}${district}${road}`);

    /**
     * 取得送出資料
     * @return {Object}
     */
    const getSubmitData = () => {
        if (orgType === Category.MEDICAL) {
            const address = parseAddress(fullAddress);
            return {
                //編號
                orgPkey: orgPkey,
                //院所(機構)代碼
                orgNo: orgId,
                //機構型態
                orgType: orgType,
                //院所名稱
                orgName: orgName,
                // 縣市
                orgAddrCity: address.city,
                // 區域
                orgAddrLocal: address.district,
                // 路
                orgAddrInfo: address.road,
            };
        } else if (orgType === Category.NON_MEDICAL) {
            return {
                //編號
                orgPkey: orgPkey,
                //院所(機構)代碼
                orgNo: orgId,
                //機構型態
                orgType: orgType,
                //院所名稱
                orgName: orgName,
                // 縣市
                orgAddrCity: orgAddress.city,
                // 區域
                orgAddrLocal: orgAddress.district,
                // 路
                orgAddrInfo: orgAddress.road,
            };
        }
    };

    /**
     * 存檔事件
     * @return {void}
     */
    const handleOnSave = () => {
        // 判斷使用者是新增還是編輯
        const apiCall = modalType === "ADD" ? rsisAddOrganization : rsisEditOrganization;
        // 取得送出資料
        const data = getSubmitData();

        //只檢查醫療部分
        if (data.orgType === Category.MEDICAL) {
            //資料輸入不完全
            if (
                stringIsEmpty(data.orgAddrInfo) &&
                stringIsEmpty(data.orgAddrLocal) &&
                stringIsEmpty(data.orgAddrCity)
            ) {
                showToast({message: "資料輸入不完全", type: AlertTypeEnum.Error});
                return;
            }
        }

        // 院所(機構)代碼為空
        if (stringIsEmpty(data.orgNo)) {
            showToast({message: "院所(機構)代碼不得為空", type: AlertTypeEnum.Error});
            return;
        }

        // 機構名稱為空
        if (stringIsEmpty(data.orgName)) {
            showToast({message: "機構名稱不得為空", type: AlertTypeEnum.Error});
            return;
        }

        //縣市為空
        if (stringIsEmpty(data.orgAddrCity) || !data.orgAddrCity) {
            showToast({message: "縣市不得為空", type: AlertTypeEnum.Error});
            return;
        }

        //只檢查非醫療部分
        if (data.orgType === Category.NON_MEDICAL) {
            //區域為空
            if (stringIsEmpty(data.orgAddrLocal)) {
                showToast({message: "區域不得為空", type: AlertTypeEnum.Error});
                return;
            }
        }

        //道路為空
        if (stringIsEmpty(data.orgAddrInfo)) {
            showToast({message: "道路不得為空", type: AlertTypeEnum.Error});
            return;
        }

        // 呼叫API
        apiCall(modalType === "ADD" ? data : [data]).then((res) => {
            const {err} = res;
            if (err === ApiErrorStatusEnum.Success) {
                showToast({
                    message: `支援機構${modalType === "ADD" ? "新增" : "修改"}成功`,
                    type: AlertTypeEnum.Success,
                });
                // 關閉Modal
                handleOnClose();
                // 重置全域變數focusItem
                setFocusItem(null);
                // 更新主頁面列表
                handleOnUpdate();
            } else {
                showToast({
                    message: `支援機構${modalType === "ADD" ? "新增" : "修改"}失敗`,
                    type: AlertTypeEnum.Error,
                });
            }
        });
    };

    /**
     * 取得縣市地區
     * @return {void}
     */
    const getCityDistrict = () => {
        rsisQueryCodeValue({effFlag: "ALL"}).then((res) => {
            const {err, data, msg} = res;
            if (err === ApiErrorStatusEnum.Success) {
                const cities = data
                    .filter((item) => item.codeType === "ORGANIZATION_CITY")
                    .map((item) => ({
                        name: item.codeValue1,
                        codeNo: item.codeNo,
                    }));
                const districts = data
                    .filter((item) => item.codeType === "ORGANIZATION_LOCAL")
                    .map((item) => ({
                        name: item.codeValue1,
                        codeNo: item.codeNo,
                    }));
                setCityOptions(cities);
                setAllDistricts(districts);

                // 如果已經選擇了城市，更新可用區域
                if (focusItem && focusItem.orgAddrCity) {
                    // 尋找縣市
                    const selectedCity = cities.find((city) => city.name === focusItem.orgAddrCity);
                    // 有選取縣市的話
                    if (selectedCity) {
                        // 取得縣市
                        const filteredDistricts = districts.filter((district) =>
                            district.codeNo.startsWith(selectedCity.codeNo)
                        );
                        // 設定可用縣市
                        setAvailableDistricts(filteredDistricts);
                    }
                }
            } else {
                // 錯誤時
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Warning});
                // 將資料都清空
                setCityOptions([]);
                setAllDistricts([]);
                setAvailableDistricts([]);
            }
        });
    };

    /**
     * 監聽縣市狀態，根據縣市更新可選區域
     * @param cityName {String} 縣市名稱
     * @return {void}
     */
    const updateAvailableDistricts = useCallback(
        (cityName) => {
            // 取得選取城市
            const selectedCity = cityOptions.find((city) => city.name === cityName);
            // 城市存在的話
            if (selectedCity) {
                const filteredDistricts = allDistricts.filter((district) =>
                    district.codeNo.startsWith(selectedCity.codeNo)
                );
                setAvailableDistricts(filteredDistricts);
            } else {
                //找不到城市
                setAvailableDistricts([]);
            }
        },
        [cityOptions, allDistricts]
    );

    /**
     * 監聽data更新時，更新地址狀態
     */
    useEffect(() => {
        // 當data存在時
        if (data) {
            const initialFullAddress = `${data.orgAddrCity || ""}${data.orgAddrLocal || ""}${
                data.orgAddrInfo || ""
            }`;
            // 更新地址狀態
            updateAddressStates(initialFullAddress, data.orgType);
            // 設定機構型態
            setOrgType(data.orgType);
            // 如果是非醫療機構，且有縣市資料
            if (data.orgType === Category.NON_MEDICAL && data.orgAddrCity) {
                // 更新可用區域
                updateAvailableDistricts(data.orgAddrCity);
            }
        }
    }, [data, updateAvailableDistricts]);

    /**
     * 當focusItem、更新時，更新地址狀態
     * @return {void}
     */
    useEffect(() => {
        if (!objectIsEmpty(focusItem) && focusItem.hospAddress) {
            // 設定機構代碼
            setOrgId(focusItem.hospNo);
            // 設定機構名稱
            setOrgName(focusItem.hospName);
            // 解析地址
            const address = parseAddress(focusItem.hospAddress);
            // 有地址時
            if (address) {
                // 設定地址
                setOrgAddress(address);
                // 設定完整地址
                setFullAddress(`${address.city}${address.district}${address.road}`);
                // 更新可用區域
                updateAvailableDistricts(address.city);
            }

            // 設定機構型態
            setOrgType(focusItem.orgType || Category.MEDICAL);
        }
        // 重置focusItem
        setFocusItem(null);
    }, [focusItem, updateAvailableDistricts]);

    /**
     * 第一次執行時
     * @return {void}
     */
    useEffect(() => {
        // 取得縣市地區資料
        getCityDistrict();
    }, []);

    return (
        <Dialog
            open={true}
            title={t("RSIS.Popup.EditSupportOrg")}
            content={
                <div className="px-6">
                    <div className="flex flex-row justify-end mb-4">
                        <Button
                            sx={{marginLeft: "1rem"}}
                            color={ButtonColorEnum.Secondary}
                            size={ButtonSizeEnum.Medium}
                            variant={ButtonVariantEnum.Outlined}
                            text="查詢全國醫療院所"
                            onClick={() => setShowQueryHospitalModal(true)}
                        />
                    </div>
                    <div className="flex flex-col gap-2 mb-4">
                        {/* 第一列 */}
                        <div className="flex flex-row justify-between">
                            <Field label="機構型態">
                                <Select
                                    data={{
                                        label: "機構型態",
                                        options: [
                                            {value: Category.MEDICAL, label: "醫療"},
                                            {value: Category.NON_MEDICAL, label: "非醫療"},
                                        ],
                                    }}
                                    value={orgType}
                                    onChange={handleOrgTypeChange}
                                    showLabel={false}
                                />
                            </Field>
                            <Field label="院所(機構)代碼" className="flex flex-row">
                                <TextField value={orgId} onChange={(e) => setOrgId(e.target.value)}/>
                            </Field>
                        </div>
                        {/* 第二列 */}
                        <div className="w-full flex flex-row">
                            <Field label="機構名稱">
                                <TextField
                                    inputWidth={SizeEnum.Fill}
                                    value={orgName}
                                    onChange={(e) => setOrgName(e.target.value)}
                                />
                            </Field>
                        </div>
                        {/* 第三列 */}
                        <div className="w-full flex flex-row gap-4">
                            <Field label="機構住址">
                                <div className="w-full flex flex-row gap-2 items-center">
                                    {orgType === Category.NON_MEDICAL ? (
                                        // 非醫療呈現方式
                                        <>
                                            <Select
                                                data={{
                                                    label: "選擇縣市",
                                                    options: cityOptions.map((city) => ({
                                                        value: city.name,
                                                        label: city.name,
                                                    })),
                                                }}
                                                value={orgAddress.city || ""}
                                                onChange={handleCityChange}
                                                displayEmpty={true}
                                                notched={true}
                                            />
                                            <Select
                                                data={{
                                                    label: "選擇區域",
                                                    options: availableDistricts.map((district) => ({
                                                        label: district.name,
                                                        value: district.name,
                                                    })),
                                                }}
                                                value={orgAddress.district}
                                                onChange={handleDistrictChange}
                                                displayEmpty={true}
                                                notched={true}
                                            />
                                            <TextField
                                                value={orgAddress.road}
                                                placeholder="詳細地址"
                                                onChange={handleRoadChange}
                                            />
                                        </>
                                    ) : (
                                        // 醫療呈現方式
                                        <TextField
                                            inputWidth={SizeEnum.Fill}
                                            value={fullAddress}
                                            placeholder="完整地址"
                                            onChange={handleAddressChange}
                                        />
                                    )}
                                </div>
                            </Field>
                        </div>
                    </div>
                </div>
            }
            variant={DialogVariant.EDIT}
            paperStyleProps={{width: DialogSizeEnums.LG}}
            onClose={handleOnClose}
            onSave={handleOnSave}
            muiDialogContentProps={{dividers: true}}
        />
    );
}

export default EditSupportOrg;
