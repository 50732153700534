import {
    stringIsEmpty
} from "edah_utils/dist";

/**
 * 判斷當前選擇的日期時間是不是在選擇的日期及時間區段內
 * @param {Number} apn
 * @param {Date} sourceDate 日期
 * @returns
 */
const isCurrentDateTimeWithinRange = (sourceDate, apn) => {
    const currentDate = new Date()
    const choicedDate = new Date(sourceDate)
    const tmpCurrentDate = new Date(currentDate)

    tmpCurrentDate.setHours(0, 0, 0, 0);

    if (choicedDate.toDateString() != tmpCurrentDate.toDateString()) {
        return false
    }

    const isWithinTimeRange = (startHour, startMinute, endHour, endMinute) => {
        const startTime = new Date(currentDate)
        startTime.setHours(startHour, startMinute, 0, 0)
        const endTime = new Date(currentDate)
        endTime.setHours(endHour, endMinute, 0, 0)
        return currentDate >= startTime && currentDate < endTime
    };

    if (apn === 1) {
        //0:00~12:00
        return isWithinTimeRange(0, 0, 12, 1)
    } else if (apn === 2) {
        //12:00~17:00
        return isWithinTimeRange(12, 1, 17, 0)
    } else {
        //18:00~21:00
        return isWithinTimeRange(17, 1, 24, 0)
    }
}

/**
 * 透過idNo檢查性別
 * @param {String} idNo
 * @returns
 */
const checkGender = (idNo) => {
    if (stringIsEmpty(idNo) || idNo.length < 10) {
        return "";
    }

    const secondChar = idNo[1];
    let genderName = "";
    switch (secondChar) {
        case "1": //台灣身分證
        case "8": //外勞
        case "A": //台灣地區居留證
        case "C": //外僑居留證
        case "X": //遊民
            genderName = "男";
            break;
        case "2": //台灣身分證
        case "9": //外勞
        case "B": //台灣地區居留證
        case "D": //外僑居留證
        case "Y": //遊民
            genderName = "女";
            break;
        default:
    }
    return genderName;
};

export {
    isCurrentDateTimeWithinRange,
    checkGender
}
