/**
 *  AppointmentList component
 *  @param {Array} data - An array of appointment objects
 *  @return {JSX.Element}
 */
const AppointmentList = ({data}) => {
    return (
        <div className="flex flex-row absolute top-0 left-0 h-full w-full">
            <div className="flex flex-col w-1/5 bg-blue-100  items-center pt-20 h-full space-y-6 ">
                <div className="flex flex-col space-y-2 text-xl">
                    <p>已掛: 0人</p>
                    <p>完診: 0人</p>
                    <p>待看: 0人</p>
                </div>
                <div className="flex flex-col w-full space-y-4 text-lg ">
                    <div className="flex flex-row justify-center space-x-4">
                        <div className="flex flex-row space-x-4 items-center">
                            <input
                                type="radio"
                                className="appearance-none w-4 h-4 rounded-sm bg-white ring-1 ring-gray-400 ring-offset-4 ring-offset-white checked:bg-blue-400"
                            />
                            <label>已給號</label>
                        </div>
                        <div className="flex flex-row space-x-3 items-center">
                            <input
                                type="radio"
                                className="appearance-none w-4 h-4 rounded-sm bg-white ring-1 ring-gray-400 ring-offset-4 ring-offset-white checked:bg-blue-400"
                            />
                            <label>預約號</label>
                        </div>
                    </div>
                    <div className="flex flex-row justify-center space-x-4">
                        <div className="flex flex-row space-x-4 items-center">
                            <input
                                type="radio"
                                className="appearance-none w-4 h-4 rounded-sm bg-white ring-1 ring-gray-400 ring-offset-4 ring-offset-white checked:bg-blue-400"
                            />
                            <label>預約號</label>
                        </div>
                        <div className="flex flex-row space-x-4 items-center">
                            <input
                                type="radio"
                                className="appearance-none w-4 h-4 rounded-sm bg-white ring-1 ring-gray-400 ring-offset-4 ring-offset-white checked:bg-blue-400"
                            />
                            <label>VIP號</label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-grow p-12">
                <div className="h-fit w-fit">
                    <div className="grid grid-cols-10 gap-y-4 gap-x-5">
                        {
                            data.map((appointment, index) =>
                                <div
                                    className="w-24 h-8 border-2 border-gray-600 rounded-lg flex flex-row items-center justify-between text-center"
                                    key={index}>
                                    <div className="w-full h-full  bg-blue-200">
                                        {appointment.doctor ? index + 1 : ""}
                                    </div>
                                    <div className="w-full h-full  bg-customYellow text-left px-3">
                                        {appointment.time ? appointment.time : ""}
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AppointmentList;
