// Import ICON
import SearchIcon from "../../assets/images/search-interface-symbol.png"

// Import function
import {
    ApiErrorStatusEnum,
    EssiCrlStatusEnum,
    PopupModeEnum,
    arrayIsEmpty,
    fuzzySearchObjects,
    multiTermFuzzySearchObjects,
    objectIsEmpty, ToastTypeEnum, stringIsEmpty,
} from "edah_utils/dist"
import React, {useEffect, useMemo, useRef, useState} from "react"
import HcaPopup from "./HcaPopup"
import useOutsideClick from "../../hooks/useOutsideClick"
import {queryAllDoctor} from "../../api/v1/Menu"
import {t} from "i18next"
import {essiAddUserCert, essiEditUserCert, essiReadHCA} from "../../api/v1/ESSI"
import useToast from "../../hooks/useToast"

/**
 * 人員憑證維護-新增 / 修改彈窗
 * @param show 是否顯示
 * @param data {Object} 資料
 * @param mode {Number} 新增或編輯
 * @param closePopupOnClick {function} 關閉按鈕點擊事件
 * @return {JSX.Element}
 */
const AddEditUserCerPopup = ({show = false, data, mode, closePopupOnClick}) => {
    /**
     * grid element 的預設type
     */
    const GridDefaultType = "grid grid-cols-2 gap-x-6 gap-y-2"

    /**
     * Show Toast
     */
    const showToast = useToast()

    /**
     * 預設資料
     */
    const defaultData = {
        // 卡片號碼
        "cardNumber": '',
        // 使用者代號
        "userNo": '',
        // 使用者名稱
        "userName": '',
        // HCA 卡別
        "cardType": '',
        // 存取碼,只用在自動簽章使用
        "accessCode": '',
        // HCA 憑證簽章類型 1 : SHA1 2 : SHA2
        "hcaCertType": '',
        // CA 憑證
        "certTypeCa": '',
        // 驗證簽章用(ID1)
        "certTypeId1": '',
        // 加解密用(ID2)
        "certTypeId2": '',
        // 憑證有效日期-起
        "effStartDate": '',
        // 憑證有效日期-迄
        "effEndDate": '',
        // 憑證有效否(N 正常/C 己廢止/S 停用)
        "crlStatus": ''
    }

    //是否為新增模式
    const [isAddMode, setIsAddMode] = useState(false)

    //新增或修改的資料
    const [newData, setNewData] = useState(mode === PopupModeEnum.Add ? defaultData : data)

    // 讀取HCA popup設定
    const [hcaPopup, setHcaPopup] = useState(false)

    // 是否顯示人員代號選項
    const [showUserDropdown, setShowUserDropdown] = useState(false)

    //ref 用於指向人員代下拉菜單元素
    const dropdownUserRef = useRef(null)

    //ref 用於指向pinCode元素
    const pinCodeRef = useRef(null)

    //是否顯示彈窗
    const [showPopup, setShowPopup] = useState(show)

    // 醫生選項
    const [doctorOptions, setDoctorOptions] = useState([])

    // HCA資料
    const [hcaData, setHcaData] = useState({})

    /**
     * 處理人員代號搜尋文字框焦點事件
     * @return {void}
     */
    const handleUserSearchTextOnFocus = () => setShowUserDropdown(true)

    /**
     * 處理人員代號選項點擊事件
     * @param option {Object} 人員代號
     * @return {void}
     */
    const handleUserOptionOnClick = (option) => {
        // 值不為空
        if (!objectIsEmpty(option)) {
            setNewData({...newData, userNo: `${option.userNo} ${option.userName}`})
        }
        // 關閉醫師下拉選單
        setShowUserDropdown(false)
    }

    /**
     * 取得 醫師選項列表
     * @return {Array<Object>}
     */
    const getFilterDoctorOptionList = () =>
        // 醫生是否有空格
        newData.userNo.indexOf(' ') >= 0 ?
            // 多個字串模糊搜尋
            multiTermFuzzySearchObjects(doctorOptions, newData.userNo.split(' ')) :
            // 單個字串模糊搜尋
            fuzzySearchObjects(doctorOptions, newData.userNo)

    /**
     * 確定刪除點擊事件
     * @return {void}
     */
    const handleConfirmOnClick = () => closePopupOnClick()

    /**
     * 儲存或編輯按鈕被點擊時
     * @return {void}
     */
    const handleSaveOrEditOnClick = () => {
        // 如果是新增模式
        if (isAddMode) {
            addUserCert()
        } else { // 如果是編輯模式
            editUserCert()
        }
    }

    /**
     * 關閉HCA視窗關閉事件
     * @return {void}
     */
    const handleHcaPopupOnClose = () => setHcaPopup(false)

    /**
     * 使用者改變時
     * @param e {Event}
     * @return {void}
     */
    const handleUserOnChange = (e) => setNewData({...newData, userNo: e.target.value})

    /**
     * 欄位改變時
     * @return {void}
     */
    const handleServerTypeOnChange = (e) => setNewData({...newData, crlFlag: e.target.value})

    /**
     * Pin Code欄位改變時
     * @return {void}
     */
    const handleAccessCodeOnChange = (e) => setNewData({...newData, accessCode: e.target.value})

    /**
     * 點擊讀取HCA卡時
     * 出現彈跳視窗>(如PIN CODE為空要求輸入，如PIN CODE有值將其帶入)要求輸入pin code>HCA API>顯示成功/失敗原因
     * 成功紀錄此筆certTypeCa、certTypeId1、certTypeId2
     * @param e {Event} 事件
     * @return {void}
     */
    const handleHcaButtonOnClick = (e) => {
        // 阻止預設行為
        e.preventDefault()
        // 如果PIN CODE為空要求輸入
        if (stringIsEmpty(newData.accessCode)) {
            // 顯示錯誤訊息
            showToast({message: '請輸入PIN CODE', type: ToastTypeEnum.Error})
            // 聚焦
            pinCodeRef.current.focus()
            return
        }

        // 顯示HCA Popup
        setHcaPopup(true)
    }

    /**
     * 處理API回傳結果
     * @param res {Object} API回傳結果
     * @return {void}
     */
    const handleApiResult = (res) => {
        // 顯示訊息
        const {err, msg} = res

        if (err === ApiErrorStatusEnum.Success) {
            // 顯示訊息
            showToast({message: msg})
        } else {
            // 顯示錯誤訊息
            showToast({message: msg, type: ToastTypeEnum.Error})
        }
    }

    /**
     *  新增人員憑證維護
     *  @return {void}
     */
    const addUserCert = () => {
        essiAddUserCert([newData]).then(handleApiResult)
    }

    /**
     * 修改人員憑證維護資料
     * @return {void}
     */
    const editUserCert = () => {
        essiEditUserCert([newData]).then(handleApiResult)
    }

    /**
     * 讀取HCA卡片
     * @return {void}
     */
    const readHCA = () => {
        essiReadHCA({}).then(res => {
            const {err, data} = res
            if (err === ApiErrorStatusEnum.Success) {
                // 設定HCA資料
                setHcaData(data)
            }
        })
    }

    /**
     * 取得查詢醫生
     * @return {void}
     */
    const getQueryAllDoctor = () => {
        queryAllDoctor({}).then(res => {
            if (res.err === ApiErrorStatusEnum.Success) {
                // 設定醫生選項
                setDoctorOptions(res.data)
            }
        })
    }

    /**
     * 點擊外部區域時
     */
    useOutsideClick({
        ref: dropdownUserRef,
        handler: () => setShowUserDropdown(false)
    })

    /**
     * 第一次執行時
     * @return {void}
     */
    useMemo(() => getQueryAllDoctor(), [])

    /**
     * 監聽是否顯示彈窗Pros變化
     * @return {void}
     */
    useEffect(() => {
        // 設定是否顯示彈窗
        setShowPopup(show)
        // 設定是否為新增模式
        setIsAddMode(mode === PopupModeEnum.Add)
        // 設定資料
        setHcaData({})
    }, [show])

    return showPopup ?
        <div
            className="absolute top-0 left-0 bg-[rgba(0,0,0,0.5)] w-screen h-screen z-80 min-w-screen min-h-screen items-center justify-center flex py-12 px-[100px] text-globalFont">
            <div className="w-[930px] bg-[#ffffff] rounded-md text-lg relative flex flex-col">
                {/**Title */}
                <div
                    className="h-[60px] w-full px-6 py-4 bg-white rounded-tl-lg rounded-tr-lg border-b border-neutral-900 border-opacity-20 flex justify-between items-center gap-2.5">
                    <div className="text-black text-xl font-normal">
                        人員憑證維護
                    </div>
                    {/*關閉*/}
                    <div className="text-sky-700 text-lg font-normal cursor-pointer"
                         onClick={handleConfirmOnClick}>
                        {t('general.close')}
                    </div>
                </div>
                {/**Container*/}
                <div className="px-4 py-5">
                    <div className={`${GridDefaultType}`}>
                        {/*員工代號*/}
                        <div className={`${GridDefaultType}`}>
                            <p className="text-right mt-1">員工代號</p>
                            <div className="flex flex-row space-x-2 items-center relative w-full"
                                 ref={dropdownUserRef}>
                                <input
                                    className="bg-white w-full px-2 p-1 pl-9 text-[#3F3F46] rounded-md border border-[#D4D4D8]"
                                    value={newData.userNo}
                                    onChange={(e) => handleUserOnChange(e)}
                                    onFocus={handleUserSearchTextOnFocus}/>
                                <img className="w-4 h-4 absolute top-w-1/2 left-0 translate-x-1" alt="search-icon"
                                     src={SearchIcon} role="button"/>
                                {
                                    !arrayIsEmpty(doctorOptions) && showUserDropdown && (
                                        <div
                                            className="z-10 absolute left-5 border border-[#D4D4D8] bg-white rounded-md top-8 w-64 flex flex-col">
                                            <div className="max-h-[200px] bg-transparent overflow-y-auto px-2.5">
                                                {
                                                    getFilterDoctorOptionList().map((doctor, index) =>
                                                        <div
                                                            className="bg-transparent border-b min-h-10 flex space-x-6 px-4 py-1 text-[#18181B] items-center"
                                                            role="button" key={index}
                                                            onClick={() => handleUserOptionOnClick(doctor)}>
                                                            <p>{`${doctor.userNo} ${doctor.userName}`}</p>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div>
                            {/*憑證有效否*/}
                            <div className="flex flex-row justify-end mr-6">
                                <p className=" text-right mt-1 min-w-[300px] mr-2">憑證有效否</p>
                                <select
                                    className="px-[14px] min-w-[100px] border border-[#D4D4D8] h-[40px] rounded-[6px]"
                                    value={newData.crlFlag}
                                    onChange={(e) => handleServerTypeOnChange(e)}>
                                    {
                                        Object.keys(EssiCrlStatusEnum).map((item, index) =>
                                            <option key={index} value={EssiCrlStatusEnum[item]}>
                                                {t(`ESSI.CertMaintenance.crlFlagEnum.${item}`)}
                                            </option>
                                        )
                                    }
                                </select>
                            </div>
                        </div>
                        <div className={` ${GridDefaultType}`}>
                            <p className=" text-right mt-1">PIN CODE</p>
                            <input
                                ref={pinCodeRef}
                                className={`px-[14px] w-[100%] border border-[#D4D4D8] h-[40px] rounded-[6px] mr-[30px]`}
                                type="password"
                                value={newData.accessCode}
                                onChange={(e) => handleAccessCodeOnChange(e)}/>
                        </div>
                        <div className="flex flex-row justify-start">
                            <button
                                className="bg-[#FAFAFA] border-[2px] px-4 border-[#D4D4D8] rounded-[6px] text-[16px] min-w-[64px]"
                                onClick={handleHcaButtonOnClick}>
                                讀取HCA卡片
                            </button>
                        </div>
                        <div className={`${GridDefaultType}`}>
                            <p className="text-right mt-1">卡號</p>
                            <input
                                className={`px-[14px] w-[100%] border border-[#D4D4D8] h-[40px] rounded-[6px] mr-[30px]`}
                                disabled={true} type="text" value={newData.cardNumber}/>
                        </div>
                        <div></div>
                        <div className={`${GridDefaultType}`}>
                            <p className="text-right mt-1">HCA卡別</p>
                            <input
                                className={`px-[14px] w-[100%] border border-[#D4D4D8] h-[40px] rounded-[6px] mr-[30px]`}
                                disabled={true} type="text" value={newData.cardType}/>
                        </div>
                        <div></div>
                        <div className={`${GridDefaultType}`}>
                            <p className="text-right mt-1">HCA憑證類別</p>
                            <input
                                className={`px-[14px] w-[100%] border border-[#D4D4D8] h-[40px] rounded-[6px] mr-[30px]`}
                                type="text" value={newData.hcaCertType} disabled={true}/>
                        </div>
                        <div></div>
                        <div className={`${GridDefaultType}`}>
                            <p className=" text-right mt-1">HCA有效起訖日期</p>
                            <input
                                className={`px-[14px] w-[100%] border border-[#D4D4D8] h-[40px] rounded-[6px] mr-[30px]`}
                                type="date" value={newData.effStartDate} disabled={true}/>
                        </div>
                        <div className="flex flex-row">
                            <p className="mr-4 text-right mt-1">~</p>
                            <input
                                className={`px-[14px] w-[50%] border border-[#D4D4D8] h-[40px] rounded-[6px] mr-[30px]`}
                                type="date" value={newData.effEndDate} disabled={true}/>
                        </div>
                        <div className={`${GridDefaultType}`}>
                            <p className="mx-2 text-right mt-1">HCA簽章憑證</p>
                            <p className="mx-2 text-left mt-1">HCA簽章憑證</p>
                        </div>
                    </div>
                </div>
                {/**下方區塊(存檔) */}
                <div
                    className="h-[60px] p-4 bg-white rounded-bl-lg rounded-br-lg border-t border-neutral-900 border-opacity-20 flex-col justify-center items-end inline-flex">
                    {/*送出按鈕*/}
                    <button className="bg-[#2B6CB0] p-2 text-white rounded-[6px] text-[16px] min-w-[64px]"
                            onClick={handleSaveOrEditOnClick}>
                        送出
                    </button>
                </div>
            </div>
            {/*HCA Popup*/}
            <HcaPopup show={hcaPopup} closePopupButtonOnClick={handleHcaPopupOnClose} pinCode={newData.accessCode}/>
        </div> : <></>
}

export default AddEditUserCerPopup
