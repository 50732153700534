import { createSlice } from '@reduxjs/toolkit'

export const print = createSlice({
    name: 'print',
    initialState: {
        reportType: '',
        printData: null,
        isPrintMode: false,
    },
    reducers: {
        /**
         * 更新列印資料
         * @param state
         * @param action
         */
        updatePrintContent(state, action) {
            const { reportType, printData } = action.payload
            state.reportType = reportType
            state.printData = printData
            state.isPrintMode = true
        },
        /**
         * 結束列印模式
         * @param state
         * @param action
         */
        completePrintMode(state, action) {
            state.isPrintMode = false
        }
    },
})
export const { updatePrintContent, completePrintMode } = print.actions
export default print.reducer
