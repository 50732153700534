import React, {useEffect, useState} from 'react'
import CustomTable from '../RSISCustomTable'
import {RSISQueryHospitalTable} from '../Constant'
import {usePopup} from "../PopupProvider";
import {t} from 'i18next'
// 引入api
import {queryAllHospital} from '../../../api/v1/Menu'
import {ApiErrorStatusEnum} from "edah_utils/dist";
import {Dialog, DialogSizeEnums, DialogVariant, RadioGroup, SizeEnum, TextField} from "edah-component/dist";

/**
 * 查詢全國醫療院所Modal 清單組件
 * @param {item} item required
 * @param {index} index required
 * @param {hasSelectAll} hasSelectAll default false
 * @returns {JSX.Element}
 */
const ListItem = ({
                      item,
                      index,
                      hasSelectAll = false,
                  }) => {
    // 使用上下文
    const {setShowQueryHospitalModal, setFocusItem} = usePopup();

    /**
     * 點擊列表項目事件，帶回支援機構資料
     * @return {void}
     */
    const handleItemOnClick = () => {
        setFocusItem(item)
        setShowQueryHospitalModal(false)
    }

    return (
        <li className='hover:cursor-pointer' key={index} onClick={handleItemOnClick}>
            <div>{item.hospNo}</div>
            <div>{item.hospName}</div>
            <div>{item.hospAddress}</div>
        </li>
    )
}

// 健保分局選項
const hospitalBranchOptions = [
    {label: '台北', value: '1'},
    {label: '北區', value: '2'},
    {label: '中區', value: '3'},
    {label: '南區', value: '4'},
    {label: '高屏', value: '5'},
    {label: '東區', value: '6'},
]

// 醫院類型選項
const hospGradeOption = [
    {label: '醫學中心', value: '1'},
    {label: '區域醫院', value: '2'},
    {label: '地區醫院', value: '3'},
    {label: '基層院所', value: '4'},
]

/** 查詢全國醫療院所Modal
 * @param {function} handleOnClose required
 * @returns {JSX.Element}
 * */
function QueryHospital({handleOnClose}) {
    // 健保地區
    const [hospBranch, setHospBranch] = useState('')
    // 醫院類型
    const [hospGrade, setHospGrade] = useState('')
    // 名稱搜尋
    const [querySearchString, setQuerySearchString] = useState('')
    // 進階搜尋
    const [queryAdvancedSearch, setQueryAdvancedSearch] = useState(false)
    // 列表清單組件
    const List = ListItem
    // 列表清單資料集
    const [dataList, setDataList] = useState([])
    // 分頁組件
    const [paginationProps, setPaginationProps] = useState({
        pageNum: 1,
        pageSize: 10,
        totalItemSize: 0,
        totalPageSize: 0,
        currentPageItemSize: 0,
        fn: () => {
        }
    })

    /**
     * 醫院類型改變事件
     * @param {string} value 選取的值
     * @return {void}
     */
    const handleHospitalTypeClick = (value) => {
        if (hospGrade === value) setHospGrade('') // 如果點擊的是當前選中的選項，取消選擇
        else setHospGrade(value)
    }

    /**
     * 健保分局點選時
     * @param {string} value 選取的值
     * @return {void}
     */
    const handleHospitalBranchClick = (value) => {
        if (hospBranch === value) setHospBranch('') // 如果點擊的是當前選中的選項，取消選擇
        else setHospBranch(value)
    }

    /**
     * 按下Enter鍵時
     * @return {void}
     */
    const handleOnKeyDown = (event) => {
        if (event.key === 'Enter') {
            getAllHospital()
        }
    }

    /**
     * 取得全國醫療院所
     * @return {void}
     */
    const getAllHospital = () => {
        queryAllHospital({
            // 健保分局，空值查全部，1 台北地區 1:台北地區 2北部地區 3中部地區 4南部地區 5高屏地區 6東部地區
            hospBranch: hospBranch,
            // 特約類別，空值查全部
            hospGrade: hospGrade,
            // 醫院代號，模糊搜尋，空值查全部
            hospNo: '',
            // 醫院名稱，模糊搜尋，空值查全部
            hospName: querySearchString,
            // 醫院地址，模糊搜尋，空值查全部
            hospAddress: '',
            // 高雄區域地址，模糊搜尋，空值查全部
            khhAddress: '',
            // 第幾頁
            pageNum: paginationProps.pageNum,
            // 每頁幾筆
            pageSize: paginationProps.pageSize,
        }).then(res => {
            const {err, data, msg} = res
            console.log(data)
            if (err === ApiErrorStatusEnum.Success) {
                setDataList(data.dataList)
                setPaginationProps({
                    ...paginationProps,
                    totalItemSize: data.totalItemSize,
                    totalPageSize: data.totalPageSize,
                    currentPageItemSize: data.currentPageItemSize,
                })
            } else {
                setDataList([])
            }
        })
    }

    // 第一次執行時
    useEffect(() => {
        // 取得全國醫療院所
        getAllHospital()
    }, []);

    // 監聽健保分局、醫院類型改變時
    useEffect(() => {
        // 取得全國醫療院所
        getAllHospital()
    }, [hospGrade, hospBranch]);

    return (
        <Dialog
            open={true}
            title={t('RSIS.Popup.QueryHospital')}
            content={
                <div className='px-6 py-4'>
                    <div className='flex flex-col gap-4'>
                        {/* 健保分局 */}
                        <div className='flex flex-row items-center gap-6'>
                            <p>健保分局 :</p>
                            <div className='flex flex-row items-center gap-[24px]'>
                                <RadioGroup
                                    row={true} size={SizeEnum.Medium} value={hospBranch}
                                    optionProps={{
                                        options: hospitalBranchOptions.map((item) => ({
                                            label: item.label,
                                            value: item.value,
                                        }))
                                    }}
                                    onChange={(event) => handleHospitalBranchClick(event.target.value)}/>
                            </div>
                        </div>
                        {/* 醫院分局 */}
                        <div className='flex flex-row items-center gap-6'>
                            <p>醫院分局 :</p>
                            <RadioGroup
                                row={true} size={SizeEnum.Medium} value={hospGrade}
                                optionProps={{
                                    options: hospGradeOption.map((item) => ({
                                        label: item.label,
                                        value: item.value,
                                    }))
                                }}
                                onChange={(event) => handleHospitalTypeClick(event.target.value)}/>
                        </div>
                        <div className='flex flex-row items-center mb-4'>
                            <TextField
                                label="名稱搜尋"
                                placeholder='請輸入名稱'
                                searchText={querySearchString}
                                onChange={(e) => setQuerySearchString(e.target.value)}
                                onKeyDown={handleOnKeyDown}
                            />
                        </div>
                    </div>
                    <CustomTable
                        fields={RSISQueryHospitalTable.fields}
                        type={'RSISQueryHospitalTable'}
                        dataList={dataList}
                        ListItem={List}
                        hasSelectAll={false}
                        advancedSearch={true}
                        paginationProps={paginationProps}
                    />
                </div>
            }
            variant={DialogVariant.CONFIRM}
            paperStyleProps={{width: DialogSizeEnums.LG}}
            onClose={handleOnClose}
            muiDialogContentProps={{dividers: true}}
            muiDialogActionsProps={{sx: {display: 'none'}}}
        />
    )
}

export default QueryHospital
