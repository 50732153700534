import {BaseInput} from "./BaseInput"
import {InputTypeEnum} from "edah_utils/dist"

import searchIcon from "../../assets/images/search-interface-symbol.png"

/**
 * 搜尋輸入框
 * @param searchText {String} 搜尋文字
 * @param onChange {Function} 搜尋文字改變時觸發
 * @param className {String} 自訂樣式
 * @param placeholder {String} 提示文字
 * @param onKeyDown {Function|Null} 鍵盤按鍵按下事件
 */
export const SearchInput = ({searchText, onChange, onClick=null, onKeyDown = null, className = '', placeholder = ''}) => (
    <div className="w-[182px] px-[8px] flex items-center relative">
        <BaseInput
            className={`${className ? className : 'bg-white w-full px-2 text-black rounded-[6px] border-2 py-1 border-gray-300 pl-8'}`}
            type={InputTypeEnum.Text} value={searchText} placeholder={placeholder}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onClick={onClick}/>
        <img className="w-4 h-4 absolute top-w-1/2 left-0 translate-x-5"
             src={searchIcon} alt="search-icon" role="button"/>
    </div>
)

