import React, {useEffect, useState} from "react";
import {t, use} from "i18next";
import {BaseInput} from "../../components/Input/BaseInput";
import {arrayIsEmpty, fuzzySearchObjects,} from "edah_utils/dist"
import {
    Select,
    SelectWidthEnum,
    Button,
    ButtonSizeEnum,
    ButtonVariantEnum,
    ButtonColorEnum,
    IconEnum,
    DatePicker
} from "edah-component/dist";
import dayjs from "dayjs";

/**
 * 居家護理記錄項目維護
 * @return {JSX.Element}
 */
const HomeAssessmentOperations = () => {
    // 搜尋文字
    const [searchText, setSearchText] = useState('')
    // 類別代碼
    const [categoryCode, setCategoryCode] = useState('0')
    // Fake Data
    const [dataObject, setDataObject] = useState([
        {
            categories: '001', //類別代碼
            name: '訪視紀錄', //類別名稱
            sortBy: '1', //順序
            deactiveDate: '2024-06-18', //停用日期
        },
        {
            categories: '002',
            name: '心肺功能(肢體水腫)',
            sortBy: '2',
            deactiveDate: '2024-06-18',
        },
        {
            categories: '003',
            name: '心肺功能(呼吸音)',
            sortBy: '2',
            deactiveDate: '2024-06-18',
        },
    ]);
    // Fake Data
    const [detailData, setDetailData] = useState([
        {
            categories: '001', //類別代碼
            name: '訪視紀錄', //類別名稱
            itemNo: 'A01', //項目代碼
            itemName: '新案', //項目名稱
            sortBy: '1', //順序
            deactiveDate: '2024-06-18', //停用日期
        },
        {
            categories: '002',
            name: '心肺功能(肢體水腫)',
            itemNo: 'A02', //項目代碼
            itemName: '護理訪視', //項目名稱
            sortBy: '2',
            deactiveDate: '2024-06-18',
        },
        {
            categories: '003',
            name: '心肺功能(呼吸音)',
            itemNo: 'A03', //項目代碼
            itemName: '醫訪', //項目名稱
            sortBy: '2',
            deactiveDate: '2024-06-18',
        },
    ]);
    //edit State
    const [editIndex, setEditIndex] = useState(-1); // Track which item is being edited
    const [editData, setEditData] = useState({}); // Store edited data temporarily
    //add State for New Row
    const [isNewRowVisible, setIsNewRowVisible] = useState(false);
    const [newRowData, setNewRowData] = useState({
        categories: '',
        name: '',
        sortBy: '',
        deactiveDate: ''
    });

    /**
     * 搜尋文字改變時
     * @param e {Event} e
     * @return {void}
     */
    const handleSearchTextOnChange = (e) => setSearchText(e.target.value)

    /**
     * 類別代碼改變時
     * @param value {string}
     * @return {void}
     */
    const handleCategoryCodeOnChange = (value) => setCategoryCode(value)

    /**
     * 取得模糊匹配後的資料陣列
     * @return {Array<Object>}
     */
    const getFilterData = (data) => fuzzySearchObjects(data, searchText)

    /**
     * copy data to editData
     */
    const handleEditClick = (index) => {
        setEditIndex(index);
        setEditData({...dataObject[index]});
    };

    const handleSaveEdit = (index) => {
        // update dataObject with edited data
        const newDataObject = [...dataObject];
        newDataObject[index] = editData;
        setDataObject(newDataObject);

        // reset edit mode
        setEditIndex(-1);
        setEditData({});
    };

    const handleCancelEdit = () => {
        // Cancel edit mode
        setEditIndex(-1);
        setEditData({});
    };

    const handleInputChange = (date, key) => {
        // Update editData when input changes
        setEditData({
            ...editData,
            [key]: date ? date.toDate() : null,
        });
    };

    /**
     * handle new row and change
     * */
    const toggleNewRow = () => {
        setIsNewRowVisible(!isNewRowVisible);
    };
    const handleNewRowInputChange = (e, key) => {
        setNewRowData({
            ...newRowData,
            [key]: e.target.value,
        });
    };
    //save new row
    const handleSaveNewRow = () => {
        // Validate newRowData if necessary
        const newDataObject = [...dataObject];
        newDataObject.push(newRowData);
        setDataObject(newDataObject);

        // reset new row state
        setIsNewRowVisible(false);
        setNewRowData({
            categories: '',
            name: '',
            sortBy: '',
            deactiveDate: ''
        });
    };

    return (
        <div className="w-full p-4 bg-[#FAFAFA]">
            {/*篩選清單*/}
            <div className="filterGroup flex flex-row flex-wrap items-center justify-start space-x-2 py-2">
                    {/*類別代碼*/}
                    <Select
                        data={{
                            label: '類別代碼',
                            options: [
                                {value: "0", label: "001 訪視紀錄"},
                                {value: "1", label: "002"},
                                {value: "2", label: "003"}
                            ]
                        }}
                        value={categoryCode}
                        onChange={handleCategoryCodeOnChange}
                        notched={true}
                        width={SelectWidthEnum.Medium}
                    />

                {/*查詢送出按鈕*/}
                    <Button color={ButtonColorEnum.Primary} variant={ButtonVariantEnum.Contained}
                            size={ButtonSizeEnum.Medium} text={t('general.query')}/>
            </div>

            {/*上－清單區塊*/}
            <div className="maintainContainer flex flex-row items-start justify-start">
                <div className="p-4 bg-white w-full border-[1px] border-[#D4D4D8] rounded-[12px] mr-4">
                    <div className="searchGroup flex flex-row items-center justify-between mb-4">
                        {/*進階搜尋*/}
                        <BaseInput className="w-[320px] h-10 pl-4 border-[2px] border-[#D4D4D8] rounded-[6px]"
                                   type="text"
                                   placeholder={`${t('general.advancedSearch')}`}
                                   inputMode={'search'}
                                   value={searchText}
                                   onChange={(e) => handleSearchTextOnChange(e)}/>
                    </div>

                    {/*列表清單*/}
                    <ul className="tableList HCRMTableList">
                        {/*表單項目標題*/}
                        <li className="title">
                            <div>
                                {/*add new row btn*/}
                                <Button color={ButtonColorEnum.Success} variant={ButtonVariantEnum.Text}
                                        size={ButtonSizeEnum.Large} text={t('general.add')}
                                        icon={IconEnum.Add} onClick={toggleNewRow}
                                />
                            </div>
                            <div>類別代碼</div>
                            <div>類別名稱</div>
                            <div>順序</div>
                            <div>停用日期</div>
                        </li>

                        {!arrayIsEmpty(getFilterData(dataObject)) &&
                            getFilterData(dataObject).map((item, index) => (
                                <li key={index}>
                                    {editIndex === index ? (
                                        <>
                                            {/* Edit mode */}
                                            <div>
                                                <Button color={ButtonColorEnum.Primary} variant={ButtonVariantEnum.Text}
                                                        size={ButtonSizeEnum.Large} text={t('general.save')}
                                                        icon={IconEnum.Save} onClick={() => handleSaveEdit(index)}
                                                />
                                                <Button color={ButtonColorEnum.Primary} variant={ButtonVariantEnum.Text}
                                                        size={ButtonSizeEnum.Large} text={t('general.cancel')}
                                                        icon={IconEnum.Cancel} onClick={handleCancelEdit}
                                                />
                                            </div>
                                            <div
                                                className="w-[320px] h-10 pl-4 border-[2px] border-[#D4D4D8] rounded-[6px]">
                                                {editData.categories}
                                            </div>
                                            <div>
                                                <BaseInput
                                                    className="w-[320px] h-10 pl-4 border-[2px] border-[#D4D4D8] rounded-[6px]"
                                                    type="text"
                                                    value={editData.name}
                                                    onChange={(e) => handleInputChange(e, 'name')}
                                                />
                                            </div>
                                            <div>
                                                <BaseInput
                                                    className="w-[320px] h-10 pl-4 border-[2px] border-[#D4D4D8] rounded-[6px]"
                                                    type="text"
                                                    value={editData.sortBy}
                                                    onChange={(e) => handleInputChange(e, 'sortBy')}
                                                />
                                            </div>
                                            {/*停用日期－編輯模式*/}
                                            <DatePicker
                                                label=""
                                                value={editData.deactiveDate ? dayjs(editData.deactiveDate) : null}
                                                onChange={(date) => handleInputChange(date, 'deactiveDate')}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            {/* View mode */}
                                            <div>
                                                <Button color={ButtonColorEnum.Primary} variant={ButtonVariantEnum.Text}
                                                        size={ButtonSizeEnum.Large} text={t('general.edit')}
                                                        icon={IconEnum.Edit} onClick={() => handleEditClick(index)}
                                                />
                                                <Button color={ButtonColorEnum.Primary} variant={ButtonVariantEnum.Text}
                                                        size={ButtonSizeEnum.Large} text={t('general.delete')}
                                                        icon={IconEnum.Delete}
                                                />
                                            </div>
                                            {/*類別代碼*/}
                                            <div>{item.categories}</div>
                                            {/*類別名稱*/}
                                            <div>{item.name}</div>
                                            {/*順序*/}
                                            <div>{item.sortBy}</div>
                                            {/*停用日期*/}
                                            <div>{item.deactiveDate}</div>
                                        </>
                                    )}
                                </li>

                            ))}
                        {/* New row input fields */}
                        {isNewRowVisible && (
                            <li>
                                <div>
                                    <Button color={ButtonColorEnum.Primary} variant={ButtonVariantEnum.Text}
                                            size={ButtonSizeEnum.Large} text={t('general.save')}
                                            icon={IconEnum.Save} onClick={handleSaveNewRow}
                                    />
                                    <Button color={ButtonColorEnum.Primary} variant={ButtonVariantEnum.Text}
                                            size={ButtonSizeEnum.Large} text={t('general.cancel')}
                                            icon={IconEnum.Cancel} onClick={toggleNewRow}
                                    />
                                </div>
                                <div className="w-[320px] h-10 pl-4">
                                    {/* categories not allow to edit */}
                                </div>
                                <div>
                                    <BaseInput
                                        className="w-[320px] h-10 pl-4 border-[2px] border-[#D4D4D8] rounded-[6px]"
                                        type="text"
                                        value={newRowData.name}
                                        onChange={(e) =>
                                            handleNewRowInputChange(e, "name")
                                        }
                                    />
                                </div>
                                <div>
                                    <BaseInput
                                        className="w-[320px] h-10 pl-4 border-[2px] border-[#D4D4D8] rounded-[6px]"
                                        type="text"
                                        value={newRowData.sortBy}
                                        onChange={(e) => handleNewRowInputChange(e, "sortBy")}
                                    />
                                </div>
                                {/*停用日期－新增模式*/}
                                <DatePicker
                                    label=""
                                    value={editData.deactiveDate ? dayjs(editData.deactiveDate) : null}
                                    onChange={(date) => handleInputChange(date, 'deactiveDate')}
                                />
                            </li>
                        )}
                    </ul>
                </div>
            </div>

            {/*下－清單區塊*/}
            <div className="maintainContainer flex flex-row items-start justify-start mt-5">
                <div className="p-4 bg-white w-full border-[1px] border-[#D4D4D8] rounded-[12px] mr-4">
                    <div className="searchGroup flex flex-row items-center justify-between mb-4">
                        {/*進階搜尋*/}
                        <BaseInput className="w-[320px] h-10 pl-4 border-[2px] border-[#D4D4D8] rounded-[6px]"
                                   type="text"
                                   placeholder={`${t('general.advancedSearch')}`}
                                   inputMode={'search'}
                        />
                    </div>

                    {/*列表清單*/}
                    <ul className="tableList HCRMTableListDown">
                        {/*表單項目標題*/}
                        <li className="title">
                            <div>
                                {/*add new row btn*/}
                                <Button color={ButtonColorEnum.Success} variant={ButtonVariantEnum.Text}
                                        size={ButtonSizeEnum.Large} text={t('general.add')}
                                        icon={IconEnum.Add}
                                />
                            </div>
                            <div>類別代碼</div>
                            <div>類別名稱</div>
                            <div>項目代碼</div>
                            <div>項目名稱</div>
                            <div>順序</div>
                            <div>停用日期</div>
                        </li>
                        {!arrayIsEmpty(detailData) &&
                            detailData.map((item, index) => (
                                <li key={index}>
                                    <div>
                                        <Button color={ButtonColorEnum.Primary} variant={ButtonVariantEnum.Text}
                                                size={ButtonSizeEnum.Large} text={t('general.edit')}
                                                icon={IconEnum.Edit}
                                        />
                                        <Button color={ButtonColorEnum.Primary} variant={ButtonVariantEnum.Text}
                                                size={ButtonSizeEnum.Large} text={t('general.delete')}
                                                icon={IconEnum.Delete}
                                        />
                                    </div>
                                    {/*類別代碼*/}
                                    <div>{item.categories}</div>
                                    {/*類別名稱*/}
                                    <div>{item.name}</div>
                                    {/*項目代碼*/}
                                    <div>{item.itemNo}</div>
                                    {/*項目名稱*/}
                                    <div>{item.itemName}</div>
                                    {/*順序*/}
                                    <div>{item.sortBy}</div>
                                    {/*停用日期*/}
                                    <div>{item.deactiveDate}</div>
                                </li>
                            ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};
export default HomeAssessmentOperations;