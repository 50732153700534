import React, {useState} from 'react'
import CustomTable from '../../components/ReferralSystem/CustomTable'
import {Pagination} from '../../components/Pagination/Pagination'
import {ER_QUALITY_VPN_FIELDS} from '../../constants/referralSystem/erQualityVpnUploadExport'
import {queryRmisEmgquality, tranRmisEmgqualityToTxt} from '../../api/v1/Rmis'
import {
    ApiErrorStatusEnum,
    time2String,
    stringIsEmpty,
    downloadFile,
    FileExtensionsEnum,
    BlobTypeEnum,
} from 'edah_utils/dist'
import useToast from '../../hooks/useToast'
import {
    AlertTypeEnum,
    DatePicker,
    SizeEnum,
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    IconEnum
} from "edah-component/dist"
import dayjs from "dayjs"
import {t} from "i18next";

/**
 * 急診品質方案VPN上傳匯出作業
 * @return {JSX.Element}
 */
const ErQualityVpnUploadExport = () => {
    // 就醫年月
    const [encounterYearMonth, setEncounterYearMonth] = useState('')
    // 就醫年月是否有效
    const [encounterYearMonthIsValid, setEncounterYearMonthIsValid] =
        useState(true)
    // 資料列表
    const [dataList, setDataList] = useState([])
    // 分頁資料
    const [filter, setFilter] = useState({
        totalPage: 1,
        total: 0,
        currentPage: 1,
        pageSize: 10,
    })
    // toast提醒訊息
    const showToast = useToast()

    /**
     * 匯出
     * @return {void}
     */
    const exportText = () => {
        const month = encounterYearMonth ? encounterYearMonth.split('-')[1] : ''
        if (stringIsEmpty(month)) {
            return
        }
        const data = {
            //月份(only for file name)
            month,
            //需要轉換的資料
            rmisEmgualityData: dataList,
        }
        // 匯出文字檔
        tranRmisEmgqualityToTxt(data).then((res) => {
            // 成功
            if (res.err === ApiErrorStatusEnum.Success) {
                const blob = new Blob([res.data.data], {
                    type: BlobTypeEnum.PlainText,
                })
                downloadFile(blob, res.data.fileName, FileExtensionsEnum.TXT)
            } else {
                // 失敗
                showToast({
                    message: `匯出失敗: ${res.msg}`,
                    type: AlertTypeEnum.Error,
                })
            }
        })
    }

    /**
     * 分頁
     * @param page {string} 目前頁碼
     * @return {void}
     */
    const onPageOnChange = (page) => {
        const currentPage = Number(page)
        getErQualityList(currentPage, filter.pageSize)
    }

    /**
     * 每頁筆數
     * @param event {Event} 事件
     * @return {void}
     */
    const onPageSizeChange = (event) => {
        const pageSize = Number(event.target.value)
        getErQualityList(1, pageSize)
    }

    /**
     * 上一頁
     * @return {void}
     */
    const onPrevPageOnClick = () => {
        const prevPage = filter.currentPage - 1
        // 如果上一頁小於1，則顯示最後一頁，否則顯示上一頁
        const page = prevPage < 1 ? filter.totalPage : prevPage
        getErQualityList(page, filter.pageSize)
    }

    /**
     * 下一頁
     * @return {void}
     */
    const onNextPageOnClick = () => {
        const nextPage = filter.currentPage + 1
        const firstPage = 1
        // 如果下一頁大於總頁數，則顯示第一頁，否則顯示下一頁
        const page = nextPage > filter.totalPage ? firstPage : nextPage
        getErQualityList(page, filter.pageSize)
    }

    /**
     * 查詢
     * @return {void}
     */
    const handleSearch = () => {
        getErQualityList(1, filter.pageSize)
    }

    /**
     * 取得急診品質方案列表
     * @param pageNum 頁碼 
     * @param pageSize 每頁筆數
     * @return {void}
     */
    const getErQualityList = (pageNum, pageSize) => {
        const encounterYearMonthIsValid = !stringIsEmpty(encounterYearMonth)
        setEncounterYearMonthIsValid(encounterYearMonthIsValid)
        // 如果就醫年月無效，則不查詢
        if (!encounterYearMonthIsValid) {
            return
        }
        // 查詢
        queryRmisEmgquality({
            pageNum,
            pageSize,
            month: time2String(encounterYearMonth, 'YYYY-MM'),
        }).then((result) => {
            // 成功
            if (result.err === ApiErrorStatusEnum.Success) {
                const list = result.data || []
                if (list.length === 0) {
                    showToast({
                        message: '查無資料',
                        type: AlertTypeEnum.Warning,
                    })
                }
                setDataList(list)
                setFilter({
                    ...filter,
                    totalPage: result.totalPageSize,
                    total: result.totalItemSize,
                    pageNum,
                    pageSize,
                })
            } else {
                // 失敗
                showToast({message: result.msg, type: AlertTypeEnum.Warning})
            }
        })
    }

    return (
        <>
            <div className="p-2 w-full h-[calc(100vh-98px)] overflow-auto">
                <div className="flex items-center space-x-2 p-2">
                    {/* 就醫年月選取 */}
                    <DatePicker
                        required
                        value={encounterYearMonth ? dayjs(encounterYearMonth) : null}
                        views={['year', 'month']}  // 限制選擇範圍只到年和月
                        label="就醫年月"
                        onChange={(newValue) => {
                            setEncounterYearMonth(newValue ? newValue.format('YYYY-MM') : '')
                        }}
                        error={!encounterYearMonthIsValid}/>
                    {/* 查詢按鈕 */}
                    <Button
                        sx={{fontWeight: 'bold', height: '2.5rem'}}
                        color={ButtonColorEnum.Primary}
                        text={t('general.query')}
                        variant={ButtonVariantEnum.Contained}
                        size={ButtonSizeEnum.Medium}
                        onClick={handleSearch}
                    />
                    <div className="flex-grow"></div>
                    {/* Add this line to push the export button to the right */}
                    <div className="flex justify-end">
                        {/*匯出按鈕*/}
                        <Button
                            color={ButtonColorEnum.Success} variant={ButtonVariantEnum.Text}
                            size={ButtonSizeEnum.Large} icon={IconEnum.Print}
                            onClick={exportText}>
                            {t('general.export')}
                        </Button>
                    </div>
                </div>

                {/* 表格 */}
                <CustomTable
                    fields={ER_QUALITY_VPN_FIELDS}
                    dataList={dataList}
                    totalPage={filter.page}
                    total={filter.total}
                />
                {/* 分頁 */}
                <div className="flex justify-end">
                    <Pagination
                        pageSize={filter.pageSize}
                        totalSize={filter.total}
                        currentPage={filter.currentPage}
                        totalPageSize={filter.totalPage}
                        onPageOnChange={onPageOnChange}
                        onPageSizeChange={onPageSizeChange}
                        onPrevPageOnClick={onPrevPageOnClick}
                        onNextPageOnClick={onNextPageOnClick}
                    />
                </div>
            </div>
        </>
    )
}
export default ErQualityVpnUploadExport
