import React, {useState} from "react";
import {t} from "i18next";
import {BaseInput} from "../../components/Input/BaseInput";
import {arrayIsEmpty, fuzzySearchObjects} from "edah_utils/dist"
import {ReactComponent as Check} from "../../assets/images/icons/checkbox.svg";
import {
    Button,
    ButtonSizeEnum,
    ButtonColorEnum,
    ButtonVariantEnum,
    IconEnum,
    DateRangePicker
} from "edah-component/dist";
import dayjs from "dayjs";

/**
 * 居家個案已結案狀態維護
 * @return {JSX.Element}
 */
const CaseCloseStatusMaintenance = () => {
    // 搜尋文字
    const [searchText, setSearchText] = useState('')

    // 日期範圍
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    // 篩選後的數據
    const [filteredData, setFilteredData] = useState([]);

    // Fake Data
    const [dataObject, setDataObject] = useState([
        {
            familySerialNumber: '09876',       // 家字號
            medicalRecordNumber: '0801234',    // 病歷號
            caseName: '黃ＯＯ',                // 個案姓名
            gender: '女',                      // 性別
            caseManager: '廖ＯＯ',             // 收案人員
            admissionDate: '2023-09-12',       // 收案日期
            dischargeDate: '2023-09-12',       // 結案日期
            lockStatus: 'Ｙ'                   // 鎖定狀態
        }
    ]);

    /**
     * 搜尋文字改變時
     * @param e {Event} e
     * @return {void}
     */
    const handleSearchTextOnChange = (e) => setSearchText(e.target.value)

    /**
     * 取得模糊匹配後的資料陣列
     * @return {Array<Object>}
     */
    const getFilterData = (data) => fuzzySearchObjects(data, searchText)

    /**
     * 日期範圍改變時
     * @param dateRange {Array} [startDate, endDate]
     * @return {void}
     */
    const handleDateRangeChange = (dateRange) => {
        const [start, end] = dateRange;
        setStartDate(start ? start.toDate() : null);  // Convert dayjs object to JavaScript Date
        setEndDate(end ? end.toDate() : null);
    };

    /**
     * 篩選數據
     * @param data {Array} 數據數組
     * @return {Array} 篩選後的數據數組
     */
    const filterDataByDateRange = (data) => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        return data.filter(item => {
            const caseDate = new Date(item.admissionDate);
            return caseDate >= start && caseDate <= end;
        });
    };


    /**
     * 查詢按鈕點擊時
     * @return {void}
     */
    const handleQueryClick = () => {
        let filtered = [];
        filtered = filterDataByDateRange(dataObject);
        setFilteredData(fuzzySearchObjects(filtered, searchText));
    };

    return (
        <div className="w-full p-4 bg-[#FAFAFA]">
            {/*篩選清單*/}
            <div className="filterGroup flex flex-row flex-wrap items-center justify-start space-x-2 my-2">
                {/* 查詢日期起訖 */}
                <DateRangePicker
                    required
                    localeText={{start: '查詢開始日期', end: '查詢結束日期',}}
                    value={[startDate ? dayjs(startDate) : null, endDate ? dayjs(endDate) : null,]}
                    onChange={handleDateRangeChange}/>
                {/*查詢送出按鈕*/}
                <Button color={ButtonColorEnum.Primary} variant={ButtonVariantEnum.Contained}
                        size={ButtonSizeEnum.Medium} text={t('general.query')} onClick={handleQueryClick}/>
            </div>

            {/*列表清單區塊*/}
            <div className="maintainContainer flex flex-row items-start justify-start">
                <div className="p-4 bg-white w-full border-[1px] border-[#D4D4D8] rounded-[12px] mr-4">
                    <div className="searchGroup flex flex-row items-center justify-between mb-4">
                        {/*進階搜尋*/}
                        <BaseInput className="w-[320px] h-10 pl-4 border-[2px] border-[#D4D4D8] rounded-[6px]"
                                   type="text"
                                   placeholder={`${t('general.advancedSearch')}`}
                                   inputMode={'search'}
                                   value={searchText}
                                   onChange={(e) => handleSearchTextOnChange(e)}/>
                        {/* excel輸出 */}
                        <Button color={ButtonColorEnum.Success} variant={ButtonVariantEnum.Text}
                                size={ButtonSizeEnum.Large} text={t('general.export')}
                                icon={IconEnum.Export}
                        />
                    </div>

                    {/*列表清單*/}
                    <ul className="tableList CCSMTableList">
                        {/*表單項目標題*/}
                        <li className="title">
                            <div><Check/></div>
                            <div>家字號</div>
                            <div>病歷號</div>
                            <div>個案姓名</div>
                            <div>性別</div>
                            <div>收案人員</div>
                            <div>收案日期</div>
                            <div>結案日期</div>
                            <div>鎖定狀態</div>
                        </li>

                        {!arrayIsEmpty(getFilterData(filteredData)) ? getFilterData(filteredData).map((item, index) =>
                            <li key={index}>
                                {/*checkBox*/}
                                <div>
                                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5"
                                           type="checkbox"
                                           id={`r${index}`} style={{accentColor: "#38A169"}}/>
                                </div>
                                {/*家字號*/}
                                <div>{item.familySerialNumber}</div>
                                {/*病歷號*/}
                                <div>{item.medicalRecordNumber}</div>
                                {/*個案姓名*/}
                                <div>{item.caseName}</div>
                                {/*性別*/}
                                <div>{item.gender}</div>
                                {/*收案人員*/}
                                <div>{item.caseManager}</div>
                                {/*收案日期*/}
                                <div>{item.admissionDate}</div>
                                {/*結案日期*/}
                                <div>{item.dischargeDate}</div>
                                {/*鎖定狀態*/}
                                <div>{item.lockStatus}</div>
                            </li>
                        ) : null}
                    </ul>
                </div>
            </div>

        </div>
    )
}
export default CaseCloseStatusMaintenance