import {v1Api} from "../Http"
import {HttpMethodEnum} from "edah_utils/dist"

/**
 * 取得月班基礎資料
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const regsMonthClinicBaseData = (params) => v1Api.request({
    url: '/regs/regsclinic/queryRegclinicBaseData',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 依科別查詢月班表
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const regsQueryMonthClinicByDivNo = (params) => v1Api.request({
    url: '/regs/regsclinic/queryRegsclinicByDivNo',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 依醫師查詢月班表
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const regsQueryMonthClinicByDoctorNo = (params) => v1Api.request({
    url: '/regs/regsclinic/queryRegsclinicByDoctorNo',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 新增月班單一診間班表
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const regsAddMonthClinic = (data) => v1Api.request({
    url: '/regs/regsclinic/addRegsclinic',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 更新月班單一診間班表
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const regsEditMonthClinic = (data) => v1Api.request({
    url: '/regs/regsclinic/editRegsclinic',
    method: HttpMethodEnum.PATCH,
    data
})

/**
 * 查詢當週班表(日期必填,其他選填)
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const regsQueryClinicOfTheWeek = (params) => v1Api.request({
    url: '/regs/regsclinic/queryRegsClinicOfTheWeek',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 診間狀況
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const queryRegsClinicStatus = (params) => v1Api.request({
    url: '/regs/regsclinic/queryRegsClinicStatus',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 診間狀況(依時間)
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const queryRegsClinicStatusByTime = (params) => v1Api.request({
    url: '/regs/regsclinic/queryRegsClinicStatusByTime',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 依時間起迄、時段、醫師、科別查詢班表
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const regsQueryByDateRangeApnDocDiv = (params) => v1Api.request({
    url: '/regs/regsclinic/queryByDateRangeApnDocDiv',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 批次更新班表
 * @param data data
 * @returns {AxiosPromise}
 */
const regsEditClinicList = (data) => v1Api.request({
    url: '/regs/regsclinic/editRegsClinicList',
    method: HttpMethodEnum.PATCH,
    data
})

/**
 * 診間班表查詢
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const regsQueryClinic = (params) => v1Api.request({
    url: '/regs/regsclinic/queryClinic',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 存檔前檢查同時段(代班)醫師是否有其他診
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const regsCheckForClinicDocOrLocumDuplicate = (params) => v1Api.request({
    url: '/regs/regsclinic/checkForClinicDocOrLocumDuplicate',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 編輯月班前確認報備支援
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const regsCheckBeforeSaveClinicByRsis = (data) => v1Api.request({
    url: '/regs/regsclinic/checkBeforeSaveClinicByRsis',
    method: HttpMethodEnum.POST,
    data
})

export {
    regsMonthClinicBaseData,
    regsQueryMonthClinicByDivNo,
    regsQueryMonthClinicByDoctorNo,
    regsAddMonthClinic,
    regsEditMonthClinic,
    regsQueryClinicOfTheWeek,
    queryRegsClinicStatus,
    queryRegsClinicStatusByTime,
    regsQueryByDateRangeApnDocDiv,
    regsEditClinicList,
    regsQueryClinic,
    regsCheckForClinicDocOrLocumDuplicate,
    regsCheckBeforeSaveClinicByRsis
}
