import React, {useMemo, useState} from 'react';
import {ApiErrorStatusEnum, arrayIsEmpty, objectIsEmpty, stringIsEmpty, ToastTypeEnum} from "edah_utils/dist";
import {BaseInput} from "../../components/Input/BaseInput";
import {t} from "i18next"
import {regsNotypeDeleteNotype, regsNotypeQueryByNoType, regsNotypeUpdateNotype} from '../../api/v1/RegsNotype';
import useToast from '../../hooks/useToast';
import SavePromptPopup from '../../components/Regs/QueueNumberManagement/PromptPopup';
import {DeletePopup} from '../../components/Popup/DeletePopup';
import {
    AlertTypeEnum,
    Button,
    ButtonSizeEnum,
    ButtonVariantEnum,
    ColorEnum,
    Divider,
    IconEnum,
    RadioGroup,
    Select,
    SizeEnum,
    TextField
} from 'edah-component/dist'

/**
 * 給號方式設定頁面組件
 * @return {JSX.Element}
 */
const NumberAssignmentPage = () => {
    //號碼模式種類
    const numberType = [
        {justnameNo: "single", justnameName: "單號"},
        {justnameNo: "double", justnameName: "雙號"},
        {justnameNo: "range", justnameName: "號碼起迄"},
    ]
    // 輸入框數據顯示狀態
    const [displayInputData, setDisplayInputData] = useState({
        text: "",
    })

    // 查詢輸入數據狀態
    const [queryInputData, setQueryInputData] = useState({
        text: "",
    })
    // 修改 tableData 的初始狀態
    const [tableData, setTableData] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    // 選擇號碼模式
    const [choiceNumberType, setChoiceNumberType] = useState(numberType[0].justnameNo)
    // 開始號碼
    const [startNumber, setStartNumber] = useState(1)
    // 結束號碼
    const [endNumber, setEndNumber] = useState(500)
    // 掛號方式
    const [appointmentType, setAppointmentType] = useState('-')
    // 顯示儲存提示彈出框
    const [showSavePromptPopup, setShowSavePromptPopup] = useState(false)
    // 選擇的notype(未來)
    const [selectNoTypeFeature, setSelectNoTypeFeature] = useState('')
    //控制刪除彈窗的顯示狀態
    const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false)
    //Toast Message Hooks
    const showToast = useToast()

    // 生成 500 筆 預設給號資料
    const generateDefaultNumberData = () => {
        //接預設為 現場
        return Array(500).fill('A')
    }

    /**
     * 處理輸入變更
     * @param {Event} e - 輸入事件
     */
    const handleInputChange = (e) => {
        setDisplayInputData({...displayInputData, [e.target.name]: e.target.value});
    };

    /**
     * 查詢按鈕點擊事件
     * @return {void}
     */
    const handleSearchOnClick = () => {
        // 這裡實現查詢邏輯
        setQueryInputData(displayInputData)
        queryNotypeList(displayInputData)
    };

    /**
     * 查詢給號方式清單
     * @param {Object} searchInput 查詢輸入的資料
     */
    const queryNotypeList = (searchInput) => {
        regsNotypeQueryByNoType({
            noType: searchInput.text
        }).then((res => {
            if (res.err === ApiErrorStatusEnum.Success) {
                //查詢成功
                if (!arrayIsEmpty(res.data)) {
                    const data = res.data.map((item, index) => {
                        // 將 content 字串轉為陣列
                        let contentArray = item.content.split('')
                        if (contentArray.length < 500) {
                            // 如果 content 的長度小於 500，則填充到 500 個元素
                            contentArray = contentArray.concat(Array(500 - contentArray.length).fill(''));
                        }
                        return {
                            ...item,
                            content: contentArray,
                            //新增兩個property
                            key: index,
                            new: false  //新增項目
                        }
                    })

                    setTableData(data)
                    //取消編輯模式
                    setIsEditing(false)
                    //選取第一個
                    setSelectedItem(JSON.parse(JSON.stringify(data[0])))
                } else {
                    setTableData(null)
                    //取消編輯模式
                    setIsEditing(false)
                    setSelectedItem(null)
                }
            } else {
                const msg = `查詢失敗: ${res.msg}`
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        }))
    }

    /**
     * 添加新行
     * @return {void}
     */
    const addRow = () => {
        const newKey = arrayIsEmpty(tableData) ? 0 : tableData.length
        const newRow = {
            key: newKey,
            //給號代號
            noType: '',
            //給號名稱
            typeName: '',
            //給號代碼內容
            content: generateDefaultNumberData(),
            new: true   //新增項目
        };
        //開啟編輯狀態
        setIsEditing(true)
        setTableData([...tableData, newRow])
        setSelectedItem(newRow);  // 自動選中新增的行
    };

    /**
     * 複製選中的行
     * @return {void}
     */
    const copyRow = () => {
        if (selectedItem) {
            const newKey = arrayIsEmpty(tableData) ? 0 : tableData.length
            const newRow = {
                ...selectedItem,
                key: newKey,
                //給號代號
                noType: '',
                //給號名稱
                typeName: '',
                //給號代碼內容
                content: selectedItem.content.map(row => row),
                //是否為新增
                new: true
            };
            //開啟編輯狀態
            setIsEditing(true)
            setTableData([...tableData, newRow])
            setSelectedItem(newRow);  // 自動選中新複製的行
        }
    };

    /**
     * 刪除選中的行
     * @return {void}
     */
    const deleteRow = () => {
        setIsDeletePopupOpen(true)
    }

    /**
     * 編輯狀態
     * @type {[bool, function]}
     */
    const [isEditing, setIsEditing] = useState(null);

    /**
     * 處理 radio 按鈕變更
     */
    const handleRadioChange = (elementIndex, value) => {
        if (objectIsEmpty(selectedItem))
            return

        const updatedSelectData = {
            ...selectedItem,
            content: selectedItem.content.map((row, rowIndex) =>
                rowIndex === elementIndex ? value : row
            )
        }
        setSelectedItem(updatedSelectData)
    };

    /**
     * 選擇號碼模式變更
     * @param {Event} e
     */
    const handleSelectNumberTypeOnChange = (e) => {
        setChoiceNumberType(e.target.value)
    }

    /**
     * 掛號方式變更
     * @param {Event} e
     */
    const handleAppointmentTypeOnChange = (value) => {
        setAppointmentType(value)
    }

    /**
     * 給號按鈕點擊事件
     * @returns
     */
    const handleGiveNumberOnClick = () => {
        if (appointmentType === 'A' || appointmentType === 'R' || appointmentType === 'F' || appointmentType === 'X') {
            let updateContent = '';
            const startNum = parseInt(startNumber, 10);
            const endNum = parseInt(endNumber, 10);

            if (choiceNumberType === "single") {
                //單號
                updateContent = selectedItem.content.map((item, index) =>
                    (index + 1) % 2 !== 0 ? appointmentType : item
                );
            } else if (choiceNumberType === "double") {
                //雙號
                updateContent = selectedItem.content.map((item, index) =>
                    (index + 1) % 2 === 0 ? appointmentType : item
                );
            } else {
                //區間
                if (isNaN(startNum) || startNum <= 0 || startNum > 500) {
                    showToast({message: "開始號碼錯誤", type: AlertTypeEnum.Warning});
                    return;
                }

                if (isNaN(endNum) || endNum <= 0 || endNum > 500) {
                    showToast({message: "結束號碼錯誤", type: AlertTypeEnum.Warning});
                    return;
                }

                if (startNum > endNum) {
                    showToast({message: "開始號碼不可大於結束號碼", type: AlertTypeEnum.Warning});
                    return;
                }

                updateContent = selectedItem.content.map((item, index) =>
                    (index + 1) >= startNum && (index + 1) <= endNum ? appointmentType : item
                );
            }

            setSelectedItem({
                ...selectedItem,
                content: updateContent
            })
        }
    }

    /**
     * 開始號碼變更事件
     * @param {Event} e
     */
    const handleStartNumberOnChange = (e) => {
        const value = e.target.value;
        const intValue = parseInt(value, 10);
        setStartNumber(isNaN(intValue) ? '' : intValue);
    };

    /**
     * 結束號碼變更事件
     * @param {Event} e
     */
    const handleEndNumberOnChange = (e) => {
        const value = e.target.value;
        const intValue = parseInt(value, 10);
        setEndNumber(isNaN(intValue) ? '' : intValue);
    };

    const detectSelectItemHaveChange = () => {
        if (objectIsEmpty(selectedItem)) {
            // 沒有select
            return false
        }

        if (selectedItem.new !== null && selectedItem.new === true) {
            // 新增狀態
            return true
        }

        const fundedData = tableData.find(item => selectedItem.key === item.key)
        if (objectIsEmpty(fundedData)) {
            return true
        }

        if (fundedData.typeName !== selectedItem.typeName) {
            // 名稱不一樣
            return true
        }

        //比對content是否一樣
        const isDifferent = !fundedData.content.every((value, index) => value === selectedItem.content[index])
        return isDifferent
    }

    /**
     * 儲存按鈕點擊事件
     */
    const handleSaveBtnOnClick = () => {
        let needSave = detectSelectItemHaveChange()

        if (needSave) {
            saveNotypeAndSelect(selectedItem.noType)
        } else {
            setIsEditing(false)
        }
    }

    /**
     * 儲存noType並選擇
     * @param {String} featureSelectNoType 儲存後要選擇得notype項目
     */
    const saveNotypeAndSelect = (featureSelectNoType) => {

        // 檢核代號、方式名稱
        if (stringIsEmpty(selectedItem.noType)) {
            showToast({message: '新增儲存失敗: 代號不能為空', type: AlertTypeEnum.Error})
            return
        }
        if (stringIsEmpty(selectedItem.typeName)) {
            showToast({message: '新增儲存失敗: 方式名稱不能為空', type: AlertTypeEnum.Error})
            return
        }

        if (selectedItem.new !== null && selectedItem.new === true) {
            const fundedData = tableData.find(item => (item.new === null || item.new === false) && item.noType === selectedItem.noType)
            if (!objectIsEmpty(fundedData)) {
                showToast({message: '新增儲存失敗: 已有相同代號', type: AlertTypeEnum.Error})
                return
            }
        }

        regsNotypeUpdateNotype({
            //版本鎖定
            lockVersion: (selectedItem.lockVersion === undefined || selectedItem.lockVersion === null) ? null : selectedItem.lockVersion,
            //給號代碼
            noType: selectedItem.noType,
            //給號代碼名稱
            typeName: selectedItem.typeName,
            //給號代碼內容
            content: selectedItem.content.join('')
        }).then((res => {
            if (res.err === ApiErrorStatusEnum.Success) {
                //儲存成功
                regsNotypeQueryByNoType({
                    noType: queryInputData.text
                }).then((res => {
                    if (res.err === ApiErrorStatusEnum.Success) {
                        //查詢成功
                        if (!arrayIsEmpty(res.data)) {
                            const data = res.data.map((item, index) => {
                                // 將 content 字串轉為陣列
                                let contentArray = item.content.split('')
                                if (contentArray.length < 500) {
                                    // 如果 content 的長度小於 500，則填充到 500 個元素
                                    contentArray = contentArray.concat(Array(500 - contentArray.length).fill(''));
                                }
                                return {
                                    ...item,
                                    content: contentArray,
                                    //新增的property
                                    key: index,
                                    new: false  //新增項目
                                }
                            })

                            setTableData(data)
                            const fundedData = data.find(item => item.noType === featureSelectNoType)

                            //關閉編輯狀態
                            setIsEditing(false)
                            setSelectedItem(fundedData)
                            setSelectNoTypeFeature('')
                        } else {
                            setTableData(null)
                            setSelectedItem(null)
                            setSelectNoTypeFeature('')
                        }
                    } else {
                        const msg = `查詢失敗: ${res.msg}`
                        showToast({message: msg, type: ToastTypeEnum.Error})
                    }
                }))
            } else {
                const msg = `儲存失敗: ${res.msg}`
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        }))
    }

    /**
     * 給號方式項目點選事件
     * @param {Object} item
     */
    const handleSelectGiveNumberItem = (item) => {
        if (!objectIsEmpty(item) && !objectIsEmpty(selectedItem) && item.key === selectedItem.key) {
            return
        }

        if (detectSelectItemHaveChange()) {
            // 選擇的資料有變動
            // 彈出是否儲存詢問
            setShowSavePromptPopup(true)
            setSelectNoTypeFeature(item.noType)
            return
        }

        //關閉編輯狀態
        setIsEditing(false)

        if (!objectIsEmpty(selectedItem)) {
            //清除tableData內新增尚未儲存的data
            setTableData(tableData.filter(item => item.new !== true))
        }

        const obj = JSON.parse(JSON.stringify(item))

        setSelectedItem(obj)
    }

    /**
     * 給號方式項目雙擊事件
     */
    const handleNotypeRowDoubleClick = () => {
        //開啟編輯狀態
        setIsEditing(true)
    }

    /**
     * 編輯給號代號輸入框值變更事件
     * @param {Event} e
     */
    const handleInputNotypeIdOnChange = (e) => {
        setSelectedItem({
            ...selectedItem,
            noType: e.target.value
        })
    }

    /**
     * 編輯給號名稱輸入框值變更事件
     * @param {Event} e
     */
    const handleInputNotypeNameOnChange = (e) => {
        setSelectedItem({
            ...selectedItem,
            typeName: e.target.value
        })
    }

    /**
     * 關閉儲存提示彈出框事件
     */
    const handleCloseSavePromptPopup = () => {
        //移除tableData中新增的給號類別
        let updateTableData = tableData.filter(item => (item.new === null || item.new !== true))
        if (!stringIsEmpty(selectNoTypeFeature)) {
            // 選擇notype項目
            const fundedData = updateTableData.find(item => item.noType === selectNoTypeFeature)
            if (!objectIsEmpty(fundedData)) {
                setSelectedItem(JSON.parse(JSON.stringify(fundedData)))
            }
        }
        //關閉編輯狀態
        setIsEditing(false)
        setTableData(updateTableData)
        setShowSavePromptPopup(false)
    }

    /**
     * 儲存提示框點擊儲存按鈕事件
     */
    const handleSavePromptPopupSaveBtnOnClick = () => {
        saveNotypeAndSelect(selectNoTypeFeature)

        setShowSavePromptPopup(false)
    }

    /**
     * 確認刪除
     */
    const confirmDelete = () => {
        if (!arrayIsEmpty(tableData) && !objectIsEmpty(selectedItem)) {
            if (selectedItem.new !== null && selectedItem.new === true) {
                //新增中時
                //直接重新取得給號資料
                queryNotypeList(queryInputData)

                setIsDeletePopupOpen(false)
            } else {
                regsNotypeDeleteNotype({
                    noType: selectedItem.noType
                }).then((res => {
                    if (res.err === ApiErrorStatusEnum.Success) {

                        //刪除成功
                        queryNotypeList(queryInputData)

                        setIsDeletePopupOpen(false)
                    } else {
                        setIsDeletePopupOpen(false)
                        const msg = `刪除失敗: ${res.msg}`
                        showToast({message: msg, type: ToastTypeEnum.Error})
                    }
                }))
            }
        }
    }

    /**
     * 取消刪除
     */
    const cancelDelete = () => {
        setIsDeletePopupOpen(false)
    }

    useMemo(() => {
        queryNotypeList(queryInputData)
    }, [])


    // 計算需要顯示的列數
    const columnCount = 5; // 固定為 5 列，每列 100 筆數據

    return (
        <div className="p-4 bg-gray-100 w-full">
            {/* 過濾器部分 */}
            <div className="mb-4 flex items-center justify-between overflow-x-auto pb-4">
                <div className="flex flex-row items-center flex-shrink-0 text-left">
                    <TextField
                        label="給號方式"
                        sx={{mr: 1}}
                        size={SizeEnum.Medium}
                        name="text"
                        value={displayInputData.text}
                        inputMode="search"
                        onChange={handleInputChange}
                    />
                    <Button
                        sx={{marginRight: "1rem"}}
                        color={ColorEnum.Primary} variant={ButtonVariantEnum.Contained}
                        size={ButtonSizeEnum.Medium}
                        onClick={handleSearchOnClick}>
                        {t('general.query')}
                    </Button>
                    <Divider orientation="vertical" variant='middle' sx={{mx: 2}}/>
                    <div className="flex items-center space-x-2 mr-3">
                        <RadioGroup
                            row
                            labelProps={{text: "給號規則"}}
                            value={choiceNumberType}
                            optionProps={{
                                options: numberType.map(item => ({
                                    label: item.justnameName, // 顯示在 radio 的標籤
                                    value: item.justnameNo    // 對應的值
                                }))
                            }}
                            size={SizeEnum.Medium}
                            onChange={handleSelectNumberTypeOnChange}/>
                    </div>
                    <TextField
                        inputWidth={SizeEnum.Small}
                        type="number"
                        placeholder="1"
                        value={startNumber}
                        disable={choiceNumberType !== "range"}
                        onChange={handleStartNumberOnChange}
                    />
                    <p>~</p>
                    <TextField
                        inputWidth={SizeEnum.Small}
                        type="number"
                        placeholder="500"
                        value={endNumber}
                        disable={choiceNumberType !== "range"}
                        onChange={handleEndNumberOnChange}
                    />
                    {/* 給號方式 */}
                    <Select
                        data={{
                            label: "給號方式",
                            options: [
                                {value: '-', label: `-`},
                                {value: 'A', label: `${t('Regs.NumberAssignmentPageEnum.A')}`},
                                {value: 'R', label: `${t('Regs.NumberAssignmentPageEnum.R')}`},
                                {value: 'F', label: `${t('Regs.NumberAssignmentPageEnum.F')}`},
                                {value: 'X', label: `${t('Regs.NumberAssignmentPageEnum.X')}`},
                            ]
                        }}
                        value={appointmentType}
                        onChange={handleAppointmentTypeOnChange}
                        displayEmpty={true}
                        notched ={true}
                    />
                    <Button
                        sx={{marginRight: '0.5rem'}}
                        color={ColorEnum.Secondary} variant={ButtonVariantEnum.Outlined}
                        size={ButtonSizeEnum.Medium} disabled={objectIsEmpty(selectedItem)}
                        onClick={handleGiveNumberOnClick}>
                        {'給號'}
                    </Button>
                </div>
                <Button
                    sx={{marginRight: "0.5rem"}}
                    color={ColorEnum.Primary} variant={ButtonVariantEnum.Contained}
                    size={ButtonSizeEnum.Medium} disabled={objectIsEmpty(selectedItem)}
                    onClick={handleSaveBtnOnClick}>
                    {t('general.saveFile')}
                </Button>
            </div>

            {/* 表格部分 */}
            <div className="data-table">
                {/* 表格操作按鈕 */}
                <div className="table-actions p-2 border-b flex space-x-2">
                    <Button
                        color={ColorEnum.Success} variant={ButtonVariantEnum.Text}
                        size={ButtonSizeEnum.Large} icon={IconEnum.Add}
                        disabled={isEditing}
                        onClick={addRow}>
                        {t('general.add')}
                    </Button>
                    <Button
                        sx={{marginRight: '0.5rem'}}
                        color={ColorEnum.Secondary} variant={ButtonVariantEnum.Outlined}
                        size={ButtonSizeEnum.Medium}
                        disabled={objectIsEmpty(selectedItem) || isEditing}
                        onClick={copyRow}>
                        {t('general.copy')}
                    </Button>
                    <Button sx={{marginRight: '0.5rem'}}
                            color={ColorEnum.Secondary} variant={ButtonVariantEnum.Outlined}
                            size={ButtonSizeEnum.Medium}
                            disabled={objectIsEmpty(selectedItem)}
                            onClick={deleteRow}>
                        {t('general.delete')}
                    </Button>
                </div>
                {/* 表格內容 */}
                <div
                    className="flex flex-row items-start justify-start bg-white rounded shadow p-2 relative">
                    <ul className="tableList QNMTitle mr-4 max-h-[calc(100vh-270px)]">
                        {/* 表頭 */}
                        <li className="title flex flex-row items-center justify-center sticky top-0">
                            <div>代號</div>
                            <div>方式名稱</div>
                        </li>
                        {/* 數據行 */}
                        {!arrayIsEmpty(tableData) && tableData.map(row => (
                            <li
                                key={row.key}
                                className={`flex flex-row items-center justify-center cursor-pointer ${(!objectIsEmpty(selectedItem) && selectedItem.key === row.key) ? 'selected' : ''}`}
                                onClick={() => handleSelectGiveNumberItem(row)}
                                onDoubleClick={handleNotypeRowDoubleClick}
                            >
                                {
                                    isEditing && !objectIsEmpty(selectedItem) && selectedItem.key === row.key && selectedItem.new === true ?
                                        <BaseInput
                                            className={`w-[50px] h-10 pl-2 border-[1px] border-[#D4D4D8] rounded-[6px]`}
                                            type="number"
                                            value={selectedItem.noType}
                                            min={1}
                                            onChange={(e) => handleInputNotypeIdOnChange(e)}
                                            autoFocus
                                        /> :
                                        <div>
                                            {row.noType}
                                        </div>
                                }

                                {isEditing && !objectIsEmpty(selectedItem) && selectedItem.key === row.key ? (
                                    <BaseInput
                                        className={`w-[180px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]`}
                                        type="text"
                                        value={selectedItem.typeName}
                                        onChange={(e) => handleInputNotypeNameOnChange(e)}
                                        autoFocus
                                    />
                                ) : (
                                    <div
                                        className={`text-ellipsis ... ${(!objectIsEmpty(selectedItem) && selectedItem.key === row.key) ? 'selected' : ''}`}>
                                        <span>
                                            {row.typeName}
                                        </span>
                                    </div>
                                )}
                            </li>
                        ))}
                    </ul>
                    <div className="flex flex-row items-start justify-start max-h-[calc(100vh-270px)] overflow-auto">
                        {[...Array(columnCount)].map((_, colIndex) => (
                            <div className="relative" key={colIndex}>
                                <ul className="tableList QNMTableLiat mr-0.5 sticky top-0">
                                    {/* 表頭 */}
                                    <li className="title flex flex-row items-center justify-center">
                                        <div className="flex justify-center space-x-1">號碼</div>
                                        <div
                                            className="flex justify-center space-x-1">{t('Regs.NumberAssignmentPageEnum.A')}</div>
                                        <div
                                            className="flex justify-center space-x-1">{t('Regs.NumberAssignmentPageEnum.R')}</div>
                                        <div
                                            className="flex justify-center space-x-1">{t('Regs.NumberAssignmentPageEnum.F')}</div>
                                        <div
                                            className="flex justify-center space-x-1">{t("Regs.NumberAssignmentPageEnum.X")}</div>
                                    </li>
                                </ul>
                                {/* 表格行 */}
                                <ul className="tableList QNMTableLiat mr-0.5">
                                    {!objectIsEmpty(selectedItem) && selectedItem.content.slice(colIndex * 100, (colIndex + 1) * 100).map((row, index) => {
                                        const elementIndex = colIndex * 100 + (index)
                                        return (
                                            <li key={elementIndex}
                                                className="flex flex-row items-center justify-center">
                                                <div
                                                    className="flex justify-center space-x-0.5">{elementIndex + 1}</div>
                                                {/* 單選按鈕組 */}
                                                <div className="flex justify-center space-x-0.5">
                                                    {/*現場*/}
                                                    <input
                                                        type="radio"
                                                        name={`option-${selectedItem.key}-${elementIndex}`}
                                                        value="A"
                                                        checked={row === 'A'}
                                                        onChange={() => handleRadioChange(elementIndex, 'A')}
                                                        className="form-radio w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0]"
                                                        style={{accentColor: "#2B6CB0"}}
                                                    />
                                                </div>
                                                <div className="flex justify-center space-x-0.5">
                                                    {/*預約*/}
                                                    <input
                                                        type="radio"
                                                        name={`option-${selectedItem.key}-${elementIndex}`}
                                                        value="R"
                                                        checked={row === 'R'}
                                                        onChange={() => handleRadioChange(elementIndex, 'R')}
                                                        className="form-radio w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0]"
                                                        style={{accentColor: "#2B6CB0"}}
                                                    />
                                                </div>
                                                <div className="flex justify-center space-x-0.5">
                                                    {/*科初診*/}
                                                    <input
                                                        type="radio"
                                                        name={`option-${selectedItem.key}-${elementIndex}`}
                                                        value="F"
                                                        checked={row === 'F'}
                                                        onChange={() => handleRadioChange(elementIndex, 'F')}
                                                        className="form-radio w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0]"
                                                        style={{accentColor: "#2B6CB0"}}
                                                    />
                                                </div>
                                                <div className="flex justify-center space-x-0.5">
                                                    {/*不給號*/}
                                                    <input
                                                        type="radio"
                                                        name={`option-${selectedItem.key}-${elementIndex}`}
                                                        value="X"
                                                        checked={row === 'X'}
                                                        onChange={() => handleRadioChange(elementIndex, 'X')}
                                                        className="form-radio w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0]"
                                                        style={{accentColor: "#2B6CB0"}}
                                                    />
                                                </div>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <SavePromptPopup
                show={showSavePromptPopup}
                closePopupButtonOnClick={handleCloseSavePromptPopup}
                handleSaveOnClick={handleSavePromptPopupSaveBtnOnClick}/>
            {/*刪除popup*/}
            <DeletePopup
                content={t('sloganManagement.deleteConfirmation', '確定是否刪除？')}
                show={isDeletePopupOpen}
                onYesButtonClick={confirmDelete}
                onNoButtonClick={cancelDelete}
                onClosePopupButtonClick={cancelDelete}
            />
        </div>
    );
};

export default NumberAssignmentPage;
