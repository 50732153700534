//Import css &icons
import '../../components/TableList/tableList.scss'
import '../../components/ICCard/maintain.scss'

//Import function
import React, {useState} from "react"
import {t} from "i18next"
import {BaseInput} from "../../components/Input/BaseInput"
import {
    ApiErrorStatusEnum,
    arrayIsEmpty,
    fuzzySearchObjects,
    InputTypeEnum,
    time2String,
} from "edah_utils/dist"
import {iccmPaticWaitWriteData} from "../../api/v1/ICCM"
import UseToast from "../../hooks/useToast"
import {Button, ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum, DateRangePicker} from "edah-component/dist/index";
import dayjs from "dayjs";
import {AlertTypeEnum} from "edah-component/dist";

/**
 * 待寫卡清單
 * @return {JSX.Element}
 */
const TodoListPage = ({}) => {
    /**
     * Show Toast
     */
    const showToast = UseToast()

    //就醫開始日期
    const [startEncounterDate, setStartEncounterDate] = useState("")
    //就醫結束日期
    const [endEncounterDate, setEndEncounterDate] = useState("")
    //搜尋文字
    const [searchText, setSearchText] = useState("")
    //待寫卡清單
    const [data, setData] = useState([])


    /**
     * 日期範圍改變時
     * @param dateRange {Array} [startDate, endDate]
     * @return {void}
     */
    const handleDateRangeChange = (dateRange) => {
        const [start, end] = dateRange
        setStartEncounterDate(start ? dayjs(start).format('YYYY-MM-DD') : null)
        setEndEncounterDate(end ? dayjs(end).format('YYYY-MM-DD') : null)
    }

    /**
     * 處理搜尋文字改變
     * @param e {Event} 事件
     * @return {void}
     */
    const handleSearchTextOnChange = (e) => setSearchText(e.target.value)

    /**
     * 處理查詢按鈕點擊
     * @return {void}
     */
    const handleQueryOnClick = () => getWaitWriteData()

    /**
     * 取得搜尋文字是否禁用
     * @return {boolean}
     */
    const getSearchTextIsDisable = () => arrayIsEmpty(data)

    /**
     * 取得過濾資料
     * return {Array<Object>}
     */
    const getFilterData = () => fuzzySearchObjects(data, searchText)

    /**
     *　取得待寫卡清單
     * @return {void}
     */
    const getWaitWriteData = () => {
        if (!dayjs(startEncounterDate).isValid() || !dayjs(endEncounterDate).isValid()) {
            showToast({message: '就醫日期為必填', type: AlertTypeEnum.Error})
            return
        }
        iccmPaticWaitWriteData({
            // 就醫開始日期
            startEncounterDate: startEncounterDate ? `${startEncounterDate} 00:00:00` : null,
            // 就醫結束日期
            endEncounterDate: endEncounterDate ? `${endEncounterDate} 23:59:59` : null,
        }).then(res => {
            //　狀態 / 資料 / 訊息
            const {err, data, msg} = res
            // 取得待寫卡清單成功
            if (err === ApiErrorStatusEnum.Success) {
                // 設定待寫卡清單
                setData(data)
            } else {
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    return (
        <div className="w-full p-4 bg-[#FAFAFA]">
            <div className="filterGroup flex flex-row flex-wrap items-center justify-start space-x-2 my-2">
                {/* 就醫日期起訖 */}
                <DateRangePicker
                    localeText={{start: '就醫開始日期', end: '就醫結束日期'}}
                    value={[startEncounterDate ? dayjs(startEncounterDate) : null, endEncounterDate ? dayjs(endEncounterDate) : null]}
                    required
                    onChange={handleDateRangeChange}
                />
                {/*查詢按鈕*/}
                <Button color={ButtonColorEnum.Primary} size={ButtonSizeEnum.Medium}
                        variant={ButtonVariantEnum.Contained} text={t('general.query')} onClick={handleQueryOnClick}/>
            </div>
            <div className="todoList py-2.5 px-4 bg-white border-[1px] border-[#D4D4D8] rounded-[12px]">
                <div className="title&search flex flex-row items-center justify-start mb-2.5">
                    {/*待寫卡清單*/}
                    <h3 className="flex items-center justify-start text-[20px] mr-4 pl-2 text-[#2B6CB0] border-l-8 border-[#2B6CB0]">
                        <span>待寫卡清單</span>
                    </h3>
                    {/*進階搜尋*/}
                    <BaseInput className="w-[320px] h-10 pl-4 border-[2px] border-[#D4D4D8] rounded-[6px]"
                               type={InputTypeEnum.Text} value={searchText} placeholder={t('general.advancedSearch')}
                               inputMode={InputTypeEnum.Search} disable={getSearchTextIsDisable()}
                               onChange={handleSearchTextOnChange}/>
                </div>
                <ul className="tableList icTodoList">
                    <li className="title">
                        <div>就醫號</div>
                        <div>就醫日期</div>
                        <div>門/住</div>
                        <div>就醫類別</div>
                        <div>{t('general.patientNo')}</div>
                        <div>姓名</div>
                        <div>身分證號</div>
                        <div>序號</div>
                        <div>新生兒註記</div>
                        <div>科別</div>
                        <div>醫師</div>
                        <div>診斷碼</div>
                        <div>寫入</div>
                        <div>狀態</div>
                    </li>
                    {
                        !arrayIsEmpty(data) && getFilterData().map((item, index) =>
                            <li key={index}>
                                {/*就醫號*/}
                                <div>{item.encounterId}</div>
                                {/*就醫日期*/}
                                <div>{time2String(item.encDatetime, 'YYYY-MM-DD')}</div>
                                {/*門/住*/}
                                <div>{t(`ICCard.InpOpdEnum.${item.inpOpd}`)}</div>
                                {/*就醫類別*/}
                                <div>{item.icEncType}</div>
                                {/*病歷號*/}
                                <div>{item.patientId}</div>
                                {/*姓名*/}
                                <div></div>
                                {/*身分證號*/}
                                <div>{item.idNo}</div>
                                {/*序號*/}
                                <div>{item.encSeqNo}</div>
                                {/*新生兒註記*/}
                                <div>{item.childBornEncNote}</div>
                                {/*科別*/}
                                <div></div>
                                {/*醫師*/}
                                <div>{item.doctorIdNo}</div>
                                {/*診斷碼*/}
                                <div></div>
                                {/*寫入按鈕*/}
                                <div>
                                    <button
                                        className="flex items-center justify-center h-10 px-2 mr-2 border-[2px] bg-[#2B6CB0] text-white border-[#2B6CB0] rounded-[6px]">
                                        寫入
                                    </button>
                                    (IC卡寫卡)
                                </div>
                                {/*狀態*/}
                                <div></div>
                            </li>
                        )
                    }
                </ul>
            </div>
        </div>
    )
}
export default TodoListPage
