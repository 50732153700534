import {createSlice} from "@reduxjs/toolkit"

/**
 * Login Page Slice
 */
export const LoadingPageSlice = createSlice({
    name: 'loadingPage',
    initialState: false,
    reducers: {
        /**
         * 顯示
         * @param state {boolean}
         * @return {boolean}
         */
        showLoadingPage(state) {
            return true
        },
        /**
         * 隱藏
         * @param state {boolean}
         * @return {boolean}
         */
        hideLoadingPage(state) {
            return false
        },
    }
})
export const {showLoadingPage, hideLoadingPage} = LoadingPageSlice.actions
export default LoadingPageSlice.reducer

