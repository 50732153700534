import React, {useEffect, useState} from "react"
import {Dialog, DialogSizeEnums, DialogVariant} from "edah-component/dist"

/**
 * 儲存提醒彈窗
 * @param {function} closePopupButtonOnClick - 控制彈窗關閉
 * @param {function} handleSaveOnClick - 儲存事件
 * @return {JSX.Element}
 */
const SavePromptPopup = ({closePopupButtonOnClick, handleSaveOnClick, show = false}) => {
    /**
     * 是否顯示彈窗
     */
    const [showPopup, setShowPopup] = useState(show)

    /**
     * 監聽是否顯示彈窗Pros變化
     */
    useEffect(() => {
        // 設定是否顯示彈窗狀態
        setShowPopup(show)
    }, [show])

    return (
        // showPopup ?
        <Dialog
            open={showPopup}
            title={'提醒'}
            content={'資料異動是否存擋?'}
            variant={DialogVariant.DELETE}
            paperStyleProps={{width: DialogSizeEnums.SM}}
            onClose={closePopupButtonOnClick}
            onDelete={handleSaveOnClick}
            muiDialogContentProps={{sx: {padding: '4rem 1.5rem 4rem 1.5rem'}}}
        />
    )

    // <BasePopup
    //     title="提醒"
    //     closePopupButtonOnClick={closePopupButtonOnClick}
    //     width="559px"
    //     content={
    //         <div>
    //             <p className="h-[110px] px-6 py-9 text-[18px] text-left border-b-[1px] border-[rgba(0,0,0,0.15)]">資料異動是否存檔？</p>
    //             <div className="h-[60px] flex flex-row items-center justify-end">
    //                 <Button sx={{marginRight: '1rem'}}
    //                         color={ColorEnum.Primary} variant={ButtonVariantEnum.Contained}
    //                         size={ButtonSizeEnum.Medium}
    //                         onClick={() => handleSaveOnClick()}>
    //                     {t('general.yes')}
    //                 </Button>
    //                 <Button sx={{marginRight: '1rem'}}
    //                         color={ColorEnum.Primary} variant={ButtonVariantEnum.Outlined}
    //                         size={ButtonSizeEnum.Medium}
    //                         onClick={closePopupButtonOnClick}>
    //                     {t('general.no')}
    //                 </Button>
    //             </div>
    //         </div>
    //     }
    // /> :
    // <></>
}

export default SavePromptPopup
