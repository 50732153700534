import React, {useState} from "react";
import {BaseInput} from "../../components/Input/BaseInput";
import {arrayIsEmpty, fuzzySearchObjects, InputTypeEnum} from "edah_utils/dist"
import {BasePopup} from "../../components/Popup/BasePopup";
import {Button, ButtonSizeEnum, ButtonVariantEnum, ButtonColorEnum, IconEnum, Select, SelectWidthEnum} from "edah-component/dist";
import {t} from "i18next";

/**
 * 居家潛在個案維護＿收案清單
 * @return {JSX.Element}
 */
const HomePotentialManagement = () => {
    // 搜尋文字
    const [searchText, setSearchText] = useState('')

    // 級別
    const [caseClass, setCaseClass] = useState('0')

    // 選擇個案
    const [showSelectPopup, setShowSelectPopup] = useState(false)

    // Fake Data
    const [dataObject, setDataObject] = useState([
        {
            mrn: '0801234',
            name: '黃ＯＯ',
            gender: '女',
            contact: '廖ＯＯ',
            date: '2023-09-12',
            status: '',
            code: '0987',
            rn: '李ＯＯ'
        },
        {
            mrn: '0801234',
            name: '黃ＯＯ',
            gender: '男',
            contact: '廖ＯＯ',
            date: '2023-09-12',
            status: '住院(C)',
            code: '5D56B',
            rn: '李ＯＯ'
        },
        {
            mrn: '0801234',
            name: '黃ＯＯ',
            gender: '男',
            contact: '廖ＯＯ',
            date: '2023-09-12',
            status: '住院(A)',
            code: '5D56BAA',
            rn: '李ＯＯ'
        }
    ]);

    /**
     * 搜尋文字改變時
     * @param e {Event} e
     * @return {void}
     */
    const handleSearchTextOnChange = (e) => setSearchText(e.target.value)

    /**
     * 選取內容變化時
     * @param value {String}
     * @return {void}
     */
    const handleOptionOnChange = (value)=> setCaseClass(value)
    /**
     * 取得模糊匹配後的資料陣列
     * @return {Array<Object>}
     */
    const getFilterData = (data) => fuzzySearchObjects(data, searchText)


    return (
        <div className="w-full p-4 bg-[#FAFAFA]">
            {/*篩選清單*/}
            <div className="filterGroup flex flex-row flex-wrap items-center justify-start">
                <div className="flex flex-row items-center justify-start mb-4 mr-4 text-left">
                    {/*收案人員*/}
                    <Select
                        width={SelectWidthEnum.Small}
                        data ={{
                            label:'收案人員',
                            options:[{value:'0', label:'全部'}]
                        }}
                        value = '0'
                        notched={true}/>
                    {/*家字號*/}
                    <p className="ml-4 mr-1">家字號:</p>
                    <BaseInput className="w-[102px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                               type={InputTypeEnum.Text}
                               inputMode={'search'}
                    />
                    {/*病歷號*/}
                    <p className="mr-1">病歷號:</p>
                    <BaseInput className="w-[102px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                               type={InputTypeEnum.Text}
                               inputMode={'search'}
                    />
                    {/*級別*/}
                    <Select
                        width={SelectWidthEnum.Small}
                        data={{
                            label:'級別',
                            options: [
                                {value:'0',label:'全部'},
                                {value:'1',label:'S2'},
                                {value:'2',label:'S3'},
                            ]
                        }}
                        value = {caseClass}
                        notched={true}
                        onChange={handleOptionOnChange}/>
                </div>

                {/*查詢送出按鈕*/}
                <div className="flex flex-row items-center justify-start mb-4 mr-4">
                    <Button color={ButtonColorEnum.Primary} variant={ButtonVariantEnum.Contained}
                            size={ButtonSizeEnum.Medium} text={t('general.query')}
                    />
                </div>
            </div>

            {/*新增服務註冊*/}
            <div className="w-full h-[60px] py-[10px]">
                <Button sx={{display:'flex', justifyContent:'center', marginLeft: '1rem'}}
                        color={ButtonColorEnum.Success} variant={ButtonVariantEnum.Text}
                        size={ButtonSizeEnum.Large} icon={IconEnum.Add} text={t('general.add')} />
            </div>

            {/*列表清單區塊*/}
            <div className="maintainContainer flex flex-row items-start justify-start">
                <div className="p-4 bg-white w-full border-[1px] border-[#D4D4D8] rounded-[12px] mr-4">
                    <div className="searchGroup flex flex-row items-center justify-between mb-4">
                        {/*進階搜尋*/}
                        <BaseInput className="w-[320px] h-10 pl-4 border-[2px] border-[#D4D4D8] rounded-[6px]"
                                   type="text"
                                   placeholder="進階搜尋"
                                   inputMode={'search'}
                                   value={searchText}
                                   onChange={(e) => handleSearchTextOnChange(e)}/>
                    </div>

                    {/*列表清單*/}
                    <ul className="tableList HPMTableList">
                        {/*表單項目標題*/}
                        <li className="title">
                            <div></div>
                            <div>病歷號</div>
                            <div>個案姓名</div>
                            <div>性別</div>
                            <div>收案人員</div>
                            <div>收案日期</div>
                            <div>個案動態</div>
                            <div>床號</div>
                            <div>護理師</div>
                        </li>

                        {!arrayIsEmpty(getFilterData(dataObject)) ? getFilterData(dataObject).map((item, index) =>
                            <li key={index}>
                                {/*操作*/}
                                <div>
                                    <Button color={ButtonColorEnum.Primary} variant={ButtonVariantEnum.Text}
                                            size={ButtonSizeEnum.Large} icon={IconEnum.Edit}
                                            text={t('general.edit')}/>
                                    <Button color={ButtonColorEnum.Primary} variant={ButtonVariantEnum.Text}
                                            size={ButtonSizeEnum.Large} icon={IconEnum.Delete}
                                            text={t('general.delete')}/>
                                </div>
                                {/*病歷號*/}
                                <div>{item.mrn}</div>
                                {/*個案姓名*/}
                                <div>{item.name}</div>
                                {/*性別*/}
                                <div>{item.gender}</div>
                                {/*收案人員*/}
                                <div>{item.contact}</div>
                                {/*收案日期*/}
                                <div>{item.date}</div>
                                {/*個案動態*/}
                                <div>{item.status}</div>
                                {/*床號*/}
                                <div>{item.code}</div>
                                {/*護理師*/}
                                <div>{item.rn}</div>
                            </li>
                        ) : null}
                    </ul>
                </div>
            </div>

            {/*選擇個案Popup*/}
            {showSelectPopup && (
                <BasePopup title={'選擇個案級別'}/>
            )}
        </div>
    )
}
export default HomePotentialManagement