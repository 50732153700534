import {getCalendarMonth} from "./CalendarHelper"
import {objectIsEmpty, stringIsEmpty, arrayIsEmpty} from "edah_utils/dist"
import {t} from "i18next"

/**
 * 月曆
 * @param {number} year 指定年份
 * @param {number} month 指定月份
 * @param {Array<Object>} monthData 月份資料
 * @param {Boolean} disable 失效狀態
 * @param {Array<Object>} holidayKindArray 假日類別資料
 * @param {String} selectedHolidayType 選擇的假日類別
 * @param {Function} onDateClick 日期click事件
 * @return {JSX.Element}
 */
const Month = ({year, month, monthData, disable, holidayKindArray, selectedHolidayType, onDateClick}) => {
    /**
     * 日期 click事件
     * @param {Object} day 日期 object
     * @return {void}
     */
    const handleDateOnClick = (day) => {
        if (!day.current || disable)
            return

        onDateClick(month + 1, day.date.getDate())
    }

    /**
     * 判斷是否為slected的日期
     * @param {Date} date 日期
     * @return {Boolean}
     */
    const isSelectedDate = (date) => {
        const day = date.getDate()
        const findedDay = monthData.find(item => item.date === day)
        return !objectIsEmpty(findedDay) ? findedDay.selected : false
    }

    /**
     * 取得天區塊的class
     * @param {Object} day 日期 object
     * @return {string}
     */
    const getWeekClassName = (day) =>
        `w-8 items-center flex justify-center text-[#6D6D6D] text-base ${day.current ? "cursor-pointer" : ""} ${day.current ? (isSelectedDate(day.date) ? 'border-2 border-[#2B6CB0] bg-[#85DFFF]' : 'border border-[#D7D7D7] bg-[#F6F6F6]') : ''}`

    /**
     * 取得天區塊的背景色
     * @param {Object} day 日期 object
     * @returns
     */
    const getDayBackgroundColor = (day) => {
        let bgColor = ''
        const dayIndex = day.date.getDate() - 1
        if (day.current) {
            bgColor = '#F6F6F6'
            if (isSelectedDate(day.date)) {
                if (selectedHolidayType !== null && !arrayIsEmpty(holidayKindArray)) {
                    const findedKind = holidayKindArray.find(item => item.kindNo === selectedHolidayType)
                    if (!objectIsEmpty(findedKind))
                        bgColor = findedKind.color
                }
            } else if (!arrayIsEmpty(monthData) && !objectIsEmpty(monthData[dayIndex]) && !stringIsEmpty(monthData[dayIndex].holidayKind) && !arrayIsEmpty(holidayKindArray)) {
                const findedKind = holidayKindArray.find(item => item.kindNo === monthData[dayIndex].holidayKind)
                if (!objectIsEmpty(findedKind)) {
                    bgColor = findedKind.color
                }
            }
        } else {
            bgColor = ''
        }

        return bgColor
    }

    /**
     * 渲染day
     * @return {JSX.Element}
     */
    const renderDays = () => {
        //月份日曆的數組
        let calendar = getCalendarMonth(month, year)
        return (
            calendar.map((week, i) =>
                <div className="h-8 grid grid-cols-7 gap-0.5 mb-1" key={i}>
                    {
                        week.map((day, index) =>
                            <div className={getWeekClassName(day)} key={index}
                                 onClick={() => handleDateOnClick(day)}
                                 style={{backgroundColor: getDayBackgroundColor(day)}}>
                                {/*day是當前月份的日期就顯示getDate,不是就顯示空*/}
                                {day.current ? day.date.getDate() : ""}
                            </div>
                        )
                    }
                </div>
            )
        )
    }

    return (
        <div>
            <div className="grid grid-cols-7 gap-0.5 my-0.5">
                {
                    /**渲染星期 */
                    Array.from({length: 7}, (v, i) => i).map((_, index) =>
                        <div className="h-8 w-8 text-[#6D6D6D] text-base items-center flex justify-center" key={index}>
                            {t(`general.dateTime.weekRange.short.${index}`)}
                        </div>
                    )
                }
            </div>
            {/*渲染*/}
            {renderDays()}
        </div>
    )
}

export default Month;
