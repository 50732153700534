import React, {useEffect, useState} from "react";
import {t} from "i18next";
import {
    Button, ButtonSizeEnum, ButtonVariantEnum, ColorEnum,
    Card,AlertTypeEnum, AdvancedDataGrid, Select, SizeEnum, TabsEnum, Tabs
} from "edah-component/dist/index";
import {ApiErrorStatusEnum} from "edah_utils/dist";
import {Checkbox, TextField} from "edah-component/dist";
import { cpoeQueryPatientList} from "../../api/v1/Cpoe";
import useToast from "../../hooks/useToast";
import { getBirthDayAndDetailedAge } from '../ReferralSystem/utils'
import {ReactComponent as DoneIcon} from "../../assets/images/icons/done.svg"
import {ReactComponent as UnFinishIcon} from "../../assets/images/icons/close.svg"
import PromptPopup from "../Appointment/Popup/PromptPopup";

/**
 * 看診患者清單
 * @param clinicList {Array} 排班看診診間資訊
 * @param clinicInfo {Object} 診間資訊
 * @param setPatientInfo {Function} 設定選中的病人資料
 * @param setClinicInfo {Function} 設定選中的班次
 * @param setShowCpoeView{Function} 打開cpoe畫面
 * @return {JSX.Element}
 */
const ClinicPatientList = ({
                               clinicList,
                               clinicInfo,
                               setPatientInfo,
                               setClinicInfo,
                               setShowCpoeView
                           })=>{
    //Toast Message Hooks
    const showToast = useToast()
    // 患者看診清單
    const [patientList, setPatientList] = useState([])
    // 健保欠卡
    const [noIcFlag, setNoIcFlag] = useState(false)
    // 自費
    const [selfFlag, setSelfFlag] = useState(false)
    // 已完成
    const [encounterStatusFinishFlag, setEncounterStatusFinishFlag] = useState(false)
    // 暫存
    const [encounterStatusUnstableFlag, setEncounterStatusUnstableFlag] = useState(false)
    // 待看診
    const [encounterStatusNoneFlag, setEncounterStatusNoneFlag] = useState(false)
    // 異常碼
    const [icErrorEncSeq, setIcErrorEncSeq] = useState('')
    // 已報到
    const [checkInFlag, setCheckInFlag] = useState(false)
    // 未報到
    const [notCheckInFlag, setNotCheckInFlag] = useState(false)
    // 診號
    const [viewNo, setViewNo] = useState('')
    // 當前選中的tab頁
    const [currentTab, setCurrentTab] = useState('')
    // 未報到的提示
    const [showNotCheckinPromptPopup, setShowNotCheckinPromptPopup] = useState(false)

    // columns定義
    const patientColumns = [
        { field: 'viewNo', headerName: '診號', width:80},
        { field: 'checkInFlag', headerName: '報到', width:80,
            renderCell: (params) => {
                return (<div className="inline-flex items-center justify-center mt-2">
                    {params.value ? (<DoneIcon />) : <UnFinishIcon />}
                </div>)
            }
        },
        { field: 'encounterStatusName', headerName: '暫存', width:100},
        { field: 'patientId', headerName: '病歷號', width:220,
            renderCell: (params)=> (<a className='cursor-pointer text-primary underline' onClick={()=>handleClickToCpoe(params)}>{params.value}</a>)
        },
        {field: 'patientName', headerName: '姓名', width: 200},
        { field: 'genderName', headerName: '性別', width:80},
        { field: 'birthDate', headerName: '生日', width:200,
            valueGetter: (value, row) => (value ?  getBirthDayAndDetailedAge(value) : '')
        },
        { field: 'flag', headerName: '注記', width:100,
            renderCell: (params) => {
               return (
                   <div className="inline-flex items-center justify-center mt-2 gap-2">
                       {params.row['fallFlag'] && <span>跌</span>}
                       {params.row['manageFlag'] && <span>管</span>}
                       {params.row['oldFlag'] && <span>老</span>}
                   </div>
               )
            }
        },
        { field: 'ptTypeName', headerName: '身份', width:80},
        { field: 'reportFlag', headerName: '報告', width:100,
            renderCell: (params) => {
                return (
                    <div className="inline-flex items-center justify-center mt-2 gap-2">
                        {params.row['reportFlag'] && (<span>報</span>)}
                        {params.row['reportCriticalFlag'] && (<span>危</span>)}
                    </div>
                )
            }
        }
    ]

    /**
     * 獲取看診科室tab頁
     */
    const getTabItem = ()=>{
        return clinicList.map(item =>  ({id: item.titleDivName,title:item.titleDivName ,content: ''}))
    }
    /**
     * handleTableOnChange
     */
    const handleTabOnChange = (value)=>{
        setCurrentTab(value)
        setClinicInfo(clinicList.find(item => item.titleDivName === value))
    }

    /**
     * datagrid选择患者
     */
    const handleRowSelectChange = (newSelectionModel) =>{
        if(newSelectionModel.length > 0){
            const findedData = patientList.find(item => (item.patientListId === newSelectionModel[0]))
            setPatientInfo(findedData)
        }else{
        }
    }

    /**
     * 從病歷號進入cpoe畫面
     * @param params{Object} rows param
     */
    const handleClickToCpoe = (params) =>{
        const checkFlag = params.row?.checkInFlag
        // 已報到患者可進入
        if(checkFlag){
            setShowCpoeView(true)
        }else{
            //未報到彈出提示
            setShowNotCheckinPromptPopup(true)
        }

    }

    const handleNotCheckinPromptPopupOnClose = ()=>{
        setShowNotCheckinPromptPopup(false)
    }

    /**
     * 查詢病人候診清單
     * @returns {void}
     */
    const queryPatientList = ()=>{
        cpoeQueryPatientList({
            encounterDate: clinicInfo.encounterDate,
            clinicNo: clinicInfo.clinicNo,
            apn: clinicInfo.apn,
            noIcFlag: noIcFlag,
            selfFlag: selfFlag,
            encounterStatusFinishFlag: encounterStatusFinishFlag,
            encounterStatusUnstableFlag: encounterStatusUnstableFlag,
            encounterStatusNoneFlag: encounterStatusNoneFlag,
            viewNo: viewNo, //診號
            icErrorEncSeq: icErrorEncSeq,
            checkInFlag: checkInFlag,
            notCheckInFlag: notCheckInFlag
        }).then(res => {
            if(res.err === ApiErrorStatusEnum.Success){
                setPatientList(res.data.ocisPatientListParamList)
            }else{
                showToast({message: res.msg, type: AlertTypeEnum.Error})
                setPatientList([])
            }
        })
    }

    /**
     * 監聽診間信息變化查詢看診患者清單
     */
    useEffect(()=>{
        if(clinicInfo.clinicNo && clinicInfo.apn){
            queryPatientList()
        }
    },[clinicInfo.clinicNo,clinicInfo.apn])


    return (
        <div className="relative">
            <Card title="病人清單">
                <div className='absolute right-2 top-2 text-left'>
                    <Button color={ColorEnum.Secondary} variant={ButtonVariantEnum.Contained}
                            size={ButtonSizeEnum.Medium}>
                        {"未確認清單(5)"}
                    </Button>
                </div>
                {/*查詢條件*/}
                <div className="flex flex-row flex-wrap justify-start items-center gap-2">
                    <Checkbox checked={selfFlag} label="自費"
                              onChange={(event) => setSelfFlag(event.target.checked)}/>
                    <Checkbox checked={noIcFlag} label="健保欠卡"
                              onChange={(event) => setNoIcFlag(event.target.checked)}/>
                    <Select value={icErrorEncSeq} notched={true}
                            data={{
                                label: "異常碼",
                                options: [{label: 'a', value: 'a'}, {label: 'b', value: 'b'}, {
                                    label: 'c',
                                    value: ''
                                }]
                            }}
                            onChange={(value) => setIcErrorEncSeq(value)}
                    />
                    <TextField label="診號" inputWidth={SizeEnum.Tiny} value={viewNo}/>
                    <Button color={ColorEnum.Primary} variant={ButtonVariantEnum.Outlined}
                            size={ButtonSizeEnum.Medium}>
                        {"實體IC"}
                    </Button>
                    <Button color={ColorEnum.Primary} variant={ButtonVariantEnum.Outlined}
                            size={ButtonSizeEnum.Medium}>
                        {"虛擬IC"}
                    </Button>
                    <Checkbox checked={encounterStatusFinishFlag} label="已完成"
                              onChange={(event) => setEncounterStatusFinishFlag(event.target.checked)}/>
                    <Checkbox checked={encounterStatusUnstableFlag} label="暫存"
                              onChange={(event) => setEncounterStatusUnstableFlag(event.target.checked)}/>
                    <Checkbox checked={encounterStatusNoneFlag} label="待看診"
                              onChange={(event) => setEncounterStatusNoneFlag(event.target.checked)}/>
                    <Checkbox checked={checkInFlag} label="已報到"
                              onChange={(event) => setCheckInFlag(event.target.checked)}/>
                    <Checkbox checked={notCheckInFlag} label="未報到"
                              onChange={(event) => setNotCheckInFlag(event.target.checked)}/>
                    <Button
                        color={ColorEnum.Primary} variant={ButtonVariantEnum.Contained}
                        size={ButtonSizeEnum.Medium} onClick={queryPatientList}>
                        {t('general.query')}
                    </Button>
                </div>
                {/*tab頁簽*/}
                <Tabs
                    items={getTabItem()}
                    variant={TabsEnum.INDICATOR_TAB}
                    activeTabId={currentTab}
                    onTabChange={handleTabOnChange}
                />
                {/* 清單datagrid */}
                <AdvancedDataGrid
                    rows={patientList}
                    columns={patientColumns}
                    checkboxSelection={false}
                    disableRowSelectionOnClick={false}
                    disableColumnMenu={true}
                    disableColumnSorting={true}
                    height={'400px'}
                    getRowHeight={(params) => 40}
                    getRowId={(row) => row.patientListId}
                    onSelectionChange={(newSelection) =>
                        handleRowSelectChange(newSelection)
                    }
                />
            </Card>
            {
                //病患還未報到的提示
                <PromptPopup
                    promptText={"該病人還沒報到，請先報到再進入看診畫面"}
                    show={showNotCheckinPromptPopup}
                    closePopupButtonOnClick={
                        handleNotCheckinPromptPopupOnClose
                    }
                />
            }
        </div>
    )
}


export default ClinicPatientList