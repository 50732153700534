import React from 'react'
import ContactPersonMaintain from '../../components/RSIS/ContactPersonMaintain/ContactPersonMaintain'
import { PopupProvider } from '../../components/RSIS/PopupProvider'

/**
 * 承辦部門人員維護
 * @returns {JSX.Element}
 */
function ContactPersonMaintainLayout() {
  return (
    <PopupProvider>
      <ContactPersonMaintain />
    </PopupProvider>
  )
}

export default ContactPersonMaintainLayout