import CustomScroll from 'react-custom-scroll';
import { arrayIsEmpty } from "edah_utils/dist";
import {ReactComponent as AddIcon} from '../../assets/images/icons/add_circle.svg';
import {ReactComponent as EditIcon} from '../../assets/images/CDSS/edit_square.svg';
import {ReactComponent as DeleteIcon} from '../../assets/images/CDSS/delete.svg';
import { PopupModeEnum } from "edah_utils/dist"

const InformationDataGrid = ({ 
    arrayData,
    showPopup,
    showDeletePopup
}) => {
    /**
     * 點擊新增時
     */
    const handleAddOnClick = () => {
        showPopup(null, PopupModeEnum.Add);
    }

    /**
     * 點擊編輯時
     */
    const handleEditOnClick = (data) => {
        showPopup(data, PopupModeEnum.Modify);
    }

    /**
     * 點擊刪除紐時
     * @param {Object} data 要刪除的資料
     */
    const handleDeleteOnClick = (data) => {
        showDeletePopup(data);
    }

    return (
        <div className="bg-white w-full">
            <div className=''>
                <div className='table flex border-collapse w-full'>
                    <div className='flex bg-[#e4e4e7] min-h-[56px] border-collapse text-[#3f3f46] text-left text-[18px]'>
                        <div className='w-[50px] border-r border-[#111111]/10 flex justify-center items-center'>
                            <input 
                                className="bg-green-400 checked:bg-green-600 text-green-600 w-[20px] h-[20px]"
                                type="checkbox" 
                                style={{accentColor: "#38A169"}}
                            />
                        </div>
                        <div
                            className='w-[165px] p-[5px] px-[10px] border-r border-[#111111]/10 flex items-center cursor-pointer'
                            onClick={handleAddOnClick}
                        >
                            <AddIcon className="icon" />
                            <span className="ml-[5px] text-[#38A169]">新增</span>
                        </div>
                        <div className='w-[150px] p-[5px] px-[10px] border-r border-[#111111]/10 flex items-center'>規則代碼</div>
                        <div className='w-[130px] p-[5px] px-[10px] border-r border-[#111111]/10 flex items-center'>分類</div>
                        <div className='w-[250px] p-[5px] px-[10px] border-r border-[#111111]/10 flex items-center'>目的說明</div>
                        <div className='w-[285px] p-[5px] px-[10px] border-r border-[#111111]/10 flex items-center'>規則說明</div>
                        <div className='w-[105px] p-[5px] px-[10px] border-r border-[#111111]/10 flex items-center'>規劃出處</div>
                        <div className='w-[160px] p-[5px] px-[10px] border-r border-[#111111]/10 flex items-center'>服務伺服器類型</div>
                        <div className='w-[80px] p-[5px] px-[10px] border-r border-[#111111]/10 flex items-center'>服務中</div>
                        <div className='w-[115px] p-[5px] px-[10px] border-r border-[#111111]/10 flex items-center'>參數規範</div>
                        <div className='flex-1 min-w-[170px] p-[5px] px-[10px] flex items-center'>回傳結果</div>
                    </div>

                    <div className='h-[65vh] max-h-[65vh]'>
                        
                            <div className='scroll_contain text-[18px] '>
                                {!arrayIsEmpty(arrayData) && arrayData.map((data, index) => {
                                return (
                                <div key={index} 
                                    className={`flex min-h-[40px] text-[#3f3f3f] text-left ${index % 2 === 0 ? '':'bg-[#f4f4f5]'}`}
                                >
                                    <div
                                        className='border-r border-[#111111]/15 w-[50px] flex items-center justify-center'
                                    >
                                        <input 
                                            className="bg-green-400 checked:bg-green-600 text-green-600 w-[20px] h-[20px]"
                                            type="checkbox" 
                                            style={{accentColor: "#38A169"}}
                                        />
                                    </div>
                                    <div className='border-r border-[#111111]/15 w-[165px] flex items-center justify-center'>
                                        <div className='flex space-x-[8px]'>
                                            <div 
                                                className='flex items-center cursor-pointer'
                                                onClick={() => handleEditOnClick(data)}
                                            >
                                                <EditIcon/>
                                                <div className='text-[#2B6CB0]'>編輯</div>
                                            </div>
                                            <div 
                                                className='flex items-center cursor-pointer'
                                                onClick={() => handleDeleteOnClick(data)}
                                            >
                                                <DeleteIcon/>
                                                <div className='text-[#2B6CB0]'>刪除</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='w-[150px] border-r border-[#111111]/15 p-[5px] px-[10px] font-normal flex items-center'>
                                        {data.cdssId}
                                    </div>
                                    <div className='w-[130px] border-r border-[#111111]/15 p-[5px] px-[10px] font-normal flex items-center'>
                                        {data.category}
                                    </div>
                                    <div className='w-[250px] border-r border-[#111111]/15 p-[5px] px-[10px] font-normal flex items-center'>
                                        {data.purpose}
                                    </div>
                                    <div className='w-[285px] border-r border-[#111111]/15 p-[5px] px-[10px] font-normal flex items-center'>
                                        {data.description}
                                    </div>
                                    <div className='w-[105px] border-r border-[#111111]/15 p-[5px] px-[10px] font-normal flex items-center'>
                                        {data.ruleSource}
                                    </div>
                                    <div className='w-[160px] border-r border-[#111111]/15 p-[5px] px-[10px] font-normal flex items-center'>
                                        {data.serverType}
                                    </div>
                                    <div className='w-[80px] border-r border-[#111111]/15 p-[5px] px-[10px] font-normal flex items-center'>
                                        {data.effectiveFlag ? "是" : "否"}
                                    </div>
                                    <div className='w-[115px] border-r border-[#111111]/15 p-[5px] px-[10px] font-normal flex items-center'>
                                        {data.parameterConstraints}
                                    </div>
                                    <div className='flex-1 min-w-[170px]  p-[5px] px-[10px] font-normal flex items-center'>
                                        {data.returnResult}
                                    </div>
                                </div>
                                );
                            })}
                            </div>
                   
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InformationDataGrid;