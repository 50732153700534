import React, {useEffect, useState} from "react"
import {Pagination} from "../../components/Pagination/Pagination"
import {PopupModeEnum} from "edah_utils/dist"

/**
 * 退掛確認彈窗
 * @param show 是否顯示
 * @param closePopupButtonOnClick 關閉按鈕點擊事件
 * @param detailSignData 已簽章data
 * @param detailUnsignData 未簽章data
 * @returns
 */
const DetailSignPopup = ({
   show = false,
   detailSignData,
   detailUnsignData,
   closePopupButtonOnClick
}) => {
    //Table element 的預設type
    const tableElementDefaultType = "px-[16px] border-r border-[#111111]/15 font-normal"

    //當前頁碼
    const [currentPage, setCurrentPage] = useState(1)

    //每頁資料筆數
    const [pageSize, setPageSize] = useState(10)

    //總資料筆數
    const [totalSize, setTotalSize] = useState(0)

    //顯示已簽或未簽-table data
    const [data, setData] = useState([])

    //顯示已簽radio(PopupModeEnum.Modify)或未簽radio(PopupModeEnum.Add)
    const [signRadio, setSignRadio] = useState([])

    /**
    * 進階搜尋文字輸入事件
    * @param e {Event} 事件
    * @return {void}
    */
    const onAdvancedSearchTextOnInputChange = (e) => setAdvancedSearchText(e.target.value)

    /**
     * 進階搜尋文字鍵盤按鈕按下事件
     * @param e {Event} 事件
     * @return {void}
     */
    const onAdvancedSearchTextOnInputKeyDown = (e) => {
        if (e.key === 'Enter') {
            //搜尋Patient
            advancedQueryPatient()
        }
    }

    /**
    * 頁碼變更事件
    * @param page {Number} 頁碼
    */
    const onPaginationPageOnChange = (page) => {
        setTimeout(() => setCurrentPage(page), 100)
    }

    /**
     * 每頁資料筆數變更事件
     * @param e {Event} 事件
     */
    const onPaginationPageSizeOnChange = (e) => {
        // 設定每頁資料筆數
        setPageSize(e.target.value)
    }

    /**
     * 上一頁點擊事件
     */
    const onPaginationPreviousOnClick = () => (currentPage - 1) > 1 ? setCurrentPage(currentPage - 1) : setCurrentPage(1)

    /**
     * 下一頁點擊事件
     */
    const onPaginationNextOnClick = () => (currentPage + 1) < totalPage ? setCurrentPage(currentPage + 1) : setCurrentPage(totalPage)


    /**
     * 是否顯示彈窗
     */
    const [showPopup, setShowPopup] = useState(show)

    /**
     * 確定刪除點擊事件
     */
    const handleConfirmOnClick = () => {
        closePopupButtonOnClick()
    }

    /**
     * 取消刪除點擊事件
     */
    const handleCancelOnClick = () => {
        closePopupButtonOnClick()
    }

    /**
     * signRadio改變時
     * @param signRadio {Number}
     */
    const handleSignRadioOnChange = (signRadio) => {
        setSignRadio(signRadio)
    }

    /**
     * 監聽是否顯示彈窗Pros變化
     * 開啟畫面下，radio為已簽章
     */
    useEffect(() => {
        setShowPopup(show)
        setSignRadio(PopupModeEnum.Modify)
        signRadio == PopupModeEnum.Modify? setData(detailSignData): setData(detailUnsignData)
    }, [show])

    /**
     * 監聽SignRadio變化
     * 變動data為detailSignData-SignRadio已簽-PopupModeEnum.Modify
     * 變動data為detailUnsignData-SignRadio未簽-PopupModeEnum.Add
     */
    useEffect(() => {
        signRadio == PopupModeEnum.Modify? setData(detailSignData): setData(detailUnsignData)
    }, [signRadio])


    return (showPopup ?
        <>
            <div
                className="absolute top-0 left-0 bg-[rgba(0,0,0,0.5)] w-screen h-screen z-80 min-w-screen min-h-screen items-center justify-center flex py-12 px-[100px] text-globalFont"
            >
                <div
                    className="flex w-[930px] bg-[#ffffff] rounded-md text-lg relative flex flex-col"
                    onClick={(e) => e.stopPropagation()}>
                    {/**Title */}
                    <div
                        className="h-[60px] w-full px-6 py-4 bg-white rounded-tl-lg rounded-tr-lg border-b border-neutral-900 border-opacity-20 flex justify-between items-center gap-2.5">
                        <div className="text-black text-xl font-normal">
                            明細查詢
                        </div>
                        <div className="text-sky-700 text-lg font-normal cursor-pointer"
                            onClick={handleConfirmOnClick}>關閉
                        </div>
                    </div>
                    {/**Container */}
                    <div>
                        <div>
                            <div className="flex-row flex space-x-6 items-center ml-10 mt-2">
                                <div>
                                    <input
                                        className="appearance-none ring-2 w-2 h-2 ring-gray-200 rounded-full ring-offset-4 checked:bg-blue-400 checked:ring-blue-400"
                                        id="departmentRadio" type="radio" name="filterRadio"
                                        checked={signRadio === PopupModeEnum.Modify}
                                        onChange={() => handleSignRadioOnChange(PopupModeEnum.Modify)}
                                        />
                                    <label className="mx-3" htmlFor="departmentRadio" style={{cursor: "pointer"}}>已簽章</label>
                                </div>
                                <div>
                                    <input
                                        className="appearance-none ring-2 w-2 h-2 ring-gray-200 rounded-full ring-offset-4 checked:bg-blue-400 checked:ring-blue-400"
                                        id="doctorRadio" type="radio" name="filterRadio"
                                        checked={signRadio === PopupModeEnum.Add}
                                        onChange={() => handleSignRadioOnChange(PopupModeEnum.Add)}
                                        />
                                    <label className="mx-3" htmlFor="doctorRadio" style={{cursor: "pointer"}}>未簽章</label>
                                </div>
                            </div>
                            <div className="mt-2">
                                {/* table */}
                                <div className="py-2 px-2 m-4 bg-white border-[1px] border-[#D4D4D8] rounded-[6px]">
                                    <div className="flex flex-row items-center justify-between mb-2">
                                        <div>
                                            <input className="w-[320px] h-10 pl-4 border-[2px] border-[#D4D4D8] rounded-[6px]"
                                               type="text" placeholder="進階搜尋"/>
                                        </div>
                                        <div>
                                        </div>
                                    </div>
                                    <div className=' w-full overflow-x-auto overflow-y-auto min-h-[100px] max-h-[35vh] xl:max-h-[60vh]'>
                                        <table className="table-fixed w-full text-left overflow-x-auto overflow-y-auto min-h-[100px] max-h-[35vh] xl:max-h-[50vh]">
                                            <thead>
                                                <tr className="bg-[#e4e4e7] h-[56px] border-collapse text-lg text-[#18181b]">
                                                    <th className={` ${tableElementDefaultType}`}>建立日期 <br/> 時間</th>
                                                    <th className={` ${tableElementDefaultType}`}>單據類別 <br/> 單據抬頭</th>
                                                    <th className={` ${tableElementDefaultType}`}>病人資訊<br/> 來源/門住日期</th>
                                                    <th className={` ${tableElementDefaultType}`}>建立人員</th>
                                                    <th className={` ${tableElementDefaultType}`}>簽章日期<br/>時間</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    data && data.map((data, index) => (
                                                        <tr
                                                            className={`h-[40px] ${index % 2 === 0 ? '':'bg-[#f4f4f5]'} text-[#18181b]`}
                                                            key={index}>
                                                            <td className={`${tableElementDefaultType}`}>{data.createDatetime?data.createDatetime:""}</td>
                                                            <td className={`${tableElementDefaultType}`}>{data.emrRpttype?data.emrRpttype:""}<br/>{data.emrTitle?data.emrTitle:""}</td>
                                                            <td className={`${tableElementDefaultType}`}>{data.patientName?data.patientName:""}<br/>{data.inpOpd?data.inpOpd:""}</td>
                                                            <td className={`${tableElementDefaultType}`}>{data.creatrUserNo?data.creatrUserNo:""}</td>
                                                            <td className={`${tableElementDefaultType}`}>{data.signPcName1?data.signPcName1:""}</td>
                                                        </tr>
                                                    ))
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="flex justify-end w-full">
                                        <Pagination totalPageSize={10} currentPage={currentPage} pageSize={pageSize}
                                                    totalSize={data.length}
                                                    onPageOnChange={(page) => onPaginationPageOnChange(page)}
                                                    onPreviousOnClick={onPaginationPreviousOnClick}
                                                    onNextOnClick={onPaginationNextOnClick}
                                                    onPageSizeChange={onPaginationPageSizeOnChange}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </> : <></>
    )
}

export default DetailSignPopup;