import React from "react"

/**
 * 檢核結果 Tab Content
 * @return {JSX.Element}
 */
export const ResultContent = () => {
    return (
        <div className="w-[835px] h-[754px] p-4 bg-[#F4F4F5] rounded-[6px]">
            <p className="text-left"> &lt;M19&gt; : 原就診日期時間 -- 應小於 M11/M49，但 M07=A1 時，M19
                應小於 M11/M49 <br/>
                且 M19 前 7 碼需等於 M11/M49 前 7 碼 <br/>
                &lt;D11&gt; : 處方簽章 -- 欄位資料必填寫</p>
        </div>
    )
}