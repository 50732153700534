import React, {useEffect, useMemo, useState} from 'react'
import CustomTable from '../RSISCustomTable'
import {
    ApiErrorStatusEnum,
    generateTimeIntervals,
    getSpecificDateTimeRanges,
    string2Date,
    stringIsEmpty,
    time2String,
    WeekDayEnum
} from 'edah_utils/dist'
import {usePopup} from '../PopupProvider'
import {DelSlotBtn} from '../SlotButton'
import {deleteItem} from '../utils'
import DeletePopup from '../Popup/DeletePopup'
import {rsisCheckTimeValid} from "../../../api/v1/RSIS";
import {t} from 'i18next'
import {
    AlertTypeEnum,
    Checkbox,
    DatePicker,
    Dialog,
    DialogVariant,
    Field,
    RadioGroup,
    Select,
    SizeEnum,
    SelectWidthEnum
} from 'edah-component/dist'
import dayjs from 'dayjs';

/**
 * 星期映射表
 * @type {{sunday: string, saturday: string, tuesday: string, wednesday: string, thursday: string, friday: string, monday: string}}
 */
const weekDays = {
    /**
     * 星期一
     */
    monday: '星期一',
    /**
     * 星期二
     */
    tuesday: '星期二',
    /**
     * 星期三
     */
    wednesday: '星期三',
    /**
     * 星期四
     */
    thursday: '星期四',
    /**
     * 星期五
     */
    friday: '星期五',
    /**
     * 星期六
     */
    saturday: '星期六',
    /**
     * 星期日
     */
    sunday: '星期日'
}

/**
 * 支援日期時間選擇彈窗列表組件
 * @param item {array} 列表資料
 * @param index {number} 索引
 * @return {JSX.Element}
 */
const ListItem = ({item, index}) => {
    // 使用上下文
    const {setShowSubDeletePopup, setSubFocusIndex} = usePopup()

    // 刪除列表，彈出刪除彈窗，並設定焦點索引
    const handleOnDelete = () => {
        setShowSubDeletePopup(true)
        setSubFocusIndex(index)
    }

    return <li key={index}>
        <div><DelSlotBtn onClick={handleOnDelete}/></div>
        <div>{item.startDate}</div>
        <div>{item.startTime}</div>
        <div>{item.endDate}</div>
        <div>{item.endTime}</div>
    </li>
}

/**
 * 生成時間區段
 * @param startDate {String|Date} 開始日期
 * @param endDate {String|Date} 結束日期
 * @param startTime {String|Date} 開始時間
 * @param endTime {String|Date} 結束時間
 * @param type {String} 類型 ('ALL', 'CUSTOM', 'WEEKLY')
 * @param weekInterval {Object} 周區間
 * @param customDate {String} 自訂日期
 * @return {Array<Object>} 時間區段數組
 */
const generateSupportTimeIntervals = (startDate, endDate, startTime, endTime, type, weekInterval, customDate) => {
    const intervals = [];
    const start = new Date(startDate);
    const end = new Date(endDate);
    // 為持續ALL時
    if (type === 'ALL') {
        intervals.push({
            startDate: time2String(new Date(start), 'YYYY-MM-DD'),
            startTime: startTime,
            endDate: time2String(new Date(end), 'YYYY-MM-DD'),
            endTime: endTime
        });
    } else if (type === 'WEEKLY') { //為間斷並重複時
        // 取得目標星期
        const targetWeekdays = Object.entries(weekInterval)
            .filter(([_, value]) => value)  // 過濾掉值為 false 的項
            .map(([key]) => WeekDayEnum[`${key.charAt(0).toUpperCase()}${key.slice(1).toLowerCase()}`])  // 轉換為對應的 WeekDayEnum
        //
        getSpecificDateTimeRanges({
            dateRange: {start: startDate, end: endDate},
            timeRange: {start: startTime, end: endTime},
        }, targetWeekdays)
            // 取得時間區間
            .map(({dateRange, timeRange}) =>
                // 將時間區間加入 intervals
                intervals.push({
                    startDate: dateRange.start,
                    startTime: timeRange.start,
                    endDate: dateRange.end,
                    endTime: timeRange.end
                }))
    } else if (type === 'CUSTOM' && customDate) {   //為自行選擇時
        // 暫存開始日期
        let tempCustomStartDate = string2Date(customDate)
        // 暫存結束日期
        let tempCustomEndDate = string2Date(customDate)
        //  暫存開始時間
        let tempStartTime = startTime
        // 暫存結束時間
        let tempEndTime = endTime

        // 如果開始時間為24:00，則將開始日期+1
        if (tempStartTime === '24:00') {
            // 將開始日期+1
            tempCustomStartDate.setDate(tempCustomStartDate.getDate() + 1)
            // 將結束日期直接設定為開始日期
            tempCustomEndDate = tempCustomStartDate
            // 將開始時間設定為00:00
            tempStartTime = '00:00'
        } else if (tempEndTime === '24:00') { // 如果結束時間為24:00，則將結束日期+1
            // 將結束日期+1
            tempCustomEndDate.setDate(tempCustomEndDate.getDate() + 1)
            // 將結束時間設定為00:00
            tempEndTime = '00:00'
        }

        intervals.push({
            startDate: time2String(tempCustomStartDate, 'YYYY-MM-DD'),
            startTime: tempStartTime,
            endDate: time2String(tempCustomEndDate, 'YYYY-MM-DD'),
            endTime: tempEndTime
        })
    }

    return intervals;
};

/**
 * 表格欄位
 * @type {string[]}
 */
const fields = ["", "開始日期", "開始時間", "結束日期", "結束時間"]

/**
 * 支援日期時間選擇彈窗
 * @param data {array} 列表資料
 * @param supportNo {string} 單號
 * @param supportUserNo {string} 支援人員編號
 * @param itemVersion {string} 版本
 * @param handleUpdateDataList {function} 更新列表資料
 * @param handleOnClose {function} 關閉彈窗
 * @param isEdit {boolean} 是否為編輯狀態
 * @param userName {string} 使用者名稱
 * @return {JSX.Element}
 */
const SupportDateChoice = ({
                               data,
                               supportNo = '',
                               supportUserNo,
                               itemVersion = '1',
                               handleUpdateDataList,
                               handleOnClose,
                               isEdit = false,
                               userName
                           }) => {
    // 列表資料
    const [dataList, setDataList] = useState([])

    // 使用列表組件
    const List = ListItem

    // 支援開始日期
    const [itemStartDate, setItemStartDate] = useState('')

    // 支援結束日期
    const [itemEndDate, setItemEndDate] = useState('')

    // 支援開始時間
    const [itemStartTime, setItemStartTime] = useState('00:00')

    // 支援結束時間
    const [itemEndTime, setItemEndTime] = useState('00:00')

    // 自行選擇日期
    const [customDate, setCustomDate] = useState('')

    // 自行選擇開始時間
    const [customStartTime, setCustomStartTime] = useState('00:00')

    // 自行選擇結束時間
    const [customEndTime, setCustomEndTime] = useState('00:00')

    // 持續形式及週區間
    const [continueType, setContinueType] = useState('A')

    // 週區間
    const [weekInterval, setWeekInterval] = useState({
        monday: false, tuesday: false, wednesday: false, thursday: false, friday: false, saturday: false, sunday: false
    })

    // 使用上下文
    const {
        showSubDeletePopup,
        setShowSubDeletePopup,
        showToast,
        subFocusIndex
    } = usePopup()

    // 分頁屬性
    const [paginationProps, setPaginationProps] = useState({
        pageNum: 1, pageSize: 10, totalItemSize: 0, totalPageSize: 0, currentPageItemSize: 0, fn: () => {
        }
    })

    /**
     * 取得時間區間
     * @param interval {Object}
     * @return {[number,number]}
     */
    const getDateTimeRange = (interval) => {
        // 取得開始時間和結束時間
        const [startHour, startMinute] = interval.startTime.split(':')
        // 取得結束時間
        const [endHour, endMinute] = interval.endTime.split(':')
        // 設定開始時間和結束時間
        const startDate = new Date(interval.startDate).setHours(startHour, startMinute, 0, 0)
        // 設定結束時間
        const endDate = new Date(interval.endDate).setHours(endHour, endMinute, 0, 0)
        return [startDate, endDate]
    }

    /**
     * 檢查是否有重疊的時間區段
     * @param newIntervals {array} 新的時間區段數組
     * @param existingIntervals {array} 已有的時間區段數組
     * @return {{hasOverlap: boolean, overlaps: *[]}} 是否有重疊
     */
    const hasOverlap = (newIntervals, existingIntervals) => {
        const overlaps = [];
        // 檢查是否有重疊的時間區段
        for (let newInterval of newIntervals) {
            // 取得新的時間區段
            for (let existingInterval of existingIntervals) {
                // 取得已有的時間區段
                const [newStart, newEnd] = getDateTimeRange(newInterval)
                // 取得新的開始時間和結束時間
                const [existingStart, existingEnd] = getDateTimeRange(existingInterval)

                // 檢查是否有重疊的時間區段
                if ((existingStart <= newStart && newStart < existingEnd) ||
                    (newStart <= existingStart && existingStart < newEnd)) {
                    // 當有重疊時，將新的時間區段和已有的時間區段加入重疊列表
                    overlaps.push({
                        new: newInterval,
                        existing: existingInterval
                    })
                }
            }
        }

        return {
            hasOverlap: overlaps.length > 0,
            overlaps: overlaps
        }
    };

    /**
     * 取得時間區間選項
     * @return {string[]}
     */
    const getTimeIntervals = () => {
        const intervals = generateTimeIntervals()
        intervals.push('24:00')
        // 產生時間區間
        return useMemo(() => intervals, [])
    }

    /**
     * 重置日期和時間
     * @return {void}
     */
    const resetDateTime = () => {
        // 重置日期和時間
        setItemStartDate('')
        setItemEndDate('')
        setItemStartTime('00:00')
        setItemEndTime('00:00')
        // 重置自行選擇日期和時間
        setCustomDate('')
        setCustomStartTime('00:00')
        setCustomEndTime('00:00')
        // 重置持續形式
        setContinueType('A')
        // 重置週區間
        setWeekInterval({
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
            sunday: false
        })
    }

    /**
     * 取得替換時間
     * @param endDate {String|null} 終止日期
     * @param endTime {String|null} 終止時間
     * @param startDate {String|null} 起始日期
     * @param startTime {String|null} 起始時間
     * @return {Object}
     */
    const getReplaceTime = ({endDate, endTime, startDate, startTime}) => {
        //取得日期時間
        startDate = startDate ? `${startDate} ${startTime}:00` : ""
        startTime = startTime ? startTime : ""
        endDate = endDate ? `${endDate} ${endTime}:00` : ""
        endTime = endTime ? endTime : ""

        //是否超過2400
        if (endTime !== "" && endTime === "24:00") {
            const newDate = new Date(endDate);
            endDate = time2String(newDate, "YYYY-MM-DD");
            //將終止時間設定為隔天0000
            endTime = "00:00";
        }
        if (startTime !== "" && startTime === "24:00") {
            const newDate = new Date(startDate);
            startDate = time2String(newDate, "YYYY-MM-DD");
            //將終止時間設定為隔天0000
            startTime = "00:00";
        }

        return {
            startDate: startDate.split(" ")[0],
            startTime: startTime,
            endDate: endDate.split(" ")[0],
            endTime: endTime,
        }
    }

    /**
     * 新增支援列表資料
     * @return {void}
     */
    const handleOnAdd = () => {
        //當天日期
        const currentDate = new Date(`${time2String(new Date, 'YYYY-MM-DD')} 00:00:00`)
        // 當持續形式不為自行選擇時
        if (continueType !== 'C') {
            // 當起始日期或結束日期或起始時間或結束時間為空時
            if (itemStartDate === '' || itemEndDate === '' || itemStartTime === '' || itemEndTime === '') {
                showToast({message: `${userName}：請填入完整的支援時間！`, type: AlertTypeEnum.Warning});
                return;
            }
            const startTimeInfo = itemStartTime.split(':')
            const endTimeInfo = itemEndTime.split(':')
            const startDate = new Date(itemStartDate).setHours(startTimeInfo[0], startTimeInfo[1], 0, 0)
            const endDate = new Date(itemEndDate).setHours(endTimeInfo[0], endTimeInfo[1], 0, 0)

            //起始日期
            const tempStartDate = new Date(`${time2String(startDate, 'YYYY-MM-DD')} 00:00:00`)
            //結束日期
            const tempEndDate = new Date(`${time2String(endDate, 'YYYY-MM-DD')} 00:00:00`)

            //起始時間起始日期小於當前日期 或 起始時間結束日期小於當前日期
            if ((currentDate.getTime() > tempStartDate.getTime()) || (currentDate.getTime() > tempEndDate.getTime())) {
                showToast({message: '日期不可輸入過往日期!!', type: AlertTypeEnum.Warning})
                return
            }

            // 當結束日期早於起始日期時
            if (endDate <= startDate) {
                showToast({
                    message: `${userName}：支援日期時間不符合，請重新填寫！如需選擇半夜 12 點，請點選 24:00`,
                    type: AlertTypeEnum.Warning
                });
                return
            }

            if (continueType === 'B') { // 為間斷並且重複時
                // 如果開始日期和結束日期相同，並且起始時間和結束時間相同，錯誤訊息請show : 起始時間與結束時間相同
                if (itemStartDate === itemEndDate && itemStartTime === itemEndTime) {
                    showToast({message: '起始時間與結束時間相同', type: AlertTypeEnum.Warning})
                    return
                }

                // 若週區間全為false，則不新增
                if (Object.values(weekInterval).every(value => !value)) {
                    showToast({message: '請選擇週區間', type: AlertTypeEnum.Warning});
                    return;
                }
            }
        } else if (continueType === 'C') { //自行選擇
            //起始日期
            const tempCustomDate = new Date(`${time2String(customDate, 'YYYY-MM-DD')} 00:00:00`)
            //自行選擇起始日期小於當前時間
            if (currentDate.getTime() > tempCustomDate.getTime()) {
                showToast({message: '日期不可輸入過往日期!!', type: AlertTypeEnum.Warning})
                return
            }

            // 取得計算後的時間區段
            const tempInterval = generateSupportTimeIntervals('', '', customStartTime, customEndTime, 'CUSTOM', {}, customDate)[0]
            // 日期相同才需要去檢核時間
            if (tempInterval.startDate === tempInterval.endDate) {
                // 結束時間小於或等於開始時間
                if (tempInterval.endTime <= tempInterval.startTime) {
                    // 開始時間和結束時間都為24:00時
                    if (customStartTime === '24:00' && customEndTime === '24:00') {
                        showToast({
                            message: `${userName}：日期錯誤，起始日期時間不能大於等於結束日期時間`,
                            type: AlertTypeEnum.Warning
                        })
                        return
                    } else { // 開始時間和結束時間不為24:00時
                        showToast({
                            message: `${userName}：支援日期時間不符合，請重新填寫！如需選擇半夜 12 點，請點選 24:00`,
                            type: AlertTypeEnum.Warning
                        })
                        return
                    }
                }
            }
        }

        // 新的時間區段array
        let newIntervals = [];
        // 依照持續形式新增時間區段
        if (continueType === 'A') { // 為持續時
            newIntervals = generateSupportTimeIntervals(itemStartDate, itemEndDate, itemStartTime, itemEndTime, 'ALL');
        } else if (continueType === 'B') { // 為間斷並重複時
            newIntervals = generateSupportTimeIntervals(itemStartDate, itemEndDate, itemStartTime, itemEndTime, 'WEEKLY', weekInterval);
        } else { // 為自行選擇時
            newIntervals = generateSupportTimeIntervals('', '', customStartTime, customEndTime, 'CUSTOM', {}, customDate);
        }

        // 檢查是否有重疊或重複的時間區段
        const overlapResult = hasOverlap(newIntervals, dataList);
        if (overlapResult.hasOverlap) {
            const overlapMessage = overlapResult.overlaps.map(overlap => {
                const newInterval = overlap.new;
                const existingInterval = overlap.existing;
                return `\n${newInterval.startDate} ${newInterval.startTime} ~ ${newInterval.endDate} ${newInterval.endTime}支援時段重疊，支援人員：${userName}已於${existingInterval.startDate} ${existingInterval.startTime} ~ ${existingInterval.endDate} ${existingInterval.endTime}申請報備支援。\n`;
            }).join('');
            showToast({message: overlapMessage, type: AlertTypeEnum.Warning})
            return;
        }
        const replaceNewIntervals = newIntervals.map(interval => getReplaceTime(interval));
        // 更新時間區段dataList
        setDataList([...dataList, ...replaceNewIntervals]);

        // 重置日期和時間
        resetDateTime()
    };

    /**
     * 持續形式改變時
     * @param v {String} value
     * @return {void}
     */
    const handleContinueTypeOnChange = (v) => setContinueType(v)

    /**
     * 刪除列表特定索引資料
     * @return {void}
     */
    const handleOnDelete = () => {
        setDataList(deleteItem(dataList, subFocusIndex))
        setShowSubDeletePopup(false)
    }

    /**
     * 支援開始日期改變時
     * @param newValue {dayjs} 選擇的新開始日期
     * @return {void}
     */
    const handleItemStartDateOnChange = (newValue) => {
        setItemStartDate(dayjs(newValue).format('YYYY-MM-DD'));
    }

    /**
     * 支援開始時間改變時
     * @param value {string}
     * @return {void}
     */
    const handleItemStartTime = (value) => setItemStartTime(value)

    /**
     * 支援結束日期改變時
     * @param newValue {dayjs} 選擇的新結束日期
     * @return {void}
     */
    const handleItemEndDateOnChange = (newValue) => {
        setItemEndDate(dayjs(newValue).format('YYYY-MM-DD'));
    }

    /**
     * 支援結束時間改變時
     * @param value {string}
     * @return {void}
     */
    const handleItemEndTime = (value) => setItemEndTime(value)

    /**
     * 自行選擇開始日期改變時
     * @param newValue {dayjs} 選擇的新日期
     * @return {void}
     */
    const handleCustomDate = (newValue) => {
        setCustomDate(dayjs(newValue).format('YYYY-MM-DD'));
    }

    /**
     * 自行選擇開始時間改變時
     * @param value {string}
     * @return {void}
     */
    const handleCustomStartTime = (value) => setCustomStartTime(value)

    /**
     * 自行選擇結束時間改變時
     * @param value {string}
     * @return {void}
     */
    const handleCustomEndTime = (value) => setCustomEndTime(value)

    /**
     * 取得日期和時間
     * @param date
     * @param time
     * @returns {{date: string, time: string}}
     */
    const getDateAndTime = (date, time) => {
        return {
            date: time2String(date, 'YYYY-MM-DD'),
            time: `${time.substring(0, 2)}:${time.substring(2, 4)}`
        }
    }

    /**
     * 取得重疊的時間段
     * @param date
     * @param time
     * @return {[{date: string, time: string}]}
     */
    const getOverlapDateAndTimeList = (date, time) => {
        // 結果
        let result = []
        // 檢查data 和 time 長度是否一樣
        date.length === time.length ? date.map((item, index) => {
            //將結果加入result
            result.push(getDateAndTime(item, time[index]))
        }) : []
        return result
    }

    /**
     * 確認按鈕
     * @return {void}
     */
    const handleOnConfirm = async () => {
        // 檢查時間是否為空值
        if (dataList.length === 0) {
            showToast({message: '請新增時間段後展開明細', type: AlertTypeEnum.Warning});
            return;
        }
        // 檢查是否在編輯狀態且時間大於一筆
        if (isEdit && dataList.length > 1) {
            showToast({message: '修改時間只能輸入一筆資料', type: AlertTypeEnum.Warning});
            return;
        }

        try {
            // 使用Promise.all 並行檢查是否有重疊的時間段
            const result = await handleCheckOverlap()
            if (!result.overlaps) {
                handleUpdateDataList(dataList);
                handleOnClose();
            } else {
                const overlapList = result.time.filter(item => item.overlapFlag === true)
                const overlapMessage = `${userName}：${overlapList?.map(item => {
                    // 取得起始日期和時間
                    const {
                        date: inputStartDate,
                        time: inputStartTime
                    } = getDateAndTime(item.itemStartDate, item.itemStartTime)
                    // 取得結束日期和時間
                    const {
                        date: inputEndDate,
                        time: inputEndTime
                    } = getDateAndTime(item.itemEndDate, item.itemEndTime)
                    // 取得起始時間重疊的時間段
                    const overlapStartDate = getOverlapDateAndTimeList(item.dbStartDate, item.dbStartTime)
                    // 取得結束時間重疊的時間段
                    const overlapEndDate = getOverlapDateAndTimeList(item.dbEndDate, item.dbEndTime)
                    // 重疊時間字串
                    let overlapTime = ''
                    // 組合重疊時間字串
                    overlapStartDate.map((item, index) => {
                        overlapTime += `${item.date} ${item.time} ~ ${overlapEndDate[index].date} ${overlapEndDate[index].time}\n`
                    })

                    return `\n${inputStartDate} ${inputStartTime} ~ ${inputEndDate} ${inputEndTime}支援時段重疊，支援人員：${userName}已於${overlapTime}申請報備支援。`
                })
                }`;

                showToast({message: overlapMessage, type: AlertTypeEnum.Error});
            }
        } catch (error) {
            showToast({message: '檢查過程中發生錯誤', type: AlertTypeEnum.Error});
        }
    }

    /**
     * 檢查伺服器是否有時間重疊
     * @return {Promise<{overlaps: boolean, time: string}>}
     */
    const handleCheckOverlap = async () => {
        try {
            const time = dataList.map((item) => ({
                itemStartDate: `${item.startDate} 00:00:00`,
                itemEndDate: `${item.endDate} 00:00:00`,
                itemStartTime: item.startTime.replace(":", ""),
                itemEndTime: item.endTime.replace(":", "")
            }))
            const res = await rsisCheckTimeValid({
                supportNo: supportNo,
                supportUserNo: supportUserNo,
                itemVersion: itemVersion,
                timeList: time
            });
            if (res.err === ApiErrorStatusEnum.Success && res.data.every(item => item.overlapFlag === false)) {
                return {overlaps: false, time: res.data};
            } else {
                return {overlaps: true, time: res.data};
            }
        } catch (error) {
            console.error('Error in handleCheckOverlap:', error);
            showToast({message: `檢查時間時發生錯誤`, type: AlertTypeEnum.Error});
            return {overlaps: true, time};
        }
    }

    useEffect(() => {
        if (data.length > 0) {
            const list = []
            data.map((item, index) => {
                list.push({
                    startDate: item.startDate,
                    startTime: item.startTime.includes(':') ? data[0].startTime : `${data[0].startTime.substring(0, 2)}:${data[0].startTime.substring(2, 4)}`,
                    endDate: item.endDate,
                    endTime: item.endTime.includes(':') ? data[0].endTime : `${data[0].endTime.substring(0, 2)}:${data[0].endTime.substring(2, 4)}`
                })
            })
            setDataList(list)
        } else {
            setDataList([])
        }
    }, []);

    /**
     * 監聽支援開始時間
     * @return {void}
     */
    useEffect(() => {
        // 當支援開始日期和時間都有值時
        if (!stringIsEmpty(itemStartDate) && !stringIsEmpty(itemStartTime)) {
            //只有當支援開始時間為24:00時，支援開始日期才會+1
            if (itemStartTime === '24:00') {
                //取得新的支援開始日期
                const newItemStartDate = dayjs(itemStartDate).add(1, 'day').format('YYYY-MM-DD')
                // 設定新的支援開始日期
                setItemStartDate(newItemStartDate)
                // 設定支援開始時間為0000
                setItemStartTime('00:00')
            }
        }
    }, [itemStartDate, itemStartTime])

    /**
     * 監聽支援結束時間
     * @return {void}
     */
    useEffect(() => {
        // 當支援結束日期和時間都有值時
        if (!stringIsEmpty(itemEndDate) && !stringIsEmpty(itemEndTime)) {
            //只有當支援結束時間為24:00時，支援結束日期才會+1
            if (itemEndTime === '24:00') {
                //取得新的支援結束日期
                const newItemEndDate = dayjs(itemEndDate).add(1, 'day').format('YYYY-MM-DD')
                // 設定新的支援結束日期
                setItemEndDate(newItemEndDate)
                // 設定支援結束時間為0000
                setItemEndTime('00:00')
            }
        }
    }, [itemEndDate, itemEndTime])

    return (
        <Dialog
            open={true}
            title={t('RSIS.Popup.SupportDateChoice')}
            content={
                <>
                    <div className='flex flex-col px-6 py-4 gap-4 justify-center items-start'>
                        {/*起始時間*/}
                        <Field label='起始時間' labelWidth={80}>
                            <div className="flex space-x-2 items-center">
                                <DatePicker
                                    label="開始日期"
                                    disablePast
                                    disabled={continueType === 'C'}
                                    value={dayjs(itemStartDate)}
                                    onChange={handleItemStartDateOnChange}
                                />
                                <Select
                                    data={{
                                        label: '開始時間',
                                        options: getTimeIntervals().map((item) => ({
                                            value: item,
                                            label: item,
                                        }))
                                    }}
                                    value={itemStartTime}
                                    onChange={handleItemStartTime}
                                    width={SelectWidthEnum.Small}
                                    disabled={continueType === 'C'}
                                    displayEmpty={true}
                                    notched={true}
                                />
                                <p>～</p>
                                <DatePicker
                                    label="結束日期"
                                    disablePast
                                    disabled={continueType === 'C'}
                                    value={dayjs(itemEndDate)}
                                    onChange={handleItemEndDateOnChange}
                                />
                                <Select
                                    data={{
                                        label: '結束時間',
                                        options: getTimeIntervals().map((item) => ({
                                            value: item,
                                            label: item,
                                        }))
                                    }}
                                    value={itemEndTime}
                                    onChange={handleItemEndTime}
                                    width={SelectWidthEnum.Small}
                                    disabled={continueType === 'C'}
                                    displayEmpty={true}
                                    notched={true}
                                />
                            </div>
                        </Field>
                        {/*持續形式/週區間/自行選擇日期*/}
                        <div className='flex flex-col items-start justify-center gap-4 my-2'>
                            <RadioGroup
                                row={false} size={SizeEnum.Medium} value={continueType}
                                optionProps={{
                                    options: [
                                        {
                                            label: '持續(ALL)',
                                            value: 'A',
                                        },
                                        {
                                            label:
                                                <div>
                                                    <span className='mr-3'>間斷並重複</span>
                                                    {
                                                        Object.keys(weekDays).map(day => (
                                                            <Checkbox key={day}
                                                                      label={`${weekDays[day]}`}
                                                                      disabled={continueType !== 'B'}
                                                                      checked={continueType === 'B' && weekInterval[day]}
                                                                      onChange={e => setWeekInterval({
                                                                          ...weekInterval, [day]: e.target.checked
                                                                      })}
                                                            />
                                                        ))
                                                    }
                                                </div>,
                                            value: 'B',
                                        },
                                        {
                                            label:
                                                <div className='flex flex-row items-center space-x-4'>
                                                    <span>自行選擇</span>
                                                    <div className="flex flex-row gap-2 items-center">
                                                        <DatePicker
                                                            disablePast
                                                            value={dayjs(customDate)}
                                                            disabled={continueType !== 'C'}
                                                            onChange={handleCustomDate}/>
                                                        <Select
                                                            data={{
                                                                label: '開始時間',
                                                                options: getTimeIntervals().map((item) => ({
                                                                    value: item,
                                                                    label: item,
                                                                }))
                                                            }}
                                                            value={customStartTime}
                                                            onChange={handleCustomStartTime}
                                                            disabled={continueType !== 'C'}
                                                            width={SelectWidthEnum.Small}
                                                            notched={true}
                                                            displayEmpty={true}
                                                        />
                                                        <p>～</p>
                                                        <Select
                                                            data={{
                                                                label: '結束時間',
                                                                options: getTimeIntervals().map((item) => ({
                                                                    value: item,
                                                                    label: item,
                                                                }))
                                                            }}
                                                            value={customEndTime}
                                                            onChange={handleCustomEndTime}
                                                            disabled={continueType !== 'C'}
                                                            width={SelectWidthEnum.Small}
                                                            notched={true}
                                                            displayEmpty={true}
                                                        />
                                                    </div>
                                                </div>,
                                            value: 'C',
                                        },
                                    ]
                                }}
                                onChange={(e) => handleContinueTypeOnChange(e.target.value)}/>
                        </div>
                        {/* 新增按鈕及展開明細 */}
                        <div className='flex flex-row items-center gap-4 mt-2'>
                            {/* 展開明細 */}
                            <button
                                className='border-2 rounded-[6px] border-[#D4D4D8] w-[102px] h-10 hover:bg-primary hover:text-white'
                                onClick={handleOnAdd}>
                                展開明細
                            </button>
                        </div>
                        <CustomTable
                            fields={fields}
                            dataList={dataList}
                            ListItem={List}
                            type='RSISSupportDateChoiceTable'
                            advancedSearch={true}
                            paginationProps={paginationProps}
                        />
                    </div>
                    {/* 刪除彈窗 */}
                    {showSubDeletePopup && (<DeletePopup
                        handleDeletePopupClose={() => setShowSubDeletePopup(false)}
                        handleNotificationDelete={handleOnDelete}
                        currentKey={subFocusIndex}
                    />)}
                </>
            }
            onClose={handleOnClose}
            onConfirm={handleOnConfirm}
            variant={DialogVariant.CONFIRM}
            paperStyleProps={{width: '1162px'}}
            muiDialogContentProps={{dividers: true}}
        />
    )
}

export default SupportDateChoice
