import { useSelector } from 'react-redux'
import A4Print from './A4Print'
import { STRATEGIC_ALLIANCE_MAINTAIN } from '../../constants/referralSystem/maintainAreaHeader'
import { time2String } from 'edah_utils'
import { DATE_FORMAT } from '../../constants/common'

/**
 * 策略聯盟維護 - 列印內容
 * @returns {JSX.Element}
 */ 
const FederalList = () => {
    const printData = useSelector((state) => state.print.printData)
    const booleanToDisplay = (value) => {
        return value ? '是' : '否'
    }
    return (
        <>
            <h1 className="text-xl font-bold text-center">策略聯盟維護</h1>
            <table className="w-full border-collapse text-sm border-t border-[#111111]/15">
                <thead>
                    <tr className="h-[40px] text-[#18181b]">
                        <th className="w-[calc(100%/15)] text-left break-words p-1">
                            院所代碼
                        </th>
                        <th className="w-[calc(100%/15)] text-left break-words p-1">
                            院所名稱
                        </th>
                        <th className="w-[calc(100%/15)] text-left break-words p-1">
                            等級
                        </th>
                        <th className="w-[calc(100%/15)] text-left break-words p-1">
                            郵遞區號
                        </th>
                        <th className="w-[calc(100%/15)] text-left break-words p-1">
                            策略聯盟
                        </th>
                        <th className="w-[calc(100%/15)] text-left break-words p-1">
                            醫療群
                        </th>
                        <th className="w-[calc(100%/15)] text-left break-words p-1 ">
                            支援院所
                        </th>
                        <th className="w-[calc(100%/15)] text-left break-words p-1">
                            科別1
                        </th>
                        <th className="w-[calc(100%/15)] text-left break-words p-1">
                            科別2
                        </th>
                        <th className="w-[calc(100%/15)] text-left break-words p-1">
                            科別3
                        </th>
                        <th className="w-[calc(100%/15)] text-left break-words p-1">
                            科別4
                        </th>
                        <th className="w-[calc(100%/15)] text-left break-words p-1">
                            科別5
                        </th>
                        <th className="w-[calc(100%/15)] text-left break-words p-1">
                            開始日期
                        </th>
                        <th className="w-[calc(100%/15)] text-left break-words p-1">
                            結束時間
                        </th>
                        <th className="w-[calc(100%/15)] text-left break-words p-1">
                            排序
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {printData.map((item, index) => {
                        return (
                            <tr
                                key={index}
                                className="h-[50px] text-[#18181b] "
                            >
                                <td className="text-left break-words p-1">
                                    {item.hospNo}
                                </td>
                                <td className="text-left break-words p-1">
                                    {item.hospName}
                                </td>
                                <td className="text-left break-words p-1">
                                    {item.hospGrade}
                                </td>
                                <td className="text-left break-words p-1">
                                    {item.hospZipCode}
                                </td>
                                <td className="text-left break-words p-1">
                                    {booleanToDisplay(item.federalFlag)}
                                </td>
                                <td className="text-left break-words p-1">
                                    {booleanToDisplay(item.communityFlag)}
                                </td>
                                <td className="text-left break-words p-1">
                                    {booleanToDisplay(item.supportFlag)}
                                </td>
                                <td className="text-left break-words p-1">
                                    {item.suggestDiv01Name}
                                </td>
                                <td className="text-left break-words p-1">
                                    {item.suggestDiv02Name}
                                </td>
                                <td className="text-left break-words p-1">
                                    {item.suggestDiv03Name}
                                </td>
                                <td className="text-left break-words p-1">
                                    {item.suggestDiv04Name}
                                </td>
                                <td className="text-left break-words p-1">
                                    {item.suggestDiv05Name}
                                </td>
                                <td className="text-left break-words p-1">
                                    {time2String(item.effectDate, DATE_FORMAT)}
                                </td>
                                <td className="text-left break-words p-1">
                                    {time2String(item.endDate, DATE_FORMAT)}
                                </td>
                                <td className="text-left break-words p-1">
                                    {item.sortBy}
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </>
    )
}
/**
 * 策略聯盟維護 - 列印
 * @returns {JSX.Element}
 */ 
const PrintFederalList = () => {
    return (
        <A4Print>
            <FederalList />
        </A4Print>
    )
}
export default PrintFederalList
