//Import Function
import React, {useEffect, useRef, useState} from "react"
import DoubleCircleChart from "../../components/Chart/DoubleCircleChart"
import {BaseInput} from "../../components/Input/BaseInput"
import {
    ApiErrorStatusEnum,
    arrayIsEmpty,
    CampusEnum,
    fuzzySearchObjects,
    getLocalStorage,
    InputTypeEnum,
    objectIsEmpty,
    time2String,
    ToastTypeEnum,
    WDISActiveEnum,
    WDISAlarmTimeEnum,
    WDISServiceEnableEnum,
    WDISServiceStatusListTypeEnum
} from "edah_utils/dist"
import EggSwitch from "../../components/Button/EggSwitch"
import {AlertButton} from "../../components/WDIS/ServiceStatusInquiry/AlertButton"
import {AlertPopup} from "../../components/WDIS/ServiceStatusInquiry/AlertPopup"
import {t} from "i18next"
import {wdisQueryServiceMonitorStatus, WdisServiceNotifyQueryBaseData} from "../../api/v1/WDIS"
import useToast from "../../hooks/useToast"

/**
 * 服務狀態查詢頁面
 * @return {JSX.Element}
 */
const ServiceStatusInquiry = () => {
    /**
     * 滑鼠樣式
     */
    const cursorStyle = {cursor: "pointer"}

    /**
     * Show Toast
     */
    const showToast = useToast()

    // 取得分院清單
    const campusList = Object.values(CampusEnum)

    // 顯示模式切換
    const [mode, setMode] = useState(WDISServiceStatusListTypeEnum.Chart)

    //資料
    const [data, setData] = useState([])

    //院區
    const [campus, setCampus] = useState([])

    // 是否活動中：0非活動/1活動中/9全部
    const [active, setActive] = useState(WDISActiveEnum.All)

    // 服務啓用否(0 停止/1 啟用) 啟用否與刪除之欄位
    // 有不同的意思, 例如未來可能做定期檢查 "服務啟用否" 之檢查或清單
    const [serviceEnable, setServiceEnable] = useState(WDISServiceEnableEnum.All)

    // 服務ID
    const [serviceId, setServiceId] = useState('')

    // 服務ID下拉選單
    const [showServiceIdDropdown, setShowServiceIdDropdown] = useState(false)

    // 負責人代號
    const [ownerUserNo, setOwnerUserNo] = useState('')

    // 負責人員下拉選單
    const [showOwnerUserNoDropdown, setShowOwnerUserNoDropdown] = useState(false)

    // 勿擾設定  無設定0, 有設定1  全部9
    const [noalarm, setNoalarm] = useState(WDISAlarmTimeEnum.All)

    //資料最後更新時間
    const [lastDataUpdatedTime, setLastDataUpdatedTime] = useState('')

    // 停止警報 popup控制
    const [showAlertPopup, setShowAlertPopup] = useState(false)

    //進階搜尋
    const [advancedSearch, setAdvancedSearch] = useState('')

    //基礎資料
    const [baseData, setBaseData] = useState([])

    //是否已經取得資料
    const hasFirstFetchedData = useRef(false)


    /**
     * 進階搜尋改變時
     * @param e {event} 事件
     * @return {void}
     */
    const handleAdvancedSearchOnChange = (e) => setAdvancedSearch(e.target.value)

    /**
     * 模式改變時
     * @param mode {WDISServiceStatusListTypeEnum} mode
     * @return {void}
     */
    const handleModeOnChange = (mode) => setMode(mode)

    /**
     * 取得模式是否被選中
     * @param listType {WDISServiceStatusListTypeEnum} listType
     * @return {boolean} 是否被選中
     */
    const getModeChecked = (listType) => mode === listType

    /**
     * 院區改變時
     * @param index {number} index
     * @return {void}
     */
    const handleCampusOnChange = (index) => {
        // 取得當前院區
        const newCampus = [...campus]
        // 設定當前院區值
        newCampus[index] = {...newCampus[index], value: !newCampus[index].value}
        // 設定院區
        setCampus(newCampus)
    }

    /**
     * 服務啓用否改變時
     * @param e {event} 事件
     * @return {void}
     */
    const handleServiceEnableOnChange = (e) => setServiceEnable(e.target.value)

    /**
     * 活動中改變時
     * @param e {event} 事件
     * @return {void}
     */
    const handleActiveOnChange = (e) => setActive(e.target.value)

    /**
     * 服務ID改變時
     * @param e {event} 事件
     * @return {void}
     */
    const handleServiceIdOnChange = (e) => setServiceId(e.target.value)

    /**
     * 服務ID焦點時
     * @return {void}
     */
    const handleServiceIdOnFocus = () => setShowServiceIdDropdown(true)

    /**
     * 服務ID失去焦點時
     * @return {void}
     */
    const handleServiceIdOnBlur = () => setTimeout(() => setShowServiceIdDropdown(false), 200)

    /**
     * 服務名稱選項點擊時
     * @param option {Object} 選項
     * @return {void}
     */
    const handleServiceNameOptionOnClick = (option) => {
        // 值不為空
        if (!objectIsEmpty(option)) {
            setServiceId(option.serviceName)
        }

        // 關閉服務名稱下拉選單
        setShowServiceIdDropdown(false)
    }

    /**
     * 負責人員改變時
     * @param e {event} 事件
     * @return {void}
     */
    const handleOwnerUserNoOnChange = (e) => setOwnerUserNo(e.target.value)

    /**
     *  負責人員取得焦點時
     *  @return {void}
     */
    const handleOwnerUserNoOnFocus = () => setShowOwnerUserNoDropdown(true)

    /**
     *  負責人員失去焦點時
     *  @return {void}
     */
    const handleOwnerUserNoOnBlur = () => setTimeout(() => setShowOwnerUserNoDropdown(false), 200)

    /**
     * 負責人員選項點擊時
     * @param option {Object} 選項
     * @return {void}
     */
    const handleOwnerUserNoOptionOnClick = (option) => {
        // 值不為空
        if (!objectIsEmpty(option)) {
            setOwnerUserNo(option.ownerUserNo)
        }

        // 關閉負責人員下拉選單
        setShowOwnerUserNoDropdown(false)
    }

    /**
     * 勿打擾時段改變時
     * @param e {event} 事件
     * @return {void}
     */
    const handleNoalarmOnChange = (e) => setNoalarm(e.target.value)

    /**
     * 點擊重新整理時
     * @return {void}
     */
    const handleRefreshOnClick = () => getQueryServiceMonitorStatus()

    /**
     * 取得過濾後的資料
     * @return {Array<Object>} 過濾後的資料
     */
    const getFilterData = () => fuzzySearchObjects(data, advancedSearch)

    /**
     * 取得過濾後的服務名稱清單
     * @return {Array<Object>} 過濾後的服務名稱清單
     */
    const getFilterServiceIdList = () => fuzzySearchObjects(baseData.serviceIdAndNameList, serviceId)

    /**
     * 取得過濾後的負責人員清單
     * @return {Array<Object>} 過濾後的負責人員清單
     */
    const getFilterOwnerUserNoList = () => fuzzySearchObjects(baseData.ownerUserSet, ownerUserNo)

    /**
     * 取得清單區塊
     * @return {JSX.Element} 清單區塊
     */
    const getListSection = () => {
        return mode === WDISServiceStatusListTypeEnum.Chart ?
            // 圖形介面
            <div className="maintainContainer flex flex-row flex-wrap items-start justify-start">
                {
                    !arrayIsEmpty(data) && data.map((item, index) =>
                        <div
                            className="w-[375px] border-[1px] bg-white border-[#1111111]/30 rounded-[4px] px-[20px] mr-[30px] mb-4"
                            key={index}>
                            <div
                                className="flex flex-row items-center justify-between pt-[21px] pb-[10px] border-b-[1px] border-[#1111111]/30 mb-[10px]">
                                {/*院區*/}
                                <h3 className="font-bold text-[20px]">{t(`campus.shortName.${item.zone}`)}</h3>
                                {/*標題*/}
                                <p>{item.serviceName}</p>
                                {/*狀態文字*/}
                                <span>{t(`WDIS.ServiceEnableEnum.${item.active}`)}</span>
                                {/*狀態*/}
                                <EggSwitch initialState={item.active === 1 ? true : false}/>
                            </div>
                            <div className="relative w-full flex flex-col items-center justify-center pt-8 pb-5">
                                <div className="absolute left-0 top-0">
                                    <AlertButton alertStatus={'alert'}/>
                                </div>
                                <DoubleCircleChart percentage={item.percent} status={item.active === 1 ? true : false}>
                                    {/*百分比*/}
                                    <div className="font-bold text-[20px]">{item.percent}%</div>
                                    {/*最近執行時間*/}
                                    <div>最近執行時間</div>
                                    <div>{item.latestExecDatetime}</div>
                                    <div>執行時長: {item.sec} 秒</div>
                                </DoubleCircleChart>
                                <div
                                    className="text-center mt-[20px]">負責人員：{item.ownerUserNo.replace(/[0-9\s]/g, '')}</div>
                            </div>
                        </div>
                    )
                }
            </div> :
            // 列表方式
            <div className="maintainContainer flex flex-row items-start justify-start">
                <div className="p-4 bg-white w-full border-[1px] border-[#D4D4D8] rounded-[12px] mr-4">
                    {/*進階搜尋*/}
                    <div className="searchGroup flex flex-row items-center justify-between mb-4">
                        <BaseInput className="w-[320px] h-10 pl-4 border-[2px] border-[#D4D4D8] rounded-[6px]"
                                   type="text" placeholder={t('general.advancedSearch')} inputMode="search"
                                   value={advancedSearch} onChange={handleAdvancedSearchOnChange}/>
                    </div>
                    <ul className="tableList SSTableList">
                        <li className="title">
                            <div>報警中</div>
                            <div>院區</div>
                            <div>負責人</div>
                            <div>服務代號 <br/> 名稱</div>
                            <div>活動狀態</div>
                            <div>最近執行時間</div>
                            <div>最近執行時長</div>
                            <div>最近報警時間</div>
                            <div>勿打擾時段</div>

                        </li>
                        {
                            !arrayIsEmpty(data) && getFilterData().map((item, index) =>
                                <li key={index}>
                                    {/*狀態*/}
                                    <div><AlertButton alertStatus={'alert'}/></div>
                                    {/*院區*/}
                                    <div>{t(`campus.shortName.${item.zone}`)}</div>
                                    {/*負責人*/}
                                    <div>{item.ownerUserNo.replace(/[0-9\s]/g, '')}</div>
                                    {/*服務代號 名稱*/}
                                    <div>{item.serviceId}<br/>{item.serviceName}</div>
                                    {/*活動狀態*/}
                                    <div>{t(`WDIS.ServiceEnableEnum.${item.active}`)}</div>
                                    {/*最近執行時間*/}
                                    <div>{item.latestExecDatetime}</div>
                                    {/*最近執行時長*/}
                                    <div>{item.sec} 秒</div>
                                    {/*最近報警時間*/}
                                    <div>{item.latestAlarmDatetime}</div>
                                    {/*勿打擾時段*/}
                                    <div>{t(`WDIS.NoAlarmEnum.${item.noalarm}`)}</div>
                                </li>
                            )
                        }
                    </ul>
                </div>
            </div>
    }

    /**
     * 取得服務監控狀態
     * @return {void}
     */
    const getQueryServiceMonitorStatus = () => {
        // 取得分院
        const zone = campus.filter(item => item.value === true).map(item => item.key)
        wdisQueryServiceMonitorStatus({
            // 分院
            zone: zone.join(','),
            // 是否活動中：0非活動/1活動中/9全部
            active: active,
            // 服務啓用否(0 停止/1 啟用) 啟用否與刪除之欄位
            // 有不同的意思, 例如未來可能做定期檢查 "服務啟
            //  用否" 之檢查或清單
            serviceEnable: serviceEnable,
            // 服務ID
            serviceId: serviceId,
            // 負責人代號
            ownerUserNo: ownerUserNo,
            //勿擾設定  無設定0, 有設定1  全部9
            noalarm: noalarm,
        }).then(res => {
            // 取得錯誤 / 資料 / 訊息
            const {err, data, msg} = res
            // 執行成功
            if (err === ApiErrorStatusEnum.Success) {
                // 避免後續重複取得資料
                hasFirstFetchedData.current = true
                // 設定資料
                setData(data)
                // 設定最後更新時間
                setLastDataUpdatedTime(time2String(new Date(), 'HH:mm:ss'))
            } else { // 執行失敗
                // 顯示錯誤訊息
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 取得基本資料
     * @return {void}
     */
    const getQueryBaseData = () => {
        WdisServiceNotifyQueryBaseData({}).then(res => {
            // 取得錯誤 / 資料 / 訊息
            const {err, data, msg} = res
            // 執行成功
            if (err === ApiErrorStatusEnum.Success) {
                // 設定基本資料
                setBaseData(data)
            } else {// 執行失敗
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 第一次執行時
     * @return {void}
     */
    useEffect(() => {
        // 建立院區
        const createCampus = campusList.map(item => ({key: item, value: false}))
        // 取得當前角色
        const currentCampus = getLocalStorage('campus')
        const newCampus = createCampus.map(item => item.key === currentCampus ? {...item, value: true} : item)
        // 設定當前角色
        setCampus(newCampus)
        // 取得基本資料
        getQueryBaseData()

    }, [])

    /**
     * 當院區改變時
     * @return {void}
     */
    useEffect(() => {
        if (!arrayIsEmpty(campus)) {
            // 是否為第一次取得資料
            if (!hasFirstFetchedData.current) {
                // 取得服務監控狀態清單
                getQueryServiceMonitorStatus()
            }
        }
    }, [campus])


    return (
        <div className="w-full p-4 bg-[#FAFAFA]">
            {/*篩選清單*/}
            <div className="filterGroup w-full flex flex-row flex-wrap items-center justify-start mb-4 relative">
                <div className="flex flex-row items-center justify-start mr-4">
                    {/*圖形介面*/}
                    <div onClick={() => handleModeOnChange(WDISServiceStatusListTypeEnum.Chart)}>
                        <input
                            className="mr-4 appearance-none ring-2 w-2 h-2 ring-gray-200 rounded-full ring-offset-4 checked:bg-blue-400 checked:ring-blue-400"
                            id="chartRadio" name="modeRadio"
                            checked={getModeChecked(WDISServiceStatusListTypeEnum.Chart)}
                            onChange={() => {
                            }}
                            type="radio" style={cursorStyle}/>
                        <label htmlFor="chartRadio" className="mr-4" style={cursorStyle}>
                            圖形介面
                        </label>
                    </div>
                    {/*列表方式*/}
                    <div onClick={() => handleModeOnChange(WDISServiceStatusListTypeEnum.List)}>
                        <input
                            className="mr-4 appearance-none ring-2 w-2 h-2 ring-gray-200 rounded-full ring-offset-4 checked:bg-blue-400 checked:ring-blue-400"
                            id="listRadio" name="modeRadio" checked={getModeChecked(WDISServiceStatusListTypeEnum.List)}
                            onChange={() => {
                            }}
                            type="radio" style={cursorStyle}/>
                        <label htmlFor="listRadio" className="mr-4" style={cursorStyle}>
                            列表方式
                        </label>
                    </div>
                </div>
                {
                    // 顯示院區多選
                    !arrayIsEmpty(campus) && campus.map((item, index) =>
                        <div className="flex flex-row space-x-3 mb-4 mr-4" key={index}>
                            <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5" type="checkbox"
                                   id={`r${index}`} style={{accentColor: "#38A169"}} checked={campus[index].value}
                                   onChange={() => handleCampusOnChange(index)}/>
                            <label className="cursor-pointer" htmlFor={`r${index}`}>
                                {t(`campus.shortName.${item.key}`)}
                            </label>
                        </div>
                    )
                }
                <div className="flex flex-row items-center absolute top-0 right-8">
                    {/*重整按鈕*/}
                    <button
                        className="flex items-center justify-center h-10 font-medium px-4 mr-2 border-[2px] rounded-[6px]"
                        onClick={handleRefreshOnClick}>
                        重整
                    </button>
                    <p>{t('WDIS.ServiceStatus.lastDataUpdatedTime', {lastDataUpdatedTime: lastDataUpdatedTime})}</p>
                </div>
                <div className="flex flex-row items-center justify-start mt-4 mr-4 w-full">
                    {/*服務啓用否(0 停止 /1 啟用 / 9 全部) 啟用否與刪除之欄位有不同的意思, 例如未來可能做定期檢查 "服務啟用否" 之檢查或清單*/}
                    <select className="w-[100px] h-10 px-2 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                            value={serviceEnable} onChange={handleServiceEnableOnChange}>
                        {
                            Object.keys(WDISServiceEnableEnum)
                                .filter(key => isNaN(Number(key)))
                                .map((key, index) =>
                                    <option key={index} value={WDISServiceEnableEnum[key]}>
                                        {t(`WDIS.ServiceEnableEnum.${WDISServiceEnableEnum[key]}`)}
                                    </option>
                                )
                        }
                    </select>
                    {/*狀態*/}
                    <select className="w-[110px] h-10 px-2 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                            value={active} onChange={handleActiveOnChange}>
                        {
                            Object.keys(WDISActiveEnum)
                                .filter(key => isNaN(Number(key)))
                                .map((key, index) =>
                                    <option key={index} value={WDISActiveEnum[key]}>
                                        {t(`WDIS.activeEnum.${WDISActiveEnum[key]}`)}
                                    </option>
                                )
                        }
                    </select>
                    {/*服務ID*/}
                    <p className="mr-4">服務名稱：</p>
                    <div className="flex flex-row space-x-2 items-center relative w-[186px] mr-4">
                        <BaseInput className={`w-[186px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4`}
                                   type={InputTypeEnum.Text} inputMode={'search'} value={serviceId}
                                   onChange={handleServiceIdOnChange}
                                   onFocus={handleServiceIdOnFocus}
                                   onBlur={handleServiceIdOnBlur}/>
                        {
                            showServiceIdDropdown && !objectIsEmpty(baseData) && !arrayIsEmpty(baseData.serviceIdAndNameList) &&
                            (
                                <div
                                    className="bg-white z-10 absolute left-5 border border-[#d4d4d8] rounded-md top-8 w-64 flex flex-col">
                                    <div className="max-h-[200px] bg-transparent overflow-y-auto px-2.5">
                                        {
                                            getFilterServiceIdList().map((item, index) =>
                                                <div
                                                    className="bg-transparent border-b min-h-10 flex space-x-6 px-4 py-1 text-[#18181B] items-center"
                                                    role="button" key={index}
                                                    onClick={() => handleServiceNameOptionOnClick(item)}>
                                                    <p>{`${item.serviceName}`}</p>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    {/*負責人員*/}
                    <p className="mr-4">負責人員：</p>
                    <div className="flex flex-row space-x-2 items-center relative w-[186px] mr-4">
                        <BaseInput className={`w-[186px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4`}
                                   type={InputTypeEnum.Text} inputMode={'search'} value={ownerUserNo}
                                   onChange={handleOwnerUserNoOnChange}
                                   onFocus={handleOwnerUserNoOnFocus}
                                   onBlur={handleOwnerUserNoOnBlur}/>
                        {
                            showOwnerUserNoDropdown && !objectIsEmpty(baseData) && !arrayIsEmpty(baseData.ownerUserSet) && (
                                <div
                                    className="bg-white z-10 absolute left-5 border border-[#d4d4d8] rounded-md top-8 w-64 flex flex-col">
                                    <div className="max-h-[200px] bg-transparent overflow-y-auto px-2.5">
                                        {
                                            getFilterOwnerUserNoList().map((item, index) =>
                                                <div
                                                    className="bg-transparent border-b min-h-10 flex space-x-6 px-4 py-1 text-[#18181B] items-center"
                                                    role="button" key={index}
                                                    onClick={() => handleOwnerUserNoOptionOnClick(item)}>
                                                    <p>{`${item.ownerUserNo}`}</p>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    {/*勿打擾時段*/}
                    <p className="mr-4">勿打擾時段：</p>
                    <select className="w-[100px] h-10 px-2 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                            value={noalarm} onChange={handleNoalarmOnChange}>
                        {
                            Object.keys(WDISAlarmTimeEnum)
                                .filter(key => isNaN(Number(key)))
                                .map((key, index) =>
                                    <option key={index} value={WDISAlarmTimeEnum[key]}>
                                        {t(`WDIS.WDISAlarmTimeEnum.${WDISAlarmTimeEnum[key]}`)}
                                    </option>
                                )
                        }
                    </select>
                </div>
            </div>
            {
                // 取得清單區塊
                getListSection()
            }
            <AlertPopup/>
        </div>
    )
}
export default ServiceStatusInquiry
