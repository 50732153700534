import { useSelector } from 'react-redux'
import A4Print from './A4Print'
import { time2String } from 'edah_utils'
import { DATE_FORMAT } from '../../constants/common'

/**
 * 轉診代碼維護 - 列印內容
 * @returns {JSX.Element}
 */
const ReferralCodeListContent = () => {
    const printData = useSelector((state) => state.print.printData)
    return (
        <>
            <h1 className="text-xl font-bold text-center">轉診代碼維護</h1>
            <table className="w-full border-collapse text-sm border-t border-[#111111]/15">
                <thead>
                    <tr className="h-[40px] text-[#18181b]">
                        <th className="w-[100px] text-left break-words p-1">
                            類別代碼
                        </th>
                        <th className="w-[100px] text-left break-words p-1">
                            類別名稱
                        </th>
                        <th className="w-[100px] text-left break-words p-1">
                            項目代碼
                        </th>
                        <th className="w-[100px] text-left break-words p-1">
                            項目名稱
                        </th>
                        <th className="w-[100px] text-left break-words p-1">
                            上傳碼
                        </th>
                        <th className="w-[100px] text-left break-words p-1">
                            開始日期
                        </th>
                        <th className="w-[100px] text-left break-words p-1">
                            結束時間
                        </th>
                        <th className="w-[100px] text-left break-words p-1">
                            排序
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {printData.map((item, index) => {
                        return (
                            <tr
                                key={index}
                                className="h-[50px] text-[#18181b] "
                            >
                                <td className="text-left break-words p-1">
                                    {item.codeType}
                                </td>
                                <td className="text-left break-words p-1">
                                    {item.codeTypeName}
                                </td>
                                <td className="text-left break-words p-1">
                                    {item.codeNo}
                                </td>
                                <td className="text-left break-words p-1">
                                    {item.codeNoName}
                                </td>
                                <td className="text-left break-words p-1">
                                    {item.uploadCodeNo}
                                </td>
                                <td className="text-left break-words p-1">
                                    {time2String(
                                        item.effectiveDate,
                                        DATE_FORMAT
                                    )}
                                </td>
                                <td className="text-left break-words p-1">
                                    {time2String(item.endDate, DATE_FORMAT)}
                                </td>
                                <td className="text-left break-words p-1">
                                    {item.sortBy}
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </>
    )
}
/**
 * 轉診代碼維護 - 列印
 * @returns {JSX.Element}
 */
const PrintReferralCodeList = () => {
    return (
        <A4Print>
            <ReferralCodeListContent />
        </A4Print>
    )
}
export default PrintReferralCodeList
