import React, {useMemo, useState} from "react"
import {
    ApiErrorStatusEnum,
    InputTypeEnum,
    objectIsEmpty,
    stringIsEmpty,
    time2String
} from "edah_utils/dist"
import {queryAllDivision, queryAllDoctor} from "../../api/v1/Menu"
import {queryPatientByPatientIdForClinic} from "../../api/v1/Mris"
import AppointmentRegistrationPopup
    from "../../components/Regs/DoctorOrderAppointmentRegistration/AppointmentRegistrationPopup"
import useToast from "../../hooks/useToast"
import {
    AlertTypeEnum,
    Button,
    ButtonSizeEnum,
    ButtonVariantEnum,
    ColorEnum,
    DatePicker,
    Field,
    Search,
    SizeEnum,
    TextField
} from "edah-component/dist"
import dayjs, {Dayjs} from "dayjs";
import {t} from "i18next";

/**
 * 醫囑預約掛號作業
 * @return {JSX.Element}
 */
const DoctorOrderAppointmentRegistration = () => {
    const [inputData, setInputData] = useState({
        //門診日期
        encounterDate: "",
        //病歷號
        patientId: "",
        //科別
        div: "",
        //醫師
        doctor: "",
        //天數
        day: 1,
        //病人身份(先強制設為健保)
        ptType: 21
    })
    // 病人資料
    const [patientData, setPatientData] = useState(null)
    // 所有醫生
    const [allDoctor, setAllDoctor] = useState(null)
    // 所有科別
    const [allDept, setAllDept] = useState(null)
    // 醫師下拉選項
    const [doctorOptionList, setDoctorOptionList] = useState(null)
    // 科別下拉選項
    const [deptOptionList, setDeptOptionList] = useState(null)
    //顯示預約掛號彈出窗
    const [showAppointmentRegistrationPopup, setShowAppointmentRegistrationPopup] = useState(false)

    //Toast Message Hooks
    const showToast = useToast()

    /**
     * 取得醫師清單
     * @return {void}
     */
    const getQueryAllDoctor = () => {
        queryAllDoctor({}).then(res => {
            //狀態/資料/訊息
            const {err, data, msg} = res
            //取得成功
            if (err === ApiErrorStatusEnum.Success) {
                // 設定醫師清單資料
                setAllDoctor(data)
            } else {
                // 清空醫師清單
                setAllDoctor([])
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 取得科別清單
     * @return {void}
     */
    const getQueryAllDept = () => {
        queryAllDivision({}).then(res => {
            //狀態/資料/訊息
            const {err, data, msg} = res
            //取得成功
            if (err === ApiErrorStatusEnum.Success) {
                // 設定醫師清單資料
                setAllDept(data)
            } else {
                // 清空醫師清單
                setAllDept([])
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 開診日期方框的值變動時
     * @return {void}
     */
    const handleInputEncounterDateOnChange = (newDate) => {
        setInputData({...inputData, encounterDate: newDate ? newDate.format('YYYY-MM-DD') : ""})
    }

    /**
     * 病歷號方框的值變動時
     * @return {void}
     */
    const handleInputPatientIdOnChange = (e) => {
        setInputData({...inputData, patientId: e.target.value})
    }

    /**
     * 醫師代號方框的值變動時
     * @return {void}
     */
    const handleInputDoctorNoOnChange = (e, value) => {
        setInputData({...inputData, doctor: value})
    }

    /**
     * 選取科別下拉選項時
     * @param item {Object} 選取的選項
     * @return {void}
     */
    const handleDeptOptionOnClick = (item) => {
        const value = item?.divNo && item?.divName ? `${item?.divNo} ${item?.divName}` : ''
        setInputData({
            ...inputData,
            div: value
        })
    }

    /**
     * 選取醫師下拉選項時
     * @param item {Object} 選取的選項
     * @return {void}
     */
    const handleDoctorOptionOnClick = (item) => {
        const value = item?.userNo && item?.userName ? `${item?.userNo} ${item?.userName}` : ''
        setInputData({
            ...inputData,
            doctor: value
        })
    }

    /**
     * 天數輸入框Input事件
     * @return {void}
     */
    const handleInputDayOnInput = (e) => {
        const value = e.target.value
        // 移除所有非數字字符
        e.target.value = value.replace(/[^0-9]/g, '')
    }

    /**
     * 天數方框的值變動時
     * @return {void}
     */
    const handleInputDayOnChange = (e) => {
        setInputData({...inputData, day: e.target.value})
    }

    /**
     * 確認按鈕點擊事件
     * @returns
     */
    const handleConfirmOnClick = () => {
        if (stringIsEmpty(inputData.encounterDate)) {
            showToast({message: '門診日期不可為空', type: AlertTypeEnum.Error})
            return
        }
        if (stringIsEmpty(inputData.patientId)) {
            showToast({message: '病歷號不可為空', type: AlertTypeEnum.Error})
            return
        }
        if (stringIsEmpty(inputData.div)) {
            showToast({message: '科別不可為空', type: AlertTypeEnum.Error})
            return
        }
        if (stringIsEmpty(inputData.doctor)) {
            showToast({message: '醫師不可為空', type: AlertTypeEnum.Error})
            return
        }

        if (stringIsEmpty(inputData.day.toString())) {
            showToast({message: '天數不可為空', type: AlertTypeEnum.Error})
            return
        }

        queryPatientByPatientIdForClinic({
            patientId: inputData.patientId
        }).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            // 取得資料成功
            if (err === ApiErrorStatusEnum.Success) {

                if (objectIsEmpty(data)) {
                    showToast({message: '找不到此病歷號', type: AlertTypeEnum.Warning})
                } else {
                    setPatientData({
                        patientId: data.patientId,
                        idNo: data.idNo,
                        patientName: data.patientName,
                        birthDate: data.birthDate,
                        age: data.age
                    })
                    setShowAppointmentRegistrationPopup(true)
                }
            } else { // 取得資料失敗
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })


    }

    /**
     * 關閉預約掛號彈出窗
     */
    const handleCloseAppointmentRegistrationPopup = () => {
        setShowAppointmentRegistrationPopup(false)
    }

    // 第一次執行時
    useMemo(() => {
        // 取得醫師清單
        getQueryAllDoctor()

        getQueryAllDept()

        setInputData({
            ...inputData,
            encounterDate: time2String(new Date(), "YYYY-MM-DD")
        })
    }, [])

    return (
        <div className="h-[calc(100vh-110px)] items-center flex justify-center w-full">
            <div className="flex flex-col gap-5 rounded-md py-14 pl-10 pr-10 border border-[#D4D4D8]">
                {/*門診日期*/}
                {/* <div className='flex flex-row h-[40px]'> */}
                <Field label="門診日期" isRequired>
                    <DatePicker
                        label=""
                        required
                        value={dayjs(inputData.encounterDate)}
                        onChange={handleInputEncounterDateOnChange}/>
                </Field>
                {/* </div> */}

                {/*病歷號*/}
                <Field label={t('general.patientNo')} isRequired>
                    <TextField
                        inputWidth={SizeEnum.Fill}
                        value={inputData.patientId}
                        onChange={handleInputPatientIdOnChange}
                        required
                        error={stringIsEmpty(inputData.patientId)}
                    />
                </Field>
                {/*科別*/}
                <Field label="科別" isRequired>
                    {allDept && <Search
                        inputWidth={SizeEnum.Fill}
                        inputLabel=''
                        onChange={(_e, value) => handleDeptOptionOnClick(value)}
                        disablePortal={false}
                        clearOnBlur
                        options={allDept}
                        getOptionLabel={(option) => `${option.divNo} ${option.divName}`}/>}
                </Field>

                {/*醫師*/}
                <Field label="醫師" isRequired>
                    {allDoctor && <Search
                        inputWidth={SizeEnum.Fill}
                        inputLabel=''
                        clearOnBlur
                        onChange={(_e, value) => handleDoctorOptionOnClick(value)}
                        onInputChange={(e, value) => handleInputDoctorNoOnChange(e, value)}
                        disablePortal={false}
                        options={allDoctor}
                        getOptionLabel={(option) => `${option.userNo} ${option.userName}`}/>}
                </Field>

                {/*天數*/}
                <Field label="天數" isRequired>
                    <TextField
                        inputWidth={SizeEnum.Fill}
                        type={InputTypeEnum.Number}
                        value={inputData.day}
                        min={0}
                        onInput={handleInputDayOnInput}
                        onChange={handleInputDayOnChange}
                        error={stringIsEmpty(inputData.day.toString())}
                    />
                </Field>

                <div className="">
                    <Button
                        color={ColorEnum.Primary} variant={ButtonVariantEnum.Contained}
                        size={ButtonSizeEnum.Medium}
                        onClick={handleConfirmOnClick}>
                        {t('general.confirm')}
                    </Button>
                </div>
            </div>
            <AppointmentRegistrationPopup
                show={showAppointmentRegistrationPopup}
                closePopupButtonOnClick={handleCloseAppointmentRegistrationPopup}
                patientData={patientData}
                baseQueryCondition={inputData}
                doctorArray={allDoctor}
                deptArray={allDept}/>
        </div>
    )
}

export default DoctorOrderAppointmentRegistration
