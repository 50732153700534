import {v1Api} from "../Http"
import {HttpMethodEnum} from "edah_utils/dist"

/**
 * 取得角色Menu清單
 * @param params params
 * @returns {AxiosPromise}
 */
const querySystemByRole = (params) => v1Api.request({
    url: '/install/userRole/querySystemByRole',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 查詢所有醫生
 * @param params params
 * @returns {AxiosPromise}
 */
const queryAllDoctor = (params) => v1Api.request({
    url: '/install/doctor/queryAllDoctor',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 查詢所有科別
 * @param params params
 * @returns {AxiosPromise}
 */
const queryAllDivision = (params) => v1Api.request({
    url: '/install/division/queryAllDivision',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 依類別查詢清單
 * @param params params
 * @returns {AxiosPromise}
 */
const queryJustNameByCategories = (params) => v1Api.request({
    url: '/install/justnamed/queryjustnameByCategories',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 查詢假日檔(整個月)
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const queryHolidayByYearMonth = (params) => v1Api.request({
    url: '/install/holiday/queryHolidayByYearMonth',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 查詢假日檔(整年)
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const queryHolidayByYear = (params) => v1Api.request({
    url: '/install/holiday/queryHolidayByYear',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 查詢假日檔
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const queryHoliday = (params) => v1Api.request({
    url: '/install/holiday/queryHoliday',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 假日檔存檔
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const updateHoliday = (data) => v1Api.request({
    url: '/install/holiday/updateHoliday',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 刪除假日檔
 * @param data {Object} data
 * @returns {AxiosPromise}
 */
const delHoliday = (data) => v1Api.request({
    url: '/install/holiday/delHoliday',
    method: HttpMethodEnum.DELETE,
    data
})

/**
 * 查詢全國醫療院所
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const queryAllHospital = (params) => v1Api.request({
    url: '/install/hospinfo/queryHospinfoList',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 查詢全國醫療院所(院所代號)
 * @param params params
 * @returns {AxiosPromise}
 */
const queryByHospNo = (params) => v1Api.request({
    url: '/install/hospinfo/queryByHospNo',
    method: HttpMethodEnum.GET,
    params
})
/**
 * 刪除類別維護master&detail
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const deleteCategories = (params) => v1Api.request({
    url: '/install/justnamem/deleteJustnameMAndDByCategories',
    method: HttpMethodEnum.DELETE,
    params
})

/**
 * 查詢人員部門
 * @param params params
 * @returns {AxiosPromise}
 */
const queryDepartmentByUserNo = (params) => v1Api.request({
    url: '/install/department/queryDepartmentByUserNo',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 查詢所有院區
 * @param params {Object}
 * @return {AxiosPromise}
 */
const zoneSetQueryAll = (params) => v1Api.request({
    url: '/install/zoneset/queryAll',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 查詢當前登入院區代號
 * @param params {Object}
 * @return {AxiosPromise}
 */
const zoneSetQueryZoneNo = (params) => v1Api.request({
    url: '/install/zoneset/query',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 檢核醫師是否有符合資料
 * @param params {Object}
 * @return {AxiosPromise}
 */
const checkDoctorLicence = (params) => v1Api.request({
    url: '/install/licence/queryLicenceByDoctorNo',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 確認登入人員有無加掛權限
 * @param params {Object}
 * @return {AxiosPromise}
 */
const checkRegsAddPermissionsByLoginUser = (params) => v1Api.request({
    url: '/install/user/queryRegsAddPermissionsByLoginUser',
    method: HttpMethodEnum.GET,
    params
})

export {
    querySystemByRole,
    queryAllDoctor,
    queryAllDivision,
    queryJustNameByCategories,
    queryHolidayByYearMonth,
    queryHolidayByYear,
    queryHoliday,
    updateHoliday,
    delHoliday,
    queryAllHospital,
    queryByHospNo,
    queryDepartmentByUserNo,
    deleteCategories,
    zoneSetQueryAll,
    zoneSetQueryZoneNo,
    checkDoctorLicence,
    checkRegsAddPermissionsByLoginUser
}
