// PopupContext.js
import React, {createContext, useState, useContext} from 'react'
import useToast from "../../hooks/useToast"

// 創建上下文
const PopupContext = createContext();

// 創建提供程序組件
export const PopupProvider = ({children}) => {
    /**
     * Show Toast
     */
    const showToast = useToast()
    //收案確認modal顯示狀態
    const [showCaseConfirmation, setShowCaseConfirmation] = useState(false);
    //報備支援內容顯示modal顯示狀態
    const [showContentView, setShowContentView] = useState(false);
    //報備支援新增顯示modal狀態
    const [showAddModal, setShowAddModal] = useState(false);
    //報備支援複製modal顯示狀態
    const [showCopyModal, setShowCopyModal] = useState(false);
    //報備支援修改-編輯modal顯示狀態
    const [showEditModal, setShowEditModal] = useState(false);
    //報備支援修改-變更modal顯示狀態
    const [showChangeModal, setShowChangeModal] = useState(false);
    //報備支援申請註銷作業-modal顯示狀態
    const [showLogOffModal, setShowLogOffModal] = useState(false);
    //個案管理批次作業選擇狀態
    const [batchSelected, setBatchSelected] = useState(null)
    //整批變更modal顯示狀態
    const [showBatchesChange, setShowBatchesChange] = useState(false);
    // 確認使用者是否送衛福部彈窗
    const [showSendMOHWConfirm, setShowSendMOHWConfirm] = useState(false);
    // 清空批次選擇
    const [cleanBatchSelected, setCleanBatchSelected] = useState(false)
    //整批註銷modal顯示狀態
    const [showBatchesLogOff, setShowBatchesLogOff] = useState(false);
    //支援機構維護-查詢modal顯示狀態
    const [showQuerySupportOrgModal, setShowQuerySupportOrgModal] = useState(false);
    //支援機構維護-編輯modal顯示狀態
    const [showEditSupportOrgModal, setShowEditSupportOrgModal] = useState(false);
    // 查詢變更紀錄-modal顯示狀態
    const [showChangeRecordModal, setShowChangeRecordModal] = useState(false);
    //特殊權限維護modal顯示狀態
    const [showSpecialPermissionMaintain, setShowSpecialPermissionMaintain] = useState(false);
    //查詢全國醫療院所
    const [showQueryHospitalModal, setShowQueryHospitalModal] = useState(false);
    //功能操作說明維護_編輯modal顯示狀態
    const [showFunctionOperateIntroEditModal, setShowFunctionOperateIntroEditModal] = useState(false);
    //報備支援-支援日期時間選擇modal顯示狀態
    const [showSupportDateChoice, setShowSupportDateChoice] = useState(false);
    // 人員清單查詢modal顯示狀態
    const [showQueryPersonList, setShowQueryPersonList] = useState(false);
    // 承辦部門人員查詢modal顯示狀態
    const [showQueryContactPerson, setShowQueryContactPerson] = useState(false);
    // 所有人員清單查詢
    const [showQueryCreateUserList, setShowQueryCreateUserList] = useState(false);
    //主程式刪除列表popup顯示狀態
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    //彈窗刪除列表popup顯示狀態
    const [showSubDeletePopup, setShowSubDeletePopup] = useState(false);
    //列表當前按鈕狀態
    const [listItemBtnType, setListItemBtnType] = useState(null);
    //列表當前焦點索引
    const [focusIndex, setFocusIndex] = useState(null);
    //子彈窗當前焦點索引
    const [subFocusIndex, setSubFocusIndex] = useState(null);
    //Excel當前焦點索引
    const [excelFocusIndex, setExcelFocusIndex] = useState(null);
    // 顯示匯出檔案名稱輸入modal
    const [showExportFileNameInputModal, setShowExportFileNameInputModal] = useState(false);
    // 準備好可以存擋匯出
    const [isReadyForExport, setIsReadyForExport] = useState(false);
    //當前焦點物件
    const [focusItem, setFocusItem] = useState(null);

    return (
        <PopupContext.Provider value={{
            showCaseConfirmation,
            setShowCaseConfirmation,
            showContentView,
            setShowContentView,
            showAddModal,
            showCopyModal,
            setShowCopyModal,
            setShowAddModal,
            showDeletePopup,
            setShowDeletePopup,
            showSubDeletePopup,
            setShowSubDeletePopup,
            showEditModal,
            setShowEditModal,
            showChangeModal,
            setShowChangeModal,
            batchSelected,
            setBatchSelected,
            showSendMOHWConfirm,
            setShowSendMOHWConfirm,
            showBatchesChange,
            setShowBatchesChange,
            showBatchesLogOff,
            setShowBatchesLogOff,
            cleanBatchSelected,
            setCleanBatchSelected,
            showQuerySupportOrgModal,
            setShowQuerySupportOrgModal,
            showEditSupportOrgModal,
            setShowEditSupportOrgModal,
            showChangeRecordModal,
            setShowChangeRecordModal,
            showQueryHospitalModal,
            setShowQueryHospitalModal,
            showSpecialPermissionMaintain,
            setShowSpecialPermissionMaintain,
            showFunctionOperateIntroEditModal,
            setShowFunctionOperateIntroEditModal,
            showSupportDateChoice,
            setShowSupportDateChoice,
            showQueryPersonList,
            setShowQueryPersonList,
            showQueryContactPerson,
            setShowQueryContactPerson,
            showQueryCreateUserList,
            setShowQueryCreateUserList,
            listItemBtnType,
            setListItemBtnType,
            showExportFileNameInputModal,
            setShowExportFileNameInputModal,
            isReadyForExport,
            setIsReadyForExport,
            showLogOffModal,
            setShowLogOffModal,
            focusIndex,
            setFocusIndex,
            subFocusIndex,
            setSubFocusIndex,
            excelFocusIndex,
            setExcelFocusIndex,
            focusItem,
            setFocusItem,
            showToast
        }}>
            {children}
        </PopupContext.Provider>
    );
};

// 創建自定義 hook 以便於使用上下文
export const usePopup = () => useContext(PopupContext);
