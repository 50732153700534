import React, {useEffect, useState} from "react";
import {t, use} from "i18next";
import {BaseInput} from "../../components/Input/BaseInput";
import {ReactComponent as Check} from "../../assets/images/icons/checkbox.svg"
import {arrayIsEmpty, fuzzySearchObjects} from "edah_utils/dist"
import {Button, ButtonSizeEnum, ButtonVariantEnum, ButtonColorEnum, DateRangePicker} from "edah-component/dist";
import dayjs from "dayjs";

/**
 * 居家護理=>居護個案基本資料API
 * @return {JSX.Element}
 */
const HomeHealthCareInfoAPI = () => {
    // 搜尋文字
    const [searchText, setSearchText] = useState('')
    // 開始日期
    const [startDate, setStartDate] = useState('')
    // 結束日期
    const [endDate, setEndDate] = useState('')
    // Fake Data
    const [dataObject, setDataObject] = useState([
        {
            dataDate: '2023-12-12', //資料日期
            homeNo: '01972', //家字號
            patientId: '0801234', //病歷號
            patientName: '黃ＯＯ', //姓名
            gender: '女', //性別
            caseStartClerk: '陳ＯＯ', //收案人員
            caseStartDate: '2023-09-12', //收案日期
            status: '住院(C)', //個案動態
            code: '3C01', //床號
            levelType: 'S2', //級別
            rn: '陳ＯＯ', //護理師
            updateDate: 'yyyy-MM-dd', //上傳日期時間
            updateResult: 'OK', //上傳結果
        },
        {
            dataDate: '2024-01-01', //資料日期
            homeNo: '01973', //家字號
            patientId: '0804567', //病歷號
            patientName: '劉ＯＯ', //姓名
            gender: '男', //性別
            caseStartClerk: '王ＯＯ', //收案人員
            caseStartDate: '2024-01-01', //收案日期
            status: '住院(C)', //個案動態
            code: '2C02', //床號
            levelType: 'S3', //級別
            rn: '郝ＯＯ', //護理師
            updateDate: 'yyyy-MM-dd', //上傳日期時間
            updateResult: 'OK', //上傳結果
        }
    ]);

    /**
     * 搜尋文字改變時
     * @param e {Event} e
     * @return {void}
     */
    const handleSearchTextOnChange = (e) => setSearchText(e.target.value)

    /**
     * 取得模糊匹配後的資料陣列
     * @return {Array<Object>}
     */
    const getFilterData = (data) => fuzzySearchObjects(data, searchText)

    /**
     * 日期範圍改變時
     * @param dateRange {Array} [startDate, endDate]
     * @return {void}
     */
    const handleDateRangeChange = (dateRange) => {
        const [start, end] = dateRange;
        setStartDate(start ? start.toDate() : null);
        setEndDate(end ? end.toDate() : null);
    };


    return (
        <div className="w-full p-4 bg-[#FAFAFA]">
            {/* 篩選清單 */}
            <div className="flex flex-row flex-wrap items-center justify-between">
                <div className="flex flex-row items-center justify-start space-x-2 py-2">
                    {/* 資料起訖區間 */}
                    <DateRangePicker
                        localeText={{start: '資料開始日期', end: '資料結束日期'}}
                        value={[startDate ? dayjs(startDate) : null, endDate ? dayjs(endDate) : null]}
                        required
                        onChange={handleDateRangeChange}
                    />
                    {/* 上傳選項 */}
                    <p className="mr-1">上傳選項:</p>
                    <select className="w-[102px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-2">
                        <option key="" value="0">全部</option>
                        <option key="" value="1">未傳</option>
                        <option key="" value="1">已傳</option>
                    </select>
                    {/* 查詢送出按鈕 */}
                    <Button color={ButtonColorEnum.Primary}
                            size={ButtonSizeEnum.Medium}
                            variant={ButtonVariantEnum.Contained}>
                        {t('general.query')}
                    </Button>
                </div>

                <div className="flex flex-row items-center justify-end">
                    {/* 上傳btn */}
                    <Button color={ButtonColorEnum.Secondary} size={ButtonSizeEnum.Medium}
                            variant={ButtonVariantEnum.Outlined}>
                        {t('general.upload')}
                    </Button>
                </div>
            </div>


            {/*列表清單區塊*/}
            <div className="maintainContainer flex flex-row items-start justify-start">
                <div className="p-4 bg-white w-full border-[1px] border-[#D4D4D8] rounded-[12px] mr-4">
                    <div className="searchGroup flex flex-row items-center justify-between mb-4">
                        {/*進階搜尋*/}
                        <BaseInput className="w-[320px] h-10 pl-4 border-[2px] border-[#D4D4D8] rounded-[6px]"
                                   type="text"
                                   placeholder="進階搜尋"
                                   inputMode={'search'}
                                   value={searchText}
                                   onChange={(e) => handleSearchTextOnChange(e)}/>
                    </div>

                    {/*列表清單*/}
                    <ul className="overflow-x-hidden tableList HHCITableList">
                        {/*表單項目標題*/}
                        <li className="title">
                            <div><Check/></div>
                            <div>資料日期</div>
                            <div>家字號</div>
                            <div>病歷號</div>
                            <div>姓名</div>
                            <div>性別</div>
                            <div>收案人員</div>
                            <div>收案日期</div>
                            <div>個案動態</div>
                            <div>床號</div>
                            <div>級別</div>
                            <div>護理師</div>
                            <div>上傳日期時間</div>
                            <div>上傳結果</div>
                        </li>

                        {!arrayIsEmpty(getFilterData(dataObject)) ? getFilterData(dataObject).map((item, index) =>
                            <li key={index}>
                                {/*checkBox*/}
                                <div>
                                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5"
                                           type="checkbox"
                                           id={`r${index}`} style={{accentColor: "#38A169"}}/>
                                </div>
                                {/*資料日期*/}
                                <div>{item.dataDate}</div>
                                {/*家字號*/}
                                <div>{item.homeNo}</div>
                                {/* 病歷號 */}
                                <div>{item.patientId}</div>
                                {/*姓名*/}
                                <div>{item.patientName}</div>
                                {/*性別*/}
                                <div>{item.gender}</div>
                                {/* 收案人員 */}
                                <div>{item.caseStartClerk}</div>
                                {/* 收案日期 */}
                                <div>{item.caseStartDate}</div>
                                {/* 個案動態 */}
                                <div>{item.status}</div>
                                {/* 床號 */}
                                <div>{item.code}</div>
                                {/* 級別 */}
                                <div>{item.levelType}</div>
                                {/* 護理師 */}
                                <div>{item.rn}</div>
                                {/* 上傳日期時間 */}
                                <div>{item.updateDate}</div>
                                {/* 上傳結果 */}
                                <div>{item.updateResult}</div>
                            </li>
                        ) : null}
                    </ul>
                </div>
            </div>

        </div>
    )
}
export default HomeHealthCareInfoAPI