import {arrayIsEmpty, time2String} from "edah_utils/dist"
import React, {useState} from "react";
import {t} from "i18next";
/**
 * 中醫用藥
 * @param data {Array} 資料
 * @return {JSX.Element}
 */
export const ChineseMedicine = ({data}) => {
    return (
        <div className="w-full">
            {/*列表清單區塊*/}
            {/*列表清單*/}
            <ul className="tableList icCMList">
                {/*表單項目標題*/}
                <li className="title">
                    <div>門住診別</div>
                    <div>主診斷碼</div>
                    <div>主診斷名稱</div>
                    <div>慢連箋</div>
                    <div>健保代碼</div>
                    <div>方名</div>
                    <div>效能名</div>
                    <div>用法用量</div>
                    <div>給藥日數</div>
                    <div>劑型</div>
                    <div>給藥總量</div>
                    <div>就醫(調劑)日期</div>
                    <div>醫事機構代碼</div>
                    <div>醫事機構簡稱</div>
                    <div>就醫序號</div>
                </li>

                {
                    !arrayIsEmpty(data) && data.map((item, index) =>
                        <li key={index}>
                            {/*門住診別*/}
                            <div>{t(`ICCard.InpOpdEnum.${item.inpOpd}`)}</div>
                            {/*主診斷碼*/}
                            <div>{item.treatmentCode}</div>
                            {/*主診斷名稱*/}
                            <div>{item.treatmentName}</div>
                            {/*慢連箋*/}
                            <div>{item.chronicFlag}</div>
                            {/*健保代碼*/}
                            <div>{item.orderHeCode}</div>
                            {/*方名*/}
                            <div>{item.formulaeName}</div>
                            {/*效能名*/}
                            <div>{item.effectiveName}</div>
                            {/*用法用量*/}
                            <div>{item.useName}</div>
                            {/*給藥日數*/}
                            <div>{item.days}</div>
                            {/*劑型*/}
                            <div>{item.preparation}</div>
                            {/*給藥總量*/}
                            <div>{item.tqty}</div>
                            {/*就醫(調劑)日期*/}
                            <div>{time2String(item.encounterStartDate, 'YYYY-MM-DD')}</div>
                            {/*醫事機構代碼*/}
                            <div>{item.hospNo}</div>
                            {/*醫事機構簡稱*/}
                            <div>{item.hospName}</div>
                            {/*就醫序號*/}
                            <div>{item.encSeqNo}</div>
                        </li>
                    )}
            </ul>
        </div>
    )
}