const OptionOne = [
    {
        label: '嚴重食慾不佳',
        value: 0
    },
    {
        label: '中度食慾不佳',
        value: 1
    },
    {
        label: '食慾無變化',
        value: 2
    }
]
const OptionTwo = [
    {
        label: '體重減輕>3公斤',
        value: 0
    },
    {
        label: '不知道',
        value: 1
    },
    {
        label: '體重減輕1-3公斤',
        value: 2
    },
    {
        label: '體重無改變',
        value: 3
    }
]
const OptionThree = [
    {
        label: '臥床或輪椅',
        value: 0
    },
    {
        label: '可下床活動或離開輪椅但無法自由走動',
        value: 1
    },
    {
        label: '可以自由走動',
        value: 2
    }
]
const OptionFour = [
    {
        label: '是',
        value: 0
    },
    {
        label: '否',
        value: 1
    }
]
const OptionFive = [
    {
        label: '嚴重癡呆或抑鬱',
        value: 0
    },
    {
        label: '輕度失智',
        value: 1
    },
    {
        label: '無精神問題',
        value: 2
    }
]
const OptionSix = [
    {
        label: 'BMI ≤ 19',
        value: 0
    },
    {
        label: '19 ≤ BMI ≤ 21',
        value: 1
    },
    {
        label: '21 < BMI ≤ 23',
        value: 2
    },
    {
        label: 'BMI > 23',
        value: 3
    }
]

export {
    OptionOne,
    OptionTwo,
    OptionThree,
    OptionFour,
    OptionFive,
    OptionSix,
}