import React, {useEffect, useState} from 'react'
import {InputTypeEnum, stringIsEmpty, isNumber} from "edah_utils/dist"

/**
 * 基礎輸入框
 * @param type {String} 輸入框類型
 * @param value {String} 輸入框內容
 * @param className {String} 自訂樣式
 * @param onChange {Function} 輸入框內容改變時觸發
 * @param disable {Boolean} 是否禁用
 * @param placeholder {String} 提示文字
 * @param onClick {Function|Null} 點擊事件
 * @param onFocus {Function|Null} 獲取焦點事件
 * @param onBlur {Function|Null} 失去焦點事件
 * @param onKeyDown {Function|Null} 鍵盤按鍵按下事件
 * @param onInput {Function|Null} 輸入時事件
 * @param inputMode {String} 選擇輸入匡類型
 * @param inputState {Null|Boolean} 輸入框狀態
 * @param errorMsg {String} 欄位檢核錯誤訊息
 * @param min {String} 允許的最小值
 * @param max {String} 允許的最大值
 * @param name {String} 名稱
 */
export const BaseInput = ({
                              type,
                              inputMode,
                              value,
                              disable = false,
                              className = '',
                              placeholder = '',
                              onChange = ()=>{},
                              onClick = null,
                              onFocus = null,
                              onBlur = null,
                              onKeyDown = null,
                              onInput=null,
                              inputState = null,
                              errorMsg = '',
                              min = '',
                              max = '',
                              name = '',
                          }) => {
    /**
     * 最大支持的時間
     */
    const MAX_DATE = "9999-12-31"

    //input className
    const [customClassName, setCustomClassName] = useState('')

    /**
     * 依照不同input類型對應不同的icon設計
     * @param type {String} inputMode
     * @return {string} icon className
     */
    const getInputType = (type) => {
        if (type === InputTypeEnum.Search) {  // 放大鏡icon搜尋
            return 'pl-8 bg-searchIcon bg-[length:16px_16px] bg-[center_left_10px] bg-no-repeat'
        } else if (type === InputTypeEnum.Date) {  // 日曆icon日期選擇
            return 'pl-8 bg-dateIcon bg-[length:20px_20px] bg-[center_left_10px] bg-no-repeat'
        } else if (type === 'clock') {  // 時鐘icon
            return 'pl-8 bg-clock bg-[length:20px_20px] bg-[center_left_10px] bg-no-repeat'
        }

        return ''
    }

    /**
     * 取得最大值
     * @return {string} 最大值
     */
    const getMax = () => {
        // 若為日期選擇模式，則最大值為9999-12-31
        if (inputMode === InputTypeEnum.Date) {
            return MAX_DATE
        } else { // 其他模式
            // 若有設定最大值，則回傳最大值
            if (isNumber(max) || !stringIsEmpty(max)) {
                return max
            }
        }

        return ''
    }

    /**
     * 欄位檢核
     * @return {void}
     */
    const validateInput = () => {
        // 欄位檢核錯誤訊息
        const errorClassName = 'border-[#E53E3E] border-[1px]'
        // 欄位檢核正確訊息
        const successClassName = 'border-[#38A169] border-[1px]'
        let customClassList = [
            className,
            getInputType(inputMode),
            successClassName,
            errorClassName,
        ]

        if (inputState === null) {
            // 欄位為初始狀態(null)
            customClassList = customClassList.filter(item => item !== errorClassName && item !== successClassName)
        } else if (inputState) {
            // 欄位通過檢核
            customClassList = customClassList.filter(item => item !== errorClassName)
        } else {
            // 欄位尚未通過檢核
            customClassList = customClassList.filter(item => item !== successClassName)
        }
        const classNameString = customClassList.join(' ')
        setCustomClassName(classNameString)
    }

    /**
     * 依照傳入的inputState變更input className
     * 若inputState = false 顯示紅色框
     * @return {void}
     */
    useEffect(() => {
        validateInput()
    }, [inputMode, inputState, className])

    return (
        <div className="relative baseInput">
            <input
                className={`${customClassName} cursor-pointer`}
                inputMode={inputMode}
                type={type}
                value={value}
                disabled={disable}
                placeholder={placeholder}
                onChange={onChange}
                onClick={onClick}
                onFocus={onFocus}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                max={getMax()}
                min={min}
                onInput={onInput}
                name={name}
            />
            {/* 欄位檢核錯誤訊息 */}
            {
                inputState !== null && !inputState && errorMsg ? (
                    <div className="absolute text-danger text-left text-xs">{errorMsg}</div>) : (<></>)
            }
        </div>
    )
}
