//Import Function
import React, {useEffect, useState} from 'react'
import CustomTable from '../RSISCustomTable';
import ListItem from './ListItem';
import {SupportOrgTable} from '../Constant';
import {usePopup} from '../PopupProvider';
import DeletePopup from '../Popup/DeletePopup';
import QuerySupportOrg from '../Modal/QuerySupportOrg';
import QueryHospital from '../Modal/QueryHospital';
import EditSupportOrg from '../Modal/EditSupportOrg';
import {rsisDelOrganization, rsisQueryOrganization} from "../../../api/v1/RSIS"
import {ApiErrorStatusEnum} from "edah_utils/dist"
import {t} from "i18next"
import {Button, ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum, IconEnum, AlertTypeEnum} from "edah-component/dist"

/**
 * 支援機構維護
 * @return {JSX.Element}
 */
const SupportOrgMaintain = () => {
    // 表格資料集
    const [dataList, setDataList] = useState([])
    // Modal類型
    const [type, setType] = useState('')
    // 分頁參數
    const [paginationProps, setPaginationProps] = useState({
        pageNum: 1,
        pageSize: 10,
        totalItemSize: 0,
        totalPageSize: 0,
        currentPageItemSize: 0,
        fn: () => {
        }
    })

    // 列表組件
    const List = ListItem

    // 使用上下文
    const {
        // 顯示支援機構查詢彈窗
        showQuerySupportOrgModal,
        setShowQuerySupportOrgModal,
        // 顯示全國醫療院所查詢彈窗
        showQueryHospitalModal,
        setShowQueryHospitalModal,
        // 顯示支援機構新增或修改彈窗
        showEditSupportOrgModal,
        setShowEditSupportOrgModal,
        // 顯示刪除彈窗
        showDeletePopup,
        setShowDeletePopup,
        // 焦點索引
        focusIndex,
        // 焦點項目
        focusItem,
        setFocusItem,
        showToast
    } = usePopup()

    /**
     * 新增支援機構事件
     * @return {void}
     */
    const handleAddOnClick = () => {
        // 重置焦點校牧
        setFocusItem(null)
        // 顯示新增或修改支援機構彈窗
        setShowEditSupportOrgModal(true)
    }

    /**
     * 列表更新事件
     * @return {void}
     */
    const handleOnUpdate = () => getQueryOrganization()

    /**
     * 列表刪除事件
     * @return {void}
     */
    const handleListItemOnDelete = () => delOrganization()

    /**
     * 列表修改彈窗關閉事件
     * @return{void}
     */
    const handeLeOnEditClose = () => {
        setShowEditSupportOrgModal(false)
        setFocusItem(null)
    }

    /**
     * 取得支援機構所有資料
     * @return {void}
     */
    const getQueryOrganization = () => {
        rsisQueryOrganization({}).then(res => {
            //狀態 / 訊息 / 資料
            const {err, msg, data} = res
            //取得成功
            if (err === ApiErrorStatusEnum.Success) {
                // 設定資料
                setDataList(data)
            } else { //取得失敗
                //清空資料
                setDataList([])
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 刪除支援機構資料
     * @return {void}
     */
    const delOrganization = () => {
        rsisDelOrganization({
            // 支援機構 id
            orgPkey: focusItem.orgPkey
        }).then(res => {
            const {err, msg, data} = res
            //刪除成功
            if (err === ApiErrorStatusEnum.Success) {
                // 顯示成功訊息
                showToast({message: msg, type: AlertTypeEnum.Success})
                // 關閉刪除彈窗
                setShowDeletePopup(false)
                // 重新取得資料
                getQueryOrganization()
            } else { //刪除失敗
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 第一次執行時
     * @return {void}
     */
    useEffect(() => getQueryOrganization(), [])

    return (
        <div className='px-6 py-2'>
            {/*新增按鈕*/}
            <Button sx={{
                width: '76px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '4px',
                marginLeft: '4px'
            }} text={t('general.add')} icon={IconEnum.Add} size={ButtonSizeEnum.Large}
                    variant={ButtonVariantEnum.Text} color={ButtonColorEnum.Success}
                    onClick={handleAddOnClick}/>
            {/*數據呈現表單*/}
            <CustomTable
                advancedSearch={true}
                fields={SupportOrgTable.fields}
                dataList={dataList}
                ListItem={List}
                type="RSISSupportOrgTable"
                paginationProps={paginationProps}
            />
            {/* 支援機構查詢彈窗 */}
            {showQuerySupportOrgModal &&
                <QuerySupportOrg
                    handleOnClose={() => setShowQuerySupportOrgModal(false)}
                    currentKey={focusIndex}
                />
            }
            {/* 支援機構新增或修改彈窗 */}
            {showEditSupportOrgModal &&
                <EditSupportOrg
                    data={focusItem}
                    dataList={dataList}
                    type={focusItem ? 'EDIT' : 'ADD'}
                    handleOnUpdate={handleOnUpdate}
                    handleOnClose={handeLeOnEditClose}
                />
            }
            {/* 全國醫療院所查詢彈窗 */}
            {showQueryHospitalModal &&
                <QueryHospital
                    handleOnClose={() => setShowQueryHospitalModal(false)}
                />
            }
            {/* 刪除彈窗 */}
            {showDeletePopup &&
                <DeletePopup
                    handleDeletePopupClose={() => setShowDeletePopup(false)}
                    handleNotificationDelete={handleListItemOnDelete}
                    currentKey={focusIndex}
                />
            }
        </div>
    )
}

export default SupportOrgMaintain
