import { FORM_TYPE } from '../../constants/referralSystem/print'
import PrintTranBackCaseList from '../../components/Print/PrintTranBackCaseList'
import PrintTranInForm from './PrintTranInForm'
import '../../components/Print/print.scss'
import { useSelector } from 'react-redux'
import PrintTranOutNoReplyLetterList from './PrintTranOutNoReplyLetterList'
import PrintTranOutForm from './PrintTranOutForm'
import PrintPermissionList from './PrintPermissionList'
import PrintPermissionLabel from './PrintPermissionLabel'
import PrintAidHospitalList from './PrintAidHospitalList'
import PrintReferralCodeList from './PrintReferralCodeList'
import PrintLongTermCareEnvelope from './PrintLongTermCareEnvelope'
import PrintApplyEnvelope from './PrintApplyEnvelope'
import PrintFederalList from './PrintFederalList'
import PrintReplyLetterList from './PrintReplyLetterList'
import PrintReplyLetterEnvelopeCover from './PrintReplyLetterEnvelopeCover'
import PrintReplyLetterLabel from './PrintReplyLetterLabel'
import PrintLongTermCareList from './PrintLongTermCareList'
import PrintReplyLetterOrder from './PrintReplyLetterOrder'
import PrintDischargeSummaryList from './PrintDischargeSummaryList'
import PrintTranOutNoReplyLetterLabel from './PrintTranOutNoReplyLetterLabel'
import PrintDischargeSummaryLabel from './PrintDischargeSummaryLabel'
import PrintDischargeSummaryEnvelope from './PrintDischargeSummaryEnvelope'
import PrintReturnTicket from './PrintReturnTicket'

/**
 * 列印元件
 * @return {JSX.Element}
 */
const Print = () => {
    // 列印類型
    const reportType = useSelector((state) => state.print.reportType)
    // 是否為列印模式
    const isPrintMode = useSelector((state) => state.print.isPrintMode)

    const formComponent = () => {
        switch (reportType) {
            // 列印轉回原醫療院所名單
            case FORM_TYPE.tranBackCaseList:
                return <PrintTranBackCaseList />
            // 列印未收到原醫療院所回覆名單
            case FORM_TYPE.tranOutNoReplyLetterList:
                return <PrintTranOutNoReplyLetterList />
            // 列印轉入單(回覆單)列印
            case FORM_TYPE.tranInForm:
                return <PrintTranInForm />
            // 轉出單(轉診單) - 列印
            case FORM_TYPE.tranOutForm:
                return <PrintTranOutForm />
            // 權限設定 - 列印  
            case FORM_TYPE.permissionSettingList:
                return <PrintPermissionList />
            // 權限設定- 標籤列印
            case FORM_TYPE.permissionLabel:
                return <PrintPermissionLabel />
            // 急救責任醫院 - 列印
            case FORM_TYPE.aidHospitalList:
                return <PrintAidHospitalList />
            // 轉診代碼維護 - 列印
            case FORM_TYPE.referralCodeList:
                return <PrintReferralCodeList />
            // 長照中心信封
            case FORM_TYPE.longTermCareEnvelope:
                return <PrintLongTermCareEnvelope />
            // 長照中心維護 - 列印
            case FORM_TYPE.longTermCareList:
                return <PrintLongTermCareList />
            // 病症暨失能證明書 - 列印申請人信封
            case FORM_TYPE.applyEnvelope:
                return <PrintApplyEnvelope />
            // 策略聯盟維護 - 列印
            case FORM_TYPE.federalList:
                return <PrintFederalList />
            // 轉診回函明細列印格式
            case FORM_TYPE.replyLetterList:
                return <PrintReplyLetterList />
            // 轉診回函信封列印
            case FORM_TYPE.replyLetterEnvelopeCover:
                return <PrintReplyLetterEnvelopeCover />
            // 轉診回函 - 信封列印
            case FORM_TYPE.replyLetterLabel:
                return <PrintReplyLetterLabel />
            // 轉診回函 - 單筆/整批列印
            case FORM_TYPE.replyLetterOrder:
                return <PrintReplyLetterOrder />
            // 出院摘要 - 列印
            case FORM_TYPE.dischargeSummary:
                return <PrintDischargeSummaryList />
            // 出院摘要 - 標籤列印
            case FORM_TYPE.dischargeSummaryLabel:
                return <PrintDischargeSummaryLabel />
            //  出院病摘 - 信封列印
            case FORM_TYPE.dischargeSummaryEnvelope:
                return <PrintDischargeSummaryEnvelope />
            // 未收到原醫療院所回覆名單 - 標籤列印
            case FORM_TYPE.tranOutNoReplyLetterLabel:
                return <PrintTranOutNoReplyLetterLabel />
            // 轉診回診證明單 - 列印
            case FORM_TYPE.returnTicket:
                return <PrintReturnTicket />
            default:
                return <></>
        }
    }

    return (
        <div className={`${isPrintMode ? 'hidden print-only' : 'hidden'}`}>
            {formComponent()}
        </div>
    )
}
export default Print
