import { DATE_FORMAT } from '../common'
import { time2String } from 'edah_utils/dist'
import i18n from '../../i18n'

/**
 * 門/急 table header
 */
const OPD_ER_FIELDS = [
  {
    key: 'encounterId',
    name: i18n.t('Rmis.pages.referralTicket.opdErTableHeader.encounterId'), // 就醫號碼
    className: 'w-[100px] text-center',
  },
  {
    key: 'patientId',
    name: i18n.t('Rmis.pages.referralTicket.opdErTableHeader.patientId'), // 病歷號
    className: 'w-[100px] text-center',
  },
  {
    key: 'patientName',
    name: i18n.t('Rmis.pages.referralTicket.opdErTableHeader.patientName'), // 姓名
    className: 'text-center',
  },
  {
    key: 'inpOpdName',
    name: i18n.t('Rmis.pages.referralTicket.opdErTableHeader.inpOpdName'), // 診別
    className: 'w-[50px] text-center',
  },
  {
    key: 'encounterDate',
    name: i18n.t('Rmis.pages.referralTicket.opdErTableHeader.encounterDate'), // 就醫日期
    format: (value) => time2String(value, DATE_FORMAT),
    className: 'w-[100px] text-center',
  },
  {
    key: 'weekName',
    name: i18n.t('Rmis.pages.referralTicket.opdErTableHeader.weekName'), // 星期
    className: 'w-[50px] text-center',
  },
  {
    key: 'divName',
    name: i18n.t('Rmis.pages.referralTicket.opdErTableHeader.divName'), // 就醫科別
    className: 'w-[70px] text-center',
  },
  {
    key: 'doctorName',
    name: i18n.t('Rmis.pages.referralTicket.opdErTableHeader.doctorName'), // 醫師
    className: 'text-center',
  },
  {
    key: 'status',
    name: i18n.t('Rmis.pages.referralTicket.opdErTableHeader.status'), // 狀態
    className: 'w-[50px] text-center',
  },
  {
    key: 'haveTranOut',
    name: i18n.t('Rmis.pages.referralTicket.opdErTableHeader.haveTranOut'), // 轉出
    className: 'text-center',
  },
  {
    key: 'haveTranIn',
    name: i18n.t('Rmis.pages.referralTicket.opdErTableHeader.haveTranIn'), // 轉入
    className: 'text-center',
  },
]

/**
 * 住院 table header
 */
const INP_FIELDS = [
  {
    key: 'encounterId',
    name: i18n.t('Rmis.pages.referralTicket.inpTableHeader.encounterId'), // 就醫號碼
    className: 'w-[100px] text-center',
  },
  {
    key: 'patientId',
    name: i18n.t('Rmis.pages.referralTicket.inpTableHeader.patientId'), // 病歷號
    className: 'w-[100px] text-center',
  },
  {
    key: 'patientName',
    name: i18n.t('Rmis.pages.referralTicket.inpTableHeader.patientName'), // 姓名
  },
  {
    key: 'admissionDate',
    name: i18n.t('Rmis.pages.referralTicket.inpTableHeader.admissionDate'), // 入院日期
    format: (value) => time2String(value, DATE_FORMAT),
    className: 'w-[100px] text-center',
  },
  {
    key: 'divName',
    name: i18n.t('Rmis.pages.referralTicket.inpTableHeader.divName'), // 科別
    className: 'w-[50px] text-center',
  },
  {
    key: 'doctorName',
    name: i18n.t('Rmis.pages.referralTicket.inpTableHeader.doctorName'), // 主治醫師
    className: 'w-[70px] text-center',
  },
  {
    key: 'drgCode',
    name: i18n.t('Rmis.pages.referralTicket.inpTableHeader.drgCode'), // DRG碼
    className: 'w-[80px] text-center',
  },
  {
    key: 'dischargeDate',
    name: i18n.t('Rmis.pages.referralTicket.inpTableHeader.dischargeDate'), // 出院日期
    format: (value) => time2String(value, DATE_FORMAT),
    className: 'w-[100px] text-center',
  },
  {
    key: 'haveTranOut',
    name: i18n.t('Rmis.pages.referralTicket.inpTableHeader.haveTranOut'), // 轉出
    className: 'text-center',
  },
  {
    key: 'haveTranIn',
    name: i18n.t('Rmis.pages.referralTicket.inpTableHeader.haveTranIn'), // 轉入
    className: 'text-center',
  },
]

export { OPD_ER_FIELDS, INP_FIELDS }
