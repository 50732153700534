import {v1Api} from "../Http"
import {HttpMethodEnum} from "edah_utils/dist"

/**
 * 取得轉診基礎資料
 * @param params params
 * @return {AxiosPromise}
 */
const trancaseQueryBasicData = (params) => v1Api.request({
    url: '/rmis/trancase/queryBasicData',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 查詢轉入單清單
 * @param params params
 * @return {AxiosPromise}
 */
const trancaseQueryTranInBySearch = (params) => v1Api.request({
    url: '/rmis/trancase/queryTranInBySearch',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 查詢轉入單資料(單筆)
 * @param params params
 * @return {AxiosPromise}
 */
const trancaseQueryTranInByEncounterId = (params) => v1Api.request({
    url: '/rmis/trancase/queryTranInByEncounterId',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 新增轉入單
 * @param data data
 * @return {AxiosPromise}
 */
const trancaseAddTranIn = (data) => v1Api.request({
    url: '/rmis/trancase/addTranIn',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 修改轉入單
 * @param data data
 * @return {AxiosPromise}
 */
const trancaseEditTranIn = (data) => v1Api.request({
    url: '/rmis/trancase/editTranIn',
    method: HttpMethodEnum.PATCH,
    data
})

/**
 * 查詢轉出單清單
 * @param params params
 * @return {AxiosPromise}
 */
const trancaseQueryTranOutBySearch = (params) => v1Api.request({
    url: '/rmis/trancase/queryTranOutBySearch',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 查詢轉出單資料
 * @param params params
 * @return {AxiosPromise}
 */
const trancaseQueryTranOutByEncounterId = (params) => v1Api.request({
    url: '/rmis/trancase/queryTranOutByEncounterId',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 修改轉出單
 * @param data data
 * @return {AxiosPromise}
 */
const trancaseEditTranOut = (data) => v1Api.request({
    url: '/rmis/trancase/editTranOut',
    method: HttpMethodEnum.PATCH,
    data
})

/**
 * 查詢尚未收到接收轉診醫院回覆名單
 * @param params params
 * @return {AxiosPromise}
 */
const trancaseQueryNoReplyLetterCaseBySearch = (params) => v1Api.request({
    url: '/rmis/trancase/queryNoReplyLetterCaseBySearch',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 查詢轉回原院所回函清單
 * @param params params
 * @return {AxiosPromise}
 */
const trancaseQueryTranBackCaseBySearch = (params) => v1Api.request({
    url: '/rmis/trancase/queryTranBackCaseBySearch',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 申請下載電子轉診平台資料
 * @param params params
 * @return {AxiosPromise}
 */
const downloadAllApi = (params) => v1Api.request({
    url: '/rmis/downapietran/downLoadAllApi',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 申請下載電子轉診平台資料_未完成
 * @param params params
 * @return {AxiosPromise}
 */
const downETranQueryBySearch = (params) => v1Api.request({
    url: '/rmis/downapietran/queryEtranBySearch',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 新增申請下載電子轉診平台資料_未完成
 * @param data data
 * @return {AxiosPromise}
 */
const downETranAddRmisTranXml = (data) => v1Api.request({
    url: '/rmis/downapietran/addRmisTranXml',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 查詢電子轉診平台資料_未完成
 * @param params params
 * @return {AxiosPromise}
 */
const downETranQueryDownLoadRmisTranXml = (params) => v1Api.request({
    url: '/rmis/downapietran/queryDownLoadRmisTranXml',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 轉診回函查詢
 * @param params params
 * @return {AxiosPromise}
 */
const queryTranInCaseBySearch = (params) => v1Api.request({
    url: '/rmis/trancase/queryTranInListByReplyLetter',
    method: HttpMethodEnum.GET,
    params
})


/**
 * 修改轉診外網登入設定維護
 * @param data data
 * @return {AxiosPromise}
 */
const saveTranUser = (data) => v1Api.request({
    url: '/rmis/tranUser/save',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 刪除轉診外網登入設定維護
 * @param params params
 * @returns {AxiosPromise}
 */
const deleteTranUser = (params) => v1Api.request({
    url: '/rmis/tranUser/del',
    method: HttpMethodEnum.DELETE,
    params
})

/**
 * 轉診外網登入設定維護查詢
 * @param params params
 * @return {AxiosPromise}
 */
const queryTranUser = (params) => v1Api.request({
    url: '/rmis/tranUser/query',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 轉診代碼維護查詢
 * @param params params
 * @return {AxiosPromise}
 */
const queryTranCode = (params) => v1Api.request({
    url: '/rmis/tranCode/query',
    method: HttpMethodEnum.GET,
    params
})
/**
 * 轉診代碼清單
 * @param params params
 * @return {AxiosPromise}
 */
const queryTranCodeList = (params) => v1Api.request({
    url: '/rmis/tranCode/queryTranCodeList',
    method: HttpMethodEnum.GET,
    params
})
/**
 * 刪除轉診代碼
 * @return {AxiosPromise}
 * @param data
 */
const deleteTranCode = (params) => v1Api.request({
    url: '/rmis/tranCode/del',
    method: HttpMethodEnum.DELETE,
    params
})
/**
 * 轉診代碼存檔
 * @return {AxiosPromise}
 * @param data
 */
const saveTranCode = (data) => v1Api.request({
    url: '/rmis/tranCode/save',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 新增策略聯盟維護
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const addAddTranFederal = (data) => v1Api.request({
    url: '/rmis/tranFederal/addTranFederal',
    method: HttpMethodEnum.POST,
    data
})
/**
 * 修改策略聯盟維護
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const editTranFederal = (data) => v1Api.request({
    url: '/rmis/tranFederal/editTranFederal',
    method: HttpMethodEnum.PUT,
    data
})
/**
 * 策略聯盟維護查詢
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const queryTranFederal = (params) => v1Api.request({
    url: '/rmis/tranFederal/queryTranFederal',
    method: HttpMethodEnum.GET,
    params
})
/**
 * 刪除策略聯盟維護
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const delTranFederal = (params) => v1Api.request({
    url: `/rmis/tranFederal/delTranFederal`,
    method: HttpMethodEnum.DELETE,
    params
})


/**
 * 醫事機構代碼清單
 * @param params params
 * @return {AxiosPromise}
 */
const queryHospitalInfoList = (params) => v1Api.request({
    url: '/rmis/trancase/queryHospinfoList',
    method: HttpMethodEnum.GET,
    params
})
/**
 * 查詢看診清單測試資料
 * @param params params
 * @return {AxiosPromise}
 */
const queryTestData = (params) => v1Api.request({
    url: '/rmis/trancase/queryTestData',
    method: HttpMethodEnum.GET,
    params
})
/**
 * 查詢看診清單測試資料
 * @param params params
 * @return {AxiosPromise}
 */
const queryTranTypeByEncounterId = (params) => v1Api.request({
    url: '/rmis/trancase/queryTranTypeByEncounterId',
    method: HttpMethodEnum.GET,
    params
})
/**
 * 儲存轉診開單作業
 * @param data params
 * @return {AxiosPromise}
 */
const saveTranTypeByEncounterId = (data) => v1Api.request({
    url: '/rmis/trancase/saveTranTypeByEncounterId',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 查詢轉診個案管理轉出清單
 * @param params params
 * @return {AxiosPromise}
 */
const queryTranCaseToTranApiBySearch = (params) => v1Api.request({
    url: '/rmis/sendApiEtran/queryTranCaseToTranApiBySearch',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 轉診急救責任醫院_編輯
 * @param  params params
 * @returns {AxiosPromise}
 */
const queryTranFirstAid = (params) => v1Api.request({
    url: '/rmis/firstAid/queryTranFirstAid',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 轉診急救責任醫院
 * @param  params params
 * @returns {AxiosPromise}
 */
const editTranFirstAid = (data) => v1Api.request({
    url: '/rmis/firstAid/editTranFirstAid',
    method: HttpMethodEnum.PUT,
    data
})

/**
 * 轉診急救責任醫院刪除
 * @param params params
 * @returns {AxiosPromise}
 */
const delTranFirstAi = (params) => v1Api.request({
    url: '/rmis/firstAid/delTranFirstAid',
    method: HttpMethodEnum.DELETE,
    params
})

/**
 * 查詢轉診回診清單
 * @param params {encounterDate, patientId, pageNum, pageSize}
 * @returns {Promise<any>}
 */
const queryTranInToOpd = (params) => v1Api.request({
    url: '/rmis/trancase/queryTranInToOpd',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 新增健保特約醫療院所
 * @param data { empNo, hospNo}
 * @returns {Promise<any>}
 */
const addHospitalinfoSelf = (data) => v1Api.request({
    url: '/rmis/hospitalinfoSelf/addHospitalinfoSelf',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 刪除健保特約醫療院所
 * @param params  { empNo, hospNo}
 * @returns {Promise<any>}
 */
const delHospitalinfoSelf = (params) => v1Api.request({
    url: '/rmis/hospitalinfoSelf/delHospitalinfoSelf',
    method: HttpMethodEnum.DELETE,
    params
})

/**
 * 查詢病症暨失能診斷證明書
 * @param params {applicantNo}
 * @returns {Promise<any>}
 */
const queryTranForeignByApplicantNo = (params) => v1Api.request({
    url: '/rmis/tranForeign/queryTranForeignByApplicantNo',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 查詢病症暨失能診斷證明書清單
 * @param params { applicantStartDate, applicantEndDate, pageNum, pageSize, search}
 * @returns {Promise<any>}
 */
const queryTranForeignBySearch = (params) => v1Api.request({
    url: '/rmis/tranForeign/queryTranForeignBySearch',
    method: HttpMethodEnum.GET,
    params
})
/**
 * 新增病症暨失能診斷證明書
 * @param data
 * @returns {Promise<any>}
 */
const addTranForeign = (data) => v1Api.request({
    url: '/rmis/tranForeign/addTranForeign',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 修改病症暨失能診斷證明書
 * @param data
 * @returns {Promise<any>}
 */
const editTranForeign = (data) => v1Api.request({
    url: '/rmis/tranForeign/editTranForeign',
    method: HttpMethodEnum.PUT,
    data
})

/**
 * 刪除病症暨失能診斷證明書
 * @param params
 * @returns {Promise<any>}
 */
const deleteTranForeign = (data) => v1Api.request({
    url: '/install/crud/delete',
    method: HttpMethodEnum.DELETE,
    data
})

/**
 * 新增長照機構代碼維護
 * @param data
 * @returns {Promise<any>}
 */
const addRmisTranCareCenter = (data) => v1Api.request({
    url: '/rmis/tranCareCenter/addRmisTranCareCenter',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 修改長照機構代碼維護
 * @param data
 * @returns {Promise<any>}
 */
const editRmisTranCareCenter = (data) => v1Api.request({
    url: '/rmis/tranCareCenter/editRmisTranCareCenter',
    method: HttpMethodEnum.PATCH,
    data
})

/**
 * 刪除長照機構代碼維護
 * @param params
 * @returns {Promise<any>}
 */
const deleteRmisTranCareCenter = (data) => v1Api.request({
    url: '/install/crud/delete',
    method: HttpMethodEnum.DELETE,
    data
})

/**
 * 查詢長照機構代碼維護
 * @param params
 * @returns {Promise<any>}
 */
const queryRmisTranCareCenter = (params) => v1Api.request({
    url: '/rmis/tranCareCenter/queryRmisTranCareCenter',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 查詢電子轉診清單視窗
 * @param params
 * @returns {Promise<any>}
 */
const queryEtranToOpd = (params) => v1Api.request({
    url: '/rmis/trancase/queryEtranToOpd',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 查詢看診清單測試資料
 * @param params {Object}
 * @return {AxiosPromise}
 */
const queryOpdInpTestData = (params) => v1Api.request({
    url: '/rmis/trancase/queryOpdInpTestData',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 查詢離島偏鄉維護
 * @param params
 * @return {AxiosPromise}
 */
const queryTranRural = (params) => v1Api.request({
    url: '/rmis/tranRural/queryTranRural',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 新增離島偏鄉維護
 * @param data
 * @return {AxiosPromise}
 */
const addTranRural = (data) => v1Api.request({
    url: '/rmis/tranRural/addTranRural',
    method: HttpMethodEnum.POST,
    data,
})

/**
 * 修改離島偏鄉維護
 * @param data
 * @return {AxiosPromise}
 */
const editTranRural = (data) => v1Api.request({
    url: '/rmis/tranRural/editTranRural',
    method: HttpMethodEnum.PUT,
    data,
})

/**
 * 刪除離島偏鄉維護
 * @param params 
 * @return {AxiosPromise}
 */
const delTranRural = (params) => v1Api.request({
    url: '/rmis/tranRural/delTranRural',
    method: HttpMethodEnum.DELETE,
    params,
})

/**
 * 列印轉診回函
 * @param params
 * @return {AxiosPromise}
 */
const printTranReplyLetter = (params) => v1Api.request({
    url: '/rmis/trancase/printTranReplyLetter',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 
 * @param  params 
 * @returns {AxiosPromise}
 */
const queryApplyDischargenoteByArpv = (params) => v1Api.request({
    url: '/rmis/applydischargenote/queryApplyDischargenoteByArpv',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 出院病摘核准查詢
 * @param params 
 * @returns {AxiosPromise}
 */
const queryApplyDischargenoteApply = (params) => v1Api.request({
    url: '/rmis/applydischargenote/queryApplyDischargenoteApply',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 出院病摘申請新增
 * @param params 
 * @returns {AxiosPromise}
 */
const addApplyDischargenote = (data) => v1Api.request({
    url: '/rmis/applydischargenote/addApplyDischargenote',
    method: HttpMethodEnum.PUT,
    data
})

/**
 * 出院病摘編輯(核准確認)
 * @param params 
 * @returns {AxiosPromise}
 */
const editApplyDischargenote = (data) => v1Api.request({
    url: '/rmis/applydischargenote/editApplyDischargenote',
    method: HttpMethodEnum.PUT,
    data
})

/**
 * 轉診單使用紀錄判斷
 * @param params 
 * @returns {AxiosPromise}
 */
const getPartNoByEncounterList = (params) => v1Api.request({
    url: '/rmis/encounterList/getPartNoByEncounterList',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 查詢工具
 * @param params 
 * @returns {AxiosPromise}
 */
const queryTransTool = (params) => v1Api.request({
    url: '/rmis/trancase/queryTransTool',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 急診品質上傳轉檔
 * @param params
 * @returns {Promise<any>}
 */
const tranRmisEmgqualityToTxt = (data) => v1Api.request({
    url: '/rmis/emgquality/tranRmisEmgqualityToTxt',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 急診品質上傳作業
 * @param params
 * @returns {Promise<any>}
 */
const queryRmisEmgquality = (params) => v1Api.request({
    url: '/rmis/emgquality/queryRmisEmgquality',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 急診品質上傳新增
 * @param data
 * @returns {Promise<any>}
 */
const addRmisEmgquality = (data) => v1Api.request({
    url: '/rmis/emgquality/addRmisEmgquality',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 查詢不轉診原因清單
 * @param params 
 * @returns {AxiosPromise}
 */
const queryNotTranBackReasonBySearch = (params) => v1Api.request({
    url: '/rmis/trancase/queryNotTranBackReasonBySearch',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 查詢附件清單
 * @param params 
 * @returns 
 */
const queryAppendixByEncounterId = (params) => v1Api.request({
    url: '/rmis/sendApiEtran/queryAppendixByEncounterId',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 上傳附件
 * @param params 
 * @returns {AxiosPromise}
 */
const sendAppendix = (data) => v1Api.request({
    url: '/rmis/sendApiEtran/sendAppendix',
    method: HttpMethodEnum.POST,
    data,
})

/**
 * 查詢45天內未輸入不轉診原因之轉診單
 * @param params 
 * @returns {AxiosPromise}
 */
const queryNotTranBackReasonByDay = (params) => v1Api.request({
    url: '/rmis/trancase/queryNotTranBackReasonByDay',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 上傳電子轉診XML
 * @param data
 * @returns {AxiosPromise}
 */
const tranApiToXml = (data) => v1Api.request({
    url: '/rmis/sendApiEtran/tranApiToXml',
    method: HttpMethodEnum.POST,
    data
})

export {
    trancaseQueryBasicData,
    trancaseQueryTranInBySearch,
    trancaseQueryTranInByEncounterId,
    trancaseAddTranIn,
    trancaseEditTranIn,
    trancaseQueryTranOutBySearch,
    trancaseQueryTranOutByEncounterId,
    trancaseEditTranOut,
    trancaseQueryNoReplyLetterCaseBySearch,
    trancaseQueryTranBackCaseBySearch,
    downloadAllApi,
    downETranQueryBySearch,
    downETranAddRmisTranXml,
    downETranQueryDownLoadRmisTranXml,
    saveTranUser,
    deleteTranUser,
    queryTranUser,
    delTranFederal,
    deleteTranCode,
    saveTranCode,
    queryTranCodeList,
    queryTranCode,
    addAddTranFederal,
    editTranFederal,
    queryTranFederal,
    queryHospitalInfoList,
    queryTestData,
    queryTranTypeByEncounterId,
    saveTranTypeByEncounterId,
    queryTranCaseToTranApiBySearch,
    queryTranFirstAid,
    editTranFirstAid,
    delTranFirstAi,
    queryTranInCaseBySearch,
    queryTranInToOpd,
    addHospitalinfoSelf,
    delHospitalinfoSelf,
    queryTranForeignByApplicantNo,
    queryTranForeignBySearch,
    addTranForeign,
    editTranForeign,
    queryEtranToOpd,
    queryOpdInpTestData,
    addRmisTranCareCenter,
    editRmisTranCareCenter,
    queryRmisTranCareCenter,
    queryTranRural,
    addTranRural,
    editTranRural,
    delTranRural,
    deleteRmisTranCareCenter,
    deleteTranForeign,
    printTranReplyLetter,
    queryApplyDischargenoteByArpv,
    queryApplyDischargenoteApply,
    editApplyDischargenote,
    addApplyDischargenote,
    getPartNoByEncounterList,
    queryTransTool,
    tranRmisEmgqualityToTxt,
    queryRmisEmgquality,
    addRmisEmgquality,
    queryNotTranBackReasonBySearch,
    queryAppendixByEncounterId,
    sendAppendix,
    queryNotTranBackReasonByDay,
    tranApiToXml,
}
