import {arrayIsEmpty, convertMinGuoToGregorian, time2String} from "edah_utils/dist"

/**
 * 就醫資料不需HPC卡區塊
 * @param data {Array} data
 * @return {JSX.Element}
 */
export const NoHpcSection = ({data}) => {

    /**
     * 將就醫日期轉換成字串
     * @param encounterDate {string} 就醫日期
     * @return {string} 就醫日期字串
     */
    const convert2Date = (encounterDate) => {
        // 取得民國日期
        const date = encounterDate.slice(0, 7)
        // 取得時間
        const time = encounterDate.slice(7, encounterDate.length)
            //確保每兩個字元加一個冒號
            .match(/.{1,2}/g).join(':')
        return `${convertMinGuoToGregorian(date)} ${time}`
    }

    return !arrayIsEmpty(data) ? (
        // 就醫資料不需HPC卡
        <div className="noHpc">
            <ul className="tableList noHpc">
                <li className="title bg-[#E4E4E7]">
                    <div>就醫代碼</div>
                    <div>就醫類別</div>
                    <div>新生兒註記</div>
                    <div>就診日期時間</div>
                    <div>補卡註記</div>
                    <div>就醫序號</div>
                    <div>醫療院所代碼</div>
                    <div>醫療院所</div>
                    <div>門診醫療費用(當次)</div>
                    <div>門診部分負擔費用(當次)</div>
                    <div>住院醫療費用(當次)</div>
                    <div>住院負擔費用(當次急性30天，慢性180天以下)</div>
                    <div>住院負擔費用(當次急性31天，慢性181天以上)</div>
                </li>
                {
                    data.map((item, index) =>
                        <li key={index}>
                            {/*就醫代碼*/}
                            <div>{item.icEncType}</div>
                            {/*就醫類別*/}
                            <div>{item.icEncTypeName}</div>
                            {/*新生兒註記*/}
                            <div>{item.childBronNote}</div>
                            {/*就診日期時間*/}
                            <div>{/*convert2Date(item.encounterDate)*/time2String(item.encounterDate, "YYYY-MM-DD")}</div>
                            {/*補卡註記*/}
                            <div>{item.encSuppleNote}</div>
                            {/*就醫序號*/}
                            <div>{item.encSeqNo}</div>
                            {/*醫療院所代碼*/}
                            <div>{item.hospNo}</div>
                            {/*醫療院所*/}
                            <div>{item.hospName}</div>
                            {/*門診醫療費用(當次)*/}
                            <div>{item.opdAmt}</div>
                            {/*門診部分負擔費用(當次)*/}
                            <div>{item.opdPartAmt}</div>
                            {/*住院醫療費用(當次)*/}
                            <div>{item.inpAmt}</div>
                            {/*住院負擔費用(當次急性30天，慢性180天以下)*/}
                            <div>{item.inpPartAmt1}</div>
                            {/*住院負擔費用(當次急性31天，慢性181天以上)*/}
                            <div>{item.inpPartAmt2}</div>
                        </li>
                    )
                }
            </ul>
        </div>
    ) : null
}

