import {arrayIsEmpty, time2String} from "edah_utils/dist"
import React, {useState} from "react";
import { t } from "i18next";


/**
 * 過敏藥
 * @param data {Array} 資料
 * @return {JSX.Element}
 */
export const AllergyMedicine = ({data}) => {
    return (
        <div className="w-full">
            {/*列表清單區塊*/}
            {/*列表清單*/}
            <ul className="tableList icAMList ">
                {/*表單項目標題*/}
                <li className="title">
                    <div>上傳日期</div>
                    <div>醫療院所代碼</div>
                    <div>醫療院所簡稱</div>
                    <div>上傳註記</div>
                    <div>過敏藥物</div>
                </li>

                {
                    !arrayIsEmpty(data) && data.map((item, index) =>
                        <li key={index}>
                            {/*上傳日期*/}
                            <div>{time2String(item.uploadDate, 'YYYY-MM-DD')}</div>
                            {/*醫療院所代碼*/}
                            <div>{item.hospNo}</div>
                            {/*醫療院所簡稱*/}
                            <div>{item.hospName}</div>
                            {/*上傳註記*/}
                            <div>{item.uploadNote}</div>
                            {/*過敏藥物*/}
                            <div>{item.allergy}</div>
                        </li>
                    )}
            </ul>
        </div>
    )
}