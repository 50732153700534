import {BaseInput} from "../../Input/BaseInput";
import {InputTypeEnum} from "edah_utils/dist";
import React from "react";
import {t} from "i18next";
import {
    TextArea,
    SizeEnum,
    Field,
    Button,
    ButtonSizeEnum,
    ButtonColorEnum,
    ButtonVariantEnum,
    IconEnum,
    DatePicker, TextField
} from "edah-component/dist";

/**
 * 補述B表紀錄
 * @return {JSX.Element}
 */
const Supplement = () => {
    // 頁面按鈕樣式
    const SecondButtonStyle = "flex items-center justify-center px-4 h-10 rounded-[6px] border-2 bg-[#FAFAFA]"

    return (
        <div className="w-full p-4 bg-white rounded-[6px] border border-[#111111]/15">
            <h3 className="flex items-center justify-start text-[20px] mr-4 mb-2.5 pl-2 text-[#2B6CB0] border-l-8 border-[#2B6CB0]">
                <span>補述B表紀錄</span>
            </h3>
            <div className="text-[#71717A] text-[14px] w-fit">評估日期</div>
            {/* 新增 */}
            <div className="flex items-center ">
                <Button color={ButtonColorEnum.Success} variant={ButtonVariantEnum.Text}
                        size={ButtonSizeEnum.Large} text={t('general.add')} icon={IconEnum.Add}
                />
                <div className="w-[1px] h-[20px] bg-[#D4D4D8]"/>
                <div className="flex justify-center gap-2 ml-2">
                    <Button color={ButtonColorEnum.Secondary} variant={ButtonVariantEnum.Outlined}
                            size={ButtonSizeEnum.Medium} text={'上頁'}
                    />
                    <Button color={ButtonColorEnum.Secondary} variant={ButtonVariantEnum.Outlined}
                            size={ButtonSizeEnum.Medium} text={'2024/06/28'}
                    />
                    <Button color={ButtonColorEnum.Secondary} variant={ButtonVariantEnum.Outlined}
                            size={ButtonSizeEnum.Medium} text={'下頁'}
                    />
                </div>
            </div>
            <div className="flex justify-between items-center pt-2 mt-2.5 mb-2.5 w-full border-t border-[#111111]/15">
                {/*功能操作*/}
                <div className="w-full h-[60px] py-[10px]">
                    <Button sx={{display: 'flex', justifyContent: 'flex-start'}}
                            color={ButtonColorEnum.Primary} variant={ButtonVariantEnum.Text}
                            size={ButtonSizeEnum.Large} text={t('general.delete')} icon={IconEnum.Delete}
                    />
                </div>
                <div className="flex gap-2 flex-shrink-0">
                    <Button color={ButtonColorEnum.Secondary} variant={ButtonVariantEnum.Outlined}
                            size={ButtonSizeEnum.Medium} text={'Ditto'}
                    />
                    <Button color={ButtonColorEnum.Secondary} variant={ButtonVariantEnum.Outlined}
                            size={ButtonSizeEnum.Medium} text={t('general.print')}
                    />
                    <Button color={ButtonColorEnum.Primary} variant={ButtonVariantEnum.Contained}
                            size={ButtonSizeEnum.Medium} text={t('general.saveFile')}
                    />
                </div>
            </div>
            <div className="flex flex-row items-center justify-start mb-6">
                {/* 評估日期輸入框 */}
                <div>

                    <Field label="評估日期">
                        <DatePicker/>
                    </Field>
                </div>
                <div>
                    {/* 評估人員輸入框 */}
                    <Field label="評估人員">
                        <TextField size={SizeEnum.Small}/>
                    </Field>
                </div>
            </div>
            <div className="flex flex-row items-start justify-start w-full">
                <Field label={"護理紀錄"}>
                    <TextArea inputSize={SizeEnum.Fill}/>
                </Field>
            </div>
        </div>
    )
}
export default Supplement
