import {Search} from "edah-component/dist/index";
import React, {useCallback, useState} from "react";
import {ocisEnCounterplaceOrder} from "../../api/v1/Cpoe";
import {ApiErrorStatusEnum, ToastTypeEnum} from "edah_utils/dist";
import useToast from "../../hooks/useToast";

/**
 * 根據keyword去後端獲取options
 * @param inputSize {String}
 * @param inputWidth {String}
 * @param inputLabel {String}
 * @param getOptionLabel {(item: Object)=>String}
 * @param inputValue {Function}
 * @param onInputChange {Function}
 * @param onChange {Function}
 * @param value {String}
 * @param props {any}
 * @retrun {JSX.Element}
 */
export const CpoeOrderSearch = ({
                                    inputSize,
                                    inputWidth,
                                    inputLabel,
                                    getOptionLabel,
                                    inputValue,
                                    onInputChange,
                                    onChange,
                                    value,
                                    ...props
                                }) => {
    // 關鍵字長度
    const KEY_WORDS_LENGTH = 3;
    // 醫藥基本資料集
    const [placeOrderList, setPlaceOrderList] = useState([])
    // 使用toast
    const showToast = useToast()

    /**
     * 監聽到input change時候
     * @param _ {Event}
     * @param value {String}
     * @return {void}
     */
    const handleInputChange = (_, value) => {
        // 如果關鍵字長度大於等於3
        if (value?.length >= KEY_WORDS_LENGTH) {
            queryPlaceOrder(value)
        } else { // 如果關鍵字長度小於3
            // 清空清單
            setPlaceOrderList([])
        }
    }

    /**
     * 查詢醫藥囑基本資料
     * @param keyWords {String} 關鍵字
     * @return {any}
     */
    const queryPlaceOrder = useCallback((keyWords) => {
        ocisEnCounterplaceOrder({
            search: keyWords
        }).then(res => {
            const {err, msg, data} = res
            // 取得成功
            if (err === ApiErrorStatusEnum.Success) {
                setPlaceOrderList(data)
            } else { // 取得失敗
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }, [showToast])

    return <Search
        value={value}
        inputLabel={inputLabel}
        disablePortal={false}
        options={placeOrderList}
        getOptionLabel={getOptionLabel}
        onChange={onChange}
        onInputChange={handleInputChange}
        {...props}/>
}
