import { useSelector } from 'react-redux'
import './print.scss'

/**
 * 出院摘要 - 標籤列印
 * @returns {JSX.Element}
 */
const PrintDischargeSummaryLabel = () => {
    const printData = useSelector((state) => state.print.printData)
    return (
        <>
            {printData.map((item, index) => {
                return (
                    <div key={index} className="label-page p-1">
                        <div className="border-b border-[#111111]">
                            <div>平信</div>
                            <div>{item.tranInHospName} 轉診中心收</div>
                        </div>
                        <div>
                            <div>{item.tranInHospZipCode}</div>
                            <div>{item.tranInHospAddress}</div>
                        </div>
                    </div>
                )
            })}
        </>
    )
}

export default PrintDischargeSummaryLabel
