import React, {useEffect, useState} from "react"
import {arrayIsEmpty, stringIsEmpty, time2String} from "edah_utils/dist"
import {t} from "i18next"
import {Checkbox, Dialog, DialogSizeEnums, DialogVariant, Field} from "edah-component/dist"

const CancelStopClinicPromptPopup = ({
                                         show = false,
                                         data,
                                         closePopupButtonOnClick,
                                         onConfirmClick
                                     }) => {
    /**
     * 是否顯示彈窗
     */
    const [showPopup, setShowPopup] = useState(show)

    /**
     * 取得Content
     * @return {JSX.Element}
     */
    const getContent = () => (
        <>
            <div
                className="flex flex-col w-full max-h-[calc(80vh-100px)] overflow-y-auto bg-white px-5">
                <div className="grid grid-rows-8 gap-2">
                    <Field label={t("Regs.general.clinicDate")}>
                        <p className="text-left">
                            {!arrayIsEmpty(data) && !stringIsEmpty(data.encounterDate) ? time2String(data.encounterDate, "YYYY-MM-DD") : ""}
                        </p>
                    </Field>
                    <Field label={t("Regs.general.timeSlot")}>
                        <p className="text-left">
                            {!arrayIsEmpty(data) && !stringIsEmpty(data.apnName) ? data.apnName : ""}
                        </p>
                    </Field>
                    <Field label="診室號">
                        <p className="text-left">
                            {!arrayIsEmpty(data) && !stringIsEmpty(data.clinicNo) ? data.clinicNo : ""}
                        </p>
                    </Field>
                    <Field label={t('general.doctor')}>
                        <p className="text-left">
                            {!arrayIsEmpty(data) && !stringIsEmpty(data.doctorName) ? data.doctorName : ""}
                        </p>
                    </Field>
                    <Field label={t('general.department')}>
                        <p className="text-left">
                            {!arrayIsEmpty(data) && !stringIsEmpty(data.divName) ? data.divName : ""}
                        </p>
                    </Field>
                    <Field label="停診">
                        <div className="text-left">
                            <Checkbox
                                checked={!arrayIsEmpty(data) && !stringIsEmpty(data.stopClinicReason) && stringIsEmpty(data.locumDoctor)}
                            />
                        </div>
                    </Field>
                    <Field label="停代診原因">
                        <p className="text-left">
                            {!arrayIsEmpty(data) && !stringIsEmpty(data.stopClinicReasonName) ? data.stopClinicReasonName : ""}
                        </p>
                    </Field>
                    <Field label="代診醫師">
                        <p className="text-left">
                            {!arrayIsEmpty(data) && !stringIsEmpty(data.locumDoctorName) ? data.locumDoctorName : ""}
                        </p>
                    </Field>
                </div>
            </div>
        </>
    )

    /**
     * 監聽是否顯示彈窗Pros變化
     */
    useEffect(() => {

        setShowPopup(show)
    }, [show])

    return (
        <Dialog
            open={showPopup}
            title={'取消停代診'}
            variant={DialogVariant.CONFIRM}
            paperStyleProps={{width: DialogSizeEnums.SM}}
            content={getContent()}
            onClose={closePopupButtonOnClick}
            onConfirm={() => onConfirmClick(data.clinicId)}
            muiDialogContentProps={{dividers: true}}/>
    )
}

export default CancelStopClinicPromptPopup
