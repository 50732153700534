import { DATE_FORMAT } from "../common"
import {time2String} from "edah_utils/dist"
import i18n from "../../i18n"

/**
 * 門/急 清單
 */
export const OPD_ER_FIELDS = [
    {
        key: 'inpOpdName',
        name: i18n.t('Rmis.pages.patientVisitList.opdErTableHeader.inpOpdName'), // 診別
    },
    {
        key: 'encounterDate',
        name: i18n.t('Rmis.pages.patientVisitList.opdErTableHeader.encounterDate'), // 就醫日期
        format: (value) => time2String(value, DATE_FORMAT),
    },
    {
        key: 'weekName',
        name: i18n.t('Rmis.pages.patientVisitList.opdErTableHeader.weekName'), // 星期
    },
    {
        key: 'divName',
        name: i18n.t('Rmis.pages.patientVisitList.opdErTableHeader.divName'), // 就醫科別
    },
    {
        key: 'doctorName',
        name: i18n.t('Rmis.pages.patientVisitList.opdErTableHeader.doctorName'), // 醫師
    },
    {
        key: 'status',
        name: i18n.t('Rmis.pages.patientVisitList.opdErTableHeader.status'), // 狀態
    },
]

/**
 * 住院 清單
 */
export const INP_FIELDS = [
    {
        key: 'admissionDate',
        name: i18n.t('Rmis.pages.patientVisitList.inpTable.admissionDate'), // 入院日期
        format: (value) => time2String(value, DATE_FORMAT),
    },
    {
        key: 'divName',
        name: i18n.t('Rmis.pages.patientVisitList.inpTable.divName'), // 科別
    },
    {
        key: 'doctorName',
        name: i18n.t('Rmis.pages.patientVisitList.inpTable.doctorName'), // 主治醫師
    },
    {
        key: 'drgCode',
        name: i18n.t('Rmis.pages.patientVisitList.inpTable.drgCode'), // DRG碼
    },
    {
        key: 'dischargeDate',
        name: i18n.t('Rmis.pages.patientVisitList.inpTable.dischargeDate'), // 出院日期
        format: (value) => time2String(value, DATE_FORMAT),
    },
]
