import React, {useState} from "react";
import {
    Button,
    ButtonSizeEnum,
    ButtonColorEnum,
    ButtonVariantEnum,
    IconEnum,
    DatePicker,
    TextField,
    Field,
    SizeEnum, Divider
} from "edah-component/dist";
import {t} from "i18next";
import {BaseInput} from "../../Input/BaseInput";
import {InputTypeEnum} from "edah_utils/dist";

/**
 * 安寧居家療護持續疼痛評估表S3
 * @return {JSX.Element}
 */
const CareContinuousPainAssessment = () => {

    // 頁面按鈕樣式
    const SecondButtonStyle = "flex items-center justify-center px-4 h-10 rounded-[6px] border-2 bg-[#FAFAFA]"

    const [formData, setFormData] = useState({
        diagnosis: '',
        transferDepartment: '',
        painIntensity: '',
        prescription: '',
        respiration: '',
        consciousness: '',
        sideEffects: '',
        otherCareMethods: '',
        medicationRecords: {
            date: '',
            time: '',
            staff: '',
            medication: '',
            scheduledDose: '',
            prnDose: '',
            totalDose: '',
        }
    });

    /**
     * 處理表單變更
     * @param {Object} e - 事件對象
     */
    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    /**
     * 處理藥物記錄變更
     * @param {Object} e - 事件對象
     */
    const handleMedicationChange = (e) => {
        const {name, value} = e.target;
        setFormData((prevState) => ({
            ...prevState,
            medicationRecords: {
                ...prevState.medicationRecords,
                [name]: value,
            },
        }));
    };

    /**
     * 處理表單提交
     * @param {Object} e - 事件對象
     */
    const handleSubmit = (e) => {
        e.preventDefault();
        // 處理表單提交邏輯
        console.log(formData);
    };

    return (
        <div className="h-[calc(100vh-175px)] overflow-y-auto p-6 bg-white">
            <h3 className="flex items-center justify-start z-10 text-[20px] mr-4 mb-2.5 pl-2 bg-white sticky top-0 text-[#2B6CB0] border-l-8 border-[#2B6CB0]">
                <span>安寧居家療護持續疼痛評估表(S3)</span>
            </h3>
            <div className="text-[#71717A] text-[14px] w-fit">評估日期</div>
            {/* 新增 */}
            <div className="flex items-center ">
                <Button color={ButtonColorEnum.Success} variant={ButtonVariantEnum.Text}
                        size={ButtonSizeEnum.Large} text={t('general.add')} icon={IconEnum.Add}
                />
                <div className="w-[1px] h-[20px] bg-[#D4D4D8]"/>
                <div className="flex justify-center gap-2 ml-2">
                    <Button color={ButtonColorEnum.Secondary} variant={ButtonVariantEnum.Outlined}
                            size={ButtonSizeEnum.Medium} text={'上頁'}
                    />
                    <Button color={ButtonColorEnum.Secondary} variant={ButtonVariantEnum.Outlined}
                            size={ButtonSizeEnum.Medium} text={'2024/06/28'}
                    />
                    <Button color={ButtonColorEnum.Secondary} variant={ButtonVariantEnum.Outlined}
                            size={ButtonSizeEnum.Medium} text={'下頁'}
                    />
                </div>
            </div>
            <div className="flex justify-between items-center pt-2 mt-2.5 mb-2.5 w-full border-t border-[#111111]/15">
                {/*功能操作*/}
                <div className="w-full h-[60px] py-[10px]">
                    <Button sx={{display: 'flex', justifyContent: 'flex-start'}}
                            color={ButtonColorEnum.Primary} variant={ButtonVariantEnum.Text}
                            size={ButtonSizeEnum.Large} text={t('general.delete')} icon={IconEnum.Delete}
                    />
                </div>
                <div className="flex gap-2 flex-shrink-0">
                    <Button color={ButtonColorEnum.Secondary} variant={ButtonVariantEnum.Outlined}
                            size={ButtonSizeEnum.Medium} text={'Ditto'}
                    />
                    <Button color={ButtonColorEnum.Secondary} variant={ButtonVariantEnum.Outlined}
                            size={ButtonSizeEnum.Medium} text={t('general.print')}
                    />
                    <Button color={ButtonColorEnum.Primary} variant={ButtonVariantEnum.Contained}
                            size={ButtonSizeEnum.Medium} text={t('general.saveFile')}
                    />
                </div>
            </div>
            <div className="flex flex-row items-center justify-start mb-6">
                {/* 評估日期輸入框 */}
                <div>
                    <Field childrenSx={{display: 'flex', alignItems: 'center'}} label="評估日期">
                        <DatePicker/>
                    </Field>
                </div>
                {/* 評估人員輸入框 */}
                <div>
                    <Field childrenSx={{display: 'flex', alignItems: 'center'}} label="評估人員">
                        <TextField/>
                    </Field>
                </div>
            </div>
            <form className="border border-[#111111]/15 py-4 px-6 space-y-3" onSubmit={handleSubmit}>
                <div className="flex flex-row items-center justify-start">
                    {/* 診斷 */}
                    <Field childrenSx={{pl: 1}} labelWidth={150} label="診斷">
                        <TextField size={SizeEnum.Small}
                                   name="diagnosis"
                                   value={formData.diagnosis}
                                   onChange={handleChange}/>
                    </Field>
                    <Field childrenSx={{pl: 1}} labelWidth={150} label="轉移部位">
                        <TextField size={SizeEnum.Small} name="transferDepartment"
                                   value={formData.transferDepartment}
                                   onChange={handleChange}/>
                    </Field>
                </div>

                {/* 疼痛強度(0-10) */}
                <Field childrenSx={{pl: 1}} labelWidth={150} label="疼痛強度(0-10)">
                    <TextField size={SizeEnum.Small} name="painIntensity"
                               value={formData.painIntensity}
                               onChange={handleChange}/>
                </Field>

                {/* 處方(藥物劑量、時間、途徑) */}
                <Field childrenSx={{pl: 1}} labelWidth={150} label="處方(藥物劑量、時間、途徑)"
                       className="flex flex-row items-center justify-start">
                    <TextField size={SizeEnum.Small} name="prescription"
                               value={formData.prescription}
                               onChange={handleChange}/>
                </Field>
                {/* 呼吸(分) */}
                <Field childrenSx={{pl: 1}} labelWidth={150} label="呼吸(分)">
                    <TextField size={SizeEnum.Small} name="respiration"
                               inputWidth={SizeEnum.Small}
                               value={formData.respiration}
                               onChange={handleChange}/>
                </Field>

                {/* 意識狀態 */}
                <Field childrenSx={{pl: 1}} labelWidth={150} label="意識狀態">
                    <TextField size={SizeEnum.Small} inputWidth={SizeEnum.Small}
                               name="consciousness"
                               value={formData.consciousness}
                               onChange={handleChange}
                    />
                </Field>

                {/* 其他如副作用、情緒及治療後之反應等(家住護理方法) */}
                <Field childrenSx={{pl: 1}} labelWidth={150} label="其他如副作用、情緒及治療後之反應等(家住護理方法)">
                    <TextField size={SizeEnum.Small}
                               name="sideEffects"
                               value={formData.sideEffects}
                               onChange={handleChange}/>
                </Field>
                <Field labelWidth={180} label="副作用請填寫代碼如下">
                    1. 呼吸抑制 &nbsp;&nbsp; 2. 鎮靜作用 &nbsp;&nbsp; 3.
                    頭暈 &nbsp;&nbsp; 4. 噁心 &nbsp;&nbsp; 5. 嘔吐 &nbsp;&nbsp; 6. 流汗 &nbsp;&nbsp; 7.
                    便秘 &nbsp;&nbsp; 8. 欣快感 &nbsp;&nbsp; 9. 口乾 &nbsp;&nbsp; 10. 皮膚紅疹 &nbsp;&nbsp; 11.
                    其他(請註明)
                </Field>
                <div className="flex items-center justify-start mb-4 w-full pb-4">
                    其他護理方法，如：想像治療、鬆弛治療、音樂治療、娛樂治療、寵物治療、芳香治療、指壓按摩、TENS等
                </div>
                <Divider/>
                <div className="flex flex-row items-start space-x-5 mb-4">
                    <h3 className="w-[287px] h-[317px] p-2 font-bold text-left border-[1px] border-[#D4D4D8] rounded-[6px]">藥物點滴記錄</h3>
                    {/* 記錄日期 */}
                    <div className="space-y-2">
                        <Field labelWidth={170} label="記錄日期">
                            <TextField size={SizeEnum.Small}
                                       name="date"
                                       value={formData.medicationRecords.date}
                                       onChange={handleMedicationChange}
                            />
                        </Field>

                        {/* 記錄時間 */}
                        <Field labelWidth={170} label="記錄時間">
                            <TextField size={SizeEnum.Small}
                                       name="time"
                                       value={formData.medicationRecords.time}
                                       onChange={handleMedicationChange}
                            />
                        </Field>

                        {/* 記錄人員 */}
                        <Field labelWidth={170} label="記錄人員">
                            <TextField size={SizeEnum.Small}
                                       name="staff"
                                       value={formData.medicationRecords.staff}
                                       onChange={handleMedicationChange}
                            />
                        </Field>

                        {/* 藥物 */}
                        <Field labelWidth={170} label="藥物" className="flex flex-row items-center justify-start mb-4">
                            <TextField size={SizeEnum.Small}
                                       name="medication"
                                       value={formData.medicationRecords.medication}
                                       onChange={handleMedicationChange}
                            />
                        </Field>

                        {/* 按時給藥一天總劑量 */}
                        <Field labelWidth={170} label="按時給藥一天總劑量">
                            <TextField size={SizeEnum.Small}
                                       name="scheduledDose"
                                       value={formData.medicationRecords.scheduledDose}
                                       onChange={handleMedicationChange}
                            />
                        </Field>
                        {/* p.r.n給藥一天總劑量 */}
                        <Field labelWidth={170} label="p.r.n給藥一天總劑量">
                            <TextField size={SizeEnum.Small}
                                       name="prnDose"
                                       value={formData.medicationRecords.prnDose}
                                       onChange={handleMedicationChange}
                            />
                        </Field>

                        {/* 全天給藥天總劑量 */}
                        <Field labelWidth={170} label="全天給藥天總劑量">
                            <TextField size={SizeEnum.Small}
                                       name="totalDose"
                                       value={formData.medicationRecords.totalDose}
                                       onChange={handleMedicationChange}
                            />
                        </Field>
                    </div>
                </div>
            </form>
        </div>
    )
}
export default CareContinuousPainAssessment