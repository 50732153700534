import {arrayIsEmpty, time2String} from "edah_utils/dist"
import React, {useState} from "react";
import { t } from "i18next";
/**
 * 出院病摘
 * @param data {Array} 資料
 * @return {JSX.Element}
 */
export const DischargeSummary = ({data}) => {
    return (
        <div className="w-full">
            {/*列表清單區塊*/}
            {/*列表清單*/}
            <ul className="tableList icDSList ">
                {/*表單項目標題*/}
                <li className="title">
                    <div>醫事機構代碼</div>
                    <div>醫事機構簡稱</div>
                    <div>出院科別代碼</div>
                    <div>出院科別名稱</div>
                    <div>出院診斷碼</div>
                    <div>出院診斷名稱</div>
                    <div>住院日期</div>
                    <div>出院日期</div>
                    <div>出院病摘檔案名稱</div>
                </li>

                {
                !arrayIsEmpty(data) && data.map((item, index) =>
                        <li key={index}>
                            {/*醫事機構代碼*/}
                            <div>{item.hospNo}</div>
                            {/*醫事機構簡稱*/}
                            <div>{item.hospName}</div>
                            {/*出院科別代碼*/}
                            <div>{item.heDivNo}</div>
                            {/*出院科別名稱*/}
                            <div>出院科別名稱</div>
                            {/*出院診斷碼*/}
                            <div>{item.treatmentCode}</div>
                            {/*出院診斷名稱*/}
                            <div>{item.treatmentName}</div>
                            {/*住院日期*/}
                            <div>{time2String(item.admissionDate, 'YYYY-MM-DD')}</div>
                            {/*出院日期*/}
                            <div>{time2String(item.dischargeDate, 'YYYY-MM-DD')}</div>
                            {/*出院病摘檔案名稱*/}
                            <div>{item.dischargeNote}</div>
                        </li>
                    )}
            </ul>
        </div>
    )
}