import React from "react";
import {ReactComponent as Check} from "../../assets/images/icons/checkbox.svg";
import {arrayIsEmpty, fuzzySearchObjects} from "edah_utils/dist";

const ActiveCaseList = ({data, searchText}) => {
    /**
     * 取得模糊匹配後的資料陣列
     * @return {Array<Object>}
     */
    const getFilterData = (data) => fuzzySearchObjects(data, searchText);

    return (
        <ul className="tableList CQRATableList">
            {/*表單項目標題*/}
            <li className="title">
                <div><Check/></div>
                <div>收案日期</div>
                <div>姓名</div>
                <div>出生日期</div>
                <div>年齡</div>
                <div>家字號</div>
                <div>病歷號</div>
                <div>身分證號</div>
                <div>性別</div>
                <div>主診斷碼</div>
                <div>主診斷碼敘述</div>
                <div>轉介來源</div>
                <div>巴氏</div>
                <div>科氏</div>
                <div>聯絡人</div>
                <div>聯絡電話</div>
                <div>聯絡地址</div>
                <div>收案人員</div>
                <div>營養評估日</div>
                <div>進食狀況</div>
                <div>體重變化</div>
                <div>行動力</div>
                <div>急性疾病</div>
                <div>神經精神問題</div>
                <div>BMI</div>
                <div>總分</div>
                <div>營養師介入</div>
                <div>營養師介入說明</div>
                <div>營養評估護理師</div>
                <div>營養轉介日期</div>
                <div>鼻造管、胃造口留置</div>
                <div>MNA &lt; 11</div>
                <div>Albumin &lt; 3.0mg/dl</div>
                <div>需營養諮詢個案</div>
                <div>其他</div>
                <div>主要照顧者評估日期</div>
                <div>主要照顧者評估</div>
                <div>主顧者姓名</div>
                <div>關係</div>
                <div>年齡</div>
                <div>性別</div>
                <div>就業狀況</div>
                <div>照顧時間</div>
                <div>每日平均小時</div>
                <div>照顧月數</div>
                <div>主顧者身體健康</div>
                <div>主顧者身心理狀況</div>
                <div>家庭互動關係</div>
                <div>負荷總分</div>
                <div>無照護負荷問題</div>
                <div>照顧品質不佳</div>
                <div>照護負荷過重</div>
                <div>無照護意願</div>
                <div>其他</div>
                <div>照顧者總評敘述</div>
                <div>照顧者評估護理師</div>
            </li>

            {!arrayIsEmpty(getFilterData(data)) ? getFilterData(data).map((item, index) =>
                <li key={index}>
                    {/*操作*/}
                    <div>
                        <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5"
                               type="checkbox"
                               id={`r${index}`} style={{accentColor: "#38A169"}}/>
                    </div>
                    {/*收案日期*/}
                    <div>{item.admissionDate}</div>
                    {/*姓名*/}
                    <div>{item.name}</div>
                    {/*出生日期*/}
                    <div>{item.birthDate}</div>
                    {/*年齡*/}
                    <div>{item.age}</div>
                    {/*家字號*/}
                    <div>{item.familySerialNumber}</div>
                    {/*病歷號*/}
                    <div>{item.medicalRecordNumber}</div>
                    {/*身分證號*/}
                    <div>{item.idNumber}</div>
                    {/*性別*/}
                    <div>{item.gender}</div>
                    {/*主診斷碼*/}
                    <div>{item.primaryDiagnosisCode}</div>
                    {/*主診斷碼敘述*/}
                    <div>{item.primaryDiagnosisDescription}</div>
                    {/*轉介來源*/}
                    <div>{item.referralSource}</div>
                    {/*巴氏*/}
                    <div>{item.barthelIndex}</div>
                    {/*科氏*/}
                    <div>{item.katsIndex}</div>
                    {/*聯絡人*/}
                    <div>{item.contactPerson}</div>
                    {/*聯絡電話*/}
                    <div>{item.contactPhone}</div>
                    {/*聯絡地址*/}
                    <div>{item.contactAddress}</div>
                    {/*收案人員*/}
                    <div>{item.caseManager}</div>
                    {/*營養評估日*/}
                    <div>{item.nutritionAssessmentDate}</div>
                    {/*進食狀況*/}
                    <div>{item.eatingStatus}</div>
                    {/*體重變化*/}
                    <div>{item.weightChange}</div>
                    {/*行動力*/}
                    <div>{item.mobility}</div>
                    {/*急性疾病*/}
                    <div>{item.acuteDisease}</div>
                    {/*神經精神問題*/}
                    <div>{item.neuroPsychiatricProblems}</div>
                    {/*BMI*/}
                    <div>{item.bmi}</div>
                    {/*總分*/}
                    <div>{item.totalScore}</div>
                    {/*營養師介入*/}
                    <div>{item.nutritionistIntervention}</div>
                    {/*營養師介入說明*/}
                    <div>{item.nutritionistInterventionDescription}</div>
                    {/*營養評估護理師*/}
                    <div>{item.nutritionAssessmentNurse}</div>
                    {/*營養轉介日期*/}
                    <div>{item.nutritionReferralDate}</div>
                    {/*鼻造管、胃造口留置*/}
                    <div>{item.nasogastricTubeOrGastrostomy}</div>
                    {/*MNA &lt; 11*/}
                    <div>{item.mnaLessThan11}</div>
                    {/*Albumin &lt; 3.0mg/dl*/}
                    <div>{item.albuminLessThan3}</div>
                    {/*需營養諮詢個案*/}
                    <div>{item.needNutritionConsultation}</div>
                    {/*其他*/}
                    <div>{item.others}</div>
                    {/*主要照顧者評估日期*/}
                    <div>{item.primaryCaregiverAssessmentDate}</div>
                    {/*主要照顧者評估*/}
                    <div>{item.primaryCaregiverAssessment}</div>
                    {/*主顧者姓名*/}
                    <div>{item.primaryCaregiverName}</div>
                    {/*關係*/}
                    <div>{item.relationship}</div>
                    {/*年齡*/}
                    <div>{item.primaryCaregiverAge}</div>
                    {/*性別*/}
                    <div>{item.primaryCaregiverGender}</div>
                    {/*就業狀況*/}
                    <div>{item.employmentStatus}</div>
                    {/*照顧時間*/}
                    <div>{item.caregivingTime}</div>
                    {/*每日平均小時*/}
                    <div>{item.averageDailyHours}</div>
                    {/*照顧月數*/}
                    <div>{item.caregivingMonths}</div>
                    {/*主顧者身體健康*/}
                    <div>{item.primaryCaregiverHealth}</div>
                    {/*主顧者身心理狀況*/}
                    <div>{item.primaryCaregiverMentalStatus}</div>
                    {/*家庭互動關係*/}
                    <div>{item.familyInteraction}</div>
                    {/*負荷總分*/}
                    <div>{item.burdenTotalScore}</div>
                    {/*無照護負荷問題*/}
                    <div>{item.noBurdenIssues}</div>
                    {/*照顧品質不佳*/}
                    <div>{item.poorCareQuality}</div>
                    {/*照護負荷過重*/}
                    <div>{item.heavyBurden}</div>
                    {/*無照護意願*/}
                    <div>{item.noWillingnessToCare}</div>
                    {/*其他*/}
                    <div>{item.otherBurden}</div>
                    {/*照顧者總評敘述*/}
                    <div>{item.caregiverOverallDescription}</div>
                    {/*照顧者評估護理師*/}
                    <div>{item.caregiverAssessmentNurse}</div>
                </li>
            ) : null}
        </ul>
    );
};

export default ActiveCaseList;
