import React, {useEffect, useState} from 'react'
import {usePopup} from '../PopupProvider'
import CustomTable from '../RSISCustomTable'
import ListItem from './ListItem'
import FunctionOperateIntroMaintain from '../Modal/FunctionOperateIntroMaintain'
import {rsisQueryFunctionDoc} from "../../../api/v1/RSIS"
import {ApiErrorStatusEnum} from "edah_utils/dist"
import {AlertTypeEnum} from "edah-component/dist"

// 表格欄位
const fields = [
    "",
    "功能ID",
    "功能名稱",
    "類別",
    "操作說明",
    "有效否",
]

/**
 * 報備支援-功能操作維護頁面
 * @return {JSX.Element}
 * */
const FunctionOperateMaintain = () => {
    // 列表資料集
    const [dataList, setDataList] = useState([])
    // 列表組件
    const List = ListItem
    // 使用上下文
    const {showFunctionOperateIntroEditModal, setShowFunctionOperateIntroEditModal, focusItem, showToast} = usePopup()

    // 分頁組件
    const [paginationProps, setPaginationProps] = useState({
        pageNum: 1,
        pageSize: 10,
        totalItemSize: 0,
        totalPageSize: 0,
        currentPageItemSize: 0,
        fn: () => {
        }
    })

    /**
     * 取得查詢所有功能操作說明維護
     * @return {void}
     */
    const getQueryFunctionDoc = () => {
        rsisQueryFunctionDoc({}).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            // 取得成功
            if (err === ApiErrorStatusEnum.Success) {
                // 設定列表資料
                setDataList(data)
            } else { // 取得失敗
                // 清空資料
                setDataList([])
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 第一次執行時
     * @return {void}
     */
    useEffect(() => getQueryFunctionDoc(), [])

    return (
        <div className='px-4 py-2'>
            <CustomTable advancedSearch={true} fields={fields} dataList={dataList} ListItem={List}
                         type="RSISFunctionOperateMaintainTable"
                         paginationProps={paginationProps}/>
            {/** 功能操作說明維護編輯彈出視窗 */}
            {
                showFunctionOperateIntroEditModal &&
                <FunctionOperateIntroMaintain
                    data={focusItem}
                    handleOnClose={setShowFunctionOperateIntroEditModal}
                    handleOnUpdate={getQueryFunctionDoc}
                />
            }
        </div>
    )
}

export default FunctionOperateMaintain
