
const RSISMasterQueryTable = {
    fields:[
        "",
        ['申請單號','院區名稱'],
        ['申請人代號','姓名'],
        ['支援醫師代號','姓名'],
        ['支援機構代號', '名稱'],
        '支援科別',
        ['報備期間','開始-結束日期'],
        '狀態別',
        "",
        '衛福部案號',
        '匯出編號'
    ]
}

const RSISCaseManagementTable = {
    fields:[
        "",
        ['申請單號','院區名稱'],
        ['申請人代號','姓名'],
        ['支援醫師代號','姓名'],
        ['支援機構代號', '名稱'],
        ['支援科別代號', '名稱'],
        ['報備期間','開始-結束日期 時間'],
        '狀態別',
        "",
        '衛福部案號',
        '匯出編號',
        '備註'
    ]
}

const RSISResponisblePersonnelQueryTable = {
    key:[
        "人員工號",
        "姓名",
        "部門代號",
        "部門名稱"
    ]
}

const RSISPersonnelListQueryTable = {
    key:[
        "醫師代號/工號",
        "部門",
        "姓名",
        "執登院所"
    ]
}

const RSISSupportOrgQueryTable = {
    key:[
        "機構代碼",
        "機構型態",
        "機構名稱",
        "機構住址",
    ]
}

const RSISChangeRecordQueryTable = {
    fields:[
        "版次",
        "機構代號",
        "支援科別",
        "支援目的",
        "支援期間",
        "變更欄位"
    ]
}

const RSISAddModalTable = {
    fields: [
        "",
        "開始日期",
        "結束日期",
        "開始時間",
        "結束時間",
    ]
}

const RSISLogOffDeleteBatchesTable = {
    registryFields: [
        ['申請單號','院區名稱'],
        '申請人',
        ['支援醫師', '姓名'],
        ['支援機構代號', '名稱'],
        '支援科別',
        ['報備期間','開始-結束日期'],
        '狀態別',
        "送審編號",
        '衛福部案號',
    ],
    submitFields: [
        ['申請單號','院區名稱'],
        '申請人',
        ['支援醫師', '姓名'],
        ['支援機構代號', '名稱'],
        '支援科別',
        ['報備期間','開始-結束日期'],
        '狀態別',
        "",
        "送審編號",
        '衛福部案號',
    ],
}

const MOHWgovFeedbackTable = {
    fields: [
        "",
        '匯出編號',
        '備註',
        '衛福部案號',
        '匯出人員',
        '匯出時間',
    ]
}

//EDAQA-377 加入機構型態
const SupportOrgTable = {
    fields: [
        "",
        "機構型態",
        "機構代碼",
        "機構名稱",
        "機構住址",
    ]
}

const RSISQueryHospitalTable = {
    fields: [
        "院所代碼",
        "醫院院所名稱",
        "院所地址",
    ]
}

export {
    RSISMasterQueryTable,
    RSISCaseManagementTable,
    RSISResponisblePersonnelQueryTable,
    RSISPersonnelListQueryTable,
    RSISSupportOrgQueryTable,
    RSISChangeRecordQueryTable,
    RSISAddModalTable,
    RSISLogOffDeleteBatchesTable,
    MOHWgovFeedbackTable,
    SupportOrgTable,
    RSISQueryHospitalTable
}