import { useSelector } from 'react-redux'
import A4Print from './A4Print'
import { useEffect } from 'react'
import { time2String } from 'edah_utils/dist'
import { DATE_FORMAT } from '../../constants/common'


/**
 * 列印轉回原醫療院所名單內容
 * @returns {JSX.Element}
 */
const TranBackCaseListContent = () => {
    const printData = useSelector((state) => state.print.printData)
    return (
      <div className="mx-auto p-4 font-sans">
        <p className="text-right mb-3">轉診組您好:</p>
        <p>
          為落實兩院病患醫療照顧之雙向轉診制度，本院隨信檢附一份貴院轉診至本院就醫
          病患單，懇請貴院協助追蹤名單中之病患是否有續回貴院就醫追蹤，請貴院協助
          查閱後回覆給我們，謝謝您們的幫忙!
        </p>
        <p className="text-right mt-3 mb-2">義大醫院財團法人義大醫院轉診服務中心</p>
        <table className="w-full border-collapse border border-[#111111]/15 text-sm mb-4">
          <thead>
            <tr className="text-[#18181b] border-b border-[#111111]/15">
              <th
                rowSpan="2"
                className="break-words p-2 border border-[#111111]/15"
              >
                編號
              </th>
              <th
                rowSpan="2"
                className="break-words p-2 border border-[#111111]/15 w-[20%]"
              >
                就醫日期
              </th>
              <th
                rowSpan="2"
                className="break-words p-2 border border-[#111111]/15"
              >
                病歷號
              </th>
              <th rowSpan="2" className="p-2 border border-[#111111]/15">
                身分證號
              </th>
              <th
                rowSpan="2"
                className="p-2 border border-[#111111]/15 w-[20%]"
              >
                姓名
              </th>
              <th colSpan="4" className="p-2">
                追蹤狀態
              </th>
            </tr>
            <tr>
              <th className="border border-[#111111]/15 p-2 w-[10%]">住院</th>
              <th className="border break-words border-[#111111]/15 p-2 w-[10%]">
                門診追蹤治療
              </th>
              <th className="border border-[#111111]/15 p-2 w-[10%]">未回院</th>
              <th className="border border-[#111111]/15 p-2 w-[10%]">其他</th>
            </tr>
          </thead>
          <tbody>
            {printData ? (
              printData.map((item, index) => (
                <tr
                  key={index}
                  className={`h-[50px] text-[#18181b] ${
                    index === printData.length
                      ? ""
                      : "border-b border-[#111111]/15"
                  }`}
                >
                  <td className="break-words p-2 border-r border-[#111111]/15">
                    {item.encounterId}
                  </td>
                  <td className="break-words p-2 border-r border-[#111111]/15">
                    {time2String(item.encounterDate, DATE_FORMAT)}
                  </td>
                  <td className="break-words p-2 border-r border-[#111111]/15">
                    {item.patientId}
                  </td>
                  <td className="break-words p-2 border-r border-[#111111]/15">
                    {item.idNo}
                  </td>
                  <td className="break-words p-2 border-r border-[#111111]/15">
                    {item.patientName}
                  </td>
                  <td className="border-r border-[#111111]/15"></td>
                  <td className="border-r border-[#111111]/15"></td>
                  <td className="border-r border-[#111111]/15"></td>
                  <td className="border-r border-[#111111]/15"></td>
                </tr>
              ))
            ) : (
              <></>
            )}
          </tbody>
        </table>
      </div>
    );
}

/**
 * 列印轉回原醫療院所名單
 * @returns {JSX.Element}
 */
const PrintTranBackCaseList = () => {
    return <A4Print><TranBackCaseListContent /></A4Print>
}

export default PrintTranBackCaseList
