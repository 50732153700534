import {NavLink} from "react-router-dom";
import {Card} from "edah-component/dist";

const InfoCard = ({titles, height, children}) => {
    return (
        <Card title={titles}>
            {children}
        </Card>
    );
};

export default InfoCard;
