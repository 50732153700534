import React, {useEffect, useRef, useState} from 'react'
import {t} from "i18next"
import {
    arrayIsEmpty,
    objectIsEmpty,
    RegsWeekReservationLimitEnum,
    RegsWeekReservationMethodEnum,
    stringIsEmpty
} from "edah_utils/dist"
import {
    Button,
    ButtonSizeEnum,
    ButtonVariantEnum,
    Checkbox,
    ColorEnum,
    Field,
    Select,
    SizeEnum,
    Tabs,
    TabsEnum,
    TextField,
    RadioGroup,
    Search
} from 'edah-component/dist'

/**
 * 批次更新表單
 * @param {Object} previewData 觀看的資料
 * @param {Object} baseData 基礎資料
 * @param {Function}  儲存事件
 * @return {JSX.Element}
 */
const ScheduleBatchesUpdateForm = ({
                                       previewData,
                                       selectableData = null,
                                       baseData,
                                       onSave
                                   }) => {
    const defaultModifyData = {
        //預約最大看診人次
        limit: null,
        //現場最大看診人次
        onsiteLimit: null,
        //看診時間
        slotMin: null,
        //預約方式
        giveNumType: null,
        //預約限制
        reserveLimitType: null,
        //收費別
        cashType: null,
        //網掛/APP
        webapptFlag: null,
        //網掛/APP取消看診進度
        showProcessFlag: null,
        //預約網掛天數
        webapptDay: null,
        //診室地點
        clinicLocation: null,
        //注意事項
        weekNotice: null,
        //診間標語
        clinicSlogan: null,
        //代班醫師
        locumDoctor: null,
        //停診
        stopClinicFlag: null,
        //停診原因
        stopClinicReason: null,
        //當日停診公告
        stopClinicBulletinFlag: null,
    }
    //變動的資料
    const [modifyData, setModifyData] = useState(defaultModifyData)
    //現行資料
    const [currentData, setCurrentData] = useState(previewData)

    //當前tab
    const [currentTab, setCurrentTab] = useState("current")
    // tab 文字顏色 、 tab底線顏色
    const [tabColor, setTabColor] = useState(["text-primary", "border-b-primary", "text-base", "border-b-base"])
    // 代理醫師下拉選項
    const [locumDoctorOptionList, setLocumDoctorOptionList] = useState(null)

    /**
     * 根據搜尋文字過濾代診醫生選項
     * @return {Array}
     */
    const getFilterLocumDoctorOptions = () => {
        const searchText = modifyData.locumDoctor
        // 搜尋文字為空
        if (stringIsEmpty(searchText)) {
            return baseData.doctorList
        } else { //搜尋文字不為空
            //是否有空白
            const hasSpace = searchText.indexOf(' ') >= 0
            //分割搜尋文字
            const splitSearchTextArray = searchText.split(' ')

            return baseData.doctorList.filter(doctor => hasSpace ?
                (doctor.userNo.includes(splitSearchTextArray[0]) && doctor.userName.includes(splitSearchTextArray[1])) :
                (doctor.userNo.includes(searchText) || doctor.userName.includes(searchText))
            )
        }
    }

    /**
     *
     * @param data {Object} 現行資料tab, updateForm 預異動項目tab
     */
    const handleTableOnChange = (data) => {
        // setCurrentTab(data)
        if (data !== currentTab) setCurrentTab(data)
        if (data === "current") {
            setTabColor(["text-primary", "border-b-primary", "text-base", "border-b-base"])
        } else if (data === "update") {
            setTabColor(["text-base", "border-b-base", "text-primary", "border-b-primary"])
        }
    }

    /**
     * 預約最大看診人次改變時
     * @param v
     */
    const handleMaxPatientOnChange = (v) => {
        setModifyData({
            ...modifyData,
            limit: v
        })
    }

    /**
     * 現場最大看診人次人數改變時
     * @param v
     */
    const handleSiteNumberOnChange = (v) => {
        setModifyData({
            ...modifyData,
            onsiteLimit: v
        })
    }

    /**
     * 預約方式改變時
     * @param e {event}
     * @return {void}
     */
    const handleReservationMethodOnChange = (e) => {
        setModifyData({
            ...modifyData,
            giveNumType: e.target.value
        })
    }

    /**
     * 預約限制改變時
     * @param e {event}
     * @return {void}
     */
    const handleReservationLimitOnChange = (e) => {
        setModifyData({
            ...modifyData,
            reserveLimitType: e.target.value
        })
    }

    /**
     * 收費別改變時
     * @param value {String}
     * @return {void}
     */
    const handleCashTypeOnChange = (value) => {
        setModifyData({
            ...modifyData,
            cashType: value
        })
    }

    /**
     * 通報單位改變時
     * @param v
     */
    const handleNotificationUnitOnChange = (v) => {
        setModifyData({
            ...modifyData,
            notificationUnit: v
        })
    }

    /**
     * 是否調閱病歷改變時
     * @param v
     */
    const handleSentChartFlagOnChange = () => {
        setModifyData({
            ...modifyData,
            sentChartFlag: !sentChartFlag
        })
    }

    /**
     * 是否網掛/APP
     *
     */
    const handleWebAppFlagOnChange = (state) => {
        setModifyData({
            ...modifyData,
            webapptFlag: state
        })
    }

    /**
     * 是否網掛/APP取消看診進度
     *
     */
    const handleWebOrAppCancelOnChange = (state) => {
        setModifyData({
            ...modifyData,
            showProcessFlag: state
        })
    }

    /**
     * 診室地點改變時
     * @param v
     */
    const handleClinicLocationOnChange = (v) => {
        setModifyData({
            ...modifyData,
            clinicLocation: v
        })
    }

    /**
     * 注意事項改變時
     * @param v
     */
    const handleWeekNoticeOnChange = (v) => {
        setModifyData({
            ...modifyData,
            weekNotice: v
        })
    }

    /**
     * 診間標語改變時
     * @param v
     */
    const handleClinicSloganOnChange = (v) => {
        setModifyData({
            ...modifyData,
            clinicSlogan: v
        })
    }

    /**
     * 停診原因改變時
     * @param v
     */
    const handleStopClinicReasonOnChange = (value) => {
        setModifyData({
            ...modifyData,
            stopClinicReason: value
        })
    }

    /**
     * 當日停代診公告改變時
     */
    const handleStopClinicBulletinFlagOnChange = () => {
        setModifyData({
            ...modifyData,
            stopClinicBulletinFlag: !modifyData.stopClinicBulletinFlag
        })
    }

    /**
     * 代班醫師輸入框輸入變更
     * @param {string} inputValue
     */
    const handleLocumDoctorOnInputChange = (inputValue) => {
        let haveLocumDoctor = !stringIsEmpty(inputValue)
        //代班醫師要連動停代診公告
        setModifyData({
            ...modifyData,
            locumDoctor: inputValue,
            stopClinicBulletinFlag: haveLocumDoctor
        })
    }

    /**
     * 選取代理醫師下拉選項時
     * @param item {Object} 選取的選項
     * @return {void}
     */
    const handleLocumDoctorOptionOnClick = (item) => {
        if (objectIsEmpty(item)) return
        setModifyData({
            ...modifyData,
            locumDoctor: `${item.userNo} ${item.userName}`,
            stopClinicBulletinFlag: true
        })
    }

    /**
     * 停診勾選變更
     */
    const handleStopClinicFlagOnChange = () => {
        let checked = !modifyData.stopClinicFlag
        let stopClinicReason = modifyData.stopClinicReason
        if (checked && stringIsEmpty(stopClinicReason)) {
            //勾選停診原因，但停診原因下拉值為空時
            stopClinicReason = baseData.stopClinicReasonList[0].justnameNo
        } else if (!checked) {
            stopClinicReason = ""
        }

        setModifyData({
            ...modifyData,
            stopClinicFlag: checked,
            stopClinicBulletinFlag: checked,
            stopClinicReason: stopClinicReason
        })
    }

    /**
     * 網掛預約期限(天)
     * @param {Event} e
     */
    const handleWenapptDayOnChange = (e) => {
        setModifyData({
            ...modifyData,
            webapptDay: e.target.value
        })
    }

    const handleConsultationTimeOnChange = (time) => {
        setModifyData({
            ...modifyData,
            slotMin: time
        })
    }

    /**
     * 點擊儲存批次更新按鈕事件
     */
    const handleSaveBacthUpdateOnClick = () => {
        let stopReason = null, locumDoctor = null
        if (modifyData.stopClinicFlag !== null && modifyData.stopClinicFlag === true) {
            stopReason = modifyData.stopClinicReason
        }
        if (!stringIsEmpty(modifyData.locumDoctor)) {
            locumDoctor = modifyData.locumDoctor.split(' ')[0]
        }

        onSave({
            //預約最大看診人次
            limit: modifyData.limit,
            //現場最大看診人次
            onsiteLimit: modifyData.onsiteLimit,
            //看診時間
            slotMin: modifyData.slotMin,
            //預約方式
            giveNumType: modifyData.giveNumType,
            //預約限制
            reserveLimitType: modifyData.reserveLimitType,
            //收費別
            cashType: modifyData.cashType,
            //網掛/APP
            webapptFlag: modifyData.webapptFlag,
            //網掛/APP取消看診進度
            showProcessFlag: modifyData.showProcessFlag,
            //預約網掛天數
            webapptDay: modifyData.webapptDay,
            //診室地點
            clinicLocation: modifyData.clinicLocation,
            //注意事項
            weekNotice: modifyData.weekNotice,
            //診間標語
            clinicSlogan: modifyData.clinicSlogan,
            //代班醫師
            locumDoctor: locumDoctor,
            //停診
            stopClinicFlag: modifyData.stopClinicFlag,
            //停診原因
            stopClinicReason: stopReason,
            //當日停診公告
            stopClinicBulletinFlag: modifyData.stopClinicBulletinFlag
        })
        //reset異動項目
        setModifyData(defaultModifyData)
    }

    /**
     * 取得預約限制字串
     * @param {String} type 限制類型
     */
    const getReserveLimitTypeString = (type) => {
        switch (type) {
            case RegsWeekReservationLimitEnum.Normal:
                return t(`Regs.RegsWeekReservationLimitEnum.${RegsWeekReservationLimitEnum.Normal}`)
            case RegsWeekReservationLimitEnum.DoctorOnly:
                return t(`Regs.RegsWeekReservationLimitEnum.${RegsWeekReservationLimitEnum.DoctorOnly}`)
            case RegsWeekReservationLimitEnum.DoctorSelfOnly:
                return t(`Regs.RegsWeekReservationLimitEnum.${RegsWeekReservationLimitEnum.DoctorSelfOnly}`)
        }
        return ''
    }

    /**
     * 取得代班醫師名字
     * @param {String} doctorNo 醫生碼
     */
    const getLocumDoctorName = (doctorNo) => {
        if (doctorNo === 'ALL') {
            return ''
        }
        const findedDoctor = baseData.doctorList.find(item => item.userNo === doctorNo)

        return objectIsEmpty(findedDoctor) ? '' : findedDoctor.userName
    }

    /**
     * 取得收費別字串
     * @param {String} type 收費別
     */
    const getCashTypeString = (type) => {
        const findedCashType = baseData.cashTypeList.find(item => item.cashType === type)

        return objectIsEmpty(findedCashType) ? '' : findedCashType.cashName
    }

    /**
     * 取得停診原因字串
     * @param {String} no 理由碼
     * @returns
     */
    const getStopClinicReasonName = (no) => {
        const finded = baseData.stopClinicReasonList.find(item => item.justnameNo === no)

        return objectIsEmpty(finded) ? '' : finded.justnameName
    }


    const getTabItem = () => {
        return [
            // 現行資料
            {
                id: 'current',
                title: t('Schedule.scheduleCurrentData'),
                content: currentTab === 'current' && !objectIsEmpty(currentData) &&
                    <div className='w-full min-w-[742px] flex flex-col gap-[5px] min-h-[976px]'>
                        {/* 預約最大看診人次 */}
                        <Field labelWidth={170} label={t("Regs.general.maximumNumberOfAppointments")}>
                            <TextField
                                value={currentData.limit}
                                disabled={true}
                            />
                        </Field>
                        {/* 現場最大看診人次 */}
                        <Field labelWidth={170} label={t("Regs.general.maximumOnsitePatients")}>
                            <TextField
                                value={currentData.onsiteLimit}
                                disabled={true}
                            />
                        </Field>
                        {/* 看診時間(分/人) */}
                        <Field labelWidth={170} label={t("Regs.general.slotTime")}>
                            <TextField
                                value={currentData.slotMin}
                                disabled={true}
                            />
                        </Field>
                        {/* 預約方式 */}
                        <Field labelWidth={170} label={t("Regs.general.reservationMethod")}>
                            <div className='w-[260px] flex flex-row items-center pl-4 space-x-4'>
                                <RadioGroup
                                    row={true}
                                    size={SizeEnum.Medium}
                                    defaultValue={currentData.giveNumType}
                                    value={currentData.giveNumType}
                                    optionProps={{
                                        options: [
                                            // 診號
                                            {
                                                label: t(`Regs.RegsWeekReservationMethodEnum.${RegsWeekReservationMethodEnum.ClinicNumber}`),
                                                value: RegsWeekReservationMethodEnum.ClinicNumber.toString(),
                                                disabled: currentData.giveNumType !== RegsWeekReservationMethodEnum.ClinicNumber.toString()
                                            },
                                            // 時間
                                            {
                                                label: t(`Regs.RegsWeekReservationMethodEnum.${RegsWeekReservationMethodEnum.Time}`),
                                                value: RegsWeekReservationMethodEnum.Time.toString(),
                                                disabled: currentData.giveNumType !== RegsWeekReservationMethodEnum.Time.toString(),
                                            },
                                        ]
                                    }}
                                    onChange={undefined}/>
                            </div>
                        </Field>
                        {/* 預約限制 */}
                        <Field labelWidth={170} label={t("Regs.general.reservationRestriction")}>
                            <div className='w-[350px] flex flex-row items-center pl-4 space-x-4'>
                                <RadioGroup
                                    row={true} size={SizeEnum.Medium}
                                    defaultValue={currentData.reserveLimitType}
                                    value={currentData.reserveLimitType}
                                    optionProps={{
                                        options: [
                                            // 一般
                                            {
                                                label: t(`Regs.RegsWeekReservationLimitEnum.${RegsWeekReservationLimitEnum.Normal}`),
                                                value: RegsWeekReservationLimitEnum.Normal,
                                                disabled: currentData.reserveLimitType !== RegsWeekReservationLimitEnum.Normal
                                            },
                                            // 限醫師
                                            {
                                                label: t(`Regs.RegsWeekReservationLimitEnum.${RegsWeekReservationLimitEnum.DoctorOnly}`),
                                                value: RegsWeekReservationLimitEnum.DoctorOnly,
                                                disabled: currentData.reserveLimitType !== RegsWeekReservationLimitEnum.DoctorOnly
                                            },
                                            // 限醫生本人
                                            {
                                                label: t(`Regs.RegsWeekReservationLimitEnum.${RegsWeekReservationLimitEnum.DoctorSelfOnly}`),
                                                value: RegsWeekReservationLimitEnum.DoctorSelfOnly,
                                                disabled: currentData.reserveLimitType !== RegsWeekReservationLimitEnum.DoctorSelfOnly
                                            }
                                        ]
                                    }}
                                    onChange={undefined}/>
                            </div>
                        </Field>
                        {/* 收費別 */}
                        <Field labelWidth={170} label={t("Regs.general.cashType")}>
                            <Select
                                data={{
                                    label: "收費別",
                                    options: [{
                                        value: currentData.cashType,
                                        label: currentData.cashTypeName
                                    }]
                                }}
                                value={currentData.cashType || ''}
                                disabled
                                showLabel={false}
                                readOnly={true}
                            />
                        </Field>
                        {/* 調病歷 */}
                        <Field labelWidth={170} isShowColon={false}>
                            <Checkbox
                                label={t("Regs.general.reviewMedicalRecords")}
                                checked={currentData.sentChartFlag}
                                onChange={() => {
                                }}
                            />
                        </Field>
                        {/* 網掛/APP */}
                        <Field labelWidth={170} isShowColon={false}>
                            <Checkbox
                                label={t("Regs.general.webAppointment")}
                                checked={currentData.webapptFlag}
                                onChange={() => {
                                }}
                            />
                        </Field>
                        {/* 網掛/APP取消看診進度 */}
                        <Field labelWidth={170} isShowColon={false}>
                            <Checkbox
                                label={t("Regs.general.webAppointmentCancelProcess")}
                                checked={currentData.showProcessFlag}
                                onChange={() => {
                                }}
                            />
                        </Field>
                        <Field labelWidth={170} label={t("Regs.general.webAppointmentLimit")}>
                            <div className="flex items-center">
                                <TextField
                                    size={SizeEnum.Small}
                                    inputWidth={SizeEnum.Small}
                                    type='number'
                                    value={currentData.webapptDay}
                                    disabled={true}
                                />
                                <span>天</span>
                            </div>
                        </Field>
                        <Field labelWidth={170} label={t("Regs.general.clinicLocation")}>
                            <TextField
                                size={SizeEnum.Small}
                                inputWidth={SizeEnum.Large}
                                value={currentData.clinicLocation}
                                disabled={true}
                            />
                        </Field>
                        <Field labelWidth={170} label={t("Regs.general.notice")}>
                            <TextField
                                size={SizeEnum.Small}
                                inputWidth={SizeEnum.Large}
                                value={currentData.weekNotice ? currentData.weekNotice : ""}
                                disabled={true}
                            />
                        </Field>
                        <Field labelWidth={170} label={t("Regs.general.locumDoctor")}>
                            <Select
                                data={{
                                    label: "代班醫師",
                                    options: [{
                                        value: currentData.locumDoctor,
                                        label: currentData.locumDoctor
                                    }]
                                }}
                                value={currentData.locumDoctor || ''}
                                readOnly={true}
                                disabled
                                showLabel={false}
                            />
                        </Field>
                        <Field labelWidth={170} label={
                            <Checkbox
                                label={t("Regs.general.stopClinicReason")}
                                checked={currentData.stopClinicFlag ? currentData.stopClinicFlag : false}
                                onChange={() => {
                                }}
                            />
                        }>
                            <Select
                                data={{
                                    label: "停診原因",
                                    options: [{
                                        value: currentData.stopClinicReason,
                                        label: currentData.stopClinicReasonText
                                    }]
                                }}
                                disabled={true}
                                value={currentData.stopClinicReason || ""}
                                showLabel={false}
                                readOnly={true}
                            />
                        </Field>
                        <Field labelWidth={170} isShowColon={false}>
                            <Checkbox
                                label={t("Regs.general.stopClinicBulletinForTheDay")}
                                checked={currentData.stopClinicBulletinFlag}
                                onChange={() => {
                                }}
                            />
                        </Field>
                    </div>,
            },
            // 預異動項目
            {
                id: 'update',
                title: t('Schedule.schedulePreUpdateItem'),
                content: currentTab === "update" && !arrayIsEmpty(selectableData) &&
                    <div className='w-full min-w-[742px] flex flex-col gap-[5px] min-h-[1083px]'>
                        {/* 預約最大看診人次 */}
                        <Field labelWidth={170} label={t("Regs.general.maximumNumberOfAppointments")}>
                            <TextField
                                size={SizeEnum.Small}
                                inputWidth={SizeEnum.Large}
                                value={modifyData.limit}
                                onChange={(e) => handleMaxPatientOnChange(e.target.value)}
                                type='number'
                            />
                            {/*Input hide number arrow*/}
                            <style>
                                {`
                        input::-webkit-outer-spin-button,
                        input::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }
                        input[type="number"] {
                            -moz-appearance: textfield;
                        }
                        `}
                            </style>
                        </Field>

                        {/* 現場最大看診人次 */}
                        <Field labelWidth={170} label={t("Regs.general.maximumOnsitePatients")}>
                            <TextField
                                size={SizeEnum.Small}
                                inputWidth={SizeEnum.Large}
                                type='number'
                                value={modifyData.onsiteLimit}
                                onChange={(e) => handleSiteNumberOnChange(e.target.value)}
                            />
                            {/*Input hide number arrow*/}
                            <style>
                                {`
                        input::-webkit-outer-spin-button,
                        input::-webkit-inner-spin-button {
                                -webkit-appearance: none;
                            margin: 0;
                        }
                        input[type="number"] {
                            -moz-appearance: textfield;
                            }
                        `}
                            </style>
                        </Field>
                        {/* 看診時間(分/人) */}
                        <Field labelWidth={170} label={t("Regs.general.slotTime")}>
                            <TextField
                                size={SizeEnum.Small}
                                inputWidth={SizeEnum.Large}
                                type='number'
                                value={modifyData.slotMin}
                                readOnly={false}
                                onChange={(e) => handleConsultationTimeOnChange(e.target.value)}
                            />
                            {/*Input hide number arrow*/}
                            <style>
                                {`
                        input::-webkit-outer-spin-button,
                        input::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }
                        input[type="number"] {
                            -moz-appearance: textfield;
                        }
                        `}
                            </style>
                        </Field>
                        {/* 預約方式 */}
                        <Field labelWidth={170} label={t("Regs.general.reservationMethod")}>
                            <div className='w-[260px] flex flex-row items-center pl-4 space-x-4'>
                                <RadioGroup
                                    row
                                    size={SizeEnum.Medium}
                                    defaultValue={modifyData.giveNumType}
                                    value={modifyData.giveNumType}
                                    optionProps={{
                                        options: [
                                            // 診號
                                            {
                                                label: t(`Regs.RegsWeekReservationMethodEnum.${RegsWeekReservationMethodEnum.ClinicNumber}`),
                                                value: RegsWeekReservationMethodEnum.ClinicNumber.toString(),
                                            },
                                            // 時間
                                            {
                                                label: t(`Regs.RegsWeekReservationMethodEnum.${RegsWeekReservationMethodEnum.Time}`),
                                                value: RegsWeekReservationMethodEnum.Time.toString(),
                                            },
                                        ]
                                    }}
                                    onChange={(e) => handleReservationMethodOnChange(e)}/>
                            </div>
                        </Field>
                        {/* 預約限制 */}
                        <Field labelWidth={170} label={t("Regs.general.reservationRestriction")}>
                            <div className='w-[350px] flex flex-row items-center pl-4 space-x-4'>
                                <RadioGroup
                                    row size={SizeEnum.Medium}
                                    defaultValue={modifyData.reserveLimitType}
                                    value={modifyData.reserveLimitType}
                                    optionProps={{
                                        options: [
                                            // 一般
                                            {
                                                label: t(`Regs.RegsWeekReservationLimitEnum.${RegsWeekReservationLimitEnum.Normal}`),
                                                value: RegsWeekReservationLimitEnum.Normal,
                                            },
                                            // 限醫師
                                            {
                                                label: t(`Regs.RegsWeekReservationLimitEnum.${RegsWeekReservationLimitEnum.DoctorOnly}`),
                                                value: RegsWeekReservationLimitEnum.DoctorOnly,
                                            },
                                            // 限醫生本人
                                            {
                                                label: t(`Regs.RegsWeekReservationLimitEnum.${RegsWeekReservationLimitEnum.DoctorSelfOnly}`),
                                                value: RegsWeekReservationLimitEnum.DoctorSelfOnly,
                                            }
                                        ]
                                    }}
                                    onChange={(e) => handleReservationLimitOnChange(e)}/>
                            </div>
                        </Field>
                        {/* 收費別 */}
                        <Field labelWidth={170} label={t("Regs.general.cashType")}>
                            <div className="w-[260px] h-[42px] flex justify-center">
                                <Select
                                    data={{
                                        label: '收費別',
                                        options: baseData.cashTypeList?.map((item) => ({
                                            value: item.cashType,
                                            label: item.cashName
                                        })) || []
                                    }}
                                    value={modifyData.cashType || ''}
                                    onChange={handleCashTypeOnChange}
                                    showLabel = {false}
                                />
                            </div>
                        </Field>
                        {/* 網掛/APP */}
                        <Field labelWidth={170} label="開放網掛/APP">
                            <div className="w-[289px] h-[42px] flex justify-start items-center pl-4 space-x-4">
                                <RadioGroup
                                    row size={SizeEnum.Medium}
                                    defaultValue={modifyData.webapptFlag}
                                    value={modifyData.webapptFlag}
                                    optionProps={{
                                        options: [
                                            {
                                                label: t('general.yes'),
                                                value: true,
                                            },
                                            {
                                                label: t('general.no'),
                                                value: false,
                                            },
                                        ]
                                    }}
                                    onChange={(e) => handleWebAppFlagOnChange(e.target.value === 'true')}/>
                            </div>
                        </Field>
                        {/* 網掛/APP取消看診進度 */}
                        <Field labelWidth={170} label="網掛/APP取消看診進度">
                            <div className="w-[289px] h-[42px] flex justify-start items-center pl-4 space-x-4">
                                <RadioGroup
                                    row size={SizeEnum.Medium}
                                    defaultValue={modifyData.showProcessFlag}
                                    value={modifyData.showProcessFlag}
                                    optionProps={{
                                        options: [
                                            {
                                                label: t('general.yes'),
                                                value: true,
                                            },
                                            {
                                                label: t('general.no'),
                                                value: false,
                                            },
                                        ]
                                    }}
                                    onChange={(e) => handleWebOrAppCancelOnChange(e.target.value === 'true')}/>
                            </div>
                        </Field>
                        {/*網掛預約期限 */}
                        <Field labelWidth={170} label={t("Regs.general.webAppointmentLimit")}>
                            <div className="flex items-center">
                                <div className='w-[130px] h-[42px] flex justify-center'>
                                    <TextField
                                        type='number'
                                        value={modifyData.webapptDay}
                                        onChange={handleWenapptDayOnChange}
                                    />
                                    {/*Input hide number arrow*/}
                                    <style>
                                        {`
                                input::-webkit-outer-spin-button,
                                input::-webkit-inner-spin-button {
                                    -webkit-appearance: none;
                                    margin: 0;
                                }
                                input[type="number"] {
                                    -moz-appearance: textfield;
                                }
                                `}
                                    </style>
                                </div>
                                <div className='h-[42px] flex items-center'>
                                    <p>{t("general.dateTime.day")}</p>
                                </div>
                            </div>
                        </Field>
                        {/* 診室地點 */}
                        <Field labelWidth={170} label={t("Regs.general.clinicLocation")}>
                            <TextField
                                value={modifyData.clinicLocation}
                                onChange={(e) => handleClinicLocationOnChange(e.target.value)}
                            />
                        </Field>
                        {/* 注意事項 */}
                        <Field labelWidth={170} label={t("Regs.general.notice")}>
                            <TextField
                                value={modifyData.weekNotice}
                                onChange={(e) => handleWeekNoticeOnChange(e.target.value)}
                                readOnly={false}
                            />
                        </Field>
                        {/* 診間標語 */}
                        <Field labelWidth={170} label={t("Regs.general.clinicSlogan")}>
                            <TextField
                                value={modifyData.clinicSlogan}
                                onChange={(e) => handleClinicSloganOnChange(e.target.value)}
                                readOnly={false}
                            />
                        </Field>
                        {/* 代班醫師 */}
                        <Field labelWidth={170} label={t("Regs.general.locumDoctor")}>
                            <Search
                                onChange={(_e, value) => {
                                    handleLocumDoctorOptionOnClick(value)
                                }}
                                onInputChange={(_e, newInputValue) => {
                                    handleLocumDoctorOnInputChange(newInputValue)
                                }
                                }
                                disablePortal={false}
                                options={locumDoctorOptionList}
                                showLabel
                                getOptionLabel={(option) => `${option?.userNo} ${option?.userName}`}
                            />

                        </Field>
                        {/* 停診 */}
                        <Field
                            labelWidth={170}
                            label={<Checkbox
                                label={t("Regs.general.stopClinic")}
                                checked={modifyData.stopClinicFlag}
                                onChange={handleStopClinicFlagOnChange}
                                disabled={!stringIsEmpty(modifyData.locumDoctor)}/>}
                        >
                            {/* 停診原因 */}
                            <div className="w-[260px] h-[42px] flex justify-center">
                                <Select
                                    data={{
                                        label: '停診原因',
                                        options: baseData?.stopClinicReasonList?.map((reason) => ({
                                            value: reason.justnameNo,
                                            label: reason.justnameName
                                        })) || []
                                    }}
                                    showLabel={!modifyData.stopClinicFlag}
                                    value={modifyData.stopClinicReason || ''}
                                    onChange={handleStopClinicReasonOnChange}
                                    disabled={!modifyData.stopClinicFlag}
                                />
                            </div>
                        </Field>
                        {/* 當日停代診公告 */}
                        <Field labelWidth={170} isShowColon={false}>
                            <Checkbox
                                label={t("Regs.general.stopClinicBulletinForTheDay")}
                                checked={modifyData.stopClinicBulletinFlag}
                                onChange={handleStopClinicBulletinFlagOnChange}
                            />
                        </Field>

                        <div className='border-solid border-t-2 w-full'>
                            <div
                                className='w-full h-[42px] mb-2 flex flex-start justify-start items-center text-xl text-primary pl-4 pt-2'>
                                {t('Regs.ScheduleBatchedUpdate.selectedDatasIntendedChangedInformation', {selectedCount: arrayIsEmpty(selectableData) ? "0" : selectableData.length})}
                            </div>
                            {modifyData.limit !== null &&
                                <Field labelWidth={170} label={t("Regs.general.maximumNumberOfAppointments")}>
                                    {modifyData.limit}
                                </Field>
                            }
                            {modifyData.onsiteLimit != null &&
                                <Field labelWidth={170} label={t("Regs.general.maximumOnsitePatients")}>
                                    {modifyData.onsiteLimit}
                                </Field>
                            }
                            {modifyData.slotMin != null &&
                                <Field labelWidth={170} label={t("Regs.general.slotTime")}>
                                    {modifyData.slotMin}
                                </Field>
                            }
                            {modifyData.giveNumType !== null &&
                                <Field labelWidth={170} label={t("Regs.general.reservationMethod")}>
                                    {modifyData.giveNumType === RegsWeekReservationMethodEnum.Time.toString() ? t(`Regs.RegsWeekReservationMethodEnum.${RegsWeekReservationMethodEnum.Time}`) : t(`Regs.RegsWeekReservationMethodEnum.${RegsWeekReservationMethodEnum.ClinicNumber}`)}
                                </Field>
                            }
                            {modifyData.reserveLimitType !== null &&
                                <Field labelWidth={170} label={t("Regs.general.reservationRestriction")}>
                                    {getReserveLimitTypeString(modifyData.reserveLimitType)}
                                </Field>
                            }
                            {modifyData.cashType !== null &&
                                <Field labelWidth={170} label={t("Regs.general.cashType")}>
                                    {getCashTypeString(modifyData.cashType)}
                                </Field>
                            }
                            {modifyData.webapptFlag !== null &&
                                <Field labelWidth={170} label="開放網掛/APP">
                                    {modifyData.webapptFlag ? t('general.yes') : t('general.no')}
                                </Field>
                            }
                            {modifyData.showProcessFlag !== null &&
                                <Field labelWidth={170} label={t("Regs.general.webAppointmentCancelProcess")}>
                                    {modifyData.showProcessFlag ? t('general.yes') : t('general.no')}
                                </Field>
                            }
                            {modifyData.webapptDay !== null &&
                                <Field labelWidth={170} label={t("Regs.general.webAppointmentLimit")}>
                                    {modifyData.webapptDay} {t("general.dateTime.day")}
                                </Field>
                            }
                            {modifyData.clinicLocation !== null &&
                                <Field labelWidth={170} label={t("Regs.general.clinicLocation")}>
                                    {modifyData.clinicLocation}
                                </Field>
                            }
                            {modifyData.weekNotice !== null &&
                                <Field labelWidth={170} label={t("Regs.general.notice")}>
                                    {modifyData.weekNotice}
                                </Field>
                            }
                            {modifyData.clinicSlogan !== null &&
                                <Field labelWidth={170} label={t("Regs.general.clinicSlogan")}>
                                    {modifyData.clinicSlogan}
                                </Field>
                            }
                            {modifyData.locumDoctor !== null &&
                                <Field labelWidth={170} label={t("Regs.general.locumDoctor")}>
                                    {modifyData.locumDoctor}
                                </Field>
                            }
                            {modifyData.stopClinicFlag !== null &&
                                <Field labelWidth={170} label={t("Regs.general.stopClinic")}>
                                    {modifyData.stopClinicFlag ? t('general.yes') : t('general.no')}
                                </Field>
                            }
                            {modifyData.stopClinicReason !== null &&
                                <Field labelWidth={170} label={t("Regs.general.stopClinicReason")}>
                                    {modifyData.stopClinicFlag ? getStopClinicReasonName(modifyData.stopClinicReason) : ''}
                                </Field>
                            }
                            {modifyData.stopClinicBulletinFlag !== null &&
                                <Field labelWidth={170} label={t("Regs.general.stopClinicBulletinForTheDay")}>
                                    {modifyData.stopClinicBulletinFlag ? t('general.yes') : t('general.no')}
                                </Field>
                            }
                            <div className='w-full flex justify-end pr-[32px] pb-2'>
                                <Button
                                    color={ColorEnum.Primary} variant={ButtonVariantEnum.Contained}
                                    size={ButtonSizeEnum.Medium} disabled={arrayIsEmpty(selectableData)}
                                    onClick={handleSaveBacthUpdateOnClick}>
                                    {t('general.saveFile')}
                                </Button>
                            </div>
                        </div>
                    </div>
            }
        ]
    }

    /**
     * 監聽現行資料變動
     */
    useEffect(() => {
        if (previewData !== null) {
            //透過停診原因碼找出停診原因字串
            const findedReason = baseData.stopClinicReasonList.find(item => item.justnameNo === previewData.stopClinicReason)
            const stopClinicReasonText = objectIsEmpty(findedReason) ? "" : findedReason.justnameName
            //透過收費別碼找出收費別字串
            const findedCashType = baseData.cashTypeList.find(item => item.cashType === previewData.cashType)
            const cashTypeName = objectIsEmpty(findedCashType) ? "" : findedCashType.cashName
            setCurrentData({
                ...previewData,
                stopClinicReasonText: stopClinicReasonText,
                cashTypeName: cashTypeName
            })
        } else {
            setCurrentData(null)
        }
    }, [previewData])

    /**
     * 監聽selectableData變化
     */
    useEffect(() => {
        if (arrayIsEmpty(selectableData)) {
            //為空或empty
            //reset異動項目
            setModifyData(defaultModifyData)
        }
    }, [selectableData])

    /**
     * 監聽代理醫生輸入框變化
     */
    useEffect(() => {
        setLocumDoctorOptionList(getFilterLocumDoctorOptions())
    }, [modifyData.locumDoctor])

    return (
        <div className='w-full min-w-[822px] flex flex-col'>
            <Tabs
                items={getTabItem()}
                variant={TabsEnum.INDICATOR_TAB}
                activeTabId={currentTab}
                onTabChange={handleTableOnChange}
                tabsProps={{
                    sx: {
                        '& .MuiTabs-flexContainer': {
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '0.5rem 1.5rem 1.5rem 1.5rem'
                        },
                        '& .MuiTab-root': {
                            flex: 1,
                            maxWidth: 'none',
                            textAlign: 'center',
                        },
                        '& .MuiTabs-indicator': {
                            width: '50%',
                        },
                    }
                }}
            />
        </div>
    )
}

export default ScheduleBatchesUpdateForm
