// Definition of ButtonEnum for Button component
export const ButtonEnum = {
    /**
     * @type {string}
     * Primary Button type definition
    */
    "primary": "bg-primary text-white p-1.5 px-3 disabled:bg-[#D4D4D8] hover:bg-[#3182CE] active:bg-[#2C5282] rounded-lg fit-content",
    /**
     * @type {string}
     * Default Button type definition
    */
    "default": "bg-white text text-black p-1.5 px-3 rounded-lg fit-content",
    /**
     * @type {string}
     * Outline Button type definition
    */
   "outline-primary": "bg-transparent text-primary disabled:text-[#D4D4D8] border-2 border-primary disabled:border-[#D4D4D8] p-1.5 px-3 rounded-lg",
    /**
     * @type {string}
     * Outline Default Button type definition
    */
    "outline-default": "bg-transparent text-black border-2 border-black p-1.5 px-3 rounded-lg",
    /**
     * @type {string}
     * Icon Button type definition
     */
    "icon": "w-24 h-24",
    /**
     * @type {string} 
     * Text Button type primary color theme definition
     */
    "text-primary": "text-md, text-primary",
    /**
     * @type {string}
     * Text Button type default color theme definition
     */
    "text-default": "text-md, text-black",
    /**
     * @type {string}
     * Icon Text Button type definition
     */
    "icon-text": "flex flex-row space-x-2 items-center",
    
}