import {v1Api} from "../Http"
import {HttpMethodEnum} from "edah_utils/dist"

/**
 * 危急值通報
 * @param data data
 * @returns {AxiosPromise}
 */
const cvnotify = (data) => v1Api.request({
    url: '/cvis/notifyrec/cvnotify',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 儲存通報對象資料
 * @param data data
 * @returns {AxiosPromise}
 */
const saveCvisNotifyobject = (data) => v1Api.request({
    url: '/cvis/notifyrec/saveCvisNotifyobject',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 危急值/感染管制通報紀錄查詢
 * @param params params
 * @returns {AxiosPromise}
 */
const queryCvisNotifyrec = (params) => v1Api.request({
    url: '/cvis/notifyrec/queryCvisNotifyrec',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 查詢通報對象資料 危急值/感染管制通報對象清單查詢
 * @param params params
 * @returns {AxiosPromise}
 */
const queryCvisNotifyobject = (params) => v1Api.request({
    url: '/cvis/notifyrec/queryCvisNotifyobject',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 危急值/感染管制通報資料查詢
 * @param params params
 * @returns {AxiosPromise}
 */
const queryCvisNotifyrecById = (params) => v1Api.request({
    url: '/cvis/notifyrec/queryCvisNotifyrecById',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 危急值通報回覆紀錄查詢
 * @param params params
 * @returns {AxiosPromise}
 */
const queryCvisResponse = (params) => v1Api.request({
    url: '/cvis/notifyrec/queryCvisResponse',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 危急值逾時回覆再通知紀錄查詢
 * @param params params
 * @returns {AxiosPromise}
 */
const queryCvisRenotify = (params) => v1Api.request({
    url: '/cvis/notifyrec/queryCvisRenotify',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 刪除危急值通報
 * @param data data
 * @returns {AxiosPromise}
 */
const cancelCvisNotifyrec = (data) => v1Api.request({
    url: '/cvis/notifyrec/CancelCvisNotifyrec',
    method: HttpMethodEnum.PUT,
    data
})

/**
 * 危急值統計筆數查詢
 * @param params params
 * @returns {AxiosPromise}
 */
const countCvisResponseNumber = (params) => v1Api.request({
    url: '/cvis/notifyrec/countCvisResponseNumber',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 危急值回覆待回覆清單查詢
 * @param params params
 * @returns {AxiosPromise}
 */
const queryCvisResponseByStatus = (params) => v1Api.request({
    url: '/cvis/notifyrec/queryCvisResponseByStatus',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 危急值回覆作業
 * @param data data
 * @returns {AxiosPromise}
 */
const saveCvisResponse = (data) => v1Api.request({
    url: '/cvis/notifyrec/saveCvisResponse',
    method: HttpMethodEnum.PUT,
    data
})

/**
 * A級危急值逾時未回覆清單
 * @param params params
 * @returns {AxiosPromise}
 */
const queryCvisClassANoReply = (params) => v1Api.request({
    url: '/cvis/notifyrec/queryCvisClassANoReply',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 新增危急值再通知紀錄
 * @param data data
 * @returns {AxiosPromise}
 */
const saveCvisRenotify = (data) => v1Api.request({
    url: '/cvis/notifyrec/saveCvisRenotify',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 危急值逾時回覆清單查詢
 * @param params params
 * @returns {AxiosPromise}
 */
const queryCvisNotifyrecNoReply = (params) => v1Api.request({
    url: '/cvis/notifyrec/queryCvisNotifyrecNoReply',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 危急值閉環狀態資料查詢
 * @param params params
 * @returns {AxiosPromise}
 */
const queryCvisNotifyrecByStatus = (params) => v1Api.request({
    url: '/cvis/notifyrec/queryCvisNotifyrecByStatus',
    method: HttpMethodEnum.GET,
    params
})

/**
 * A級危急值通報逾時回覆筆數查詢
 * @param params params
 * @returns {AxiosPromise}
 */
const countCvisClassA = (params) => v1Api.request({
    url: '/cvis/notifyrec/countCvisClassA',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 危急值通報單位選單查詢
 * @param params params
 * @return {AxiosPromise}
 */
const queryCvisNotifyUnit = (params) => v1Api.request({
    url: '/cvis/notifyrec/queryCvisNotifyUnit',
    method: HttpMethodEnum.GET,
    params
})

export {
    cvnotify,
    saveCvisNotifyobject,
    queryCvisNotifyrec,
    queryCvisNotifyobject,
    queryCvisNotifyrecById,
    queryCvisResponse,
    queryCvisRenotify,
    cancelCvisNotifyrec,
    countCvisResponseNumber,
    queryCvisResponseByStatus,
    saveCvisResponse,
    queryCvisClassANoReply,
    saveCvisRenotify,
    queryCvisNotifyrecNoReply,
    queryCvisNotifyrecByStatus,
    countCvisClassA,
    queryCvisNotifyUnit
}
