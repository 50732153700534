import {BaseInput} from "../../components/Input/BaseInput"
import {
    ApiErrorStatusEnum,
    arrayIsEmpty,
    CampusEnum,
    fuzzySearchObjects,
    getLocalStorage,
    WDISServiceLogOperationEnum
} from "edah_utils/dist"
import React, {useEffect, useState} from "react"
import {t} from "i18next"
import {wdisQueryServiceMonitorOperlog, WdisServiceMonitorOperLogQueryBaseData} from "../../api/v1/WDIS"
import {Search, DateTimeRangePicker, Select,Button, ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum, CheckboxGroup} from "edah-component/dist"
import dayjs, {Dayjs} from "dayjs"

// 服務LOG查詢頁面
const ServiceLogInquiry = () => {

    // 取得分院清單
    const campusList = Object.values(CampusEnum)

    // 搜尋文字
    const [searchText, setSearchText] = useState('')

    //服務名稱
    const [serviceId, setServiceId] = useState('')

    //負責人代號
    const [ownerUserNo, setOwnerUserNo] = useState('')

    //執行結果
    const [operResult, setOperResult] = useState(WDISServiceLogOperationEnum.Normal)

    // 記錄日期時間範圍，預設為今天的日期時間
    const [logDateTimeRange, setLogDateTimeRange] = useState([dayjs(), null])

    //院區
    const [campus, setCampus] = useState([])

    //基礎資料
    const [baseData, setBaseData] = useState({})

    //資料
    const [data, setData] = useState([])

    /**
     * 服務代號/名稱輸入框變動時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleServiceIdOnChange = (e) => {
        setServiceId(e.target.value);
    }

    /**
     * 選取服務代號/名稱下拉選項時
     * @param option {Object} 選取的選項
     * @return {void}
     */
    const handleServiceIdOptionOnClick = (option) => {
        const value = option?.serviceId ? option.serviceId : '';
        setServiceId(value);
    }

    /**
     * 選取負責人員下拉選項時
     * @param option {Object} 選取的選項
     * @return {void}
     */
    const handleOwnerUserNoOptionOnClick = (option) => {
        const value = option?.ownerUserNo ? option.ownerUserNo : '';
        setOwnerUserNo(value);
    }

    /**
     * 負責人員代號輸入框變動時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleOwnerUserNoOnChange = (e) => {
        setOwnerUserNo(e.target.value);
    }

    /**
     * 處理日期時間範圍變更
     * @param {Array<Dayjs | null>} newValue 選擇的新日期範圍
     */
    const handleDateTimeRangeChange = (newValue) => {
        setLogDateTimeRange(newValue)
    }

    /**
     * 執行結果改變時
     */
    const handleOperResultOnChange = (value) => {
        setOperResult(value)
    }

    /**
     * 院區改變時
     * @param index {number} index
     * @return {void}
     */
    const handleCampusOnChange = (index) => {
        // 取得當前院區
        const newCampus = [...campus]
        // 設定當前院區值
        newCampus[index] = {...newCampus[index], value: !newCampus[index].value}
        // 設定院區
        setCampus(newCampus)
    }

    /**
     * 查詢按鈕點擊時
     * @return {void}
     */
    const handleQueryOnClick = () => getQueryServiceMonitorOperlog()

    /**
     * 搜尋文字改變
     * @param e {event} event
     * @return {void}
     */
    const handleSearchTextOnChange = (e) => setSearchText(e.target.value)

    /**
     * 取得篩選資料
     * @return {Array<Object>}
     */
    const getFilterData = () => fuzzySearchObjects(data, searchText)


    /**
     * 取得基礎資料
     * @return {void}
     */
    const getQueryBaseData = () => {
        WdisServiceMonitorOperLogQueryBaseData({}).then(res => {
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                // 設定基礎資料
                setBaseData(data)
            } else { // 取得失敗

            }
        })
    }

    /**
     * 服務Log查詢
     * @return {void}
     */
    const getQueryServiceMonitorOperlog = () => {
        // 格式化日期範圍的起始和結束時間
        const logStartDatetime = logDateTimeRange[0] ? logDateTimeRange[0].format('YYYY-MM-DD HH:mm:ss') : ''
        const logEndDatetime = logDateTimeRange[1] ? logDateTimeRange[1].format('YYYY-MM-DD HH:mm:ss') : ''
        // 取得分院
        const zone = campus.filter(item => item.value === true).map(item => item.key)
        wdisQueryServiceMonitorOperlog({
            //院區代號
            zone: zone.join(','),
            //服務代號
            serviceId: serviceId,
            //負責人代號
            ownerUserNo: ownerUserNo,
            //操作結果(N正常/E異常/O其它)
            operResult: operResult,
            //服務紀錄時間--起
            logStartDatetime: logStartDatetime,
            //服務紀錄時間--訖
            logEndDatetime: logEndDatetime
        }).then(res => {
            // 取得狀態 / 資料 / 訊息
            const {err, data, msg} = res
            // 取得成功
            if (err === ApiErrorStatusEnum.Success) {
                // 設定資料
                setData(data)
            } else { // 取得失敗
                // 清空資料
                setData([])
            }
        })
    }

    /**
     * 第一次進入頁面時
     * @return {void}
     */
    useEffect(() => {
        // 取得基礎資料
        getQueryBaseData()
        // 建立院區
        const createCampus = campusList.map(item => ({key: item, value: false}))
        // 取得當前角色
        const currentCampus = getLocalStorage('campus')
        const newCampus = createCampus.map(item => item.key === currentCampus ? {...item, value: true} : item)
        // 設定當前角色
        setCampus(newCampus)
    }, [])

    return (
        <div className="w-full p-4 bg-[#FAFAFA]">
            {/*篩選清單*/}
            <div className="filterGroup flex flex-row flex-wrap items-center justify-start">
                <div className="flex flex-row items-center justify-start mb-2 space-x-2">
                    {/* 服務代號/名稱 */}
                    <Search
                        inputLabel={'服務代號/名稱'}
                        onChange={(_e, value) => {
                            handleServiceIdOptionOnClick(value);
                        }}
                        onInputChange={(e) => handleServiceIdOnChange(e)}
                        options={baseData.serviceIdAndNameList || []}
                        getOptionLabel={(option) => `${option?.serviceName}`}
                    />
                    {/* 負責人員 */}
                    <Search
                        inputLabel={'負責人員'}
                        onChange={(_e, value) => {
                            handleOwnerUserNoOptionOnClick(value);
                        }}
                        onInputChange={(e) => handleOwnerUserNoOnChange(e)}
                        disablePortal={false}
                        options={baseData.ownerUserParamSet || []}
                        getOptionLabel={(option) => `${option?.ownerUserNo} ${option?.ownerUserName}`}
                    />
                    {/*服務記錄起迄*/}
                    <DateTimeRangePicker
                        value={logDateTimeRange}
                        onChange={handleDateTimeRangeChange}
                        required
                    />
                    {/*執行結果下拉選單*/}
                    <Select
                        width="Small"
                        data={{
                            label: '執行結果',
                            options: Object.keys(WDISServiceLogOperationEnum)
                                .filter(key => isNaN(Number(key)))
                                .map((key) => ({
                                    label:
                                        WDISServiceLogOperationEnum[key] === "N" ? "正常" :
                                            WDISServiceLogOperationEnum[key] === "E" ? "異常" :
                                                WDISServiceLogOperationEnum[key] === "O" ? "其他" : "",
                                    value: WDISServiceLogOperationEnum[key]
                                }))
                        }}
                        value={operResult}
                        onChange={handleOperResultOnChange}
                        notched={true}/>
                    {/* 院區多選 */}
                    {
                        !arrayIsEmpty(campus) && (
                            <CheckboxGroup
                                labelText="院區"
                                showLabel
                                required
                                row={true}
                                checkboxes={campus.map((item, index) => ({
                                    label: t(`campus.shortName.${item.key}`),
                                    checked: item.value,
                                    onChange: () => handleCampusOnChange(index),
                                }))}
                            />
                        )
                    }
                    {/* 查詢按鈕 */}
                    <Button
                        color={ButtonColorEnum.Primary}
                        text={t('general.query')}
                        variant={ButtonVariantEnum.Contained}
                        size={ButtonSizeEnum.Medium}
                        onClick={handleQueryOnClick}
                        sx={{ height: '2.5rem'}}
                    />
                </div>
            </div>
            {/*服務表格清單*/}
            <div className="maintainContainer flex flex-row items-start justify-start">
                <div className="p-4 bg-white w-full border-[1px] border-[#D4D4D8] rounded-[12px] mr-4">
                    {/*進階搜尋*/}
                    <div className="searchGroup flex flex-row items-center justify-between mb-4">
                        <BaseInput className={`w-[320px] h-10 pl-8 border-[2px] border-[#D4D4D8] rounded-[6px]`}
                                   type="text" placeholder={t('general.advancedSearch')} inputMode={'search'}
                                   value={searchText} onChange={handleSearchTextOnChange}/>
                    </div>
                    <ul className="tableList SLTableList">
                        <li className="title">
                            <div>服務代號</div>
                            <div>服務名稱</div>
                            <div>負責人</div>
                            <div>紀錄時間</div>
                            <div>結束時間</div>
                            <div>狀態</div>
                            <div>通知</div>
                        </li>
                        {
                            !arrayIsEmpty(data) && getFilterData().map((item, index) =>
                                <li key={index}>
                                    {/*服務代號*/}
                                    <div>{item.serviceId}</div>
                                    {/*服務名稱*/}
                                    <div>{item.serviceName}</div>
                                    {/*負責人*/}
                                    <div>{`${item.ownerUserNo} ${item.ownerUserName}`}</div>
                                    {/*紀錄時間*/}
                                    <div>{item.logDatetime}</div>
                                    {/*結束時間*/}
                                    <div>{item.serviceExecEndDatetime}</div>
                                    {/*狀態*/}
                                    <div>{item.operResult}</div>
                                    {/*通知*/}
                                    <div>{item.notifyMessage}</div>
                                </li>
                            )
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}
export default ServiceLogInquiry
