import React from "react";

/**
 *
 * @param {String} id id
 * @param {String} color 文字顏色
 * @param {String} text 按鈕文字
 * @param {Function} onClick 按鈕點擊事件
 * @param {Boolean} selected 是否被選擇
 * @returns
 */
const ToggleButton = ({
                          id,
                          color = "#000000",
                          text,
                          onClick,
                          selected
                      }) => {
    /**
     * 按鈕點擊事件
     */
    const handleOnClick = () => {
        onClick(id)
    }

    return (
        <button
            className={`flex items-center space-x-2 ${selected ? "bg-[#6196BE] text-white" : "bg-transparent text-primary"} hover:border-[#3182CE] border-2 border-primary p-1.5 px-3 rounded-lg`}
            onClick={handleOnClick}
        >
            <div
                className="h-[18px] w-[18px]"
                style={{backgroundColor: color}}
            />
            <div>{text}</div>
        </button>
    )
}

export default ToggleButton;
