import React, {useEffect, useState} from 'react'
import CustomTable from '../../components/ReferralSystem/CustomTable'
import {Pagination} from '../../components/Pagination/Pagination'
import {REFERRAL_LIST_MODAL} from '../../constants/referralSystem/maintainAreaHeader'
import {ApiErrorStatusEnum, arrayIsEmpty,} from 'edah_utils/dist'
import {queryEtranToOpd} from '../../api/v1/Rmis'
import useToast from '../../hooks/useToast'
import {
    AlertTypeEnum,
    Field,
    Dialog,
    DialogVariant,
} from "edah-component/dist"

/**
 * 電子轉診清單
 * @param {object} info {object} 病人資料   
 * @param {function} onClose {function} 關閉視窗 callback
 * @param {function} onConfirm {function} 確定 callback
 * @return {JSX.Element}
 */
const ReferralListModalContent = ({info, onClose, onConfirm}) => {
    // 表格相關選項
    const [dataList, setDataList] = useState([])
    // pagination
    const [filter, setFilter] = useState({
        totalPageSize: 1,
        totalItemSize: 0,
        pageNum: 1,
        pageSize: 10,
    })

    // 顯示Toast
    const showToast = useToast()

    /**
     * 確定
     * @return {void}
     */
    const handleConfirm = () => {
        const etranNo = dataList.find((item) => item.isSelected)?.etranNo ?? ''
        onConfirm(etranNo)
    }

    /**
     * 選擇單筆資料
     * @param isChecked {boolean} 是否勾選
     * @param selectedItem {object} 勾選的資料
     * @param index {number} 勾選項目的index
     * @return {void}
     */
    const toggleItem = (isChecked, selectedItem, index) => {
        // 修改勾選狀態
        const modifiedList = dataList.map((item) => {
            return {
                ...item,
                isSelected:
                    selectedItem.originIndex === item.originIndex
                        ? isChecked
                        : false,
            }
        })
        setDataList(modifiedList)
    }

    /**
     * 點選 pagination 頁碼
     * @param page {string} 目前頁碼
     */
    const handlePageOnChange = (page) => {
        const currentPage = Number(page)
        getReferralList(currentPage, filter.pageSize)
    }

    /**
     * 變更每頁筆數
     * @param event {object}
     * @return {void}
     */
    const handlePageSizeChange = (event) => {
        const pageSize = Number(event.target.value)
        getReferralList(1, pageSize)
    }

    /**
     * 點選上一頁
     * @return {void}
     */
    const handlePrevPageOnClick = () => {
        const prevPage = filter.pageNum - 1
        const page = prevPage < 1 ? filter.totalPageSize : prevPage
        getReferralList(page, filter.pageSize)
    }

    /**
     * 點選下一頁
     * @return {void}
     */
    const handleNextPageOnClick = () => {
        const nextPage = filter.pageNum + 1
        const firstPage = 1
        const page = nextPage > filter.totalPageSize ? firstPage : nextPage
        getReferralList(page, filter.pageSize)
    }

    /**
     * 取得電子轉診清單
     * @param {number} pageNum
     * @param {number} pageSize
     */
    const getReferralList = (pageNum, pageSize) => {
        queryEtranToOpd({
            encounterDate: info.encounterDate,
            patientId: info.patientId,
            pageNum,
            pageSize,
        }).then((res) => {
            // 成功
            if (res.err === ApiErrorStatusEnum.Success) {
                // 轉診清單預設為未勾選
                const list = arrayIsEmpty(res.data)
                    ? []
                    : res.data.map((item, index) => {
                        return {
                            ...item,
                            isSelected: false,
                            originIndex: index,
                        }
                    })
                // 當清單為空時，顯示查無資料
                if (list.length === 0) {
                    showToast({
                        message: '查無資料',
                        type: AlertTypeEnum.Warning,
                    })
                }
                // 設定轉診清單
                setDataList(list)
                // 設定分頁
                setFilter({
                    ...filter,
                    pageNum,
                    pageSize,
                    totalItemSize: res.totalItemSize,
                    totalPageSize: res.totalPageSize,
                })
            } else {
                // 失敗
                showToast({
                    message: res.msg,
                    type: AlertTypeEnum.Error,
                })
            }
        })
    }

    /**
     * 初始化取得轉診清單
     * @return {void}
     */
    useEffect(() => {
        getReferralList(1, filter.pageSize)
    }, [])

    return (
        <Dialog
            open={true}
            variant={DialogVariant.CONFIRM}
            paperStyleProps={{width: '80%'}}
            title={'電子轉診清單'}
            onClose={onClose}
            onConfirm={handleConfirm}
            muiDialogContentProps={{dividers: true}}
            content={
                <>
                    <div className="flex justify-between p-2 items-center mb-3">
                        <div className="flex items-center">
                            <Field label="病歷號" labelWidth={75}>
                                {info.patientId}
                            </Field>
                            <Field label="姓名" labelWidth={75}>
                                {info.patientName}
                            </Field>
                        </div>
                    </div>
                    {/* 表格 */}
                    <CustomTable
                        isSelectable={true}
                        isSingleSelect={true}
                        fields={REFERRAL_LIST_MODAL}
                        dataList={dataList}
                        toggleItem={toggleItem}
                    />
                    {/* 分頁 */}
                    <div className="flex justify-end">
                        <Pagination
                            pageSize={filter.pageSize}
                            totalSize={filter.totalItemSize}
                            currentPage={filter.pageNum}
                            totalPageSize={filter.totalPageSize}
                            onPageOnChange={handlePageOnChange}
                            onPageSizeChange={handlePageSizeChange}
                            onPrevPageOnClick={handlePrevPageOnClick}
                            onNextPageOnClick={handleNextPageOnClick}
                        />
                    </div>
                    {/* 說明 */}
                    <div className="px-2 pt-4 pb-4">
                        <div className="mb-2">
                            ※若病人為電子轉診病人，請勾選後帶回。
                        </div>
                        <div className="mb-2">※打勾處反灰，代表此單已被使用。</div>
                        <div className="mb-2">
                            ※取消打勾後，按確定，即可取消使用此單表。
                        </div>
                    </div>
                </>
            }
        />
    )
}

/**
 * 電子轉診清單
 * @param {object} onClose {function} 關閉視窗 callback
 * @param {object} info {object} 病人資料
 * @param {function} onConfirm {function} 確定 callback
 * @return {JSX.Element}
 */
const ReferralListModal = ({onClose, info, onConfirm}) => {
    return (
        <ReferralListModalContent
            info={info}
            onClose={onClose}
            onConfirm={onConfirm}
        />
    )
}

export default ReferralListModal
