// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `header {
  height: 56px;
}

.header-title {
  font-size: 20px;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/header.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAEA;EACE,eAAA;AACF","sourcesContent":["header {\n  height: 56px;\n}\n\n.header-title {\n  font-size: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
