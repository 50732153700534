import React, {useState} from 'react'
import CustomTable from './CustomTable'
import {
    ADMISSION_FIELDS,
    ADMISSION_RESERVATION_FIELDS,
    OPD_FIELDS,
    REPLY_LETTER_FIELDS,
    TRAN_OUT_FIELDS,
} from '../../constants/referralSystem/tranInCase'
import {t} from 'i18next'
import {queryTransTool} from '../../api/v1/Rmis'
import {ApiErrorStatusEnum, time2String, validDateFormat,} from 'edah_utils/dist'
import {DATETIME_FORMAT} from '../../constants/common'
import useToast from '../../hooks/useToast'
import {
    AlertTypeEnum,
    TextField,
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum
} from "edah-component/dist"
import {DateRangePicker, SizeEnum} from "edah-component/dist/index";
import dayjs from "dayjs";

/**
 * 查詢工具視窗
 * @return {JSX.Element}
 */
const QueryToolsModal = () => {
    // 查詢工具視窗輸入框
    const [searchToolParams, setSearchToolParams] = useState({
        // 病歷號/身分證號
        search: '',
        // 就醫開始日期
        encounterStartDate: '',
        // 就醫結束日期
        encounterEndDate: '',
        // 就醫號
        encounterId: '',
    })
    // 查詢工具視窗輸入框狀態
    const [inputState, setInputState] = useState({
        encounterStartDate: true,
        encounterEndDate: true,
    })

    // 查詢工具視窗查詢table
    const [queryList, setQueryList] = useState({
        // 預約住院查詢table
        reserveInpList: [],
        // 住院主檔查詢table
        inpList: [],
        // 門診看診主檔查詢table
        opdList: [],
        // 轉診單查詢table
        tranOutList: [],
        // 回覆單查詢table
        tranInList: [],
    })
    // 顯示訊息
    const showToast = useToast()

    /**
     * 更新查詢工具視窗輸入框
     * @param e {Event} 事件
     * @param field {string} 欄位
     * @return {void}
     */
    const updateParams = (e, field) => {
        const value = e.target.value
        setSearchToolParams((prev) => ({...prev, [field]: value}))
    }

    /**
     * 就醫日期範圍變動事件
     * @param newDates {Array<Dayjs | null>} 日期範圍 [開始日期, 結束日期]
     * @return {void}
     */
    const handleDateRangeChange = (newDates) => {
        const [newStartDate, newEndDate] = newDates;
        setSearchToolParams({
            ...searchToolParams,
            encounterStartDate: newStartDate ? newStartDate.format('YYYY-MM-DD') : '',
            encounterEndDate: newEndDate ? newEndDate.format('YYYY-MM-DD') : '',
        })
    }

    /**
     * 查詢按鈕事件
     * @return {void}
     */
    const handleQueryOnClick = () => {
        // 檢核就醫開始日期
        const encounterStartDateIsValid = validDateFormat(
            searchToolParams.encounterStartDate
        )
        // 檢核就醫結束日期
        const encounterEndDateIsValid = validDateFormat(
            searchToolParams.encounterEndDate
        )
        console.log('encounterStartDateIsValid', encounterStartDateIsValid)
        console.log('encounterEndDateIsValid', encounterEndDateIsValid)

        // 更新檢核日期狀態
        setInputState({
            encounterStartDate: encounterStartDateIsValid,
            encounterEndDate: encounterEndDateIsValid,
        })
        // 檢核日期是否為空
        if (!encounterEndDateIsValid || !encounterEndDateIsValid) {
            return
        }
        
        // 查詢轉診工具
        queryTransTool({
            ...searchToolParams,
            encounterStartDate: time2String(
                searchToolParams.encounterStartDate,
                DATETIME_FORMAT
            ),
            encounterEndDate: time2String(
                searchToolParams.encounterEndDate,
                DATETIME_FORMAT
            ),
        }).then((res) => {
            // 成功
            if (res.err === ApiErrorStatusEnum.Success) {
                setQueryList({
                    inpList: res.data?.inpList || [],
                    opdList: res.data?.opdList || [],
                    reserveInpList: res.data?.reserveInpList || [],
                    tranInList: res.data?.tranInList || [],
                    tranOutList: res.data?.tranOutList || [],
                })
            } else {
                // 失敗
                showToast({
                    message: '預約住院查無資料',
                    type: AlertTypeEnum.Error,
                })
            }
        })
    }

    return (
        <>
            <div className="flex">
                <div className="date flex items-center justify-start mb-4 space-x-2">
                    {/* 就醫日期起訖 */}
                    <DateRangePicker
                        required
                        localeText={{
                            start: '就醫開始日期',
                            end: '就醫結束日期',
                        }}
                        value={[
                            searchToolParams.encounterStartDate ? dayjs(searchToolParams.encounterStartDate) : null,
                            searchToolParams.encounterEndDate ? dayjs(searchToolParams.encounterEndDate) : null,
                        ]}
                        onChange={handleDateRangeChange}
                        error={!inputState.encounterStartDate || !inputState.encounterEndDate}
                    />
                    {/* 病歷號/身分證號 */}
                    <TextField
                        label={`${t('general.patientNo')}/${t('general.idNumber')}`}
                        value={searchToolParams.search}
                        onChange={(e) => updateParams(e, 'search')}
                    />
                    {/* 就醫號 */}
                    <TextField
                        label="就醫號"
                        value={searchToolParams.encounterId}
                        onChange={(e) => updateParams(e, 'encounterId')}
                    />
                    {/* 查詢按鈕 */}
                    <Button
                        text={t('general.query')}
                        color={ButtonColorEnum.Primary}
                        size={ButtonSizeEnum.Medium}
                        variant={ButtonVariantEnum.Contained}
                        onClick={handleQueryOnClick}
                        sx={{fontWeight: 'bold', height: '2.5rem'}}
                    />
                </div>
            </div>
            <div className="flex flex-col space-y-2">
                {/* 預約住院查詢 */}
                <CustomTable
                    title="預約住院查詢"
                    fields={ADMISSION_RESERVATION_FIELDS}
                    dataList={queryList.reserveInpList}
                />
                {/* 住院主檔查詢 */}
                <CustomTable
                    title="住院主檔查詢"
                    fields={ADMISSION_FIELDS}
                    dataList={queryList.inpList}
                />
                {/* 門診看診主檔查詢 */}
                <CustomTable
                    title="門診看診主檔查詢"
                    fields={OPD_FIELDS}
                    dataList={queryList.opdList}
                />
                {/* 轉診單查詢 */}
                <CustomTable
                    title="轉診單查詢"
                    fields={TRAN_OUT_FIELDS}
                    dataList={queryList.tranOutList}
                />
                {/* 回覆單查詢 */}
                <CustomTable
                    title="回覆單查詢"
                    fields={REPLY_LETTER_FIELDS}
                    dataList={queryList.tranInList}
                />
            </div>
        </>
    )
}

export default QueryToolsModal
