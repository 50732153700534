// Icon
import { ReactComponent as RefreshIcon } from '../../../../assets/images/icons/refresh.svg'

// Import Function
import {
    icHisReadPrescriptAllergic,
    icHisWriteAllergicMedicines
} from '../../../../api/v1/LocalService'
import { vhcHisReadPrescriptAllergic } from '../../../../api/v1/ICCM'
import { useState } from 'react'
import {
    ApiErrorStatusEnum,
    getStringByteLength,
    ToastTypeEnum,
    validNumber
} from 'edah_utils/dist'
import { showToastMessage } from '../../../../redux/Slice/ToastMessageSlice'
import { Field, SizeEnum, TextField, Button, ButtonSizeEnum, ButtonVariantEnum, ButtonColorEnum, IconEnum } from "edah-component/dist"

/**
 * 過敏藥物
 * @param info {object} info
 * @return {JSX.Element}
 */
export const AllergyMedicineSection = ({ info }) => {
    // 字串最大Byte長度
    const strMaxByteSize = 40

    // 預設API Response
    const defaultResponse = {
        msg: '',
        err: null,
        statusCode: 0,
        data: null,
        runTime: 0
    }

    // API Response
    const [response, setResponse] = useState(defaultResponse)

    /**
     * 讀取就醫資料-過敏藥物
     * @return {void}
     */
    const getIcHisReadPrescriptAllergic = () => {
        if (info.isPhysical) {
            icHisReadPrescriptAllergic({
                //欲讀取的起始資料
                iStartPos: 1,
                //欲讀取的起始資料(該欄位不可超過3)
                iEndPos: 3
            }).then((res) => {
                // 設定API回應
                setResponse(res)
            })
        } else {
            vhcHisReadPrescriptAllergic({
                //欲讀取的起始資料
                iStartPos: 1,
                //欲讀取的起始資料(該欄位不可超過3)
                iEndPos: 3
            }).then((res) => {
                // 設定API回應
                setResponse(res)
            })
        }
    }

    /**
     * 過敏藥物寫入作業
     * @return {void}
     */
    const saveIcHisWriteAllergicMedicines = () => {
        // 每筆長度只能為40byte
        if (getStringByteLength(defaultResponse.data) > strMaxByteSize) {
            // 顯示Toast訊息
            showToastMessage(
                '過敏藥物每筆長度只能為40byte',
                ToastTypeEnum.Error
            )
            return
        }

        icHisWriteAllergicMedicines({
            //身分證
            pPatientID: info.pPatientID,
            //出生日
            pPatientBirthDate: info.pPatientBirthDate,
            //過敏資料 1筆40 byte 最多3筆
            pDataWrite: 'Allergic00'
        }).then((res) => {
            // 寫入成功
            if (res.err === ApiErrorStatusEnum.Success) {
                // 設定API回應
                setResponse(defaultResponse)
            }
        })
    }

    /**
     * 取得寫入卡片區塊
     * @return {JSX.Element}
     */
    const getWriteCardSection = () =>
        // 錯誤狀態碼為空
        info.isPhysical === true &&
        !validNumber(response.err) &&
        // 且API回應為成功時，顯示寫入卡片按鈕
        response.err === ApiErrorStatusEnum.Success ? (
            <Button color='primary' variant='contained' size='medium' sx={{float:'right'}} onClick={saveIcHisWriteAllergicMedicines}>{'寫入卡片'}</Button>
        ) : null

    return (
        <div className="allergyMedicine">
            {/*過敏藥物讀取*/}
            <div className="allergyLoading flex flex-row items-center mb-8">
                <p className="text-[18px] text-[#3F3F46]">讀取結果：</p>
                <span className="text-[18px] text-[#E53E3E] mr-4">
                    {response.msg}
                </span>
                <Button 
                    color={ButtonColorEnum.Secondary}
                    variant={ButtonVariantEnum.Outlined}
                    size={ButtonSizeEnum.Medium}
                    icon={IconEnum.Refresh}
                    sx={{marginRight:'1.5rem'}} 
                    onClick={getIcHisReadPrescriptAllergic}
                >
                    {'讀取過敏藥物'}
                </Button>
            </div>
            {/*過敏藥物*/}
            <div className="allergyContent w-[732px] h-[360px] p-16 bg-[#F4F4F5] rounded-[12px]">
                <ul className="mb-4">
                    <li className="flex flex-row items-center justify-start h-[56px] mb-2">
                        <Field label="過敏藥物 1">
                            <TextField inputWidth={SizeEnum.Fill} sx={{backgroundColor: "#FFFFFF"}}/>
                        </Field>
                    </li>
                    <li className="flex flex-row items-center justify-start h-[56px] mb-2">
                        <Field label="過敏藥物 2">
                            <TextField inputWidth={SizeEnum.Fill} sx={{backgroundColor: "#FFFFFF"}}/>
                        </Field>
                    </li>
                    <li className="flex flex-row items-center justify-start h-[56px]">
                        <Field label="過敏藥物 3">
                            <TextField inputWidth={SizeEnum.Fill} sx={{backgroundColor: "#FFFFFF"}}/>
                        </Field>
                    </li>
                </ul>
                {/*顯示寫入卡片按鈕*/}
                {getWriteCardSection()}
            </div>
        </div>
    )
}
