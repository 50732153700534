import React from 'react'
import MOHWgovFeedback from '../../components/RSIS/MOHWgovFeedback/MOHWgovFeedback'
import {PopupProvider} from '../../components/RSIS/PopupProvider'

/**
 * 衛福部案號回饋
 * @return {JSX.Element}
 */
const MOHWgovFeedbackLayout = () =>
    <PopupProvider>
        <MOHWgovFeedback/>
    </PopupProvider>

export default MOHWgovFeedbackLayout
