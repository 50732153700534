import React, {useEffect, useState} from "react";

/**
 * 雙環圖表
 * @param percentage {Number} 百分比
 * @param status {Boolean} 控制雙環狀態定應變色
 * @param children 自定義內容
 */
const DoubleCircleChart = ({ percentage, children, status }) => {
    // 圓形圖表半徑
    const radius = 100;
    // 雙環寬度
    const strokeWidth = 15;
    // 依照雙環寬度去計算所繪出圓形半徑
    const normalizedRadius = radius - (strokeWidth / 2);
    // 圓的周長
    const circumference = normalizedRadius * 2 * Math.PI;
    // 計算百分比圓的偏移量
    const strokeDashoffset = circumference - (percentage / 100) * circumference;
    // 控制雙環圖狀態
    const [color, setColor] = useState('#757575');

    useEffect(() => {
        setColor(status ? '#FF8F6B' : '#757575');
    }, [status]);

    return (
        <div className="w-[200px] h-[200px] relative">
            <svg height={radius * 2} width={radius * 2}>
                {/*背景圓圈*/}
                <circle
                    stroke="#5B93FF"
                    fill="transparent"
                    strokeWidth={strokeWidth}
                    r={normalizedRadius}
                    cx={radius}
                    cy={radius}
                    style={{opacity: '5%'}}
                />
                {/*百分比圓圈*/}
                <circle
                    stroke={color}
                    fill="transparent"
                    strokeWidth={strokeWidth}
                    strokeDasharray={circumference + ' ' + circumference}
                    // rotate可定義數值起始點
                    style={{ strokeDashoffset, transform: 'rotate(90deg)', transformOrigin: '50% 50%', transitionDuration: '300ms' }}
                    strokeLinecap="round"
                    r={normalizedRadius}
                    cx={radius}
                    cy={radius}
                />
            </svg>
            {/*圓環中間自定義內容*/}
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center">
                {children}
            </div>
        </div>
    );
};
export default DoubleCircleChart