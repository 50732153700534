import React, {useEffect, useState} from "react"
import {ApiErrorStatusEnum, time2String} from "edah_utils/dist"
import {queryRegsClinicStatus} from "../../../api/v1/RegsMonth"
import {Checkbox, DialogSizeEnums, Dialog, DialogVariant} from "edah-component/dist"

/**
 * 診號狀況彈窗
 * @param closePopupButtonOnClick {Function} 關閉按鈕點擊事件
 * @param show {Boolean} 是否顯示
 * @param clinicId {String} 診室id
 * @returns
 */
const ClinicVisitsNumberStatePopup = ({
                                          show,
                                          closePopupButtonOnClick,
                                          clinicId
                                      }) => {
    /**
     * 是否顯示彈窗
     */
    const [showPopup, setShowPopup] = useState(show)
    //診號狀況資料
    const [clinicStatusData, setClinicStatusData] = useState(null)
    //已給號勾選狀態
    const [checkedGiven, setCheckedGiven] = useState(true)
    //未給號勾選狀態
    const [checkedNotYetGiven, setCheckedNotYetGiven] = useState(true)
    //預約號勾選狀態
    const [checkdAppointment, setCheckdAppointment] = useState(true)
    //科初診勾選狀態
    const [checkdFirstView, setCheckdFirstView] = useState(true)
    //不給號勾選狀態
    const [checkdNoGiven, setCheckdNoGiven] = useState(true)

    /**
     * 已給號勾選框變更狀態事件
     * @returns
     */
    const handleGivenOnChange = () => setCheckedGiven(!checkedGiven)

    /**
     * 未給號勾選框變更狀態事件
     * @returns
     */
    const handleNotYetGivenOnChange = () => setCheckedNotYetGiven(!checkedNotYetGiven)

    /**
     * 預約號勾選框變更狀態事件
     * @returns
     */
    const handleAppointmentOnChange = () => setCheckdAppointment(!checkdAppointment)

    /**
     * 科初診勾選框變更狀態事件
     * @returns
     */
    const handleFirstViewOnChange = () => setCheckdFirstView(!checkdFirstView)

    /**
     * 不給號勾選框變更狀態事件
     * @returns
     */
    const handleNoGivenOnChange = () => setCheckdNoGiven(!checkdNoGiven)

    /**
     * 診號種類背景顏色
     * @param type {String} 種類
     * @returns
     */
    const givenNoTypeBkColor = (type) => {
        if (type === '*')    //已給號:*
        {
            return 'bg-[#FFFFFF]'
        }

        if (type === 'A') //未給號
        {
            return 'bg-[#FFFFFF]'
        }

        if (type === 'R') //預約號
        {
            return 'bg-[#FFFFFF]'
        }

        if (type === 'F') //科初診
        {
            return 'bg-[#FFFFFF]'
        }

        if (type === 'X') //不給號
        {
            return 'bg-[#FFFFFF]'
        }

        return 'bg-[#fafafa]'
    }

    /**
     * 診號背景顏色
     * @param viewType {String}
     * @returns
     */
    const numberBkColor = (viewType) => {
        if (viewType === '*' && checkedGiven)    //已給號:*
        {
            return 'bg-[#B1B1B1]'
        }

        if (viewType === 'A' && checkedNotYetGiven) //未給號
        {
            return givenNoTypeBkColor(viewType)
        }

        if (viewType === 'R' && checkdAppointment) //預約號
        {
            return givenNoTypeBkColor(viewType)
        }

        if (viewType === 'F' && checkdFirstView) //科初診
        {
            return givenNoTypeBkColor(viewType)
        }

        if (viewType === 'X' && checkdNoGiven) //不給號
        {
            return givenNoTypeBkColor(viewType)
        }

        return 'bg-[#fafafa]'
    }

    /**
     * 診號外框顏色
     * @param viewType {String}
     * @returns
     */
    const numberBorderColor = (viewType) => {
        if (viewType === '*' && checkedGiven)    //已給號:*
        {
            return 'border-[#D4D4D8]'
        }

        if (viewType === 'A' && checkedNotYetGiven) //未給號
        {
            return 'border-[#D4D4D8]'
        }

        if (viewType === 'R' && checkdAppointment) //預約號
        {
            return 'border-[#D4D4D8]'
        }

        if (viewType === 'F' && checkdFirstView) //科初診
        {
            return 'border-[#D4D4D8]'
        }

        if (viewType === 'X' && checkdNoGiven) //不給號
        {
            return 'border-[#D4D4D8]'
        }

        return 'border-[#D4D4D8]'
    }

    /**
     * 取得內容
     * @return {JSX.Element}
     */
    const getContent = () => (
        <>
            <div className="flex flex-col px-6 pb-4">
                <div className="flex flex-row space-x-4">
                    <div>
                        <label>已掛：</label>
                        <label>
                            {clinicStatusData && clinicStatusData.regCount != null ? clinicStatusData.regCount : ""}人
                        </label>
                    </div>
                    <div>
                        <label>完診：</label>
                        <label>
                            {clinicStatusData && clinicStatusData.finishCount != null ? clinicStatusData.finishCount : ""}人
                        </label>
                    </div>
                    <div>
                        <label>待看：</label>
                        <label>
                            {clinicStatusData && clinicStatusData.unfinishCount != null ? clinicStatusData.unfinishCount : ""}人
                        </label>
                    </div>
                </div>

                <div className="flex flex-row space-x-2 mt-3.5">
                    <div className={`flex flex-row space-x-2 py-1.5 pl-1.5 ${givenNoTypeBkColor('*')}`}>
                        <Checkbox
                            label="已給號"
                            checked={checkedGiven}
                            onChange={handleGivenOnChange}
                        />
                    </div>

                    <div className={`flex flex-row space-x-2 py-1.5 pl-1.5 ${givenNoTypeBkColor('A')}`}>
                        <Checkbox
                            label="未給號"
                            checked={checkedNotYetGiven}
                            onChange={handleNotYetGivenOnChange}
                        />
                    </div>

                    <div className={`flex flex-row space-x-2 py-1.5 pl-1.5 ${givenNoTypeBkColor('R')}`}>
                        <Checkbox
                            label="預約號"
                            checked={checkdAppointment}
                            onChange={handleAppointmentOnChange}
                        />
                    </div>

                    <div className={`flex flex-row space-x-2 py-1.5 pl-1.5 ${givenNoTypeBkColor('F')}`}>
                        <Checkbox
                            label="科初診"
                            checked={checkdFirstView}
                            onChange={handleFirstViewOnChange}
                        />
                    </div>

                    <div className={`flex flex-row space-x-2 py-1.5 pl-1.5 ${givenNoTypeBkColor('X')}`}>
                        <Checkbox
                            label="不給號"
                            checked={checkdNoGiven}
                            onChange={handleNoGivenOnChange}
                        />
                    </div>
                </div>

                <div className="flex flex-wrap gap-2 mt-4 max-h-[240px] overflow-y-auto">
                    {
                        clinicStatusData && clinicStatusData.clinicStatusList &&
                        clinicStatusData.clinicStatusList.map((item, index) => (
                            <div
                                key={index}
                                className={`flex w-[100px] h-[40px] border-2 rounded-[4px] justify-center items-center
                                        ${numberBkColor(item.viewTypeEnumCode)} ${numberBorderColor(item.viewTypeEnumCode)}`}>
                                <label className="text-[#4299E1]">{item.viewNo}：</label>
                                <label>{time2String(item.startDateTime, "HH:mm")}</label>

                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    )

    /**
     * 監聽是否顯示彈窗Pros變化
     */
    useEffect(() => {
        if (show) {
            //取得診間狀況
            queryRegsClinicStatus({
                clinicId: clinicId,
            }).then(res => {
                if (res.err === ApiErrorStatusEnum.Success) {
                    setClinicStatusData(res.data)
                } else {
                    console.log(res.msg)
                }
            })

            //預設都勾選
            setCheckedGiven(true)
            setCheckedNotYetGiven(true)
            setCheckdAppointment(true)
            setCheckdFirstView(true)
            setCheckdNoGiven(true)
        }

        setShowPopup(show)
    }, [show])

    return (
        <Dialog
            open={show}
            variant={DialogVariant.EDIT}
            paperStyleProps={{width: DialogSizeEnums.MD}}
            content={getContent()}
            title={"診號狀況"}
            onClose={closePopupButtonOnClick}
            muiDialogActionsProps={{sx: {display: 'none'}}}
            muiDialogContentProps={{dividers: true}}
        />
    )
}

export default ClinicVisitsNumberStatePopup;
