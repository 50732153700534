// Icon
import AddIcon from "../../assets/images/icons/add_circle.svg"
import ExportIcon from "../../assets/images/SystemMonitoring/export.svg"
import {ReactComponent as EditBtnIcon} from "../../assets/images/ICCard/edit_square.svg"
import {ReactComponent as DeleteBtnIcon} from "../../assets/images/ICCard/delete.svg"
import {ReactComponent as CopyBtnIcon} from "../../assets/images/SystemMonitoring/copy.svg"

// Import Function
import React, {useEffect, useRef, useState} from "react"
import {
    ApiErrorStatusEnum,
    arrayIsEmpty,
    CampusEnum,
    fuzzySearchObjects,
    getLocalStorage,
    objectIsEmpty,
    PopupModeEnum,
    ToastTypeEnum,
    WDISAlarmTimeEnum,
    WDISCheckTypeEnum,
    WDISEffFlagEnum,
    WDISServiceEnableEnum
} from "edah_utils/dist"
import {BaseInput} from "../../components/Input/BaseInput"
import {wdisDelServiceMonitor, WdisQueryBaseData, wdisQueryServiceMonitor} from "../../api/v1/WDIS"
import {t} from "i18next"
import {DeletePopup} from "../../components/Popup/DeletePopup"
import {PersonInChargePopup} from "../../components/WDIS/BasicDataMaintenance/PersonInChargePopup"
import {AddEditRegisterServicePopup} from "../../components/WDIS/BasicDataMaintenance/AddEditRegisterServicePopup"
import useToast from "../../hooks/useToast"

/**
 * 服務資料維護頁面
 * @return {JSX.Element}
 */
const BasicDataMaintenance = () => {
    /**
     * Show Toast
     */
    const showToast = useToast()

    // 取得分院清單
    const campusList = Object.values(CampusEnum)

    // 搜尋文字
    const [searchText, setSearchText] = useState('')

    //服務名稱
    const [serviceName, setServiceName] = useState('')

    //檢核類別
    const [checkType, setCheckType] = useState(WDISCheckTypeEnum.All)

    //勿打擾
    const [alarmTime, setAlarmTime] = useState(WDISAlarmTimeEnum.All)

    //服務啓用否(0停止/1啟用)
    const [serviceEnable, setServiceEnable] = useState(WDISServiceEnableEnum.All)

    //院區
    const [campus, setCampus] = useState([])

    //是否服務正執行活動中
    const [action, setAction] = useState(false)

    //服務有效否(0無效/1有效)
    const [effFlag, setEffFlag] = useState(WDISEffFlagEnum.Enable)

    // 是否顯示新增服務註冊popup
    const [showAddEditRegisterServicePopup, setShowAddEditRegisterServicePopup] = useState(false)

    // 新增服務註冊popup模式
    const [addEditRegisterServicePopupMode, setAddEditRegisterServicePopupMode] = useState(PopupModeEnum.Add)

    // 是否顯示模組選單
    const [showModuleMenuPopup, setShowModuleMenuPopup] = useState(false)

    // 是否顯示負責人員選單
    const [showPersonInChargePopup, setShowPersonInChargePopup] = useState(false)

    // 是否顯示刪除視窗
    const [showDeletePopup, setShowDeletePopup] = useState(false)

    //資料
    const [data, setData] = useState([])

    //是否已經取得資料
    const hasFirstFetchedData = useRef(false)

    //基礎資料
    const [baseData, setBaseData] = useState([])

    //是否顯示服務名稱下拉選單
    const [showServiceNameDropdown, setShowServiceNameDropdown] = useState(false)

    //刪除項目
    const [delItem, setDelItem] = useState(null)

    /**
     * 當搜尋文字改變時，更新 searchText
     * @param e {event} event
     * @return {void}
     */
    const handleSearchTextOnChange = (e) => setSearchText(e.target.value)

    /**
     * 點擊新增服務註冊按鈕時
     * @return {void}
     */
    const handleShowAddPopupOnClick = () => {
        // 設定新增服務註冊popup模式
        setAddEditRegisterServicePopupMode(PopupModeEnum.Add)
        // 設定新增服務註冊popup模式
        setShowAddEditRegisterServicePopup(true)
    }

    /**
     * 服務名稱改變時
     * @param e {event} event
     * @return {void}
     */
    const handleServiceNameOnChange = (e) => setServiceName(e.target.value)

    /**
     * 服務名稱輸入框點擊時
     * @return {void}
     */
    const handleServiceNameOnFocus = () => setShowServiceNameDropdown(true)

    /**
     * 服務名稱輸入框失焦時
     * @return {void}
     */
    const handleServiceNameOnBlur = () => setTimeout(() => setShowServiceNameDropdown(false), 200)

    /**
     * 服務名稱選項點擊時
     * @param option {Object} option
     * @return {void}
     */
    const handleServiceNameOptionOnClick = (option) => {
        // 值不為空
        if (!objectIsEmpty(option)) {
            setServiceName(option.serviceName)
        }
        // 關閉服務名稱下拉選單
        setShowServiceNameDropdown(false)
    }

    /**
     * 檢核類別改變時
     * @param e {event} event
     * @return {void}
     */
    const handleCheckTypeOnChange = (e) => setCheckType(e.target.value)

    /**
     * 勿打擾改變時
     * @param e {event} event
     * @return {void}
     */
    const handleAlarmTimeOnChange = (e) => setAlarmTime(e.target.value)

    /**
     * 活動中改變時
     * @return {void}
     */
    const handleActionOnChange = () => setAction(!action)

    /**
     * 服務有效否改變時
     * @return {void}
     */
    const handleEffFlagOnChange = () => setEffFlag(Number(!effFlag))

    /**
     * 搜尋按鈕點擊時
     * @return {void}
     */
    const handleSearchButtonOnClick = () => getQueryServiceMonitor()

    /**
     * 編輯按鈕點擊時
     * @return {void}
     */
    const handleEditButtonOnClick = () => {

    }

    /**
     * 刪除按鈕點擊時
     * @param item {Object} item
     * @return {void}
     */
    const handleDelButtonOnClick = (item) => {
        // 設定刪除項目
        setDelItem(item)
        // 顯示刪除視窗
        setShowDeletePopup(true)
    }

    /**
     * 複製按鈕點擊時
     * @return {void}
     */
    const handleCopyButtonOnClick = () => {

    }

    /**
     * 院區改變時
     * @param index {number} index
     * @return {void}
     */
    const handleCampusOnChange = (index) => {
        // 取得當前院區
        const newCampus = [...campus]
        // 設定當前院區值
        newCampus[index] = {...newCampus[index], value: !newCampus[index].value}
        // 設定院區
        setCampus(newCampus)
    }

    /**
     * 新增服務註冊popup關閉時
     * @return {void}
     */
    const handleAddRegisterServicePopupOnClose = () => setShowAddEditRegisterServicePopup(false)

    /**
     * 狀態改變時
     * @param e {event} event
     * @return {void}
     */
    const handleServiceEnableOnChange = (e) => setServiceEnable(e.target.value)

    /**
     * 刪除視窗關閉時
     * @return {void}
     */
    const handleDeletePopupOnClose = () => setShowDeletePopup(false)

    /**
     * 刪除視窗確定按鈕點擊時
     * @return {void}
     */
    const handleDeletePopupYesBtnOnClick = () => delServiceMonitor()

    /**
     * 刪除視窗確定按鈕點擊時
     * @return {void}
     */
    const handleDeletePopupNoBtnOnClick = () => handleDeletePopupOnClose()

    /**
     * 取得過濾後的資料
     * @return {Array<Object>}
     */
    const getFilterData = () => fuzzySearchObjects(data, searchText)

    /**
     * 取得過濾後的服務名稱
     * @return {Array<Object>}
     */
    const getFilterServiceNameList = () => fuzzySearchObjects(baseData, serviceName)

    /**
     * 刪除服務監控
     * @return {void}
     */
    const delServiceMonitor = () => {
        wdisDelServiceMonitor({
            // 服務ID
            serviceId: delItem.serviceId
        }).then(res => {
            // 狀態 / 訊息
            const {err, msg} = res
            // 刪除成功
            if (err === ApiErrorStatusEnum.Success) {
                // 重新取得
                getQueryServiceMonitor()
                // 清空資料
                setDelItem(null)
                // 關閉刪除視窗
                setShowDeletePopup(false)
                // 顯示成功訊息
                showToast({message: msg})
            } else { //無法刪除
                // 顯示錯誤訊息
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 取得該搜尋條件之服務資料
     * @return {void}
     */
    const getQueryServiceMonitor = () => {
        // 取得分院
        const zone = campus.filter(item => item.value === true).map(item => item.key)
        wdisQueryServiceMonitor({
            //服務啓用否(0停止/1啟用)
            // 啟用否與刪除之欄位有不同的意思, 例如未來可能做定期檢查 "服務啟用否" 之檢查或清單
            serviceEnable: serviceEnable,
            //服務名稱
            serviceName: serviceName,
            //檢核類別: 1:Month/2:Week/3:Day/
            // 4:Hour/5:Min/6:Special
            checkType: checkType,
            //勿打擾 0:全部/1:己設定/2:未設定
            alarmTime: alarmTime,
            //醫院代號
            zone: zone.join(','),
            //是否服務正執行活動中
            action: action ? 1 : 0,
            //服務有效否(0無效/1有效)
            effFlag: effFlag,
        }).then(res => {
            const {err, data} = res
            if (err === ApiErrorStatusEnum.Success) {
                // 設定資料
                setData(data)
            }
        })
    }

    /**
     * 取得基礎資料
     * @return {void}
     */
    const getQueryBaseData = () => {
        WdisQueryBaseData({}).then(res => {
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                // 設定基礎資料
                setBaseData(data)
            } else { // 取得失敗
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 第一次執行時，取得服務資料
     * @return {void}
     */
    useEffect(() => {
        //取得基礎資料
        getQueryBaseData()
        // 建立院區
        const createCampus = campusList.map(item => ({key: item, value: false}))
        // 取得當前角色
        const currentCampus = getLocalStorage('campus')
        const newCampus = createCampus.map(item => item.key === currentCampus ? {...item, value: true} : item)
        // 設定當前角色
        setCampus(newCampus)
    }, [])

    /**
     * 院區改變時，取得該搜尋條件之服務資料
     * @return {void}
     */
    useEffect(() => {
        // 當前院區不為空
        if (!arrayIsEmpty(campus)) {
            // 是否為第一次取得資料
            if (!hasFirstFetchedData.current) {
                //取得該搜尋條件之服務資料
                getQueryServiceMonitor()
                // 設定為已取得資料
                hasFirstFetchedData.current = true
            }
        }
    }, [campus])

    return (
        <div className="w-full p-4 bg-[#FAFAFA]">
            {/*篩選清單*/}
            <div className="filterGroup flex flex-row flex-wrap items-center justify-start">
                <div className="flex flex-row items-center justify-start mb-4 mr-4">
                    {/*狀態*/}
                    <p className="mr-1">{t('general.status')}：</p>
                    <select className="w-[145px] h-10 px-2 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                            value={serviceEnable} onChange={handleServiceEnableOnChange}>
                        {
                            Object.keys(WDISServiceEnableEnum)
                                .filter(key => isNaN(Number(key)))
                                .map((key, index) =>
                                    <option key={index} value={WDISServiceEnableEnum[key]}>
                                        {t(`WDIS.ServiceEnableEnum.${WDISServiceEnableEnum[key]}`)}
                                    </option>
                                )
                        }
                    </select>
                    {/*服務名稱*/}
                    <p className="mr-1">服務名稱：</p>
                    <div className="flex flex-row space-x-2 items-center relative w-[186px] mr-4">
                        <BaseInput
                            className="w-full h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                            inputMode={'search'} value={serviceName}
                            onFocus={handleServiceNameOnFocus}
                            onChange={handleServiceNameOnChange}
                            onBlur={handleServiceNameOnBlur}/>
                        {
                            showServiceNameDropdown && !arrayIsEmpty(baseData) &&
                            (
                                <div
                                    className="bg-white z-10 absolute left-5 border border-[#d4d4d8] rounded-md top-8 w-64 flex flex-col">
                                    <div className="max-h-[200px] bg-transparent overflow-y-auto px-2.5">
                                        {
                                            getFilterServiceNameList().map((item, index) =>
                                                <div
                                                    className="bg-transparent border-b min-h-10 flex space-x-6 px-4 py-1 text-[#18181B] items-center"
                                                    role="button" key={index}
                                                    onClick={() => handleServiceNameOptionOnClick(item)}>
                                                    <p>{`${item.serviceName}`}</p>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        }
                    </div>

                    {/*檢核類別*/}
                    <p className="mr-1">檢核類別：</p>
                    <select className="w-[145px] h-10 px-2 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                            value={checkType} onChange={handleCheckTypeOnChange}>
                        {
                            Array.from({length: 7}, (_, index) =>
                                <option key={index} value={index}>
                                    {t(`WDIS.CheckTypeEnum.${index}`)}
                                </option>)
                        }
                    </select>
                    {/*勿打擾*/}
                    <p className="mr-1">勿打擾：</p>
                    <select className="w-[145px] h-10 px-2 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                            value={alarmTime} onChange={handleAlarmTimeOnChange}>
                        {
                            Object.keys(WDISAlarmTimeEnum)
                                .filter(key => isNaN(Number(key)))
                                .map((key, index) =>
                                    <option key={index} value={WDISAlarmTimeEnum[key]}>
                                        {t(`WDIS.WDISAlarmTimeEnum.${WDISAlarmTimeEnum[key]}`)}
                                    </option>
                                )
                        }
                    </select>
                </div>
                {
                    // 顯示院區多選
                    !arrayIsEmpty(campus) && campus.map((item, index) =>
                        <div className="flex flex-row space-x-3 mb-4 mr-4" key={index}>
                            <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5" type="checkbox"
                                   id={`r${index}`} style={{accentColor: "#38A169"}} checked={campus[index].value}
                                   onChange={() => handleCampusOnChange(index)}/>
                            <label className="cursor-pointer" htmlFor={`r${index}`}>
                                {t(`campus.shortName.${item.key}`)}
                            </label>
                        </div>
                    )
                }
                <div className="w-[1px] h-[40px] bg-[#111111]/15 mx-4 mb-4"></div>
                <div className="flex flex-row space-x-3 mb-4 mr-4">
                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5" type="checkbox" id="r4"
                           style={{accentColor: "#38A169"}} checked={action} onChange={handleActionOnChange}/>
                    <label className="cursor-pointer" htmlFor="r4">活動中</label>
                </div>
                <div className="flex flex-row space-x-3 mb-4 mr-4">
                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5" type="checkbox" id="r5"
                           style={{accentColor: "#38A169"}} checked={effFlag === WDISEffFlagEnum.Disable}
                           onChange={handleEffFlagOnChange}/>
                    <label className="cursor-pointer" htmlFor="r5">已刪除</label>
                </div>
                <div className="flex flex-row items-center justify-start mb-4 mr-4">
                    {/*查詢按鈕*/}
                    <button
                        className="flex items-center justify-center h-10 px-4 mr-2 border-[2px] bg-[#2B6CB0] text-white border-[#2B6CB0] rounded-[6px]"
                        onClick={handleSearchButtonOnClick}>
                        {t('general.query')}
                    </button>
                </div>
            </div>
            {/*新增服務註冊*/}
            <div className="w-full h-[60px] py-[10px]">
                {/*新增按鈕*/}
                <button className="flex flex-row justify-center items-center w-[112px] h-10"
                        onClick={handleShowAddPopupOnClick}>
                    <div className="p-[3.33px] w-10 h-10">
                        <img className="w-full h-full" src={AddIcon} alt="addIcon"/>
                    </div>
                    <p className="text-center text-[#38A169]">
                        {t('general.add')}
                    </p>
                </button>
            </div>
            {/*服務表格清單*/}
            <div className="maintainContainer flex flex-row items-start justify-start">
                <div className="p-4 bg-white w-full border-[1px] border-[#D4D4D8] rounded-[12px] mr-4">
                    <div className="searchGroup flex flex-row items-center justify-between mb-4">
                        {/*進階搜尋*/}
                        <BaseInput className="w-[320px] h-10 pl-4 border-[2px] border-[#D4D4D8] rounded-[6px]"
                                   type="text" placeholder={t('general.advancedSearch')} inputMode={'search'}
                                   value={searchText} onChange={handleSearchTextOnChange}/>
                        {/*輸出按鈕*/}
                        <button className="flex flex-row justify-center items-center w-[112px] h-10">
                            <div className="w-10 h-10">
                                <img className="w-full h-full" src={ExportIcon} alt="addIcon"/>
                            </div>
                            <p className="text-center text-[#38A169]">
                                {t('general.export')}
                            </p>
                        </button>
                    </div>
                    <ul className="tableList BDMTableList">
                        <li className="title">
                            <div>操作</div>
                            <div>服務代號</div>
                            <div>服務名稱 <br/>簡稱</div>
                            <div>檢核類別</div>
                            <div>檢查頻率</div>
                            <div>延時分鐘</div>
                            <div>負責人</div>
                            <div>註解</div>
                            <div>啟用否</div>
                            <div>刪除否</div>
                            <div>勿打擾</div>
                        </li>
                        {
                            !arrayIsEmpty(data) && getFilterData().map((item, index) =>
                                // 操作
                                <li key={index}>
                                    <div className="flex flex-row items-center justify-start">
                                        {/*複製按鈕*/}
                                        <button className="flex flex-row items-center justify-start text-[#2B6CB0] mr-3"
                                                onClick={handleEditButtonOnClick}>
                                            <EditBtnIcon className="mr-1"/>
                                            {t('general.edit')}
                                        </button>
                                        {/*複製按鈕*/}
                                        <button className="flex flex-row items-center justify-start text-[#2B6CB0] mr-3"
                                                onClick={handleCopyButtonOnClick}>
                                            <CopyBtnIcon className="mr-1"/>
                                            {t('general.copy')}
                                        </button>
                                        {/*刪除按鈕*/}
                                        <button className="flex flex-row items-center justify-start text-[#2B6CB0]"
                                                onClick={() => handleDelButtonOnClick(item)}>
                                            <DeleteBtnIcon className="mr-1"/>
                                            {t('general.delete')}
                                        </button>
                                    </div>
                                    {/*服務代號*/}
                                    <div>{item.serviceId}</div>
                                    {/*服務名稱 簡稱*/}
                                    <div>{item.serviceName} <br/> {item.serviceSname}</div>
                                    {/*檢核類別*/}
                                    <div>{t(`WDIS.CheckTypeEnum.${item.checkType}`)}</div>
                                    {/*檢查頻率*/}
                                    <div></div>
                                    {/*延時分鐘*/}
                                    <div>{item.delayMin}</div>
                                    {/*負責人*/}
                                    <div></div>
                                    {/*註解*/}
                                    <div>{item.serviceNote}</div>
                                    {/*啟用否*/}
                                    <div>{item.serviceEnable ? '啟用中' : '未啟用'}</div>
                                    {/*刪除否*/}
                                    <div></div>
                                    {/*勿打擾*/}
                                    <div></div>
                                </li>
                            )
                        }
                    </ul>
                </div>
            </div>

            {/*新增服務註冊popup*/}
            {<AddEditRegisterServicePopup show={showAddEditRegisterServicePopup}
                                          popupMode={addEditRegisterServicePopupMode}
                                          campusList={campusList}
                                          popupCloseButtonOnClick={handleAddRegisterServicePopupOnClose}/>}

            {/*負責人員選單*/}
            {<PersonInChargePopup show={showPersonInChargePopup}/>}

            {/*刪除確認popup*/}
            <DeletePopup show={showDeletePopup} content="是否確定刪除？"
                         onClosePopupButtonClick={handleDeletePopupOnClose}
                         onYesButtonClick={handleDeletePopupYesBtnOnClick}
                         onNoButtonClick={handleDeletePopupNoBtnOnClick}/>
        </div>
    )
}
export default BasicDataMaintenance
