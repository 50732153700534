import {BaseInput} from "../../components/Input/BaseInput";
import {t} from "i18next"
import {fuzzySearchObjects} from "edah_utils/dist";
import React, {useState} from "react";
import NewCaseList from "../../components/HomeHealthCare/NewCaseList";
import ActiveCaseList from "../../components/HomeHealthCare/ActiveCaseList";
import CloseCaseList from "../../components/HomeHealthCare/ClosedCaseList";
import {
    Select,
    SelectWidthEnum,
    Button,
    ButtonSizeEnum,
    ButtonVariantEnum,
    ButtonColorEnum,
    IconEnum,
    DateRangePicker
} from "edah-component/dist";
import dayjs from "dayjs";

/**
 * 個案查詢報表_新收案
 * @return {JSX.Element}
 */
const CaseQueryReport = () => {

    // 搜尋文字
    const [searchText, setSearchText] = useState('')

    // 日期範圍
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    // 選擇的篩選條件
    const [selectedOption, setSelectedOption] = useState('0');

    // 查詢時的篩選條件
    const [queryOption, setQueryOption] = useState('0');

    // 篩選後的數據
    const [filteredData, setFilteredData] = useState([]);

    // Fake Data
    // 新收案
    const [dataObject, setDataObject] = useState([
        {
            familySerialNumber: '09876',         // 家字號
            medicalRecordNumber: '0801234',      // 病歷號
            name: '黃ＯＯ',                      // 姓名
            admissionDate: '2024-06-12',         // 收案日期
            primaryDiagnosisCode: 'C77.9',       // 主診斷碼
            primaryDiagnosisDescription: '腸阻塞', // 主診斷碼敘述
            referralSource: '住院',              // 轉介來源
            generalPassport: 'N',                // 一般護照
            nasogastricTube: 'N',                // 留置鼻胃管
            urinaryCatheter: 'N',                // 留置尿管
            tracheostomyReplacement: 'N',        // 氣切更換
            ventilator: 'N',                     // 呼吸器
            woundCare: 'N',                      // 傷口照護
            ostomyCare: 'N',                     // 造口護理
            specimenCollection: 'N',             // 檢體收集
            others: 'N',                         // 其他
            otherDescription: '安寧居家ECOG4級', // 其他敘述
            caseManager: '廖ＯＯ'                // 收案人員
        },
        {
            familySerialNumber: '09876',         // 家字號
            medicalRecordNumber: '0801234',      // 病歷號
            name: '黃ＯＯ',                      // 姓名
            admissionDate: '2024-06-13',         // 收案日期
            primaryDiagnosisCode: 'C77.9',       // 主診斷碼
            primaryDiagnosisDescription: '腸阻塞', // 主診斷碼敘述
            referralSource: '住院',              // 轉介來源
            generalPassport: 'N',                // 一般護照
            nasogastricTube: 'N',                // 留置鼻胃管
            urinaryCatheter: 'N',                // 留置尿管
            tracheostomyReplacement: 'N',        // 氣切更換
            ventilator: 'N',                     // 呼吸器
            woundCare: 'N',                      // 傷口照護
            ostomyCare: 'N',                     // 造口護理
            specimenCollection: 'N',             // 檢體收集
            others: 'N',                         // 其他
            otherDescription: '安寧居家ECOG4級', // 其他敘述
            caseManager: '廖ＯＯ'                // 收案人員
        },
        {
            familySerialNumber: '09876',         // 家字號
            medicalRecordNumber: '0801234',      // 病歷號
            name: '黃ＯＯ',                      // 姓名
            admissionDate: '2024-06-15',         // 收案日期
            primaryDiagnosisCode: 'C77.9',       // 主診斷碼
            primaryDiagnosisDescription: '腸阻塞', // 主診斷碼敘述
            referralSource: '住院',              // 轉介來源
            generalPassport: 'N',                // 一般護照
            nasogastricTube: 'N',                // 留置鼻胃管
            urinaryCatheter: 'N',                // 留置尿管
            tracheostomyReplacement: 'N',        // 氣切更換
            ventilator: 'N',                     // 呼吸器
            woundCare: 'N',                      // 傷口照護
            ostomyCare: 'N',                     // 造口護理
            specimenCollection: 'N',             // 檢體收集
            others: 'N',                         // 其他
            otherDescription: '安寧居家ECOG4級', // 其他敘述
            caseManager: '廖ＯＯ'                // 收案人員
        }
    ]);
    // 活動案
    const [activeData, setActiveData] = useState([
        {
            admissionDate: '2024-06-12',                // 收案日期
            name: '黃ＯＯ',                             // 姓名
            birthDate: '2023-09-12',                    // 出生日期
            age: '18',                                  // 年齡
            familySerialNumber: '00001',                // 家字號
            medicalRecordNumber: '1234567',             // 病歷號
            idNumber: 'E123456789',                     // 身分證號
            gender: '女',                               // 性別
            primaryDiagnosisCode: '100',                // 主診斷碼
            primaryDiagnosisDescription: '鉤端螺旋體病', // 主診斷碼敘述
            referralSource: '其他',                      // 轉介來源
            barthelIndex: '5',                          // 巴氏
            katsIndex: '4',                             // 科氏
            contactPerson: '東尼',                      // 聯絡人
            contactPhone: '075436600',                  // 聯絡電話
            contactAddress: '高雄市燕巢區義大路1號',    // 聯絡地址
            caseManager: '資訊測試',                    // 收案人員
            nutritionAssessmentDate: '其他',            // 營養評估日
            eatingStatus: '照顧者評估護理師',            // 進食狀況
            weightChange: '2023-09-12',                 // 體重變化
            mobility: '0',                              // 行動力
            acuteDisease: '0',                          // 急性疾病
            neuroPsychiatricProblems: '0',              // 神經精神問題
            bmi: '0',                                   // BMI
            totalScore: '0',                            // 總分
            nutritionistIntervention: '0',              // 營養師介入
            nutritionistInterventionDescription: 'N',   // 營養師介入說明
            nutritionAssessmentNurse: 'test',           // 營養評估護理師
            nutritionReferralDate: 'EDAHAP',            // 營養轉介日期
            nasogastricTubeOrGastrostomy: '2023-09-12', // 鼻造管、胃造口留置
            mnaLessThan11: 'Y',                         // MNA<11
            albuminLessThan3: 'N',                      // Albumin<3.0mg/dl
            needNutritionConsultation: 'N',             // 需營養諮詢個案
            others: 'N',                                // 其他
            primaryCaregiverAssessmentDate: 'N',        // 主要照顧者評估日期
            primaryCaregiverAssessment: '2023-09-12',   // 主要照顧者評估
            primaryCaregiverName: '2',                  // 主顧者姓名
            relationship: '外傭',                       // 關係
            primaryCaregiverAge: '37',                  // 年齡
            primaryCaregiverGender: '0',                // 性別
            employmentStatus: '2',                      // 就業狀況
            caregivingTime: '1',                        // 照顧時間
            averageDailyHours: '130',                   // 每日平均小時
            caregivingMonths: '2',                      // 照顧月數
            primaryCaregiverHealth: '1',                // 主顧者身體健康
            primaryCaregiverMentalStatus: 'N',          // 主顧者身心理狀況
            familyInteraction: '楊ＯＯ',                // 家庭互動關係
            burdenTotalScore: '3',                      // 負荷總分
            noBurdenIssues: 'Y',                        // 無照護負荷問題
            poorCareQuality: 'N',                       // 照顧品質不佳
            heavyBurden: 'N',                           // 照護負荷過重
            noWillingnessToCare: 'N',                   // 無照護意願
            otherBurden: 'N',                           // 其他
            caregiverOverallDescription: 'text',        // 照顧者總評敘述
            caregiverAssessmentNurse: '一樣幫我做成資料格式並標上註記' // 照顧者評估護理師
        }
    ]);
    // 已結案
    const [closeData, setCloseData] = useState([
        {
            id: '1',                             // 編號
            familySerialNumber: '09876',         // 家字號
            medicalRecordNumber: '0801234',      // 病歷號
            name: '黃ＯＯ',                      // 姓名
            birthDate: '1990-12-12',             // 出生日期
            idNumber: 'S123456789',              // 身分證號
            admissionDate: '2024-06-14',         // 收案日期
            dischargeDate: '2023-09-12',         // 銷案日期
            caseCloser: '陳ＯＯ',                // 結案人員
            closeReason: '死亡',                 // 結案原因
            closeReasonOtherDescription: 'N',    // 結案原因其他描述
            notes: '備註'                        // 備註
        },
        {
            id: '2',                             // 編號
            familySerialNumber: '09876',         // 家字號
            medicalRecordNumber: '0801234',      // 病歷號
            name: '黃ＯＯ',                      // 姓名
            birthDate: '1990-12-12',             // 出生日期
            idNumber: 'E220998987',              // 身分證號
            admissionDate: '2024-06-14',         // 收案日期
            dischargeDate: '2023-09-12',         // 銷案日期
            caseCloser: '王ＯＯ',                // 結案人員
            closeReason: '問題解決',             // 結案原因
            closeReasonOtherDescription: '尿管移除', // 結案原因其他描述
            notes: '備註'                        // 備註
        },
        {
            id: '3',                             // 編號
            familySerialNumber: '09876',         // 家字號
            medicalRecordNumber: '0801234',      // 病歷號
            name: '黃ＯＯ',                      // 姓名
            birthDate: '1990-12-12',             // 出生日期
            idNumber: 'E220998987',              // 身分證號
            admissionDate: '2024-06-12',         // 收案日期
            dischargeDate: '2023-09-12',         // 銷案日期
            caseCloser: '林ＯＯ',                // 結案人員
            closeReason: '問題解決',             // 結案原因
            closeReasonOtherDescription: '尿管移除，目前解尿正常', // 結案原因其他描述
            notes: '備註'                        // 備註
        }
    ]);


    /**
     * 搜尋文字改變時
     * @param e {Event} e
     * @return {void}
     */
    const handleSearchTextOnChange = (e) => setSearchText(e.target.value)

    /**
     * 篩選條件改變時
     * @param value {String} 篩選條件內容
     * @return {void}
     */
    const handleSelectChange = (value) => setSelectedOption(value);

    /**
     * 日期範圍改變時
     * @param dateRange {Array} [startDate, endDate]
     * @return {void}
     */
    const handleDateRangeChange = (dateRange) => {
        const [start, end] = dateRange;
        setStartDate(start ? start.toDate() : null);
        setEndDate(end ? end.toDate() : null);
    };

    /**
     * 篩選數據
     * @param data {Array} 數據數組
     * @return {Array} 篩選後的數據數組
     */
    const filterDataByDateRange = (data) => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        return data.filter(item => {
            const admissionDate = new Date(item.admissionDate);
            return admissionDate >= start && admissionDate <= end;
        });
    };

    /**
     * 查詢按鈕點擊時
     * @return {void}
     */
    const handleQueryClick = () => {
        let filtered = [];
        if (selectedOption === '0') {
            filtered = filterDataByDateRange(dataObject);
        } else if (selectedOption === '1') {
            filtered = filterDataByDateRange(activeData);
        } else if (selectedOption === '2') {
            filtered = filterDataByDateRange(closeData);
        }

        setFilteredData(fuzzySearchObjects(filtered, searchText));
        setQueryOption(selectedOption);
    };

    return (
        <div className="w-full p-4 bg-[#FAFAFA]">
            {/*篩選清單*/}
            <div className="filterGroup flex flex-row flex-wrap items-center justify-start">
                <div className="flex flex-row items-center justify-start space-x-2 py-2">
                    {/* 查詢日期起訖 */}
                    <DateRangePicker
                        required
                        localeText={{start: '查詢開始日期', end: '查詢結束日期'}}
                        value={[startDate ? dayjs(startDate) : null, endDate ? dayjs(endDate) : null]}
                        onChange={handleDateRangeChange}/>
                    {/*新收案*/}
                    <Select
                        data={{
                            label: t('general.option'),
                            options: [
                                {value: "0", label: t('homeHealthCare.newCase')},
                                {value: "1", label: t('homeHealthCare.activeCase')},
                                {value: "2", label: t('homeHealthCare.closeCase')}
                            ]
                        }}
                        value={selectedOption}
                        onChange={handleSelectChange}
                        notched={true}
                        displayEmpty={true}
                        width={SelectWidthEnum.Medium}/>
                    {/*查詢送出按鈕*/}
                    <Button color={ButtonColorEnum.Primary}
                            size={ButtonSizeEnum.Medium}
                            variant={ButtonVariantEnum.Contained}
                            onClick={handleQueryClick}>
                        {t('general.query')}
                    </Button>
                </div>

                {/*列表清單區塊-新收案*/}
                <div className="maintainContainer flex flex-row items-start justify-start w-full">
                    <div className="p-4 bg-white w-full border-[1px] border-[#D4D4D8] rounded-[12px] mr-4">
                        <div className="searchGroup flex flex-row items-center justify-between mb-4">
                            {/*進階搜尋*/}
                            <BaseInput className="w-[320px] h-10 pl-4 border-[2px] border-[#D4D4D8] rounded-[6px]"
                                       type="text"
                                       placeholder={`${t('general.advancedSearch')}`}
                                       inputMode={'search'}
                                       value={searchText}
                                       onChange={(e) => handleSearchTextOnChange(e)}/>
                            {/*匯出按鈕*/}
                            <Button color={ButtonColorEnum.Success}
                                    size={ButtonSizeEnum.Large}
                                    variant={ButtonVariantEnum.Text}
                                    icon={IconEnum.Export}>
                                {t('general.export')}
                            </Button>
                        </div>
                        {queryOption === '0' && <NewCaseList data={filteredData} searchText={searchText}/>}
                        {queryOption === '1' && <ActiveCaseList data={filteredData} searchText={searchText}/>}
                        {queryOption === '2' && <CloseCaseList data={filteredData} searchText={searchText}/>}
                    </div>
                </div>

                {/*列表清單區塊-活動案*/}


                {/*列表清單區塊-已結案*/}

            </div>
        </div>
    )
}
export default CaseQueryReport