import { time2String } from 'edah_utils/dist'
import { DATE_FORMAT } from '../common'
import i18n from '../../i18n'

export const ER_QUALITY_VPN_FIELDS = [
    {
        key: 'encounterId',
        name: i18n.t('Rmis.pages.erQualityVpnUpload.tableHeader.encounterId'), // Con1
    },
    {
        key: 'idNo',
        name: i18n.t('Rmis.pages.erQualityVpnUpload.tableHeader.idNo'), // id_no
    },
    {
        key: 'birthDate',
        name: i18n.t('Rmis.pages.erQualityVpnUpload.tableHeader.birthDate'), // Birth DATE
        format: (value) => time2String(value, DATE_FORMAT),
    },
    {
        key: 'emgInDatetime',
        name: i18n.t('Rmis.pages.erQualityVpnUpload.tableHeader.emgInDatetime'), // Epd st Dt
    },
    {
        key: 'emgOutDatetime',
        name: i18n.t('Rmis.pages.erQualityVpnUpload.tableHeader.emgOutDatetime'), // Epd ed Dt
    },
]
