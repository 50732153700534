import {arrayIsEmpty, time2String} from "edah_utils/dist"
import React from "react";
import { t } from "i18next";

/**
 * 用藥資料
 * @param data {Array<Object>} 用藥資料
 * @return {JSX.Element}
 */
export const MedicationInformation = ({data}) => {

    return (
        <div className="w-full">
            {/*列表清單區塊*/}
            {/*列表清單*/}
            <ul className="tableList icMiList ">
                {/*表單項目標題*/}
                <li className="title">
                    <div>門住診別</div>
                    <div>主診斷碼</div>
                    <div>主診斷名稱</div>
                    <div>ATC7代碼</div>
                    <div>ATC7名稱</div>
                    <div>成分代碼</div>
                    <div>成份名稱</div>
                    <div>藥品健保代碼</div>
                    <div>藥品名稱</div>
                    <div>藥品規格量</div>
                    <div>藥品規格單位</div>
                    <div>用法用量</div>
                    <div>就醫日期<br/>(住院用藥起日)</div>
                    <div>慢連箋領藥日<br/>(住院用藥迄日)</div>
                    <div>給藥日數</div>
                    <div>複方註記</div>
                    <div>就醫序號</div>
                    <div>醫事機構代碼</div>
                    <div>醫事機構簡稱</div>
                </li>
                {
                    !arrayIsEmpty(data) && data.map((item, index) =>
                        <li key={index}>
                            {/*門住*/}
                            <div>{t(`ICCard.InpOpdEnum.${item.inpOpd}`)}</div>
                            {/*主診斷碼*/}
                            <div>{item.treatmentCode}</div>
                            {/*主診斷名稱*/}
                            <div>{item.treatmentName}</div>
                            {/*ATC7代碼*/}
                            <div>{item.atc7Code}</div>
                            {/*ATC7名稱*/}
                            <div>{item.atc7Name}</div>
                            {/*成分代碼*/}
                            <div>{item.ingredientCode}</div>
                            {/*成份名稱*/}
                            <div>{item.ingredientName}</div>
                            {/*藥品健保代碼*/}
                            <div>{item.orderHeCode}</div>
                            {/*藥品名稱*/}
                            <div>{item.orderHeName}</div>
                            {/*藥品規格量*/}
                            <div>{item.specificationsQty}</div>
                            {/*藥品規格單位*/}
                            <div>{item.specificationsUnit}</div>
                            {/*用法用量*/}
                            <div>{item.useNo}</div>
                            {/*就醫日期(住院用藥起日)*/}
                            <div>{time2String(item.encounterStartDate, 'YYYY-MM-DD')}</div>
                            {/*慢連箋領藥日(住院用藥迄日)*/}
                            <div>{time2String(item.encounterEndDate, 'YYYY-MM-DD')}</div>
                            {/*給藥日數*/}
                            <div>{item.days}</div>
                            {/*複方註記*/}
                            <div>{item.compoundNote}</div>
                            {/*就醫序號*/}
                            <div>{item.encSeqNo}</div>
                            {/*醫事機構代碼*/}
                            <div>{item.hospNo}</div>
                            {/*醫事機構簡稱*/}
                            <div>{item.hospName}</div>
                        </li>
                    )}
            </ul>
        </div>
    )
}



