import {ReactComponent as DeleteBtn} from "../../assets/images/icons/del_square.svg"
import {ReactComponent as ViewBtn} from '../../assets/images/icons/view.svg';
import {ReactComponent as EditBtn} from '../../assets/images/icons/edit.svg';
import {ReactComponent as CopyBtn} from '../../assets/images/SystemMonitoring/copy.svg';
import {ReactComponent as ExportBtn} from '../../assets/images/SystemMonitoring/export.svg';
import {ReactComponent as SaveBtn} from '../../assets/images/icons/save_default.svg';
import {ReactComponent as CancelBtn} from '../../assets/images/icons/cancel_default.svg';
import {ReactComponent as FastEditBtn} from '../../assets/images/icons/edit_fast.svg';

// 刪除按鈕
const DelSlotBtn = ({onClick}) => (
    <span className='flex items-center text-primary hover:cursor-pointer' onClick={onClick}>
        <button>
            <DeleteBtn className='w-6 h-6 z-50'/>
        </button>
        <p className={'w-[38px]'}>
            刪除
        </p>
    </span>
);

// 檢視按鈕
const ViewSlotBtn = ({onClick}) => (
    <span className='flex items-center text-primary hover:cursor-pointer' onClick={onClick}>
        <button>
            <ViewBtn className='w-6 h-6 '/>
        </button>
        <p className={'w-[38px]'}>
            檢視
        </p>
    </span>
);

// 編輯按鈕
const EditSlotBtn = ({onClick}) => (
    <span className='flex items-center text-primary hover:cursor-pointer' onClick={onClick}>
        <button>
            <EditBtn className='w-6 h-6 '/>
        </button>
        <p className={'w-[38px]'}>
            編輯
        </p>
    </span>
);

// 複製按鈕  
const CopySlotBtn = ({onClick}) => (
    <span className='flex items-center text-primary hover:cursor-pointer' onClick={onClick}>
        <button>
            <CopyBtn className='w-6 h-6 '/>
        </button>
        <p className={'w-[38px]'}>
            複製
        </p>
    </span>
);

// 註銷按鈕
const LofoffSlotBtn = ({onClick}) => (
    <span className='flex items-center text-primary hover:cursor-pointer' onClick={onClick}>
        <button>
            <DeleteBtn className='w-6 h-6 '/>
        </button>
        <p className='w-[38px]'>
            註銷
        </p>
    </span>
);

// 變更按鈕
const ChangeSlotBtn = ({onClick}) => (
    <span className='flex items-center text-primary hover:cursor-pointer' onClick={onClick}>
        <button>
            <EditBtn className='w-6 h-6 '/>
        </button>
        <p className='w-[38px]'>
            變更
        </p>
    </span>
);

/**
 * 表格儲存按鈕
 * @returns {JSX.Element}
 * */
const SaveSlotBtn = ({onClick}) => (
    <span className='flex items-center text-primary hover:cursor-pointer' onClick={onClick}>
      <button className='w-[64px] h-10 rounded-[6px] bg-primary text-white'>
        存檔
      </button>
    </span>
);

/**
 * 表格匯出按鈕
 * @returns {JSX.Element}
 * */
const ExportSlotBtn = ({onClick}) => (
    <span className='flex items-center text-lg text-[#25855A] hover:cursor-pointer' onClick={onClick}>
      <button>
        <ExportBtn className='w-10 h-10 '/>
      </button>
      匯出
    </span>
);

const CancelSlotBtn = ({onClick}) => (
    <span className='flex items-center text-primary hover:cursor-pointer' onClick={onClick}>
      <button>
        <CancelBtn className='w-6 h-6 '/>
      </button>
        <p className={'w-[38px]'}>
            取消
        </p>
    </span>
);

const FastEditSlotBtn = ({onClick}) => (
    <span className='flex items-center text-primary hover:cursor-pointer' onClick={onClick}>
      <button>
        <FastEditBtn className='w-6 h-6 '/>
      </button>
        <span className='flex flex-col items-center justify-center'>
            <p className='w-full'>快速</p>
            <p className='w-full'>編輯</p>
        </span>
    </span>
);

const SaveIconSlotBtn = ({onClick}) => (
    <span className='flex items-center text-primary hover:cursor-pointer' onClick={onClick}>
      <button>
        <SaveBtn className='w-6 h-6 '/>
      </button>
        <p className={'w-[38px]'}>
            儲存
        </p>
    </span>
);


export {
    DelSlotBtn,
    ViewSlotBtn,
    EditSlotBtn,
    CopySlotBtn,
    LofoffSlotBtn,
    ChangeSlotBtn,
    SaveSlotBtn,
    ExportSlotBtn,
    CancelSlotBtn,
    FastEditSlotBtn,
    SaveIconSlotBtn
}