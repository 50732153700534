import {arrayIsEmpty, convertMinGuoToGregorian, time2String, stringIsEmpty} from "edah_utils/dist"

/**
 * 預防保健資料
 * @param data {Array} 資料
 * @return {JSX.Element}
 */
export const PreventiveHealthInfoSection = ({data}) => {
    return !arrayIsEmpty(data) ?
        (
            <div className="PreventiveHealthInfo">
                <ul className="tableList PreventiveHealthInfo">
                    <li className="title">
                        <div>保健服務項目</div>
                        <div>保健服務</div>
                        <div>檢查日期</div>
                        <div>醫療院所代碼</div>
                        <div>醫療院所</div>
                        <div>檢查項目代碼</div>
                        <div>檢查項目</div>
                    </li>
                    {
                        data.map((item, index) =>
                            <li key={index}>
                                {/*保健服務項目*/}
                                <div>{stringIsEmpty(item.healthNote) ? "" : item.healthNote}</div>
                                {/*保健服務*/}
                                <div>{stringIsEmpty(item.hearltNoteName) ? "" : item.hearltNoteName}</div>
                                {/*檢查日期*/}
                                <div>{time2String(item.examDate, "YYYY-MM-DD")}</div>
                                {/*醫療院所代碼*/}
                                <div>{stringIsEmpty(item.hospNo) ? "" : item.hospNo}</div>
                                {/*醫療院所*/}
                                <div>{stringIsEmpty(item.hospName) ? "" : item.hospName}</div>
                                {/*檢查項目代碼*/}
                                <div>{stringIsEmpty(item.examNo) ? "" : item.examNo}</div>
                                {/*檢查項目*/}
                                <div>{stringIsEmpty(item.examName) ? "" : item.examName}</div>
                            </li>
                        )
                    }
                </ul>
            </div>
        ) : null
}
