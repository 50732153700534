import React, {useState} from 'react'
import {ApiErrorStatusEnum, stringIsEmpty} from 'edah_utils/dist';
import CustomTable from '../RSISCustomTable';
import {queryEmployeeInfo} from "../../../api/v1/Users";
import {usePopup} from "../PopupProvider";
import {
    TextField,
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    Dialog,
    DialogVariant,
    DialogSizeEnums,
    AlertTypeEnum
} from "edah-component/dist";
import {t} from "i18next";

// 表格欄位
const fields = [
    '人員工號',
    '姓名',
    '部門代號',
    '部門名稱'
]

/**
 * 列表組件
 * @param item 列表項目
 * @param index 列表索引
 * @param hasSelectAll 是否全選
 * @param handleOnClick 點擊事件
 * @returns {JSX.Element}
 */
const ListItem = ({
                      item,
                      index,
                      hasSelectAll = false,
                      handleOnUpdate
                  }) => {
    /**
     * 點擊列表項目事件，帶回承辦人員資料
     * @return {void}
     */
    const handleItemOnClick = () => {
        handleOnUpdate(item)
    }

    return (
        <li className='hover:cursor-pointer' key={index} onClick={handleItemOnClick}>
            <div>{item.userNo}</div>
            <div>{item.userName}</div>
            <div>{item.depNo}</div>
            <div>{item.depName}</div>
        </li>
    )
}

/**
 * 人員查詢
 * @param {func} handleOnUpdate 設定選擇的人員
 * @param {func} handleOnClose 關閉彈窗
 * @returns {JSX.Element}
 */
const QueryCreateUserList = ({handleOnUpdate, handleOnClose}) => {
    // 資料列表
    const [dataList, setDataList] = useState([])
    // 人員工號
    const [userNo, setUserNo] = useState('')
    // 姓名
    const [name, setName] = useState('')
    // 使用上下文
    const {showToast} = usePopup();
    const [paginationProps, setPaginationProps] = useState({
        pageNum: 1,
        pageSize: 10,
        totalItemSize: 0,
        totalPageSize: 0,
        currentPageItemSize: 0,
        fn: () => {
        }
    });

    /**
     * 查詢
     * return {void}
     */
    const handleOnSearch = () => {
        getCreateUserList()
    }

    /**
     * 取得申請人員清單
     * @return {void}
     */
    const getCreateUserList = () => {
        // 如果人員工號為空和姓名為空
        if (stringIsEmpty(userNo) && stringIsEmpty(name)) {
            showToast({message: '請輸入查詢值!', type: AlertTypeEnum.Warning})
            return
        }

        queryEmployeeInfo({
            userNo: userNo,
            userName: name,
            medicalStaff: '',
            activeFlag: true
        }).then((res) => {
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                // 如果資料長度為0
                if (data.length === 0) {
                    showToast({message: '查無資料', type: AlertTypeEnum.Warning})
                }
                setDataList(data)
            } else {
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    return (
        <Dialog
            open={true}
            title={t('RSIS.Popup.QueryCreateUserList')}
            content={
                <div className='px-6 pb-6'>
                    <div className='flex justify-start items-center mb-4 gap-4'>
                        <TextField
                            value={userNo}
                            label="人員代號/工號"
                            onChange={(e) => setUserNo(e.target.value)}/>
                        <TextField
                            label='姓名'
                            value={name}
                            onChange={(e) => setName(e.target.value)}/>
                        <div className="source flex flex-row items-center justify-start mr-4">
                            <Button color={ButtonColorEnum.Primary} variant={ButtonVariantEnum.Contained}
                                    size={ButtonSizeEnum.Medium} text={t('general.query')}
                                    onClick={handleOnSearch}/>
                        </div>
                    </div>
                    <hr className="pb-6"/>
                    <CustomTable
                        hasSelectAll={false}
                        advancedSearch={true}
                        fields={fields}
                        dataList={dataList}
                        ListItem={ListItem}
                        type={'RSISResponiblePersonnalTable'}
                        paginationProps={paginationProps}
                        handleOnUpdateCallback={handleOnUpdate}/>
                </div>
            }
            variant={DialogVariant.CONFIRM}
            onClose={handleOnClose}
            paperStyleProps={{width: DialogSizeEnums.LG}}
            muiDialogContentProps={{dividers: true}}
            muiDialogActionsProps={{sx: {display: 'none'}}}
        />
    )
}

export default QueryCreateUserList
