//Import css
import '../../components/TableList/tableList.scss'
import '../../components/ICCard/maintain.scss'

//Import function
import React, {useState} from "react"
import {t} from "i18next"
import {iccmUploaDrecQueryUploadData} from "../../api/v1/ICCM"
import useToast from "../../hooks/useToast"
import {
    ApiErrorStatusEnum,
    arrayIsEmpty,
    enum2Array,
    fuzzySearchObjects, ICCMUploadTabEnum,
    InputTypeEnum,
    ToastTypeEnum
} from "edah_utils/dist"
import {BaseInput} from "../../components/Input/BaseInput"
import {
    DateRangePicker,
    Button,
    ButtonSizeEnum,
    ButtonVariantEnum,
    ButtonColorEnum,
    AlertTypeEnum
} from "edah-component/dist"
import dayjs from "dayjs"
import {Tabs, TabsEnum} from "edah-component/dist/index";
import {ReturnResultTab} from "../../components/ICCard/UploadSearchPage/Tab/ReturnResultTab";
import {UploadXMLTab} from "../../components/ICCard/UploadSearchPage/Tab/UploadXMLTab";
import {FieldMappingTab} from "../../components/ICCard/UploadSearchPage/Tab/FieldMappingTab";
import {ErrorCodeTab} from "../../components/ICCard/UploadSearchPage/Tab/ErrorCodeTab";

const tabList = enum2Array(ICCMUploadTabEnum)
/**
 * IC卡資料上傳資料查詢
 * @return {JSX.Element}
 */
const UploadSearchPage = () => {
    // 定義activeTab狀態
    const [activeTab, setActiveTab] = useState(ICCMUploadTabEnum.ReturnResult)
    /**
     * Show Toast
     */
    const showToast = useToast()

    // 就醫起始日期
    const [startEncounterDate, setStartEncounterDate] = useState('')
    // 就醫結束日期
    const [endEncounterDate, setEndEncounterDate] = useState('')
    //資料
    const [data, setData] = useState([])
    //搜尋文字
    const [advancedSearch, setAdvancedSearch] = useState('')


    /**
     * 日期範圍改變時
     * @param dateRange {Array} [startDate, endDate]
     * @return {void}
     */
    const handleDateRangeChange = (dateRange) => {
        const [start, end] = dateRange;
        setStartEncounterDate(start ? start.toDate() : null);
        setEndEncounterDate(end ? end.toDate() : null);
    };

    /**
     * 查詢按鈕點擊時
     * @return {void}
     */
    const handleQueryOnClick = () => getQueryUploadData()

    /**
     * 進階搜尋改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleAdvancedSearchOnChange = (e) => setAdvancedSearch(e.target.value)

    /**
     * 取得篩選資料
     * @return {Array<Object>}
     */
    const getFilterData = () => fuzzySearchObjects(data, advancedSearch)

    /**
     * 取得IC卡資料上傳查詢
     * @return {void}
     */
    const getQueryUploadData = () => {
        iccmUploaDrecQueryUploadData({
            //就醫起始日期
            startEncounterDate: `${startEncounterDate} 00:00:00`,
            //就醫結束日期
            endEncounterDate: `${endEncounterDate} 23:59:59`
        }).then(res => {
            //狀態 / 資料 / 訊息
            const {err, data, msg} = res
            //取得成功
            if (err === ApiErrorStatusEnum.Success) {
                //設定資料
                setData(data)
            } else { //取得失敗
                //清空資料
                setData([])
                //顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    // 取得 tab 項目
    const getTabItems = () => {
        return tabList.map((item, index) => {
            let title = ''
            let content = <></>
            switch (item.value) {
                case ICCMUploadTabEnum.ReturnResult:
                    title = '回傳結果'
                    content = <ReturnResultTab/>
                    break
                case ICCMUploadTabEnum.UploadXML:
                    title = '上傳XML'
                    content = <UploadXMLTab/>
                    break
                case ICCMUploadTabEnum.FieldMapping:
                    title = '欄位對照'
                    content = <FieldMappingTab/>
                    break
                case ICCMUploadTabEnum.ErrorCode:
                    title = '錯誤代碼'
                    content = <ErrorCodeTab/>
            }
            return {
                id: item.key,
                title,
                content
            }
        })
    }

    /**
     * 點擊Tab時
     * @param tab {ICCMUploadTabEnum} Tab Enum
     * @return {void}
     */
    const handleTabClick = (tab) => setActiveTab(tab)


    return (
        <div className="w-full p-4 bg-[#FAFAFA]">
            <div className="filterGroup flex flex-row flex-wrap items-center justify-start space-x-2 py-2">
                {/* 就醫起始日期起訖 */}
                <DateRangePicker
                    localeText={{start: '就醫起始日期', end: '就醫結束日期'}}
                    value={[startEncounterDate ? dayjs(startEncounterDate) : null, endEncounterDate ? dayjs(endEncounterDate) : null]}
                    required
                    onChange={handleDateRangeChange}
                />
                {/*查詢按鈕*/}
                <Button color={ButtonColorEnum.Primary}  size={ButtonSizeEnum.Medium}
                        variant={ButtonVariantEnum.Contained} text={t('general.query')} onClick={handleQueryOnClick}/>
            </div>
            <div className="maintainContainer flex flex-row items-start justify-start">
                <div className="p-4 bg-white w-[891px] border-[1px] border-[#D4D4D8] rounded-[12px] mr-4">
                    <div className="searchGroup flex flex-row items-center justify-between px-2 mb-4">
                        {/*進階搜尋*/}
                        <BaseInput className="w-[320px] h-10 pl-4 border-[2px] border-[#D4D4D8] rounded-[6px]"
                                   type={InputTypeEnum.Text} inputMode={InputTypeEnum.Search}
                                   placeholder={t('general.advancedSearch')} value={advancedSearch}
                                   onChange={handleAdvancedSearchOnChange}/>
                    </div>
                    <ul className="tableList icUploadList">
                        <li className="title">
                            <div>序號</div>
                            <div>上傳時間</div>
                            <div>代號</div>
                            <div>下載時間</div>
                            <div>是否成功</div>
                            <div>無效明細筆數</div>
                        </li>
                        {
                            !arrayIsEmpty(getFilterData) && getFilterData().map((item, index) =>
                                <li key={index}>
                                    {/*序號*/}
                                    <div>{item.icUploadrecId}</div>
                                    {/*上傳時間*/}
                                    <div>{item.uploadDateTime}</div>
                                    {/*代號*/}
                                    <div>{item.dataType}</div>
                                    {/*下載時間*/}
                                    <div>{item.downloadDateTime}</div>
                                    {/*是否成功*/}
                                    <div>{item.uploadStatus}</div>
                                    {/*無效明細筆數*/}
                                    <div>{item.invalidRecs}</div>
                                </li>
                            )
                        }
                    </ul>
                </div>
                <Tabs variant={TabsEnum.INDICATOR_TAB} activeTabId={activeTab} items={getTabItems()} onTabChange={handleTabClick}/>
            </div>
        </div>
    );
};

export default UploadSearchPage;
