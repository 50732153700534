import React, {useEffect, useState} from 'react'
import '../../TableList/tableList.scss'
import {ApiErrorStatusEnum, fuzzySearchObjects, objectIsEmpty, stringIsEmpty} from "edah_utils/dist"
import {getCurrentDateForInput} from "../utils"
import CvisListItem from "../CvisListItem"
import {Pagination} from "../../Pagination/Pagination"
import {queryCvisNotifyrecById, queryCvisResponse, saveCvisResponse} from "../../../api/v1/Critical"
import useToast from "../../../hooks/useToast";
import {
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    IconEnum,
    TextField,
    DatePicker,
    AlertTypeEnum,
    Field,
    SizeEnum,
    TextArea,
    RadioGroup,
    Card,
    Dialog,
    DialogVariant,
    DialogSizeEnums
} from "edah-component/dist";
import {t} from "i18next"
import {SearchTextField} from "../../SearchTextField/SearchTextField";
import dayjs from "dayjs";

/**
 * 處理狀態別列表
 */
const radioOptions = [
    {value: '0', label: '通知病患或家屬'},
    {value: '1', label: '持續觀察'},
    {value: '2', label: '病人通知不到'},
    {value: '3', label: '通知主治醫師'},
    {value: '4', label: '其他'},
    {value: '5', label: '處置說明'},
];
/**
 * 回覆作業彈窗
 * @param doctor {string} 主治醫師，預設9999，目前沒有系統能呼叫此頁面，所以先寫死
 * @param cvisID {string} 流水號ID，帶入查詢回覆紀錄API
 * @param handlePopupClose {function} 回覆作業彈窗事件
 * @param handleSaveSubmit {function} 儲存回覆內容事件
 * @return {JSX.Element}
 */
const CrisisAlertReplyAssignmentPopup = ({
                                             doctor = "9999",
                                             cvisID,
                                             handlePopupClose
                                         }) => {
    const showToast = useToast()
    const [data, setData] = useState({
        // 通報內容
        cvisNotifyContent: '',
        // 通報日期(發送日期時間)
        cvisNotifyDatetime: '',
        // 病歷號
        patientId: '',
        // 病患姓名
        patientName: '',
        // 電話
        phone: '',
        // 行動電話
        mobile: '',
        // 公司電話
        companyPhone: '',
        // 分機
        extension: '',
        // 聯絡人
        contactPerson: '',
        // 聯絡電話
        contactPhone: '',
        // 公司電話
        contactCompanyPhone: '',
        // 分機
        contactExtension: ''
    })
    // 回覆作業內容
    const [replyContent, setReplyContent] = useState({
        // 流水號id
        cvisId: cvisID,
        // 回覆日期時間
        replyDate: getCurrentDateForInput(),
        // 回覆人員
        replyPerson: doctor,
        // 處理狀態
        status: '',
        // 處理說明
        content: ''
    })
    // 回覆紀錄清單
    const [replyRecordList, setReplyRecordList] = useState([])
    // 過濾回覆紀錄清單
    const [filteredItems, setFilteredItems] = useState([])
    // 進階搜尋字串
    const [queryString, setQueryString] = useState('')
    // 顯示新增內容
    const [showAddReplyContent, setShowAddReplyContent] = useState(false)
    // pagination控制變數
    const [paginationProps, setPaginationProps] = useState({
        pageNum: 1,
        pageSize: 10,
        totalItemSize: 0,
        totalPageSize: 0,
    })

    /**
     * 回覆作業表格指定頁碼變動事件
     * @param page {Number} - 指定頁碼
     * @return {void}
     */
    const onPaginationPageOnChange = (page) => {
        setPaginationProps({
            ...paginationProps,
            pageNum: page
        });
        getResponse(page, paginationProps.pageSize);
    }

    /**
     * 回覆作業表格指定每頁筆數變動事件
     * @param e {Event} Event
     * @return {void}
     */
    const onPaginationPageSizeOnChange = (e) => {
        const newSize = e.target.value;
        setPaginationProps({
            ...paginationProps,
            pageSize: newSize
        });
        getResponse(paginationProps.pageNum, newSize);
    }

    /**
     * 回覆作業表格上一頁按鈕事件
     * @return {void}
     */
    const onPaginationPreviousOnClick = () => {
        const page = (paginationProps.pageNum - 1) > 1 ? (paginationProps.pageNum - 1) : 1;
        setPaginationProps({
            ...paginationProps,
            pageNum: page
        });
        getResponse(page, paginationProps.pageSize);
    }

    /**
     * 回覆作業表格下一頁按鈕事件
     * @return {void}
     * */
    const onPaginationNextOnClick = () => {
        const page = (paginationProps.pageNum + 1) < paginationProps.totalPageSize ? (paginationProps.pageNum + 1) : paginationProps.totalPageSize;
        setPaginationProps({
            ...paginationProps,
            pageNum: page
        });
        getResponse(page, paginationProps.pageSize);
    }

    /**
     * 控制回覆日期輸入框內的值變動事件
     * @param newDate {dayjs} - 選擇的新日期
     * @return {void}
     */
    const handleReplyDateOnChange = (newDate) =>
        setReplyContent({...replyContent, replyDate: newDate.format('YYYY-MM-DD')})

    /**
     * 控制Query輸入框內的值變動事件
     * @param event {Event}
     * @return {void}
     */
    const handleQueryInputOnChange = (event) => {
        if (event.target.value === ' ')
            setQueryString('');
        else
            setQueryString(event.target.value)
    }

    /**
     * 選擇狀態更改事件
     * @param event {Event} - React change event
     * @return {void}
     */
    const handleStatusOnChange = (event) =>
        setReplyContent({...replyContent, status: event.target.value})

    /**
     * 控制新增內容按鈕事件
     * @return {void}
     */
    const handleShowAddReplyContent = () => setShowAddReplyContent(!showAddReplyContent)

    /**
     * 儲存回覆內容
     * @param event {Object}
     * @return {void}
     */
    const handleContentOnChange = (event) =>
        setReplyContent({...replyContent, content: event.target.value})

    /**
     * 呼叫API儲存回覆內容
     * @return {void}
     */
    const handleContentSave = () => {
        // 檢查是否有空值
        if (stringIsEmpty(replyContent.cvisId) || stringIsEmpty(replyContent.status) || stringIsEmpty(replyContent.content)) {
            showToast({message: '請填寫完整資料', type: AlertTypeEnum.Error})
        } else { // 沒有空值時
            saveCvisResponse({
                cvisNotifyId: replyContent.cvisId,
                cvisProcessStatus: replyContent.status,
                cvisProcessContent: replyContent.content
            }).then((res) => {
                // 錯誤 / 訊息
                const {err, msg} = res

                // 成功取得
                if (err === ApiErrorStatusEnum.Success) {
                    getResponse(paginationProps.pageNum, paginationProps.pageSize)
                    showToast({message: '儲存成功', type: AlertTypeEnum.Success})
                } else { // 取得失敗
                    showToast({message: msg, type: AlertTypeEnum.Error})
                }
            })
        }
    }

    /**
     * 取得回覆紀錄清單
     * @param page {number} - 目前頁碼
     * @param pageSize {number} - 每頁筆數
     * @return {void}
     */
    const getResponse = (page, pageSize) => {
        queryCvisResponse({
            "cvisNotifyId": replyContent.cvisId,
            "pageNum": page,
            "pageSize": pageSize
        }).then((res => {
            // 錯誤 / 資料 / 訊息
            const {err, data, msg} = res

            if (err === ApiErrorStatusEnum.Success) {
                // 資料 / 總筆數 / 總頁數
                const {dataList, totalItemSize, totalPageSize} = data
                dataList.map((item) => item.doctor = doctor)
                // 設定回覆紀錄清單
                setReplyRecordList(dataList)
                // 設定過濾後的回覆紀錄清單
                setFilteredItems(dataList)
                // 設定分頁
                setPaginationProps({
                    ...paginationProps,
                    totalItemSize: totalItemSize,
                    totalPageSize: totalPageSize,
                    pageNum: page,
                    pageSize: pageSize
                })
            } else { // 取得失敗
                // 重置回覆紀錄清單
                setReplyRecordList([])
                // 重置過濾後的回覆紀錄清單
                setFilteredItems([])
                // 重置分頁
                setPaginationProps({
                    ...paginationProps,
                    totalItemSize: 0,
                    totalPageSize: 0,
                    pageNum: 1,
                    pageSize: 10
                })
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        }))
    }

    /**
     * 取得回覆紀錄清單 By ID
     * @return {void}
     */
    const getNotifyRec = () => {
        queryCvisNotifyrecById({
            "cvisNotifyId": cvisID
        }).then((res => {
            // 錯誤 / 資料 / 訊息
            const {err, data, msg} = res

            if (err === ApiErrorStatusEnum.Success) { // 取得成功
                setData(data)
            } else { // 取得失敗
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        }))
    }

    /**
     * 初始化後取得回覆紀錄清單
     * @return {void}
     */
    useEffect(() => {
        getNotifyRec()
        getResponse(paginationProps.pageNum, paginationProps.pageSize)
    }, [])

    useEffect(() => {
        if (queryString) {
            const result = fuzzySearchObjects(replyRecordList, queryString)
            setFilteredItems(result)
        } else {
            setFilteredItems(replyRecordList)
        }
    }, [queryString, replyRecordList])

    return (
        <Dialog
            open={true}
            title={t('CVIS.page.CrisisAlertReplyAssignmentPopup')}
            content={
                <>
                    {/* 主要內容 */}
                    <div className='w-full bg-white p-4 flex flex-col gap-4 overflow-x-scroll'>
                        {/* 通報內容 */}
                        <div className='w-full flex flex-row justify-between items-center'>
                            <TextField sx={{mr: 2}} inputWidth={SizeEnum.XLarge} label="通報內容"
                                       value={data.cvisNotifyContent}/>
                        </div>
                        {/* 病患資料 */}
                        <div
                            className='flex flex-col w-full min-w-[1556px] p-4 border-[1px] border-[#D4D4D8] rounded-[6px] gap-2 overflow-y-scroll'>
                            {/* 第一列 */}
                            <div className="grid grid-cols-6 gap-2 bg-[#F4F4F5] p-2">
                                {/* 病歷號 */}
                                <Field
                                    labelSx={{width: 'auto'}}
                                    sx={{display: 'flex', alignItems: 'start'}}
                                    label={t('general.patientNo')}>
                                    {data.patientId ? data.patientId : "無資料"}
                                </Field>
                                {/* 姓名 */}
                                <Field
                                    labelSx={{width: 'auto'}}
                                    sx={{display: 'flex', alignItems: 'start'}}
                                    label={t('general.username')}>
                                    {data.patientName ? data.patientName : "無資料"}
                                </Field>
                                {/* 電話 */}
                                <Field
                                    labelSx={{width: 'auto'}}
                                    sx={{display: 'flex', alignItems: 'start'}}
                                    label={t('general.telephone')}>
                                    {data.phone ? data.phone : ""}
                                </Field>
                                {/* 行動電話 */}
                                <Field
                                    labelSx={{width: 'auto'}}
                                    sx={{display: 'flex', alignItems: 'start'}}
                                    label="行動電話">
                                    {data.mobile ? data.mobile : ""}
                                </Field>
                                {/* 公司電話 */}
                                <Field
                                    labelSx={{width: 'auto'}}
                                    sx={{display: 'flex', alignItems: 'start'}}
                                    label="公司電話">
                                    {data.companyPhone ? data.companyPhone : ""}
                                </Field>
                                {/* 分機 */}
                                <Field
                                    labelSx={{width: 'auto'}}
                                    sx={{display: 'flex', alignItems: 'start'}}
                                    label="分機">
                                    {data.extension ? data.extension : ""}
                                </Field>
                            </div>
                            {/* 第二列 */}
                            <div className="grid grid-cols-6 gap-2 bg-[#F4F4F5] p-2">
                                {/* 聯絡人 */}
                                <Field
                                    labelSx={{width: 'auto'}}
                                    sx={{display: 'flex', alignItems: 'start'}}
                                    label="聯絡人">
                                    {data.contactPerson ? data.contactPerson : ""}
                                </Field>
                                {/* 電話 */}
                                <Field
                                    labelSx={{width: 'auto'}}
                                    sx={{display: 'flex', alignItems: 'start'}}
                                    label={t('general.telephone')}>
                                    {data.contactPhone ? data.contactPhone : ""}
                                </Field>
                                {/* 公司電話 */}
                                <Field
                                    labelSx={{width: 'auto'}}
                                    sx={{display: 'flex', alignItems: 'start'}}
                                    label="公司電話">
                                    {data.contactCompanyPhone ? data.contactCompanyPhone : ""}
                                </Field>
                                {/* 分機 */}
                                <Field
                                    labelSx={{width: 'auto'}}
                                    sx={{display: 'flex', alignItems: 'start'}}
                                    label="分機">
                                    {data.contactExtension ? data.contactExtension : ""}
                                </Field>
                            </div>
                        </div>
                        {/* 資料內容 */}
                        <div className="flex flex-row w-full gap-2">
                            {/* 回覆紀錄 */}
                            <div className="w-full flex flex-col justify-start items-center">
                                <Card title={"回覆紀錄"}>
                                    <div className="flex flex-row w-full">
                                        {/* 搜尋欄 */}
                                        <SearchTextField placeholder={t('general.advancedSearch')} value={queryString}
                                                         onChange={handleQueryInputOnChange}/>
                                    </div>
                                    {/* 清單表格 */}
                                    <div className='min-w-[649px] w-full'>
                                        <ul className='tableList crisisAlertReplyRecord w-full'>
                                            <li className='title'>
                                                {/*病歷號*/}
                                                <div>{t('general.patientNo')}</div>
                                                {/*姓名*/}
                                                <div>{t('general.username')}</div>
                                                <div>主治醫師</div>
                                                <div className="flex flex-col justify-start">
                                                    <p>回覆日期時間</p>
                                                </div>
                                                <div>回覆人員</div>
                                                <div>處理狀態</div>
                                                <div>處理說明</div>
                                            </li>
                                            {/* 回覆紀錄清單 */}
                                            {
                                                !objectIsEmpty(filteredItems) && filteredItems.map((item, index) =>
                                                    <CvisListItem key={index} index={index} item={item}
                                                                  type='AssignmentPopupReplyRecordList'/>
                                                )
                                            }
                                        </ul>
                                    </div>
                                    {/* 分頁 */}
                                    <Pagination
                                        totalPageSize={paginationProps.totalPageSize}
                                        pageSize={paginationProps.pageSize}
                                        totalSize={paginationProps.totalItemSize}
                                        currentPage={paginationProps.pageNum}
                                        onPageOnChange={onPaginationPageOnChange}
                                        onPageSizeChange={onPaginationPageSizeOnChange}
                                        onPrevPageOnClick={onPaginationPreviousOnClick}
                                        onNextPageOnClick={onPaginationNextOnClick}/>
                                </Card>
                            </div>
                            {/* 新增內容 */}
                            <div
                                className='flex flex-col w-full min-w-[600px] h-[664px] border-[1px] border-[#D4D4D8] rounded-[6px] px-4 justify-between'>
                                <div className="w-full">
                                    {/* 新增內容按鈕 */}
                                    <div className="w-full h-[60px] py-[10px]">
                                        <Button sx={{display: 'flex', justifyContent: 'center'}}
                                                color={ButtonColorEnum.Success} variant={ButtonVariantEnum.Text}
                                                size={ButtonSizeEnum.Large} text="新增內容" icon={IconEnum.Add}
                                                onClick={handleShowAddReplyContent}/>
                                    </div>
                                    {/* 回覆內容 */}
                                    {
                                        showAddReplyContent && (
                                            <div className="w-2/3">
                                                {/* 回覆日期及回覆人員 */}
                                                <div className="flex flex-row items-center ">
                                                    {/* 回覆日期 */}
                                                    <div className="flex flex-row justify-end items-center">
                                                        <Field label={"回覆日期時間"}>
                                                            <DatePicker
                                                                label=""
                                                                value={dayjs(replyContent.replyDate)}
                                                                onChange={handleReplyDateOnChange}/>
                                                        </Field>
                                                    </div>
                                                </div>
                                                {/* 回覆人員 */}
                                                <div className="flex items-center mt-4">
                                                    <Field label={"回覆人員"}>
                                                        <TextField value={doctor}/>
                                                    </Field>
                                                </div>
                                                {/* 處理狀態 */}
                                                <div className="flex items-center mt-4">
                                                    <Field label={"處理狀態"}
                                                           sx={{display: 'flex', alignItems: 'start'}}>
                                                        <RadioGroup
                                                            value={replyContent.status}
                                                            optionProps={{
                                                                options: radioOptions.map(option => ({
                                                                    label: option.label,  // 顯示在 radio 的標籤
                                                                    value: option.value   // 對應的值
                                                                }))
                                                            }}
                                                            size={SizeEnum.Small}
                                                            onChange={handleStatusOnChange}
                                                        />
                                                    </Field>
                                                </div>
                                                {/* 說明 */}
                                                <div className="flex flex-row items-center mt-4 ">
                                                    <Field label={"處理說明"}
                                                           sx={{display: 'flex', alignItems: 'start'}}>
                                                        <TextArea
                                                            inputSize={SizeEnum.Fill}
                                                            value={replyContent.content}
                                                            onChange={handleContentOnChange}
                                                            placeholder="請輸入說明"/>
                                                    </Field>
                                                </div>
                                                {/* 儲存按鈕 */}
                                                <div className="w-full flex justify-end pt-5 ">
                                                    <Button color={ButtonColorEnum.Primary}
                                                            variant={ButtonVariantEnum.Contained}
                                                            size={ButtonSizeEnum.Medium} onClick={handleContentSave}
                                                            text={t('general.saveFile')}/>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
            variant={DialogVariant.CONFIRM}
            paperStyleProps={{width: DialogSizeEnums.XL}}
            onClose={handlePopupClose}
            muiDialogActionsProps={{sx: {display: 'none'}}}
            muiDialogContentProps={{dividers: true}}
        />
    )
}

export default CrisisAlertReplyAssignmentPopup
