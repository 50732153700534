import {BasePopup} from "../../Popup/BasePopup"
import React, {useEffect, useState} from "react"
import {t} from "i18next"

/**
 * 通知人員維護刪除彈窗
 * @param {object} info 資訊
 * @param {boolean} show 是否顯示
 * @param onClosePopupButtonClick {function} 關閉彈窗時的 callback
 * @return {JSX.Element}
 */
export const DeleteNotifyMaintainerPopup = ({info, show, onClosePopupButtonClick}) => {
    // 是否顯示彈窗
    const [isShow, setIsShow] = useState(show)

    /**
     * 點擊Yes按鈕
     * @return {void}
     */
    const handleYesButtonOnClick = () => {

    }

    /**
     * 點擊No按鈕
     * @return {void}
     */
    const handleNoButtonOnClick = () => {
        // 關閉彈窗
        setIsShow(false)
        // 關閉彈窗時的 callback
        onClosePopupButtonClick()
    }

    /**
     * 取得Content
     * @return {JSX.Element}
     */
    const getContent = () => <div>
        <p className="h-[110px] px-6 py-9 text-[18px] text-left border-b-[1px] border-[rgba(0,0,0,0.15)]">是否確定刪除？</p>
        <div className="h-[60px] flex flex-row items-center justify-end">
            <button
                className="flex items-center justify-center h-10 px-4 mr-4 border-[2px] bg-[#2B6CB0] text-white border-[#2B6CB0] rounded-[6px]"
                onClick={handleYesButtonOnClick}>
                {t('general.yes')}
            </button>
            <button
                className="flex items-center justify-center h-10 px-4 mr-4 border-[2px] bg-white text-[#2B6CB0] border-[#2B6CB0] rounded-[6px]"
                onClick={handleNoButtonOnClick}>
                {t('general.no')}
            </button>
        </div>
    </div>

    /**
     * show 改變時，更新 isShow
     */
    useEffect(() => setIsShow(show), [show])

    return isShow ? <BasePopup title="提醒" width="559px" content={getContent()}
                               closePopupButtonOnClick={onClosePopupButtonClick}/> : null
}
