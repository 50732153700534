import React, { Fragment, useEffect, useRef, useState } from "react";
import { ReactComponent as BellIcon } from "../../assets/images/icons/default_bell.svg";
import NotificationMedicationInstructionsImage from "../../assets/images/icons/notification_medication_instructions.svg";
import NotificationChangeDrugDisposition from "../../assets/images/icons/notification_change_drug_disposition.svg";
import { ReactComponent as NotificationImportant } from "../../assets/images/icons/notification_important.svg";
import Card from "./Card";
import { ColorEnum, Badge } from "edah-component/dist";

const UnreadCountTip = ({ data }) => {
  const unreadCount = data.filter((notification) => !notification.read).length;

  return unreadCount > 0 ? (
    <Badge sx={{zIndex: 0}} badgeContent={unreadCount} color={ColorEnum.Red} />
  ) : null;
};

const GetNotificationTypeImage = (type) => {
  switch (type) {
    case 0:
      return NotificationMedicationInstructionsImage;
      break;
    case 1:
      return NotificationChangeDrugDisposition;
      break;
    case 2:
      return NotificationImportant;
      break;
  }

  return null;
};

const NotificationsMenu = ({
  data,
  setData,
  openNotifitionItemModal,
  openViewMoreModal,
  submittingNotificationStatus,
  type,
}) => {
  //是否顯示
  const [show, setShow] = useState(false);
  //監聽是否點在notification控件上
  const containerRef = useRef(null);

  /* 監聽mouse down不是點在notification控件上就hidden */
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  /**
   * 點擊see all
   */
  const handleSeeAll = () => {
    console.log("click see all");

    setShow((prev) => false);

    openViewMoreModal();
  };

  /**
   * 點擊全部標記為已讀
   */
  const handleMarkAllAsRead = () => {
    const updatedData = data.map((item) =>
      item.read !== true ? { ...item, read: true } : item
    );
    submittingNotificationStatus.current = true;

    setData((prev) => updatedData);
  };

  const handleCardClick = (clickedItem) => {
    // 在這裡更新特定object的某個值(read state)
    const updatedData = data.map((item) =>
      item === clickedItem ? { ...item, read: true } : item
    );

    submittingNotificationStatus.current = true;

    setData((prev) => updatedData);

    setShow((prev) => false);

    //開啟dialog
    openNotifitionItemModal(
      clickedItem.message,
      clickedItem.detail,
      clickedItem.receivedTime
    );
  };

  return (
    <div className="flex items-center justify-center w-22" ref={containerRef}>
      <div
        className="flex items-center justify-center w-full"
        onClick={() => setShow((prevShow) => !prevShow)}
      >
        {type === "important" ? (
          <div className="flex items-center justify-center w-full h-10">
            <NotificationImportant className="w-5 h-5 mr-1" />
            <div className=" w-9 text-sm text=[#404040] text-white mr-1">
              重要
            </div>
            {/* 未讀數量 */}
            <UnreadCountTip data={data} />
          </div>
        ) : (
          <div className="flex items-center justify-center h-10">
            <BellIcon className="fill-white w-5 h-5" />
            <div className="w-9 text-sm text=[#404040] text-white mr-1">
              通知
            </div>
            {/* 未讀數量 */}
            <UnreadCountTip data={data} />
          </div>
        )}
      </div>

      {/* notification container */}
      <div
        className={`rounded-t-[10px] w-[340px] shadow-[0_3px_8px_rgba(0,0,0,0.25)] bg-[#d9d9d9] absolute top-14 z-20 ${
          show ? "visible" : "hidden"
        }`}
      >
        {/* notification header */}
        <div className="flex justify-between m-[10px] items-center">
          <div className="font-bold text-[22px] text-[#404040] ml-[10px]">
            通知
          </div>
          <div
            className="text-[15px] text-[#404040] cursor-pointer"
            onClick={handleMarkAllAsRead}
          >
            全部標記為已讀
          </div>
        </div>

        {/* notification data items*/}
        <div className="h-[300px] overflow-y-auto">
          <Fragment>
            {data.slice(0, 10).map((item, index) => {
              return (
                <Card
                  key={index}
                  data={item}
                  img={GetNotificationTypeImage(item.message_type)}
                  dataClick={handleCardClick}
                />
              );
            })}
          </Fragment>
        </div>

        {/* view all */}
        <div className="text-center p-[5px]">
          <button
            className="font-bold text-[#545454] cursor-pointer"
            onClick={handleSeeAll}
          >
            view more
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotificationsMenu;
