import React, {useEffect, useMemo, useState} from 'react'
import {TRAN_FOREIGN_LIST_FIELDS} from '../../constants/referralSystem/disabilityCertificateList'
import CustomTable from '../../components/ReferralSystem/CustomTable'
import {Pagination} from '../../components/Pagination/Pagination'
import CertificateEditorModal from '../../components/ReferralSystem/DisabilityCertificate/CertificateEditorModal'
import {
    ApiErrorStatusEnum,
    arrayIsEmpty,
    downloadFile,
    exportExcelAsBlob,
    FileExtensionsEnum,
    validDateFormat,
} from 'edah_utils/dist'
import {t} from 'i18next'
import useToast from '../../hooks/useToast'
import {queryTranForeignBySearch, trancaseQueryBasicData} from '../../api/v1/Rmis'
import {completePrintMode, updatePrintContent,} from '../../redux/Slice/printSlice'
import {FORM_TYPE} from '../../constants/referralSystem/print'
import store from '../../redux/store'
import {useSelector} from 'react-redux'
import {addTab} from '../../redux/Slice/TabSlice'
import {LONG_TERM_CARE_CENTER_NO} from '../../constants/menuFuncNo'
import {
    AlertTypeEnum,
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    Checkbox,
    DateRangePicker,
    IconEnum,
    SizeEnum,
    TextField
} from "edah-component/dist";
import dayjs from "dayjs"

/**
 * 病症暨失能診斷證明書
 * @returns {JSX.Element}
 */
const DisabilityCertificateList = () => {
    /**
     * 病症暨失能診斷證明書清單
     */
    const [dataList, setDataList] = useState([])

    /**
     * 日期檢核
     */
    const [isDateRangeValid, setIsDateRangeValid] = useState(true)

    /**
     * 顯示編輯視窗
     */
    const [isShowCertificateEditorModal, setIsShowCertificateEditorModal] =
        useState(false)

    /**
     * 編輯單筆資料
     */
    const [editRow, setEditRow] = useState(null)

    /**
     * pagination
     */
    const [filter, setFilter] = useState({
        totalPageSize: 1,
        totalItemSize: 0,
        pageNum: 1,
        pageSize: 10,
    })

    /**
     * 查詢條件
     */
    const [searchParams, setSearchParams] = useState({
        // 查詢開始日期
        tranOutStartDate: '',
        // 查詢結束日期
        tranOutEndDate: '',
        // 病歷號/身分證號
        patientId: '',
    })

    /**
     * 轉診基礎資料
     */
    const [baseData, setBaseData] = useState({
        divList: [], // 科別
        doctorList: [] // 醫師
    })

    // toast message
    const showToast = useToast()

    /**
     * 列印模式
     */
    const isPrintMode = useSelector((state) => state.print.isPrintMode)

    /**
     * 編輯 or 新增內容
     */
    const [isEdit, setIsEdit] = useState(false)

    /**
     * 更新搜尋條件
     * @param e {Event} 事件
     * @param field {String} 欄位
     * @return {void}
     */
    const updateSearchParams = (e, field) => {
        const value = e.target.value
        setSearchParams({
            ...searchParams,
            [field]: value,
        })
    }

    /**
     * 查詢日期範圍變動事件
     * @param newDates {Array<Dayjs | null>} 日期範圍 [開始日期, 結束日期]
     * @return {void}
     */
    const handleDateRangeChange = (newDates) => {
        const [newStartDate, newEndDate] = newDates;
        setSearchParams({
            ...searchParams,
            tranOutStartDate: newStartDate ? newStartDate.format('YYYY-MM-DD') : '',
            tranOutEndDate: newEndDate ? newEndDate.format('YYYY-MM-DD') : '',
        })
    }

    /**
     * 編輯按鈕
     * @param referralCase {object} 要編輯的單筆資料
     * @returns {JSX.Element}
     */
    const Editor = (referralCase) => (
        <Button
            icon={IconEnum.Edit}
            text={t('general.edit')}
            variant={ButtonVariantEnum.Text}
            color={ButtonColorEnum.Primary}
            size={ButtonSizeEnum.Medium}
            onClick={() => {
                handleEditButtonOnClick(referralCase)
            }}
        />
    )

    /**
     * 匯出Excel
     * @return {void}
     */
    const exportExcel = () => {
        // 已勾選的資料
        const selectedList = dataList.filter((item) => item.isSelected)
        if (selectedList.length === 0) {
            return
        }
        // 匯出資料陣列
        const dataArray = []
        // 匯出欄位，不包含[編輯]欄位
        const fields = TRAN_FOREIGN_LIST_FIELDS.slice(1)

        selectedList.forEach((item) => {
            const data = []
            // 遍歷匯出欄位，並將資料轉換資料格式
            fields.forEach((field) => {
                const value = field.format
                    ? field.format(item[field.key])
                    : item[field.key]
                // 將匯出資料推入data陣列
                data.push(value)
            })
            // 將匯出資料推入dataArray陣列
            dataArray.push(data)
        })
        // 匯出資料
        const sheets = [
            {
                titles: fields.map((item) => item.name),
                data: dataArray,
            },
        ]
        // 下載匯出檔案
        downloadFile(
            exportExcelAsBlob(sheets),
            '病症暨失能診斷證明書_清單',
            FileExtensionsEnum.XLSX
        )
    }

    /**
     * 匯出Excel按鈕
     * @return {JSX.Element}
     */
    const ExportExcelBtn = () => {
        return (
            <Button
                icon={IconEnum.Export}
                text={t('general.export')}
                variant={ButtonVariantEnum.Text}
                color={ButtonColorEnum.Success}
                size={ButtonSizeEnum.Large}
                onClick={exportExcel}
            />
        )
    }

    /**
     * 病症暨失能診斷證明書清單 table 顯示欄位
     * 編輯、勾選、巴氏量表、CDR 欄位渲染相對應的元件
     * @return {void}
     */
    const renderFields = useMemo(() => {
        // 表格欄位
        const fields = TRAN_FOREIGN_LIST_FIELDS
        // 渲染表格欄位
        return fields.map((item) => {
            switch (item.key) {
                // 編輯
                case 'edit':
                    return {
                        ...item,
                        render: Editor,
                    }
                // 巴氏量表
                case 'barthelFlag':
                    return {
                        ...item,
                        render: (referralCase) =>
                            <Checkbox
                                checked={!!referralCase.barthelFlag}
                                readOnly
                            />,
                    }
                // CDR
                case 'cdrFlag':
                    return {
                        ...item,
                        render: (referralCase) =>
                            <Checkbox
                                checked={!!referralCase.cdrFlag}
                                readOnly
                            />,
                    }
                // 預設
                default:
                    return item
            }
        })
    })

    /**
     * 查詢病症暨失能診斷證明書清單
     * @return {void}
     */
    const queryDisabilityCertificateList = async (pageNum, pageSize) => {
        // 檢核查詢日期格式
        const tranOutStartDateIsValid = validDateFormat(
            searchParams.tranOutStartDate
        )
        const tranOutEndDateIsValid = validDateFormat(
            searchParams.tranOutEndDate
        )
        // 設定日期檢核結果
        setIsDateRangeValid(tranOutStartDateIsValid && tranOutEndDateIsValid)
        // 日期檢核不通過則不查詢
        if (!tranOutStartDateIsValid || !tranOutEndDateIsValid) {
            return
        }
        // 查詢病症暨失能診斷證明書清單
        const result = await queryTranForeignBySearch({
            applicantStartDate: `${searchParams.tranOutStartDate} 00:00:00`,
            applicantEndDate: `${searchParams.tranOutEndDate} 00:00:00`,
            search: searchParams.patientId,
            pageNum: pageNum,
            pageSize: pageSize,
        })
        // 查詢成功
        if (result.err === ApiErrorStatusEnum.Success) {
            const list = result?.data?.dataList || []
            if (arrayIsEmpty(list.length)) {
                showToast({
                    message: '查無資料',
                    type: AlertTypeEnum.Warning,
                })
            }
            // 預設資料尚未勾選
            const selectableList = result?.data?.dataList?.map((item) => ({
                ...item,
                isSelected: false,
                applyName: item.patientName,
            }))
            setDataList(selectableList)
            setFilter({
                ...filter,
                totalPageSize: result?.data?.totalPageSize,
                totalItemSize: result?.data?.totalItemSize,
                pageNum,
                pageSize,
            })
        } else {
            // 查詢失敗
            showToast({
                message: result.msg,
                type: AlertTypeEnum.Error,
            })
        }
    }

    /**
     * 新增內容
     * 開啟新增病症暨失能診斷證明書 modal
     * @return {void}
     */
    const handleAddButtonOnClick = () => {
        setIsShowCertificateEditorModal(true)
        setIsEdit(false)
    }

    /**
     * 編輯
     * 開啟回覆個管作業_維護modal
     * @param {object} referralCase 點選編輯的單筆資料
     */
    const handleEditButtonOnClick = (referralCase) => {
        setIsShowCertificateEditorModal(true)
        setIsEdit(true)
        setEditRow(referralCase)
    }


    /**
     * 查詢病症暨失能診斷證明書清單
     * @return {void}
     */
    const handleQueryOnClick = () => {
        queryDisabilityCertificateList(1, filter.pageSize)
    }

    /**
     * 點選 pagination 頁碼
     * @param {string} page 目前頁碼
     * @return {void}
     */
    const onPageOnChange = (page) => {
        const currentPage = Number(page)
        queryDisabilityCertificateList(currentPage, filter.pageSize)
    }

    /**
     * 變更每頁筆數
     * @param {object} event
     * @return {void}
     */
    const onPageSizeChange = (event) => {
        const pageSize = Number(event.target.value)
        queryDisabilityCertificateList(1, pageSize)
    }

    /**
     * 點選上一頁
     * @return {void}
     */
    const onPrevPageOnClick = () => {
        const prevPage = filter.pageNum - 1
        const page = prevPage < 1 ? filter.totalPageSize : prevPage
        queryDisabilityCertificateList(page, filter.pageSize)
    }

    /**
     * 點選下一頁
     * @return {void}
     */
    const onNextPageOnClick = () => {
        const nextPage = filter.pageNum + 1
        const firstPage = 1
        // 下一頁超出總頁數則跳至第一頁
        const page = nextPage > filter.totalPageSize ? firstPage : nextPage
        queryDisabilityCertificateList(page, filter.pageSize)
    }

    /**
     * 全選資料
     * @param isAllSelected {boolean} 是否全選
     * @return {void}
     */
    const toggleAll = (isAllSelected) => {
        const modifiedList = dataList.map((item) => ({
            ...item,
            isSelected: isAllSelected,
        }))
        setDataList(modifiedList)
    }

    /**
     * 選擇單筆資料
     * @param isChecked {boolean} 是否勾選
     * @param selectedItem {object} 勾選的資料
     * @param index {number} 勾選項目的index
     * @return {void}
     */
    const toggleItem = (isChecked, selectedItem, index) => {
        const modifiedList = dataList.map((item, itemIndex) => {
            if (index === itemIndex) {
                return {
                    ...item,
                    isSelected: isChecked,
                }
            }
            return item
        })
        setDataList(modifiedList)
    }

    /**
     * 列印申請人信封
     * @return {void}
     */
    const handlePrintEnvelope = () => {
        const printData = dataList.filter((item) => item.isSelected)
        // 更新列印資料
        store.dispatch(
            updatePrintContent({
                // 列印類型
                reportType: FORM_TYPE.applyEnvelope,
                // 列印資料
                printData,
            })
        )
    }

    /**
     * 列印長照信封
     * @return {void}
     */
    const handlePrintLongTermCareEnvelope = () => {
        const printData = dataList.filter((item) => item.isSelected)
        // 更新列印資料
        store.dispatch(
            updatePrintContent({
                // 列印類型
                reportType: FORM_TYPE.longTermCareEnvelope,
                // 列印資料
                printData,
            })
        )
    }

    /**
     * 關閉彈窗
     * @param isNeedQuery {boolean} 是否需要重新查詢取得清單
     * @return {void}
    */
    const handleClose = (isNeedQuery) => {
        setIsShowCertificateEditorModal(false)
        if (isNeedQuery) {
            handleQueryOnClick()
        }
    }

    /**
     * 開啟長照中心維護 tab
     * @return {void}
     */
    const handleOpenTab = () => {
        store.dispatch(
            addTab({id: LONG_TERM_CARE_CENTER_NO, name: '長照中心維護'})
        )
    }

    /**
     * 開啟列印模式則開啟瀏覽器列印視窗
     * 結束列印則關閉列印模式
     */
    useEffect(() => {
        if (isPrintMode) {
            // 開始瀏覽器列印視窗
            window.print()
            // 結束列印
            store.dispatch(completePrintMode())
        }
    }, [isPrintMode])

    /**
     * 取得轉診基礎資料
     * @return {void}
     */
    const getTranCaseQueryBasicData = () => {
        trancaseQueryBasicData({}).then((res) => {
            // 取得成功
            if (res.err === ApiErrorStatusEnum.Success) {
                setBaseData({
                    divList: res.data.divList,
                    doctorList: res.data.doctorList
                })
            } else {
                showToast({message: res.msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 取得轉診基礎資料
     */
    useEffect(() => {
        getTranCaseQueryBasicData()
    }, [])

    return (
        <div className="p-2 w-full h-[calc(100vh-98px)] overflow-auto">
            <div className="flex justify-between">
                <div className="flex flex-wrap p-2 space-x-2">
                    <div className="date flex flex-row items-center justify-start">
                        {/* 查詢日期起訖 */}
                        <DateRangePicker
                            required
                            localeText={{
                                start: '查詢開始日期',
                                end: '查詢結束日期',
                            }}
                            value={[
                                searchParams.tranOutStartDate ? dayjs(searchParams.tranOutStartDate) : null,
                                searchParams.tranOutEndDate ? dayjs(searchParams.tranOutEndDate) : null,
                            ]}
                            onChange={handleDateRangeChange}
                            error={!isDateRangeValid}
                        />
                    </div>
                    {/* 病歷號/身分證號 */}
                    <div className="flex flex-row items-center justify-start">
                        <TextField
                            label="病歷號/身分證號"
                            value={searchParams.patientId}
                            onChange={(e) => updateSearchParams(e, 'patientId')}
                        />
                    </div>
                    {/* 查詢按鈕 */}
                    <Button
                        sx={{fontWeight: 'bold', height: '2.5rem'}}
                        text={t('general.query')}
                        color={ButtonColorEnum.Primary}
                        variant={ButtonVariantEnum.Contained}
                        size={ButtonSizeEnum.Medium}
                        onClick={handleQueryOnClick}
                    />
                </div>
                <div className="flex flex-wrap items-center justify-between p-2">
                    <div className="flex flex-wrap gap-2">
                        {/* 長照中心維護 */}
                        <Button
                            sx={{fontWeight: 'bold', height: '2.5rem'}}
                            text='長照中心維護'
                            color={ButtonColorEnum.Secondary}
                            variant={ButtonVariantEnum.Outlined}
                            size={ButtonSizeEnum.Medium}
                            onClick={handleOpenTab}
                        />
                        {/* 申請人信封 */}
                        <Button
                            sx={{fontWeight: 'bold', height: '2.5rem'}}
                            text='申請人信封'
                            color={ButtonColorEnum.Secondary}
                            variant={ButtonVariantEnum.Outlined}
                            size={ButtonSizeEnum.Medium}
                            onClick={() => handlePrintEnvelope()}
                        />
                        {/* 長照信封 */}
                        <Button
                            sx={{fontWeight: 'bold', height: '2.5rem'}}
                            text='長照信封'
                            color={ButtonColorEnum.Secondary}
                            variant={ButtonVariantEnum.Outlined}
                            size={ButtonSizeEnum.Medium}
                            onClick={() => handlePrintLongTermCareEnvelope()}
                        />
                    </div>
                </div>
            </div>
            {/* 新增內容按鈕 */}
            <Button
                sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '3px'}}
                icon={IconEnum.Add}
                text='新增內容'
                color={ButtonColorEnum.Success}
                variant={ButtonVariantEnum.Text}
                size={ButtonSizeEnum.Large}
                onClick={handleAddButtonOnClick}
            />
            <CustomTable
                isSelectable={true}
                fields={renderFields}
                dataList={dataList}
                toggleAll={toggleAll}
                toggleItem={toggleItem}
                slotButton={ExportExcelBtn()}
            />
            <div className="flex justify-end mt-2">
                <Pagination
                    pageSize={filter.pageSize}
                    totalSize={filter.totalItemSize}
                    currentPage={filter.pageNum}
                    totalPageSize={filter.totalPageSize}
                    onPageOnChange={onPageOnChange}
                    onPageSizeChange={onPageSizeChange}
                    onPrevPageOnClick={onPrevPageOnClick}
                    onNextPageOnClick={onNextPageOnClick}
                />
            </div>
            {/* 病症暨失能診斷證明書編輯 modal */}
            {isShowCertificateEditorModal && (
                <CertificateEditorModal
                    onClose={handleClose}
                    isEdit={isEdit}
                    applicantNo={editRow?.applicantNo}
                    doctorList={baseData.doctorList}
                    divList={baseData.divList}
                />
            )}
        </div>
    )
}
export default DisabilityCertificateList
