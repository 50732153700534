import { useSelector } from 'react-redux'
import './print.scss'

/**
 * 出院病摘 - 信封列印
 * @returns {JSX.Element}
 */
const PrintDischargeSummaryEnvelope = () => {
    const printData = useSelector((state) => state.print.printData)
    return (
        <>
            {printData?.map((item, index) => {
                return (
                    <div key={index} className="envelope-cover-basic break-after">
                        <div className="border-[#D4D4D8] border p-8 rounded-md">
                            <div className="border-b border-[#111111]">
                                <div className='ml-3'>{item?.tranInHospName} 收</div>
                            </div>
                            <div>
                                <div>{item?.tranInHospZipCode}</div>
                                <div>{item?.tranInHospAddress}</div>
                            </div>
                        </div>
                    </div>
                )
            })
            }
        </>

    )
}

export default PrintDischargeSummaryEnvelope
