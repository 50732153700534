import React, {useEffect, useMemo, useState} from 'react'
import ChangeRecordQuery from './QueryChangeRecord';
import SupportDateChoice from "./SupportDateChoice";
import {USER_ROLE} from "../userRole";
import {
    getLocalStorage,
    multiTermFuzzySearchObjects,
    objectIsEmpty,
    RsisStepStatusEnum, stringIsEmpty,
    time2String,
    ApiErrorStatusEnum,
} from 'edah_utils/dist';
import {usePopup} from '../PopupProvider';
import {t} from 'i18next'

// import API
import {
    rsisEditMaster,
    rsisQueryCodeValue,
    rsisQueryAgentByUserNo, rsisQueryBaseData, rsisMailConfirm
} from '../../../api/v1/RSIS';

import QueryPersonList from "./QueryPersonList";
import QuerySupportOrg from "./QuerySupportOrg";
import {checkDoctorLicence} from "../../../api/v1/Menu";
import {
    Checkbox,
    Divider,
    Field,
    SizeEnum,
    TextField,
    Search,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    Card,
    Button,
    Dialog,
    DialogVariant,
    AlertTypeEnum,
    Select,
    SelectWidthEnum
} from "edah-component/dist";

/**
 * 報備支援收案確認Popup
 * @param {string} type 彈窗類型
 * @param {object} data 報備支援資料
 * @param {array} doctorList 醫師清單
 * @param {array} orgList 機構清單
 * @param {array} deptList 承辦部門清單
 * @param {function} handleOnUpdate 更新列表事件callback
 * @param {function} handleOnClose 關閉彈窗事件callback
 * @return {JSX.Element}
 */
const EditModal = ({
                       type = 'EDIT',
                       data,
                       doctorList,
                       orgList,
                       deptList,
                       handleOnUpdate,
                       handleOnClose
                   }) => {
    // 角色
    const [userRole, setUserRole] = useState(data.role)
    // 批次處理資料清單
    const [dataList, setDataList] = useState([])
    // 代理人下拉清單
    const [agentUserList, setAgentUserList] = useState([])
    // 所有科別清單
    const [allDivisionList, setAllDivisionList] = useState([])
    // 支援目的選項清單
    const [purposeOptions, setPurposeOptions] = useState(data.purposeCode ? [data.purposeCode.split(' ').filter(Boolean)] : [])
    // 子支援目的選項清單
    const [subPurposeOptions, setSubPurposeOptions] = useState([])
    // 性質清單
    const [kindList, setKindList] = useState([])

    /** 支援人員欄位 */
        // 員工/醫師
    const [supportUserNo, setSupportUserNo] = useState(data.supportUserNo ? data.supportUserNo : '')
    // 支援人員名稱
    const [supportUserName, setSupportUserName] = useState(data.supportUserName ? data.supportUserName : '')
    // 執登科別編號
    const [divNo, setDivNo] = useState(data.divNo)
    // 執登科別名稱
    const [divName, setDivName] = useState(data.divName)
    // 證書字號
    const [licenceId, setLicenceId] = useState(data.licenceId)
    // 執登機構編號
    const [licenceKind, setLicenceKind] = useState(data.licenceKind)
    // 執登機構名稱
    const [licenceKindName, setLicenceKindName] = useState(data.licenceKindName)
    // 值類別
    const [hospNo, setHospNo] = useState(data.hospNo)
    // 值類別名稱
    const [hospName, setHospName] = useState(data.hospName)


    /** 報備支援內容欄位 */
        // 星期幾
    const [day, setDay] = useState(data.day ? data.day : '')
    // 支援機構Pkey
    const [supportOrgPkey, setSupportOrgPkey] = useState(data.supportOrgPkey ? data.supportOrgPkey : '')
    // 支援機構字串
    const [supportOrgStr, setSupportOrgStr] = useState('')
    // 機構代碼
    const [supportOrgNo, setSupportOrgNo] = useState(data.supportOrgNo ? data.supportOrgNo : data.supportOrgNo)
    // 機構名稱
    const [supportOrgName, setSupportOrgName] = useState(data.supportOrgName ? data.supportOrgName : '')
    // 申請單號
    const [supportNo, setSupportNo] = useState(data.supportNo ? data.supportNo : '')
    // 支援科別字串
    const [supportDivStr, setSupportDivStr] = useState('')
    // 支援科別
    const [supportDivNo, setSupportDivNo] = useState(data.supportDivNo ? data.supportDivNo : '')
    // 支援科別名稱
    const [supportDivName, setSupportDivName] = useState(data.supportDivName ? data.supportDivName : '')
    // 支援目的
    const [purposeCode, setPurposeCode] = useState(data.purposeCode ? data.purposeCode.split(',').filter(Boolean) : []);
    // 支援目的門住
    const [purposeCodeExt, setPurposeCodeExt] = useState(!stringIsEmpty(data.purposeCodeExt) ? data.purposeCodeExt : '1')
    // 承辦部門字串
    const [ownerDeptStr, setOwnerDeptStr] = useState('')
    // 承辦部門
    const [ownerDeptNo, setOwnerDeptNo] = useState(data.ownerDeptNo ? data.ownerDeptNo : '')
    // 承辦部門名稱
    const [ownerDeptName, setOwnerDeptName] = useState(data.ownerDeptName ? data.ownerDeptName : '')
    // 代理人員字串
    const [agentUserStr, setAgentUserStr] = useState('')
    // 代理人員代號
    const [agentUserNo, setAgentUserNo] = useState(data.agentUserNo ? data.agentUserNo : '')
    // 代理人員名稱
    const [agentUserName, setAgentUserName] = useState(data.agentUserName ? data.agentUserName : '')
    // 性質
    const [supportProperties, setSupportProperties] = useState(data.supportProperties ? data.supportProperties : '')
    // 執行內容
    const [executeContent, setExecuteContent] = useState(data.executeContent ? data.executeContent : '')
    // 備註
    const [memo, setMemo] = useState(data.memo ? data.memo : '')
    // 支援起始日期
    const [itemStartDate, setItemStartDate] = useState(data.itemStartDate ? data.itemStartDate : '')
    // 支援起始時間
    const [itemStartTime, setItemStartTime] = useState(data.itemStartTime ? data.itemStartTime : '')
    // 支援結束日期
    const [itemEndDate, setItemEndDate] = useState(data.itemEndDate ? data.itemEndDate : '')
    // 支援結束時間
    const [itemEndTime, setItemEndTime] = useState(data.itemEndTime ? data.itemEndTime : '')
    // 彈窗
    // 顯示醫師人員搜尋彈窗
    const [showDoctorPopup, setShowDoctorPopup] = useState(false)
    // 顯示支援機構彈窗
    const [showOrgDropdown, setShowOrgDropdown] = useState(false)
    // 是否為承辦人
    const isOwnUser = JSON.parse(getLocalStorage('isOwnUser')) || false

    // 使用上下文
    const {
        showToast,
        // 變更紀錄彈窗
        showChangeRecordModal,
        setShowChangeRecordModal,
        // 支援日期期間選擇彈窗
        setShowSupportDateChoice,
        showSupportDateChoice,
    } = usePopup()

    /**
     * 支援科別變更時
     * @param item {string} 支援科別
     * @return {void}
     */
    const handleSupportDivNoChange = (item) => {
        setSupportDivStr(item)
        setSupportDivNo(item.split(' ')[0])
        setSupportDivName(item.split(' ')[1])
    }

    /**
     * 承辦部門變更時
     * @param item {string} 承辦部門
     * @return {void}
     */
    const handleOwnerDeptNoChange = (item) => {
        setOwnerDeptStr(item)
        setOwnerDeptNo(item.split(' ')[0])
        setOwnerDeptName(item.split(' ')[1])
    }

    /**
     * 代理人員變更時
     * @param item {string} 代理人員
     * @return {void}
     */
    const handleAgentUserNoChange = (item) => {
        setAgentUserStr(item)
        setAgentUserNo(item.split(' ')[0])
        setAgentUserName(item.split(' ')[1])
    }

    /**
     * 新增報備支援內容
     * @return {void}
     */
    const handleFormSave = () => {
        // 當支援明細資料為空時，顯示錯誤訊息
        if (dataList.length === 0) {
            showToast({message: '請增加支援期間時間', type: AlertTypeEnum.Warning})
            return
        }

        // 檢查支援機構是否有值
        if (!supportOrgNo) {
            showToast({
                message: `支援者：${supportUserName}，「支援機構」為必填欄位，請重新確認！`,
                type: AlertTypeEnum.Warning
            })
            return
        }

        // 檢查支援科別是否有值
        if (!supportDivNo) {
            showToast({
                message: `支援者：${supportUserName}，「支援科別」為必填欄位，請重新確認！`,
                type: AlertTypeEnum.Warning
            })
            return
        }

        // 取得送出資料
        const data = getSendData()
        // 執行編輯報備支援內容
        saveEditMaster(data)
    }

    /**
     * 更新列表項目
     * @param data {Object} 待更新資料
     * @return {void}
     */
    const handleUpdateDataList = (data) => setDataList(data)

    /**
     * 選取員工/醫師代碼選項時
     * @param item {Object} 選取的選項
     * @return {void}
     */
    const handleDoctorOptionOnClick = (item) => {
        // 取得醫師清單
        getDoctorData(item.userNo)
        // 取得代理人員
        getAgentUserList(item.userNo)
        // 設定支援醫師代碼/名稱
        setSupportUserNo(item.userNo)
        // 設定支援醫師/名稱
        setSupportUserName(item.userName)
        //關閉彈窗
        setShowDoctorPopup(false)
    }

    /**
     * 選取支援機構選項時
     * @param item {Object} 選取的選項
     * @return {void}
     */
    const handleOrgOptionOnClick = (item) => {
        // 設定支援機構代碼
        setSupportOrgNo(item.orgNo)
        // 設定支援機構名稱
        setSupportOrgName(item.orgName)
        // 關閉彈窗
        setShowOrgDropdown(false)
    }

    /**
     * 查詢變更紀錄點擊時
     * @return {void}
     */
    const handleQueryChangeRecordOnClick = () => {
        if (supportNo) setShowChangeRecordModal(true)
        else showToast({message: '申請單號為空，尚未有資料', type: AlertTypeEnum.Warning})
    }

    /**
     * 渲染支援目的選項
     * @return {JSX.Element}
     */
    const renderPurposeOptions = () => {
        // 過濾重複的選項
        const uniquePurposeOptions = Array.from(new Set(purposeOptions.map(item => item.codeValue1)))
            .filter(codeValue1 => codeValue1 != null)
            .map(codeValue1 => purposeOptions.find(item => item.codeValue1 === codeValue1))
            .filter(Boolean)
            .sort((a, b) => parseInt(a.codeNo) - parseInt(b.codeNo)); // 根據 codeNo 排序

        // 處理支援目的選項變更
        const handlePurposeChange = (codeNo) => {
            setPurposeCode(prevCodes => {
                if (prevCodes.includes(codeNo)) {
                    return prevCodes.filter(code => code !== codeNo);
                } else {
                    return [...prevCodes, codeNo];
                }
            });
        };

        return (
            <div className="grid grid-cols-5 w-full">
                {uniquePurposeOptions.map((item, index) => {
                    if (item.codeNo == null) {
                        return null; // 如果 codeNo 為 null，不渲染任何內容
                    }

                    if (item.codeNo === '10') {
                        return (
                            <div key={index} className="flex flex-row items-center gap-2 col-span-1">
                                <Checkbox
                                    key={item.codeNo}
                                    label={item.codeValue1}
                                    checked={purposeCode.includes(item.codeNo)}
                                    onChange={() => handlePurposeChange(item.codeNo)}
                                />
                                <Select
                                    data={{
                                        label: '報備為一般支援',
                                        options: subPurposeOptions.map((item) => ({
                                            value: item.codeNo,
                                            label: item.codeValue1
                                        }))
                                    }}
                                    value={purposeCodeExt}
                                    onChange={setPurposeCodeExt}
                                    showLabel={false}
                                    width={SelectWidthEnum.Tiny}
                                />
                            </div>
                        );
                    } else {
                        return (
                            <Checkbox
                                key={item.codeNo}
                                label={item.codeValue1}
                                checked={purposeCode.includes(item.codeNo)}
                                onChange={() => handlePurposeChange(item.codeNo)}
                            />
                        );
                    }
                })}
            </div>
        );
    };

    /**
     * 儲存修改後的報備支援內容
     * @param data {Array<Object>} 資料
     * @return {void}
     */
    const saveEditMaster = (data) => {
        if (type === 'EDIT') {
            // 執行修改報備支援內容
            rsisEditMaster(data).then(res => {
                // 狀態 / 資料 / 訊息
                const {err, data, msg} = res
                if (err === ApiErrorStatusEnum.Success) {
                    showToast({message: '修改成功', type: AlertTypeEnum.Success})
                    handleOnUpdate()
                    handleOnClose()
                } else {
                    showToast({message: msg, type: AlertTypeEnum.Error})
                }
            })
        } else if (type === 'IMPORT') {
            // 取得data array[0]的資料
            handleOnUpdate(data[0])
        }
    }

    /**
     * 承辦人員收案確認
     * @return {void}
     */
    const handleMailConfirm = () => {
        const data = getSendData()
        // 更新收案確認資料
        const updatedData = data.map(item => ({
            ...item,
            // 收案確認人員編號
            confirmUserNo: getLocalStorage('userno'),
            // 收案確認日期
            confirmDatetime: time2String(new Date(), 'YYYY-MM-DD HH-mm-ss').split('_')[0].replace(/(\d{4}-\d{2}-\d{2}) (\d{2})-(\d{2})-(\d{2})/, "$1 $2:$3:$4")
        }))
        // 執行收案確認
        rsisMailConfirm(updatedData).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                showToast({message: '收案成功', type: AlertTypeEnum.Success})
                handleOnClose()
                handleOnUpdate()
            } else {
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }


    /**
     * 建構送出資料
     * @returns {Array<Object>} 送出資料
     */
    const getSendData = () => {
        // 當支援明細資料為空時，顯示錯誤訊息
        if (dataList.length === 0) {
            showToast({message: '請新增報備支援內容', type: AlertTypeEnum.Error})
            return
        }
        // 根據支援明細資料產生對應比數的資料
        const sendData = dataList.map(item => {
            // 取得機構物件
            const org = orgList.find(o => o.orgNo === supportOrgNo && o.orgName === supportOrgName)
            if (org) {
                return {
                    // 申請單號
                    supportNo: supportNo,
                    // 版本號
                    itemVersion: data.itemVersion,
                    // 支援醫生編號
                    supportUserNo: supportUserNo,
                    // 支援機構Pkey
                    supportOrgPkey: org.orgPkey,
                    // 支援機構名稱
                    supportOrgName: supportOrgName,
                    // 支援科別編號
                    supportDivNo: supportDivNo,
                    // 支援科別名稱
                    supportDivName: supportDivName,
                    // 支援目的代碼
                    purposeCode: Array.isArray(purposeCode) ? purposeCode.join(',') : purposeCode, // 是否為Array，若是則轉換為字串
                    // 支援目的說明
                    purposeCodeExt: purposeCodeExt,
                    // 支援期間起始日期
                    itemStartDate: item.startDate ? `${item.startDate} 00:00:00` : '',
                    // 支援期間起始時間
                    itemStartTime: item.startTime.includes(':') ? item.startTime.split(':')[0] + item.startTime.split(':')[1] : item.startTime,
                    // 支援期間結束日期
                    itemEndDate: item.endDate ? `${item.endDate} 00:00:00` : '',
                    // 支援期間結束時間
                    itemEndTime: item.endTime.includes(':') ? item.endTime.split(':')[0] + item.endTime.split(':')[1] : item.endTime,
                    // 支援性質
                    supportProperties: supportProperties,
                    // 執行內容
                    executeContent: executeContent,
                    // 備註
                    memo: memo,
                    // 代理人員編號
                    agentUserNo: agentUserNo,
                    // 狀態(1申請/2確認/3送審/9失效)
                    stepStatus: item.stepStatus ? item.stepStatus : data.stepStatus,
                    // 變更/註銷狀態(null空:無/M: 變更/D:刪除)
                    supportModifyStatus: item.supportModifyStatus ? item.supportModifyStatus : data.supportModifyStatus,
                    // 變更/註銷日期
                    supportModifyDatetime: item.supportModifyDatetime ? item.supportModifyDatetime : data.supportModifyDatetime,
                    // 變更/註銷原因代號
                    supportModifyReasonCode: item.supportModifyReasonCode ? item.supportModifyReasonCode : data.supportModifyReasonCode,
                    // 變更/註銷原因說明
                    supportModifyReasonDoc: item.supportModifyReasonDoc ? item.supportModifyReasonDoc : data.supportModifyReasonDoc,
                    // 匯出編號
                    sendFileName: item.sendFileName ? item.sendFileName : data.sendFileName,
                    // 衛福部案號
                    sendCaseNo: item.sendCaseNo ? item.sendCaseNo : data.sendCaseNo,
                    // 送審日期
                    sendDatetime: item.sendDatetime ? item.sendDatetime : data.sendDatetime,
                    // 申請方式(N:一般 / E:急件申請)
                    applyType: item.applyType ? item.applyType : data.applyType,
                    // 院區
                    zone: item.zone ? item.zone : data.zone,
                    // 有效否0/1
                    effFlag: true,
                    // 承辦部門代號
                    ownerDeptNo: ownerDeptNo,
                    // 欄位變更說明, 以中文標記欄位名稱,欄位間用","區隔
                    changeFieldsDoc: item.changeFieldsDoc ? item.changeFieldsDoc : data.changeFieldsDoc,
                    // 收案確認人員代號
                    confirmUserNo: item.confirmUserNo ? item.confirmUserNo : data.confirmUserNo,
                    // 收案確認日期時間
                    confirmDatetime: item.confirmDatetime ? item.confirmDatetime : data.confirmDatetime,
                }
            }
        })
        // 若從整批匯入編輯資料
        if (type === 'IMPORT') {
            // 假如有day的資料，則將其加入到sendData[0]中
            if (day) sendData[0].day = day
            // 支援醫師編號
            sendData[0].supportUserNo = supportUserNo
            // 支援醫師名稱
            sendData[0].supportUserName = supportUserName
            // 承辦部門編號
            sendData[0].ownerDeptNo = ownerDeptNo
            // 承辦部門名稱
            sendData[0].ownerDeptName = ownerDeptName
            // 支援機構編號
            sendData[0].supportOrgNo = supportOrgNo
            // 支援機構名稱
            sendData[0].supportOrgName = supportOrgName
            // 支援科別編號
            sendData[0].supportDivNo = supportDivNo
            // 支援科別名稱
            sendData[0].supportDivName = supportDivName
            // 代理人員編號
            sendData[0].agentUserNo = agentUserNo
            // 代理人員名稱
            sendData[0].agentUserName = agentUserName
        }
        return sendData

    }

    /**
     * 依照員工/醫師代碼取得代理人員代號姓名
     * @param userNo {string} 員工/醫師代碼
     * @return {void}
     */
    const getAgentUserList = (userNo) => {
        // 根據員工/醫師代碼查詢代理人員代號姓名
        rsisQueryAgentByUserNo({
            userNo: userNo
        }).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                setAgentUserList(data)
            } else {
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 取得所有基本資料設定
     * @return {void}
     */
    const getPurposeOptions = () => {
        rsisQueryCodeValue({effFlag: 'ALL'}).then(res => {
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                const purposeData = data.filter(item => item.codeType === 'PURPOSE')
                const subPurposeData = data.filter(item => item.codeType === 'SUBPURPOSE')
                const natureData = data.filter(item => item.codeType === 'KIND')
                // 設定支援目的選項
                setPurposeOptions(purposeData)
                // 設定子支援目的選項
                setSubPurposeOptions(subPurposeData)
                // 設定性質清單
                setKindList(natureData)
            } else {
                setPurposeOptions([])
                setSubPurposeOptions([])
                setKindList([])
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 取得醫師執登資料
     * @param userNo {string} 醫師代號
     * @return {void}
     */
    const getDoctorData = (userNo) => {
        // 根據醫師代碼查詢醫師資料
        checkDoctorLicence({
            doctorNo: userNo,
            divNo: ''
        }).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                // 若有執豋資料，則設定執豋資料
                if (data) {
                    // 設定執登科別代號
                    setDivNo(data.divNo)
                    // 設定執登科別名稱
                    setDivName(data.divName)
                    // 設定證書字號
                    setLicenceId(data.licenceId)
                    // 設定職類別
                    setLicenceKind(data.licenceKind)
                    // 設定職類別名稱
                    setLicenceKindName(data.licenceKindName)
                    // 設定執登機構編號
                    setHospNo(data.hospNo)
                    // 設定執登機構名稱
                    setHospName(data.hospName)
                } else {
                    // 若無執登資料，則設定空值
                    setDivNo('')
                    setDivName('')
                    setLicenceId('')
                    setLicenceKind('')
                    setLicenceKindName('')
                    setHospNo('')
                    setHospName('')
                }
            } else {
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 取得預設支援科別
     * @return {Object|null}
     */
    const getDefaultDivNoValue = useMemo(() => {
            const data = allDivisionList?.find(option => supportDivNo?.includes(option?.supportDivNo || ''))
            return data && supportDivNo ? data : null
        }, [allDivisionList, supportDivNo]
    )

    /**
     * 取得預設部門值
     * @return {Object|null}
     */
    const getDefaultDepNoValue = useMemo(() => {
            const data = deptList?.find(option => ownerDeptNo?.includes(option?.depNo || ''))
            return data && ownerDeptNo ? data : null
        }, [deptList, ownerDeptNo]
    )

    /**
     * 取得預設代理人員值
     * @return {Object|null}
     */
    const getDefaultAgentUserValue = useMemo(() => {
            const data = agentUserList?.find(option => agentUserNo?.includes(option?.agentUserNo || ''))
            return data && agentUserNo ? data : null
        }, [agentUserList, agentUserNo]
    )

    /**
     * 取得DIVISION中 SUPPORT_DIV_NO不為空的資料
     * @return {void}
     */
    const getAllDivision = () => {
        rsisQueryBaseData({}).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                setAllDivisionList(data.supportDivList)
            } else {
                setAllDivisionList([])
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 第一次執行時
     */
    useEffect(() => {
        // 取得所有科別
        getAllDivision()
        // 設定報備支援資料
        if (!objectIsEmpty(data)) {
            setSupportDivStr(`${supportDivNo} ${supportDivName}`)
            // 承辦部門名稱
            let dn = ''
            // 如果是整批匯入作業時使用編輯
            if (type === 'IMPORT') {
                // 如果有承辦部門No，根據承辦部門No取得承辦部門名稱
                if (data.ownerDeptNo) {
                    dn = deptList.find(item => item.deptNo === data.ownerDeptNo).deptName
                }
                setOwnerDeptName(dn)
            }
            // 設定承辦部門顯示字串
            setOwnerDeptStr(`${ownerDeptNo ? ownerDeptNo : ''} ${ownerDeptName ? ownerDeptName : dn}`)
            // 設定代理人員顯示字串
            setAgentUserStr(`${agentUserNo ? agentUserNo : ''} ${agentUserName ? agentUserName : ''}`)
            // 設定支援機構顯示字串
            setSupportOrgStr(`${supportOrgNo ? supportOrgNo : ''} ${supportOrgName ? supportOrgName : ''}`)
            // 取得醫師執登資料
            getDoctorData(data.supportUserNo)
            // 取得支援日期資料
            if (!objectIsEmpty(data)) {
                const newDataItems = [{
                    ...data,
                    startDate: time2String(data.itemStartDate, 'YYYY-MM-DD'),
                    endDate: time2String(data.itemEndDate, 'YYYY-MM-DD'),
                    startTime: data.itemStartTime,
                    endTime: data.itemEndTime,
                }]
                setDataList(newDataItems)
            }
        }
        // 取得支援目的選項
        getPurposeOptions()
        // 取得代理人員
        getAgentUserList(data.supportUserNo)
    }, [])

    // 監聽當支援期間明細日期資料更新時
    useEffect(() => {
        if (dataList.length > 0) {
            setItemStartDate(dataList[0].startDate)
            setItemEndDate(dataList[0].endDate)
            setItemStartTime(dataList[0].startTime)
            setItemEndTime(dataList[0].endTime)
        } else {
            setItemStartDate('')
            setItemEndDate('')
            setItemStartTime('')
            setItemEndTime('')
        }
    }, [dataList])

    return (
        <Dialog
            open={true}
            title={t('RSIS.Popup.EditModal')}
            content={
                <div className='px-6'>
                    <div className='flex flex-row justify-end items-center w-full gap-2 pb-2'>
                        <Button color={ButtonColorEnum.Secondary}
                                variant={ButtonVariantEnum.Outlined}
                                size={ButtonSizeEnum.Medium}
                                text='查詢變更紀錄'
                                onClick={handleQueryChangeRecordOnClick}
                                sx={{height: '2.5rem'}}
                        />
                        <Select
                            data={{
                                label: "院區",
                                options: [
                                    {
                                        value: getLocalStorage('campus'),
                                        label: t(`campus.${getLocalStorage('campus')}`)
                                    }]
                            }}
                            value={getLocalStorage('campus')}
                            disabled={true}
                            displayEmpty={true}
                            notched={true}
                            width={SelectWidthEnum.Tiny}
                        />
                        <span
                            className='flex items-center text-[#38A169]'>{`${t(`RSIS.StepStatusEnum.${data.stepStatus}`)}狀態`}</span>
                    </div>
                    {/* 支援人員 */}
                    <Card title={"支援人員"}>
                        <div className='flex flex-col gap-2  mb-6'>
                            {/* 員工/醫師代碼 */}
                            <Field label="員工/醫師代碼">
                                <TextField
                                    size={SizeEnum.Small}
                                    value={`${supportUserNo} ${supportUserName}`}
                                    onClick={() => setShowDoctorPopup(true)}
                                />
                            </Field>
                            <div className='flex flex-row items-center gap-4'>
                                {/* 執登機構 */}
                                <Field label="執登機構">
                                    {!stringIsEmpty(hospNo) && !stringIsEmpty(hospName) ? `${hospNo} ${hospName}` : ''}
                                </Field>
                                {/*職類別*/}
                                <Field label="職類別">
                                    {!stringIsEmpty(licenceKind) && !stringIsEmpty(licenceKindName) ? `${licenceKind} ${licenceKindName}` : ''}
                                </Field>
                                { /* 執登科別 */}
                                <Field label="執登科別">
                                    {!stringIsEmpty(divNo) && !stringIsEmpty(divName) ? `${divNo} ${divName}` : ''}
                                </Field>
                                {/* 證書字號 */}
                                <Field label="證書字號">
                                    {!stringIsEmpty(licenceId) ? licenceId : ''}
                                </Field>
                            </div>
                        </div>
                    </Card>
                    {/* 分隔線 */}
                    <div className='border-b-[1px] border-[rgba(0,0,0,0.15)]'></div>
                    {/* 報備支援內容 */}
                    <Card title={"報備支援內容"}>
                        <div className='w-full flex flex-col gap-2 mt-2'>
                            {/* 機構代碼/名稱 */}
                            <div className='flex justify-between items-center'>
                                <Field label="機構代碼/名稱">
                                    <TextField
                                        size={SizeEnum.Small}
                                        inputWidth={SizeEnum.XLarge}
                                        value={`${supportOrgNo} ${supportOrgName}`}
                                        onClick={() => setShowOrgDropdown(true)}/>
                                </Field>
                                <Field label="申請單號">
                                    {supportNo}
                                </Field>
                            </div>
                            {/* 支援科別 */}
                            <Field label={"支援科別"}>
                                <Search
                                    freeSolo
                                    value={getDefaultDivNoValue}
                                    onChange={(_e, value) => {
                                        const newValue = value?.supportDivNo ? `${value?.supportDivNo} ${value?.supportDivName}` : ''
                                        handleSupportDivNoChange(newValue)
                                    }}
                                    filterOptions={(options, {inputValue}) => {
                                        if (!inputValue) {
                                            return getDefaultDivNoValue || options
                                        }
                                        return multiTermFuzzySearchObjects(options, inputValue.split(' '))
                                    }}
                                    options={allDivisionList}
                                    getOptionLabel={(option) => `${option.supportDivNo} ${option.supportDivName}`}
                                />
                            </Field>
                            {/* 支援目的 */}
                            <Field label="支援目的" sx={{display: 'flex', alignItems: 'start'}}>
                                <div className='flex flex-col items-start w-full'>
                                    {renderPurposeOptions()}
                                </div>
                            </Field>
                        </div>
                    </Card>
                    {/* 分隔線 */}
                    <Divider/>
                    <div className='flex flex-col gap-2 my-4 mb-4'>
                        <div className='flex flex-row items-center justify-between gap-4'>
                            <div className='flex flex-row items-center gap-4'>
                                {/* 承辦部門 */}
                                <Field label={"承辦部門"}>
                                    <Search
                                        freeSolo
                                        value={getDefaultDepNoValue}
                                        onChange={(_e, value) => {
                                            const newValue = value?.deptNo ? `${value?.deptNo} ${value?.deptName}` : ''
                                            handleOwnerDeptNoChange(newValue)
                                        }}
                                        filterOptions={(options, {inputValue}) => {
                                            if (!inputValue) {
                                                return getDefaultDepNoValue || options
                                            }
                                            return multiTermFuzzySearchObjects(options, inputValue.split(' '))
                                        }}
                                        options={deptList}
                                        getOptionLabel={(option) => `${option.deptNo} ${option.deptName}`}
                                    />
                                </Field>
                                {/* 代理人員代號/姓名 */}
                                <Field label={'代理人員代號/姓名'} labelWidth={150}>
                                    <Search
                                        freeSolo
                                        value={getDefaultAgentUserValue}
                                        onChange={(_e, value) => {
                                            const newValue = value?.agentUserNo ? `${value?.agentUserNo} ${value?.agentUserName}` : ''
                                            handleAgentUserNoChange(newValue)
                                        }}
                                        filterOptions={(options, {inputValue}) => {
                                            if (!inputValue) {
                                                return getDefaultAgentUserValue || options
                                            }
                                            return multiTermFuzzySearchObjects(options, inputValue.split(' '))
                                        }}
                                        options={agentUserList}
                                        getOptionLabel={(option) => `${option.agentUserNo} ${option.agentUserName}`}
                                    />
                                </Field>
                            </div>
                            {/* 收件確認按鈕 */}
                            <Button sx={{marginLeft: '1rem'}}
                                    color={ButtonColorEnum.Secondary} variant={ButtonVariantEnum.Outlined}
                                    size={ButtonSizeEnum.Medium}
                                    text="收件確認"
                                    disabled={!(data.stepStatus == RsisStepStatusEnum.Apply && userRole === USER_ROLE.DEPT_USER)}
                                    onClick={handleMailConfirm}
                            />
                        </div>
                        {/* 性質 */}
                        <Field label="性質">
                            <Select
                                data={{
                                    label: '性質',
                                    options: kindList.map((item) => ({
                                        label: item.codeValue1,
                                        value: item.codeNo
                                    }))
                                }}
                                value={supportProperties}
                                showLabel={false}
                                width={SelectWidthEnum.Medium}
                                onChange={setSupportProperties}
                            />
                        </Field>
                        {/* 執行內容 */}
                        <Field label="執行內容">
                            <TextField
                                size={SizeEnum.Small}
                                inputWidth={SizeEnum.Fill}
                                value={executeContent}
                                onChange={(e) => setExecuteContent(e.target.value)}
                            />
                        </Field>
                        {/* 備註 */}
                        <Field label="備註">
                            <TextField
                                size={SizeEnum.Small}
                                inputWidth={SizeEnum.Fill}
                                disabled={!isOwnUser}
                                value={memo}
                                onChange={(e) => setMemo(e.target.value)}
                            />
                        </Field>
                        {/* 支援期間明細 */}
                        <Field label="支援期間">
                            <div className="flex items-center space-x-2">
                                <Button color={ButtonColorEnum.Primary} variant={ButtonVariantEnum.Outlined}
                                        size={ButtonSizeEnum.Medium}
                                        text={t('general.edit')}
                                        onClick={() => setShowSupportDateChoice(true)}/>
                                <p>{`${itemStartDate.replace(/ 00:00:00/g, '')}~${itemEndDate.replace(/ 00:00:00/g, '')} ${itemStartTime}~${itemEndTime}`}</p>
                            </div>
                        </Field>
                    </div>
                    {/* 分隔線 */}
                    <Divider/>
                    {/* 存檔按鈕 */}
                    <div className='w-full flex justify-end my-2'>
                        <Button sx={{marginLeft: '1rem'}} color={ButtonColorEnum.Primary}
                                variant={ButtonVariantEnum.Contained}
                                size={ButtonSizeEnum.Medium} text={t('general.saveFile')} onClick={handleFormSave}
                        />
                    </div>
                    {/* 變更紀錄彈窗 */}
                    {showChangeRecordModal && (
                        <ChangeRecordQuery
                            supportNo={data.supportNo}
                            dropListData={[purposeOptions, subPurposeOptions]}
                            handleOnClose={() => setShowChangeRecordModal(false)}
                        />
                    )}
                    {/* 報備支援-支援日期時間選擇 */}
                    {showSupportDateChoice && (
                        <SupportDateChoice
                            userName={supportUserName}
                            isEdit={true}
                            data={dataList}
                            supportNo={supportNo}
                            supportUserNo={supportUserNo}
                            itemVersion={data.itemVersion}
                            handleUpdateDataList={handleUpdateDataList}
                            handleOnClose={() => setShowSupportDateChoice(false)}/>
                    )}
                    {/* 醫師搜尋彈窗 */}
                    {
                        showDoctorPopup && (
                            <QueryPersonList
                                filterEmp={true}
                                handleOnClose={() => setShowDoctorPopup(false)}
                                handleOnUpdate={handleDoctorOptionOnClick}/>
                        )
                    }
                    {/* 支援機構彈窗 */}
                    {
                        showOrgDropdown && (
                            <QuerySupportOrg
                                filterOrg={true}
                                handleOnClose={() => setShowOrgDropdown(false)}
                                handleOnUpdate={handleOrgOptionOnClick}/>
                        )
                    }
                </div>
            }
            variant={DialogVariant.EDIT}
            paperStyleProps={{width: '1653px'}}
            onClose={handleOnClose}
            onSave={handleFormSave}
            muiDialogActionsProps={{sx: {display: 'none'}}}
            muiDialogContentProps={{dividers: true}}
        />
    )
}

export default EditModal
