import { time2String } from 'edah_utils/dist'
import { DATE_FORMAT } from '../common'
import { booleanForDisplay } from '../../components/ReferralSystem/utils'
import i18n from '../../i18n'

export const TRAN_OUT_LIST_FIELDS = [
    {
        key: 'editor',
        name: i18n.t('Rmis.pages.tranOutCaseList.tableHeader.editor'), // 編輯
        className: 'text-sm whitespace-nowrap min-w-[80px]',
    },
    {
        key: 'tranDate',
        name: i18n.t('Rmis.pages.tranOutCaseList.tableHeader.tranDate'), // 轉出日期
        className: 'text-sm whitespace-nowrap min-w-[120px]',
        format: (value) => time2String(value, DATE_FORMAT),
    },
    {
        key: 'encounterDate',
        name: i18n.t('Rmis.pages.tranOutCaseList.tableHeader.encounterDate'), // 就醫日期
        className: 'text-sm whitespace-nowrap min-w-[120px]',
        format: (value) => time2String(value, DATE_FORMAT),
    },
    {
        key: 'inp', // TODO: api 待確認
        name: i18n.t('Rmis.pages.tranOutCaseList.tableHeader.inp'), // 住院
        className: 'text-sm whitespace-nowrap min-w-[120px]',
    },
    {
        key: 'patientId',
            name: i18n.t('Rmis.pages.tranOutCaseList.tableHeader.patientId'), // 病歷號
        className: 'text-sm whitespace-nowrap min-w-[120px]',
    },
    {
        key: 'patientName',
        name: i18n.t('Rmis.pages.tranOutCaseList.tableHeader.patientName'), // 病人姓名
        className: 'text-sm whitespace-nowrap min-w-[120px]',
    },
    {
        key: 'idNo',
        name: i18n.t('Rmis.pages.tranOutCaseList.tableHeader.idNo'), // 身分證號
        className: 'text-sm whitespace-nowrap min-w-[120px]',
    },
    {
        key: 'birthDate',
        name: i18n.t('Rmis.pages.tranOutCaseList.tableHeader.birthDate'), // 生日
        className: 'text-sm whitespace-nowrap min-w-[120px]',
        format: (value) => time2String(value, DATE_FORMAT),
    },
    {
        key: 'tel',
        name: i18n.t('Rmis.pages.tranOutCaseList.tableHeader.tel'), // 電話
        className: 'text-sm whitespace-nowrap min-w-[120px]',
    },
    {
        key: 'phone',
        name: i18n.t('Rmis.pages.tranOutCaseList.tableHeader.phone'), // 手機
        className: 'text-sm whitespace-nowrap min-w-[120px]',
    },
    {
        key: 'divName',
        name: i18n.t('Rmis.pages.tranOutCaseList.tableHeader.divName'), // 就醫科別
        className: 'text-sm whitespace-nowrap min-w-[120px]',
    },
    {
        key: 'inpOpdName',
        name: i18n.t('Rmis.pages.tranOutCaseList.tableHeader.inpOpdName'), // 轉診來源
        className: 'text-sm whitespace-nowrap',
    },
    {
        key: 'ptTypeName', // TODO: api 待確認
        name: i18n.t('Rmis.pages.tranOutCaseList.tableHeader.ptTypeName'), // 身分
        className: 'text-sm whitespace-nowrap min-w-[80px]',
    },
    {
        key: 'bedNo',
        name: i18n.t('Rmis.pages.tranOutCaseList.tableHeader.bedNo'), // 床號
        className: 'text-sm whitespace-nowrap min-w-[80px]',
    },
    {
        key: 'doctorName',
        name: i18n.t('Rmis.pages.tranOutCaseList.tableHeader.doctorName'), // 醫師
        className: 'text-sm whitespace-nowrap min-w-[120px]',
    },
    {
        key: 'acceptFlag',
        name: i18n.t('Rmis.pages.tranOutCaseList.tableHeader.acceptFlag'), // 個管顯示
        className: 'text-sm whitespace-nowrap min-w-[80px]',
    },
    {
        key: 'treatmentCode',
        name: i18n.t('Rmis.pages.tranOutCaseList.tableHeader.treatmentCode'), // 主診斷
        className: 'text-sm whitespace-nowrap min-w-[80px]',
    },
    {
        key: 'treatmentName',
        name: i18n.t('Rmis.pages.tranOutCaseList.tableHeader.treatmentName'), // 主診斷名稱
        className: 'text-sm whitespace-nowrap min-w-[120px]',
    },
    {
        key: 'treatmentCode1',
        name: i18n.t('Rmis.pages.tranOutCaseList.tableHeader.treatmentCode1'), // 次診斷
        className: 'text-sm whitespace-nowrap min-w-[80px]',
    },
    {
        key: 'treatmentName1',
        name: i18n.t('Rmis.pages.tranOutCaseList.tableHeader.treatmentName1'), // 次診斷名稱
        className: 'text-sm whitespace-nowrap min-w-[120px]',
    },
    {
        key: 'treatmentCode2',
        name: i18n.t('Rmis.pages.tranOutCaseList.tableHeader.treatmentCode2'), // 第三診斷
        className: 'text-sm whitespace-nowrap min-w-[80px]',
    },
    {
        key: 'treatmentName2',
        name: i18n.t('Rmis.pages.tranOutCaseList.tableHeader.treatmentName2'), // 第三診斷名稱
        className: 'text-sm whitespace-nowrap min-w-[120px]',
    },
    {
        key: 'acceptReply',
        name: i18n.t('Rmis.pages.tranOutCaseList.tableHeader.acceptReply'), // 接受對方回覆
        className: 'text-sm whitespace-nowrap',
    },
    {
        key: 'outStatusName',
        name: i18n.t('Rmis.pages.tranOutCaseList.tableHeader.outStatusName'), // 轉出追蹤狀態
        className: 'text-sm whitespace-nowrap',
    },
    {
        key: 'tranOutHospName',
        name: i18n.t('Rmis.pages.tranOutCaseList.tableHeader.tranOutHospName'), // 轉出院所
        className: 'text-sm whitespace-nowrap min-w-[120px]',
    },
    {
        key: 'federalFlag',
        name: i18n.t('Rmis.pages.tranOutCaseList.tableHeader.federalFlag'), // 策略聯盟
        className: 'text-sm whitespace-nowrap',
        format: (value) => booleanForDisplay(value),
    },
    {
        key: 'hospGradeName',
        name: i18n.t('Rmis.pages.tranOutCaseList.tableHeader.hospGradeName'), // 院所層級
        className: 'text-sm whitespace-nowrap',
    },
    {
        key: 'tranOuRealtHospName',
        name: i18n.t('Rmis.pages.tranOutCaseList.tableHeader.tranOuRealtHospName'), // 實際轉出醫院名稱
        className: 'text-sm whitespace-nowrap min-w-[120px]',
    },
    {
        key: 'tranReason',
        name: i18n.t('Rmis.pages.tranOutCaseList.tableHeader.tranReason'), // 轉院原因
        className: 'text-sm whitespace-nowrap min-w-[120px]',
    },
    {
        key: 'tranType',
        name: i18n.t('Rmis.pages.tranOutCaseList.tableHeader.tranType'), // 轉出類別
        className: 'text-sm whitespace-nowrap',
    },
    {
        key: 'outReturnDate',
        name: i18n.t('Rmis.pages.tranOutCaseList.tableHeader.outReturnDate'), // 轉診單回收
        className: 'text-sm whitespace-nowrap',
        format: (value) => time2String(value, DATE_FORMAT),
    },  
    {
        key: 'outReplyDate',
        name: i18n.t('Rmis.pages.tranOutCaseList.tableHeader.outReplyDate'), // 接受回覆日期
        className: 'text-sm whitespace-nowrap min-w-[120px]',
        format: (value) => time2String(value, DATE_FORMAT),
    },
    {
        key: 'admissionDate',
        name: i18n.t('Rmis.pages.tranOutCaseList.tableHeader.admissionDate'), // 住院日期
        className: 'text-sm whitespace-nowrap min-w-[120px]',
        format: (value) => time2String(value, DATE_FORMAT),
    },
    {
        key: 'dischargeDate',
        name: i18n.t('Rmis.pages.tranOutCaseList.tableHeader.dischargeDate'), // 出院日期
        className: 'text-sm whitespace-nowrap min-w-[120px]',
        format: (value) => time2String(value, DATE_FORMAT),
    },
    {
        key: 'outCancerFlag',
        name: i18n.t('Rmis.pages.tranOutCaseList.tableHeader.outCancerFlag'), // 癌症篩選
        className: 'text-sm whitespace-nowrap',
        format: (value) => booleanForDisplay(value),
    },
    {
        key: 'outRemark1',
        name: i18n.t('Rmis.pages.tranOutCaseList.tableHeader.outRemark1'), // 備註2
        className: 'text-sm whitespace-nowrap min-w-[120px]',
    },
    {
        key: 'inpCancelReason',
        name: i18n.t('Rmis.pages.tranOutCaseList.tableHeader.inpCancelReason'), // 住院取消原因
        className: 'text-sm whitespace-nowrap min-w-[120px]',
    },
    {
        key: 'payFeeNo', // TODO: api 待確認
        name: i18n.t('Rmis.pages.tranOutCaseList.tableHeader.payFeeNo'), // 批價序號
        className: 'text-sm whitespace-nowrap',
    },
    {
        key: 'encounterId',
        name: i18n.t('Rmis.pages.tranOutCaseList.tableHeader.encounterId'), // 單號
        className: 'text-sm whitespace-nowrap',
    },
]

/**
 * 列印尚未收到接受轉診醫院回覆名單
 */
export const NO_RECEIPT_LETTER_FIELDS = [
    {
        key: 'encounterId',
        name: i18n.t(
            'Rmis.pages.tranOutCaseList.noReplyLetterListModal.tableHeader.encounterId'
        ), // 編號
    },
    {
        key: 'patientId',
        name: i18n.t(
            'Rmis.pages.tranOutCaseList.noReplyLetterListModal.tableHeader.patientId'
        ), // 病歷號
    },
    {
        key: 'patientName',
        name: i18n.t(
            'Rmis.pages.tranOutCaseList.noReplyLetterListModal.tableHeader.patientName'
        ), // 姓名
    },
    {
        key: 'idNo',
        name: i18n.t(
            'Rmis.pages.tranOutCaseList.noReplyLetterListModal.tableHeader.idNo'
        ), // 身分證號
    },
    {
        key: 'tranDate',
        name: i18n.t(
            'Rmis.pages.tranOutCaseList.noReplyLetterListModal.tableHeader.tranDate'
        ), // 轉出日期
        format: (value) => time2String(value, DATE_FORMAT),
    },
    {
        key: 'outStatus',
        name: i18n.t(
            'Rmis.pages.tranOutCaseList.noReplyLetterListModal.tableHeader.outStatus'
        ), // 追蹤狀態
    },
]
