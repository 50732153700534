import React, {useEffect, useState} from 'react'
import {t} from "i18next"
import {rsisEditSendLog, rsisQueryOwnerUser, rsisQuerySendLog} from "../../../api/v1/RSIS"
import {ApiErrorStatusEnum, fuzzySearchObjects, getLocalStorage} from "edah_utils/dist"
import {usePopup} from "../PopupProvider"
import {
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    TextField,
    AlertTypeEnum,
    IconEnum,
    Card,
    InlineEditDataGrid
} from "edah-component/dist"
import {SearchTextField} from "../../SearchTextField/SearchTextField";

/**
 * 衛福部案號回饋作業
 * @return {JSX.Element}
 */
const MOHWgovFeedback = ({}) => {
    // 取得彈窗
    const {showToast} = usePopup()

    // 表格資料
    const [dataList, setDataList] = useState([])

    // 匯出編號
    const [sendFileName, setSendFileName] = useState('')

    // 備註
    const [memo, setMemo] = useState('')

    // 衛福部案號
    const [sendCaseNo, setSendCaseNo] = useState('')

    // 是否為承辦人員
    const [isOwner, setIsOwner] = useState(false)

    // 模糊搜尋的字串
    const [queryString, setQueryString] = useState('');

    // 未保存的資料集
    const [unSaveDataMap, setUnSaveDataMap] = useState({})

    /**
     * 取得模糊匹配後的資料陣列
     * @return {Array<Object>}
     */
    const getFilterData = () => fuzzySearchObjects(dataList, queryString)
    /**
     * 搜尋字串改變時
     * @param event {Event} - 事件
     * @return {void}
     */
    const handleQueryOnChange = (event) => setQueryString(event.target.value);

    /**
     * 查詢按鈕點擊事件
     * @return {void}
     */
    const handleQueryButtonOnClick = () => getQuerySendLog()

    /**
     * 取得查詢衛褔部案號回饋作業
     * @return {void}
     */
    const getQuerySendLog = () => {
        rsisQuerySendLog({
            sendFileName: sendFileName,
            memo: memo,
            sendCaseNo: sendCaseNo,
        }).then(res => {
            // 取得回傳資料
            const {err, msg, data} = res
            // 取得成功
            if (err === ApiErrorStatusEnum.Success) {
                // 排序時間
                const list = data ? data.sort((a, b) => new Date(b.sendDatetime) - new Date(a.sendDatetime)) : data
                setDataList(list)
            } else { //取得失敗
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 取得承辦人員清單並判斷登入者是否為承辦人員
     * @return {void}
     */
    const getOwnerUserList = () => {
        rsisQueryOwnerUser({}).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            // 取得成功
            if (err === ApiErrorStatusEnum.Success) {
                // 取得登入者
                const loginUser = getLocalStorage('userno')
                // 判斷是否為承辦人員
                setIsOwner(data.some(item => item.userNo === loginUser))

            } else { // 取得失敗
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 快速編輯欄位輸入事件
     * @param event {Event} - 事件
     * @param key {string} - 欄位名稱
     * @param id {string} - row id
     */
    const handleEditRowChange = (event, key, id) => {
        const {value} = event.target
        setUnSaveDataMap({
            ...unSaveDataMap,
            [id]: {
                ...unSaveDataMap[key],
                [key]: value
            }
        })
    }

    /**
     * 儲存修改後結果
     * @param data {Object} - 修改後的資料
     */
    const saveEditSendLog = (data) => {
        rsisEditSendLog(data).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                // 顯示成功訊息
                showToast({message: msg, type: AlertTypeEnum.Success})
                // 重新取得資料
                getQuerySendLog()
            } else {
                // 顯示錯誤訊息
                showToast({message: `儲存失敗，${msg}`, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 快速儲存
     * @param id {string} - row id
     * @param rawData {Object} - 原始資料
     * @return {void}
     */
    const handleSave = (id, rawData) => {
        // 取得未保存的資料
        const saveData = unSaveDataMap[id]
        // 備註
        const saveMemo = saveData?.memo ?? rawData?.memo
        // 衛福部案號
        const saveSendCaseNo = saveData?.sendCaseNo ?? rawData?.sendCaseNo
        // 若未修改則提示
        if (saveMemo === rawData?.memo && saveSendCaseNo === rawData?.sendCaseNo) {
            showToast({message: '請確認修改內容', type: AlertTypeEnum.Warning})
            return
        }
        // 若原始衛福部案號不為空值，且更新後的衛福部案號為空值，則提示錯誤訊息
        if (!saveData?.sendCaseNo && !rawData?.sendCaseNo) {
            showToast({message: '衛福部案號不能修改為空值', type: AlertTypeEnum.Warning})
            return
        }

        // 保存的資料
        const data = {
            //send uuid
            sendUuid: rawData.sendUuid,
            //匯出人員代號 string
            sendUserNo: rawData.sendUserNo,
            // 匯出日期時間
            sendDatetime: rawData.sendDatetime,
            //匯出檔案名稱
            sendFileName: rawData.sendFileName,
            //備註
            memo: saveMemo,
            //衛褔部案號
            sendCaseNo: saveSendCaseNo
        }
        saveEditSendLog(data)
    }

    /**
     * 衛福部案號回饋作業 - 表格用的 header 與設定資料
     */
    const header = [
        { field: 'sendFileName', headerName: '匯出編號', flex: 2, minWidth: 350 },
        { field: 'memo', headerName: '備註', flex: 1, minWidth: 150, renderCell: (params) => {
                // 是否可編輯
                const isEditable = params.isEditable
                return  isEditable ?
                    <div className="flex items-center h-[100%]">
                        <TextField
                            defaultValue={params.row.memo}
                            onChange={(event=>{
                                handleEditRowChange(event, 'memo', params.id)
                            })}
                        />
                    </div>: <>{params.row.memo}</>
            } },
        { field: 'sendCaseNo', headerName: '衛福部案號', flex: 1, minWidth: 200, renderCell: (params) => {
                // 是否可編輯
                const isEditable = params.isEditable
                return  isEditable ?
                    <div className="flex items-center h-[100%]">
                        <TextField
                            defaultValue={params.row.sendCaseNo}
                            onChange={(event=>{
                                handleEditRowChange(event, 'sendCaseNo', params.id)
                            })}
                        />
                    </div>: <>{params.row.sendCaseNo}</>
            } },
        { field: 'sendUserNo', headerName:'匯出人員', flex: 1, minWidth: 200, renderCell: (params) => {
                return <div>{`${params.row.sendUserNo ? params.row.sendUserNo : ''}${params.row.userName ? params.row.userName : ''}`}</div>
            }
        },
        {field: 'sendDatetime', headerName: '匯出時間', flex: 1, minWidth: 250},
    ]
    /**
     * 第一次執行時取得查詢衛褔部案號回饋作業
     */
    useEffect(() => {
        // 取得承辦人員清單
        getOwnerUserList()
        // 取得查詢衛褔部案號回饋作業
        getQuerySendLog()
    }, [])

    return (
        <Card sx={{pt: 2}}>
            <div className='flex flex-row items-center justify-start gap-6'>
                {/* 匯出編號 */}
                <TextField
                    label="匯出編號"
                    onChange={(e) => setSendFileName(e.target.value)}
                    value={sendFileName}/>
                {/* 備註 */}
                <TextField
                    label="備註"
                    onChange={(e) => setMemo(e.target.value)}
                    value={memo}/>
                {/* 衛福部案號 */}
                <TextField
                    label="衛福部案號"
                    onChange={(e) => setSendCaseNo(e.target.value)}
                    value={sendCaseNo}/>
                {/* 查詢按鈕 */}
                <div className="source flex flex-row items-center justify-start">
                    <Button text={t('general.query')} size={ButtonSizeEnum.Medium}
                            color={ButtonColorEnum.Primary} variant={ButtonVariantEnum.Contained}
                            onClick={handleQueryButtonOnClick}/>
                </div>
            </div>
            <div >
                <div className="text-left my-2">
                    {/*表格進階搜尋*/}
                    <SearchTextField
                        value={queryString}
                        onChange={handleQueryOnChange}
                        placeholder={t('general.advancedSearch')}
                    />
                </div>
                {/*衛福部案號回饋作業表格*/}
                <InlineEditDataGrid
                    rows={getFilterData() || []}
                    columns={header}
                    checkboxSelection={false}
                    disableColumnFilter
                    disableColumnSelector
                    disableColumnMenu
                    disableDensitySelector
                    disableRowSelectionOnClick
                    height={'600px'}
                    disableDelete={true}
                    disableAddButton={true}
                    customEditText={
                        {
                            text: '快速編輯',
                            icon: IconEnum.FastEdit
                        }
                    }
                    onSave={(row, key)=> handleSave(row.id, row)}
                />
            </div>
        </Card>
    )
}

export default MOHWgovFeedback
