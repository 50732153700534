import {v1Api} from "../Http"
import {HttpMethodEnum} from "edah_utils/dist"

/**
 * 取得病房代理人員所有資料
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const rsisQueryAgent = (params) => v1Api.request({
    url: '/rsis/agent/queryAgent',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 依人員工號/名稱做模糊查詢人員
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const rsisQueryAgentLike = (params) => v1Api.request({
    url: '/rsis/agent/queryAgent/like',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 根據人員代號查詢代理人員資料
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const rsisQueryAgentByUserNo = (params) => v1Api.request({
    url: '/rsis/agent/queryAgentNoAndNameByUserNo',
    method: HttpMethodEnum.GET,
    params
})


/**
 * 新增病房代理人員資料
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const rsisAddAgent = (data) => v1Api.request({
    url: '/rsis/agent/addAgent',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 病房代理人查詢所有有效醫師
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const rsisQueryAllActiveDoctor = (params) => v1Api.request({
    url: '/rsis/agent/queryAllActiveDoctor',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 病房代理人查詢所有有效員工
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const rsisQueryAllActiveEmployee = (params) => v1Api.request({
    url: '/rsis/agent/queryAllActiveEmp',
    method: HttpMethodEnum.GET,
    params
})


/**
 * 刪除病房代理人員資料
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const rsisDelAgent = (data) => v1Api.request({
    url: '/rsis/agent/delAgent/:PKey'.replace(':PKey', data.PKey),
    method: HttpMethodEnum.DELETE,
})

/**
 * 取得特殊權限設定(無需稽核報備設定)資料
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const rsisQuerySpecialRole = (params) => v1Api.request({
    url: '/rsis/specialrole/querySpecialRole',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 新增特殊權限設定(無需稽核報備設定)資料
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const rsisAddSpecialRole = (data) => v1Api.request({
    url: '/rsis/specialrole/addSpecialRole',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 修改特殊權限設定(無需稽核報備設定)資料
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const rsisEditSpecialRole = (data) => v1Api.request({
    url: '/rsis/specialrole/editSpecialRole',
    method: HttpMethodEnum.PUT,
    data
})

/**
 * 刪除特殊權限設定(無需稽核報備設定)資料
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const rsisDelSpecialRole = (data) => v1Api.request({
    url: '/rsis/specialrole/delSpecialRole/:specialRoleId'.replace(':specialRoleId', data.specialRoleId),
    method: HttpMethodEnum.DELETE,
})

/**
 * 取得承辦部門人員所有資料
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const rsisQueryOwnerUser = (params) => v1Api.request({
    url: '/rsis/owneruser/queryOwnerUser',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 依人員工號/名稱做模糊查詢人員
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const rsisQueryOwnerUserLike = (params) => v1Api.request({
    url: '/rsis/owneruser/queryOwnerUser/like',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 承辦部門人員維護-人員工號初始值查詢
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const rsisGetOwnerUserByID = (params) => v1Api.request({
    url: '/rsis/owneruser/queryBySearchField/:userNo'.replace(':userNo', params.userNo),
    method: HttpMethodEnum.GET,
    params
})


/**
 * 新增承辦部門人員
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const rsisAddOwnerUser = (data) => v1Api.request({
    url: '/rsis/owneruser/addOwnerUser',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 依照醫師取得License相關資料
 * param {Object} params
 * return {AxiosPromise}
 */
const rsisQueryLicenseByDoctor = (params) => v1Api.request({
    url: 'rsis/master/queryLicenceByDoctor',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 刪除承辦部門人員
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const rsisDelOwnerUser = (data) => v1Api.request({
    url: '/rsis/owneruser/delOwnerUser/:useNo'.replace(':useNo', data.useNo),
    method: HttpMethodEnum.DELETE,
})

/**
 * 查詢所有功能操作說明維護
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const rsisQueryFunctionDoc = (params) => v1Api.request({
    url: '/rsis/functiondoc/queryFunctionDoc',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 修改功能操作說明維護
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const rsisEditFunctionDoc = (data) => v1Api.request({
    url: '/rsis/functiondoc/editFunctionDoc',
    method: HttpMethodEnum.PUT,
    data
})

/**
 * 查詢所有基本資料設定
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const rsisQueryCodeValue = (params) => v1Api.request({
    url: '/rsis/codevalue/queryCodeValue',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 依照類別查詢基本資料設定
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const rsisQueryCodeValueCodeType = (params) => v1Api.request({
    url: '/rsis/codevalue/queryCodeValue',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 依照類別查詢基本資料設定選項
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const rsisQueryCodeValueCodeTypeOption = (params) => v1Api.request({
    url: '/rsis/codevalue/queryCodeType',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 新增基本資料設定
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const rsisAddCodeValue = (data) => v1Api.request({
    url: '/rsis/codevalue/addCodeValue',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 修改基本資料設定
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const rsisEditCodeValue = (data) => v1Api.request({
    url: '/rsis/codevalue/editCodeValue',
    method: HttpMethodEnum.PUT,
    data
})

/**
 * 刪除基本資料設定
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const rsisDelCodeValue = (data) => v1Api.request({
    url: '/rsis/codevalue/delCodeValue/:codevaluePk'.replace(':codevaluePk', data.codevaluePk),
    method: HttpMethodEnum.DELETE,
})

/**
 * 取得支援機構所有資料
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const rsisQueryOrganization = (params) => v1Api.request({
    url: '/rsis/organization/queryOrganization',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 取得支援機構所有資料
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const rsisQueryOrganizationLike = (params) => v1Api.request({
    url: '/rsis/organization/queryOrganization/like',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 新增支援機構資料
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const rsisAddOrganization = (data) => v1Api.request({
    url: '/rsis/organization/addOrganization',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 修改支援機構資料
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const rsisEditOrganization = (data) => v1Api.request({
    url: '/rsis/organization/editOrganization',
    method: HttpMethodEnum.PUT,
    data
})

/**
 * 刪除支援機構資料
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const rsisDelOrganization = (data) => v1Api.request({
    url: '/rsis/organization/delOrganization/:orgPkey'.replace(':orgPkey', data.orgPkey),
    method: HttpMethodEnum.DELETE,
})

/**
 * 查詢報備支援資料
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const rsisQueryMaster = (params) => v1Api.request({
    url: '/rsis/master/queryMaster',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 新增/複製單筆或多筆報備支援資料
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const rsisAddMaster = (data) => v1Api.request({
    url: '/rsis/master/addMaster',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 修改單筆或多筆報備支援資料
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const rsisEditMaster = (data) => v1Api.request({
    url: '/rsis/master/editMaster',
    method: HttpMethodEnum.PUT,
    data
})

/**
 * 刪除單筆或多筆報備支援資料
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const rsisDelMaster = (data) => v1Api.request({
    url: '/rsis/master/delMaster',
    method: HttpMethodEnum.PUT,
    data
})

/**
 * 變更單筆或多筆報備支援資料
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const rsisChangeMaster = (data) => v1Api.request({
    url: '/rsis/master/changeMaster',
    method: HttpMethodEnum.PUT,
    data
})

/**
 * 註銷單筆或多筆報備支援資料
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const rsisCancelMaster = (data) => v1Api.request({
    url: '/rsis/master/cancelMaster',
    method: HttpMethodEnum.PUT,
    data
})

/**
 * 快速修改報備資源之狀態、衛福部案號、匯出編號
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const rsisQuickEdit = (data) => v1Api.request({
    url: '/rsis/master/editMaster/quickEdit',
    method: HttpMethodEnum.PUT,
    data
})

/**
 * 查詢該筆報備資源
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const rsisQueryMasterKey = (params) => v1Api.request({
    url: '/rsis/master/queryMaster/:supportNo/:itemVersion'.replace(':supportNo', params.supportNo).replace(':itemVersion', params.itemVersion),
    method: HttpMethodEnum.GET,
    params
})

/**
 * 修改該筆報備支援資料狀態
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const rsisEditMasterKey = (data) => v1Api.request({
    url: '/rsis/master/editMaster/:supportNo/:itemVersion/:stepStatus'.replace(':supportNo', data.supportNo).replace(':itemVersion', data.itemVersion).replace(':stepStatus', data.stepStatus),
    method: HttpMethodEnum.PUT,
    data
})

/**
 * 查詢報備支援版次變更記錄
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const rsisQueryMasterHistory = (params) => v1Api.request({
    url: '/rsis/master/queryMaster/:supportNo'.replace(':supportNo', params.supportNo),
    method: HttpMethodEnum.GET,
    params
})

/**
 * 修改單筆或多筆報備資源狀態為確認
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const rsisMailConfirm = (data) => v1Api.request({
    url: '/rsis/master/editMaster/mailConfirm',
    method: HttpMethodEnum.PUT,
    data
})

/**
 * 修改單筆或多筆報備資源退回申請
 * @param data
 * @return {AxiosPromise}
 */
const rsisReturnReply = (data) => v1Api.request({
    url: '/rsis/master/returnApply',
    method: HttpMethodEnum.PUT,
    data
})


/**
 * 報備支援時段檢核
 * @param data {Object} params
 * @return {AxiosPromise}
 */
const rsisCheckTimeValid = (data) => v1Api.request({
    url: '/rsis/master/checkTimeValid',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 特殊權限查詢診室名稱
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const rsisQueryClinicForSpacialAuth = (params) => v1Api.request({
    url: '/regs/regsclinic/queryClinicForSpacialAuth',
    method: HttpMethodEnum.GET,
    params
})


/**
 * 取得查詢報備支援資料的相關參數初始值
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const rsisQueryBaseData = (params) => v1Api.request({
    url: '/rsis/master/queryBaseData',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 新增衛褔部案號回饋記錄
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const rsisAddSendLog = (data) => v1Api.request({
    url: '/rsis/sendlog/addSendLog',
    method: HttpMethodEnum.POST,
    data,
})

/**
 * 修改衛褔部案號回饋作業
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const rsisEditSendLog = (data) => v1Api.request({
    url: '/rsis/sendlog/editSendLog',
    method: HttpMethodEnum.PUT,
    data,
})

/**
 * 查詢衛褔部案號回饋作業
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const rsisQuerySendLog = (params) => v1Api.request({
    url: '/rsis/sendlog/querySendLog',
    method: HttpMethodEnum.GET,
    params,
})

/**
 * Mail 發送人員名單維護--查詢
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const rsisQueryMail = (params) => v1Api.request({
    url: '/rsis/mail/queryMail',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 新增 Mail 發送人員名單
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const rsisAddMail = (data) => v1Api.request({
    url: '/rsis/mail/addMail',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 刪除 Mail 發送人員名單
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const rsisDelMail = (params) => v1Api.request({
    url: '/rsis/mail/delMail',
    method: HttpMethodEnum.DELETE,
    params
})

/**
 * 編輯 Mail 發送人員名單
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const rsisEditMail = (data) => v1Api.request({
    url: '/rsis/mail/editMail',
    method: HttpMethodEnum.PUT,
    data
})

/**
 * 取得功能操作說明維護HTML內容-佈告欄
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const rsisQueryFunctionDocHtml = (params) => v1Api.request({
    url: 'rsis/functiondoc/queryHtmlContentByFunNo',
    method: HttpMethodEnum.GET,
    params
})


export {
    rsisQueryAgent,
    rsisQueryAgentLike,
    rsisQueryAgentByUserNo,
    rsisQueryAllActiveDoctor,
    rsisQueryAllActiveEmployee,
    rsisAddAgent,
    rsisDelAgent,
    rsisQuerySpecialRole,
    rsisAddSpecialRole,
    rsisEditSpecialRole,
    rsisDelSpecialRole,
    rsisQueryOwnerUser,
    rsisQueryLicenseByDoctor,
    rsisQueryOwnerUserLike,
    rsisGetOwnerUserByID,
    rsisAddOwnerUser,
    rsisDelOwnerUser,
    rsisQueryFunctionDoc,
    rsisEditFunctionDoc,
    rsisQueryCodeValue,
    rsisQueryCodeValueCodeType,
    rsisAddCodeValue,
    rsisEditCodeValue,
    rsisDelCodeValue,
    rsisQueryOrganization,
    rsisQueryOrganizationLike,
    rsisAddOrganization,
    rsisEditOrganization,
    rsisDelOrganization,
    rsisQueryMaster,
    rsisAddMaster,
    rsisEditMaster,
    rsisDelMaster,
    rsisChangeMaster,
    rsisCancelMaster,
    rsisQuickEdit,
    rsisQueryMasterKey,
    rsisEditMasterKey,
    rsisQueryMasterHistory,
    rsisMailConfirm,
    rsisCheckTimeValid,
    rsisQueryClinicForSpacialAuth,
    rsisQueryBaseData,
    rsisAddSendLog,
    rsisEditSendLog,
    rsisQuerySendLog,
    rsisQueryCodeValueCodeTypeOption,
    rsisQueryMail,
    rsisAddMail,
    rsisDelMail,
    rsisEditMail,
    rsisQueryFunctionDocHtml,
    rsisReturnReply
}
