import React, {useEffect, useState} from "react";
import addIcon from "../../../assets/images/icons/add_circle.svg";
import {Button, ButtonSizeEnum, ButtonColorEnum, ButtonVariantEnum, IconEnum, Select} from "edah-component/dist";
import deleteIcon from "../../../assets/images/icons/del_circle.svg";
import {t} from "i18next";
const SecondButtonStyle = "flex items-center justify-center px-4 h-10 rounded-[6px] border-2"
const ButtonStyle = "ml-4 flex items-center justify-center font-bold px-4 h-10  rounded-[6px] bg-[#2B6CB0] text-white w-[100px]"
/**
 * 本地儲存設定
 * @param data {object}
 * @param name {string}
 * @param onClickForm {function}
 * @returns {Element}
 */
const LocalStorageFormSetting = ({ data = {},name, onClickForm }) => {
    const [storageData, setStorageData] = useState({})
    const [activeForm, setActiveForm] = useState(null)
    const [sortedDateList, setSortedDateList] = useState([])

    /**
     * 儲存
     * @param targetDate {string}
     */
    const handleSave = (targetDate, saveValue ) => {
        const date = targetDate || activeForm
        const save = saveValue || data
        const value = storageData?.[date]?  { ...storageData,[date]: {...save}} : { ...storageData, [date]: {}}
        localStorage.setItem(name, JSON.stringify(value));
        setStorageData(value)
        handleClickDate(date, save)
    }
    /**
     * 新增內容
     */
    const handleAddNewForm = ()=>{
        const date = new Date();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const targetDate = year + "-" + month + "-1";
        if(!storageData || !storageData[targetDate]){
            handleSave(targetDate, {})
        }
    }
    /**
     * 處理按下日期按鈕
     * @param date {string}
     * @param data {object}
     */
    const handleClickDate = (date, data) =>{
        onClickForm && onClickForm(data);
        setActiveForm(date)
    }
    /**
     * 處理列印
     */
    const handlePrint = () =>{

    }
    /**
     * 取得排序過的日期列表，由大到小
     */
    const getSortedDates = () => {
        const value =  Object.keys(storageData).sort((a, b) => b.replace(/-/g, "") - a.replace(/-/g, ""));
        setSortedDateList(value)
    }
    /**
     * 取得目前使用中日期表單的 index
     */
    const getActiveDateIndex = () =>{
      return sortedDateList.findIndex((item)=> item === activeForm)
    }

    /**
     * 處理下一筆
     */
    const handleNext = () =>{
      const activeIndex = getActiveDateIndex()
        if(activeIndex < sortedDateList.length - 1){
            setActiveForm(sortedDateList[activeIndex + 1])
        }
    }
    /**
     * 處理上一筆
     */
    const handlePre = () =>{
        const activeIndex = getActiveDateIndex()
        if(activeIndex > 0){
            setActiveForm(sortedDateList[activeIndex - 1])
        }
    }
    /**
     * 處理複製
     */
    const handleCopy = () =>{
        const activeIndex = getActiveDateIndex()
        if(activeIndex < sortedDateList.length - 1){
            const copyDate = sortedDateList[activeIndex + 1]
            const value = storageData[copyDate]
            handleSave(activeForm, value)
        }
    }
    /**
     * 處理刪除
     */
    const handleDelete = () =>{
        const otherData = { ...storageData}
        delete otherData[activeForm]
        setActiveForm(otherData)
        setStorageData(otherData)
        onClickForm && onClickForm(null);
        localStorage.setItem(name, JSON.stringify(otherData));
    }
    /**
     * 初始化
     */
    useEffect(() => {
        const value = localStorage.getItem(name) || '';
        if(value){
            const parseValue = JSON.parse(value)
            setStorageData(parseValue)
            getSortedDates(parseValue)
        }
    }, []);
    return (
        <div>
            <div className="text-[#71717A] text-[14px] w-fit">評估日期</div>
            {/* 新增 */}
            <div className="flex items-center ">
                <Button color={ButtonColorEnum.Success} variant={ButtonVariantEnum.Text}
                        size={ButtonSizeEnum.Large} text={t('general.add')} icon={IconEnum.Add}
                        onClick={handleAddNewForm}
                />
                <div className="w-[1px] h-[20px] bg-[#D4D4D8] ml-2"/>
                <div className="flex justify-center gap-2 ml-2">
                    {storageData &&
                        <Button color={ButtonColorEnum.Secondary} variant={ButtonVariantEnum.Outlined}
                                size={ButtonSizeEnum.Medium} text={'上頁'}
                                onClick={handlePre}/>
                    }
                    {storageData && Object.keys(storageData).reverse().map((item, index)=>{
                        return  <>
                            <Button color={ButtonColorEnum.Secondary} variant={ButtonVariantEnum.Outlined}
                                    size={ButtonSizeEnum.Medium} text={item} onClick={()=>handleClickDate(item, storageData[item])}

                            />
                        </>
                    })}
                    {storageData && <Button color={ButtonColorEnum.Secondary} variant={ButtonVariantEnum.Outlined}
                                            size={ButtonSizeEnum.Medium} text={'下頁'} onClick={handleNext}/>
                    }
                </div>
            </div>
            {/* 刪除與其他按鈕*/}
            <div className="flex justify-between items-center pt-2 mt-2.5 mb-2.5 w-full border-t border-[#111111]/15">
                <Button sx={{display: 'flex', justifyContent: 'flex-start'}}
                        color={ButtonColorEnum.Primary} variant={ButtonVariantEnum.Text}
                        size={ButtonSizeEnum.Large} text={t('general.delete')} icon={IconEnum.Delete}
                        onClick={handleDelete}
                />
                <div className="flex gap-2 flex-shrink-0">
                    <Button color={ButtonColorEnum.Secondary} variant={ButtonVariantEnum.Outlined}
                            size={ButtonSizeEnum.Medium} text={'Ditto'} onClick={handleCopy}
                    />
                    <Button color={ButtonColorEnum.Secondary} variant={ButtonVariantEnum.Outlined}
                            size={ButtonSizeEnum.Medium} text={t('general.print')} onClick={handlePrint}
                    />
                    <Button color={ButtonColorEnum.Primary} variant={ButtonVariantEnum.Contained}
                            size={ButtonSizeEnum.Medium} text={t('general.saveFile')} onClick={handleSave}
                    />
                </div>
            </div>
        </div>
    )
}

export default LocalStorageFormSetting