import React, {useState} from 'react';
import {BaseInput} from "../../Input/BaseInput";
import moment from 'moment';
import {InputTypeEnum} from "edah_utils/dist";
import useToast from '../../../hooks/useToast';
import {AlertTypeEnum, Dialog, DialogVariant, DialogSizeEnums,DatePicker,TimePicker} from 'edah-component/dist'
import dayjs from "dayjs";


/**
 * 編輯事件彈窗組件
 * @param {Object} props - 組件屬性
 * @param {Object} props.event - 要編輯的事件對象
 * @param {Object} props.apnTimeRange - 時段時間區間
 * @param {Function} props.onConfirm - 確認編輯的回調函數
 * @param {Function} props.onCancel - 取消編輯的回調函數
 * @returns {JSX.Element} 編輯事件彈窗組件
 */
const EditEventPopup = ({
                            event,
                            apnTimeRange,
                            onConfirm,
                            onCancel,
                            onDelete
                        }) => {
    // 事件標題狀態
    const [title, setTitle] = useState(event.title);
    // 事件日期狀態
    const [selectDate, setSelectDate] = useState(dayjs(event.start).format('YYYY-MM-DD'));
    // 事件開始時間狀態
    const [startTime, setStartTime] = useState(dayjs(event.start).format('HH:mm'));
    // 事件結束時間狀態
    const [endTime, setEndTime] = useState(dayjs(event.end).format('HH:mm'));
    //Toast Message Hooks
    const showToast = useToast()

    /**
     * 處理表單提交
     * @param {React.FormEvent} e - 表單提交事件
     */
    const handleSubmit = (e) => {
        e.preventDefault();
        /**
         * 將選擇的日期和時間組合成一個日期時間物件
         */
        const startDateTime = dayjs(`${selectDate} ${startTime}`, 'YYYY-MM-DD HH:mm').toDate();
        const endDateTime = dayjs(`${selectDate} ${endTime}`, 'YYYY-MM-DD HH:mm').toDate();
        //檢查start、end時間
        if (startDateTime < apnTimeRange.start || startDateTime > apnTimeRange.end) {
            showToast({message: "起始時間不在時段內", type: AlertTypeEnum.Error})
            return
        }
        if (endDateTime < apnTimeRange.start || endDateTime > apnTimeRange.end) {
            showToast({message: "結束時間不在時段內", type: AlertTypeEnum.Error})
            return
        }
        if (startDateTime >= endDateTime) {
            showToast({message: "起始時間不能大於等於結束時間", type: AlertTypeEnum.Error})
            return
        }

        onConfirm({
            ...event,
            title,
            start: startDateTime,
            end: endDateTime
        });
    };

    /**
     * 刪除按鈕點擊事件
     */
    const handleRemoveOnClick = () => {
        onDelete(event.id)
    }

    // 彈窗內容
    const content = (
        <form className="px-4 pb-4" onSubmit={handleSubmit}>
            <p className="text-left mb-4 font-bold text-[24px]">{title}</p>
            <div className="flex flex-row justify-between items-center gap-2">
                <DatePicker
                    label=""
                    value={dayjs(selectDate)}
                    disabled
                    onChange={(e) => setSelectDate(e.target.value)}
                />
                <TimePicker
                    label="開始時間"
                    value={dayjs(`${startTime}`, 'HH:mm')}
                    onChange={(time) => setStartTime(dayjs(time).format('HH:mm'))}                 />
                <p>-</p>
                <TimePicker
                    label="結束時間"
                    value={dayjs(`${endTime}`, 'HH:mm')}
                    onChange={(time) => setEndTime(dayjs(time).format('HH:mm'))}
                />
            </div>
        </form>
    );

    return (
        <Dialog
            open={true}
            title={"編輯掛號"}
            content={content}
            variant={DialogVariant.EDIT}
            paperStyleProps={{width: DialogSizeEnums.SM}}
            onClose={onCancel}
            onSave={handleSubmit}
            onDelete={handleRemoveOnClick}
            muiDialogActionsProps={{
                sx: {
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'end',
                    gap: '0.5rem',
                    padding: '1rem'
                }
            }}
        />
    )
};

export default EditEventPopup;
