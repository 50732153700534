import React from 'react';
import {ReactComponent as CloseIcon} from '../../assets/images/icons/close.svg';
import {t} from "i18next"

const NotificationItemModal = ({message, detail, triggerTime, closeModal}) => (
    <div
        className="absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 w-[100vw] h-[100vh] bg-[rgb(200,200,200)]/40 fixed flex justify-center items-center">
        <div className="w-[340px] rounded-[10px] bg-[#4855a2] shadow-[#000000]/50 shadow-[0px 5px 15px] flex flex-col">
            <div className='flex justify-between mx-[13px] my-[6px]'>
                <div className="text-center text-white text-[20px]">
                    <h1>{t('general.notification')}</h1>
                </div>
                <div className="flex text-white mr-[2px]">
                    <button className='bg-[transparent] border-none cursor-pointer'
                            onClick={closeModal()}>
                        <CloseIcon/>
                    </button>
                </div>
            </div>
            <div className='bg-white rounded-b-[10px] mx-[4px] mb-[4px]'>
                <div className="mx-[30px] mt-[7px]">
                    <div className='flex items-center'>
                        <p className='font-bold text-[20px] text-[#545454] w-[145px]'>{message}</p>
                        <p className='text-xs text-[#898989] ml-[5px]'>{triggerTime}</p>
                    </div>
                    <p className='mt-[7px] text-[15px] text-[#6e6e6e]'>{detail}</p>
                </div>
                <div className="justify-end flex mt-[7px]">
                    <button
                        className='bg-[#4855a2] border-none rounded-[5px] w-[100px] mb-[15px] mr-[15px] p-[5px] text-white'
                        onClick={closeModal()} id="cancelBtn">
                        {t('general.confirm')}
                    </button>
                </div>
            </div>
        </div>
    </div>
);

export default NotificationItemModal;
