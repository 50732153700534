import HeaderLayout from "./HeaderLayout";
import Sidebar from "../components/Sidebar/Sidebar";
import {Content} from "../components/Default/Content";
import NotificationItemModal from "../components/Notifications/NotificationItemModal";
import NotificationsModal from "../components/Notifications/NotificationsModal";
import Tab from "../components/Tab/Tab";
import TabContainer from "../components/Tab/TabContainer";
import routes from "../router";
import React, {useState} from 'react';
import Print from "../components/Print/Print";
import {useSelector} from "react-redux";
import store from "../redux/store";
import {addTab, setActiveTabId, updateTabList} from "../redux/Slice/TabSlice";

/**
 * 根據ID查找Route Element
 * @param id {String}
 * @return {null|Element}
 */
const findRouteElementById = (id) => {
    const route = routes.find((route) => route.id === id);
    return route ? route.element : null;
}

/**
 * DefaultLayout
 * @param content
 * @return {JSX.Element}
 */
const DefaultLayout = ({content}) => {
    //當前選中的tab
    const activeTabId = useSelector((state) => state.tab.activeTabId)
    //tab列表
    const tabList = useSelector((state) => state.tab.tabList)
    //是否為列印模式
    const isPrintMode = useSelector((state) => state.print.isPrintMode)
    //sideBar toggle
    const [isOpen, setIsOpen] = useState(true);
    //menu toggle
    const [toggleMenuOpen, setToggleMenuOpen] = useState(false)
    //當activeTabId改變時，重新設置activeTabContent
    const activeTabContent = findRouteElementById(activeTabId);
    // NotificationsModal
    const [isNotificationItemOpen, setIsNotificationItemOpen] = useState(false);
    // NotificationItemModal的資訊
    const [notificationItemInfo, setNotificationItemInfo] = useState({message: '', detail: '', triggerTime: ''});
    // 為了讓NotificationItemModal能夠顯示，所以需要一個狀態來控制是否顯示
    const [isNotificationViewMoreOpen, setIsNotificationViewMoreOpen] = useState(false);

    /**
     * 切換sidebar
     * @return {void}
     */
    const toggleSidebar = () => {
        setIsOpen(!isOpen)
        setToggleMenuOpen(!toggleMenuOpen)
    }

    /**
     * 新增Tab
     * @param id {String}
     * @param name {String}
     * @return {void}
     */
    const addNewTab = (id, name) => {
        store.dispatch(addTab({id, name}))
    }

    /**
     * 移除Tab
     * @param id {String}
     * @return {void}
     */
    const removeTab = (id) => {
        const newTabList = tabList.filter((tab) => tab.id !== id);
        store.dispatch(updateTabList(newTabList))

        if (id === activeTabId) {
            store.dispatch(setActiveTabId({id: newTabList.length > 0 ? newTabList[newTabList.length - 1].id : null}))
        }
    }

    /**
     * 打開NotificationItemModal
     * @param message {String}
     * @param detail {String}
     * @param triggerTime {String}
     * @return {void}
     */
    const openNotificationItemModal = (message, detail, triggerTime) => {
        setIsNotificationItemOpen(prev => true)
        setNotificationItemInfo({message: message, detail: detail, triggerTime: triggerTime})
    }

    /**
     * 關閉NotificationItemModal
     * @return {void}
     */
    const closeNotificationItemModal = () => setIsNotificationItemOpen(prev => false)

    /**
     * 打開NotificationViewMoreModal
     * @return {void}
     */
    const openNotificationViewMoreModal = () => setIsNotificationViewMoreOpen(prev => true)

    /**
     * 關閉NotificationViewMoreModal
     * @return {void}
     */
    const closeNotificationViewMoreModal = () => setIsNotificationViewMoreOpen(prev => false)

    return (
        <>
            <div className={`text-center ${isPrintMode ? 'no-print' : ''}`}>
                <HeaderLayout openNotifitionItemModal={openNotificationItemModal}
                              openNotifitionViewMoreModal={openNotificationViewMoreModal}/>
                <div className='flex mt-px'>
                    <Sidebar isOpen={isOpen} Add={addNewTab} toggleSidebar={toggleSidebar} activeTabId={activeTabId}/>
                    <div className={`flex flex-col ${toggleMenuOpen ? "w-full" : "w-[calc(100%-232px)]"}`}>
                        <Tab tabList={tabList} activeTabId={activeTabId} handleTabOnClose={removeTab}/>
                        <Content content={<TabContainer content={activeTabContent}/>}/>
                    </div>
                </div>
            </div>
            <Print/>
            {isNotificationViewMoreOpen && <NotificationsModal closeModal={closeNotificationViewMoreModal}/>}
            {isNotificationItemOpen && (<NotificationItemModal closeModal={closeNotificationItemModal}
                                                               message={notificationItemInfo.message}
                                                               detail={notificationItemInfo.detail}
                                                               triggerTime={notificationItemInfo.triggerTime}/>)}
        </>
    )
}
export default DefaultLayout;
