import React, {useEffect, useMemo, useState} from "react"
import {BasePopup} from "../../Popup/BasePopup"
import {t} from "i18next"
import {
    ApiErrorStatusEnum,
    convertGregorianToMinGuo,
    convertMinGuoToGregorian,
    objectIsEmpty,
    stringIsEmpty,
    time2String
} from "edah_utils/dist"
import {SwitchContainer} from "../../ICCard/InfoPage/SwitchContainer"
import {icHisWriteNewBorn} from "../../../api/v1/LocalService"
import '../../ICCard/PatientInfo.scss'
import useToast from "../../../hooks/useToast"
import {
    AlertTypeEnum, Button, Checkbox, Field, SizeEnum, TextField,
    ColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    IconEnum
} from "edah-component/dist"

/**
 * IC卡檢視彈出窗
 * @param show {Boolean} 是否顯示彈窗
 * @param closePopupButtonOnClick {Function} 關閉事件
 * @param icCardData {Object} IC卡資料
 * @returns {Element}
 * @constructor
 */
const IcCardViewPopup = ({show = false, closePopupButtonOnClick, icCardData}) => {
    //是否顯示彈窗
    const [showPopup, setShowPopup] = useState(show)
    // 資料
    const [data, setData] = useState({})
    // 控制是否可編輯日期及胎兒數字
    const [isEditable, setIsEditable] = useState(false);
    // 控制是否可編輯電話
    const [isPhoneEdit, setIsPhoneEdit] = useState(false);
    // 新生兒資料修改部分
    const [modifyChildData, setModifyChildData] = useState({})
    // 新生兒資料
    const [info, setInfo] = useState({})
    //Toast Message Hooks
    const showToast = useToast()

    /**
     *  新生兒出生日期變更
     *  @param event {Event} 事件
     *  @return {void}
     */
    const handleChildBirthDateOnChange = (event) => {
        // 只有編輯模式才可以修改
        if (!isEditable) {
            return
        }

        // 設定新生兒出生日期
        setModifyChildData({
            childBirthDate: convertGregorianToMinGuo(event.target.value),
            childBornNote: modifyChildData.childBornNote,
        })
    }

    /**
     * 新生兒胞胎註記數量變更
     * @param event {Event} 事件
     * @return {void}
     */
    const handleChildBornNoteOnChange = (event) => {
        // 只有編輯模式才可以修改
        if (!isEditable) {
            return
        }

        // 設定新生兒胞胎註記
        setModifyChildData({
            childBirthDate: modifyChildData.childBirthDate,
            childBornNote: event.target.value
        })
    }

    /**
     * 新生兒Icon點擊事件
     * @return {void}
     */
    const handleChildIconOnClick = () => {
        // 取得新的狀態
        const newResult = !isEditable

        // 取得新生兒資料
        const newData = newResult ? {
            //新生兒出生日期
            childBirthDate: data.childBirthDate,
            //新生兒胞胎註記
            childBornNote: data.childBornNote
        } : {}

        // 如果上一步是儲存狀態
        if (!newResult) {
            //比較值是否有變更
            const keys = Object.keys(modifyChildData)
            //是否有被變更過資料
            const isModify = keys.every(key => modifyChildData[key] === data[key])
            //有修改過則呼叫API
            if (!isModify) {
                icHisWriteNewBorn({
                    //身分證號
                    pPatientID: data.idNo,
                    //生日(明國年月日)
                    pPatientBirthDate: data.birthDate,
                    //新生兒出生日期(明國年月日)
                    pNewBornDate: modifyChildData.childBirthDate,
                    //新生兒胞胎註記
                    pNoOfDelivered: modifyChildData.childBornNote
                }).then(res => {
                    if (res.err === ApiErrorStatusEnum.Success) {
                        // 成功的話直接修改當前的資料
                        setInfo({
                            ...info,
                            childBirthDate: modifyChildData.childBirthDate,
                            childBornNote: modifyChildData.childBornNote
                        })
                    } else {
                        showToast({message: res.msg, type: AlertTypeEnum.Error})
                    }
                })
            }
        }

        // 設定新狀態
        setIsEditable(newResult)
        // 設定新生兒資料
        setModifyChildData(newData)
    }

    /**
     * 取得內容
     * @return {JSX.Element}
     */
    const getContent = () => (
        <>
            <div className="w-full px-5 pb-5">
                {/*病人資料*/}
                <div className="w-full px-6 border-[1px] border-[#D4D4D8] rounded-[12px] mb-4 py-4 bg-white">
                    <div className="flex flex-row w-full overflow-scroll">
                        {/*病人基本資料*/}
                        <div className="info min-w-[1680px]">
                            <div className="grid  grid-cols-6">
                                <div>
                                    <Field label="卡號" sx={{mb: 2}}>
                                        <div className="text-left">
                                            {objectIsEmpty(info) ? "" : info.icCardNo}
                                        </div>
                                    </Field>
                                    <Field label="卡片有效期限" sx={{mb: 2}}>
                                        <div className="text-left">
                                            {objectIsEmpty(info) ? "" : stringIsEmpty(info.cardExpirationDate) ? "" : time2String(info.cardExpirationDate, "YYYY-MM-DD")}
                                        </div>
                                    </Field>
                                </div>
                                <div>
                                    <Field label="姓名" sx={{mb: 2}}>
                                        <div className="text-left">
                                            {objectIsEmpty(info) ? "" : info.patientName}
                                        </div>
                                    </Field>
                                    <Field label="緊急聯絡電話" sx={{mb: 2}}>
                                        <div className="text-left">
                                            {objectIsEmpty(info) ? "" : info.emergentTel}
                                        </div>
                                        {/*<input onChange={handlePhoneChange}*/}
                                        {/*       value={phoneValue}*/}
                                        {/*       readOnly={!isPhoneEdit} // 根據狀態設定是否只讀*/}
                                        {/*/>*/}
                                        {/*{!isPhoneEdit && (*/}
                                        {/*    <button onClick={handlePhoneEdit}*/}
                                        {/*            className="flex flex-row items-center justify-start text-[#2B6CB0]">*/}
                                        {/*        <EditBtn/> 編輯</button>*/}
                                        {/*)}*/}
                                        {/*{isPhoneEdit && (*/}
                                        {/*    <button onClick={handlePhoneCheck}*/}
                                        {/*            className="flex flex-row items-center justify-start text-[#2B6CB0]">*/}
                                        {/*        <EditCheck/></button>*/}
                                        {/*)}*/}
                                    </Field>
                                </div>
                                <div>
                                    <Field label="身分證號" sx={{mb: 2}}>
                                        <div className="text-left">
                                            {objectIsEmpty(info) ? "" : info.idNo}
                                        </div>
                                    </Field>
                                    <Field label="卡片註銷註記" sx={{mb: 2}}>
                                        <div className="text-left">正常卡</div>
                                    </Field>
                                </div>
                                <div>
                                    <Field label="性別" sx={{mb: 2}}>
                                        <div className="text-left">
                                            {objectIsEmpty(info) ? "" : info.gender === 'F' ? t('general.gender.female') : t('general.gender.male')}
                                        </div>
                                    </Field>
                                    <Field label="就醫可用次數" sx={{mb: 2}}>
                                        <div className="text-left">
                                            {objectIsEmpty(info) ? "" : info.availableTimesOfMed}
                                        </div>
                                    </Field>
                                </div>
                                <div>
                                    <Field labelWidth={130} label="生日" sx={{mb: 2}}>
                                        <div className="text-left">
                                            {objectIsEmpty(info) ? "" : time2String(info.birthDate, "YYYY-MM-DD")}
                                        </div>
                                    </Field>
                                    <Field labelWidth={130} label="最後一次就醫序號" sx={{mb: 2}}>
                                        <div className="text-left">
                                            {objectIsEmpty(info) ? "" : info.clinicSeqNo}
                                        </div>
                                    </Field>
                                </div>
                                <div>
                                    <Field labelWidth={130} label="發卡日期" sx={{mb: 2}}>
                                        <div className="text-left">
                                            {objectIsEmpty(info) ? "" : stringIsEmpty(info.cardIssueDate) ? "" : time2String(info.cardIssueDate, "YYYY-MM-DD")}
                                        </div>
                                    </Field>
                                    <Field labelWidth={130} label="保險對象身份註記" sx={{mb: 2}}>
                                        <div className="text-left">
                                            {objectIsEmpty(info) ? "" : stringIsEmpty(info.insurerNote) ? "" : t(`ICCard.insurerNote.${info.insurerNote}`)}
                                        </div>
                                    </Field>
                                </div>
                            </div>
                            <ul className="flex flex-row items-center justify-start w-full h-[48px] mb-2">
                                <div
                                    className="bg-[#F4F4F5] flex flex-row items-center justify-start h-full px-4 mr-4 min-w-[518px]">
                                    <div className="flex flex-row justify-start items-center mr-3">

                                        <Field label="新生兒出生日期" labelWidth={115}>
                                            <input
                                                className="w-[125px] h-10 pl-2 border-[2px] border-[#D4D4D8] rounded-[6px] ml-2"
                                                type="date" name="birthDate" max="9999-12-31" disabled={!isEditable}
                                                value={isEditable ?
                                                    stringIsEmpty(modifyChildData.childBirthDate) ? "" : convertMinGuoToGregorian(modifyChildData.childBirthDate) :
                                                    (objectIsEmpty(info) || stringIsEmpty(info.childBirthDate)) ? "" : convertMinGuoToGregorian(info.childBirthDate)}
                                                onChange={(e) => handleChildBirthDateOnChange(e)} // 根據狀態設定是否只讀
                                            />
                                        </Field>
                                    </div>
                                    <div className="flex flex-row justify-start items-center">
                                        <TextField
                                            inputWidth={SizeEnum.Small}
                                            label="新生兒胞胎註記"
                                            value={isEditable ? modifyChildData.childBornNote : (objectIsEmpty(info) ? "" : info.childBornNote)}
                                            onChange={(e) => handleChildBornNoteOnChange(e)} // 根據狀態設定是否只讀
                                            disabled={!isEditable}
                                        />
                                        {
                                            !icCardData.isPhysical ?
                                                <></> :
                                                !isEditable ?
                                                    <Button
                                                        sx={{marginLeft: '0.5rem'}}
                                                        color={ColorEnum.Primary} variant={ButtonVariantEnum.Text}
                                                        size={ButtonSizeEnum.Large} icon={IconEnum.Edit}
                                                        onClick={handleChildIconOnClick}>
                                                        {t('general.edit')}
                                                    </Button> :
                                                    <Button
                                                        sx={{marginLeft: '0.5rem'}}
                                                        color={ColorEnum.Primary} variant={ButtonVariantEnum.Text}
                                                        size={ButtonSizeEnum.Large} icon={IconEnum.Save}
                                                        onClick={handleChildIconOnClick}>
                                                        {t('general.save')}
                                                    </Button>
                                        }
                                    </div>
                                </div>
                                <div
                                    className="bg-[#F4F4F5] flex flex-row items-center justify-start h-full px-4 min-w-[1068px]">
                                    <Field label="病人註記" labelWidth={70}>
                                        <Checkbox
                                            label="同意器官捐贈"
                                            checked={objectIsEmpty(info) ? false : info.organDonateFlag}
                                            disabled
                                        />
                                        <Checkbox
                                            label="同意安寧緩和醫療"
                                            checked={objectIsEmpty(info) ? false : info.hospiceCareFlag}
                                            disabled
                                        />
                                        <Checkbox
                                            label="同意不施行心肺復甦手術"
                                            checked={objectIsEmpty(info) ? false : info.notUseCPRFlag}
                                            disabled
                                        />
                                        <Checkbox
                                            label="同意不施行維生醫療"
                                            checked={objectIsEmpty(info) ? false : info.notUseLifeSustainingMedicalFlag}
                                            disabled
                                        />
                                        <Checkbox
                                            label="同意預立醫療決定"
                                            checked={objectIsEmpty(info) ? false : info.advanceMedicalDecisionFlag}
                                            disabled
                                        />
                                    </Field>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
                <SwitchContainer icCardData={icCardData}/>
            </div>
        </>
    )

    useMemo(() => {
        /*    //讀取IC卡基本資料
            icHisGetHisRegisterBasic({}).then(res => {
                //是否讀取成功
                const apiStatus = res.err === ApiErrorStatusEnum.Success
                //設定資料
                setData(apiStatus ? res.data : {})
            })*/
    }, [])

    /**
     * 監聽是否顯示彈窗Pros變化
     */
    useEffect(() => {
        if (show) {
            setData(icCardData)
        } else {
            setData(null)
            setIsEditable(false)
            setIsPhoneEdit(false)
            setModifyChildData({})
            setData({})
        }
        setShowPopup(show)
    }, [show])

    /**
     * 資料變更時監聽
     */
    useEffect(() => {
        setInfo(data)
    }, [data])

    return showPopup ?
        <BasePopup
            title={"IC卡檢視"}
            content={getContent()}
            width="100%"
            closePopupButtonOnClick={closePopupButtonOnClick}/> :
        <></>
}

export default IcCardViewPopup
