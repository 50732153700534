import React, {useEffect, useState} from "react"
import {Dialog, DialogVariant} from 'edah-component/dist'

/**
 * PDF Preview Popup
 * @param blob {Blob} Blob
 * @param closePopupButtonOnClick {function|null} 關閉時的方法
 * @param showPopup {Boolean} 是否顯示彈窗
 * @param width {String} 彈窗寬度
 * @param height {String} 彈窗高度
 * @return {JSX.Element}
 */
const PDFPreviewPopup = ({blob, closePopupButtonOnClick, showPopup = false, width = '80%', height = "80%"}) => {
    // 是否顯示彈窗
    const [show, setShow] = useState(showPopup)

    console.log(height)

    /**
     * 關閉彈窗時
     * @return {void}
     */
    const handleClosePopup = () => {
        // 關閉彈窗
        setShow(false)
        //方法存在時
        if (closePopupButtonOnClick) {
            closePopupButtonOnClick()
        }
    }

    /**
     * 監聽狀態變化
     * @return {void}
     */
    useEffect(() => setShow(showPopup), [showPopup])

    return show && blob ? (
        <Dialog
            open={true}
            title={' '}
            content={<iframe src={URL.createObjectURL(blob)} width="100%" height="800px" className="rounded-[6px]"/>}
            variant={DialogVariant.CONFIRM}
            paperStyleProps={{width: width}}
            onClose={handleClosePopup}
            muiDialogContentProps={{sx: {height: `${height}`}}}
            muiDialogTitleProps={{sx: {display: 'flex', justifyContent: 'end'}}}
            muiDialogActionsProps={{sx: {display: 'none'}}}
        />
    ) : <></>
}
export default PDFPreviewPopup
