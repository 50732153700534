import {useState} from "react"
import {arrayIsEmpty, convertMinGuoToGregorian, time2String, stringIsEmpty} from "edah_utils/dist"

/**
 * 控制就醫資料和重大傷病切換
 * @type {{MedicalData: number, MajorInjury: number}}
 * @enum {number}
 * @return {Object}
 */
const ActiveHPCTabEnum = Object.freeze({
    /**
     * 就醫資料
     */
    MedicalData: 0,
    /**
     * 重大傷病
     */
    MajorInjury: 1
})

/**
 * HPC卡
 * @param show {Boolean} 是否顯示
 * @param severeIllness {Array} 重大傷病註記
 * @param treatment {Array} 就醫資料登錄
 * @param getFilterDataFunc {Function} 取得過濾資料function
 * @return {JSX.Element}
 */
export const HasHpcSection = ({severeIllness, treatment, getFilterDataFunc}) => {
    // 控制就醫資料和重大傷病切換
    const [activeHPCTab, setActiveHPCTab] = useState(ActiveHPCTabEnum.MedicalData)

    /**
     * 簡化HPC卡btn class
     * @param {string} tab
     */
    const getHPCBtnClass = (tab) => `w-[96px] h-[42px] px-4 py-2 text-[16px] ${activeHPCTab === tab ? 'border-b-[2px] border-[#2B6CB0] text-[#2B6CB0]' : ''}`

    /**
     * HPC卡切換時
     * @param tab {Number} tab
     * @return {void}
     */
    const handleTabOnClick = (tab) => setActiveHPCTab(tab)

    /**
     * 取得資料區塊
     * @return {JSX.Element}
     */
    const getSection = () => {
        return activeHPCTab === ActiveHPCTabEnum.MedicalData ?
            // 就醫資料
            (
                <ul className="tableList hasHpc">
                    <li className="title">
                        <div>就診日期</div>
                        <div>主要診</div>
                        <div>主要診斷名稱</div>
                        <div>次要診斷1</div>
                        <div>次要診斷名稱1</div>
                        <div>次要診斷2</div>
                        <div>次要診斷名稱2</div>
                        <div>次要診斷3</div>
                        <div>次要診斷名稱3</div>
                        <div>次要診斷4</div>
                        <div>次要診斷名稱4</div>
                        <div>次要診斷5</div>
                        <div>次要診斷名稱5</div>
                    </li>
                    {
                        getFilterDataFunc(treatment).map((item, index) =>
                            <li key={index}>
                                {/*就診日期*/}
                                <div>{time2String(item.encDatetime, "YYYY-MM-DD")}</div>
                                {/*主要診*/}
                                <div>{stringIsEmpty(item.diseaseCode) ? "" : item.diseaseCode}</div>
                                {/*主要診斷名稱*/}
                                <div>{stringIsEmpty(item.diseaseName) ? "" : item.diseaseName}</div>
                                {/*次要診斷1*/}
                                <div>{stringIsEmpty(item.diseaseCode1) ? "" : item.diseaseCode1}</div>
                                {/*次要診斷名稱1*/}
                                <div>{stringIsEmpty(item.diseaseName1) ? "" : item.diseaseName1}</div>
                                {/*次要診斷2*/}
                                <div>{stringIsEmpty(item.diseaseCode2) ? "" : item.diseaseCode2}</div>
                                {/*次要診斷名稱2*/}
                                <div>{stringIsEmpty(item.diseaseName2) ? "" : item.diseaseName2}</div>
                                {/*次要診斷3*/}
                                <div>{stringIsEmpty(item.diseaseCode3) ? "" : item.diseaseCode3}</div>
                                {/*次要診斷名稱3*/}
                                <div>{stringIsEmpty(item.diseaseName3) ? "" : item.diseaseName3}</div>
                                {/*次要診斷4*/}
                                <div>{stringIsEmpty(item.diseaseCode4) ? "" : item.diseaseCode4}</div>
                                {/*次要診斷名稱4*/}
                                <div>{stringIsEmpty(item.diseaseName4) ? "" : item.diseaseName4}</div>
                                {/*次要診斷5*/}
                                <div>{stringIsEmpty(item.diseaseCode5) ? "" : item.diseaseCode5}</div>
                                {/*次要診斷名稱1*/}
                                <div>{stringIsEmpty(item.diseaseName5) ? "" : item.diseaseName5}</div>
                            </li>
                        )
                    }
                </ul>
            ) :
            // 重大傷病
            (
                <ul className="tableList majorInjury">
                    <li className="title">
                        <div>重大傷病代碼</div>
                        <div>重大傷病</div>
                        <div>有效期限起始日</div>
                        <div>有效期限終止日</div>
                    </li>
                    {
                        getFilterDataFunc(severeIllness).map((item, index) =>
                            <li key={index}>
                                {/*重大傷病代碼*/}
                                <div>{item.severeIllnessCode}</div>
                                {/*重大傷病*/}
                                <div>{item.severeIllnessName}</div>
                                {/*有效期限起始日*/}
                                <div>{time2String(item.expirationDateStart, "YYYY-MM-DD")}</div>
                                {/*有效期限終止日*/}
                                <div>{time2String(item.expirationDateEnd, "YYYY-MM-DD")}</div>
                            </li>
                        )
                    }
                </ul>
            )
    }

    return !arrayIsEmpty(severeIllness) && !arrayIsEmpty(treatment) ? (
        <div className="hasHpc">
            {/*點擊按鈕切換下方資料表*/}
            <div className="hasHpcSwitch mb-4 flex flex-row items-center justify-start">
                <button className={getHPCBtnClass(ActiveHPCTabEnum.MedicalData)}
                        onClick={() => handleTabOnClick(ActiveHPCTabEnum.MedicalData)}>
                    就醫資料
                </button>
                <button className={getHPCBtnClass(ActiveHPCTabEnum.MajorInjury)}
                        onClick={() => handleTabOnClick(ActiveHPCTabEnum.MajorInjury)}>
                    重大傷病
                </button>
            </div>
            {/*資料表切換*/}
            {getSection()}
        </div>
    ) : null
}
