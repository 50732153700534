import React, {useEffect, useState} from 'react'
import {t} from "i18next";
import {
    addTranForeign,
    deleteTranForeign,
    editTranForeign,
    queryTranForeignByApplicantNo
} from "../../../api/v1/Rmis";
import {ApiErrorStatusEnum, time2String} from "edah_utils/dist";
import useToast from "../../../hooks/useToast";
import {
    AlertTypeEnum,
    Field,
    SizeEnum,
    TextArea,
    IconEnum,
    Dialog,
    DialogVariant,
    DialogSizeEnums,
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    Checkbox,
    Divider,
    Search,
    TextField,
    DatePicker
} from "edah-component/dist"
import dayjs from "dayjs"

/**
 * 病症暨失能診斷證明書_維護
 * @param Props {object}
 * @param Props.onClose {function} 關閉彈窗 callback function
 * @param Props.isEdit {boolean} 編輯or新增
 * @param Props.applicantNo {string} 申請單號
 * @param Props.divList {array} 科別清單
 * @param Props.doctorList {array} 醫師清單
 * @returns {JSX.Element}
 */
const CertificateEditorModal = ({onClose, isEdit = false, applicantNo, divList, doctorList}) => {
    // 病症暨失能診斷證明書資料內容
    const [caseDetail, setCaseDetail] = useState({
        patientId: '', // 病歷號
        patientName: '', // 姓名
        birthDate: '', // 出生日期
        idNo: '', // 身分證號
        doctorNo: '', // 醫師代碼
        doctorName: '', // 醫師姓名
        divNo: '', // 科別代碼
        divName: '', // 科別名稱
        cancelFlag: '', // 註銷
        barthelFlag: '', // 巴0醫師勾選
        barthelScore: '', // 巴氏量表分數
        cdrFlag: '', // CDR表
        hospNoCare: '', // 長照中心
        hospTel: '', // 長照中心電話
        applicantsUserName: '', // 申請人姓名
        applicantAdress: '', // 申請人地址
        recipientDate: '', // 收到退件日
        returnTime: '', // 退件次數
        returnReason: '', // 退件原因
        returnReason2: '', // 退件原因2
        returnReason3: '', // 退件原因3
        returnReason4: '', // 退件原因4
        returnReasonName: '', // 退件原因說明
        remark: '', // 備註
        mpUser: '', // 醫事課人員
        tranUser: '', // 轉診櫃台人員
        sendDate: '', // 寄件日期
        diagDate: '', // 證書日期
        applicantDate: '', // 申請日期
        lockVersion: '', // 鎖定版本
    })

    // 是否存檔
    const [isSaved, setIsSaved] = useState(false)

    // 刪除確認popup控制
    const [showDeletePopup, setShowDeletePopup] = useState(false)

    // 儲存成功 or 失敗 Toast
    const showToast = useToast()

    /**
     * 更新 caseDetail 的值
     * @param {Event} e - 事件對象
     * @param {string} field - 要更新的欄位名稱
     * @return {void}
     */
    const updateCaseDetail = (e, field) => {
        const value = e.target.value
        setCaseDetail({
            ...caseDetail,
            [field]: value,
        })
    }

    /**
     * 日期改變時通用處理
     * @param {Dayjs | null} newValue - 選中的新日期
     * @param {string} field - caseDetail 中的字段名稱 (ex 'birthDate', 'diagDate', 'recipientDate')
     * @return {void}
     */
    const handleDateChange = (newValue, field) => {
        const formattedDate = newValue ? newValue.format('YYYY-MM-DD') : ''
        setCaseDetail({
            ...caseDetail,
            [field]: formattedDate // 使用動態字段名來更新相應的日期
        })
    }

    /**
     * 儲存資料
     * @return {void}
     */
    const handleSaveData = async () => {
        // 驗證必填欄位
        const validateKey = ['applicantDate', 'patientId', 'diagDate', 'recipientDate', 'sendDate', 'mpUser', 'tranUser', 'barthelScore', 'hospNoCare']
        // 設置為已點選過存檔
        setIsSaved(true)
        // 驗證必填欄位
        if (validateKey.every((item) => validValue(item))) {

            // 檢驗巴氏量表分數
            const barthelScore = parseInt(caseDetail.barthelScore,10);
            // 如果巴氏量表分數是非數字或大於100
            if(isNaN(barthelScore) || barthelScore >100){
                showToast({message:'巴氏量表分數不得大於100或非數字', type: AlertTypeEnum.Error})
                return
            }

            const row = {
                ...caseDetail,
                diagDate: caseDetail.diagDate ? `${caseDetail.diagDate} 00:00:00` : '',
                recipientDate: caseDetail.recipientDate ? `${caseDetail.recipientDate} 00:00:00` : '',
                sendDate: caseDetail.sendDate ? `${caseDetail.sendDate} 00:00:00` : '',
                applicantDate: caseDetail.applicantDate ? `${caseDetail.applicantDate} 00:00:00` : '',
            }
            // 判斷是新增還是編輯
            const targetApi = isEdit ? editTranForeign : addTranForeign
            // 執行新增或編輯
            const res = await targetApi(row)
            // 判斷是否成功
            if (res.err === ApiErrorStatusEnum.Success) {
                // 顯示成功訊息
                showToast({message: '儲存成功', type: AlertTypeEnum.Success})
                // 是否需要重新查詢取得清單
                const isNeedQuery = true
                // 已存檔並關閉彈窗
                onClose && onClose(isNeedQuery)
            } else {
                // 顯示錯誤訊息
                const msg = `儲存失敗: ${res.msg}`
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        }
    }

    /**
     * 驗證欄位是否填寫
     * @param key {string} - 欄位名稱
     * @return {boolean} - 是否填寫
     */
    const validValue = (key) => {
        return !!caseDetail[key]
    }

    /**
     * 刪除單筆資料
     * @return {void}
     */
    const handleConfirmDelete = () => {
        // 將 applicantNo 和 lockVersion 轉換為 JSON 字串
        const applicantNoString = JSON.stringify([{applicantNo: applicantNo, lockVersion: caseDetail.lockVersion}])
        // 執行刪除
        deleteTranForeign({
            tableName: "tw.com.hismax.his.entity.rmis.RmisTranForeign",
            tableData: applicantNoString
        }).then((res) => {
            // 判斷是否成功
            if (res.err === ApiErrorStatusEnum.Success) {
                // 顯示成功訊息
                showToast({message: '刪除成功', type: AlertTypeEnum.Success})
                // 是否需要重新查詢取得清單
                const isNeedQuery = true
                // 已刪除並關閉彈窗
                onClose && onClose(isNeedQuery)
            } else {
                // 顯示錯誤訊息
                showToast({message: res.msg, type: AlertTypeEnum.Error})
            }
            // 關閉刪除確認彈窗
            setShowDeletePopup(false)
        })
    }

    /**
     * 開啟刪除彈窗
     * @return {void}
     */
    const handleDeletePopupOpen = () => {
        setShowDeletePopup(true)
    }

    /**
     * 關閉刪除彈窗
     * @return {void}
     */
    const handleDeletePopupClose = () => {
        setShowDeletePopup(false)
    }

    /**
     * 選擇科別
     * @param division {object} 選擇的科別
     * @return {void}
     */
    const selectDivision = (division) => {
        // 如果 division 不存在，則返回
        if (!division) {
            return
        }
        // 更新科別的值
        setCaseDetail({
            ...caseDetail,
            divName: division.divName,
            divNo: division.divNo
        })
    }

    /**
     * 模糊搜尋選單-選擇的醫師
     * @param doctor {object}
     * @return {void}
     */
    const selectDoctor = (doctor) => {
        // 如果 doctor 不存在，則返回
        if (!doctor) {
            return
        }
        // 更新醫師的值
        setCaseDetail({
            ...caseDetail,
            doctorNo: doctor ? doctor.doctorNo : '',
            doctorName: doctor ? doctor.doctorName : '',
        })
    }

    /**
     * 關閉 病症暨失能診斷證明書_維護 彈窗
     * @return {void}
     */
    const handleDialgOnClose = () => {
        const isNeedQuery = false
        onClose && onClose(isNeedQuery)
    }

    /**
     * 查詢單筆資料
     * @return {void}
     */
    useEffect(() => {
        // 如果是編輯則查詢單筆資料
        if (isEdit) {
            queryTranForeignByApplicantNo({applicantNo}).then(
                (res) => {
                    // 判斷是否成功
                    if(res.err === ApiErrorStatusEnum.Success) {
                        // 填入單筆資料的值
                        setCaseDetail((prevCaseDetail) => ({
                            ...prevCaseDetail,
                            ...res.data,
                        }))
                    } else {
                        // 顯示錯誤訊息
                        showToast({message: res.msg, type: AlertTypeEnum.Error})
                    }
                }
            )
        }
    }, [isEdit])

    return (
        <Dialog
            open={true}
            title={'病症暨失能診斷證明書_維護'}
            variant={DialogVariant.EDIT}
            paperStyleProps={{width: '80%', maxHeight: 'calc(100vh-140px)', padding: '0.5rem'}}
            onClose={handleDialgOnClose}
            onSave={handleSaveData}
            content={
                <div>
                    {/*刪除確認popup*/}
                    <Dialog
                        open={showDeletePopup}
                        title="提醒"
                        variant={DialogVariant.DELETE}
                        onClose={handleDeletePopupClose}
                        onDelete={handleConfirmDelete}
                        paperStyleProps={{width: DialogSizeEnums.SM}}
                        content='是否確定刪除？'
                        muiDialogContentProps={{sx: {height: '110px', display: 'flex', alignItems: 'center'}}}
                    />
                    <div className="flex justify-end items-center">
                        {/* 刪除此筆資料 */}
                        <Button
                            text='刪除此筆資料'
                            color={ButtonColorEnum.Primary}
                            size={ButtonSizeEnum.Large}
                            variant={ButtonVariantEnum.Text}
                            icon={IconEnum.Delete}
                            onClick={handleDeletePopupOpen}
                            sx={{fontWeight: 'bold'}}
                        />
                    </div>
                    <div className="p-3 space-y-2">
                        <div className="w-full grid grid-cols-4">
                            {/*申請日期*/}
                            <Field label="申請日期"
                                   isRequired={!isEdit}>
                                {/* 如果是編輯模式則顯示 DatePicker */}
                                {
                                    isEdit ? <>{time2String(caseDetail.applicantDate, 'YYYY-MM-DD')}</> : (
                                        <DatePicker
                                            label=""
                                            value={caseDetail.applicantDate ? dayjs(caseDetail.applicantDate) : null}
                                            onChange={(newValue) => handleDateChange(newValue, 'applicantDate')}
                                            error={isSaved && !validValue("applicantDate")} // 錯誤處理
                                            helperText=""
                                        />
                                    )
                                }
                            </Field>
                            {/*病歷號*/}
                            <Field label={t('general.patientNo')} isRequired={!isEdit}>
                                {/* 如果是編輯模式則顯示病歷號輸入框 */}
                                {
                                    isEdit ? <>{caseDetail.patientId}</> : (
                                        <TextField
                                            size={SizeEnum.Small}
                                            value={caseDetail.patientId}
                                            onChange={(e) => updateCaseDetail(e, 'patientId')}
                                            error={isSaved && !validValue("patientId")}
                                        />
                                    )
                                }

                            </Field>
                            {/*姓名*/}
                            <Field label={t('general.username')}>
                                <TextField
                                    size={SizeEnum.Small}
                                    value={caseDetail.patientName}
                                    onChange={(e) => updateCaseDetail(e, 'patientName')}
                                />
                            </Field>
                            {/*身分證號*/}
                            <Field label={t('general.idNumber')}>
                                <TextField
                                    size={SizeEnum.Small}
                                    value={caseDetail.idNo}
                                    onChange={(e) => updateCaseDetail(e, 'idNo')}
                                />
                            </Field>
                        </div>
                        <div className="w-full grid grid-cols-4">
                            {/*就醫科別*/}
                            <Field label="就醫科別">
                                <Search
                                    options={divList}
                                    getOptionLabel={(option) => `${option.divNo} ${option.divName}`}
                                    value={{
                                        divNo: caseDetail.divNo || '',
                                        divName: caseDetail.divName || '',
                                    }}
                                    onChange={(_e, newValue) => selectDivision(newValue)}
                                />
                            </Field>
                            {/*醫師*/}
                            <Field label={t('general.doctor')}>
                                <Search
                                    options={doctorList}
                                    getOptionLabel={(option)=> `${option.doctorNo} ${option.doctorName}` }
                                    value = {{
                                        doctorNo: caseDetail.doctorNo || '',
                                        doctorName: caseDetail.doctorName || '',
                                    }}
                                    onChange={(_e, newValue)=> selectDoctor(newValue)}
                                />
                            </Field>
                            {/*出生日期*/}
                            <Field label={t('general.birthday')}>
                                <DatePicker
                                    label=""
                                    value={caseDetail.birthDate ? dayjs(caseDetail.birthDate) : null}
                                    onChange={(newValue) => handleDateChange(newValue, 'birthDate')}
                                    disableFuture
                                />
                            </Field>
                            {/*註銷*/}
                            <Field label={'註銷'}>
                                <Checkbox
                                    value={caseDetail.cancelFlag}
                                    onChange={(e) => updateCaseDetail(e, 'cancelFlag')}
                                />
                            </Field>
                        </div>
                        <Divider />
                        <div className="w-full grid grid-cols-4">
                            {/*證書日期*/}
                            <Field label="證書日期" isRequired>
                                <DatePicker
                                    label=""
                                    value={caseDetail.diagDate ? dayjs(caseDetail.diagDate) : null}
                                    onChange={(newValue) => handleDateChange(newValue, 'diagDate')}
                                    error={isSaved && !validValue("diagDate")} // 錯誤處理
                                    helperText=""
                                />
                            </Field>
                            {/*收件日期*/}
                            <Field label="收件日期" isRequired>
                                <DatePicker
                                    label=""
                                    value={caseDetail.recipientDate ? dayjs(caseDetail.recipientDate) : null}
                                    onChange={(newValue) => handleDateChange(newValue, 'recipientDate')}
                                    error={isSaved && !validValue("recipientDate")} // 錯誤處理
                                    helperText=""
                                />
                            </Field>
                            {/*寄件日期*/}
                            <Field label="寄件日期" isRequired>
                                <DatePicker
                                    label=""
                                    value={caseDetail.sendDate ? dayjs(caseDetail.sendDate) : null}
                                    onChange={(newValue) => handleDateChange(newValue, 'sendDate')}
                                    error={isSaved && !validValue("sendDate")} // 錯誤處理
                                    helperText=""
                                />
                            </Field>
                        </div>
                        <div className="w-full grid grid-cols-4">
                            {/*醫事課人員*/}
                            <Field label="醫事課人員" isRequired>
                                <TextField
                                    size={SizeEnum.Small}
                                    value={caseDetail.mpUser}
                                    onChange={(e) => updateCaseDetail(e, 'mpUser')}
                                    error={isSaved && !validValue("mpUser")}
                                />
                            </Field>
                            {/*轉診櫃台人員*/}
                            <Field label="轉診櫃台人員" isRequired>
                                <TextField
                                    size={SizeEnum.Small}
                                    value={caseDetail.tranUser}
                                    onChange={(e) => updateCaseDetail(e, 'tranUser')}
                                    error={isSaved &&!validValue("tranUser")}
                                />
                            </Field>
                            {/*巴氏量表分數*/}
                            <Field label="巴氏量表分數" isRequired>
                                <TextField
                                    size={SizeEnum.Small}
                                    value={caseDetail.barthelScore}
                                    onChange={(e) =>
                                        updateCaseDetail(e, 'barthelScore')
                                    }
                                    error={isSaved &&!validValue("barthelScore")}
                                />
                            </Field>
                            <div className="flex flex-row items-center justify-center mb-2">
                                {/*巴0醫師勾選*/}
                                <Checkbox
                                    label="巴0醫師勾選"
                                    value={caseDetail.barthelFlag}
                                    onChange={(e) => updateCaseDetail(e, 'barthelFlag')}
                                />
                                {/*CDR表*/}
                                <Checkbox
                                    label="CDR表"
                                    value={caseDetail.cdrFlag}
                                    onChange={(e) => updateCaseDetail(e, 'cdrFlag')}
                                />
                            </div>
                        </div>
                        <div className="w-full grid grid-cols-4">
                            {/*長照中心*/}
                            <Field label="長照中心" isRequired>
                                <TextField
                                    size={SizeEnum.Small}
                                    value={caseDetail.hospNoCare}
                                    onChange={(e) => updateCaseDetail(e, 'hospNoCare')}
                                    error={isSaved && !validValue("hospNoCare")}
                                />
                            </Field>
                            {/*電話*/}
                            <Field label={t('general.telephone')}>
                                <TextField
                                    size={SizeEnum.Small}
                                    value={caseDetail.hospTel}
                                    onChange={(e) => updateCaseDetail(e, 'hospTel')}
                                />
                            </Field>
                        </div>
                        <Divider sx={{my: 2}}/>
                        {/*就醫科別*/}
                        <Field label="申請人姓名" className="w-full flex">
                            <TextField
                                size={SizeEnum.Small}
                                value={caseDetail.applicantsUserName}
                                onChange={(e) =>
                                    updateCaseDetail(e, 'applicantsUserName')
                                }
                            />
                        </Field>
                        {/*申請人地址*/}
                        <Field label="申請人地址">
                            <TextField
                                size={SizeEnum.Small}
                                value={caseDetail.applicantAdress}
                                onChange={(e) => updateCaseDetail(e, 'applicantAdress')}
                            />
                        </Field>
                        <div className="grid grid-cols-4">
                            {/*收到退件日*/}
                            <Field label="收到退件日">
                                <DatePicker
                                    label=""
                                    value={caseDetail.recipientDate ? dayjs(caseDetail.recipientDate) : null}
                                    onChange={(newValue) => handleDateChange(newValue, 'recipientDate')}
                                />
                            </Field>
                            {/*退件次數*/}
                            <Field label="退件次數">
                                <TextField
                                    size={SizeEnum.Small}
                                    value={caseDetail.returnTime}
                                    onChange={(e) => updateCaseDetail(e, 'returnTime')}
                                />
                            </Field>
                        </div>
                        {/*退件原因*/}
                        <Field label="退件原因" sx={{alignItems: "start"}}>
                            <div className="space-y-2">
                                {/*退件原因1*/}
                                <TextField
                                    size={SizeEnum.Small}
                                    inputWidth={SizeEnum.Fill}
                                    value={caseDetail.returnReason}
                                    onChange={(e) =>
                                        updateCaseDetail(e, 'returnReason')
                                    }
                                />
                                {/*退件原因2*/}
                                <TextField
                                    size={SizeEnum.Small}
                                    inputWidth={SizeEnum.Fill}
                                    value={caseDetail.returnReason2}
                                    onChange={(e) =>
                                        updateCaseDetail(e, 'returnReason2')
                                    }
                                />
                                {/*退件原因3*/}
                                <TextField
                                    size={SizeEnum.Small}
                                    inputWidth={SizeEnum.Fill}
                                    value={caseDetail.returnReason3}
                                    onChange={(e) =>
                                        updateCaseDetail(e, 'returnReason3')
                                    }
                                />
                                {/*退件原因4*/}
                                <TextField
                                    size={SizeEnum.Small}
                                    inputWidth={SizeEnum.Fill}
                                    value={caseDetail.returnReason4}
                                    onChange={(e) =>
                                        updateCaseDetail(e, 'returnReason4')
                                    }
                                />
                            </div>
                        </Field>
                        {/*退件原因說明*/}
                        <Field label={"退件原因說明"} sx={{alignItems: "start"}}>
                            <TextArea
                                inputSize={SizeEnum.Fill}
                                value={caseDetail.returnReasonName}
                                onChange={(e) =>
                                    updateCaseDetail(e, 'returnReasonName')
                                }
                            />
                        </Field>
                        {/*備註*/}
                        <Field label={"備註"} sx={{alignItems: "start"}}>
                            <TextArea
                                inputSize={SizeEnum.Fill}
                                value={caseDetail.remark}
                                onChange={(e) => updateCaseDetail(e, 'remark')}
                            />
                        </Field>
                    </div>
                </div>
            }
            muiDialogContentProps={{dividers: true}}
        />
    )
}
export default CertificateEditorModal
