/**
 * Loading Component
 * @param isShow {boolean}
 */
const Loading = ({isShow}) =>
    isShow ?
        (
            <div className="absolute top-0 left-0 z-[100] flex min-h-screen w-full items-center justify-center bg-gray-50 bg-opacity-80">
                <div style={{borderTopColor: 'transparent'}}
                     className="h-8 w-8 animate-spin rounded-full border-4 border-blue-400"></div>
                <p className="ml-5 text-[#3F3F46]">Loading...</p>
            </div>
        ) :
        <></>

export default Loading
