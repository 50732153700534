import React, {useEffect, useMemo, useState} from 'react'
import {formatTime} from '../utils';
import ChangeRecordQuery from "./QueryChangeRecord";
import {
    ApiErrorStatusEnum,
    generateTimeIntervals,
    getLocalStorage,
    multiTermFuzzySearchObjects,
    stringIsEmpty,
    time2String
} from 'edah_utils/dist';
import {rsisChangeMaster, rsisCheckTimeValid, rsisQueryBaseData, rsisQueryCodeValue,} from "../../../api/v1/RSIS";
import {usePopup} from "../PopupProvider";
import {t} from "i18next";
import {USER_ROLE} from "../userRole";
import {checkDoctorLicence} from "../../../api/v1/Menu";
import {
    AlertTypeEnum,
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    Card,
    Checkbox,
    DatePicker,
    Dialog,
    DialogVariant,
    Divider,
    Field,
    Search,
    Select,
    SelectWidthEnum,
    SizeEnum,
    TextField
} from "edah-component/dist";
import dayjs from 'dayjs';

/**
 * 報備支援修改-變更modal
 * @param data {object}  資料
 * @param handleOnClose {function}  關閉modal
 * @param handleOnUpdate {function} 更新資料callback
 * @return {JSX.Element}
 */
const ChangeModal = ({data, handleOnClose, handleOnUpdate}) => {
    // 使用者角色
    const [role, setRole] = useState('')
    // 所有科別清單
    const [allDivisionList, setAllDivisionList] = useState([])
    // 員工/醫師代碼
    const [supportUserNo, setSupportUserNo] = useState(data.supportUserNo)
    // 員工/醫師名稱
    const [supportUserName, setSupportUserName] = useState(data.supportUserName)
    // 支援目的清單
    const [purposeOptions, setPurposeOptions] = useState([])
    // 子支援目的清單
    const [subPurposeOptions, setSubPurposeOptions] = useState([])
    // 性質清單
    const [kindData, setKindData] = useState([])
    // 變更原因
    const [changeReason, setChangeReason] = useState([])
    // 院區
    const [zone, setZone] = useState(data.zone)
    // 執登科別編號
    const [divNo, setDivNo] = useState('')
    // 執登科別名稱
    const [divName, setDivName] = useState('')
    // 證書字號
    const [licenceId, setLicenceId] = useState('')
    // 職類別代號
    const [licenceKind, setLicenceKind] = useState('')
    // 職類別名稱
    const [licenceKindName, setLicenceKindName] = useState('')
    // 執登機構代號
    const [hospNo, setHospNo] = useState('')
    // 執登機構名稱
    const [hospName, setHospName] = useState('')
    // 支援目的修改前
    const [purposeCodeBeforeChanged, setPurposeCodeBeforeChanged] = useState(data.purposeCode)
    /** 變更後內容 */
    // 支援科別字串
    const [supportDivStr, setSupportDivStr] = useState('')
    // 支援科別編號
    const [supportDivNo, setSupportDivNo] = useState(data.supportDivNo)
    // 支援科別名稱
    const [supportDivName, setSupportDivName] = useState(data.supportDivName)
    // 支援目的
    const [purposeCode, setPurposeCode] = useState(data.purposeCode ? data.purposeCode.split(',').filter(Boolean) : []);
    // 支援目的門住 支援目的更改為預設如果為null則設定為門診，值為1
    const [purposeCodeExt, setPurposeCodeExt] = useState('1')
    // 支援日期區間起始日期
    const [itemStartDate, setItemStartDate] = useState(time2String(data.itemStartDate, 'YYYY-MM-DD'))
    // 支援日期區間結束日期
    const [itemEndDate, setItemEndDate] = useState(time2String(data.itemEndDate, 'YYYY-MM-DD'))
    // 支援日期區間起始時間
    const [itemStartTime, setItemStartTime] = useState(formatTime(data.itemStartTime))
    // 支援日期區間結束時間
    const [itemEndTime, setItemEndTime] = useState(formatTime(data.itemEndTime))
    // 備註
    const [memo, setMemo] = useState(data.memo || '')
    // 變更原因
    const [modifyReason, setModifyReason] = useState(data.supportModifyReasonCode ? data.supportModifyReasonCode : '')
    // 其他說明
    const [otherDescription, setOtherDescription] = useState(data.supportModifyReasonDoc ? data.supportModifyReasonDoc : '')
    // 判斷是否為承辦人，則可以編輯
    const isOwnUser = JSON.parse(getLocalStorage('isOwnUser')) || false
    // 使用上下文
    const {
        showToast,
        // 設定顯示變更記錄modal
        setShowChangeRecordModal,
        // 顯示變更記錄modal
        showChangeRecordModal
    } = usePopup()

    /**
     * 支援科別更新時
     * @param item {object} 支援科別
     * @return {void}
     */
    const handleOnDivChange = (item) => {
        // 設定支援科別顯示字串
        setSupportDivStr(item)
        // 設定支援科別編號
        setSupportDivNo(item.split(' ')[0])
        // 設定支援科別名稱
        setSupportDivName(item.split(' ')[1])
    }

    /**
     * 渲染支援目的選項
     * @return {JSX.Element}
     */
    const renderPurposeOptions = () => {
        // 過濾重複的選項
        const uniquePurposeOptions = Array.from(new Set(purposeOptions.map(item => item.codeValue1)))
            .filter(codeValue1 => codeValue1 != null)
            .map(codeValue1 => purposeOptions.find(item => item.codeValue1 === codeValue1))
            .filter(Boolean)
            .sort((a, b) => parseInt(a.codeNo) - parseInt(b.codeNo)); // 根據 codeNo 排序

        /**
         * 處理支援目的選項變更
         * @param codeNo {String} 支援目的代碼
         * @return {void}
         */
        const handlePurposeChange = (codeNo) => {
            setPurposeCode(prevCodes => {
                const prevCodesArray = Array.isArray(prevCodes) ? prevCodes : prevCodes.split(' ').filter(Boolean);
                if (prevCodesArray.includes(codeNo)) {
                    return prevCodesArray.filter(code => code !== codeNo);
                } else {
                    return [...prevCodesArray, codeNo];
                }
            })
        }

        /**
         * 檢查支援目的是否被選中
         * @param codeNo {String} 支援目的代碼
         * @return {boolean}
         */
        const isChecked = (codeNo) => {
            if (Array.isArray(purposeCode)) {
                return purposeCode.includes(codeNo);
            } else {
                // 如果 purposeCode 是字符串，將其分割成數組
                const codeArray = purposeCode.split(',').filter(Boolean);
                return codeArray.includes(codeNo);
            }
        };
        return (
            <div className="grid grid-cols-2 gap-4 w-full">
                {
                    uniquePurposeOptions.map((item, index) => {
                        if (item.codeNo == null) {
                            return null; // 如果 codeNo 為 null，不渲染任何內容
                        }
                        if (item.codeNo === '10') {
                            return (
                                <div key={index} className="flex items-center space-x-2 col-span-1">
                                    <Checkbox label={item.codeValue1} key={index} checked={isChecked(item.codeNo)}
                                              onClick={() => handlePurposeChange(item.codeNo)}/>
                                    <Select
                                        data={{
                                            label: '報備為一般資源',
                                            options: subPurposeOptions.map((item) => ({
                                                value: item.codeNo,
                                                label: item.codeValue1
                                            }))
                                        }} value={purposeCodeExt} showLabel={false}
                                        width={SelectWidthEnum.Tiny}
                                        onChange={setPurposeCodeExt}/>
                                </div>
                            )
                        } else {
                            return (
                                <div key={index} className="flex items-center space-x-2 col-span-1">
                                    <Checkbox id={item.codeNo} label={item.codeValue1} checked={isChecked(item.codeNo)}
                                              onChange={() => handlePurposeChange(item.codeNo)}/>
                                </div>
                            )
                        }
                    })
                }
            </div>
        );
    }

    /**
     * 理由選擇變更
     * @param value {String}
     * @return {void}
     */
    const handleReasonSelectOnChange = (value) => {
        setModifyReason(value)
    }

    /**
     * 渲染變更前支援目的選項
     * @return {JSX.Element}
     */
    const renderPurposeOptionsBeforeChanged = () => {
        // 將 purposeCode 字符串轉換為數組
        const purposeCodes = purposeCodeBeforeChanged.split(',').filter(Boolean);
        // 子支援目的，門診，值班，會診
        const subPurpose = () => {
            if (data.purposeCodeExt === '1') {
                return t('RSIS.subPurposeOptionsEnum.1')
            } else if (data.purposeCodeExt === '2') {
                return t('RSIS.subPurposeOptionsEnum.2')
            } else if (data.purposeCodeExt === '3') {
                return t('RSIS.subPurposeOptionsEnum.3')
            }
        }
        // 根據 codeNo 查找對應的支援目的字串值
        const purposeTexts = purposeCodes.map(codeNo => {
            // 如果有找到對應的支援目的，返回對應的支援目的字串
            const purpose = purposeOptions.find(item => item.codeNo === codeNo);
            if (purpose) {
                // 如果codeNo=10，則返回支援目的(報備為一般支援的情況下)+子支援目的
                if (codeNo === '10') { // 假設 '10' 是 "報備為一般支援，支援目的" 的代碼
                    return `${purpose.codeValue1} ${data.purposeCodeExt ? `${subPurpose()}` : ''}`;
                }
                return purpose.codeValue1;
            }
            return null;
        }).filter(Boolean);

        // 渲染支援目的字串
        return purposeTexts.map((text, index) => (
            <p key={index} className='flex justify-start pl-4 py-[9px]'>{text}</p>
        ));
    };

    /**
     * 取得時間區間選項
     * @return {string[]}
     */
    const getTimeIntervals = () => {
        const intervals = generateTimeIntervals()
        intervals.push('24:00')
        // 產生時間區間
        return useMemo(() => intervals, [])
    }

    /**
     * 支援期間起始日期變更
     * @param {Dayjs | null} date 選定的日期
     */
    const handleOnStartDate = (date) => {
        if (date && dayjs(date).isValid()) {
            setItemStartDate(date.format('YYYY-MM-DD'));
        } else if (!date) {
            setItemStartDate(null)
        }
    }

    /**
     * 支援期間結束日期變更
     * @param {Dayjs | null} date 選定的日期
     */
    const handleOnEndDate = (date) => {
        if (date && dayjs(date).isValid()) {
            setItemEndDate(date.format('YYYY-MM-DD'));
        } else if (!date) {
            setItemEndDate(null)
        }
    }

    /**
     * 支援期間起始時間
     * @param {string} value 選定的開始時間
     * @return {void}
     */
    const handleOnStartTime = (value) => setItemStartTime(value);

    /**
     * 支援期間結束時間
     * @param {string} value 選定的結束時間
     * @return {void}
     */
    const handleOnEndTime = (value) => setItemEndTime(value);

    /**
     * 取得醫師執登資料
     * @param {string} userNo 醫師代碼
     * @return {void}
     */
    const getDoctorData = (userNo) => {
        // 根據醫師代碼查詢醫師資料
        checkDoctorLicence({
            doctorNo: userNo,
            divNo: ''
        }).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                // 若有執豋資料，則設定執豋資料
                if (data) {
                    // 設定執登科別代號
                    setDivNo(data.divNo)
                    // 設定執登科別名稱
                    setDivName(data.divName)
                    // 設定證書字號
                    setLicenceId(data.licenceId)
                    // 設定職類別
                    setLicenceKind(data.licenceKind)
                    // 設定職類別名稱
                    setLicenceKindName(data.licenceKindName)
                    // 設定執登機構代號
                    setHospNo(data.hospNo)
                    // 設定執登機構名稱
                    setHospName(data.hospName)
                } else { // 若無執豋資料，則設定空值
                    setDivNo('')
                    setDivName('')
                    setLicenceId('')
                    setLicenceKind('')
                    setLicenceKindName('')
                    setHospNo('')
                    setHospName('')
                }
            } else {
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 取得日期時間區間
     * @return {String}
     */
    const getDateTimeRange = () => {
        let str = ''
        //資料存在時
        if (data) {
            //起始和終止日期存在
            if (data.itemStartDate && data.itemEndDate) {
                str += `${time2String(data.itemStartDate, 'YYYY-MM-DD')}~${time2String(data.itemEndDate, 'YYYY-MM-DD')}`
            }

            //起始和終止時間存在
            if (data.itemStartTime && data.itemEndTime) {
                str += ` ${data.itemStartTime}~${data.itemEndTime}`
            }
        }
        return str
    }

    /**
     * 取得送出變更內容資料
     * @return {Object}
     */
    const getSendData = () => {
        return {
            // 支援編號
            supportNo: data.supportNo,
            // 支援人員編號
            itemVersion: data.itemVersion,
            // 支援人員編號
            supportUserNo: supportUserNo,
            // 支援機構Pkey
            supportOrgPkey: data.supportOrgPkey,
            // 支援科別名稱
            supportOrgName: data.supportOrgName,
            // 支援科別編號
            supportDivNo: supportDivNo,
            // 支援目的代碼
            purposeCode: purposeCode.join(','),
            // 支援目的說明
            purposeCodeExt: purposeCodeExt,
            // 支援期間起始日期
            itemStartDate: `${itemStartDate} 00:00:00`,
            // 支援期間結束日期
            itemEndDate: `${itemEndDate} 00:00:00`,
            // 支援期間起始時間
            itemStartTime: itemStartTime.split(':')[0] + itemStartTime.split(':')[1],
            // 支援期間結束時間
            itemEndTime: itemEndTime.split(':')[0] + itemEndTime.split(':')[1],
            // 支援性質
            supportProperties: data.supportProperties,
            // 執行內容
            executeContent: data.executeContent,
            // 備註
            memo: memo,
            // 代理人代號
            agentUserNo: data.agentUserNo,
            // 狀態(1申請/2確認/3送審/9失效)
            stepStatus: data.stepStatus,
            // 變更/註銷狀態(null空:無/M: 變更/D:刪除)
            supportModifyStatus: '',
            // 變更/註銷日期
            supportModifyDatetime: '',
            // 變更/註銷原因代號
            supportModifyReasonDoc: otherDescription,
            // 變更/註銷原因說明
            supportModifyReasonCode: modifyReason,
            // 匯出編號
            sendFileName: data.sendFileName,
            // 衛福部案號
            sendCaseNo: data.sendCaseNo,
            // 送審日期
            sendDatetime: data.sendDatetime,
            // 申請方式(N:一般 / E:急件申請)
            applyType: data.applyType,
            // 院區
            zone: zone,
            // 有效否 0/1
            effFlag: data.effFlag,
            // 承辦部門代號
            ownerDeptNo: data.ownerDeptNo,
            // 欄位變更說明, 以中文標記欄位名稱,欄位間用","區隔
            changeFieldsDoc: '',
            // 收案確認人員代號
            confirmUserNo: data.confirmUserNo,
            // 收案確認日期時間
            confirmDatetime: data.confirmDatetime,
        }
    }

    /**
     * 取得日期和時間
     * @param date {string} 日期
     * @param time {string} 時間
     * @return {Object}
     */
    const getDateAndTime = (date, time) => {
        return {
            date: time2String(date, 'YYYY-MM-DD'),
            time: `${time.substring(0, 2)}:${time.substring(2, 4)}`
        }
    }

    /**
     * 取得重疊的時間段
     * @param date {string} 日期
     * @param time {string} 時間
     * @return {Array<Object>}
     */
    const getOverlapDateAndTimeList = (date, time) => {
        // 結果
        let result = []
        // 檢查data 和 time 長度是否一樣
        date.length === time.length ? date.map((item, index) => {
            //將結果加入result
            result.push(getDateAndTime(item, time[index]))
        }) : []
        return result
    }

    /**
     * 送出變更內容表單
     * @return {void}
     */
    const handleOnSubmit = async () => {
        // 取得送出資料
        const sendData = getSendData()

        // 檢查支援科別是否有值
        if (!supportDivNo) {
            showToast({
                message: `支援者：${supportUserName}，「支援機構」為必填欄位，請重新確認！`,
                type: AlertTypeEnum.Warning
            })
            return
        }

        // 檢查變更原因是否為其他，若是則檢查是否有輸入其他說明
        if (sendData.supportModifyReasonCode === 'other' && sendData.supportModifyReasonDoc === '') {
            showToast({message: '請輸入其他原因之說明', type: AlertTypeEnum.Warning})
            return
        }

        // 若時間有進行變更
        if (sendData.itemStartDate !== data.itemStartDate || sendData.itemEndDate !== data.itemEndDate || sendData.itemStartTime !== data.itemStartTime || sendData.itemEndTime !== data.itemEndTime) {
            const startTimeInfo = sendData.itemStartTime
            const endTimeInfo = sendData.itemEndTime
            const startDate = new Date(sendData.itemStartDate.split(' ')[0]).setHours(startTimeInfo.slice(0, 2), startTimeInfo.slice(2, 4), 0, 0)
            const endDate = new Date(sendData.itemEndDate.split(' ')[0]).setHours(endTimeInfo.slice(0, 2), endTimeInfo.slice(2, 4), 0, 0)

            // 當結束日期早於起始日期
            if (endDate <= startDate) {
                showToast({message: '結束日期不得早於開始日期', type: AlertTypeEnum.Error})
                return
            }

            try {
                // 檢查時間是否重疊
                const result = await handleCheckOverlap(sendData)

                // 時間段不重疊
                if (!result.overlaps) {
                   changeMaster(sendData)
                } else {
                    const overlapList = result.time.filter(item => item.overlapFlag === true)
                    const overlapMessage = `${supportUserName}：${overlapList?.map(item => {
                        // 取得起始日期和時間
                        const {
                            date: inputStartDate,
                            time: inputStartTime
                        } = getDateAndTime(item.itemStartDate, item.itemStartTime)
                        // 取得結束日期和時間
                        const {
                            date: inputEndDate,
                            time: inputEndTime
                        } = getDateAndTime(item.itemEndDate, item.itemEndTime)
                        // 取得起始時間重疊的時間段
                        const overlapStartDate = getOverlapDateAndTimeList(item.dbStartDate, item.dbStartTime)
                        // 取得結束時間重疊的時間段
                        const overlapEndDate = getOverlapDateAndTimeList(item.dbEndDate, item.dbEndTime)
                        // 重疊時間字串
                        let overlapTime = ''
                        // 組合重疊時間字串
                        overlapStartDate.map((item, index) => {
                            overlapTime += `${item.date} ${item.time} ~ ${overlapEndDate[index].date} ${overlapEndDate[index].time}\n`
                        })

                        return `\n${inputStartDate} ${inputStartTime} ~ ${inputEndDate} ${inputEndTime}支援時段重疊，支援人員：${supportUserName}已於${overlapTime}申請報備支援。`
                    })
                    }`;
                    showToast({message: overlapMessage, type: AlertTypeEnum.Error})
                }
            } catch (error) {
                console.error('Error checking overlap:', error)
                showToast({message: '檢查時間重疊時發生錯誤', type: AlertTypeEnum.Error})
            }
        } else {
            changeMaster(sendData)
        }
    }

    /**
     * 備註變更
     * @param e {Event} 事件
     * @return {*}
     */
    const handleMemoOnChange = (e) => setMemo(e.target.value)

    /**
     * 檢查伺服器是否有時間重疊
     * @param {Object} data - 要檢查的時間項
     * @return {Promise<{overlaps: boolean, time: string}>}
     */
    const handleCheckOverlap = async (data) => {
        try {
            const time = [{
                itemStartDate: data.itemStartDate,
                itemEndDate: data.itemEndDate,
                itemStartTime: data.itemStartTime,
                itemEndTime: data.itemEndTime
            }]
            const res = await rsisCheckTimeValid({
                supportNo: data.supportNo,
                supportUserNo: supportUserNo,
                itemVersion: data.itemVersion,
                timeList: time
            });
            if (res.err === ApiErrorStatusEnum.Success && res.data.every(item => item.overlapFlag === false)) {
                return {overlaps: false, time: res.data};
            } else {
                return {overlaps: true, time: res.data};
            }
        } catch (error) {
            console.error('Error in handleCheckOverlap:', error);
            showToast({message: `檢查時間 ${time} 時發生錯誤`, type: AlertTypeEnum.Error});
            return {overlaps: true, time};
        }
    }

    /**
     * 變更報備支援內容
     * @param data {Object} 待變更內容
     * @return {void}
     */
    const changeMaster = (data) => {
        if (!modifyReason) {
            // 顯示提示訊息
            showToast({message: "變更原因為必須選擇", type: AlertTypeEnum.Warning})
            return
        }
        // 變更報備支援內容api
        rsisChangeMaster([data]).then(res => {
            // 狀態  / 訊息
            const {err, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                showToast({message: msg, type: AlertTypeEnum.Success})
                handleOnUpdate()
                handleOnClose()
            } else {
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 取得支援目的選項
     * @return {void}
     */
    const getPurposeOptions = () => {
        // 查詢支援目的選項
        rsisQueryCodeValue({effFlag: 'ALL'}).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                // 設定支援目的選項
                setPurposeOptions(data.filter(item => item.codeType === 'PURPOSE'))
                // 設定子支援目的選項
                setSubPurposeOptions(data.filter(item => item.codeType === 'SUBPURPOSE'))
                // 設定變更原因
                // 若不是承辦人，則取得code_value = "N"的資料，若為承辦人則包含code_value = "Y"的資料，即是全部資料
                isOwnUser ? setChangeReason(data.filter(item => item.codeType === 'CHANGE')) : setChangeReason(data.filter(item => item.codeType === 'CHANGE' && item.codeValue2 === 'N'))
            } else {
                setSubPurposeOptions([])
                setChangeReason([])
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 取得所有科別
     * return {void}
     */
    const getAllDivision = () => {
        rsisQueryBaseData({}).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                // 設定所有科別清單
                setAllDivisionList(data.supportDivList)
            } else {
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 取得預設支援科別
     * @return {Object}
     */
    const getDefaultDivNoValue = useMemo(() => {
            const data = allDivisionList?.find(option => supportDivNo?.includes(option?.supportDivNo || ''))
            return data && supportDivNo ? data : null
        }, [allDivisionList, supportDivNo]
    )

    /**
     * 第一次執行時
     * @return {void}
     */
    useEffect(() => {
        // 取得所有科別
        getAllDivision()
        // 取得支援目的選項
        getPurposeOptions()
        // 取得醫師執登資料
        getDoctorData(supportUserNo.split(' ')[0])
        // 設定支援科別顯示字串
        setSupportDivStr(`${supportDivNo} ${supportDivName}`)
        // 是否為承辦人員
        if (data.role === USER_ROLE.DEPT_USER) { // 是否為承辦人員
            setRole(USER_ROLE.DEPT_USER)
        } else if (data.role === data.createUser) { // 是否為申請人
            setRole(USER_ROLE.CREATE_USER)
        } else {
            setRole(USER_ROLE.OTHER_USER) // 既不是承辦人員也不是申請人
        }
    }, []);

    return (
        <>
            <Dialog
                open={true}
                title={t('RSIS.Popup.ChangeModal')}
                content={
                    <div className='flex flex-col px-6 gap-4 overflow-auto'>
                        {/* 第一列員工搜尋、查詢變更紀錄、狀態 */}
                        <div className='flex justify-between'>
                            <Field label="員工/醫師代碼">
                                <TextField
                                    value={`${supportUserNo} ${supportUserName}`}
                                    disabled={true}
                                />
                            </Field>
                            <div className='flex flex-row justify-end items-center w-full gap-2'>
                                {role === USER_ROLE.DEPT_USER && (
                                    <Button color={ButtonColorEnum.Secondary}
                                            variant={ButtonVariantEnum.Outlined}
                                            size={ButtonSizeEnum.Medium}
                                            text='查詢變更紀錄'
                                            onClick={() => setShowChangeRecordModal(true)}
                                            sx={{height: '2.5rem'}}
                                    />
                                )}
                                <Select
                                    data={{
                                        label: "院區",
                                        options: [{value: "1", label: `${t(`campus.${zone}`)}`}],
                                    }}
                                    value="1"
                                    disabled={true}
                                    notched={true}
                                    displayEmpty={true}
                                />
                                <span
                                    className='flex items-center text-[#38A169]'>{`${t(`RSIS.StepStatusEnum.${data.stepStatus}`)}狀態`}</span>
                            </div>
                        </div>
                        {/* 第二列資訊 */}
                        <div className='flex flex-row'>
                            {/* 執登機構 */}
                            <Field label="執登機構">
                                {!stringIsEmpty(hospNo) && !stringIsEmpty(hospName) ? `${hospNo} ${hospName}` : ''}
                            </Field>
                            {/* 職類別 */}
                            <Field label="職類別">
                                {!stringIsEmpty(licenceKind) && !stringIsEmpty(licenceKindName) ? `${licenceKind} ${licenceKindName}` : ''}
                            </Field>
                            {/* 執登科別 */}
                            <Field label="執登科別">
                                {!stringIsEmpty(divNo) && !stringIsEmpty(divName) ? `${divNo} ${divName}` : ''}
                            </Field>
                            {/* 證書字號 */}
                            <Field label='證書字號'>
                                {!stringIsEmpty(licenceId) ? licenceId : ''}
                            </Field>
                        </div>
                        {/* 變更內容區塊 */}
                        <div className='flex flex-row gap-4'>
                            {/* 變更前內容 */}
                            <Card title={"變更前內容"}>
                                <div className='flex flex-col gap-4'>
                                    <Field label="申請單號">
                                        {data.supportNo}
                                    </Field>
                                    <Field label="機構代碼/名稱">
                                        {`${data.supportOrgNo} ${data.supportOrgName}`}
                                    </Field>
                                    <Field label="支援科別">
                                        {`${data.supportDivNo} ${data.supportDivName}`}
                                    </Field>
                                    <Field label="支援目的">
                                        {renderPurposeOptionsBeforeChanged()}
                                    </Field>
                                    <Field label="支援日期區間">
                                        {getDateTimeRange()}
                                    </Field>
                                    <Field label="備註">
                                        {data.memo}
                                    </Field>
                                </div>
                                {/* 分隔線 */}
                                <Divider sx={{my: 2}}/>
                                <Field label="變更日期">
                                    {data.supportModifyDatetime ? data.supportModifyDatetime : ''}
                                </Field>
                            </Card>
                            {/* 可變更內容 */}
                            <Card title={"可變更內容"}>
                                <div className='flex flex-col gap-4 mb-4'>
                                    <div className='flex flex-row items-center'>
                                        <Field label={"支援科別"}>
                                            <Search
                                                freeSolo
                                                value={getDefaultDivNoValue}
                                                onChange={(_e, value) => {
                                                    const newValue = value?.supportDivNo ? `${value?.supportDivNo} ${value?.supportDivName}` : ''
                                                    handleOnDivChange(newValue)
                                                }}
                                                filterOptions={(options, {inputValue}) => {
                                                    if (!inputValue) {
                                                        return getDefaultDivNoValue || options
                                                    }
                                                    return multiTermFuzzySearchObjects(options, inputValue.split(' '))
                                                }}
                                                options={allDivisionList}
                                                getOptionLabel={(option) => `${option.supportDivNo} ${option.supportDivName}`}
                                            />
                                        </Field>
                                    </div>
                                    <Field label="支援目的" sx={{display: 'flex', alignItems: 'start'}}>
                                        {renderPurposeOptions()}
                                    </Field>
                                    <Field label="支援日期區間" sx={{display: 'flex', alignItems: 'start'}}>
                                        <div className='flex flex-row items-center gap-2 mb-2'>
                                            <DatePicker
                                                label="開始日期"
                                                disablePast
                                                value={itemStartDate ? dayjs(itemStartDate) : null}
                                                onChange={handleOnStartDate}
                                            />
                                            －
                                            <DatePicker
                                                label="結束日期"
                                                disablePast
                                                value={itemEndDate ? dayjs(itemEndDate) : null}
                                                onChange={handleOnEndDate}
                                            />
                                        </div>
                                        <div className='flex flex-row gap-2 item-center mt-4'>
                                            <Select
                                                data={{
                                                    label: '開始時間',
                                                    options: getTimeIntervals().map((item) => ({
                                                        value: item,
                                                        label: item,
                                                    }))
                                                }}
                                                value={itemStartTime}
                                                onChange={handleOnStartTime}
                                                width={SelectWidthEnum.Small}
                                                notched={true}
                                                displayEmpty={true}
                                            />
                                            <p className='flex items-center'>-</p>
                                            <Select
                                                data={{
                                                    label: '結束時間',
                                                    options: getTimeIntervals().map((item) => ({
                                                        value: item,
                                                        label: item,
                                                    }))
                                                }}
                                                value={itemEndTime}
                                                onChange={handleOnEndTime}
                                                width={SelectWidthEnum.Small}
                                                notched={true}
                                                displayEmpty={true}
                                            />
                                        </div>
                                    </Field>
                                    <Field label="備註">
                                        <TextField value={memo} disabled={!isOwnUser}
                                                   sx={{width: '250px'}}
                                                   onChange={handleMemoOnChange}/>
                                    </Field>
                                </div>
                                {/* 分隔線 */}
                                <Divider sx={{my: 2}}/>
                                <div className='flex flex-col gap-4'>
                                    <Field label="變更原因">
                                        <Select
                                            data={{
                                                label: '變更原因',
                                                options: changeReason.map((item) => ({
                                                    value: item.codeNo,
                                                    label: item.codeValue1
                                                }))
                                            }}
                                            value={modifyReason} showLabel={false}
                                            onChange={handleReasonSelectOnChange}/>
                                    </Field>
                                    <Field label="其他說明">
                                        <TextField
                                            value={otherDescription}
                                            onChange={(e) => setOtherDescription(e.target.value)}/>
                                    </Field>
                                </div>
                            </Card>
                        </div>
                    </div>
                }
                variant={DialogVariant.EDIT}
                paperStyleProps={{width: '1736px'}}
                muiDialogContentProps={{dividers: true}}
                onClose={handleOnClose}
                onSave={handleOnSubmit}/>
            {/* 變更紀錄彈窗 */}
            {
                showChangeRecordModal && (
                    <ChangeRecordQuery
                        supportNo={data.supportNo}
                        dropListData={[purposeOptions, subPurposeOptions]}
                        handleOnClose={() => setShowChangeRecordModal(false)}/>
                )
            }
        </>
    )
}
export default ChangeModal
