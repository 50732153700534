//Import Icon
import ExportIcon from "../../assets/images/SystemMonitoring/export.svg"
import {ReactComponent as EditBtnIcon} from "../../assets/images/ICCard/edit_square.svg"
import {ReactComponent as DeleteBtnIcon} from "../../assets/images/ICCard/delete.svg"
import {ReactComponent as CopyBtnIcon} from "../../assets/images/SystemMonitoring/copy.svg"

// Import Function
import React, {useEffect, useState} from "react"
import {
    ApiErrorStatusEnum,
    arrayIsEmpty,
    fuzzySearchObjects,
    InputTypeEnum,
    objectIsEmpty,
    ToastTypeEnum,
    WDISNotificationEnum
} from "edah_utils/dist"
import {BaseInput} from "../../components/Input/BaseInput"
import {
    ModifyNotifyMaintainerPopup
} from "../../components/WDIS/NotificationPersonnelMaintenance/ModifyNotifyMaintainerPopup"
import {DeleteNotifyRecordPopup} from "../../components/WDIS/NotificationPersonnelMaintenance/DeleteNotifyRecordPopup"
import {
    DeleteNotifyMaintainerPopup
} from "../../components/WDIS/NotificationPersonnelMaintenance/DeleteNotifyMaintainerPopup"
import {AddNotifyMaintainerPopup} from "../../components/WDIS/NotificationPersonnelMaintenance/AddNotifyMaintainerPopup"
import {
    ModifyNotifyMaintainerDetailPopup
} from "../../components/WDIS/NotificationPersonnelMaintenance/ModifyNotifyMaintainerDetailPopup"
import {wdisQueryServiceNotify, WdisServiceNotifyQueryBaseData} from "../../api/v1/WDIS"
import {t} from "i18next"
import useToast from "../../hooks/useToast"

/**
 * 通知人員維護頁面
 * @return {JSX.Element}
 */
const NotificationPersonnelMaintenance = () => {
    /**
     * Show Toast
     */
    const showToast = useToast()

    // 進階搜尋
    const [advancedSearch, setAdvancedSearch] = useState("")

    // 服務代號
    const [serviceId, setServiceId] = useState('')

    // 顯示服務代號下拉選單
    const [showServiceIdDropdown, setShowServiceIdDropdown] = useState(false)

    // 負責人員編號
    const [ownerUserNo, setOwnerUserNo] = useState('')

    // 顯示負責人員下拉選單
    const [showOwnerUserNoDropdown, setShowOwnerUserNoDropdown] = useState(false)

    // 服務啟用否 服務啟用否:全部/啟用/未啟用
    const [serviceEnable, setServiceEnable] = useState(WDISNotificationEnum.All)

    // 顯示未設定之通知人員 (預設-未勾選)
    const [displayonlynotset, setDisplayonlynotset] = useState(0)

    // 含己刪除之註冊服務(預設-未勾選)
    const [effFlag, setEffFlag] = useState(0)

    // 資料
    const [data, setData] = useState([])

    // 基礎資料
    const [baseData, setBaseData] = useState({})

    /**
     * 進階搜尋改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleAdvancedSearchOnChange = (e) => setAdvancedSearch(e.target.value)

    /**
     * 服務代號/名稱改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleServiceIdOnChange = (e) => setServiceId(e.target.value)

    /**
     * 服務代號/名稱輸入框點擊時
     * @return {void}
     */
    const handleServiceIdOnFocus = () => setShowServiceIdDropdown(true)

    /**
     * 服務代號/名稱輸入框失焦時
     * @return {void}
     */
    const handleServiceIdOnBlur = () => setTimeout(() => setShowServiceIdDropdown(false), 200)

    /**
     * 負責人員改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleOwnerUserNoOnChange = (e) => setOwnerUserNo(e.target.value)

    /**
     * 負責人員輸入框點擊時
     * @return {void}
     */
    const handleOwnerUserNoOnFocus = () => setShowOwnerUserNoDropdown(true)

    /**
     * 負責人員輸入框失焦時
     * @return {void}
     */
    const handleOwnerUserNoOnBlur = () => setTimeout(() => setShowOwnerUserNoDropdown(false), 200)

    /**
     * 服務啟用否改變時
     * @param {Event} e
     * @return {void}
     */
    const handleServiceEnableOnChange = (e) => setServiceEnable(e.target.value)

    /**
     * 顯示未設定之通知人員改變時
     * @return {void}
     */
    const handleDisplayonlynotsetOnChange = () => setDisplayonlynotset(displayonlynotset ? 0 : 1)

    /**
     * 含己刪除之註冊服務改變時
     * @return {void}
     */
    const handleEffFlagOnChange = () => setEffFlag(effFlag ? 0 : 1)

    /**
     * 取得過濾後的資料
     * @return {Array<Object>}
     */
    const getFilterData = () => fuzzySearchObjects(data, advancedSearch)

    /**
     * 取得過濾後的服務名稱清單
     * @return {Array<Object>}
     */
    const getFilterServiceNameList = () => fuzzySearchObjects(baseData.serviceIdAndNameList, serviceId)

    /**
     * 取得過濾後的負責人員清單
     * @return {Array<Object>}
     */
    const getFilterOwnerUserNoList = () => fuzzySearchObjects(baseData.ownerUserSet, ownerUserNo)

    /**
     * 服務名稱選項點擊時
     * @param {Object} option
     * @return {void}
     */
    const handleServiceIdOptionOnClick = (option) => {
        // 值不為空
        if (!objectIsEmpty(option)) {
            setServiceId(option.serviceName)
        }
        // 關閉服務名稱下拉選單
        setShowServiceIdDropdown(false)
    }

    /**
     * 負責人員選項點擊時
     * @param {Object} option
     * @return {void}
     */
    const handleOwnerUserNoOptionOnClick = (option) => {
        // 值不為空
        if (!objectIsEmpty(option)) {
            setOwnerUserNo(option.ownerUserNo)
        }
        // 關閉負責人員下拉選單
        setShowOwnerUserNoDropdown(false)
    }

    /**
     * 查詢服務對應通知人員
     * @return {void}
     */
    const getQueryServiceNotify = () => {
        wdisQueryServiceNotify({
            //服務代號/名稱(空白代表不限名稱)
            // 啟用否與刪除之欄位有不同的意思, 例如未來可能做定期檢查 "服務啟用否" 之檢查或清單
            serviceId: serviceId,
            //負責人員
            ownerUserNo: ownerUserNo,
            //通知啟用否
            //服務啟用否:全部/啟用/未啟用
            serviceEnable: serviceEnable,
            //顯示未設定之通知人員 (預設-未勾選)
            displayonlynotset: displayonlynotset,
            //含己刪除之註冊服務(預設-未勾選)
            effFlag: effFlag,
        }).then(res => {
            // 取得錯誤狀態 / 資料 / 訊息
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                // 設定資料
                setData(data)
            } else { // 錯誤
                // 顯示錯誤訊息
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 取得通知人員維護的相關參數初始值
     * @return {void}
     */
    const getQueryBaseData = () => {
        WdisServiceNotifyQueryBaseData({}).then(res => {
            // 取得錯誤狀態 / 資料 / 訊息
            const {err, data, msg} = res
            // 取得成功
            if (err === ApiErrorStatusEnum.Success) {
                // 設定資料
                setBaseData(data)
            } else { // 錯誤
                // 顯示錯誤訊息
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 第一次執行時
     * @return {void}
     */
    useEffect(() => {
        // 取得基礎資料
        getQueryBaseData()
        // 查詢服務對應通知人員
        getQueryServiceNotify()
    }, [])

    return (
        <div className="w-full p-4 bg-[#FAFAFA]">
            {/*篩選清單*/}
            <div className="filterGroup flex flex-row flex-wrap items-center justify-start">
                {/*服務代號/名稱*/}
                <p className="mb-4 mr-1">服務代號/名稱：</p>
                <div className="flex flex-row space-x-2 items-center relative w-[186px] mr-4 mb-4">
                    <BaseInput className="w-full h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                               type={InputTypeEnum.Text} inputMode={'search'} value={serviceId}
                               onChange={handleServiceIdOnChange}
                               onFocus={handleServiceIdOnFocus}
                               onBlur={handleServiceIdOnBlur}/>
                    {
                        showServiceIdDropdown && !objectIsEmpty(baseData) && !arrayIsEmpty(baseData.serviceIdAndNameList) && (
                            <div
                                className="bg-white z-10 absolute left-5 border border-[#d4d4d8] rounded-md top-8 w-64 flex flex-col">
                                <div className="max-h-[200px] bg-transparent overflow-y-auto px-2.5">
                                    {
                                        getFilterServiceNameList().map((item, index) =>
                                            <div
                                                className="bg-transparent border-b min-h-10 flex space-x-6 px-4 py-1 text-[#18181B] items-center"
                                                role="button" key={index}
                                                onClick={() => handleServiceIdOptionOnClick(item)}>
                                                <p>{`${item.serviceName}`}</p>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        )
                    }
                </div>
                {/*負責人員*/}
                <p className="mr-1 mb-4">負責人員：</p>
                <div className="flex flex-row space-x-2 items-center relative w-[186px] mb-4">
                    <BaseInput className={`w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4`}
                               type={InputTypeEnum.Text} inputMode={'search'} value={ownerUserNo}
                               onChange={handleOwnerUserNoOnChange}
                               onFocus={handleOwnerUserNoOnFocus}
                               onBlur={handleOwnerUserNoOnBlur}/>
                    {
                        showOwnerUserNoDropdown && !objectIsEmpty(baseData) && !arrayIsEmpty(baseData.ownerUserSet) && (
                            <div
                                className="bg-white z-10 absolute left-5 border border-[#d4d4d8] rounded-md top-8 w-64 flex flex-col">
                                <div className="max-h-[200px] bg-transparent overflow-y-auto px-2.5">
                                    {
                                        getFilterOwnerUserNoList().map((item, index) =>
                                            <div
                                                className="bg-transparent border-b min-h-10 flex space-x-6 px-4 py-1 text-[#18181B] items-center"
                                                role="button" key={index}
                                                onClick={() => handleOwnerUserNoOptionOnClick(item)}>
                                                <p>{`${item.ownerUserNo}`}</p>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        )
                    }
                </div>
                {/*通知啟用否*/}
                <div className="flex flex-row items-center justify-start mb-4 mr-4">
                    <p className="mr-1">通知啟用否：</p>
                    <select className="w-[145px] h-10 px-2 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                            value={serviceEnable}
                            onChange={handleServiceEnableOnChange}>
                        {
                            Object.keys(WDISNotificationEnum)
                                .filter(key => isNaN(Number(key)))
                                .map((key, index) =>
                                    <option key={index} value={WDISNotificationEnum[key]}>
                                        {t(`WDIS.NotificationEnum.${WDISNotificationEnum[key]}`)}
                                    </option>
                                )
                        }
                    </select>
                </div>
                {/*只顯示為設定通知人員*/}
                <div className="flex flex-row space-x-3 mb-4 mr-4">
                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5" type="checkbox"
                           id="r1" style={{accentColor: "#38A169"}} value={displayonlynotset}
                           onChange={handleDisplayonlynotsetOnChange}/>
                    <label className="cursor-pointer" htmlFor="r1">只顯示為設定通知人員</label>
                </div>
                {/*含已刪除之註冊服務*/}
                <div className="flex flex-row space-x-3 mb-4 mr-4">
                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5" type="checkbox"
                           id="r2" style={{accentColor: "#38A169"}} value={effFlag}
                           onChange={handleEffFlagOnChange}/>
                    <label className="cursor-pointer" htmlFor="r2">含已刪除之註冊服務</label>
                </div>
                <div className="flex flex-row items-center justify-start mb-4 mr-4">
                    {/*查詢按鈕*/}
                    <button
                        className="flex items-center justify-center h-10 px-4 mr-2 border-[2px] bg-[#2B6CB0] text-white border-[#2B6CB0] rounded-[6px]">
                        {t('general.query')}
                    </button>
                </div>
            </div>
            {/*服務表格清單*/}
            <div className="maintainContainer flex flex-row items-start justify-start">
                <div className="p-4 bg-white w-full border-[1px] border-[#D4D4D8] rounded-[12px] mr-4">
                    {/*進階搜尋*/}
                    <div className="searchGroup flex flex-row items-center justify-between mb-4">
                        <BaseInput className={`w-[320px] h-10 pl-8 border-[2px] border-[#D4D4D8] rounded-[6px]`}
                                   type="text" placeholder={t('general.advancedSearch')} inputMode={'search'}
                                   value={advancedSearch}
                                   onChange={handleAdvancedSearchOnChange}/>
                        {/*輸出按鈕*/}
                        <button className="flex flex-row justify-center items-center w-[112px] h-10">
                            <div className="w-10 h-10">
                                <img className="w-full h-full" src={ExportIcon} alt="addIcon"/>
                            </div>
                            <p className="text-center text-[#38A169]">
                                {t('general.export')}
                            </p>
                        </button>
                    </div>
                    <ul className="tableList NPMTableList">
                        <li className="title">
                            <div>操作</div>
                            <div>服務代號<br/>服務名稱</div>
                            <div>負責人員</div>
                            <div>通知人員</div>
                            <div>服務啟用狀態</div>
                            <div>服務刪除狀態</div>
                        </li>
                        {
                            !arrayIsEmpty(data) && getFilterData().map((item, index) =>
                                // 操作
                                <li key={index}>
                                    <div className="flex flex-row items-center justify-start">
                                        {/*編輯按鈕*/}
                                        <button className="flex flex-row items-center justify-start text-[#2B6CB0] mr-3">
                                            <EditBtnIcon className="mr-1"/>
                                            {t('general.edit')}
                                        </button>
                                        {/*複製按鈕*/}
                                        <button className="flex flex-row items-center justify-start text-[#2B6CB0] mr-3">
                                            <CopyBtnIcon className="mr-1"/>
                                            {t('general.copy')}
                                        </button>
                                        {/*刪除按鈕*/}
                                        <button className="flex flex-row items-center justify-start text-[#2B6CB0]">
                                            <DeleteBtnIcon className="mr-1"/>
                                            {t('general.delete')}
                                        </button>
                                    </div>
                                    {/*服務代號 服務名稱*/}
                                    <div>{item.id} <br/>{item.action}</div>
                                    {/*負責人員*/}
                                    <div>{item.userID}-{item.user}</div>
                                    {/*通知人員*/}
                                    <div>{item.notification}</div>
                                    {/*服務啟用狀態*/}
                                    <div>{item.currentStatus}</div>
                                    {/*服務刪除狀態*/}
                                    <div>{item.deleteStatus}</div>
                                </li>
                            )
                        }
                    </ul>
                </div>
            </div>
            {/*操作 / 編輯 細節 Popup*/}
            <ModifyNotifyMaintainerDetailPopup/>
            {/*通知人員新增popup*/}
            <AddNotifyMaintainerPopup/>
            {/*通知人員修改popup*/}
            <ModifyNotifyMaintainerPopup/>
            {/*刪除通知人員維護Record彈窗*/}
            <DeleteNotifyRecordPopup/>
            {/*通知人員維護刪除彈窗*/}
            <DeleteNotifyMaintainerPopup/>
        </div>
    )
}
export default NotificationPersonnelMaintenance
