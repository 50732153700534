import React, {useEffect, useState} from 'react'
import {t} from 'i18next'
import CustomTable from '../CustomTable'
import {REFERRAL_FORM_UPLOAD_ATTACHMENT} from '../../../constants/referralSystem/maintainAreaHeader'
import {queryAppendixByEncounterId, sendAppendix} from '../../../api/v1/Rmis'
import {
    ApiErrorStatusEnum,
    time2String,
    arrayIsEmpty,
} from 'edah_utils/dist'
import useToast from '../../../hooks/useToast'
import {
    AlertTypeEnum,
    Field,
    Card,
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    Dialog,
    DialogVariant
} from "edah-component/dist"

/**
 * 檔案類型文字
 */
const FILE_TYPE_TEXT = {
    LAB_REPORT: '檢驗檢查報告',
    IMAGE_REPORT: '影像報告',
    OPERATION_RECORD: '手術紀錄',
}

/**
 * 檔案類型 key值
 */ 
const FILE_TYPE = {
    LAB_REPORT: 'LAB_REPORT',
    IMAGE_REPORT: 'IMAGE_REPORT',
    OPERATION_RECORD: 'OPERATION_RECORD',
}

const AttachmentModal = ({onClose, info, onUploadSuccess}) => {
    // 檔案類別
    const [activeFileType, setActiveFileType] = useState(FILE_TYPE.LAB_REPORT)

    const [fileLists, setFileLists] = useState({
        // 檢驗檢查報告
        [FILE_TYPE.LAB_REPORT]: [],
        // 影像報告
        [FILE_TYPE.IMAGE_REPORT]: [],
        // 手術紀錄
        [FILE_TYPE.OPERATION_RECORD]: [],
    })

    const [patientName, setPatientName] = useState('')

    // toast message
    const showToast = useToast()

    /**
     * 選擇檔案類型
     * @param key {string} 檔案類型key值
     * @return {void}
     */
    const handleActiveFileType = (key) => {
        setActiveFileType(key)
    }

    /**
     * 上傳檔案
     * @return {void}
     */
    const uploadFiles = () => {
        // 選擇的檢驗檢查報告清單
        const selectedLabList = fileLists[FILE_TYPE.LAB_REPORT].filter(
            (item) => item.isSelected
        )  
        // 選擇的影像報告清單
        const selectedImageReportList = fileLists[
            FILE_TYPE.IMAGE_REPORT
            ].filter((item) => item.isSelected)
        // 選擇的手術紀錄清單
        const selectedOpNoteList = fileLists[FILE_TYPE.OPERATION_RECORD].filter(
            (item) => item.isSelected
        )

        // 檢驗檢查報告、影像報告、手術紀錄 都沒有選擇的檔案則不進行上傳
        if (
            arrayIsEmpty(selectedLabList) &&
            arrayIsEmpty(selectedImageReportList) &&
            arrayIsEmpty(selectedOpNoteList)
        ) {
            return
        }

        // TODO:上傳檔案
        sendAppendix().then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                showToast({message: '上傳成功', type: AlertTypeEnum.Success})
                onUploadSuccess()
            } else {
                showToast({
                    message: `上傳失敗:${res.msg}`,
                    type: AlertTypeEnum.Error,
                })
            }
        })
    }

    /**
     * 取得檔案
     * @return {void}
     */
    const getFileLists = () => {
        queryAppendixByEncounterId({
            encounterId: info.encounterId,
            etranNo: info.etranNo,
            patientId: info.patientId,
        }).then((res) => {
            // 取得檔案成功
            if (res.err === ApiErrorStatusEnum.Success) {
                const labList =
                    res.data?.labList?.map((item) => ({
                        ...item,
                        isSelected: false,
                    })) || []
                const imageList =
                    res.data?.xRayList?.map((item) => ({
                        ...item,
                        isSelected: false,
                    })) || []
                const opNoteList =
                    res.data?.orList?.map((item) => ({
                        ...item,
                        isSelected: false,
                    })) || []
                setFileLists({
                    [FILE_TYPE.LAB_REPORT]: labList,
                    [FILE_TYPE.IMAGE_REPORT]: imageList,
                    [FILE_TYPE.OPERATION_RECORD]: opNoteList,
                })
                setPatientName(res.data.patientName)
            } else {
                // 取得檔案失敗
                showToast({message: res.msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 選取要上傳的檔案 因api回傳的list沒有id故用index查找相對應的資料
     * @param isChecked {boolean} 是否選取
     * @param selectedItem {object} 選取的檔案
     * @param index {number} 檔案index
     * @return {void}
     */
    const handleToggleItem = (isChecked, selectedItem, index) => {
        // 選取的檔案
        const modifiedList = fileLists[activeFileType].map(
            (item, fileIndex) => {
                if (index === fileIndex) {
                    return {
                        ...item,
                        isSelected: isChecked,
                    }
                }
                return item
            }
        )

        // 設定選取的檔案
        setFileLists((prev) => {
            return {
                ...prev,
                [activeFileType]: modifiedList,
            }
        })
    }

    /**
     * 全選要上傳的檔案
     * @param isAllSelected {boolean} 是否全選
     * @return {void}
     */
    const handleToggleAll = (isAllSelected) => {
        setFileLists((prev) => {
            return {
                ...prev,
                [activeFileType]: prev[activeFileType].map((item) => ({
                    ...item,
                    isSelected: isAllSelected,
                })),
            }
        })
    }

    useEffect(() => {
        getFileLists()
    }, [])

    return (
        <Dialog
            open={true}
            title={'電子轉診單_附件上傳'}
            variant={DialogVariant.CUSTOM}
            onClose={onClose}
            paperStyleProps={{width: '80%'}}
            footerContent={
            <>
                {/* 附件上傳 */}
                <Button
                    text={'附件上傳'}
                    color={ButtonColorEnum.Primary}
                    size={ButtonSizeEnum.Medium}
                    variant={ButtonVariantEnum.Contained}
                    onClick={uploadFiles}
                />
                {/* 返回清單 */}
                <Button
                    text={'返回清單'}
                    color={ButtonColorEnum.Primary}
                    size={ButtonSizeEnum.Medium}
                    variant={ButtonVariantEnum.Contained}
                    onClick={onClose}
                />
            </>
            }
            content={
                <div className="p-4">
                    <div className="p-5 flex justify-between space-y-2">
                        {/* 開單日期 */}
                        <Field label="開單日期">
                            {info?.orderDatetime &&
                                time2String(info?.orderDatetime, 'YYYY-MM-DD')}
                        </Field>
                        {/* 病歷號 */}
                        <Field label={t('general.patientNo')}>
                            {info?.patientId}
                        </Field>
                        {/* 姓名 */}
                        <Field label={t('general.username')}>
                            {patientName}
                        </Field>
                        {/* 電子轉診單號 */}
                        <Field label="電子轉診單號">
                            {info?.etranNo}
                        </Field>
                    </div>
                    <div className="px-5 flex">
                        {/* 檔案類別 */}
                        <Card title="檔案類別" sx={{width: '30%'}}>
                            <div className="border-[1px] rounded-[4px]">
                                {Object.keys(FILE_TYPE).map((key) => (
                                    <div
                                        key={key}
                                        className={`flex items-center cursor-pointer hover:bg-[#BEE3F8] ${
                                            activeFileType === key ? 'bg-[#BEE3F8]' : ''
                                        }`}
                                        onClick={() => handleActiveFileType(key)}
                                    >
                                        <div className="m-1 ml-2">
                                            {FILE_TYPE_TEXT[key]}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Card>
                        {/* 院內檔案 */}
                        <Card title="院內檔案" sx={{width: '70%'}}>
                            <div className="border-2 rounded-[4px]">
                                <CustomTable
                                    isShoeDataFilter={false}
                                    isSelectable={true}
                                    fields={REFERRAL_FORM_UPLOAD_ATTACHMENT}
                                    dataList={fileLists[activeFileType]}
                                    toggleAll={handleToggleAll}
                                    toggleItem={handleToggleItem}
                                    isCrud={false}
                                />
                            </div>
                        </Card>
                    </div>
                </div>
            }
        />
    )
}

export default AttachmentModal
