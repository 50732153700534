import {arrayIsEmpty, time2String} from "edah_utils/dist";

/**
 * 預防接種資料
 * @param data {Array} 資料
 * @return {JSX.Element}
 */
export const VaccinationInfoSection = ({data}) => {
    return !arrayIsEmpty(data) ? (
        <div className="vaccinationInfo">
            <ul className="tableList vaccinationInfo">
                <li className="title">
                    <div>疫苗種類</div>
                    <div>接種日期</div>
                    <div>醫療院所代碼</div>
                    <div>醫療院所</div>
                    <div>疫苗批號</div>
                </li>
                {
                    data.map((item, index) =>
                        <li key={index}>
                            <div>{item.vaccineType}</div>
                            <div>{time2String(item.startDate, "YYYY-MM-DD")}</div>
                            <div>{item.hospNo}</div>
                            <div>義大醫院</div>
                            <div>{item.vaccineLot}</div>
                        </li>
                    )
                }
            </ul>
        </div>
    ) : null
}
