import React, {useEffect, useMemo, useState} from "react"
import {SearchInput} from "../../components/Input/SearchInput"
import {
    ApiErrorStatusEnum,
    arrayIsEmpty,
    fuzzySearchObjects,
    objectIsEmpty,
    stringIsEmpty,
    time2String
} from "edah_utils/dist"
import {t} from "i18next"
import useToast from "../../hooks/useToast"
import {deleteCategories} from "../../api/v1/Menu"
import {DeletePopup} from "../../components/Popup/DeletePopup"
import {curdDelete, curdQuery, curdSave} from "../../api/v1/CRUD"
import {
    AlertTypeEnum, Button, DatePicker, ColorEnum,
    TextField,
    ButtonSizeEnum,
    SizeEnum,
    ButtonVariantEnum,
    IconEnum,
    Card,
    Pagination,
    InlineEditDataGrid,
    EDITABLE_ON_ADD_CLASS

} from 'edah-component/dist'
import dayjs from 'dayjs';
import {SearchTextField} from "../../components/SearchTextField/SearchTextField";

/**
 * 類別維護
 * @returns {Element}
 * @constructor
 */
const TypeMaintenance = () => {
    // 限資訊室維護資料
    const [itRoomMaintenance, setItRoomMaintenance] = useState(null)
    // 限資訊室維護資料的進階搜尋清單
    const [advItRoomMaintenance, setAdvItRoomMaintenance] = useState(null)
    // 單位維護資料
    const [unitMaintenance, setUnitMaintenance] = useState(null)
    // 單位維護資料的進階搜尋清單
    const [advUnitMaintenance, setAdvUnitMaintenance] = useState(null)
    // 限資訊室維護 pagination控制變數
    const [itRoomMaintenancePaginationProps, setItRoomMaintenancePaginationProps] = useState({
        //當前頁碼
        currentPage: 1,
        //每頁資料筆數
        pageSize: 10,
        //總資料筆數
        totalItemSize: 0,
        //總頁碼
        totalPageSize: 0,
    })
    // 單位維護 pagination控制變數
    const [unitMaintenancePaginationProps, setUnitMaintenancePaginationProps] = useState({
        //當前頁碼
        currentPage: 1,
        //每頁資料筆數
        pageSize: 10,
        //總資料筆數
        totalItemSize: 0,
        //總頁碼
        totalPageSize: 0,
    })
    // 類別輸入框搜尋文字
    const [inputTypeSearchText, setInputTypeSearchText] = useState('')
    // 類別搜尋的文字
    const [typeQueryText, setTypeQueryText] = useState('')
    // 刪除彈窗開關
    const [showDeletePopup, setShowDeletePopup] = useState(false)
    // 限資訊室維護資料 進階搜尋文字
    const [advItRoomSearchText, setAdvItRoomSearchText] = useState('')
    //單位維護資料 進階搜尋文字
    const [advUnitSearchText, setAdvUnitSearchText] = useState('')
    //選擇的master資料
    const [selectedMasterData, setSelectedMasterData] = useState(null)
    //刪除的項目 (isMaster->是否為master, key->要刪除的項目key)
    const [delItem, setDelItem] = useState(null)
    //Toast Message Hooks
    const showToast = useToast()

    /**
     * 點選查詢按鈕事件
     */
    const handleSearchOnClick = () => {

        setTypeQueryText(inputTypeSearchText)
        //master
        queryTypeDataForItRoom(inputTypeSearchText, 1, itRoomMaintenancePaginationProps.pageSize)
    }

    /**
     * 查詢限資訊室維護的類別資料
     * @param queryText {String} 查詢的文字
     * @param page {Number} 查詢頁碼
     * @param pageSize {Number} 每頁資料筆數
     */
    const queryTypeDataForItRoom = (queryText, page, pageSize) => {
        curdQuery({
            //module name
            system: "menu",
            //compare operator
            compare: "like",
            tableName: "Justnamem",
            searchColumn: "categories",
            value: queryText,
            pageNum: page,  //第幾頁
            pageSize: pageSize //一頁幾筆資料
        }).then((res => {
            if (res.err === ApiErrorStatusEnum.Success) {
                //在資料中加上key值
                const dataWithKeys = res.data ? res.data.map((item, index) => {
                    return {...item, key: index}
                }) : []
                setItRoomMaintenance(dataWithKeys)

                setSelectedMasterData(arrayIsEmpty(dataWithKeys) ? null : dataWithKeys[0])

                setItRoomMaintenancePaginationProps({
                    ...itRoomMaintenancePaginationProps,
                    totalItemSize: res.totalItemSize,
                    totalPageSize: res.totalPageSize,
                    currentPage: page,
                    pageSize: pageSize
                })
            } else {
                const msg = `取得限資訊室維護資料失敗: ${res.msg}`
                showToast({message: msg, type: AlertTypeEnum.Error})

                setItRoomMaintenance(null)
                setItRoomMaintenancePaginationProps({
                    ...itRoomMaintenancePaginationProps,
                    totalItemSize: 0,
                    totalPageSize: 0,
                    pageSize: pageSize
                })
            }
        }))
    }

    /**
     * 查詢資訊室/單位維護的類別資料
     * @param  page {Number} 查詢頁碼
     * @param  pageSize {Number} 每頁資料筆數
     */
    const queryTypeDataForUnit = (page, pageSize) => {

        if (objectIsEmpty(selectedMasterData)) {
            //限資訊室維護資料(master)沒有選擇時
            setUnitMaintenance(null)
            setUnitMaintenancePaginationProps({
                ...unitMaintenancePaginationProps,
                totalItemSize: 0,
                totalPageSize: 0,
                pageSize: pageSize
            })
            return
        }
        curdQuery({
            //module name
            system: "menu",
            //compare operator
            compare: "==",
            tableName: "Justnamed",
            searchColumn: "categories",
            value: selectedMasterData.categories,
            pageNum: page,  //第幾頁
            pageSize: pageSize //一頁幾筆資料
        }).then((res => {
            if (res.err === ApiErrorStatusEnum.Success) {
                //在資料中加上key值
                const dataWithKeys = res.data ? res.data.map((item, index) => {
                    return {...item, key: index}
                }) : []
                setUnitMaintenance(dataWithKeys)

                setUnitMaintenancePaginationProps({
                    ...unitMaintenancePaginationProps,
                    totalItemSize: res.totalItemSize,
                    totalPageSize: res.totalPageSize,
                    currentPage: page,
                    pageSize: pageSize
                })
            } else {
                const msg = `取得資訊室/單位維護資料失敗: ${res.msg}`
                showToast({message: msg, type: AlertTypeEnum.Error})
                setUnitMaintenance(null)
                setUnitMaintenancePaginationProps({
                    ...unitMaintenancePaginationProps,
                    totalItemSize: 0,
                    totalPageSize: 0,
                    pageSize: pageSize
                })
            }
        }))
    }

    /**
     * 限資訊室維護資料的進階搜尋
     */
    const getAdvanceItRoomSearchTypeData = () => {
        const tmpArray =
            arrayIsEmpty(itRoomMaintenance) ? [] :
                itRoomMaintenance.map((item) => (
                    {
                        //key
                        key: item.key,  //這個不要過濾，不在顯示欄位內

                        //只copy要進階搜尋的欄位
                        //類別代碼
                        categories: item.categories,
                        //類別名稱
                        name: item.name,
                        //停用日期
                        deactiveDate: time2String(item.deactiveDate, "YYYY-MM-DD")
                    }
                ))

        //開始模糊搜尋
        const findedDataArray = fuzzySearchObjects(tmpArray, advItRoomSearchText, ["key"]) //key不要搜尋
        setAdvItRoomMaintenance(findedDataArray)

        if (!objectIsEmpty(selectedMasterData)) {
            //限資訊室維護資料有以選擇的資料時
            //進階搜尋後的資料是否包含被選擇的資料
            const findedSelected = arrayIsEmpty(findedDataArray) ? null : findedDataArray.find(item => item.key === selectedMasterData.key)
            if (objectIsEmpty(findedSelected)) {
                setSelectedMasterData(null)
                setUnitMaintenance(null)
                setAdvUnitSearchText('')
                setUnitMaintenancePaginationProps({
                    ...unitMaintenancePaginationProps,
                    totalItemSize: 0,
                    totalPageSize: 0
                })
            }
        }
    }

    /**
     * 單位維護資料的進階搜尋
     */
    const getAdvanceUnitSearchTypeData = () => {
        const tmpArray =
            arrayIsEmpty(unitMaintenance) ? [] :
                unitMaintenance.map((item) => (
                    {
                        //key
                        key: item.key,  //這個不要過濾，不在顯示欄位內

                        //只copy要進階搜尋的欄位
                        //類別代碼
                        justnameNo: item.justnameNo,
                        //類別名稱
                        justnameName: item.justnameName,
                        //排序
                        sortBy: item.sortBy,
                        //停用日期
                        deactiveDate: time2String(item.deactiveDate, "YYYY-MM-DD")
                    }
                ))

        //開始模糊搜尋
        const findedDataArray = fuzzySearchObjects(tmpArray, advUnitSearchText, ["key"]) //key不要搜尋
        setAdvUnitMaintenance(findedDataArray)
    }

    /**
     * 限資訊室維護-頁碼變更事件
     * @param page {Number} 頁碼
     * @param pageSize {Number} 每頁資料筆數
     */
    const onItRoomMaintenancePaginationPageOnChange = (page, pageSize) => {
        queryTypeDataForItRoom(typeQueryText, page, pageSize)
        setItRoomMaintenancePaginationProps({
            ...itRoomMaintenancePaginationProps,
            currentPage: page,
            pageSize: pageSize
        })
    }

    /**
     * 單位維護-每頁資料筆數變更事件
     * @param page {Number} 頁碼
     * @param pageSize {Number} 每頁資料筆數
     */
    const onUnitMaintenancePaginationPageSizeOnChange = (page, pageSize) => {
        // 設定每頁資料筆數
        queryTypeDataForUnit(1, pageSize)
    }

    /**
     * 類別輸入框搜尋文字變更事件
     * @param e {Event} 事件
     */
    const handleInputTypeSearchTextOnChange = (e) => {
        setInputTypeSearchText(e.target.value)
    }

    /**
     * 刪除彈窗關閉時
     * @return {void}
     */
    const handleDeletePopupCloseOnClick = () => {
        setDelItem(null)
        setShowDeletePopup(false)
    }

    /**
     * 刪除彈窗點擊刪除按鈕時
     * @return {void}
     */
    const handleDeletePopupYesBtnOnClick = (delItem) => {
        if (!objectIsEmpty(delItem) && delItem.isMaster !== null && delItem.isMaster !== undefined && delItem.key !== null && delItem.key !== undefined) {
            if (delItem.isMaster) {
                const fundedData = itRoomMaintenance.find(item => item.key === delItem.key)
                if (!objectIsEmpty(fundedData)) {
                    //master的項目存在，進行刪除
                    deleteCategories({
                        categories: fundedData.categories
                    }).then((res => {
                        if (res.err === ApiErrorStatusEnum.Success) {
                            //更新左邊table
                            queryTypeDataForItRoom(typeQueryText, 1, itRoomMaintenancePaginationProps.pageSize)
                        } else {
                            const msg = `刪除失敗: ${res.msg}`
                            showToast({message: msg, type: AlertTypeEnum.Error})
                        }
                    }))
                }
            } else {
                const fundedData = unitMaintenance.find(item => item.key === delItem.key)
                if (!objectIsEmpty(fundedData)) {
                    //detail的項目存在，進行刪除
                    const tableData = [{
                        "createDatetime": stringIsEmpty(fundedData.createDatetime) ? null : fundedData.createDatetime,
                        "createUser": fundedData.createUser,
                        "modifyDatetime": fundedData.modifyDatetime,
                        "modifyUser": fundedData.modifyUser,
                        "lockVersion": fundedData.lockVersion,
                        "categories": fundedData.categories,
                        "justnameNo": fundedData.justnameNo,
                        "justnameName": fundedData.justnameName,
                        "deactiveDate": stringIsEmpty(fundedData.deactiveDate) ? null : time2String(fundedData.deactiveDate, 'YYYY-MM-DD 00:00:00'),
                        "sortBy": fundedData.sortBy
                    }]
                    //轉成json格式
                    const tableDataJSON = JSON.stringify(tableData)

                    curdDelete({
                        "tableName": "tw.com.hismax.his.entity.menu.Justnamed",
                        "tableData": tableDataJSON
                    }).then((res => {
                        if (res.err === ApiErrorStatusEnum.Success) {
                            //更新右邊table
                            queryTypeDataForUnit(1, itRoomMaintenancePaginationProps.pageSize)
                        } else {
                            const msg = `刪除失敗: ${res.msg}`
                            showToast({message: msg, type: AlertTypeEnum.Error})
                        }
                    }))
                }
            }
        }
        setDelItem(null)
        setShowDeletePopup(false)
    }

    /**
     * 刪除彈窗點擊刪除按鈕時
     * @return {void}
     */
    const handleDeletePopupNoBtnOnClick = () => handleDeletePopupCloseOnClick()

    /**
     * 限資訊室維護(master)項目刪除按鈕點擊事件
     * @param keyToDel {Number} 要刪除的項目key
     */
    const handleItRoomDeleteTypeBtnOnClick = (keyToDel) => {
        setDelItem({
            isMaster: true,
            key: keyToDel
        })
        handleDeletePopupYesBtnOnClick({
            isMaster: true,
            key: keyToDel
        })
    }

    /**
     * 單位維護(detail)項目刪除按鈕點擊事件
     * @param keyToDel {Number} 要刪除的項目key
     */
    const handleUnitDeleteTypeBtnOnClick = (keyToDel) => {
        setDelItem({
            isMaster: false,
            key: keyToDel
        })
        handleDeletePopupYesBtnOnClick({
            isMaster: false,
            key: keyToDel
        })
    }

    /**
     * 限資訊室維護的進階搜尋輸入框文字變更事件
     * @param e {Event} 事件
     */
    const handleAdvItRoomSearchTextOnChange = (e) => {
        setAdvItRoomSearchText(e.target.value)
    }

    /**
     * 單位維護的進階搜尋輸入框文字變更事件
     * @param e {Event} 事件
     */
    const handleAdvUnitSearchTextOnChange = (e) => {
        setAdvUnitSearchText(e.target.value)
    }

    /**
     * It room 類別編輯項目儲存按鈕點擊事件
     * @param keyToSave {String} 要儲存的項目key
     * @param row {Object} 要儲存的資料
     */
    const handleItRoomEditSaveBtnOnClick = (keyToSave, row) => {
        const old = itRoomMaintenance.find(item => item.key === keyToSave)
        const fundedData = {...old, ...row}

        if (objectIsEmpty(fundedData)) return

        const tableData = [{
            "createDatetime": stringIsEmpty(fundedData.createDatetime) ? null : fundedData.createDatetime,
            "createUser": fundedData.createUser,
            "modifyDatetime": fundedData.modifyDatetime,
            "modifyUser": fundedData.modifyUser,
            "lockVersion": fundedData.lockVersion,
            "categories": fundedData.categories,
            "name": fundedData.name,
            "deactiveDate": stringIsEmpty(fundedData.deactiveDate) ? null : time2String(fundedData.deactiveDate, 'YYYY-MM-DD 00:00:00')
        }]
        //轉成json格式
        const tableDataJSON = JSON.stringify(tableData)
        //存檔
        curdSave({
            "tableName": "tw.com.hismax.his.entity.menu.Justnamem",
            "tableData": tableDataJSON
        }).then((res => {
            if (res.err === ApiErrorStatusEnum.Success) {
                //更新左邊table
                queryTypeDataForItRoom(typeQueryText, 1, itRoomMaintenancePaginationProps.pageSize)
            } else {
                const msg = `儲存失敗: ${res.msg}`
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        }))
    }

    /**
     *
     * @param key {Number} 要新增的項目key
     */
    const handleItRoomDataOnClick = (key) => {
        const findedData = itRoomMaintenance.find(item => item.key === key)
        setSelectedMasterData(findedData)
    }

    /**
     * Detail 類別編輯項目儲存按鈕點擊事件
     * @param keyToSave {String} 要儲存的項目key
     * @param row {Object} 要儲存的資料
     */
    const handleDetailEditSaveBtnOnClick = (keyToSave, row) => {
        const old = unitMaintenance.find(item => item.key === keyToSave)
        const fundedData = {...old, ...row}

        if (objectIsEmpty(fundedData)) return

        const tableData = [{
            "createDatetime": stringIsEmpty(fundedData.createDatetime) ? null : fundedData.createDatetime,
            "createUser": fundedData?.createUser || null,
            "modifyDatetime": fundedData.modifyDatetime || null,
            "modifyUser": fundedData.modifyUser || null,
            "lockVersion": fundedData.lockVersion || 0,
            "categories": fundedData.categories || selectedMasterData.categories,
            "justnameNo": fundedData.justnameNo || null,
            "justnameName": fundedData.justnameName || null,
            "deactiveDate": stringIsEmpty(fundedData.deactiveDate) ? null : time2String(fundedData.deactiveDate, 'YYYY-MM-DD 00:00:00'),
            "sortBy": fundedData.sortBy
        }]
        //轉成json格式
        const tableDataJSON = JSON.stringify(tableData)
        //存檔
        curdSave({
            "tableName": "tw.com.hismax.his.entity.menu.Justnamed",
            "tableData": tableDataJSON
        }).then((res => {
            if (res.err === ApiErrorStatusEnum.Success) {
                //更新右邊table
                queryTypeDataForUnit(1, unitMaintenancePaginationProps.pageSize)
            } else {
                const msg = `儲存失敗: ${res.msg}`
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        }))
    }


    /**
     * 限資訊室維護 - 表格用的 header 與設定資料
     */
    const header = [
        { field: 'categories', headerName: '類別代碼', flex: 1, cellClassName: EDITABLE_ON_ADD_CLASS, type: 'string'  },
        { field: 'name', headerName:'類別名稱', flex: 1,  editable: true, type: 'string' },
        { field: 'deactiveDate', headerName: '停用日期', flex: 1,  editable: true, type: 'date' },
    ]

    /**
     * 資訊室/單位維護 - 表格用的 header 與設定資料
     */
    const subHeader = [
        { field: 'justnameNo', headerName: '代碼', flex: 1, cellClassName: EDITABLE_ON_ADD_CLASS, type: 'string'  },
        { field: 'justnameName', headerName:'名稱', flex: 1,  editable: true, type: 'string' },
        { field: 'sortBy', headerName:'排序', flex: 1,  editable: true, type: 'string' },
        { field: 'deactiveDate', headerName: '停用日期', flex: 1,  editable: true, type: 'date' },
    ]


    /**
     * 監聽advItRoomSearchText, itRoomMaintenance的變化
     */
    useEffect(() => {
        //取得進階查詢的資料
        getAdvanceItRoomSearchTypeData()
    }, [advItRoomSearchText, itRoomMaintenance])

    /**
     * advUnitSearchText, unitMaintenance的變化
     */
    useEffect(() => {
        //取得進階查詢的資料
        getAdvanceUnitSearchTypeData()
    }, [advUnitSearchText, unitMaintenance])

    /**
     * 監聽選擇的master資料變化
     */
    useEffect(() => {
        queryTypeDataForUnit(1, unitMaintenancePaginationProps.pageSize)
    }, [selectedMasterData])

    /**
     * 初始
     */
    useMemo(() => {
        handleSearchOnClick()
    }, [])

    return (
        <div className="w-full p-4 bg-[#FAFAFA] space-y-2.5 max-h-[calc(100vh-101px)] overflow-y-auto">
            {/*Filter區塊*/}
            <div className="flex flex-row flex-wrap items-center">
                {/*類別代碼*/}
                <div className="flex items-center">
                    <TextField
                        label="類別代碼"
                        sx={{mr: 1}}
                        value={inputTypeSearchText}
                        onChange={handleInputTypeSearchTextOnChange}
                    />
                </div>

                {/*查詢*/}
                <Button
                    color={ColorEnum.Primary} variant={ButtonVariantEnum.Contained}
                    size={ButtonSizeEnum.Medium}
                    onClick={handleSearchOnClick}>
                    {t('general.query')}
                </Button>
            </div>
            {/*查詢table*/}
            <div className="flex flex-row space-x-3">
                {/*限資訊室維護*/}
                <div className='w-1/2'>
                    <Card title='限資訊室維護'
                          className="min-h-[calc(100vh-200px)] max-h-[calc(100vh-200px)]">
                        <div className="flex items-center mb-2">
                            <SearchTextField
                                placeholder={t('general.advancedSearch')}
                                value={advItRoomSearchText}
                                onChange={handleAdvItRoomSearchTextOnChange}
                            />
                        </div>
                        {/*限資訊室維護 Table*/}
                        <InlineEditDataGrid
                            rows={advItRoomMaintenance || []}
                            columns={header}
                            checkboxSelection={false}
                            disableColumnFilter
                            disableColumnSelector
                            disableColumnMenu
                            disableDensitySelector
                            disableRowSelectionOnClick={false}
                            height={'600px'}
                            onSave={(row, key)=> handleItRoomEditSaveBtnOnClick(row.key, row)}
                            onAddRow={()=> {
                                setSelectedMasterData(null)
                            }}
                            onDeleteRow={(row)=> handleItRoomDeleteTypeBtnOnClick(row.key)}
                            onRowClick={(info)=> handleItRoomDataOnClick(info.row.key)}
                        />
                        {/*分頁*/}
                        <div className="flex justify-end w-full mt-1.5">
                            <Pagination
                                totalPageSize={itRoomMaintenancePaginationProps.totalPageSize}
                                totalSize={itRoomMaintenancePaginationProps.totalItemSize}
                                onPageOnChange={(page, pageSize) =>
                                    onItRoomMaintenancePaginationPageOnChange(page, pageSize)
                                }
                                page={itRoomMaintenancePaginationProps.currentPage}
                                pageSize={itRoomMaintenancePaginationProps.pageSize}
                                showFirstButton
                                showLastButton
                            />

                        </div>
                    </Card>
                </div>
                {/*（資訊室/單位維護）*/}
                <div className='w-1/2'>
                    {
                        !objectIsEmpty(selectedMasterData) &&
                        <Card title='資訊室/單位維護'
                              className="min-h-[calc(100vh-200px)] max-h-[calc(100vh-200px)] w-[50%]">
                            <div className="flex items-center mb-2">
                                <SearchTextField
                                    placeholder={t('general.advancedSearch')}
                                    value={advUnitSearchText}
                                    onChange={handleAdvUnitSearchTextOnChange}
                                />
                            </div>
                            {/* 資訊室/單位維護 Table*/}
                            <InlineEditDataGrid
                                rows={advUnitMaintenance || []}
                                columns={subHeader}
                                checkboxSelection={false}
                                disableColumnFilter
                                disableColumnSelector
                                disableColumnMenu
                                disableDensitySelector
                                disableRowSelectionOnClick={false}
                                height={'600px'}
                                onSave={(row, key)=> handleDetailEditSaveBtnOnClick(row.key, row)}
                                onDeleteRow={(row)=> handleUnitDeleteTypeBtnOnClick(row.key)}
                            />
                            {/*分頁*/}
                            <div className="flex justify-end w-full mt-1.5">
                                <Pagination
                                    totalPageSize={unitMaintenancePaginationProps.totalPageSize}
                                    totalSize={unitMaintenancePaginationProps.totalItemSize}
                                    onPageOnChange={(page, pageSize) =>
                                        onUnitMaintenancePaginationPageSizeOnChange(page, pageSize)
                                    }
                                    page={unitMaintenancePaginationProps.currentPage}
                                    pageSize={unitMaintenancePaginationProps.pageSize}
                                    showFirstButton
                                    showLastButton
                                />

                            </div>
                        </Card>
                    }
                </div>
            </div>
            {/* 刪除彈窗 */}
            <DeletePopup show={showDeletePopup} content="是否確定刪除?"
                         onClosePopupButtonClick={handleDeletePopupCloseOnClick}
                         onYesButtonClick={handleDeletePopupYesBtnOnClick}
                         onNoButtonClick={handleDeletePopupNoBtnOnClick}/>
        </div>
    )
}

export default TypeMaintenance
