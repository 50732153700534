// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.scheduleSection {
  padding: 0 0 30px 0;
  max-height: 800px;
}
.scheduleSection .morning, .scheduleSection .afternoon, .scheduleSection .night {
  min-height: 580px;
}

.scheduleList {
  overflow: auto;
  border-radius: 6px;
  height: 100%;
}
.scheduleList li {
  padding: 8px;
  padding-bottom: 0;
  min-height: 52px;
  height: initial !important;
  border-bottom: 1px solid #DBDBDB;
  font-size: 14px;
  color: #3A3A3A;
  background-color: #fff;
  transition-duration: 0.3s;
}
.scheduleList li.disable {
  background-color: #D4D4D8;
  border-bottom: 1px solid #ACACAC;
}
.scheduleList li.disable .remark {
  background-color: #B9B9BA;
}
.scheduleList li.disable:hover {
  background-color: #D4D4D8; /* 保持原有背景颜色 */
}
.scheduleList li.disable:hover .remark {
  background-color: #B9B9BA; /* 保持原有背景颜色 */
}
.scheduleList li:hover {
  background-color: #BEE3F8;
}
.scheduleList li:hover .remark {
  background-color: transparent;
}
.scheduleList p.onlySelf {
  color: #BA904D;
}
.scheduleList p.called {
  color: #B25A5A;
}
.scheduleList .remark {
  text-align: left;
  background-color: #F4F4F5;
}`, "",{"version":3,"sources":["webpack://./src/components/Appointment/AppointmentSchedule/AppointmentPopup/appointmentPopup.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACE,mBAAA;EACA,iBAAA;AAEF;AADE;EACE,iBAAA;AAGJ;;AAAA;EACE,cAAA;EAGA,kBAAA;EACA,YAAA;AAGF;AAFE;EACE,YAAA;EACA,iBAAA;EACA,gBAAA;EACA,0BAAA;EACA,gCAAA;EACA,eAAA;EACA,cAAA;EACA,sBAAA;EACA,yBAAA;AAIJ;AAHI;EACE,yBAAA;EACA,gCAAA;AAKN;AAJM;EACE,yBAAA;AAMR;AAHM;EACE,yBAAA,EAAA,aAAA;AAKR;AAHQ;EACE,yBAAA,EAAA,aAAA;AAKV;AADI;EACE,yBAAA;AAGN;AAFM;EACE,6BAAA;AAIR;AACI;EACE,cAAA;AACN;AACI;EACE,cAAA;AACN;AAEE;EACE,gBAAA;EACA,yBAAA;AAAJ","sourcesContent":[".scheduleSection {\n  padding:0 0 30px 0;\n  max-height: 800px;\n  .morning, .afternoon, .night {\n    min-height: 580px;\n  }\n}\n.scheduleList {\n  overflow: auto;\n  -webkit-border-radius: 6px;\n  -moz-border-radius: 6px;\n  border-radius: 6px;\n  height: 100%;\n  li {\n    padding: 8px;\n    padding-bottom: 0;\n    min-height: 52px;\n    height: initial !important;\n    border-bottom: 1px solid #DBDBDB;\n    font-size: 14px;\n    color: #3A3A3A;\n    background-color: #fff;\n    transition-duration: .3s;\n    &.disable {\n      background-color: #D4D4D8;\n      border-bottom: 1px solid #ACACAC;\n      .remark {\n        background-color: #B9B9BA;\n      }\n\n      &:hover {\n        background-color: #D4D4D8; /* 保持原有背景颜色 */\n        \n        .remark {\n          background-color: #B9B9BA; /* 保持原有背景颜色 */\n        }\n      }\n    }\n    &:hover {\n      background-color: #BEE3F8;\n      .remark {\n        background-color: transparent;\n      }\n    }\n  }\n  p {\n    &.onlySelf {\n      color: #BA904D;\n    }\n    &.called {\n      color: #B25A5A;\n    }\n  }\n  .remark {\n    text-align: left;\n    background-color: #F4F4F5;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
