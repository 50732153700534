import moment from 'moment-timezone';
const Card = ({data, img, dataClick}) => {

    const getTimeDifference = (time) => {
        
        const timeZone = 'Asia/Taipei'
        const currentTime = moment().tz(timeZone);
        
        const targetTime = moment(time);
        
      
        const duration = moment.duration(currentTime.diff(targetTime));
        
        const years = Math.floor(duration.asYears());
        const months = Math.floor(duration.asMonths()) % 12;
        const days = Math.floor(duration.asDays()) % 30;
        const hours = Math.floor(duration.asHours()) % 24;
        const minutes = Math.floor(duration.asMinutes()) % 60;

        let timeDifferenceString;
        if(years > 0)
        {
            timeDifferenceString =  `${years} 年前`;
        }
        else if(months > 0) 
        {
            timeDifferenceString =  `${months} 個月前`;
        }
        else if(days > 0) 
        {
            timeDifferenceString =  `${days} 天前`;
        }
        else if(hours > 0) 
        {
            timeDifferenceString =  `${hours} 小時前`;
        }
        else if(minutes > 0) 
        {
            timeDifferenceString =  `${minutes} 分中前`;
        }
        else
        {
            timeDifferenceString =  `< 1分鐘`;
        }
        //const timeDifferenceString = `${years} years, ${months} months, ${days} days, ${hours} hours, ${minutes} minutes`;
      
        return timeDifferenceString;
      };

    return (
        <div className='flex border border-[#b5b5b5] bg-white hover:bg-[#ebedf0] p-[6px] h-[75px]'
            onClick={() => dataClick(data)}>
            {/* content */}
            <div className="flex items-center">
                <div className={`shrink-0 w-[10px] h-[10px] rounded-[50%] ${data.read ? "bg-[#ffffff00]" : "bg-[#3785e1]"}`}/>
                <div className='shrink-0 h-[30px] w-[30px] m-[5px]'>
                    <img src={img} className='h-[30px] w-[30px] rounded-full object-cover' />
                </div>
                {/* message */}
                <div className='mx-[5px] '>
                    <div className="flex justify-between items-center">
                        {/* message type text */}
                        <div className='w-[170px] text-black text-xl text-left truncate text-[#545454]'>{data.message}</div>
                        {/* 訊息時間 */}
                        <div className='text-[#bcbcbc] text-[15px] truncate w-[70px]'>{getTimeDifference(data.receivedTime)}</div>
                    </div>
                    {/*detail 最多3行*/}
                    <div className='text-[#6d6d6d] text-[15px] text-left line-clamp-1 w-[250px]'>{data.detail}</div>

                    
                </div>
            </div>

        </div>
    )
}

export default Card;