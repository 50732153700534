import React, {useState} from "react";
import {
    Select,
    SelectWidthEnum, 
    Button, 
    ButtonSizeEnum, 
    ButtonColorEnum, 
    ButtonVariantEnum, 
    Field, 
    TextField, 
    SizeEnum, 
    Divider, 
    DatePicker, 
    Checkbox
} from "edah-component/dist";
import {t} from "i18next";

/**
 * 安寧居家療護病歷
 * @return {JSX.Element}
 */
const PalliativeHomeCareMedicalRecord = () => {

    //同上變數：主要聯絡人
    const [mainContact, setMainContact] = useState({
        name: '',
        relation: '',
        phone: '',
        education: '',
        occupation: '',
        lang: ''
    });

    //同上變數：主要照顧者
    const [primaryCaregiver, setPrimaryCaregiver] = useState({
        name: '',
        relation: '',
        phone: '',
        education: '',
        occupation: '',
        lang: ''
    });

    /**
     * 同上
     * @return void
     */
    const handleSameAsAbove = () => {
        setPrimaryCaregiver({...mainContact});
        console.log(primaryCaregiver)
    };

    /**
     * 資料輸入
     * @param e
     * @param setFunc {Function}
     * @param key
     * @return void
     */
    const handleInputChange = (e, setFunc, key) => {
        setFunc(prevState => ({...prevState, [key]: e.target.value}));
    };

    return (
        <div className="h-[calc(100vh-175px)] overflow-y-auto p-6 bg-white space-y-3">
            <div className="flex justify-end items-center pb-4 mb-2.5 w-full">
                <div className="flex flex-row gap-2">
                    <Button color={ButtonColorEnum.Secondary} variant={ButtonVariantEnum.Outlined}
                            size={ButtonSizeEnum.Medium} text={'結案'}/>
                    <Button color={ButtonColorEnum.Secondary} variant={ButtonVariantEnum.Outlined}
                            size={ButtonSizeEnum.Medium} text={'看診資料查詢'}/>
                    <Button color={ButtonColorEnum.Secondary} variant={ButtonVariantEnum.Outlined}
                            size={ButtonSizeEnum.Medium} text={t('general.print')}/>
                    <Button color={ButtonColorEnum.Primary} variant={ButtonVariantEnum.Contained}
                            size={ButtonSizeEnum.Medium} text={t('general.saveFile')}/>
                </div>
            </div>
            <Divider/>
            <div className="space-y-3 overflow-x-auto">
                <div className="flex justify-end">
                    <div>
                        <Field label="級別">
                            S3
                        </Field>
                    </div>
                </div>
                <div className="flex flex-row items-center justify-start">
                    <Field label="個案姓名">
                        黃ＯＯ
                    </Field>
                    <Field label="病歷號">
                        numbers
                    </Field>
                    <Field label="身分證號">
                        E123456789
                    </Field>
                    <Field label="性別">
                        男
                    </Field>
                </div>
                <div className="flex flex-row items-center justify-start">
                    <Field label="家字號碼">
                        A009484738
                    </Field>
                    <Field label="出生日期">
                        2023/09/01
                    </Field>
                    <Field label="電話">
                        0987888000 / 072235500
                    </Field>
                    <Field label="地址">
                        高雄市燕巢區義大路1號
                    </Field>
                </div>
            </div>
            <Divider/>
            {/*主要聯絡人*/}
            <div className="space-y-3 pt-4">
                <div className="flex flex-row items-center justify-start">
                    <Field label="主要聯絡人">
                        <TextField
                            size={SizeEnum.Small} 
                            value={mainContact.name}
                            onChange={e => handleInputChange(e, setMainContact, 'name')}
                        />
                    </Field>
                    <Field label="關係">
                        <Select
                            data={{
                                label: "關係",
                                options: [
                                    {value:"0", label:""},
                                ]
                            }}
                            value={mainContact.relation}
                            onChange={(newValue)=>handleInputChange({target: {value: newValue}}, setMainContact, 'relation')}
                            showLabel={false}
                            width={SelectWidthEnum.Medium}
                        />
                    </Field>
                    <Field label="電話">
                        <TextField
                            size={SizeEnum.Small}
                            value={mainContact.phone}
                            onChange={e => handleInputChange(e, setMainContact, 'phone')}
                        />
                    </Field>
                </div>
                <div className="flex flex-row items-center justify-start">
                    <Field label="教育程度">
                        <Select
                            data={{
                                label: "教育程度",
                                options: [
                                    {value:"0", label:""},
                                ]
                            }}
                            value={mainContact.education}
                            onChange={(newValue)=>handleInputChange({target: {value: newValue}}, setMainContact, 'education')}
                            showLabel={false}
                            width={SelectWidthEnum.Medium}
                        />
                    </Field>
                    <Field label="職業">
                        <Select
                            data={{
                                label: "職業",
                                options: [
                                    {value:"0", label:""},
                                ]
                            }}
                            value={mainContact.occupation}
                            onChange={(newValue)=>
                                handleInputChange({target: {value: newValue}}, setMainContact, 'occupation')}
                            showLabel={false}
                            width={SelectWidthEnum.Medium}
                        />
                    </Field>
                    <Field label="溝通語言">
                        <Select
                            data={{
                                label: "溝通語言",
                                options: [
                                    {value:"0", label:""},
                                ]
                            }}
                            value={mainContact.lang}
                            onChange={(newValue)=>handleInputChange({target: {value: newValue}}, setMainContact, 'lang')}
                            showLabel={false}
                            width={SelectWidthEnum.Medium}
                        />
                    </Field>
                </div>
            </div>
            <Divider/>
            {/*主要照顧者*/}
            <div className="space-y-3 pt-4">
                <div className="flex flex-row items-center justify-start">
                    <Field label="主要照顧者">
                        <TextField
                            size={SizeEnum.Small}
                            value={primaryCaregiver.name}
                           onChange={e => handleInputChange(e, setPrimaryCaregiver, 'name')}
                        />
                    </Field>
                    <Field label="關係">
                        <Select
                            data={{
                                label: "關係",
                                options: [
                                    {value:"0", label:""},
                                ]
                            }}
                            value={primaryCaregiver.relation}
                            onChange={(newValue)=>handleInputChange({target: {value: newValue}}, setMainContact, 'relation')}
                            showLabel={false}
                            width={SelectWidthEnum.Medium}
                        />
                    </Field>
                    <Field label="電話">
                        <TextField
                            size={SizeEnum.Small}
                            value={primaryCaregiver.phone}
                            onChange={e => handleInputChange(e, setPrimaryCaregiver, 'phone')}
                        />
                    </Field>
                    {/*同上按鈕*/}
                    <Button color={ButtonColorEnum.Secondary} variant={ButtonVariantEnum.Outlined} size={ButtonSizeEnum.Medium} text={'同上'} onClick={handleSameAsAbove} />
                </div>
                <div className="flex flex-row items-center justify-start">
                    <Field label="教育程度">
                        <Select
                            data={{
                                label: "教育程度",
                                options: [
                                    {value:"0", label:""},
                                ]
                            }}
                            value={primaryCaregiver.education}
                            onChange={(newValue)=>handleInputChange({target: {value: newValue}}, setMainContact, 'education')}
                            showLabel={false}
                            width={SelectWidthEnum.Medium}
                        />
                    </Field>
                    <Field label="職業">
                        <Select
                            data={{
                                label: "職業",
                                options: [
                                    {value:"0", label:""},
                                ]
                            }}
                            value={primaryCaregiver.occupation}
                            onChange={(newValue)=>handleInputChange({target: {value: newValue}}, setMainContact, 'occupation')}
                            showLabel={false}
                            width={SelectWidthEnum.Medium}
                        />
                    </Field>
                    <Field label="溝通語言">
                        <Select
                            data={{
                                label: "溝通語言",
                                options: [
                                    {value:"0", label:""},
                                ]
                            }}
                            value={primaryCaregiver.lang}
                            onChange={(newValue)=>handleInputChange({target: {value: newValue}}, setMainContact, 'lang')}
                            showLabel={false}
                            width={SelectWidthEnum.Medium}
                        />
                    </Field>
                </div>
            </div>
            <Divider/>
            <div className="space-y-3 pt-4">
                <Field label="疾病主診斷">
                    <TextField size={SizeEnum.Small}/>
                </Field>
                <Field childrenSx={{display: 'flex', alignItems: 'center'}} label="重大傷病卡號">
                    <div className="flex w-[30%]">
                        <input type="radio" id="card1" name="card"
                            className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                            style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="card1">有</label>
                        <input type="radio" id="card2" name="card"
                            className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                            style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="card2">沒有</label>
                    </div>
                    <Field label="迄未日期" className="w-[70%]">
                        <DatePicker size={SizeEnum.Small}/>
                    </Field>
                </Field>
                <Field label="ICD-10">
                    <TextField size={SizeEnum.Small}/>
                </Field>
            </div>
            <Divider/>
            <div className="space-y-3 pt-4">
                <div className="flex flex-row items-center justify-start">
                    <Field label="轉介來源">
                        <TextField size={SizeEnum.Small}/>
                    </Field>
                    <Field label="住ODP">
                        <TextField size={SizeEnum.Small}/>
                    </Field>
                    <Field label="轉介日期">
                        <DatePicker size={SizeEnum.Small}/>
                    </Field>
                </div>
                <Field childrenSx={{display: 'flex', alignItems: 'center'}} label="轉介人">
                    <TextField size={SizeEnum.Small}/>
                    <input type="radio" id="referrals1" name="referrals"
                        className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                        style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="referrals1">居家醫療個管師</label>
                    <input type="radio" id="referrals2" name="referrals"
                        className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                        style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="referrals2">安寧共照護理師</label>
                </Field>
                <Field label="居家模式">
                    <input type="radio" id="liveMode1" name="liveMode"
                        className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                        style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="liveMode1">新制</label>
                    <input type="radio" id="liveMode2" name="liveMode"
                        className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                        style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="liveMode2">舊制</label>
                </Field>
                <div className="flex items-center">
                    <div className="w-[30%]">
                        <Field labelWidth={160} label="轉介居家收案醫師">
                            <TextField size={SizeEnum.Small}/>
                        </Field>
                    </div>
                    <div className="w-[30%]">
                        <Field label="居家護理師">
                            <TextField size={SizeEnum.Small}/>
                        </Field>
                    </div>
                </div>
            </div>
            <Divider/>
            <div className="space-y-3 pt-4">
                <Field label="收案日期">
                    <DatePicker size={SizeEnum.Small}/>
                </Field>
                <Field sx={{display: 'flex', alignItems: 'start'}} labelSx={{paddingTop: 1}} label="收案原因">
                    <div>
                        <div className="flex flex-row items-center justify-start">
                            <Checkbox label="疼痛"/>
                            <Checkbox label="呼吸困難"/>
                            <Checkbox label="噁心、嘔吐"/>
                            <Checkbox label="便秘"/>
                            <Checkbox label="吞嚥困難"/>
                            <Checkbox label="腫瘤潰爛傷口"/>
                        </div>
                        <div className="flex flex-row flex-wrap items-center justify-start">
                            <Checkbox label="腹水"/>
                            <Checkbox label="淋巴水腫"/>
                            <Checkbox label="軟弱、倦怠"/>
                            <Checkbox label="大小便失禁"/>
                            <Checkbox label="其他"/>
                            <TextField size={SizeEnum.Small}/>
                        </div>
                    </div>
                </Field>
                <Field childrenSx={{display: 'flex', alignItems: 'center'}} labelWidth={182} label="收案時Karnofsky Scale">
                    <TextField inputWidth={SizeEnum.Small} size={SizeEnum.Small}/>
                    <p className="mr-4">分</p>
                    <p className="mr-1">ECOG</p>
                    <TextField inputWidth={SizeEnum.Small} size={SizeEnum.Small}/>
                    <p className="mr-4">分</p>
                </Field>
            </div>
            <div className="space-y-3 pt-4">
                <Field childrenSx={{display: 'flex', alignItems: 'center'}} label="結案日期">
                    <DatePicker size={SizeEnum.Small}/>
                </Field>
                <Field sx={{display: 'flex', alignItems: 'start'}} label="結案原因">
                    <div className="flex flex-row items-center justify-start w-[940px] h-[40px]">
                        <input type="radio" id="close1" name="closeCase"
                            className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                            style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="close1">問題解決</label>
                        <input type="radio" id="close2" name="closeCase"
                            className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                            style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="close2">轉安寧病房</label>
                        <input type="radio" id="close3" name="closeCase"
                            className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                            style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="close3">死亡</label>
                        <input type="radio" id="close4" name="closeCase"
                            className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                            style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="close4">轉其他醫療機構</label>
                        <input type="radio" id="close5" name="closeCase"
                            className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                            style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="close5">轉他願安寧居家</label>
                    </div>
                    <div className="flex flex-row items-center justify-start w-[940px] h-[40px]">
                        <input type="radio" id="close6" name="closeCase"
                            className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                            style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="close6">病人拒訪</label>
                        <input type="radio" id="close6" name="closeCase"
                            className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                            style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="close6">家屬拒訪</label>
                        <input type="radio" id="close7" name="closeCase"
                            className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                            style={{accentColor: "#2B6CB0"}}/>
                            <label className="mr-4" htmlFor="close7">其他</label>
                        <TextField size={SizeEnum.Small}/>
                    </div>
                </Field>
                <Divider/>
                <div className="flex flex-row items-center justify-start">
                    <div className="w-[30%]">
                        <Field label="結案醫師">
                            <TextField size={SizeEnum.Small}/>
                        </Field>
                    </div>
                    <div className="w-[30%]">
                        <Field label="結案居家護理師">
                            <TextField size={SizeEnum.Small}/>
                        </Field>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default PalliativeHomeCareMedicalRecord