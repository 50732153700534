import {
    ApiErrorStatusEnum,
    arrayIsEmpty,
    getLocalStorage,
    setLocalStorage,
    stringIsEmpty,
    ToastTypeEnum
} from "edah_utils/dist";
import DOMPurify from "dompurify";
import {zoneSetQueryZoneNo} from "../../api/v1/Menu";

/**
 * 布局
 * @param children
 * @return {JSX.Element}
 * @constructor
 */
const Layout = ({children}) => {
    return <div className="p-2 w-full h-[calc(100vh-98px)] overflow-auto">
        {children}
    </div>
}

/**
 * 標題
 * @param children
 * @param extendClass
 * @return {JSX.Element}
 * @constructor
 */
const Title = ({children, extendClass}) => {
    return (
        <div className={`date flex flex-row items-center justify-start ${extendClass}`}>
            <div>{children}</div>
        </div>
    )
}

/**
 * 格式化時間
 * @param timeString
 * @return {string}
 */
function formatTime(timeString) {
    return timeString.replace(/^(\d{2})(\d{2})$/, '$1:$2');
}

/**
 * 獲取日期往後天數範圍
 * @param startDate {Date} 開始日期
 * @param days {number} 天數
 * @return {string[]}
 */
function getDateRange(startDate, days) {
    // 複製startDate以避免修改原始日期
    const endDate = new Date(startDate.getTime());

    // 增加指定的天數
    endDate.setDate(endDate.getDate() + days);

    // 格式化日期為 "YYYY-MM-DD" 格式
    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    // 返回格式化後的日期範圍
    return [formatDate(startDate), formatDate(endDate)];
}

// 使用示例
const today = new Date();
const [startDateString, endDateString] = getDateRange(today, 60);
console.log("開始日期:", startDateString);
console.log("結束日期:", endDateString);

// 按鈕樣式
const ButtonStyle = "ml-4 flex items-center justify-center font-bold px-4 h-10 mr-10 rounded-[6px] bg-[#2B6CB0] text-white"

// 輸入框樣式
const InputStyle = (width) => {
    return `w-[${width}px] h-10 bg-white text-black rounded-[6px] border border-gray-300`
}

/**
 * 新增單筆資料
 * @param {dataList} Array, 原始資料列表
 * @param {item} Object, 預新增的單筆資料
 * @returns
 */
const addListItem = (dataList, item) => {
    return [...dataList, {...item, id: Date.now()}];
};

/**
 * 新增多筆資料
 * @param {dataList} Array, 原始資料列表
 * @param {items} Object Array, 預新增的資料列表
 * @returns
 */
const addListItems = (dataList, items) => {
    const newItems = items.map(item => ({...item, id: Date.now() + Math.random()}));
    return [...dataList, ...newItems];
};

/**
 * 修改單筆資料
 * @param {dataList} Array, 原始資料列表
 * @param {id} Number, 預修改的單筆資料index
 * @param {newItem} Object, 修改後的單筆資料
 * @returns
 */
const updateItem = (dataList, id, newItem) => {
    return dataList.map(item => (item.id === id ? {...item, ...newItem} : item));
};

/**
 * 修改多筆資料
 * @param {dataList} Array, 原始資料列表
 * @param {items} Object Array, 預修改的多筆資廖
 * @returns
 */
const updateItems = (dataList, items) => {
    return dataList.map(item => {
        const newItem = items.find(i => i.id === item.id);
        return newItem ? {...item, ...newItem} : item;
    });
};

/**
 * 刪除單筆資料
 * @param {dataList} Array, 原始資料列表
 * @param {id} Number, 預刪除的單筆資料index
 * @returns
 */
const deleteItem = (array, index) => {
    if (index < 0 || index >= array.length) {
        throw new Error("Index out of bounds");
    }
    return array.slice(0, index).concat(array.slice(index + 1));
};

/**
 * 刪除多筆資料
 * @param {dataList} Array, 原始資料列表
 * @param {ids} Number Array, 預修改的資料index列表
 * @returns
 */
const deleteItems = (dataList, ids) => {
    return dataList.filter(item => !ids.includes(item.id));
};

/**
 * 判斷是否為承辦人
 * @param {string} loginUser 登入使用者編號
 * @param {array }ownerList 承辦部門清單
 * @return {boolean}
 */
const isOwnerUser = (loginUser, ownerList) => {
    if (!arrayIsEmpty(ownerList) && !stringIsEmpty(loginUser)) {
        // 只要有一個承辦人是登入使用者就回傳true
        return ownerList.some(owner => owner.userNo === loginUser)
    }else {
        return false;
    }
}

/**
 * 過濾HTML資料，返回安全的HTML
 * @param {string} htmlContent
 * @return {DOMPurify.sanitize}
 */
const sanitizedData = (htmlContent) => {
    // 設定DOMPurify組態配置
    const sanitizeConfig = {
        ADD_TAGS: ['table', 'thead', 'tbody', 'tr', 'th', 'td', 'br', 'figure', 'h2', 'strong', 'img', 'p', 'a', 'ul', 'ol', 'li'],
        ADD_ATTR: ['class', 'src', 'alt', 'href', 'target'],
    }

    if (htmlContent) {
        // 使用DOMPurify過濾資料
        return DOMPurify.sanitize(htmlContent, sanitizeConfig);
    }
}

/**
 * 取得當前登入院區代號
 * @return {string}
 */
const getCurrentZoneNo = () => {
    zoneSetQueryZoneNo({
        zone: getLocalStorage('campus')
    }).then(res => {
        const {err, data, msg} = res
        if (err === ApiErrorStatusEnum.Success) {
            setLocalStorage('campusId', data.zoneNo)
        }else {
            showToast({message: msg, type:ToastTypeEnum.Error})
        }
    })
}

/**
 * 過濾字串為空值，null，undefined，'null'，'undefined'
 * @param {string} value
 * @return {boolean}
 */
const filterStringIsEmpty = (value) => {
    if(value === 'null' || value === 'undefined') return true
    else return stringIsEmpty(value)
}

// 測試專任、兼任、本院員工假資料
// 專任及本院員工測試資料
const fakeTestData = [
    {
        userNo: "1001",
        userName: "陳文旭",
        depNo: "A04",
        depName: "醫療事務部",
        hospNo: "1142120001",
        hospName: "義大醫院"
    },
    {
        userNo: "1002",
        userName: "陳文旭",
        depNo: "A04",
        depName: "醫療事務部",
        hospNo: "1142120001",
        hospName: "義大醫院"
    },
    {
        userNo: "1003",
        userName: "陳文旭",
        depNo: "A04",
        depName: "醫療事務部",
        hospNo: "1107120017",
        hospName: "義大癌治療醫院"
    },
    {
        userNo: "1004",
        userName: "陳文旭",
        depNo: "A04",
        depName: "醫療事務部",
        hospNo: "",
        hospName: ""
    },
    {
        userNo: "1005",
        userName: "陳文旭",
        depNo: "A04",
        depName: "醫療事務部",
        hospNo: "1142120001",
        hospName: "義大醫院"
    },
    {
        userNo: "1028",
        userName: "陳文旭",
        depNo: "A04",
        depName: "醫療事務部",
        hospNo: "PPPP",
        hospName: "義大屏東"
    },
    {
        userNo: "1033",
        userName: "屏東兩個",
        depNo: "A04",
        depName: "醫療事務部",
        hospNo: "PPPP",
        hospName: "義大屏東"
    },
    {
        userNo: "1010",
        userName: "大昌三個",
        depNo: "A04",
        depName: "醫療事務部",
        hospNo: "1107320017",
        hospName: "大昌醫院"
    },
    {
        userNo: "1111",
        userName: "大昌人",
        depNo: "A04",
        depName: "醫療事務部",
        hospNo: "1107320017",
        hospName: "大昌醫院"
    },
    {
        userNo: "1111",
        userName: "大昌人2",
        depNo: "A04",
        depName: "醫療事務部",
        hospNo: "1107320017",
        hospName: "大昌醫院"
    },
]


export {
    Layout,
    Title,
    ButtonStyle,
    InputStyle,
    formatTime,
    getDateRange,
    addListItem,
    addListItems,
    updateItem,
    updateItems,
    deleteItem,
    deleteItems,
    isOwnerUser,
    sanitizedData,
    getCurrentZoneNo,
    filterStringIsEmpty,
    fakeTestData
}