import {v1Api} from "../Http"
import {HttpMethodEnum} from "edah_utils/dist"

/**
 * CDSS服務查詢
 * @param params {Object} params
 * @returns {AxiosPromise}
 */
const queryByCdssId = (params) => v1Api.request({
    url: '/cdss/cdssInfo/queryByCdssId',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 儲存CDSS Info
 * @param data {Object} params
 * @return {AxiosPromise}
 */
const saveCdss = (data) => v1Api.request({
    url: '/cdss/cdssInfo/save',
    method: HttpMethodEnum.PUT,
    data
})

/**
 * 刪除CDSS Info
 * @param params params
 * @returns {AxiosPromise}
 */
const delCdssId = (params) => v1Api.request({
    url: '/cdss/cdssInfo/delete',
    method: HttpMethodEnum.DELETE,
    params
})

export {
    queryByCdssId,
    saveCdss,
    delCdssId
}