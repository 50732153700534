import A4Print from "./A4Print";
import {time2String} from "edah_utils/dist";
import {useSelector} from "react-redux";
import logOffDeleteBatches from "../RSIS/LogOffDeleteBatches/LogOffDeleteBatches";
import React from "react";
import {getBirthDayAndDetailedAge} from "../ReferralSystem/utils";

/**
 * 轉入單(回覆單)列印
 * @returns {JSX.Element}
 */
const TranInFormContent = () => {
    const printData = useSelector((state) => state.print.printData)
    const today = new Date();

    return <div className="max-w-4xl mx-auto p-4 font-sans break-after">
        <h1 className="text-2xl font-bold mb-2 text-center">全民健康保險回覆單</h1>
        <div className="flex justify-between mb-2 font-bold text-sm">
            <p>保險醫事服務機構代號:{printData?.hospNo}</p>
            <p className="mr-6">轉入單號:{printData?.tranNo}</p>
        </div>

        <div className="flex">
            <table className="w-full border-collapse border border-gray-400 text-sm mb-4">
                <tbody>
                <th rowSpan="11"
                    className="border border-gray-400 p-1 w-8 [writing-mode:vertical-lr] tracking-[3em]">接受轉診醫院診所
                </th>
                <tr>
                    <th rowSpan="4"
                        className="border border-gray-400 p-1 w-10  [writing-mode:vertical-lr] tracking-[.35em]">保險對象基本資料
                    </th>
                    <th colSpan="2" className="border border-gray-400 p-1">姓名</th>
                    <th colSpan="2" className="border border-gray-400 p-1">身分證號</th>
                    <th colSpan="1" className="border border-gray-400 p-1">性別</th>
                    <th className="border border-gray-400 p-1">出生日期</th>
                    <th colSpan="3" className="border border-gray-400 p-1">病歷號</th>
                </tr>
                <tr>
                    <td colSpan="2" className="border border-gray-400 p-1">{printData?.patientName}</td>
                    <td colSpan="2" className="border border-gray-400 p-1">{printData?.patientName}</td>
                    <td colSpan="1" className="border border-gray-400 p-1">{printData?.genderName}</td>
                    <td className="border border-gray-400 p-1">
                        {printData.birthDate?getBirthDayAndDetailedAge(printData.birthDate):''}</td>
                    <td colSpan="3" className="border border-gray-400 p-1">{printData?.patientId}</td>
                </tr>
                <tr>
                    <th className="border border-gray-400 p-1 ">聯絡電話</th>
                    <th colSpan="1" className="border border-gray-400 p-1">聯絡人</th>
                    <th colSpan="6" className="border border-gray-400 p-1">聯絡地址</th>
                </tr>
                <tr>
                    <td className="border border-gray-400 p-1">{printData?.tranIn.contactTel}</td>
                    <td colSpan="1" className="border border-gray-400 p-1">{printData?.tranIn.contact}</td>
                    <td colSpan="6" className="border border-gray-400 p-1">{printData?.tranIn.contactAddress}</td>
                </tr>
                <tr>
                    <th colSpan="1"
                        className="border border-gray-400 p-1 [writing-mode:vertical-lr] tracking-[.35em]">病歷摘要
                    </th>
                    <td colSpan="8"
                        className="border border-gray-400 p-1 text-xs">請參閱附件資料，相關檢驗與檢查報告結果請上轉診資訊平台查詢。
                    </td>
                </tr>
                <tr>
                    <th colSpan="1" className="border border-gray-400 p-1">轉診結果</th>
                    <td colSpan="8" className="border border-gray-400 p-1">{printData?.tranIn.tranFindingName}</td>
                </tr>
                <tr>
                    <th colSpan="1" rowSpan="2" className="border border-gray-400 p-1">院所地址</th>
                    <td colSpan="5" rowSpan="2"
                        className="border border-gray-400 p-1">{printData?.hospAddress}</td>
                    <th className="border border-gray-400 p-1">聯絡電話</th>
                    <td colSpan="3" className="border border-gray-400 p-1 text-xs">{printData?.hospTel}
                    </td>
                </tr>
                <tr>
                    <th className="border border-gray-400 p-1">電子信箱</th>
                    <td colSpan="3" className="border border-gray-400 p-1">{printData?.hospMail}</td>
                </tr>
                <tr>
                    <th colSpan="1" className="border border-gray-400 p-1">診治醫師</th>
                    <td colSpan="1" className="border border-gray-400 p-1 w-[50px]">姓名</td>
                    <td colSpan="1" className="border border-gray-400 p-1">{printData?.doctorName}</td>
                    <td colSpan="1" className="border border-gray-400 p-1">科別</td>
                    <td colSpan="2" className="border border-gray-400 p-1 w-[80px]">{printData?.divName}</td>
                    <th className="border border-gray-400 p-1">醫師簽章</th>
                    <td colSpan="2" className="border border-gray-400 p-1"></td>
                </tr>
                <tr>
                    <th colSpan="1" className="border border-gray-400 p-1">轉出日期</th>
                    <td colSpan="4" className="border border-gray-400 p-1">{printData?.encounterDate}</td>
                    <th className="border border-gray-400 p-1">有效期限</th>
                    <td colSpan="2" className="border border-gray-400 p-1">{printData?.tranIn.effDate}</td>
                </tr>
                <tr>
                    <th colSpan="1" rowSpan="2"
                        className="border border-gray-400 p-1 [writing-mode:vertical-lr] tracking-[.35em]">原診治醫療院所
                    </th>
                    <th colSpan="1" className="border border-gray-400 p-1">院所名稱</th>
                    <td colSpan="4" className="border border-gray-400 p-1">
                        {printData?.tranIn.hospNoIn}<br/>
                        {printData?.tranIn.hospNameIn}
                    </td>
                    <th colSpan="1" className="border border-gray-400 p-1">電話傳真</th>
                    <td className="border border-gray-400 p-1">{printData?.tranIn.tranInHospTel}</td>
                    <th className="border border-gray-400 p-1">電子信箱</th>
                    <td className="border border-gray-400 p-1">{printData?.tranIn.tranInHospMail}</td>
                </tr>
                <tr>
                    <th colSpan="1" className="border border-gray-400 p-1">診治醫師</th>
                    <td colSpan="1" className="border border-gray-400 p-1">姓名</td>
                    <td colSpan="1" className="border border-gray-400 p-1">{printData?.tranIn.replyDoctorName}</td>
                    <td colSpan="1" className="border border-gray-400 p-1">科別</td>
                    <td colSpan="1" className="border border-gray-400 p-1 w-[80px]">{printData?.tranIn.replyDivName}</td>
                    <th colSpan="1" className="border border-gray-400 p-1">醫師簽章</th>
                    <td className="border border-gray-400 p-1"></td>
                    <th colSpan="1" className="border border-gray-400 p-1">回覆日期</th>
                    <td colSpan="1" className="border border-gray-400 p-1">{printData?.tranIn.replyDate}</td>
                </tr>

                <tr>
                    <th colSpan="1" className="border border-gray-400 p-1">備註</th>
                    <td colSpan="9" className="border border-gray-400 p-1">
                        <p>本人: {printData?.divName} (病歷號 {printData?.patientId})，於 {time2String(printData?.tranIn.tranDate, 'yyyy-MM-DD HH:mm')} 經轉診至 {printData?.hospName}
                            {printData?.divName} 醫師 {printData?.doctorName} 醫師看診，</p>
                        <p>本人:_________，法定代理人:_________ ☐ 同意 ☐ 不同意
                            此次轉診後發生之各項病歷資料，可供原轉診院所查詢。</p>

                    </td>

                </tr>

                </tbody>
            </table>
            <div className="[writing-mode:vertical-lr] tracking-[0.5em]"> ★第一聯 回覆原診治醫院、診所</div>
        </div>

        <div className="text-xs text-gray-600 flex flex-row justify-end mr-6">
            <div>
                <p>列印日期: {time2String(today, 'yyyy-MM-DD HH:mm')}</p>
                <p>1021.04 修訂 1040601 實施</p>
            </div>
        </div>
    </div>
}

/**
 * 用藥資訊
 * @param info {Array}
 * @returns {Element}
 */
const UsageInfo = ({ info } = []) => {
  return (
    <table className="w-full border-separate border-spacing-2">
      <thead>
        <tr>
          <th className="border-gray-300 border text-left">藥品名稱</th>
          <th className="text-danger border-gray-300 border">次量</th>
          <th className="border-gray-300 border">單位</th>
          <th className="text-danger border-gray-300 border">用法</th>
          <th className="text-danger border-gray-300 border">天</th>
          <th className="border-gray-300 border">途徑</th>
          <th className="border-gray-300 border">總量</th>
          <th className="border-gray-300 border">單位</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="border border-gray-300 text-left">
            Amitriptyline 25mg/tab
          </td>
          <td className="border border-gray-300 text-center">1.0</td>
          <td className="border border-gray-300 text-center">TAB</td>
          <td className="border border-gray-300 text-center">HS</td>
          <td className="border border-gray-300 text-center">7</td>
          <td className="border border-gray-300 text-center">PO</td>
          <td className="border border-gray-300 text-center">7.00</td>
          <td className="border border-gray-300 text-center">TAB</td>
        </tr>
        {info?.map((item, index) => (
          <tr>
            <td className="border border-gray-300 text-left">
              Amitriptyline 25mg/tab
            </td>
            <td className="border border-gray-300 text-center">1.0</td>
            <td className="border border-gray-300 text-center">TAB</td>
            <td className="border border-gray-300 text-center">HS</td>
            <td className="border border-gray-300 text-center">7</td>
            <td className="border border-gray-300 text-center">PO</td>
            <td className="border border-gray-300 text-center">7.00</td>
            <td className="border border-gray-300 text-center">TAB</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

/**
 * 轉入單(回覆單) - 附件
 * @returns {Element}
 * @constructor
 */
const TranInFormSubContent = () => {
  const printData = useSelector((state) => state.print.printData);
  const today = new Date();

  return (
    <div className="mx-auto p-4">
      <h1 className="text-xl font-bold text-center mb-4">
        義大醫院轉診回覆單附件
      </h1>
      <div className="w-full flex justify-between mb-2 border-b border-b-black">
        <div className="flex">
          <div>病歷號：</div>
          <div>{printData?.patientId}</div>
        </div>
        <div className="flex">
          <div> 姓名：</div>
          <div>{printData?.patientName}</div>
        </div>
        <p className="">列印日期: {time2String(today, "yyyy-MM-DD HH:mm")}</p>
      </div>
      <h2 className="font-semibold mb-2">病歷摘要：</h2>

      <table className="w-full h-[calc(100vh-200px)]">
        <tr className="border border-black">
          <div className="p-4">
            <h2 className="font-semibold mb-2">
              (一)、病情摘要（主訴及簡短病史）：
            </h2>
            <p>test</p>
            <p>test</p>
          </div>
        </tr>
        <tr className="border border-black">
          <div className="p-4">
            <h2 className="font-semibold mb-2">(二)、診斷：</h2>
            <p>Z23</p>
          </div>
        </tr>
        <tr className="border border-black">
          <div className="p-4">
            <h2 className="font-semibold mb-2">(三)、檢查及治療摘要：</h2>
            <p>Upper GI Panendoscopy;</p>
          </div>
        </tr>
        <tr className="border border-black">
          <div className="p-4">
            <h2 className="font-semibold mb-2">(四)、用藥資訊：</h2>
            <UsageInfo />
          </div>
        </tr>
        <tr className="border border-black">
          <div className="p-4">
            <h2 className="font-semibold mb-2">藥物退敏史：</h2>
            <h2 className="font-semibold mb-2">建識事項：</h2>
          </div>
        </tr>
      </table>
    </div>
  );
};

export const PrintTranInForm = () => {
  return (
    <>
      <A4Print>
        <TranInFormContent />
      </A4Print>
      <A4Print>
        <TranInFormSubContent />
      </A4Print>
    </>
  );
};


export default PrintTranInForm