//Icon
import AddIcon from "../../../assets/images/icons/add_circle.svg"
import {ReactComponent as SaveBtnIcon} from "../../../assets/images/icons/save_default.svg"
import {ReactComponent as CancelBtnIcon} from "../../../assets/images/icons/cancel_default.svg"
import {ReactComponent as EditBtnIcon} from "../../../assets/images/ICCard/edit_square.svg"
import {ReactComponent as DeleteBtnIcon} from "../../../assets/images/ICCard/delete.svg"

// Import Function
import {BasePopup} from "../../Popup/BasePopup"
import {BaseInput} from "../../Input/BaseInput"
import {ApiErrorStatusEnum, arrayIsEmpty, fuzzySearchObjects, InputTypeEnum, ToastTypeEnum} from "edah_utils/dist"
import React, {useEffect, useState} from "react"
import {t} from "i18next"
import {wdisAddTagName, wdisDelTagName, wdisEditTagName, wdisQueryTagName} from "../../../api/v1/WDIS"
import useToast from "../../../hooks/useToast"
import {DeletePopup} from "../../Popup/DeletePopup";

/**
 * 模組選單popup
 * @param show {Boolean} 是否顯示
 * @param onClose {Function} 關閉彈窗
 * @param onDoubleClick {Function} 雙擊事件
 * @return {JSX.Element}
 */
export const ModuleMenuPopup = ({show, onClose, onDoubleClick}) => {
    /**
     * Show Toast
     */
    const showToast = useToast()

    // 是否顯示刪除彈窗
    const [isShow, setIsShow] = useState(show)

    //搜尋文字
    const [searchText, setSearchText] = useState('')

    //模板資料
    const [data, setData] = useState([])

    //是否顯示刪除彈窗
    const [showDeletePopup, setShowDeletePopup] = useState(false)

    //刪除資料
    const [delRecord, setDelRecord] = useState({})

    /**
     * 新增按鈕時
     * @return {void}
     */
    const handleAddButtonOnClick = () => {
        // 新增資料
        const newData = [...data]
        // 新增一筆資料
        newData.push({
            // 模板代號
            tagCode: '',
            // 模板說明
            tagName: '',
            // 是否編輯
            isEditing: true,
            // 是否為API取得
            isApi: false
        })
        // 設定資料
        setData(newData)
    }

    /**
     * 當搜尋文字改變時，更新 searchText
     * @param e {event} event
     * @return {void}
     */
    const handleSearchTextOnChange = (e) => setSearchText(e.target.value)

    /**
     * 關閉彈窗時
     * @return {void}
     */
    const handleClosePopupButtonOnClick = () => onClose()

    /**
     * 刪除彈窗關閉時
     * @return {void}
     */
    const handleDeletePopupCloseOnClick = () => setShowDeletePopup(false)

    /**
     * 刪除彈窗點擊刪除按鈕時
     * @return {void}
     */
    const handleDeletePopupYesBtnOnClick = () => delTagName()

    /**
     * 刪除彈窗點擊刪除按鈕時
     * @return {void}
     */
    const handleDeletePopupNoBtnOnClick = () => handleDeletePopupCloseOnClick()

    /**
     * 儲存按鈕點擊時
     * @param record {Object} 索引
     * @return {void}
     */
    const handleSaveOnClick = (record) => {
        // 如果是舊有資料
        if (record.isApi) {
            editTagName(record)
        } else {
            addTagName(record)
        }
    }

    /**
     * 取消按鈕點擊時
     * @param index {Number} 索引
     * @return {void}
     */
    const handleCancelOnClick = (index) => {
        // 複製資料
        const newData = [...data]
        // 設定編輯狀態
        newData[index].isEditing = false
        // 設定資料
        setData(newData)
    }

    /**
     * 編輯按鈕點擊時
     * @param index {number} 索引
     * @return {void}
     */
    const handleEditOnClick = (index) => {
        // 複製資料
        const newData = [...data]
        // 設定編輯狀態
        newData[index].isEditing = true
        // 設定資料
        setData(newData)
    }

    /**
     * 點擊刪除按鈕時
     * @param record {Object} 資料
     * @return {void}
     */
    const handleDeleteButtonOnClick = (record) => {
        // 設定刪除資料
        setDelRecord(record)
        // 顯示刪除彈窗
        setShowDeletePopup(true)
    }

    /**
     * 模板代號雙擊時
     * @param e {event} event
     * @param tagCode {String} 資料
     * @return {void}
     */
    const handleTagCodeOnDoubleClick = (e, tagCode) => {
        // 取得按鈕
        const {button} = e
        // 如果是左鍵
        if (button === 0) {
            // 返回雙擊事件
            onDoubleClick(tagCode)
            // 關閉彈窗
            onClose()
        }
    }

    /**
     * 模板代號改變時
     * @param e {event} event
     * @param index {number} 索引
     * @return {void}
     */
    const handleTagCodeOnChange = (e, index) => {
        // 複製資料
        const newData = [...data]
        // 設定模板代號
        newData[index].tagCode = e.target.value
        // 設定資料
        setData(newData)
    }

    /**
     * 模板說明改變時
     * @param e {event} event
     * @param index {number} 索引
     * @return {void}
     */
    const handleTagNameOnChange = (e, index) => {
        // 複製資料
        const newData = [...data]
        // 設定模板說明
        newData[index].tagName = e.target.value
        // 設定資料
        setData(newData)
    }

    /**
     * 取得過濾後的資料
     * @return {Array<Object>} 過濾後的資料
     */
    const getFilterData = () => fuzzySearchObjects(data, searchText, ['isEditing', 'isApi'])

    /**
     * 查詢所有通知內容板模維護之內容
     * @return {void}
     */
    const getQueryTagName = () => {
        wdisQueryTagName({}).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            // 如果狀態是成功
            if (err === ApiErrorStatusEnum.Success) {
                // 新資料
                const newData = data.map(item => ({
                    // 模板代號
                    tagCode: item.tagCode,
                    // 模板說明
                    tagName: item.tagName,
                    // 是否編輯
                    isEditing: false,
                    // 是否為API取得
                    isApi: true
                }))
                // 設定資料
                setData(newData)
            } else { // 如果狀態是失敗
                // 清空資料
                setData([])
                // 顯示錯誤訊息
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 編輯模板名稱
     * @param record {Object} 索引
     * @return {void}
     */
    const editTagName = (record) => {
        wdisEditTagName(record).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            // 如果狀態是成功
            if (err === ApiErrorStatusEnum.Success) {
                // 顯示成功訊息
                showToast({message: msg, type: ToastTypeEnum.Success})
                // 取得通知內容板模維護之內容
                getQueryTagName()
            } else { // 如果狀態是失敗
                // 顯示錯誤訊息
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 新增模板名稱
     * @param record {Object} 資料
     * @return {void}
     */
    const addTagName = (record) => {
        wdisAddTagName(record).then(res => {
            // 狀態 / 訊息
            const {err, msg} = res
            // 如果狀態是成功
            if (err === ApiErrorStatusEnum.Success) {
                // 顯示成功訊息
                showToast({message: msg})
                // 取得通知內容板模維護之內容
                getQueryTagName()
            } else { // 如果狀態是失敗
                // 顯示錯誤訊息
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 刪除模板名稱
     * @return {void}
     */
    const delTagName = () => {
        wdisDelTagName(delRecord).then(res => {
            // 狀態 / 訊息
            const {err, msg} = res
            // 如果狀態是成功
            if (err === ApiErrorStatusEnum.Success) {
                // 關閉刪除彈窗
                setShowDeletePopup(false)
                // 顯示成功訊息
                showToast({message: msg})
                // 取得通知內容板模維護之內容
                getQueryTagName()
            } else { // 如果狀態是失敗
                // 顯示錯誤訊息
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 取得Content
     * @return {JSX.Element}
     */
    const getContent = () => <div>
        <div
            className="w-full h-[60px] py-[10px] flex flex-row items-center justify-between">
            {/*新增按鈕*/}
            <button className="flex flex-row justify-center items-center w-[112px] h-10"
                    onClick={handleAddButtonOnClick}>
                <div className="p-[3.33px] w-10 h-10">
                    <img className="w-full h-full" src={AddIcon} alt="addIcon"/>
                </div>
                <p className="text-center text-[#38A169]">
                    {t('general.add')}
                </p>
            </button>
            {/*進階搜尋*/}
            <BaseInput className="w-[185px] h-10 pl-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                       type={InputTypeEnum.Text} inputMode={'search'} value={searchText}
                       onChange={handleSearchTextOnChange}/>
        </div>
        {/*模板清單*/}
        <div className="maintainContainer flex flex-row items-start justify-start p-4">
            <div
                className="p-4 bg-white w-full border-[1px] border-[#D4D4D8] rounded-[12px]">
                <ul className="tableList modalList">
                    <li className="title">
                        <div>操作</div>
                        <div>模板代號</div>
                        <div>模板說明</div>
                    </li>
                    {
                        !arrayIsEmpty(data) && getFilterData().map((item, index) =>
                            <li key={index}>
                                {/*是否編輯*/}
                                {
                                    item.isEditing ? (
                                        // 可編輯
                                        <>
                                            {/*操作*/}
                                            <div className="flex flex-row items-center justify-start">
                                                {/*儲存按鈕*/}
                                                <button
                                                    className="flex flex-row items-center justify-start text-[#2B6CB0] mr-3"
                                                    onClick={() => handleSaveOnClick(item)}>
                                                    <SaveBtnIcon/>
                                                    {t('general.save')}
                                                </button>
                                                {/*取消按鈕*/}
                                                <button className="flex flex-row items-center justify-start text-[#2B6CB0]"
                                                        onClick={() => handleCancelOnClick(index)}>
                                                    <CancelBtnIcon/>
                                                    {t('general.cancel')}
                                                </button>
                                            </div>
                                            {/*模板代號*/}
                                            <BaseInput
                                                className="w-[190px] h-10 pl-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                                                type={InputTypeEnum.Text} value={item.tagCode} disable={item.isApi}
                                                onChange={(e) => handleTagCodeOnChange(e, index)}/>
                                            {/*模板說明*/}
                                            <BaseInput
                                                className="w-[190px] h-10 pl-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                                                type={InputTypeEnum.Text} value={item.tagName}
                                                onChange={(e) => handleTagNameOnChange(e, index)}/>
                                        </>
                                    ) : (
                                        // 不可編輯
                                        <>
                                            <div className="flex flex-row items-center justify-start">
                                                {/*編輯按鈕*/}
                                                <button
                                                    className="flex flex-row items-center justify-start text-[#2B6CB0] mr-3"
                                                    onClick={() => handleEditOnClick(index)}>
                                                    <EditBtnIcon/>
                                                    {t('general.edit')}
                                                </button>
                                                {/*刪除按鈕*/}
                                                <button className="flex flex-row items-center justify-start text-[#2B6CB0]"
                                                        onClick={() => handleDeleteButtonOnClick(item)}>
                                                    <DeleteBtnIcon/>
                                                    {t('general.delete')}
                                                </button>
                                            </div>
                                            {/*模板代號*/}
                                            <div onDoubleClick={(e) => handleTagCodeOnDoubleClick(e, item.tagCode)}>
                                                {item.tagCode}
                                            </div>
                                            {/*模板說明*/}
                                            <div>
                                                {item.tagName}
                                            </div>
                                        </>
                                    )
                                }
                            </li>
                        )
                    }
                </ul>
            </div>
        </div>
    </div>

    /**
     * 當 show 改變時，更新 isShow
     * @return {void}
     */
    useEffect(() => {
        // 設定是否顯示
        setIsShow(show)
    }, [show])

    /**
     * 第一次執行時
     * @return {void}
     */
    useEffect(() => getQueryTagName(), [])

    return isShow ?
        <>
            <BasePopup title={'模板清單'} closePopupButtonOnClick={handleClosePopupButtonOnClick} width={'700px'}
                       content={getContent()}/>
            <DeletePopup show={showDeletePopup} content="是否確定刪除?"
                         onClosePopupButtonClick={handleDeletePopupCloseOnClick}
                         onYesButtonClick={handleDeletePopupYesBtnOnClick}
                         onNoButtonClick={handleDeletePopupNoBtnOnClick}/>
        </> : null
}
