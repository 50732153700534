import React from "react";
import {DatePicker, Divider, Field, Select, SizeEnum, TextField} from "edah-component/dist";
import dayjs from "dayjs";

/**
 * 基本資料 Tab Content
 * @param isEditable {Boolean} control form editable
 * @return {JSX.Element}
 */
export const InfoContent = ({isEditable}) => {
    const [dataValues, setDataValues] = React.useState({
        // 姓名
        name: 'test',
        // 資料正確否
        dataCorrect: 'true',
        // 身分證號碼
        idNumber: '',
        // 出生日期
        birthDate: '',
        // 上傳資料型態
        medicalDataFormat: '0',
        // 資料格式
        dataFormat: '0',
        // 醫療院所代碼
        hospitalCode: '',
        // 安全模組
        securityModule: '',
        // IC卡卡號
        cardNumber: '',
        // 就醫序號
        medicalNumber: '',
        // 就診日期時間
        medicalDateTime: '',
        // 註記
        note: '0',
        // 就醫類別
        medicalType: '',
        // 就醫類別描述
        medicalDescription: '',
        // 寫卡錯誤碼1
        errorCode1: '',
        // 寫卡錯誤碼2
        errorCode2: '',
        // 新生兒就醫註記
        newbornMedicalNote: '',
        // 新生兒生日
        newbornBirthday: '',
        // 新生兒胞胎數
        newbornSiblingCount: '',
        // 被依附者非產婦之身分證
        dependentId: '',
        // 醫事人員身分證號
        medicalStaffId: '',
        // 床位
        bedNumber: '',
        // 疫苗種類
        vaccineType: '',
        // 疫苗批號
        vaccineBatchNumber: '',
        // 大便卡1
        fecalCard1: '',
        // 大便卡2
        fecalCard2: '',
        // 新增批號
        newBatchNumber: '0',
        // 診斷碼1
        diagnosisCode1: '',
        // 診斷碼2
        diagnosisCode2: '',
        // 診斷碼3
        diagnosisCode3: '',
        // 診斷碼4
        diagnosisCode4: '',
        // 診斷碼5
        diagnosisCode5: '',
        // 診斷碼6
        diagnosisCode6: '',
        // 給付類別1
        benefitCategory1: '',
        // 給付類別2
        benefitCategory2: '',
        // 就醫識別碼
        medicalId: '',
        // 安全簽章
        securitySignature: '',
        // 過敏資料
        allergyData: '',
    })

    /**
     * 控制表單編輯樣式class
     * @return {JSX.Element}
     */
    const getMaintainInfoClass = () => {
        return `maintainInfo ${isEditable ? '' : 'disabled'}`;
    }

    /**
     * 控制輸入內容變化
     * @param key {String}
     * @param value {String}
     * @return {void}
     */
    const handleInputChange = (key, value) => {
        setDataValues({
            ...dataValues,
            [key]: value
        })
    }

    const handleBirthDate = (date) => {
        const formattedDate = dayjs(date).isValid() ? dayjs(date).format('YYYY-MM-DD') : null
        setDataValues({
            ...dataValues,
            birthDate: formattedDate
        })
    }

    const handleEncounterDate = (date) => {
        const formattedDate = dayjs(date).isValid() ? dayjs(date).format('YYYY-MM-DD') : null
        setDataValues({
            ...dataValues,
            medicalDateTime: formattedDate
        })
    }

    const handleNewbornBirthDay = (date) => {
        const formattedDate = dayjs(date).isValid() ? dayjs(date).format('YYYY-MM-DD') : null
        setDataValues({
            ...dataValues,
            newbornBirthday: formattedDate
        })
    }
    return (
        <div className="space-y-3">
            <div className="flex space-y-3">
                <Field childrenSx={{ml: 3}} label="姓名" isShowColon={false}>
                    {isEditable ? <TextField size={SizeEnum.Small} value={dataValues.name}
                                             onChange={(e) => handleInputChange('name', e.target.value)}
                                             disabled/> : dataValues.name}
                </Field>
                <Field childrenSx={{ml: 3}} label="資料正確否" isShowColon={false}>
                    {isEditable ? (
                        <Select
                            data={{
                                label: '資料正確否',
                                options: [
                                    {label: '正確', value: 'true'},
                                    {label: '不正確', value: 'false'}
                                ]
                            }}
                            value={dataValues.dataCorrect}
                            showLabel={false}
                            onChange={(newValue) => handleInputChange('dataCorrect', newValue)}/>
                    ) : (
                        <p>{dataValues.dataCorrect === 'true' ? '正確' : '不正確'}</p>
                    )}
                </Field>
            </div>
            {!isEditable && <Divider/>}
            <div className="flex">
                <Field childrenSx={{ml: 3}} label="身分證號" isShowColon={false}>
                    {isEditable ? <TextField size={SizeEnum.Small} value={dataValues.idNumber}
                                             onChange={(e) => handleInputChange('idNumber', e.target.value)}/> : dataValues.idNumber}
                </Field>
                <Field childrenSx={{ml: 3}} label="出生日期" isShowColon={false}>
                    {isEditable ? <DatePicker value={dayjs(dataValues.birthDate)}
                                              onChange={handleBirthDate}/> : dataValues.birthDate}
                </Field>
            </div>
            {!isEditable && <Divider/>}
            <div className="flex">
                <Field childrenSx={{ml: 3}} label="上傳資料型態" isShowColon={false}>
                    {isEditable ? (
                        <Select
                            data={{
                                label: "上傳資料型態",
                                options: [
                                    {value: "0", label: "健保就醫"},
                                    {value: "1", label: "無健保就醫"},
                                ]
                            }}
                            value={dataValues.medicalDataFormat}
                            showLabel={false}
                            onChange={(value) => handleInputChange('medicalDataFormat', value)}
                        />
                    ) : (
                        <p>{dataValues.medicalDataFormat === "0" ? '健保就醫' : ''}</p>
                    )}
                </Field>
                <Field childrenSx={{ml: 3}} label="資料格式" isShowColon={false}>
                    {isEditable ? (
                        <Select
                            data={{
                                label: '資料格式',
                                options: [
                                    {label: 'D-整筆刪除', value: '0'},
                                ]
                            }}
                            value={dataValues.dataFormat}
                            showLabel={false}
                            onChange={(newValue) => handleInputChange('dataFormat', newValue)}/>
                    ) : (
                        <p>{dataValues.medicalDataFormat === "0" ? 'D-整筆刪除' : ''}</p>
                    )}
                </Field>

            </div>
            {!isEditable && <Divider/>}
            <div className="flex">
                <Field childrenSx={{ml: 3}} label="醫療院所代碼" isShowColon={false}>
                    {
                        isEditable ? <TextField size={SizeEnum.Small}
                                value={dataValues.hospitalCode}
                                onChange={(e) => handleInputChange('hospitalCode', e.target.value)}/>
                            : dataValues.hospitalCode
                    }
                </Field>
                <Field childrenSx={{ml: 3}} label="安全模組" isShowColon={false}>
                    {
                        isEditable ? <TextField size={SizeEnum.Small} value={dataValues.securityModule}
                                                onChange={(e) => handleInputChange('securityModule', e.target.value)}/>
                            : dataValues.securityModule
                    }
                </Field>
            </div>
            {!isEditable && <Divider/>}
            <div className="flex">
                <Field childrenSx={{ml: 3}} label="IC卡卡號" isShowColon={false}>
                    {
                        isEditable ? <TextField size={SizeEnum.Small} value={dataValues.cardNumber}
                                                onChange={(e) => handleInputChange('cardNumber', e.target.value)}/>
                            : dataValues.cardNumber
                    }
                </Field>
                <Field childrenSx={{ml: 3}} label="就醫序號" isShowColon={false}>
                    {
                        isEditable ? <TextField size={SizeEnum.Small} value={dataValues.medicalNumber}
                                                onChange={(e) => handleInputChange('medicalNumber', e.target.value)}/>
                            : dataValues.medicalNumber
                    }
                </Field>
            </div>
            {!isEditable && <Divider/>}
            <div className="flex">
                <Field childrenSx={{ml: 3}} label="就診日期時間" isShowColon={false}>
                    {
                        isEditable ? <DatePicker value={dayjs(dataValues.medicalDateTime)}
                                                 onChange={handleEncounterDate}/>
                            : dataValues.medicalDateTime
                    }
                </Field>
                <Field childrenSx={{ml: 3}} label="註記" isShowColon={false}>
                    {
                        isEditable ? (
                            <Select
                                data={{
                                    label: '註記',
                                    options: [
                                        {label: '正常', value: '0'},
                                    ]
                                }}
                                value={dataValues.note}
                                showLabel={false}
                                onChange={(newValue) => handleInputChange('note', newValue)}/>
                        ) : (
                            <p>{dataValues.note === "0" ? '正常' : ''}</p>
                        )
                    }
                </Field>
            </div>
            {!isEditable && <Divider/>}
            <div>
                <Field childrenSx={{ml: 3}} label="就醫類別" isShowColon={false}>
                    {
                        isEditable ?
                            <>
                                <TextField size={SizeEnum.Small} sx={{mr: 1}} inputWidth={SizeEnum.Small}
                                           value={dataValues.medicalType}
                                           onChange={(e) => handleInputChange('medicalType', e.target.value)}/>
                                <TextField size={SizeEnum.Small} inputWidth={SizeEnum.XLarge} value={dataValues.medicalDescription}
                                           onChange={(e) => handleInputChange('medicalDescription', e.target.value)}
                                           disabled/>
                            </> : `${dataValues.medicalType} ${dataValues.medicalDescription}`
                    }
                </Field>
            </div>
            {!isEditable && <Divider/>}
            <div>
                <Field childrenSx={{ml: 3}} label="寫卡錯誤碼" isShowColon={false}>
                    {
                        isEditable ?
                            <>
                                <TextField size={SizeEnum.Small} sx={{mr: 1}} inputWidth={SizeEnum.Small} value={dataValues.errorCode1}
                                           onChange={(e) => handleInputChange('errorCode1', e.target.value)}/>
                                <TextField size={SizeEnum.Small} inputWidth={SizeEnum.XLarge} value={dataValues.errorCode2}
                                           onChange={(e) => handleInputChange('errorCode2', e.target.value)}
                                           disabled/>
                            </> : `${dataValues.errorCode1} ${dataValues.errorCode2}`
                    }
                </Field>
            </div>
            {!isEditable && <Divider/>}
            <div className="flex">
                <Field childrenSx={{ml: 3}} label="新生兒就醫註記" isShowColon={false}>
                    {
                        isEditable ?
                            <TextField size={SizeEnum.Small} value={dataValues.newbornMedicalNote}
                                       onChange={(e) => handleInputChange('newbornMedicalNote', e.target.value)}/>
                            : dataValues.newbornMedicalNote
                    }
                </Field>
                <Field childrenSx={{ml: 3}} label="新生兒生日" isShowColon={false}>
                    {
                        isEditable ?
                            <DatePicker value={dayjs(dataValues.newbornBirthday)}
                                        onChange={handleNewbornBirthDay}/>
                            : dataValues.newbornBirthday
                    }
                </Field>
            </div>
            {!isEditable && <Divider/>}
            <div className="flex">
                <Field childrenSx={{ml: 3}} label="新生兒胞胎數" isShowColon={false}>
                    {
                        isEditable ?
                            <TextField size={SizeEnum.Small} value={dataValues.newbornSiblingCount}
                                       onChange={(e) => handleInputChange('newbornSiblingCount', e.target.value)}/>
                            : dataValues.newbornSiblingCount
                    }
                </Field>
                <Field childrenSx={{ml: 3}} label="被依附者非產婦之身分證" isShowColon={false}>
                    {
                        isEditable ?
                            <TextField size={SizeEnum.Small} value={dataValues.dependentId}
                                       onChange={(e) => handleInputChange('dependentId', e.target.value)}/>
                            : dataValues.dependentId
                    }
                </Field>
            </div>
            {!isEditable && <Divider/>}
            <div className="flex">
                <Field childrenSx={{ml: 3}} label="醫事人員身分證號" isShowColon={false}>
                    {
                        isEditable ?
                            <TextField size={SizeEnum.Small} value={dataValues.medicalStaffId}
                                       onChange={(e) => handleInputChange('medicalStaffId', e.target.value)}/>
                            : dataValues.medicalStaffId
                    }
                </Field>
                <Field childrenSx={{ml: 3}} label="床位" isShowColon={false}>
                    {
                        isEditable ?
                            <TextField size={SizeEnum.Small} value={dataValues.bedNumber}
                                       onChange={(e) => handleInputChange('bedNumber', e.target.value)}/>
                            : dataValues.bedNumber
                    }
                </Field>
            </div>
            {!isEditable && <Divider/>}
            <div className="flex">
                <Field childrenSx={{ml: 3}} label="疫苗種類" isShowColon={false}>
                    {
                        isEditable ?
                            <TextField size={SizeEnum.Small} value={dataValues.vaccineType}
                                       onChange={(e) => handleInputChange('vaccineType', e.target.value)}/>
                            : dataValues.vaccineType
                    }
                </Field>
                <Field childrenSx={{ml: 3}} label="疫苗批號" isShowColon={false}>
                    {
                        isEditable ?
                            <TextField size={SizeEnum.Small} value={dataValues.vaccineBatchNumber}
                                       onChange={(e) => handleInputChange('vaccineBatchNumber', e.target.value)}/>
                            : dataValues.vaccineBatchNumber
                    }
                </Field>
            </div>
            {!isEditable && <Divider/>}
            <div className="flex">
                <Field childrenSx={{ml: 3}} label="大便卡" isShowColon={false}>
                    {
                        isEditable ?
                            <div className="space-x-1">
                                <TextField size={SizeEnum.Small} inputWidth={SizeEnum.Small} value={dataValues.fecalCard1}
                                           onChange={(e) => handleInputChange('fecalCard1', e.target.value)}/>
                                <TextField size={SizeEnum.Small} inputWidth={SizeEnum.Small} value={dataValues.fecalCard2}
                                           onChange={(e) => handleInputChange('fecalCard2', e.target.value)}/>
                            </div>
                            : `${dataValues.fecalCard1} ${dataValues.fecalCard2}`
                    }
                </Field>
                <Field childrenSx={{ml: 3}} label="新增批號" isShowColon={false}>
                    {
                        isEditable ? (
                            <Select
                                data={{
                                    label: "新增批號",
                                    options: [{value: "0", label: "--"}]
                                }}
                                value={dataValues.newBatchNumber}
                                showLabel={false}
                                onChange={(value) => handleInputChange('newBatchNumber', value)}/>
                        ) : (
                            <p>{dataValues.newBatchNumber === "0" ? '--' : ''}</p>
                        )
                    }
                </Field>
            </div>
            <div>
                <Field childrenSx={{ml: 3}} label="診斷碼" isShowColon={false}>
                    <div className="grid grid-cols-6 gap-1">
                        {
                            isEditable ? <>
                                <TextField size={SizeEnum.Small} inputWidth={SizeEnum.Fill} value={dataValues.diagnosisCode1}
                                           onChange={(e) => handleInputChange('diagnosisCode1', e.target.value)}/>
                                <TextField size={SizeEnum.Small} inputWidth={SizeEnum.Fill} value={dataValues.diagnosisCode2}
                                           onChange={(e) => handleInputChange('diagnosisCode2', e.target.value)}/>
                                <TextField size={SizeEnum.Small} inputWidth={SizeEnum.Fill} value={dataValues.diagnosisCode3}
                                           onChange={(e) => handleInputChange('diagnosisCode3', e.target.value)}/>
                                <TextField size={SizeEnum.Small} inputWidth={SizeEnum.Fill} value={dataValues.diagnosisCode4}
                                           onChange={(e) => handleInputChange('diagnosisCode4', e.target.value)}/>
                                <TextField size={SizeEnum.Small} inputWidth={SizeEnum.Fill} value={dataValues.diagnosisCode5}
                                           onChange={(e) => handleInputChange('diagnosisCode5', e.target.value)}/>
                                <TextField size={SizeEnum.Small} inputWidth={SizeEnum.Fill} value={dataValues.diagnosisCode6}
                                           onChange={(e) => handleInputChange('diagnosisCode6', e.target.value)}/>
                            </> : <>
                                <div>{dataValues.diagnosisCode1}</div>
                                <div>{dataValues.diagnosisCode2}</div>
                                <div>{dataValues.diagnosisCode3}</div>
                                <div>{dataValues.diagnosisCode4}</div>
                                <div>{dataValues.diagnosisCode5}</div>
                                <div>{dataValues.diagnosisCode6}</div>
                            </>
                        }
                    </div>
                </Field>
            </div>
            {!isEditable && <Divider/>}
            <div>
                <Field childrenSx={{ml: 3}} label="給付類別" isShowColon={false}>
                    {
                        isEditable ?
                            <>
                                <TextField size={SizeEnum.Small} sx={{mr: 1}} inputWidth={SizeEnum.Small} value={dataValues.benefitCategory1}
                                           onChange={(e) => handleInputChange('benefitCategory1', e.target.value)}/>
                                <TextField size={SizeEnum.Small} inputWidth={SizeEnum.XLarge} value={dataValues.benefitCategory2}
                                           onChange={(e) => handleInputChange('benefitCategory2', e.target.value)}
                                           disabled/>
                            </> : `${dataValues.benefitCategory1} ${dataValues.benefitCategory2}`
                    }
                </Field>
            </div>
            {!isEditable && <Divider/>}
            <div>
                <Field childrenSx={{ml: 3}} label="就醫識別碼" isShowColon={false}>
                    {
                        isEditable ?
                            <TextField size={SizeEnum.Small} inputWidth={SizeEnum.Fill} value={dataValues.medicalId}
                                       onChange={(e) => handleInputChange('medicalId', e.target.value)}/> : dataValues.medicalId
                    }
                </Field>
            </div>
            {!isEditable && <Divider/>}
            <div>
                <Field childrenSx={{ml: 3}} label="安全簽章" isShowColon={false}>
                    {
                        isEditable ?
                            <TextField size={SizeEnum.Small} inputWidth={SizeEnum.Fill} value={dataValues.securitySignature}
                                       onChange={(e) => handleInputChange('securitySignature', e.target.value)}
                                       disabled/> : dataValues.securitySignature
                    }
                </Field>
            </div>
            {!isEditable && <Divider/>}
            <div>
                <Field childrenSx={{ml: 3}} label="過敏資料" isShowColon={false}>
                    {
                        isEditable ?
                            <TextField size={SizeEnum.Small} inputWidth={SizeEnum.Fill} value={dataValues.allergyData}
                                       onChange={(e) => handleInputChange('allergyData', e.target.value)}/>
                            : dataValues.allergyData
                    }
                </Field>
            </div>
        </div>
    )
}
