//Import icons
import { ReactComponent as CheckIcon } from "../../assets/images/icons/check.svg";
import { ReactComponent as CollapseIcon } from "../../assets/images/icons/collapse.svg";
import { ReactComponent as RefreshIcon } from "../../assets/images/icons/refresh.svg";
import { ReactComponent as UserIcon } from "../../assets/images/icons/user.svg";
import { ReactComponent as CardIcon } from "../../assets/images/icons/card.svg";
import { ReactComponent as DataIcon } from "../../assets/images/icons/data.svg";
import { ReactComponent as EditIcon } from "../../assets/images/icons/edit.svg";
//Import function
import { useState } from "react";
import { t } from "i18next";

//Import layout
import DefaultLayout from "../../layout/DefaultLayout";
//Import tab button
// import Tab from "../../components/Tab/Tab";
import Tab from "../../components/Tab/Tab.jsx";

const Demo = () => {
  // 控制基本資料展開
  const [isCollapse, setCollapse] = useState(false);

  const handleCollapse = () => setCollapse(!isCollapse);

  const collapseClass = isCollapse ? "active" : "";
  return (
    <DefaultLayout
      content={<div className="w-full h-full bg-red-600">HELLO</div>}
    />
  );
};
export default Demo;
