import React from "react"

/**
 * IC卡資料上傳資料查詢-上傳XML
 * @param show {boolean} 是否顯示
 * @return {JSX.Element}
 */
export const UploadXMLTab = ({show}) => {

    return <div className="w-[835px] h-[754px] p-4 bg-[#F4F4F5] rounded-[6px]">
        2
    </div>
}
