// Import CSS
import '../../TableList/tableList.scss'

import React, {useEffect, useMemo, useState} from 'react'
import {
    ApiErrorStatusEnum,
    arrayIsEmpty,
    getLocalStorage,
    multiTermFuzzySearchObjects,
    RsisStepStatusEnum,
    stringIsEmpty,
} from 'edah_utils/dist'
import {usePopup} from '../PopupProvider';
import CustomTable from '../RSISCustomTable';
import DeletePopup from '../Popup/DeletePopup';
import SupportDateChoice from "./SupportDateChoice";
import {t} from 'i18next'

// import API
import {rsisAddMaster, rsisQueryAgentByUserNo, rsisQueryBaseData, rsisQueryCodeValue,} from '../../../api/v1/RSIS';
import QuerySupportOrg from "./QuerySupportOrg";
import QueryPersonList from "./QueryPersonList";
import {checkDoctorLicence} from "../../../api/v1/Menu";
import {
    AlertTypeEnum,
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    Card,
    Checkbox,
    Dialog,
    DialogVariant,
    Divider,
    Field,
    FontSizeEnum,
    IconEnum,
    Search,
    Select,
    SizeEnum,
    TextField,
    SelectWidthEnum
} from "edah-component/dist";
import {SearchTextField} from "../../SearchTextField/SearchTextField";

// 表格欄位
const fields = [
    "",
    "開始日期",
    "結束日期",
    "開始時間",
    "結束時間",
]

/**
 * 新增報備支援Popup
 * @param doctorList {array} 醫師清單
 * @param orgList {array} 機構清單
 * @param deptList {array} 承辦部門清單
 * @param handleOnUpdate {(createUser:String|null)=>void} 更新列表資料
 * @param handleOnClose {function} 關閉彈窗事件
 * @return {JSX.Element}
 */
const AddModal = ({doctorList, orgList, deptList, handleOnUpdate, handleOnClose}) => {
    //當前院區
    const currentCampus = getLocalStorage('campus') || ''
    // 資料集
    const [dataList, setDataList] = useState([])
    // 員工/醫師代碼下拉清單
    const [showDoctorPopup, setShowDoctorPopup] = useState(false)
    // 機構代碼/名稱查詢彈窗
    const [showOrgDropdown, setShowOrgDropdown] = useState(false)
    // 所有科別清單
    const [allDivisionList, setAllDivisionList] = useState([])
    // 支援目的選項清單
    const [purposeOptions, setPurposeOptions] = useState([]);
    // 子支援目的選項清單
    const [subPurposeOptions, setSubPurposeOptions] = useState([]);
    // 性質清單
    const [kindList, setKindList] = useState([])

    /** 支援人員欄位 */
        // 員工/醫師
    const [supportUserNo, setSupportUserNo] = useState('')
    // 員工/醫師名稱
    const [supportUserName, setSupportUserName] = useState('')
    // 執登科別編號
    const [divNo, setDivNo] = useState('')
    // 執登科別名稱
    const [divName, setDivName] = useState('')
    // 證書字號
    const [licenceId, setLicenceId] = useState('')
    // 職類別
    const [licenceKind, setLicenceKind] = useState('')
    // 職類別名稱
    const [licenceKindName, setLicenceKindName] = useState('')
    // 職登機構代碼
    const [hospNo, setHospNo] = useState('')
    // 職登機構名稱
    const [hospName, setHospName] = useState('')

    /** 報備支援內容欄位 */
        // 機構代碼/名稱
    const [supportOrgNo, setSupportOrgNo] = useState('')
    // 機構名稱
    const [supportOrgName, setSupportOrgName] = useState('')
    // 申請單號
    const [supportNo, setSupportNo] = useState('')
    // 支援科別字串
    const [supportDivStr, setSupportDivStr] = useState('')
    // 支援科別
    const [supportDivNo, setSupportDivNo] = useState('')
    // 支援科別名稱
    const [supportDivName, setSupportDivName] = useState('')
    // 支援目的
    const [purposeCode, setPurposeCode] = useState(['10'])
    // 支援目的門住
    const [purposeCodeExt, setPurposeCodeExt] = useState('1')
    // 承辦部門字串
    const [ownerDeptStr, setOwnerDeptStr] = useState('')
    // 承辦部門
    const [ownerDeptNo, setOwnerDeptNo] = useState('')
    // 承辦部門名稱
    const [ownerDeptName, setOwnerDeptName] = useState('')
    // 代理人員字串
    const [agentUserStr, setAgentUserStr] = useState('')
    // 代理人員代號
    const [agentUserNo, setAgentUserNo] = useState('')
    // 代理人員姓名
    const [agentUserName, setAgentUserName] = useState('')
    // 性質
    const [supportProperties, setSupportProperties] = useState('')
    // 執行內容
    const [executeContent, setExecuteContent] = useState('')
    // 備註
    const [memo, setMemo] = useState('')
    // 員工/醫師狀態
    const [isSupportUserNoError, setIsSupportUserNoError] = useState(true)
    // 是否為承辦人員
    const isOwnUser = JSON.parse(getLocalStorage('isOwnUser')) || false
    // 使用上下文
    const {
        setShowSupportDateChoice,
        showSupportDateChoice,
        showToast,
        focusItem,
        setFocusItem,
    } = usePopup()

    // 分頁組件
    const [paginationProps, setPaginationProps] = useState({
        pageNum: 1,
        pageSize: 10,
        totalItemSize: 0,
        totalPageSize: 0,
        currentPageItemSize: 0,
        fn: () => {
        }
    })

    // 刪除彈窗開關
    const [showDeletePopup, setShowDeletePopup] = useState(false)

    // 代理人員清單
    const [agentUserList, setAgentUserList] = useState([])

    /**
     * 列表項目
     * @param item 列表資料
     * @param index 索引
     * @return {Element}
     */
    const ListItem = ({item, index}) => {
        /**
         * 點擊事件
         */
        const handleOnClick = () => {
            if (focusItem !== item) {
                // 設定待刪除的項目
                setFocusItem(item)
            }
        }
        return (
            <li key={index} onClick={handleOnClick}>
                <div>
                    {/* 刪除按鈕 */}
                    <Button
                        sx={{fontSize: FontSizeEnum.TextMedium}}
                        color={ButtonColorEnum.Primary} size={ButtonSizeEnum.Medium}
                        icon={IconEnum.Delete}
                        text={t('general.delete')} variant={ButtonVariantEnum.Text}
                        onClick={() => setShowDeletePopup(true)}/>
                </div>
                <div>{item.startDate}</div>
                <div>{item.endDate}</div>
                <div>{item.startTime}</div>
                <div>{item.endTime}</div>
            </li>
        )
    }

    /**
     * 更新列表項目
     * @param data {Array} 更新的支援期間明細資料
     * @return {void}
     */
    const handleUpdateDataList = (data) => setDataList(data)

    /**
     * 支援科別更新時
     * @param item {String} 選取的選項
     * @return {void}
     */
    const handleSupportDivChange = (item) => {
        // 設定支援科別顯示字串
        setSupportDivStr(item)
        // 設定支援科別代號
        setSupportDivNo(!stringIsEmpty(item) ? item.split(' ')[0] : '')
        // 設定支援科別名稱
        setSupportDivName(!stringIsEmpty(item) ? item.split(' ')[1] : '')
    }

    /**
     * 承辦部門人員更新時
     * @param item {String} 選取的選項
     * @return {void}
     */
    const handleOwnerDeptChange = (item) => {
        // 設定承辦部門顯示字串
        setOwnerDeptStr(item)
        // 設定承辦部門代號
        setOwnerDeptNo(item.split(' ')[0])
        // 設定承辦部門名稱
        setOwnerDeptName(item.split(' ')[1])
    }

    /**
     * 代理人員更新時
     * @param item {String} 選取的選項
     * @return {void}
     */
    const handleAgentUserNoChange = (item) => {
        // 設定代理人員顯示字串
        setAgentUserStr(item)
        // 設定代理人員代號
        setAgentUserNo(item.split(' ')[0])
        // 設定代理人員名稱
        setAgentUserName(item.split(' ')[1])
    }

    /**
     * 刪除列表項目
     * @param item {Object} 刪除的列表項目
     * @return {void}
     */
    const deleteRow = (item) => {
        // 刪除指定列表項目
        setDataList(dataList.filter((data) => data !== item))
        // 關閉刪除彈窗
        setShowDeletePopup(false)
        // 重置focusItem
        setFocusItem(null)
    }

    /**
     * 表單提交合法性檢查
     * @return {boolean} 是否通過檢查
     */
    const validateFormData = () => {
        // 是否選擇支援醫師
        if (!supportUserNo) {
            setIsSupportUserNoError(true)
            showToast({message: '請選擇支援醫師', type: AlertTypeEnum.Warning})
            return false
        }

        // 檢查機構代碼是否有值
        if (!supportOrgNo) {
            showToast({
                message: `支援者：${supportUserName || '未知'}，「支援機構」為必填欄位，請重新確認！`,
                type: AlertTypeEnum.Warning
            })
            return false
        }

        // 檢查支援科別是否有值
        if (!supportDivNo) {
            showToast({
                message: `支援者：${supportUserName || '未知'}，「支援科別」為必填欄位，請重新確認！`,
                type: AlertTypeEnum.Warning
            })
            return false
        }

        // 檢查支援期間是否有值
        if (dataList.length === 0) {
            showToast({message: '請新增支援日期', type: AlertTypeEnum.Warning})
            return false
        }

        return true
    }

    /**
     * 新增報備支援內容
     * @return {void}
     */
    const handleFormSave = () => {
        // 檢查表單合法性
        if (!validateFormData()) return

        // 遞交資料
        const sendData = dataList.map(item => {
            //取得機構物件
            const org = orgList.find(o => o.orgNo === supportOrgNo)
            //機構存在的話再回傳
            if (org) {
                return {
                    // 單號
                    supportNo: supportNo,
                    // 版本號
                    itemVersion: '1',
                    // 支援醫生代號
                    supportUserNo: supportUserNo,
                    // 支援機構Pkey
                    supportOrgPkey: org.orgPkey,
                    // 支援機構代號
                    supportOrgNo: supportOrgNo,
                    // 支援機構名稱
                    supportOrgName: supportOrgName,
                    // 支援科別代號
                    supportDivNo: supportDivNo,
                    // 支援科別名稱
                    supportDivName: supportDivName,
                    // 支援目的代碼
                    purposeCode: Array.isArray(purposeCode) ? purposeCode.join(',') : purposeCode,
                    // 支援目的說明
                    purposeCodeExt: purposeCodeExt,
                    // 支援日期起始日期
                    itemStartDate: `${item.startDate} 00:00:00`,
                    // 支援日期起始時間
                    itemStartTime: item.startTime.split(':')[0] + item.startTime.split(':')[1],
                    // 支援日期結束日期
                    itemEndDate: `${item.endDate} 00:00:00`,
                    // 支援日期結束時間
                    itemEndTime: item.endTime.split(':')[0] + item.endTime.split(':')[1],
                    // 支援性質
                    supportProperties: supportProperties,
                    // 執行內容
                    executeContent: executeContent,
                    // 備註
                    memo: memo,
                    // 代理人員代號
                    agentUserNo: agentUserNo,
                    // 狀態(1申請/2確認/3送審/9失效)
                    stepStatus: RsisStepStatusEnum.Apply,
                    // 變更/註銷狀態(null空:無/M: 變更/D:刪除)
                    supportModifyStatus: '',
                    // 變更/註銷日期
                    supportModifyDatetime: '',
                    // 變更/註銷原因代號
                    supportModifyReasonCode: '',
                    // 變更/註銷原因說明
                    supportModifyReasonDoc: "",
                    // 匯出編號
                    sendFileName: '',
                    // 衛福部案號
                    sendCaseNo: '',
                    // 送審日期
                    sendDatetime: '',
                    // 申請方式(N:一般 / E:急件申請)
                    applyType: 'N',
                    // 院區
                    zone: getLocalStorage('campus'),
                    // 有效否0/1
                    effFlag: true,
                    // 承辦部門代號
                    ownerDeptNo: ownerDeptNo,
                    // 欄位變更說明, 以中文標記欄位名稱,欄位間用","區隔
                    changeFieldsDoc: '',
                    // 收案確認人員代號
                    confirmUserNo: '',
                    // 收案確認日期時間
                    confirmDatetime: ''
                }
            }
        })
        // 執行新增報備支援內容
        saveAddMaster(sendData)
    }

    /**
     * 選取員工/醫師代碼選項時
     * @param item {Object} ListItem選取的選項
     * @return {void}
     */
    const handleDoctorOptionOnClick = (item) => {
        //取得醫師執登資料
        getDoctorData(item.userNo)
        //取得代理人員代號姓名
        getAgentUserList(item.userNo)
        //設定支援醫師代碼
        setSupportUserNo(item.userNo)
        //設定支援醫師名稱
        setSupportUserName(item.userName)
        //關閉彈窗
        setShowDoctorPopup(false)
    }

    /**
     * 機構代碼/名稱選項被選擇時
     * @param item {Object} 選取的選項
     * @return {void}
     */
    const handleSupportOrgOptionOnClick = (item) => {
        // 設定支援機構代號
        setSupportOrgNo(item.orgNo)
        // 設定支援機構名稱
        setSupportOrgName(item.orgName)
        // 關閉支援機構dropdown
        setShowOrgDropdown(false)
    }

    /**
     * 點擊支援期間明細編輯時
     * @return {void}
     */
    const handleCheckSupportNo = () => {
        // 判斷是否選擇支援醫師
        if (supportUserNo) {
            setIsSupportUserNoError(false)
            setShowSupportDateChoice(true)
        } else {
            // 顯示提示訊息
            showToast({message: '請選擇支援醫師', type: AlertTypeEnum.Warning})
            // 支援醫師狀態
            setIsSupportUserNoError(true)
        }
    }

    /**
     * 渲染支援目的選項
     * @return {JSX.Element}
     */
    const renderPurposeOptions = () => {
        // 過濾重複的選項
        const uniquePurposeOptions = Array.from(new Set(purposeOptions.map(item => item.codeValue1)))
            .filter(codeValue1 => codeValue1 != null)
            .map(codeValue1 => purposeOptions.find(item => item.codeValue1 === codeValue1))
            .filter(Boolean)
            .sort((a, b) => parseInt(a.codeNo) - parseInt(b.codeNo)); // 根據 codeNo 排序

        // 處理支援目的選項變更
        const handlePurposeChange = (codeNo) => {
            setPurposeCode(prevCodes => {
                if (prevCodes.includes(codeNo)) {
                    return prevCodes.filter(code => code !== codeNo);
                } else {
                    return [...prevCodes, codeNo];
                }
            });
        };

        return (
            <div className="grid grid-cols-5 gap-2 w-full text-center items-center">
                {
                    uniquePurposeOptions.map((item, index) => {
                        if (item.codeNo == null) {
                            return null; // 如果 codeNo 為 null，不渲染任何內容
                        }

                        if (item.codeNo === '10') {
                            return (
                                <Checkbox
                                    key={index}
                                    label={
                                        <div className='flex justify-around '>
                                            <div className='flex  items-center'>{item.codeValue1}</div>
                                            <Select
                                                data={{
                                                    label: '報備為一般資源',
                                                    options: subPurposeOptions.map((item) => ({
                                                        label: item.codeValue1,
                                                        value: item.codeNo,
                                                    }))
                                                }}
                                                value={purposeCodeExt}
                                                onChange={setPurposeCodeExt}
                                                showLabel={false}
                                                width={SelectWidthEnum.Tiny}
                                            />
                                        </div>}
                                    checked={purposeCode.includes(item.codeNo)}
                                    onChange={() => handlePurposeChange(item.codeNo)}/>
                            );
                        } else {
                            return (
                                <Checkbox
                                    key={index}
                                    label={item.codeValue1}
                                    checked={purposeCode.includes(item.codeNo)}
                                    onChange={() => handlePurposeChange(item.codeNo)}
                                />
                            )
                        }
                    })
                }
            </div>
        )
    }

    /**
     * 新增報備支援內容
     * @param data {Array} 待新增的內容
     * @return {void}
     */
    const saveAddMaster = (data) => {
        // 執行儲存報備支援內容
        rsisAddMaster(data).then(res => {
            // 狀態 / 訊息
            const {err, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                // 取得當前使用者
                const currentUserNo = getLocalStorage('userno')
                // 取得當前使用者名稱
                const currentUserName = getLocalStorage('username')
                // 更新列表資料
                handleOnUpdate(`${currentUserNo} ${currentUserName}`)
                handleOnClose()
                showToast({message: '新增成功', type: AlertTypeEnum.Success})
            } else {
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 依照員工/醫師代碼取得代理人員代號姓名
     * @param userNo {String} 員工/醫師代碼
     * @return {void}
     */
    const getAgentUserList = (userNo) => {
        // 根據員工/醫師代碼查詢代理人員代號姓名
        rsisQueryAgentByUserNo({
            userNo: userNo
        }).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                setAgentUserList(data)
            } else {
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 取得醫師執登資料
     * @param userNo {String} 醫師代碼
     * @return {void}
     */
    const getDoctorData = (userNo) => {
        // 根據醫師代碼查詢醫師資料
        checkDoctorLicence({
            doctorNo: userNo,
            divNo: ''
        }).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                if (data) {
                    // 設定執登科別代號
                    setDivNo(data.divNo)
                    // 設定執登科別名稱
                    setDivName(data.divName)
                    // 設定證書字號
                    setLicenceId(data.licenceId)
                    // 設定職類別
                    setLicenceKind(data.licenceKind)
                    // 設定職類別名稱
                    setLicenceKindName(data.licenceKindName)
                    // 設定執登機構編號
                    setHospNo(data.hospNo)
                    // 設定執登機構名稱
                    setHospName(data.hospName)
                    // 當支援科別代號及執登科別不為空時，自動帶入支援科別
                    data.supportDivNo && data.divName
                        ? handleSupportDivChange(`${data.supportDivNo} ${data.divName}`)
                        : handleSupportDivChange('')
                } else {
                    // 清空科別資料
                    setDivNo('')
                    // 清空科別名稱
                    setDivName('')
                    // 清空證書字號
                    setLicenceId('')
                    // 清空職類別
                    setLicenceKind('')
                    // 清空職類別名稱
                    setLicenceKindName('')
                    // 清空執登機構編號
                    setHospNo('')
                    // 清空執登機構名稱
                    setHospName('')
                    // 清空支援科別
                    handleSupportDivChange('')
                }
            } else {
                // 清空科別資料
                setDivNo('')
                // 清空科別名稱
                setDivName('')
                // 清空證書字號
                setLicenceId('')
                // 清空職類別
                setLicenceKind('')
                // 清空職類別名稱
                setLicenceKindName('')
                // 清空執登機構編號
                setHospNo('')
                // 清空執登機構名稱
                setHospName('')
                // 清空支援科別
                handleSupportDivChange('')
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 取得DIVISION中 SUPPORT_DIV_NO不為空的資料
     * @return {void}
     */
    const getAllDivision = () => {
        rsisQueryBaseData({}).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                setAllDivisionList(data?.supportDivList || [])
            } else {
                setAllDivisionList([])
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 取得所有基本資料設定
     * @return {void}
     */
    const getPurposeOptions = () => {
        rsisQueryCodeValue({effFlag: 'ALL'}).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                const purposeData = data.filter(item => item.codeType === 'PURPOSE')
                const subPurposeData = data.filter(item => item.codeType === 'SUBPURPOSE')
                const natureData = data.filter(item => item.codeType === 'KIND')
                // 設定支援目的選項
                setPurposeOptions(purposeData)
                // 設定子支援目的選項
                setSubPurposeOptions(subPurposeData)
                // 設定性質清單
                setKindList(natureData)

                if (!arrayIsEmpty(natureData))
                    setSupportProperties(natureData[0].codeNo)
            } else {
                setPurposeOptions([])
                setSubPurposeOptions([])
                setKindList([])
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 取得預設支援科別
     */
    const getDefaultDivNoValue = useMemo(() => {
            const data = allDivisionList?.find(option => supportDivNo?.includes(option?.supportDivNo || ''))
            return data && supportDivNo ? data : null
        }, [allDivisionList, supportDivNo]
    )

    /**
     * 取得預設部門值
     */
    const getDefaultDepNoValue = useMemo(() => {
            const data = deptList?.find(option => ownerDeptNo?.includes(option?.depNo || ''))
            return data && ownerDeptNo ? data : null
        }, [deptList, ownerDeptNo]
    )

    /**
     * 取得預設代理人員值
     */
    const getDefaultAgentUserValue = useMemo(() => {
            const data = agentUserList?.find(option => agentUserNo?.includes(option?.agentUserNo || ''))
            return data && agentUserNo ? data : null
        }, [agentUserList, agentUserNo]
    )

    /**
     * 第一次執行時
     * @return {void}
     */
    useEffect(() => {
        // 取得所有科別
        getAllDivision()
        // 取得支援目的選項
        getPurposeOptions()
    }, [])

    return (
        <Dialog
            open={true}
            title={t('RSIS.Popup.AddModal')}
            content={
                <div className='px-6'>
                    <div className='flex flex-row justify-between w-full gap-2'>
                        <div>
                            <Field label='員工/醫師代碼' isRequired>
                                <TextField
                                    size={SizeEnum.Small}
                                    value={`${supportUserNo} ${supportUserName}`}
                                    onClick={() => setShowDoctorPopup(true)}
                                    error={isSupportUserNoError}/>
                            </Field>
                        </div>
                        <div className='flex flex-row gap-2'>
                            <Select
                                data={{
                                    label: '院區',
                                    options: [{
                                        value: currentCampus,
                                        label: currentCampus ? t(`campus.${currentCampus}`) : ''
                                    }]
                                }}
                                value={currentCampus} disabled={true} width={SelectWidthEnum.Small} displayEmpty={true}
                                notched={true}/>
                            <span className='flex items-center text-[#38A169]'>
                                {`${t(`RSIS.StepStatusEnum.1`)}狀態`}
                            </span>
                        </div>
                    </div>
                    {/* 第二列資訊 */}
                    <div className='flex flex-row justify-between'>
                        <div className='flex flex-row items-center w-[70%]'>
                            {/* 執登機構 */}
                            <Field label="執登機構">
                                {!stringIsEmpty(hospNo) && !stringIsEmpty(hospName) ? `${hospNo} ${hospName}` : ''}
                            </Field>
                            {/* 職類別 */}
                            <Field label='職類別'>
                                {!stringIsEmpty(licenceKind) && !stringIsEmpty(licenceKindName) ? `${licenceKind} ${licenceKindName}` : ''}
                            </Field>
                            {/* 執登科別 */}
                            <Field label='執登科別'>
                                {!stringIsEmpty(divNo) && !stringIsEmpty(divName) ? `${divNo} ${divName}` : ''}
                            </Field>
                            {/* 證書字號 */}
                            <Field label="證書字號">{`${!stringIsEmpty(licenceId) ? licenceId : ''}`}</Field>
                        </div>
                        {/*存擋按鈕*/}
                        <div className='flex flex-row gap-2'>
                            <div className='flex my-2'>
                                {/*存檔*/}
                                <Button
                                    color={ButtonColorEnum.Primary} size={ButtonSizeEnum.Medium}
                                    text={t('general.saveFile')} variant={ButtonVariantEnum.Contained}
                                    onClick={handleFormSave}/>
                            </div>
                        </div>
                    </div>
                    <Card title={"報備支援內容"}>
                        {/* 報備支援內容 */}
                        <div className='flex flex-col mb-6 mt-6'>
                            <div className='w-full flex flex-col gap-2'>
                                <div className='grid grid-cols-2'>
                                    <Field label="機構代碼/名稱">
                                        <SearchTextField sx={{width: '100%'}} size={SizeEnum.Small}
                                                         value={`${supportOrgNo} ${supportOrgName}`}
                                                         onClick={() => setShowOrgDropdown(true)}/>
                                    </Field>
                                    <Field label="申請單號">{supportNo}</Field>
                                </div>
                                {/* 支援科別 */}
                                <Field label={"支援科別"}>
                                    <Search
                                        freeSolo
                                        value={getDefaultDivNoValue}
                                        onChange={(_e, value) => {
                                            const newValue = value?.supportDivNo ? `${value?.supportDivNo} ${value?.supportDivName}` : ''
                                            handleSupportDivChange(newValue)
                                        }}
                                        filterOptions={(options, {inputValue}) => {
                                            if (!inputValue) {
                                                return getDefaultDivNoValue || options
                                            }
                                            return multiTermFuzzySearchObjects(options, inputValue.split(' '))
                                        }}
                                        options={allDivisionList}
                                        getOptionLabel={(option) => `${option.supportDivNo} ${option.supportDivName}`}/>
                                </Field>
                                {/* 支援目的 */}
                                <Field label={"支援目的"} sx={{display: 'flex', alignItems: 'start'}}>
                                    {renderPurposeOptions()}
                                </Field>
                            </div>
                        </div>
                        {/* 分隔線 */}
                        <Divider/>
                        {/* 第二列表 */}
                        <div className='flex flex-col gap-2 my-4 mb-4'>
                            <div className='flex flex-row items-center justify-between gap-4'>
                                <div className='flex flex-row items-center gap-4'>
                                    {/* 承辦部門 */}
                                    <Field label={"承辦部門"}>
                                        <Search
                                            freeSolo
                                            value={getDefaultDepNoValue}
                                            onChange={(_e, value) => {
                                                const newValue = value?.deptNo ? `${value?.deptNo} ${value?.deptName}` : ''
                                                handleOwnerDeptChange(newValue)
                                            }}
                                            filterOptions={(options, {inputValue}) => {
                                                if (!inputValue) {
                                                    return getDefaultDepNoValue || options
                                                }
                                                return multiTermFuzzySearchObjects(options, inputValue.split(' '))
                                            }}
                                            options={deptList}
                                            getOptionLabel={(option) => `${option.deptNo} ${option.deptName}`}/>
                                    </Field>
                                    {/* 代理人員代號/姓名 */}
                                    <Field label={'代理人員代號/姓名'} labelWidth={150}>
                                        <Search
                                            freeSolo
                                            value={getDefaultAgentUserValue}
                                            onChange={(_e, value) => {
                                                const newValue = value?.agentUserNo ? `${value?.agentUserNo} ${value?.agentUserName}` : ''
                                                handleAgentUserNoChange(newValue)
                                            }}
                                            filterOptions={(options, {inputValue}) => {
                                                if (!inputValue) {
                                                    return getDefaultAgentUserValue || options
                                                }
                                                return multiTermFuzzySearchObjects(options, inputValue.split(' '))
                                            }}
                                            options={agentUserList}
                                            getOptionLabel={(option) => `${option.agentUserNo} ${option.agentUserName}`}/>
                                    </Field>
                                </div>
                            </div>
                            {/* 性質 */}
                            <Field label={'性質'}>
                                <Select
                                    data={{
                                        label: "性質",
                                        options: kindList.map((item) => ({
                                            label: item.codeValue1,
                                            value: item.codeNo
                                        }))
                                    }}
                                    value={supportProperties}
                                    onChange={setSupportProperties}
                                    showLabel={false}
                                />
                            </Field>
                            {/* 執行內容 */}
                            <Field label={'執行內容'}>
                                <TextField
                                    size={SizeEnum.Small}
                                    inputWidth={SizeEnum.Fill}
                                    value={executeContent}
                                    onChange={(e) => setExecuteContent(e.target.value)}
                                />
                            </Field>
                            {/* 備註 */}
                            <Field label={'備註'}>
                                <TextField
                                    size={SizeEnum.Small}
                                    inputWidth={SizeEnum.Fill}
                                    value={memo}
                                    disabled={!isOwnUser}
                                    onChange={(e) => setMemo(e.target.value)}
                                />
                            </Field>
                        </div>
                        {/* 分隔線 */}
                        <Divider/>
                        {/* 支援期間明細 */}
                        <Field label="支援期間明細" sx={{my: 2}}>
                            {/*編輯按鈕*/}
                            <Button
                                color={ButtonColorEnum.Primary} size={ButtonSizeEnum.Medium}
                                disabled={!supportUserNo}
                                text={t('general.edit')} variant={ButtonVariantEnum.Outlined}
                                onClick={handleCheckSupportNo}/>
                        </Field>
                        <CustomTable
                            width="1653px"
                            advancedSearch={true}
                            fields={fields}
                            dataList={dataList}
                            ListItem={ListItem}
                            type={"RSISAddModalTable"}
                            paginationProps={paginationProps}/>
                    </Card>
                    {/* 刪除彈窗 */}
                    {
                        showDeletePopup && (
                            <DeletePopup currentKey={focusItem}
                                         handleDeletePopupClose={() => setShowDeletePopup(false)}
                                         handleNotificationDelete={deleteRow}/>
                        )
                    }
                    {/* 報備支援-支援日期時間選擇 */}
                    {
                        showSupportDateChoice && (
                            <SupportDateChoice
                                userName={supportUserName}
                                data={dataList}
                                supportUserNo={supportUserNo}
                                handleUpdateDataList={handleUpdateDataList}
                                handleOnClose={() => setShowSupportDateChoice(false)}/>
                        )
                    }
                    {/* 報備支援-支援機構查詢*/}
                    {
                        showOrgDropdown && (
                            <QuerySupportOrg
                                filterOrg={true}
                                orgList={orgList}
                                handleOnClose={() => setShowOrgDropdown(false)}
                                handleOnUpdate={handleSupportOrgOptionOnClick}/>
                        )
                    }
                    {/* 員工/醫師代碼查詢 */}
                    {
                        showDoctorPopup && (
                            <QueryPersonList
                                filterEmp={true}
                                doctorList={doctorList}
                                handleOnClose={() => setShowDoctorPopup(false)}
                                handleOnUpdate={handleDoctorOptionOnClick}/>
                        )
                    }
                </div>
            }
            variant={DialogVariant.EDIT}
            paperStyleProps={{width: '1653px'}}
            muiDialogContentProps={{dividers: true}}
            muiDialogActionsProps={{sx: {display: 'none'}}}
            onClose={handleOnClose}/>
    )
}

export default AddModal
