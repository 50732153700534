import { useSelector } from 'react-redux'
import A4Print from './A4Print'
import { time2String } from 'edah_utils/dist'
import { DATE_FORMAT } from '../../constants/common'

/**
 * 權限設定 - 列印內容
 * @returns {JSX.Element}
 */
const PrintPermissionListContent = () => {
    const printData = useSelector((state) => state.print.printData)
    return (
        <>
            <h1 className="text-xl font-bold text-center">權限設定</h1>
            <table className="w-full border-collapse text-sm border-t border-[#111111]/15">
                <thead>
                    <tr className="h-[40px] text-[#18181b]">
                        <th className="w-[100px] text-left break-words p-1">
                            院所代碼
                        </th>
                        <th className="w-[100px] text-left break-words p-1">
                            院所名稱
                        </th>
                        <th className="w-[100px] text-left break-words p-1">
                            申請日期
                        </th>
                        <th className="w-[100px] text-left break-words p-1">
                            帳號
                        </th>
                        <th className="w-[100px] text-left break-words p-1">
                            密碼
                        </th>
                        <th className="w-[100px] text-left break-words p-1">
                            聯絡人
                        </th>
                        <th className="w-[100px] text-left break-words p-1">
                            聯絡人信箱
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {printData.map((item, index) => {
                        return (
                            <tr
                                key={index}
                                className="h-[50px] text-[#18181b] "
                            >
                                <td className="text-left break-words p-1">
                                    {item.hospNo}
                                </td>
                                <td className="text-left break-words p-1">
                                    {item.hospName}
                                </td>
                                <td className="text-left break-words p-1">
                                    {time2String(
                                        item.applicantDate,
                                        DATE_FORMAT
                                    )}
                                </td>
                                <td className="text-left break-words p-1">
                                    {item.hospAccount}
                                </td>
                                <td className="text-left break-words p-1">
                                    {item.hospPasswd}
                                </td>
                                <td className="text-left break-words p-1">
                                    {item.applicantName}
                                </td>
                                <td className="text-left break-words p-1">
                                    {item.applicantEmail}
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </>
    )
}

/**
 * 權限設定 - 列印 
 * @returns {JSX.Element}
 */
const PrintPermissionList = () => {
    return (
        <A4Print>
            <PrintPermissionListContent />
        </A4Print>
    )
}
export default PrintPermissionList
