import React from 'react'
import {PopupProvider} from '../../components/RSIS/PopupProvider'
import ImportResource from '../../components/RSIS/ImportResource/ImportResource'

/**
 * 整批匯入作業
 * @returns {JSX.Element}
 */
const ImportResourcesLayout = () =>
    <PopupProvider>
        <ImportResource/>
    </PopupProvider>
export default ImportResourcesLayout
