import React from 'react';

// const pieData = [
//     { label: '自然搜尋', value: 51, color: '#7FB3A7' },
//     { label: '社交媒體', value: 5, color: '#4E4C67' },
//     { label: '付費搜尋', value: 10, color: '#DC7669' },
//     { label: '所有其他', value: 34, color: '#E2BC80' },
// ];

const PieChart = ({ data, width, height }) => {
    const total = data.reduce((acc, item) => acc + item.value, 0);
    let cumulativeValue = 0;

    const getCoordinatesForPercent = (percent) => {
        const x = Math.cos(2 * Math.PI * percent);
        const y = Math.sin(2 * Math.PI * percent);
        return [x, y];
    };

    return (
        <div className="flex flex-col items-center">
            <svg viewBox="-1 -1 2 2" className={`w-[${width}] h-[${height}]`}>
                {data.map((slice, index) => {
                    const [startX, startY] = getCoordinatesForPercent(cumulativeValue / total);
                    cumulativeValue += slice.value;
                    const [endX, endY] = getCoordinatesForPercent(cumulativeValue / total);
                    const largeArcFlag = slice.value / total > 0.5 ? 1 : 0;
                    const pathData = [
                        `M ${startX} ${startY}`, // Move
                        `A 1 1 0 ${largeArcFlag} 1 ${endX} ${endY}`, // Arc
                        `L 0 0`, // Line
                    ].join(' ');

                    return (
                        <path
                            key={index}
                            d={pathData}
                            fill={slice.color}
                            stroke="#fff"
                            strokeWidth="0.01"
                        />
                    );
                })}
            </svg>
            <ul className="flex space-x-4 mt-4">
                {data.map((slice, index) => (
                    <li key={index} className="flex items-center space-x-2">
                        <span className="block w-4 h-4" style={{ backgroundColor: slice.color }}></span>
                        <span>{slice.label}</span>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default PieChart;
