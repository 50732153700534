import {v1Api} from "../Http"
import {HttpMethodEnum} from "edah_utils/dist"

/**
 * 查詢 Table 名稱
 * @param params {Object}
 * @return {AxiosPromise}
 */
const curdQueryTableBySearchField = (params) => v1Api.request({
    url: '/base/funcrud/queryTableBySearchField',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 查詢 Column 清單
 * @param params {Object}
 * @return {AxiosPromise}
 */
const curdQueryColumnByTable = (params) => v1Api.request({
    url: '/base/funcrud/queryColumnByTable',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 名稱 CRUD 設定儲存
 * @param data {Object}
 * @return {AxiosPromise}
 */
const curdFunctionSave = (data) => v1Api.request({
    url: '/base/funcrud/save',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 依程式查詢 CRUD 設定
 * @param params {Object}
 * @return {AxiosPromise}
 */
const curdQueryByFunNo = (params) => v1Api.request({
    url: '/base/funcrud/queryByFunNo',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 依程式查詢 ValueList
 * @param params {Object}
 * @return {AxiosPromise}
 */
const curdQueryValueListByFunNo = (params) => v1Api.request({
    url: '/base/funcrud/queryValueListByFunNo',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 查詢 table 中符合條件的資料
 * @param params {Object}
 * @return {AxiosPromise}
 */
const curdQuery = (params) => v1Api.request({
    url: '/install/crud/query',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 查詢 table 中符合條件的資料 (all equal)
 * @param params {Object}
 * @return {AxiosPromise}
 */
const curdEqualQuery = (params) => v1Api.request({
    url: '/install/crud/equalQuery',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 查詢 table 中符合條件的資料 (all equal)
 * @param params {Object}
 * @return {AxiosPromise}
 */
const curdQueryStructure = (params) => v1Api.request({
    url: '/install/crud/queryStructure',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 新增或儲存 table 資料
 * @param data {Object}
 * @return {AxiosPromise}
 */
const curdSave = (data) => v1Api.request({
    url: '/install/crud/save',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 刪除 table 資料
 * @param data {Object}
 * @return {AxiosPromise}
 */
const curdDelete = (data) => v1Api.request({
    url: '/install/crud/delete',
    method: HttpMethodEnum.DELETE,
    data
})

/**
 * 查詢資料(like, equal)可排序
 * @param params {Object}
 * @return {AxiosPromise}
 */
const curdMultiQuery = (params) => v1Api.request({
    url: '/install/crud/multiQuery',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 儲存table 資料(新增修改、刪除)
 * @param params {Object}
 * @return {AxiosPromise}
 */
const curdSaveAll = (params) => v1Api.request({
    url: '/install/crud/saveAll',
    method: HttpMethodEnum.POST,
    params
})

export {
    curdQueryTableBySearchField,
    curdQueryColumnByTable,
    curdFunctionSave,
    curdQueryByFunNo,
    curdQueryValueListByFunNo,
    curdQuery,
    curdEqualQuery,
    curdQueryStructure,
    curdSave,
    curdDelete,
    curdMultiQuery,
    curdSaveAll
}
