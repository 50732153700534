import React, {useEffect, useState} from 'react'
import CustomTable from '../RSISCustomTable'
import {RSISChangeRecordQueryTable} from '../Constant'
import {ApiErrorStatusEnum, time2String} from "edah_utils/dist"
import {usePopup} from '../PopupProvider'
import {Dialog, DialogVariant, DialogSizeEnums, AlertTypeEnum} from "edah-component/dist";
import {t} from 'i18next'
// import API
import {rsisQueryCodeValue, rsisQueryMasterHistory, rsisQueryOrganization} from '../../../api/v1/RSIS'

/**
 * 變更欄位模板
 * 數據結構為:欄位|新資料|舊資料
 * @param content {String} 值
 * @param purposeMap {Map<String, String>}
 * @param organizationList {Array<Object>}
 * @return {JSX.Element}
 */
const PurposeTemplate = ({content, purposeMap, organizationList}) => {
    /**
     * 種類列舉
     */
    const TypeEnums = Object.freeze({
        /** 支援目的 */
        purposeCode: 0,
        /** 支援科別 */
        supportDivNo: 1,
        /** 支援機構 */
        supportOrgPkey: 2,
        /** 狀態 */
        stepStatus: 3,
        /** 變更註銷狀態 */
        supportModifyStatus: 4,
        /** 變更註銷時間 */
        supportModifyDatetime: 5,
        /** 變更註銷原因 */
        supportModifyReasonCode: 6,
        /** 變更註銷說明 */
        supportModifyReasonDoc: 7,
        /** 支援期間 */
        supportDateRange: 8,
        /** 支援目的 */
        purposeCodeExt: 9,
    })

    //切割後陣列
    const splitArray = content.split('|')
    //取得種類
    const type = TypeEnums[splitArray[0]]

    /**
     * 取得標題
     * @return {String}
     */
    const getTitle = () => {
        switch (type) {
            // 支援目的
            case TypeEnums.purposeCode:
                return '支援目的'
            // 支援科別
            case TypeEnums.supportDivNo:
                return '支援科別'
            // 支援機構
            case TypeEnums.supportOrgPkey:
                return '支援機構'
            // 狀態
            case TypeEnums.stepStatus:
                return '狀態'
            // 變更註銷狀態
            case TypeEnums.supportModifyStatus:
                return '變更註銷狀態'
            // 變更時間
            case TypeEnums.supportModifyDatetime:
                return '變更時間'
            // 變更原因
            case TypeEnums.supportModifyReasonCode:
                return '變更原因'
            // 變更註銷說明
            case TypeEnums.supportModifyReasonDoc:
                return '變更註銷原因說明'
            // 支援期間
            case TypeEnums.supportDateRange:
                return '支援期間'
            // 支援目的
            case TypeEnums.purposeCodeExt:
                return '(次)支援目的'
            default :
                return ''
        }
    }

    /**
     * 取得新資料數據
     * @param data {String} 數據
     * @return {String}
     */
    const getNewData = (data) => {
        //結果
        let result = ''
        //數據為空或null
        if (!data || data === 'null') {
            return '空白'
        }

        //新資料數據
        const newData = data

        switch (type) {
            //支援目的
            case TypeEnums.purposeCode:
                result = newData.split(',').map(str => `${str} ${purposeMap.get(str)}`).join(', ')
                break
            // 支援科別
            case TypeEnums.supportDivNo:
                result = newData
                break
            // 支援機構
            case TypeEnums.supportOrgPkey:
                const org = organizationList.find(o => o.orgPkey === newData)
                result = org ? org.orgName : '空白'
                break
            // 狀態
            case TypeEnums.stepStatus:
                result = t(`RSIS.StepStatusEnum.${newData}`)
                break
            // 變更註銷狀態
            case TypeEnums.supportModifyStatus:
                result = t(`RSIS.SupportModifyStatusEnum.${newData}`)
                break
            // 變更註銷時間
            case TypeEnums.supportModifyDatetime:
                result = time2String(newData, 'YYYY-MM-DD HH:mm:ss')
                break
            // 變更註銷原因
            case TypeEnums.supportModifyReasonCode:
                result = t(`RSIS.changeReason.${newData}`)
                break
            // 變更註銷說明
            case TypeEnums.supportModifyReasonDoc:
                result = newData
                break
            // 支援期間
            case TypeEnums.supportDateRange:
                result = newData
                break
            // 支援目的
            case TypeEnums.purposeCodeExt:
                const num = parseInt(data.trim())
                result = data ? ((num >= 1 && num <= 3) ? t(`RSIS.subPurposeOptionsEnum.${num}`) : '') : ''
                break
        }

        return result
    }

    return (<span className='flex flex-col gap-1 mb-4' key="purposeChange">
            <p className='w-full pl-4 font-bold'>{getTitle()}</p>
            <p className="w-full pl-4">(原): {getNewData(splitArray[2])}</p>
            <p className="w-full pl-4">(新): {getNewData(splitArray[1])}</p>
    </span>)
}

/**
 * @param item {Object} 數據
 * @param organizationList {Array<Object>} 機構清單
 * @param codeValueList {Array<Object>} 基礎代碼清單
 * @return {JSX.Element}
 */
const ListItem = ({item, codeValueList, organizationList}) => {
    //支援目的代碼Map
    const purposeMap = codeValueList ?
        // 有數據的話
        new Map(codeValueList.filter(code => code.codeType === "PURPOSE")
            // 重組結構
            .map(code => [
                // 代號
                code.codeNo,
                // 數值
                code.codeValue1
            ])) :
        // 資料不存在時
        new Map()

    /**
     * 解析 changeFieldsDoc
     * @return {JSX.Element | Array<JSX.Element>}
     */
    const parserChangeFieldsDoc = () => {
        //當值存在時
        if (item.changeFieldsDoc) {
            //切割後的陣列
            const splitArray = item.changeFieldsDoc.indexOf(', ') > 0 ?
                //如果有相關符號則取得切割後結果
                item.changeFieldsDoc.split(', ') :
                //沒有則代表只有修改一筆的情況
                [item.changeFieldsDoc]

            // 將切割後的數據轉換成對象
            const dataObject = splitArray.reduce((acc, entry) => {
                const [key, ...values] = entry.split('|')
                acc[key] = values
                return acc
            }, {})

            // 檢查 itemStartDate 和 itemEndDate 是否存在並合併它們
            const supportDateRange = dataObject.itemStartDate && dataObject.itemEndDate ?
                `supportDateRange|${(time2String(dataObject.itemStartDate[0], 'YYYY-MM-DD'))} - ${time2String(dataObject.itemEndDate[0], 'YYYY-MM-DD')}|${time2String(dataObject.itemStartDate[1], 'YYYY-MM-DD')} - ${time2String(dataObject.itemEndDate[1], 'YYYY-MM-DD')}` : ''

            // 更新 splitArray，移除原有的 itemStartDate 和 itemEndDate 並加入 supportDateRange
            const updatedSplitArray = splitArray.filter(entry => !entry.startsWith('itemStartDate') && !entry.startsWith('itemEndDate'))
            if (supportDateRange) {
                updatedSplitArray.push(supportDateRange)
            }

            return updatedSplitArray.map((item, index) =>
                <PurposeTemplate key={index} content={item} purposeMap={purposeMap}
                                 organizationList={organizationList}/>
            )
        }

        return <></>
    }

    /**
     * 取得支援目的
     * @return {JSX.Element | Array<JSX.Element>}
     */
    const getPurposeCode = () => {
        //資料存在時
        if (item.purposeCode) {
            //拆分array
            const splitArray = item.purposeCode.split(',')

            //回傳結果
            return splitArray.map((item, index) =>
                <p key={index} className='flex justify-start pl-4 h-fit'>
                    {`${item} ${purposeMap.get(`${item}`)}`}
                </p>
            )
        }

        return <></>
    }

    /**
     * 格式化日期時間
     * @param date {String} 日期
     * @param time {String} 時間
     * @return {String}
     */
    const formatDateTime = (date, time) => {
        //當日期或時間不存在時回傳空
        if (!date || !time) {
            return ''
        }
        //取得日期
        const formattedDate = date.split(' ')[0]
        //取得時間
        const formattedTime = time.slice(0, 2) + ':' + time.slice(2)
        //回傳結果
        return `${formattedDate} ${formattedTime}`
    }

    /**
     * 取得內容
     * @return {JSX.Element}
     */
    const getContent = () =>
        <li>
            <div>{item.itemVersion}</div>
            <div className={'flex flex-col justify-start'}>
                <p className={'w-full'}>{item.supportOrgNo}</p>
                <p className={'w-full'}>{item.supportOrgName}</p>
            </div>
            <div className={'flex flex-col justify-start'}>
                <p className={'w-full'}>{item.supportDivNo}</p>
                <p className={'w-full'}>{item.supportDivName}</p>
            </div>
            {/* 支援目的 */}
            <div>{getPurposeCode()}</div>
            <div className={'flex flex-col justify-start items-center'}>
                <p className={'w-full'}>{formatDateTime(item.itemStartDate, item.itemStartTime)}</p>
                <p className={'w-full'}>{formatDateTime(item.itemEndDate, item.itemEndTime)}</p>
            </div>
            {/* 變更欄位 */}
            <div>{parserChangeFieldsDoc()}</div>
        </li>

    return getContent()
}

/**
 * 承辦人員專屬功能-變更紀錄查詢
 * @param supportNo {String}
 * @param purposeList {Array<Object>}
 * @param handleOnClose {function}
 * @return {JSX.Element}
 * @constructor
 */
const QueryChangeRecord = ({supportNo, purposeList, handleOnClose}) => {
    // 使用上下文
    const {showToast} = usePopup()
    //基礎代碼清單
    const [codeValueList, setCodeValueList] = useState([])
    //機構清單
    const [organizationList, setOrganizationList] = useState([])
    const [dataList, setDataList] = useState()
    const List = ListItem
    const [paginationProps, setPaginationProps] = useState({
        pageNum: 1,
        pageSize: 10,
        totalItemSize: 0,
        totalPageSize: 0,
        currentPageItemSize: 0,
        fn: () => {
        }
    })

    /**
     * 變更紀錄、版次查詢
     * @return {void}
     */
    const getChangeRecord = () => {
        rsisQueryMasterHistory({
            supportNo: supportNo
        }).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                const sortedData = data.sort((a, b) => {
                    return b.itemVersion - a.itemVersion
                })
                setDataList(sortedData)
            } else {
                setDataList([])
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 取得機構清單
     * @return {void}
     */
    const getQueryOrganization = () => {
        rsisQueryOrganization({}).then(res => {
            //狀態/資料/訊息
            const {err, data, msg} = res
            //取得成功
            if (err === ApiErrorStatusEnum.Success) {
                // 設定機構清單
                setOrganizationList(data)
            } else {
                // 清空機構清單
                setOrganizationList([])
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 取得基礎代碼
     * @return {void}
     */
    const getQueryCodeValue = () => {
        rsisQueryCodeValue({effFlag: 'ALL'}).then(res => {
            //狀態/資料/訊息
            const {err, data, msg} = res
            //取得成功
            if (err === ApiErrorStatusEnum.Success) {
                // 設定基礎代碼
                setCodeValueList(data)
            } else {
                // 清空基礎代碼
                setCodeValueList([])
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 第一次執行時，取得資料
     * @return {void}
     */
    useEffect(() => {
        getChangeRecord()
        getQueryCodeValue()
        getQueryOrganization()
    }, [])

    return (
        <Dialog
            open={true}
            title={t('RSIS.Popup.QueryChangeRecord')}
            content={
                <div className='px-6 pb-6'>
                    <CustomTable
                        fields={RSISChangeRecordQueryTable.fields}
                        dataList={dataList}
                        advancedSearch={true}
                        ListItem={(props) => <List {...props} codeValueList={codeValueList}
                                                   organizationList={organizationList}/>}
                        hasSelectAll={false}
                        type="RSISChangeRecordQueryTable"
                        paginationProps={paginationProps}
                        extraDropDownListData={purposeList}/>
                </div>}
            variant={DialogVariant.CONFIRM}
            paperStyleProps={{width: DialogSizeEnums.LG}}
            onClose={handleOnClose}
            muiDialogActionsProps={{sx: {display: 'none'}}}
            muiDialogContentProps={{dividers: true}}
        />
    )
}

export default QueryChangeRecord
