import React, {useEffect, useState} from "react"
import {BasePopup} from "../Popup/BasePopup"
import {DefaultButton} from "../Button/DefaultButton";

/**
 * 退掛確認彈窗
 * @param show 是否顯示
 * @param closePopupButtonOnClick 關閉按鈕點擊事件
 * @returns
 */
const PdfJsonPopup = ({
   show = false,
   closePopupButtonOnClick
}) => {

    /**
     * 是否顯示彈窗
     */
    const [showPopup, setShowPopup] = useState(show)

    /**
     * 確定刪除點擊事件
     */
    const handleConfirmOnClick = () => {
        closePopupButtonOnClick()
    }

    /**
     * 取消刪除點擊事件
     */
    const handleCancelOnClick = () => {
        closePopupButtonOnClick()
    }

    /**
     * 取得內容
     * @return {JSX.Element}
     */
    const getContent = () => (
        <>
            如為PDF則顯示PDF
            如為JSON格式以表格方式呈現
        </>
    )
    /**
     * 監聽是否顯示彈窗Pros變化
     */
    useEffect(() => {

        setShowPopup(show)
    }, [show])
    return showPopup ?
        <BasePopup
            title={"PDF內容"}
            content={getContent()}
            width={"560px"}
            closePopupButtonOnClick={closePopupButtonOnClick}/> :
        <></>
}

export default PdfJsonPopup;