/**
 * 使用者角色
 */
export const USER_ROLE = {
    // 承辦人員
    DEPT_USER:'deptUser',
    // 申請人員
    CREATE_USER:'createUser',
    // 支援人員
    SUPPORT_USER:'supportUser',
    // 其他人員
    OTHER_USER:'otherUser'
}

