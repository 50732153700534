import React, {useState} from "react";
import {
    Button,
    ButtonSizeEnum,
    ButtonColorEnum,
    ButtonVariantEnum,
    IconEnum,
    Select,
    TextField,
    Field,
    DatePicker
} from "edah-component/dist";
import {t} from "i18next";
import dayjs from "dayjs";

/**
 * 技能評估
 * @return {JSX.Element}
 */
const SkillsAssessment = () => {
    // 頁面按鈕樣式
    const SecondButtonStyle = "flex items-center justify-center px-4 h-10 rounded-[6px] border-2 bg-[#FAFAFA]"

    // 評估結果選項
    const evaluationOptions = [
        {value: "0", label: "良好"},
        {value: "1", label: "尚可"},
        {value: "2", label: "惡劣"},
        {value: "3", label: "不適用"}
    ];

    // 知識/技能選項
    const knowledgeOptions = [
        {value: "0", label: "純熟"},
        {value: "1", label: "尚可"},
        {value: "2", label: "生疏"},
        {value: "3", label: "完全不會"}
    ];

    // 執行狀況選項
    const executionOptions = [
        {value: "1", label: "確實執行"},
        {value: "2", label: "部分執行"},
        {value: "3", label: "偶爾執行"},
        {value: "4", label: "完全不執行"},
        {value: "5", label: "執行部分錯誤"},
        {value: "6", label: "執行嚴重錯誤"}
    ];

    // 表單區塊及項目
    const sections = [
        {
            title: "身體評估",
            children: [
                {
                    title: "身體擦澡",
                    id: "bodyBathing"
                },
                {
                    title: "口腔清潔",
                    id: "oralHygiene"
                },
                {
                    title: "會陰/尿道沖洗",
                    id: "perineumUrethraWash"
                },
                {
                    title: "洗頭",
                    id: "hairWashing"
                },
                {
                    title: "手足清潔及修剪指甲",
                    id: "limbHygiene"
                },
            ]
        },
        {
            title: "呼吸道",
            children: [
                {
                    title: "協助痰液排出",
                    id: "phlegmAssistance"
                },
                {
                    title: "氣切套管護理",
                    id: "tracheotomyCare"
                },
                {
                    title: "氣切造瘻口護理",
                    id: "tracheotomyStomaCare"
                },
                {
                    title: "氧氣使用",
                    id: "oxygenUse"
                },
                {
                    title: "蒸氣機使用",
                    id: "steamMachineUse"
                }
            ]
        },
        {
            title: "進食",
            children: [
                {
                    title: "飲食調理與供應",
                    id: "dietPreparation"
                },
                {
                    title: "餵食/管灌",
                    id: "feeding"
                },
                {
                    title: "鼻胃管護理",
                    id: "nasogastricTubeCare"
                },
                {
                    title: "水分供給足夠",
                    id: "sufficientHydration"
                },
            ]
        },
        {
            title: "排泄",
            children: [
                {
                    title: "存留導尿管護理",
                    id: "urinaryCatheterCare"
                },
                {
                    title: "尿布檢視及更換",
                    id: "diaperCheck"
                },
                {
                    title: "維持排便",
                    id: "bowelMaintenance"
                },
            ]
        },
        {
            title: "身體活動",
            children: [
                {
                    title: "給予被動關節運動",
                    id: "passiveJointExercise"
                },
                {
                    title: "督促個案主動運動",
                    id: "activeExerciseSupervision"
                },
                {
                    title: "協助改變姿勢",
                    id: "postureChangeAssistance"
                },
                {
                    title: "協助下床活動",
                    id: "gettingOutOfBedAssistance"
                },
            ]
        },
        {
            title: "皮膚護理",
            children: [
                {
                    title: "皮膚檢視及照護",
                    id: "skinInspection"
                },
                {
                    title: "傷口護理",
                    id: "woundCare"
                },
                {
                    title: "胃造口/腸造口/膀胱造口護理",
                    id: "ostomyCare"
                },
            ]
        },
        {
            title: "社交",
            children: [{
                title: "休閒娛樂安排與協助參與",
                id: "leisureActivities"
            },
                {
                    title: "避免社交隔離(忽略)",
                    id: "socialIsolationPrevention"
                },
                {
                    title: "合理約束",
                    id: "reasonableRestraint"
                }]
        }
    ];

    // 表單狀態
    const [formData, setFormData] = useState(
        sections.reduce((acc, section) => {
            section.children.forEach((child) => {
                acc[child.id] = {evaluation: "", knowledge: "", execution: ""};
            });
            return acc;
        }, {})
    );

    /**
     * 更新表單值
     * @param sectionId {String} 表單區域ID
     * @param field {String} 表單欄位名稱
     * @param value {String} 表單值
     */
    const handleChange = (sectionId, field, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [sectionId]: {
                ...prevData[sectionId],
                [field]: value
            }
        }));
    };

    return (
        <div
            className="w-full h-[calc(100vh-165px)] overflow-y-auto p-4 bg-white rounded-[6px] border border-[#111111]/15">
            <h3 className="flex items-center justify-start text-[20px] mr-4 mb-4 pl-2 bg-white sticky top-0 text-[#2B6CB0] border-l-8 border-[#2B6CB0]">
                <span>技能評估</span>
            </h3>
            <div className="text-[#71717A] text-[14px] w-fit">評估日期</div>
            {/* 新增 */}
            <div className="flex items-center ">
                <Button color={ButtonColorEnum.Success} variant={ButtonVariantEnum.Text}
                        size={ButtonSizeEnum.Large} text={t('general.add')} icon={IconEnum.Add}
                />
                <div className="w-[1px] h-[20px] bg-[#D4D4D8]"/>
                <div className="flex justify-center gap-2 ml-2">
                    <Button color={ButtonColorEnum.Secondary} variant={ButtonVariantEnum.Outlined}
                            size={ButtonSizeEnum.Medium} text={'上頁'}
                    />
                    <Button color={ButtonColorEnum.Secondary} variant={ButtonVariantEnum.Outlined}
                            size={ButtonSizeEnum.Medium} text={'2024/06/28'}
                    />
                    <Button color={ButtonColorEnum.Secondary} variant={ButtonVariantEnum.Outlined}
                            size={ButtonSizeEnum.Medium} text={'下頁'}
                    />
                </div>
            </div>
            <hr className="my-3"/>
            <div className="flex justify-between items-center">
                {/*功能操作*/}
                <Button sx={{display: 'flex', justifyContent: 'flex-start'}}
                        color={ButtonColorEnum.Primary} variant={ButtonVariantEnum.Text}
                        size={ButtonSizeEnum.Large} text={t('general.delete')} icon={IconEnum.Delete}
                />
                <div className="flex gap-2 flex-shrink-0">
                    <Button color={ButtonColorEnum.Secondary} variant={ButtonVariantEnum.Outlined}
                            size={ButtonSizeEnum.Medium} text={'Ditto'}
                    />
                    <Button color={ButtonColorEnum.Secondary} variant={ButtonVariantEnum.Outlined}
                            size={ButtonSizeEnum.Medium} text={t('general.print')}
                    />
                    <Button color={ButtonColorEnum.Primary} variant={ButtonVariantEnum.Contained}
                            size={ButtonSizeEnum.Medium} text={t('general.saveFile')}
                    />
                </div>
            </div>
            <div className="flex flex-row items-center justify-between py-3">
                {/*評估日期輸入框*/}
                <Field label={"評估日期"}>
                    <DatePicker
                        value={formData.evaluationDate ? dayjs(formData.evaluationDate) : null}
                        label=""
                        onChange={(date) => setFormData({...formData, evaluationDate: date.toDate()})}/>
                </Field>
                {/*評估人員輸入框*/}
                <Field label={"評估人員"}>
                    <TextField
                        value={formData.evaluator}
                        onChange={(e) => setFormData({...formData, evaluator: e.target.value})}/>
                </Field>
            </div>

            {/*表單*/}
            <form className="w-full bg-white rounded-[6px]">
                <div className="flex flex-row items-center justify-start">
                    <div
                        className="w-[300px] h-[300px] flex items-center justify-start px-4 border border-[#111111]/15">分項/評估項目
                    </div>
                    <div className="w-[300px] h-[300px] border-r border-b border-[#111111]/15">
                        <p className="flex items-center justify-center text-[18px] h-[48px] border-y border-[#111111]/15">個案結果</p>
                        <div className="text-left text-[16px] pl-2">
                            0.良好 <br/>
                            1.尚可 <br/>
                            2.惡劣 <br/>
                            3.不適用
                        </div>
                    </div>
                    <div className="w-[300px] h-[300px] text-[18px] border-r border-b border-[#111111]/15">
                        <p className="flex items-center justify-center text-[18px] h-[48px] border-y border-[#111111]/15">知識/技能</p>
                        <div className="text-left text-[16px] pl-2">
                            0.純熟 <br/>
                            1.尚可 <br/>
                            2.生疏 <br/>
                            3.完全不會
                        </div>
                    </div>
                    <div className="w-[300px] h-[300px] text-[18px] border-r border-b border-[#111111]/15">
                        <p className="flex items-center justify-center text-[18px] h-[48px] border-y border-[#111111]/15">執行狀況</p>
                        <div className="text-left text-[16px] pl-2">
                            1.確實執行<br/>
                            2.部分執行<br/>
                            3.偶爾執行<br/>
                            4.完全不執行<br/>
                            5.執行部分錯誤<br/>
                            6.執行嚴重錯誤
                        </div>
                    </div>
                </div>
                {sections.map((section) => (
                    <div key={section.title} className="flex flex-row border-l border-[#111111]/15">
                        <h2 className="w-[39px] tracking-wide flex items-center justify-center border-b border-[#111111]/15"
                            style={{writingMode: 'vertical-lr'}}>{section.title}</h2>
                        <div className="flex flex-col border-l border-[#111111]/15">
                            {section.children.map((child) => (
                                <div key={child.id} className="flex flex-row items-center">
                                    <p className="w-[259px] h-[52px] flex items-center justify-start px-2 border-b border-r border-[#111111]/15">{child.title}</p>
                                    <div className="flex flex-row text-left">
                                        <label
                                            className="w-[300px] h-[52px] flex items-center justify-center border-b border-r border-[#111111]/15">
                                            <Select
                                                data={{
                                                    label: "個案結果",
                                                    options: evaluationOptions.map((option) => ({
                                                        value: option.value,
                                                        label: option.label
                                                    }))
                                                }}
                                                value={formData[child.id].evaluation}
                                                showLabel={false}
                                                onChange={(newValue) => handleChange(child.id, "evaluation", newValue)}/>
                                        </label>
                                        <label
                                            className="w-[300px] h-[52px] flex items-center justify-center border-b border-r border-[#111111]/15">
                                            <Select
                                                data={{
                                                    label: "知識/技能",
                                                    options: knowledgeOptions.map((option) => ({
                                                        value: option.value,
                                                        label: option.label
                                                    }))
                                                }}
                                                value={formData[child.id].knowledge}
                                                showLabel={false}
                                                onChange={(newValue) => handleChange(child.id, "knowledge", newValue)}/>
                                        </label>
                                        <label
                                            className="w-[300px] h-[52px] flex items-center justify-center border-b border-r border-[#111111]/15">
                                            <Select
                                                data={{
                                                    label: "執行狀況",
                                                    options: executionOptions.map((option) => ({
                                                        value: option.value,
                                                        label: option.label
                                                    }))
                                                }}
                                                value={formData[child.id].execution}
                                                showLabel={false}
                                                onChange={(newValue) => handleChange(child.id, "execution", newValue)}/>
                                        </label>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </form>
        </div>
    );
};

export default SkillsAssessment;
