import { useEffect } from 'react';

/**
 * 自訂一Hook，用於處理點擊指定元素外部的事件
 * @param {React.RefObject<HTMLElement>} params.ref - The reference to the element.
 * @param {() => void} params.handler - The function to call when a click outside is detected.
 */
const useOutsideClick = ({ ref, handler }) => {
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                handler();
            }
        };

        //添加mousedown事件監聽
        document.addEventListener('mousedown', handleClickOutside);

        //組件卸載時移除事件監聽
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, handler]);
};

export default useOutsideClick;