//Setting i18n
import i18n from "i18next"
import {initReactI18next} from "react-i18next"
import zh_TW from "./zh-TW.json"
import {LangEnum} from "edah_utils/dist"

i18n.use(initReactI18next)
    .init({
        resources: {
            zh: {
                translation: zh_TW
            }
        },
        lng: LangEnum.Taiwan,
        fallbackLng: LangEnum.Taiwan,
        interpolation: {
            //避免React遭受XSS攻擊
            escapeValue: false
        },
    });
export default i18n
