import React, {useState} from 'react'
import CustomTable from '../../components/ReferralSystem/CustomTable'
import {INP_FIELDS, OPD_ER_FIELDS,} from '../../constants/referralSystem/addReferralForm'
import {Pagination} from '../../components/Pagination/Pagination'
import ReferralTicketModal from '../../components/ReferralSystem/ReferralTicketModal/ReferralTicketModal'
import {ApiErrorStatusEnum, objectIsEmpty, time2String, validDateFormat,} from 'edah_utils/dist'
import useToast from '../../hooks/useToast'
import {getPartNoByEncounterList, queryNotTranBackReasonByDay, queryTestData,} from '../../api/v1/Rmis'
import ReturnAppointmentModal from './ReturnAppointmentModal'
import {DATETIME_FORMAT} from '../../constants/common'
import NoReferralReasonModal from '../../components/ReferralSystem/NoReferralReasonModal'
import {
    AlertTypeEnum,
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    SizeEnum,
    DateRangePicker,
    TextField
} from "edah-component/dist";
import dayjs from "dayjs"
import {t} from 'i18next'

/**
 * 區分勾選資料是門急還是住院資料
 * opdEr: 門/急 資料
 * inp: 住院 資料
 */
const DATA_TYPE = {
    opdEr: 'opdEr',
    inp: 'inp',
}

/**
 * 轉出轉入開立作業
 * @returns {JSX.Element}
 */
const AddReferralForm = () => {
    /**
     * 是否顯示轉診開單作業
     */
    const [isShowReferralTicketModal, setIsShowReferralTicketModal] =
        useState(false)

    /**
     * 查詢條件
     */
    const [searchParams, setSearchParams] = useState({
        search: '', //病歷號/身分證號
        encounterStartDate: '', //看診/住院開始日期 
        encounterEndDate: '', //看診/住院結束日期
        patientName: '', //姓名
    })

    /**
     * 搜尋輸入框的檢核狀態
     * true: 通過檢核
     * false: 未通過檢核
     */
    const [inputState, setInputState] = useState({
        encounterStartDateState: true, //看診/住院開始日期 狀態
        encounterEndDateState: true, //看診/住院結束日期 狀態
    })

    /**
     * 門/急表格
     */
    const [opdErList, setOpdErList] = useState([])

    /**
     * 住院表格
     */
    const [inpList, setInpList] = useState([])

    /**
     * 看診資料
     */
    const [selectedVisitInfo, setSelectedVisitInfo] = useState({})

    /**
     * 開啟轉診回診清單視窗
     */
    const [isShowReturnAppointmentModal, setIsShowReturnAppointmentModal] =
        useState(false)

    const [isShowNoReferralReasonModal, setIsShowNoReferralReasonModal] =
        useState(false)
    /**
     * 門/急table的pagination
     */
    const [opdErFilter, setOpdErFilter] = useState({
        pageNum: 1, // 目前頁碼
        pageSize: 10, // 每頁筆數
        totalPageSize: 0, // 總頁數
        totalItemSize: 0, // 總筆數
    })

    /**
     * 住院table的pagination
     */
    const [inpFilter, setInpFilter] = useState({
        pageNum: 1, // 目前頁碼
        pageSize: 10, // 每頁筆數
        totalPageSize: 0, // 總頁數
        totalItemSize: 0, // 總筆數
    })

    /**
     * 顯示 toast
     */
    const showToast = useToast()

    /**
     * 更新查詢條件
     * @param event {Event} 事件
     * @param field {string} 欄位
     * @return {void}
     */
    const updateSearchParams = (e, field) => {
        setSearchParams((prev) => ({
            ...prev,
            [field]: e.target.value,
        }))
    }

    /**
     * 看診/住院日期範圍變動事件
     * @param newDates {Array<Dayjs | null>} 日期範圍 [開始日期, 結束日期]
     * @return {void}
     */
    const handleDateRangeChange = (newDates) => {
        const [newStartDate, newEndDate] = newDates;
        setSearchParams({
            ...searchParams,
            encounterStartDate: newStartDate ? newStartDate.format('YYYY-MM-DD') : '',
            encounterEndDate: newEndDate ? newEndDate.format('YYYY-MM-DD') : '',
        })
    }

    /**
     * 查詢 (測試資料)
     * @return {void}
     */
    const handleQuerOnClick = () => {
        // 清空選擇的資料
        setSelectedVisitInfo(null)
        // 檢核看診/住院日期格式
        const encounterStartDateIsValid = validDateFormat(
            searchParams.encounterStartDate
        )
        const encounterEndDateIsValid = validDateFormat(
            searchParams.encounterEndDate
        )
        // 更新檢核狀態
        setInputState({
            encounterStartDateState: encounterStartDateIsValid,
            encounterEndDateState: encounterEndDateIsValid,
        })
        // 檢核看診/住院日期格式
        if (!encounterStartDateIsValid || !encounterEndDateIsValid) {
            return
        }
        // 取得看診/住院資料
        getInpOpdList({
            inpPageNum: 1,
            inpPageSize: inpFilter.pageSize,
            opdPageNum: 1,
            opdPageSize: opdErFilter.pageSize,
        })
    }

    /**
     * 取得門急/住院資料
     * @param inpPageNum {number} 住院目前頁碼
     * @param inpPageSize {number} 住院每頁筆數
     * @param opdPageNum {number} 門急目前頁碼
     * @param opdPageSize {number} 門急每頁筆數
     * @return {void}
     */
    const getInpOpdList = ({
                               inpPageNum,
                               inpPageSize,
                               opdPageNum,
                               opdPageSize,
                           }) => {
        // 取得門急/住院資料
        queryTestData({
            // 看診/住院開始日期格式轉為YYYY-MM-DD 00:00:00
            encounterStartDate: time2String(
                searchParams.encounterStartDate,
                DATETIME_FORMAT
            ),
            // 看診/住院結束日期格式轉為YYYY-MM-DD 00:00:00
            encounterEndDate: time2String(
                searchParams.encounterEndDate,
                DATETIME_FORMAT
            ),
            // 病歷號/身分證號
            search: searchParams.search,
            // 姓名
            patientName: searchParams.patientName,
            // 住院目前頁碼
            inpPageNum,
            // 住院每頁筆數
            inpPageSize,
            // 門急目前頁碼
            opdPageNum,
            // 門急每頁筆數
            opdPageSize,
        }).then((res) => {
            // 取得門急/住院資料成功
            if (res.err === ApiErrorStatusEnum.Success) {
                // data:門急/住院資料
                const {data} = res
                // 門急清單
                const opdList = data?.opd.dataList.map((item) => ({
                    ...item,
                    isSelected: false, // 是否勾選
                }))
                // 住院清單
                const inpList = data?.inp.dataList.map((item) => ({
                    ...item,
                    isSelected: false, // 是否勾選
                }))
                // 更新門急清單
                setOpdErList(opdList)
                // 更新住院清單
                setInpList(inpList)
                // 更新住院過濾條件
                setInpFilter({
                    ...inpFilter,
                    pageNum: inpPageNum,
                    pageSize: inpPageSize,
                    totalPageSize: data?.inp.totalPageSize,
                    totalItemSize: data?.inp.totalItemSize,
                })
                // 更新門急過濾條件
                setOpdErFilter({
                    ...opdErFilter,
                    pageNum: opdPageNum,
                    pageSize: opdPageSize,
                    totalPageSize: data?.opd.totalPageSize,
                    totalItemSize: data?.opd.totalItemSize,
                })
            } else {
                // 取得門急/住院資料失敗
                showToast({message: res.msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 開啟轉診開單作業視窗
     * @return {void}
     */
    const showAddReferralFormModal = () => {
        // 判斷是否有選擇資料
        if (selectedVisitInfo?.patient) {
            // 開啟轉診開單作業視窗
            setIsShowReferralTicketModal(true)
        }
    }

    /**
     * 轉診回診清單
     */
    const handleReturnAppointment = () => {
        // 判斷是否有選擇資料
        if (selectedVisitInfo?.patient) {
            // 開啟轉診回診清單視窗
            setIsShowReturnAppointmentModal(true)
        }
    }

    /**
     * 查詢45天內未輸入不轉診原因之轉診單
     * api回傳有資料不轉診原因視窗
     * @return {void}
     */
    const handleNoReferralReason = () => {
        // 判斷是否有選擇資料
        if (selectedVisitInfo?.patient) {
            // 查詢45天內未輸入不轉診原因之轉診單
            queryNotTranBackReasonByDay({
                // 病歷號
                patientId: selectedVisitInfo.patient.patientId,
                // 看診/住院日期
                encounterDate: selectedVisitInfo.patient.encounterDate,
                // 科別
                divNo: selectedVisitInfo.patient.divNo,
            }).then((res) => {
                // 查詢45天內未輸入不轉診原因之轉診單成功
                if (res.err === ApiErrorStatusEnum.Success) {
                    // 判斷是否有資料
                    if (!objectIsEmpty(res.data)) {
                        // 開啟不轉診原因視窗
                        setIsShowNoReferralReasonModal(true)
                    }
                } else {
                    // 查詢45天內未輸入不轉診原因之轉診單失敗
                    showToast({message: res.msg, type: AlertTypeEnum.Error})
                }
            })
        }
    }

    /**
     * 不轉診原因視窗點選確定
     */
    const handleShowReferralTicket = () => {
        // 開啟轉診開單作業視窗
        setIsShowReferralTicketModal(true)
    }

    /**
     * 選擇單筆門/急資料
     * @param {boolean} isChecked 是否勾選
     * @param {object} selectedItem 勾選的資料
     * @param {number} index 勾選項目的index
     * @return {void}
     */
    const toggleOpdErItem = (isChecked, selectedItem, index) => {
        // 未勾選
        if (!isChecked) {
            // 清空選擇的資料
            setSelectedVisitInfo(null)
        } else {
            // 勾選單筆門/急資料
            setSelectedVisitInfo({
                patient: selectedItem,
                type: DATA_TYPE.opdEr,
            })
        }
        // 只能選擇單筆
        if (selectedVisitInfo && selectedVisitInfo.type === DATA_TYPE.inp && isChecked) {
            // 將已勾選的住院資料取消勾選
            setInpList((prev) =>
                prev.map((item) => ({
                    ...item,
                    isSelected: false,
                }))
            )
        }
        // 將勾選的門/急資料更新為勾選狀態，其他資料更新為未勾選狀態
        const modifiedList = opdErList.map((item) => {
            return {
                ...item,
                isSelected:
                    selectedItem.encounterId === item.encounterId
                        ? isChecked
                        : false,
            }
        })
        // 更新門急清單
        setOpdErList(modifiedList)
    }

    /**
     * 選擇單筆住院資料
     * @param isChecked {boolean} 是否勾選
     * @param selectedItem {object}  勾選的資料
     * @return {void}
     */
    const toggleInpItem = (isChecked, selectedItem) => {
        // 未勾選
        if (!isChecked) {
            // 清空選擇的資料
            setSelectedVisitInfo(null)
        } else {
            // 勾選單筆住院資料
            setSelectedVisitInfo({
                patient: selectedItem,
                type: DATA_TYPE.inp,
            })
        }
        // 判斷是否已有勾選的門/急資料
        if (
            selectedVisitInfo &&
            selectedVisitInfo.type === DATA_TYPE.opdEr &&
            isChecked
        ) {
            // 將已勾選的門/急資料取消勾選
            setOpdErList((prev) =>
                prev.map((item) => ({
                    ...item,
                    isSelected: false,
                }))
            )
        }
        // 將勾選的住院資料更新為勾選狀態，其他資料更新為未勾選狀態
        const modifiedList = inpList.map((item) => {
            return {
                ...item,
                isSelected:
                    selectedItem.encounterId === item.encounterId
                        ? isChecked
                        : false,
            }
        })
        // 更新住院清單
        setInpList(modifiedList)
    }

    /**
     * 點選 pagination 頁碼
     * @param page {string}  目前頁碼
     * @param type {string}  資料類型
     * @return {void}
     */
    const onPageOnChange = (page, type) => {
        const currentPage = Number(page)
        // 判斷是門/急還是住院
        const filter =
            type === DATA_TYPE.opdEr
                ? {
                    inpPageNum: inpFilter.pageNum,
                    inpPageSize: inpFilter.pageSize,
                    opdPageNum: currentPage,
                    opdPageSize: opdErFilter.pageSize,
                }
                : {
                    inpPageNum: currentPage,
                    inpPageSize: inpFilter.pageSize,
                    opdPageNum: opdErFilter.pageNum,
                    opdPageSize: opdErFilter.pageSize,
                }
        // 取得門急/住院資料
        getInpOpdList(filter)
    }

    /**
     * 變更每頁筆數
     * @param event {object}
     * @return {void}
     */
    const onPageSizeChange = (event, type) => {
        const pageSize = Number(event.target.value)
        // 判斷是門/急還是住院
        const filter =
            type === DATA_TYPE.opdEr
                ? {
                    inpPageNum: inpFilter.pageNum,
                    inpPageSize: inpFilter.pageSize,
                    opdPageNum: 1,
                    opdPageSize: pageSize,
                }
                : {
                    inpPageNum: 1,
                    inpPageSize: pageSize,
                    opdPageNum: opdErFilter.pageNum,
                    opdPageSize: opdErFilter.pageSize,
                }
        // 取得門急/住院資料
        getInpOpdList(filter)
    }

    /**
     * 點選上一頁
     * @param type {string}  門急/住院
     * @return {void}
     */
    const onPrevPageOnClick = (type) => {
        let filter = {}
        if (type === DATA_TYPE.opdEr) {
            const prevPage = opdErFilter.pageNum - 1
            const page = prevPage < 1 ? opdErFilter.totalPageSize : prevPage
            filter = {
                inpPageNum: inpFilter.pageNum,
                inpPageSize: inpFilter.pageSize,
                opdPageNum: page,
                opdPageSize: opdErFilter.pageSize,
            }
        } else {
            const prevPage = inpFilter.pageNum - 1
            const page = prevPage < 1 ? inpFilter.totalPageSize : prevPage
            filter = {
                inpPageNum: page,
                inpPageSize: inpFilter.pageSize,
                opdPageNum: opdErFilter.pageNum,
                opdPageSize: opdErFilter.pageSize,
            }
        }
        getInpOpdList(filter)
    }

    /**
     * 點選下一頁
     * @param type {string}  門急/住院
     * @return {void}
     */
    const onNextPageOnClick = (type) => {
        let filter = {}
        // 判斷是門/急還是住院
        if (type === DATA_TYPE.opdEr) {
            // 取得門急下一頁頁碼
            const nextPage = opdErFilter.pageNum + 1
            // 取得門急第一頁頁碼
            const firstPage = 1
            // 取得門急下一頁頁碼，如果超過總頁數，則回到第一頁
            const page =
                nextPage > opdErFilter.totalPageSize ? firstPage : nextPage
            filter = {
                inpPageNum: inpFilter.pageNum,
                inpPageSize: inpFilter.pageSize,
                opdPageNum: page,
                opdPageSize: opdErFilter.pageSize,
            }
        } else {
            // 取得住院下一頁頁碼
            const nextPage = inpFilter.pageNum + 1
            // 取得住院第一頁頁碼
            const firstPage = 1
            // 取得住院下一頁頁碼，如果超過總頁數，則回到第一頁
            const page =
                nextPage > inpFilter.totalPageSize ? firstPage : nextPage
            filter = {
                inpPageNum: page,
                inpPageSize: inpFilter.pageSize,
                opdPageNum: opdErFilter.pageNum,
                opdPageSize: opdErFilter.pageSize,
            }
        }
        // 取得門急/住院資料
        getInpOpdList(filter)
    }

    /**
     * 轉診回診清單視窗[確定]按鈕
     * @param data {Array<object>}  轉診回診清單
     * @return {void}
     */
    const handleReturnAppointmentOnConfirm = (data) => {
        const info = data[0]
        if (info) {
            // 轉診單使用紀錄判斷
            getPartNoByEncounterList({
                // 就醫號
                tranEncounterId: info.tranEncounterId,
                // 回診就醫序號
                encounterId: info.encounterId,
                // 就醫日期
                encounterDate: info.encounterDate,
                // 門急/住院
                inpOpd: info.inpOpd,
            }).then((res) => {
                // 轉診單使用紀錄判斷成功
                if (res.err === ApiErrorStatusEnum.Success) {
                    // 關閉轉診回診清單視窗
                    setIsShowReturnAppointmentModal(false)
                } else {
                    // 轉診單使用紀錄判斷失敗
                    showToast({message: res.msg, type: AlertTypeEnum.Error})
                }
            })
        }
    }

    return (
        <div className="p-2 w-full h-[calc(100vh-98px)] overflow-auto">
            <div className="flex justify-between items-center">
                <div className="flex items-center justify-start space-x-2 p-2">
                    {/* 病歷號/身分證號 */}
                    <TextField
                        label={'病歷號/身分證號'}
                        value={searchParams.search}
                        onChange={(e) => updateSearchParams(e, 'search')}
                    />
                    {/* 姓名 */}
                    <TextField
                        label={t('general.username')}
                        value={searchParams.patientName}
                        onChange={(e) => updateSearchParams(e, 'patientName')}
                    />
                    {/* 看診/住院日期起訖 */}
                    <DateRangePicker
                        required
                        localeText={{
                            start: '看診/住院開始日期',
                            end: '看診/住院結束日期',
                        }}
                        value={[
                            searchParams.encounterStartDate ? dayjs(searchParams.encounterStartDate) : null,
                            searchParams.encounterEndDate ? dayjs(searchParams.encounterEndDate) : null,
                        ]}
                        onChange={handleDateRangeChange}
                        error={!inputState.encounterStartDateState || !inputState.encounterEndDateState}
                    />
                    {/* 查詢按鈕 */}
                    <Button
                        color={ButtonColorEnum.Primary}
                        text={t('general.query')}
                        variant={ButtonVariantEnum.Contained}
                        size={ButtonSizeEnum.Medium}
                        onClick={handleQuerOnClick}
                        sx={{fontWeight: 'bold', height: '2.5rem'}}
                    />
                </div>
                <div className="flex items-end space-x-2">
                    {/* 不轉診原因 */}
                    <Button
                        sx={{fontWeight: 'bold', height: '2.5rem'}}
                        color={ButtonColorEnum.Primary}
                        text='不轉診原因'
                        variant={ButtonVariantEnum.Contained}
                        size={ButtonSizeEnum.Medium}
                        onClick={handleNoReferralReason}
                    />
                    {/* 轉診回診清單 */}
                    <Button
                        sx={{fontWeight: 'bold', height: '2.5rem'}}
                        color={ButtonColorEnum.Primary}
                        text='轉診回診清單'
                        variant={ButtonVariantEnum.Contained}
                        size={ButtonSizeEnum.Medium}
                        onClick={handleReturnAppointment}
                    />
                    {/* 轉診開單作業 */}
                    <Button
                        sx={{fontWeight: 'bold', height: '2.5rem'}}
                        color={ButtonColorEnum.Secondary}
                        text='轉診開單作業'
                        variant={ButtonVariantEnum.Outlined}
                        size={ButtonSizeEnum.Medium}
                        onClick={showAddReferralFormModal}
                    />
                </div>
            </div>
            <div className="flex flex-wrap mt-2">
                <div className="w-[50%] pr-1 min-h-[600px]">
                    {/* 門/急 table */}
                    <CustomTable
                        title="門/急"
                        fields={OPD_ER_FIELDS}
                        dataList={opdErList}
                        toggleItem={toggleOpdErItem}
                        isSingleSelect
                        isSelectable
                    />
                    <div className="flex justify-end">
                        <Pagination
                            pageSize={opdErFilter.pageSize}
                            totalSize={opdErFilter.totalItemSize}
                            currentPage={opdErFilter.pageNum}
                            totalPageSize={opdErFilter.totalPageSize}
                            onPageOnChange={(page) =>
                                onPageOnChange(page, 'opdEr')
                            }
                            onPageSizeChange={(page) =>
                                onPageSizeChange(page, 'opdEr')
                            }
                            onPrevPageOnClick={() => onPrevPageOnClick(DATA_TYPE.opdEr)}
                            onNextPageOnClick={() => onNextPageOnClick(DATA_TYPE.opdEr)}
                        />
                    </div>
                </div>
                <div className="w-[50%] pl-1 min-h-[600px]">
                    {/* 住院 table */}
                    <CustomTable
                        title="住院"
                        fields={INP_FIELDS}
                        dataList={inpList}
                        toggleItem={toggleInpItem}
                        isSingleSelect
                        isSelectable
                    />
                    <div className="flex justify-end">
                        <Pagination
                            pageSize={inpFilter.pageSize}
                            totalSize={inpFilter.totalItemSize}
                            currentPage={inpFilter.pageNum}
                            totalPageSize={inpFilter.totalPageSize}
                            onPageOnChange={(page) =>
                                onPageOnChange(page, DATA_TYPE.inp)
                            }
                            onPageSizeChange={(page) =>
                                onPageSizeChange(page, DATA_TYPE.inp)
                            }
                            onPrevPageOnClick={() => onPrevPageOnClick(DATA_TYPE.inp)}
                            onNextPageOnClick={() => onNextPageOnClick(DATA_TYPE.inp)}
                        />
                    </div>
                </div>
            </div>
            {/* 轉診開單作業視窗 */}
            {isShowReferralTicketModal && (
                <ReferralTicketModal
                    selectedVisitInfo={selectedVisitInfo}
                    onClose={() => setIsShowReferralTicketModal(false)}
                />
            )}
            {/* 轉診回診清單視窗 */}
            {isShowReturnAppointmentModal && (
                <ReturnAppointmentModal
                    patientInfo={selectedVisitInfo.patient}
                    onConfirm={handleReturnAppointmentOnConfirm}
                    onClose={() => setIsShowReturnAppointmentModal(false)}
                />
            )}
            {/* 不轉診原因視窗 */}
            {isShowNoReferralReasonModal && (
                <NoReferralReasonModal
                    patientInfo={selectedVisitInfo.patient}
                    onClose={() => setIsShowNoReferralReasonModal(false)}
                    goReferralTicket={handleShowReferralTicket}
                />
            )}
        </div>
    )
}

export default AddReferralForm
