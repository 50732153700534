import { useSelector } from 'react-redux'
import './print.scss'

/**
 * 權限設定- 標籤列印
 * @returns {JSX.Element}
 */
const PrintPermissionLabel = () => {
    const printData = useSelector((state) => state.print.printData)
    return (
        <>
            {printData.map((item) => {
                return (
                    <div className="label-page p-1">
                        <div className="border-b border-[#111111]">
                            <div>平信</div>
                            <div>{item.hospName}</div>
                            <div className='text-right'>轉診中心 收</div>
                        </div>
                        <div>
                            <div>{item.hospZipCode}</div>
                            <div>{item.hospAddress}</div>
                        </div>
                    </div>
                )
            })}
        </>
    )
}

export default PrintPermissionLabel
