import React, {useEffect, useState} from "react";
import {BaseInput} from "../../Input/BaseInput";
import {InputTypeEnum} from "edah_utils/dist";
import {
    Button,
    ButtonSizeEnum,
    ButtonColorEnum,
    ButtonVariantEnum,
    IconEnum,
    Select,
    SelectWidthEnum,
    Field,
    TextField,
    DatePicker,
    Divider, SizeEnum, Checkbox
} from "edah-component/dist";
import {t} from "i18next";
import dayjs from "dayjs";

/**
 * 主要照顧者評估
 * @returns {JSX.Element}
 * @constructor
 */
const CareGiver = () => {
    const [formData, setFormData] = useState({
        caregiverStatus: '',
        name: '',
        relationship: '',
        age: '',
        gender: '',
        employmentStatus: '',
        careDays: '',
        careHoursPerDay: '',
        careDaysPerMonth: '',
        caregiverHealth: '',
        caregiverPsychological: '',
        familyInteraction: '',
        totalCareScore: '',
        caregiverEvaluation: '',
        otherComments: '',
    });

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    /**
     * 處理Select 變更
     * @param value
     * return {void}
     */
    const handleSelectChange = (name, value) => {
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Process form submission
        console.log(formData);
    };

    const caregiverHealthOptions = [
        '主要照顧者的健康與過去差不多，沒什麼變化',
        '主要照顧者因照顧個案而感到疲累、身體不適，但不需要看醫生',
        '主要照顧者因照顧個案感到疲累、身體不適，而需要看醫生或接受治療，但仍可繼續照顧',
        '主要照顧者因照顧個案而太過疲累而需要住院，或無法繼續照顧，必須換人',
    ];

    const caregiverPsychologicalOptions = [
        '主要照顧者的心理狀況與過去一樣，沒什麼變化',
        '主要照顧者的情緒偶爾會焦慮、擔心、憂鬱，但不至於影響生活作息',
        '主要照顧者會受到個案影響而情緒變化大甚至需要服用鎮定劑、安眠藥',
        '主要照顧者會因個案影響心理狀況產生精神症狀需要常看醫師或住院',
    ];

    const familyInteractionOptions = [
        '家人的互動關係由於個案的相關問題而更能相互關心或沒什麼改變',
        '家人的關係會因為個案的相關問題而起小衝突，但尚能解決問題，維持和諧關係',
        '家人之間會因個案的相關問題而常發生衝突，有些衝突不易解決，但尚不致嚴重破壞家庭的和諧',
        '家人因個案的相關問題常發生嚴重衝突無法解決而嚴重破壞關係，或常處於緊張狀態',
    ];

    const caregiverEvaluationOptions = [
        '無照護負荷問題',
        '照護品質不佳',
        '家庭照護負荷過重',
        '家屬無照護意願',
    ];

    // 計算總分
    const calculateTotalScore = () => {
        const getScore = (value, options) => {
            const index = options.indexOf(value);
            return index !== -1 ? index + 1 : 0;
        };

        const healthScore = getScore(formData.caregiverHealth, caregiverHealthOptions);
        const psychologicalScore = getScore(formData.caregiverPsychological, caregiverPsychologicalOptions);
        const interactionScore = getScore(formData.familyInteraction, familyInteractionOptions);

        return healthScore + psychologicalScore + interactionScore;
    };

    // 更新總分
    useEffect(() => {
        setFormData((prevData) => ({
            ...prevData,
            totalCareScore: calculateTotalScore()
        }));
    }, [formData.caregiverHealth, formData.caregiverPsychological, formData.familyInteraction]);


    const labelStyle = () => {
        return 'flex flex-row items-center justify-start mr-4'
    }

    return (
        <div
            className="w-full h-[calc(100vh-165px)] overflow-y-auto p-4 bg-white rounded-[6px] border border-[#111111]/15">
            <h3 className="flex items-center justify-start text-[20px] mr-4 mb-4 pl-2 bg-white sticky top-0 text-[#2B6CB0] border-l-8 border-[#2B6CB0]">
                <span>主要照顧者評估</span>
            </h3>
            <div className="text-[#71717A] text-[14px] w-fit">評估日期</div>
            {/* 新增 */}
            <div className="flex items-center ">
                <Button color={ButtonColorEnum.Success} variant={ButtonVariantEnum.Text}
                        size={ButtonSizeEnum.Large} text={t('general.add')} icon={IconEnum.Add}
                />
                <div className="w-[1px] h-[20px] bg-[#D4D4D8]"/>
                <div className="flex justify-center gap-2 ml-2">
                    <Button color={ButtonColorEnum.Secondary} variant={ButtonVariantEnum.Outlined}
                            size={ButtonSizeEnum.Medium} text={'上頁'}
                    />
                    <Button color={ButtonColorEnum.Secondary} variant={ButtonVariantEnum.Outlined}
                            size={ButtonSizeEnum.Medium} text={'2024/06/28'}
                    />
                    <Button color={ButtonColorEnum.Secondary} variant={ButtonVariantEnum.Outlined}
                            size={ButtonSizeEnum.Medium} text={'下頁'}
                    />
                </div>
            </div>
            <hr className="my-3"/>
            <div className="flex justify-between items-center">
                {/*功能操作*/}
                <Button sx={{display: 'flex', justifyContent: 'flex-start'}}
                        color={ButtonColorEnum.Primary} variant={ButtonVariantEnum.Text}
                        size={ButtonSizeEnum.Large} text={t('general.delete')} icon={IconEnum.Delete}
                />
                <div className="flex gap-2 flex-shrink-0">
                    <Button color={ButtonColorEnum.Secondary} variant={ButtonVariantEnum.Outlined}
                            size={ButtonSizeEnum.Medium} text={'Ditto'}
                    />
                    <Button color={ButtonColorEnum.Secondary} variant={ButtonVariantEnum.Outlined}
                            size={ButtonSizeEnum.Medium} text={t('general.print')}
                    />
                    <Button color={ButtonColorEnum.Primary} variant={ButtonVariantEnum.Contained}
                            size={ButtonSizeEnum.Medium} text={t('general.saveFile')}
                    />
                </div>
            </div>
            <div className="flex flex-row items-center justify-between py-3">
                {/*評估日期輸入框*/}
                <Field label={"評估日期"}>
                    <DatePicker
                        value={formData.evaluationDate ? dayjs(formData.evaluationDate) : null}
                        label=""
                        onChange={(date) => setFormData({...formData, evaluationDate: date.toDate()})}/>
                </Field>
                {/*評估人員輸入框*/}
                <Field label={"評估人員"}>
                    <TextField
                        value={formData.evaluator}
                        onChange={(e) => setFormData({...formData, evaluator: e.target.value})}/>
                </Field>
            </div>
            <form className="py-2 px-4 border border-[#1111111]/15" onSubmit={handleSubmit}>
                {/*主要照顧者狀況*/}
                <div
                    className="flex flex-row items-center justify-start h-[42px] box-content py-4 w-full">
                    <label className={labelStyle()}>
                        <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}
                               type="radio"
                               name="caregiverStatus"
                               value="無主要照顧者"
                               checked={formData.caregiverStatus === '無主要照顧者'}
                               onChange={handleChange}
                        />
                        無主要照顧者
                    </label>
                    <label className={labelStyle()}>
                        <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}
                               type="radio"
                               name="caregiverStatus"
                               value="有主要照顧者"
                               checked={formData.caregiverStatus === '有主要照顧者'}
                               onChange={handleChange}
                        />
                        有主要照顧者
                    </label>
                    <label className={labelStyle()}>
                        <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}
                               type="radio"
                               name="caregiverStatus"
                               value="有主要照顧者，不與個案同住"
                               checked={formData.caregiverStatus === '有主要照顧者，不與個案同住'}
                               onChange={handleChange}
                        />
                        有主要照顧者，不與個案同住
                    </label>
                </div>
                <Divider/>
                <div className="py-4 w-full space-y-2">
                    <div className="flex items-center justify-start box-content w-full">
                        <Field label="姓名">
                            <TextField size={SizeEnum.Small} inputWidth={SizeEnum.Small}
                                       name="name"
                                       value={formData.name}
                                       onChange={handleChange}
                            />
                        </Field>
                        <Field label="與個案關係">
                            <Select
                                data={{
                                    label: "與個案關係",
                                    options: [{value: "", label: ""}]
                                }}
                                value={formData.relationship}
                                onChange={(newValue) => handleSelectChange("relationship", newValue)}
                                showLabel={false}
                                width={SelectWidthEnum.Small}
                            />
                        </Field>
                        <Field label="年齡">
                            <TextField size={SizeEnum.Small} inputWidth={SizeEnum.Small}
                                       name="age"
                                       value={formData.age}
                                       onChange={handleChange}
                            />
                        </Field>
                        <Field childrenSx={{display: 'flex', alignItems: "center"}} label="性別">
                            <label className={labelStyle()}>
                                <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                       style={{accentColor: "#2B6CB0"}}
                                       type="radio"
                                       name="gender"
                                       value="男"
                                       checked={formData.gender === '男'}
                                       onChange={handleChange}
                                />
                                男
                            </label>
                            <label className={labelStyle()}>
                                <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                       style={{accentColor: "#2B6CB0"}}
                                       type="radio"
                                       name="gender"
                                       value="女"
                                       checked={formData.gender === '女'}
                                       onChange={handleChange}
                                />
                                女
                            </label>
                        </Field>
                    </div>
                    <Field childrenSx={{display: 'flex', alignItems: 'center'}} label="目前就業狀況">
                        <label className={labelStyle()}>
                            <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                   style={{accentColor: "#2B6CB0"}}
                                   type="radio"
                                   name="employmentStatus"
                                   value="無"
                                   checked={formData.employmentStatus === '無'}
                                   onChange={handleChange}
                            />
                            無
                        </label>
                        <label className={labelStyle()}>
                            <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                   style={{accentColor: "#2B6CB0"}}
                                   type="radio"
                                   name="employmentStatus"
                                   value="全職"
                                   checked={formData.employmentStatus === '全職'}
                                   onChange={handleChange}
                            />
                            全職
                        </label>
                        <label className={labelStyle()}>
                            <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                   style={{accentColor: "#2B6CB0"}}
                                   type="radio"
                                   name="employmentStatus"
                                   value="兼職"
                                   checked={formData.employmentStatus === '兼職'}
                                   onChange={handleChange}
                            />
                            兼職(部分時間工作)
                        </label>
                    </Field>
                    <Field childrenSx={{display: 'flex', alignItems: 'center'}} label="每天照顧時間">
                        <label className={labelStyle()}>
                            <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                   style={{accentColor: "#2B6CB0"}}
                                   type="radio"
                                   name="careHoursPerDay"
                                   value="全天"
                                   checked={formData.careDays === '全天'}
                                   onChange={handleChange}
                            />
                            全天
                        </label>
                        <label className={labelStyle()}>
                            <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                   style={{accentColor: "#2B6CB0"}}
                                   type="radio"
                                   name="careHoursPerDay"
                                   value="部分時間"
                                   checked={formData.careDays === '部分時間'}
                                   onChange={handleChange}
                            />
                            部分時間
                        </label>
                        <label className={labelStyle()}>
                            每日平均
                            <TextField size={SizeEnum.Small} inputWidth={SizeEnum.Small}
                                       name="careDaysPerMonth"
                                       value={formData.careHoursPerDay}
                                       onChange={handleChange}
                            />
                            小時
                        </label>
                    </Field>
                    <Field childrenSx={{display: 'flex', alignItems: 'center'}} label="照顧個案月數">
                        <TextField size={SizeEnum.Small} inputWidth={SizeEnum.Small}
                                   name="careDaysPerMonth"
                                   value={formData.careDaysPerMonth}
                                   onChange={handleChange}/>
                        月
                    </Field>
                </div>
                <Divider/>
                <div className="py-4 px-4 w-full">
                    <div className="flex flex-row items-center justify-start h-[42px] box-content w-full">
                        <p className="mr-4 pr-4 text-right">主要照顧者負荷：依照實際情形點選適合選項</p>
                    </div>
                    <div className="flex flex-row items-center justify-start h-[42px] box-content w-full">
                        <p className="mr-4 pr-4 text-right">主要照顧者身體健康：</p>
                    </div>
                    {caregiverHealthOptions.map((option, index) => (
                        <label className={`${labelStyle()} h-[42px]`} key={index}>
                            <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                   style={{accentColor: "#2B6CB0"}}
                                   type="radio"
                                   name="caregiverHealth"
                                   value={option}
                                   checked={formData.caregiverHealth === option}
                                   onChange={handleChange}
                            />
                            {option}
                        </label>
                    ))}
                </div>
                <Divider/>
                <div className="py-4 px-4 w-full">
                    <div className="flex flex-row items-center justify-start h-[42px] box-content w-full">
                        <p className="mr-4 pr-4 text-right">主要照顧者心理狀況</p>
                    </div>
                    {caregiverPsychologicalOptions.map((option, index) => (
                        <label className={`${labelStyle()} h-[42px]`} key={index}>
                            <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                   style={{accentColor: "#2B6CB0"}}
                                   type="radio"
                                   name="caregiverPsychological"
                                   value={option}
                                   checked={formData.caregiverPsychological === option}
                                   onChange={handleChange}
                            />
                            {option}
                        </label>
                    ))}
                </div>
                <Divider/>
                <div className="py-4 px-4 w-full">
                    <div className="flex flex-row items-center justify-start h-[42px] box-content w-full">
                        <p className="mr-4 pr-4 text-right">家庭的互動關係</p>
                    </div>
                    {familyInteractionOptions.map((option, index) => (
                        <label className={`${labelStyle()} h-[42px]`} key={index}>
                            <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                   style={{accentColor: "#2B6CB0"}}
                                   type="radio"
                                   name="familyInteraction"
                                   value={option}
                                   checked={formData.familyInteraction === option}
                                   onChange={handleChange}
                            />
                            {option}
                        </label>
                    ))}
                    <div className="flex flex-row items-center justify-start h-[42px] box-content w-full">
                        <p className="mr-4 text-right">家庭照顧者負荷總計分：</p>
                        <BaseInput
                            className="w-10 h-10 text-[#C53030]"
                            type={InputTypeEnum.Text}
                            name="totalCareScore"
                            value={formData.totalCareScore}
                            readOnly
                        />
                    </div>
                </div>
                <Divider/>
                <div
                    className="flex flex-row items-center justify-start py-4 px-4 w-full">
                    <div className="flex flex-row items-center justify-start h-[42px] box-content">
                        <p className="mr-4 pr-4 text-right">照顧者總評</p>
                    </div>
                    {caregiverEvaluationOptions.map((option, index) => (
                        <Checkbox key={index} label={option}
                                  name="caregiverEvaluation"
                                  value={option}
                                  checked={formData.caregiverEvaluation === option}
                                  onChange={handleChange}
                        />
                    ))}
                    <div className="flex flex-row items-center justify-start h-[42px] box-content">
                        <Checkbox label="其他"
                                  name="caregiverEvaluation"
                                  value="其他"
                                  checked={formData.caregiverEvaluation === "其他"}
                                  onChange={handleChange}/>
                        <TextField size={SizeEnum.Small}
                                   name="otherComments"
                                   value={formData.otherComments}
                                   onChange={handleChange}
                        />
                    </div>
                </div>
                <Divider/>
            </form>
        </div>
    )
}
export default CareGiver
