//Import css
import '../../components/TableList/tableList.scss'
import '../../components/ICCard/maintain.scss'

//Import function
import React, {useState} from "react"
import {t} from "i18next"
import {
    ApiErrorStatusEnum,
    arrayIsEmpty,
    enum2Array,
    fuzzySearchObjects,
    ICCMDataCorrectFlagEnum,
    ICCMInpOpdEnum,
    ICCMMaintainPageActiveTabEnum,
    ICCMMaintainPageFunEnum,
    ICCMOperationModeEnum,
    ICCMUploadStatusEnum,
    InputTypeEnum, stringIsEmpty,
    validDateFormat
} from "edah_utils/dist"
import {ActiveTab} from "../../components/ICCard/MaintainPage/ActiveTab"
import {iccmPaticQueryPatic} from "../../api/v1/ICCM";
import useToast from "../../hooks/useToast"
import {BaseInput} from "../../components/Input/BaseInput"
import {
    DateRangePicker,
    Button,
    ButtonSizeEnum,
    ButtonVariantEnum,
    ButtonColorEnum,
    Select,
    SelectWidthEnum,
    TextField, AlertTypeEnum, Checkbox, Card
} from "edah-component/dist";
import dayjs from "dayjs";

/**
 * IC卡寫卡資料維護
 * @return {JSX.Element}
 */
const MaintainPage = () => {
    /**
     * Toast
     */
    const showToast = useToast()

    // 進階搜尋文字
    const [searchText, setSearchText] = useState('')
    // 選取的功能
    const [funEnum, setFunEnum] = useState(ICCMMaintainPageFunEnum.uploadA1)
    // 資料
    const [data, setData] = useState([])
    //就醫起始日期
    const [encounterDateStartDate, setEncounterDateStartDate] = useState('')
    //就醫結束日期
    const [encounterDateEndDate, setEncounterDateEndDate] = useState('')
    // 門/住
    const [inpOpd, setInpOpd] = useState(ICCMInpOpdEnum.outpatient)
    // 上傳狀態
    const [icUploadStatus, setIcUploadStatus] = useState(ICCMUploadStatusEnum.uploaded)
    // 資料是否正確
    const [icDataCorrectFlag, setIcDataCorrectFlag] = useState(ICCMDataCorrectFlagEnum.YES)
    // 身分證號
    const [idNo, setIdNo] = useState('')
    // 病歷號起號
    const [patientIdStart, setPatientIdStart] = useState('')
    // 病歷號訖號
    const [patientIdEnd, setPatientIdEnd] = useState('')
    // 是否全選
    const [selectedAll, setSelectedAll] = useState(false)

    /**
     * 處理上傳(A1)按鈕點擊
     * @return {void}
     */
    const handleUploadA1ButtonOnClick = () => setFunEnum(ICCMMaintainPageFunEnum.uploadA1)

    /**
     * 處理轉成XML按鈕點擊
     * @return {void}
     */
    const handleConvertToXmlButtonOnClick = () => setFunEnum(ICCMMaintainPageFunEnum.convertToXml)

    /**
     * 處理檢核資料正確性按鈕點擊
     * @return {void}
     */
    const handleCheckDataCorrectButtonOnClick = () => setFunEnum(ICCMMaintainPageFunEnum.checkDataCorrect)

    /**
     * 處理刪除按鈕點擊
     * @return {void}
     */
    const handleDeleteButtonOnClick = () => setFunEnum(ICCMMaintainPageFunEnum.delete)

    /**
     * 處理新增退掛(ZB)按鈕點擊
     * @return {void}
     */
    const handleAddReturnZbButtonOnClick = () => setFunEnum(ICCMMaintainPageFunEnum.addReturnZB)

    /**
     * 處理進階搜尋文字變更
     * @param {Event} e
     * @return {void}
     */
    const handleSearchTextOnChange = (e) => setSearchText(e.target.value)


    /**
     * 日期範圍改變時
     * @param dateRange {Array} [startDate, endDate]
     * @return {void}
     */
    const handleDateRangeChange = (dateRange) => {
        const [start, end] = dateRange;
        setEncounterDateStartDate(start ? start.toDate() : null);
        setEncounterDateEndDate(end ? end.toDate() : null);
    };

    /**
     * 處理來源下拉選單變更
     * @param value {String}
     * @return {void}
     */
    const handleInpOpdOnChange = (value) => setInpOpd(value)

    /**
     * 處理上傳狀態下拉選單變更
     * @param value {String}
     * @return {void}
     */
    const handleIcUploadStatusOnChange = (value) => setIcUploadStatus(value)

    /**
     * 處理資料正確下拉選單變更
     * @param value {String}
     * @return {void}
     */
    const handleIcDataCorrectFlagOnChange = (value) => setIcDataCorrectFlag(value)

    /**
     * 處理身分證號變更
     * @param {Event} e
     * @return {void}
     */
    const handleIdNoOnChange = (e) => setIdNo(e.target.value)

    /**
     * 取得過濾資料
     * @return {Array<Object>}
     */
    const getFilterData = () => fuzzySearchObjects(data, searchText)

    /**
     * 點擊查詢按鈕
     * @return {void}
     */
    const handleQueryButtonOnClick = () => getQueryPatic()

    /**
     * 選取欄位變更
     * @param index {number} 索引
     * @return {void}
     */
    const handleSelectionOnChange = (index) => {
        // 複製資料
        const newData = [...data]
        // 設定選取
        newData[index].selected = !newData[index].selected
        // 設定資料
        setData(newData)
    }

    /**
     * 選取全部變更
     * @return {void}
     */
    const handleSelectedAllOnChange = () => {
        // 複製資料
        const newData = [...data]
        // 設定選取
        newData.forEach(item => item.selected = !selectedAll)
        // 設定資料
        setData(newData)
        // 設定是否全選
        setSelectedAll(!selectedAll)
    }

    /**
     * 病歷號起號變更
     * @param event {Event} 事件
     */
    const handlePatientIdStartOnChange = (event) => {
        const value = event.target.value
        setPatientIdStart(value)
    }

    /**
     * 病歷號迄號變更
     * @param event {Event} 事件
     */
    const handlePatientIdEndOnChange = (event) => {
        const value = event.target.value
        setPatientIdEnd(value)
    }

    /**
     * 取得是否全選禁用
     * @return {boolean}
     */
    const getSelectedAllDisabled = () => arrayIsEmpty(data)

    /**
     * 新增選擇欄位
     * @param data {Array<Object>} 資料
     * @return {Array<Object>}
     */
    const addSelectionField = (data) => data.map(item => ({...item, selected: false}))

    /**
     *　取得選取的資料
     * @return {Array<Object>}
     */
    const getSelectedList = () => arrayIsEmpty(data) ? [] : data.filter(item => item.selected)

    /**
     * 取得選取的筆數
     * @return {number}
     */
    const getSelectedCount = () => arrayIsEmpty(data) ? 0 : getSelectedList().length

    /**
     *　取得功能按鈕是否被禁用
     * @return {boolean}
     */
    const getFunButtonDisable = () => getSelectedCount() === 0

    /**
     * 取得IC卡寫卡基本資料讀取
     * @return {void}
     */
    const getQueryPatic = () => {
        if (!validDateFormat(encounterDateStartDate) || !validDateFormat(encounterDateEndDate)) {
            showToast({message: '就醫日期為必填', type: AlertTypeEnum.Error})
            return
        }
        // 至少填入身分證號或病歷號起訖其中一組
        if (stringIsEmpty(idNo) && stringIsEmpty(patientIdStart) && stringIsEmpty(patientIdEnd)) {
            showToast({message: '至少填入身分證號或病歷號起訖其中一組', type: AlertTypeEnum.Error})
            return
        }
        iccmPaticQueryPatic({
            encounterDateStartDate: dayjs(encounterDateStartDate).format('YYYY-MM-DD 00:00:00'),
            encounterDateEndDate: dayjs(encounterDateEndDate).format('YYYY-MM-DD 00:00:00'),
            inpOpd,
            icUploadStatus,
            icDataCorrectFlag,
            idNo,
            patientIdStart,
            patientIdEnd
        }).then(res => {
            // 取得錯誤 / 訊息 / 資料
            const {err, msg, data} = res

            // 取得成功
            if (err === ApiErrorStatusEnum.Success) {
                // 設定資料
                setData(addSelectionField(data))
            } else { // 取得失敗
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    return (
        <div className="w-full p-4 bg-[#FAFAFA]">
            {/* 搜尋列 */}
            <div className="flex items-center justify-start space-x-2 mb-2">
                {/* 就醫日期起訖 */}
                <DateRangePicker
                    localeText={{start: '就醫開始日期', end: '就醫結束日期'}}
                    value={[encounterDateStartDate ? dayjs(encounterDateStartDate) : null, encounterDateEndDate ? dayjs(encounterDateEndDate) : null]}
                    required
                    onChange={handleDateRangeChange}
                />
                {/*來源下拉選單*/}
                <Select
                    width={SelectWidthEnum.Medium}
                    data={{
                        label: '來源',
                        options: enum2Array(ICCMInpOpdEnum).map((item) => ({
                            value: item.value,
                            label: t(`ICCard.InpOpdEnum.${item.value}`)
                        }))
                    }}
                    value={inpOpd}
                    notched={true}
                    onChange={handleInpOpdOnChange}
                />
                {/*上傳狀態下拉選單*/}
                <Select
                    width={SelectWidthEnum.Medium}
                    data={{
                        label: '上傳狀態',
                        options: enum2Array(ICCMUploadStatusEnum).map((item) => ({
                            value: item.value,
                            label: t(`ICCard.UploadStatusEnum.${item.value}`)
                        }))
                    }}
                    value={icUploadStatus}
                    notched={true}
                    onChange={handleIcUploadStatusOnChange}
                />
                {/*資料正確下拉選單*/}
                <Select
                    width={SelectWidthEnum.Medium}
                    data={{
                        label: '資料正確',
                        options: enum2Array(ICCMDataCorrectFlagEnum).map((item) => ({
                            value: item.value,
                            label: t(`ICCard.DataCorrectFlagEnum.${item.value}`)
                        }))
                    }}
                    value={icDataCorrectFlag}
                    notched={true}
                    onChange={handleIcDataCorrectFlagOnChange}
                />
                {/*身分證號*/}
                <TextField label="身分證號" value={idNo}
                           onChange={handleIdNoOnChange}/>
            </div>
            <div className="flex space-x-2 mb-2">
                <div className="flex flex-row items-center justify-start space-x-2">
                    {/*病歷號開始*/}
                    <TextField label="病歷號起號" placeholder="1111201" value={patientIdStart}
                               onChange={handlePatientIdStartOnChange}/>
                    <p>～</p>
                    {/*病歷號結束*/}
                    <TextField label="病歷號迄號" placeholder="1111216" value={patientIdEnd}
                               onChange={handlePatientIdEndOnChange}/>
                </div>
                <div className="flex items-center">
                    {/*查詢按鈕*/}
                    <Button color={ButtonColorEnum.Primary} size={ButtonSizeEnum.Medium}
                            variant={ButtonVariantEnum.Contained} onClick={handleQueryButtonOnClick}
                            text={t('general.query')}/>
                </div>
            </div>
            <div className="maintainContainer flex flex-row items-start justify-start">
                <div className="p-4 bg-white w-[773px] border-[1px] border-[#D4D4D8] rounded-[12px] mr-4">
                    <div className="btnGroup flex flex-row mb-4 gap-2">
                        {/*上傳(A1)按鈕*/}
                        <Button color={ButtonColorEnum.Primary} size={ButtonSizeEnum.Medium}
                                variant={ButtonVariantEnum.Contained} disabled={getFunButtonDisable()}
                                onClick={handleUploadA1ButtonOnClick}
                                text={t(`ICCard.page.maintainPage.fun.${ICCMMaintainPageFunEnum.uploadA1}`)}/>
                        {/*轉成XML*/}
                        <Button color={ButtonColorEnum.Secondary} size={ButtonSizeEnum.Medium}
                                variant={ButtonVariantEnum.Outlined} disabled={getFunButtonDisable()}
                                onClick={handleConvertToXmlButtonOnClick}
                                text={t(`ICCard.page.maintainPage.fun.${ICCMMaintainPageFunEnum.convertToXml}`)}/>
                        {/*檢核資料正確性*/}
                        <Button color={ButtonColorEnum.Secondary} size={ButtonSizeEnum.Medium}
                                variant={ButtonVariantEnum.Outlined} disabled={getFunButtonDisable()}
                                text={t(`ICCard.page.maintainPage.fun.${ICCMMaintainPageFunEnum.checkDataCorrect}`)}
                                onClick={handleCheckDataCorrectButtonOnClick}/>
                        {/*刪除*/}
                        <Button color={ButtonColorEnum.Secondary} size={ButtonSizeEnum.Medium}
                                variant={ButtonVariantEnum.Outlined} disabled={getFunButtonDisable()}
                                text={t(`ICCard.page.maintainPage.fun.${ICCMMaintainPageFunEnum.delete}`)}
                                onClick={handleDeleteButtonOnClick}/>
                        {/*新增退掛(ZB)*/}
                        <Button color={ButtonColorEnum.Secondary} size={ButtonSizeEnum.Medium}
                                variant={ButtonVariantEnum.Outlined} disabled={getFunButtonDisable()}
                                text={t(`ICCard.page.maintainPage.fun.${ICCMMaintainPageFunEnum.addReturnZB}`)}
                                onClick={handleAddReturnZbButtonOnClick}/>
                    </div>
                    {/*進階搜尋*/}
                    <Card>
                        <div className="flex justify-between">
                            <BaseInput className="w-[320px] h-10 pl-4 border-[2px] border-[#D4D4D8] rounded-[6px]"
                                       type={InputTypeEnum.Text} placeholder={t('general.advancedSearch')}
                                       value={searchText} inputMode={InputTypeEnum.Search}
                                       onChange={handleSearchTextOnChange}/>
                            {
                                //已選取筆數
                                getSelectedCount() > 0 && (
                                    <p>{t('general.selectedCount', {selectedCount: getSelectedCount()})}</p>
                                )
                            }
                        </div>
                    </Card>
                    <ul className="tableList icMaintain">
                        <li className="title">
                            <div>
                                <BaseInput type={InputTypeEnum.Checkbox} value={selectedAll}
                                           disable={getSelectedAllDisabled()}
                                           onChange={handleSelectedAllOnChange}/>
                            </div>
                            <div>就醫序號</div>
                            <div>門/住</div>
                            <div>就醫類別</div>
                            <div>{t('general.patientNo')}</div>
                            <div>姓名</div>
                            <div>序號</div>
                            <div>寫卡狀態</div>
                            <div>上傳狀態</div>
                        </li>
                        {
                            !arrayIsEmpty(data) && getFilterData().map((item, index) =>
                                <li key={index}>
                                    {/*選擇欄位*/}
                                    <div>
                                        <Checkbox value={item.selected}
                                                  onChange={() => handleSelectionOnChange(index)}/>
                                    </div>
                                    {/*就醫序號*/}
                                    <div>{item.encounterId}</div>
                                    {/*門/住*/}
                                    <div>{item.inpOpd}</div>
                                    {/*就醫類別*/}
                                    <div>{item.icEncType}</div>
                                    {/*病例號*/}
                                    <div>{item.patientId}</div>
                                    {/*姓名*/}
                                    <div></div>
                                    {/*序號*/}
                                    <div>{item.bedNo}</div>
                                    {/*寫卡狀態*/}
                                    <div></div>
                                    {/*上傳狀態*/}
                                    <div>{item.icUploadStatus}</div>
                                </li>
                            )
                        }
                    </ul>
                </div>
                {/*Active Tab*/}
                <ActiveTab/>
            </div>
        </div>
    )
}
export default MaintainPage
