import React, { useState } from 'react'
import ReferralFormSearchBar from '../../components/ReferralSystem/ReferralForm/ReferralFormSearchBar'
import { REFERRAL_FORM_FIELDS } from '../../constants/referralSystem/referralForm'
import {
    ApiErrorStatusEnum,
    time2String,
    stringIsEmpty,
    downloadFile,
    exportExcelAsBlob,
    FileExtensionsEnum,
    fuzzySearchObjects,
} from 'edah_utils/dist'
import { downloadAllApi, downETranQueryBySearch } from '../../api/v1/Rmis'
import { DATETIME_FORMAT } from '../../constants/common'
import useToast from '../../hooks/useToast'
import moment from 'moment'
import { t } from 'i18next'
import {
    AlertTypeEnum,
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    IconEnum,
} from 'edah-component/dist'
import AdvancedSearchTable from '../../components/Table/AdvancedSearchTable'

/**
 * 電子轉診單_下載
 * @returns {JSX.Element}
 */
const ReferralFormDownload = () => {
    /**
     * 電子轉診單清單
     */
    const [dataList, setDataList] = useState([])

    /**
     * 搜尋後的電子轉診單清單
     */
    const [filteredList, setFilteredList] = useState([])

    /**
     * 表格選取的資料
     */
    const [selectedEtranNoList, setSelectedEtranNoList] = useState([])

    /**
     * 進階搜尋關鍵字
     */
    const [keyword, setKeyword] = useState('')

    /**
     * pagination
     */
    const [filter, setFilter] = useState({
        // 總頁數
        totalPageSize: 1,
        // 總筆數
        totalSize: 0,
        // 當前頁碼
        page: 1,
        // 每頁筆數
        pageSize: 10,
    })

    /**
     * 查詢欄位
     */
    const [searchParams, setSearchParams] = useState({
        // 有效日期(起日)
        effStartDate: '',
        // 有效日期(迄日)
        effEndDate: '',
        // 身分證號
        idNo: '',
    })

    /**
     * toast message
     */
    const showToast = useToast()

    /**
     * 匯出
     * @return {void}
     */
    const handleExport = () => {
        // 已勾選的資料
        const selectedDataList = dataList.filter((item) =>
            selectedEtranNoList.includes(item.etranNo)
        )
        // 無選取資料則不匯出
        if (selectedEtranNoList.length === 0) {
            return
        }
        // 匯出資料陣列
        const dataArray = []
        selectedDataList.forEach((item) => {
            const data = []
            // 遍歷欄位將當列資料從object轉換成陣列
            REFERRAL_FORM_FIELDS.forEach((column) => {
                switch (column.field) {
                    case 'orderDate':
                    case 'orderDataUploadDatetime':
                        data.push(column.valueFormatter(item[column.field])) // 資料格式轉換 ex: 日期格式轉為YYYY-MM-DD
                        break
                    default:
                        data.push(item[column.field])
                }
            })
            dataArray.push(data)
        })
        // excel sheet資料
        const sheets = [
            {
                titles: REFERRAL_FORM_FIELDS.map((item) => item.headerName),
                data: dataArray,
            },
        ]
        // 匯出
        downloadFile(
            exportExcelAsBlob(sheets),
            '電子轉診單',
            FileExtensionsEnum.XLSX
        )
    }

    /**
     * 查詢電子轉診單
     * @param pageNum {number} 當前頁碼
     * @param pageSize {number} 每頁筆數
     * @return {void}
     */
    const getETranList = (page, pageSize) => {
        // 必填欄位未填寫則不查詢
        if (
            stringIsEmpty(searchParams.effStartDate) ||
            stringIsEmpty(searchParams.effEndDate)
        ) {
            return
        }
        // 查詢參數
        const params = {
            ...searchParams,
            pageNum: page,
            pageSize,
        }
        // 查詢電子轉診單
        downETranQueryBySearch(params).then((response) => {
            // 成功
            if (response.err === ApiErrorStatusEnum.Success) {
                const list = response.data.dataList
                // 無資料則顯示查無資料提醒
                if (list.length === 0) {
                    showToast({
                        message: '查無資料',
                        type: AlertTypeEnum.Warning,
                    })
                }
                // 修改資料格式
                const modifiedList = list.map((item) => {
                    const tranPurpose = item.tranPurpose || ''
                    const tranPurposeRemark = item.tranPurposeRemark || ''
                    return {
                        ...item,
                        tranPurposeContent: `${tranPurpose}.${tranPurposeRemark}`,
                    }
                })
                // 更新資料列表
                setDataList(modifiedList)
                setFilteredList(modifiedList)
                // 更新分頁資料
                setFilter({
                    ...filter,
                    totalSize: response.data.totalItemSize,
                    totalPageSize: response.data.totalPageSize,
                    pageSize,
                    pageNum: page,
                })
            } else {
                // 顯示錯誤提醒
                showToast({
                    message: response.msg,
                    type: AlertTypeEnum.Error,
                })
            }
        })
    }

    /**
     * 點選查詢按鈕
     * @return {void}
     */
    const handleQueryETranList = () => {
        getETranList(1, filter.pageSize)
    }

    /**
     * 更新查詢條件
     * @param searchParams {object} 查詢條件
     * @return {void}
     */
    const searchParamsOnChange = (searchParams) => {
        setSearchParams({
            effStartDate: time2String(
                searchParams.effStartDate,
                DATETIME_FORMAT
            ),
            effEndDate: time2String(searchParams.effEndDate, DATETIME_FORMAT),
            idNo: searchParams.idNo,
        })
    }

    /**
     * 點選頁碼
     * @param page {number} 當前頁碼
     * @return {void}
     */
    const onPageOnChange = (page, pageSize) => {
        getETranList(page, pageSize)
    }

    /**
     * 選取資料變更
     * @param selectedEtranNoList 已選取的資料清單
     */
    const onSelectionChange = (selectedEtranNoList) => {
        setSelectedEtranNoList(selectedEtranNoList)
    }

    /**
     * 申請下載電子轉診單
     * @return {void}
     */
    const applyDownloadEtran = () => {
        // 系統當天日期
        const today = time2String(new Date(), DATETIME_FORMAT)
        // 系統前一天日期
        const yesterday = time2String(
            moment(today).subtract(1, 'days'),
            DATETIME_FORMAT
        )
        // 查詢參數
        const params = {
            tranApiStartDate: yesterday, // 系統前一天日期
            tranApiEndDate: today, // 系統當天日期
        }
        // 申請下載電子轉診單
        downloadAllApi(params).then((res) => {
            // 成功
            if (res.err === ApiErrorStatusEnum.Success) {
                showToast({
                    message: '申請下載成功',
                    type: AlertTypeEnum.Success,
                })
            } else {
                // 顯示錯誤提醒
                showToast({ message: res.msg, type: AlertTypeEnum.Error })
            }
        })
    }

    const handleDataFilter = (e) => {
        const value = e.target.value
        setKeyword(value)
        const filteredDataList = fuzzySearchObjects(dataList, value)
        setFilteredList(filteredDataList)
    }

    return (
        <div className="p-2 w-full">
            <div className="flex justify-between text-center p-2">
                {/* 查詢欄位 */}
                <ReferralFormSearchBar
                    handleQuery={handleQueryETranList}
                    searchParamsOnChange={searchParamsOnChange}
                />
                {/* 申請下載按鈕 */}
                <div>
                    <Button
                        sx={{ fontWeight: 'bold' }}
                        color={ButtonColorEnum.Secondary}
                        size={ButtonSizeEnum.Large}
                        text={t('general.download')}
                        variant={ButtonVariantEnum.Outlined}
                        onClick={applyDownloadEtran}
                    />
                </div>
            </div>
            <AdvancedSearchTable
                rows={filteredList}
                columns={REFERRAL_FORM_FIELDS}
                checkboxSelection
                onSelectionChange={onSelectionChange}
                getRowId={(row) => row.etranNo}
                height={'600px'}
                headerSlot={
                    <Button
                        color={ButtonColorEnum.Success}
                        icon={IconEnum.Export}
                        size={ButtonSizeEnum.Large}
                        text={t('general.export')}
                        variant={ButtonVariantEnum.Text}
                        onClick={handleExport}
                    />
                }
                pagination={filter}
                onPageOnChange={onPageOnChange}
                isShowPagination={true}
            />
        </div>
    )
}

export default ReferralFormDownload
