// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.maintainInfo.disabled input, .maintainInfo.disabled select {
  pointer-events: none;
  border: none;
  background-color: #fff !important;
}
.maintainInfo.disabled select {
  appearance: none;
}
.maintainInfo.disabled input[type=date]::-webkit-calendar-picker-indicator {
  display: none;
}
.maintainInfo.disabled li {
  border-bottom: 1px solid rgba(17, 17, 17, 0.15);
}
.maintainInfo.CVRInfo {
  overflow-x: scroll;
}
.maintainInfo.CVRInfo li {
  width: 840px;
  height: 42px;
}
.maintainInfo.CVRInfo div {
  flex-shrink: 0;
  flex-grow: 0;
}
.maintainInfo.CVRInfo div:nth-of-type(2n) {
  text-align: left;
  margin-left: 16px;
}
.maintainInfo li {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 42px;
  justify-content: flex-start;
  margin-bottom: 5px;
}
.maintainInfo div {
  width: 160px;
  text-align: right;
  margin-right: 16px;
}
.maintainInfo input, .maintainInfo select {
  width: 235px;
  height: 40px;
  padding: 0 16px;
  border: 1px solid #D4D4D8;
  border-radius: 6px;
}`, "",{"version":3,"sources":["webpack://./src/components/ICCard/maintain.scss"],"names":[],"mappings":"AAEI;EACE,oBAAA;EACA,YAAA;EACA,iCAAA;AADN;AAGI;EAGE,gBAAA;AADN;AAGI;EACE,aAAA;AADN;AAGI;EACE,+CAAA;AADN;AAKE;EACE,kBAAA;AAHJ;AAII;EACE,YAAA;EACA,YAAA;AAFN;AAII;EACE,cAAA;EACA,YAAA;AAFN;AAGM;EACE,gBAAA;EACA,iBAAA;AADR;AAKE;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,YAAA;EACA,2BAAA;EACA,kBAAA;AAHJ;AAME;EACE,YAAA;EACA,iBAAA;EACA,kBAAA;AAJJ;AAME;EACE,YAAA;EACA,YAAA;EACA,eAAA;EACA,yBAAA;EAGA,kBAAA;AAJJ","sourcesContent":[".maintainInfo {\n  &.disabled {\n    input, select {\n      pointer-events: none;\n      border: none;\n      background-color: #fff !important;\n    }\n    select {\n      -webkit-appearance: none;\n      -moz-appearance: none;\n      appearance: none;\n    }\n    input[type=\"date\"]::-webkit-calendar-picker-indicator {\n      display: none;\n    }\n    li {\n      border-bottom: 1px solid rgba(#111111, 0.15);\n\n    }\n  }\n  &.CVRInfo {\n    overflow-x: scroll;\n    li {\n      width: 840px;\n      height: 42px;\n    }\n    div {\n      flex-shrink: 0;\n      flex-grow: 0;\n      &:nth-of-type(2n) {\n        text-align: left;\n        margin-left: 16px;\n      }\n    }\n  }\n  li {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    height: 42px;\n    justify-content: flex-start;\n    margin-bottom: 5px;\n  }\n\n  div {\n    width: 160px;\n    text-align: right;\n    margin-right: 16px;\n  }\n  input, select {\n    width: 235px;\n    height: 40px;\n    padding: 0 16px;\n    border: 1px solid #D4D4D8;\n    -webkit-border-radius: 6px;\n    -moz-border-radius: 6px;\n    border-radius: 6px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
