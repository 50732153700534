import { useSelector } from 'react-redux'
import A4Print from './A4Print'
import { time2String } from 'edah_utils'
import { DATE_FORMAT } from '../../constants/common'

/**
 * 轉診回函明細清單
 * @returns {JSX.Element}
 */
const ReplyLetterContent = () => {
    const printData = useSelector((state) => state.print.printData)
    return (
        <>
            <h1 className="text-xl font-bold text-center">轉診回函</h1>
            <table className="w-full border-collapse text-sm border-t border-[#111111]/15">
                <thead>
                    <tr className="h-[40px] text-[#18181b]">
                        <th className="w-[100px] text-left break-words p-1">
                            就醫日期
                        </th>
                        <th className="w-[100px] text-left break-words p-1">
                            病歷號
                        </th>
                        <th className="w-[100px] text-left break-words p-1">
                            身分證號
                        </th>
                        <th className="w-[100px] text-left break-words p-1">
                            姓名
                        </th>
                        <th className="w-[100px] text-left break-words p-1">
                            就醫科別
                        </th>
                        <th className="w-[100px] text-left break-words p-1">
                            就醫醫師
                        </th>
                        <th className="w-[100px] text-left break-words p-1">
                            列印日期
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {printData.map((item, index) => {
                        return (
                            <tr
                                key={index}
                                className="h-[50px] text-[#18181b] "
                            >
                                <td className="text-left break-words p-1">
                                    {time2String(
                                        item.encounterDate,
                                        DATE_FORMAT
                                    )}
                                </td>
                                <td className="text-left break-words p-1">
                                    {item.patientId}
                                </td>
                                <td className="text-left break-words p-1">
                                    {item.idNo}
                                </td>
                                <td className="text-left break-words p-1">
                                    {item.patientName}
                                </td>
                                <td className="text-left break-words p-1">
                                    {item.divName}
                                </td>
                                <td className="text-left break-words p-1">
                                    {item.doctorName}
                                </td>
                                <td className="text-left break-words p-1">
                                    {item.tranInPrintDate}
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </>
    )
}

/**
 * 轉診回函明細列印格式
 * @returns {JSX.Element}
 */
const PrintReplyLetterList = () => {
    return (
        <A4Print>
            <ReplyLetterContent />
        </A4Print>
    )
}
export default PrintReplyLetterList
