
import React, {useEffect, useState} from "react";
import {BasePopup} from "../../../Popup/BasePopup";
import RadioButton from "../../../Input/RadioButton";
import {OptionChange, OptionColor, OptionExudates, OptionLevel} from "./formOption";
import {ReactComponent as DeleteIcon} from '../../../../assets/images/icons/del_square.svg'
import {Button, DatePicker, Field, SizeEnum, TextField} from "edah-component/dist";
import dayjs from "dayjs";

export const ModalStatus = {
    ADD: "ADD",
    EDIT: "EDIT",
}

/**
 * 策略聯盟維護表單
 * @returns {JSX.Element}
 */
const WoundRecordForm = ({status, data, onSave, onDelete}) => {

    const initData = {
        date: "",
        change: "",
        wound: "",
        long: 0,
        width: 0,
        depth: 0,
        level: false,
        color: false,
        exudates: false,
        type: "",
        count: "",
        nurse: ""
    }
    // 預設表單資料
    const [formData, setFormData] = useState(initData);
    /**
     * 更改表單資料
     * @param e
     * @param item
     */
    const handleInputChange = (e, item) => {
        const {value} = e.target;
        setFormData({
            ...formData,
            [item]: value
        });
    };

    const handleDateChange = (date) => {
        setFormData({
            ...formData,
            date
        });
    }
    /**
     * 更改單選表單資料
     * @param value
     * @param item
     */
    const handleRadioChange = (value, item) => {
        setFormData({
            ...formData,
            [item]: value
        });
    };
    // 刪除確認popup控制
    const [showDeletePopup, setShowDeletePopup] = useState(false)


    // 儲存
    const handleSave = () => {
        onSave && onSave(formData)
    }


    // 刪除單筆資料
    const handleConfirmDelete = () => {
        onDelete && onDelete()
    }
    // 開啟刪除彈窗
    const handleDeletePopupOpen = () => {
        setShowDeletePopup(true)
    }
    // 關閉刪除彈窗
    const handleDeletePopupClose = () => {
        setShowDeletePopup(false)
    }
    useEffect(() => {
        console.log({data})
        setFormData(data?.tableRow || initData)

    }, []);
    return <>
        {/*刪除確認popup*/}
        {showDeletePopup && (
            <BasePopup
                title="提醒"
                closePopupButtonOnClick={handleDeletePopupClose}
                width="559px"
                content={
                    <div>
                        <p className="h-[110px] px-6 py-9 text-[18px] text-left border-b-[1px] border-[rgba(0,0,0,0.15)]">是否確定刪除？</p>
                        <div className="h-[60px] flex flex-row items-center justify-end">
                            <button onClick={handleConfirmDelete}
                                    className="flex items-center justify-center h-10 px-4 mr-4 border-[2px] bg-[#2B6CB0] text-white border-[#2B6CB0] rounded-[6px]"
                            >是
                            </button>
                            <button onClick={handleDeletePopupClose}
                                    className="flex items-center justify-center h-10 px-4 mr-4 border-[2px] bg-white text-[#2B6CB0] border-[#2B6CB0] rounded-[6px]"
                            >否
                            </button>
                        </div>
                    </div>
                }
            />
        )}
        {/*刪除彈窗*/}
        {status === ModalStatus.EDIT && <div className="flex items-center justify-start px-8 pt-8">
            <button
                className="text-[#007CDB] flex"
                onClick={() => handleDeletePopupOpen()}
            >
                <DeleteIcon/>
                <div className="ml-1">刪除</div>
            </button>
        </div>}

        <div className="w-full overflow-auto p-4 space-y-4">
            {/* 記錄日期 */}
            <div className="grid grid-cols-2">
                <Field label="記錄日期">
                    <DatePicker
                        size={SizeEnum.Small}
                        value={formData.date ? dayjs(formData.date) : null}
                        onChange={handleDateChange}/>
                </Field>

                <Field label="護理師">
                    <TextField size={SizeEnum.Small}
                               value={formData.nurse}
                               onChange={(e) => {
                                   handleInputChange(e, "nurse")
                               }}
                    />
                </Field>
            </div>

            {/* 傷口變化 */}
            <Field childrenSx={{display: 'flex', alignItems: 'center'}} label="傷口變化">
                {
                    OptionChange.map((option, index) => (
                        <RadioButton wrapperClassName={"w-[100px] ml-2"} mode={formData.change}
                                     modeChecked={option.value}
                                     handleModeChange={(value) => {
                                         handleRadioChange(value, "change")
                                     }} label={option.label}/>
                    ))
                }
            </Field>
            {/* 傷口大小 */}
            <Field childrenSx={{display: 'flex', alignItems: 'center', gap: 2}} label="傷口大小">
                <div className="flex items-center">長
                    <TextField size={SizeEnum.Small} inputWidth={SizeEnum.Small}
                               value={formData.long}
                               onChange={(e) => {
                                   handleInputChange(e, "long")
                               }}/>
                </div>
                <div className="flex items-center">寬
                    <TextField size={SizeEnum.Small} inputWidth={SizeEnum.Small}
                               value={formData.width}
                               onChange={(e) => {
                                   handleInputChange(e, "width")
                               }}/>
                </div>
                <div className="flex items-center">深
                    <TextField size={SizeEnum.Small} inputWidth={SizeEnum.Small}
                               value={formData.depth}
                               onChange={(e) => {
                                   handleInputChange(e, "depth")
                               }}/>
                </div>
            </Field>
            {/* 潛行傷口 */}
            <Field label="潛行傷口">
                <TextField size={SizeEnum.Small}
                           value={formData.wound}
                           onChange={(e) => {
                               handleInputChange(e, "wound")
                           }}/>
            </Field>
            {/* 等級 */}
            <Field childrenSx={{display: 'flex', alignItems: 'center'}} label="等級">
                {
                    OptionLevel.map((option, index) => (
                        <RadioButton wrapperClassName={"w-[100px] ml-2"} mode={formData.level}
                                     modeChecked={option.value}
                                     handleModeChange={(value) => {
                                         handleRadioChange(value, "level")
                                     }} label={option.label}/>
                    ))
                }
            </Field>
            {/* 傷口顏色 */}
            <Field childrenSx={{display: 'flex', alignItems: 'center'}} label="傷口顏色">
                {
                    OptionColor.map((option, index) => (
                        <RadioButton wrapperClassName={"w-[100px] ml-2"} mode={formData.color}
                                     modeChecked={option.value}
                                     handleModeChange={(value) => {
                                         handleRadioChange(value, "color")
                                     }} label={option.label}/>
                    ))
                }
            </Field>
            {/* 滲出液 */}
            <Field childrenSx={{display: 'flex', alignItems: 'center'}} label="滲出液">
                {
                    OptionExudates.map((option, index) => (
                        <RadioButton wrapperClassName={index > 1 ? "w-[180px]" : "w-[100px] ml-2"}
                                     mode={formData.exudates}
                                     modeChecked={option.value}
                                     handleModeChange={(value) => {
                                         handleRadioChange(value, "exudates")
                                     }} label={option.label}/>
                    ))
                }
            </Field>
            {/* 換藥方式 */}
            <Field label="換藥方式">
                <TextField size={SizeEnum.Small}
                           value={formData.type}
                           onChange={(e) => {
                               handleInputChange(e, "type")
                           }}/>
            </Field>
            {/* 擦藥次數 */}
            <Field label="擦藥次數">
                <TextField size={SizeEnum.Small}
                           value={formData.count}
                           onChange={(e) => {
                               handleInputChange(e, "count")
                           }}/>
            </Field>
        </div>
        <div
            className="title w-full flex flex-row items-center justify-end px-6 py-4 border-t-[1px] border-[rgba(0,0,0,0.15)]">
            <Button text={"存檔"} onClick={handleSave}/>
        </div>

    </>
}

export default WoundRecordForm