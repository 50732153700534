//Import icon
import SearchIcon from "../../assets/images/search-interface-symbol.png"

//Import function
import React, {useEffect, useRef, useState} from "react"
import {
    ApiErrorStatusEnum,
    arrayIsEmpty,
    fuzzySearchObjects,
    multiTermFuzzySearchObjects,
    objectIsEmpty,
    PopupModeEnum,
    ToastTypeEnum
} from "edah_utils/dist"
import useOutsideClick from "../../hooks/useOutsideClick"
import {t} from "i18next"
import {essiAddSignAgent, essiEditSignAgent} from "../../api/v1/ESSI"
import useToast from "../../hooks/useToast"

/**
 * 代簽人員維護-新增修改彈窗
 * @param show 是否顯示
 * @param dataRow {Object} 資料
 * @param doctors {Array<Object>} 醫生列表
 * @param mode {Number} 新增或編輯
 * @param closePopupOnClick {function} 關閉按鈕點擊事件
 * @return {JSX.Element}
 */
const AddEditSignAgentPopup = ({show, rowData, doctors, mode, closePopupOnClick}) => {
    /**
     * grid element 的預設type
     */
    const GridDefaultType = "grid grid-cols-2 gap-x-6 gap-y-2"

    /**
     * Toast
     */
    const showToast = useToast()

    /**
     * 預設資料
     */
    const defaultData = {
        // 代理人員主鍵
        "agnetPk": null,
        // 簽章人員編號
        "userNo": '',
        // 代理人員編號
        "agentUserNo": '',
        // 生效日期-起
        "effStartDatetime": null,
        // 生效日期-迄
        "effEndDatetime": null,
        // 備註
        "memo": null
    }

    //是否顯示彈窗
    const [showPopup, setShowPopup] = useState(false)

    // 是否顯示人員代號選項
    const [showUserDropdown, setShowUserDropdown] = useState(false)

    // 是否顯示代理人員代號選項
    const [showUserAgentDropdown, setShowUserAgentDropdown] = useState(false)

    //是否為新增模式
    const [isAddMode, setIsAddMode] = useState(false)

    //ref 用於指向人員代下拉菜單元素
    const dropdownUserRef = useRef(null)

    //ref 用於指向代理人員代號下拉菜單元素
    const dropdownAgentRef = useRef(null)

    //新增 / 修改資料
    const [newData, setNewData] = useState(null)

    /**
     * 處理人員代號搜尋文字框焦點事件
     * @return {void}
     */
    const handleUserNoSearchTextOnFocus = () => setShowUserDropdown(true)

    /**
     * 處理代理人員代號搜尋文字框焦點事件
     * @return {void}
     */
    const handleAgentNoSearchTextOnFocus = () => setShowUserAgentDropdown(true)

    /**
     * 處理人員代號選項點擊事件
     * @param option {Object} 人員代號
     * @return {void}
     */
    const handleUserNoOptionOnClick = (option) => {
        if (!objectIsEmpty(option)) {
            // 設定新資料
            setNewData({...newData, userNo: `${option.userNo} ${option.userName}`})
        }
        // 關閉下拉選單
        setShowUserDropdown(false)
    }

    /**
     * 處理代理人員代號選項點擊事件
     * @param option {Object} 人員代號
     * @return {void}
     */
    const handleAgentNoOptionOnClick = (option) => {
        if (!objectIsEmpty(option)) {
            // 設定新資料
            setNewData({...newData, agentUserNo: `${option.userNo} ${option.userName}`})
        }
        // 關閉下拉選單
        setShowUserAgentDropdown(false)
    }

    /**
     * 確定刪除點擊事件
     * @return {void}
     */
    const handleCloseButtonOnClick = () => {
        // 呼叫父層關閉彈窗
        closePopupOnClick()
        // 關閉彈窗
        setShowPopup(false)
    }

    /**
     * 儲存或編輯按鈕被點擊時
     * @return {void}
     */
    const handleSaveOrEditOnClick = () => {
        if (isAddMode) {
            // 新增代簽人員維護資料
            addSignAgent()
        } else {
            // 修改代簽人員維護資料
            editSignAgent()
        }
    }

    /**
     * 使用者代號選項改變時
     * @param e {Event}
     * @return {void}
     */
    const handleUserNoOnChange = (e) => setNewData({...newData, userNo: e.target.value})

    /**
     * 代理人代號選項改變時
     * @param e {Event}
     * @return {void}
     */
    const handleAgentUserNoOnChange = (e) => setNewData({...newData, agentUserNo: e.target.value})

    /**
     * 生效日期-起迄改變時
     * @param e {Event}
     * @return {void}
     */
    const handleEffStartDatetimeOnChange = (e) => setNewData({...newData, effStartDatetime: e.target.value})

    /**
     * 生效日期-迄改變時
     * @param e {Event}
     * @return {void}
     */
    const handleEffEndDatetimeOnChange = (e) => setNewData({...newData, effEndDatetime: e.target.value})

    /**
     * 取得使用者選項過濾後列表
     * @return {Array<Object>}
     */
    const getFilterUserNoOptionList = () =>
        newData.userNo.indexOf(' ') >= 0 ?
            // 多個字串模糊搜尋
            multiTermFuzzySearchObjects(doctors, newData.userNo.split(' ')) :
            // 單個字串模糊搜尋
            fuzzySearchObjects(doctors, newData.userNo)

    /**
     * 取得代理人使用者選項過濾後列表
     * @return {Array<Object>}
     */
    const getFilterAgentUserNoOptionList = () =>
        newData.agentUserNo.indexOf(' ') >= 0 ?
            // 多個字串模糊搜尋
            multiTermFuzzySearchObjects(doctors, newData.agentUserNo.split(' ')) :
            // 單個字串模糊搜尋
            fuzzySearchObjects(doctors, newData.agentUserNo)

    /**
     * 處理API回應
     * @param res {Object} API回應
     * @return {void}
     */
    const handleApiResponse = (res) => {
        // 取得API回應
        if (res.err === ApiErrorStatusEnum.Success) {
            // 顯示成功訊息
            showToast({message: res.msg})
            // 呼叫父層關閉彈窗
            closePopupOnClick()
        } else { // 顯示錯誤訊息
            showToast({type: ToastTypeEnum.Error, message: res.msg})
        }
    }

    /**
     * 新增代簽人員維護資料
     * @return {void}
     */
    const addSignAgent = () => {
        essiAddSignAgent([
            {
                ...newData,
                userNo: newData.userNo.split(' ')[0],
                agentUserNo: newData.agentUserNo.split(' ')[0]
            }
        ]).then(handleApiResponse)
    }

    /**
     * 修改代簽人員維護資料
     * @return {void}
     */
    const editSignAgent = () => {
        essiEditSignAgent([
            {
                ...newData,
                userNo: newData.userNo.split(' ')[0],
                agentUserNo: newData.agentUserNo.split(' ')[0]
            }
        ]).then(handleApiResponse)
    }

    /**
     * 監聽是否顯示彈窗Pros變化
     * @return {void}
     */
    useEffect(() => {
        // 設定是否顯示彈窗
        setShowPopup(show)
        // 設定是否為新增模式
        setIsAddMode(mode === PopupModeEnum.Add)
    }, [show])

    /**
     * 間接監聽rowData變化
     * @return {void}
     */
    useEffect(() => {
        const isAddMode = mode === PopupModeEnum.Add
        if (isAddMode) {
            setNewData(defaultData)
        } else {
            // 取得使用者資料
            const user = fuzzySearchObjects(doctors, rowData.userNo)[0]
            const agentUser = fuzzySearchObjects(doctors, rowData.agentUserNo)[0]
            setNewData({
                ...rowData,
                userNo: `${user.userNo} ${user.userName}`,
                agentUserNo: `${agentUser.userNo} ${agentUser.userName}`
            })
        }
    }, [rowData])

    /**
     * 點擊外部區域時
     */
    useOutsideClick({
        ref: dropdownAgentRef,
        handler: () => setShowUserAgentDropdown(false),
    })

    /**
     * 點擊外部區域時
     */
    useOutsideClick({
        ref: dropdownUserRef,
        handler: () => setShowUserDropdown(false),
    })

    return showPopup ?
        <div
            className="absolute top-0 left-0 bg-[rgba(0,0,0,0.5)] w-screen h-screen z-80 min-w-screen min-h-screen items-center justify-center flex py-12 px-[100px] text-globalFont">
            <div className="flex w-[930px] bg-[#ffffff] rounded-md text-lg relative flex-col">
                {/**Title*/}
                <div
                    className="h-[60px] w-full px-6 py-4 bg-white rounded-tl-lg rounded-tr-lg border-b border-neutral-900 border-opacity-20 flex justify-between items-center gap-2.5">
                    {/*代簽人員選擇*/}
                    <div className="text-black text-xl font-normal">
                        代簽人員選擇
                    </div>
                    {/*關閉按鈕*/}
                    <div className="text-sky-700 text-lg font-normal cursor-pointer"
                         onClick={handleCloseButtonOnClick}>
                        {t('general.close')}
                    </div>
                </div>
                {/**Container*/}
                <div className="px-4 py-5">
                    <div className={`${GridDefaultType}`}>
                        {/*人員代號*/}
                        <div className={`${GridDefaultType}`}>
                            <p className="text-right mt-1">人員代號</p>
                            <div className="flex flex-row space-x-2 items-center relative w-full">
                                <div className="flex flex-row space-x-2 items-center relative w-full"
                                     ref={dropdownUserRef}>
                                    <input
                                        className="bg-white w-full px-2 p-1 pl-9 text-[#3F3F46] rounded-md border border-[#D4D4D8]"
                                        value={newData.userNo}
                                        onChange={(e) => handleUserNoOnChange(e)}
                                        onFocus={handleUserNoSearchTextOnFocus}/>
                                    <img className="w-4 h-4 absolute top-w-1/2 left-0 translate-x-1" alt="search-icon"
                                         src={SearchIcon} role="button"/>
                                    {
                                        !arrayIsEmpty(doctors) && showUserDropdown && (
                                            <div
                                                className="z-10 absolute left-5 border border-[#d4d4d8] bg-white rounded-md top-8 w-64 flex flex-col">
                                                <div className="max-h-[200px] bg-transparent overflow-y-auto px-2.5">
                                                    {
                                                        getFilterUserNoOptionList().map((doctor, index) =>
                                                            <div
                                                                className="bg-transparent border-b min-h-10 flex space-x-6 px-4 py-1 text-[#18181B] items-center"
                                                                role="button" key={index}
                                                                onClick={() => handleUserNoOptionOnClick(doctor)}>
                                                                <p>{`${doctor.userNo} ${doctor.userName}`}</p>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        {/*代理人員代號*/}
                        <div className={`${GridDefaultType}`}>
                            <p className="text-right mt-1">代理人員代號</p>
                            <div className="flex flex-row space-x-2 items-center relative w-full">
                                <div className="flex flex-row space-x-2 items-center relative w-full"
                                     ref={dropdownAgentRef}>
                                    <input
                                        className="bg-white w-full px-2 p-1 pl-9 text-[#3F3F46] rounded-md border border-[#D4D4D8]"
                                        value={newData.agentUserNo}
                                        onChange={(e) => handleAgentUserNoOnChange(e)}
                                        onFocus={handleAgentNoSearchTextOnFocus}/>
                                    <img className="w-4 h-4 absolute top-w-1/2 left-0 translate-x-1" alt="search-icon"
                                         src={SearchIcon} role="button"/>
                                    {
                                        !arrayIsEmpty(doctors) && showUserAgentDropdown && (
                                            <div
                                                className="z-10 absolute left-5 border border-[#d4d4d8] bg-white rounded-md top-8 w-64 flex flex-col">
                                                <div className="max-h-[200px] bg-transparent overflow-y-auto px-2.5">
                                                    {
                                                        getFilterAgentUserNoOptionList().map((doctor, index) =>
                                                            <div
                                                                className="bg-transparent border-b min-h-10 flex space-x-6 px-4 py-1 text-[#18181B] items-center"
                                                                role="button" key={index}
                                                                onClick={() => handleAgentNoOptionOnClick(doctor)}>
                                                                <p>{`${doctor.userNo} ${doctor.userName}`}</p>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        {/*代簽的起始日期*/}
                        <div className={`${GridDefaultType}`}>
                            <p className="text-right mt-1">代簽的起訖日期</p>
                            <input
                                className={`px-[14px] w-[100%] border border-[#D4D4D8] h-[40px] rounded-[6px] mr-[30px]`}
                                type="date" value={newData.effStartDatetime}
                                onChange={handleEffStartDatetimeOnChange}/>
                        </div>
                        {/*代簽的結束日期*/}
                        <div className="flex flex-row">
                            <p className="mr-4 text-right mt-1">~</p>
                            <input
                                className={`px-[14px] w-[50%] border border-[#D4D4D8] h-[40px] rounded-[6px] mr-[30px]`}
                                type="date" value={newData.effEndDatetime}
                                onChange={handleEffEndDatetimeOnChange}/>
                        </div>
                    </div>
                </div>
                {/**下方區塊(存檔) */}
                <div
                    className="h-[60px] p-4 bg-white rounded-bl-lg rounded-br-lg border-t border-neutral-900 border-opacity-20 flex-col justify-center items-end inline-flex">
                    <button className="bg-[#2B6CB0] p-2 text-white rounded-[6px] text-[16px] min-w-[64px]"
                            onClick={handleSaveOrEditOnClick}>
                        送出
                    </button>
                </div>
            </div>
        </div> : null
}
export default AddEditSignAgentPopup
