import { useSelector } from 'react-redux'
import './print.scss'
/**
 * 病症暨失能證明書 - 列印申請人信封
 * @returns {JSX.Element}
 */
const PrintApplyEnvelope = () => {
    const printData = useSelector((state) => state.print.printData)
    return (
        <>
        {printData?.map((item) => {
                return (
                    <div className="envelope-cover-basic">
                        <div className="border-[#D4D4D8] border p-8 rounded-md">
                            <div className="border-b border-[#111111]">
                                <div>掛號</div>
                                <div className="ml-3">{item?.patientName} 收</div>
                            </div>
                            <div>
                                <div>{item?.patientZipCode}</div>
                                <div>{item?.patientAddress}</div>
                            </div>
                        </div>
                    </div>
                )
            })
        }
        </>
    )
}

export default PrintApplyEnvelope
