import React, {useEffect, useState} from "react";
import InformationDataGrid from "../../components/CDSS/InformationDataGrid";
import RulePopup from "../../components/CDSS/RulePopup";
import { PopupModeEnum, ApiErrorStatusEnum, stringIsEmpty, objectIsEmpty, arrayIsEmpty } from "edah_utils/dist"
import { queryByCdssId, delCdssId } from "../../api/v1/CDSS";
import DeleteRuleMessagePopup from "../../components/CDSS/DeleteRuleMessagePopup";
import {AlertTypeEnum, ColorEnum, Select, SelectWidthEnum, SizeEnum, TextField, Button, ButtonSizeEnum, ButtonVariantEnum} from "edah-component/dist";
import useToast from "../../hooks/useToast";
import {t} from "i18next";

const InformationMaintain = () => {
    //fake data
    const informationDataArray = [
        {
            "createDatetime": "2024-04-25 10:24:41",
            "createUser": "edah",
            "modifyDatetime": null,
            "modifyUser": null,
            "lockVersion": 0,
            "cdssId": "RULE9996",
            "category": "系統測試",
            "purpose": "測試 KIE-Server 是否正常",
            "description": "KIE-Server 部署一個 container , 測試呼叫 KIE-Server, kie.containerId=rule9996_1.0.0-SNAPSHOT",
            "ruleSource": "測試",
            "serverType": "KIE-Server",
            "effectiveFlag": false,
            "parameterConstraints": "所有診斷碼",
            "returnResult": "判別是否為小孩"
        }
    ];
    // 是否顯示Popup
    const [showPopup, setShowPopup] = useState(false);
    // Popup模式
    const [popupMode, setPopupMode] = useState(null);
    //當前選擇的資料
    const [selectedData, setSelectedData] = useState(null);
    //Query 資料
    const [queryData, setQuertData] = useState(null);
    //搜尋文字
    const [searchText, setSearchText] = useState("");
    // 是否顯示刪除的彈窗
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    //刪除的CDSSID
    const [deleteDataId, setDeleteDataId] =useState(null);
    // Toast Message Hook
    const showToast = useToast();
    /**
     * Toast資料
     */
    const [toast, setToast] = useState(null)

    /**
     * 顯示Popup
     * @param data {Object}
     * @param mode {String}
     */
    const handleShowPopup = (data, mode = PopupModeEnum.Add) => {
        // 設定Popup模式
        setPopupMode(mode);
        //取得資料
        const newData = mode === PopupModeEnum.Modify ?
            {...data} :
            {
                //複製原先資料
                ...data,
            }
        // 設定選擇的資料
        setSelectedData(newData)
        // 顯示Popup
        setShowPopup(true);
    }

    /**
     * 顯示刪除的彈窗
     * @param data {Object} 要刪除的Rule
     */
    const handleShowDeletePopup = (data) => {

        if(!objectIsEmpty(data)) {
            setDeleteDataId(data.cdssId);
        }
        //顯示刪除的彈窗
        setShowDeletePopup(true);
    }

    /**
     * 關閉Popup
     * @return void
     */
    const handleClosePopup = () => setShowPopup(false)

    /**
     * 取得CDSS 規則資料
     */
    const handleSuccessCreateRule = () => {
        // 關閉Popup
        setShowPopup(false)

        setToast({
            show:true,
            type:"Success",
            message: popupMode === PopupModeEnum.Modify ? "編輯成功":"新增成功"
        })
    }

    /**
     * 點擊搜尋規則事件
     */
    const handleQuerySearchOnClick = () => {
        queryByCdssId({cdssId:searchText}).then(res => {
            if (res.err === ApiErrorStatusEnum.Success) {
                setQuertData(res.data)

                if(arrayIsEmpty(res.data)) {
                    setToast({
                        show:true,
                        type:"Warning",
                        message: "沒有找到符合的資料"
                    })
                }
            }
            else {
                setQuertData(null);
                setToast({
                    show:true,
                    type:"Error",
                    message: res.msg
                })
            }
        })
    }

    /**
     * 搜尋文字改變時
     * @param e {Object}
     */
    const handleSearchTextOnChange = (e) => setSearchText(e.target.value)

    /**
     * 刪除視窗關閉事件
     * @return void
     */
    const handleDeletePopupOnClose = () => setShowDeletePopup(false)

    /**
     * 刪除事件
     */
    const handleDeleteOnClick = () => {

        if(!stringIsEmpty(deleteDataId))
        {
            delCdssId({
                cdssId: deleteDataId
            }).then(res => {
                //執行成功
                if (res.err === ApiErrorStatusEnum.Success) {
                    // 透過API重新取資料
                    setToast({
                        show:true,
                        type:"Success",
                        message: "刪除成功"
                    })

                    handleQuerySearchOnClick()
                }
                else {
                    setToast({
                        show:true,
                        type:"Error",
                        message: res.msg
                    })
                }
            })
            console.log(deleteDataId);
        }

        setShowDeletePopup(false);

        setDeleteDataId(null);
    }

    // 監聽是否需要顯示 Alert
    useEffect(()=>{
        if(toast){
            showToast({type:AlertTypeEnum[toast.type], message:toast.message})
        }
    },[toast])

    return (
        <div className="w-full p-4 bg-[#FAFAFA]">
            {/*篩選清單*/}
            <div className="filterGroup flex flex-row flex-wrap items-center justify-start space-x-2 py-2 text-left">
                <Select
                    width={SelectWidthEnum.Medium}
                    data={{
                        label: '查詢條件',
                        options: [{value:'規則代碼',label:'規則代碼'}]
                    }}
                    value={"規則代碼"}
                    notched={true}/>
                <TextField
                    value={searchText}
                    inputWidth={SizeEnum.Medium}
                    label={"輸入查詢值"}
                    onChange={handleSearchTextOnChange}/>
                <Button
                    color={ColorEnum.Primary}
                    variant={ButtonVariantEnum.Contained}
                    size={ButtonSizeEnum.Medium}
                    onClick={handleQuerySearchOnClick}
                >
                    {t('general.query')}
                </Button>
            </div>
            {/*表格區*/}
            <div className="maintainContainer flex flex-row items-start justify-start">
                <div className="p-4 bg-white w-full border-[1px] border-[#D4D4D8] rounded-[12px] mr-4">
                    <div className="flex items-center justify-between mb-4 overflow-auto">
                        <InformationDataGrid
                            arrayData={queryData}
                            showPopup={handleShowPopup}
                            showDeletePopup={handleShowDeletePopup}
                        />
                    </div>

                </div>
            </div>
            {/*規則Popup*/}
            {showPopup && (
                <RulePopup
                    mode={popupMode}
                    data={selectedData}
                    closePopup={handleClosePopup}
                    handleSuccessCreateRule={handleSuccessCreateRule}/>
            )}
            {/*刪除彈窗*/}
            {<DeleteRuleMessagePopup
                closePopupButtonOnClick={handleDeletePopupOnClose}
                show={showDeletePopup}
                handleDeleteOnClick={handleDeleteOnClick}/>
            }
        </div>
    )
}

export default InformationMaintain;