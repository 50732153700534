import React, {useEffect, useState} from "react"
import {t} from "i18next"
import {Button, Dialog, DialogSizeEnums, DialogVariant} from 'edah-component/dist'

/**
 * 刪除彈窗
 * @param content {String} 彈窗內容
 * @param show {Boolean} 是否顯示
 * @param onYesButtonClick {function} 確認刪除時的 callback
 * @param onNoButtonClick {function} 取消刪除時的 callback
 * @param onClosePopupButtonClick {function} 關閉彈窗時的 callback
 * @return {JSX.Element}
 */
export const DeletePopup = ({content, show, onYesButtonClick, onNoButtonClick, onClosePopupButtonClick}) => {
    // 是否顯示刪除彈窗
    const [isShow, setIsShow] = useState(show)

    /**
     * 取得 Content
     * @return {JSX.Element}
     */
    const getContent = () => <div>
        <p className="h-[110px] px-6 py-9 text-[18px] text-left border-b-[1px] border-[rgba(0,0,0,0.15)]">{content}</p>
        <div className="h-[60px] flex flex-row items-center justify-end">
            {/*是*/}
            <Button color="primary" variant="contained" size="medium" sx={{marginRight: '1rem'}}
                    onClick={onYesButtonClick}>{t('general.yes')}</Button>
            {/*否*/}
            <Button color="primary" variant="outlined" size="medium" sx={{marginRight: '1rem'}}
                    onClick={onNoButtonClick}>{t('general.no')}</Button>

        </div>
    </div>

    /**
     * show 改變時，更新 isShow
     */
    useEffect(() => setIsShow(show), [show])

    return (
        <Dialog
            open={isShow}
            title={t('general.reminder')}
            content={content}
            variant={DialogVariant.DELETE}
            paperStyleProps={{width: DialogSizeEnums.SM}}
            onClose={onClosePopupButtonClick}
            onDelete={onYesButtonClick}
            muiDialogContentProps={{sx: {padding: '4rem 1.5rem 4rem 1.5rem'}}}
        />
    )
    // isShow ?
    //     <BasePopup title={t('general.reminder')} width="559px" closePopupButtonOnClick={onClosePopupButtonClick}
    //                content={getContent()}/> : null
}
