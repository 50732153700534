import React, {useEffect, useState} from 'react'
import CustomTable from '../../components/ReferralSystem/CustomTable'
import {RURAL_HOSPITAL_FIEDLS} from '../../constants/referralSystem/maintainAreaHeader'
import useDataGrid from '../../hooks/useDataGrid'
import {
    ApiErrorStatusEnum,
    downloadFile,
    exportExcelAsBlob,
    FileExtensionsEnum,
    objectIsEmpty,
    stringIsEmpty,
    time2String,
} from 'edah_utils/dist'
import {DATE_FORMAT, DATETIME_FORMAT} from '../../constants/common'
import {addTranRural, delTranRural, editTranRural, queryTranRural, trancaseQueryBasicData,} from '../../api/v1/Rmis'
import useToast from '../../hooks/useToast'
import {queryByHospNo} from '../../api/v1/Menu'
import {debounce} from '../../components/ReferralSystem/utils'
import {
    AlertTypeEnum,
    TextField,
    IconEnum,
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    Dialog,
    DialogVariant, DialogSizeEnums
} from "edah-component/dist"
import {t} from 'i18next'

/**
 * layout component
 * @returns {JSX.Element}
 */
const Layout = ({children}) => {
    return (
        <div className="p-2 w-full overflow-auto">
            {children}
        </div>
    )
}

/**
 * 離島偏鄉維護
 * @return {JSX.Element}
 */
const RuralHospitalMaintain = () => {
    const {
        updateDataList,
        handleEdit,
        handleSave,
        handleCancel,
        addNewData,
        handleToggleItem,
        handleToggleAll,
        deleteRow,
        dataOnChange,
        dataList,
        isEditMode,
    } = useDataGrid()

    /**
     * 離島偏鄉名稱
     */
    const [ruralHospName, setRuralHospName] = useState('')

    /**
     * 院所代碼
     */
    const [ruralHospNo, setRuralHospNo] = useState('')

    /**
     * 刪除popup顯示狀態
     */
    const [showDeletePopup, setShowDeletePopup] = useState(false)

    /**
     * 欲刪除的資料
     */
    const [deletedRow, setDeletedRow] = useState(null)

    /**
     * 是否為新增資料
     */
    const [isAdd, setIsAdd] = useState(false)

    /**
     * 偏鄉離島註記下拉選單
     */
    const [ruralTypeList, setRuralTypeList] = useState([])

    const showToast = useToast()

    /**
     * 更新離島偏鄉醫院名稱
     * @param e {event}
     * @return {void}
     */
    const ruralHospNameOnChange = (e) => setRuralHospName(e.target.value)

    /**
     * 更新院所代碼
     * @param e {event}
     * @return {void}
     */
    const ruralHospNoOnChange = (e) => setRuralHospNo(e.target.value)

    /**
     * 查詢按鈕
     * @return {void}
     */
    const handleClickQuery = () => {
        fetchRuralHospital()
    }

    /**
     * 取得離島偏鄉維護資料
     * @return {void}
     */
    const fetchRuralHospital = () => {
        queryTranRural({
            hospName: ruralHospName,
            hospNo: ruralHospNo,
        }).then((res) => {
            // 成功
            if (res.err === ApiErrorStatusEnum.Success) {
                // 取得資料
                const list = res.data || []
                // 查無資料
                if (list.length === 0) {
                    showToast({
                        message: '查無資料',
                        type: AlertTypeEnum.Warning,
                    })
                }
                // 更新資料
                updateDataList(list)
            } else {
                // 失敗
                showToast({message: res.msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 刪除單筆資料
     * @return {void}
     */
    const handleConfirmDelete = () => {
        delTranRural({hospNo: deletedRow.hospNo}).then((res) => {
            // 成功
            if (res.err === ApiErrorStatusEnum.Success) {
                showToast({message: '刪除成功', type: AlertTypeEnum.Success})
                // 刪除資料
                deleteRow(deletedRow)
                // 關閉彈窗
                handleDeletePopupClose()
            } else {
                // 失敗
                showToast({message: '刪除失敗', type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 開啟刪除彈窗
     * @param tableRow {object} 該列資料
     * @return {void}
     */
    const handleDeletePopupOpen = (tableRow) => {
        // 開啟彈窗
        setShowDeletePopup(true)
        setDeletedRow(tableRow)
    }

    /**
     * 關閉刪除彈窗
     * @return {void}
     */
    const handleDeletePopupClose = () => {
        setShowDeletePopup(false)
    }

    /**
     * 匯出Excel
     * @return {void}
     */
    const handleExportExcel = () => {
        const selectedData = dataList.filter((item) => item.isSelected)
        // 未選取不能匯出 excel
        if (selectedData.length === 0) {
            return
        }

        // 取得欄位
        const keys = RURAL_HOSPITAL_FIEDLS.map((item) => item.key)
        // 取得欄位名稱
        const titles = RURAL_HOSPITAL_FIEDLS.map((item) => item.name)
        const dataArray = []
        // 將選取的資料轉換成陣列
        selectedData.forEach((item) => {
            const data = []
            keys.forEach((key) => {
                if (key === 'deactiveDate') {
                    // 轉換日期格式 YYYY-MM-DD
                    data.push(time2String(item[key], DATE_FORMAT))
                } else {
                    data.push(item[key])
                }
            })
            dataArray.push(data)
        })
        // 建立 Excel sheets
        const sheets = [
            {
                titles,
                data: dataArray,
            },
        ]
        // 匯出Excel
        downloadFile(
            exportExcelAsBlob(sheets),
            '離島偏鄉維護',
            FileExtensionsEnum.XLSX
        )
    }

    /**
     * 匯出Excel按鈕
     * @return {JSX.Element}
     */
    const exportExcelBtn = () => {
        return (
            <Button
                icon={IconEnum.Export}
                text={t('general.export')}
                color={ButtonColorEnum.Success}
                size={ButtonSizeEnum.Large}
                variant={ButtonVariantEnum.Text}
                onClick={handleExportExcel}
            />
        )
    }

    /**
     * 儲存table資料
     * @param tableRow {object} 該列資料
     * @return {void}
     */
    const handleSaveRuralHospital = (tableRow) => {
        // 院所代碼或偏鄉類別為空
        if (
            stringIsEmpty(tableRow.hospNo) ||
            stringIsEmpty(tableRow.ruralType)
        ) {
            return
        }
        // 新增或修改
        const targetApi = isAdd ? addTranRural : editTranRural
        
        targetApi({
            // 院所代碼
            hospNo: tableRow.hospNo,
            // 離島偏鄉註記
            ruralType: tableRow.ruralType,
            // 停用日期
            deactiveDate: time2String(tableRow.deactiveDate, DATETIME_FORMAT),
            // 排序
            sortBy: tableRow.sortBy,
            // 鎖定版本
            lockVersion: tableRow.lockVersion,
        }).then((res) => {
            // 成功
            if (res.err === ApiErrorStatusEnum.Success) {
                showToast({
                    message: '儲存成功',
                    type: AlertTypeEnum.Success,
                })
                // 更新資料
                handleSave(tableRow)
                // 重新查詢
                fetchRuralHospital()
            } else {
                // 失敗
                showToast({
                    message: `儲存失敗: ${res.msg}`,
                    type: AlertTypeEnum.Error,
                })
            }
        })
    }

    /**
     * 院所代碼輸入框失焦事件-以院所代碼取得院所名稱
     * @param e {event} 失焦事件
     * @param row {object} 該列資料
     * @param key {string} 欄位
     * @return {void}
     */
    const handleInputOnBlur = (e, row, key) => {
        // 檢查院所代碼是否為空
        if (key === 'hospNo' && !stringIsEmpty(row.hospNo)) {
            const value = e.target.value
            getHospNameByHospNo(value, row)
        }
    }

    /**
     * 以院所代碼取得院所名稱
     * @param value {string} 院所代碼
     * @param row {object} 該列資料
     * @return {void}
     */
    const getHospNameByHospNo = debounce((value, row) => {
        // 取得院所名稱
        queryByHospNo({hospNo: value}).then((res) => {
            // 成功
            if (res.err === ApiErrorStatusEnum.Success) {
                // 查無相關院所代碼
                if (objectIsEmpty(res.data)) {
                    showToast({
                        message: '查無相關院所代碼',
                        type: AlertTypeEnum.Error,
                    })
                    // 清空院所名稱與院所代碼
                    dataOnChange(row, 'hospName', '')
                    dataOnChange(row, 'hospNo', '')
                } else {
                    // 更新院所名稱與院所代碼
                    dataOnChange(row, 'hospName', res.data.hospName || '')
                    dataOnChange(row, 'hospNo', value)
                }
            } else {
                // 失敗
                showToast({
                    message: res.msg,
                    type: AlertTypeEnum.Error,
                })
                // 清空院所名稱與院所代碼
                dataOnChange(row, 'hospName', '')
                dataOnChange(row, 'hospNo', '')
            }
        })
    })

    /**
     * 編輯該列資料
     * @param row {object} 該列資料
     * @return {void}
     */
    const editRuralHospital = (row) => {
        handleEdit({
            ...row,
            hospName: row.hospName || '',
            hospNo: row.hospNo || '',
            ruralType: row.ruralType || '',
            ruralTypeName: row.ruralTypeName || '',
            deactiveDate: time2String(row.deactiveDate, DATE_FORMAT) || '',
            sortBy: row.sortBy,
        })
    }

    /**
     * 在table新增一列資料
     * @return {void}
     */
    const addNewRuralHospital = () => {
        const newHosp = {
            lockVersion: 0,
            hospName: '',
            hospNo: '',
            deactiveDate: '',
            ruralTypeName: '',
            ruralType: ruralTypeList[1].ruralType,
            sortBy: '',
        }
        addNewData(newHosp)
        setIsAdd(true)
    }

    /**
     * 取得轉診基礎資料
     * @return {void}
     */
    const getTrancaseQueryBasicData = () => {
        trancaseQueryBasicData().then((response) => {
            const {data, err} = response
            if (err === ApiErrorStatusEnum.Success) {
                setRuralTypeList(data.ruralTypeList)
            } else {
                showToast({message: response.msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 渲染欄位
     * @return {void}
     */
    const renderFields = () => {
        const fields = RURAL_HOSPITAL_FIEDLS
        // 移除[全部]選項
        fields[2].options = ruralTypeList.filter(
            (item) => item.ruralType !== 'ALL'
        )
        // 設定偏鄉類別選項所對應的名稱與值，以供下拉選單使用
        fields[2].optionNameAndValue = ['ruralTypeName', 'ruralType']
        return fields
    }

    useEffect(() => {
        getTrancaseQueryBasicData()
    }, [])

    return (
        <Layout>
            {/*刪除確認popup*/}
            <Dialog
                muiDialogContentProps={{sx: {height: '110px', display: 'flex', alignItems: 'center'}}}
                open={showDeletePopup}
                title={"提醒"}
                variant={DialogVariant.DELETE}
                paperStyleProps={{width: DialogSizeEnums.SM}}
                onClose={handleDeletePopupClose}
                onDelete={handleConfirmDelete}
                content={"是否確定刪除？"}
            />
            <div className="flex justify-start items-center p-2  space-x-2">
                {/*搜尋列*/}
                {/*院所代碼*/}
                <TextField
                    label="院所代碼"
                    value={ruralHospNo}
                    onChange={ruralHospNoOnChange}
                />
                {/*離島偏鄉醫院名稱*/}
                <TextField
                    label="離島偏鄉醫院名稱"
                    value={ruralHospName}
                    onChange={ruralHospNameOnChange}
                />
                {/* 查詢按鈕 */}
                <Button
                    sx={{fontWeight: 'bold', height: '2.5rem'}}
                    color={ButtonColorEnum.Primary}
                    text={t('general.query')}
                    variant={ButtonVariantEnum.Contained}
                    size={ButtonSizeEnum.Medium}
                    onClick={handleClickQuery}
                />
            </div>
            <CustomTable
                isSelectable={true}
                fields={renderFields()}
                dataList={dataList}
                slotButton={exportExcelBtn()}
                toggleItem={handleToggleItem}
                toggleAll={handleToggleAll}
                isCrud={true}
                isEditMode={isEditMode}
                handleEdit={editRuralHospital}
                handleSave={handleSaveRuralHospital}
                handleCancel={handleCancel}
                addNewData={addNewRuralHospital}
                deleteRow={handleDeletePopupOpen}
                handleInputOnBlur={handleInputOnBlur}
            />
        </Layout>
    )
}

export default RuralHospitalMaintain
