import {t} from "i18next"
import {Card, AdvancedDataGrid} from 'edah-component/dist'

/**
 * 病人排檢資訊
 * @param patientData {Array} 病人資訊
 * @param showData {Boolean} 是否顯示資料
 */
const PatientInfo = ({patientData, showData}) => {
    const columns = [
        {field: 'campus', headerName: t('Regs.general.campus'), flex: 1},
        {field: 'date', headerName: '排檢日期', flex: 1},
        {field: 'doctor', headerName: t('general.doctor'), flex: 1},
        {field: 'department', headerName: t('general.department'), flex: 1},
        {field: 'object', headerName: '排檢項目', flex: 1},
    ]
    return (
        <Card title="病人排檢資訊">
            <AdvancedDataGrid
                rows={showData ? patientData : []}
                columns={columns}
                checkboxSelection={false}
                disableRowSelectionOnClick
                disableColumnMenu={true}
                height={'600px'}
                getRowId={(row) => row.regId}
                getRowHeight={(params) => 56}
            />
        </Card>
    )
}

export default PatientInfo
