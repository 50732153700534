import {arrayIsEmpty, time2String} from "edah_utils/dist"
import React, {useState} from "react";
import {t} from "i18next";

/**
 * 復健醫療
 * @param data {Array} 資料
 * @return {JSX.Element}
 */
export const RehabilitationMedicine = ({data}) => {
    return (
        <div className="w-full">
            {/*列表清單區塊*/}
            {/*列表清單*/}
            <ul className="tableList icRMList">
                {/*表單項目標題*/}
                <li className="title">
                    <div>門住診別</div>
                    <div>主診斷碼</div>
                    <div>主診斷名稱</div>
                    <div>治療類別</div>
                    <div>強度</div>
                    <div>醫令數量</div>
                    <div>就醫日期<br/>(住院日期)</div>
                    <div>治療結束日期</div>
                    <div>執行時間-起</div>
                    <div>執行時間-迄</div>
                    <div>醫事機構代碼</div>
                    <div>醫事機構簡稱</div>
                    <div>診療部位</div>
                </li>

                {
                    !arrayIsEmpty(data) && data.map((item, index) =>
                        <li key={index}>
                            {/*門住診別*/}
                            <div>{t(`ICCard.InpOpdEnum.${item.inpOpd}`)}</div>
                            {/*主診斷碼*/}
                            <div>{item.treatmentCode}</div>
                            {/*主診斷名稱*/}
                            <div>{item.treatmentName}</div>
                            {/*治療類別*/}
                            <div>{item.zrTypeName}</div>
                            {/*強度*/}
                            <div>{item.zrIntensity}</div>
                            {/*醫令數量*/}
                            <div>{item.tqty}</div>
                            {/*就醫日期(住院日期)*/}
                            <div>{time2String(item.encounterStartDate, 'YYYY-MM-DD')}</div>
                            {/*治療結束日期*/}
                            <div>{time2String(item.encounterEndDate, 'YYYY-MM-DD')}</div>
                            {/*執行時間-起*/}
                            <div>{time2String(item.execStartDate, 'YYYY-MM-DD')}</div>
                            {/*執行時間-迄*/}
                            <div>{time2String(item.execEndDate, 'YYYY-MM-DD')}</div>
                            {/*醫事機構代碼*/}
                            <div>{item.hospNo}</div>
                            {/*醫事機構簡稱*/}
                            <div>{item.hospName}</div>
                            {/*診療部位*/}
                            <div>{t(`ICCard.orderPos.${item.orderPos}`)}</div>
                        </li>
                    )}
            </ul>
        </div>
    )
}