import React, { useState } from 'react'

/**
 * Button 組件
 * @returns {React.Component} - Button 組件
 * @param {props} - 點擊事件，tailwindcss class，按鈕顯示文字
 */
function Button({ onClickFn, classNames, text=null, icon=null, disable=false}) {
    //設定按鈕狀態
    const [ status, setStatus ] = useState('Disable')
    //點擊按鈕事件
    const handleClick = () => {
        onClickFn()
        setStatus(!status)
    }
  return (
      <button className={classNames} disabled={disable} onClick={handleClick}>
        {icon ? <img src={icon} alt="icon" /> : null}
        {text}
    </button>
  )
}

export default Button