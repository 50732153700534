import React, {useState} from 'react'
import CustomTable from '../../components/ReferralSystem/CustomTable'
import {Pagination} from '../../components/Pagination/Pagination'
import {NO_REFERRAL_REPORT_FIELDS} from '../../constants/referralSystem/noReferralReport'
import {queryNotTranBackReasonBySearch} from '../../api/v1/Rmis'
import {
    ApiErrorStatusEnum,
    validDateFormat,
    time2String,
    downloadFile,
    exportExcelAsBlob,
    FileExtensionsEnum,
} from 'edah_utils/dist'
import useToast from '../../hooks/useToast'
import {DATE_FORMAT, DATETIME_FORMAT} from '../../constants/common'
import {
    AlertTypeEnum,
    SizeEnum,
    DateRangePicker,
    TextField,
    IconEnum,
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum
} from "edah-component/dist"
import dayjs from "dayjs"
import {t} from "i18next"

/**
 * 不轉診原因報表清單
 * @return {JSX.Element}
 */
const NoReferralReport = () => {
    // 資料列表
    const [dataList, setDataList] = useState([])
    // 分頁
    const [filter, setFilter] = useState({
        totalPageSize: 1,
        totalItemSize: 0,
        pageNum: 1,
        pageSize: 10,
    })

    // 查詢參數
    const [searchParams, setSearchParams] = useState({
        patientId: '',
        encounterStartDate: '',
        encounterEndDate: '',
    })

    // 輸入框檢驗狀態
    const [inputState, setInputState] = useState({
        encounterStartDate: null,
        encounterEndDate: null,
    })

    // Toast
    const showToast = useToast()

    /**
     * 插入Table的匯出按鈕
     * @return {JSX.Element} 匯出按鈕
     */
    const exportTextButton = () => {
        /**
         * 匯出Excel
         */
        const handleExportExcel = () => {
            // 取得欄位 api key
            const keys = NO_REFERRAL_REPORT_FIELDS.map((item) => item.key)
            // 取得欄位名稱
            const titles = NO_REFERRAL_REPORT_FIELDS.map((item) => item.name)
            // 資料陣列
            const dataArray = []
            dataList.forEach((item) => {
                const data = []
                keys.forEach((key) => {
                    // 日期格式轉換成 YYYY-MM-DD
                    if (key === 'encounterDate') {
                        data.push(time2String(item[key], DATE_FORMAT))
                    } else {
                        data.push(item[key])
                    }
                })
                dataArray.push(data)
            })
            // excel sheet 陣列
            const sheets = [
                {
                    titles,
                    data: dataArray,
                },
            ]
            // 匯出Excel
            downloadFile(
                exportExcelAsBlob(sheets),
                '不轉診原因報表',
                FileExtensionsEnum.XLSX
            )
        }
        return (
            <Button
                icon={IconEnum.Export}
                text={t('general.export')}
                color={ButtonColorEnum.Success}
                size={ButtonSizeEnum.Large}
                onClick={handleExportExcel}
                variant={ButtonVariantEnum.Text}
            />
        )
    }

    /**
     * 取得不轉診原因清單
     * @param pageNum {number} 頁碼
     * @param pageSize {number} 每頁筆數
     */
    const getNoTranBackReasonList = (pageNum, pageSize) => {
        // 檢驗輸入框
        const encounterStartDateIsValid = validDateFormat(
            searchParams.encounterStartDate
        )
        const encounterEndDateIsValid = validDateFormat(
            searchParams.encounterEndDate
        )
        // 更新輸入框檢驗狀態
        setInputState({
            encounterStartDate: encounterStartDateIsValid,
            encounterEndDate: encounterEndDateIsValid,
        })
        // 檢驗輸入框
        if (!encounterStartDateIsValid || !encounterEndDateIsValid) {
            return
        }
        // 查詢
        queryNotTranBackReasonBySearch({
            ...searchParams,
            encounterStartDate: time2String(
                searchParams.encounterStartDate,
                DATETIME_FORMAT
            ),
            encounterEndDate: time2String(
                searchParams.encounterEndDate,
                DATETIME_FORMAT
            ),
            pageNum,
            pageSize,
        }).then((res) => {
            // 成功
            if (res.err === ApiErrorStatusEnum.Success) {
                // 無資料
                if (res.data.dataList?.length === 0) {
                    showToast({
                        message: '查無資料',
                        type: AlertTypeEnum.Warning,
                    })
                }
                // 資料列表
                const list = res.data.dataList || []
                // 資料列表
                setDataList(list)
                // 更新分頁
                setFilter({
                    ...filter,
                    totalItemSize: res.data.totalItemSize,
                    totalPageSize: res.data.totalPageSize,
                    pageNum,
                    pageSize,
                })
            } else {
                showToast({message: res.msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 查詢
     * @return {void}
     */
    const handleQueryOnClick = () => {
        getNoTranBackReasonList(1, filter.pageSize)
    }

    /**
     * 更新查詢輸入框
     * @param e {object} 事件
     * @param field {string} input欄位名稱
     * @return {void}
     */
    const updateSearchParams = (e, field) => {
        const value = e.target.value
        setSearchParams({
            ...searchParams,
            [field]: value,
        })
    }

    /**
     * 就醫日期範圍變動事件
     * @param newDates {Array<Dayjs | null>} 日期範圍 [開始日期, 結束日期]
     * @return {void}
     */
    const handleDateRangeChange = (newDates) => {
        const [newStartDate, newEndDate] = newDates;
        setSearchParams({
            ...searchParams,
            encounterStartDate: newStartDate ? newStartDate.format('YYYY-MM-DD') : '',
            encounterEndDate: newEndDate ? newEndDate.format('YYYY-MM-DD') : '',
        })
    }

    /**
     * 點選頁碼
     * @param page {string} 頁碼
     * @return {void}
     */
    const onPageOnChange = (page) => {
        const currentPage = Number(page)
        getNoTranBackReasonList(currentPage, filter.pageSize)
    }

    /**
     * 每頁筆數
     * @param event {object} 事件
     * @return {void}
     */
    const onPageSizeChange = (event) => {
        const pageSize = Number(event.target.value)
        getNoTranBackReasonList(1, pageSize)
    }

    /**
     * 上一頁
     * @return {void}
     */
    const onPrevPageOnClick = () => {
        const prevPage = filter.pageNum - 1
        const page = prevPage < 1 ? filter.totalPageSize : prevPage
        getNoTranBackReasonList(page, filter.pageSize)
    }

    /**
     * 下一頁
     * @return {void}
     */
    const onNextPageOnClick = () => {
        const nextPage = filter.pageNum  + 1
        const firstPage = 1
        const page = nextPage > filter.totalPageSize ? firstPage : nextPage
        getNoTranBackReasonList(page, filter.pageSize)
    }

    return (
        <div className="p-2 w-full">
            <div className="flex justify-start items-center p-2 space-x-2">
                {/* 病歷號 */}
                <TextField
                    label={t('general.patientNo')}
                    value={searchParams.patientId}
                    onChange={(e) => updateSearchParams(e, 'patientId')}
                />
                {/* 就醫日期起訖 */}
                <DateRangePicker
                    required
                    localeText={{
                        start: '就醫開始日期',
                        end: '就醫結束日期',
                    }}
                    value={[
                        searchParams.encounterStartDate ? dayjs(searchParams.encounterStartDate) : null,
                        searchParams.encounterEndDate ? dayjs(searchParams.encounterEndDate) : null,
                    ]}
                    onChange={handleDateRangeChange}
                />
                {/* 查詢按鈕 */}
                <Button
                    sx={{fontWeight: 'bold', height: '2.5rem'}}
                    color={ButtonColorEnum.Primary}
                    text={t('general.query')}
                    variant={ButtonVariantEnum.Contained}
                    size={ButtonSizeEnum.Medium}
                    onClick={handleQueryOnClick}
                />
            </div>
            {/* 表格 */}
            <CustomTable
                fields={NO_REFERRAL_REPORT_FIELDS}
                slotButton={exportTextButton()}
                dataList={dataList}
            />
            {/* 分頁 */}
            <div className="flex justify-end">
                <Pagination
                    pageSize={filter.pageSize}
                    totalSize={filter.totalItemSize}
                    currentPage={filter.pageNum}
                    totalPageSize={filter.totalPageSize}
                    onPageOnChange={onPageOnChange}
                    onPageSizeChange={onPageSizeChange}
                    onPrevPageOnClick={onPrevPageOnClick}
                    onNextPageOnClick={onNextPageOnClick}
                />
            </div>
        </div>
    )
}

export default NoReferralReport
