import React, { useState } from 'react';

/**
 * 雙環圖表
 * @param onToggle {Function} 百分比
 * @param initialState {Boolean} 控制雙環狀態定應變色
 */
const EggSwitch = ({ onToggle, initialState}) => {
    const [isOn, setIsOn] = useState(initialState);

    const toggleSwitch = () => {
        const newState = !isOn;
        setIsOn(newState);
        onToggle(newState); // 將新的狀態值傳遞給父組件
    };

    return (
        <div
            onClick={()=>{}}
            className={`disabled w-16 h-8 flex items-center rounded-full p-1 cursor-pointer ${isOn ? 'bg-[#2B6CB0]' : 'bg-gray-300'}`}
            style={{ transition: 'background-color 0.3s' }}
        >
            <div
                className={`bg-white w-6 h-6 rounded-full shadow-md transform ${isOn ? 'translate-x-8' : 'translate-x-0'}`}
                style={{ transition: 'transform 0.3s' }}
            />
        </div>
    );
};

export default EggSwitch;