import {v1LocalServiceAPI} from "../Http"
import {HttpMethodEnum} from "edah_utils/dist"

/**
 * 讀取基本資料_測試(不用卡)
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const icHisGetBasicData_test = (params) => v1LocalServiceAPI.request({
    url: '/ic/hisGetBasicData_test',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 讀取基本資料
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const icHisGetRegisterBasic = (params) => v1LocalServiceAPI.request({
    url: '/ic/hisGetRegisterBasic',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 預防保健掛號作業
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const icHisGetRegisterPrevent = (params) => v1LocalServiceAPI.request({
    url: '/ic/hisGetRegisterPrevent',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 孕婦產前檢查掛號作業
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const icHisGetRegisterPregnant = (params) => v1LocalServiceAPI.request({
    url: '/ic/hisGetRegisterPregnant',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 讀取就醫資料不需HPC卡的部分
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const icHisGetTreatmentNoNeedHPC = (params) => v1LocalServiceAPI.request({
    url: '/ic/hisGetTreatmentNoNeedHPC',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 讀取就醫資料需HPC卡的部分
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const icHisGetTreatmentNeedHPC = (params) => v1LocalServiceAPI.request({
    url: '/ic/hisGetTreatmentNeedHPC',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 預防接種資料
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const icHisGetInoculateData = (params) => v1LocalServiceAPI.request({
    url: '/ic/hisGetInoculateData',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 讀取同意器官捐贈及安寧緩和醫療註記
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const icHisGetOrganDonate = (params) => v1LocalServiceAPI.request({
    url: '/ic/hisGetOrganDonate',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 新生兒註記寫入作業
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const icHisWriteNewBorn = (data) => v1LocalServiceAPI.request({
    url: '/ic/hisWriteNewBorn',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 過敏藥物寫入作業
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const icHisWriteAllergicMedicines = (data) => v1LocalServiceAPI.request({
    url: '/ic/hisWriteAllergicMedicines',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 同意器官捐贈及安寧緩和醫 療註記寫入作業
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const icHisWriteOrganDonate = (data) => v1LocalServiceAPI.request({
    url: '/ic/hisWriteOrganDonate',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 預防保健資料寫入作業
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const icHisWriteHealthInsurance = (data) => v1LocalServiceAPI.request({
    url: '/ic/hisWriteHealthInsurance',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 寫入產前檢查資料
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const icHisWritePredeliveryCheckup = (data) => v1LocalServiceAPI.request({
    url: '/ic/hisWritePredeliveryCheckup',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 讀取就醫資料-門診處方箋
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const icHisReadPrescriptMain = (params) => v1LocalServiceAPI.request({
    url: '/ic/hisReadPrescriptMain',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 讀取就醫資料-長期處方箋
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const icHisReadPrescriptLongTerm = (params) => v1LocalServiceAPI.request({
    url: '/ic/hisReadPrescriptLongTerm',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 讀取就醫資料-重要醫令
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const icHisReadPrescriptHVE = (params) => v1LocalServiceAPI.request({
    url: '/ic/hisReadPrescriptHVE',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 讀取就醫資料-過敏藥物
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const icHisReadPrescriptAllergic = (params) => v1LocalServiceAPI.request({
    url: '/ic/hisReadPrescriptAllergic',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 多筆處方籤寫入作業-回傳簽章
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const icHisWriteMultiPrescriptSign = (data) => v1LocalServiceAPI.request({
    url: '/ic/hisWriteMultiPrescriptSign',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 取得就醫序號新版-就醫識別碼
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const icHisGetSeqNumber256N1 = (params) => v1LocalServiceAPI.request({
    url: '/ic/hisGetSeqNumber256N1',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 異常時取得就醫識別碼
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const icHisGetTreatNumNoICCard = (params) => v1LocalServiceAPI.request({
    url: '/ic/hisGetTreatNumNoICCard',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 掛號或報到時讀取基本資料
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const icHisGetHisRegisterBasic = (params) => v1LocalServiceAPI.request({
    url: '/ic/hisGetHisRegisterBasic',
    method: HttpMethodEnum.GET,
    params
})

/**
 * IC卡資料上傳
 * @param data {Object} params
 * @return {AxiosPromise}
 */
const icUploadData = (data) => v1LocalServiceAPI.request({
    url: '/ic/uploadData',
    method: HttpMethodEnum.PUT,
    data
})

/**
 * 資料轉換
 * @param data {Object} params
 * @return {AxiosPromise}
 */
const icToXmlStr = (data) => v1LocalServiceAPI.request({
    url: '/ic/toXmlStr',
    method: HttpMethodEnum.POST,
    data
})

export {
    icHisGetBasicData_test,
    icHisGetRegisterBasic,
    icHisGetRegisterPrevent,
    icHisGetRegisterPregnant,
    icHisGetTreatmentNoNeedHPC,
    icHisGetTreatmentNeedHPC,
    icHisGetInoculateData,
    icHisGetOrganDonate,
    icHisWriteNewBorn,
    icHisWriteAllergicMedicines,
    icHisWriteMultiPrescriptSign,
    icHisWriteOrganDonate,
    icHisWriteHealthInsurance,
    icHisWritePredeliveryCheckup,
    icHisReadPrescriptMain,
    icHisReadPrescriptLongTerm,
    icHisReadPrescriptHVE,
    icHisReadPrescriptAllergic,
    icHisGetSeqNumber256N1,
    icHisGetTreatNumNoICCard,
    icHisGetHisRegisterBasic,
    icUploadData,
    icToXmlStr
}
