import React from 'react';
import '../../components/ICCard/VHICard.scss'
import {ReactComponent as QRCode} from "../../assets/images/ICCard/qr_code_scanner.svg";
import {Button, ButtonColorEnum, Dialog, DialogVariant, Field, GrayColorEnum, TextField} from "edah-component/dist";

/**
 *  虛擬健保卡
 *  @param showVHICard {boolean}  - 是否顯示虛擬健保卡
 *  @param closeVHICard {function}  - 關閉虛擬健保卡
 *  @returns {JSX.Element}
 */
const VHICard = ({showVHICard, closeVHICard}) => {

    const getContent = () => {
        return (
            <>
                <div className={`w-[85%] py-6 px-4 border-[2px] border-${GrayColorEnum.Gray100} rounded m-auto`}>
                    <h3 className="text-[20px] mb-6">使用遠端請求</h3>
                    <p className="mb-4 text-[18px] text-left">1. 先輸入病人「身分證號」，並點選「產生請求碼」</p>
                    <Field childrenSx={{display: "flex", alignItems: 'center'}} labelWidth={80} label="身分證號">
                        <TextField sx={{mr: 1}}/>
                        <Button color={ButtonColorEnum.Secondary} text='產生請求碼'/>
                    </Field>
                    <p className="text-[18px] text-left">2. 請民眾登入健保快易通App</p>
                    <p className="text-[18px] text-left">3. 點選虛擬健保卡，點選『同意』，完成後通知您</p>
                    <p className="mb-4 text-[18px] text-left">4. 點選下方按鈕</p>
                    <div className="flex justify-center">
                        <Button text='確認並取得QRcode'/>
                    </div>
                </div>
                <p className="errorCode text-[18px] text-[#E53E3E]">101 : QRcode超過有效期限</p>
            </>
        )
    }
    return (
        <Dialog maxWidth={'sm'} open={showVHICard} title="虛擬健保卡" content={getContent()}
                variant={DialogVariant.CUSTOM} onClose={closeVHICard}/>
    )

}

export default VHICard;