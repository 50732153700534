import {arrayIsEmpty, ICCMMaintainPageActiveTabEnum, time2String} from "edah_utils/dist";
import React, { useState } from "react";
import { t } from "i18next";

/**
 * 檢驗(查)報告
 * @param data {Array} 資料
 * @return {JSX.Element}
 */
export const InspectionReport = ({ data }) => {
    const [notesContent, setNotesContent] = useState(null);

    const handleReportClick = (notesContent) => {
        setNotesContent(notesContent);
    };

    return (
        <div className="w-full flex flex-row">
            <div className="w-2/3">
                {/*列表清單區塊*/}
                {/*列表清單*/}
                <ul className="tableList icIR2List">
                    {/*表單項目標題*/}
                    <li className="title">
                        <div>門住診別</div>
                        <div>類別</div>
                        <div>檢查檢驗類別</div>
                        <div>醫令代碼</div>
                        <div>檢驗(查)報告</div>
                        <div>檢體</div>
                        <div>開立醫令日期</div>
                        <div>採檢/實際檢查日期</div>
                        <div>報告日期</div>
                        <div>醫事機構代碼</div>
                        <div>醫事機構簡稱</div>
                        <div>TAF認證</div>
                        <div>影像</div>
                    </li>

                    {!arrayIsEmpty(data) &&
                        data.map((item, index) => (
                            <li key={index} onClick={() => handleReportClick(item.notesContent)}>
                                {/*門住診別*/}
                                <div>{t(`ICCard.InpOpdEnum.${item.inpOpd}`)}</div>
                                {/*類別*/}
                                <div>{item.cloudencType}</div>
                                {/*檢查檢驗類別*/}
                                <div>{item.heDivNo}</div>
                                {/*醫令代碼*/}
                                <div>{item.orderHeCode}</div>
                                {/*檢驗(查)報告*/}
                                <div className="overflow-ellipsis overflow-hidden whitespace-nowrap">{item.notesContent}</div>
                                {/*檢體*/}
                                <div>{item.specimen}</div>
                                {/*開立醫令日期*/}
                                <div>{time2String(item.orderDate, 'YYYY-MM-DD')}</div>
                                {/*採檢/實際檢查日期*/}
                                <div>{time2String(item.examDate, 'YYYY-MM-DD')}</div>
                                {/*報告日期*/}
                                <div>{time2String(item.reportDate, 'YYYY-MM-DD')}</div>
                                {/*醫事機構代碼*/}
                                <div>{item.hospNo}</div>
                                {/*醫事機構簡稱*/}
                                <div>{item.hospName}</div>
                                {/*TAF認證*/}
                                <div>{item.tafFlag}</div>
                                {/*影像*/}
                                <div>{item.imageDataFlag}</div>
                            </li>
                        ))}
                </ul>
            </div>
            <div className="ml-2 p-2 w-1/3 border text-left rounded-[12px]">
                {/*檢驗(查)報告*/}
                <button className=" h-[42px] px-4 py-2 text-[16px] border-b-[2px] border-[#2B6CB0] text-[#2B6CB0]">
                    檢驗(查)報告
                </button>
                <div className="text-left overflow-ellipsis whitespace-wrap p-2 mt-2">{notesContent}</div>
            </div>
        </div>
    );
};
