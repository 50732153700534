const OptionOne = [
    {
        label: '0',
        value: 0
    },
    {
        label: '1',
        value: 1
    },
    {
        label: '2',
        value: 2
    },
    {
        label: '3',
        value: 3
    },
    {
        label: '4',
        value: 4
    }
]
const Header = [
    {
        label: '不會',
    },
    {
        label: '輕微',
    },
    {
        label: '中等程度',
    },
    {
        label: '嚴重',
    },
    {
        label: '非常嚴重',
    }
]

export {
    OptionOne,
    Header
}