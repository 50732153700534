import {useDispatch} from "react-redux"
import {showToastMessage} from "../redux/Slice/ToastMessageSlice"
import {AlertAlertOrigin, AlertHorizontalEnum, AlertTypeEnum, AlertVerticalEnum} from "edah-component/dist"

/**
 * useToast Hook
 * @return {Function} showToast
 */
const useToast = () => {
    /**
     * dispatch
     */
    const dispatch = useDispatch()
    /**
     * Show Toast
     * @param message {String} message content
     * @param type {AlertTypeEnum} ToastTypeEnum
     * @param duration {Number} duration of toast message
     * @param origin {AlertAlertOrigin} show or hide toast message
     * @param open {Boolean} show or hide toast message
     * @param showCloseButton {Boolean} show or hide close button
     */
    return ({
                message,
                type,
                duration = 3000,
                origin = {
                    vertical: AlertVerticalEnum.Top,
                    horizontal: AlertHorizontalEnum.Center
                },
                open = true,
                showCloseButton = false,
            }) => {
        dispatch(showToastMessage({message, type, duration, origin, open, showCloseButton}))
        if (duration && duration > 0 && open) {
            setTimeout(() => dispatch(showToastMessage({
                message,
                type,
                duration,
                origin,
                open: false,
                showCloseButton
            })), duration)
        }
    }
}

export default useToast
