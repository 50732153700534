import React, { useState } from 'react'
//引入按鈕樣式
import { ButtonEnum } from './ButtonEnum'
//引入Button組件
import Button from './Button'

/**
 * DefaultButton 組件
 * @returns {React.Component} - DefaultButton 組件
 * @param {type, onClickFn, text, icon}
 * type: {string} - 按鈕樣式
 * onClickFn: {function} - 按鈕點擊事件
 * text: {string} - 按鈕文字
 * icon: {React.Component} - 按鈕圖示 (optional)
 */
function DefaultButton({ type, onClickFn, text, icon=null , disable=false}) {
    //取得DefaultButton的css class
    const classNames = ButtonEnum[type]
  return (
    <Button classNames={classNames} text={text} onClickFn={onClickFn} icon={icon} disable={disable}/>
  )
}

function LargeDefaultButton({ type, onClickFn, text, icon=null, disable=false }) {
    const classNames = type
    return (
        <DefaultButton type={`${classNames} px-16 py-2`} text={text} onClickFn={onClickFn} icon={icon} disable={disable}/>
    )
}

export {DefaultButton, LargeDefaultButton}
