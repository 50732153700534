//Import css &icons
import {ReactComponent as RefreshIcon} from "../../assets/images/icons/refresh.svg";
import {ReactComponent as Avatar} from "../../assets/images/ICCard/Avatar.svg";
import '../../components/ICCard/PatientInfo.scss'
import '../../components/TableList/tableList.scss'
import {Button, ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum, Card, IconEnum} from "edah-component/dist"

//Import function
import React, {useState} from "react";
import {t} from "i18next";
import PatientInfo from "../../components/ICCard/PatientInfo";
import VHICard from '../../components/ICCard/VirtualHealthInsuranceCard';
import {icHisGetHisRegisterBasic} from "../../api/v1/LocalService"
import {ApiErrorStatusEnum, stringIsEmpty} from "edah_utils/dist"
import {SwitchContainer} from "../../components/ICCard/InfoPage/SwitchContainer"
import VirtualHealthInsuranceCardPopup from "../../components/Appointment/Popup/VirtualHealthInsuranceCardPopup";

/**
 * 病人IC卡資料查詢頁面
 * @return {JSX.Element}
 */
const InfoPage = () => {
    // 控制基本資料展開
    const [isRead, setIsRead] = useState(false)

    // 用來控制 VHICard 是否顯示的狀態
    const [showVHICard, setShowVHICard] = useState(false);

    // 資料
    const [data, setData] = useState({})

    // 讀取狀態
    const [status, setStatus] = useState('')

    // 讀取時間
    const [duration, setDuration] = useState(0)

    /**
     * 打開 VHICard
     * @return {void}
     */
    const openVHICard = () => {
        setShowVHICard(true);
    };

    /**
     * 關閉 VHICard
     * @return {void}
     */
    const closeVHICard = () => {
        setShowVHICard(false);
    };

    /**
     * TODO: 跟掛號同邏輯
     */
    const successToGetIcCard = () => {

    }

    /**
     * 讀取IC卡基本資料
     * @return {void}
     */
    const readICCard = () => {
        //讀取IC卡基本資料
        icHisGetHisRegisterBasic({}).then(res => {
            //運行時間存在
            if (res.runTime) {
                //設定讀取時間, 以秒為單位
                setDuration(res.runTime / 1000)
            }

            //是否讀取成功
            const apiStatus = res.err === ApiErrorStatusEnum.Success
            //設定狀態
            setStatus(apiStatus ? t('general.success') : t('general.failed'))
            //設定資料
            setData(apiStatus ? {...res.data, isPhysical: true} : {})
            //設定是否讀取
            setIsRead(apiStatus)
        })
    }

    return (
        <div className="w-full bg-[#FAFAFA] mb-3">
            {/*IC卡讀取*/}
            <div className="flex flex-row w-full h-[68px] pl-4">
                <div className="w-full h-full flex flex-row items-center justify-start">
                    <h2 className=" flex flex-row items-center justify-center text-[#2B6CB0] mr-4">
                        <Avatar className={"mr-2"}/> {t('general.patientInfo')}
                    </h2>
                    <div className='flex items-center justify-center h-11 mr-4'>
                        <p className='text-[16px] text-[#3F3F46]'>{t('ICCard.cardReading', {status: stringIsEmpty(status) ? '--' : status})}</p>
                    </div>
                    <div className='flex items-center justify-center h-11 mr-4'>
                        <p className='text-[16px] text-[#3F3F46]'>{t('ICCard.readingTime', {duration: duration === 0 ? '--' : duration})}</p>
                    </div>
                    <Button sx={{marginRight: '1.5rem'}}
                            color={ButtonColorEnum.Secondary}
                            size={ButtonSizeEnum.Medium}
                            variant={ButtonVariantEnum.Outlined}
                            icon={IconEnum.Refresh}
                            onClick={readICCard}>
                        {t('general.read')}
                    </Button>
                </div>
                <div className="w-full h-full flex flex-row items-center justify-start gap-6">
                    <Button color={ButtonColorEnum.Secondary} size={ButtonSizeEnum.Medium}
                            variant={ButtonVariantEnum.Outlined} text={t('ICCard.readICCard')}
                            onClick={readICCard}/>
                    <Button color={ButtonColorEnum.Secondary} size={ButtonSizeEnum.Medium}
                            variant={ButtonVariantEnum.Outlined} text={t('ICCard.virtualHealthInsuranceCard')}
                            onClick={openVHICard}/>
                    <Button color={ButtonColorEnum.Secondary} size={ButtonSizeEnum.Medium}
                            variant={ButtonVariantEnum.Outlined} text={t('ICCard.icCardUpdate')}/>
                </div>
            </div>
            <div className="p-3 space-y-3">
                {/*病人資料*/}
                <PatientInfo isRead={isRead} data={data}/>

                {/*虛擬健保卡*/}
                <VirtualHealthInsuranceCardPopup show={showVHICard} closePopupButtonOnClick={closeVHICard} successToGetIcCard={successToGetIcCard}/>
                {/*<VHICard showVHICard={showVHICard} closeVHICard={closeVHICard}/>*/}
                <SwitchContainer icCardData={data}/>
            </div>
        </div>
    );
}
export default InfoPage;
