import {arrayIsEmpty, time2String} from "edah_utils/dist"
import React, {useState} from "react";
import { t } from "i18next";

/**
 * 檢查檢驗
 * @param data {Array<Object>}
 * @return {JSX.Element}
 */
export const InspectionAndInspection = ({data}) => {
    return (
        <div className="w-full">
            {/*列表清單區塊*/}
            {/*列表清單*/}
            <ul className="tableList icIAIList">
                {/*表單項目標題*/}
                <li className="title">
                    <div>門住診別</div>
                    <div>主診斷碼</div>
                    <div>主診斷名稱</div>
                    <div>醫令代碼</div>
                    <div>醫令名稱</div>
                    <div>診療部位</div>
                    <div>醫令總量</div>
                    <div>執行時間-起<br/>(取件日期)</div>
                    <div>執行時間-迄<br/>(檢驗報告日期)</div>
                    <div>醫事機構代碼</div>
                    <div>醫事機構簡稱</div>
                    <div>就醫/檢查日期</div>
                </li>

                {
                    !arrayIsEmpty(data) && data.map((item, index) =>
                        <li key={index}>
                            {/*門住診別*/}
                            <div>{t(`ICCard.InpOpdEnum.${item.inpOpd}`)}</div>
                            {/*主診斷碼*/}
                            <div>{item.treatmentCode}</div>
                            {/*主診斷名稱*/}
                            <div>{item.treatmentName}</div>
                            {/*醫令代碼*/}
                            <div>{item.orderHeCode}</div>
                            {/*醫令名稱*/}
                            <div>{item.orderHeName}</div>
                            {/*診療部位*/}
                            <div>{t(`ICCard.orderPos.${item.orderPos}`)}</div>
                            {/*醫令總量*/}
                            <div>{item.tqty}</div>
                            {/*執行時間-起（取件日期）*/}
                            <div>{time2String(item.execStartDate, 'YYYY-MM-DD')}</div>
                            {/*執行時間-迄（檢驗報告日期）*/}
                            <div>{time2String(item.execEndDate, 'YYYY-MM-DD')}</div>
                            {/*醫事機構代碼*/}
                            <div>{item.hospNo}</div>
                            {/*醫事機構簡稱*/}
                            <div>{item.hospName}</div>
                            {/*就醫/檢查日期*/}
                            <div>{item.orderDate}</div>
                        </li>
                    )}
            </ul>
        </div>
    )
}
