import {
    ApiErrorStatusEnum,
    time2String,
    stringIsEmpty,
    objectIsEmpty,
} from 'edah_utils/dist'
import React, {useEffect, useState} from 'react'
import {delTranFederal, editTranFederal} from '../../../api/v1/Rmis'
import {queryAllDivision, queryByHospNo} from '../../../api/v1/Menu'
import useToast from '../../../hooks/useToast'
import {DATETIME_FORMAT, DATE_FORMAT} from '../../../constants/common'
import {debounce} from '../utils'
import {t} from 'i18next'
import {
    AlertTypeEnum,
    Field,
    Search,
    SizeEnum,
    Checkbox,
    TextField,
    IconEnum,
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    DateRangePicker,
    Dialog,
    DialogVariant, DialogSizeEnums,
} from "edah-component/dist"
import dayjs from "dayjs"

/**
 * 策略聯盟維護表單狀態
 * ADD: 新增
 * EDIT: 維護
 */
export const ModalStatus = {
    ADD: 'ADD',
    EDIT: 'EDIT',
}

/**
 * 策略聯盟檢核選項
 */
const CheckListMap = {
    federalFlag: '策略聯盟',
    communityFlag: '社區醫療群',
    supportFlag: '支援院所',
}
/**
 * 策略聯盟維護表單
 * @returns {JSX.Element}
 */
const StrategicAllianceMaintainForm = ({data, onClose, status}) => {
    // 院所代碼
    const [hospitalCode, setHospitalCode] = useState(data.hospNo || '')

    // 院所名稱
    const [hospitalName, setHospitalName] = useState(data.hospName || '')

    // 郵遞區號
    const [zipCode, setZipCode] = useState(data.hospZipCode || '')

    /**
     * checkbox選項們: 策略聯盟、社區醫療群、支援院所
     */
    const [checkList, setCheckList] = useState({
        federalFlag: data.federalFlag,
        communityFlag: data.communityFlag,
        supportFlag: data.supportFlag,
    })

    /**
     * 科別列表
     */
    const [divList, setDivList] = useState([])

    // 輸入框檢核狀態
    const [hospitalCodeState, setHospitalCodeState] = useState(null)

    // 科別1
    const [departmentOne, setDepartmentOne] = useState({
        divNo: data.suggestDiv_01 || '',
        divName: data.suggestDiv01Name || '',
    })

    // 科別 2
    const [departmentTwo, setDepartmentTwo] = useState({
        divNo: data.suggestDiv_02 || '',
        divName: data.suggestDiv02Name || '',
    })

    // 科別 3
    const [departmentThree, setDepartmentThree] = useState({
        divNo: data.suggestDiv_03 || '',
        divName: data.suggestDiv03Name || '',
    })

    // 科別 4
    const [departmentFour, setDepartmentFour] = useState({
        divNo: data.suggestDiv_04 || '',
        divName: data.suggestDiv04Name || '',
    })

    // 科別 5
    const [departmentFive, setDepartmentFive] = useState({
        divNo: data.suggestDiv_05 || '',
        divName: data.suggestDiv05Name || '',
    })

    // 開始日期
    const [startDate, setStartDate] = useState(
        time2String(data.effectDate, DATE_FORMAT) || ''
    )

    // 結束日期
    const [endDate, setEndDate] = useState(
        time2String(data.endDate, DATE_FORMAT) || ''
    )

    // 刪除確認popup控制
    const [showDeletePopup, setShowDeletePopup] = useState(false)

    // 儲存成功 or 失敗 Toast
    const showToast = useToast()

    /**
     * 院所代碼輸入框變動事件
     * @param e {Event} 事件物件
     * @return {void}
     */
    const handleHospitalCodeChange = (e) => setHospitalCode(e.target.value)

    /**
     * 院所代碼輸入框失焦事件
     * @param e {Event} 事件物件
     * @return {void}
     */
    const handleHospitalCodeInputOnBlur = (e) => {
        const value = e.target.value
        // 輸入框有值才會查詢
        if (!stringIsEmpty(value)) {
            getHospNameByHospNo(value)
        }
    }

    /**
     * 以院所代碼查詢院所名稱
     * @param hospNo {String} 院所代碼
     * @return {void}
     */
    const getHospNameByHospNo = debounce((hospNo) => {
        queryByHospNo({hospNo}).then((res) => {
            // 成功
            if (res.err === ApiErrorStatusEnum.Success) {
                // 查無相關院所代碼
                if (objectIsEmpty(res.data)) {
                    showToast({
                        message: '查無相關院所代碼',
                        type: AlertTypeEnum.Error,
                    })
                    // 清空輸入框
                    setHospitalName('')
                    setHospitalCode('')
                    setZipCode('')
                } else {
                    // 設定院所名稱與郵遞區號
                    setHospitalName(res.data.hospName || '')
                    setZipCode(res.data.hospZipCode)
                }
            } else {
                // 取得失敗
                showToast({
                    message: res.msg,
                    type: AlertTypeEnum.Error,
                })
                // 清空輸入框
                setHospitalName('')
                setHospitalCode('')
                setZipCode('')
            }
        })
    })

    /**
     * checkbox變動事件
     * @param item {String} 選項名稱
     * @return {void}
     */
    const handleCheckboxOnChange = (item) => {
        setCheckList({
            ...checkList,
            [item]: !checkList[item],
        })
    }


    /**
     * 日期範圍變動事件
     * @param {Array<Dayjs | null>} newDates 日期範圍 [開始日期, 結束日期]
     * @return {void}
     */
    const handleDateRangeChange = (newDates) => {
        const [newStartDate, newEndDate] = newDates;

        setStartDate(newStartDate ? newStartDate.format('YYYY-MM-DD') : '');
        setEndDate(newEndDate ? newEndDate.format('YYYY-MM-DD') : '');
    }

    /**
     * 更新科別1
     * @param division {Object} 科別資料
     * @return {void}
     */
    const selectDivision01 = (division) => {
        setDepartmentOne({
            ...departmentOne,
            divNo: division?.divNo ? division.divNo : '',
            divName: division?.divName ? division.divName : '',
        })
    }

    /**
     * 更新科別2
     * @param division {Object} 科別資料
     * @return {void}
     */
    const selectDivision02 = (division) => {
        setDepartmentTwo({
            ...departmentTwo,
            divNo: division?.divNo ? division.divNo : '',
            divName: division?.divName ? division.divName : '',
        })
    }

    /**
     * 更新科別3
     * @param division {Object} 科別資料
     * @return {void}
     */
    const selectDivision03 = (division) => {
        setDepartmentThree({
            ...departmentThree,
            divNo: division?.divNo ? division.divNo : '',
            divName: division?.divName ? division.divName : '',
        })
    }

    /**
     * 更新科別4
     * @param division {Object} 科別資料
     * @return {void}
     */
    const selectDivision04 = (division) => {
        setDepartmentFour({
            ...departmentFour,
            divNo: division?.divNo ? division.divNo : '',
            divName: division?.divName ? division.divName : '',
        })
    }

    /**
     * 更新科別5
     * @param division {Object} 科別資料
     * @return {void}
     */
    const selectDivision05 = (division) => {
        setDepartmentFive({
            ...departmentFive,
            divNo: division?.divNo ? division.divNo : '',
            divName: division?.divName ? division.divName : '',
        })
    }

    /**
     * 儲存
     * @return {void}
     */
    const handleSave = () => {
        const hospitalCodeisValid = !stringIsEmpty(hospitalCode)
        setHospitalCodeState(hospitalCodeisValid)
        if (!hospitalCodeisValid) {
            return
        }
        const params = {
            //院所代號
            hospNo: hospitalCode,
            //策略聯盟
            federalFlag: checkList.federalFlag,
            //社區療群
            communityFlag: checkList.communityFlag,
            //支援院所
            supportFlag: checkList.supportFlag,
            //推薦科別01
            suggestDiv_01: departmentOne.divNo,
            //推薦科別02
            suggestDiv_02: departmentTwo.divNo,
            //推薦科別03
            suggestDiv_03: departmentThree.divNo,
            //推薦科別04
            suggestDiv_04: departmentFour.divNo,
            //推薦科別05
            suggestDiv_05: departmentFive.divNo,
            //開始日期
            effectDate: time2String(startDate, DATETIME_FORMAT),
            //結束日期
            endDate: time2String(endDate, DATETIME_FORMAT),
            //排序
            sortBy: data.sortBy,
            lockVersion: data.lockVersion,
        }
        // 儲存
        editTranFederal(params).then((res) => {
            // 成功
            if (res.err === ApiErrorStatusEnum.Success) {
                showToast({message: '儲存成功', type: AlertTypeEnum.Success})
                onClose && onClose()
            } else {
                // 儲存失敗
                const msg = `儲存失敗: ${res.msg}`
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 刪除單筆資料
     * @return {void}
     */
    const handleConfirmDelete = () => {
        delTranFederal({hospNo: hospitalCode}).then((res) => {
            // 成功
            if (res.err === ApiErrorStatusEnum.Success) {
                showToast({message: '刪除成功', type: AlertTypeEnum.Success})
                handleDeletePopupClose()
                onClose && onClose()
            } else {
                // 刪除失敗
                showToast({
                    message: `刪除失敗: ${res.msg}`,
                    type: AlertTypeEnum.Error,
                })
            }
        })
    }

    /**
     * 開啟刪除彈窗
     * @return {void}
     */
    const handleDeletePopupOpen = () => {
        setShowDeletePopup(true)
    }

    /**
     * 關閉刪除彈窗
     * @return {void}
     */
    const handleDeletePopupClose = () => {
        setShowDeletePopup(false)
    }

    /**
     * 取得基礎選單資料
     * @return {void}
     */
    const getDivisionList = () => {
        queryAllDivision().then((res) => {
            // 取得成功
            if (res.err === ApiErrorStatusEnum.Success) {
                setDivList(res.data)
            } else {
                // 取得失敗
                showToast({message: res.msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 取得科別列表
     * @return {void}
     */
    useEffect(() => {
        getDivisionList()
    }, [])

    return (
        <Dialog
            open={true}
            title={status === ModalStatus.ADD ? '策略聯盟新增' : '策略聯盟維護'}
            variant={DialogVariant.EDIT}
            paperStyleProps={{width: '80%'}}
            onClose={onClose}
            onSave={handleSave}
            content={
                <>
                    {/*刪除確認popup*/}
                    <Dialog
                        open={showDeletePopup}
                        title={"提醒"}
                        variant={DialogVariant.DELETE}
                        paperStyleProps={{width: DialogSizeEnums.SM}}
                        onClose={handleDeletePopupClose}
                        onDelete={handleConfirmDelete}
                        content={'是否確定刪除？'}
                        muiDialogContentProps={{sx:{height: '110px', display: 'flex', alignItems: 'center'}}}
                    />
                    {/*刪除彈窗*/}
                    {status === ModalStatus.EDIT && (
                        <div className="flex items-center justify-end px-8 pt-8">
                            {/*<Button*/}
                            <Button
                                icon={IconEnum.Delete}
                                text={'刪除此筆資料'}
                                color={ButtonColorEnum.Primary}
                                size={ButtonSizeEnum.Large}
                                variant={ButtonVariantEnum.Text}
                                onClick={handleDeletePopupOpen}
                            />
                        </div>
                    )}
                    <div className="w-full overflow-auto max-h-[calc(100vh-180px)] p-5 space-y-2">
                        <div className="grid grid-cols-3">
                            {/* 院所代碼 */}
                            <Field label="院所代碼" isRequired>
                                <TextField
                                    size={SizeEnum.Small}
                                    value={hospitalCode}
                                    onChange={handleHospitalCodeChange}
                                    error={!hospitalCodeState}
                                    onBlur={handleHospitalCodeInputOnBlur}
                                    disabled={status === ModalStatus.EDIT}
                                />
                            </Field>
                            {/* 院所名稱 */}
                            <Field label="院所名稱">
                                <TextField
                                    size={SizeEnum.Small}
                                    value={hospitalName}
                                    disabled
                                />
                            </Field>
                        </div>
                        <div>
                            {/* 郵遞區號 */}
                            <Field label={t('general.zipCode')}>
                                <TextField
                                    size={SizeEnum.Small}
                                    value={zipCode}
                                    disabled={true}
                                />
                            </Field>
                        </div>
                        <div className="mt-2 grid grid-cols-3">
                            {/* 科別 1 */}
                            <Field label="科別 1">
                                {divList && <Search
                                    options={divList}
                                    getOptionLabel={(option) => `${option.divNo} ${option.divName}`}
                                    value={{
                                        divNo: departmentOne?.divNo,
                                        divName: departmentOne.divName,
                                    }}
                                    onChange={(_event, newValue) => selectDivision01(newValue)}
                                />}
                            </Field>
                            {/* 科別 2 */}
                            <Field label="科別 2">
                                {divList && <Search
                                    options={divList}
                                    getOptionLabel={(option) => `${option.divNo} ${option.divName}`}
                                    value={{
                                        divNo: departmentTwo?.divNo,
                                        divName: departmentTwo.divName,
                                    }}
                                    onChange={(_event, newValue) => selectDivision02(newValue)}
                                />}
                            </Field>
                            {/* 科別 3 */}
                            <Field label="科別 3">
                                {divList && <Search
                                    options={divList}
                                    getOptionLabel={(option) => `${option.divNo} ${option.divName}`}
                                    value={{
                                        divNo: departmentThree?.divNo,
                                        divName: departmentThree.divName,
                                    }}
                                    onChange={(_event, newValue) => selectDivision03(newValue)}
                                />}
                            </Field>
                        </div>
                        <div className="mt-2 grid grid-cols-3">
                            {/* 科別 4 */}
                            <Field label="科別 4">
                                {divList && <Search
                                    options={divList}
                                    getOptionLabel={(option) => `${option.divNo} ${option.divName}`}
                                    value={{
                                        divNo: departmentFour?.divNo,
                                        divName: departmentFour.divName,
                                    }}
                                    onChange={(_event, newValue) => selectDivision04(newValue)}
                                />}
                            </Field>
                            {/* 科別 5 */}
                            <Field label="科別 5">
                                {divList && <Search
                                    options={divList}
                                    getOptionLabel={(option) => `${option.divNo} ${option.divName}`}
                                    value={{
                                        divNo: departmentFive?.divNo,
                                        divName: departmentFive.divName,
                                    }}
                                    onChange={(_event, newValue) => selectDivision05(newValue)}
                                />}
                            </Field>
                        </div>
                        <div className="flex ml-[130px]">
                            {/* 策略聯盟、社區醫療群、支援院所 */}
                            {Object.keys(checkList).map((item, index) => {
                                return (
                                    <Checkbox
                                        key={index}
                                        label={CheckListMap[item]}
                                        checked={checkList[item]}
                                        onChange={() =>
                                            handleCheckboxOnChange(item)
                                        }
                                    />
                                )
                            })}
                        </div>
                        {/* 日期起訖 */}
                        <Field label={"日期起迄"}>
                            <DateRangePicker
                                required
                                value={[
                                    startDate ? dayjs(startDate) : null,
                                    endDate ? dayjs(endDate) : null,
                                ]}
                                onChange={handleDateRangeChange}
                            />
                        </Field>
                    </div>
                </>
            }
            muiDialogContentProps={{dividers: true}}
        />
    )
}

export default StrategicAllianceMaintainForm
