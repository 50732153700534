import {
    ApiErrorStatusEnum,
    arrayIsEmpty,
    downloadFile,
    exportExcelAsBlob,
    FileExtensionsEnum,
    stringIsEmpty,
    time2String,
} from 'edah_utils/dist'
import React, {useState} from 'react'
import CustomTable from '../../components/ReferralSystem/CustomTable'
import {REFERRAL_CODE_MAINTAIN_HEADER} from '../../constants/referralSystem/maintainAreaHeader'
import {Pagination} from '../../components/Pagination/Pagination'
import {
    deleteTranCode,
    queryTranCode,
    saveTranCode,
    trancaseQueryBasicData,
} from '../../api/v1/Rmis'
import {useEffect} from 'react'
import useToast from '../../hooks/useToast'
import useDataGrid from '../../hooks/useDataGrid'
import {DATE_FORMAT, DATETIME_FORMAT} from '../../constants/common'
import {
    updatePrintContent,
    completePrintMode,
} from '../../redux/Slice/printSlice'
import {useSelector} from 'react-redux'
import store from '../../redux/store'
import {FORM_TYPE} from '../../constants/referralSystem/print'
import {
    AlertTypeEnum,
    Select,
    IconEnum,
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    Dialog,
    DialogVariant,
    DialogSizeEnums
} from "edah-component/dist"
import {t} from 'i18next'

/**
 * 布局
 * @param {JSX.Element} children 子元件
 * @return {JSX.Element}
 */
const Layout = ({children}) => {
    return (
        <div className="p-2 w-full h-[calc(100vh-98px)] overflow-auto">
            {children}
        </div>
    )
}

/**
 * 轉診代碼維護
 * @return {JSX.Element}
 */
const ReferralCodeMaintain = () => {
    // 分頁
    const [filter, setFilter] = useState({
        totalPageSize: 1,
        totalItemSize: 0,
        pageNum: 1,
        pageSize: 10,
    })
    // datagrid
    const {
        // 更新資料列表
        updateDataList,
        // 編輯
        handleEdit,
        // 儲存
        handleSave,
        // 取消
        handleCancel,
        // 新增
        addNewData,
        // 勾選單筆
        handleToggleItem,
        // 勾選全部
        handleToggleAll,
        // 刪除
        deleteRow,
        // 資料列表
        dataList,
        // 是否編輯
        isEditMode,
    } = useDataGrid()
    // 類別代碼
    const [codeType, setCodeType] = useState('')
    // 類別代碼列表
    const [codeTypeList, setCodeTypeList] = useState([])
    // toast
    const showToast = useToast()
    // 列印模式
    const isPrintMode = useSelector((state) => state.print.isPrintMode)
    // 當前選取的資料列
    const [activeRow, setActiveRow] = useState({})
    // 刪除確認popup控制
    const [showDeletePopup, setShowDeletePopup] = useState(false)

    /**
     * 改變類別代碼內容
     * @param value {string} 類別代碼
     * @return {void}
     */
    const handleCodeTypeChange = (value) => setCodeType(value)

    /**
     * 查詢按鈕
     */
    const handleClickQuery = async () => {
        getTranCodeList(1, filter.pageSize)
    }

    /**
     * 取得轉診代碼列表
     * @param pageNum {number} 頁碼
     * @param pageSize {number} 每頁筆數
     * @return {void}
     */
    const getTranCodeList = (pageNum, pageSize) => {
        // 轉診代碼維護查詢
        queryTranCode({
            codeType,
            pageNum,
            pageSize,
        }).then((res) => {
            // 成功
            if (res.err === ApiErrorStatusEnum.Success) {
                // 資料列表
                const list = res.data || []
                // 無資料則顯示查無資料提醒
                if (arrayIsEmpty(list)) {
                    showToast({
                        message: '查無資料',
                        type: AlertTypeEnum.Warning,
                    })
                }
                // 更新分頁
                setFilter({
                    ...filter,
                    totalItemSize: res.totalItemSize,
                    totalPageSize: res.totalPageSize,
                    pageSize,
                    pageNum,
                })
                // 更新資料列表
                updateDataList(list)
            } else {
                // 顯示錯誤提醒
                showToast({
                    message: res.msg,
                    type: AlertTypeEnum.Error,
                })
            }
        })
    }

    /**
     * 刪除單筆資料
     * @return {void}
     */
    const handleConfirmDelete = () => {
        // 刪除轉診代碼
        deleteTranCode({
            codeType: activeRow.codeType,
            codeNo: activeRow.codeNo,
        }).then((res) => {
            // 成功
            if (res.err === ApiErrorStatusEnum.Success) {
                showToast({message: '刪除成功', type: AlertTypeEnum.Success})
                deleteRow(activeRow)
            } else {
                // 顯示錯誤提醒
                showToast({message: res.msg, type: AlertTypeEnum.Error})
            }
            setShowDeletePopup(false)
        })
    }

    /**
     * 開啟刪除彈窗
     * @param tableRow {object} 當前選取的資料列
     * @return {void}
     */
    const handleDeletePopupOpen = (tableRow) => {
        setShowDeletePopup(true)
        setActiveRow(tableRow)
    }

    /**
     * 關閉刪除彈窗
     * @return {void}
     */
    const handleDeletePopupClose = () => {
        setShowDeletePopup(false)
    }

    /**
     * 點選 pagination 頁碼
     * @param page {string} 目前頁碼
     * @return {void}
     */
    const handlePageOnChange = (page) => {
        const currentPage = Number(page)
        getTranCodeList(currentPage, filter.pageSize)
    }

    /**
     * 變更每頁筆數
     * @param event {object} 事件
     * @return {void}
     */
    const handlePageSizeChange = (event) => {
        const pageSize = Number(event.target.value)
        getTranCodeList(1, pageSize)
    }
    /**
     * 點選上一頁
     * @return {void}
     */
    const handlePrevPageOnClick = () => {
        const prevPage = filter.pageNum - 1
        const page = prevPage < 1 ? filter.totalPageSize : prevPage
        getTranCodeList(page, filter.pageSize)
    }

    /**
     * 點選下一頁
     * @return {void}
     */
    const handleNextPageOnClick = () => {
        const nextPage = filter.pageNum + 1
        const firstPage = 1
        const page = nextPage > filter.totalPageSize ? firstPage : nextPage
        getTranCodeList(page, filter.pageSize)
    }

    /**
     * 匯出Excel
     * @return {void}
     */
    const handleExportExcel = () => {
        // 已勾選的資料
        const selectedData = dataList.filter((item) => item.isSelected)
        // 無選取資料則不能匯出 excel
        if (selectedData.length === 0) {
            return
        }
        // 取得欄位 key
        const keys = REFERRAL_CODE_MAINTAIN_HEADER.map((item) => item.key)
        // 取得欄位名稱
        const titles = REFERRAL_CODE_MAINTAIN_HEADER.map((item) => item.name)
        // 資料陣列
        const dataArray = []
        // 遍歷已勾選的資料，將該列資料(object)轉換成陣列
        selectedData.forEach((item) => {
            const data = []
            keys.forEach((key) => {
                if (key === 'applicantDate') {
                    data.push(time2String(item[key], DATE_FORMAT))
                } else {
                    data.push(item[key])
                }
            })
            dataArray.push(data)
        })
        // excel sheet資料
        const sheets = [
            {
                titles,
                data: dataArray,
            },
        ]
        // 匯出Excel
        downloadFile(
            exportExcelAsBlob(sheets),
            '轉診代碼維護',
            FileExtensionsEnum.XLSX
        )
    }

    /**
     * 匯出Excel
     */
    const ExportExcelBtn = () => {
        return (
            <Button
                icon={IconEnum.Export}
                text={t('general.export')}
                color={ButtonColorEnum.Success}
                size={ButtonSizeEnum.Large}
                variant={ButtonVariantEnum.Text}
                onClick={handleExportExcel}
            />
        )
    }

    /**
     * 編輯該列資料
     * @param row {object} 當前選取的資料列
     * @return {void}
     */
    const editTranCode = (row) => {
        handleEdit({
            ...row,
            // 類別代碼
            codeType: row.codeType || '',
            // 類別代碼名稱
            codeTypeName: row.codeTypeName || '',
            // 項目代碼
            codeNo: row.codeNo || '',
            // 項目代碼名稱
            codeNoName: row.codeNoName || '',
            // 上傳項目代碼
            uploadCodeNo: row.uploadCodeNo || '',
            // 開始日期
            effectiveDate: time2String(row.effectiveDate, DATE_FORMAT) || '',
            // 結束日期
            endDate: time2String(row.endDate, DATE_FORMAT) || '',
            // 排序
            sortBy: row.sortBy || '',
        })
    }

    /**
     * 新增資料
     * @return {void}
     */
    const addTranCode = () => {
        // 取得目前查詢列的類別代碼及類別名稱
        const codeTypeItem = codeTypeList.find((item) => item.codeNo === codeType)
        const codeTypeName = codeTypeItem ? codeTypeItem.codeName : ''
        // 新增資料
        const newData = {
            lockVersion: 0,
            codeType,
            codeTypeName,
            codeNo: '',
            codeNoName: '',
            uploadCodeNo: '',
            effectiveDate: '',
            endDate: '',
            sortBy: '',
        }
        addNewData(newData)
    }

    /**
     * 儲存該列資料
     * @param tableRow {object} 當前選取的資料列
     * @return {void}
     */
    const saveTranCodeData = (tableRow) => {
        // 必填欄位未填寫則不儲存
        if (
            stringIsEmpty(tableRow.codeType) ||
            stringIsEmpty(tableRow.codeTypeName) ||
            stringIsEmpty(tableRow.codeNo) ||
            stringIsEmpty(tableRow.codeNoName)
        ) {
            return
        }
        // 儲存資料
        const data = {
            // 鎖定版本
            lockVersion: tableRow.lockVersion,
            // 類別代碼
            codeType: tableRow.codeType,
            // 類別代碼名稱
            codeTypeName: tableRow.codeTypeName,
            // 項目代碼
            codeNo: tableRow.codeNo,
            // 項目代碼名稱
            codeNoName: tableRow.codeNoName,
            // 上傳項目代碼
            uploadCodeNo: tableRow.uploadCodeNo,
            // 開始日期
            effectiveDate: time2String(tableRow.effectiveDate, DATETIME_FORMAT),
            // 結束日期
            endDate: time2String(tableRow.endDate, DATETIME_FORMAT),
            // 排序
            sortBy: tableRow.sortBy,
        }
        // 儲存轉診代碼
        saveTranCode(data).then((res) => {
            // 成功
            if (res.err === ApiErrorStatusEnum.Success) {
                // 更新資料列
                handleSave(tableRow)
                // 重新取得資料列表
                getTranCodeList(filter.pageNum, filter.pageSize)
                // 顯示儲存成功提醒
                showToast({message: '儲存成功', type: AlertTypeEnum.Success})
            } else {
                // 顯示錯誤提醒
                showToast({message: res.msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 列印按鈕
     * @return {void}
     */
    const handlePrint = () => {
        const printData = dataList.filter((item) => item.isSelected)
        store.dispatch(
            updatePrintContent({
                reportType: FORM_TYPE.referralCodeList,
                printData,
            })
        )
    }

    useEffect(() => {
        trancaseQueryBasicData().then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                setCodeTypeList(res.data.codeTypeList)
                setCodeType(res.data.codeTypeList[0].codeNo)
            }
        })
    }, [])

    /**
     * 開啟列印模式則開啟瀏覽器列印視窗
     * 結束列印則關閉列印模式
     */
    useEffect(() => {
        if (isPrintMode) {
            window.print()
            store.dispatch(completePrintMode())
        }
    }, [isPrintMode])

    return (
        <Layout>
            {/*刪除確認popup*/}
            <Dialog
                muiDialogContentProps={{sx: {height: '110px', display: 'flex', alignItems: 'center'}}}
                open={showDeletePopup}
                title={'提醒'}
                variant={DialogVariant.DELETE}
                paperStyleProps={{width: DialogSizeEnums.SM}}
                content={'是否確定刪除？'}
                onClose={handleDeletePopupClose}
                onDelete={handleConfirmDelete}
            />
            <div className="flex justify-between p-2 items-center">
                <div className="flex items-center text-left gap-2">
                    {/* 類別代碼 */}
                        <Select
                            data={{
                                label: "類別代碼",
                                options: codeTypeList?.map((item) => ({
                                    value: item.codeNo,
                                    label: item.codeName,
                                })),
                            }}
                            value={codeType}
                            onChange={handleCodeTypeChange}
                            displayEmpty={true}
                            notched={true}
                        />
                    {/* 查詢 */}
                    <Button
                        sx={{fontWeight: 'bold', height: '2.5rem'}}
                        text={t('general.query')}
                        color={ButtonColorEnum.Primary}
                        size={ButtonSizeEnum.Medium}
                        variant={ButtonVariantEnum.Contained}
                        onClick={handleClickQuery}
                    />
                </div>
                <div>
                    {/* 列印 */}
                    <Button
                        sx={{fontWeight: 'bold', height: '2.5rem'}}
                        text={t('general.print')}
                        color={ButtonColorEnum.Primary}
                        size={ButtonSizeEnum.Medium}
                        variant={ButtonVariantEnum.Contained}
                        onClick={handlePrint}
                    />
                </div>
            </div>
            {/* 表格 */}
            <CustomTable
                isSelectable={true}
                fields={REFERRAL_CODE_MAINTAIN_HEADER}
                dataList={dataList}
                toggleAll={handleToggleAll}
                toggleItem={handleToggleItem}
                slotButton={<ExportExcelBtn/>}
                isCrud={true}
                isEditMode={isEditMode}
                handleEdit={editTranCode}
                handleSave={saveTranCodeData}
                handleCancel={handleCancel}
                addNewData={addTranCode}
                deleteRow={handleDeletePopupOpen}
            />
            {/* 分頁 */}
            <div className="flex justify-end">
                <Pagination
                    pageSize={filter.pageSize}
                    totalSize={filter.totalItemSize}
                    currentPage={filter.pageNum}
                    totalPageSize={filter.totalPageSize}
                    onPageOnChange={handlePageOnChange}
                    onPageSizeChange={handlePageSizeChange}
                    onPrevPageOnClick={handlePrevPageOnClick}
                    onNextPageOnClick={handleNextPageOnClick}
                />
            </div>
        </Layout>
    )
}

export default ReferralCodeMaintain
