// Import Function
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {usePopup} from '../PopupProvider';
import {
    rsisAddAgent,
    rsisDelAgent,
    rsisQueryAgent,
    rsisQueryAgentByUserNo,
    rsisQueryAgentLike,
    rsisQueryBaseData,
} from "../../../api/v1/RSIS"
import {t} from "i18next"
import {ApiErrorStatusEnum, fuzzySearchObjects, getLocalStorage, multiTermFuzzySearchObjects} from "edah_utils/dist"
import QueryPersonList from "../Modal/QueryPersonList";
import QueryCreateUserList from "../Modal/QueryCreateUserList";
import {
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    TextField,
    AlertTypeEnum,
    Card,
    InlineEditDataGrid,
} from "edah-component/dist"
import {SearchTextField} from "../../SearchTextField/SearchTextField";
import {Search} from "edah-component/dist/index";
import {queryDepartmentByUserNo} from "../../../api/v1/Menu";

/**
 * 病房代理人員維護
 * @return {JSX.Element}
 */
const WardAgentMaintain = () => {
    // 醫師資料清單
    const [doctorList, setDoctorList] = useState([])
    // 醫師代號/姓名
    const [userNo, setUserNo] = useState('')
    // 選中的 row id
    const [focusRowId, setFocusRowId] = useState(null)
    // 模糊搜尋的字串
    const [queryString, setQueryString] = useState('');
    // 未保存的資料集
    const [unSaveDataMap, setUnSaveDataMap] = useState({})

    // 表格資料集
    const [dataList, setDataList] = useState([])
    // 使用上下文
    const {
        setShowDeletePopup,
        setFocusIndex,
        // 顯示醫師查詢彈窗
        showQueryPersonList,
        setShowQueryPersonList,
        // 顯示申請人查詢彈窗
        showQueryCreateUserList,
        setShowQueryCreateUserList,
        // 焦點項目
        setFocusItem,
        showToast
    } = usePopup()

    /**
     * 重置醫師代號/姓名
     * @return {void}
     */
    const handleResetValue = () => {
        //清空資料
        setUserNo('')
        //取得病房代理人員所有資料
        getQueryAgent()
    }

    /**
     * 儲存列表事件
     * @param {Object} item - 要保存的項目
     * @return {void}
     */
    const handleSaveItemOnClick = (item) => {
        rsisAddAgent({
            //UUID_PK
            agentId: '',
            // 支援人員代號
            supportUserNo: item.supportUserNo,
            // 代理人員代號
            agentUserNo: item.agentUserNo,
            // 代理人員部門代號
            agentDeptNo: item.agentDeptNo,
            // 備註
            memo: '',
            // 院區
            zone: getLocalStorage('campus'),
            // 支援人員名稱
            supportUserName: item.supportUserName,
            // 代理人員名稱
            agentUserName: item.agentUserName,
        }).then((res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                getQueryAgent()
                showToast({message: '保存成功', type: AlertTypeEnum.Success});
            } else {
                showToast({message: `${msg}，保存失敗`, type: AlertTypeEnum.Error});
            }
        }))
    }

    /**
     * 列表刪除事件
     * @return {void}
     */
    const handleListItemOnDelete = (agentId) => delAgent(agentId)

    /**
     * 醫師資料更動時
     * @param {string} item - 醫師資料
     * @return {void}
     */
    const handleDoctorChange = (item) => {
        // 設定醫師代號/姓名
        setUserNo(`${item.userNo} ${item.userName}`)
        // 關閉查詢彈窗
        setShowQueryCreateUserList(false)
        // 根據醫師代號/姓名取得病房代理人員資料
        getQueryAgentLike(item.userNo)
    }



    /**
     * 取得病房代理人員所有資料
     * @return {void}
     */
    const getQueryAgent = () => {
        rsisQueryAgent({}).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            // 取得成功
            if (err === ApiErrorStatusEnum.Success) {
                // 設定列表資料
                setDataList(data)
            } else { // 取得失敗
                // 清空列表
                setDataList([])
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 根據醫師代號/姓名取得病房代理人員資料
     * @return {void}
     */
    const getQueryAgentLike = (item) => {
        rsisQueryAgentLike({
            filterName: 'CODENAME',
            value: item
        }).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            // 取得成功
            if (err === ApiErrorStatusEnum.Success) {
                // 設定列表資料
                setDataList(data)
            } else { // 取得失敗
                // 清空列表
                setDataList([])
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 取得所有醫師資料
     * @return {void}
     */
    const getDoctorList = () => {
        rsisQueryBaseData({}).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            // 取得成功
            if (err === ApiErrorStatusEnum.Success) {
                // 設定醫師資料
                setDoctorList(data.doctorList)
            } else { // 取得失敗
                // 清空醫師資料
                setDoctorList([])
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 取得基本資料
     * @return {void}
     */
    const getBasicData = () => {
        rsisQueryBaseData({}).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            // 取得成功
            if (err === ApiErrorStatusEnum.Success) {
                const key = Object.keys(data)
            } else { // 取得失敗
                // 清空醫師資料
                setDoctorList([])
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 刪除病房代理人員資料
     * @return {void}
     */
    const delAgent = (agentId) => {
        rsisDelAgent({
            // 代理人員代號
            PKey: agentId
        }).then(res => {
            // 狀態 / 訊息 / 資料
            const {err, msg, data} = res
            // 刪除成功
            if (err === ApiErrorStatusEnum.Success) {
                // 清空資料
                setFocusIndex(null)
                // 重新取得列表資料
                getQueryAgent()
                // 關閉刪除彈窗
                setShowDeletePopup(false)
            } else { // 刪除失敗
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 支援醫師代碼欄位輸入框點擊時
     * @param id {string} - row id
     * @return {void}
     */
    const handlePersonSearchOnClick = (id) => {
        setFocusRowId(id)
        setUnSaveDataMap({...unSaveDataMap, [id]:{
            ...unSaveDataMap[id],
                agentUserNo: '',
                agentDeptName: '',
                agentDeptNo: ''
            }})
        setShowQueryPersonList(true)
    }

    /**
     * 取得模糊匹配後的資料陣列
     * @return {Array<Object>}
     */
    const getFilterData = () => fuzzySearchObjects(dataList, queryString)
    /**
     * 搜尋字串改變時
     * @param event {Event}
     * @return {void}
     */
    const handleQueryOnChange = (event) => setQueryString(event.target.value);
    /**
     * 取得代理人員部門
     * @return {void}
     */
    const getDepartmentByDoctor = (userNo, updateValue) => {
        queryDepartmentByUserNo({
            userNo: userNo
        }).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            // 取得成功
            if (err === ApiErrorStatusEnum.Success) {
                setUnSaveDataMap({...unSaveDataMap, [focusRowId]:{
                        ...unSaveDataMap[focusRowId],
                        agentDeptNo: data?.deptNo,
                        agentDeptName: data?.deptName,
                        ...updateValue
                    }
                })
            } else {
                showToast({message: msg, type: AlertTypeEnum.Error});
            }
        })
    }

    /**
     * 根據醫師代碼取得代理人資料
     * @param userNo {string} 醫師代碼
     * @return {void}
     */
    const getAgentByUserNo = (userNo) => {
        rsisQueryAgentByUserNo({
            userNo: userNo
        }).then(res => {
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                setDoctorList(data)
                if (data === null) {
                    showToast({message: '支援醫師查無代理人資料', type: AlertTypeEnum.Warning})
                }
            } else {
                showToast({message: msg, type: AlertTypeEnum.Error});
            }
        })
    }

    /**
     * 支援醫師資料更動時
     * @param  item {Object} - 支援醫師資料
     */
    const handleSupportDoctorChange = (item) => {
        setFocusItem(item)
        setUnSaveDataMap({...unSaveDataMap, [focusRowId]: {
                ...unSaveDataMap[focusRowId],
                supportUserNo: item.userNo,
                supportUserName: item.userName,
                supportFullName: `${item.userNo} ${item.userName}`
            }})
        // 關閉彈窗
        setShowQueryPersonList(false)

        getAgentByUserNo(item.userNo)

    }

    /**
     * 取得過濾後的醫師清單
     * @return {Object<Array>}
     */
    const doctorAsAgentFilterList = useMemo(() => {
        const targetData = unSaveDataMap[focusRowId] || {}
        if (targetData?.agentUserNo?.indexOf(' ') > 0) {
            return multiTermFuzzySearchObjects(doctorList, targetData?.agentUserNo?.split(' '));
        } else {
            return fuzzySearchObjects(doctorList, targetData?.agentUserNo);
        }
    },[unSaveDataMap, doctorList])


    /**
     * 取得支援醫師資料
     * @param id {string} - row id
     * @type {Function}
     */
    const getSupportSearchValue = useCallback((id) => {
        return doctorAsAgentFilterList?.find(item => item.agentUserNo === unSaveDataMap[id]?.agentUserNo) || null
    },[doctorAsAgentFilterList,unSaveDataMap])

    /**
     * 病房代理人維護 - 表格用的 header 與設定資料
     */
    const header = [
        { field: 'supportUserNo', headerName: '支援醫師代碼-姓名', flex: 1, editable: true, minWidth: 150, renderCell: (params) => {
                // 是否可編輯
                const isEditable = params.isEditable
                return  isEditable ?
                    <div className="flex items-center h-[100%]">
                        <TextField
                            value={unSaveDataMap[params.id]?.supportFullName}
                            onClick={()=>handlePersonSearchOnClick(params.id)}
                        />
                    </div>: <>{params.row.supportUserNo} {params.row.supportUserName}</>
            }},
        { field: 'agentUserNo', headerName:'代理人員代號-姓名', flex: 2, minWidth: 150, renderCell: (params) => {
                // 是否為新項目
                const isEditable = params.isEditable
                return isEditable ?
                    <div className="flex flex-row mt-2 items-center">
                        <Search
                            freeSolo
                            clearOnBlur
                            key={params.id}
                            value={getSupportSearchValue(params.id)}
                            onChange={(_e, value) => {
                                const updateValue = {agentUserNo: value?.agentUserNo, agentUserName: value?.agentUserName}
                                getDepartmentByDoctor(value?.agentUserNo, updateValue)
                            }}
                            options={doctorAsAgentFilterList||[]}
                            getOptionLabel={(option) => `${option.agentUserNo} ${option.agentUserName}`}
                        />
                    </div>: <>{params.row.agentUserNo} {params.row.agentUserName}</>
            }
        },
        { field: 'agentDeptNo', headerName:'代理人員部門', flex: 1, minWidth: 120, renderCell: (params) => {
                const isNew = params.row.isNew
                return isNew ? <>{unSaveDataMap[params.id]?.agentDeptNo} {unSaveDataMap[params.id]?.agentDeptName}</> : <>{params.row.agentDeptNo} {params.row.agentDeptName}</>
            }
        }
    ]

    /**
     * 第一次執行時
     * @return {void}
     */
    useEffect(() => {
        // 取得基本資料
        getBasicData()
        // 取得病房代理人員所有資料
        getQueryAgent()
        // 取得所有醫師資料
        getDoctorList()
    }, [])

    return (
        <Card className='px-6 py-2'>
            <div className='flex flex-row items-center gap-1 pt-4'>
                <TextField
                    label="醫師代號/姓名"
                    value={userNo}
                    onClick={() => setShowQueryCreateUserList(true)}/>
                {/* 清除搜尋條件 */}
                <Button text={t('general.clearQueryParams')} size={ButtonSizeEnum.Medium}
                        variant={ButtonVariantEnum.Outlined} color={ButtonColorEnum.Primary}
                        onClick={handleResetValue}/>
            </div>

            <div className="text-left my-2">
                <SearchTextField
                    value={queryString}
                    onChange={handleQueryOnChange}
                    placeholder={t('general.advancedSearch')}
                />
            </div>
            {/* 承辦部門人員維護表格 */}
            <InlineEditDataGrid
                rows={getFilterData() || []}
                columns={header}
                checkboxSelection={false}
                disableColumnFilter
                disableColumnSelector
                disableColumnMenu
                disableDensitySelector
                disableRowSelectionOnClick
                height={'600px'}
                disableEdit={true}
                onSave={(row) => handleSaveItemOnClick(unSaveDataMap[row.id])}
                onCancel={(row) => {
                    const rawTemp = {...unSaveDataMap}
                    delete rawTemp[row.id]
                    setUnSaveDataMap(rawTemp)
                }}
                onDeleteRow={(row) => {
                    handleListItemOnDelete(row.agentId)
                }}
            />

            {/* 查詢醫師彈窗 */}
            {showQueryCreateUserList && (
                <QueryCreateUserList
                    handleOnClose={() => setShowQueryCreateUserList(false)}
                    handleOnUpdate={handleDoctorChange}
                />
            )}
            {/* 支援醫師查詢彈窗 */}
            {showQueryPersonList && (
                <QueryPersonList
                    handleOnClose={() => setShowQueryPersonList(false)}
                    handleOnUpdate={handleSupportDoctorChange}
                />
            )}

        </Card>
    )
}

export default WardAgentMaintain
