import React from "react";
// import { ReactComponent as Exit } from "../../assets/images/icons/close.svg";

function TabContainer({ content }) {
  return (
    <div className=" flex flex-row justify-between items-center w-full h-full border">
      {content}
    </div>
  );
}

export default TabContainer;
