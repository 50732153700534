// Icon
import ExportIcon from "../../../assets/images/SystemMonitoring/export.svg"

// Import API
import {useEffect, useState} from "react"
import {BaseInput} from "../../Input/BaseInput"
import {InputTypeEnum} from "edah_utils/dist"
import {t} from "i18next"
import {BasePopup} from "../../Popup/BasePopup"

/**
 * 負責人員選單
 * @param {Boolean} show 是否顯示
 * @return {JSX.Element}
 */
export const PersonInChargePopup = ({show}) => {
    // 預設員工資料
    const defaultEmployee = {
        // 員工工號
        id: '',
        // 員工姓名
        name: ''
    }

    // 是否顯示刪除彈窗
    const [isShow, setIsShow] = useState(show)

    // 員工資料
    const [employee, setEmployee] = useState(defaultEmployee)

    //搜尋文字
    const [searchText, setSearchText] = useState('')

    /**
     * 當搜尋文字改變時，更新 searchText
     * @param e {event} event
     * @return {void}
     */
    const handleSearchTextOnChange = (e) => setSearchText(e.target.value)

    /**
     * 當員ID改變時，更新 employee
     * @param e {event} event
     * @return {void}
     */
    const handleEmployeeIdOnChange = (e) => setEmployee({...employee, id: e.target.value})

    /**
     * 當員工姓名改變時，更新 employee
     * @return {void}
     */
    const handleEmployeeNameOnChange = (e) => setEmployee({...employee, name: e.target.value})

    /**
     * 關閉彈窗
     * @return {void}
     */
    const handleClosePopupButtonOnClick = () => {

    }

    /**
     * 取得 Content
     * @return {JSX.Element}
     */
    const getContent = () => <div>
        <div className="w-full flex flex-row items-center justify-start py-4 px-6 mb-4">
            {/*員工工號*/}
            <p className="w-[80px] text-left pr-4">員工工號</p>
            <BaseInput className="w-[235px] h-10 pl-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                       type={InputTypeEnum.Text} placeholder={'--'} value={employee.id}
                       onChange={(e) => handleEmployeeIdOnChange(e)}/>
            {/*員工姓名*/}
            <p className="w-[80px] text-left pr-4">員工姓名</p>
            <BaseInput className="w-[235px] h-10 pl-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                       type={InputTypeEnum.Text} placeholder={'--'} value={employee.name}
                       onChange={(e) => handleEmployeeNameOnChange(e)}/>
            {/*查詢按鈕*/}
            <button
                className="flex items-center justify-center h-10 px-4 mr-4 border-[2px] bg-[#2B6CB0] text-white border-[#2B6CB0] rounded-[6px]">
                {t('general.query')}
            </button>
        </div>
        <div className="maintainContainer flex flex-row items-start justify-start">
            <div className="p-4 bg-white w-full border-[1px] border-[#D4D4D8] rounded-[12px] m-4">
                <div className="searchGroup flex flex-row items-center justify-between mb-4">
                    {/*進階搜尋*/}
                    <BaseInput className="w-[320px] h-10 pl-4 border-[2px] border-[#D4D4D8] rounded-[6px]"
                               type="text" placeholder={t('general.advancedSearch')} inputMode={'search'}
                               value={searchText} onChange={(e) => handleSearchTextOnChange(e)}/>
                    {/*輸出按鈕*/}
                    <button className="flex flex-row justify-center items-center w-[112px] h-10">
                        <div className="w-10 h-10">
                            <img className="w-full h-full" src={ExportIcon} alt="addIcon"/>
                        </div>
                        <p className="text-center text-[#38A169]">{t('general.export')}</p>
                    </button>
                </div>
                <ul className="tableList chargerList">
                    <li className="title">
                        <div>工號</div>
                        <div>姓名</div>
                    </li>
                    {
                        // filteredStaffData.map(([key, item]) =>
                        //     <li key={key}>
                        //         <div>{item.id}</div>
                        //         <div>{item.name}</div>
                        //     </li>
                        // )
                    }
                </ul>
            </div>
        </div>
    </div>

    /**
     * 當 show 改變時，更新 isShow
     */
    useEffect(() => setIsShow(show), [show])

    return isShow ?
        <BasePopup title="負責人員選單" closePopupButtonOnClick={handleClosePopupButtonOnClick} width={"800px"}
                   content={getContent()}/>
        : null
}
