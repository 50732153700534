import React from 'react'

//引入Button組件

/**
 *
 * @returns radius button 組件
 * @param {mode, modeChecked, handleModeChange, label}
 * mode: {string} - useState 當前狀態
 * modeChecked: {string} - 狀態匹配值
 * handleModeChange: {function} - 狀態改變事件
 * label: {string} - 標籤文字
 * @param {value} - value
 * @param {name} - name
 * @param {boolean} - isDisabled
 */
const RadioButton = ({mode, modeChecked, handleModeChange, label, value, name = '', wrapperClassName, isDisabled = false}) => (
    <div className={`flex flex-row space-x-4 items-center ${wrapperClassName}`}>
        <input
            className="appearance-none ring-2 w-2 h-2 ring-gray-200 rounded-full bg-white ring-offset-4 checked:bg-blue-400 checked:ring-blue-400 hover:cursor-pointer disabled:checked:ring-[#2B6CB0] disabled:checked:bg-[#2B6CB0] disabled:checked:opacity-50"
            type="radio" id={`${name}_${label}`} value={value} name={name}
            onChange={() => handleModeChange(modeChecked)}
            checked={mode === modeChecked}
            disabled={isDisabled}/>
        <label htmlFor={`${name}_${label}`} className={`${isDisabled ? 'opacity-50': ''}`}>{label}</label>
    </div>
)

export default RadioButton
