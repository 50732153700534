import React, {useEffect, useMemo, useState} from "react"
import AppointmentPopup from "../../components/Regs/MonthMaintenance/AppointmentPopup"
import {
    regsMonthClinicBaseData,
    regsQueryMonthClinicByDivNo,
    regsQueryMonthClinicByDoctorNo
} from "../../api/v1/RegsMonth"
import {ApiErrorStatusEnum, PopupModeEnum, RegsWeekModeEnum, time2String, TimeslotEnum} from "edah_utils/dist"
import {t} from "i18next"
import ScheduleTimeBox from "../../components/Regs/MonthMaintenance/ScheduleTimeTable/ScheduleTimeBox"
import {
    Button,
    ButtonSizeEnum,
    ButtonVariantEnum,
    ColorEnum,
    DatePicker,
    RadioGroup,
    Search,
    Select,
    SizeEnum
} from 'edah-component/dist'
import dayjs from "dayjs";

/**
 * 月班維護
 * @return {JSX.Element}
 */
const MonthMaintenance = () => {
    // 計算總高度
    const totalHeight = window.innerHeight
    // 頂部區塊&日期區塊高度
    const defaultHeight = 120
    const topBlockHeight = 60
    const dateBlockHeight = 40
    // remain height
    const remainingHeight = totalHeight - defaultHeight - topBlockHeight - dateBlockHeight
    //計算時段高度
    const blockHeightPerSection = remainingHeight / 3

    //當前週數
    const [currentDate, setCurrentDate] = useState(null)
    //Viewing Filter -> 醫師 or 科別
    const [filterMode, setFilterMode] = useState(RegsWeekModeEnum.Department)
    //搜尋文字
    const [searchText, setSearchText] = useState("")
    // 所有 醫師 list/科別 list
    const [allDoctorOrDept, setAllDoctorOrDept] = useState([])
    // 醫師/科別的下拉選單
    const [doctorOrDeptOptionList, setDoctorOrDeptOptionList] = useState([])
    // 是否顯示Popup
    const [showPopup, setShowPopup] = useState(false)
    // Popup模式
    const [popupMode, setPopupMode] = useState(null)
    // 基礎資料
    const [baseData, setBaseData] = useState(null)
    // 月班資料
    const [data, setData] = useState(null)
    //當前右鍵點擊的區塊
    const [currentRightClick, setCurrentRightClick] = useState({
        week: null,
        timeslot: null,
    })
    //當前選擇的資料
    const [selectedData, setSelectedData] = useState(null)

    /**
     * 預設周邊要插入資料
     * @return {Array<Object>}
     */
    const defaultWeekData = () => Array.from({length: 7}, (_, i) => i + 1).map(
        (index) => {
            return {
                //星期幾的數值
                weekIndex: index,
                // 早上
                morning: [],
                // 中午
                afternoon: [],
                // 晚上
                night: [],
            }
        }
    )

    /**
     * 改變日期時
     * @param newDate {Dayjs | null} - 選擇的日期或 null
     * @return {void}
     */
    const handleDateOnChange = (newDate) => {
        // 如果 newDate 為 null , 使用 currentDate 或預設日期
        const selectedDate = newDate ? newDate.format('YYYY-MM-DD') : currentDate;

        // 設定當前日期
        setCurrentDate(selectedDate);

        // 重新設定新問診
        setCurrentRightClick({week: null, timeslot: null});
    };

    /**
     * 搜尋文字改變時
     * @param e {Object}
     * @return {void}
     */
    const handleSearchTextOnChange = (e) => setSearchText(e?.target?.value || '')

    /**
     * 模式改變時
     * @param mode {Number}
     * @return {void}
     */
    const handleModeOnChange = (mode) => {
        const newMode = parseInt(mode)
        //設定模式
        setFilterMode(newMode)
        //是否科別模式
        const isDepartmentMode = newMode === RegsWeekModeEnum.Department
        //根據科別取得下拉選項
        const options = isDepartmentMode ? baseData.divisionList : baseData.doctorList
        //設定下拉選項
        setAllDoctorOrDept(options)
        // 重新設定新問診
        setCurrentRightClick({week: null, timeslot: null})
        const ele = document.querySelector('button.MuiAutocomplete-clearIndicator')
        if (ele) ele.click();
        //清空搜尋文字
        setSearchText('')
    }

    /**
     * 取得過濾選項
     * @param option {Object}
     * @return {string}
     */
    const getFilterOptionContent = (option) => +filterMode === RegsWeekModeEnum.Department ?
        //科別代號 科別名稱
        `${option.divNo} ${option.divName}` :
        //醫師代號 醫師名稱
        `${option.userNo} ${option.userName}`

    /**
     * 取得到搜尋的科別或醫生選項
     * @return {Array<Object>}
     */
    const getDoctorOrDeptOptions = () => {
        //搜尋文字為空時
        if (searchText === '') {
            return allDoctorOrDept
        } else { //搜尋文字有數據時
            return allDoctorOrDept.filter(option => {
                //是否科別模式
                const isDepartmentMode = +filterMode === RegsWeekModeEnum.Department
                //編號
                const noID = isDepartmentMode ? option.divNo : option.userNo
                //名稱
                const name = isDepartmentMode ? option.divName : option.userName
                //是否有空白
                const hasSpace = searchText.indexOf(' ') >= 0
                //回傳是否符合條件
                return hasSpace ?
                    //有空白時
                    (noID.includes(searchText.split(' ')[0]) && name.includes(searchText.split(' ')[1])) :
                    //無空白時
                    (noID.includes(searchText) || name.includes(searchText))
            })
        }
    }

    /**
     * 選擇過濾選項時
     * @param option {Object}
     * @return void
     */
    const handleFilterOptionOnClick = (option) => {
        //是否科別模式
        const isDepartmentMode = +filterMode === RegsWeekModeEnum.Department

        const div = option?.divNo && option?.divName ? `${option?.divNo} ${option?.divName}` : ''
        const user = option?.userNo && option?.userName ? `${option?.userNo} ${option?.userName}` : ''

        //設定搜尋文字
        setSearchText(isDepartmentMode ? div : user)
    }

    /**
     * 取得月班資料
     * @return {void}
     */
    const getMonthData = () => {
        //是否科別模式
        const isDepartmentMode = +filterMode === RegsWeekModeEnum.Department
        //編號
        const no = searchText ? searchText.split(' ')[0] : null

        // 月班模式
        if (isDepartmentMode) {
            //依科別查詢月班表
            regsQueryMonthClinicByDivNo({
                encounterDate: `${currentDate} 00:00:00`,
                divNo: no
            }).then(res => {
                if (res.err === ApiErrorStatusEnum.Success) {
                    //設定資料
                    parserMonthData(res.data)
                }
            })
        } else { // 醫師模式
            //依醫師查詢月班表
            regsQueryMonthClinicByDoctorNo({
                encounterDate: `${currentDate} 00:00:00`,
                doctorNo: no
            }).then(res => {
                if (res.err === ApiErrorStatusEnum.Success) {
                    //設定資料
                    parserMonthData(res.data)
                }
            })
        }
    }

    /**
     * 解析月班資料
     * @param data {Array<Object>}
     * @return {void}
     */
    const parserMonthData = (data) => {
        //設定資料
        const newData = [...defaultWeekData()]

        data.forEach(item => {
            //取得星期幾
            let week = parseInt(item.week)
            if (week === 0) week = 7
            //取得時段
            const apn = item.apn
            //根據時段去設定週班資料
            switch (apn) {
                case TimeslotEnum.Morning:
                    newData[week - 1].morning.push(...item.regsClinicList)
                    break
                case TimeslotEnum.Afternoon:
                    newData[week - 1].afternoon.push(...item.regsClinicList)
                    break
                case TimeslotEnum.Night:
                    newData[week - 1].night.push(...item.regsClinicList)
                    break
            }
        })
        //設定資料
        setData(newData)
    }

    /**
     * 關閉Popup
     * @return {void}
     */
    const handleClosePopup = () => setShowPopup(false)

    /**
     * 取得本週日期
     * @return {Array<Object>}
     */
    const getWeekDates = () => {
        //避免第一次進來時沒有日期報錯
        if (!currentDate) {
            return []
        }

        const date = new Date(currentDate);
        const dayOfWeek = date.getDay(); // 0 (Sunday) to 6 (Saturday)
        const startDate = new Date(date);
        // 設定當前日期為星期日的情況，使其成為星期天 (0) 以符合 ISO 8601，其中星期一是1
        startDate.setDate(date.getDate() + (dayOfWeek === 0 ? -6 : 1 - dayOfWeek)); // Start from Monday

        // 保存該週的所有日期
        const weekDates = [];
        // 生成該週的所有日期 (從星期一到星期日)
        for (let i = 0; i < 7; i++) {
            const current = new Date(startDate);
            current.setDate(startDate.getDate() + i);
            // 格式化日期或者直接使用 Date 物件
            weekDates.push(`${time2String(current, "MM/DD")}(${t(`general.dateTime.weekRange.short.${(i + 1)}`)})`); // Convert to YYYY-MM-DD format
        }

        return weekDates
    }

    /**
     * 點擊上一週或下一週時
     * @param isIncrement {Boolean} 是否增加
     * @return {void}
     */
    const handleCurrentWeekOnClick = (isIncrement = true) => {

        const date = new Date(currentDate);
        const newWeekDate = new Date(date);
        if (isIncrement)
            newWeekDate.setDate(date.getDate() + 7);
        else
            newWeekDate.setDate(date.getDate() - 7);

        setCurrentDate(time2String(newWeekDate, "YYYY-MM-DD"))

        // 重新設定新問診
        setCurrentRightClick({week: null, timeslot: null})
    }

    /**
     * 取得週的指定星期幾的日期
     * @param dayOfWeek {Number} 星期幾 (0: 星期日, 1: 星期一, ..., 6: 星期六)
     * @return {String}
     */
    function getDateOfWeek(dayOfWeek) {
        // 如果當前日期不存在，則返回空字串
        if (!currentDate) {
            return ''
        }
        const date = new Date(currentDate);
        const days = date.getDay(); // 0 (Sunday) to 6 (Saturday)
        const startDate = new Date(date);
        startDate.setDate(date.getDate() + (days === 0 ? -6 : 1 - days)); // Start from Monday

        const targetDate = new Date(startDate)
        targetDate.setDate(startDate.getDate() + dayOfWeek)
        console.log(targetDate)

        // 格式化日期為YYYY-MM-DD
        return `${targetDate.getFullYear()}/${(targetDate.getMonth() + 1).toString().padStart(2, '0')}/${targetDate.getDate().toString().padStart(2, '0')}`
    }

    /**
     * 新增新問診時
     * @param week {Number} 週數
     * @param timeslot {Number} 時段
     */
    const handleOnAddNewAppointment = (week, timeslot) => setCurrentRightClick({week: week, timeslot: timeslot})

    /**
     * 顯示Popup
     * @param data {Object}
     * @param mode {Number}
     * @return {void}
     */
    const handleShowPopup = (data, mode = PopupModeEnum.Add) => {
        // 設定Popup模式
        setPopupMode(mode)
        //取得資料
        const newData = mode === PopupModeEnum.Modify ?
            {...data} :
            {
                //複製原先資料
                ...data,
                //設定日期
                date: getDateOfWeek(currentRightClick.week),
                //當前選擇醫生
                doctor: +filterMode === RegsWeekModeEnum.Doctor ? (searchText ? searchText : null) : null,
                //當前選擇科別
                department: +filterMode === RegsWeekModeEnum.Department ? (searchText ? searchText : null) : null
            }
        // 設定選擇的資料
        setSelectedData(newData)
        // 顯示Popup
        setShowPopup(true)
    }

    /**
     * 取得月班資料
     * @return {void}
     */
    const handleGetMonthData = () => {
        // 關閉Popup
        setShowPopup(false)
        // 重新設定新問診
        setCurrentRightClick({week: null, timeslot: null})
        // 取得月班資料
        getMonthData()
    }

    /**
     * 第一次進來時取得基礎資料
     * @return {void}
     */
    useMemo(() => {
        setData(defaultWeekData())
        // 設定當前週
        setCurrentDate(time2String(new Date(), "yyyy-MM-DD"))
        // 取得月班基礎資料
        regsMonthClinicBaseData({}).then(res => {
            // 成功
            if (res.err === ApiErrorStatusEnum.Success) {
                // 設定基礎資料
                setBaseData(res.data)
                // 設定科別
                setAllDoctorOrDept(res.data.divisionList)
            }
        })
    }, [])

    /**
     * 當日期改變時
     * @return {void}
     */
    useEffect(() => {
        if (!!currentDate) {
            //取得月班資料
            getMonthData()
        }
    }, [currentDate])

    /**
     * 搜尋文字改變時
     * @return {void}
     */
    useEffect(() => {
        setDoctorOrDeptOptionList(getDoctorOrDeptOptions())
        //切割有空白的文字為陣列
        const splitArray = searchText ? searchText.split(' ') : []
        //取得月班資料
        getMonthData()

    }, [searchText, allDoctorOrDept])

    return (
        <div className="w-full">
            <div className="w-full overflow-x-scroll">
                {/*頂部區塊*/}
                <div className="w-full min-w-[1688px] flex flex-row items-center space-x-8 px-[26px] py-2 text-left">

                    <Select
                        data={{
                            label: '搜尋條件',
                            options: [
                                {value: '屏東義大醫院', label: '屏東義大醫院'},
                            ]
                        }}
                        value="屏東義大醫院"
                        showLabel={false}
                    />
                    <div className="flex flex-row space-x-6">
                        <Button
                            color={ColorEnum.Secondary} variant={ButtonVariantEnum.Outlined}
                            size={ButtonSizeEnum.Medium}
                            onClick={() => handleCurrentWeekOnClick(false)}>
                            {t('general.dateTime.lastWeek')}
                        </Button>
                        {/*<input className="border-2 text-center px-2 rounded-[6px]" type="week" value={currentDate}
                               onChange={(e) => handleDateOnChange(e)}/>*/}
                        <DatePicker
                            label=""
                            value={dayjs(currentDate)}
                            displayWeekNumber
                            onChange={handleDateOnChange}
                        />
                        <Button color={ColorEnum.Secondary} variant={ButtonVariantEnum.Outlined}
                                size={ButtonSizeEnum.Medium}
                                onClick={() => handleCurrentWeekOnClick()}>
                            {t('general.dateTime.nextWeek')}
                        </Button>
                    </div>
                    <div className="flex flex-row space-x-6 items-center">
                        {/*RadioButton*/}
                        <div className="flex flex-row space-x-4 items-center">
                            <RadioGroup
                                row defaultValue={filterMode} value={filterMode}
                                optionProps={{
                                    options: [
                                        // 科別
                                        {label: t('general.department'), value: RegsWeekModeEnum.Department},
                                        // 醫師
                                        {label: t('general.doctor'), value: RegsWeekModeEnum.Doctor}
                                    ]
                                }}
                                size={SizeEnum.Medium}
                                onChange={(e) => handleModeOnChange(e.target.value)}/>
                        </div>
                        {
                            doctorOrDeptOptionList &&
                            <Search
                                disablePortal={false}
                                options={doctorOrDeptOptionList}
                                getOptionLabel={(option) => getFilterOptionContent(option)}
                                onChange={(_, value) => handleFilterOptionOnClick(value)}
                                onInputChange={(e) => handleSearchTextOnChange(e)}/>
                        }
                    </div>
                </div>
                {/*月班Content*/}
                <div className="flex flex-col w-full min-w-[1688px]">
                    {/**日期區 */}
                    <div className="w-full py-1 pl-[72px] pr-[16px] min-h-[40px] flex items-center mt-[5px]">
                        <div className="w-full min-w-[1457px] flex flex-row gap-2">
                            {
                                getWeekDates().map((date, index) =>
                                    <div className="w-full min-w-[220px] flex justify-center" key={index}>
                                        <p>{date}</p>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    {/**區塊 - 早上*/}
                    <div className="w-full min-w-[1688px] h-[208px] bg-[#EBF8FF] flex flex-row gap-2 px-4 py-1  ">
                        <div className="w-12 h-[200px] flex-row justify-center items-center px-1 py-[52px]">
                            <div className="w-10 h-24 text-base">
                                <p className="w-12 text-center">{t('general.dateTime.timeslot.short.morning')}</p>
                                <p className="w-12 text-center">08:30</p>
                                <p className="w-12 text-center">~</p>
                                <p className="w-12 text-center">12:00</p>
                            </div>
                        </div>
                        {
                            data && data.map((d, index) => (
                                <ScheduleTimeBox
                                    key={index}
                                    list={d.morning}
                                    timeslot={TimeslotEnum.Morning}
                                    week={index}
                                    baseData={baseData}
                                    isAddAppointment={currentRightClick.week === index && currentRightClick.timeslot === TimeslotEnum.Morning}
                                    onAddAppointment={handleOnAddNewAppointment}
                                    showPopup={handleShowPopup}/>
                            ))
                        }
                        <div className="h-[208px] flex items-center">
                            <div
                                className="flex items-center h-[40px] border-solid border-2 border-gray-400 rounded-lg">
                            </div>
                        </div>
                    </div>
                    {/**區塊 - 下午 */}
                    <div className="w-full min-w-[1688px] h-[208px] bg-customYellow flex flex-row gap-2 px-4 py-1">
                        <div className="w-12 h-[200px] flex-row justify-center items-center px-1 py-[52px]">
                            <div className="w-10 h-24 text-base">
                                <p className="w-12 text-center">{t('general.dateTime.timeslot.short.afternoon')}</p>
                                <p className="w-12 text-center">13:30</p>
                                <p className="w-12 text-center">~</p>
                                <p className="w-12 text-center">17:00</p>
                            </div>
                        </div>
                        {
                            data && data.map((d, index) => (
                                <ScheduleTimeBox
                                    key={index}
                                    list={d.afternoon}
                                    timeslot={TimeslotEnum.Afternoon}
                                    week={index}
                                    baseData={baseData}
                                    isAddAppointment={currentRightClick.week === index && currentRightClick.timeslot === TimeslotEnum.Afternoon}
                                    onAddAppointment={handleOnAddNewAppointment}
                                    showPopup={handleShowPopup}/>
                            ))
                        }
                        <div className="h-[208px] flex items-center">
                            <div
                                className="flex items-center h-[40px] border-solid border-2 border-gray-400 rounded-lg">
                            </div>
                        </div>
                    </div>
                    {/**區塊 - 晚上 */}
                    <div className="w-full min-w-[1688px] h-[208px] bg-[#FBF5FF] flex flex-row gap-2 px-4 py-1">
                        <div className="w-12 h-[200px] flex-row justify-center items-center px-1 py-[52px]">
                            <div className="w-10 h-24 text-base">
                                <p className="w-12 text-center">{t('general.dateTime.timeslot.short.night')}</p>
                                <p className="w-12 text-center">18:00</p>
                                <p className="w-12 text-center">~</p>
                                <p className="w-12 text-center">21:00</p>
                            </div>
                        </div>
                        {
                            data && data.map((d, index) => (
                                    <ScheduleTimeBox
                                        key={index}
                                        list={d.night}
                                        timeslot={TimeslotEnum.Night}
                                        week={index}
                                        baseData={baseData}
                                        isAddAppointment={currentRightClick.week === index && currentRightClick.timeslot === TimeslotEnum.Night}
                                        onAddAppointment={handleOnAddNewAppointment}
                                        showPopup={handleShowPopup}/>
                                )
                            )
                        }
                        <div className="h-[208px] flex items-center">
                            <div
                                className="flex items-center h-[40px] border-solid border-2 border-gray-400 rounded-lg">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*月班Popup*/}
            {
                showPopup && (
                    <AppointmentPopup
                        mode={popupMode}
                        filterMode={filterMode}
                        baseData={baseData}
                        data={selectedData}
                        closePopup={handleClosePopup}
                        getMonthData={handleGetMonthData}/>
                )
            }
        </div>
    )
}

export default MonthMaintenance
