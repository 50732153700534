import { useSelector } from 'react-redux'
import './print.scss'

/**
 * 轉診回函 - 信封列印
 * @returns {JSX.Element}
 */
const PrintReplyLetterLabel = () => {
    const printData = useSelector((state) => state.print.printData)
    return (
        <>
            {printData?.map((item) => {
                return (
                    <div className="label-page p-1 flex items-center">
                        <div>
                            <h1 className="font-bold text-xl">平信</h1>
                            {item.recipient.split('\n').map((text, index) => {
                                return index === 0 ? (
                                    <p key={`recipient-label${index}`}>
                                        {text.replace(/\s/g, '')}
                                    </p>
                                ) : (
                                    <p key={`recipient-label${index}`}>
                                        {text}
                                    </p>
                                )
                            })}
                        </div>
                    </div>
                )
            })}
        </>
    )
}

export default PrintReplyLetterLabel
