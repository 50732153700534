import { useSelector } from 'react-redux'
import A4Print from './A4Print'
import { time2String } from 'edah_utils/dist'
import { DATE_FORMAT } from '../../constants/common'
import './print.scss'

/**
 * 未收到原醫療院所回覆名單 - 列印內容
 * @returns {JSX.Element}
 */
const PrintTranOutNoReplyLetterListContent = () => {
    const printData = useSelector((state) => state.print.printData)

    return (
        <>
            <div className="break-after">
                <div className="w-full rounded-md grid grid-cols-[10%,90%] mb-5">
                    <div>From：</div>
                    <div>
                        <p className="text-left mb-2">
                            義大醫療財團法人義大醫院轉診中心
                            (機構代號1142120001)
                        </p>
                        <p className="text-left">高雄市燕巢區角宿里義大路1號</p>
                    </div>
                </div>
                <div className="w-full grid grid-cols-[5%,5%,90%] mt-5">
                    <div></div>
                    <div>To: </div>
                    <div className="border-[#D4D4D8] border p-2">
                        <div className="flex justify-between">
                            <div>{printData[0].tranOutHospName}</div>
                            <div>轉診中心收</div>
                        </div>
                        <div>
                          {/* FIXME: 暫用第一筆資料的轉診醫院顯示 */}
                            {`${printData[0].tranOutHospZipCode} ${printData[0].tranOutHospAddress}`}
                        </div>
                    </div>
                </div>
            </div>
            <div className="mx-auto p-4 font-sans">
                <p className="text-right mb-3">轉診組您好:</p>
                <p>
                    承蒙貴醫院(診所)接受並照顧本院轉診之個案，因迄今尚未收到貴醫院(診所)的轉診
                    回函，懇請協助查閱後能否將個案診治經過回覆給我們，同時也煩請將此單章一併寄回，謝謝您的幫忙!
                </p>
                <p className="text-right mt-3 mb-2">
                    義大醫院財團法人義大醫院轉診服務中心
                </p>
                <table className="w-full border-collapse border border-[#111111]/15 text-sm mb-4">
                    <thead>
                        <tr className="text-[#18181b] border-b border-[#111111]/15">
                            <th
                                rowSpan="2"
                                className="break-words p-2 border border-[#111111]/15"
                            >
                                編號
                            </th>
                            <th
                                rowSpan="2"
                                className="break-words p-2 border border-[#111111]/15"
                            >
                                病歷號
                            </th>
                            <th
                                rowSpan="2"
                                className="p-2 border border-[#111111]/15 w-[20%]"
                            >
                                姓名
                            </th>
                            <th
                                rowSpan="2"
                                className="p-2 border border-[#111111]/15"
                            >
                                身分證號
                            </th>
                            <th
                                rowSpan="2"
                                className="p-2 border border-[#111111]/15"
                            >
                                轉出日期
                            </th>
                            <th
                                colSpan="6"
                                className="break-words p-2"
                            >
                                追蹤狀態
                            </th>
                        </tr>
                        <tr>
                            <th className="border border-[#111111]/15 p-1 w-[10%]">
                                住院
                            </th>
                            <th className="border break-words border-[#111111]/15 p-2 w-[20%]">
                                門診追蹤治療
                            </th>
                            <th className="border border-[#111111]/15 p-1 w-[20%]">
                                已予適當處置並離院
                            </th>
                            <th className="border border-[#111111]/15 p-1 w-[10%]">
                                未到院
                            </th>
                            <th className="border border-[#111111]/15 p-1 w-[10%]">
                                查無此人
                            </th>
                            <th className="border border-[#111111]/15 p-1 w-[10%]">
                                未持轉診單
                            </th>
                            <th className="border border-[#111111]/15 p-1 w-[10%]">
                                其他
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {printData ? (
                            printData.map((item, index) => (
                                <tr
                                    key={index}
                                    className={`h-[50px] text-[#18181b] ${index === printData.length ? '' : 'border-b border-[#111111]/15'}`}
                                >
                                    <td className="break-words p-2 border-r border-[#111111]/15">
                                        {item.encounterId}
                                    </td>
                                    <td className="break-words p-2 border-r border-[#111111]/15">
                                        {item.patientId}
                                    </td>
                                    <td className="break-words p-2 border-r border-[#111111]/15">
                                        {item.patientName}
                                    </td>
                                    <td className="break-words p-2 border-r border-[#111111]/15">
                                        {item.idNo}
                                    </td>
                                    <td className="break-words p-2 border-r border-[#111111]/15">
                                        {time2String(
                                            item.tranDate,
                                            DATE_FORMAT
                                        )}
                                    </td>
                                    <td className="border-r border-[#111111]/15"></td>
                                    <td className="border-r border-[#111111]/15"></td>
                                    <td className="border-r border-[#111111]/15"></td>
                                    <td className="border-r border-[#111111]/15"></td>
                                    <td className="border-r border-[#111111]/15"></td>
                                    <td className="border-r border-[#111111]/15"></td>
                                </tr>
                            ))
                        ) : (
                            <></>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    )
}

/**
 * 列印未收到原醫療院所回覆名單
 * @returns {JSX.Element}
 */
const PrintTranOutNoReplyLetterList = () => {
    return (
        <A4Print>
            <PrintTranOutNoReplyLetterListContent />
        </A4Print>
    )
}

export default PrintTranOutNoReplyLetterList
