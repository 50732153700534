import {v1Api} from "../Http"
import {HttpMethodEnum} from "edah_utils/dist"

/**
 * 手動查詢病人 (主畫面)
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const queryPatientBySearch = (params) => v1Api.request({
    url: '/mris/patient/queryPatientBySearch',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 手動查詢病人依照欄位 (POPUP視窗)
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const queryPatientByField = (params) => v1Api.request({
    url: '/mris/patient/queryPatientByField',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 查詢病歷 & 掛號紀錄
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const queryPatientAndRegByIdNoAndBirthDate = (params) => v1Api.request({
    url: '/mris/patient/queryPatientAndRegByIdNoAndBirthDate',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 多因素查詢病歷
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const queryPatientByMultiField = (params) => v1Api.request({
    url: '/mris/patient/queryPatientByMultiField',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 新增初診預約存檔
 * @param data data
 * @returns {AxiosPromise}
 */
const addNewPatient = (data) => v1Api.request({
    url: '/mris/patient/addNewPatient',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 病歷編輯存檔
 * @param data data
 * @returns {AxiosPromise}
 */
const patientRegEditSave = (data) => v1Api.request({
    url: '/mris/patient/patientRegEditSave',
    method: HttpMethodEnum.PATCH,
    data
})

/**
 * 診間預約-依照病歷號查詢病歷
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const queryPatientByPatientIdForClinic = (params) => v1Api.request({
    url: '/mris/patient/queryPatientByPatientIdForClinic',
    method: HttpMethodEnum.GET,
    params
})

export {
    queryPatientBySearch,
    queryPatientByField,
    queryPatientAndRegByIdNoAndBirthDate,
    queryPatientByMultiField,
    addNewPatient,
    patientRegEditSave,
    queryPatientByPatientIdForClinic
}