import {v1Api} from "../Http"
import {HttpMethodEnum} from "edah_utils/dist"

/**
 * 查詢給號方式清單
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const regsNotypeQueryByNoType = (params) => v1Api.request({
    url: '/regs/notype/queryRegsNotypeByNoType',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 給號方式存檔
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const regsNotypeUpdateNotype = (data) => v1Api.request({
    url: '/regs/notype/updateRegsNotype',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 給號維護刪除
 * @param params {Object} data
 * @return {AxiosPromise}
 */
const regsNotypeDeleteNotype = (params) => v1Api.request({
    url: '/regs/notype/deleteRegsNoType',
    method: HttpMethodEnum.DELETE,
    params
})

export {
    regsNotypeQueryByNoType,
    regsNotypeUpdateNotype,
    regsNotypeDeleteNotype
}