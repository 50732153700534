import {v1Api} from "../Http"
import {HttpMethodEnum} from "edah_utils/dist"

/**
 * 查詢停帶診作業需要的基本資料
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const regsQueryStopClinicBaseData = (params) => v1Api.request({
    url: '/regs/reg/queryRegsStopBaseData',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 查詢停代診明細
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const regsQueryStopClinicReg = (params) => v1Api.request({
    url: '/regs/reg/queryStopClinicReg',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 查詢聯絡紀錄
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const regsQueryStopClinicContactHistory = (params) => v1Api.request({
    url: '/regs/reg/queryStopdregd',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 刪除聯絡紀錄
 * @param params params
 * @return {AxiosPromise}
 */
const regsDelStopClinicContactHistory = (params) => v1Api.request({
    url: '/regs/reg/delStopdregd',
    method: HttpMethodEnum.DELETE,
    params
})

/**
 * 取消停代診
 * @param data data
 * @return {AxiosPromise}
 */
const regsCancelStopClinicAndLocum = (data) => v1Api.request({
    url: '/regs/regsclinic/cancelStopAndLocum',
    method: HttpMethodEnum.PATCH,
    data
})

/**
 * 新增聯絡記錄存檔&退掛及完成確認
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const regsAddStopdregdAndCancelAndCompSave = (data) => v1Api.request({
    url: '/regs/reg/addStopdregdAndCancelAndCompSave',
    method: HttpMethodEnum.POST,
    data
})

export {
    regsQueryStopClinicBaseData,
    regsQueryStopClinicReg,
    regsQueryStopClinicContactHistory,
    regsDelStopClinicContactHistory,
    regsCancelStopClinicAndLocum,
    regsAddStopdregdAndCancelAndCompSave
}