import React, {memo, useEffect, useState} from "react";
import {t} from "i18next";
import {
    Button, ButtonSizeEnum, ButtonVariantEnum, ColorEnum,
    Card, Field, AlertTypeEnum, HisTree, HisTreeTextAlignEnum
} from "edah-component/dist/index";
import {ApiErrorStatusEnum, objectIsEmpty, stringIsEmpty, time2String} from "edah_utils/dist";
import {Checkbox} from "edah-component/dist";
import {cpoeQueryDoctorClinic} from "../../api/v1/Cpoe";
import useToast from "../../hooks/useToast";
import ClinicPatientList from "../../components/CPOE/ClinicPatientList";
import CpoeMain from "../../components/CPOE/CpoeMain";
/**
 * 時段
 * @type {{"": string, "1": string, "2": string, "3": string}}
 */
const apnList = {
    "1": '早',
    "2": '午',
    "3": '晚',
}
/**
 * EMR操作主畫面，通過看診清單進入cpoe操作畫面
 * @return {JSX.Element}
 */
const EmrMainV = ()=>{
    //Toast Message Hooks
    const showToast = useToast()
    //診間資訊相關資料
    const [clinicInfo, setClinicInfo] = useState({
        // 看診日期
        encounterDate: '2024-10-14 00:00:00',
        titleDivName: '',
        divName: '',
        apn: '',
        doctorNo: '2345',
        doctorName: '',
        clinicOpenDate: '2024-04-04',
        clinicNo: '',
        divNo: '',
    })
    // 看診診間清單
    const [clinicDivList, setClinicDivList] = useState([])
    // 病人資料
    const [patientInfo, setPatientInfo] = useState({})
    // 是否顯示cpoe
    const [isShowCpoeView, setShowCpoeView] = useState(false)

    // hisTree元件需要的欄位
    const commonItems = []
    // hisTree元件
    const [functionList,setFunctionList] = useState([
        { id: "AP-SS-1", name: 'IC卡功能',
            children:[{id: 'AP-SS-1-1', name: 'PACS'}]
        },
        { id: "AP-SS-2", name: '檢驗報告查詢',
            children:[
                {id: 'AP-SS-2-1', name: '檢查報告查詢'},
                {id: 'AP-SS-2-2', name: '就診紀錄查詢'},
                {id: 'AP-SS-2-3', name: '檢查報告查詢'},
                {id: 'AP-SS-2-4', name: '電子病歷查詢'},
                {id: 'AP-SS-2-5', name: '簽章病例查詢'},
                {id: 'AP-SS-2-6', name: '重大傷病申請'},
            ]},
        { id: "AP-SS-3", name: '用藥安全',
            children:[
                {id: 'AP-SS-3-1', name: '掛號'},{id: 'AP-SS-3-2', name: '預約住院'},
                {id: 'AP-SS-3-3', name: '診斷證明書'},{id: 'AP-SS-3-4', name: '同意書'},
                {id: 'AP-SS-3-5', name: '轉診'},{id: 'AP-SS-3-6', name: '約診單'},
                {id: 'AP-SS-3-7', name: '重印單據'},{id: 'AP-SS-3-8', name: '個人史'},
                {id: 'AP-SS-3-9', name: '醫病共享決策'}
            ]
        },
        { id: "AP-SS-4", name: '評估/療程紀錄'},
        { id: "AP-SS-5", name: '照會'},
        { id: "AP-SS-6", name: '論質計酬'},
        { id: "AP-SS-7", name: '癌症中心'},
        { id: "AP-SS-8", name: '調閱病歷'}
    ])

    // 使用memo来缓存组件，减少不必要的重新渲染
    // const MemoizedClinicPatientList = memo(ClinicPatientList);
    // const MemoizedCpoeMain = memo(CpoeMain);

    /**
     * 回到病人清單
     */
    const handleReturnPtList = ()=>{
        setShowCpoeView(false)
    }

    /**
     * 選擇項目
     * @param id {String}
     * @param name {String}
     * @param hasChildren {Boolean}
     * @return {void}
     */
    const handleSelectItem = (id, name, hasChildren) => {

    }

    /**
     * 取得醫師當天門診，剛進入和日期切換需呼叫
     * encounterDate=2024-07-18 00:00:00&doctorNo=1774
     * @returns {void}
     */
    const queryDoctorClinic = ()=>{
        cpoeQueryDoctorClinic({
            encounterDate: clinicInfo.encounterDate,
            doctorNo: clinicInfo.doctorNo
        }).then(res => {
            if(res.err === ApiErrorStatusEnum.Success){
                setClinicDivList(res.data)
                setClinicInfo(res.data.length > 0 ?res.data[0] : [])
            }else{
                showToast({message: res.msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 畫面初始化查詢診間資訊
     */
    useEffect(()=>{
        queryDoctorClinic()
    },[])

    return (
        <div className="w-full p-4 bg-[#FAFAFA] space-y-2.5 max-h-[calc(100vh-101px)] overflow-y-auto">
            {/*診間資訊，病人資訊*/}
            <div className="flex flex-row flex-wrap justify-start items-center gap-2">
                <Card title={"診間資訊"} sx={{flex: 1}}>
                    <div className="flex flex-row items-center justify-start gap-2">
                        <div className="flex-1 flex-col">
                            <Field labelWidth={80} label={t('日期')}>
                                <p className="text-left">{time2String(clinicInfo.encounterDate, "yyyy-MM-DD")}</p>
                            </Field>
                            <Field labelWidth={80} label={t('時段')}>
                                <p className="text-left">{apnList[clinicInfo.apn] || ''}</p>
                            </Field>
                        </div>
                        <div className="flex-1 flex-col">
                            <Field labelWidth={80} label={t('診室名稱')}>
                                <p className="text-left">{clinicInfo.titleDivName}</p>
                            </Field>
                            <Field labelWidth={80} label={t('看診醫師')}>
                                <p className="text-left">{clinicInfo.doctorName}</p>
                            </Field>
                        </div>
                        <div className="flex-1 flex-col">
                            <Field isShowColon={false} labelWidth={80} label={clinicInfo.divName}><span></span> </Field>
                            <Field labelWidth={80} label={t('開放日期')}>
                                <p className="text-left">{clinicInfo.clinicOpenDate}</p>
                                {/*<Checkbox size="small" label={clinicInfo.clinicOpenDate}/>*/}
                            </Field>
                        </div>
                    </div>
                </Card>
                <Card title={"病人資訊"} sx={{flex: 1}}>
                    <div className="flex flex-row items-center justify-start gap-2">
                        <div className="flex-1 flex-col">
                            <Field labelWidth={80} label={t('病歷號')}>
                                <p className="text-left">{patientInfo?.patientId || ''}</p>
                            </Field>
                            <Field labelWidth={80} label={t('姓名')}>
                                <p className="text-left">{patientInfo?.patientName || ''}</p>
                            </Field>
                        </div>
                        <div className="flex-1 flex-col">
                            <Field labelWidth={80} label={t('性別')}>
                                <p className="text-left">{patientInfo?.genderName || ''}</p>
                            </Field>
                            <Field labelWidth={80} label={t('生日')}>
                                <p className="text-left">{time2String(patientInfo?.birthDate, "yyyy-MM-DD") || ''}</p>
                            </Field>
                        </div>
                    </div>
                </Card>
                {isShowCpoeView && (<Button
                    color={ColorEnum.Secondary} variant={ButtonVariantEnum.Contained}
                    size={ButtonSizeEnum.Medium} onClick={handleReturnPtList}>
                    {t('回病人清單')}
                </Button>)}
            </div>
            <div className="flex flex-wrap justify-start items-start gap-2">
                {/*功能列表*/}
                <Card title={"功能表"} sx={{width: '232px'}}>
                    <HisTree
                        menuTree={functionList}
                        textAlign={HisTreeTextAlignEnum.Left}
                        frequentlyUsedArray={commonItems}
                        onSelectNode={handleSelectItem}/>
                </Card>
                <div className="flex-1 w-0 overflow-auto">
                    {/*病人挂號清單   */}
                    {!isShowCpoeView && (<ClinicPatientList
                        clinicList={clinicDivList}
                        clinicInfo={clinicInfo}
                        setClinicInfo={setClinicInfo}
                        setPatientInfo={setPatientInfo}
                        setShowCpoeView={setShowCpoeView}
                    />)}
                    {/*Cpoe畫面*/}
                    {isShowCpoeView && (<CpoeMain patientInfo={patientInfo} />)}
                </div>
            </div>

        </div>
    )
}

export default EmrMainV