import {t} from "i18next"
import CalendarYearly from "../../components/Regs/CalendarMaintenance/CalendarYearly";
import React, {useEffect, useMemo, useState} from "react";
import ToggleButton from "../../components/Regs/CalendarMaintenance/ToggleButton";
import {
    ApiErrorStatusEnum,
    arrayIsEmpty,
    enum2Array,
    RegsHolidayKindEnum,
    stringIsEmpty,
    time2String
} from "edah_utils/dist"
import {delHoliday, queryHolidayByYear, queryJustNameByCategories, updateHoliday} from "../../api/v1/Menu"
import useToast from "../../hooks/useToast"
import DeletePromptPopup from "../../components/Regs/CalendarMaintenance/Popup/DeletePromptPopup";
import {
    AlertTypeEnum,
    Button,
    Select,
    Checkbox,
    BlueColorEnum,
    GrayColorEnum,
    ColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    SizeEnum,
    TextField,
    IconEnum,
    SelectWidthEnum
} from 'edah-component/dist'

/**
 * 行事曆維護
 * @return {JSX.Element}
 * @constructor
 */
const CalendarMaintenance = () => {
    /**
     * Show Toast
     */
    const showToast = useToast()

    /**
     * 假日種類顏色
     */
    const holidayKindColors = ['#FFA6A5', '#85DFFF', '#C5C5C5', '#D16059', '#3586A0']
    /**
     * 假期種類
     */
    const holidayKindArray = enum2Array(RegsHolidayKindEnum).map((item, index) => ({
        // 假日種類值
        kindNo: item.value,
        // 假日種類名稱
        kindName: t(`Regs.RegsHolidayKindEnum.${item.value}`),
        // 假日種類顏色
        color: holidayKindColors[index]
    }))
    //
    const [calendarCategoryArray, setCalendarCategoryArray] = useState([])
    //行事曆資料
    const [calendarData, setCalendarData] = useState(null)
    //選擇的年份
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
    //顯示編輯國定假日明細設定區塊
    const [showPublicHolidaySetting, setShowPublicHolidaySetting] = useState(false);
    //星期六勾選狀態
    const [saturdayChecked, setSaturdayChecked] = useState(false);
    //星期日勾選狀態
    const [sundayChecked, setSundayChecked] = useState(false);
    //行事曆類別
    const [calendaryCategory, setCalendaryCategory] = useState("");
    //選擇的假日類別
    const [selectedHolidayType, setSelectedHolidayType] = useState(null)
    //年份選項
    const [yearOptions, setYearOptions] = useState([])
    //假日資料
    const [data, setData] = useState([])
    //國定假日資料
    const [publicHolidayData, setPublicHolidayData] = useState(null)
    //國定假日編輯資料
    const [publicHolidayModifyData, setPublicHolidayModifyData] = useState(null)
    //國定假日類別Array
    const [publicHolidayKindArray, setPublicHolidayKindArray] = useState(null)
    //顯示刪除提示popup
    const [showDeletePromptPopup, setShowDeletePromptPopup] = useState(false)
    //編輯模式下選擇的國定假日
    const [choicedPublicHolidayData, setChoicedPublicHolidayData] = useState(false)

    /**
     * 產生日曆Array，用來記錄假日類別
     * @param year {Number} 年分
     * @return {Array<Array<Object>>} 整年的日曆數據。每個月是一個子數組，包含該月每天。 
     */
    const generateYearCalendar = (year) => {
        const months = Array.from({length: 12}, (_, month) => {
            const daysInMonth = new Date(year, month + 1, 0).getDate()
            return Array.from({length: daysInMonth}, (_, day) => {
                const date = new Date(year, month, day + 1)
                let dayOfWeek = date.getDay()
                if (date.getDay() === 0)
                    dayOfWeek = 7   //星期天
                return {
                    //號
                    date: day + 1,
                    //星期 1,2,3,4,5,6,7
                    dayOfWeek: dayOfWeek,
                    //假日類別
                    holidayKind: null,
                    //已選擇狀態
                    selected: false,
                    //日期
                    yearMonthDate: time2String(date, "YYYY-MM-DD 00:00:00")
                }
            })
        })

        return months;
    }

    /**
     * 年份下拉選單改變時
     * @param value {number} 年份
     * @return {void} 
     */
    const handleYearOnChange = (value) => {
        //重置編輯區塊
        resetEditArea()
        //重置目前的行事曆資料的選擇狀態
        resetCalendarDataSelected()

        setSelectedYear(value)
    }

    /**
     * 星期六勾選變更事件
     * @return {void}
     */
    const handleSaturdayChange = () => {
        const selected = !saturdayChecked
        setSaturdayChecked(_prev => selected)

        //將行事曆所有星期六的selected狀態設為true
        const updatedCalendar = calendarData.map(month =>
            month.map(day => ({
                ...day,
                selected: day.dayOfWeek === 6 ? selected : day.selected
            }))
        )
        setCalendarData(updatedCalendar)
    }

    /**
     * 星期日勾選變更事件
     * @return {void}
     */
    const handleSundayChange = () => {
        const selected = !sundayChecked
        setSundayChecked(_prev => selected)

        //將行事曆所有星期天的selected狀態設為true
        const updatedCalendar = calendarData.map(month =>
            month.map(day => ({
                ...day,
                selected: day.dayOfWeek === 7 ? selected : day.selected
            }))
        )
        setCalendarData(updatedCalendar)
    }

    /**
     * 重置目前的行事曆資料的選擇狀態
     * @return {void}
     */
    const resetCalendarDataSelected = () => {
        const updatedCalendar = calendarData.map(month =>
            month.map(day => ({
                ...day,
                selected: false
            }))
        )
        setCalendarData(updatedCalendar)
    }

    /**
     * 重置編輯區塊
     * @return {void}
     */
    const resetEditArea = () => {
        //關閉編輯國定假日明細
        setShowPublicHolidaySetting(false)
        setSaturdayChecked(false)
        setSundayChecked(false)
        setSelectedHolidayType(null)
    }

    /**
     * 行事曆類別下拉選單改變時
     * @param value {String} 行事曆類別
     */
    const handleCalendaryCategoryOnChange = (value) => {
        //重置編輯區塊
        resetEditArea()
        //重置目前的行事曆資料的選擇狀態
        resetCalendarDataSelected()

        setCalendaryCategory(value)
    }

    /**
     * 假日類別變更事件
     * @param type {String} 
     */
    const handleHolidayTypeOnChange = (type) => {
        if (type === selectedHolidayType) {
            setSelectedHolidayType(null)
        } else {
            setSelectedHolidayType(type)
        }
    }

    /**
     * 點選日期事件
     * @param month {Number} 
     * @param day {Number}
     * @return {void}
     */
    const onDateClick = (month, day) => {
        const updatedCalendar = calendarData.map((monthData, monthIndex) =>
            monthData.map((dayData) => ({
                ...dayData,
                selected: (monthIndex + 1) === month && dayData.date === day ? !dayData.selected : dayData.selected,
            }))
        )
        setCalendarData(updatedCalendar)
        //檢查快選全部星期六勾選狀態
        const allSaturdaysSelected = updatedCalendar.every(monthData =>
            monthData.every(dayData => dayData.dayOfWeek !== 6 || dayData.selected)
        );
        setSaturdayChecked(allSaturdaysSelected)
        //檢查快選全部星期天的勾選狀態
        const allSundaysSelected = updatedCalendar.every(monthData =>
            monthData.every(dayData => dayData.dayOfWeek !== 7 || dayData.selected)
        );
        setSundayChecked(allSundaysSelected)
    }

    /**
     * 點選編輯國定假日明細的返回按鈕事件
     * @return {void}
     */
    const handleCancelModifyPublicHolidayButtonOnClick = () => setShowPublicHolidaySetting(false)

    /**
     * 開啟編輯國定假日明細按鈕點擊事件
     * @return {void}
     */
    const hanldeOpenModifyPublicHolidayOnClick = () => {
        setShowPublicHolidaySetting(true)
        setSaturdayChecked(false)
        setSundayChecked(false)
        setSelectedHolidayType(null)
        //將行事曆selected狀態設為false
        const updatedCalendar = calendarData.map(month =>
            month.map(day => ({
                ...day,
                selected: false
            }))
        )
        setCalendarData(updatedCalendar)
    }

    /**
     * 點選存檔按鈕事件
     * @return {void}
     */
    const handleSaveOnClick = async () => {

        if (stringIsEmpty(selectedHolidayType)) {
            return
        }

        if (selectedHolidayType === 'N') {
            //刪除假日
            let delArray = []
            for (const monthData of calendarData) {
                for (const dayData of monthData) {
                    if (dayData.selected) {
                        delArray.push(dayData.yearMonthDate)
                    }
                }
            }

            delHoliday({
                // 行事類類別：01.人事行政局，02.假日維護檔。
                "holidayType": calendaryCategory,
                //日期 yyyy-MM-DD HH:mm:ss
                "yearMonthDate": delArray
            }).then(res => {
                // 狀態 / 訊息
                const {err, data, msg} = res
                // 取得資料成功
                if (err === ApiErrorStatusEnum.Success) {
                    //刪除成功
                    //重新取得假日資料
                    getQueryHolidayByYear()
                    //reset右邊區塊
                    setSaturdayChecked(false)
                    setSundayChecked(false)
                    setSelectedHolidayType(null)

                } else { // 刪除失敗
                    // 顯示錯誤訊息
                    showToast({message: msg, type: AlertTypeEnum.Error})
                }
            })
        } else {
            //變更假日
            let selectedDatas = []
            calendarData.map((monthData) => {
                monthData.map((dayData) => {
                    if (dayData.selected) {
                        const lockVersion = dayData.lockVersion ?? 0
                        const data = {
                            // 行事類類別：01.人事行政局，02.假日維護檔。
                            "holidayType": calendaryCategory,
                            //日期 yyyy-MM-DD HH:mm:ss
                            "yearMonthDate": dayData.yearMonthDate,
                            // 星期1,2,3,4,5,6,7
                            "week": dayData.dayOfWeek.toString(),
                            // 假日種類：Y:假日，H.假日(半天)，N.非假日
                            "holidayKind": selectedHolidayType,
                            // 行事曆說明
                            "holidayRemark": dayData.holidayRemark,
                            //版本鎖定
                            "lockVersion": lockVersion
                        }

                        selectedDatas.push(data)
                    }
                })
            })
            updateHoliday(selectedDatas).then(res => {
                // 狀態 / 訊息
                const {err, data, msg} = res
                // 取得資料成功
                if (err === ApiErrorStatusEnum.Success) {
                    //儲存成功
                    showToast({message: '存檔成功', type: AlertTypeEnum.Success})
                    //重新取得假日資料
                    getQueryHolidayByYear()
                    //reset右邊區塊
                    setSaturdayChecked(false)
                    setSundayChecked(false)
                    setSelectedHolidayType(null)

                } else { // 取得資料失敗
                    // 顯示錯誤訊息
                    showToast({message: msg, type: AlertTypeEnum.Error})
                }
            })
        }
    }

    /**
     * 取得指定年份的假日資料
     * @return {void}
     */
    const getQueryHolidayByYear = () => {
        queryHolidayByYear({
            // 行事曆類別
            holidayType: calendaryCategory,
            //日期(年)
            year: selectedYear,
        }).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            // 取得資料成功
            if (err === ApiErrorStatusEnum.Success) {
                // 設定假日資料
                setData(data)
                //開始parse資料至我的格式 calendarData
                const updatedCalendarData = generateYearCalendar(selectedYear)
                if (!arrayIsEmpty(data)) {

                    data.map((item) => {
                        const dateTime = new Date(item.yearMonthDate)
                        const monthIndex = dateTime.getMonth()
                        const dateIndex = dateTime.getDate() - 1

                        const updateDayData = {
                            ...updatedCalendarData[monthIndex][dateIndex],
                            holidayKind: item.holidayKind,
                            holidayRemark: item.holidayRemark,
                            lockVersion: item.lockVersion,
                        }
                        updatedCalendarData[monthIndex][dateIndex] = updateDayData
                    })
                }
                setCalendarData(updatedCalendarData)
            } else { // 取得資料失敗
                //清空資料
                setData([])
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 取得行事曆類別陣列
     * @return {void}
     */
    const getCalendarTypeArray = () => {
        queryJustNameByCategories({
            // 行事曆類別
            categories: "HOLIDAY_TYPE"
        }).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            // 取得資料成功
            if (err === ApiErrorStatusEnum.Success) {
                // 設定行事曆類別資料
                setCalendarCategoryArray(data)
                if (!arrayIsEmpty(data)) {
                    //設定當前行事曆類別
                    setCalendaryCategory(data[0].justnamedNo)
                } else {
                    setCalendaryCategory(null)
                }
            } else { // 取得資料失敗
                setCalendaryCategory(null)
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 取得已選擇的天數
     * @return {any | number}
     */
    const getSelectedDateCount = () => {
        const selectedDaysCount = calendarData !== null ? calendarData.map(month => month.filter(day => day.selected).length).reduce((total, count) => total + count, 0) : 0
        return selectedDaysCount
    }

    /**
     * 取得假日的顏色
     * @param type {String} 假日類別
     * @return {string}
     */
    const getHolidayColor = (type) => {
        const finded = holidayKindArray.find(item => item.kindNo === type)

        return finded ? finded.color : ""
    }

    /**
     * 國定假日名稱輸入框值變更事件
     * @param e {Event}
     * @param publicHolidayData {Object} 國定假日資料
     * @return {void}
     */
    const handlePublicHolidayNameOnChange = (e, publicHolidayData) => {
        const updateData = publicHolidayModifyData.map((item) => {
            if (item.yearMonthDate === publicHolidayData.yearMonthDate) {
                const newData = {
                    ...item,
                    holidayRemark: e.target.value
                }
                return newData
            } else {
                return item
            }
        })
        setPublicHolidayModifyData(updateData)
    }

    /**
     * 國定假日編輯儲存按鈕點擊事件
     * @return {void}
     */
    const handlePublicHolidayModifySaveButtonOnClick = () => {
        let editedArray = []
        //比對哪些國定假日有被編輯過
        publicHolidayModifyData.map((item) => {
            const findedData = publicHolidayData.find((original) => original.yearMonthDate === item.yearMonthDate)
            if (findedData) {
                if (findedData.holidayKind !== item.holidayKind || findedData.holidayRemark !== item.holidayRemark) {
                    //有編輯過
                    editedArray.push({
                        // 行事類類別：01.人事行政局，02.假日維護檔。
                        "holidayType": calendaryCategory,
                        //日期 yyyy-MM-DD HH:mm:ss
                        "yearMonthDate": item.yearMonthDate,
                        // 星期1,2,3,4,5,6,7
                        "week": item.dayOfWeek.toString(),
                        // 假日種類：Y:假日，H.假日(半天)，N.非假日
                        "holidayKind": item.holidayKind,
                        // 行事曆說明
                        "holidayRemark": item.holidayRemark,
                        //版本鎖定
                        "lockVersion": item.lockVersion
                    })
                }
            }
        })

        if (!arrayIsEmpty(editedArray)) {
            updateHoliday(editedArray).then(res => {
                // 狀態 / 訊息
                const {err, data, msg} = res
                // 取得資料成功
                if (err === ApiErrorStatusEnum.Success) {
                    //儲存成功
                    showToast({message: '編輯存檔成功', type: AlertTypeEnum.Success})
                    //重新取得假日資料
                    getQueryHolidayByYear()
                    //reset右邊區塊
                    setSaturdayChecked(false)
                    setSundayChecked(false)
                    setSelectedHolidayType(null)

                    //關閉編輯過定假日明細
                    setShowPublicHolidaySetting(false)

                } else { // 取得資料失敗
                    // 顯示錯誤訊息
                    showToast({message: msg, type: AlertTypeEnum.Error})
                }
            })
        }
    }

    /**
     * 國定假日類別變更事件
     * @param date {String} 
     * @param type {String} 
     * @return {void}
     */
    const handlePublicHolidaySelectOnChange = (date) => (newValue) => {
        const updateData = publicHolidayModifyData.map((item) => {
            if (item.yearMonthDate === date) {
                return {
                    ...item,
                    holidayKind: newValue
                };
            }
            return item;
        });
        setPublicHolidayModifyData(updateData);
    };


    /**
     * 刪除國定假日按鈕點擊事件
     * @param {Object} publicHoliday
     * @return {void}
     */
    const handleDeletePublicHolidayOnClick = (publicHoliday) => {
        setChoicedPublicHolidayData(publicHoliday)
        setShowDeletePromptPopup(true)
    }

    /**
     * 刪除國定假日
     * @return {void}
     */
    const onDeletePublicHoliday = () => {
        delHoliday({
            // 行事類類別：01.人事行政局，02.假日維護檔。
            "holidayType": calendaryCategory,
            //日期 yyyy-MM-DD HH:mm:ss
            "yearMonthDate": [choicedPublicHolidayData.yearMonthDate]
        }).then(res => {
            // 狀態 / 訊息
            const {err, data, msg} = res
            // 取得資料成功
            if (err === ApiErrorStatusEnum.Success) {
                //刪除成功
                const message = `刪除成功: ${time2String(choicedPublicHolidayData.yearMonthDate, "YYYY-MM-DD")}，${t(`Regs.RegsHolidayKindEnum.${choicedPublicHolidayData.holidayKind}`)}`
                showToast({message: message, type: AlertTypeEnum.Success})
                //更新publicHolidayModifyData
                const filteredData = publicHolidayModifyData.filter(item => item.yearMonthDate !== choicedPublicHolidayData.yearMonthDate)
                setPublicHolidayModifyData(filteredData)
                //重新取得假日資料
                getQueryHolidayByYear()

            } else { // 刪除失敗
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
            }

            setShowDeletePromptPopup(false)
        })

    }

    /**
     * 監聽年分選擇的變化
     * @return {void}
     */
    useEffect(() => {
        const resetData = generateYearCalendar(selectedYear)
        setCalendarData(resetData)
    }, [selectedYear])

    /**
     * 監聽年份變化和行事曆類別變化
     * @return {void}
     */
    useEffect(() => {
        if (!stringIsEmpty(calendaryCategory) && !isNaN(selectedYear))
            getQueryHolidayByYear()
    }, [calendaryCategory, selectedYear])

    /**
     * 第一次執行時
     * @return {void}
     */
    useMemo(() => {
        // 設定年份選項
        if (arrayIsEmpty(yearOptions)) {
            //範圍 (當前年度 - 10) ~ (當前年度 + 1)
            const currentYear = new Date().getFullYear()
            const startYear = currentYear - 10
            const endYear = currentYear + 1
            const yearRange = Array.from({length: endYear - startYear + 1}, (_, y) => y + startYear)
            setYearOptions(yearRange.sort((a, b) => b - a))
            //setYearOptions(generateYearRange1911ToCurrent())
        }
        // 取得行事曆類別
        if (stringIsEmpty(calendaryCategory)) {
            getCalendarTypeArray()
        }
    }, [])

    /**
     * 監聽國定假日編輯模式的切換
     * @return {void}
     */
    useEffect(() => {
        if (showPublicHolidaySetting) {
            let arrayData = []
            calendarData.map((monthData) => {
                monthData.map((dayData) => {
                    if (dayData.holidayKind === 'A' || dayData.holidayKind === 'B') {
                        arrayData.push(dayData)
                    }
                })
            })
            setPublicHolidayData(arrayData)
            setPublicHolidayModifyData(arrayData)
        } else {
            setPublicHolidayData(null)
            setPublicHolidayModifyData(null)
        }

        if (arrayIsEmpty(publicHolidayKindArray)) {
            let arrayData = []
            holidayKindArray.map(item => {
                if (item.kindNo === 'A' || (item.kindNo === 'B')) {
                    arrayData.push(item)
                }
            })
            setPublicHolidayKindArray(arrayData)
        }
    }, [showPublicHolidaySetting])

    return (
        <div className="w-full">
            <div className="w-full overflow-x-scroll bg-[#fafafa]">
                {/*頂部區塊*/}
                <div className="w-[1100px] flex items-center justify-between py-4 pl-4 text-left">
                    <div className="flex gap-2">
                    {/* 行事曆類別下拉選單 */}
                    <Select
                        data={{
                            label: "行事曆類別",
                            options: calendarCategoryArray?.map((item) => ({
                                value: item.justnamedNo,
                                label: t(`Regs.RegsHolidayTypeEnum.${item.justnamedNo}`)
                            }) || []
                        )
                    }}
                    value={calendaryCategory}
                    disabled={showPublicHolidaySetting}
                    onChange={handleCalendaryCategoryOnChange}
                    displayEmpty={true}
                    notched = {true}
                    width={SelectWidthEnum.Large}
                    />                          
                    {/*年份*/}
                    <Select
                        data={{
                            label: "年份",
                            options: yearOptions?.map((year) => ({
                                value: year,
                                label: year
                            }) || []
                        )
                    }}
                    value={selectedYear}
                    disabled={showPublicHolidaySetting}
                    onChange={handleYearOnChange}
                    displayEmpty={true}
                    notched = {true}
                    width={SelectWidthEnum.Large}
                    />                   
                    </div>
                    {/** 假日類別說明 */}
                    <div className = 'flex gap-6 '>
                        <div>
                            {
                                holidayKindArray.slice(0, 2).map((item, index) =>
                                    <div
                                        className="h-1/2 space-x-2 flex items-center justify-center text-base font-medium"
                                        key={index}>
                                        <div className="w-[18px] h-[18px]" style={{backgroundColor: item.color}}/>
                                        <div>{item.kindName}</div>
                                    </div>
                                )
                            }
                        </div>
                        <div>
                            {
                                holidayKindArray.slice(3).map((item, index) =>
                                    <div
                                        className="h-1/2 space-x-2 flex items-center justify-center text-base font-medium"
                                        key={index}>
                                        <div className="w-[18px] h-[18px]" style={{backgroundColor: item.color}}/>
                                        <div>{item.kindName}</div>
                                    </div>
                                )
                            }
                        </div>
                        <div>
                            {
                                holidayKindArray.slice(2, 3).map((item, index) =>
                                    <div
                                        className="h-1/2 space-x-2 flex items-center justify-center text-base font-medium"
                                        key={index}>
                                        <div className="w-[18px] h-[18px]" style={{backgroundColor: item.color}}/>
                                        <div>{item.kindName}</div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
                <div className="flex px-4 h-[calc(100vh-170px)]">
                    <div className="min-w-[1100px] pr-[5px] overflow-y-auto h-full">
                        <CalendarYearly yearData={calendarData} year={selectedYear} disable={showPublicHolidaySetting}
                                        selectedHolidayType={selectedHolidayType} holidayKindArray={holidayKindArray}
                                        onDateClick={onDateClick}/>
                    </div>
                    {/**編輯設定區 */}
                    <div className="mx-2 min-w-[575px] flex flex-col overflow-y-auto h-full">
                        {
                            !showPublicHolidaySetting ?
                                <>
                                    <div className="text-left">
                                        <div className="text-xl text-[#18181B]">
                                            1.點擊選取左方假日日期
                                        </div>
                                        <div className="flex items-center pl-3 mt-2.5 space-x-2.5">
                                            <Checkbox
                                                checkboxSx={{color: BlueColorEnum.Blue600}}
                                                labelSx={{color: saturdayChecked ? BlueColorEnum.Blue600 : GrayColorEnum.Gray700}}
                                                color={BlueColorEnum.Blue600}
                                                label="快選全部星期六"
                                                checked={saturdayChecked}
                                                onChange={handleSaturdayChange}
                                            />
                                        </div>
                                        <div className="flex items-center pl-3 mt-2.5 space-x-2.5">
                                            <Checkbox
                                                checkboxSx={{color: BlueColorEnum.Blue600}}
                                                labelSx={{color: sundayChecked ? BlueColorEnum.Blue600 : GrayColorEnum.Gray700}}
                                                color={BlueColorEnum.Blue600}
                                                label="快選全部星期日"
                                                checked={sundayChecked}
                                                onChange={handleSundayChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="text-left mt-[30px]">
                                        <div className="text-xl text-[#18181B]">
                                            2.目前已選取 {getSelectedDateCount()} 天，這幾天是
                                        </div>
                                        {/*假日類別選擇*/}
                                        <div className="pl-3 mt-2.5 space-y-2">
                                            <div className="flex space-x-2">
                                                {
                                                    holidayKindArray.slice(0, 3).map((item, index) =>
                                                        <ToggleButton id={item.kindNo} key={index}
                                                                      text={item.kindName}
                                                                      color={item.color}
                                                                      selected={selectedHolidayType === item.kindNo}
                                                                      onClick={handleHolidayTypeOnChange}/>
                                                    )
                                                }
                                            </div>
                                            <div className="flex space-x-2">
                                                {
                                                    holidayKindArray.slice(3).map((item, index) =>
                                                        <ToggleButton id={item.kindNo} key={index}
                                                                      text={item.kindName}
                                                                      color={item.color}
                                                                      selected={selectedHolidayType === item.kindNo}
                                                                      onClick={handleHolidayTypeOnChange}/>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="text-left mt-[30px]">
                                        <div className="text-xl text-[#18181B]">
                                            3.存檔
                                        </div>
                                        <div className="pl-3 mt-2.5">
                                            <Button
                                                color={ColorEnum.Primary} variant={ButtonVariantEnum.Contained}
                                                size={ButtonSizeEnum.Medium} disabled={selectedHolidayType === null}
                                                onClick={handleSaveOnClick}>
                                                {t("general.saveFile")}
                                            </Button>
                                        </div>
                                    </div>

                                    <div className="text-left mt-[30px] flex-1 flex items-end">
                                        <Button
                                            color={ColorEnum.Primary} variant={ButtonVariantEnum.Outlined}
                                            size={ButtonSizeEnum.Medium}
                                            onClick={hanldeOpenModifyPublicHolidayOnClick}>
                                            編輯國定假日明細
                                        </Button>
                                    </div>
                                </>
                                :
                                /**編輯國定假日明細 */
                                <div
                                    className="flex flex-col bg-[#fafafa] border border-[#D4D4D8] rounded-[6px] mb-[10px] h-full px-3 pb-2.5">
                                    <div className="bg-white rounded-[6px] pt-2.5 px-2 h-[90%] w-full">
                                        {/* Title */}
                                        <div className="ml-5 flex space-x-2">
                                            <div className="bg-[#2B6CB0] w-2"/>
                                            <div className="text-[#2B6CB0] text-xl">
                                                編輯國定假日明細
                                            </div>
                                        </div>
                                        {/* 明細表: 顯示國定假日半天或全天假 */}
                                        <div className="overflow-y-auto h-[calc(100%-100px)]">

                                            <div className="flex h-[50px] border-collapse text-[#18181b] items-center">
                                                <div className='text-base font-normal min-w-[13%] max-w-[13%]'></div>
                                                <div className='text-base font-normal min-w-[28%] max-w-[28%]'>日期
                                                </div>
                                                <div className='text-base font-normal min-w-[23%] max-w-[23%]'>名稱
                                                </div>
                                                <div className='text-base font-normal min-w-[36%] max-w-[36%]'>假別
                                                </div>
                                            </div>

                                            {
                                                !arrayIsEmpty(publicHolidayModifyData) && publicHolidayModifyData.map((item, index) =>
                                                    <div className="flex items-center min-h-[50px] text-[#18181b]"
                                                         key={item.yearMonthDate}>
                                                        <div className="min-w-[13%] max-w-[13%]">
                                                            <Button
                                                                sx={{whiteSpace: 'nowrap'}}
                                                                color={ColorEnum.Primary} variant={ButtonVariantEnum.Text}
                                                                size={ButtonSizeEnum.Large} icon={IconEnum.Delete}
                                                                onClick={() => handleDeletePublicHolidayOnClick(item)}>
                                                                {t('general.delete')}
                                                            </Button>
                                                        </div>
                                                        <div className="items-center min-w-[28%] max-w-[28%] px-1">
                                                            <div
                                                                className="min-h-10 text-center px-2 py-1.5 w-full h-full border border-[#D4D4D8] bg-[#D4D4D8]/20 rounded">
                                                                {`${time2String(item.yearMonthDate, "YYYY/MM/DD")} (${t(`general.dateTime.weekRange.short.${item.dayOfWeek}`)})`}
                                                            </div>
                                                        </div>
                                                        <div className="min-w-[23%] max-w-[23%] px-1">
                                                            <TextField
                                                                inputWidth={SizeEnum.Fill}
                                                                value={item.holidayRemark}
                                                                onChange={(e) => handlePublicHolidayNameOnChange(e, item)}
                                                            />
                                                        </div>
                                                        <div className="items-center min-w-[36%] max-w-[36%] flex px-1">
                                                            <Select
                                                                data={{
                                                                    label: "假別",
                                                                    options: publicHolidayKindArray?.map((kind) => ({
                                                                        label: t(`Regs.RegsHolidayKindEnum.${kind.kindNo}`),
                                                                        value: kind.kindNo
                                                                    })) || []
                                                                }}
                                                                onChange={handlePublicHolidaySelectOnChange(item.yearMonthDate)}
                                                                value={item.holidayKind}
                                                                showLabel={false}
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            }

                                        </div>
                                        <div className="flex items-end justify-end mt-4">
                                            <Button
                                                color={ColorEnum.Primary} variant={ButtonVariantEnum.Contained}
                                                size={ButtonSizeEnum.Medium}
                                                onClick={handlePublicHolidayModifySaveButtonOnClick}>
                                                {t('general.saveFile')}
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="flex-1 flex items-end justify-end mr-2">
                                        <Button
                                            color={ColorEnum.Primary} variant={ButtonVariantEnum.Outlined}
                                            size={ButtonSizeEnum.Medium}
                                            onClick={handleCancelModifyPublicHolidayButtonOnClick}
                                            text={'返回'}>
                                        </Button>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>
            {
                <DeletePromptPopup
                    show={showDeletePromptPopup}
                    closePopupButtonOnClick={() => setShowDeletePromptPopup(false)}
                    message={`${time2String(choicedPublicHolidayData.yearMonthDate, "YYYY-MM-DD")}，${t(`Regs.RegsHolidayKindEnum.${choicedPublicHolidayData.holidayKind}`)}`}
                    handleDeleteOnClick={onDeletePublicHoliday}/>
            }
        </div>
    )
}

export default CalendarMaintenance;
