//Import css
import '../../components/TableList/tableList.scss'
import '../../components/ICCard/maintain.scss'

//Import Icons
import {ReactComponent as AddIcon} from '../../assets/images/ICCard/add_circle.svg'
import {ReactComponent as DeleteIcon} from '../../assets/images/ICCard/delete.svg'
import {ReactComponent as EditBtnIcon} from "../../assets/images/ICCard/edit_square.svg"

//Import function
import {
    ApiErrorStatusEnum,
    PopupModeEnum,
    ToastTypeEnum,
    fuzzySearchObjects,
    objectIsEmpty,
    stringIsEmpty,
    time2String,
} from "edah_utils/dist"
import {Pagination} from "../../components/Pagination/Pagination"
import React, {useEffect, useState} from "react"
import {DeletePopup} from "../../components/Popup/DeletePopup"
import {t} from "i18next"
import AddEditUserCerPopup from "../../components/ESSI/AddEditUserCerPopup"
import {essiDelUserCert, essiQueryUserCert} from "../../api/v1/ESSI"
import useToast from "../../hooks/useToast"
import {Button, ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum, IconEnum} from "edah-component/dist"

/**
 * 人員憑證維護
 * @return {JSX.Element}
 */
const UserCertMaintenance = () => {
    /**
     * Table element 的預設type
     */
    const tableElementDefaultType = "px-[16px] border-r border-[#111111]/15 font-normal"

    /**
     * Show Toast
     */
    const showToast = useToast()

    // 初始資料
    const [data, setData] = useState([])

    //當前頁碼
    const [currentPage, setCurrentPage] = useState(1)

    //每頁資料筆數
    const [pageSize, setPageSize] = useState(10)

    //總資料筆數
    const [totalSize, setTotalSize] = useState(0)

    //總頁數
    const [totalPageSize, setTotalPageSize] = useState(0)

    //是否顯示Add Edit彈窗
    const [showAddEditPopup, setShowAddEditPopup] = useState(false)

    //是否為新增或編輯
    const [popupMode, setPopupMode] = useState(PopupModeEnum.Add)

    // 是否顯示刪除的彈窗
    const [showDeletePopup, setShowDeletePopup] = useState(false);

    //進階搜尋文字
    const [advancedSearchText, setAdvancedSearchText] = useState("")

    //新增或修改的資料
    const [newData, setNewData] = useState({})

    /**
     * 進階搜尋文字輸入事件
     * @param e {Event} 事件
     * @return {void}
     */
    const handleAdvancedSearchTextOnChange = (e) => setAdvancedSearchText(e.target.value)

    /**
     * 頁碼變更事件
     * @param page {Number} 頁碼
     */
    const onPaginationPageOnChange = (page) => setTimeout(() => setCurrentPage(page), 100)

    /**
     * 每頁資料筆數變更事件
     * @param e {Event} 事件
     * @return {void}
     */
    const onPaginationPageSizeOnChange = (e) => setPageSize(e.target.value)

    /**
     * 上一頁點擊事件
     * @return {void}
     */
    const onPaginationPreviousOnClick = () => (currentPage - 1) > 1 ? setCurrentPage(currentPage - 1) : setCurrentPage(1)

    /**
     * 下一頁點擊事件
     * @return {void}
     */
    const onPaginationNextOnClick = () => (currentPage + 1) < totalPage ? setCurrentPage(currentPage + 1) : setCurrentPage(totalPage)

    /**
     * 點擊編輯時
     * @param data {Object} 資料
     * @return {void}
     */
    const handleEditButtonOnClick = (data) => {
        // 設定資料
        setNewData(data)
        // 設定模式為修改
        setPopupMode(PopupModeEnum.Modify)
        // 顯示新增/修改popup
        setShowAddEditPopup(true)
    }

    /**
     * 點選Delete按鈕事件
     * @param data {Object} 資料
     * @return {void}
     */
    const handleDeleteButtonOnClick = (data) => {
        // 設定資料
        setNewData(data)
        // 顯示刪除彈窗
        setShowDeletePopup(true)
    }

    /**
     * 點選Add按鈕事件
     * @return {void}
     */
    const handleCancelAddOnClick = () => {
        // 顯示新增/修改popup
        setShowAddEditPopup(true)
        // 設定為新增模式
        setPopupMode(PopupModeEnum.Add)
    }

    /**
     * 關閉新增/修改popup按鈕事件
     * @return {void}
     */
    const handleAddEditPopupOnClose = () => setShowAddEditPopup(false)

    /**
     * 刪除彈窗是按鈕事件
     * @return {void}
     */
    const handleDeletePopupYesButtonOnClick = () => delUserCert()

    /**
     * 刪除彈窗否按鈕事件
     * @return {void}
     */
    const handleDeletePopupNoButtonOnClick = () => {
        //清空資料
        setNewData({})
        // 關閉刪除彈窗
        setShowDeletePopup(false)
    }

    /**
     * 刪除彈窗關閉按鈕事件
     * @return {void}
     */
    const handleDeletePopupCloseButtonOnClick = () => handleDeletePopupNoButtonOnClick()

    /**
     * 取得有效日期
     * @param data {Object} 資料
     * @return {string}
     */
    const getExpirationDate = (data) => {
        // 取得開始日期
        const startDate = data.effStartDate ? time2String(data.effStartDate, 'YYYY-MM-DD') : ""
        // 取得結束日期
        const endDate = data.effEndDate ? time2String(data.effEndDate, 'YYYY-MM-DD') : ""
        // 如果開始日期與結束日期皆為空
        if (stringIsEmpty(startDate) && stringIsEmpty(endDate)) {
            // 回傳空值
            return ""
        }

        return `${startDate}~${endDate}`
    }

    /**
     * 取得進階搜尋後的資料
     * @return {Array<Object>}
     */
    const getFilterData = () => fuzzySearchObjects(data, advancedSearchText)

    /**
     * 刪除該筆電子表單類別維護資料
     * @return {void}
     */
    const delUserCert = () => {
        essiDelUserCert([newData]).then(res => {
            const {err, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                // 關閉刪除彈窗
                setShowDeletePopup(false)
                // 顯示成功訊息
                showToast({message: msg})
                // 重新取得資料
                getQueryUserCert()
            } else {
                // 顯示錯誤訊息
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 依權限查詢人員憑證維護
     * @return {void}
     */
    const getQueryUserCert = () => {
        essiQueryUserCert({
            // 當前頁碼
            pageNum: currentPage,
            // 每頁資料筆數
            pageSize: pageSize,
        }).then(res => {
            // 取得API回傳資料
            const {data, totalPageSize, totalItemSize, err, msg} = res
            // 如果API回傳成功
            if (err === ApiErrorStatusEnum.Success) {
                // 設定資料
                setData(data)
            } else { // 如果API回傳失敗
                // 清空資料
                setData([])
                // 顯示錯誤訊息
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
            //設定總筆數
            setTotalSize(totalItemSize)
            // 設定總頁數
            setTotalPageSize(totalPageSize)
        })
    }

    /**
     * 第一次進入頁面時取得資料
     **/
    useEffect(() => getQueryUserCert(), [])

    return <div className="w-full p-4 bg-[#FAFAFA]">
        <div className='mb-2'>
            <Button sx={{ display: 'flex' }}
                    color={ButtonColorEnum.Success} size={ButtonSizeEnum.Large}
                    variant={ButtonVariantEnum.Text} text={`${t('general.add')}內容`}
                    icon={IconEnum.Add}
                    onClick={handleCancelAddOnClick} />
        </div>
        <div className="py-2 px-2 bg-white border-[1px] border-[#D4D4D8] rounded-[6px]">
            {/*進階搜尋*/}
            <div className="flex flex-row items-center justify-between mb-2">
                <div>
                    <input className="w-[320px] h-10 pl-4 border-[2px] border-[#D4D4D8] rounded-[6px]"
                           type="text" placeholder={t('general.advancedSearch')} value={advancedSearchText}
                           onChange={handleAdvancedSearchTextOnChange}/>
                </div>
            </div>
            <div className=' w-full overflow-x-auto overflow-y-auto min-h-[100px] max-h-[35vh] xl:max-h-[65vh]'>
                <table
                    className="table-fixed w-full text-left overflow-x-auto overflow-y-auto min-h-[100px] max-h-[35vh] xl:max-h-[50vh]">
                    <thead>
                    <tr className="bg-[#e4e4e7] h-[56px] border-collapse text-lg text-[#18181b]">
                        <th className={`${tableElementDefaultType}`}>操作</th>
                        <th className={`${tableElementDefaultType}`}>員工代號</th>
                        <th className={`${tableElementDefaultType}`}>卡號</th>
                        <th className={`${tableElementDefaultType}`}>HCA卡別</th>
                        <th className={`${tableElementDefaultType}`}>卡片有效日期</th>
                        <th className={`${tableElementDefaultType}`}>憑證廢止</th>
                        <th className={`${tableElementDefaultType}`}>備註</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        !objectIsEmpty(data) && getFilterData().map((data, index) =>
                            <tr className={`h-[40px] ${index % 2 === 0 ? '' : 'bg-[#f4f4f5]'} text-[#18181b]`}
                                key={index}>
                                {/*操作*/}
                                <td className={`${tableElementDefaultType}`}>
                                    <div className='flex flex-row items-center justify-start'>
                                        {/*編輯按鈕*/}
                                        <Button color={ButtonColorEnum.Primary} size={ButtonSizeEnum.Large}
                                                variant={ButtonVariantEnum.Text} text={t('general.edit')}
                                                icon={IconEnum.Edit}
                                                onClick={handleEditButtonOnClick}
                                        />
                                        {/*刪除按鈕*/}
                                        <Button color={ButtonColorEnum.Primary} size={ButtonSizeEnum.Large}
                                                variant={ButtonVariantEnum.Text} text={t('general.delete')}
                                                icon={IconEnum.Delete}
                                                onClick={handleDeleteButtonOnClick}
                                        />
                                    </div>
                                </td>
                                {/*員工代號*/}
                                <td className={`${tableElementDefaultType}`}>
                                    {data.userNo}
                                </td>
                                {/*卡號*/}
                                <td className={`${tableElementDefaultType}`}>
                                    {data.cardNumber}
                                </td>
                                {/*HCA卡別*/}
                                <td className={`${tableElementDefaultType}`}>
                                    {data.cardType}
                                </td>
                                {/*卡片有效日期*/}
                                <td className={`${tableElementDefaultType}`}>
                                    {getExpirationDate(data)}
                                </td>
                                {/*憑證廢止*/}
                                <td className={`${tableElementDefaultType}`}>
                                    {data.crlFlag}
                                </td>
                                {/*備註*/}
                                <td className={`${tableElementDefaultType}`}>
                                    {data.memo}
                                </td>
                            </tr>
                        )
                    }
                    </tbody>
                </table>
            </div>
            <div className="flex justify-end w-full">
                <Pagination totalPageSize={totalPageSize} currentPage={currentPage} pageSize={pageSize}
                            totalSize={totalSize}
                            onPageOnChange={(page) => onPaginationPageOnChange(page)}
                            onPreviousOnClick={onPaginationPreviousOnClick}
                            onNextOnClick={onPaginationNextOnClick}
                            onPageSizeChange={onPaginationPageSizeOnChange}/>
            </div>
        </div>
        {/*新增/修改popup*/}
        <AddEditUserCerPopup
            mode={popupMode}
            data={newData}
            show={showAddEditPopup}
            closePopupOnClick={handleAddEditPopupOnClose}/>
        {/*刪除彈窗*/}
        <DeletePopup content="是否確定刪除?" show={showDeletePopup}
                     onClosePopupButtonClick={handleDeletePopupCloseButtonOnClick}
                     onYesButtonClick={handleDeletePopupYesButtonOnClick}
                     onNoButtonClick={handleDeletePopupNoButtonOnClick}/>
    </div>
}

export default UserCertMaintenance;
