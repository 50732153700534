import {v1Api, v1LocalServiceAPI} from "../Http"
import {HttpMethodEnum} from "edah_utils/dist"

/**
 * IC卡寫卡基本資料讀取
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const iccmPaticQueryPatic = (params) => v1Api.request({
    url: '/iccm/patic/queryPatic',
    method: HttpMethodEnum.GET,
    params
})

/**
 * IC卡寫卡基本資料讀取 local
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const iccmPaticQueryPaticByLocal = (params) => v1LocalServiceAPI.request({
    url: '/iccm/patic/queryPatic',
    method: HttpMethodEnum.GET,
    params
})

/**
 * IC卡醫囑資料讀取1.0
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const iccmPaticdQueryPaticd10 = (params) => v1Api.request({
    url: '/iccm/paticd/queryPaticd10',
    method: HttpMethodEnum.GET,
    params
})

/**
 * IC卡醫囑資料讀取2.0
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const iccmPaticdQueryPaticd20 = (params) => v1Api.request({
    url: '/iccm/paticd/queryPaticd20',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 待寫卡清單
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const iccmPaticWaitWriteData = (params) => v1Api.request({
    url: '/iccm/patic/waitWriteData',
    method: HttpMethodEnum.GET,
    params
})

/**
 * IC卡資料上傳查詢
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const iccmUploaDrecQueryUploadData = (params) => v1Api.request({
    url: '/iccm/uploadrec/queryUploadData',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 查詢要自動上傳的IC資料
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const iccmPaticQueryAutoUpload = (params) => v1Api.request({
    url: '/iccm/patic/query/autoUpload',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 雲端0-讀取用藥資料
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const iccmCloudencQueryIccmClouden0Data = (params) => v1Api.request({
    url: '/iccm/cloudenc/queryIccmClouden0Data',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 雲端2-讀取檢查檢驗紀錄資料
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const iccmCloudencQueryIccmClouden2Data = (params) => v1Api.request({
    url: '/iccm/cloudenc/queryIccmClouden2Data',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 雲端3-讀取檢查檢驗紀錄資料
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const iccmCloudencQueryIccmClouden3Data = (params) => v1Api.request({
    url: '/iccm/cloudenc/queryIccmClouden3Data',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 雲端4-讀取牙科處置及手術資料
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const iccmCloudencQueryIccmClouden4Data = (params) => v1Api.request({
    url: '/iccm/cloudenc/queryIccmClouden4Data',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 雲端5-讀取過敏藥資料
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const iccmCloudencQueryIccmClouden5Data = (params) => v1Api.request({
    url: '/iccm/cloudenc/queryIccmClouden5Data',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 雲端6-讀取檢驗檢查結果資料
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const iccmCloudencQueryIccmClouden6Data = (params) => v1Api.request({
    url: '/iccm/cloudenc/queryIccmClouden6Data',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 雲端7-讀取出院時間資料資料
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const iccmCloudencQueryIccmClouden7Data = (params) => v1Api.request({
    url: '/iccm/cloudenc/queryIccmClouden7Data',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 雲端8-讀取復健醫療資料
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const iccmCloudencQueryIccmClouden8Data = (params) => v1Api.request({
    url: '/iccm/cloudenc/queryIccmClouden8Data',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 雲端9-讀取復健醫療資料
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const iccmCloudencQueryIccmClouden9Data = (params) => v1Api.request({
    url: '/iccm/cloudenc/queryIccmClouden9Data',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 查詢要自動下載的IC資料
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const iccmPaticQueryAutoDownload = (params) => v1Api.request({
    url: '/iccm/patic/query/autoDownload',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 刪除指定IC卡資料
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const iccmPaticDelData = (data) => v1Api.request({
    url: '/iccm/patic/delData',
    method: HttpMethodEnum.DELETE,
    data
})

/**
 * 重大傷病讀取
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const iccmCriticalillnessQueryData = (params) => v1Api.request({
    url: '/iccm/criticalillness/queryCritialillnessData',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 更新後端資料
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const iccmPaticUpdateDataFromFront = (data) => v1Api.request({
    url: '/iccm/patic/updateDataFromFront',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 重大傷病存檔
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const iccmCriticalillnessSaveData = (data) => v1Api.request({
    url: '/iccm/criticalillness/saveCritialillnessData',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 用藥資料/檢驗檢查
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const iccmNhiimiGetMedPrtData = (data) => v1Api.request({
    url: '/iccm/nhiimi/getMedPrtData',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 過敏藥物
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const iccmNhiimiGetAllergyData = (data) => v1Api.request({
    url: '/iccm/nhiimi/getAllergyData',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 藥物交互作用
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const iccmNhiimiGetDDIData = (data) => v1Api.request({
    url: '/iccm/nhiimi/getDDIData',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 虛擬IC卡 請求授權獲取qrCode
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const vhcGetQRCodeByVHCTLMRReqCode = (params) => v1LocalServiceAPI.request({
    url: '/vhc/getQRCodeByVHCTLMRReqCode',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 虛擬IC卡 獲取請求碼
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const vhcGetVHCTeleMedicineReqCode = (params) => v1LocalServiceAPI.request({
    url: '/vhc/getVHCTeleMedicineReqCode',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 虛擬IC卡 1.2 讀取基本資料
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const vhcHisGetVHChisRegisterBasic = (params) => v1LocalServiceAPI.request({
    url: '/vhc/vHChisGetvHChisRegisterBasic',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 虛擬IC卡 1.2 讀取基本資料
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const vHChisGetRegisterBasic = (params) => v1LocalServiceAPI.request({
    url: '/vhc/vHChisGetRegisterBasic',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 虛擬IC卡 1.41 讀取就醫資料-門診處方箋
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const vhcHisReadPrescriptMain = (params) => v1LocalServiceAPI.request({
    url: '/vhc/vHChisReadPrescriptMain',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 虛擬IC卡 1.42 讀取就醫資料-長期處方箋
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const vhcHisReadPrescriptLongTerm = (params) => v1LocalServiceAPI.request({
    url: '/vhc/vHChisReadPrescriptLongTerm',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 虛擬IC卡 1.43 讀取就醫資料-重要醫令
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const vhcHisReadPrescriptHVE = (params) => v1LocalServiceAPI.request({
    url: '/vhc/vHChisReadPrescriptHVE',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 虛擬IC卡 1.44 讀取就醫資料-過敏藥物
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const vhcHisReadPrescriptAllergic = (params) => v1LocalServiceAPI.request({
    url: '/vhc/vHChisReadPrescriptAllergic',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 虛擬IC卡 1.17 就醫費用資料寫入作業
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const vhcHisWriteTreatmentFee = (data) => v1LocalServiceAPI.request({
    url: '/vhc/vHChisWriteTreatmentFee',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 虛擬IC卡 1.49 多筆處方籤寫入作業-回傳簽章
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const vhcHisWriteMultiPrescriptSign = (data) => v1LocalServiceAPI.request({
    url: '/vhc/vHChisWriteMultiPrescriptSign',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 虛擬IC卡 1.53 取得就醫序號新版-就醫識別碼
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const vhcHisGetSeqNumber256N1 = (params) => v1LocalServiceAPI.request({
    url: '/vhc/vHChisGetSeqNumber256N1',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 虛擬IC卡 1.5 讀取就醫資料不需HPC卡的部分
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const vhcHisGetTreatmentNoNeedHPC = (params) => v1LocalServiceAPI.request({
    url: '/vhc/vHChisGetTreatmentNoNeedHPC',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 虛擬IC卡 1.8 讀取就醫資料需要HPC卡的部份
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const vhcHisGetTreatmentNeedHPC = (params) => v1LocalServiceAPI.request({
    url: '/vhc/vHChisGetTreatmentNeedHPC',
    method: HttpMethodEnum.GET,
    params
})

export {
    iccmPaticQueryPatic,
    iccmPaticQueryPaticByLocal,
    iccmPaticdQueryPaticd10,
    iccmPaticdQueryPaticd20,
    iccmPaticWaitWriteData,
    iccmUploaDrecQueryUploadData,
    iccmPaticQueryAutoUpload,
    iccmCloudencQueryIccmClouden0Data,
    iccmCloudencQueryIccmClouden2Data,
    iccmCloudencQueryIccmClouden3Data,
    iccmCloudencQueryIccmClouden4Data,
    iccmCloudencQueryIccmClouden5Data,
    iccmCloudencQueryIccmClouden6Data,
    iccmCloudencQueryIccmClouden7Data,
    iccmCloudencQueryIccmClouden8Data,
    iccmCloudencQueryIccmClouden9Data,
    iccmPaticQueryAutoDownload,
    iccmPaticDelData,
    iccmCriticalillnessQueryData,
    iccmPaticUpdateDataFromFront,
    iccmCriticalillnessSaveData,
    iccmNhiimiGetMedPrtData,
    iccmNhiimiGetAllergyData,
    iccmNhiimiGetDDIData,
    vhcGetQRCodeByVHCTLMRReqCode,
    vhcGetVHCTeleMedicineReqCode,
    vhcHisGetVHChisRegisterBasic,
    vHChisGetRegisterBasic,
    vhcHisReadPrescriptMain,
    vhcHisReadPrescriptLongTerm,
    vhcHisReadPrescriptHVE,
    vhcHisReadPrescriptAllergic,
    vhcHisWriteTreatmentFee,
    vhcHisWriteMultiPrescriptSign,
    vhcHisGetSeqNumber256N1,
    vhcHisGetTreatmentNoNeedHPC,
    vhcHisGetTreatmentNeedHPC
}
