import React, {useState} from "react";
import {arrayIsEmpty, fuzzySearchObjects, InputTypeEnum} from "edah_utils/dist";
import {BaseInput} from "../Input/BaseInput";
import {t} from "i18next";
import {BasePopup} from "../Popup/BasePopup";
import {
    Button,
    ButtonSizeEnum,
    ButtonVariantEnum,
    ButtonColorEnum,
    IconEnum,
    Select,
    SelectWidthEnum,
    Field, TextField
} from "edah-component/dist";

/**
 * 居家評估作業＿收案清單列表
 * @return {JSX.Element}
 */
const CaseReceiptList = () => {

    // 收案人員
    const [caseReceiver, setCaseReceiver] = useState('0')
    // 級別
    const [caseClass, setCaseClass] = useState('0')
    // 搜尋文字
    const [searchText, setSearchText] = useState('')


    // 選擇個案
    const [showSelectPopup, setShowSelectPopup] = useState(false)

    // Fake Data
    const [dataObject, setDataObject] = useState([
        {
            fsn: '09876',
            mrn: '0801234',
            name: '黃ＯＯ',
            gender: '女',
            contact: '廖ＯＯ',
            date: '2023-09-12',
            status: '',
            code: '0987',
            level: 'S2',
            rn: '李ＯＯ'
        },
        {
            fsn: '09876',
            mrn: '0801234',
            name: '黃ＯＯ',
            gender: '男',
            contact: '廖ＯＯ',
            date: '2023-09-12',
            status: '住院(C)',
            code: '5D56B',
            level: 'S3',
            rn: '李ＯＯ'
        },
        {
            fsn: '09876',
            mrn: '0801234',
            name: '黃ＯＯ',
            gender: '男',
            contact: '廖ＯＯ',
            date: '2023-09-12',
            status: '住院(A)',
            code: '5D56BAA',
            level: 'S3',
            rn: '李ＯＯ'
        }
    ]);

    /**
     * 收案人員改變時
     * @param value {string}
     * @return {void}
     */

    const handleCaseReceiverOnChange = (value) => setCaseReceiver(value)

    /**
     * 級別改變時
     * @param value {string}
     * @return {void}
     */
    const handleCaseClassOnChange = (value) => setCaseClass(value)

    /**
     * 搜尋文字改變時
     * @param e {Event} e
     * @return {void}
     */
    const handleSearchTextOnChange = (e) => setSearchText(e.target.value)

    /**
     * 取得模糊匹配後的資料陣列
     * @return {Array<Object>}
     */
    const getFilterData = (data) => fuzzySearchObjects(data, searchText)

    const handleSelectPopup = () => {
        setShowSelectPopup(true)
    }
    const handleSelectPopupClose = () => {
        setShowSelectPopup(false)
    }

    return(
        <div className="bg-white border-[1px] border-[#D4D4D8] rounded-[6px] p-4">
            {/*篩選清單*/}
            <div className="filterGroup flex flex-row flex-wrap items-center justify-start">
                <div className="flex flex-row items-center justify-start mb-4 mr-4 text-left">
                    {/*收案人員*/}
                    <Select
                        width={SelectWidthEnum.Small}
                        data={{
                            label:"收案人員",
                            options:[
                                {value:"0", label:"全部"},
                            ]
                        }}
                        value = {caseReceiver}
                        notched={true}
                        onChange={handleCaseReceiverOnChange}/>
                    {/*家字號*/}
                    <p className="ml-4 mr-1">家字號:</p>
                    <BaseInput className="w-[102px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                               type={InputTypeEnum.Text}
                               inputMode={'search'}
                    />

                    {/*病歷號*/}
                    <p>病歷號:</p>
                    <BaseInput className="w-[102px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                               type={InputTypeEnum.Text}
                               inputMode={'search'}
                    />
                    {/*級別*/}
                    <Select
                        width={SelectWidthEnum.Small}
                        data={{
                            label: "級別",
                            options:[
                                {value: "0", label: "全部"},
                                {value: "1", label: "S2"},
                                {value: "2", label: "S3"},
                            ]
                        }}
                        value = {caseClass}
                        notched={true}
                        onChange = {handleCaseClassOnChange}/>
                </div>
                {/*查詢送出按鈕*/}
                <div className="flex flex-row items-center justify-start mb-4 mr-4">
                    <Button color={ButtonColorEnum.Primary} variant={ButtonVariantEnum.Contained}
                            size={ButtonSizeEnum.Medium} text={t('general.query')}
                    />
                </div>
            </div>

            {/*新增服務註冊*/}
            <div className="w-full h-[60px] py-[10px]">
                <Button sx={{display:'flex', justifyContent:'center', marginLeft:'1rem'}}
                        color={ButtonColorEnum.Success} variant={ButtonVariantEnum.Text}
                        size={ButtonSizeEnum.Large} text={t('general.add')} icon={IconEnum.Add}
                        onClick={handleSelectPopup}
                />
            </div>

            {/*列表清單區塊*/}
            <div className="maintainContainer flex flex-row items-start justify-start">
                <div className="p-4 bg-white w-full border-[1px] border-[#D4D4D8] rounded-[12px] mr-4">
                    <div className="searchGroup flex flex-row items-center justify-between mb-4">
                        {/*進階搜尋*/}
                        <BaseInput className="w-[320px] h-10 pl-4 border-[2px] border-[#D4D4D8] rounded-[6px]"
                                   type="text"
                                   placeholder={`${t('general.advancedSearch')}`}
                                   inputMode={'search'}
                                   value={searchText}
                                   onChange={(e) => handleSearchTextOnChange(e)}/>
                    </div>

                    {/*列表清單*/}
                    <ul className="tableList HAOTableList">
                        {/*表單項目標題*/}
                        <li className="title">
                            <div></div>
                            <div></div>
                            <div>家字號</div>
                            <div>病歷號</div>
                            <div>個案姓名</div>
                            <div>性別</div>
                            <div>收案人員</div>
                            <div>收案日期</div>
                            <div>個案動態</div>
                            <div>床號</div>
                            <div>級別</div>
                            <div>護理師</div>
                        </li>

                        {!arrayIsEmpty(getFilterData(dataObject)) ? getFilterData(dataObject).map((item, index) =>
                            <li key={index}>
                                {/*操作*/}
                                <div>
                                    <Button color={ButtonColorEnum.Primary} variant={ButtonVariantEnum.Text}
                                            size={ButtonSizeEnum.Small} text={t('general.delete')} icon={IconEnum.Delete}
                                    />
                                </div>
                                {/*表單評估*/}
                                <div>
                                    <Button color={ButtonColorEnum.Primary} variant={ButtonVariantEnum.Outlined}
                                            size={ButtonSizeEnum.Medium} text={'表單評估'}
                                    />
                                </div>
                                {/*家字號*/}
                                <div>{item.fsn}</div>
                                {/*病歷號*/}
                                <div>{item.mrn}</div>
                                {/*個案姓名*/}
                                <div>{item.name}</div>
                                {/*性別*/}
                                <div>{item.gender}</div>
                                {/*收案人員*/}
                                <div>{item.contact}</div>
                                {/*收案日期*/}
                                <div>{item.date}</div>
                                {/*個案動態*/}
                                <div>{item.status}</div>
                                {/*床號*/}
                                <div>{item.code}</div>
                                {/*級別*/}
                                <div>{item.level}</div>
                                {/*護理師*/}
                                <div>{item.rn}</div>
                            </li>
                        ) : null}
                    </ul>
                </div>
            </div>

            {/*選擇個案Popup*/}
            {showSelectPopup && (
                <BasePopup title={'選擇個案級別'}
                           closePopupButtonOnClick={handleSelectPopupClose}
                           width={'559px'}
                           content={
                               <>
                                   <div className="px-6 pt-8 pb-10 space-y-4 border-b-[1px] border-[rgba(0,0,0,0.15)]">
                                       <Field label="級別">
                                           <input className="mr-1" id="r-1" type="radio" name="radioGroup"/>
                                           <label htmlFor="r-1" className="mr-4 cursor-pointer">S2</label>
                                           <input className="mr-1" id="r-2" type="radio" name="radioGroup"/>
                                           <label htmlFor="r-2" className="mr-4 cursor-pointer">S3</label>
                                       </Field>
                                       <Field label="病歷號">
                                           <TextField/>
                                       </Field>
                                   </div>
                                   <div className="h-[60px] flex flex-row items-center justify-end">
                                       <button
                                           className="flex items-center justify-center h-10 px-4 mr-4 border-[2px] bg-[#2B6CB0] text-white border-[#2B6CB0] rounded-[6px]"
                                           onClick={handleSelectPopupClose}
                                       >確定
                                       </button>
                                   </div>
                               </>
                           }/>
            )}
        </div>
    )
}
export default CaseReceiptList