import React, {useEffect, useState} from 'react';
import LocalStorageFormSetting from "../../Share/LocalStorageFormSetting";
import RadioButton from "../../../Input/RadioButton";
import {OptionFive, OptionFour, OptionOne, OptionSix, OptionThree, OptionTwo} from "./formOption";
import {ButtonColorEnum, Field, SizeEnum, TextField, Button} from "edah-component/dist";

const Layout = ({children}) => {
    return (
        <div className="p-4 w-full overflow-auto border-[1px] border-[rgba(0,0,0,0.15)] rounded-md h-full">
            {children}
        </div>
    )
}

/**
 * 迷你營養評估
 * @return {JSX.Element}
 */
const MiniNutritionalAssessment = () => {
    /**
     * 初始值
     * @type {{legLong: number, optionFour: number, kneeLong: number, hipLong: number, optionTwo: number, optionThree: number, optionOne: number, armLong: number, optionSix: number, evaluationDate: string, calorie: number, evaluator: string, optionFive: number}}
     */
    const initData = {
        evaluationDate: "", // 評估日期
        evaluator: "", // 評估人員
        optionOne: 0,
        optionTwo: 0,
        optionThree: 0,
        optionFour: 0,
        optionFive: 0,
        optionSix: 0,
        calorie: 0,
        armLong: 0,
        kneeLong: 0,
        legLong: 0,
        hipLong: 0
    }

    // 預設表單資料
    const [formData, setFormData] = useState(null);
    /**
     * 更改表單資料
     * @param e
     * @param item
     */
    const handleInputChange = (e, item) => {
        const {value} = e.target;
        setFormData({
            ...formData,
            [item]: value
        });
    };
    /**
     * 更改單選表單資料
     * @param value
     * @param item
     */
    const handleRadioChange = (value, item) => {
        setFormData({
            ...formData,
            [item]: value
        });
    };

    /**
     * 新增表單內容
     * @param data
     */
    const handleInitData = (data) => {
        if (!data) {
            setFormData(null)
        } else {
            setFormData({...initData, ...data})
        }
    };
    /**
     * 公式計算按鈕
     */
    const handleCalculation = () => {

    };


    return <Layout>
        {/* 標題 */}
        <div className="flex items-center">
            <div
                className="flex items-center justify-start text-[20px] mr-4 mb-4 pl-2 bg-white sticky top-0 text-[#2B6CB0] border-l-8 border-[#2B6CB0]">迷你營養評估
            </div>
        </div>
        {/* 本地儲存 */}
        <LocalStorageFormSetting name="mini-nutritional-assessment" data={formData}
                                 onClickForm={(data) => handleInitData(data)}/>
        {formData && <>
            {/* 評估人員 */}
            <div className="flex justify-start p-2 items-center">
                <div>
                    <Field childrenSx={{flexGrow: 0}} label="評估日期">
                        <TextField
                            value={formData.evaluationDate}
                            onChange={(e) => {
                                handleInputChange(e, "evaluationDate")
                            }}/>
                    </Field>
                </div>
                <div>
                    <Field childrenSx={{flexGrow: 0}} label="評估人員">
                        <TextField
                            value={formData.evaluator}
                            onChange={(e) => {
                                handleInputChange(e, "evaluator")
                            }}/>
                    </Field>
                </div>
            </div>
            <div className="relative overflow-x-auto overflow-y-auto h-[55vh]">
                <table className="whitespace-nowrap bg-white w-full text-sm text-left min-w-full border">
                    <tbody>
                    {/* 基本資料 */}
                    <tr className="w-full border-b border-gray-200">
                        <td colSpan={8} className="w-full px-4 py-4 ">
                            <div>營養狀況評估：依個案情形逐一圈選下列選項之得分，並在最後計算總分</div>
                            <div className="w-full grid grid-cols-6">
                                <Field labelWidth={70} label="年齡">
                                    71歲
                                </Field>
                                <Field labelWidth={70} childrenSx={{display: 'flex', alignItems: 'center'}}
                                       label="膝長">
                                    <TextField size={SizeEnum.Small} inputWidth={SizeEnum.Small}
                                               value={formData.kneeLong}
                                               onChange={(e) => {
                                                   handleInputChange(e, "kneeLong")
                                               }}
                                    />
                                    <div>公分</div>
                                </Field>
                                <Field labelWidth={70} childrenSx={{display: 'flex', alignItems: 'center'}}
                                       label="臂圍">
                                    <TextField size={SizeEnum.Small} inputWidth={SizeEnum.Small}
                                               value={formData.armLong}
                                               onChange={(e) => {
                                                   handleInputChange(e, "armLong")
                                               }}
                                    />
                                    公分
                                </Field>
                                <Field labelWidth={70} childrenSx={{display: 'flex', alignItems: 'center'}}
                                       label="腿圍">
                                    <TextField size={SizeEnum.Small} inputWidth={SizeEnum.Small}
                                               value={formData.legLong}
                                               onChange={(e) => {
                                                   handleInputChange(e, "legLong")
                                               }}
                                    />
                                    公分
                                </Field>
                                <Field labelWidth={70} childrenSx={{display: 'flex', alignItems: 'center'}}
                                       label="臀圍">
                                    <TextField size={SizeEnum.Small} inputWidth={SizeEnum.Small}
                                               value={formData.hipLong}
                                               onChange={(e) => {
                                                   handleInputChange(e, "hipLong")
                                               }}
                                    />
                                    公分
                                </Field>
                                <div className="flex justify-center">
                                    <Button color={ButtonColorEnum.Secondary} text={"公式計算"}
                                            onClick={handleCalculation}/>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr className="bg-white border-b border-gray-200">
                        <td colSpan={8} className="px-4 py-4">
                            <div className="w-full grid grid-cols-6">
                                <Field labelWidth={70} label="身高">
                                    144.4公分
                                </Field>
                                <Field labelWidth={70} label="體重">
                                    37.4公斤
                                </Field>
                                <Field labelWidth={70} label="BMI">
                                    17.9
                                </Field>
                                <Field labelWidth={70} label="理想體重">
                                    46公斤
                                </Field>
                                <Field childrenSx={{display: 'flex', alignItems: 'center'}}
                                       className="col-span-2"
                                       labelWidth={95}
                                       label="建議攝取熱量">
                                    <TextField size={SizeEnum.Small}
                                               inputWidth={SizeEnum.Small}
                                               value={formData.calorie}
                                               onChange={(e) => {
                                                   handleInputChange(e, "calorie")
                                               }}/>
                                    大卡/天
                                </Field>
                            </div>
                        </td>
                    </tr>
                    {/* 標題 */}
                    <tr className="w-full bg-white border-b border-gray-200">
                        <td colSpan={8} className="w-full">

                            <div className="w-full grid grid-cols-[300px,40px,1fr,60px]">
                                <div className="border-r border-gray-200 flex items-center px-3 py-2">
                                    營養篩檢項目(第一階段)
                                </div>
                                <div className="border-r border-gray-200 flex items-center px-1 py-2">
                                    分數
                                </div>
                                <div className="border-r border-gray-200 flex items-center px-3 py-2">
                                    說明
                                </div>
                                <div className="flex items-center px-3 py-2 justify-center">
                                    得分
                                </div>

                            </div>
                        </td>

                    </tr>
                    {/* 過去三個月是否因食慾不佳、消化問題、咀嚼或吞嚥困難以致進食量越來越少？ */}
                    <tr className="w-full bg-white border-b border-gray-200">
                        <td colSpan={8} className="w-full">
                            <div className="w-full grid grid-cols-[300px,40px,1fr,60px]">
                                <div className="whitespace-normal border-r border-gray-200 flex items-center px-3 py-2">
                                    過去三個月是否因食慾不佳、消化問題、咀嚼或吞嚥困難以致進食量越來越少？
                                </div>
                                <div
                                    className="border-r border-gray-200 px-2 py-2 flex-col flex items-end justify-between">
                                    <div>0</div>
                                    <div>1</div>
                                    <div>2</div>
                                </div>
                                <div className="border-r border-gray-200 items-center ml-4">
                                    {
                                        OptionOne.map((option, index) => (
                                            <RadioButton wrapperClassName={"w-[200px] my-2"} mode={formData.optionOne}
                                                         modeChecked={option.value}
                                                         handleModeChange={(value) => {
                                                             handleRadioChange(value, "optionOne")
                                                         }} label={option.label}/>
                                        ))
                                    }
                                </div>
                                <div className="flex items-center px-3 py-2 justify-center">
                                    {formData.optionOne}
                                </div>

                            </div>
                        </td>

                    </tr>
                    {/* 近三個月體重變化？ */}
                    <tr className="w-full bg-white border-b border-gray-200">
                        <td colSpan={8} className="w-full">
                            <div className="w-full grid grid-cols-[300px,40px,1fr,60px]">
                                <div className="whitespace-normal border-r border-gray-200 flex items-center px-3 py-2">
                                    近三個月體重變化？
                                </div>
                                <div
                                    className="border-r border-gray-200 px-2 py-2 flex-col flex items-end justify-between">
                                    <div>0</div>
                                    <div>1</div>
                                    <div>2</div>
                                    <div>3</div>
                                </div>
                                <div className="border-r border-gray-200 items-center ml-4">
                                    {
                                        OptionTwo.map((option, index) => (
                                            <RadioButton wrapperClassName={"w-[200px] my-2"} mode={formData.optionTwo}
                                                         modeChecked={option.value}
                                                         handleModeChange={(value) => {
                                                             handleRadioChange(value, "optionTwo")
                                                         }} label={option.label}/>
                                        ))
                                    }
                                </div>
                                <div className="flex items-center px-3 py-2 justify-center">
                                    {formData.optionTwo}
                                </div>

                            </div>
                        </td>

                    </tr>
                    {/* 行動力？ */}
                    <tr className="w-full bg-white border-b border-gray-200">
                        <td colSpan={8} className="w-full">
                            <div className="w-full grid grid-cols-[300px,40px,1fr,60px]">
                                <div className="whitespace-normal border-r border-gray-200 flex items-center px-3 py-2">
                                    行動力？
                                </div>
                                <div
                                    className="border-r border-gray-200 px-2 py-2 flex-col flex items-end justify-between">
                                    <div>0</div>
                                    <div>1</div>
                                    <div>2</div>
                                </div>
                                <div className="border-r border-gray-200 items-center ml-4">
                                    {
                                        OptionThree.map((option, index) => (
                                            <RadioButton wrapperClassName={"w-[300px] my-2"} mode={formData.optionThree}
                                                         modeChecked={option.value}
                                                         handleModeChange={(value) => {
                                                             handleRadioChange(value, "optionThree")
                                                         }} label={option.label}/>
                                        ))
                                    }
                                </div>
                                <div className="flex items-center px-3 py-2 justify-center">
                                    {formData.optionThree}
                                </div>

                            </div>
                        </td>

                    </tr>
                    {/* 過去三個月內曾有精神性壓力或急性疾病發作？ */}
                    <tr className="w-full bg-white border-b border-gray-200">
                        <td colSpan={8} className="w-full">
                            <div className="w-full grid grid-cols-[300px,40px,1fr,60px]">
                                <div className="whitespace-normal border-r border-gray-200 flex items-center px-3 py-2">
                                    過去三個月內曾有精神性壓力或急性疾病發作？
                                </div>
                                <div
                                    className="border-r border-gray-200 px-2 py-2 flex-col flex items-end justify-between">
                                    <div>0</div>
                                    <div>1</div>
                                </div>
                                <div className="border-r border-gray-200 items-center ml-4">
                                    {
                                        OptionFour.map((option, index) => (
                                            <RadioButton wrapperClassName={"w-[200px] my-2"} mode={formData.optionFour}
                                                         modeChecked={option.value}
                                                         handleModeChange={(value) => {
                                                             handleRadioChange(value, "optionFour")
                                                         }} label={option.label}/>
                                        ))
                                    }
                                </div>
                                <div className="flex items-center px-3 py-2 justify-center">
                                    {formData.optionFour}
                                </div>

                            </div>
                        </td>

                    </tr>
                    {/* 神經精神問題？ */}
                    <tr className="w-full bg-white border-b border-gray-200">
                        <td colSpan={8} className="w-full">
                            <div className="w-full grid grid-cols-[300px,40px,1fr,60px]">
                                <div className="whitespace-normal border-r border-gray-200 flex items-center px-3 py-2">
                                    神經精神問題？
                                </div>
                                <div
                                    className="border-r border-gray-200 px-2 py-2 flex-col flex items-end justify-between">
                                    <div>0</div>
                                    <div>1</div>
                                    <div>2</div>
                                </div>
                                <div className="border-r border-gray-200 items-center ml-4">
                                    {
                                        OptionFive.map((option, index) => (
                                            <RadioButton wrapperClassName={"w-[200px] my-2"} mode={formData.optionFive}
                                                         modeChecked={option.value}
                                                         handleModeChange={(value) => {
                                                             handleRadioChange(value, "optionFive")
                                                         }} label={option.label}/>
                                        ))
                                    }
                                </div>
                                <div className="flex items-center px-3 py-2 justify-center">
                                    {formData.optionFive}
                                </div>

                            </div>
                        </td>

                    </tr>
                    {/* 身體質量指數(BMI)？ */}
                    <tr className="w-full bg-white border-b border-gray-200">
                        <td colSpan={8} className="w-full">
                            <div className="w-full grid grid-cols-[300px,40px,1fr,60px]">
                                <div className="whitespace-normal border-r border-gray-200 flex items-center px-3 py-2">
                                    身體質量指數(BMI)？
                                </div>
                                <div
                                    className="border-r border-gray-200 px-2 py-2 flex-col flex items-end justify-between">
                                    <div>0</div>
                                    <div>1</div>
                                    <div>2</div>
                                    <div>3</div>
                                </div>
                                <div className="border-r border-gray-200 items-center ml-4">
                                    {
                                        OptionSix.map((option, index) => (
                                            <RadioButton wrapperClassName={"w-[200px] my-2"} mode={formData.optionSix}
                                                         modeChecked={option.value}
                                                         handleModeChange={(value) => {
                                                             handleRadioChange(value, "optionSix")
                                                         }} label={option.label}/>
                                        ))
                                    }
                                </div>
                                <div className="flex items-center px-3 py-2 justify-center">
                                    {formData.optionSix}
                                </div>

                            </div>
                        </td>

                    </tr>
                    {/* 總分 */}
                    <tr className="w-full bg-white border-b border-gray-200">
                        <td colSpan={8} className="w-full">
                            <div className="w-full justify-center grid grid-cols-[1fr,60px]">
                                <div className="flex border-r border-gray-200 justify-center items-center px-3 py-2">
                                    總分
                                </div>
                                <div className="flex items-center px-3 py-2 justify-center">
                                    {formData.optionOne + formData.optionTwo + formData.optionThree + formData.optionFour + formData.optionFive + formData.optionSix}
                                </div>

                            </div>
                        </td>

                    </tr>
                    </tbody>
                </table>
            </div>
        </>
        }
    </Layout>
}
export default MiniNutritionalAssessment