import Pagination from "./Pagination/Pagination";
import PropTypes from "prop-types";

const PageTable = ({columns, currentTableData, pageSize, currentPage, totalCount, setCurrentPage}) => {
    return (
        <div className='bg-zinc-300 rounded-bl-[5px] rounded-br-[5px] border-t-0 border-l-2 border-r-2 border-b-2 border-zinc-500 overflow-auto'>
            <div className='flex justify-center mx-[50px] my-[30px]'>
                <table className=' w-[500px] justify-center w-[90%]'>
                    <thead className='bg-[#e4e4e4] text-black text-sm font-bold h-[40px]'>
                        <tr>
                            {columns.map((item, index) => {
                                return (<th key={index} className='border-solid border-[1px] border-[#818181]'>{item.Header}</th>);
                            })}
                        </tr>
                    </thead>
                    <tbody>
                    {currentTableData.map((item, row_index) => {
                        return (
                        <tr key={row_index} className='h-[40px] text-center text-[#202020] text-xs font-medium'>
                            {
                                columns.map((column, index) => {
                                    
                                    return(
                                    <td key={index} className='border-solid border-[1px] border-[#818181]'>
                                        {
                                            (column['has_checked'] !== undefined && column['has_checked'] === true) ?
                                            <input className='text-black text-xl' type="checkbox"></input> : item.hasOwnProperty(column['accessor']) ?
                                            item[column['accessor']] : ''
                                        }
                                    </td>
                                    )
                                })
                            }
                        </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>
            <Pagination
                className='justify-center w-full flex mb-[10px]'
                currentPage={currentPage}
                totalCount={totalCount}
                pageSize={pageSize}
                onPageChange={page => setCurrentPage(page)}
            />
        </div>
    );
};

PageTable.defaultProps = {
    columns: [],
    currentTableData: [],
    pageSize: 10,
    currentPage: 1,
    totalCount: 0,
    setCurrentPage: null
}

PageTable.propTypes = {
    columns: PropTypes.array.isRequired,        //[{Header: '', accessor: '', has_checked: true}]  
    currentTableData: PropTypes.array.isRequired,
    pageSize: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired,
    setCurrentPage: PropTypes.func
};
  
export default PageTable;