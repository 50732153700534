import React, { useState, useMemo, useEffect, useCallback } from 'react';
import {ReactComponent as CloseIcon} from '../../assets/images/icons/close_notify_view_more.svg';
import PageTable from './PageTable';
import { fetchNotificationData } from '../../api/api';
import InfinityTable from './InfinityTable';

let initialDataSize = 15;
let nextCount = 10;

const columns = [
    { Header: "Checked", accessor: "checked", has_checked: true, classWidth: "w-[100px]" },
    { Header: "Time", accessor: "receivedTime", classWidth: "w-[200px]" },
    { Header: "Type", accessor: "message", classWidth: "w-[200px]" },
    { Header: "Detail", accessor: "detail" }
  ];

const NotificationsModal = ({ closeModal }) => {
    const [data, steNotificationsData] = useState([]);
    const [currentData, setCurrentData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        const totalData = fetchNotificationData();
        steNotificationsData(totalData);

        const initialData = totalData.slice(0, initialDataSize);
        setCurrentData(initialData);
        setIsLoading(false);
    }, [])

    const updateMoreData = () => {
        if (isLoading) return;

        setIsLoading(true);

        const totalData = fetchNotificationData();
        steNotificationsData(totalData);
        if(currentData.length < totalData.length) {
            const tmpData = totalData.slice(currentData.length-1, currentData.length + nextCount - 1);
            if(tmpData.length > 0)
            {
                setCurrentData((prevDatas) => [...prevDatas, ...tmpData]);
            }
        }
        setIsLoading(false);
    }

    return (
        <div className='absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 w-full h-[100vh] bg-[rgb(200,200,200)]/40 justify-center items-center flex'>
            <div className=' w-[80%] h-[80%] bg-transparent flex flex-col'>
                <div className='h-[60px] h-[60px] bg-zinc-300 rounded-tl-[5px] rounded-tr-[5px] border-2 border-zinc-500 flex justify-between items-center'>
                    <div className='text-neutral-500 text-[28px] font-medium text-left ml-[30px]'>所有通知</div>
                    <button className='mr-[20px]'
                            onClick={() => {
                                closeModal();
                            }}><CloseIcon/></button>
                </div>
                {/*
                <PageTable
                    columns={columns}
                    currentTableData={currentPageData}
                    currentPage={currentPage}
                    totalCount={data.length}
                    pageSize={PageSize}
                    setCurrentPage={setCurrentPage}
                />
                */}
            </div>
        </div>
    )
}

export default NotificationsModal;
