import React, {useState} from "react";
import {t} from "i18next"
import SubSidebar from "../../components/Sidebar/SubSideBar";
import CaseReceiptList from "../../components/HomeHealthCare/CaseReceiptList";
import { ReactComponent as Close } from "../../assets/images/icons/close.svg";

/**
 * 居家評估作業＿收案清單
 * @return {JSX.Element}
 */
const HomeAssessmentOperations = () => {

    // 保存當前打開的分頁
    const [tabs, setTabs] = useState([{ path: '/caseReceiptList', name: '個案列表', component: CaseReceiptList }]);
    const [activeTab, setActiveTab] = useState('/caseReceiptList');

    /**
     * 處理菜單點擊事件
     * @param {Object} menuItem - 菜單項目對象
     * @return {void}
     */
    const handleMenuClick = (menuItem) => {
        if (!tabs.find(tab => tab.path === menuItem.path) && menuItem.path !== '/caseReceiptList') {
            const newTabs = tabs.length >= 6 ? [tabs[0], ...tabs.slice(2), menuItem] : [...tabs, menuItem];
            setTabs(newTabs);
        }
        setActiveTab(menuItem.path);
    };

    /**
     * 處理關閉分頁事件
     * @param {string} tabPath - 要關閉的分頁路徑
     * @return {void}
     */
    const handleCloseTab = (tabPath) => {
        if (tabPath === '/caseReceiptList') {
            return;
        }
        const tabIndex = tabs.findIndex(tab => tab.path === tabPath);
        const newTabs = tabs.filter(tab => tab.path !== tabPath);
        setTabs(newTabs);

        // 關閉tab時去切換啟用前一個tab
        if (activeTab === tabPath) {
            const newActiveTab = tabIndex > 0 ? newTabs[tabIndex - 1].path : '/caseReceiptList';
            setActiveTab(newActiveTab);
        }
    };

    /**
     * 當全選擇的分頁內容
     * @return {JSX.Element|null}
     */
    const renderActiveTabContent = () => {
        const activeComponent = tabs.find(tab => tab.path === activeTab)?.component;
        return activeComponent ? React.createElement(activeComponent) : null;
    };

    return (
        <div className="w-full p-4 bg-[#FAFAFA] flex flex-row items-start justify-start">
            {/* 側邊欄 */}
            <SubSidebar onMenuClick={handleMenuClick} />
            <div className="w-[calc(100%-310px)]">
                {/* 分頁區域 */}
                <div className="tabs flex flex-row items-center justify-start overflow-x-auto">
                    {tabs.map((tab, index) => (
                        <div className={`${activeTab === tab.path ? "bg-white text-[#2B6CB0] flex-shrink-0 border-b-4 border-[#2B6CB0]" : "text-[#111111] bg-[#E4E4E7]"} tab flex-shrink-0 h-10 flex flex-row items-center justify-between p-4 mr-2 rounded-t-[6px]`}
                             key={index}>
                            <span className="min-w-[150px] text-left cursor-pointer"
                                onClick={() => setActiveTab(tab.path)}>{tab.name}</span>
                            {tab.path !== '/caseReceiptList' && (
                                <button onClick={() => handleCloseTab(tab.path)}><Close /></button>
                            )}
                        </div>
                    ))}
                </div>
                {/* 內容區域 */}
                <div className="content">
                    {renderActiveTabContent()}
                </div>
            </div>
        </div>
    )
}
export default HomeAssessmentOperations