import React, {useEffect, useState} from "react";
import {t} from "i18next";
import {BaseInput} from "../../components/Input/BaseInput";
import {ReactComponent as Check} from "../../assets/images/icons/checkbox.svg"
import {arrayIsEmpty, fuzzySearchObjects, InputTypeEnum} from "edah_utils/dist"
import {Button, ButtonSizeEnum, ButtonVariantEnum, ButtonColorEnum, IconEnum} from "edah-component/dist";
import {DateRangePicker} from "edah-component/dist/index";
import dayjs from "dayjs";

/**
 * 居家護理>急診使用查詢報表
 * @return {JSX.Element}
 */
const EmergencyUseReport = () => {
    // 搜尋文字
    const [searchText, setSearchText] = useState('')
    // 日期範圍
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    // 篩選後的數據
    const [filteredData, setFilteredData] = useState([]);

    // Fake Data
    const [dataObject, setDataObject] = useState([
        {
            homeNo: '09876',
            patientId: '0801234',
            patientName: '黃ＯＯ',
            treatmentCode:"J20.9 Acute bromcjiois inspecified",
            emrgencyPriceNo:"EAA00022",
            emrgencyZone: 'EDAH',
            emrgencyStartDate: '呼吸胸腔',
            emrgencyTrends: '(MBD)穩定出院',
            emrgencyEndDate: '2023-09-12',
            caseNis:"陳ＯＯ",
            caseStartDate:'2023-09-12',
            caseEndDate:'2023-09-12'
        },
        {
            homeNo: '09876',
            patientId: '0801234',
            patientName: '黃ＯＯ',
            treatmentCode:"N39.0Urinary tract infection site not specified",
            emrgencyPriceNo:"EAA00022",
            emrgencyZone: 'EDAH',
            emrgencyStartDate: '呼吸胸腔',
            emrgencyTrends: '(MBD)穩定出院',
            emrgencyEndDate: '2023-09-12',
            caseNis:"陳ＯＯ",
            caseStartDate:'2023-09-12',
            caseEndDate:'2023-09-12'
        }        
    ]);

    /**
     * 搜尋文字改變時
     * @param e {Event} e
     * @return {void}
     */
    const handleSearchTextOnChange = (e) => setSearchText(e.target.value)

    /**
     * 取得模糊匹配後的資料陣列
     * @return {Array<Object>}
     */
    const getFilterData = (data) => fuzzySearchObjects(data, searchText)

    /**
     * 日期範圍改變時
     * @param dateRange {Array} [startDate, endDate]
     * @return {void}
     */
    const handleDateRangeChange = (dateRange) => {
        const [start, end] = dateRange;
        setStartDate(start ? start.toDate() : null);
        setEndDate(end ? end.toDate() : null);
    };

    /**
     * 篩選數據
     * @param data {Array} 數據數組
     * @return {Array} 篩選後的數據數組
     */
    const filterDataByDateRange = (data) => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        return data.filter(item => {
            const caseStartDate = new Date(item.caseStartDate);
            return caseStartDate >= start && caseStartDate <= end;
        });
    };

    /**
     * 查詢按鈕點擊時
     * @return {void}
     */
    const handleQueryClick = () => {
        let filtered = [];
        filtered = filterDataByDateRange(dataObject);
        setFilteredData(fuzzySearchObjects(filtered, searchText));
    };

    return (
        <div className="w-full p-4 bg-[#FAFAFA]">
            {/*篩選清單*/}
            <div className="filterGroup flex flex-row flex-wrap items-center justify-start space-x-2 py-2">
                {/* 查詢日期起訖 */}
                <DateRangePicker
                    required
                    localeText={{start: '查詢開始日期', end: '查詢結束日期'}}
                    value={[startDate ? dayjs(startDate) : null, endDate ? dayjs(endDate) : null]}
                    onChange={handleDateRangeChange}/>
                {/*查詢送出按鈕*/}
                    <Button color={ButtonColorEnum.Primary}
                            size={ButtonSizeEnum.Medium}
                            variant={ButtonVariantEnum.Contained}
                            onClick={handleQueryClick}>
                        {t('general.query')}
                    </Button>
            </div>

            {/*列表清單區塊*/}
            <div className="maintainContainer flex flex-row items-start justify-start">
                <div className="p-4 bg-white w-full border-[1px] border-[#D4D4D8] rounded-[12px] mr-4">
                    <div className="searchGroup flex flex-row items-center justify-between mb-4">
                        {/*進階搜尋*/}
                        <BaseInput className="w-[320px] h-10 pl-4 border-[2px] border-[#D4D4D8] rounded-[6px]"
                                   type="text"
                                   placeholder={`${t('general.advancedSearch')}`}
                                   inputMode={'search'}
                                   value={searchText}
                                   onChange={(e) => handleSearchTextOnChange(e)}/>
                        {/* excel輸出 */}
                        <Button color={ButtonColorEnum.Success}
                                size={ButtonSizeEnum.Large}
                                variant={ButtonVariantEnum.Text}
                                icon={IconEnum.Export}>
                            {t('general.export')}
                        </Button>
                    </div>

                    {/*列表清單*/}
                    <ul className="tableList EURTableList">
                        {/*表單項目標題*/}
                        <li className="title">
                            <div><Check/></div>
                            <div>家字號</div>
                            <div>病歷號</div>
                            <div>個案姓名</div>
                            <div>診斷</div>
                            <div>急診批價<br/>序號</div>
                            <div>入急診<br/>醫院</div>
                            <div>入急診<br/>日期</div>
                            <div>急診後送動向</div>
                            <div>出急診日期</div>
                            <div>收案護理師</div>
                            <div>收案日期</div>
                            <div>結案日期</div>
                        </li>

                        {!arrayIsEmpty(getFilterData(filteredData)) ? getFilterData(filteredData).map((item, index) =>
                            <li key={index}>
                                {/*checkBox*/}
                                <div>
                                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5"
                                           type="checkbox"
                                           id={`r${index}`} style={{accentColor: "#38A169"}}/>
                                </div>
                                {/*家字號*/}
                                <div>{item.homeNo}</div>
                                {/*病歷號*/}
                                <div>{item.patientId}</div>
                                {/*個案姓名*/}
                                <div>{item.patientName}</div>
                                {/* 診斷 */}
                                <div>{item.treatmentCode}</div>
                                {/*急診批價序號*/}
                                <div>{item.emrgencyPriceNo}</div>
                                {/*入急診醫院*/}
                                <div>{item.emrgencyZone}</div>
                                {/*入急診日期*/}
                                <div>{item.emrgencyStartDate}</div>
                                {/*急診後送動向*/}
                                <div>{item.emrgencyTrends}</div>
                                {/*出急診日期*/}
                                <div>{item.emrgencyEndDate}</div>
                                {/*收案護理師*/}
                                <div>{item.caseNis}</div>
                                {/* 收案日期 */}
                                <div>{item.caseStartDate}</div>
                                {/* 結案日期 */}
                                <div>{item.caseEndDate}</div>
                            </li>
                        ) : null}
                    </ul>
                </div>
            </div>
            
        </div>
    )
}
export default EmergencyUseReport