import React, {useMemo, useState} from 'react'
import {usePopup} from '../PopupProvider';
import {rsisAddOwnerUser, rsisDelOwnerUser, rsisQueryOwnerUser} from "../../../api/v1/RSIS"
import {ApiErrorStatusEnum, fuzzySearchObjects} from "edah_utils/dist"
import QueryCreateUserList from "../Modal/QueryCreateUserList";
import {InlineEditDataGrid, TextField, Card, AlertTypeEnum} from "edah-component/dist";
import {SearchTextField} from "../../SearchTextField/SearchTextField";
import {t} from "i18next";

/**
 * 承辦部門人員維護
 * @returns {JSX.Element}
 */
const ContactPersonMaintain = () => {
    // 表格資料集
    const [dataList, setDataList] = useState([])
    // 未保存的資料集
    const [unSaveDataMap, setUnSaveDataMap] = useState({})
    // 選中的 row id
    const [focusRowId, setFocusRowId] = useState(null)
    // 模糊搜尋的字串
    const [queryString, setQueryString] = useState('');

    // 使用上下文
    const {
        setShowQueryCreateUserList,
        showQueryCreateUserList,
        showToast,
    } = usePopup()


    /**
     * 查詢彈窗點擊時，更新列表事件
     * @param  item {Object} - 選擇的人員
     * @return {void}
     */
    const handleOnUpdate = (item) => {
        setUnSaveDataMap({...unSaveDataMap, [focusRowId]: {
                userNo: item.userNo,
                userName: item.userName,
                deptNo: item.depNo,
                deptName: item.depName
            }})
        // 關閉彈窗
        setShowQueryCreateUserList(false)
    }

    /**
     * 儲存列表事件
     * @param  item {Object} - 要保存的項目
     * @return {void}
     */
    const handleSaveItemOnClick = (item) => {
        rsisAddOwnerUser(item).then(res => {
            const {err, data, msg} = res
            // 保存成功
            if (err === ApiErrorStatusEnum.Success) {
                showToast({message: '保存成功', type: AlertTypeEnum.Success})
                // 更新列表中的項目，將其標記為非新項目且不可編輯
                getQueryOwnerUser()
            } else {
                // 保存失敗
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 刪除承辦部門人員
     * @param item {Object} - 要刪除的項目
     * @return {void}
     */
    const delOwnerUser = (item) => {
        rsisDelOwnerUser({
            // 人員工號
            useNo: item.userNo,
        }).then(res => {
            const {err, msg} = res
            // 刪除成功
            if (err === ApiErrorStatusEnum.Success) {
                // 重新取得列表資料
                getQueryOwnerUser()
                // 顯示成功訊息
                showToast({message: msg, type: AlertTypeEnum.Success})
            } else { // 刪除失敗
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 人員查詢方框點擊時
     * @param id {string} - row id
     * @return {void}
     */
    const handlePersonSearchOnClick = (id) => {
        setFocusRowId(id)
        setShowQueryCreateUserList(true)
    }


    /**
     * 取得承辦部門人員所有資料
     * @return {void}
     */
    const getQueryOwnerUser = () => {
        rsisQueryOwnerUser({}).then(res => {
            const {err, data, msg} = res
            //取得成功
            if (err === ApiErrorStatusEnum.Success) {
                // 設定列表資料
                setDataList(data)
            } else { //取得失敗
                //清除列表資料
                setDataList([])
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 取得模糊匹配後的資料陣列
     * @return {Array<Object>}
     */
    const getFilterData = () => fuzzySearchObjects(dataList, queryString)
    /**
     * 搜尋字串改變時
     * @param event {Event}
     * @return {void}
     */
    const handleQueryOnChange = (event) => setQueryString(event.target.value);

    /**
     * 承辦部門人員維護 - 表格用的 header 與設定資料
     */
    const header = [
        { field: 'userNo', headerName: '人員工號', flex: 1, editable: true, minWidth: 120, renderCell: (params) => {
                // 是否可編輯
                const isEditable = params.isEditable
                return  isEditable ?
                    <div className="flex items-center h-[100%]">
                        <TextField
                            value={unSaveDataMap[params.id]?.userNo}
                            onClick={()=>handlePersonSearchOnClick(params.id)}
                        />
                    </div>: <>{params.row.userNo}</>
            }},
        { field: 'userName', headerName:'姓名', flex: 2, minWidth: 120, renderCell: (params) => {
                // 是否為新項目
                const isNew = params.row.isNew
                return isNew ? <>{unSaveDataMap[params.id]?.userName}</> : <>{params.row.userName}</>
        }
        },
        { field: 'deptNo', headerName:'部門代號', flex: 1, minWidth: 120, renderCell: (params) => {
                const isNew = params.row.isNew
                return isNew ? <>{unSaveDataMap[params.id]?.deptNo}</> : <>{params.row.deptNo}</>
        }
        },
        { field: 'deptName', headerName: '部門名稱', flex: 1, minWidth: 150, renderCell: (params) => {
                const isNew = params.row.isNew
                return isNew ? <>{unSaveDataMap[params.id]?.deptName}</> : <>{params.row.deptName}</>
        }
        },
    ]

    /**
     * 第一次執行時
     * @return {void}
     */
    useMemo(() => {
        // 取得承辦部門人員所有資料
        getQueryOwnerUser()
    }, [])

    return (
        <Card className='px-6 py-2'>
            <div className="mt-8">
                <div className="text-left mb-2">
                    <SearchTextField
                        value={queryString}
                        onChange={handleQueryOnChange}
                        placeholder={t('general.advancedSearch')}
                    />
                </div>
                {/* 承辦部門人員維護表格 */}
                <InlineEditDataGrid
                    rows={getFilterData() || []}
                    columns={header}
                    checkboxSelection={false}
                    disableColumnFilter
                    disableColumnSelector
                    disableColumnMenu
                    disableDensitySelector
                    disableRowSelectionOnClick
                    height={'600px'}
                    disableEdit={true}
                    onSave={(row, key)=> handleSaveItemOnClick(unSaveDataMap[row.id])}
                    onCancel={(row)=> {
                        const rawTemp = {...unSaveDataMap}
                        delete rawTemp[row.id]
                        setUnSaveDataMap(rawTemp)
                    }}
                    onDeleteRow={(row)=> delOwnerUser(row)}
                />
            </div>
            {/* 人員查詢彈窗 */}
            {showQueryCreateUserList && (
                <QueryCreateUserList
                    handleOnUpdate={handleOnUpdate}
                    handleOnClose={() => setShowQueryCreateUserList(false)}
                />
            )}
        </Card>
    )
}

export default ContactPersonMaintain
