import { useSelector } from 'react-redux'
import A4Print from './A4Print'
import { time2String } from 'edah_utils/dist'
import { DATE_FORMAT } from '../../constants/common'
import './print.scss'
import PrintReplyLetterOrderCover from './PrintReplyLetterOrderCover'

/**
 * 轉診回函 - 單筆/整批列印內容
 * @returns {JSX.Element}
 */
const ReplyLetterOrderContent = ({ info }) => {
    return (
        <div className="mx-auto font-sans">
            <div className="font-bold text-lg flex justify-between">
                <div className="flex">
                    <div className="mr-2">
                        {time2String(info.encounterDate, DATE_FORMAT)}
                    </div>
                    <div className="mr-2">{info.encouterId}</div>
                    <div className="mr-2">{info.patientId}</div>
                    <div className="mr-2">{info.patientName}</div>
                    <div>Dr: {info.doctorName}</div>
                </div>
                <div className="flex">
                    <div className="mr-2">{info.ptTypeName}</div>
                    <div>{info.cardNo}</div>
                </div>
            </div>
            <ol>
                <li className="mb-1">
                    1.
                    <span className="border-b  border-[#111111] mx-2">
                        {info.treatmentCode}
                    </span>
                    <span className="border-b  border-[#111111]">
                        {info.treatmentCodeName}
                    </span>
                </li>
                <li className="mb-1">
                    2.
                    <span className="border-b  border-[#111111] mx-2">
                        {info.treatmentCode1}
                    </span>
                    <span className="border-b  border-[#111111]">
                        {info.treatmentCode1Name}
                    </span>
                </li>
                <li className="mb-1">
                    3.
                    <span className="border-b  border-[#111111] mx-2">
                        {info.treatmentCode2}
                    </span>
                    <span className="border-b  border-[#111111]">
                        {info.treatmentCode2Name}
                    </span>
                </li>
            </ol>
            <div className="w-full">
                <div className="w-full grid grid-cols-[40%,8%,7%,8%,4%,7%,7%,8%,4%,4%] gap-0.5">
                    <div className="border border-[#111111] p-1">醫令</div>
                    <div className="border border-[#111111] p-1">次量</div>
                    <div className="border border-[#111111] p-1">單位</div>
                    <div className="border border-[#111111] p-1">用法</div>
                    <div className="border border-[#111111] p-1">天</div>
                    <div className="border border-[#111111] p-1">途徑</div>
                    <div className="border border-[#111111] p-1">總量</div>
                    <div className="border border-[#111111] p-1">單位</div>
                    <div className="border border-[#111111] p-1">注</div>
                    <div className="border border-[#111111] p-1">自</div>
                </div>
                {info.fakeOpdOrderList.map((order) => (
                    <div
                        key={`${order.code}`}
                        className="w-full grid grid-cols-[40%,8%,7%,8%,4%,7%,7%,8%,4%,4%] gap-0.5"
                    >
                        <div>{order.fullName}</div>
                        <div className="text-right">{order.qty}</div>
                        <div>{order.sunit}</div>
                        <div>{order.use}</div>
                        <div className="text-center">{order.day}</div>
                        <div>{order.method}</div>
                        <div className="text-right">{order.tqyt}</div>
                        <div>{order.unit}</div>
                        <div className="text-center">{order.injFlag}</div>
                        <div className="text-center">{order.self}</div>
                    </div>
                ))}
            </div>
        </div>
    )
}

/**
 * 轉診回函 - 單筆/整批列印
 * @returns {JSX.Element}
 */
const PrintReplyLetterOrder = () => {
    const printData = useSelector((state) => state.print.printData)
    return (
        <>
            {printData &&
                printData.map((item) => (
                    <>
                        <PrintReplyLetterOrderCover
                            className="break-after"
                            info={item}
                        />
                        <A4Print>
                            <div
                                key={item.encounterId}
                                className="break-after"
                            >
                                <ReplyLetterOrderContent info={item} />
                            </div>
                        </A4Print>
                    </>
                ))}
        </>
    )
}

export default PrintReplyLetterOrder
