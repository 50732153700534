import {createSlice} from "@reduxjs/toolkit"
import {AlertHorizontalEnum, AlertProps, AlertTypeEnum, AlertVerticalEnum} from "edah-component/dist"

/**
 * Login Page Slice
 */
export const ToastMessageSlice = createSlice({
    name: 'toastMessage',
    initialState: {
        /**
         * 訊息
         */
        message: '',
        /**
         * 種類
         */
        type: AlertTypeEnum.Success,
        /**
         * 持續時間
         */
        duration: 3000,
        /**
         * 顯示方向
         */
        origin: {
            vertical: AlertVerticalEnum.Top,
            horizontal: AlertHorizontalEnum.Center
        },
        /**
         * 是否開啟
         */
        open: false,
        /**
         * 是否顯示關閉按鈕
         */
        showCloseButton: false,
    },
    reducers: {
        /**
         * 顯示ToastMessage
         * @param state {Object}
         * @param action {Object} action
         * @param action.payload {AlertProps} AlertProps
         * @return {void}
         */
        showToastMessage(state, action) {
            const {message, type, duration, origin, open, showCloseButton} = action.payload
            state.message = message
            state.type = type
            state.duration = duration
            state.open = open
            state.showCloseButton = showCloseButton
            if (origin) {
                state.origin = origin
            }
        },
        /**
         * 隱藏ToastMessage
         * @param state {AlertProps}
         * @return {void}
         */
        hiddenToastMessage(state) {
            state.message = ''
            state.type = AlertTypeEnum.Success
            state.duration = 3000
            state.open = false
            state.showCloseButton = false
            state.origin = {
                vertical: AlertVerticalEnum.Top,
                horizontal: AlertHorizontalEnum.Center
            }
        }
    }
})
export const {showToastMessage, hiddenToastMessage} = ToastMessageSlice.actions
export default ToastMessageSlice.reducer
