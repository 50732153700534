import {v1Api} from "../Http"
import {HttpMethodEnum} from "edah_utils/dist"

/**
 * 醫囑畫面初始化取得相關代碼資訊（下拉清單）
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const ocisEnCounterQueryBaseData = (params) => v1Api.request({
    url: '/cpoe/queryBaseData',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 目前醫囑清單
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const ocisEncounterQueryOrder = (params) => v1Api.request({
    url: '/cpoe/queryEncounterOrder',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 醫囑畫面初始化取得相關代碼資訊（下拉清單）
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const ocisEnCounterplaceOrder = (params) => v1Api.request({
    url: '/cpoe/placeOrder',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 次量批次異動計算
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const cpoeBatchModifyQty = (data) => v1Api.request({
    url: '/cpoe/batchModifyQty',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 用法批次異動計算
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const cpoeBatchModifyFreqNo = (data) => v1Api.request({
    url: '/cpoe/batchModifyFreqNo',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 天數批次異動計算
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const cpoeBatchModifyDay = (data) => v1Api.request({
    url: '/cpoe/batchModifyDay',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 新增醫囑
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const cpoeAddOrder = (data) => v1Api.request({
    url: '/cpoe/addOrder',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 異動醫囑
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const cpoeModifyOrder = (data) => v1Api.request({
    url: '/cpoe/modifyOrder',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 病人醫藥囑存檔
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const cpoeSaveEncounterOrder = (data) => v1Api.request({
    url: '/cpoe/saveEncounterOrder',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 取得醫師當天門診，剛進入和日期切換需呼叫
 * @param params {Object}
 * @return {AxiosPromise}
 */
const cpoeQueryDoctorClinic = (params) => v1Api.request({
    url: '/cpoe/queryDoctorClinicList',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 查詢病人候診清單
 * @param params {Object}
 * @return {AxiosPromise}
 */
const cpoeQueryPatientList = (params) => v1Api.request({
    url: '/cpoe/queryOcisPatientListData',
    method: HttpMethodEnum.GET,
    params
})


/**
 * 查詢Ditto的就診歷史清單
 * @param params {Object}
 * @return {AxiosPromise}
 */
const cpoeQueryDittoOrders = (params) => v1Api.request({
    url:'cpoe/queryDittoOrders',
    method: HttpMethodEnum.GET,
    params
})



export {
    ocisEnCounterQueryBaseData,
    ocisEncounterQueryOrder,
    ocisEnCounterplaceOrder,
    cpoeBatchModifyQty,
    cpoeBatchModifyFreqNo,
    cpoeBatchModifyDay,
    cpoeAddOrder,
    cpoeModifyOrder,
    cpoeSaveEncounterOrder,
    cpoeQueryDoctorClinic,
    cpoeQueryPatientList,
    cpoeQueryDittoOrders
}
