import React from 'react'
import '../../TableList/tableList.scss'
import {usePopup} from '../PopupProvider'
import {t} from 'i18next'
import {stringIsEmpty} from "edah_utils/dist"
import {
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    IconEnum
} from "edah-component/dist/index"

/**
 * RSIS匯入資源報備資料列表組件
 * @param item {Object} 清單資料
 * @param index {Number} 清單索引
 * @param isSelectedAll {Boolean|null} 是否包含全選
 * @return {JSX.Element}
 */
const ListItem = ({item, index, isSelectAll = null}) => {
    // 使用上下文
    const {
        setShowEditModal,
        setShowDeletePopup,
        setFocusItem,
        setExcelFocusIndex
    } = usePopup();

    /**
     * 刪除事件，指定刪除index，顯示刪除彈窗
     * @return {void}
     */
    const handleOnItemDelete = () => {
        // 傳回本筆資料
        setFocusItem(item)
        // 顯示刪除彈窗
        setShowDeletePopup(true)
    }

    /**
     * 編輯事件，指定編輯index
     * @return {void}
     */
    const handleOnItemEdit = () => {
        // 傳回本筆資料
        setFocusItem(item)
        // 傳回本筆excel索引
        setExcelFocusIndex(index)
        // 顯示編輯彈窗
        setShowEditModal(true)
    }

    /**
     * 解析報備支援目的
     * @param v {Number} 值
     * @return {String}
     */
    const parserPurposeCodeContent = (v) =>
        //資料不存在的話
        stringIsEmpty(item.purposeCode) ? "N" :
            //資料存在的話
            item.purposeCode.split(',').some(i => i.trim() === `${v}`) ?
                //包含於
                'Y' :
                //不包含
                'N'

    /**
     * 格式化時間
     * @param time {String}
     * @return {String}
     */
    const formatTime = (time) => stringIsEmpty(time) ? '' : `${time.slice(0, 2)}:${time.slice(2)}`

    /**
     *  格式化日期
     *  @param date {String}
     *  @return {String}
     */
    const formatDate = (date) => stringIsEmpty(date) ? '' : date.split(" ")[0]

    /**
     * 定義SlotBtn，根據狀態顯示對應操作按鈕
     * @return {JSX.Element}
     */
    const renderSlotBtn = () => (
        <>
            {/*編輯按鈕*/}
            <Button
                color={ButtonColorEnum.Primary} size={ButtonSizeEnum.Large} icon={IconEnum.Edit}
                text={t('general.edit')} variant={ButtonVariantEnum.Text}
                onClick={handleOnItemEdit}/>
            {/*刪除按鈕*/}
            <Button
                color={ButtonColorEnum.Primary} size={ButtonSizeEnum.Large} icon={IconEnum.Delete}
                text={t('general.delete')} variant={ButtonVariantEnum.Text}
                onClick={handleOnItemDelete}/>
        </>
    )

    return (
        <li key={index}>
            {/* 申請單號、院區名稱 */}
            <div className='flex flex-col justify-start'>
                <p className='w-full'>{item.supportNo ? item.supportNo : ''}</p>
                <p className='w-full'>{t(`campus.fullName.${item.zone ? item.zone : ''}`)}</p>
            </div>
            {/* 申請人 */}
            <div>{item.applicant ? item.applicant : ''}</div>
            {/* 支援醫師代號/工號 姓名 */}
            <div className='flex flex-col justify-start'>
                <p className='w-full'>{item.supportUserNo ? item.supportUserNo : ''}</p>
                <p className='w-full'>{item.supportUserName ? item.supportUserName : ''}</p>
            </div>
            {/* 支援機構代號、名稱 */}
            <div className='flex flex-col justify-start'>
                <p className='w-full'>{item.supportOrgNo ? item.supportOrgNo : ''}</p>
                <p className='w-full'>{item.supportOrgName ? item.supportOrgName : ''}</p>
            </div>
            {/* 支援科別 */}
            <div className='flex flex-col justify-start'>
                <p className='w-full'>{item.supportDivNo ? item.supportDivNo : ''}</p>
                <p className='w-full'>{item.supportDivName ? item.supportDivName : ''}</p>
            </div>
            {/* 開始日期 */}
            <div>
                <p>{formatDate(item.itemStartDate)}</p>
            </div>
            {/* 開始時間 */}
            <div>
                <p>{formatTime(item.itemStartTime)}</p>
            </div>
            {/* 結束日期 */}
            <div>
                <p>{formatDate(item.itemEndDate)}</p>
            </div>
            {/* 結束時間 */}
            <div>
                <p>{formatTime(item.itemEndTime)}</p>
            </div>
            {/* 狀態別 */}
            <div>{t(`RSIS.StepStatusEnum.${item.stepStatus ? item.stepStatus : ''}`)}</div>
            {/* 衛福部案號版次 */}
            <div>{item.sendCaseNo ? item.sendCaseNo : ''}</div>
            {/* 報備支援為一般目的 */}
            <div>{parserPurposeCodeContent(10)}</div>
            {/* 報備支援為負責人 */}
            <div>{parserPurposeCodeContent(20)}</div>
            {/* 報備支援為糖尿病共同維護 */}
            <div>{parserPurposeCodeContent(30)}</div>
            {/* 報備支援為整合式健康檢查 */}
            <div>{parserPurposeCodeContent(40)}</div>
            {/* 報備支援為預防針接種 */}
            <div>{parserPurposeCodeContent(50)}</div>
            {/* 備註 */}
            <div>{item.memo ? item.memo : ''}</div>
        </li>
    );
}

export default ListItem;
