import React, {useEffect, useState} from "react"
import {Dialog, DialogSizeEnums, DialogVariant} from "edah-component/dist";

const DeleteRuleMessagePopup = ({ 
    show = false,
    closePopupButtonOnClick, 
    handleDeleteOnClick
}) => {
    /**
     * 是否顯示彈窗
     */
    const [showPopup, setShowPopup] = useState(show);

    /**
     * 監聽是否顯示彈窗Pros變化
     */
    useEffect(() => {
        // 設定是否顯示彈窗狀態
        setShowPopup(show)
    }, [show])

    return (
        showPopup ?
        <Dialog
            paperStyleProps={{width:DialogSizeEnums.SM}}
            open={show}
            title={'提醒'}
            variant={DialogVariant.DECISION}
            content={<p>是否確認刪除?</p>}
            onClose={closePopupButtonOnClick}
            onConfirm={handleDeleteOnClick}
        /> : <></>
    )
}

export default DeleteRuleMessagePopup;