import {getLocalStorage} from "edah_utils/dist";
import {Navigate} from "react-router-dom";

/**
 * 檢查路由授權
 */
export const RoutesAuth = ({children}) => {
    //取得Token
    const token = getLocalStorage('token')

    //token不存在
    if (!token) {
        return <Navigate to='/login' replace/>
    }

    //token存在且為Login URL
    if (/\/login($|[^A-Za-z0-9])/.test(children.path)) {
        //轉跳至首頁
        return <Navigate to='/' replace/>
    }

    //token存在且不為Login URL
    return children.element
}
