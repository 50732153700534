import { booleanForDisplay } from '../../components/ReferralSystem/utils'
import { DATE_FORMAT } from '../common'
import { time2String } from 'edah_utils/dist'
import i18n from '../../i18n/index'

/**
 * 電子轉診單下拉選單
 */
export const TRAN_NO_FLAG = [
    {
        name: i18n.t('Rmis.pages.tranInCaseList.etranOptions.all'),
        value: 'ALL',
    },
    {
        name: i18n.t('Rmis.pages.tranInCaseList.etranOptions.hasReferralForm'),
        value: 'Y',
    },
    {
        name: i18n.t('Rmis.pages.tranInCaseList.etranOptions.noReferralForm'),
        value: 'N',
    },
]

export const REPLY_CASE_FIELDS = [
    {
        key: 'editor',
        name: i18n.t('Rmis.pages.tranInCaseList.tableHeader.editor'), // 編輯
        className: 'text-sm whitespace-nowrap min-w-[80px]',
    },
    {
        key: 'encounterDate',
        name: i18n.t('Rmis.pages.tranInCaseList.tableHeader.encounterDate'), // 就醫日期
        className: 'text-sm whitespace-nowrap min-w-[120px]',
        format: (value) => time2String(value, DATE_FORMAT),
    },
    {
        key: 'patientId',
        name: i18n.t('Rmis.pages.tranInCaseList.tableHeader.patientId'), // 病歷號
        className: 'text-sm whitespace-nowrap min-w-[120px]',
    },
    {
        key: 'patientName',
        name: i18n.t('Rmis.pages.tranInCaseList.tableHeader.patientName'), // 病人姓名
        className: 'text-sm whitespace-nowrap min-w-[120px]',
    },
    {
        key: 'tel',
        name: i18n.t('Rmis.pages.tranInCaseList.tableHeader.tel'), // 電話
        className: 'text-sm whitespace-nowrap min-w-[120px]',
    },
    {
        key: 'phone',
        name: i18n.t('Rmis.pages.tranInCaseList.tableHeader.phone'), // 手機
        className: 'text-sm whitespace-nowrap min-w-[120px]',
    },
    {
        key: 'idNo',
        name: i18n.t('Rmis.pages.tranInCaseList.tableHeader.idNo'), // 身分證號
        className: 'text-sm whitespace-nowrap min-w-[120px]',
    },
    {
        key: 'divName',
        name: i18n.t('Rmis.pages.tranInCaseList.tableHeader.divName'), // 就醫科別
        className: 'text-sm whitespace-nowrap min-w-[120px]',
    },
    {
        key: 'inpDivName',
        name: i18n.t('Rmis.pages.tranInCaseList.tableHeader.inpDivName'), // 入院科別
        className: 'text-sm whitespace-nowrap min-w-[120px]',
    },
    {
        key: 'inpOpdName',
        name: i18n.t('Rmis.pages.tranInCaseList.tableHeader.inpOpdName'), // 轉診來源
        className: 'text-sm whitespace-nowrap',
    },
    {
        key: 'originInpOpd',
        name: i18n.t('Rmis.pages.tranInCaseList.tableHeader.originInpOpd'), // 原轉診來源
        className: 'text-sm whitespace-nowrap',
    },
    {
        key: 'admissionDate',
        name: i18n.t('Rmis.pages.tranInCaseList.tableHeader.admissionDate'), // 入院日期
        className: 'text-sm whitespace-nowrap min-w-[120px]',
        format: (value) => time2String(value, DATE_FORMAT),
    },
    {
        key: 'bedNo',
        name: i18n.t('Rmis.pages.tranInCaseList.tableHeader.bedNo'), // 床號
        className: 'text-sm whitespace-nowrap min-w-[80px]',
    },
    {
        key: 'doctorName',
        name: i18n.t('Rmis.pages.tranInCaseList.tableHeader.doctorName'), // 醫師
        className: 'text-sm whitespace-nowrap min-w-[100px]',
    },
    {
        key: 'ptTypeName',
        name: i18n.t('Rmis.pages.tranInCaseList.tableHeader.ptTypeName'), // 身分
        className: 'text-sm whitespace-nowrap min-w-[100px]',
    },
    {
        key: 'acceptFlagName',
        name: i18n.t('Rmis.pages.tranInCaseList.tableHeader.acceptFlagName'), // 個管顯示
        className: 'text-sm whitespace-nowrap min-w-[100px]',
    },
    {
        key: 'treatmentCode',
        name: i18n.t('Rmis.pages.tranInCaseList.tableHeader.treatmentCode'), // 主診斷
        className: 'text-sm whitespace-nowrap min-w-[80px]',
    },
    {
        key: 'treatmentName',
        name: i18n.t('Rmis.pages.tranInCaseList.tableHeader.treatmentName'), // 主診斷名稱
        className: 'text-sm whitespace-nowrap min-w-[120px]',
    },
    {
        key: 'treatmentCode1',
        name: i18n.t('Rmis.pages.tranInCaseList.tableHeader.treatmentCode1'), // 次診斷
        className: 'text-sm whitespace-nowrap min-w-[80px]',
    },
    {
        key: 'treatmentName1',
        name: i18n.t('Rmis.pages.tranInCaseList.tableHeader.treatmentName1'), // 次診斷名稱
        className: 'text-sm whitespace-nowrap min-w-[120px]',
    },
    {
        key: 'treatmentCode2',
        name: i18n.t('Rmis.pages.tranInCaseList.tableHeader.treatmentCode2'), // 第三診斷
        className: 'text-sm whitespace-nowrap min-w-[80px]',
    },
    {
        key: 'treatmentName2',
        name: i18n.t('Rmis.pages.tranInCaseList.tableHeader.treatmentName2'), // 第三診斷名稱
        className: 'text-sm whitespace-nowrap min-w-[120px]',
    },
    {
        key: 'hospNameIn',
        name: i18n.t('Rmis.pages.tranInCaseList.tableHeader.hospNameIn'), // 轉入院所
        className: 'text-sm whitespace-nowrap min-w-[120px]',
    },
    {
        key: 'federalFlag',
        name: i18n.t('Rmis.pages.tranInCaseList.tableHeader.federalFlag'), // 策略聯盟
        className: 'text-sm whitespace-nowrap',
        format: (value) => booleanForDisplay(value),
    },
    {
        key: 'hospGradeName',
        name: i18n.t('Rmis.pages.tranInCaseList.tableHeader.hospGradeName'), // 醫院層級
        className: 'text-sm whitespace-nowrap',
    },
    {
        key: 'dischargeDate',
        name: i18n.t('Rmis.pages.tranInCaseList.tableHeader.dischargeDate'), // 出院日期
        className: 'text-sm whitespace-nowrap min-w-[120px]',
        format: (value) => time2String(value, DATE_FORMAT),
    },
    {
        key: 'tranInReceiveDate',
            name: i18n.t('Rmis.pages.tranInCaseList.tableHeader.tranInReceiveDate'), // 轉診單回收日
        className: 'text-sm whitespace-nowrap',
        format: (value) => time2String(value, DATE_FORMAT),
    },
    {
        key: 'encounterDateToTranInReceiveDateDay',
        name: i18n.t('Rmis.pages.tranInCaseList.tableHeader.encounterDateToTranInReceiveDateDay'), // 看診日轉診單回收天數
        className: 'text-sm whitespace-nowrap',
    },
    {
        key: 'tranInPrintDate',
        name: i18n.t('Rmis.pages.tranInCaseList.tableHeader.tranInPrintDate'), // 轉診回函列印日期
        className: 'text-sm whitespace-nowrap min-w-[120px]',
        format: (value) => time2String(value, DATE_FORMAT),
    },
    {
        key: 'inReplyDate',
        name: i18n.t('Rmis.pages.tranInCaseList.tableHeader.inReplyDate'), // 收到外院回復日期
        className: 'text-sm whitespace-nowrap min-w-[120px]',
        format: (value) => time2String(value, DATE_FORMAT),
    },
    {
        key: 'outNotePrintDate',
        name: i18n.t('Rmis.pages.tranInCaseList.tableHeader.outNotePrintDate'), // 病摘回覆列印
        className: 'text-sm whitespace-nowrap',
    },
    {
        key: 'outNotePrintDateToDischargeDateDay',
        name: i18n.t('Rmis.pages.tranInCaseList.tableHeader.outNotePrintDateToDischargeDateDay'), // 出院日期至病摘回覆列印天數
        className: 'text-sm whitespace-nowrap',
    },
    {
        key: 'inUnprintSummaryReasonName',
        name: i18n.t('Rmis.pages.tranInCaseList.tableHeader.inUnprintSummaryReasonName'), // 無法列印病摘原因
        className: 'text-sm whitespace-nowrap min-w-[120px]',
    },
    {
        key: 'tranPurposeInName',
        name: i18n.t('Rmis.pages.tranInCaseList.tableHeader.tranPurposeInName'), // 轉入原因
        className: 'text-sm whitespace-nowrap min-w-[120px]',
    },
    {
        key: 'systemTranOutReason',
        name: i18n.t('Rmis.pages.tranInCaseList.tableHeader.systemTranOutReason'), // 體系內轉出原因
        className: 'text-sm whitespace-nowrap min-w-[120px]',
    },
    {
        key: 'tranType',
        name: i18n.t('Rmis.pages.tranInCaseList.tableHeader.tranType'), // 轉入類別
        className: 'text-sm whitespace-nowrap',
    },
    {
        key: 'inCancerFlag',
        name: i18n.t('Rmis.pages.tranInCaseList.tableHeader.inCancerFlag'), // 癌症篩檢
        className: 'text-sm whitespace-nowrap',
        format: (value) => booleanForDisplay(value),
    },
    {
        key: 'inSpecialReplyDate',
        name: i18n.t('Rmis.pages.tranInCaseList.tableHeader.inSpecialReplyDate'), // 特殊報告回覆日期
        className: 'text-sm whitespace-nowrap min-w-[120px]',
        format: (value) => time2String(value, DATE_FORMAT),
    },
    {
        key: 'inRecoveryDate',
        name: i18n.t('Rmis.pages.tranInCaseList.tableHeader.inRecoveryDate'), // 回覆單回收日期
        className: 'text-sm whitespace-nowrap min-w-[120px]',
        format: (value) => time2String(value, DATE_FORMAT),
    },
    {
        key: 'inTraninCompleteFlag',
        name: i18n.t('Rmis.pages.tranInCaseList.tableHeader.inTraninCompleteFlag'), // 回覆單填寫
        className: 'text-sm whitespace-nowrap',
        format: (value) => booleanForDisplay(value),
    },
    {
        key: 'inRemark',
        name: i18n.t('Rmis.pages.tranInCaseList.tableHeader.inRemark'), // 備註
        className: 'text-sm whitespace-nowrap  min-w-[120px]',
    },
    {
        key: 'payFeeNo', // TODO: api 待確認
        name: i18n.t('Rmis.pages.tranInCaseList.tableHeader.payFeeNo'), // 批價序號
        className: 'text-sm whitespace-nowrap',
    },
]

/**
 *查詢工具 - 預約住院
 */
export const ADMISSION_RESERVATION_FIELDS = [
    {
        key: 'patientId',
        name: i18n.t('Rmis.pages.queryToolModal.reservationInpTableHeader.patientId'), // 病歷號
    },
    {
        key: 'inpEncounterId',
        name: i18n.t('Rmis.pages.queryToolModal.reservationInpTableHeader.inpEncounterId'), // 住院就醫號
    },
    {
        key: 'opdEncounterId',
        name: i18n.t('Rmis.pages.queryToolModal.reservationInpTableHeader.opdEncounterId'), // 門診就醫號
    },
    {
        key: 'status',
        name: i18n.t('Rmis.pages.queryToolModal.reservationInpTableHeader.status'), // 狀態
    },
    {
        key: 'remark',
        name: i18n.t('Rmis.pages.queryToolModal.reservationInpTableHeader.remark'), // 備註
    },
    {
        key: 'tranInHospName',
        name: i18n.t('Rmis.pages.queryToolModal.reservationInpTableHeader.tranInHospName'), // 轉入院所
    },
    {
        key: 'admissionDate',
        name: i18n.t('Rmis.pages.queryToolModal.reservationInpTableHeader.admissionDate'), // 住院日
        format: (value) => time2String(value, DATE_FORMAT),
    },
    {
        key: 'divName',
        name: i18n.t('Rmis.pages.queryToolModal.reservationInpTableHeader.divName'), // 科別
    },
    {
        key: 'doctorName',
        name: i18n.t('Rmis.pages.queryToolModal.reservationInpTableHeader.doctorName'), // 主治醫師
    },
    {
        key: 'reserveEncounterId',
        name: i18n.t('Rmis.pages.queryToolModal.reservationInpTableHeader.reserveEncounterId'), // 預住院單號
    },
    {
        key: 'orderHospName',
        name: i18n.t('Rmis.pages.queryToolModal.reservationInpTableHeader.orderHospName'), // 開立院所
    },
    {
        key: 'toHospName',
        name: i18n.t('Rmis.pages.queryToolModal.reservationInpTableHeader.toHospName'), // 目標院所
    },
]

/**
 *查詢工具 - 門診看診主檔
 */
export const OPD_FIELDS = [
    {
        key: 'clinNo',
        name: i18n.t('Rmis.pages.queryToolModal.opdTableHeader.clinNo'), // 診別
    },
    {
        key: 'encounterDate',
        name: i18n.t('Rmis.pages.queryToolModal.opdTableHeader.encounterDate'), // 就醫日期
        format: (value) => time2String(value, DATE_FORMAT),
    },
    {
        key: 'divName',
        name: '就醫科別',
    },
    {
        key: 'doctorName',
        name: i18n.t('Rmis.pages.queryToolModal.opdTableHeader.doctorName'), // 醫師
    },
    {
        key: 'patientId',
        name: i18n.t('Rmis.pages.queryToolModal.opdTableHeader.patientId'), // 病歷號
    },
    {
        key: 'patientName',
        name: i18n.t('Rmis.pages.queryToolModal.opdTableHeader.patientName'), // 病人姓名
    },
    {
        key: 'tranInHospName',
        name: i18n.t('Rmis.pages.queryToolModal.opdTableHeader.tranInHospName'), // 轉入院所
    },
    {
        key: 'tranOutHospName',
        name: i18n.t('Rmis.pages.queryToolModal.opdTableHeader.tranOutHospName'), // 轉出院所
    },
    {
        key: 'opdEncounterId',
        name: i18n.t('Rmis.pages.queryToolModal.opdTableHeader.opdEncounterId'), // 批價序號
    },
    {
        key: 'opdStatus',
        name: i18n.t('Rmis.pages.queryToolModal.opdTableHeader.opdStatus'), // 批價狀態
    },
    {
        key: 'partName',
        name: i18n.t('Rmis.pages.queryToolModal.opdTableHeader.partName'), // 部分負擔
    },
    {
        key: 'partNo',
        name: i18n.t('Rmis.pages.queryToolModal.opdTableHeader.partNo'), // 部分負擔代碼
    },
    
    {
        key: 'self',
        name: i18n.t('Rmis.pages.queryToolModal.opdTableHeader.self'), // 身分
    },
    
    {
        key: 'modifytStatus',
        name: i18n.t('Rmis.pages.queryToolModal.opdTableHeader.modifytStatus'), // 註銷
        format: (value) => (value === '0' ? 'N' : 'Y'),
    },
]

/**
 *查詢工具 - 住院主檔查詢
 */
export const ADMISSION_FIELDS = [
    {
        key: 'inpEncounterId',
        name: i18n.t('Rmis.pages.queryToolModal.admissionTableHeader.inpEncounterId'), // 就醫號
    },
    {
        key: 'patientId',
        name: i18n.t('Rmis.pages.queryToolModal.admissionTableHeader.patientId'), // 病歷號
    },
    {
        key: 'patientName',
        name: i18n.t('Rmis.pages.queryToolModal.admissionTableHeader.patientName'), // 姓名
    },
    {
        key: 'admissionDate',
        name: i18n.t('Rmis.pages.queryToolModal.admissionTableHeader.admissionDate'), // 入院日期
        format: (value) => time2String(value, DATE_FORMAT),
    },
    {
        key: 'tranInHospName',
        name: i18n.t('Rmis.pages.queryToolModal.admissionTableHeader.tranInHospName'), // 轉入院所
    },
    {
        key: 'tranOutHospName',
        name: i18n.t('Rmis.pages.queryToolModal.admissionTableHeader.tranOutHospName'), // 轉出院所
    },
    {
        key: 'self',
        name: i18n.t('Rmis.pages.queryToolModal.admissionTableHeader.self'), // 身分
    },
    {
        key: 'status',
        name: i18n.t('Rmis.pages.queryToolModal.admissionTableHeader.status'), // 狀態
    },
]

/**
 * 查詢工具-轉診單查詢
 */
export const TRAN_OUT_FIELDS = [
    {
        key: 'clinNo',
        name: i18n.t('Rmis.pages.queryToolModal.tranOutTableHeader.clinNo'), // 診別
    },
    {
        key: 'encounterDate',
        name: i18n.t('Rmis.pages.queryToolModal.tranOutTableHeader.encounterDate'), // 就醫日期
        format: (value) => time2String(value, DATE_FORMAT),
    },
    {
        key: 'opdEncounterId',
        name: i18n.t('Rmis.pages.queryToolModal.tranOutTableHeader.opdEncounterId'), // 批價序號
    },
    {
        key: 'patientId',
        name: i18n.t('Rmis.pages.queryToolModal.tranOutTableHeader.patientId'), // 病歷號
    },
    {
        key: 'tranOutHospName',
        name: i18n.t('Rmis.pages.queryToolModal.tranOutTableHeader.tranOutHospName'), // 轉至院所
    },
    {
        key: 'modifytStatus',
        name: i18n.t('Rmis.pages.queryToolModal.tranOutTableHeader.modifytStatus'), // 註銷
        format: (value) => (value === '0' ? 'N' : 'Y'),
    },
    {
        key: 'modifytUserName',
        name: i18n.t('Rmis.pages.queryToolModal.tranOutTableHeader.modifytUserName'), // 更新者
    },
]

/**
 * 查詢工具-回覆單查詢
 */
export const REPLY_LETTER_FIELDS = [
    {
        key: 'clinNo',
        name: i18n.t('Rmis.pages.queryToolModal.replyLetterTableHeader.clinNo'), // 診別
    },
    {
        key: 'encounterDate',
        name: i18n.t('Rmis.pages.queryToolModal.replyLetterTableHeader.encounterDate'), // 就醫日期
        format: (value) => time2String(value, DATE_FORMAT),
    },
    {
        key: 'opdEncounterId',
        name: i18n.t('Rmis.pages.queryToolModal.replyLetterTableHeader.opdEncounterId'), // 批價序號
    },
    {
        key: 'patientId',
        name: i18n.t('Rmis.pages.queryToolModal.replyLetterTableHeader.patientId'), // 病歷號
    },
    {
        key: 'tranInHospName',
        name: i18n.t('Rmis.pages.queryToolModal.replyLetterTableHeader.tranInHospName'), // 轉入院所
    },
    {
        key: 'modifytStatus',
        name: i18n.t('Rmis.pages.queryToolModal.replyLetterTableHeader.modifytStatus'), // 註銷
        format: (value) => (value === '0' ? 'N' : 'Y'),
    },
    {
        key: 'modifytUserName',
        name: i18n.t('Rmis.pages.queryToolModal.replyLetterTableHeader.modifytUserName'), // 更新者
    },
]

/**
 * 列印轉回原醫療院所名單
 */
export const TRAN_BACK_LIST_FIELDS = [
    {
        key: 'encounterId',
        name: i18n.t('Rmis.pages.tranInCaseList.tranBackListModal.tableHeader.encounterId'), // 編號
        className: 'w-[120px]',
    },
    {
        key: 'encounterDate',
        name: i18n.t('Rmis.pages.tranInCaseList.tranBackListModal.tableHeader.encounterDate'), // 就醫日期
        className: 'w-[120px]',
        format: (value) => time2String(value, DATE_FORMAT),
    },
    {
        key: 'patientId',
        name: i18n.t('Rmis.pages.tranInCaseList.tranBackListModal.tableHeader.patientId'), // 病歷號
        className: 'w-[120px]',
    },
    {
        key: 'idNo',
        name: i18n.t('Rmis.pages.tranInCaseList.tranBackListModal.tableHeader.idNo'), // 身分證號
        className: 'w-[150px]',
    },
    {
        key: 'patientName',
        name: i18n.t('Rmis.pages.tranInCaseList.tranBackListModal.tableHeader.patientName'), // 姓名
        className: 'w-[150px]',
    },
    {
        key: 'inReplyStatus',
        name: i18n.t('Rmis.pages.tranInCaseList.tranBackListModal.tableHeader.inReplyStatus'), // 追蹤狀態
    },
]
