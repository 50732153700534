import React from 'react'
import RSISCaseManagement from '../../components/RSIS/CaseManagement/RSISCaseManagement'
import {PopupProvider} from '../../components/RSIS/PopupProvider'

/**
 * 個案管理
 * @returns {JSX.Element}
 */
const CaseManagement = () =>
    <PopupProvider>
        <RSISCaseManagement/>
    </PopupProvider>

export default CaseManagement
