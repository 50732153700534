//Import css
import '../../TableList/tableList.scss';
//Import Function
import React, {useState} from 'react';
import {
    ApiErrorStatusEnum,
    enum2Array,
    RsisStepStatusEnum,
    stringIsEmpty,
    time2String,
} from 'edah_utils/dist';
import {usePopup} from '../PopupProvider'
import {USER_ROLE} from "../userRole";
import {t} from "i18next"
import {rsisQuickEdit} from '../../../api/v1/RSIS'
import {
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    FontSizeEnum,
    IconEnum,
    AlertTypeEnum,
    TextField
} from "edah-component/dist";

/**
 * RSIS支援與查詢清單組件
 * @param item {Object} 清單資料
 * @param index {Number} 清單索引
 * @return {JSX.Element}
 */
const ListItem = ({
                      item,
                      index,
                  }) => {
    // 使用上下文
    const {
        setShowCopyModal,
        setShowEditModal,
        setShowChangeModal,
        setShowLogOffModal,
        setShowContentView,
        setShowDeletePopup,
        setFocusIndex,
        showToast
    } = usePopup();
    // 是否在快速編輯狀態
    const [isFastEdit, setIsFastEdit] = useState(false);
    // 狀態別
    const [stepStatus, setStepStatus] = useState(item?.stepStatus || '');
    // 衛福部案號
    const [sendCaseNo, setSendCaseNo] = useState(item?.sendCaseNo || '');
    // 匯出編號
    const [sendFileName, setSendFileName] = useState(item?.sendFileName || '');
    // 原始衛福部案號
    const [originalSendCaseNo, setOriginalSendCaseNo] = useState(item?.sendCaseNo || '');
    // 原始匯出編號
    const [originalSendFileName, setOriginalSendFileName] = useState(item?.sendFileName || '');
    // 原始狀態別
    const [originalStepStatus, setOriginalStepStatus] = useState(item?.stepStatus || '');

    /**
     * 快速編輯存擋
     * return {void}
     */
    const handleSave = () => {
        // 檢查是否有無效的項目數據
        if (!item) {
            showToast({message: '無效的數據', type: AlertTypeEnum.Error});
            return;
        }

        const data = {
            // 申請單號
            supportNo: item.supportNo,
            // 版本號
            itemVersion: item.itemVersion,
            // 支援醫師代號
            supportUserNo: item.supportUserNo,
            // 支援機構代號
            supportOrgNo: item.supportOrgNo,
            // 支援科別代號
            supportDivNo: item.supportDivNo,
            // 支援目的代號
            purposeCode: item.purposeCode,
            // 支援目的說明
            purposeCodeExt: item.purposeCodeExt,
            // 支援期間起始日期
            itemStartDate: item.itemStartDate,
            // 支援期間結束日期
            itemEndDate: item.itemEndDate,
            // 支援期間起始時間
            itemStartTime: item.itemStartTime,
            // 支援期間結束時間
            itemEndTime: item.itemEndTime,
            // 支援性質
            supportProperties: item.supportProperties,
            // 執行內容
            executeContent: item.executeContent,
            // 備註
            memo: item.memo,
            // 代理人員代號
            agentUserNo: item.agentUserNo,
            // 狀態(1申請/2確認/3送審/9失效)
            stepStatus: item.stepStatus,
            // 變更/註銷狀態(null空:無/M: 變更/D:刪除)
            supportModifyStatus: item.supportModifyStatus,
            // 變更/註銷日期
            supportModifyDatetime: item.supportModifyDatetime,
            // 變更/註銷原因代號
            supportModifyReasonCode: item.supportModifyReasonCode,
            // 變更/註銷原因說明
            supportModifyReasonDoc: item.supportModifyReasonDoc,
            // 匯出編號
            sendFileName: sendFileName,
            // 衛福部案號
            sendCaseNo: sendCaseNo, // 更新後的值
            // 送審日期
            sendDatetime: item.sendDatetime,
            // 申請方式(N:一般 / E:急件申請)
            applyType: item.applyType,
            // 院區
            zone: item.zone,
            // 有效否
            effFlag: item.effFlag,
            // 承辦部門代號
            ownerDeptNo: item.ownerDeptNo,
            // 欄位變更說明, 以中文標記欄位名稱,欄位間用","區隔
            changeFieldsDoc: item.changeFieldsDoc,
            // 收案確認人員代號
            confirmUserNo: item.confirmUserNo,
            // 收案確認日期時間
            confirmDatetime: item.confirmDatetime,
            exportNo: '', // 更新後的值
        };

        // 快速編輯存擋api
        rsisQuickEdit([data]).then(response => {
            const {err, msg} = response;
            if (err === ApiErrorStatusEnum.Success) {
                // 更新 item 的值
                item.stepStatus = stepStatus;
                item.sendCaseNo = sendCaseNo;
                item.sendFileName = sendFileName;
                setIsFastEdit(false);
            } else {
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error});
            }
        });
    };

    /**
     * 取消快速編輯
     * return {void}
     */
    const handleCancel = () => {
        // 恢復原始狀態別
        setStepStatus(originalStepStatus);
        // 恢復原始衛福部案號
        setSendCaseNo(originalSendCaseNo);
        // 恢復原始匯出編號
        setSendFileName(originalSendFileName);
        // 退出快速編輯
        setIsFastEdit(false)
    };

    /**
     * 定義SlotBtn，根據狀態顯示對應操作按鈕
     * @return {JSX.Element}
     */
    const renderSlotBtn = () => {
        const style = 'flex flex-row gap-2'
        // 是否在快速編輯狀態
        if (isFastEdit) {
            return <span className={style}>
                {/*儲存按鈕*/}
                <Button
                    sx={{fontSize: FontSizeEnum.TextMedium}}
                    color={ButtonColorEnum.Primary} size={ButtonSizeEnum.Medium} icon={IconEnum.Save}
                    text={t('general.save')} variant={ButtonVariantEnum.Text}
                    onClick={handleSave}/>
                {/*取消按鈕*/}
                <Button
                    sx={{fontSize: FontSizeEnum.TextMedium}}
                    color={ButtonColorEnum.Primary} size={ButtonSizeEnum.Medium} icon={IconEnum.Cancel}
                    text={t('general.cancel')} variant={ButtonVariantEnum.Text}
                    onClick={handleCancel}/>
            </span>
        }

        // 根據狀態顯示對應操作按鈕
        switch (item.stepStatus) {
            // 申請狀態
            case RsisStepStatusEnum.Apply.toString():
                if (item.role === USER_ROLE.DEPT_USER || item.role === USER_ROLE.CREATE_USER || item.role === USER_ROLE.SUPPORT_USER) {
                    return <span className={style}>
                        {/*複製按鈕*/}
                        <Button
                            sx={{fontSize: FontSizeEnum.TextMedium}}
                            color={ButtonColorEnum.Primary} size={ButtonSizeEnum.Medium} icon={IconEnum.Copy}
                            text={t('general.copy')} variant={ButtonVariantEnum.Text}
                            onClick={() => setShowCopyModal(true)}/>
                        {/*編輯按鈕*/}
                        <Button
                            sx={{fontSize: FontSizeEnum.TextMedium}}
                            color={ButtonColorEnum.Primary} size={ButtonSizeEnum.Medium} icon={IconEnum.Edit}
                            text={t('general.edit')} variant={ButtonVariantEnum.Text}
                            onClick={() => setShowEditModal(true)}/>
                        {/*刪除按鈕*/}
                        <Button
                            sx={{fontSize: FontSizeEnum.TextMedium}}
                            color={ButtonColorEnum.Primary} size={ButtonSizeEnum.Medium} icon={IconEnum.Delete}
                            text={t('general.delete')} variant={ButtonVariantEnum.Text}
                            onClick={() => setShowDeletePopup(true)}/>
                        {/*檢視按鈕*/}
                        <Button
                            sx={{fontSize: FontSizeEnum.TextMedium}}
                            color={ButtonColorEnum.Primary} size={ButtonSizeEnum.Medium} icon={IconEnum.View}
                            text={t('general.view')} variant={ButtonVariantEnum.Text}
                            onClick={() => setShowContentView(item)}/>
                    </span>
                } else {
                    return <span className={style}>
                        {/*檢視按鈕*/}
                        <Button
                            sx={{fontSize: FontSizeEnum.TextMedium}}
                            color={ButtonColorEnum.Primary} size={ButtonSizeEnum.Medium} icon={IconEnum.View}
                            text={t('general.view')} variant={ButtonVariantEnum.Text}
                            onClick={() => setShowContentView(item)}/>
                    </span>
                }
            // 確認狀態
            case RsisStepStatusEnum.Confirm.toString():
                if (item.role === USER_ROLE.DEPT_USER) {
                    return <span className={style}>
                        {/*複製按鈕*/}
                        <Button
                            sx={{fontSize: FontSizeEnum.TextMedium}}
                            color={ButtonColorEnum.Primary} size={ButtonSizeEnum.Medium} icon={IconEnum.Copy}
                            text={t('general.copy')} variant={ButtonVariantEnum.Text}
                            onClick={() => setShowCopyModal(true)}/>
                        {/*編輯按鈕*/}
                        <Button
                            sx={{fontSize: FontSizeEnum.TextMedium}}
                            color={ButtonColorEnum.Primary} size={ButtonSizeEnum.Medium} icon={IconEnum.Edit}
                            text={t('general.edit')} variant={ButtonVariantEnum.Text}
                            onClick={() => setShowEditModal(true)}/>
                        {/*刪除按鈕*/}
                        <Button
                            sx={{fontSize: FontSizeEnum.TextMedium}}
                            color={ButtonColorEnum.Primary} size={ButtonSizeEnum.Medium} icon={IconEnum.Delete}
                            text={t('general.delete')} variant={ButtonVariantEnum.Text}
                            onClick={() => setShowDeletePopup(true)}/>
                        {/*檢視按鈕*/}
                        <Button
                            sx={{fontSize: FontSizeEnum.TextMedium}}
                            color={ButtonColorEnum.Primary} size={ButtonSizeEnum.Medium} icon={IconEnum.View}
                            text={t('general.view')} variant={ButtonVariantEnum.Text}
                            onClick={() => setShowContentView(item)}/>
                    </span>
                } else {
                    return <span className={style}>
                        {/*檢視按鈕*/}
                        <Button
                            sx={{fontSize: FontSizeEnum.TextMedium}}
                            color={ButtonColorEnum.Primary} size={ButtonSizeEnum.Medium} icon={IconEnum.View}
                            text={t('general.view')} variant={ButtonVariantEnum.Text}
                            onClick={() => setShowContentView(item)}/>
                    </span>
                }
            // 審核狀態
            case RsisStepStatusEnum.Review.toString():
                if (item.role === USER_ROLE.DEPT_USER || item.role === USER_ROLE.CREATE_USER || item.role === USER_ROLE.SUPPORT_USER) {
                    return <span className={style}>
                        {/*複製按鈕*/}
                        <Button
                            sx={{fontSize: FontSizeEnum.TextMedium}}
                            color={ButtonColorEnum.Primary} size={ButtonSizeEnum.Medium} icon={IconEnum.Copy}
                            text={t('general.copy')} variant={ButtonVariantEnum.Text}
                            onClick={() => setShowCopyModal(true)}/>
                        {/*變更按鈕*/}
                        <Button
                            sx={{fontSize: FontSizeEnum.TextMedium}}
                            color={ButtonColorEnum.Primary} size={ButtonSizeEnum.Medium}
                            icon={IconEnum.Change}
                            text={t('general.change')} variant={ButtonVariantEnum.Text}
                            onClick={() => setShowChangeModal(true)}/>
                        {/*註銷按鈕*/}
                        <Button
                            sx={{fontSize: FontSizeEnum.TextMedium}}
                            color={ButtonColorEnum.Primary} size={ButtonSizeEnum.Medium}
                            icon={IconEnum.LogOff}
                            text={t('general.logoff')} variant={ButtonVariantEnum.Text}
                            onClick={() => setShowLogOffModal(true)}/>
                        {/*檢視按鈕*/}
                        <Button
                            sx={{fontSize: FontSizeEnum.TextMedium}}
                            color={ButtonColorEnum.Primary} size={ButtonSizeEnum.Medium} icon={IconEnum.View}
                            text={t('general.view')} variant={ButtonVariantEnum.Text}
                            onClick={() => setShowContentView(item)}/>
                    </span>
                } else {
                    return <span className={style}>
                        {/*檢視按鈕*/}
                        <Button
                            sx={{fontSize: FontSizeEnum.TextMedium}}
                            color={ButtonColorEnum.Primary} size={ButtonSizeEnum.Medium} icon={IconEnum.View}
                            text={t('general.view')} variant={ButtonVariantEnum.Text}
                            onClick={() => setShowContentView(item)}/>
                    </span>
                }
            default:
                return null;
        }
    }

    return (
        <li key={index} onClick={() => setFocusIndex(index)}>
            {/* 根據使用者權限顯示對應按鈕 */}
            <div className='flex justify-evenly'>
                <span className='flex items-center'>
                    {renderSlotBtn()}
                </span>
            </div>
            {/* 申請單號、院區名稱 */}
            <div className='felx flex-col justify-start items-start'>
                <p className='w-full'>{item.supportNo}</p>
                <p className={'w-full'}>{t(`campus.shortName.${item.zone}`)}</p>
            </div>
            {/* 申請人 */}
            <div className='w-full'>
                <p className='w-full'> {item.createUser}</p>
                <p className='w-full'>{item.createUserName}</p>
            </div>
            {/*支援醫師姓名*/}
            <div className='flex flex-col justify-start items-start'>
                <p className='w-full'> {item.supportUserNo}</p>
                <p className='w-full'>{item.supportUserName}</p>
            </div>
            {/* 支援機構代號、名稱 */}
            <div className='flex flex-col justify-start items-start'>
                <p className='w-full'>{item.supportOrgNo}</p>
                <p className='w-full'>{item.supportOrgName}</p>
            </div>
            {/* 支援科別 */}
            <div>
                <p>{`${item.supportDivNo}${item.supportDivName}`}</p>
            </div>
            {/* 報備期間、開始-結束日期 */}
            <div className='flex flex-col justify-start items-start'>
                <p>{`${time2String(item.itemStartDate, 'YYYY-MM-DD')} ${item.itemStartTime.slice(0, 2) + ':' + item.itemStartTime.slice(2)}`}</p>
                <p>{`${time2String(item.itemEndDate, 'YYYY-MM-DD')} ${item.itemEndTime.slice(0, 2) + ':' + item.itemEndTime.slice(2)}`}</p>
            </div>
            {/* 狀態別 */}
            <div>
                {stringIsEmpty(item.stepStatus) ? 'null' :
                    (isFastEdit ? (
                        <select
                            className="w-[102px] h-10 px-2 bg-white text-black rounded-[6px] border border-gray-300"
                            value={stepStatus}
                            onChange={(e) => setStepStatus(e.target.value)}
                        >
                            {
                                enum2Array(RsisStepStatusEnum)
                                    .filter(enumItem => enumItem.value !== RsisStepStatusEnum.All)
                                    .map((enumItem, index) => (
                                        <option key={index} value={enumItem.value}>
                                            {t(`RSIS.StepStatusEnum.${enumItem.value}`)}
                                        </option>
                                    ))
                            }
                        </select>
                    ) : t(`RSIS.StepStatusEnum.${item.stepStatus}`))
                }
            </div>
            {/* 變更、註銷狀態，若無變更或註銷則為空值 */}
            <div>{!stringIsEmpty(item.supportModifyStatus) && item.supportModifyStatus != 0 ? t(`RSIS.SupportModifyStatusEnum.${item.supportModifyStatus}`) : ''}</div>
            {/* 衛福部案號 */}
            <div>
                {isFastEdit
                    ? <TextField
                        value={sendCaseNo}
                        onChange={(e) => setSendCaseNo(e.target.value)}/>
                    : item.sendCaseNo}
            </div>
            {/* 匯出編號 */}
            <div>
                {isFastEdit
                    ? <TextField
                        value={sendFileName}
                        onChange={(e) => setSendFileName(e.target.value)}/>
                    : item.sendFileName}
            </div>
        </li>
    );
}

export default ListItem;
