// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.basicInfo.active {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.basicInfo.active li {
  padding-right: 20px;
  margin-right: 20px;
  border-right: 1px solid #d2d2d2;
}
.basicInfo.active li:last-of-type {
  border-right: none;
}
.basicInfo.active p {
  margin-left: 16px;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/state.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,2BAAA;AAAJ;AACI;EACE,mBAAA;EACA,kBAAA;EACA,+BAAA;AACN;AAAM;EACE,kBAAA;AAER;AACI;EACE,iBAAA;AACN","sourcesContent":[".basicInfo {\n  &.active {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: flex-start;\n    li {\n      padding-right: 20px;\n      margin-right: 20px;\n      border-right: 1px solid #d2d2d2;\n      &:last-of-type {\n        border-right: none;\n      }\n    }\n    p {\n      margin-left: 16px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
