import React, {useEffect, useState} from "react"
import {Dialog, DialogSizeEnums, DialogVariant} from "edah-component/dist"

/**
 * 刪除聯絡紀錄提示彈窗
 * @param {Boolean} show 是否顯示
 * @param {Function} closePopupButtonOnClick 關閉按鈕點擊事件
 * @param {Function} handleDeleteOnClick 刪除事件
 * @return {JSX.Element}
 */
const DeleteContactHistoryPromptPopup = ({
                                             show = false,
                                             closePopupButtonOnClick,
                                             handleDeleteOnClick
                                         }) => {
    /**
     * 是否顯示彈窗
     */
    const [showPopup, setShowPopup] = useState(show);

    /**
     * 監聽是否顯示彈窗Pros變化
     */
    useEffect(() => {
        // 設定是否顯示彈窗狀態
        setShowPopup(show)
    }, [show])

    return (
        <Dialog
            open={showPopup}
            title={"提醒"}
            content={"是否確定刪除？"}
            variant={DialogVariant.DELETE}
            paperStyleProps={{width: DialogSizeEnums.SM}}
            onClose={closePopupButtonOnClick}
            onDelete={() => handleDeleteOnClick()}
            muiDialogContentProps={{sx: {padding: "3rem 1.5rem 3rem 1.5rem"}}}
            muiDialogActionsProps={{
                sx: {
                    display: 'flex',
                    flexDirection: "row-reverse",
                    gap: "1rem",
                    justifyContent: 'end',
                    padding: '1rem'
                }
            }}
        />
    )
}

export default DeleteContactHistoryPromptPopup
