import "./appointmentPopup.scss";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {ApiErrorStatusEnum, arrayIsEmpty, objectIsEmpty, stringIsEmpty, time2String,} from "edah_utils/dist";
import {regsQueryClinicOfTheDay,} from "../../../../api/v1/Regs";
import {t} from "i18next";
import DailyScheduleItem from "./DailyScheduleItem";
import {
    Button,
    ButtonSizeEnum,
    ButtonVariantEnum,
    ColorEnum,
    DatePicker,
    Dialog,
    DialogSizeEnums,
    DialogVariant,
    Search,
    Select
} from "edah-component/dist";
import dayjs, {Dayjs} from 'dayjs';

/**
 * 診所班表
 * @param show {Boolean} 顯示
 * @param data {Object} 班表資料
 * @param closeClinic {Function} 關閉診所班表
 * @param onSelectClinic {Function} 診間選事件
 * @param fowardRegFlag {Boolean} 是否為補輸
 */
const ClinicSchedule = ({
                            show = false,
                            data,
                            closeClinic,
                            onSelectClinic,
                            fowardRegFlag,
                        }) => {
    //查詢條件
    const [queryInput, setQueryInput] = useState({
        divNo: "",
        //醫生代碼
        doctorNo: "",
        //日期
        encounterDate: "",
        //時段
        apn: "",
        //院區
        zone: "",
    });
    // 分院清單
    const [zonesetList, setZonesetList] = useState(
        data && data.zonesetList ? data.zonesetList : []
    );
    // 診所清單
    const [clinicList, setClinicList] = useState([]);
    // 科別清單
    const [divList, setDivList] = useState([]);
    // 醫生清單
    const [doctorList, setDoctorList] = useState([]);
    // 醫師下拉選項
    const [doctorOptionList, setDoctorOptionList] = useState(null);
    // 科別下拉選項
    const [departmentOptionList, setDepartmentOptionList] = useState(null);
    // 當日班表
    const [queryData, setQueryData] = useState([]);
    // 當日班表的日期
    const [queryResultDate, setQueryResultDate] = useState(null);
    //是否顯示彈窗
    const [showPopup, setShowPopup] = useState(show);

    /**
     * 點擊前一天
     * @return {void}
     */
    const handlePreviousDayOnClick = () => {
        const date = time2String(
            new Date(queryInput.encounterDate).setDate(
                new Date(queryInput.encounterDate).getDate() - 1
            ),
            "YYYY-MM-DD"
        );

        const newQueryInput = {
            encounterDate: date,
            doctorNo: queryInput.doctorNo,
            divNo: queryInput.divNo,
            zone: queryInput.zone,
            //不需要查詢apn
            //    apn: queryInput.apn,
        };

        setQueryInput({
            ...queryInput,
            encounterDate: date,
        });

        //搜尋
        queryQueryClinicOfTheDay(newQueryInput);
    };

    /**
     * 點擊後一天
     * @return {void}
     */
    const handleNextDayOnClick = () => {
        const date = time2String(
            new Date(queryInput.encounterDate).setDate(
                new Date(queryInput.encounterDate).getDate() + 1
            ),
            "YYYY-MM-DD"
        );

        const newQueryInput = {
            encounterDate: date,
            doctorNo: queryInput.doctorNo,
            divNo: queryInput.divNo,
            zone: queryInput.zone,
            //不需要查詢apn
            //    apn: queryInput.apn,
        };

        setQueryInput({
            ...queryInput,
            encounterDate: date,
        });

        //搜尋
        queryQueryClinicOfTheDay(newQueryInput);
    };

    /**
     * 取得醫師科別資料
     * @param zone {String} 時段
     * @param date {String} 日期
     */
    const getBaseDoctorAndDivision = (zone, date) => {
        //取得指定日期的醫生及科別資料
        regsQueryClinicOfTheDay({
            encounterDate: time2String(date, "YYYY-MM-DD 00:00:00"),
            zone: zone,
        }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                setDoctorList(res.data.doctorList);
                setDivList(res.data.divList);
                setClinicList(res.data.clinicList);
            }
        });
    };

    /**
     * 查詢當日班表
     * @param searchInput {Object} 查詢條件
     */
    const queryQueryClinicOfTheDay = (searchInput) => {
        regsQueryClinicOfTheDay({
            encounterDate: time2String(
                searchInput.encounterDate,
                "YYYY-MM-DD 00:00:00"
            ),
            doctorNo: searchInput.doctorNo
                ? searchInput.doctorNo.split(" ")[0]
                : null,
            divNo: searchInput.divNo ? searchInput.divNo.split(" ")[0] : null,
            apn: !stringIsEmpty(searchInput.apn) ? searchInput.apn : "",
            zone: !stringIsEmpty(searchInput.zone) ? searchInput.zone : "",
        }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                setQueryData(res.data);
                setQueryResultDate(searchInput.encounterDate);
            }
        });
    };

    /**
     * 院區改變時
     * @param value {String}
     * @return {void}
     */
    const handleZoneSelectOnChange = (value) => {
        const zone = value;
        setQueryInput({
            ...queryInput,
            zone: zone,
            //醫師科別重置
            doctorNo: "",
            divNo: "",
        });

        //取得醫師 科別資料
        getBaseDoctorAndDivision(zone, queryInput.encounterDate);
    };

    /**
     * 日期變更事件
     * @param date {Dayjs | null} 選定的日期
     */
    const handleDateOnChange = (date) => {
        if (!date || !date.isValid()) {
            return;
        }

        const year = date.year(); // 從 dayjs 對象中獲取年份

        if (year > 9999) {
            return;
        }

        const formattedDate = date.format("YYYY-MM-DD"); // 使用 dayjs 格式化日期
        const newQueryInput = {
            encounterDate: formattedDate,
            doctorNo: queryInput.doctorNo,
            divNo: queryInput.divNo,
            zone: queryInput.zone,
        };

        // 更新狀態
        setQueryInput({
            ...queryInput,
            encounterDate: formattedDate,
        });

        // 獲取醫師和科別資料
        getBaseDoctorAndDivision(queryInput.zone, formattedDate);

        // 搜尋
        queryQueryClinicOfTheDay(newQueryInput);
    };

    /**
     * 診間點選事件
     */
    const handleClinicOnClick = (disabled, clinicData) => {
        if (disabled) return;

        onSelectClinic(queryResultDate, clinicData);
        //關閉popup
        closeClinic();
    };

    /**
     * 取得科別選項列表
     * @return {Array}
     */
    const getDepartmentOptionList = () => {
        const divText = queryInput?.divNo?.trim();
        const doctorText = queryInput?.doctorNo?.trim();
        //切割字串
        const splitArray = divText ? divText.split(" ") : [];
        //已經有選擇科別
        if (splitArray.length === 2) {
            const data = divList.filter(
                (div) =>
                    div.divNo.includes(splitArray[0]) &&
                    div.divName.includes(splitArray[1])
            );
            return data
        } else {
            //透過輸入文字
            // 醫生選項為空時
            if (stringIsEmpty(doctorText)) {
                // 科別選項為空時
                if (stringIsEmpty(divText)) {
                    return divList;
                } else {
                    // 科別選項不為空時
                    return divList.filter(
                        (div) =>
                            div.divNo.includes(divText) ||
                            div.divName.includes(divText)
                    );
                }
            } else {
                // 醫生選項不為空時
                //取得醫生切割字串
                const splitDoctorArray = doctorText.split(" ");
                //已經有選擇醫生
                if (splitDoctorArray.length === 2) {
                    if (splitArray.length === 0) {
                        //科別為空
                        //求聯集
                        return divList
                            .filter((div) =>
                                clinicList.some(
                                    (clinic) =>
                                        clinic.divNo === div.divNo &&
                                        clinic.doctorNo === splitDoctorArray[0]
                                )
                            )
                            .map(
                                (div) =>
                                    new Object({
                                        //科別代碼
                                        divNo: div.divNo,
                                        //科別名稱
                                        divName: div.divName,
                                    })
                            );
                    } else if (splitArray.length === 1) {
                        //科別有1段
                        return divList
                            .filter((div) =>
                                clinicList.some(
                                    (clinic) =>
                                        clinic.divNo === div.divNo &&
                                        clinic.doctorNo === splitDoctorArray[0]
                                )
                            )
                            .filter(
                                (div) =>
                                    div.divNo.includes(divText) ||
                                    div.divName.includes(divText)
                            )
                            .map(
                                (div) =>
                                    new Object({
                                        //科別代碼
                                        divNo: div.divNo,
                                        //科別名稱
                                        divName: div.divName,
                                    })
                            );
                    } else {
                        return [];
                    }
                } else {
                    //透過輸入文字
                    return []; //return clinicList.filter(div => div.doctorNo === queryInput.doctorNo)
                }
            }
        }
    };

    /**
     * 取得醫師選項列表
     * @return {Array}
     */
    const getDoctorOptionList = () => {
        const divText = queryInput?.divNo?.trim();
        const doctorText = queryInput?.doctorNo?.trim();
        //切割字串
        const splitArray = doctorText ? doctorText.split(" ") : [];
        //已經有選擇醫師
        if (splitArray.length === 2) {
            return doctorList.filter(
                (doctor) =>
                    doctor.doctorNo.includes(splitArray[0]) &&
                    doctor.doctorName.includes(splitArray[1])
            );
        } else {
            //透過輸入文字
            // 科別選項為空時
            if (objectIsEmpty(divText)) {
                // 醫師選項為空時
                if (stringIsEmpty(doctorText)) {
                    return doctorList;
                } else {
                    // 醫師選項不為空時
                    return doctorList.filter(
                        (doctor) =>
                            doctor.doctorNo.includes(doctorText) ||
                            doctor.doctorName.includes(doctorText)
                    );
                }
            } else {
                // 科別選項不為空時
                //取得科別切割字串
                const splitDepartmentArray = divText.split(" ");
                //已經有選擇科別
                if (splitDepartmentArray.length === 2) {
                    if (splitArray.length === 0) {
                        //醫生為空
                        //求聯集
                        return doctorList
                            .filter((doctor) =>
                                clinicList.some(
                                    (clinic) =>
                                        clinic.doctorNo === doctor.doctorNo &&
                                        clinic.divNo === splitDepartmentArray[0]
                                )
                            )
                            .map(
                                (doctor) =>
                                    new Object({
                                        //科別代碼
                                        doctorNo: doctor.doctorNo,
                                        //科別名稱
                                        doctorName: doctor.doctorName,
                                    })
                            );
                    } else if (splitArray.length === 1) {
                        //醫生有1段
                        return doctorList
                            .filter((doctor) =>
                                clinicList.some(
                                    (clinic) =>
                                        clinic.doctorNo === doctor.doctorNo &&
                                        clinic.divNo === splitDepartmentArray[0]
                                )
                            )
                            .filter(
                                (doctor) =>
                                    doctor.doctorNo.includes(doctorText) ||
                                    doctor.doctorName.includes(doctorText)
                            )
                            .map(
                                (doctor) =>
                                    new Object({
                                        //科別代碼
                                        doctorNo: doctor.doctorNo,
                                        //科別名稱
                                        doctorName: doctor.doctorName,
                                    })
                            );
                    } else {
                        return [];
                    }
                } else {
                    //透過輸入文字
                    return []; //return clinicList.filter(div => div.doctorNo === queryInput.doctorNo)
                }
            }
        }
    };

    /**
     * 處理科別搜尋文字框變更事件
     * @param e {Event} 事件
     * @return {void}
     */
    const handleDepartmentSearchTextOnChange = (value) => {
        // 設定科別選項
        setQueryInput({
            ...queryInput,
            divNo: value,
        });
    };

    /**
     * 處理科別選項點擊事件
     * @param option {Object} 科別
     * @return {void}
     */
    const handleDepartmentOptionOnClick = (option) => {
        // 科別不為空
        if (option !== null) {
            // 科別編號
            const no = option.divNo;
            // 科別名稱
            const name = option.divName;
            // 設定科別選項
            setQueryInput({
                ...queryInput,
                divNo: `${no} ${name}`,
            });
        }
    };

    /**
     * 處理醫師搜尋文字框變更事件
     * @param value {String}
     * @return {void}
     */
    const handleDoctorSearchTextOnChange = (value) => {
        // 設定醫師選項
        setQueryInput({
            ...queryInput,
            doctorNo: value,
        });
    };

    /**
     * 處理醫師選項點擊事件
     * @param option {Object} 醫師
     * @return {void}
     */
    const handleDoctorOptionOnClick = (option) => {
        // 醫師不為空
        if (option !== null) {
            // 醫師編號
            const no = option.doctorNo;
            // 醫師名稱
            const name = option.doctorName;
            // 設定醫師選項
            setQueryInput({
                ...queryInput,
                doctorNo: `${no} ${name}`,
            });
        }
    };

    /**
     * 點擊查詢按鈕事件
     */
    const hadnleSearchOnClick = () => {
        const newQueryInput = {
            encounterDate: queryInput.encounterDate,
            doctorNo: queryInput.doctorNo,
            divNo: queryInput.divNo,
            zone: queryInput.zone,
            //不需要查詢apn
            //    apn: queryInput.apn,
        };

        //搜尋
        queryQueryClinicOfTheDay(newQueryInput);
    };

    /**
     * 渲染時段日班表
     * @param apn {Number} 時段 (1:早 2:午 3:晚)
     * @param clinicArray {Array} 日班表array
     * @returns
     */
    const renderClinicOfDayListByApn = (apn, clinicArray) => {
        //找出時段(apn)的班表
        let filteredClinics = arrayIsEmpty(clinicArray)
            ? null
            : clinicArray.filter((clinic) => clinic.apn === Number(apn));

        /**
         * 是否為可以掛號的時段
         */
        const isRegistrationPeriod = (clinic) => {
            const currentDate = new Date();
            const targetDate = new Date(clinic.encounterDate);
            const tmpCurrentDate = new Date(currentDate);

            if (fowardRegFlag) {
                //補輸情況下
                return true;
            }

            tmpCurrentDate.setHours(0, 0, 0, 0);
            // 如果是過去的日期，直接返回 false（即 disabled）
            if (targetDate < tmpCurrentDate) {
                return false;
            }

            // 停診情況
            if (clinic.stopClinicFlag) {
                return false;
            }

            // 當天時段的檢查
            if (targetDate.getTime() === tmpCurrentDate.getTime()) {
                // 根據時段（早診、午診、晚診）檢查時間範圍
                if (clinic.apn === 1 && currentDate.getHours() >= 12) {
                    // 如果當前是早診，且已過 12 點
                    return false;
                } else if (clinic.apn === 2 && currentDate.getHours() >= 17) {
                    // 如果當前是午診，且已過 17 點
                    return false;
                } else if (clinic.apn === 3 && currentDate.getHours() >= 21) {
                    // 如果當前是晚診，且已過 21 點
                    return false;
                }
            }

            // 額滿情況：當前和未來時段，顯示 [滿] 但不 disabled
            if (clinic.regFullFlag) {
                return true; // 返回 true 以允許繼續掛號，只顯示 [滿] 標籤
            }

            return true; // 允許掛號
        };

        return (
            <ul className="scheduleList text-sm">
                {/*控制class去區分類別 額滿給予disable的class*/}

                {arrayIsEmpty(filteredClinics) ? (
                    /*如果沒有資料則顯示此區塊*/
                    <div
                        className="nodata flex items-center justify-center text-xl font-normal h-[400px] overflow-y-auto">
                        沒有醫生看診 <br/>
                        請選其他時間
                    </div>
                ) : (
                    filteredClinics.map((clinic, index) => {
                        let disabled = false;
                        disabled = !isRegistrationPeriod(clinic); // 判斷是否可以掛號
                        return (
                            <DailyScheduleItem
                                key={index}
                                record={clinic}
                                disabled={disabled}
                                regFullFlag={clinic.regFullFlag}
                                onClickItem={handleClinicOnClick}
                            />
                        );
                    })
                )}
            </ul>
        );
    };

    /**
     * 取得預設科別值
     */
    const getDefaultDivValue = useMemo(() => {
        const data = departmentOptionList?.find(option => queryInput?.divNo?.includes(option?.divNo || ''))
        return data ? data : null
    }, [departmentOptionList, queryInput?.divNo])

    /**
     * 取得預設醫師值
     */
    const getDefaultDoctorNoValue = useMemo(() => {
        const data = doctorOptionList?.find(option => queryInput?.doctorNo?.includes(option?.doctorNo || ''))
        return data ? data : null
    }, [doctorOptionList, queryInput?.doctorNo])
    /**
     * 監聽是否顯示彈窗Pros變化
     */
    useEffect(() => {
        if (show) {
            const firstDoctorNo =
                objectIsEmpty(data) || objectIsEmpty(data.doctorNo)
                    ? ""
                    : data.doctorNo;
            const firstDivNo =
                objectIsEmpty(data) || objectIsEmpty(data.divNo)
                    ? ""
                    : data.divNo;
            const firstDate = time2String(
                data != null && data.date ? data.date : new Date(),
                "YYYY-MM-DD"
            );
            const firstZone =
                objectIsEmpty(data) || objectIsEmpty(data.zone)
                    ? ""
                    : data.zone;
            const firstApn =
                objectIsEmpty(data) || objectIsEmpty(data.apn) ? "" : data.apn;

            setZonesetList(data && data.zonesetList ? data.zonesetList : []);

            setQueryInput({
                //科別代碼
                divNo: firstDivNo,
                //醫生代碼
                doctorNo: firstDoctorNo,
                //日期
                encounterDate: firstDate,
                //時段
                apn: firstApn,
                //院區
                zone: firstZone,
            });

            //取得醫師科別基礎資料
            getBaseDoctorAndDivision(firstZone, firstDate);

            //查詢日班表資料
            const newQueryInput = {
                encounterDate: firstDate,
                doctorNo: firstDoctorNo,
                divNo: firstDivNo,
                zone: firstZone,
                //不需要查詢apn
                //    apn: queryInput.apn,
            };

            queryQueryClinicOfTheDay(newQueryInput);
        }

        setShowPopup(show);
    }, [show]);

    /**
     * 監聽科別代碼輸入框變化
     */
    useEffect(() => {
        const value = getDepartmentOptionList()
        setDepartmentOptionList(value);
    }, [queryInput.divNo, queryInput.doctorNo, divList]);

    /**
     * 監聽醫師代碼輸入框變化
     */
    useEffect(() => {
        setDoctorOptionList(getDoctorOptionList());
    }, [queryInput.doctorNo, queryInput.divNo, doctorList]);

    return (
        <Dialog
            open={showPopup}
            title={"當日班表_選擇掛號"}
            content={
                <>
                    {/*filter篩選*/}
                    <div className="filter text-left flex flex-row items-center justify-start space-x-2 py-2">
                        {/*院區*/}
                        <Select
                            data={{
                                label: "院區",
                                options:
                                    zonesetList.map((zone) => ({
                                        label: zone.zoneName,
                                        value: zone.zone,
                                    })) || [],
                            }}
                            value={queryInput.zone}
                            onChange={handleZoneSelectOnChange}
                            notched={true}
                        />
                        {/*科別*/}
                        <Search
                            freeSolo
                            value={getDefaultDivValue}
                            inputLabel={t('general.department')}
                            onChange={(_e, value) => {
                                handleDepartmentOptionOnClick(value)
                            }}
                            onInputChange={(_e, value) => {
                                handleDepartmentSearchTextOnChange(value)
                            }
                            }
                            disablePortal={false}
                            options={departmentOptionList}
                            getOptionLabel={(option) => `${option?.divNo} ${option?.divName}`}
                        />
                        {/*醫師*/}
                        <Search
                            freeSolo
                            value={getDefaultDoctorNoValue}
                            inputLabel={t('general.doctor')}
                            onChange={(_e, value) => handleDoctorOptionOnClick(value)}
                            onInputChange={(_e, value) => handleDoctorSearchTextOnChange(value)}
                            disablePortal={false}
                            options={doctorOptionList}
                            getOptionLabel={(option) => `${option?.doctorNo} ${option?.doctorName}`}
                        />
                        {/*查詢按鈕*/}
                        <Button
                            color={ColorEnum.Primary}
                            variant={ButtonVariantEnum.Contained}
                            size={ButtonSizeEnum.Medium} text={t("general.query")}
                            onClick={hadnleSearchOnClick}/>
                    </div>
                    {/*時間表*/}
                    <div className="schedule">
                        <div className="dateTitle relative flex flex-row items-center justify-center mb-4">
                            {/*前一天按鈕*/}
                            <Button
                                sx={{marginRight: "0.5rem"}}
                                color={ColorEnum.Secondary} variant={ButtonVariantEnum.Outlined}
                                size={ButtonSizeEnum.Medium} text={"前一天"}
                                onClick={handlePreviousDayOnClick}/>
                            <DatePicker
                                label=""
                                value={dayjs(queryInput.encounterDate)}
                                onChange={handleDateOnChange}/>
                            {/*後一天按鈕*/}
                            <Button
                                sx={{marginLeft: "0.5rem"}}
                                color={ColorEnum.Secondary} variant={ButtonVariantEnum.Outlined}
                                size={ButtonSizeEnum.Medium} text={"後一天"}
                                onClick={handleNextDayOnClick}/>
                        </div>
                        <div className="scheduleSection w-[900px] mx-auto ">
                            <div className="flex flex-row items-start justify-center">
                                <div className="morning w-1/3 bg-[#EBF8FF] p-2">
                                    <h3 className="mb-3 text-xl font-normal">
                                        早8:30~12:00
                                    </h3>
                                    <div className="h-[510px]">
                                        {renderClinicOfDayListByApn(
                                            1,
                                            objectIsEmpty(queryData)
                                                ? null
                                                : queryData.clinicList
                                        )}
                                    </div>
                                </div>
                                <div className="afternoon w-1/3 bg-[#FEFCE8] p-2">
                                    <h3 className="mb-3 text-xl font-normal">
                                        午13:30~17:00
                                    </h3>
                                    <div className="h-[510px]">
                                        {renderClinicOfDayListByApn(
                                            2,
                                            objectIsEmpty(queryData)
                                                ? null
                                                : queryData.clinicList
                                        )}
                                    </div>
                                </div>
                                <div className="night w-1/3 bg-[#FAF5FF] p-2">
                                    <div className="h-[510px]">
                                        <h3 className="mb-3 text-xl font-normal">
                                            晚18:00~21:00
                                        </h3>
                                        {renderClinicOfDayListByApn(
                                            3,
                                            objectIsEmpty(queryData)
                                                ? null
                                                : queryData.clinicList
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
            onClose={closeClinic}
            variant={DialogVariant.CONFIRM}
            paperStyleProps={{width: DialogSizeEnums.LG}}
            muiDialogActionsProps={{sx: {display: 'none'}}}
            muiDialogContentProps={{sx: {overflowY: 'hidden'}}}
        />
    )
};

export default ClinicSchedule;
