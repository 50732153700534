import TabItem from "./TabItem";
import React from "react";
import {setActiveTabId} from "../../redux/Slice/TabSlice";
import store from "../../redux/store";

/**
 * Tab
 * @param tabList {Array}
 * @param activeTabId {String}
 * @param handleTabOnClose {Function}
 * @return {JSX.Element}
 */
const Tab = ({tabList, activeTabId, handleTabOnClose}) => {
    /**
     * 點擊Tab時
     * @param id {String}
     * @return {void}
     */
    const handleTabClick = (id) => {
        store.dispatch(setActiveTabId({id}))
    }

    return (
        <div className="w-full h-[42px] bg-[#E4E4E7] flex items-end">
            <ul className="flex overflow-auto">
                {
                    tabList.map((tab) =>
                        <li className="flex flex-col" key={tab.id}>
                            <TabItem id={tab.id} name={tab.name} isActive={tab.id === activeTabId}
                                     onClose={() => handleTabOnClose(tab.id)}
                                     onClick={() => handleTabClick(tab.id)}/>
                        </li>
                    )
                }
            </ul>
        </div>
    );
}

export default Tab;
