import Fuse from 'fuse.js';
import { useEffect } from 'react';

function getCurrentFormattedDateTime() {
    const now = new Date();

    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

function getCurrentDateForInput() {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(now.getDate()).padStart(2, '0');
  
  return `${year}-${month}-${day}`;
}

function generateMedicalId() {
    // 定義單位標識
    const units = ['A', 'O', 'I'];
    // 隨機選擇一個單位標識
    const unit = units[Math.floor(Math.random() * units.length)];
    
    // 獲取當前日期
    const date = new Date();
    const year = String(date.getFullYear()).slice(2); // 取後兩位數年
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份補零
    const day = String(date.getDate()).padStart(2, '0'); // 日期補零
    
    // 隨機生成流水號
    const randomSegment = () => Math.random().toString(36).substring(2, 6).toUpperCase(); // 生成4位隨機字母或數字
    const serialNumber = `${randomSegment()}-${randomSegment()}-${randomSegment()}-${randomSegment()}`;
  
    // 組合就醫號ID
    const medicalId = `${unit}-${year}${month}${day}-${serialNumber}`;
    
    return medicalId;
  }

  // 定義進階搜尋關鍵字
  const keywords=[
    'cvisNotifyId',
    'encounterId',
    'patientId',
    'patientName',
    'cvisNotifyDatetime',
    'cvisNotifyUserName',
    'cvisNotifyUnit',
    'cvisNotifyClass'
]

function fuseObject(data) {
    const fuse = new Fuse(data, {
        keys: keywords,
        threshold: 0.3,
    })
    return fuse
}

function inpOpdToText(inpOpd) {
    switch (inpOpd) {
        case 'I':
            return '住院';
        case 'O':
            return '門診';
        case 'E':
            return '急診';
        case '住院':
            return '住院';
        case '門診':
            return '門診';
        case '急診':
            return '急診';
        default:
            return '';
    }
}

const cvisNotifyUnitAdapter = (index) => {
    switch (index) {
      case '01':
        return "放射檢查"
      case '02':
        return "病理檢查"
      case '03':
        return "醫事檢驗"
      case '04':
        return "核醫檢查"
      case '05':
        return "心臟超音波檢查"
      case '06':
        return "神經超音波檢查"
      case '07':
        return "胸腔超音波"
      case '08':
        return "胃腸超音波"
      case '09':
        return "兒童超音波檢查"
      default:
        return "null"
    }
  }

export { 
    getCurrentFormattedDateTime,
    getCurrentDateForInput,
    generateMedicalId,
    fuseObject,
    inpOpdToText,
    cvisNotifyUnitAdapter
};