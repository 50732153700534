//Import css
import '../../TableList/tableList.scss';

//Import Function
import React, {useEffect, useState} from 'react';
import {
    ApiErrorStatusEnum,
    enum2Array,
    RsisStepStatusEnum,
    stringIsEmpty,
    time2String,
} from 'edah_utils/dist'
import {usePopup} from '../PopupProvider';
import {t} from "i18next"
import {rsisQuickEdit} from "../../../api/v1/RSIS";
import {
    AlertTypeEnum,
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    Checkbox,
    FontSizeEnum,
    IconEnum,
    TextField,
    Select,
    SelectWidthEnum
} from "edah-component/dist"

const batchModeEnum = {
    // 收案確認
    CASECONFIRM: '1',
    // 衛福部送審轉出
    SENDCASE: '2',
    // 退回申請
    REJECTAPPLY: '3'
}

/**
 * RSIS個案管理清單組件
 * @param {Object} item 清單資料
 * @param {Number} index 清單索引
 * @param {String} role 使用者角色
 * @param {boolean} isSelectAll 是否包含全選
 * @param {function} handleOnSelected 選取事件
 * @param {function} handleOnUnSelected 取消選取事件
 * @return {JSX.Element}
 */
const ListItem = ({
                      item,
                      index,
                      isSelectAll = null,
                      handleOnSelected,
                      handleOnUnSelected,
                      handleOnUpdate
                  }) => {
    // 本地資料
    const [data, setData] = useState(item)
    // 本地狀態別
    const [stepStatus, setStepStatus] = useState(item.stepStatus)
    // 本地衛福部案號
    const [sendCaseNo, setSendCaseNo] = useState(item.sendCaseNo)
    // 本地匯出編號
    const [sendFileName, setSendFileName] = useState(item.sendFileName)
    // 是否快速編輯狀態
    const [isFastEdit, setIsFastEdit] = useState(false)
    // 是否可選取
    const [isDisabled, setIsDisabled] = useState(false)
    // 是否選取
    const [isSelected, setIsSelected] = useState(false)

    // 使用上下文
    const {
        batchSelected,
        setShowCopyModal,
        setShowCaseConfirmation,
        setShowDeletePopup,
        setShowSendMOHWConfirm,
        setShowChangeModal,
        setShowLogOffModal,
        setListItemBtnType,
        setFocusIndex,
        showToast
    } = usePopup();
    /**
     * 勾選框觸發事件
     * @param e {Event}
     * @return {void}
     */
    const handleOnSelectedChange = (e) => {
        // 使用者尚未選擇批次作業功能方法時，顯示警告訊息
        if (batchSelected === null) showToast({message: '請先選擇批次作業功能', type: AlertTypeEnum.Warning})
        // 更新選取狀態
        setIsSelected(e.target.checked)
        // 呼叫父層選取或取消選取事件
        if (e.target.checked) {
            handleOnSelected(item)
        } else {
            handleOnUnSelected(item)
        }
    }

    /**
     * 編輯按鈕觸發事件
     * @return {void}
     */
    const handleOnEditClick = () => {
        // 判斷是否為急件申請案件
        if (data.applyType === 'E') {
            // 狀態別為確認時，顯示送衛福部彈窗
            if (data.stepStatus === '2') {
                // 顯示送衛福部彈窗
                setShowSendMOHWConfirm(true)
                // 設定按鈕觸發來源為編輯，開啟確認送交衛福部彈窗，根據按鈕來源顯示後續對應內容
                setListItemBtnType('EDIT')
            } else {
                setShowCaseConfirmation(true)
            }
        } else {
            setShowCaseConfirmation(true)
        }
    }

    /**
     * 刪除按鈕觸發事件
     * @return {void}
     */
    const handleOnDeleteClick = () => {
        setShowDeletePopup(true)
    }

    /**
     * 快速編輯存檔
     * @return {void}
     */
    const handleSave = () => {
        // 複製一個data物件，更新快速編輯後的資料
        const saveData = {
            ...data,
            stepStatus: stepStatus,
            sendCaseNo: sendCaseNo,
            sendFileName: sendFileName
        }
        // 呼叫API，儲存快速編輯後的資料
        rsisQuickEdit([saveData]).then(response => {
            const {err, msg} = response;
            if (err === ApiErrorStatusEnum.Success) {
                // 儲存成功，更新本地資料
                setData({
                    ...data,
                    stepStatus: stepStatus,
                    sendCaseNo: sendCaseNo,
                    sendFileName: sendFileName
                })
                handleOnUpdate()
                setIsFastEdit(false)
                // 顯示成功訊息
                showToast({message: `快速編輯儲存成功，${msg}`, type: AlertTypeEnum.Success});
            } else {
                // 顯示錯誤訊息
                showToast({message: `快速編輯儲存失敗,${msg}`, type: AlertTypeEnum.Error});
            }
        })
    }

    /**
     * 快速編輯取消
     * @return {void}
     */
    const handleCancel = () => {
        // 還原狀態別資料
        setStepStatus(data.stepStatus)
        // 還原衛福部案號資料
        setSendCaseNo(data.sendCaseNo)
        // 還原匯出編號資料
        setSendFileName(data.sendFileName)
        // 退出快速編輯狀態
        setIsFastEdit(false)
    }

    /**
     * 定義SlotBtn，根據狀態顯示對應操作按鈕
     * @return {JSX.Element}
     */
    const renderSlotBtn = () => {
        const style = 'flex flex-row gap-2'
        // 是否在快速編輯狀態
        if (isFastEdit) {
            return <span className={style}>
                {/*儲存按鈕*/}
                <Button
                    sx={{fontSize: FontSizeEnum.TextMedium}}
                    color={ButtonColorEnum.Primary} size={ButtonSizeEnum.Medium}
                    icon={IconEnum.Save}
                    text={t('general.save')} variant={ButtonVariantEnum.Text}
                    onClick={handleSave}/>
                {/*取消按鈕*/}
                <Button
                    sx={{fontSize: FontSizeEnum.TextMedium}}
                    color={ButtonColorEnum.Primary} size={ButtonSizeEnum.Medium}
                    icon={IconEnum.Cancel}
                    text={t('general.cancel')} variant={ButtonVariantEnum.Text}
                    onClick={handleCancel}/>
            </span>
        }

        /**
         * 快速編輯按鈕
         * @return {JSX.Element}
         */
        const fastEditButton = () => <Button
            sx={{fontSize: FontSizeEnum.TextMedium}}
            color={ButtonColorEnum.Primary} size={ButtonSizeEnum.Medium}
            icon={IconEnum.FastEdit}
            text={t('general.fastEdit')} variant={ButtonVariantEnum.Text}
            onClick={() => setIsFastEdit(true)}/>

        switch (data.stepStatus) {
            case RsisStepStatusEnum.Apply.toString():
            case RsisStepStatusEnum.Confirm.toString():
                return (
                    <span className={style}>
                        {/*複製按鈕*/}
                        <Button
                            sx={{fontSize: FontSizeEnum.TextMedium}}
                            color={ButtonColorEnum.Primary} size={ButtonSizeEnum.Medium}
                            icon={IconEnum.Copy}
                            text={t('general.copy')} variant={ButtonVariantEnum.Text}
                            onClick={() => setShowCopyModal(true)}/>
                        {/*編輯按鈕*/}
                        <Button
                            sx={{fontSize: FontSizeEnum.TextMedium}}
                            color={ButtonColorEnum.Primary} size={ButtonSizeEnum.Medium}
                            icon={IconEnum.Edit}
                            text={t('general.edit')} variant={ButtonVariantEnum.Text}
                            onClick={handleOnEditClick}/>
                        {/*刪除按鈕*/}
                        <Button
                            sx={{fontSize: FontSizeEnum.TextMedium}}
                            color={ButtonColorEnum.Primary} size={ButtonSizeEnum.Medium}
                            icon={IconEnum.Delete}
                            text={t('general.delete')} variant={ButtonVariantEnum.Text}
                            onClick={handleOnDeleteClick}/>
                        {/*快速編輯按鈕*/}
                        {fastEditButton()}
                    </span>
                );
            case RsisStepStatusEnum.Review.toString():
                return (
                    <span className={style}>
                        {/*變更按鈕*/}
                        <Button
                            sx={{fontSize: FontSizeEnum.TextMedium}}
                            color={ButtonColorEnum.Primary} size={ButtonSizeEnum.Medium}
                            icon={IconEnum.Change}
                            text={t('general.change')} variant={ButtonVariantEnum.Text}
                            onClick={() => setShowChangeModal(true)}/>
                        {/*註銷按鈕*/}
                        <Button
                            sx={{fontSize: FontSizeEnum.TextMedium}}
                            color={ButtonColorEnum.Primary} size={ButtonSizeEnum.Medium}
                            icon={IconEnum.LogOff}
                            text={t('general.logoff')} variant={ButtonVariantEnum.Text}
                            onClick={() => setShowLogOffModal(true)}/>
                        {fastEditButton()}
                    </span>
                )
            case RsisStepStatusEnum.Invalid.toString():
                return (
                    <span className={style}>
                        {fastEditButton()}
                    </span>
                )
            default:
                return null;
        }
    }

    /**
     * 是否可以快速編輯某些欄位
     * @return {boolean}
     */
    const canFastEdit = () => !(isFastEdit && sendCaseNo && sendFileName)

    /**
     * 全選
     * @return {void}
     */
    useEffect(() => {
        // 批次作業-收案確認，選取所有狀態為申請的案件
        if (batchSelected === batchModeEnum.CASECONFIRM && item.stepStatus === '1') setIsSelected(isSelectAll)
        // 批次作業-衛福部送審轉出或退回申請，選取所有狀態為確認且沒有變更或註銷的案件
        if (batchSelected === batchModeEnum.SENDCASE && item.stepStatus === '2' && stringIsEmpty(item.supportModifyStatus)) {
            setIsSelected(isSelectAll)
        }
        // 批次作業-退回申請，選取所有狀態為確認的案件
        if (batchSelected === batchModeEnum.REJECTAPPLY && item.stepStatus === '2') setIsSelected(isSelectAll)
    }, [isSelectAll]);

    /**
     * 批次作業選項改變時，取消選取
     * @return {void}
     */
    useEffect(() => {
        setIsSelected(false)
        // 收案確認勾選時，不得選取非申請狀態案件
        if (batchSelected === batchModeEnum.CASECONFIRM) setIsDisabled(data.stepStatus !== '1')
        // 衛福部送審轉出時，不得選取非確認狀態案件或有變更或註銷的案件
        else if (batchSelected === batchModeEnum.SENDCASE) {
            setIsDisabled(data.stepStatus !== '2' || data.supportModifyStatus === 'M' || data.supportModifyStatus === 'D')
            // 退回申請時，不得選取非確認狀態案件
        } else if (batchSelected === batchModeEnum.REJECTAPPLY) setIsDisabled(data.stepStatus !== '2')
        // 未選擇批次作業功能時，不得選取
        if (batchSelected === null) setIsDisabled(true)
    }, [batchSelected])

    /**
     * 當資料變更時，更新本地資料
     * @return {void}
     */
    useEffect(() => {
        setData(item)
    }, [item])

    return (
        <li key={index} onClick={() => setFocusIndex(index)}>
            {/* 選擇框 */}
            <div>
                <Checkbox
                    checked={isSelected}
                    onChange={handleOnSelectedChange}
                    disabled={isDisabled}
                />
            </div>
            {/* 根據使用者權限顯示對應按鈕 */}
            <div className='flex justify-evenly'>
                <span className='flex items-center'>
                    {renderSlotBtn()}
                </span>
            </div>
            {/* 申請單號、院區名稱 */}
            <div className='flex flex-col'>
                <p className='w-full'>{data?.supportNo || ''}</p>
                <p className='w-full'>{t(`campus.shortName.${data?.zone || ''}`)}</p>
            </div>
            {/* 申請人 */}
            <div className='flex flex-col'>
                <p className='w-full'>{data?.createUser || ''}</p>
                <p className='w-full'>{data?.createUserName || ''}</p>
            </div>
            {/* 支援人員 */}
            <div className='flex flex-col'>
                <p className='w-full'>{data?.supportUserNo || ''}</p>
                <p className='w-full'>{data?.supportUserName || ''}</p>
            </div>
            {/* 支援機構代號、名稱 */}
            <div className='flex flex-col'>
                <p className='w-full'>{data?.supportOrgNo || ''}</p>
                <p className='w-full'>{data?.supportOrgName || ''}</p>
            </div>
            {/* 支援科別 */}
            <div className='flex flex-col'>
                <p className='w-full'>{!stringIsEmpty(data.supportDivNo) ? data.supportDivNo : ''}</p>
                <p className='w-full'>{!stringIsEmpty(data.supportDivName) ? data.supportDivName : ''}</p>
            </div>
            {/* 報備期間、開始-結束日期 */}
            <div className='flex flex-col'>
                <p className='w-full'>{`${time2String(data.itemStartDate, 'YYYY-MM-DD')} ${data.itemStartTime.slice(0, 2) + ':' + data.itemStartTime.slice(2)}`}</p>
                <p className='w-full'>{`${time2String(data.itemEndDate, 'YYYY-MM-DD')} ${data.itemEndTime.slice(0, 2) + ':' + data.itemEndTime.slice(2)}`}</p>
            </div>
            {/* 狀態別 */}
            <div>
                {
                    isFastEdit
                        ?
                        <Select
                            data={{
                                label: "快速編輯",
                                options: enum2Array(RsisStepStatusEnum).filter(item => item.value !== RsisStepStatusEnum.All).map((item) => ({
                                    label: t(`RSIS.StepStatusEnum.${item.value}`),
                                    value: item.value
                                }))
                            }}
                            value={stepStatus}
                            onChange={setStepStatus}
                            disabled={!isFastEdit}
                            showLabel={false}
                            width={SelectWidthEnum.Tiny}
                        />
                        : t(`RSIS.StepStatusEnum.${data.stepStatus}`)
                }
            </div>
            {/* 變更或者註銷狀態 */}
            <div>{data.supportModifyStatus === 'M' || data.supportModifyStatus === 'D' ? t(`RSIS.SupportModifyStatusEnum.${data.supportModifyStatus}`) : ''}</div>
            {/* 衛福部案號 */}
            <div>
                <TextField value={!stringIsEmpty(sendCaseNo) ? sendCaseNo : ''} disabled={canFastEdit()}
                           onChange={(e) => setSendCaseNo(e.target.value)}/>
            </div>
            {/* 匯出編號 */}
            <div>
                <TextField value={!stringIsEmpty(sendFileName) ? sendFileName : ''} disabled={canFastEdit()}
                           onChange={(e) => setSendFileName(e.target.value)}/>
            </div>
            {/* 備註 */}
            <div>{data?.memo || ''}</div>
        </li>
    );
}

export default ListItem;
