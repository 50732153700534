import React, {useEffect, useState} from "react";
import {t} from "i18next";
import {
    ApiErrorStatusEnum,
    arrayIsEmpty,
    objectIsEmpty,
    PopupModeEnum,
    RegsMedicalTypeEnum,
    RegsWeekModeEnum,
    RegsWeekReservationLimitEnum,
    RegsWeekReservationMethodEnum,
    stringIsEmpty,
    TimeslotEnum
} from "edah_utils/dist";
import {
    regsAddMonthClinic,
    regsCheckBeforeSaveClinicByRsis,
    regsCheckForClinicDocOrLocumDuplicate,
    regsEditMonthClinic
} from "../../../api/v1/RegsMonth";
import {sloganQueryContentForWeekOrClinic} from "../../../api/v1/Slogn";
import useToast from "../../../hooks/useToast";
import {
    AlertTypeEnum,
    Checkbox,
    Dialog,
    DialogSizeEnums,
    DialogVariant,
    Field,
    RadioGroup,
    Search,
    Select,
    SizeEnum,
    TextArea,
    TextField,
} from "edah-component/dist"
import dayjs from 'dayjs';

/**
 * 月班Popup
 * @param mode {Number} 新增或編輯
 * @param filterMode {Number} 篩選模式
 * @param baseData {Object} 基本資料
 * @param data {Object} 資料
 * @param closePopup {Function} 關閉彈出視窗
 * @param getMonthData {Function} 取得月班資料的function
 */
const AppointmentPopup = ({
                              mode,
                              filterMode,
                              baseData,
                              data,
                              closePopup,
                              getMonthData,
                          }) => {
    //預設設定
    const baseSettings = {
        // 診間ID
        clinicId: data.clinicId ? data.clinicId : null,
        // 預約最大看診人次
        maxPatient: data.maxPatient ? data.maxPatient.toString() : "100",
        // 現場最大看診人次
        onsiteLimit: data.onsiteLimit ? data.onsiteLimit.toString() : "100",
        // 看診時間(人/分)
        consultationTime: data.consultationTime
            ? data.consultationTime.toString()
            : "5",
        // 預約方式
        reservationMethod: data.reservationMethod
            ? data.reservationMethod
            : RegsWeekReservationMethodEnum.ClinicNumber,
        // 調病歷
        canGetMedicalRecords: data.canGetMedicalRecords
            ? data.canGetMedicalRecords
            : false,
        // 給號方式
        numberGivingMethod: data.numberGivingMethod
            ? data.numberGivingMethod
            : 9,
        // 收費別
        chargeType: data.chargeType ? data.chargeType : `${baseData.cashTypeList[0]['cashType']} ${baseData.cashTypeList[0]['cashName']}`,
        // 預約限制
        reservationLimit: data.reservationLimit
            ? data.reservationLimit
            : RegsWeekReservationLimitEnum.Normal,
        // 診別
        medicalType: data.medicalType ? data.medicalType : "O",
        // 開放網掛/App掛號
        webOrAppBooking: data.webOrAppBooking ? data.webOrAppBooking : false,
        // 開放網掛/App取消看診進度
        webOrAppCancel: data.webOrAppCancel ? data.webOrAppCancel : false,
        //網路預約期限
        onlineBookingDeadline: data.onlineBookingDeadline
            ? data.onlineBookingDeadline
            : 30,
        //看診項目
        mediItem: (data.mediItem && data.mediItem !== "empty") ? data.mediItem : "",
        //診間地點
        clinicLocation: data.clinicLocation ? data.clinicLocation : "",
        //注意事項
        notes: data.notes ? data.notes : "",
        //診間標語
        clinicSlogan: data.clinicSlogan ? data.clinicSlogan : "",
        //醫生
        doctor: data.doctor ? data.doctor : null,
        //科別
        department: data.department ? data.department : null,
        //診室名稱
        clinicName: data.clinicName ? data.clinicName : null,
        //診室號
        clinicNo: data.clinicNo ? data.clinicNo : "",
        //時段
        timeslot: data.timeslot ? data.timeslot : null,
        //星期幾
        week: data.week !== null ? data.week : null,
        //當前日期
        date: data.date ? data.date : null,
        //代理醫師
        locumDoctor: data.locumDoctor ? data.locumDoctor : "",
        //停診
        stopClinicFlag: data.stopClinicFlag ? data.stopClinicFlag : false,
        //停診原因
        stopClinicReason: data.stopClinicReason ? data.stopClinicReason : null,
        //停代診公告
        stopClinicBulletinFlag: data.stopClinicBulletinFlag
            ? data.stopClinicBulletinFlag
            : false,
        //假日展班
        holidayexclFlag: data.holidayexclFlag ? data.holidayexclFlag : false,
        //看診項目
        //consultationItems: data.consultationItems ? data.consultationItems : "empty",
        weekId: data.weekId,

        lockVersion: data.lockVersion,
    };
    //當前設定
    const [record, setRecord] = useState(baseSettings);
    // 醫師下拉選項
    const [doctorOptionList, setDoctorOptionList] = useState(null);
    // 科別下拉選項
    const [departmentOptionList, setDepartmentOptionList] = useState(null);
    // 代理醫師下拉選項
    const [locumDoctorOptionList, setLocumDoctorOptionList] = useState(null);
    //是否可以修改醫生欄位
    const [canModifyDoctor, setCanModifyDoctor] = useState(true);
    //是否可以修改科別欄位
    const [canModifyDepartment, setCanModifyDepartment] = useState(true);
    // 顯示Toast
    const showToast = useToast();
    // 顯示 popup 確認醫師同時段有其它診的情況下是否儲存
    const [showDuplicateDoctorMessagePopup, setShowDuplicateDoctorMessagePopup] = useState(false);
    // 給予收費別欄位顯示資訊
    const [chargeTypeValue, setChargeTypeValue] = useState(data.chargeType ? data.chargeType : baseData.cashTypeList[0]);
    // 給予醫師欄位顯示資訊
    const [doctorValue, setDoctorValue] = useState(null);//{userNo:'',userName:''}
    // 給予科別欄位顯示資訊
    const [departmentValue, setDepartmentValue] = useState(null);//{divNo: '', divName: ''}
    // 給予代班醫師欄位顯示資訊
    const [locumDoctorValue, setLocumDoctorValue] = useState(null);
    // 儲存醫師同時段時否有其它診資料
    const [duplicateDoctorSlot, setDuplicateDoctorSlot] = useState(null);
    // 顯示醫師報備稽核錯誤訊息Popup
    const [showDoctorInformDetectMessagePopup, setShowDoctorInformDetectMessagePopup] = useState(false);
    // 儲存醫師報備稽核錯誤訊息
    const [doctorInformDetectMessage, setDoctorInformDetectMessage] = useState("")

    /**
     * 醫生搜尋文字欄位改變時
     * @param newValue {String}
     * @return {void}
     */
    const handleDoctorSearchTextOnChange = (newValue) => {
        //可以修改醫生時
        if (canModifyDoctor) {
            //設定醫生值
            setRecord({...record, doctor: newValue});
        }
    };

    /**
     * 醫生欄位被點擊時
     * @param option {Object|null}
     * @return {void}
     */
    const handleDoctorOptionOnClick = async (option) => {
        // 選項不為空
        if (option !== null) {
            let sloganText = "";
            if (
                !stringIsEmpty(record.department) &&
                record.department.split(" ")[0] !== null &&
                !stringIsEmpty(option.userNo)
            ) {
                //取得標語
                sloganText = await getSloganContentForWeekOrClinic(
                    option.userNo,
                    record.department.split(" ")[0]
                );
            }
            //設定醫生
            setRecord({
                ...record,
                doctor: `${option.userNo} ${option.userName}`,
                clinicSlogan: sloganText,
            });
        } else {
            //設定醫生值
            setRecord({...record, doctor: ''});
        }
    };

    /**
     * 根據搜尋文字過濾醫生選項
     * @return {Array}
     */
    const getFilterDoctorOptions = () => {
        //可以修改醫生時
        if (canModifyDoctor) {
            //取得搜尋文字
            const searchText = record?.doctor || '';
            // 搜尋文字為空
            if (searchText === "" || searchText === null) {
                return baseData.doctorList;
            } else {
                //搜尋文字不為空
                //是否有空白
                const hasSpace = searchText.indexOf(" ") >= 0;
                //分割搜尋文字
                const splitSearchTextArray = searchText.split(" ");

                return baseData.doctorList.filter((doctor) =>
                    hasSpace
                        ? doctor.userNo.includes(splitSearchTextArray[0]) ||
                        doctor.userName.includes(splitSearchTextArray[1])
                        : doctor.userNo.includes(searchText) ||
                        doctor.userName.includes(searchText)
                );
            }
        } else {
            //不可以修改醫生
            return [];
        }
    };

    /**
     * 根據搜尋文字過濾代診醫生選項
     * @return {Array}
     */
    const getFilterLocumDoctorOptions = () => {
        const searchText = record?.locumDoctor || '';
        // 搜尋文字為空
        if (searchText === "" || searchText === null) {
            return baseData.doctorList;
        } else {
            //搜尋文字不為空
            //是否有空白
            const hasSpace = searchText.indexOf(" ") >= 0;
            //分割搜尋文字
            const splitSearchTextArray = searchText.split(" ");

            return baseData.doctorList.filter((doctor) =>
                hasSpace
                    ? doctor.userNo.includes(splitSearchTextArray[0]) &&
                    doctor.userName.includes(splitSearchTextArray[1])
                    : doctor.userNo.includes(searchText) ||
                    doctor.userName.includes(searchText)
            );
        }
    };

    /**
     * 科別搜尋文字改變時
     * @param newValue {String}
     * @return {void}
     */
    const handleDepartmentSearchTextOnChange = (newValue) => {
        //可以修改科別時
        if (canModifyDepartment) {
            //設定科別值
            setRecord({...record, department: newValue});
        }
    };

    /**
     * 科別欄位被點擊時
     * @param {Object|null} option
     */
    const handleDepartmentOptionOnClick = async (option) => {
        // 選項不為空
        if (option !== null) {
            const departmentText = `${option.divNo} ${option.divName}`;
            let sloganText = "";
            if (
                !stringIsEmpty(record.doctor) &&
                record.doctor.split(" ")[0] !== null &&
                !stringIsEmpty(option.divNo)
            ) {
                //取得標語
                sloganText = await getSloganContentForWeekOrClinic(
                    record.doctor.split(" ")[0],
                    option.divNo
                );
            }
            //變更科別時自動變更診間名稱: 科別+診
            const clinicName = `${option.divName}診`
            //設定科別
            setRecord({
                ...record,
                department: departmentText,
                clinicSlogan: sloganText,
                clinicName: clinicName
            })
        } else {
            //設定科別值
            setRecord({...record, department: ''});
        }
    };

    /**
     * 根據搜尋文字過濾科別選項
     */
    const getFilterDepartmentOptions = () => {
        //取得搜尋文字
        const searchText = record?.department || '';

        //搜尋文字為空
        if (searchText === "" || searchText === null) {
            return baseData.divisionList;
        } else {
            //搜尋文字不為空
            //是否有空白
            const hasSpace = searchText.indexOf(" ") >= 0;
            //分割搜尋文字
            const splitSearchTextArray = searchText.split(" ");

            return baseData.divisionList.filter((department) =>
                hasSpace
                    ? department.divNo.includes(splitSearchTextArray[0]) ||
                    department.divName.includes(splitSearchTextArray[1])
                    : department.divNo.includes(searchText) ||
                    department.divName.includes(searchText)
            );
        }
    };

    /**
     * 最大看診人次欄位改變時
     * @param e {Event}
     * @return {void}
     */
    const handleMaxPatientOnChange = (e) => {
        // 取得數值
        const value = parseInt(e.target.value);
        // 數值大於等於0
        if (value >= 0) {
            //設定最大看診人次
            setRecord({...record, maxPatient: value.toString()});
        } else {
            setRecord({...record, maxPatient: ""});
        }
    };

    /**
     * 現場最大看診人次欄位改變時
     * @param e {Event}
     * @return {void}
     */
    const handleOnsiteNumberOnChange = (e) => {
        // 取得數值
        const value = parseInt(e.target.value);
        // 數值大於等於0
        if (value >= 0) {
            //設定現場最大看診人次
            setRecord({...record, onsiteLimit: value.toString()});
        } else {
            setRecord({...record, onsiteLimit: ""});
        }
    };

    /**
     * 看診時間(分/人)欄位改變時
     * @param e {Event}
     * @return {void}
     */
    const handleConsultationTimeOnChange = (e) => {
        // 取得數值
        const value = parseInt(e.target.value);
        // 數值大於等於0
        if (value >= 0) {
            setRecord({...record, consultationTime: value.toString()});
        } else {
            setRecord({...record, consultationTime: ""});
        }
    };

    /**
     * 給號方式欄位改變時
     * @param value {String}
     */
    const handleNumberGivingMethod = (value) =>
        setRecord({...record, numberGivingMethod: value});

    /**
     * 預約方式欄位改變時
     * @param v {Number}
     * @return {void}
     */
    const handleReservationMethodOnChange = (v) =>
        setRecord({...record, reservationMethod: v});

    /**
     * 預約限制欄位改變時
     * @param v {Number}
     * @return {void}
     */
    const handleReservationLimitOnChange = (v) =>
        setRecord({...record, reservationLimit: v});

    /**
     * 診室名稱欄位改變時
     * @param e {Event}
     * @return {void}
     */
    const handleClinicNameOnChange = (e) =>
        setRecord({...record, clinicName: e.target.value});

    /**
     * 診室地點欄位改變時
     * @param e {Event}
     * @return {void}
     */
    const handleClinicLocationOnChange = (e) =>
        setRecord({...record, clinicLocation: e.target.value});

    /**
     * 診別欄位改變時
     * @param value {String}
     * @return {void}
     */
    const handleMedicalTypeOnChange = (value) =>
        setRecord({...record, medicalType: value});

    /**
     * 調病歷欄位改變時
     * @return {void}
     */
    const handleCanGetMedicalRecordsOnChange = () =>
        setRecord({
            ...record,
            canGetMedicalRecords: !record.canGetMedicalRecords,
        });

    /**
     * 收費別欄位改變時
     * @param e {Event}
     * @return {void}
     */
    const handleChargeTypeSearchTextOnChange = (e) =>
        setRecord({...record, chargeType: e?.target?.value || ''});

    /**
     * 根據搜尋文字過濾收費別選項
     * @return {Array}
     */
    const getFilterChargeTypeOptions = () => {
        //取得搜尋文字
        const searchText = record?.chargeType || '';

        //搜尋文字為空
        if (searchText === "") {
            return baseData.cashTypeList;
        } else {
            //搜尋文字不為空
            //是否有空白
            const hasSpace = searchText.indexOf(" ") >= 0;
            //分割搜尋文字
            const splitSearchTextArray = searchText.split(" ");

            return baseData.cashTypeList.filter((cash) =>
                hasSpace
                    ? cash.cashType.includes(splitSearchTextArray[0]) ||
                    cash.cashName.includes(splitSearchTextArray[1])
                    : cash.cashType.includes(searchText) ||
                    cash.cashName.includes(searchText)
            );
        }
    };

    /**
     * 收費別下拉選單被點擊時
     * @param {Object|null} option
     */
    const handleChargeTypeOptionOnClick = (option) => {
        // 選項不為空
        if (option !== null) {
            //設定收費別
            setRecord({
                ...record,
                chargeType: `${option.cashType} ${option.cashName}`,
            });
        }
    };

    /**
     * 網掛/APP欄位改變時
     * @return {void}
     */
    const handleWebOrAppBookingOnChange = () =>
        setRecord({...record, webOrAppBooking: !record.webOrAppBooking});

    /**
     * 網掛/APP取消看診進度欄位改變時
     * @return {void}
     */
    const handleWebOrAppCancelOnChange = () =>
        setRecord({...record, webOrAppCancel: !record.webOrAppCancel});

    /**
     * 網掛預約期限欄位改變時
     * @param e {Event}
     * @return {void}
     */
    const handleOnlineBookingDeadlineOnChange = (e) => {
        // 取得數值
        const value = parseInt(e.target.value);
        // 數值大於等於0
        if (value >= 0) {
            setRecord({...record, onlineBookingDeadline: value});
        }
    };

    /**
     * 注意事項欄位改變時
     * @param e {Event}
     * @return {void}
     */
    const handleNotesOnChange = (e) =>
        setRecord({...record, notes: e.target.value});

    /**
     * 診間標語欄位改變時
     * @param e {Event}
     * @return {void}
     */
    const handleClinicSloganOnChange = (e) =>
        setRecord({...record, clinicSlogan: e.target.value});

    /**
     * 看診項目欄位改變時
     * @param e {Event} 事件
     * @returns
     */
    const handleConsultationItemsOnChange = (value) => {
        setRecord({...record, mediItem: value || ""});
    }

    /**
     * 取得標題的內容
     * @return {String | JSX.Element}
     */
    const getHeaderContent = () => {
        //取得時段
        const timeslot = record.timeslot;
        //取得時段字串
        const timeslotStr =
            timeslot === TimeslotEnum.Morning
                ? t("general.dateTime.timeslot.full.morning")
                : timeslot === TimeslotEnum.Afternoon
                    ? t("general.dateTime.timeslot.full.afternoon")
                    : t("general.dateTime.timeslot.full.night");
        //取得星期幾
        let week = record.week;
        //取得日期
        const date = record.date;
        //新增模式
        if (mode === PopupModeEnum.Add) {
            return `${date} ${t(
                `general.dateTime.weekRange.${week}`
            )}，${timeslotStr}`;
        } else if (mode === PopupModeEnum.Modify) {
            //修改模式
            //科別
            const splitDepartment = record.department.split(" ");
            const department =
                !arrayIsEmpty(splitDepartment) && splitDepartment.length === 2
                    ? splitDepartment[1]
                    : record.department;
            //醫生
            const splitDoctor = record.doctor.split(" ");
            const doctor =
                !arrayIsEmpty(splitDoctor) && splitDoctor.length === 2
                    ? splitDoctor[1]
                    : record.doctor;
            //診室名稱
            const clinicName = record.clinicName;

            if (week === 0) week = 7; //編輯狀態下的week 0代表是星期天........

            return (
                <>
                    {`${date} ${t(
                        `general.dateTime.weekRange.${week}`
                    )}，${timeslotStr}，${department}，`}
                    <span>{`醫師: ${doctor}，診室名稱: ${clinicName}`}</span>
                </>
            );
        }
    };

    /**
     * 儲存或編輯按鈕被點擊時
     */
    const handleSaveOrEditOnClick = async () => {
        //是否為新增模式
        const isAdd = mode === PopupModeEnum.Add;

        //欄位檢核
        if (isAdd) {
            if (record.doctor === null || record.doctor.split(" ").length !== 2) {
                showToast({
                    type: AlertTypeEnum.Error,
                    message: "醫師欄位有誤",
                });
                return;
            } else if (record.doctor.split(" ").length === 2) {
                const fundedDoctor = baseData.doctorList.find(item => item.userNo === record.doctor.split(" ")[0])
                if (objectIsEmpty(fundedDoctor)) {
                    showToast({
                        type: AlertTypeEnum.Error,
                        message: "此醫師不存在",
                    });
                    return;
                }
            }
            if (record.department === null || record.department.split(" ").length !== 2) {
                showToast({
                    type: AlertTypeEnum.Error,
                    message: "科別欄位有誤",
                });
                return;
            } else if (record.department.split(" ").length === 2) {
                const fundedDiv = baseData.divisionList.find(item => item.divNo === record.department.split(" ")[0])
                if (objectIsEmpty(fundedDiv)) {
                    showToast({
                        type: AlertTypeEnum.Error,
                        message: "此科別不存在",
                    });
                    return;
                }
            }
        }

        if (record.chargeType === null || record.chargeType.split(" ").length !== 2) {
            showToast({
                type: AlertTypeEnum.Error,
                message: "收費別欄位有誤",
            });
            return;
        }
        if (stringIsEmpty(record.clinicName)) {
            showToast({
                type: AlertTypeEnum.Error,
                message: "診室名稱有誤",
            });
            return;
        }
        if (stringIsEmpty(record.maxPatient) || parseInt(record.maxPatient) <= 0) {
            showToast({
                type: AlertTypeEnum.Error,
                message: "預約最大看診人次有誤",
            });
            return;
        }
        if (stringIsEmpty(record.onsiteLimit) || parseInt(record.onsiteLimit) <= 0) {
            showToast({
                type: AlertTypeEnum.Error,
                message: "現場最大看診人次有誤",
            });
            return;
        }
        if (stringIsEmpty(record.consultationTime) || parseInt(record.consultationTime) <= 0) {
            showToast({
                type: AlertTypeEnum.Error,
                message: "看診時間有誤",
            });
            return;
        }

        //醫師報備稽核
        const detectInformRet = await doCheckDoctorInform(record)
        if (detectInformRet === null) {
            showToast({
                type: AlertTypeEnum.Error,
                message: "醫師報備稽核錯誤",
            });
            return;
        } else if (detectInformRet.err === ApiErrorStatusEnum.Error) {
            showToast({
                type: AlertTypeEnum.Error,
                message: detectInformRet.msg,
            });
            return;
        } else if (detectInformRet.err === ApiErrorStatusEnum.Success && !stringIsEmpty(detectInformRet.data)) {
            // 顯示詢問dialog
            setDoctorInformDetectMessage(detectInformRet.data)
            setShowDoctorInformDetectMessagePopup(true)
            return;
        }

        // 是否需要檢查醫師重複診間
        const needCheckDuplicateDoctor = mode === PopupModeEnum.Add ? true : !stringIsEmpty(record.locumDoctor)
        if (needCheckDuplicateDoctor) {
            //存檔前確認醫師是否同時段有其它診間，若有則跳出出提示再次確認是否儲存
            const detectDuplicateResult = await doCheckDoctorDuplicateClinic({
                doctorNo: isAdd ? record.doctor.split(" ")[0] : record.locumDoctor.split(" ")[0],
                encounterDate: dayjs(record.date).format("YYYY-MM-DD") + " 00:00:00",
                apn: record.timeslot,
            })

            if (detectDuplicateResult === null) {
                showToast({
                    type: AlertTypeEnum.Error,
                    message: "醫師多診間稽核錯誤",
                });
                return
            } else if (detectDuplicateResult.err === ApiErrorStatusEnum.Error) {
                showToast({
                    type: AlertTypeEnum.Error,
                    message: detectDuplicateResult.msg,
                });
                return
            } else if (detectDuplicateResult.err === ApiErrorStatusEnum.Success && !stringIsEmpty(detectDuplicateResult.data)) {
                setDuplicateDoctorSlot(detectDuplicateResult)
                setShowDuplicateDoctorMessagePopup(true)
                return
            }
        }

        if (isAdd) {
            //新增月班單一診間班表
            saveNewClinic()
        } else {
            //修改模式
            //儲存診間
            saveEditClinic()
        }
    }

    /**
     * 儲存新增診間
     */
    const saveNewClinic = () => {
        regsAddMonthClinic({
            //就醫日期
            encounterDate: `${record.date.replaceAll("/", "-")} 00:00:00`,
            //時段:1.早,2.午,3.晚
            apn: record.timeslot,
            //醫師代號
            doctorNo: record.doctor.split(" ")[0],
            //診室名稱
            clinicName: record.clinicName,
            //診間(診室號)
            //"clinicNo": "0221",
            //科別代號
            divNo: record.department.split(" ")[0],
            //預約最大看診人次
            limit: parseInt(record.maxPatient),
            //現場最大看診人次
            onsiteLimit: parseInt(record.onsiteLimit),
            //看診時間(分/人)
            slotMin: parseInt(record.consultationTime),
            //給號方式:1.診號,2.時間
            giveNumType: `${record.reservationMethod}`,
            //給號代碼
            noType: record.numberGivingMethod,
            //送病歷  Initial value: 0, 0.FALSE, 1.TRUE
            sentChartFlag: record.canGetMedicalRecords,
            //預約限制:A.一般,B.限醫師,C.限醫師本人,D,限授權人員(含醫師本人)
            reserveLimitType: record.reservationLimit,
            //診間類別  O:門診,T:外檢,P,預防保健
            clinicType: record.medicalType,
            ////診間收費別
            cashType: record.chargeType.split(" ")[0],
            ////開放網掛/APP ,Initial value: 0 , 0.FALSE , 1.TRUE
            webapptFlag: record.webOrAppBooking,
            //網掛/App取消看診進度 Initial value: 0 , 0.FALSE , 1.TRUE
            showProcessFlag: record.webOrAppCancel,
            //網掛預約期限(天)
            webapptDay: record.onlineBookingDeadline,
            //週班注意事項
            weekNotice: record.notes,
            //假日展班, 0.FALSE , 1.TRUE
            holidayexclFlag: record.holidayexclFlag,
            //代班醫師
            locumDoctor: null,
            //停診
            stopClinicFlag: false,
            //停診原因
            stopClinicReason: null,
            //停代診公告
            stopClinicBulletinFlag: false,
            //診間標語
            clinicSlogan: record.clinicSlogan,
            nurse1: null,
            nurse2: null,
            nurse3: null,
            nurse4: null,
            nurse5: null,
            nurse6: null,
            nurse7: null,
            //看診項目
            clinicMarkNo:
                record.mediItem === "empty" ? null : record.mediItem,
            //診室地點
            clinicLocation: record.clinicLocation,
            //樂觀鎖
            lockVersion: 0,
        }).then((res) => {
            //新增成功
            if (res.err === ApiErrorStatusEnum.Success) {
                getMonthData();
            } else {
                const msg = `新增失敗: ${res.msg}`;
                showToast({type: AlertTypeEnum.Error, message: msg});
            }
        });
    }

    /**
     *儲存診間編輯
     */
    const saveEditClinic = () => {
        regsEditMonthClinic({
            clinicId: record.clinicId,
            //就醫日期
            encounterDate: `${record.date.replaceAll("/", "-")} 00:00:00`,
            //時段:1.早,2.午,3.晚
            apn: record.timeslot,
            //醫師代號
            doctorNo: record.doctor.split(" ")[0],
            //診室名稱
            clinicName: record.clinicName,
            //診間(診室號)
            clinicNo: record.clinicNo,
            //科別代號
            divNo: record.department.split(" ")[0],
            //預約最大看診人次
            limit: parseInt(record.maxPatient),
            //現場最大看診人次
            onsiteLimit: parseInt(record.onsiteLimit),
            //看診時間(分/人)
            slotMin: parseInt(record.consultationTime),
            //給號方式:1.診號,2.時間
            giveNumType: `${record.reservationMethod}`,
            //給號代碼
            noType: record.numberGivingMethod,
            //送病歷  Initial value: 0, 0.FALSE, 1.TRUE
            sentChartFlag: record.canGetMedicalRecords,
            //預約限制:A.一般,B.限醫師,C.限醫師本人,D,限授權人員(含醫師本人)
            reserveLimitType: record.reservationLimit,
            //診間類別  O:門診,T:外檢,P,預防保健
            clinicType: record.medicalType,
            ////診間收費別
            cashType: record.chargeType.split(" ")[0],
            ////開放網掛/APP ,Initial value: 0 , 0.FALSE , 1.TRUE
            webapptFlag: record.webOrAppBooking,
            //網掛/App取消看診進度 Initial value: 0 , 0.FALSE , 1.TRUE
            showProcessFlag: record.webOrAppCancel,
            //網掛預約期限(天)
            webapptDay: record.onlineBookingDeadline,
            //週班注意事項
            weekNotice: record.notes,
            //看診項目
            clinicMarkNo: record.mediItem,
            //假日展班, 0.FALSE , 1.TRUE
            holidayexclFlag: record.holidayexclFlag,
            //代班醫師
            locumDoctor: !stringIsEmpty(record.locumDoctor)
                ? record.locumDoctor.split(" ")[0]
                : "",
            //停診
            stopClinicFlag: record.stopClinicFlag,
            //停診原因
            stopClinicReason:
                record.stopClinicFlag !== null &&
                record.stopClinicFlag === true
                    ? record.stopClinicReason
                    : null,
            //停代診公告
            stopClinicBulletinFlag: record.stopClinicBulletinFlag,
            //診間標語
            clinicSlogan: record.clinicSlogan,
            nurse1: null,
            nurse2: null,
            nurse3: null,
            nurse4: null,
            nurse5: null,
            nurse6: null,
            nurse7: null,
            //診室地點
            clinicLocation: record.clinicLocation,
            // 建立時間
            createDatetime: record.createDatetime,
            // 建立者
            createUser: record.createUser,
            //修改時間
            modifyDatetime: record.modifyDatetime,
            //修改者
            modifyUser: record.modifyUser,
            //樂觀鎖
            lockVersion: record.lockVersion,
        }).then((res) => {
            //更新成功
            if (res.err === ApiErrorStatusEnum.Success) {
                getMonthData();
            } else {
                const msg = `儲存失敗: ${res.msg}`;
                showToast({type: AlertTypeEnum.Error, message: msg});
            }
        });
    }

    /**
     * 醫師報備稽核
     * @param data 稽核的資料
     * @returns {Promise<null>}
     */
    const doCheckDoctorInform = async (data) => {
        let response = null;
        await regsCheckBeforeSaveClinicByRsis([{
            //診間key
            clinicId: data.clinicId,
            //就醫日期
            encounterDate: `${data.date.replaceAll("/", "-")} 00:00:00`,
            //時段:1.早,2.午,3.晚
            apn: data.timeslot,
            //醫師代號
            doctorNo: data.doctor.split(" ")[0],
            //診室名稱
            clinicName: data.clinicName,
            //診間(診室號)
            clinicNo: data.clinicNo,
            //科別代號
            divNo: data.department.split(" ")[0],
            //預約最大看診人次
            limit: parseInt(data.maxPatient),
            //現場最大看診人次
            onsiteLimit: parseInt(data.onsiteLimit),
            //看診時間(分/人)
            slotMin: parseInt(data.consultationTime),
            //給號方式:1.診號,2.時間
            giveNumType: `${data.reservationMethod}`,
            //給號代碼
            noType: data.numberGivingMethod,
            //送病歷  Initial value: 0, 0.FALSE, 1.TRUE
            sentChartFlag: data.canGetMedicalRecords,
            //預約限制:A.一般,B.限醫師,C.限醫師本人,D,限授權人員(含醫師本人)
            reserveLimitType: data.reservationLimit,
            //診間類別  O:門診,T:外檢,P,預防保健
            clinicType: data.medicalType,
            //診間收費別
            cashType: data.chargeType.split(" ")[0],
            //開放網掛/APP ,Initial value: 0 , 0.FALSE , 1.TRUE
            webapptFlag: data.webOrAppBooking,
            //網掛/App取消看診進度 Initial value: 0 , 0.FALSE , 1.TRUE
            showProcessFlag: data.webOrAppCancel,
            //網掛預約期限(天)
            webapptDay: data.onlineBookingDeadline,
            //週班注意事項
            weekNotice: data.notes,
            //看診項目
            clinicMarkNo: data.mediItem,
            //假日展班, 0.FALSE , 1.TRUE
            holidayexclFlag: data.holidayexclFlag,
            //代班醫師
            locumDoctor: !stringIsEmpty(data.locumDoctor)
                ? data.locumDoctor.split(" ")[0]
                : "",
            //停診
            stopClinicFlag: data.stopClinicFlag,
            //停診原因
            stopClinicReason:
                data.stopClinicFlag !== null &&
                data.stopClinicFlag === true
                    ? data.stopClinicReason
                    : null,
            //停代診公告
            stopClinicBulletinFlag: data.stopClinicBulletinFlag,
            //診間標語
            clinicSlogan: data.clinicSlogan,
            //診室地點
            clinicLocation: data.clinicLocation,
            //周班key，照來源傳入
            weekId: data.weekId,
            // 建立時間
            createDatetime: data.createDatetime,
            createUser: data.createUser,
            modifyDatetime: data.modifyDatetime,
            modifyUser: data.modifyUser,
            lockVersion: data.lockVersion
        }]).then(res => {
            response = res
        })

        return response
    }

    /**
     * 檢查醫師是否同時段有其它診間
     * @param detectData
     * @returns {Promise<null>}
     */
    const doCheckDoctorDuplicateClinic = async (detectData) => {
        let response = null;
        await regsCheckForClinicDocOrLocumDuplicate({
            doctorNo: detectData.doctorNo,
            encounterDate: detectData.encounterDate,
            apn: detectData.apn,
        }).then(res => {
            response = res
        })

        return response
    }

    /**
     * 代理醫生值改變時
     * @param newValue {String}
     * @return {void}
     */
    const handleLocumDoctorOnChange = (newValue) => {
        let haveLocumDoctor = !stringIsEmpty(newValue);
        //代班醫師要連動停代診公告
        setRecord({
            ...record,
            locumDoctor: newValue,
            stopClinicBulletinFlag: haveLocumDoctor,
        });
    };

    /**
     * 選取代理醫師下拉選項時
     * @param item {Object} 選取的選項
     * @return {void}
     */
    const handleLocumDoctorOptionOnClick = (item) => {
        if (objectIsEmpty(item)) return;
        setRecord({
            ...record,
            locumDoctor: `${item.userNo} ${item.userName}`,
            stopClinicBulletinFlag: true,
        });
    };

    /**
     * 取得代理醫生選項
     * @return {Array}
     */
    const getLocumDoctorOptions = () => {
        // 取得當前醫生
        const currentDoctor = record.doctor.split(" ");
        // 取得醫生代號
        const id = currentDoctor[0];
        // 取得醫生名稱
        const name = currentDoctor[1];

        return baseData.doctorList.filter(
            (doctor) => doctor.userNo !== id && doctor.userName !== name
        );
    };

    /**
     * 停診原因勾選變更
     * @return {void}
     */
    const handleStopClinicFlagOnChange = () => {
        let checked = !record.stopClinicFlag;
        let stopClinicReason = record.stopClinicReason;
        if (checked && stringIsEmpty(stopClinicReason)) {
            //勾選停診原因，但停診原因下拉值為空時
            stopClinicReason = baseData.stopClinicReasonList[0].justnameNo;
        } else if (!checked) {
            //取消勾選停診原因時，清空停診原因選擇
            stopClinicReason = "";
        }

        //停診原因要連動停代診公告
        setRecord({
            ...record,
            stopClinicFlag: checked,
            stopClinicBulletinFlag: checked,
            stopClinicReason: stopClinicReason,
        });
    };

    /**
     * 停診原因值改變時
     * @param {String} value
     * @returns
     */
    const handleStopClinicReasonOnChange = (value) => {
        setRecord({...record, stopClinicReason: value});
    };

    /**
     * 停代診公告欄位改變時
     * @return {void}
     */
    const handleStopClinicBulletinFlagOnChange = () =>
        setRecord({
            ...record,
            stopClinicBulletinFlag: !record.stopClinicBulletinFlag,
        });

    /**
     * 二次確認 popup 視窗中的否被點擊時
     * @return {void}
     */
    const handleDuplicateDoctorMessagePopupOnClose = () => {
        setShowDuplicateDoctorMessagePopup(false);
    };

    /**
     * 二次確認 popup 視窗中的是被點擊時
     * @return {void}
     */
    const handleDuplicateDoctorMessagePopupOnSave = () => {

        setShowDuplicateDoctorMessagePopup(false)
        //新增月班單一診間班表
        if (mode === PopupModeEnum.Add) {
            saveNewClinic()
        } else {
            saveEditClinic()
        }
    }

    /**
     * 醫師報備稽核失敗彈窗的否被點擊時
     */
    const handleDoctorInformDetectMessagePopupOnClose = () => {
        setShowDoctorInformDetectMessagePopup(false)
    }

    /**
     * 醫師報備稽核失敗彈窗的是被點擊時
     */
    const handleDoctorInformDetectMessagePopupOnConfirm = async () => {
        setShowDoctorInformDetectMessagePopup(false)

        // 是否需要檢查醫師重複診間
        const needCheckDuplicateDoctor = mode === PopupModeEnum.Add ? true : !stringIsEmpty(record.locumDoctor)
        if (needCheckDuplicateDoctor) {
            //稽核醫師是否同時段有其它診間，若有則跳出出提示再次確認是否儲存
            const detectDuplicateResult = await doCheckDoctorDuplicateClinic({
                doctorNo: mode === PopupModeEnum.Add ? record.doctor.split(" ")[0] : record.locumDoctor.split(" ")[0],
                encounterDate: dayjs(record.date).format("YYYY-MM-DD") + " 00:00:00",
                apn: record.timeslot,
            })

            if (detectDuplicateResult === null) {
                showToast({
                    type: AlertTypeEnum.Error,
                    message: "醫師多診間稽核錯誤",
                });
                return
            } else if (detectDuplicateResult.err === ApiErrorStatusEnum.Error) {
                showToast({
                    type: AlertTypeEnum.Error,
                    message: detectDuplicateResult.msg,
                });
                return
            } else if (detectDuplicateResult.err === ApiErrorStatusEnum.Success && !stringIsEmpty(detectDuplicateResult.data)) {
                setDuplicateDoctorSlot(detectDuplicateResult)
                setShowDuplicateDoctorMessagePopup(true)
                return
            }
        }

        if (mode === PopupModeEnum.Add) {
            //新增月班單一診間班表
            saveNewClinic()
        } else {
            //開始存檔
            saveEditClinic()
        }
    }

    /**
     * 取得診間標語
     * @param {String} doctoorNo
     * @param {String} divNo
     * @returns
     */
    const getSloganContentForWeekOrClinic = async (doctoorNo, divNo) => {
        let sloganText = "";
        await sloganQueryContentForWeekOrClinic({
            //醫師代碼
            doctorNo: doctoorNo,
            //科別代碼
            divNo: divNo,
            //時段
            apn: record.timeslot,
        }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                //成功
                sloganText = stringIsEmpty(res.data) ? "" : res.data;
            } else {
                //失敗時
                showToast({message: res.msg, type: AlertTypeEnum.Error});
            }
        });
        return sloganText;
    };
    /**
     * 取得收費別預設值
     * @returns {*}
     */
    const getDefaultValue = () => {
        const data = getFilterChargeTypeOptions().find(option => record.chargeType.includes(option?.cashName || ''))
        return data || getFilterChargeTypeOptions()[0]
    }

    useEffect(() => {
        // 為新增模式
        if (mode === PopupModeEnum.Add) {
            //為醫生模式且有待醫生資料
            if (filterMode === RegsWeekModeEnum.Doctor && record.doctor) {
                //分割醫生資料
                const splitArray = record.doctor.split(" ");
                //是否有資料
                const hasData =
                    splitArray.length === 2
                        ? baseData.doctorList.findIndex(
                        (doctor) =>
                            doctor.userNo === splitArray[0] &&
                            doctor.userName === splitArray[1]
                    ) !== -1
                        : false;
                //不給予修改
                if (hasData) {
                    setCanModifyDoctor(false);
                }
            } else if (
                filterMode === RegsWeekModeEnum.Department &&
                record.department
            ) {
                //為科別模式且有待科別資料
                //分割科別資料
                const splitArray = record.department.split(" ");
                //是否有資料
                const hasData =
                    splitArray.length === 2
                        ? baseData.divisionList.findIndex(
                        (department) =>
                            department.divNo === splitArray[0] &&
                            department.divName === splitArray[1]
                    ) !== -1
                        : false;
                //不給予修改
                if (hasData) {
                    setCanModifyDepartment(false);

                    const clinicName = `${splitArray[1]}診`
                    //設定診間名稱
                    setRecord((prev) => ({
                        ...prev,
                        clinicName: clinicName
                    }))
                }
            }
        } else if (mode === PopupModeEnum.Modify) {
            // 為修改模式
            // 不給予修改醫生
            setCanModifyDoctor(false);
            // 不給予修改科別
            setCanModifyDepartment(false);
        }
    }, []);

    /**
     * 監聽醫師代碼輸入框變化
     */
    useEffect(() => {
        setDoctorOptionList(getFilterDoctorOptions());
    }, [record.doctor]);
    /**
     * 監聽科別代碼輸入框變化
     */
    useEffect(() => {
        setDepartmentOptionList(getFilterDepartmentOptions());
    }, [record.department]);
    /**
     * 監聽代理醫生輸入框變化
     */
    useEffect(() => {
        setLocumDoctorOptionList(getFilterLocumDoctorOptions());
    }, [record.locumDoctor]);

    /**
     * 監聽收費別是否存在值，如果有，將其轉換為物件並渲染於畫面上
     */
    useEffect(() => {
        // 將字串轉換為物件
        const convertChargeType = (chargeTypeString) => {
            const [cashType, cashName] = chargeTypeString.split(' ');
            return {
                cashType: cashType,
                cashName: cashName
            };
        };
        if (record && record.chargeType) {
            const convertedChargeType = convertChargeType(record.chargeType);
            setChargeTypeValue(convertedChargeType);
        }
    }, [record])

    /**
     * 監聽醫師是否存在值，如果有，將其轉換為物件並渲染於畫面上
     */
    useEffect(() => {
        if (record.doctor === null) {
            return
        }
        const selectedDoctorArray = record?.doctor.split(' ')
        if (selectedDoctorArray.length === 2) {
            setDoctorValue({userNo: selectedDoctorArray[0], userName: selectedDoctorArray[1]})
        } else {
            if (stringIsEmpty(record.doctor)) {
                setDoctorValue(null)
            }
        }
    }, [record.doctor]);

    /**
     * 監聽科別是否存在值，如果有，將其轉換為物件並渲染於畫面上
     */
    useEffect(() => {
        if (record.department === null) {
            return
        }
        const selectedDivArray = record?.department.split(' ')
        if (selectedDivArray.length === 2) {
            setDepartmentValue({divNo: selectedDivArray[0], divName: selectedDivArray[1]})
        } else {
            if (stringIsEmpty(record.department)) {
                setDepartmentValue(null)
            }
        }
    }, [record.department]);

    /**
     * 監聽代班醫師是否存在值，如果有，將其轉換為物件並渲染於畫面上
     */
    useEffect(() => {
        if (record.locumDoctor === null) {
            return
        }
        const selectedLocumDoctorArray = record?.locumDoctor.split(' ')
        if (selectedLocumDoctorArray.length === 2) {
            setLocumDoctorValue({userNo: selectedLocumDoctorArray[0], userName: selectedLocumDoctorArray[1]})
        } else {
            if (stringIsEmpty(record.locumDoctor)) {
                setLocumDoctorValue(null)
            }
        }
    }, [record.locumDoctor])

    return (
        <>
            <Dialog
                open={true}
                title={mode === PopupModeEnum.Add ? "新問診" : "班表進階設定"}
                content={<div className="px-[24px] py-[16px]">
                    <div
                        className={` ${
                            mode === PopupModeEnum.Add
                                ? "grid grid-cols-[240px,calc((100%-240px)/2),calc((100%-240px)/2)]"
                                : "justify-center text-center"
                        } w-full items-center`}
                    >
                        <div className="text-[20px]">
                            {/*取得標題內容*/}
                            {getHeaderContent()}
                        </div>
                        {
                            mode === PopupModeEnum.Add && (
                                <>
                                    {/*醫師搜尋文字以及下拉式選單*/}
                                    <Field labelWidth={80} label={t("general.doctor")} htmlFor="doctor">
                                        {
                                            <Search
                                                freeSolo clearOnBlur disabled={!canModifyDoctor} disablePortal={false}
                                                options={doctorOptionList} value={doctorValue}
                                                getOptionLabel={(option) => `${option?.userNo} ${option?.userName}`}
                                                onChange={(_e, value) => handleDoctorOptionOnClick(value)}
                                                onInputChange={(e, value) => handleDoctorSearchTextOnChange(value)}/>
                                        }
                                    </Field>
                                    {/*科別搜尋文字以及下拉式選單*/}
                                    <Field
                                        label={t("general.department")}
                                        labelWidth={80}
                                        htmlFor="department">
                                        {
                                            <Search
                                                freeSolo clearOnBlur disabled={!canModifyDepartment} disablePortal={false}
                                                options={departmentOptionList} value={departmentValue}
                                                getOptionLabel={(option) => `${option?.divNo} ${option?.divName}`}
                                                onChange={(_e, value) => handleDepartmentOptionOnClick(value)}
                                                onInputChange={(_e, value) => handleDepartmentSearchTextOnChange(value)}/>
                                        }
                                    </Field>
                                </>
                            )
                        }
                    </div>
                    <div className={`grid ${mode === PopupModeEnum.Add ? 'grid-cols-2' : 'grid-cols-3'} mt-3`}>
                        <div className="space-y-2">
                            <Field labelWidth={150} label="預約最大看診人次">
                                <TextField
                                    size={SizeEnum.Small}
                                    type="number"
                                    id="max-people"
                                    value={record.maxPatient}
                                    min={0}
                                    disabled={record.stopClinicFlag}
                                    onChange={(e) =>
                                        handleMaxPatientOnChange(e)
                                    }
                                />
                            </Field>
                            <Field labelWidth={150} label="現場最大看診人次">
                                <TextField
                                    size={SizeEnum.Small}
                                    type="number"
                                    id="current-people-count"
                                    value={record.onsiteLimit}
                                    disabled={record.stopClinicFlag}
                                    min={0}
                                    onChange={(e) =>
                                        handleOnsiteNumberOnChange(e)
                                    }
                                />
                            </Field>
                            <Field labelWidth={150} label="看診時間(分/人)">
                                <TextField
                                    size={SizeEnum.Small}
                                    type="number"
                                    id="time-per-person"
                                    value={record.consultationTime}
                                    min={0}
                                    disabled={record.stopClinicFlag}
                                    onChange={(e) =>
                                        handleConsultationTimeOnChange(e)
                                    }
                                />
                            </Field>
                            <Field labelWidth={150} label="預約方式">
                                <RadioGroup
                                    row size={SizeEnum.Medium}
                                    defaultValue={record.reservationMethod} value={record.reservationMethod}
                                    optionProps={{
                                        options: [
                                            // 科別
                                            {
                                                label: t("Regs.general.viewNumber"),
                                                value: RegsWeekReservationMethodEnum.ClinicNumber,
                                                disabled: record.stopClinicFlag
                                            },
                                            // 醫師
                                            {
                                                label: t("general.time"),
                                                value: RegsWeekReservationMethodEnum.Time,
                                                disabled: record.stopClinicFlag
                                            }
                                        ]
                                    }}
                                    onChange={(e) => handleReservationMethodOnChange(e.target.value)}/>
                            </Field>
                            <Field labelWidth={150} label="給號方式">
                                <Select
                                    data={{
                                        label: "給號方式",
                                        options: baseData?.noTypeList.map(
                                            (option) => ({
                                                label: option.typeName,
                                                value: option.noType,
                                            })
                                        ),
                                    }}
                                    value={record.numberGivingMethod}
                                    disabled={record.stopClinicFlag}
                                    onChange={handleNumberGivingMethod}
                                    showLabel={false}
                                />
                            </Field>
                            <Field labelWidth={150} label="預約限制">
                                <RadioGroup
                                    row size={SizeEnum.Medium}
                                    defaultValue={record.reservationLimit} value={record.reservationLimit}
                                    optionProps={{
                                        options: [
                                            // 一般
                                            {
                                                label: t("general.normal"),
                                                value: RegsWeekReservationLimitEnum.Normal,
                                                disabled: record.stopClinicFlag
                                            },
                                            // 限醫師
                                            {
                                                label: '限醫師',
                                                value: RegsWeekReservationLimitEnum.DoctorOnly,
                                                disabled: record.stopClinicFlag
                                            },
                                            // 限醫師本人
                                            {
                                                label: '限醫師本人',
                                                value: RegsWeekReservationLimitEnum.DoctorSelfOnly,
                                                disabled: record.stopClinicFlag
                                            }
                                        ]
                                    }}
                                    onChange={(e) => handleReservationLimitOnChange(e.target.value)}/>
                            </Field>
                            <Field labelWidth={150} label="診室名稱">
                                <TextField
                                    size={SizeEnum.Small}
                                    id="max-people"
                                    value={record.clinicName}
                                    disabled={record.stopClinicFlag}
                                    onChange={(e) =>
                                        handleClinicNameOnChange(e)
                                    }
                                />
                            </Field>
                            <Field labelWidth={150} label="診室地點">
                                <TextField
                                    size={SizeEnum.Small}
                                    id="max-people"
                                    value={record.clinicLocation}
                                    disabled={record.stopClinicFlag}
                                    onChange={(e) =>
                                        handleClinicLocationOnChange(e)
                                    }
                                />
                            </Field>
                            <Field labelWidth={150} label="診別">
                                <div className="flex">
                                    {/*診別*/}
                                    <Select
                                        id="medical-type"
                                        data={{
                                            label: "診別",
                                            options: [
                                                {
                                                    label: "門診",
                                                    value: RegsMedicalTypeEnum.OutpatientClinic,
                                                },
                                                {
                                                    label: "外檢",
                                                    value: RegsMedicalTypeEnum.MedicalCheckup,
                                                },
                                                {
                                                    label: "預防保健",
                                                    value: RegsMedicalTypeEnum.PreventiveHealthcare,
                                                },
                                            ],
                                        }}
                                        value={record.medicalType}
                                        showLabel={false}
                                        onChange={handleMedicalTypeOnChange}
                                        disabled={record.stopClinicFlag}
                                    />
                                </div>

                            </Field>
                            {/*調病歷*/}
                            <Field labelWidth={150} isShowColon={false}>
                                <Checkbox
                                    disabled={record.stopClinicFlag}
                                    checked={
                                        record.canGetMedicalRecords
                                    }
                                    onChange={
                                        handleCanGetMedicalRecordsOnChange
                                    }
                                    label="調病歷"
                                />
                            </Field>
                        </div>
                        <div className="space-y-2">
                            <Field labelWidth={150} label="收費別">
                                <Search
                                    options={baseData.cashTypeList || []}
                                    getOptionLabel={(cash) => cash ? `${cash.cashType} ${cash.cashName}` : ''}
                                    onChange={(_e, value) => {
                                        handleChargeTypeOptionOnClick(value)
                                        setChargeTypeValue(value)
                                    }}
                                    value={chargeTypeValue}
                                    disabled={record.stopClinicFlag}/>
                            </Field>
                            <Field labelWidth={150} isShowColon={false}>
                                <Checkbox label="網掛/APP"
                                          checked={record.webOrAppBooking}
                                          disabled={record.stopClinicFlag}
                                          onChange={
                                              handleWebOrAppBookingOnChange
                                          }
                                />
                            </Field>
                            <Field labelWidth={150} isShowColon={false}>
                                <Checkbox label="網掛/APP取消看診進度"
                                          checked={record.webOrAppCancel}
                                          disabled={record.stopClinicFlag}
                                          onChange={
                                              handleWebOrAppCancelOnChange
                                          }
                                />
                            </Field>
                            <Field labelWidth={150} label="網掛預約期限">
                                <div className="flex items-center space-x-2">
                                    <TextField
                                        size={SizeEnum.Small}
                                        type="number"
                                        value={record.onlineBookingDeadline}
                                        min={0}
                                        disabled={record.stopClinicFlag}
                                        onChange={(e) =>
                                            handleOnlineBookingDeadlineOnChange(
                                                e
                                            )
                                        }
                                    />
                                    <span>天</span>
                                </div>
                            </Field>
                            {/*注意事項*/}
                            <Field
                                label={"注意事項"} labelWidth={150} htmlFor="notes">
                                <TextArea
                                    id="notes"
                                    defaultValue={record.notes}
                                    value={record.notes}
                                    disabled={record.stopClinicFlag}
                                    onChange={(e) =>
                                        handleNotesOnChange(e)
                                    }
                                />
                            </Field>
                            <Field
                                label={"診間標語"} labelWidth={150} htmlFor="clinicSlogan">
                                <TextArea
                                    id="clinicSlogan"
                                    defaultValue={record.clinicSlogan}

                                    value={record.clinicSlogan}
                                    disabled={record.stopClinicFlag}
                                    onChange={(e) =>
                                        handleClinicSloganOnChange(e)
                                    }
                                />
                            </Field>
                            <Field labelWidth={150} label="看診項目">
                                <Select
                                    data={{
                                        label: "看診項目",
                                        options: [
                                            {
                                                value: "",
                                                label: "請選擇看診項目",
                                            },
                                            ...(baseData?.clinicMarkNoList?.map(
                                                (option) => ({
                                                    value: option.justnameNo,
                                                    label: option.justnameName,
                                                })
                                            ) || []),
                                        ],
                                    }}
                                    value={record.mediItem}
                                    disabled={record.stopClinicFlag}
                                    onChange={
                                        handleConsultationItemsOnChange
                                    }
                                    showLabel={false}
                                />
                            </Field>
                        </div>
                        <div className="space-y-2">

                            {
                                mode === PopupModeEnum.Modify && (
                                    <>
                                        <Field labelWidth={150} label="代班醫師">
                                            {/*代班醫師下拉選單*/}
                                            {
                                                <Search
                                                    freeSolo
                                                    clearOnBlur
                                                    onChange={(_e, value) => {
                                                        handleLocumDoctorOptionOnClick(value)
                                                    }}
                                                    onInputChange={(e, value) => {
                                                        handleLocumDoctorOnChange(value)
                                                    }}
                                                    disablePortal={false}
                                                    options={locumDoctorOptionList}
                                                    showLabel
                                                    getOptionLabel={(option) => `${option?.userNo} ${option?.userName}`}
                                                    disabled={record.stopClinicFlag}
                                                    value={locumDoctorValue}/>}
                                        </Field>
                                        {/*停診原因*/}
                                        <Field
                                            labelWidth={150}
                                            label={
                                                <Checkbox
                                                    label="停診原因"
                                                    checked={record.stopClinicFlag}
                                                    onChange={handleStopClinicFlagOnChange}
                                                    disabled={!stringIsEmpty(record.locumDoctor)}/>
                                            }
                                            sx={{
                                                '& .MuiFormControlLabel-label': {
                                                    marginRight: '-15px' // 微調標籤文字的右邊距
                                                }
                                            }}
                                        >
                                            {/*停診原因下拉選單*/}
                                            <Select
                                                data={{
                                                    label: "停診原因",
                                                    options: [
                                                        {
                                                            label: "-- 請選擇停診原因 --",
                                                            value: "",
                                                        },
                                                        ...(baseData?.stopClinicReasonList?.map(
                                                            (reason) => ({
                                                                label: reason.justnameName,
                                                                value: reason.justnameNo,
                                                            })
                                                        ) || []),
                                                    ],
                                                }}
                                                value={record.stopClinicReason || ""}
                                                disabled={!record.stopClinicFlag}
                                                onChange={handleStopClinicReasonOnChange}
                                                showLabel={false}
                                            />
                                        </Field>

                                        <Field labelWidth={150} isShowColon={false}>
                                            <Checkbox
                                                label="停代診公告"
                                                checked={
                                                    record.stopClinicBulletinFlag
                                                }
                                                onChange={
                                                    handleStopClinicBulletinFlagOnChange
                                                }
                                            />
                                        </Field>
                                    </>
                                )
                            }
                        </div>
                    </div>
                </div>}
                variant={DialogVariant.EDIT}
                paperStyleProps={{width: DialogSizeEnums.LG}}
                onClose={closePopup}
                onSave={handleSaveOrEditOnClick}
                muiDialogContentProps={{dividers: true}}
            />
            {
                /*醫師重複診間詢問*/
                showDuplicateDoctorMessagePopup && duplicateDoctorSlot &&
                <Dialog
                    open={showDuplicateDoctorMessagePopup}
                    title={'提醒'}
                    content={duplicateDoctorSlot?.data}
                    variant={DialogVariant.DECISION}
                    paperStyleProps={{width: DialogSizeEnums.SM}}
                    onClose={handleDuplicateDoctorMessagePopupOnClose}
                    onConfirm={handleDuplicateDoctorMessagePopupOnSave}
                    muiDialogContentProps={{sx: {padding: '4rem 1.5rem 4rem 1.5rem'}}}
                />
            }
            {
                /*醫師報備詢問*/
                showDoctorInformDetectMessagePopup &&
                <Dialog
                    open={showDoctorInformDetectMessagePopup}
                    title={'提醒'}
                    content={doctorInformDetectMessage}
                    variant={DialogVariant.DECISION}
                    paperStyleProps={{width: DialogSizeEnums.SM}}
                    onClose={handleDoctorInformDetectMessagePopupOnClose}
                    onConfirm={handleDoctorInformDetectMessagePopupOnConfirm}
                    muiDialogContentProps={{sx: {padding: '4rem 1.5rem 4rem 1.5rem'}}}
                />
            }
        </>
    );
};

export default AppointmentPopup;
