import React, {useState} from 'react';
import {BaseInput} from "../../Input/BaseInput";
import {InputTypeEnum} from "edah_utils/dist";
import {t} from "i18next"
import {
    Field,
    SizeEnum,
    TextArea,
    Button,
    ButtonColorEnum,
    ButtonVariantEnum,
    ButtonSizeEnum,
    Select,
    SelectWidthEnum,
    DatePicker,
    Divider,
    Checkbox,
    TextField, GrayColorEnum
} from "edah-component/dist";
import dayjs from "dayjs";

/**
 * 基本評估
 * @return {JSX.Element}
 */
const BasicAssessment = () => {

    // 等級選項
    const levelOptions = [
        {value: '0', label: '低'},
        {value: '1', label: '中'},
        {value: '2', label: '高'},
    ]

    // 感官功能選項
    const senseOptions = [
        {value: "0", label: "R&L"},
        {value: "1", label: "R"},
        {value: "2", label: "L"}
    ]

    //同上變數：主要聯絡人
    const [mainContact, setMainContact] = useState({
        name: '',
        relation: '',
        phone: '',
        education: '',
        occupation: '',
        lang: ''
    });

    //同上變數：主要照顧者
    const [primaryCaregiver, setPrimaryCaregiver] = useState({
        name: '',
        relation: '',
        phone: '',
        education: '',
        occupation: '',
        lang: ''
    });

    //殘障手冊等級
    const [disabilityLevel, setDisabilityLevel] = useState({
        // 第一筆資料
        first: '0',
        // 第二筆資料
        second: '0',
    });

    /**
     * 昏迷指數
     */
    const [drowsiness, setDrowsiness] = useState({
        // E
        E: "0",
        // M
        M: "0",
        // V
        V: "0"
    })

    // 視力
    const [vision, setVision] = useState({
        // 正常
        normal: "0",
        // 缺陷
        defect: "0",
        // 輔助物
        supportTool: "0"
    })

    // 聽力
    const [hearing, setHearing] = useState({
        // 正常
        normal: "0",
        // 缺陷
        defect: "0",
        // 輔助物
        supportTool: "0"
    })

    // 口腔
    const [oralHealth, setOralHealth] = useState({
        // 正常
        normal: "0",
        // 缺陷
        defect: "0",
        // 輔助物
        supportTool: "0"
    })

    /**
     * 同上
     * @return void
     */
    const handleSameAsAbove = () => {
        setPrimaryCaregiver({...mainContact});
        console.log(primaryCaregiver)
    };

    /**
     * 資料輸入
     * @param e
     * @param setFunc {Function}
     * @param key
     * @return void
     */
    const handleInputChange = (e, setFunc, key) => {
        setFunc(prevState => ({...prevState, [key]: e.target.value}));
    };

    return (
        <div className="h-[calc(100vh-175px)] overflow-y-auto p-6 bg-white space-y-4">
            <div className="flex justify-end items-center">
                <div className="flex flex-row gap-2">
                    <Button color={ButtonColorEnum.Secondary} variant={ButtonVariantEnum.Outlined}
                            size={ButtonSizeEnum.Medium} text={'結案'}/>
                    <Button color={ButtonColorEnum.Secondary} variant={ButtonVariantEnum.Outlined}
                            size={ButtonSizeEnum.Medium} text={'看診資料查詢'}/>
                    <Button color={ButtonColorEnum.Secondary} variant={ButtonVariantEnum.Outlined}
                            size={ButtonSizeEnum.Medium} text={t('general.print')}/>
                    <Button color={ButtonColorEnum.Primary} variant={ButtonVariantEnum.Contained}
                            size={ButtonSizeEnum.Medium} text={t('general.saveFile')}/>
                </div>
            </div>
            <Divider/>
            <div className="space-y-4 overflow-x-auto">
                <p className="w-full text-right">級別：Ａ</p>
                <div className="flex flex-row items-center justify-start mb-4">
                    <Field label="個案姓名">黃ＯＯ</Field>
                    <Field label="病歷號">numbers</Field>
                    <Field label="身分證號">E123456789</Field>
                    <Field label="性別">男</Field>
                </div>
                <div className="flex flex-row items-center justify-start mb-4">
                    <Field label="家字號碼">A009484738</Field>
                    <Field label="出生日期">2023/09/01</Field>
                    <Field label="電話">0987888000 / 072235500</Field>
                    <Field label="地址">高雄市燕巢區義大路1號</Field>
                </div>
            </div>
            <Divider/>
            {/*主要聯絡人*/}
            <div className="pt-4 space-y-4">
                <div className="flex flex-row items-center justify-start">
                    <Field label="主要聯絡人">
                        <TextField size={SizeEnum.Small} value={mainContact.name}
                                   onChange={e => handleInputChange(e, setMainContact, 'name')}/>
                    </Field>
                    <Field label="關係">
                        <Select
                            width={SelectWidthEnum.Medium}
                            data={{
                                label: '關係',
                                options: [
                                    {value: '0', label: ''},
                                ]
                            }}
                            value={mainContact.relation}
                            showLabel={false}
                            onChange={(newValue) => handleInputChange({target: {value: newValue}}, setMainContact, 'relation')}/>
                    </Field>
                    <Field label="電話">
                        <TextField size={SizeEnum.Small} value={mainContact.phone}
                                   onChange={e => handleInputChange(e, setMainContact, 'phone')}/>
                    </Field>
                </div>
                <div className="flex flex-row items-center justify-start space-y-4">
                    <Field label="教育程度">
                        <Select
                            width={SelectWidthEnum.Medium}
                            data={{
                                label: '教育程度',
                                options: [
                                    {value: '0', label: ''},
                                ]
                            }}
                            value={mainContact.education}
                            showLabel={false}
                            onChange={(newValue) => handleInputChange({target: {value: newValue}}, setMainContact, 'education')}/>

                    </Field>
                    <Field label="職業">
                        <Select
                            width={SelectWidthEnum.Medium}
                            data={{
                                label: '職業',
                                options: [
                                    {value: '0', label: ''},
                                ]
                            }}
                            value={mainContact.occupation}
                            showLabel={false}
                            onChange={(newValue) => handleInputChange({target: {value: newValue}}, setMainContact, 'occupation')}/>

                    </Field>
                    <Field label="溝通語言">
                        <Select
                            width={SelectWidthEnum.Medium}
                            data={{
                                label: '溝通語言',
                                options: [
                                    {value: '0', label: ''},
                                ]
                            }}
                            value={mainContact.lang}
                            showLabel={false}
                            displayEmpty={true}
                            onChange={(newValue) => handleInputChange({target: {value: newValue}}, setMainContact, 'lang')}/>
                    </Field>

                </div>
            </div>
            <Divider/>
            {/*主要照顧者*/}
            <div className="space-y-4 pt-4">
                <div className="flex flex-row items-center justify-start">
                    <Field label="主要照顧者">
                        <TextField size={SizeEnum.Small} value={primaryCaregiver.name}
                                   onChange={e => handleInputChange(e, setPrimaryCaregiver, 'name')}/>
                    </Field>
                    <Field label="關係">
                        <Select
                            width={SelectWidthEnum.Medium}
                            data={{
                                label: '關係',
                                options: [
                                    {value: '0', label: ''},
                                ]
                            }}
                            value={primaryCaregiver.relation}
                            showLabel={false}
                            onChange={(newValue) => handleInputChange({target: {value: newValue}}, setPrimaryCaregiver, 'relation')}/>
                    </Field>
                    <Field label="電話">
                        <TextField size={SizeEnum.Small} value={primaryCaregiver.phone}
                                   onChange={e => handleInputChange(e, setPrimaryCaregiver, 'phone')}/>
                    </Field>
                    {/*同上按鈕*/}
                    <Button color={ButtonColorEnum.Secondary} variant={ButtonVariantEnum.Outlined}
                            size={ButtonSizeEnum.Medium} text={'同上'} onClick={handleSameAsAbove}/>
                </div>
                <div className="flex flex-row items-center justify-start">
                    <Field label="教育程度">
                        <Select
                            width={SelectWidthEnum.Medium}
                            data={{
                                label: '教育程度',
                                options: [
                                    {value: '0', label: ''},
                                ]
                            }}
                            value={primaryCaregiver.education}
                            showLabel={false}
                            onChange={(newValue) => handleInputChange({target: {value: newValue}}, setPrimaryCaregiver, 'education')}/>
                    </Field>
                    <Field label="職業">
                        <Select
                            width={SelectWidthEnum.Medium}
                            data={{
                                label: '職業',
                                options: [
                                    {value: '0', label: ''},
                                ]
                            }}
                            value={primaryCaregiver.occupation}
                            showLabel={false}
                            onChange={(newValue) => handleInputChange({target: {value: newValue}}, setPrimaryCaregiver, 'occupation')}/>
                    </Field>
                    <Field label="溝通語言">
                        <Select
                            width={SelectWidthEnum.Medium}
                            data={{
                                label: '溝通語言',
                                options: [
                                    {value: '0', label: ''},
                                ]
                            }}
                            value={primaryCaregiver.lang}
                            showLabel={false}
                            onChange={(newValue) => handleInputChange({target: {value: newValue}}, setPrimaryCaregiver, 'lang')}/>
                    </Field>
                </div>
            </div>
            <Divider/>
            <div className="space-y-4 pt-4">
                <Field label="疾病主診斷">
                    <div className="flex items-center space-x-2">
                        <TextField placeholder="C50.919"/>
                        <p>女性乳房未明示部位惡性腫瘤</p>
                    </div>
                </Field>
                <div className="flex flex-row items-center justify-start">
                    <Field label="次要診斷">
                        <div className="flex items-center space-x-2">
                            <p>1</p>
                            <TextField size={SizeEnum.Small}/>

                            <p>2</p>
                            <TextField size={SizeEnum.Small}/>

                            <p>3</p>
                            <TextField size={SizeEnum.Small}/>

                            <p>4</p>
                            <TextField size={SizeEnum.Small}/>
                        </div>
                    </Field>
                </div>
                <div className="flex flex-row items-center justify-start">
                    <Field label="重大傷病卡號" childrenSx={{display: "flex"}}>
                        <p>1.C50.919</p>
                        <Field label="有效日期">
                            <p>2024/09/09</p>
                        </Field>
                        <p>2.</p>
                        <Field label="有效日期" isShowColon={false}>
                            <p>2024/09/09</p>
                        </Field>
                    </Field>
                </div>
                <div className="flex flex-row items-center justify-start">
                    <Field sx={{width: '30%'}} label="殘障手冊"> 1.第七類</Field>
                    <Field childrenSx={{display: "flex", alignItems: 'center'}} label="等級">
                        <Select
                            width={SelectWidthEnum.Small}
                            data={{
                                label: '等級',
                                options: levelOptions.map(option => ({
                                    value: option.value,
                                    label: option.label
                                }))
                            }}
                            value={disabilityLevel.first}
                            showLabel={false}
                            onChange={(newValue) => handleInputChange({target: {value: newValue}}, setDisabilityLevel, 'first')}/>
                        <Field sx={{width: '30%'}} label="有效日期">2024/09/09</Field>
                        <Button color={ButtonColorEnum.Secondary} variant={ButtonVariantEnum.Outlined}
                                size={ButtonSizeEnum.Medium} text={'更新重大傷病，殘障手冊'}
                        />
                    </Field>
                </div>
                <div className="flex flex-row items-center justify-start mb-4">
                    <Field sx={{width: '30%'}} label="殘障手冊"> 2.</Field>
                    <Field childrenSx={{display: "flex", alignItems: 'center'}} label="等級">
                        <Select
                            width={SelectWidthEnum.Small}
                            data={{
                                label: '等級',
                                options: levelOptions.map(option => ({
                                    value: option.value,
                                    label: option.label
                                }))
                            }}
                            value={disabilityLevel.second}
                            showLabel={false}
                            onChange={(newValue) => handleInputChange({target: {value: newValue}}, setDisabilityLevel, 'second')}/>
                        <Field sx={{width: '30%'}} label="有效日期">{''}</Field>
                    </Field>
                </div>
            </div>
            <Divider/>
            <div className="space-y-4">
                <Field childrenSx={{display: "flex", alignItems: 'center'}} label="轉介來源">
                    <input type="radio" id="source1" name="source"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="source1">住院</label>
                    <input type="radio" id="source2" name="source"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="source2">門診</label>
                    <input type="radio" id="source3" name="source"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="source3">外院轉介</label>
                    <input type="radio" id="source4" name="source"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="source4">自行聯絡</label>
                    <input type="radio" id="source5" name="source"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="source5">長照中心</label>
                    <input type="radio" id="source6" name="source"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-2" htmlFor="source6">其他</label>
                    <TextField size={SizeEnum.Small}/>
                </Field>

                <div className="flex flex-row items-center justify-start">
                    <Field sx={{width: "40%"}} label="科別">
                        <Select
                            width={SelectWidthEnum.Medium}
                            data={{
                                label: '科別',
                                options: [
                                    {value: "0", label: ''}
                                ]
                            }}
                            value="0"
                            showLabel={false}
                            displayEmpty={true}/>
                    </Field>
                    <Field label="轉介人">
                        <TextField size={SizeEnum.Small}/>
                    </Field>
                </div>
            </div>
            <Divider/>
            <div className="pt-4 space-y-4">
                <Field label="收案日期">
                    <DatePicker/>
                </Field>
                <Field label="收案原因">
                    <div className="flex items-center">
                        <Checkbox label="一般照護"/>
                        <Checkbox label="留置鼻胃管"/>
                        <Checkbox label="留置尿管"/>
                        <Checkbox label="氣切更換"/>
                        <Checkbox label="呼吸器"/>
                        <Checkbox label="傷口照護"/>
                        <Checkbox label="造口護理"/>
                        <Checkbox label="檢體收集"/>
                    </div>
                    <div className="flex flex-row items-center justify-start">
                        <Checkbox label="其他"/>
                        <TextField size={SizeEnum.Small}
                                   placeholder={'安寧居家ECG04級'}/>
                    </div>
                </Field>
                <Field childrenSx={{display: 'flex', alignItems: 'center'}} label="收案時ADL評分">
                    <p className="mr-1">柯氏</p>
                    <TextField size={SizeEnum.Small} inputWidth={SizeEnum.Small}/>
                    <p className="mr-4">級</p>
                    <p className="mr-1">巴氏</p>
                    <TextField size={SizeEnum.Small} inputWidth={SizeEnum.Small}/>
                    <p className="mr-4">級</p>
                </Field>
                <Field label="收案居家護理師">
                    <Select
                        width={SelectWidthEnum.Medium}
                        data={{
                            label: '收案居家護理師',
                            options: [{value: "0", label: ""}],
                        }}
                        value="0"
                        showLabel={false}/>
                </Field>
                <Field label="立案居家護理師">廖怡娟</Field>
            </div>
            <Divider/>
            <div className="space-y-4 pt-4">
                {/*結案日期*/}
                <Field label={"結案日期"}>
                    <DatePicker/>
                </Field>
                <Field sx={{alignItems: 'start'}} label="結案原因">
                    <div className="flex mb-2">
                        <input type="radio" id="close1" name="closeCase"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="close1">問題解決</label>
                        <input type="radio" id="close2" name="closeCase"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="close2">家屬已可自理</label>
                        <input type="radio" id="close3" name="closeCase"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="close3">住護理之家/安養中心</label>
                        <input type="radio" id="close4" name="closeCase"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="close4">住院</label>
                        <input type="radio" id="close5" name="closeCase"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="close5">死亡</label>
                        <input type="radio" id="close6" name="closeCase"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="close6">遷出照護區</label>
                    </div>
                    <div className="flex">
                        <input type="radio" id="close7" name="closeCase"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="close7">其他</label>
                        <TextField size={SizeEnum.Small}/>
                    </div>
                </Field>
                <Field childrenSx={{display: 'flex', alignItems: 'center'}} label="結案時ADL評分">
                    <p className="mr-1">柯氏</p>
                    <TextField size={SizeEnum.Small} inputWidth={SizeEnum.Small}/>
                    <p className="mr-4">級</p>
                    <p className="mr-1">巴氏</p>
                    <TextField size={SizeEnum.Small} inputWidth={SizeEnum.Small}/>
                    <p className="mr-4">級</p>
                </Field>
                <Field sx={{alignItems: 'start'}} label={"結案摘要"}>
                    <TextArea inputSize={SizeEnum.Fill}/>
                </Field>
                <Field label="結案居家護理師">廖怡娟</Field>
            </div>
            <Divider/>
            <div className="pt-4 space-y-4">
                <Field label="1.意識狀況">
                    <input type="radio" id="consciousness1" name="consciousness"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="consciousness1">清醒警覺</label>
                    <input type="radio" id="consciousness2" name="consciousness"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="consciousness2">人時地混淆</label>
                    <input type="radio" id="consciousness3" name="consciousness"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="consciousness3">木檯/</label>
                    <input type="radio" id="consciousness4" name="consciousness"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="consciousness4">昏迷</label>
                    <input type="radio" id="consciousness5" name="consciousness"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="consciousness5">躁動</label>
                    <input type="radio" id="consciousness6" name="consciousness"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-2" htmlFor="consciousness6">瞻妄</label>
                    <input type="radio" id="consciousness7" name="consciousness"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-2" htmlFor="consciousness7">植物人狀態</label>
                </Field>
                <Field label="昏迷指數">
                    <div className="flex items-center space-x-2">
                        <p>E</p>
                        <Select
                            width={SelectWidthEnum.Small}
                            data={{
                                label: "E",
                                options: [{value: "0", label: ""}]
                            }}
                            value={drowsiness.E}
                            showLabel={false}/>
                        <p>M</p>
                        <Select
                            width={SelectWidthEnum.Small}
                            data={{
                                label: "M",
                                options: [{value: "0", label: ""}]
                            }}
                            value={drowsiness.M}
                            showLabel={false}/>
                        <p>V</p>
                        <Select
                            width={SelectWidthEnum.Small}
                            data={{
                                label: "V",
                                options: [{value: "0", label: ""}]
                            }}
                            value={drowsiness.V}
                            showLabel={false}/>
                    </div>
                </Field>
                <Field childrenSx={{display: 'flex', alignItems: 'center'}} label="2.呼吸狀況">
                    <Checkbox label="正常"/>
                    <Checkbox label="端坐"/>
                    <Checkbox label="氧氣使用"/>
                    <Checkbox label="氣切套管"/>
                    <Checkbox label="其他"/>
                    <TextField size={SizeEnum.Small}/>
                </Field>
                <Field sx={{alignItems: 'start'}} childrenSx={{mt: 2}} label="3.感官功能">
                    <></>
                </Field>

                {/*視力*/}
                <Field label="視力">
                    <div className="flex space-x-2">
                        <div className="flex items-center space-x-1">
                            <Checkbox labelSx={{marginRight: 0}} label="正常"/>
                            <Select
                                width={SelectWidthEnum.Small}
                                data={{
                                    label: '正常',
                                    options: senseOptions.map((option) => ({
                                        value: option.value,
                                        label: option.label
                                    }))
                                }}
                                value={vision.normal}
                                showLabel={false}
                                onChange={(newValue) => handleInputChange({target: {value: newValue}}, setVision, "normal")}/>
                        </div>
                        <div className="flex items-center space-x-1">
                            <Checkbox labelSx={{marginRight: 0}} label="缺陷"/>
                            <Select
                                width={SelectWidthEnum.Small}
                                data={{
                                    label: '缺陷',
                                    options: senseOptions.map((option) => ({
                                        value: option.value,
                                        label: option.label
                                    }))
                                }}
                                value={vision.defect}
                                showLabel={false}
                                onChange={(newValue) => handleInputChange({target: {value: newValue}}, setVision, "defect")}/>
                        </div>
                        <div className="flex items-center space-x-1">
                            <Checkbox labelSx={{marginRight: 0}} label="輔助物"/>
                            <Select
                                width={SelectWidthEnum.Small}
                                data={{
                                    label: '輔助物',
                                    options: senseOptions.map((option) => ({
                                        value: option.value,
                                        label: option.label
                                    }))
                                }}
                                value={vision.supportTool}
                                showLabel={false}
                                onChange={(newValue) => handleInputChange({target: {value: newValue}}, setVision, "supportTool")}/>
                            <TextField size={SizeEnum.Small}/>
                        </div>
                        <div className="flex items-center space-x-1">
                            <Checkbox labelSx={{marginRight: 0}} label="其他"/>
                            <TextField size={SizeEnum.Small}/>
                        </div>
                    </div>
                </Field>
                {/*聽力*/}
                <Field label="聽力">
                    <div className="flex space-x-2">
                        <div className="flex items-center space-x-1">
                            <Checkbox labelSx={{marginRight: 0}} label="正常"/>
                            <Select
                                width={SelectWidthEnum.Small}
                                data={{
                                    label: '正常',
                                    options: senseOptions.map((option) => ({
                                        value: option.value,
                                        label: option.label
                                    }))
                                }}
                                value={hearing.normal}
                                showLabel={false}
                                onChange={(newValue) => handleInputChange({target: {value: newValue}}, setHearing, "normal")}/>
                        </div>
                        <div className="flex items-center space-x-1">
                            <Checkbox labelSx={{marginRight: 0}} label="缺陷"/>
                            <Select
                                width={SelectWidthEnum.Small}
                                data={{
                                    label: '缺陷',
                                    options: senseOptions.map((option) => ({
                                        value: option.value,
                                        label: option.label
                                    }))
                                }}
                                value={hearing.defect}
                                showLabel={false}
                                onChange={(newValue) => handleInputChange({target: {value: newValue}}, setHearing, "defect")}/>
                        </div>
                        <div className="flex items-center space-x-1">
                            <Checkbox labelSx={{marginRight: 0}} label="輔助物"/>
                            <Select
                                width={SelectWidthEnum.Small}
                                data={{
                                    label: '輔助物',
                                    options: senseOptions.map((option) => ({
                                        value: option.value,
                                        label: option.label
                                    }))
                                }}
                                value={hearing.supportTool}
                                showLabel={false}
                                onChange={(newValue) => handleInputChange({target: {value: newValue}}, setHearing, "supportTool")}/>
                            <TextField size={SizeEnum.Small}/>
                        </div>
                        <div className="flex items-center space-x-1">
                            <Checkbox labelSx={{marginRight: 0}} label="其他"/>
                            <TextField size={SizeEnum.Small}/>
                        </div>
                    </div>
                </Field>
                {/*口腔*/}
                <Field label="口腔">
                    <div className="flex space-x-2">
                        <div className="flex space-x-2">
                            <Checkbox labelSx={{marginRight: 0}} label="正常"/>
                            <Select
                                width={SelectWidthEnum.Small}
                                data={{
                                    label: '正常',
                                    options: senseOptions.map((option) => ({
                                        value: option.value,
                                        label: option.label
                                    }))
                                }}
                                value={oralHealth.normal}
                                showLabel={false}
                                onChange={(newValue) => handleInputChange({target: {value: newValue}}, setOralHealth, "normal")}/>
                        </div>
                        <div className="flex space-x-2">
                            <Checkbox labelSx={{marginRight: 0}} label="缺陷"/>
                            <Select
                                width={SelectWidthEnum.Small}
                                data={{
                                    label: '缺陷',
                                    options: senseOptions.map((option) => ({
                                        value: option.value,
                                        label: option.label
                                    }))
                                }}
                                value={hearing.defect}
                                showLabel={false}
                                onChange={(newValue) => handleInputChange({target: {value: newValue}}, setHearing, "defect")}/>
                        </div>
                        <div className="flex space-x-2">
                            <Checkbox labelSx={{marginRight: 0}} label="輔助物"/>
                            <TextField size={SizeEnum.Small}/>
                        </div>
                        <div className="flex space-x-2">
                            <Checkbox labelSx={{marginRight: 0}} label="其他"/>
                            <TextField size={SizeEnum.Small}/>
                        </div>
                    </div>
                </Field>
                {/*語言*/}
                <Field label="語言">
                    <div className="flex items-center space-x-2">
                        <input type="radio" id="lang1" name="language"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="lang1">正常</label>
                        <input type="radio" id="lang2" name="language"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="lang2">缺陷</label>
                        <input type="radio" id="lang3" name="language"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="lang3">其他</label>
                        <TextField size={SizeEnum.Small}/>
                    </div>
                </Field>
                {/*4.營養/飲食*/}
                <Field sx={{display: 'flex', alignItems: 'start'}} childrenSx={{mt: 4, gap: 2}} label="4.營養/飲食">
                    {/*空行*/}
                    <></>
                </Field>
                {/*體重*/}
                <Field childrenSx={{display: 'flex', alignItems: 'center'}} label="體重">
                    <input type="radio" id="weight1" name="weight"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="weight1">過重</label>
                    <input type="radio" id="weight2" name="weight"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="weight2">正常</label>
                    <input type="radio" id="weight3" name="weight"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="weight3">過輕</label>
                </Field>
                {/*每日攝取*/}
                <Field childrenSx={{display: 'flex', alignItems: 'center'}} label="每日攝取">
                    <input type="radio" id="daily1" name="daily"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="daily1">過多</label>
                    <input type="radio" id="daily2" name="daily"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="daily2">適當</label>
                    <input type="radio" id="daily3" name="daily"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="daily3">不足</label>
                </Field>
                {/*飲食限制*/}
                <Field childrenSx={{display: 'flex', alignItems: 'center'}} label="飲食限制">
                    <input type="radio" id="diet1" name="diet"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="diet1">是</label>
                    <input type="radio" id="diet2" name="diet"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="diet2">否</label>
                </Field>
                <Field childrenSx={{display: 'flex', alignItems: 'center'}} label="進食方式及種類">
                    {/*空行*/}
                    <></>
                </Field>
                {/*由口*/}
                <Field childrenSx={{display: 'flex', alignItems: 'center'}} label="由口">
                    <div className="flex space-x-2">
                        <Checkbox label="普通飲食"/>
                        <Checkbox label="軟質"/>
                        <Checkbox label="流質"/>
                        <Checkbox label="營養補充品"/>
                    </div>
                    <div
                        className={`bg-[${GrayColorEnum.Gray100}] p-2 flex flex-row items-center justify-center rounded-[6px]`}>
                        <Checkbox label="素食"/>
                        <input type="radio" id="vegetable1" name="vegetable"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="vegetable1">早素</label>
                        <input type="radio" id="vegetable2" name="vegetable"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="vegetable2">全素</label>
                        <input type="radio" id="vegetable2" name="vegetable"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label htmlFor="vegetable2">其他</label>
                    </div>
                </Field>
                {/*管灌*/}
                <Field childrenSx={{display: 'flex', alignItems: 'center'}} label="管灌">
                    <input type="radio" id="stomach1" name="stomach"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="stomach1">胃管</label>
                    <input type="radio" id="stomach2" name="stomach"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="stomach2">胃造瘻</label>
                    <input type="radio" id="stomach3" name="stomach"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="stomach3">腸造瘻</label>
                    <input type="radio" id="stomach4" name="stomach"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="stomach4">其他</label>
                    <TextField size={SizeEnum.Small}/>
                </Field>
                {/*排尿功能*/}
                <Field childrenSx={{display: 'flex', alignItems: 'center'}} label="5.排尿功能">
                    <div
                        className={`bg-[${GrayColorEnum.Gray100}] ml-1 p-2 flex flex-row items-center justify-center rounded-[6px]`}>
                        <input type="radio" id="pee1" name="peepee"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="pee1">正常</label>
                        <input type="radio" id="pee2" name="peepee"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="pee2">頻尿</label>
                        <input type="radio" id="pee3" name="peepee"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="pee3">失禁</label>
                        <input type="radio" id="pee4" name="peepee"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="pee4">少尿</label>
                        <input type="radio" id="pee4" name="peepee"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="pee4">導尿</label>
                    </div>
                    <div
                        className={`bg-[${GrayColorEnum.Gray100}] ml-1 p-2 flex flex-row items-center justify-center rounded-[6px]`}>
                        <input type="radio" id="pee5" name="peepee2"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="pee5">間歇導尿</label>
                        <input type="radio" id="pee6" name="peepee2"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="pee6">膀胱造瘻</label>
                        <input type="radio" id="pee7" name="peepee2"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="pee7">導尿留置</label>
                    </div>
                </Field>
                {/*排便功能*/}
                <Field sx={{display: 'flex', alignItems: 'start'}} childrenSx={{mt: 4}} label="6.排便功能">
                    {/*空行*/}
                    <></>
                </Field>
                {/*型態*/}
                <Field childrenSx={{display: 'flex', alignItems: 'center'}} label="型態">
                    <input type="radio" id="type1" name="type"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="type1">正常</label>
                    <input type="radio" id="type2" name="type"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="type2">便秘</label>
                    <input type="radio" id="type3" name="type"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="type3">腹瀉</label>
                </Field>
                {/*途徑*/}
                <Field childrenSx={{display: 'flex', alignItems: 'center'}} label="途徑">
                    <div
                        className={`bg-[${GrayColorEnum.Gray100}] p-2 flex items-center justify-center rounded-[6px]`}>
                        <input type="radio" id="w1" name="way"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="w1">肛門</label>
                        <input type="radio" id="w2" name="way"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="w2">造瘻</label>
                    </div>
                    <div
                        className={`bg-[${GrayColorEnum.Gray100}] ml-1 p-2 flex items-center justify-center rounded-[6px]`}>
                        <input type="radio" id="w3" name="way2"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="w3">迴腸</label>
                        <input type="radio" id="w4" name="way2"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="w4">空腸</label>
                        <input type="radio" id="w5" name="way2"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="w5">結腸</label>
                    </div>
                </Field>
                {/*輔助方式*/}
                <Field childrenSx={{display: 'flex'}} label="輔助方式">
                    <div
                        className={`bg-[${GrayColorEnum.Gray100}] p-2 flex items-center justify-center rounded-[6px]`}>
                        <input type="radio" id="s1" name="support"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="s1">軟便劑</label>
                        <input type="radio" id="s2" name="support"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="s2">塞劑</label>
                        <input type="radio" id="s3" name="support"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="s3">灌腸</label>
                        <input type="radio" id="s4" name="support"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="s4">糞崁塞處理</label>
                    </div>
                    <div
                        className={`bg-[${GrayColorEnum.Gray100}] ml-1 p-2 flex items-center justify-center rounded-[6px]`}>
                        <input type="radio" id="s5" name="support2"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="s5">每次</label>
                        <input type="radio" id="s6" name="support2"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="s6">偶爾</label>
                    </div>
                </Field>
                {/*皮膚狀況*/}
                <Field childrenSx={{display: 'flex', alignItems: 'start'}} label="7.皮膚狀況">
                    <input type="radio" id="skin1" name="skin"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="skin1">完整</label>
                    <input type="radio" id="skin2" name="skin"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="skin2">不完整</label>
                </Field>
                {/*傷口1*/}
                <Field childrenSx={{display: 'flex', alignItems: 'center'}} label="傷口1">
                    <div className="mr-1">部位</div>
                    <Select
                        width={SelectWidthEnum.Small}
                        data={{
                            label: '部位',
                            options: [{value: "0", label: ""}]
                        }}
                        value="0"
                        showLabel={false}/>
                    <TextField sx={{ml: 1}} size={SizeEnum.Small} inputWidth={SizeEnum.Small}/>
                    <div className="ml-4 mr-1">程度</div>
                    <Select
                        data={{
                            label: '程度',
                            options: [{value: "0", label: ""}]
                        }}
                        value="0"
                        showLabel={false}/>
                    <div className="ml-4 mr-1">型態</div>
                    <Select
                        width={SelectWidthEnum.Small}
                        data={{
                            label: '型態',
                            options: [{value: "0", label: ""}]
                        }}
                        value="0"
                        showLabel={false}/>
                    <p className="ml-4 mr-1">大小</p>
                    <TextField sx={{ml: 1}} size={SizeEnum.Small} inputWidth={SizeEnum.Small}/>
                </Field>
                {/*傷口2*/}
                <Field childrenSx={{display: 'flex', alignItems: 'center'}} label="傷口2">
                    <div className="flex items-center">
                        <p className="mr-1">部位</p>
                        <Select
                            width={SelectWidthEnum.Small}
                            data={{
                                label: '部位',
                                options: [{value: "0", label: ""}]
                            }}
                            value="0"
                            showLabel={false}/>
                        <TextField sx={{ml: 1}} size={SizeEnum.Small} inputWidth={SizeEnum.Small}/>
                    </div>
                    <div className="flex items-center">
                        <div className="ml-4 mr-1">程度</div>
                        <div className="flex items-center">
                            <Select
                                data={{
                                    label: '程度',
                                    options: [{value: "0", label: ""}]
                                }}
                                value="0"
                                showLabel={false}/>
                            <p className="ml-4 mr-1">型態</p>
                            <Select
                                width={SelectWidthEnum.Small}
                                data={{
                                    label: '型態',
                                    options: [{value: "0", label: ""}]
                                }}
                                value="0"
                                showLabel={false}/>
                            <p className="ml-4 mr-1">大小</p>
                            <TextField size={SizeEnum.Small} inputWidth={SizeEnum.Small}/>
                        </div>
                    </div>
                </Field>
                <Field sx={{display: 'flex', alignItems: 'start'}} label="8.肌肉骨骼功能">
                    <div className="flex items-center space-x-3">
                        <Checkbox label="正常"/>
                        <div className="flex flex-row items-center justify-start">
                            <Checkbox sx={{mr: 0}} label="關節腫-部位"/>
                            <TextField size={SizeEnum.Small} inputWidth={SizeEnum.Small}/>
                        </div>
                        <div className="flex flex-row items-center justify-start">
                            <Checkbox sx={{mr: 0}} label="脫臼-部位"/>
                            <TextField size={SizeEnum.Small} inputWidth={SizeEnum.Small}/>
                        </div>
                        <div className="flex flex-row items-center justify-start">
                            <Checkbox sx={{mr: 0}} label="截肢-部位"/>
                            <TextField size={SizeEnum.Small} inputWidth={SizeEnum.Small}/>
                        </div>
                    </div>
                    <div className="flex items-center space-x-3">
                        <div className="flex flex-row items-center justify-start">
                            <Checkbox label="萎縮-部位"/>
                            <TextField size={SizeEnum.Small} inputWidth={SizeEnum.Small}/>
                        </div>
                        <div className="flex flex-row items-center justify-start">
                            <Checkbox className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                                      type="checkbox" id="bone5"
                                      style={{accentColor: "#38A169"}}/>
                            <label className="mr-2" htmlFor="bone5">僵硬-部位</label>
                            <TextField size={SizeEnum.Small} inputWidth={SizeEnum.Small}/>
                        </div>
                    </div>
                </Field>
                <Field label="9.家庭功能">
                    {/*空行*/}
                    <></>
                </Field>
                <Field sx={{display: 'flex', alignItems: 'start'}} label="經濟來源">
                    <div className="flex items-center">
                        <Checkbox label="自己"/>
                        <Checkbox label="子女"/>
                        <Checkbox label="親友"/>
                    </div>
                    <div className="flex items-center">
                        <Checkbox label="政府補助"/>
                        <TextField size={SizeEnum.Small} inputWidth={SizeEnum.Small}/>
                        <p className="mr-8">元/月</p>
                        <Checkbox label="社會補助"/>
                        <TextField size={SizeEnum.Small} inputWidth={SizeEnum.Small}/>
                        <p>元/月</p>
                    </div>
                </Field>
                <Field sx={{display: 'flex', alignItems: 'start'}} label="經濟狀況">
                    <div className="flex items-center">
                        <input type="radio" id="economy1" name="economy"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="economy1">富裕</label>
                        <input type="radio" id="economy2" name="economy"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="economy2">小康</label>
                        <input type="radio" id="economy3" name="economy"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="economy3">中、低收入戶</label>
                        <input type="radio" id="economy4" name="economy"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="economy4">其他</label>
                        <TextField size={SizeEnum.Small}/>
                    </div>
                </Field>
                <Field label="宗教">
                    <div className="flex items-center">
                        <input type="radio" id="religion1" name="religion"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="religion1">無</label>
                        <input type="radio" id="religion2" name="religion"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="religion2">佛教</label>
                        <input type="radio" id="religion3" name="religion"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="religion3">基督教</label>
                        <input type="radio" id="religion4" name="religion"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="religion4">天主教</label>
                        <input type="radio" id="religion5" name="religion"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="religion5">民間信仰</label>
                        <input type="radio" id="religion6" name="religion"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="religion6">其他</label>
                        <TextField size={SizeEnum.Small}/>
                    </div>
                </Field>
                <Field label="溝通能力">
                    <div className="flex items-center">
                        <input type="radio" id="cs1" name="communicationSkills"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="cs1">正常</label>
                        <input type="radio" id="cs2" name="communicationSkills"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="cs2">失語</label>
                        <input type="radio" id="cs3" name="communicationSkills"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="cs3">言語不輕</label>
                        <input type="radio" id="cs4" name="communicationSkills"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="cs4">昏迷</label>
                        <input type="radio" id="cs5" name="communicationSkills"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-2" htmlFor="cs5">其他</label>
                        <TextField size={SizeEnum.Small}/>
                    </div>
                </Field>
                <Field label="溝通方式">
                    <div className="flex items-center">
                        <Checkbox label="國語"/>
                        <Checkbox label="台語"/>
                        <Checkbox label="客語"/>
                        <Checkbox label="筆談"/>
                        <Checkbox label="圖片"/>
                        <Checkbox label="手勢"/>
                        <Checkbox label="其他"/>
                        <TextField size={SizeEnum.Small}/>
                    </div>
                </Field>
            </div>
            <Divider/>
        </div>
    );
}
export default BasicAssessment