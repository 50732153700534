import { time2String } from 'edah_utils/dist'
import { DATE_FORMAT } from '../common'
import i18n from '../../i18n'

/**
 * 病症暨失能診斷證明列表
 */
export const TRAN_FOREIGN_LIST_FIELDS = [
    {
        key: 'edit',
        name: i18n.t('Rmis.pages.tranForeignList.tableHeader.edit'), // 編輯
        className: 'text-sm min-w-[80px]',
    },
    {
        key: 'sendDate',
        name: i18n.t('Rmis.pages.tranForeignList.tableHeader.sendDate'), // 寄發日期
        className: 'text-sm min-w-[120px] whitespace-nowrap',
        format: (value) => time2String(value, DATE_FORMAT),
    },
    {
        key: 'recipientDate',
        name: i18n.t('Rmis.pages.tranForeignList.tableHeader.recipientDate'), // 收件日期
        className: 'text-sm min-w-[120px] whitespace-nowrap',
        format: (value) => time2String(value, DATE_FORMAT),
    },
    {
        key: 'diagDate',
        name: i18n.t('Rmis.pages.tranForeignList.tableHeader.diagDate'), // 證明書日期
        className: 'text-sm min-w-[120px] whitespace-nowrap',
        format: (value) => time2String(value, DATE_FORMAT),
    },
    {
        key: 'applicantDate',
        name: i18n.t('Rmis.pages.tranForeignList.tableHeader.applicantDate'), // 申請日期
        className: 'text-sm min-w-[120px] whitespace-nowrap',
    },
    {
        key: 'patientId',
        name: i18n.t('Rmis.pages.tranForeignList.tableHeader.patientId'), // 病歷號
        className: 'text-sm min-w-[120px] whitespace-nowrap',
    },
    {
        key: 'patientName',
        name: i18n.t('Rmis.pages.tranForeignList.tableHeader.patientName'), // 姓名
        className: 'text-sm min-w-[120px] whitespace-nowrap',
    },
    {
        key: 'idNo',
        name: i18n.t('Rmis.pages.tranForeignList.tableHeader.idNo'), // 身分證號
        className: 'text-sm min-w-[120px] whitespace-nowrap',
    },
    {
        key: 'divName',
        name: i18n.t('Rmis.pages.tranForeignList.tableHeader.divName'), // 科別
        className: 'text-sm min-w-[120px] whitespace-nowrap',
    },
    {
        key: 'doctorName',
        name: i18n.t('Rmis.pages.tranForeignList.tableHeader.doctorName'), // 醫師
        className: 'text-sm min-w-[120px] whitespace-nowrap',
    },
    {
        key: 'barthelScore',
        name: i18n.t('Rmis.pages.tranForeignList.tableHeader.barthelScore'), // 巴氏量表分數
        className: 'text-sm whitespace-nowrap',
    },
    {
        key: 'barthelFlag',
        name: i18n.t('Rmis.pages.tranForeignList.tableHeader.barthelFlag'), // 巴氏量表零分/醫師是否勾選
        className: 'text-sm min-w-[120px]',
    },
    {
        key: 'cdrFlag',
        name: i18n.t('Rmis.pages.tranForeignList.tableHeader.cdrFlag'), // CDR表
        className: 'text-sm whitespace-nowrap',
    },
    {
        key: 'careHospName',
        name: i18n.t('Rmis.pages.tranForeignList.tableHeader.careHospName'), // 長照中心
        className: 'text-sm whitespace-nowrap min-w-[120px]',
    },
    {
        key: 'applyName',
        name: i18n.t('Rmis.pages.tranForeignList.tableHeader.applyName'), // 申請人姓名
        className: 'text-sm min-w-[120px] whitespace-nowrap',
    },
    {
        key: 'patientAddress',
        name: i18n.t('Rmis.pages.tranForeignList.tableHeader.patientAddress'), // 申請人地址
        className: 'text-sm min-w-[180px] whitespace-nowrap',
    },
    {
        key: 'returnDate', // TODO: api
        name: i18n.t('Rmis.pages.tranForeignList.tableHeader.returnDate'), // 收到退件日期
        className: 'text-sm min-w-[120px] whitespace-nowrap',
        format: (value) => time2String(value, DATE_FORMAT),
    },
    {
        key: 'returnTime',
        name: i18n.t('Rmis.pages.tranForeignList.tableHeader.returnTime'), // 退件次數
        className: 'text-sm min-w-[80px] whitespace-nowrap',
    },
    {
        key: 'returnReason',
        name: i18n.t('Rmis.pages.tranForeignList.tableHeader.returnReason'), // 退件原因1
        className: 'text-sm min-w-[120px] whitespace-nowrap',
    },
    {
        key: 'returnReason2',
        name: i18n.t('Rmis.pages.tranForeignList.tableHeader.returnReason2'), // 退件原因2
        className: 'text-sm min-w-[120px] whitespace-nowrap',
    },
    {
        key: 'returnReason3',
        name: i18n.t('Rmis.pages.tranForeignList.tableHeader.returnReason3'), // 退件原因3
        className: 'text-sm min-w-[120px] whitespace-nowrap',
    },
    {
        key: 'returnReason4',
        name: i18n.t('Rmis.pages.tranForeignList.tableHeader.returnReason4'), // 退件原因4
        className: 'text-sm min-w-[120px] whitespace-nowrap',
    },
    {
        key: 'returnReason5',
        name: i18n.t('Rmis.pages.tranForeignList.tableHeader.returnReason5'), // 退件原因5
        className: 'text-sm min-w-[120px] whitespace-nowrap',
    },
    {
        key: 'returnRemark',
        name: i18n.t('Rmis.pages.tranForeignList.tableHeader.returnRemark'), // 退件原因說明
        className: 'text-sm min-w-[180px] whitespace-nowrap',
    },
    {
        key: 'mpUser',
        name: i18n.t('Rmis.pages.tranForeignList.tableHeader.mpUser'), // 醫事課人員
        className: 'text-sm min-w-[120px] whitespace-nowrap',
    },
    {
        key: 'tranUser',
        name: i18n.t('Rmis.pages.tranForeignList.tableHeader.tranUser'), // 轉診櫃台人員
        className: 'text-sm min-w-[120px] whitespace-nowrap',
    },
    {
        key: 'payFeeNo', // TODO: api
        name: i18n.t('Rmis.pages.tranForeignList.tableHeader.payFeeNo'), //批價序號
        className: 'text-sm min-w-[120px] whitespace-nowrap',
    },
    {
        key: 'applicantNo',
        name: i18n.t('Rmis.pages.tranForeignList.tableHeader.applicantNo'), //申請號
        className: 'text-sm min-w-[120px] whitespace-nowrap',
    },
]
