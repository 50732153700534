//import Component
import RSISContentView from '../Modal/RSISContentView'
import AddModal from '../Modal/AddModal'
import CopyModal from '../Modal/CopyModal'
import EditModal from '../Modal/EditModal'
import ChangeModal from '../Modal/ChangeModal'
import LogOffModal from '../Modal/LogOffModal'
import DeletePopup from '../Popup/DeletePopup'
import QueryPersonList from "../Modal/QueryPersonList";
import QuerySupportOrg from "../Modal/QuerySupportOrg";
import QueryCreateUserList from "../Modal/QueryCreateUserList";

//Import Function
import React, {useEffect, useMemo, useState} from 'react'
import {
    ApiErrorStatusEnum,
    CampusEnum,
    enum2Array,
    getLocalStorage,
    RsisStepStatusEnum,
    RsisSupportModifyStatusEnum,
    stringIsEmpty
} from "edah_utils/dist"
import {RSISMasterQueryTable} from '../Constant';
import {USER_ROLE} from "../userRole";
import {getCurrentZoneNo, Layout, sanitizedData} from '../utils'
import CustomTable from '../RSISCustomTable'
import ListItem from './ListItem';
import {usePopup} from '../PopupProvider'
import {t} from 'i18next'
import {
    AlertTypeEnum,
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    Checkbox,
    DateRangePicker,
    IconEnum,
    Select,
    TextField,
    SelectWidthEnum
} from 'edah-component/dist'
import dayjs from 'dayjs';

//Import API
import {
    rsisDelMaster,
    rsisQueryBaseData,
    rsisQueryFunctionDocHtml,
    rsisQueryMaster,
    rsisQueryOrganization,
    rsisQueryOwnerUser
} from "../../../api/v1/RSIS"
import {useSelector} from "react-redux";
import {zoneSetQueryAll} from "../../../api/v1/Menu";

/**
 * 報備支援查詢 申請與查詢
 * @return {JSX.Element}
 */
const EntityRelationshipQuery = () => {
    // 當前院區
    const currentZone = getLocalStorage('campus')
    // 是否為承辦人員
    const isOwnUser = JSON.parse(getLocalStorage('isOwnUser')) || false
    // 取得menu id
    const activeTabId = useSelector(state => state.tab.activeTabId);
    // 資料清單
    const [dataList, setDataList] = useState([])
    // 使用上下文
    const {
        showAddModal,
        setShowAddModal,
        showCopyModal,
        setShowCopyModal,
        showEditModal,
        setShowEditModal,
        showChangeModal,
        setShowChangeModal,
        showLogOffModal,
        setShowLogOffModal,
        showContentView,
        setShowContentView,
        showQueryPersonList,
        setShowQueryPersonList,
        showQueryCreateUserList,
        setShowQueryCreateUserList,
        showQuerySupportOrgModal,
        setShowQuerySupportOrgModal,
        showDeletePopup,
        setShowDeletePopup,
        showToast,
        focusIndex,
    } = usePopup();

    // 列表組件 JSX Element
    const List = ListItem

    const [paginationProps, setPaginationProps] = useState({
        pageNum: 1,
        pageSize: 10,
        totalItemSize: 0,
        totalPageSize: 0,
        currentPageItemSize: 0,
        fn: () => {
        }
    })

    // 申請人工號
    const [createUser, setCreateUser] = useState('')
    // 醫師工號
    const [supportUserNo, setSupportUserNo] = useState('')
    // 支援機構
    const [supportOrgNo, setSupportOrgNo] = useState('')
    //分院類型
    const [branchType, setBranchType] = useState([])
    // 報備起始日期
    const [itemStartDate, setItemStartDate] = useState('')
    // 報備結束日期
    const [itemEndDate, setItemEndDate] = useState('')
    //衛福部案號
    const [sendCaseNo, setSendCaseNo] = useState('')
    // 申請狀態
    const [stepStatus, setStepStatus] = useState(RsisStepStatusEnum.All)
    // 狀態
    const [supportModifyStatus, setSupportModifyStatus] = useState(RsisSupportModifyStatusEnum.All);
    // 申請人員清單
    const [createUserList, setCreateUserList] = useState([])
    // 醫師清單
    const [doctorList, setDoctorList] = useState([])
    // 機構清單
    const [organizationList, setOrganizationList] = useState([])
    // 承辦人員清單
    const [ownerList, setOwnerList] = useState([])
    // 承辦部門清單
    const [deptList, setDeptList] = useState([])
    // 院區清單
    const [zoneSetList, setZoneSetList] = useState([])
    // 佈告欄內容
    const [htmlContent, setHtmlContent] = useState('')
    // 根據角色權限，變更table style
    const [tableStyle, setTableStyle] = useState('RSISMasterQueryOtherUser')

    /**
     * 監聽後台資料是否更新
     * @param user {String} 申請人
     * @return {void}
     */
    const handleDataIsUpdate = (user = null) => {
        //　申請人不為空時，設定申請人
        if (!stringIsEmpty(user)) {
            // 設定申請人
            setCreateUser(user)
        }
        // 重新取得資料
        getQueryMaster(user)
    }

    /**
     * 支援人員方框點擊時
     * @return {*}
     */
    const handlePersonSearchOnClick = () => setShowQueryCreateUserList(true)

    /**
     * 更新支援人員
     * @return {void}
     */
    const handleOnUpdateCreateUser = (item) => {
        setCreateUser(`${item.userNo} ${item.userName}`)
        setShowQueryCreateUserList(false)
    }

    /**
     * 醫師/工號方框點擊時
     * @return {*}
     */
    const handleQueryDoctorOnClick = () => setShowQueryPersonList(true)

    /**
     * 更新醫師/工號
     * @return {void}
     */
    const handleOnUpdateDoctor = (item) => {
        setSupportUserNo(`${item.userNo} ${item.userName}`)
        setShowQueryPersonList(false)
    }

    /**
     * 支援機構/工號方框點擊時
     * @return {*}
     */
    const handleQuerySupportOrg = () => setShowQuerySupportOrgModal(true)

    /**
     * 更新支援機構/工號
     * @return {void}
     */
    const handleOnUpdateSupportOrg = (item) => {
        setSupportOrgNo(`${item.orgNo} ${item.orgName}`)
        setShowQuerySupportOrgModal(false)
    }

    /**
     * 分院改變時
     * @param index {Number} 分院代碼
     * @return {void}
     */
    const handleBranchOnChange = (index) => {
        // 產生新資料
        const newCampus = [...branchType]
        // 設定新資料
        newCampus[index] = {
            // 取得原本資料
            ...newCampus[index],
            // 設定選取狀態
            selected: !(newCampus[index].selected)
        }
        // 設定分院類型
        setBranchType(newCampus)
    }

    /**
     * 報備日期變更事件
     * @param newValue {Array<dayjs>} 選中的日期範圍
     * @return {void}
     */
    const handleDateRangeOnChange = (newValue) => {
        const [startDate, endDate] = newValue;

        if (startDate) {
            setItemStartDate(dayjs(startDate).format('YYYY-MM-DD'));  // 更新報備開始日期
        }

        if (endDate) {
            setItemEndDate(dayjs(endDate).format('YYYY-MM-DD'));  // 更新報備結束日期
        }
    };

    /**
     * 衛福部案號改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleSendCaseNoOnChange = (e) => setSendCaseNo(e.target.value)

    /**
     * 階段狀態改變時
     * @param value {Number}
     * @return {void}
     */
    const handleStepStatusOnChange = (value) => setStepStatus(value)

    /**
     * 修改狀態改變時
     * @param value {string}
     * @return {void}
     */
    const handleSupportModifyStatusOnChange = (value) => setSupportModifyStatus(value)

    /**
     * 查詢按鈕點擊時
     * @return {void}
     */
    const handleQueryOnClick = () => getQueryMaster()

    /**
     * 刪除按鈕點擊時
     * @return {void}
     */
    const handleOnDelItem = () => {
        // 關閉刪除彈窗
        setShowDeletePopup(false)
        handleDelMaster()
    }

    /**
     * 報備支援內容彈窗關閉時
     * @return {*}
     */
    const handleRSISContentViewOnClose = () => setShowContentView(false)

    /**
     * 所有人員查詢彈窗關閉時
     * @return {*}
     */
    const handleQueryCreateUserListOnClose = () => setShowQueryCreateUserList(false)

    /**
     * 人員清單查詢彈窗關閉時
     * @return {*}
     */
    const handleQueryPersonListOnClose = () => setShowQueryPersonList(false)

    /**
     * 支援機構查詢彈窗關閉時
     * @return {*}
     */
    const handleQuerySupportOrgOnClose = () => setShowQuerySupportOrgModal(false)

    /**
     * 新增按鈕點擊時
     * @return {*}
     */
    const handleAddModalOnClose = () => setShowAddModal(false)

    /**
     * 複製彈窗關閉時
     * @return {*}
     */
    const handleCopyModalOnClose = () => setShowCopyModal(false)

    /**
     * 關閉修改彈窗
     * @return {*}
     */
    const handleEditModalOnClose = () => setShowEditModal(false)

    /**
     * 關閉新增彈窗
     * @return {*}
     */
    const handleChangeModalOnClose = () => setShowChangeModal(false)

    /**
     * 關閉報備支援內容顯示
     * @return {*}
     */
    const handleLogOffModalOnClose = () => setShowLogOffModal(false)

    /**
     * 關閉刪除彈窗
     * @return {*}
     */
    const handleDeletePopupOnClose = () => setShowDeletePopup(false)

    /**
     * 取得查詢報備支援資料
     * @param user {String|null}
     * @return {void}
     */
    const getQueryMaster = (user = null) => {
        // 送審案號為空時，判斷申請人與支援醫師是否為空
        if (stringIsEmpty(sendCaseNo)) {
            // 當申請人與支援醫師皆為空時，顯示提示訊息
            if (stringIsEmpty((createUser)) && stringIsEmpty(supportUserNo || user)) {
                showToast({message: t('RSIS.toast.queryDataNotComplete'), type: AlertTypeEnum.Warning})
                return
            }
        }
        // 取得勾選院區
        const zone = branchType.filter(item => item.selected).map(item => item.campus).join(',')
        // 支援機構代碼
        const orgNo = supportOrgNo ? (supportOrgNo.indexOf(' ') > 0 ? supportOrgNo.split(' ')[0] : supportOrgNo) : ''
        // 支援機構PK
        const org = organizationList.find(o => o.orgNo === orgNo)
        rsisQueryMaster({
            // 申請人/工號
            createUser: createUser ? (createUser.indexOf(' ') > 0 ? createUser.split(' ')[0] : createUser) : user ? (user.indexOf(' ') > 0 ? user.split(' ')[0] : supportUserNo) : '',
            // 支援醫師代碼
            supportUserNo: supportUserNo ? (supportUserNo.indexOf(' ') > 0 ? supportUserNo.split(' ')[0] : supportUserNo) : '',
            // 支援機構代碼
            supportOrgNo: org ? org.orgPkey : '',
            // 預設 0:全部/1:申請/2:確認/3:送
            // 審/刪除/9:失效
            stepStatus: stepStatus,
            // M:變更/D:刪除
            sendCaseNo: sendCaseNo,
            // 送審案號
            supportModifyStatus: supportModifyStatus,
            // 院區代號
            zone: zone,
            // 報備起始日期
            itemStartDate: stringIsEmpty(itemStartDate) ? '' : `${itemStartDate} 00:00:00`,
            // 報備結束日期
            itemEndDate: stringIsEmpty(itemEndDate) ? '' : `${itemEndDate} 23:59:59`,
        }).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            // 取得成功
            if (err === ApiErrorStatusEnum.Success) {
                const loginUser = getLocalStorage('userno')
                // 處理數組中的每個對象
                const processedData = data.map(item => {

                    // 非承辦人員，確定角色
                    let role = '';
                    // 判斷登入員是否是承辦人員
                    if (isOwnUser) {
                        // 設定role為承辦人員
                        role = USER_ROLE.DEPT_USER
                    } else { // 如果不是承辦人員
                        // 判斷是否為申請人
                        if (loginUser === item.createUser) role = USER_ROLE.CREATE_USER
                        // 判斷是否為支援醫師
                        else if (loginUser === item.supportUserNo) role = USER_ROLE.SUPPORT_USER
                        // 都不是則為其他
                        else role = USER_ROLE.OTHER_USER
                    }

                    // 過濾null或undefined的值，轉為空字串，並加入role資料
                    return Object.entries(item).reduce((acc, [key, value]) => {
                        acc[key] = (value === null || value === undefined) ? '' : value;
                        return acc;
                    }, {role});
                });
                // 當查詢資料為空時，顯示提示訊息
                if (processedData.length === 0) {
                    // 當有輸入申請人，沒有輸入支援醫師時
                    if (!stringIsEmpty(createUser) && stringIsEmpty(supportUserNo)) {
                        // 顯示查無此申請人資料
                        showToast({message: t('RSIS.toast.createUserNotFound'), type: AlertTypeEnum.Warning})
                    } else if (stringIsEmpty(createUser) && !stringIsEmpty(supportUserNo)) {
                        showToast({message: t('RSIS.toast.supportUserNotFound'), type: AlertTypeEnum.Warning})
                    } else if (!stringIsEmpty(createUser) && !stringIsEmpty(supportUserNo)) {
                        showToast({message: t('RSIS.toast.rsisDataNotFound'), type: AlertTypeEnum.Warning})
                    }
                }

                // 當每一筆資料的role都是OTHER_USER時，則設定tableStyle為RSISMasterQueryOtherUser
                const isOtherUser = processedData.every(item => item.role === USER_ROLE.OTHER_USER)
                isOtherUser ? setTableStyle('RSISMasterQueryOtherUser') : setTableStyle('RSISMasterQuery')

                // 設定資料
                setDataList(processedData)
            } else { // 取得失敗
                // 清空資料
                setDataList([])
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 刪除報備支援資料
     * @return {void}
     */
    const handleDelMaster = () => {
        const data = dataList[focusIndex]
        rsisDelMaster([data]).then(res => {
            // 狀態 / 訊息
            const {err, msg} = res
            // 刪除成功
            if (err === ApiErrorStatusEnum.Success) {
                // 重新取得資料
                getQueryMaster()
                showToast({message: msg, type: AlertTypeEnum.Success})
            } else { // 刪除失敗
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 重置搜尋條件按鈕點擊時
     * @return {void}
     */
    const handleResetSearchButtonOnClick = () => {
        // 清空值
        setCreateUser("")
        // 清空支援人員工號
        setSupportUserNo("")
        // 清空支援機構工號
        setSupportOrgNo("")
        // 清空報備起始日期
        setItemStartDate("")
        // 清空報備結束日期
        setItemEndDate("")
        // 清空衛福部案號
        setSendCaseNo("")
        // 重置階段狀態
        setStepStatus(RsisStepStatusEnum.All)
        // 重置修改狀態
        setSupportModifyStatus(RsisSupportModifyStatusEnum.All)
        // 重置分院類型
        initBranchType()
    }

    /**
     * 新增按鈕點擊時
     * @return {void}
     */
    const handleAddButtonOnClick = () => setShowAddModal(true)

    /**
     * 取得基本資料
     * @return {void}
     */
    const getQueryBaseData = () => {
        rsisQueryBaseData({}).then(res => {
            //狀態/資料/訊息
            const {err, data, msg} = res
            //取得成功
            if (err === ApiErrorStatusEnum.Success) {
                const {doctorList, deptList, createUserList, supportDivList} = data
                // 設定醫師清單資料
                setDoctorList(doctorList)
                // 設定承辦部門清單資料
                setDeptList(deptList)
                // 設定承辦人員清單資料
                setCreateUserList(createUserList)
            } else {
                // 清空醫師清單
                setDoctorList([])
                // 清空承辦人員清單
                setCreateUserList([])
                // 清空承辦部門清單
                setDeptList([])
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 取得承辦人員清單
     * @return {void}
     */
    const getOwnerUserList = () => {
        rsisQueryOwnerUser({}).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            // 取得成功
            if (err === ApiErrorStatusEnum.Success) {
                // 設定承辦人員清單
                setOwnerList(data)
            } else { // 取得失敗
                // 清空承辦人員清單
                setOwnerList([])
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 取得支援機構所有資料
     * @return {void}
     */
    const getOrganization = () => {
        rsisQueryOrganization({}).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            // 刪除成功
            if (err === ApiErrorStatusEnum.Success) {
                setOrganizationList(data)
            } else { // 刪除失敗
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 取得佈告欄
     * @return {void}
     */
    const getHTMLContent = () => {
        rsisQueryFunctionDocHtml({funNo: activeTabId}).then(res => {
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                setHtmlContent(data.htmlContent)
            } else {
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 取得分院機構清單
     * @return {void}
     */
    const getZoneSetQueryAll = () => {
        zoneSetQueryAll({}).then(res => {
            //狀態/資料/訊息
            const {err, data, msg} = res
            //取得成功
            if (err === ApiErrorStatusEnum.Success) {
                // 設定機構清單
                setZoneSetList(data)
            } else {
                // 清空機構清單
                setZoneSetList([])
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 初始化分院類型
     * @return {void}
     */
    const initBranchType = () => {
        //取得所有分院
        const allCampus = enum2Array(CampusEnum)
        // 產生分院選單
        const newCampus = allCampus.map(item => ({
            // 分院代碼
            campus: item.value,
            // 分院名稱
            campusName: t(`campus.shortName.${item.value}`),
            // 是否選取
            selected: currentZone === item.value,
        }))
        // 設定分院類型
        setBranchType(newCampus)
    }

    /**
     * 第一次執行時
     * @return {void}
     */
    useEffect(() => {
        initBranchType()
        // 若尚未取當前院區代號，則取得代號並儲存在localStorage
        if (getLocalStorage('campusId') === null) {
            // 取得院區代號
            getCurrentZoneNo()
        }
    }, [])

    // 報備支援測試用帳號: rsisApply  密碼為: test123@321Test
    /**
     * 第一次執行時
     */
    useMemo(() => {
        // 取得基本資料
        getQueryBaseData()
        // 取得承辦人員清單
        getOwnerUserList()
        // 取得支援機構所有資料
        getOrganization()
        // 取得佈告欄資料
        getHTMLContent()
        // 取得分院機構清單
        getZoneSetQueryAll()
    }, [])

    return (
        <Layout>
            {/* 查詢區塊 */}
            <div className='w-full flex flex-row justify-between items-center p-2'>
                {/* 查詢條件 */}
                <div className='flex flex-col py-5'>
                    {/* 第一列查詢條件 */}
                    <div className='flex items-center p-2 space-x-2'>
                        {/*支援人員*/}
                        <div className='flex flex-row space-x-2 items-center relative'>
                            <TextField label={`${t('RSIS.general.applicant')}/${t('RSIS.general.jobNum')}`}
                                       value={createUser} autoComplete='off'
                                       onClick={handlePersonSearchOnClick}/>
                        </div>
                        {/*報備支援醫師/工號*/}
                        <div className='flex flex-row space-x-2 items-center relative'>
                            <TextField label={`${t('RSIS.general.supportDoc')}/${t('RSIS.general.jobNum')}`}
                                       value={supportUserNo}
                                       onClick={handleQueryDoctorOnClick}/>
                        </div>
                        {/*支援機構/工號*/}
                        <div className='flex flex-row space-x-2 items-center relative'>
                            <TextField label={`${t('RSIS.general.supportOrg')}/${t('RSIS.general.jobNum')}`}
                                       value={supportOrgNo}
                                       onClick={handleQuerySupportOrg}/>
                        </div>
                        {/*院區選項*/}
                        <div>
                            {
                                branchType.map((item, index) =>
                                    <Checkbox label={item.campusName} checked={item.selected} key={item.campus}
                                              onChange={() => handleBranchOnChange(index)}/>
                                )
                            }
                        </div>
                    </div>
                    {/* 第二列查詢條件 */}
                    <div className='flex items-center p-2 space-x-2'>
                        {/*依報備期間*/}
                        <div className='flex items-center'>
                            <DateRangePicker
                                localeText={{
                                    start: '報備開始日期',
                                    end: '報備結束日期',
                                }}
                                value={[
                                    itemStartDate ? dayjs(itemStartDate) : null,
                                    itemEndDate ? dayjs(itemEndDate) : null
                                ]}
                                onChange={handleDateRangeOnChange}/>
                        </div>
                        {/*衛福部案號*/}
                        <div className='flex items-center'>
                            <TextField label={`${t('RSIS.general.ministryOfHealthAndWelfareNum')}`} value={sendCaseNo}
                                       onChange={handleSendCaseNoOnChange}/>
                        </div>
                        <div className='flex items-center gap-1 text-left'>
                            {/*階段狀態*/}
                            <Select
                                data={{
                                    label: "階段狀態",
                                    options: enum2Array(RsisStepStatusEnum).filter(i => i.value !== RsisStepStatusEnum.Invalid)
                                        .map((item) => ({
                                                value: item.value, label: t(`RSIS.StepStatusEnum.${item.value}`)
                                            }
                                        ))
                                }} value={stepStatus.toString()} width={SelectWidthEnum.Small} notched={true}
                                displayEmpty={true}
                                onChange={handleStepStatusOnChange}/>
                            {/*修改狀態*/}
                            <Select
                                data={{
                                    options: enum2Array(RsisSupportModifyStatusEnum).map((item) => ({
                                        value: item.value,
                                        label: t(`RSIS.SupportModifyStatusEnum.${item.value}`)
                                    }))
                                }} value={supportModifyStatus} width={SelectWidthEnum.Small} notched={true}
                                displayEmpty={true}
                                onChange={handleSupportModifyStatusOnChange}/>
                        </div>
                        {/*查詢按鈕*/}
                        <div className="source flex flex-row items-center justify-start">
                            <Button color={ButtonColorEnum.Primary} variant={ButtonVariantEnum.Contained}
                                    size={ButtonSizeEnum.Medium} text={t('general.query')}
                                    onClick={handleQueryOnClick}/>
                        </div>
                        {/*清除查詢條件按鈕*/}
                        <div className="source flex flex-row items-center justify-start">
                            <Button color={ButtonColorEnum.Primary} variant={ButtonVariantEnum.Outlined}
                                    size={ButtonSizeEnum.Medium} text='清除查詢條件'
                                    onClick={handleResetSearchButtonOnClick}/>
                        </div>
                    </div>
                    {/* 第三列新增按鈕 */}
                    <div className="ml-4 mt-2 mb-4">
                        <Button sx={{display: 'flex', alignItems: 'center'}} color={ButtonColorEnum.Success}
                                icon={IconEnum.Add} variant={ButtonVariantEnum.Text} size={ButtonSizeEnum.Large}
                                text={t('general.add')}
                                onClick={handleAddButtonOnClick}/>
                    </div>
                </div>
                {/* 佈告欄 */}
                <div className='w-1/6 h-[150px] bg-slate-300 flex flex-col justify-center items-center overflow-y-auto'
                     dangerouslySetInnerHTML={{__html: sanitizedData(htmlContent)}}/>
            </div>
            {/* 查詢結果 */}
            <CustomTable
                hasSelectAll={false}
                advancedSearch={true}
                fields={RSISMasterQueryTable.fields}
                dataList={dataList}
                depList={ownerList}
                ListItem={List}
                type={tableStyle}
                exportSlotBtn={true}
                paginationProps={paginationProps}/>

            {/* 報備支援內容顯示 */}
            {
                showContentView && (
                    <RSISContentView data={dataList[focusIndex]}
                                     handleOnClose={handleRSISContentViewOnClose}/>)
            }

            {/* 所有人員查詢 */}
            {
                showQueryCreateUserList && (
                    <QueryCreateUserList
                        handleOnClose={handleQueryCreateUserListOnClose}
                        handleOnUpdate={handleOnUpdateCreateUser}/>)
            }

            {/* 人員清單查詢 */}
            {
                showQueryPersonList && (
                    <QueryPersonList
                        handleOnClose={handleQueryPersonListOnClose}
                        handleOnUpdate={handleOnUpdateDoctor}/>)
            }

            {/* 支援機構查詢 */}
            {
                showQuerySupportOrgModal && (
                    <QuerySupportOrg
                        handleOnClose={handleQuerySupportOrgOnClose}
                        handleOnUpdate={handleOnUpdateSupportOrg}/>)
            }

            {/* 報備支援新增-彈出視窗 */}
            {
                showAddModal && (
                    <AddModal doctorList={doctorList}
                              orgList={organizationList}
                              deptList={deptList}
                              handleOnClose={handleAddModalOnClose}
                              handleOnUpdate={handleDataIsUpdate}/>)
            }

            {/* 報備支援複製-彈出視窗 */}
            {
                showCopyModal && (
                    <CopyModal data={dataList[focusIndex]}
                               doctorList={doctorList}
                               orgList={organizationList}
                               deptList={deptList}
                               handleOnClose={handleCopyModalOnClose}
                               handleOnUpdate={handleDataIsUpdate}/>)
            }

            {/* 報備支援修改-編輯彈出視窗 */}
            {
                showEditModal && (
                    <EditModal data={dataList[focusIndex]}
                               doctorList={doctorList}
                               orgList={organizationList}
                               deptList={deptList}
                               handleOnClose={handleEditModalOnClose}
                               handleOnUpdate={handleDataIsUpdate}/>)
            }

            {/* 報備支援修改-彈出視窗 */}
            {
                showChangeModal && (
                    <ChangeModal data={dataList[focusIndex]}
                                 handleOnClose={handleChangeModalOnClose}
                                 handleOnUpdate={handleDataIsUpdate}/>)
            }

            {/* 報備支援申請註銷作業-彈出視窗 */}
            {
                showLogOffModal && (
                    <LogOffModal data={dataList[focusIndex]}
                                 handleOnClose={handleLogOffModalOnClose}
                                 handleOnUpdate={handleDataIsUpdate}/>)
            }

            {/* 刪除彈窗 */}
            {
                showDeletePopup && (
                    <DeletePopup
                        currentKey={focusIndex}
                        handleDeletePopupClose={handleDeletePopupOnClose}
                        handleNotificationDelete={handleOnDelItem}/>)
            }
        </Layout>
    )
}

export default EntityRelationshipQuery
