import React, {useEffect, useState} from "react"
import {Dialog, DialogSizeEnums, DialogVariant} from 'edah-component/dist'

/**
 * 提示彈出窗
 * @param {String} promptText 提示字串
 * @param {Function} closePopupButtonOnClick 關閉事件
 * @param show {Boolean} 是否顯示彈窗
 * @returns
 */
const DeletePromptPopup = ({
                               promptText,
                               confirmOnClick,
                               cancelOnClick,
                               show = false
                           }) => {
    /**
     * 是否顯示彈窗
     */
    const [showPopup, setShowPopup] = useState(show)

    /**
     * 監聽是否顯示彈窗Pros變化
     */
    useEffect(() => {
        setShowPopup(show)
    }, [show])

    return (
        <Dialog
            open={showPopup}
            title={"提醒"}
            content={"是否確定刪除？"}
            variant={DialogVariant.DELETE}
            paperStyleProps={{width: DialogSizeEnums.SM}}
            onClose={cancelOnClick}
            onDelete={confirmOnClick}
            muiDialogActionsProps={{
                sx: {
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    justifyContent: 'end',
                    gap: '8px',
                    padding: '16px'
                }
            }}
        />
    )
}

export default DeletePromptPopup;
