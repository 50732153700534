import React, {useEffect, useState} from 'react';
import {objectIsEmpty, stringIsEmpty} from "edah_utils/dist";
import useToast from '../../../hooks/useToast';
import {AlertTypeEnum, Dialog, DialogSizeEnums, DialogVariant, DatePicker, TimePicker} from 'edah-component/dist'
import dayjs from "dayjs";

/**
 * 事件提示視窗組件
 * @param {Object} props - 組件屬性
 * @param {Function} props.onConfirm - 確認回調函數
 * @param {Function} props.onCancel - 取消回調函數
 * @param {Object} props.patientData - 病人資料
 * @param {Object} props.initialSelectTimeRange - 初始選擇的時間區間
 * @param {Object} props.apnTimeRange - 時段時間區間
 * @returns {JSX.Element} 事件提示視窗組件
 */
const CalendarPopup = ({
                           onConfirm,
                           onCancel,
                           patientData,
                           initialSelectTimeRange,
                           apnTimeRange
                       }) => {
    // 標題
    const [title, setTitle] = useState('');
    // 事件開始時間狀態
    const [startTime, setStartTime] = useState("");
    // 事件結束時間狀態
    const [endTime, setEndTime] = useState("");
    const [selectDate, setSelectDate] = useState("")
    //Toast Message Hooks
    const showToast = useToast()

    /**
     * 處理表單提交
     * @param {React.FormEvent} e - 表單提交事件
     */
    const handleSubmit = (e) => {
        e.preventDefault();
        /**
         * 將選擇的日期和時間組合成一個日期時間物件
         */
        const startDateTime = dayjs(`${selectDate} ${startTime}`, 'YYYY-MM-DD HH:mm').toDate();
        const endDateTime = dayjs(`${selectDate} ${endTime}`, 'YYYY-MM-DD HH:mm').toDate();
        //檢查start、end時間
        if (startDateTime < apnTimeRange.start || startDateTime > apnTimeRange.end) {
            showToast({message: "起始時間不在時段內", type: AlertTypeEnum.Error})
            return
        }
        if (endDateTime < apnTimeRange.start || endDateTime > apnTimeRange.end) {
            showToast({message: "結束時間不在時段內", type: AlertTypeEnum.Error})
            return
        }
        if (startDateTime >= endDateTime) {
            showToast({message: "起始時間不能大於等於結束時間", type: AlertTypeEnum.Error})
            return
        }

        onConfirm({
            title,
            startTime: startDateTime,
            endTime: endDateTime
        });
    };


    /**
     * 開始時間輸入框值變更時
     * @param {Date} newTime
     */
    const handleInputStartTimeOnChange = (newTime) => {
        setStartTime(dayjs(newTime).format('HH:mm'));
    };

    /**
     * 結束時間輸入框值變更時
     * @param {Date} newTime
     */
    const handleInputEndTimeOnChange = (newTime) => {
        setEndTime(dayjs(newTime).format('HH:mm'));
    };

    useEffect(() => {
        // 設定 日期、時間區間
        if (initialSelectTimeRange) {
            setSelectDate(dayjs(initialSelectTimeRange.start).format('YYYY-MM-DD'));
            setStartTime(dayjs(initialSelectTimeRange.start).format('HH:mm'));
            setEndTime(dayjs(initialSelectTimeRange.end).format('HH:mm'));
        }

        //設定title
        if (!objectIsEmpty(patientData)) {
            let titleText = ""
            if (!stringIsEmpty(patientData.patientId)) {
                titleText = `${patientData.patientId} `
            }
            if (!stringIsEmpty(patientData.patientName)) {
                titleText = `${titleText}${patientData.patientName} `
            }
            if (!stringIsEmpty(patientData.genderName)) {
                titleText = `${titleText}${patientData.genderName} `
            }
            setTitle(titleText)
        }
    }, [])


    const content = (
        <form className="px-4 pb-4" onSubmit={handleSubmit}>
            <p className="text-left mb-4 font-bold text-[24px]">{title}</p>
            <div className="flex flex-row justify-between items-center gap-2">
                <DatePicker
                    label=""
                    value={dayjs(selectDate)}
                    disabled
                    onChange={(e) => setSelectDate(e.target.value)}
                />
                <TimePicker
                label="開始時間"
                value={dayjs(`${startTime}`, 'HH:mm')}
                onChange={handleInputStartTimeOnChange}
                />
                <p>-</p>
                <TimePicker
                    label="結束時間"
                    value={dayjs(`${endTime}`, 'HH:mm')}
                    onChange={handleInputEndTimeOnChange}
                />
            </div>
        </form>
    );



    return (
        <Dialog
            open={true}
            title={"掛號確認"}
            content={content}
            onClose={onCancel}
            onSave={handleSubmit}
            variant={DialogVariant.EDIT}
            paperStyleProps={{width: DialogSizeEnums.SM}}
            muiDialogActionsProps={{
                sx: {
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    justifyContent: 'end',
                    padding: '1rem'
                }
            }}
        />
    );
};

export default CalendarPopup;
