import React, {useCallback, useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {t} from "i18next";
import {SearchTextField} from '../SearchTextField/SearchTextField'
import {
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    Card,
    Checkbox,
    DatePicker,
    IconEnum,
    SizeEnum,
    TextField,
    Select
} from 'edah-component/dist'
import dayjs from "dayjs"

/**
 * table欄位渲染內容
 * @param row {object} 單筆資料
 * @param field {object} 表格欄位
 * @param fieldIndex {number} 表格 index
 * @return {JSX.Element}
 */
const Content = ({row, field, fieldIndex}) => {
    // 若有 render 則渲染 render 的內容
    if (field.render) {
        return field.render(row, field, fieldIndex)
    }
    // 若有 format 則將值做格式化
    if (row[field.key] && field.format) {
        return field.format(row[field.key])
    }
    // 渲染值
    return <div>{row[field.key]}</div>
}
/**
 * 編輯模式渲染內容
 * @param row {object} 單筆資料
 * @param field {object} 表格欄位
 * @param allFields {array} 表格所有欄位
 * @param onChange {function} 改變單筆資料
 * @param rowIndex {number} 單筆資料 index
 * @param onBlur {function} 失去焦點事件
 */
const EditContent = ({row, field = [], allFields = [], onChange, rowIndex, onBlur}) => {
    // 可編輯欄位
    const editableField = allFields?.find(
        (item) => item.key === field.key && !!item.inputType
    )
    // 若有可編輯欄位，且當前列為編輯模式
    if (!!editableField && row.isEditable) {
        // 若有欄位檢核，則驗證欄位是否通過
        const isValid = editableField.validation
            ? editableField.validation(row[field.key])
            : true

        // 根據欄位類型渲染不同的元件
        switch (editableField.inputType) {
            // Select 元件
            case 'select':
                return (<Select
                    data={{
                        label: editableField.name,
                        // 將選項的 key 轉換成 value, label 讓 Select 元件使用
                        options: editableField.options.map((item) => ({
                            value: item[editableField.optionNameAndValue?.[1] ?? 'value'],
                            label: item[editableField.optionNameAndValue?.[0] ?? 'name']
                        }))
                    }}
                    // 將選項的 value 寫入相對應的欄位
                    value={row[field.mappingKey]}
                    onChange={(value) => onChange({...row, [field.mappingKey]: value}, rowIndex)}
                    showLabel={false}
                />)
            // TextField 元件
            case 'text':
                return (<TextField
                    inputWidth={SizeEnum.Fill}
                    value={row[field.key]}
                    onChange={(e) => onChange({...row, [field.key]: e.target.value}, rowIndex)}
                    error={!isValid}
                    helperText={isValid ? '' : editableField.errorMsg}
                    onBlur={(e) => onBlur(e, row, field.key)}
                />)
            {/* 日期元件 */
            }
            case 'date':
                return (
                    <DatePicker
                        label=""
                        value={row[field.key] ? dayjs(row[field.key]) : null}
                        onChange={(newValue) => {
                            const formattedDate = newValue ? newValue.format('YYYY-MM-DD') : ''
                            onChange({...row, [field.key]: formattedDate}, rowIndex)
                        }}
                        onBlur={(e) => onBlur(e, row, field.key)} // 維持原有的 onBlur 處理
                        slotProps={{
                            textField: {
                                error: !isValid, // 錯誤處理
                                helperText: editableField.errorMsg || '', // 顯示錯誤訊息
                            }
                        }}
                    />
                )

        }
    }
}


/**
 * @param props {object}
 * @param fields {array} 欄位名稱須包含{key: string, name: string, className?: string, isChecked?: boolean}
 * @param dataList {array} 列表資料
 * @param slotButton {jsx} 可插入功能按鈕(ex. 匯出excel)
 * @param isSelectable {boolean} 是否可選取
 * @param toggleAll {function} 全選所有資料
 * @param toggleItem {function} 選取單筆資料
 * @param rowOnClick {function} 點擊 row callback
 * @param isShoeDataFilter {boolean} 是否顯示進階搜尋
 * @param isCrud {boolean} 是否可CRUD
 * @param isEditMode {boolean} 是否編輯模式
 * @param handleEdit {function} 編輯列
 * @param handleSave {function} 儲存該列資料
 * @param handleCancel {function} 取消編輯
 * @param addNewData {function} 新增列
 * @param deleteRow {function} 刪除列
 * @param handleInputOnBlur {function} 失去焦點事件
 * @param title {string} 表格標題
 * @param isSingleSelect {boolean} 是否單選
 * @return {JSX.Element}
 * 
 */
const CustomTable = ({
                         fields,
                         dataList = [],
                         slotButton = null,
                         isSelectable = false,
                         toggleAll = () => {
                         },
                         toggleItem = () => {
                         },
                         isSingleSelect = false,
                         title = null,
                         isShoeDataFilter = true,
                         rowOnClick,
                         isCrud = false,
                         isEditMode = false,
                         handleEdit = () => {
                         },
                         handleSave = () => {
                         },
                         handleCancel = () => {
                         },
                         addNewData = () => {
                         },
                         deleteRow = () => {
                         },
                         handleInputOnBlur = () => {
                         },
                     }) => {
    const [tempDataList, setTempDataList] = useState(null);
    const [innerDataList, setInnerDataList] = useState(null);
    const handleInnerRows = (newRow, index) => {
        const updatedRow = {...newRow, isNew: false};
        innerDataList[index] = updatedRow;
        setTempDataList([...innerDataList]);
        setInnerDataList([...innerDataList]);
    }
    /**
     * 是否全選當頁清單
     */
    const [isSelectAll, setIsSelectAll] = useState(false)

    /**
     * 有無資料
     */
    const [isNoResult, setIsNoResult] = useState(false)

    /**
     * 進階搜尋關鍵字
     */
    const [keyword, setKeyword] = useState('')

    /**
     * 針對進階搜尋進行filter
     * @param {string} key 進階搜尋關鍵字
     * @param {array} dataList 列表資料
     */
    const filterDataList = useCallback((key, dataList) => {
        const lowercasedKeyword = key.toLowerCase()
        const filteredList = dataList?.filter((item) =>
            fields.some((field) => {
                return item[field.key]
                    ?.toString()
                    .toLowerCase()
                    .includes(lowercasedKeyword)
            })
        )
        const noResult = filteredList?.length === 0
        setIsNoResult(noResult)
        setInnerDataList(noResult ? [] : filteredList)
    }, [])

    /**
     * 改動進階搜尋input
     * @param {event} e
     */
    const handleDataFilter = (e) => {
        const value = e.target.value
        setKeyword(value)
        filterDataList(value, tempDataList)
    }

    /**
     * 勾選所有資料
     * @param {event} e
     */
    const handleToggleAll = (isChecked) => {
        setIsSelectAll(isChecked)
        const selectedData = innerDataList.map((item) => ({
            ...item,
            isSelected: isChecked,
        }))
        toggleAll(isChecked, selectedData)
        setInnerDataList(selectedData)
    }

    /**
     * 勾選單筆資料
     * @param {event} e
     */
    const handleToggleItem = (e, selectedItem, index) => {
        const value = e.target.checked
        toggleItem(value, selectedItem, index)
    }

    /**
     * 失去焦點事件
     * @param {event} e
     * @param {object} row 該列資料
     * @param {string} key key
     */
    const handleOnBlur = (e, row, key) => {
        handleInputOnBlur(e, row, key)
    }

    /**
     * 表格內渲染的資料
     * 1. 如果table fields傳入render，則渲染需要插入的元素
     * 2. 如果table fields有傳入format，則將傳入的值做統一格式
     * @param {object} row 單筆資料
     * @param {object} field 表格欄位
     * @param {number} fieldIndex 表格 index
     * @return {JSX.Element}
     */
    const handleRowOnClick = (row, index) => {
        if (!rowOnClick) {
            return
        }
        rowOnClick(row)
    }

    /**
     * 新增列
     */
    const handleAddRow = () => {
        const emtypData = {}
        fields.forEach((item) => {
            if (!!item.inputType) {
                emtypData[item.key] = ''
            }
        })
        addNewData(emtypData)
    }

    // 判斷是否全選dataList和檢查傳入的dataList是否有資料
    useEffect(() => {
        if (isSelectable) {
            const isSelectAll = innerDataList?.length
                ? innerDataList.every((item) => item.isSelected)
                : false
            setIsSelectAll(isSelectAll)
        }
    }, [innerDataList, keyword])

    useEffect(() => {
        setTempDataList(dataList)
        setInnerDataList(dataList)
    }, [dataList]);

    return (
        <Card>
            <div className="flex justify-between items-center">
                {isShoeDataFilter && (
                    <div className="flex items-center">
                        {title ? (
                            <h3 className="flex items-center text-[#2B6CB0] font-bold text-left border-l-[5px] pl-1 border-[#2B6CB0] mb-2 mr-3 h-[25px]">
                                {title}
                            </h3>
                        ) : null}
                        {isShoeDataFilter && (
                            <SearchTextField
                                sx={{mb: 1}}
                                onChange={(e) => handleDataFilter(e)}
                                value={keyword}
                                placeholder="進階搜尋"
                            />
                        )}
                    </div>
                )}
                <div>{slotButton}</div>
            </div>
            <div className=" max-h-[700px] w-full overflow-auto">
                <table className="table-fixed text-left min-w-full overflow-auto">
                    <thead className="bg-[#e4e4e7] h-[50px] border-collapse text-lg text-[#18181b]">
                    <tr>
                        {/* 全選Checkbox */}
                        {isSelectable && (
                            <th className="border-r border-[#111111]/15 w-[56px] text-center">
                                <Checkbox
                                    labelSx={{mr: 0}}
                                    checked={isSelectAll}
                                    onChange={(e) =>
                                        handleToggleAll(
                                            e.target.checked,
                                            innerDataList
                                        )
                                    }
                                    disabled={isSingleSelect}
                                />
                            </th>
                        )}
                        {/* CRUD 新增按鈕 */}
                        {isCrud && (
                            <th className="p-2 border-r border-[#111111]/15 w-[56px] text-left">
                                <Button
                                    icon={IconEnum.Add}
                                    color={ButtonColorEnum.Success}
                                    size={ButtonSizeEnum.Medium}
                                    variant={ButtonVariantEnum.Text}
                                    onClick={handleAddRow}
                                    disabled={isEditMode}
                                    text={t('general.add')}
                                />
                            </th>
                        )}
                        {/* 表格表頭渲染內容 */}
                        {fields.map((field, fieldIndex) => (
                            <th
                                key={`th_${field.key}_${fieldIndex}`}
                                className={`${field.className} p-2 border-r border-[#111111]/15 font-normal`}
                            >
                                {field.renderHeader
                                    ? field.renderHeader
                                    : field.name}
                            </th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    {innerDataList?.map((row, index) => (
                        <tr
                            key={index}
                            className={`h-[60px] ${
                                index % 2 === 0 ? '' : 'bg-[#f4f4f5]'
                            } text-[#18181b] hover:bg-glacierBlue ${
                                rowOnClick ? 'cursor-pointer' : ''
                            }`}
                            onClick={() => handleRowOnClick(row, index)}
                        >
                            {isSelectable &&
                                (isNoResult ? (
                                    <td className="p-2 border-r border-[#111111]/15 w-[56px]"></td>
                                ) : (
                                    <td className="p-2 border-r border-[#111111]/15 text-center">
                                        <Checkbox
                                            labelSx={{mr: 0}}
                                            checked={row.isSelected}
                                            onChange={(e) =>
                                                handleToggleItem(
                                                    e,
                                                    row,
                                                    index
                                                )
                                            }
                                        />
                                    </td>
                                ))}
                            {isCrud && (
                                <td className="p-2 border-r border-[#111111]/15 text-sm w-[150px]">
                                    {/* 非編輯模式，則顯示編輯、刪除按鈕 */}
                                    {!isNoResult && !row.isEditable && (
                                        <div className="flex items-center">
                                            {/* 編輯按鈕 */}
                                            <Button
                                                icon={IconEnum.Edit}
                                                color={ButtonColorEnum.Primary}
                                                size={ButtonSizeEnum.Medium}
                                                variant={ButtonVariantEnum.Text}
                                                onClick={() => handleEdit(row)}
                                                disabled={isEditMode}
                                                text={t('general.edit')}
                                            />
                                            {/* 刪除按鈕 */}
                                            <Button
                                                icon={IconEnum.Delete}
                                                color={ButtonColorEnum.Primary}
                                                size={ButtonSizeEnum.Medium}
                                                variant={ButtonVariantEnum.Text}
                                                onClick={() => deleteRow(row)}
                                                disabled={isEditMode}
                                                text={t('general.delete')}
                                            />
                                        </div>
                                    )}
                                    {/* 編輯模式，則顯示儲存、取消按鈕 */}
                                    {!isNoResult && row.isEditable && (
                                        <div className="flex items-center">
                                            {/* 儲存按鈕 */}
                                            <Button
                                                icon={IconEnum.Save}
                                                color={ButtonColorEnum.Primary}
                                                size={ButtonSizeEnum.Medium}
                                                variant={ButtonVariantEnum.Text}
                                                onClick={() => handleSave(row)}
                                                text={t('general.save')}
                                            />
                                            {/* 取消按鈕 */}
                                            <Button
                                                icon={IconEnum.Cancel}
                                                color={ButtonColorEnum.Primary}
                                                size={ButtonSizeEnum.Medium}
                                                variant={ButtonVariantEnum.Text}
                                                onClick={() => handleCancel(row)}
                                                text={t('general.cancel')}
                                            />
                                        </div>
                                    )}
                                </td>
                            )}
                            {fields.map((field, fieldIndex) => (
                                <td
                                    key={`cell_${field.key}_${fieldIndex}`}
                                    className={`${
                                        field.tdClassName
                                            ? field.tdClassName
                                            : ''
                                    } break-words p-2 border-r border-[#111111]/15`}
                                >
                                    {row.isEditable && field.inputType ?
                                        // 編輯模式，則渲染可編輯的元件
                                        <EditContent row={row} field={field} rowIndex={index} allFields={fields}
                                                     onChange={handleInnerRows} onBlur={handleOnBlur}/> 
                                        :
                                        // 非編輯模式，則渲染值
                                        <Content row={row} field={field} fieldIndex={fieldIndex}/>
                                    }
                                </td>
                            ))}
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </Card>
    )
}

CustomTable.propTypes = {
    fields: PropTypes.array.isRequired,
    dataList: PropTypes.array,
    slotButton: PropTypes.element,
    isSelectable: PropTypes.bool,
    toggleAll: PropTypes.func,
    toggleItem: PropTypes.func,
}

export default CustomTable
