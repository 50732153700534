//Import close icon for tab
import { useState } from "react";
import { ReactComponent as Close } from "../../assets/images/icons/close.svg";
import { ReactComponent as ActiveClose } from "../../assets/images/icons/activeClose.svg";

function TabItem({ name, id, onClose, isActive, onClick }) {
  const bgChange = isActive ? "bg-[#FAFAFA]" : "bg-[#E4E4E7]";
  const textColorChange = isActive ? "text-[#2B6CB0]" : "text-[#000000]";
  return (
    <div
      onClick={onClick}
      className={`flex flex-row justify-between items-center min-w-[200px] h-[38px] rounded-t-[6px] p-4 py-1 ${bgChange}`}
    >
      <div className={`text-lg mr-2 ${textColorChange}`}>{name}</div>
      {isActive ? (
        <ActiveClose
          className="w-3 h-3 hover:cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            onClose(id);
          }}
        />
      ) : (
        <Close
          className="w-3 h-3 hover:cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            onClose(id);
          }}
        />
      )}
    </div>
  );
}

export default TabItem;
