import { useState } from 'react'

/**
 * 編輯資料表格
 * @return {object}
 */
const useDataGrid = () => {
    /**
     * 資料列表
     */
    const [dataList, setDataList] = useState([])
    /**
     * 原始資料列表
     */
    const [originDataList, setOriginDataList] = useState([])
    /**
     * 編輯模式
     */
    const [isEditMode, setIsEditMode] = useState(false)

    /**
     * 取得列表資料
     * @param {array} data 列表資料
     */
    const updateDataList = (data) => {
        const newData = data.map((item, index) => ({
            ...item,
            // 是否為編輯模式 
            isEditable: false,
            // 原始索引
            originIndex: index,
            // 是否為新增資料
            isNewData: false,
            // 是否選取
            isSelected:false,
        }))
        setDataList(newData)
        setOriginDataList(newData)
        setIsEditMode(false)
    }

    /**
     * 該列資料顯示編輯模式
     * @param tableRow {object} 該列資料
     * @return {void}
     */
    const handleEdit = (tableRow) => {
        // 將該列資料設定為編輯模式
        const getNewList = (prevDataList) => {
            const newDataList = prevDataList.map((item, index) => {
                if (index === tableRow.originIndex) {
                    return {
                        ...item,
                        ...tableRow,
                        isEditable: !item.isEditable,
                    }
                }
                return item
            })

            return newDataList
        }
        setIsEditMode(true)
        setDataList(getNewList)
        setOriginDataList(getNewList)
    }

    /**
     * 儲存該列資料
     * @param {object} tableRow
     */
    const handleSave = (tableRow) => {
        // 儲存後將該列資料設定為非編輯模式，isNewData 設定為 false (非新增資料)
        const getNewList = (prevDataList) => {
            const newDataList = prevDataList.map((item, index) => {
                if (index === tableRow.originIndex) {
                    return {
                        ...item,
                        isEditable: false,
                        isNewData: false,
                    }
                }
                return item
            })
            return newDataList
        }
        setIsEditMode(false)
        setDataList(getNewList)
        setOriginDataList(getNewList)
    }

    /**
     * 取消該列資料變動
     * @param {object} tableRow
     */
    const handleCancel = (tableRow) => {
        // 如果該列資料為新增資料，則移除新增的資料
        if (tableRow.isNewData) {
            setDataList((prev) => {
                const list = prev.filter(
                    (item) => item.originIndex !== tableRow.originIndex
                )
                return list
            })
            setOriginDataList((prev) => {
                const list = prev.filter(
                    (item) => item.originIndex !== tableRow.originIndex
                )
                return list
            })
        } else {
            // 取消編輯模式，且資料寫回原始資料
            setDataList((prev) => {
                return prev.map((item) => {
                    if (item.originIndex === tableRow.originIndex) {
                        return {
                            ...originDataList[tableRow.originIndex],
                            isEditable: false,
                        }
                    } else {
                        return item
                    }
                })
            })
        }

        setIsEditMode(false)
    }

    /**
     * 刪除該列資料
     * @param {object} tableRow
     * @return {void}
     */
    const deleteRow = (tableRow) => {
        const newDataList = dataList
            .filter((item) => item.originIndex !== tableRow.originIndex)
            .map((item, index) => {
                return {
                    ...item,
                    originIndex: index,
                }
            })
        setDataList(newDataList)
        setOriginDataList(newDataList)
    }

    /**
     * 新增一列資料
     * @param {object} row
     */
    const addNewData = (row) => {
        setDataList((prevDataList) => [
            ...prevDataList,
            {
                ...row,
                originIndex: prevDataList.length,
                isNewData: true,
                isEditable: true,
                isSelected: false,
            },
        ])
        setIsEditMode(true)
    }

    /**
     * 新增一列資料
     * @param row {object} 該列資料
     * @param key {string} 該列資料的key
     * @param value {string} 該列資料的value
     */ 
    const dataOnChange = (row, key, value) => {
        setDataList((dataList) =>
            dataList.map((item, index) => {
                if (index === row.originIndex) {
                    return {
                        ...item,
                        [key]: value,
                    }
                }
                return item
            })
        )
    }

    /**
     * 選擇單筆資料
     * @param isChecked {boolean} 是否勾選
     * @param selectedItem {object} 勾選的資料
     * @param index {number} 勾選項目的index
     * @return {void}
     */
    const handleToggleItem = (isChecked, selectedItem, index) => {
        const modifiedList = dataList.map((item) => {
            if (selectedItem.originIndex === item.originIndex) {
                return {
                    ...item,
                    isSelected: isChecked,
                }
            }
            return item
        })
        setDataList(modifiedList)
    }

    /**
     * 選擇所有資料 
     * @param isChecked {boolean} 是否勾選
     * @return {void}
     */
    const handleToggleAll = (isChecked) => {
        setDataList((prevDataList) =>
            prevDataList.map((item) => ({
                ...item,
                isSelected: isChecked,
            }))
        )
    }

    return {
        updateDataList,
        handleEdit,
        handleSave,
        handleCancel,
        addNewData,
        dataOnChange,
        handleToggleItem,
        handleToggleAll,
        deleteRow,
        dataList,
        isEditMode,
    }
}

export default useDataGrid
