import React, {useEffect, useState} from "react"
import {DefaultButton} from "../Button/DefaultButton";
import { access } from "fs-extra";

/**
 * 退掛確認彈窗
 * @param show 是否顯示
 * @param closePopupButtonOnClick 關閉按鈕點擊事件
 * @param pinCode pincode值，無則跳出輸入視窗
 * @returns
 */
const PdfJsonPopup = ({
   show = false,
   closePopupButtonOnClick,
   pinCode
}) => {
    /*讀取HCA卡btn
    * 出現彈跳視窗>(如PIN CODE為空要求輸入，如PIN CODE有值將其帶入)要求輸入pin code>HCA API>顯示成功/失敗原因
    * 成功紀錄此筆certTypeCa、certTypeId1、certTypeId2
    * mode=0請輸入pincode mode=1將pincode傳入API並讀取 mode=2讀取成功失敗訊息
    **/

    /**
     * 是否顯示彈窗
     */
    const [showPopup, setShowPopup] = useState(show)

    /**
     * mode=0請輸入pincode mode=1將pincode傳入API並讀取 mode=2讀取成功失敗訊息
     */
    const [mode, setMode] = useState(1)

    /**
     * 確定刪除點擊事件
     */
    const handleConfirmOnClick = () => {
        closePopupButtonOnClick()
    }

    /**
     * 取消刪除點擊事件
     */
    const handleCancelOnClick = () => {
        closePopupButtonOnClick()
    }

    /**
     * 監聽是否顯示彈窗Pros變化
     */
    useEffect(() => {
        setShowPopup(show)
        setMode(pinCode? 1 : 0)
        console.log(mode)
    }, [show])

    return showPopup ? <>
        <div
            className="absolute top-0 left-0 bg-[rgba(0,0,0,0.5)] w-screen h-screen z-80 min-w-screen min-h-screen items-center justify-center flex py-12 px-[100px] text-globalFont"
        >
            <div
                className="flex w-[800px] bg-[#ffffff] rounded-md text-lg relative flex flex-col"
                onClick={(e) => e.stopPropagation()}>
                {/**Title */}
                <div
                    className="h-[60px] w-full px-6 py-4 bg-white rounded-tl-lg rounded-tr-lg border-b border-neutral-900 border-opacity-20 flex justify-between items-center gap-2.5">
                    <div className="text-black text-xl font-normal">
                        讀取HCA卡片
                    </div>
                    <div className="text-sky-700 text-lg font-normal cursor-pointer"
                        onClick={handleConfirmOnClick}>關閉
                    </div>
                </div>
                {/**Container */}
                <div>
                    {pinCode}
                </div>
            </div>
        </div>

    </> : <></>
}

export default PdfJsonPopup;